import {useNavigate, useParams} from 'react-router-dom';
import BaseTeamMembers from '../BaseFinancialInstruments/teamMembers/BaseTeamMembers';
import {useDispatch, useSelector} from 'react-redux';
import {handleBondsInstrumentState} from 'redux/store/assetClass';
import {createBondsInstrumentRoute} from './routes';

export const BondsTeamMembers = () => {
  const navigate = useNavigate();
  const {assetId} = useParams();
  const dispatch = useDispatch();
  const data = useSelector(state => state?.assetClass?.bondInstruments);

  const handleSubmit = values => {
    dispatch(handleBondsInstrumentState({...values}));
    navigate(createBondsInstrumentRoute({param: assetId, name: 'faq'}));
  };

  return (
    <div>
      <BaseTeamMembers onSubmit={handleSubmit} existingData={data} />
    </div>
  );
};
