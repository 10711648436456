import {useEffect, useState} from 'react';
import styles from './planCard.module.scss';
import trashIcon from 'images/delete.svg';
import editIcon from 'images/edit.svg';
import checkIcon from 'images/check.svg';
import disabledIcon from 'images/disabled.svg';
import DBModal from 'components/modal/modal';
import {Button} from '@dabafinance/react-components';
import {useMutation, useQuery} from '@apollo/client';
import {DELETE_PRICE, DELETE_PRODUCT} from 'graphql/mutations/plans';
import {GET_ALL_PRICES, GET_ALL_PRODUCTS} from 'graphql/queries/plans';
import {toast} from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {useNavigate} from 'react-router-dom';
import {CloseCircleOutlined} from '@ant-design/icons';

const DeleteProductModal = ({open, setIsOpen, id}) => {
  const [deleteProduct, {error, loading}] = useMutation(DELETE_PRODUCT);

  if (error) {
    toast.error('Something went wrong');
    setIsOpen(false);
  }

  const handleDeletePlan = () => {
    deleteProduct({
      variables: {
        stripeProductId: id,
      },
      refetchQueries: [{query: GET_ALL_PRODUCTS}],
    })
      .then(({data: {deleteProduct}}) => {
        if (deleteProduct.__typename === 'Error') {
          toast.error('Something went wrong');
        } else {
          toast.success('Deleted Successfully');
          setIsOpen(false);
        }
      })
      .catch(error => toast.error(error.message));
  };

  return (
    <DBModal
      isOpen={open}
      handleClose={() => setIsOpen(false)}
      content={
        <div className={styles['modal-container']}>
          <h1 className={styles['modal-title']}>
            Are you sure you want to delete this Plan?
          </h1>
          <div>
            <Button
              label="Cancel"
              className={styles.button}
              type="secondary"
              onClick={() => setIsOpen(false)}
            />
            <Button
              label="Yes, Delete"
              disabled={loading}
              className={styles.button}
              onClick={handleDeletePlan}
            />
          </div>
        </div>
      }
    />
  );
};

const DeletePriceModal = ({open, setIsOpen, id}) => {
  const [deletePrice, {error, loading}] = useMutation(DELETE_PRICE);

  if (error) {
    toast.error('Something went wrong');
    setIsOpen(false);
  }

  const handleDeletePrice = () => {
    deletePrice({
      variables: {
        stripePriceId: id,
      },
    })
      .then(({data: {deletePrice}}) => {
        if (deletePrice.__typename === 'Error') {
          toast.error('Something went wrong');
        } else {
          toast.success('Deleted Successfully');
          setIsOpen(false);
        }
      })
      .catch(error => toast.error(error.message));
  };

  return (
    <DBModal
      isOpen={open}
      handleClose={() => setIsOpen(false)}
      content={
        <div className={styles['modal-container']}>
          <h1 className={styles['modal-title']}>
            Are you sure you want to delete this Price?
          </h1>
          <div>
            <Button
              label="Cancel"
              className={styles.button}
              type="secondary"
              onClick={() => setIsOpen(false)}
            />
            <Button
              label="Yes, Delete"
              disabled={loading}
              className={styles.button}
              onClick={handleDeletePrice}
            />
          </div>
        </div>
      }
    />
  );
};

export const FreePlanCard = ({data, operations}) => {
  const [open, setIsOpen] = useState(false);
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const [totalPrice, setTotalPrice] = useState([]);
  const navigate = useNavigate();

  const {data: price, loading} = useQuery(GET_ALL_PRICES);

  useEffect(() => {
    setTotalPrice(
      price?.listPrices?.prices?.filter(
        e => e.stripeProductId === data.stripeProductId,
      ),
    );
  }, [price?.listPrices?.prices]);

  if (loading) {
    return (
      <div className={styles.skeleton}>
        <Skeleton
          count={1}
          width={300}
          baseColor="#f1eff5"
          height={150}
          className={styles.loader}
        />
        <br />
        <Skeleton
          count={1}
          width={300}
          height={350}
          baseColor="#f1eff5"
          className={styles.loader}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>{data?.name}</h1>
        <div className={styles['header-right']}>
          {operations?.delete && (
            <img src={trashIcon} alt="" onClick={() => setIsOpen(true)} />
          )}
          {operations?.update && (
            <img
              src={editIcon}
              alt=""
              onClick={() =>
                navigate(
                  `/dashboard/plans/update-product/${data?.stripeProductId}`,
                )
              }
            />
          )}
        </div>
      </div>
      <div>
        {totalPrice?.length >= 1 && (
          <h1 className={styles['price-title']}>Price(s)</h1>
        )}
        {totalPrice?.length >= 1 ? (
          totalPrice?.map((data, key) => (
            <div className={styles['price-card']} key={key}>
              <div className={styles.price}>
                <div key={data?.id} className={styles.amount}>
                  <h1>${data?.baseAmount}/</h1>
                  <h2>{data?.billingCycle}</h2>
                </div>
                <div className={styles.trial}>
                  <span>{`${data?.trialPeriodDays} Trial Days`}</span>
                </div>
              </div>
              <div>
                <CloseCircleOutlined
                  onClick={() => setOpenPriceModal(true)}
                  style={{fontSize: 20, cursor: 'pointer'}}
                />
              </div>
              <DeletePriceModal
                open={openPriceModal}
                setIsOpen={setOpenPriceModal}
                id={data.stripePriceId}
              />
            </div>
          ))
        ) : (
          <div className={styles['free-plan']}>
            <h1>This Product has no price</h1>
          </div>
        )}
      </div>
      <div>
        <h1 className={styles['price-title']}>Product</h1>
      </div>
      <div className={styles.plan}>
        {data?.items?.map(({title, functionalities}) => (
          // eslint-disable-next-line react/jsx-key
          <div>
            <h1>{title}</h1>
            {functionalities?.map(({feature, isAllowed}) => (
              // eslint-disable-next-line react/jsx-key
              <div className={styles.option}>
                <img src={isAllowed ? checkIcon : disabledIcon} alt="" />
                <span>{feature}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
      <DeleteProductModal
        open={open}
        setIsOpen={setIsOpen}
        id={data?.stripeProductId}
      />
    </div>
  );
};
