import {Form, Input} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {ventureFund} from 'redux/store/campaign';
import styles from './ventureFund.module.scss';
import FundObjectives from './ventureFundCard/fundObjectives';
import VentureFundScore from './ventureFundCard/ventureFundScore';
import {GET_ALL_STARTUPS} from 'graphql/queries/startupCompanies';
import {useQuery} from '@apollo/client';
import FadeEffect from 'components/animation/fadeEffect';
import ComparableVentureFundCard from './ventureFundCard/comparableVentureFundCard';
import plusIcon from 'images/plus-circle.png';
import VentureFundCard from './ventureFundCard/ventureFundCard';
import {
  UploadDocumentToBucket,
  resizeImageFile,
} from '../legalDocument/uploadDocument';

const VentureFundField = () => {
  const [fundObjectives, setFundObjectives] = useState({
    currentIRR: 0.0,
    currentMultipleOnInvestedCapital: 0.0,
    fundName: '',
    fundsClosedToDate: '',
    targetAverageCheckSize: 0.0,
    targetFundSize: 0.0,
    targetIRR: 0.0,
    targetMultipleOnInvestedCapital: 0.0,
    targetNumberOfInvestments: 0.0,
  });
  const [investmentStrategy, setInvestmentStrategy] = useState('');
  const [portfolioCompanyType, setPortfolioCompanyType] = useState([
    {
      companyId: null,
      description: '',
      imageUrl: '',
      multipleOnInvestedCapital: 0.0,
      name: '',
      stageInvested: '',
      title: '',
    },
  ]);
  const [score, setScore] = useState({
    eSGAndSocialImpact: 0.0,
    fundEconomicsAndFinancials: 0.0,
    fundManager: 0.0,
    fundOverview: 0.0,
    legalAndCompliance: 0.0,
    portfolioConstruction: 0.0,
    returnOutlook: 0.0,
  });
  const [thesisAndInvestmentPhilosophy, setThesisAndInvestmentPhilosophy] =
    useState('');
  const [comparableVentureFund, setComparableVentureFund] = useState([
    {
      name: '',
      companiesInvestedIn: [
        {
          companyId: '',
          imageUrl: '',
        },
      ],
      description: '',
      fundSize: 0.0,
    },
  ]);
  const [offeringName, setOfferingName] = useState('');
  const {slug, id, companyId} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const campaign = useSelector(state => state.campaign.campaign);
  const [loadingImageUpload, setLoadingImageUpload] = useState(false);

  const {data: getAllCompanies} = useQuery(GET_ALL_STARTUPS);

  useEffect(() => {
    if (campaign?.ventureFundFields) {
      setFundObjectives(campaign?.ventureFundFields?.fundObjectives);
      setInvestmentStrategy(campaign?.ventureFundFields?.investmentStrategy);
      setPortfolioCompanyType(
        campaign?.ventureFundFields?.portfolioCompanyType,
      );
      setScore(campaign?.ventureFundFields?.score);
      setThesisAndInvestmentPhilosophy(
        campaign?.ventureFundFields?.thesisAndInvestmentPhilosophy,
      );
      setComparableVentureFund(
        campaign?.ventureFundFields?.comparableVentureFund,
      );
      setOfferingName(campaign?.ventureFundFields?.offeringName);
    }
  }, [campaign?.ventureFundFields]);

  const handleDeleteImage = i => {
    const newFormValues = [...portfolioCompanyType];
    newFormValues[i].imageUrl = '';
    setPortfolioCompanyType(newFormValues);
  };

  const handleChangeImage = async (i, {file}) => {
    const temp = file.originFileObj;
    const newFormValues = [...portfolioCompanyType];
    const resizedFile = await resizeImageFile(temp);
    setLoadingImageUpload(true);
    const res = await UploadDocumentToBucket(resizedFile);
    setLoadingImageUpload(false);
    newFormValues[i].imageUrl = res;
    setPortfolioCompanyType(newFormValues);
  };

  const handleAddComparableVentureFund = () => {
    setComparableVentureFund([
      ...comparableVentureFund,
      {
        name: '',
        companiesInvestedIn: [
          {
            companyId: '',
            imageUrl: '',
          },
        ],
        description: '',
        fundSize: 0.0,
      },
    ]);
  };

  const handleDeleteComparableVentureFund = id => {
    const filteredFormValue = comparableVentureFund.filter(
      (data, index) => index !== id,
    );
    setComparableVentureFund(filteredFormValue);
  };

  const handleFundObjectivesChange = (name, value) => {
    setFundObjectives({
      ...fundObjectives,
      [name]: value,
    });
  };

  const handleAddPortfolioCompany = () => {
    setPortfolioCompanyType([
      ...portfolioCompanyType,
      {
        companyId: null,
        description: '',
        imageUrl: '',
        multipleOnInvestedCapital: 0.0,
        name: '',
        stageInvested: '',
        title: '',
      },
    ]);
  };

  const handleDeletePortfolioCompany = id => {
    const filteredFormValue = portfolioCompanyType.filter(
      (data, index) => index !== id,
    );
    setPortfolioCompanyType(filteredFormValue);
  };

  const handlePortfolioCompanyTypeChange = (i, name, value) => {
    const newFormValues = [...portfolioCompanyType];
    if (name === 'companyInDaba') {
      newFormValues[i].companyId = value?.split('~/-')[0];
      newFormValues[i].imageUrl = value?.split('~/-')[2];
      newFormValues[i].name = value?.split('~/-')[1];
      setPortfolioCompanyType(newFormValues);
    } else {
      newFormValues[i][name] = value;
      setPortfolioCompanyType(newFormValues);
    }
  };
  const handleScoreChange = (name, value) => {
    setScore({
      ...score,
      [name]: value,
    });
  };
  const handleComparableVentureFundChange = (i, name, value) => {
    const newFormValues = [...comparableVentureFund];
    if (name === 'companiesInvestedIn') {
      newFormValues[i][name] = value.map(item => {
        return {
          imageUrl: item?.split('~/-')[1],
          companyId: item?.split('~/-')[0],
        };
      });
      setComparableVentureFund(newFormValues);
    } else {
      newFormValues[i][name] = value;
      setComparableVentureFund(newFormValues);
    }
  };

  const handleSubmit = () => {
    dispatch(
      ventureFund({
        ventureFundFields: {
          comparableVentureFund: comparableVentureFund.map(item => {
            return {
              companiesInvestedIn: item?.companiesInvestedIn.map(item => {
                return {
                  companyId: item?.companyId,
                  imageUrl: item?.imageUrl,
                };
              }),
              name: item?.name,
              description: item?.description,
              fundSize: parseFloat(item?.fundSize),
            };
          }),
          fundObjectives: {
            ...fundObjectives,
            currentIRR: parseFloat(0.0),
            currentMultipleOnInvestedCapital: parseFloat(0.0),
            targetAverageCheckSize: parseFloat(
              fundObjectives?.targetAverageCheckSize,
            ),
            targetFundSize: parseFloat(fundObjectives?.targetFundSize),
            targetIRR: parseFloat(fundObjectives?.targetIRR),
            targetMultipleOnInvestedCapital: parseFloat(
              fundObjectives?.targetMultipleOnInvestedCapital,
            ),
            targetNumberOfInvestments: parseFloat(
              fundObjectives?.targetNumberOfInvestments,
            ),
          },
          investmentStrategy,
          offeringName,
          portfolioCompanyType: portfolioCompanyType.map(item => {
            return {
              companyId: item?.companyId,
              description: item?.description,
              imageUrl: item?.imageUrl,
              name: item?.name,
              stageInvested: item?.stageInvested,
              title: item?.title,
              multipleOnInvestedCapital: parseFloat(
                item?.multipleOnInvestedCapital,
              ),
            };
          }),
          score: {
            eSGAndSocialImpact: parseFloat(score?.eSGAndSocialImpact),
            fundEconomicsAndFinancials: parseFloat(
              score?.fundEconomicsAndFinancials,
            ),
            fundManager: parseFloat(score?.fundManager),
            fundOverview: parseFloat(score?.fundOverview),
            legalAndCompliance: parseFloat(score?.legalAndCompliance),
            portfolioConstruction: parseFloat(0.0),
            returnOutlook: parseFloat(score?.returnOutlook),
          },
          thesisAndInvestmentPhilosophy,
        },
      }),
    );
    if (slug) {
      navigate(`/dashboard/campaigns/${slug}/new/${id}/founders/${companyId}`);
    } else {
      navigate(`/dashboard/campaigns/new/${id}/documents/${companyId}`);
    }
  };

  return (
    <div className={styles.container}>
      <Form onFinish={handleSubmit} layout="vertical">
        <div>
          <h1 className={styles.header}>Fund Objectives</h1>
        </div>
        <FundObjectives
          campaign={campaign}
          objectives={fundObjectives}
          handleChange={handleFundObjectivesChange}
        />
        <div>
          <h1 className={styles.header}>Venture Fund Score</h1>
        </div>
        <VentureFundScore
          campaign={campaign}
          score={score}
          handleChange={handleScoreChange}
        />
        <div>
          <h1 className={styles.header}>Portfolio Company Type</h1>
        </div>
        {portfolioCompanyType?.map((data, index) => (
          // eslint-disable-next-line react/jsx-key
          <FadeEffect>
            <VentureFundCard
              id={index}
              loadingImageUpload={loadingImageUpload}
              handleChangeImage={e => handleChangeImage(index, e)}
              handleDeleteImage={() => handleDeleteImage(index)}
              handleDelete={() => handleDeletePortfolioCompany(index)}
              handleChange={(name, value) =>
                handlePortfolioCompanyTypeChange(index, name, value)
              }
              portfolioCompanyType={data}
              companies={getAllCompanies}
            />
          </FadeEffect>
        ))}
        <div
          onClick={handleAddPortfolioCompany}
          className={styles['add-button']}>
          <img src={plusIcon} alt="" />
          <h5>Add Portfolio Company</h5>
        </div>
        <div>
          <h1 className={styles.header}>Venture Fund Details</h1>
        </div>
        <div className={styles.form}>
          <Form.Item
            className={styles['field-width']}
            label="Offering Name"
            name="offeringName"
            rules={[
              {
                required: true,
                message: 'Required Field',
              },
            ]}
            initialValue={campaign?.ventureFundFields?.offeringName}>
            <Input
              defaultValue={campaign?.ventureFundFields?.offeringName}
              value={offeringName}
              onChange={e => setOfferingName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            className={styles['field-width']}
            label="Investment Strategy"
            name="investmentStrategy"
            rules={[
              {
                required: true,
                message: 'Required Field',
              },
            ]}
            initialValue={campaign?.ventureFundFields?.investmentStrategy}>
            <Input
              defaultValue={campaign?.ventureFundFields?.investmentStrategy}
              value={investmentStrategy}
              onChange={e => setInvestmentStrategy(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            className={styles['field-width']}
            label="Thesis and Investment Philosophy"
            name="thesisAndInvestmentPhilosophy"
            rules={[
              {
                required: true,
                message: 'Required Field',
              },
            ]}
            initialValue={
              campaign?.ventureFundFields?.thesisAndInvestmentPhilosophy
            }>
            <Input.TextArea
              defaultValue={
                campaign?.ventureFundFields?.thesisAndInvestmentPhilosophy
              }
              value={thesisAndInvestmentPhilosophy}
              rows={6}
              onChange={e => setThesisAndInvestmentPhilosophy(e.target.value)}
            />
          </Form.Item>
        </div>
        <div>
          <h1 className={styles.header}>Comparable Venture Fund Details</h1>
        </div>
        {comparableVentureFund?.map((data, index) => (
          // eslint-disable-next-line react/jsx-key
          <FadeEffect>
            <ComparableVentureFundCard
              id={index}
              handleDelete={() => handleDeleteComparableVentureFund(index)}
              handleChange={(name, value) =>
                handleComparableVentureFundChange(index, name, value)
              }
              campaign={data}
              comparableVentureFund={data}
              companies={getAllCompanies}
            />
          </FadeEffect>
        ))}
        <div
          onClick={handleAddComparableVentureFund}
          className={styles['add-button']}>
          <img src={plusIcon} alt="" />
          <h5>Add Comparable Venture Fund</h5>
        </div>
        <SubmitButton label="Save Changes" />
      </Form>
    </div>
  );
};

export default VentureFundField;
