import {useEffect, useState} from 'react';
import {Form, Table, Spin, Select} from 'antd';
import styles from './campaignUsers.module.scss';
import {Button} from '@dabafinance/react-components';
import {useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {GET_CAMPAIGN_USERS_BY_ID} from 'graphql/queries/campaign';
import gamerIcon from 'images/gamer.png';
import moment from 'moment';
import SubmitButton from 'components/Startup/components/SubmitButton';
import DBModal from 'components/modal/modal';
import {UserSelectField} from 'components/userSelectField/UserSelectField';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';

const UpdateCampaignUsers = () => {
  const [activeModal, setActiveModal] = useState('');
  const [removeUsers, setRemoveUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [totalUpdatedUsers, setTotalUpdatedUsers] = useState([]);
  const {id, language} = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const {data, loading} = useQuery(GET_CAMPAIGN_USERS_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
    onCompleted: data =>
      setTotalUpdatedUsers(
        data?.getCampaignById?.invitedUsers?.map(data => data?.id),
      ),
  });

  const [updateCampaign, {loading: campaignLoading}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    const newUsers = selectedUsers?.map(data => data?.split(';')[2]);
    const allUsers = [...totalUpdatedUsers, ...newUsers];

    // To Ensure we are not adding the same user twice
    // eslint-disable-next-line no-undef
    setTotalUpdatedUsers(() => Array.from(new Set(allUsers)));
  }, [selectedUsers]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <div
            onClick={() => navigate(`/dashboard/user/${record?.id}`)}
            className={styles['record-name']}>
            {record.imageUrl ? (
              <img src={record?.imageUrl} alt="" />
            ) : (
              <img src={gamerIcon} alt="" />
            )}
            <h4 style={{width: 100}}>
              {record?.firstName} {record?.lastName}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (text, record) => (
        <div>
          <h4>{record?.username}</h4>
        </div>
      ),
    },
    {
      title: 'Phone Number',
      dataIndex: 'expiring',
      key: 'expiring',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>{`${record?.countryCode ?? '--'}${
            record?.phoneNumber ?? '--'
          }`}</h4>
        </div>
      ),
    },
    {
      title: 'Profile Completion',
      dataIndex: 'profileCompleted',
      key: 'invested',
      render: (text, record) => (
        <div>
          <span>{record?.profileCompletion?.completionPercentage}%</span>
        </div>
      ),
    },
    {
      title: 'Accredited',
      dataIndex: 'accredited',
      key: 'accredited',
      render: (text, record) => (
        <div>
          <h4>
            {record?.investorProfile?.isAccredited
              ? 'Accredited'
              : 'Unaccredited'}
          </h4>
        </div>
      ),
    },
    {
      title: 'Date Joined',
      dataIndex: 'createdAt',
      key: 'joined',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>{moment(record.createdAt).format('MMM Do YYYY')}</h4>
        </div>
      ),
    },
  ];

  const handleAddUsers = () => {
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId: data?.getCampaignById?.fundraisingCompany?.id,
          language,
          invitedUsers: totalUpdatedUsers,
        },
      },
      refetchQueries: [
        {
          query: GET_CAMPAIGN_USERS_BY_ID,
          variables: {getCampaignByIdId: id, language},
        },
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error(updateCampaign.message);
        } else {
          toast.success('Users Invited Successfully');
          setSelectedUsers([]);
          setActiveModal();
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  const handleRemoveUsers = () => {
    const userIds = removeUsers?.map(data => data?.split(';')[2]);
    const usersToBeUpdated = totalUpdatedUsers.filter(
      element => !userIds.includes(element),
    );

    if (!userIds.length) {
      return toast.error('Please select users to be Deleted');
    }
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId: data?.getCampaignById?.fundraisingCompany?.id,
          invitedUsers: usersToBeUpdated,
          language,
        },
      },
      refetchQueries: [
        {
          query: GET_CAMPAIGN_USERS_BY_ID,
          variables: {getCampaignByIdId: id, language},
        },
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error(updateCampaign.message);
        } else {
          toast.success('Users Deleted Successfully');
          setRemoveUsers([]);
          form.resetFields();
          setActiveModal();
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Campaign Users</h1>
        <div>
          <Button
            onClick={() => {
              setActiveModal('ADD');
            }}
            label="Add Users"
            className={styles.button}
          />
          <Button
            label="Remove Users"
            onClick={() => {
              setActiveModal('REMOVE');
            }}
            type="secondary"
            className={styles.button}
          />
        </div>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={data?.getCampaignById?.invitedUsers}
        />
      </div>
      <div>
        <DBModal
          isOpen={!!activeModal}
          handleClose={() => setActiveModal()}
          content={
            <div className={styles['modal-content']}>
              <h2>
                {activeModal === 'ADD' ? 'ADD USER(S)' : 'REMOVE USER(S)'}
              </h2>
              <Form form={form} layout="vertical">
                <Form.Item label="Select Users" name="users">
                  {activeModal === 'ADD' ? (
                    <UserSelectField
                      users={selectedUsers}
                      setUsers={setSelectedUsers}
                    />
                  ) : (
                    <Select
                      mode="multiple"
                      showSearch
                      value={removeUsers}
                      onChange={e => setRemoveUsers(e)}>
                      {data?.getCampaignById?.invitedUsers?.map(data => (
                        <Select.Option
                          key={`${data?.firstName};${data?.lastName};${data?.id}`}>
                          <div className={styles.image}>
                            <img src={data?.imageUrl} alt="" />
                            <span>
                              {data?.firstName} {data?.lastName}
                            </span>
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <SubmitButton
                  disabled={campaignLoading}
                  handleClick={
                    activeModal === 'ADD' ? handleAddUsers : handleRemoveUsers
                  }
                  label={activeModal === 'ADD' ? 'Add Users' : 'Remove Users'}
                />
              </Form>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default UpdateCampaignUsers;
