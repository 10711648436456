import {Route, Routes} from 'react-router-dom';
import UpdateCampaignHeader from '../base/UpdateCampaignHeader';
import UpdateAnalogs from './screens/analog/UpdateAnalog';
import UpdateCampaignAnalysis from './screens/analysis/UpdateCampaignAnalysis';
import UpdateCampaignCompanyDetails from './screens/campaignCompanyDetails/UpdateCampaignCompanyDetails';
import UpdateCampaignFAQ from './screens/campaignFAQ/UpdateCampaignFAQ';
import UpdateCampaignFounders from './screens/campaignFounders/UpdateCampaignFounders';
import UpdateDabaScore from './screens/dabaScore/UpdateDabaScore';
import UpdateExternalInvestment from './screens/externalInvestment/UpdateExternalInvestment';
import UpdateFundAllocation from './screens/fundAllocation/UpdateFundAllocation';
import UpdateInvestmentDetails from './screens/investmentDetail/UpdateInvestmentDetails';
import UpdateCampaignInvestors from './screens/Investors/UpdateCampaignInvestors';
import UpdateLegalDocument from './screens/legalDocument/UpdateLegalDocument';
import UpdateListingIPO from './screens/ListingIpo/UpdateListingIPO';
import UpdateCampaignStatistics from './screens/news/UpdateCampaignStatistics';
import UpdateCampaignOverview from './screens/overview/UpdateCampaignOverview';
import UpdatePitchDeck from './screens/pitchDeck/UpdatePitchDeck';
import UpdateCampaignRisks from './screens/risks/UpdateCampaignRisks';
import UpdateCampaignSettings from './screens/settings/UpdateCampaignSettings';
import UpdateCampaignUsers from './screens/usersInvite/UpdateCampaignUsers';
import UpdateUsersInvite from './screens/usersInvite/UpdateUsersInvite';
import UpdateInvestmentContract from './screens/investmentContract/UpdateInvestmentContract';
import UpdateCurrencySettings from './screens/currencySettings/UpdateCurrencySettings';
import UpdateVentureFund from './screens/ventureFund/VentureFund';

export const UpdateLiveCampaignRoutes = () => {
  return (
    <Routes>
      <Route path="/:language/:id" element={<UpdateCampaignHeader />}>
        <Route path="/:language/:id" element={<UpdateCampaignOverview />} />
        <Route path="/:language/:id/pitch" element={<UpdatePitchDeck />} />
        <Route
          path="/:language/:id/analysis"
          element={<UpdateCampaignAnalysis />}
        />
        <Route
          path="/:language/:id/co-investors"
          element={<UpdateCampaignInvestors />}
        />
        <Route path="/:language/:id/daba-score" element={<UpdateDabaScore />} />
        <Route
          path="/:language/:id/allocation"
          element={<UpdateFundAllocation />}
        />
        <Route
          path="/:language/:id/investment"
          element={<UpdateInvestmentDetails />}
        />
        <Route
          path="/:language/:id/documents"
          element={<UpdateLegalDocument />}
        />
        <Route
          path="/:language/:id/founders"
          element={<UpdateCampaignFounders />}
        />
        <Route path="/:language/:id/faq" element={<UpdateCampaignFAQ />} />
        <Route path="/:language/:id/analogs" element={<UpdateAnalogs />} />

        <Route path="/:language/:id/ipo" element={<UpdateListingIPO />} />
        <Route
          path="/:language/:id/venture-fund"
          element={<UpdateVentureFund />}
        />
        <Route
          path="/:language/:id/external-investment"
          element={<UpdateExternalInvestment />}
        />
        <Route
          path="/:language/:id/investment-contract"
          element={<UpdateInvestmentContract />}
        />
        <Route
          path="/:language/:id/currency-settings"
          element={<UpdateCurrencySettings />}
        />
        <Route
          path="/:language/:id/company-details"
          element={<UpdateCampaignCompanyDetails />}
        />
        <Route path="/:language/:id/risks" element={<UpdateCampaignRisks />} />
        <Route
          path="/:language/:id/statistics"
          element={<UpdateCampaignStatistics />}
        />
        <Route
          path="/:language/:id/settings"
          element={<UpdateCampaignSettings />}
        />
        <Route path="/:language/:id/users" element={<UpdateCampaignUsers />} />
        <Route
          path="/:language/:id/users-invite"
          element={<UpdateUsersInvite />}
        />
      </Route>
    </Routes>
  );
};

export const UpdateTestCampaignRoutes = () => {
  return (
    <Routes>
      <Route path="/:language/:id" element={<UpdateCampaignHeader />}>
        <Route path="/:language/:id" element={<UpdateCampaignOverview />} />
        <Route path="/:language/:id/pitch" element={<UpdatePitchDeck />} />
        <Route
          path="/:language/:id/analysis"
          element={<UpdateCampaignAnalysis />}
        />
        <Route path="/:language/:id/daba-score" element={<UpdateDabaScore />} />
        <Route
          path="/:language/:id/investment"
          element={<UpdateInvestmentDetails />}
        />
        <Route path="/:language/:id/analogs" element={<UpdateAnalogs />} />
        <Route
          path="/:language/:id/founders"
          element={<UpdateCampaignFounders />}
        />
        <Route path="/:language/:id/ipo" element={<UpdateListingIPO />} />
        <Route
          path="/:language/:id/venture-fund"
          element={<UpdateVentureFund />}
        />
        <Route
          path="/:language/:id/company-details"
          element={<UpdateCampaignCompanyDetails />}
        />
        <Route
          path="/:language/:id/settings"
          element={<UpdateCampaignSettings />}
        />
        <Route
          path="/:language/:id/users-invite"
          element={<UpdateUsersInvite />}
        />
      </Route>
    </Routes>
  );
};
