import styles from './stockListing.module.scss';
import {useParams, useNavigate, Outlet} from 'react-router-dom';
import {ArrowLeft} from 'icons';
import CustomLink from 'components/customLink/customLink';

export default function StockListingUpdate() {
  const {id} = useParams();
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button onClick={() => navigate('/dashboard/stocks/listings')}>
          <ArrowLeft />
        </button>
        <h1>Update Stock Exchange</h1>
      </div>
      <section className={styles.section}>
        <nav className={styles['tab-toggler']}>
          <CustomLink
            to={`/dashboard/stocks/update-stock/${id}`}
            name="General Information"
          />
          <CustomLink
            to={`/dashboard/stocks/update-stock/${id}/stock-closure`}
            name="Stock Market Closure"
          />
        </nav>
      </section>
      <section>
        <Outlet />
      </section>
    </div>
  );
}
