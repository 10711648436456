import _ from 'lodash';
import {useQuery} from '@apollo/client';
import {
  GET_ALL_WHATSAPP_TEMPLATES,
  GET_WHATSAPP_TEMPLATE_BY_NAME,
} from 'graphql/queries/users';
import {getWhatsappDynamicLinkData} from 'hooks/useWhatsapTemplate';
import {useEffect, useState} from 'react';
import {formatWhatsappTemplate} from 'utils/helper';
import {Form, Input, Select, Tabs} from 'antd';
import styles from './whatsappNotificationFields.module.scss';
import {notificationLanguages} from 'utils/constants';
import {client} from 'index';

const {Option} = Select;
const {TabPane} = Tabs;

const getTemplateByName = async (name, language) => {
  const {data: getWhatsappTemplateByName} = await client.query({
    query: GET_WHATSAPP_TEMPLATE_BY_NAME,
    variables: {
      name,
      language,
    },
  });
  return getWhatsappTemplateByName;
};

export const WhatsappNotificationFields = ({
  inputClassName,
  previewClassName,
  hiddenFieldClassName,
  selectedWhatsappTemplate = {},
  onSelectedWhatsappTemplateChange,
  form,
  languageToBeTranslatedTo,
  baseLanguage,
}) => {
  const [selectedDynamicTemplateType, setSelectedDynamicTemplateType] =
    useState('');
  const [dynamicWhatsappTemplateOptions, setDynamicWhatsappTemplateOptions] =
    useState([]);
  const [translatedLanguage, setTranslatedLanguage] = useState('');
  const [translatedSelectedTemplate, setTranslatedSelectedTemplate] = useState(
    [],
  );
  const {data: getWhatsappTemplate} = useQuery(GET_ALL_WHATSAPP_TEMPLATES, {
    variables: {
      language: baseLanguage,
    },
  });

  useEffect(() => {
    onSelectedWhatsappTemplateChange({});
    setTranslatedLanguage();
    setDynamicWhatsappTemplateOptions([]);
  }, [baseLanguage]);

  // Fetch the translated version of seleted template, once a translated language is selected or changed
  useEffect(() => {
    if (!translatedLanguage || !selectedWhatsappTemplate) {
      return;
    }

    getTemplateByName(
      selectedWhatsappTemplate?.templateName,
      translatedLanguage,
    ).then(data => {
      setTranslatedSelectedTemplate(
        data?.getWhatsappTemplateByName?.whatsappTemplate,
      );
    });
  }, [translatedLanguage]);

  useEffect(() => {
    const fetchData = async () => {
      setDynamicWhatsappTemplateOptions([]);
      setSelectedDynamicTemplateType([]);

      if (!selectedWhatsappTemplate?.templateLink?.isStatic) {
        const response = await getWhatsappDynamicLinkData(
          selectedWhatsappTemplate?.templateLink?.type,
        );

        setDynamicWhatsappTemplateOptions(response);
        setSelectedDynamicTemplateType(
          selectedWhatsappTemplate?.templateLink?.type,
        );
      }
    };

    fetchData();
    setTranslatedLanguage();
    form?.setFieldValue('translatedLanguage', undefined);
  }, [selectedWhatsappTemplate]);

  const handleOpportunityChange = e => {
    const opportunity = dynamicWhatsappTemplateOptions?.find(
      data => data.id === e,
    );

    // Set extra fields for opportunity
    form?.setFieldValue('campaignMode', opportunity?.campaignMode);
    form?.setFieldValue('listingType', opportunity?.listingType);
  };

  const RenderTranslatedVariables = ({translatedLang, whatsappTemplate}) => {
    return (
      <>
        {whatsappTemplate?.templateVariables?.map((variable, index) => {
          if (
            whatsappTemplate?.hasNameVariable &&
            (index === 0 || variable?.isAppLink)
          )
            return;
          return (
            <Form.Item
              key={variable.description}
              rules={[
                {
                  required: true,
                  message: 'This is required',
                },
              ]}
              label={_.startCase(variable.description)}
              className={inputClassName}
              name={
                translatedLang
                  ? ['whatsappMultiLang', variable.name]
                  : variable.name
              }>
              <Input
                placeholder={_.startCase(variable.description)}
                key={variable.description}
              />
            </Form.Item>
          );
        })}

        {selectedDynamicTemplateType === 'SINGLE_OPPORTUNITY' && (
          <>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'This is required',
                },
              ]}
              label="Select Opportunity"
              className={inputClassName}
              name={
                translatedLang
                  ? ['whatsappMultiLang', 'template-link-id']
                  : 'template-link-id'
              }>
              <Select
                placeholder="Select an opportunity"
                onChange={handleOpportunityChange}>
                {dynamicWhatsappTemplateOptions?.map(
                  ({id, fundraisingCompany}) => (
                    <Option value={id} key={id}>
                      <div className={styles.whatsappNotification}>
                        <img src={fundraisingCompany?.logoImgURL} alt="" />
                        <span>{fundraisingCompany?.companyName}</span>
                      </div>
                    </Option>
                  ),
                )}
              </Select>
            </Form.Item>

            <Form.Item
              name={
                translatedLang
                  ? ['whatsappMultiLang', 'campaignMode']
                  : 'campaignMode'
              }
              className={hiddenFieldClassName}>
              <Input />
            </Form.Item>

            <Form.Item
              name={
                translatedLang
                  ? ['whatsappMultiLang', 'listingType']
                  : 'listingType'
              }
              className={hiddenFieldClassName}>
              <Input />
            </Form.Item>
          </>
        )}

        {selectedDynamicTemplateType === 'SINGLE_COMPANY' && (
          <Form.Item
            rules={[
              {
                required: true,
                message: 'This is required',
              },
            ]}
            label="Select Company"
            className={inputClassName}
            name={
              translatedLang
                ? ['whatsappMultiLang', 'template-link-id']
                : 'template-link-id'
            }>
            <Select placeholder="Select a company">
              {dynamicWhatsappTemplateOptions?.map(data => (
                <Option value={data.id} key={data?.name}>
                  <div className={styles.whatsappNotification}>
                    <img src={data?.logoImgURL} alt="" />
                    <span>{data?.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {selectedDynamicTemplateType === 'SINGLE_REPORT' && (
          <Form.Item
            rules={[
              {
                required: true,
                message: 'This is required',
              },
            ]}
            label="Select Report"
            className={inputClassName}
            name={
              translatedLang
                ? ['whatsappMultiLang', 'template-link-id']
                : 'template-link-id'
            }>
            <Select placeholder="Select a report">
              {dynamicWhatsappTemplateOptions?.map(data => (
                <Option value={data.id} key={data?.id}>
                  <span>{data?.title}</span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {selectedDynamicTemplateType === 'SINGLE_CIRCLE' && (
          <Form.Item
            rules={[
              {
                required: true,
                message: 'This is required',
              },
            ]}
            label="Select Circle"
            className={inputClassName}
            name={
              translatedLang
                ? ['whatsappMultiLang', 'template-link-id']
                : 'template-link-id'
            }>
            <Select placeholder="Select a circle">
              {dynamicWhatsappTemplateOptions?.map(data => (
                <Option value={data.id} key={data?.id}>
                  <span>{data?.name}</span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {whatsappTemplate?.templateContent && (
          <div className={styles.preview}>
            <label>Template preview</label>
            <br />
            <div
              className={previewClassName}
              dangerouslySetInnerHTML={{
                __html: formatWhatsappTemplate(
                  whatsappTemplate?.templateContent,
                  whatsappTemplate?.templateVariables,
                ),
              }}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Template is required',
          },
        ]}
        label="Whatsapp Template"
        className={inputClassName}
        name="template">
        <Select
          value={selectedWhatsappTemplate?.id}
          placeholder="Select a template"
          onChange={e =>
            onSelectedWhatsappTemplateChange(
              getWhatsappTemplate?.getWhatsappTemplate?.whatsappTemplates?.find(
                template => template.id === e,
              ),
            )
          }>
          {getWhatsappTemplate?.getWhatsappTemplate?.whatsappTemplates?.map(
            template => (
              <Option value={template.id} key={template.id}>
                {_.startCase(template.templateName?.split('_').join(' '))}
              </Option>
            ),
          )}
        </Select>
      </Form.Item>

      {selectedWhatsappTemplate?.templateTitle?.hasVariable && (
        <>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'This is required',
              },
            ]}
            label="Title template variable"
            className={inputClassName}
            name="title-template-variable">
            <Input placeholder="Title template variable" />
          </Form.Item>

          <div className={styles.titlePreview}>
            <label>
              Title preview:{' '}
              <span
                className={previewClassName}
                dangerouslySetInnerHTML={{
                  __html: formatWhatsappTemplate(
                    selectedWhatsappTemplate?.templateTitle?.title,
                    [{name: '1', description: 'template-variable'}],
                  ),
                }}
              />
            </label>
            <br />
          </div>
        </>
      )}
      <RenderTranslatedVariables whatsappTemplate={selectedWhatsappTemplate} />

      <Form.Item
        label="Translation Language"
        className={inputClassName}
        name="whatsappTranslatedLanguage">
        <Select
          placeholder="Select a Language"
          value={translatedLanguage}
          onChange={setTranslatedLanguage}
          disabled={!baseLanguage || !selectedDynamicTemplateType}>
          {languageToBeTranslatedTo.map(({label, key}) => (
            <Option key={key} value={key}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {translatedLanguage && (
        <Tabs className={styles.tabs}>
          <TabPane
            tab={
              notificationLanguages.find(
                lang => lang.key === translatedLanguage,
              ).label
            }>
            <RenderTranslatedVariables
              translatedLang={translatedLanguage}
              whatsappTemplate={translatedSelectedTemplate}
            />
          </TabPane>
        </Tabs>
      )}
    </>
  );
};
