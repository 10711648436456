import {Route, Routes} from 'react-router-dom';
import CreateCampaignHeader from '../base/CreateCampaignHeader';
import CampaignCompanyDetails from './screens/campaignCompanyDetails/CampaignCompanyDetails';
import CampaignFAQ from './screens/campaignFAQ/CampaignFAQ';
import CampaignFounders from './screens/campaignFounders/CampaignFounders';
import CompanySelect from './screens/companySelect/CompanySelect';
import DabaScore from './screens/dabaScore/DabaScore';
import FundAllocation from './screens/fundAllocation/FundAllocation';
import InvestmentDetails from './screens/investmentDetail/InvestmentDetails';
import CampaignInvestors from './screens/Investors/CampaignInvestors';
import LegalDocument from './screens/legalDocument/LegalDocument';
import CampaignStatistics from './screens/news/CampaignNews';
import CampaignOverview from './screens/overview/CampaignOverview';
import PitchDeck from './screens/pitchDeck/PitchDeck';
import CampaignProfile from './screens/profile/CampaignProfile';
import CampaignRisks from './screens/risks/CampaignRisks';
import CampaignSettings from './screens/settings/CampaignSettings';
import UsersInvite from './screens/usersInvite/UsersInvite';
import {PersistGate} from 'redux-persist/integration/react';
import {persistStore} from 'redux-persist';
import store from 'redux/store';
import CampaignAnalysis from './screens/analysis/CampaignAnalysis';
import ListingIPO from './screens/ipo/ListingIPO';
import ListingType from './screens/listingType/listingType';
import CampaignAnalog from './screens/analog/CampaignAnalog';
import InvestmentContract from './screens/investmentContract/InvestmentContract';
import CurrencySettings from './screens/currencySettings/CurrencySettings';
import VentureFundField from './screens/ventureFund/ventureFund';

const persistor = persistStore(store);

export const CampaignLiveRoutes = () => {
  return (
    <PersistGate loading={null} persistor={persistor}>
      <Routes>
        <Route path="/select-company" element={<CompanySelect />} />
        <Route path="/profile/:id" element={<CampaignProfile />} />
        <Route path="/:id" element={<CreateCampaignHeader />}>
          <Route path="/:id/type/:companyId" element={<ListingType />} />
          <Route path="/:id/:companyId" element={<CampaignOverview />} />
          <Route path="/:id/pitch/:companyId" element={<PitchDeck />} />
          <Route
            path="/:id/analysis/:companyId"
            element={<CampaignAnalysis />}
          />
          <Route
            path="/:id/co-investors/:companyId"
            element={<CampaignInvestors />}
          />
          <Route path="/:id/daba-score/:companyId" element={<DabaScore />} />
          <Route
            path="/:id/allocation/:companyId"
            element={<FundAllocation />}
          />
          <Route
            path="/:id/investment/:companyId"
            element={<InvestmentDetails />}
          />
          <Route path="/:id/documents/:companyId" element={<LegalDocument />} />
          <Route
            path="/:id/founders/:companyId"
            element={<CampaignFounders />}
          />
          <Route path="/:id/faq/:companyId" element={<CampaignFAQ />} />
          <Route path="/:id/analogs/:companyId" element={<CampaignAnalog />} />
          <Route
            path="/:id/company-details/:companyId"
            element={<CampaignCompanyDetails />}
          />
          <Route path="/:id/risks/:companyId" element={<CampaignRisks />} />
          <Route
            path="/:id/statistics/:companyId"
            element={<CampaignStatistics />}
          />
          <Route
            path="/:id/investment-contract/:companyId"
            element={<InvestmentContract />}
          />
          <Route
            path="/:id/currency-settings/:companyId"
            element={<CurrencySettings />}
          />
          <Route
            path="/:id/settings/:companyId"
            element={<CampaignSettings />}
          />
          <Route
            path="/:id/users-invite/:companyId"
            element={<UsersInvite />}
          />
          <Route path="/:id/ipo/:companyId" element={<ListingIPO />} />
          <Route
            path="/:id/venture-fund/:companyId"
            element={<VentureFundField />}
          />
        </Route>
      </Routes>
    </PersistGate>
  );
};

export const CampaignTestRoutes = () => {
  return (
    <PersistGate loading={null} persistor={persistor}>
      <Routes>
        <Route path="/select-company" element={<CompanySelect />} />
        <Route path="/profile/:id" element={<CampaignProfile />} />
        <Route path="/:id" element={<CreateCampaignHeader />}>
          <Route path="/:id/type/:companyId" element={<ListingType />} />
          <Route path="/:id/:companyId" element={<CampaignOverview />} />
          <Route path="/:id/pitch/:companyId" element={<PitchDeck />} />
          <Route
            path="/:id/analysis/:companyId"
            element={<CampaignAnalysis />}
          />
          <Route path="/:id/daba-score/:companyId" element={<DabaScore />} />
          <Route
            path="/:id/investment/:companyId"
            element={<InvestmentDetails />}
          />
          <Route
            path="/:id/founders/:companyId"
            element={<CampaignFounders />}
          />
          <Route path="/:id/analogs/:companyId" element={<CampaignAnalog />} />
          <Route
            path="/:id/company-details/:companyId"
            element={<CampaignCompanyDetails />}
          />
          <Route
            path="/:id/settings/:companyId"
            element={<CampaignSettings />}
          />
          <Route
            path="/:id/users-invite/:companyId"
            element={<UsersInvite />}
          />
          <Route path="/:id/ipo/:companyId" element={<ListingIPO />} />
          <Route
            path="/:id/venture-fund/:companyId"
            element={<VentureFundField />}
          />
        </Route>
      </Routes>
    </PersistGate>
  );
};
