import {useNavigate, useParams} from 'react-router-dom';
import {BaseDabaScore} from '../BaseFinancialInstruments/dabaScore/BaseDabaScore';
import {useDispatch, useSelector} from 'react-redux';
import {handleBondsInstrumentState} from 'redux/store/assetClass';
import {createBondsInstrumentRoute} from './routes';
import {useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {UPDATE_BOND} from 'graphql/mutations/assetManagement';
import {GET_FINANCIAL_INSTRUMENT_BY_LANGUAGE} from 'graphql/queries/assetManagement';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {handleUpdateBond} from '../actions';
import {Spin} from 'antd';

export const BondDabaScore = () => {
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});
  const navigate = useNavigate();
  const {assetId, lang, id} = useParams();
  const dispatch = useDispatch();
  const data = useSelector(state => state?.assetClass?.bondInstruments);

  const [updateBond, {loading: loadingUpdate}] = useMutation(UPDATE_BOND);
  const {
    data: updateData,
    refetch,
    loading,
  } = useQuery(GET_FINANCIAL_INSTRUMENT_BY_LANGUAGE, {
    variables: {
      getFinancialInstrumentId: id,
      language: lang,
    },
    skip: !id,
  });

  const handleSubmit = values => {
    if (id) {
      // eslint-disable-next-line no-unused-vars
      const {reportName, ...restValues} = values?.dabaScore;
      setOpenUpdateModal(true);
      setUpdatedValues({dabaScore: restValues});
    } else {
      dispatch(handleBondsInstrumentState({...values}));
      navigate(createBondsInstrumentRoute({param: assetId, name: 'pitch'}));
    }
  };

  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div>
      <BaseDabaScore
        onSubmit={handleSubmit}
        existingData={
          id ? updateData?.getFinancialInstrument?.data || {} : data
        }
      />
      <ConfirmModal
        openModal={openUpdateModal}
        setOpenModal={() => setOpenUpdateModal(false)}
        loading={loadingUpdate}
        title="Update Bond"
        confirmText="Are you sure you want to update this bond?"
        handleOperation={() =>
          handleUpdateBond(
            updateBond,
            id,
            lang,
            updatedValues,
            refetch,
            setOpenUpdateModal,
          )
        }
      />
    </div>
  );
};
