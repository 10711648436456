import {Outlet, useNavigate, useParams} from 'react-router-dom';
import styles from './collection.module.scss';
import CustomLink from 'components/customLink/customLink';
import PercentageCard from '../collectionCards/PercentageCard';
import backIcon from 'images/back.png';
import {GET_COLLECTION_STATS} from 'graphql/queries/collections';
import {toast} from 'react-toastify';
import {useQuery} from '@apollo/client';

const SingleCollection = () => {
  const navigate = useNavigate();
  const {id} = useParams();

  const {data} = useQuery(GET_COLLECTION_STATS, {
    variables: {
      collectionId: id,
    },
    errorPolicy: 'all',
    onError: error => toast.error(error?.message),
  });

  return (
    <div className={styles['investor-container']}>
      <div className={styles.header}>
        <div
          className={styles.headerTitle}
          onClick={() => navigate('/dashboard/collections')}>
          <img src={backIcon} alt="Go back" />
          <h1>{data?.adminGetCollectionStats?.data?.name}</h1>
        </div>
      </div>
      <section className={styles['percentage-tab']}>
        <div className={styles['percentage-card']}>
          <PercentageCard
            amount={data?.adminGetCollectionStats?.data?.totalCompanies || 0}
            label="Number of companies"
            postive
          />
        </div>
        <div className={styles['percentage-card']}>
          <PercentageCard
            amount={data?.adminGetCollectionStats?.data?.totalOrders || 0}
            label="Number of user"
            postive
          />
        </div>
        <div className={styles['percentage-card']}>
          <PercentageCard
            amount={data?.adminGetCollectionStats?.data?.totalInvested || 0}
            label="Amount Invested"
            postive
          />
        </div>
      </section>
      <section className={styles.section}>
        <nav className={styles['investor-toggler']}>
          <CustomLink
            to={`/dashboard/collections/${id}/companies`}
            name="Collection Companies"
          />
          <CustomLink
            to={`/dashboard/collections/${id}/users`}
            name="Invested Users"
          />
        </nav>
      </section>
      <section>
        <Outlet />
      </section>
    </div>
  );
};

export {SingleCollection};
