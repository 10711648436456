import {DatePicker, Form, Input, InputNumber, Select, Tabs} from 'antd';
import backIcon from 'images/back.png';
import styles from './createStock.module.scss';
import {useNavigate} from 'react-router-dom';
import {GET_ALL_COUNTRIES} from 'graphql/queries/users';
import {useMutation, useQuery} from '@apollo/client';
import UploadImage from 'components/Startup/profile/companyImage';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useState} from 'react';
import {CREATE_STOCK} from 'graphql/mutations/stocks';
import moment from 'moment';
import {toast} from 'react-toastify';
import {GET_ALL_STOCKS} from 'graphql/queries/stocks';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {defaultLanguages} from 'pages/news/newsTypes';
import {fieldRules} from 'utils/helper';
import {requestStocksTranslation} from 'redux/store/news/actions';
import {Button} from '@dabafinance/react-components';
import OrderSettings from 'pages/adminOpportunities/OrderSettings/OrderSettings';

const CreateStock = () => {
  const [stockState, setStockState] = useState({
    ceo: '',
    baseCurrency: '',
    website: '',
    countries: [],
    description: '',
    marketCap: '',
    code: '',
    exchangeName: '',
    headQuarters: '',
    numberOfListings: 0,
    foundedDate: '',
    defaultClosureMessage: '',
    defaultClosureTitle: '',
  });
  const [stockLogo, setStockLogo] = useState('');
  const [previewStockLogo, setPreviewStockLogo] = useState('');
  const [baseLanguage, setBaseLanguage] = useState('');
  const [translatedData, setTranslatedData] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [addStockExchange, {loading}] = useMutation(CREATE_STOCK);
  const {Option} = Select;
  const navigate = useNavigate();
  const [fees, setFeeValues] = useState([]);

  const {data: getAllCountries, loading: loadingCountries} =
    useQuery(GET_ALL_COUNTRIES);

  const handleFieldChange = payload => {
    setStockState(state => ({...state, ...payload}));
  };

  const {
    ceo,
    baseCurrency,
    website,
    countries,
    description,
    marketCap,
    code,
    exchangeName,
    headQuarters,
    numberOfListings,
    foundedDate,
    defaultClosureMessage,
    defaultClosureTitle,
  } = stockState;

  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );

  const handleChangeTranslation = (key, event) => {
    let eventName = event.target.name;
    let eventValue = event.target.value;
    const newFormValues = [...translatedData];
    newFormValues[key][eventName] = eventValue;
    setTranslatedData(newFormValues);
  };

  const languageToBeTranslatedTo = defaultLanguages.filter(
    key => key.key !== baseLanguage,
  );

  const handleTranslation = async () => {
    const newValues = [...translatedData];

    if (!baseLanguage && !description) {
      return toast.error(
        'Please make sure none of the necessary fields needed for translation are empty',
      );
    }

    const translateProps = {
      sourceLang: baseLanguage,
      targetLang: selectedLanguage,
      targetText1: description,
      targetText2: defaultClosureMessage,
      targetText3: defaultClosureTitle,
    };
    if (newValues.length && newValues[0]) {
      const languageExists = newValues.some(
        data => data?.language === selectedLanguage,
      );
      if (languageExists) {
        const updateTranslation = await requestStocksTranslation(
          translateText,
          translateProps,
        );
        const translationIndex = newValues.findIndex(
          data => data.language === selectedLanguage,
        );
        newValues[translationIndex].description = updateTranslation.description;
        newValues[translationIndex].defaultClosureMessage =
          updateTranslation.defaultClosureMessage;
        newValues[translationIndex].defaultClosureTitle =
          updateTranslation.defaultClosureTitle;
        newValues[translationIndex].language = updateTranslation.language;
        setTranslatedData(newValues);
      }
    } else {
      const triggerNewTranslation = await requestStocksTranslation(
        translateText,
        translateProps,
      );
      if (!triggerNewTranslation) {
        return toast.error('Something went wrong when Translating');
      }
      newValues.push(triggerNewTranslation);
      setTranslatedData(newValues);
    }
  };

  const handleSubmit = values => {
    addStockExchange({
      variables: {
        addStockExchangeInput: {
          logoImage: stockLogo,
          language: baseLanguage,
          CEO: ceo,
          website,
          description,
          defaultClosureMessage,
          defaultClosureTitle,
          baseCurrencyCode: baseCurrency,
          headquarters: headQuarters,
          orderExpirationLimit: Number(values?.orderExpirationLimit),
          code,
          multiLang: translatedData,
          tradeable: values.tradable != null ? values.tradable : false,
          fractionalShares:
            values.fractionalShares != null ? values.fractionalShares : false,
          numberOfListings: parseInt(numberOfListings),
          totalMarketCapitalization: marketCap.toString(),
          stockExchangeName: exchangeName,
          foundedDate: new Date(foundedDate).toISOString(),
          countriesOfOperation: countries?.map(data => data?.split(';')[1]),
          feeSchedule: fees.length
            ? fees.map(data => ({
                feeName: data?.feeName,
                feeType: data?.feeType,
                fixedFeeAmount: Number(data?.fixedFeeAmount),
                variableFeeAmount: Number(data?.variableFeeAmount),
              }))
            : [],
        },
      },
      refetchQueries: [{query: GET_ALL_STOCKS}],
    })
      .then(({data: {addStockExchange}}) => {
        if (addStockExchange.__typename === 'Error') {
          toast.error(`Something went wrong!!! - ${addStockExchange.message}`);
        } else {
          toast.success('Stock Exchange Created Successfully');
          navigate('/dashboard/stocks/listings');
        }
      })
      .catch(err => toast.error(`Something went wrong!! ${err}`));
  };

  return (
    <div>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>Create Stock Exchange</h1>
      </div>
      <div className={styles.formSection}>
        <div className={styles.uploadImage}>
          <UploadImage
            label="Upload Logo"
            previewUrl={previewStockLogo}
            setImgUrl={setStockLogo}
            setPreviewUrl={setPreviewStockLogo}
            bucket={'campaign-bucket-images'}
          />
        </div>
        <Form onFinish={handleSubmit} className={styles.form} layout="vertical">
          <Form.Item
            label="Base Language"
            className={styles.bigWidth}
            name="baseLanguage"
            rules={[fieldRules('Language')]}>
            <Select
              value={baseLanguage}
              onChange={e => setBaseLanguage(e)}
              placeholder="Select a base language">
              {defaultLanguages.map(data => (
                <Option key={data.key}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.bigWidth}
            label="Countries of Operations"
            rules={[
              {required: true, message: 'Countries of Operation is Required'},
            ]}
            name="countries">
            <Select
              value={countries}
              onChange={e => handleFieldChange({countries: e})}
              loading={loadingCountries}
              mode="multiple"
              showSearch>
              {getAllCountries?.getAllCountries.map(data => (
                <Option key={`${data?.name};${data?.id}`}>
                  <div className="select-option-style">
                    <img src={data?.icon} alt="" />
                    <span>{data?.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Translation Language"
            name="translationLanguage"
            className={styles.bigWidth}
            rules={[fieldRules('You need to select a language to Translate')]}>
            <Select
              value={selectedLanguage}
              onChange={e => setSelectedLanguage(e)}
              placeholder="Select a Language to be translated">
              {languageToBeTranslatedTo.map(name => (
                <Option key={name.key}>{name.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Tabs
            style={{width: 800}}
            tabBarExtraContent={
              selectedLanguage && (
                <Button
                  label="Translate"
                  disabled={loadingTranslation || !description}
                  onClick={handleTranslation}
                />
              )
            }>
            <Tabs.TabPane key="Original" tab="Original">
              <Form.Item label="Description" name="description">
                <Input.TextArea
                  value={description}
                  className={styles.bigWidth}
                  onChange={e =>
                    handleFieldChange({description: e.target.value})
                  }
                  rows={5}
                />
              </Form.Item>
              <Form.Item
                className={styles.bigWidth}
                label="Default Closure Title"
                name="defaultClosureTitle">
                <Input
                  value={defaultClosureTitle}
                  onChange={e =>
                    handleFieldChange({defaultClosureTitle: e.target.value})
                  }
                />
              </Form.Item>
              <Form.Item
                className={styles.bigWidth}
                label="Default Closure Message"
                name="defaultClosureMessage">
                <Input.TextArea
                  value={defaultClosureMessage}
                  className={styles.bigWidth}
                  onChange={e =>
                    handleFieldChange({defaultClosureMessage: e.target.value})
                  }
                  rows={5}
                />
              </Form.Item>
            </Tabs.TabPane>
            {translatedData?.map((item, index) => (
              <Tabs.TabPane
                key={index}
                tab={
                  languageToBeTranslatedTo.find(
                    lang => lang?.key === item?.language,
                  )?.label
                }>
                <Form.Item
                  className={styles.bigWidth}
                  label="Translated Description">
                  <Input.TextArea
                    value={item?.description}
                    onChange={e => handleChangeTranslation(index, e)}
                    rows={5}
                  />
                </Form.Item>
                <Form.Item
                  className={styles.bigWidth}
                  label="Default Closure Title">
                  <Input
                    value={item?.defaultClosureTitle}
                    onChange={e => handleChangeTranslation(index, e)}
                  />
                </Form.Item>
                <Form.Item
                  className={styles.bigWidth}
                  label="Default Closure Message">
                  <Input.TextArea
                    value={item?.defaultClosureMessage}
                    onChange={e => handleChangeTranslation(index, e)}
                    rows={5}
                  />
                </Form.Item>
              </Tabs.TabPane>
            ))}
          </Tabs>
          <Form.Item label="Total Market Capital" name="marketCap">
            <InputNumber
              value={marketCap}
              onChange={e => handleFieldChange({marketCap: e})}
              placeholder="Enter the total Market Capital"
              className={styles.inputNumber}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            className={styles.inputWidth}
            label="Order Expiration Limit (Time in Days it takes an order to expire)"
            name="orderExpirationLimit">
            <Input type="number" />
          </Form.Item>
          <Form.Item
            className={styles.inputWidth}
            label="Base Currency Code"
            name="baseCurrency"
            rules={[{required: true, message: 'Base Currency Code Required'}]}>
            <Select
              value={baseCurrency}
              onChange={e => handleFieldChange({baseCurrency: e})}>
              {currencyCodes.map(({value}) => (
                <Option key={value}>{value}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'Code is Required'}]}
            className={styles.inputWidth}
            label="Code"
            name="code">
            <Input
              value={code}
              onChange={e => handleFieldChange({code: e.target.value})}
            />
          </Form.Item>
          <Form.Item
            className={styles.inputWidth}
            rules={[
              {required: true, message: 'Stock Exchange Name is Required'},
            ]}
            label="Stock Exchange Name"
            name="name">
            <Input
              value={exchangeName}
              onChange={e => handleFieldChange({exchangeName: e.target.value})}
            />
          </Form.Item>
          <Form.Item
            className={styles.inputWidth}
            label="Headquarters"
            rules={[{required: true, message: 'Code is Required'}]}
            name="Headquarters">
            <Input
              value={headQuarters}
              onChange={e => handleFieldChange({headQuarters: e.target.value})}
            />
          </Form.Item>
          <Form.Item className={styles.inputWidth} label="CEO" name="ceo">
            <Input
              value={ceo}
              onChange={e => handleFieldChange({ceo: e.target.value})}
            />
          </Form.Item>
          <Form.Item
            className={styles.inputWidth}
            label="Number of Listings"
            name="listingNumbers">
            <Input
              value={numberOfListings}
              type="number"
              onChange={e =>
                handleFieldChange({numberOfListings: e.target.value})
              }
            />
          </Form.Item>
          <Form.Item
            className={styles.inputWidth}
            label="Website"
            rules={[{type: 'url'}]}
            name="website">
            <Input
              value={website}
              onChange={e => handleFieldChange({website: e.target.value})}
            />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'Code is Required'}]}
            label="Founded Date"
            name="date">
            <DatePicker
              value={foundedDate}
              format={'YYYY-MM-DD'}
              onChange={e =>
                handleFieldChange({
                  foundedDate: moment(e).format('YYYY-MM-DD'),
                })
              }
              className={styles.date}
            />
          </Form.Item>
          <OrderSettings fees={fees} handleChangeFees={setFeeValues} />
          <SubmitButton disabled={loading} label={`Create`} />
        </Form>
      </div>
    </div>
  );
};

export const currencyCodes = [
  {value: 'EUR', symbol: '€'},
  {value: 'XOF', symbol: 'CFA'},
  {value: 'XAF', symbol: 'FCFA'},
  {value: 'CAD', symbol: 'C$'},
  {value: 'NGN', symbol: '₦'},
  {value: 'USD', symbol: '$'},
];

export default CreateStock;
