import {Button} from '@dabafinance/react-components';
import {Spin} from 'antd';
import {useState} from 'react';
import styles from './campaign.module.scss';

import gridIcon from 'images/view-grid-outline.svg';
import listIcon from 'images/format-list-bulleted.svg';
import purpleGridIcon from 'images/grid-purple.svg';
import purpleListIcon from 'images/list-purple.svg';
import CampaignTable from './table/CampaignTable';
import {useNavigate} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {GET_ALL_FILTERED_CAMPAIGNS} from 'graphql/queries/campaign';
import {DraftCampaignCard} from './card/CampaignCards';
import {CAMPAIGN_TYPES, NavTabsEnum} from 'utils/constants';
import RenderCampaignCard from './RenderCampaignCard';
import CustomDropdown from 'components/customDropdown/CustomDropdown';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';

const Campaigns = () => {
  const [activeTab, setActiveTab] = useState('DRAFT');
  const [gridIconSelect, setGridIconSelect] = useState(purpleGridIcon);
  const [listIconSelect, setListIconSelect] = useState(listIcon);
  const [isVisible, setIsVisible] = useState(true);
  const [swtichView, setSwitchView] = useState(true);
  const navigate = useNavigate();

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Listings),
  );

  const {data, loading, refetch} = useQuery(GET_ALL_FILTERED_CAMPAIGNS, {
    fetchPolicy: 'no-cache',
    variables: {
      listingStatus: activeTab,
      isVisible: JSON.parse(isVisible),
    },
  });

  const items = [
    {
      label: 'Visible Listings',
      key: true,
    },
    {
      label: 'Hidden Listings',
      key: false,
    },
  ];

  const handleGridIconClick = e => {
    setSwitchView(true);
    setGridIconSelect((e.currentTarget.src = purpleGridIcon));
    setListIconSelect(listIcon);
  };

  const handleListIconClick = e => {
    setSwitchView(false);
    setListIconSelect((e.currentTarget.src = purpleListIcon));
    setGridIconSelect(gridIcon);
  };

  const handleClickMenu = e => {
    setIsVisible(JSON.parse(e.key));
    refetch({
      isVisible: JSON.parse(e.key),
      listingStatus: activeTab,
    });
  };

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <div className={styles.title}>
          <h1>Listings</h1>
        </div>
        <div>
          <Button
            onClick={() => navigate('/dashboard/campaigns/new/select-company')}
            label={'Create'}
            disabled={!tabOperations?.create}
          />
        </div>
      </section>

      <section className={styles['percentage-boxes']}>
        <RenderCampaignCard
          liveValues={data?.getLiveCampaignStats}
          totalCompanies={data?.getTotalNumberOfCompanies}
        />
      </section>
      <section className={styles['sections-tab']}>
        <div className={styles['toggler']}>
          {CAMPAIGN_TYPES.map(({label, key, route}) => (
            <span
              key={key}
              onClick={() => {
                setActiveTab(key);
                navigate(`/dashboard/campaigns?${route}`);
              }}
              className={activeTab === key ? styles['active-tab'] : ''}>
              {label}
            </span>
          ))}
        </div>
      </section>
      <section className={styles['campaign-section']}>
        <div className={styles['campaign-header']}>
          <h1>Listings</h1>
          <div className={styles['right-grid']}>
            <span>Switch View: </span>
            <div>
              <img
                onClick={e => handleGridIconClick(e)}
                src={gridIconSelect}
                alt=""
              />
              <img
                onClick={e => handleListIconClick(e)}
                src={listIconSelect}
                alt=""
              />
            </div>
            <CustomDropdown
              options={items}
              selectedOption={isVisible}
              setSelectedOption={handleClickMenu}
            />
          </div>
        </div>
        {loading ? (
          <div className={styles.loading}>
            <Spin size="large" />
            <h1>Loading...</h1>
          </div>
        ) : (
          <div className={styles.campaignContents}>
            {swtichView ? (
              <div className={styles['active-campaign']}>
                <>
                  {data?.getAllCampaigns?.campaigns?.map((data, index) => (
                    <div key={index}>
                      <DraftCampaignCard
                        id={data.id}
                        fundraising={data?.fundraisingCompany}
                        description={data?.aboutCompany}
                        listingType={data?.listingType}
                        offeringId={data?.offeringId}
                        visibility={data?.settings?.isVisible}
                        visibleState={isVisible}
                        activeTab={activeTab}
                        offeringType={data?.offeringType}
                        type={data?.campaignStatus}
                        operations={tabOperations}
                        mode={data?.campaignMode}
                        target={data?.investmentTerms?.fundingTarget}
                        ipoDetails={data?.ipoFields}
                        ventureFundFields={data?.ventureFundFields}
                      />
                    </div>
                  ))}
                  {data?.getAllCampaigns?.campaigns?.length < 1 && (
                    <div className={styles['no-campaign']}>
                      <h1>No Listing</h1>
                    </div>
                  )}
                </>
              </div>
            ) : (
              <div>
                <CampaignTable
                  data={data?.getAllCampaigns?.campaigns}
                  active={activeTab}
                  visibleState={isVisible}
                />
              </div>
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export {Campaigns};
