import styles from './deleteReportSection.module.scss';
import DeleteImage from 'images/delete_illustration.png';
import {Button} from '@dabafinance/react-components';

export default function DeleteReportSection({onDismiss, onProceed}) {
  return (
    <div className={styles['modal-container']}>
      <div className={styles.illustration}>
        <img src={DeleteImage} alt="" />
      </div>
      <h2 className={styles['heading-text']}>Delete Section</h2>
      <p>
        Are you sure you want to delete this section, you can always create it
        anytime
      </p>
      <div className={styles['modal-footer']}>
        <Button
          label="Cancel"
          className={styles['cancel-button']}
          onClick={() => onDismiss?.()}
        />
        <Button
          label="Proceed"
          className={styles['proceed-button']}
          onClick={() => onProceed?.()}
        />
      </div>
    </div>
  );
}
