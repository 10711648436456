import {Outlet, useNavigate} from 'react-router-dom';
import styles from './rewardSchemes.module.scss';
import backIcon from 'images/back.png';
import CustomLink from 'components/customLink/customLink';

const UpdateRewardSchemeManagement = () => {
  const navigate = useNavigate();

  return (
    <div className={styles['investor-container']}>
      <div className={styles.navigation}>
        <img
          src={backIcon}
          alt="Go back"
          onClick={() => navigate('/dashboard/reward-schemes/')}
        />
        <span>
          <span className={styles['h-style']}>Reward Scheme Updates</span>
        </span>
      </div>
      <section className={styles.section}>
        <nav className={styles['investor-toggler']}>
          <CustomLink to={''} name="Update Reward Scheme" />
          <CustomLink to={'users'} name="Rewarded Users" />
        </nav>
      </section>
      <section>
        <Outlet />
      </section>
    </div>
  );
};

export {UpdateRewardSchemeManagement};
