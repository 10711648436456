import {useState} from 'react';
import RoundedButton from 'components/button/roundedButton';
import TextInput from 'components/input/textInput';
import SignUpLayout from 'layout/signup';
import styles from './index.module.scss';
import {useNavigate} from 'react-router-dom';
import OnboardingImage from 'components/image/onboardingImage';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import classNames from 'classnames/bind';
import {loginUser} from 'redux/store/auth/actions';
import {toast} from 'react-toastify';
import {ADMIN_TABS} from 'utils/constants';
import {handleNavigateToHome} from 'redux/store/auth';

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onTouched',
  });
  const cs = classNames.bind(styles);

  const onSubmit = async data => {
    setLoading(true);
    const result = await dispatch(loginUser(data));
    if (result.error) {
      toast.error(result.error.message);
    } else {
      if (!result.user && result.twoFactorStepRequired) {
        navigate('/sign-in/otp');
      } else {
        const tabs = result?.user?.roleSettings?.tabs;
        if (!tabs) {
          toast.error('Something Went Wrong. User Service Down');
          setLoading(false);
          return;
        }
        if (tabs?.length) {
          const checkTab = tabs.some(data => data?.tab !== 'OVERVIEW');
          const firstTab = tabs[0];

          if (checkTab) {
            let tabInfo;
            for (const key of Object.keys(ADMIN_TABS)) {
              const tab = ADMIN_TABS[key];
              if (tab.tab === firstTab.tab) {
                tabInfo = tab;
                break;
              } else if (
                tab.inner &&
                Object.keys(tab.inner).includes(firstTab.tab)
              ) {
                tabInfo = tab.inner[firstTab.tab];
                break;
              }
            }
            if (tabInfo) {
              dispatch(
                handleNavigateToHome({route: `/dashboard/${tabInfo.to}`}),
              );
              navigate(`/dashboard/${tabInfo.to}`);
            }
          }
        } else {
          navigate('/dashboard');
        }
      }
    }
    setLoading(false);
  };

  return (
    <SignUpLayout
      left={
        <div className={cs('form-container')}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={cs('welcome-section')}>
              <h1>Welcome Back 👋</h1>
              <p>Please fill in your login details to get started</p>
            </div>

            <div>
              <TextInput
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
                name="email"
                label="Email address"
                placeholder="Enter Email"
                icon="fal fa-envelope"
                type="text"
                error={errors?.email}
              />
              {errors?.email && (
                <span className={styles.error}>{errors.email.message}</span>
              )}
            </div>

            <div>
              <TextInput
                name="password"
                {...register('password', {
                  required: true,
                })}
                label="Password"
                placeholder="Enter Password"
                icon="fal fa-lock"
                password={'password-type'}
                error={errors?.password}
              />
              {errors?.password && (
                <span className={styles.error}>{errors.password.message}</span>
              )}
            </div>
            <div className={cs('password-container')}>
              {/* <span className={styles['password-text']}>
                <Link to="/forgot-password" className={styles.link}>
                  Forgot password
                </Link>
              </span> */}

              <RoundedButton
                text="Sign in"
                btnstyle="fill"
                loading={loading ? true : ''}
              />
              {/* <p className={cs('account-text')}>
                Dont have an account? <a href="/register">Sign up</a>
              </p> */}
            </div>
          </form>
        </div>
      }
      right={
        <>
          <OnboardingImage alt="login image" image="login.png" />
        </>
      }
    />
  );
}
