import {Button} from '@dabafinance/react-components';
import {useEffect, useState} from 'react';
import styles from './profile.module.scss';
import {Select, Form, Input, Spin, InputNumber, Tabs} from 'antd';
import DetailOverview from './overview';
import CompanyVideoUpload from './companyVideo';
import UploadImage from './companyImage';
import {toast} from 'react-toastify';
import backIcon from 'images/back.png';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import FounderSection from '../founder';
import {useMutation, useQuery} from '@apollo/client';
import {
  GET_A_STARTUP,
  GET_COMPANY_BY_ADMIN,
} from 'graphql/queries/startupCompanies';
import {
  DELETE_COMPANY,
  UPDATE_COMPANY,
} from 'graphql/mutations/companyStartups';
import UpdateTopics from './updateTopics/UpdateTopics';
import {UPDATE_FUNDRAISING_COMPANY} from 'graphql/mutations/campaign';
import {GET_FUNDRAISING_COMPANY_BY_ID} from 'graphql/queries/campaign';
import SocialCard from './social/card/socialCard';
import FundingHistory from './fundingHistory/FundingHistory';
import CompanyKeyMetric from './keyMetric';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {requestCompanyTranslation} from 'redux/store/news/actions';
import {defaultLanguages} from 'pages/news/newsTypes';
import TranslatedCompanyDetails from '../creation/translation/TranslatedCompanyDetails';
import {fieldRules} from 'utils/helper';
import DocumentCenter from 'pages/documentCenter/DocumentCenter';
import CompanyStock from 'components/CompanyStock/CompanyStock';
import CompanyUserInterested from './companyUserInterested/CompanyUserInterested';
import {makeSelectTabOperations} from 'redux/store/auth';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';

const ProfileDetails = () => {
  const [activeTab, setActiveTab] = useState('DETAILS');
  const [bannerUrl, setBannerUrl] = useState('');
  const [bannerPreviewUrl, setBannerPreviewUrl] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [legalName, setLegalName] = useState('');
  const [description, setDescription] = useState('');
  const [foundedOn, setFoundedOn] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [location, setLocation] = useState('');
  const [stage, setStage] = useState('');
  const [website, setWebsite] = useState('');
  const [accelerator, setAccelerator] = useState('');
  const [profileData, setProfileData] = useState([]);
  const [longDescription, setLongDescription] = useState('');
  const [ticker, setTicker] = useState('');
  const [fundRaised, setFundRaised] = useState('');
  const [operatingCountries, setOperatingCountries] = useState([]);
  const [latestValuation, setLatestValuation] = useState(0);
  const [numOfEmployees, setNumOfEmployees] = useState('');
  const [baseLanguage, setBaseLanguage] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [translatedData, setTranslatedData] = useState([]);
  const [fundingHistory, setFundingHistory] = useState([
    {
      round: '',
      investors: [
        {
          investorName: '',
          investedAmount: 0,
          imageUrl: '',
        },
      ],
      fundingAmount: 0,
      fundingDate: '',
      fundingType: '',
      equityPercentage: 0,
      debtPercentage: 0,
      equityAmount: 0,
      debtAmount: 0,
      companyValuation: 0,
    },
  ]);
  const [externalCoInvestorsImage, setExternalCoInvestorsImage] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [externalInvestor, setExternalInvestor] = useState('');
  const [externalInvestedAmount, setExternalInvestedAmount] = useState(0);
  const {id} = useParams();
  const navigate = useNavigate();
  const route = useLocation();

  const pageOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Startups),
  );
  const searchParamValue = new URLSearchParams(route.search).get('route');

  useEffect(() => {
    if (searchParamValue === 'document') {
      setActiveTab('DOCUMENT_CENTER');
    } else if (searchParamValue === 'stocks') {
      setActiveTab('STOCKS');
    }
  }, [searchParamValue]);

  const {data: fundRaisingCompany, loading: loadingRaising} = useQuery(
    GET_FUNDRAISING_COMPANY_BY_ID,
    {
      variables: {
        companyId: id,
      },
    },
  );
  const {loading} = useQuery(GET_COMPANY_BY_ADMIN, {
    variables: {
      companyId: id,
    },
    onCompleted: data => setProfileData(data.adminGetCompany),
    onError: error => toast.error(error),
  });

  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );

  const handleClearExternalDetails = () => {
    setExternalCoInvestorsImage('');
    setExternalInvestor('');
    setPreviewImage('');
    setExternalInvestedAmount(0);
  };

  const [updateCompany, {loading: loadingUpdate}] = useMutation(UPDATE_COMPANY);
  const [updateFundraisingCompany] = useMutation(UPDATE_FUNDRAISING_COMPANY);
  const [deleteCompany] = useMutation(DELETE_COMPANY);

  useEffect(() => {
    setBannerPreviewUrl(profileData?.bannerImgURL);
    setImgUrl(profileData?.logoImgURL);
    setBannerUrl(profileData?.bannerImgURL);
    setPreviewUrl(profileData?.logoImgURL);
    setDescription(profileData?.shortDescription);
    setLongDescription(profileData?.description);
    setBaseLanguage(profileData?.language);
    setTranslatedData(
      profileData?.multiLang?.map(data => ({
        longDescription: data?.description,
        description: data?.shortDescription,
        language: data?.language,
      })),
    );
    setLatestValuation(profileData?.fundraisingHistory?.latestValuation);
    setFundingHistory(
      profileData?.fundraisingHistory?.fundingHistory?.map(item => ({
        round: item?.round,
        fundingAmount: item?.fundingAmount,
        fundingDate: item?.fundingDate,
        fundingType: item?.fundingType,
        equityPercentage: item?.equityPercentage,
        debtPercentage: item?.debtPercentage,
        equityAmount: item?.equityAmount,
        debtAmount: item?.debtAmount,
        companyValuation: item?.companyValuation,
        investors: item?.investors?.map(data => ({
          investorName: data?.investorName,
          investedAmount: data?.investedAmount,
          imageUrl: data?.imageUrl,
        })),
      })),
    );
  }, [profileData]);

  const {TextArea} = Input;

  const {teamMembers} = profileData;

  const languageToBeTranslatedTo = defaultLanguages.filter(
    key => key.key !== baseLanguage,
  );

  const handleChangeTranslation = (key, event, name) => {
    let eventName = name || event.target.name;
    let eventValue = name ? event : event.target.value;
    const newFormValues = [...translatedData];
    newFormValues[key][eventName] = eventValue;
    setTranslatedData(newFormValues);
  };

  const handleTranslation = async () => {
    const newValues = [...(translatedData ?? [])];

    if (!baseLanguage && !description && !longDescription) {
      return toast.error(
        'Please make sure none of the necessary fields needed for translation are empty',
      );
    }

    const translateProps = {
      sourceLang: baseLanguage,
      targetLang: selectedLanguage,
      targetText1: description,
      targetText2: longDescription,
    };
    if (newValues.length && newValues[0]) {
      const languageExists = newValues.some(
        data => data?.language === selectedLanguage,
      );
      if (languageExists) {
        const updateTranslation = await requestCompanyTranslation(
          translateText,
          translateProps,
        );
        const translationIndex = newValues.findIndex(
          data => data.language === selectedLanguage,
        );
        newValues[translationIndex].description = updateTranslation.description;
        newValues[translationIndex].longDescription =
          updateTranslation.longDescription;
        newValues[translationIndex].language = updateTranslation.language;
        setTranslatedData(newValues);
      }
    } else {
      const triggerNewTranslation = await requestCompanyTranslation(
        translateText,
        translateProps,
      );
      if (!triggerNewTranslation) {
        return toast.error('Something went wrong when Translating');
      }
      newValues.push(triggerNewTranslation);
      setTranslatedData(newValues);
    }
  };

  const handleSaveCompany = () => {
    const optionalFields = {};
    if (numOfEmployees) {
      optionalFields.numberOfEmployees = numOfEmployees;
    }
    updateCompany({
      variables: {
        input: {
          companyId: id,
          bannerImgURL: bannerUrl,
          logoImgURL: imgUrl,
          website: website || profileData?.website,
          foundedOn: foundedOn || profileData?.foundedOn,
          shortDescription: description || profileData?.shortDescription,
          accelerator: accelerator || profileData?.accelerator,
          stage: stage || profileData?.stage,
          headquarter: location || profileData?.location,
          ticker: ticker || profileData?.ticker,
          name: companyName || profileData?.name,
          legalName: legalName || profileData?.legalName,
          description: longDescription || profileData?.description,
          totalCapitalRaised: fundRaised
            ? Number(fundRaised)
            : profileData?.totalCapitalRaised,
          operatingCountries: operatingCountries
            ? operatingCountries?.map(data => data?.split(';')[1])
            : profileData?.operatingCountries.map(data => data?.id),
          fundraisingHistory: {
            latestValuation,
            fundingHistory,
          },
          multiLang: translatedData?.map(data => ({
            description: data?.longDescription,
            language: data?.language,
            shortDescription: data?.description,
          })),
          ...optionalFields,
        },
      },
      refetchQueries: [{query: GET_A_STARTUP, variables: {companyId: id}}],
    })
      .then(({data: {updateCompany}}) => {
        if (updateCompany.__typename === 'Error') {
          toast.error(updateCompany.message);
        } else {
          toast.success('Updated Successfully');
          if (fundRaisingCompany?.getFundraisingCompanyById?.id) {
            updateFundraisingCompany({
              variables: {
                input: {
                  companyId: id,
                  bannerImgURL: bannerUrl,
                  logoImgURL: imgUrl,
                  website: website || profileData?.website,
                  foundedOn: foundedOn
                    ? Number(foundedOn)
                    : Number(profileData?.foundedOn),
                  shortDescription:
                    description || profileData?.shortDescription,
                  accelerator: accelerator || profileData?.accelerator,
                  stage: stage || profileData?.stage,
                  location: location || profileData?.location,
                  ticker: ticker || profileData?.ticker,
                  companyName: companyName || profileData?.name,
                  legalName: companyName || profileData?.legalName,
                  description: longDescription || profileData?.description,
                  totalCapitalRaised: fundRaised
                    ? parseInt(fundRaised)
                    : profileData?.totalCapitalRaised,
                  operatingCountries: operatingCountries
                    ? operatingCountries?.map(data => data?.split(';')[1])
                    : profileData?.operatingCountries.map(data => data?.id),
                },
                ...optionalFields,
              },
            })
              .then(({data: {updateFundraisingCompany}}) => {
                if (updateFundraisingCompany.__typename === 'Error') {
                  toast.error(updateFundraisingCompany.message);
                } else {
                  toast.success('Fundraising Company Updated as well');
                }
              })
              .catch(error => {
                toast.error(error);
              });
          }
        }
      })
      .catch(error => {
        toast.error(error);
      });
    // Check if the company is a fundraising company and update it
  };

  const handleDeleteCompany = () => {
    deleteCompany({
      variables: {
        companyId: id,
      },
    })
      .then(({data: {deleteCompany}}) => {
        if (deleteCompany.__typename === 'Error') {
          toast.error(deleteCompany.message);
        } else {
          toast.success('Company Deleted Successfully');
          navigate('/dashboard/opportunities/startups');
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  if (loading || loadingRaising) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <>
      {profileData.length < 1 ? (
        <div className={styles.loading}>
          <Spin size="large" />
          <h1>Loading...</h1>
        </div>
      ) : (
        <>
          <section className={styles['container-header']}>
            <div className={styles.header}>
              <img src={backIcon} alt="back" onClick={() => navigate(-1)} />
              <span className={styles.startups}>
                Companies /{' '}
                <span className={styles.details}>Profile Details</span>
              </span>
            </div>
          </section>
          <section className={styles.section}>
            <div className={styles['details-toggler']}>
              <span
                className={
                  activeTab === 'DETAILS' ? styles['active-toggle'] : ''
                }
                onClick={() => setActiveTab('DETAILS')}>
                {' '}
                Startup Details
              </span>
              <span
                className={
                  activeTab === 'CATEGORIES' ? styles['active-toggle'] : ''
                }
                onClick={() => setActiveTab('CATEGORIES')}>
                {' '}
                Categories
              </span>
              <span
                className={
                  activeTab === 'SOCIALS' ? styles['active-toggle'] : ''
                }
                onClick={() => setActiveTab('SOCIALS')}>
                Socials
              </span>
              <span
                className={
                  activeTab === 'FOUNDERS' ? styles['active-toggle'] : ''
                }
                onClick={() => setActiveTab('FOUNDERS')}>
                {' '}
                Management
              </span>
              <span
                className={
                  activeTab === 'KEYMETRIC' ? styles['active-toggle'] : ''
                }
                onClick={() => setActiveTab('KEYMETRIC')}>
                {' '}
                Key Metrics
              </span>
              <span
                className={
                  activeTab === 'STOCKS' ? styles['active-toggle'] : ''
                }
                onClick={() => setActiveTab('STOCKS')}>
                {' '}
                Stocks
              </span>
              <span
                className={
                  activeTab === 'DOCUMENT_CENTER' ? styles['active-toggle'] : ''
                }
                onClick={() => setActiveTab('DOCUMENT_CENTER')}>
                {' '}
                Document
              </span>
              <span
                className={
                  activeTab === 'USER_INTERESTED' ? styles['active-toggle'] : ''
                }
                onClick={() => setActiveTab('USER_INTERESTED')}>
                {' '}
                Users Interested
              </span>
            </div>
          </section>
          {activeTab === 'DETAILS' && (
            <section className={styles.content}>
              <div className={styles['left-grid']}>
                <div>
                  <UploadImage
                    setPreviewUrl={setPreviewUrl}
                    previewUrl={previewUrl}
                    bucket="company-service-bucket"
                    setImgUrl={setImgUrl}
                  />
                </div>
                <div />
              </div>
              <div className={styles['right-grid']}>
                <div>
                  <CompanyVideoUpload
                    bannerPreviewUrl={bannerPreviewUrl}
                    setBannerUrl={setBannerUrl}
                    setBannerPreviewUrl={setBannerPreviewUrl}
                  />
                </div>
                <div className={styles['about-company']}>
                  <h1>About Company</h1>
                  <Form layout="vertical" className={styles['text-editor']}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Company Name Required',
                        },
                      ]}
                      label="Company Name"
                      name="company name">
                      <Input
                        defaultValue={profileData?.name}
                        value={companyName}
                        onChange={e => setCompanyName(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Company Name Required',
                        },
                      ]}
                      label="Legal Name"
                      name="legal name">
                      <Input
                        defaultValue={profileData?.legalName}
                        value={legalName}
                        onChange={e => setLegalName(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Base Language"
                      className={styles['form-item']}
                      name="baseLanguage"
                      initialValue={profileData?.language}
                      rules={[fieldRules('Language')]}>
                      <Select
                        value={baseLanguage}
                        defaultValue={profileData?.language}
                        onChange={e => setBaseLanguage(e)}
                        placeholder="Select a base language">
                        {defaultLanguages.map(data => (
                          <Select.Option key={data.key}>
                            {data.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Translation Language"
                      className={styles['form-item']}
                      name="translationLanguage"
                      rules={[
                        fieldRules(
                          'You need to select a language to Translate',
                        ),
                      ]}>
                      <Select
                        value={selectedLanguage}
                        onChange={e => setSelectedLanguage(e)}
                        placeholder="Select a Language to be translated">
                        {languageToBeTranslatedTo.map(name => (
                          <Select.Option key={name.key}>
                            {name.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Tabs
                      style={{maxWidth: 800}}
                      tabBarExtraContent={
                        selectedLanguage && (
                          <Button
                            label="Translate"
                            disabled={
                              loadingTranslation ||
                              !description ||
                              !longDescription
                            }
                            onClick={handleTranslation}
                          />
                        )
                      }>
                      <Tabs.TabPane key="Original" tab="Original">
                        <Form.Item
                          label="Short Description"
                          name="short description"
                          rules={[
                            {
                              required: true,
                              message:
                                'Company Short Description Required: Minimum of 30 words, Maximum of 50 words',
                              max: 300,
                              min: 50,
                            },
                          ]}>
                          <TextArea
                            value={description}
                            defaultValue={profileData?.shortDescription}
                            onChange={e => setDescription(e.target.value)}
                            rows={4}
                          />
                        </Form.Item>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message:
                                'Company Long Decription Required: Minimum of 100 words',
                              min: 300,
                            },
                          ]}
                          label="Description"
                          name="description">
                          <TextArea
                            value={longDescription}
                            onChange={e => setLongDescription(e.target.value)}
                            rows={8}
                            defaultValue={profileData?.description}
                          />
                        </Form.Item>
                      </Tabs.TabPane>
                      {translatedData?.map((item, index) => (
                        <Tabs.TabPane
                          key={index}
                          tab={
                            languageToBeTranslatedTo.find(
                              lang => lang?.key === item?.language,
                            )?.label
                          }>
                          <TranslatedCompanyDetails
                            fieldKey={index}
                            onChangeFields={handleChangeTranslation}
                            loading={loadingTranslation}
                            data={item}
                          />
                        </Tabs.TabPane>
                      ))}
                    </Tabs>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Company Type Required',
                        },
                      ]}
                      label="Company Type"
                      name="companyType">
                      <Select
                        value={companyType}
                        defaultValue={profileData?.companyType}
                        onChange={e => setCompanyType(e)}>
                        <Select.Option value="PUBLIC">Public</Select.Option>
                        <Select.Option value="PRIVATE">Private</Select.Option>
                      </Select>
                    </Form.Item>
                  </Form>
                </div>
                <div className={styles.overview}>
                  <DetailOverview
                    setAccelerator={setAccelerator}
                    setLocation={setLocation}
                    setStage={setStage}
                    setWebsite={setWebsite}
                    setTicker={setTicker}
                    setFundRaised={setFundRaised}
                    stage={stage}
                    setFoundedOn={setFoundedOn}
                    setNumOfEmployees={setNumOfEmployees}
                    setOperatingCountries={setOperatingCountries}
                  />
                </div>
                <div className={styles.overview}>
                  <Form layout="vertical" className={styles['text-editor']}>
                    <h1 className={styles.header}>Company Valuation</h1>
                    <div className={styles['form-inputs']}>
                      <div>
                        <Form.Item
                          label="Latest Valuation"
                          name="latestValuation"
                          className={styles['form-item']}>
                          <InputNumber
                            value={latestValuation}
                            defaultValue={
                              profileData?.fundraisingHistory?.latestValuation
                            }
                            className={styles['input-number']}
                            onChange={e => setLatestValuation(e)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                  <FundingHistory
                    externalInvestor={externalInvestor}
                    setExternalInvestor={setExternalInvestor}
                    externalInvestedAmount={externalInvestedAmount}
                    setExternalInvestedAmount={setExternalInvestedAmount}
                    previewImage={previewImage}
                    setPreviewImage={setPreviewImage}
                    externalCoInvestorsImage={externalCoInvestorsImage}
                    setExternalCoInvestorsImage={setExternalCoInvestorsImage}
                    profileData={profileData}
                    fundingHistory={fundingHistory}
                    clearExternalInvestor={handleClearExternalDetails}
                    setFundingHistory={setFundingHistory}
                  />
                  <Button
                    className={styles['button-style']}
                    label="Save Changes"
                    disabled={!pageOperations?.update || loadingUpdate}
                    onClick={handleSaveCompany}
                  />
                  <Button
                    className={styles['button-red']}
                    type="secondary"
                    disabled={!pageOperations?.delete}
                    label="Delete Company"
                    onClick={handleDeleteCompany}
                  />
                </div>
              </div>
            </section>
          )}
          {activeTab === 'SOCIALS' && (
            <SocialCard media={profileData?.socials} />
          )}
          {activeTab === 'CATEGORIES' && (
            <UpdateTopics
              CompanyTopics={profileData?.topics}
              CompanyIndustries={profileData?.industry}
            />
          )}
          {activeTab === 'FOUNDERS' && <FounderSection team={teamMembers} />}
          {activeTab === 'KEYMETRIC' && <CompanyKeyMetric id={id} />}
          {activeTab === 'DOCUMENT_CENTER' && <DocumentCenter id={id} />}
          {activeTab === 'USER_INTERESTED' && <CompanyUserInterested id={id} />}

          {activeTab === 'STOCKS' && (
            <CompanyStock existingData={profileData} update={true} />
          )}
        </>
      )}
    </>
  );
};

export default ProfileDetails;
