import {Route, Routes} from 'react-router-dom';
import InvestorManagement from './management';
import EditUpdate from './management/editUpdate';
import InvestmentDetails from './management/investmentDetail';
import AddNews from './management/news/AddNews/AddNews';
import EditNews from './management/news/editNews/EditNews';
import NewUpdate from './management/newUpdate';
import OlderUpdates from './management/olderUpdate';
import TrendingStartups from './management/trendingStartups';
import LearnArticle from './management/learnArticle';
import NewLearnArticle from './management/learnArticle/newLearnArticle';
import EditLearnArticle from './management/learnArticle/editLearnArticle';
import TrendingCountries from './management/trendingCountries';
import TrendingIndustries from './management/trendingIndustries';
import AddCompany from './management/trendingCountries/addCompany';
import EditCompany from './management/trendingIndustries/editCompany';
import StartupOfTheWeek from './management/StartupOfTheWeek/StartupOfTheWeek';
import CreateStartupOfTheWeek from './management/StartupOfTheWeek/create/CreateStartupOfTheWeek';

const InvestorUpdates = () => {
  return (
    <Routes>
      <Route path="/" element={<InvestorManagement />}>
        <Route index path="/" element={<InvestmentDetails />} />
        <Route path="/trending-startups" element={<TrendingStartups />} />
        <Route path="/learn-article" element={<LearnArticle />} />
        <Route path="/trending-countries/*" element={<TrendingCountries />} />
        <Route path="/trending-countries/:id" element={<AddCompany />} />
        <Route path="/trending-industries/*" element={<TrendingIndustries />} />
        <Route path="/trending-industries/:id" element={<EditCompany />} />
        <Route path="/startup-of-the-week" element={<StartupOfTheWeek />} />
        <Route />
      </Route>
      <Route
        path="/create-startup-of-the-week"
        element={<CreateStartupOfTheWeek />}
      />
      <Route path="/investor-update/edit/:id" element={<EditUpdate />} />
      <Route path="/learn-article/edit/:id" element={<EditLearnArticle />} />
      <Route path="/learn-article/new" element={<NewLearnArticle />} />
      <Route path="/news/add-news" element={<AddNews />} />
      <Route path="/news/update-news/:id" element={<EditNews />} />
      <Route path="/investor-update/new" element={<NewUpdate />} />
      <Route path="/investor-update/older-updates" element={<OlderUpdates />} />
    </Routes>
  );
};

export default InvestorUpdates;
