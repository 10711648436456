import {Form, InputNumber, Select} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import styles from './investmentDetails.module.scss';
import {useNavigate, useParams} from 'react-router-dom';
import {
  portfolioStrategyData,
  securityTypeOptions,
} from 'pages/campaigns/campaignData';
import {useDispatch, useSelector} from 'react-redux';
import {investmentDetails} from 'redux/store/campaign';

const InvestmentDetails = () => {
  const [fundingTarget, setFundingTarget] = useState(0);
  const [valuation, setValuation] = useState('');
  const [portfolioStrategy, setPortfolioStrategy] = useState([]);
  const [minimumInvestment, setMinimumInvestment] = useState(0);
  const [maximumFundingTarget, setMaximumInvestment] = useState(0);
  const [minimumFundingTarget, setMinimumFundingTarget] = useState(0);
  const [pricePerSecuirity, setPricePerSecuirity] = useState(0);
  const [securityType, setSecurityType] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [vendor, setVendor] = useState('');
  const [campaignMet, setCampaignMet] = useState(false);
  const dispatch = useDispatch();
  const {Option} = Select;
  const navigate = useNavigate();
  const {id, slug, companyId} = useParams();
  const width = 386;

  const campaign = useSelector(state => state.campaign.campaign);

  const validate =
    !fundingTarget ||
    !valuation ||
    !minimumFundingTarget ||
    fundingTarget > maximumFundingTarget ||
    fundingTarget < minimumFundingTarget ||
    !maximumFundingTarget ||
    !pricePerSecuirity ||
    !minimumInvestment ||
    !pricePerSecuirity;

  useEffect(() => {
    setDisableButton(validate);
  }, [disableButton, validate]);

  useEffect(() => {
    if (campaign?.fundingTarget != undefined) {
      setFundingTarget(campaign?.fundingTarget);
      setValuation(campaign?.valuation);
      setMinimumInvestment(campaign?.minimumInvestment);
      setVendor(campaign?.vendor);
      setSecurityType(campaign?.securityType);
      setPortfolioStrategy(campaign?.portfolioStrategy);
      setPricePerSecuirity(campaign?.pricePerSecurity);
      setCampaignMet(campaign?.targetMet);
      setMaximumInvestment(campaign?.maximumFundingTarget);
      setMinimumFundingTarget(campaign?.minimumFundingTarget);
    }
  }, [campaign?.fundingTarget]);

  const routeToNextPage = () => {
    // Route to the next page based listing type
    if (slug) {
      if (campaign?.listType === 'IPO') {
        navigate(`/dashboard/campaigns/${slug}/new/${id}/ipo/${companyId}`);
      } else if (campaign?.listType === 'VENTURE_FUND') {
        navigate(
          `/dashboard/campaigns/${slug}/new/${id}/venture-fund/${companyId}`,
        );
      } else {
        navigate(
          `/dashboard/campaigns/${slug}/new/${id}/founders/${companyId}`,
        );
      }
    } else {
      if (campaign?.listType === 'IPO') {
        navigate(`/dashboard/campaigns/new/${id}/ipo/${companyId}`);
      } else if (campaign?.listType === 'VENTURE_FUND') {
        navigate(`/dashboard/campaigns/new/${id}/venture-fund/${companyId}`);
      } else {
        navigate(`/dashboard/campaigns/new/${id}/documents/${companyId}`);
      }
    }
  };

  const handleSave = () => {
    dispatch(
      investmentDetails({
        fundingTarget,
        valuation,
        minimumInvestment,
        maximumFundingTarget,
        portfolioStrategy,
        minimumFundingTarget,
        pricePerSecurity: pricePerSecuirity,
        targetMet: campaignMet,
        vendor,
        securityType,
      }),
    );
    routeToNextPage();
  };

  return (
    <div className={styles.container}>
      <Form onFinish={handleSave} layout="vertical">
        <div>
          <h1 className={styles.header}>Third Party Provider</h1>
          <div>
            <Form.Item
              style={{width: 450, marginTop: 10}}
              name="Vendor"
              label="Vendor"
              initialValue={campaign?.vendor}>
              <Select
                name="vendor"
                defaultValue={campaign?.vendor}
                value={vendor}
                placeholder="Select a third party provider"
                onChange={e => setVendor(e)}>
                <Option value={'DABA'}>Daba</Option>
              </Select>
            </Form.Item>
          </div>
        </div>
        <div>
          <h1 className={styles.header}>Portfolio Strategy</h1>
          <div>
            <Form.Item
              style={{width: 450, marginTop: 10}}
              name="portfolio strategy"
              label="Recommended Portfolio Strategy"
              rules={[{required: true, message: 'Required Field'}]}
              initialValue={campaign?.portfolioStrategy}>
              <Select
                name="vendor"
                defaultValue={campaign?.portfolioStrategy}
                value={portfolioStrategy}
                placeholder="Select a recommended portfolio strategy"
                onChange={e => setPortfolioStrategy(e)}>
                {portfolioStrategyData?.map(data => (
                  <Option key={data.value}>
                    <span>{data.label}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div>
          <h1 className={styles.header}>
            Investment Details in Asset Currency
          </h1>
        </div>
        <div>
          <div className={styles.form}>
            <Form.Item
              className={styles['field-width']}
              name="fundingTarget"
              label="Funding Target"
              initialValue={campaign?.fundingTarget}>
              <InputNumber
                value={fundingTarget}
                onChange={e => setFundingTarget(e)}
                placeholder="Enter Funding Target Amount"
                defaultValue={campaign?.fundingTarget}
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              initialValue={campaign?.valuation}
              className={styles['field-width']}
              name="valuation"
              label="Valuation">
              <InputNumber
                value={valuation}
                onChange={e => setValuation(e)}
                placeholder="Enter Company Valuation Amount"
                defaultValue={campaign?.valuation}
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              name="Minimum Investment"
              label="Minimum Investment"
              initialValue={campaign?.minimumInvestment}>
              <InputNumber
                value={minimumInvestment}
                onChange={e => setMinimumInvestment(e)}
                placeholder="Enter amount"
                className={styles['input-number']}
                defaultValue={campaign?.minimumInvestment}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              help={
                maximumFundingTarget < fundingTarget && (
                  <small style={{color: 'orangeRed'}}>
                    This must be GREATER than the Funding Target
                  </small>
                )
              }
              name="MaximumTarget"
              label="Maximum Funding Target"
              initialValue={campaign?.maximumFundingTarget}>
              <InputNumber
                value={maximumFundingTarget}
                min={fundingTarget}
                onChange={e => setMaximumInvestment(e)}
                placeholder="Enter amount"
                defaultValue={campaign?.maximumFundingTarget}
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              name="Minimum Funding Target"
              label="Minimum Funding Target"
              initialValue={campaign?.minimumFundingTarget}
              help={
                minimumFundingTarget > fundingTarget && (
                  <small style={{color: 'orangeRed'}}>
                    This must be LESS than the Funding Target
                  </small>
                )
              }>
              <InputNumber
                value={minimumFundingTarget}
                onChange={e => setMinimumFundingTarget(e)}
                defaultValue={campaign?.minimumFundingTarget}
                max={fundingTarget}
                placeholder="Enter amount"
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              initialValue={campaign?.securityType}
              className={styles['field-width']}
              label="Security Type"
              name="Security">
              <Select
                value={securityType}
                onChange={e => setSecurityType(e)}
                placeholder="Equity"
                defaultValue={campaign?.securityType}>
                {securityTypeOptions.map(data => (
                  <Option key={data.value}>
                    <span>{data.label}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={{width}}
              label="Price Per security"
              name="PricePerSecurity"
              initialValue={campaign?.pricePerSecurity}>
              <InputNumber
                value={pricePerSecuirity}
                onChange={e => setPricePerSecuirity(e)}
                className={styles['input-number']}
                placeholder="Enter amount"
                defaultValue={campaign?.pricePerSecurity}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <div className={styles['checkbox']}>
              <input
                value={campaignMet}
                onChange={e => setCampaignMet(e.target.checked)}
                type="checkbox"
                defaultChecked={campaign?.targetMet}
                id="fund"
                name="fund"
              />
              <label>Close Campaign when Funding Target is met</label>
            </div>
          </div>
          <div className={styles['warning']}>
            {disableButton && (
              <>
                {' '}
                Please make sure to input all Investment Details Section before
                moving forward
              </>
            )}
          </div>
          <SubmitButton disabled={disableButton} label="Save & Continue" />
        </div>
      </Form>
    </div>
  );
};

export default InvestmentDetails;
