import {useNavigate, useParams} from 'react-router-dom';
import {BaseAnalysis} from '../BaseFinancialInstruments/baseAnalysis/BaseAnalysis';
import {useDispatch, useSelector} from 'react-redux';
import {handleBondsInstrumentState} from 'redux/store/assetClass';
import {createBondsInstrumentRoute} from './routes';

const BondsAnalysis = () => {
  const navigate = useNavigate();
  const {assetId} = useParams();
  const dispatch = useDispatch();
  const data = useSelector(state => state?.assetClass?.bondInstruments);

  const handleSubmit = values => {
    dispatch(handleBondsInstrumentState({...values}));
    navigate(createBondsInstrumentRoute({param: assetId, name: 'analogs'}));
  };

  return (
    <div>
      <BaseAnalysis onSubmit={handleSubmit} existingData={data} />
    </div>
  );
};

export default BondsAnalysis;
