import {DatePicker, Form, Input, Select, Spin, Switch, Tabs} from 'antd';
import {useMutation, useQuery} from '@apollo/client';
import {
  GET_DOCUMENT_CATEGORY,
  GET_SEEDED_STOCK_EXCHANGE_DOCUMENT_BY_ID,
} from 'graphql/queries/stocks';
import {toast} from 'react-toastify';
import {formatRoleString} from 'utils/helper';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {defaultLanguages} from 'pages/news/newsTypes';
import {Button} from '@dabafinance/react-components';
import {requestCompanyDocumentTranslation} from 'redux/store/news/actions';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {CREATE_STOCK_EXCHANGE_DOCUMENT} from 'graphql/mutations/stocks';
import SubmitButton from 'components/Startup/components/SubmitButton';

import styles from './documentsModals.module.scss';
import TranslatedStockExchangeDocument from 'pages/adminOpportunities/stocksListings/documents/create/TranslatedStockExchangeDocument';
import {CREATE_COMPANY_DOCUMENT_CENTER} from 'graphql/mutations/documentCenter';
import {GET_ALL_STARTUPS} from 'graphql/queries/startupCompanies';

const AddDocumentModal = ({contents, type, onClose}) => {
  const isCompany = type === 'COMPANY';
  const [form] = Form.useForm();
  const [documentName, setDocumentName] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [restrictLanguage, setRestrictLanguage] = useState(true);
  const [translatedData, setTranslatedData] = useState([]);

  const {data, loading} = useQuery(GET_DOCUMENT_CATEGORY, {
    variables: {
      filter: {
        limit: 50,
      },
    },
  });

  const {data: getAllCompanies, loading: loadingCompanies} =
    useQuery(GET_ALL_STARTUPS);

  const {data: seededDocument, loading: loadingDocument} = useQuery(
    GET_SEEDED_STOCK_EXCHANGE_DOCUMENT_BY_ID,
    {
      variables: {
        getSeededStockExchangeDocumentByIdId: contents?.id,
      },
    },
  );

  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );
  const [addStockExchangeDocument, {loading: loadingCreate}] = useMutation(
    CREATE_STOCK_EXCHANGE_DOCUMENT,
    {
      errorPolicy: 'all',
    },
  );
  const [addCompanyDocument, {loading: loadingCreateCompany}] = useMutation(
    CREATE_COMPANY_DOCUMENT_CENTER,
  );

  const languageToBeTranslatedTo = defaultLanguages.filter(
    key => key.key !== contents?.language,
  );

  useEffect(() => {
    if (seededDocument) {
      const {
        originalDocumentName,
        multiLang,
        dabaCategory,
        documentLink,
        company,
      } = seededDocument?.getSeededStockExchangeDocumentById;
      form.setFieldsValue({
        documentName: originalDocumentName,
        documentCategory: dabaCategory,
        documentUrl: documentLink,
        company: company ? `${company?.legalName};${company?.id}` : '',
      });
      setDocumentName(originalDocumentName);
      setTranslatedData(
        multiLang?.map(data => ({
          documentName: data?.originalDocumentName,
          pdfFile: data?.documentLink,
          pdfLink: data?.documentLink,
          language: data?.language,
          pdfName: data?.originalDocumentName,
          documentUrlSource: 'SEEDED',
        })),
      );
    }
  }, [seededDocument]);

  const handleTranslation = async () => {
    const newValues = [...translatedData];

    if (!contents?.language && !documentName) {
      return toast.error(
        'Please make sure none of the necessary fields needed for translation are empty',
      );
    }

    const translateProps = {
      sourceLang: contents?.language,
      targetLang: selectedLanguage,
      targetText1: documentName,
    };
    if (newValues.length && newValues[0]) {
      const languageExists = newValues.some(
        data => data?.language === selectedLanguage,
      );
      if (languageExists) {
        const updateTranslation = await requestCompanyDocumentTranslation(
          translateText,
          translateProps,
        );
        const translationIndex = newValues.findIndex(
          data => data.language === selectedLanguage,
        );
        newValues[translationIndex].documentName =
          updateTranslation.documentName;
        newValues[translationIndex].language = updateTranslation.language;
        setTranslatedData(newValues);
      }
    } else {
      let triggerNewTranslation = await requestCompanyDocumentTranslation(
        translateText,
        translateProps,
      );
      if (!triggerNewTranslation) {
        return toast.error('Something went wrong when Translating');
      }
      if (!triggerNewTranslation.documentUrlSource) {
        triggerNewTranslation.documentUrlSource = 'UPLOADED';
      }
      newValues.push(triggerNewTranslation);
      setTranslatedData(newValues);
    }
  };

  const handleSubmit = values => {
    const category = data?.getCompanyDocumentCategories?.data?.find(
      data => data?.name?.replace(' ', '_') === values?.documentCategory,
    );
    isCompany
      ? addCompanyDocument({
          variables: {
            data: {
              company: values?.company.split(';')[1],
              language: contents.language,
              documentUrlSource: 'SEEDED',
              documentCategory: category?.id,
              restrictLanguage,
              documentName: values?.documentName,
              documentUrl: contents?.documentLink,
              issuedDate: moment(values?.issuedDate).format(),
              ...(translatedData.length && {
                multilang: translatedData?.map(data => ({
                  documentUrl: data?.pdfFile,
                  documentName: data?.documentName,
                  documentUrlSource: data?.documentUrlSource,
                  language: data?.language,
                })),
              }),
            },
          },
        })
          .then(({data: {addCompanyDocument}}) => {
            if (addCompanyDocument.__typename === 'Error') {
              return toast.error(addCompanyDocument.message);
            } else {
              toast.success('Created Successfully');
              form.resetFields();
              onClose();
            }
          })
          .catch(error => toast.error(error))
      : addStockExchangeDocument({
          variables: {
            data: {
              documentId: contents?.id,
              documentCategory: values.documentCategory,
              stockExchange: values?.stockId,
              documentName: documentName,
              issuedDate: moment(values.issuedDate).format(),
              documentUrlSource: 'SEEDED',
              language: contents?.language,
              documentUrl: contents?.documentLink,
              restrictLanguage,
              ...(translatedData.length && {
                multilang: translatedData?.map(data => ({
                  documentUrl: data?.pdfFile,
                  documentName: data?.documentName,
                  documentUrlSource: data?.documentUrlSource,
                  language: data?.language,
                })),
              }),
            },
          },
        })
          .then(({data: {addStockExchangeDocument}}) => {
            if (addStockExchangeDocument.__typename === 'Error') {
              return toast.error(addStockExchangeDocument.message);
            } else {
              toast.success(`Created Successfully`);
              form.resetFields();
              onClose();
            }
          })
          .catch(error => toast.error(error));
  };

  if (loadingDocument) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading Document...</h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <h1>
          Add Document to {isCompany ? 'Company' : 'Stock Exchange'} Documents
        </h1>
      </div>
      <div className={styles.content}>
        <Form onFinish={handleSubmit} form={form} layout="vertical">
          {isCompany ? (
            <Form.Item
              name="company"
              className={styles.fieldWidth}
              rules={[{required: true, message: 'This field is Required'}]}
              label="Select Company">
              <Select allowClear showSearch loading={loadingCompanies}>
                {getAllCompanies?.getAllCompanies?.map(company => (
                  <Select.Option key={`${company?.name};${company?.id}`}>
                    <div className={styles['all-data']}>
                      <img src={company?.logoImgURL} alt="" />
                      <span>{company?.name}</span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              rules={[{required: true, message: 'This field is Required'}]}
              name="stockId"
              className={styles.fieldWidth}
              label="Select Stock Exchange">
              <Select allowClear showSearch loading={loading}>
                {data?.getAllStocksExchange?.map(data => (
                  <Select.Option key={data?.id}>
                    {data?.stockExchangeName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            rules={[
              {required: true, message: 'This field is Required'},
              {
                validator: (_, value) => {
                  const isValidCategory = isCompany
                    ? data?.getCompanyDocumentCategories?.data?.some(
                        item => item?.name.replace(' ', '_') === value,
                      )
                    : data?.getStockExchangeDocumentCategory?.data?.some(
                        item => item?.label === value,
                      );
                  if (!isValidCategory) {
                    return Promise.reject(
                      `This Category does not belong to the ${
                        isCompany ? 'Company' : 'Stock Exchange'
                      } Document Categories`,
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            name="documentCategory"
            className={styles.fieldWidth}
            label="Document Category">
            <Select loading={loading}>
              {isCompany
                ? data?.getCompanyDocumentCategories?.data?.map(data => (
                    <Select.Option key={data?.name?.replace(' ', '_')}>
                      {formatRoleString(data?.name)}
                    </Select.Option>
                  ))
                : data?.getStockExchangeDocumentCategory?.data?.map(data => (
                    <Select.Option key={data?.label}>
                      {formatRoleString(data?.name)}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>

          <div className={styles.date}>
            <Form.Item
              rules={[{required: true, message: 'This field is Required'}]}
              name="issuedDate"
              label="Issued Date">
              <DatePicker
                format={'YYYY-MM-DD HH:mm:ss'}
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm:ss'),
                }}
              />
            </Form.Item>
            <Form.Item name="restrictLanguage" label="Restrict Language">
              <Switch
                checked={restrictLanguage}
                onChange={e => setRestrictLanguage(e)}
              />
            </Form.Item>
          </div>

          {!restrictLanguage && (
            <Form.Item
              label="Translation Language"
              name="translationLanguage"
              className={styles.fieldWidth}>
              <Select
                value={selectedLanguage}
                allowClear
                onChange={e => setSelectedLanguage(e)}
                placeholder="Select a Language to be translated">
                {languageToBeTranslatedTo.map(name => (
                  <Select.Option key={name.key}>{name.label}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Tabs
            style={{width: 700}}
            tabBarExtraContent={
              selectedLanguage && (
                <Button
                  label={loadingTranslation ? 'Translating...' : 'Translate'}
                  disabled={loadingTranslation || !documentName}
                  onClick={handleTranslation}
                />
              )
            }>
            <Tabs.TabPane
              key="original"
              tab={`Original (${
                seededDocument?.getSeededStockExchangeDocumentById?.language ||
                contents?.language
              })`}>
              <Form.Item
                rules={[{required: true, message: 'This field is Required'}]}
                name="documentName"
                className={styles.fieldWidth}
                label="Document Name">
                <Input
                  value={documentName}
                  onChange={e => setDocumentName(e.target.value)}
                />
              </Form.Item>
            </Tabs.TabPane>
            {translatedData?.map((item, index) => (
              <Tabs.TabPane
                tab={
                  languageToBeTranslatedTo.find(
                    lang => lang?.key === item?.language,
                  )?.label
                }
                key={index}>
                <TranslatedStockExchangeDocument
                  index={index}
                  data={item}
                  setTranslatedData={setTranslatedData}
                  translatedData={translatedData}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
          <SubmitButton
            disabled={loadingCreate || loadingCreateCompany}
            label={'Create'}
          />
        </Form>
      </div>
    </div>
  );
};

export default AddDocumentModal;
