import {gql} from '@apollo/client';

export const CREATE_TRENDING_COUNTRIES = gql`
  mutation CreateTrendingCountries($input: CreateTrendingCountries!) {
    createTrendingCountries(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on TrendingCountriesType {
        id
        trendingCountries {
          id
          country {
            id
            name
            icon
          }
          companies {
            id
            name
          }
          sequence
          totalAmountReceived
          totalNumOfDeals
        }
        isTrending
        month
        year
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_TRENDING_COUNTRIES = gql`
  mutation UpdateTrendingCountries($input: UpdateTrendingCountries!) {
    updateTrendingCountries(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on TrendingCountriesType {
        id
        trendingCountries {
          country {
            id
            name
          }
          companies {
            id
            name
          }
          sequence
          totalAmountReceived
          totalNumOfDeals
        }
        month
        year
        isTrending
        createdAt
        updatedAt
      }
    }
  }
`;

export const ADD_COMPANY_TO_TRENDING_COUNTRIES = gql`
  mutation AddCompanyToTrendingCountry(
    $countryId: ID!
    $trendingCountryId: ID!
    $companies: [ID!]!
  ) {
    addCompanyToTrendingCountry(
      countryId: $countryId
      trendingCountryId: $trendingCountryId
      companies: $companies
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on TrendingCountriesType {
        id
        trendingCountries {
          country {
            id
            name
          }
          companies {
            id
            name
          }
          sequence
          totalAmountReceived
          totalNumOfDeals
          id
        }
        month
        year
        isTrending
        createdAt
        updatedAt
      }
    }
  }
`;

export const DELETE_TRENDING_COUNTRY = gql`
  mutation DeleteTrendingCountry(
    $trendingCountriesId: ID!
    $trendingCountryId: ID!
  ) {
    deleteTrendingCountry(
      trendingCountriesId: $trendingCountriesId
      trendingCountryId: $trendingCountryId
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on TrendingCountriesType {
        id
        trendingCountries {
          id
          country {
            id
            name
          }
          companies {
            id
            name
          }
          sequence
          totalAmountReceived
          totalNumOfDeals
        }
        isTrending
        month
        year
        createdAt
        updatedAt
      }
    }
  }
`;
