import {Button, Dropdown, Menu, Table} from 'antd';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './campaignTable.module.scss';
import {EllipsisOutlined} from '@ant-design/icons';
import editIcon from 'images/edit.svg';
import deleteIcon from 'images/delete.svg';
import userIcon from 'images/user.svg';
import eyesIcon from 'images/eye-opened.svg';
import checkIcon from 'images/check-icon.svg';
import {GET_ALL_FILTERED_CAMPAIGNS} from 'graphql/queries/campaign';
import {
  ADD_SETTLED_TRADES_TO_USERS_PORTFOLIO,
  DELETE_CAMPAIGN,
  UPDATE_CAMPAIGN_STATUS,
} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';
import DBModal from 'components/modal/modal';
import {useMutation} from '@apollo/client';
import {Fragment} from 'react';

const CampaignTable = ({data, active, visibleState}) => {
  const [activeData, setActiveData] = useState([]);
  const [openEndModal, setOpenEndModal] = useState(false);
  const [tempId, setTempId] = useState('');
  const [tempMode, setTempMode] = useState('');
  const navigate = useNavigate();

  const [updateCampaignStatus] = useMutation(UPDATE_CAMPAIGN_STATUS);
  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN);
  const [addSettledTradesToUsersPortfolio] = useMutation(
    ADD_SETTLED_TRADES_TO_USERS_PORTFOLIO,
  );

  useEffect(() => {
    switch (active) {
      case 'DRAFT':
        setActiveData(data.filter(data => data?.campaignStatus === 'DRAFT'));
        break;
      case 'ACTIVE':
        setActiveData(data.filter(data => data?.campaignStatus === 'ACTIVE'));
        break;
      case 'ENDED':
        setActiveData(data.filter(data => data?.campaignStatus === 'ENDED'));
        break;
    }
  }, [active]);

  const amountFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const handleUpdateCampaignStatus = (mode, id) => {
    updateCampaignStatus({
      variables: {
        updateCampaignStatusId: id,
        actionType: 'ACTIVE',
        campaignMode: mode,
      },
      refetchQueries: [
        {
          query: GET_ALL_FILTERED_CAMPAIGNS,
          variables: {
            listingStatus: active,
            isVisible: JSON.parse(visibleState),
          },
        },
      ],
    })
      .then(({data: {updateCampaignStatus}}) => {
        if (updateCampaignStatus.__typename === 'Error') {
          toast.error(updateCampaignStatus.message);
        } else {
          toast.success('Listing is Active');
        }
      })
      .catch(() => {
        return toast.error('Something went wrong, unable to update');
      });
  };

  const handleDelete = (mode, id) => {
    deleteCampaign({
      variables: {
        deleteCampaignId: id,
      },
      refetchQueries: [
        {
          query: GET_ALL_FILTERED_CAMPAIGNS,
          variables: {
            listingStatus: 'DRAFT',
            isVisible: JSON.parse(visibleState),
          },
        },
      ],
    })
      .then(({data: {deleteCampaign}}) => {
        if (deleteCampaign.__typename === 'Error') {
          toast.error(deleteCampaign.message);
        } else {
          toast.success('Listing Deleted Successfully');
        }
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const handleSettledTrades = (mode, id) => {
    addSettledTradesToUsersPortfolio({
      variables: {
        campaignId: id,
      },
    })
      .then(({data: {addSettledTradesToUsersPortfolio}}) => {
        if (addSettledTradesToUsersPortfolio.__typename === 'Error') {
          toast.error(addSettledTradesToUsersPortfolio.message);
        } else {
          toast.success('Settled Successfully');
        }
      })
      .catch(error => toast.error(error));
  };

  const handleEndCampaign = () => {
    updateCampaignStatus({
      variables: {
        updateCampaignStatusId: tempId,
        campaignMode: tempMode,
        actionType: 'ENDED',
      },
      refetchQueries: [
        {
          query: GET_ALL_FILTERED_CAMPAIGNS,
          variables: {
            listingStatus: active,
            isVisible: JSON.parse(visibleState),
          },
        },
      ],
    })
      .then(({data: {updateCampaignStatus}}) => {
        if (updateCampaignStatus.__typename === 'Error') {
          toast.error(updateCampaignStatus.message);
        } else {
          toast.success('Ended Successfully');
          setOpenEndModal(false);
        }
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const handleSetTestToLive = id => {
    updateCampaignStatus({
      variables: {
        updateCampaignStatusId: id,
        campaignMode: 'LIVE',
        actionType: 'ACTIVE',
      },
      refetchQueries: [
        {
          query: GET_ALL_FILTERED_CAMPAIGNS,
          variables: {
            listingStatus: active,
            isVisible: JSON.parse(visibleState),
          },
        },
      ],
    })
      .then(({data: {updateCampaignStatus}}) => {
        if (updateCampaignStatus.__typename === 'Error') {
          toast.error(updateCampaignStatus.message);
        } else {
          toast.success('Listing is now Live!!!');
          setOpenEndModal(false);
        }
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const handleUpdate = (mode, campaignId) =>
    navigate(`/dashboard/campaign/language-version/${campaignId}`);

  const handleModal = (id, mode) => {
    setTempId(id);
    setTempMode(mode);
    setOpenEndModal(true);
  };

  const draftMenu = (mode, id) => {
    return (
      <Menu>
        <Menu.Item key="1" onClick={() => handleUpdateCampaignStatus(mode, id)}>
          <div className={styles['menu-items']}>
            <img src={checkIcon} alt="" />
            <span>Set Active</span>
          </div>
        </Menu.Item>
        <Menu.Item key="2" onClick={() => handleUpdate(mode, id)}>
          <div className={styles['menu-items']}>
            <img src={editIcon} alt="" />
            <span>Edit Campaign</span>
          </div>
        </Menu.Item>
        <Menu.Item key="3" onClick={() => handleDelete(mode, id)}>
          <div className={styles['menu-items']}>
            <img src={deleteIcon} alt="" />
            <span>Delete Campaign</span>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const endedMenu = (mode, id, offeringId, fundraising) => {
    return (
      <Menu>
        <Menu.Item key="1" onClick={() => handleUpdate(mode, id)}>
          <div className={styles['menu-items']}>
            <img src={editIcon} alt="" />
            <span>Preview Campaign</span>
          </div>
        </Menu.Item>
        {mode === 'LIVE' ? (
          <>
            <Menu.Item
              key="2"
              onClick={() =>
                navigate(
                  `/dashboard/campaign/offering-transactions/${offeringId}/${fundraising?.companyId}/${id}`,
                )
              }>
              <div className={styles['menu-items']}>
                <img src={eyesIcon} alt="" />
                <span>View Transactions</span>
              </div>
            </Menu.Item>
            <Menu.Item key="3" onClick={() => handleSettledTrades(mode, id)}>
              <div className={styles['menu-items']}>
                <img src={userIcon} alt="" />
                <span>Settle Users Trades</span>
              </div>
            </Menu.Item>
          </>
        ) : (
          <Fragment>
            <Menu.Item
              key="4"
              onClick={() =>
                navigate(
                  `/dashboard/campaign/users-interested/${id}/${fundraising?.companyId}`,
                )
              }>
              <div className={styles['menu-items']}>
                <img src={eyesIcon} alt="" />
                <span>View Interested Users</span>
              </div>
            </Menu.Item>
          </Fragment>
        )}
        <Menu.Item key="5" onClick={() => handleSetTestToLive(mode, id)}>
          <div className={styles['menu-items']}>
            <img src={eyesIcon} alt="" />
            <span>Go Live</span>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const menu = (mode, id, offeringId, fundraising) => {
    return (
      <Menu>
        <Menu.Item key="1" onClick={() => handleUpdate(mode, id)}>
          <div className={styles['menu-items']}>
            <img src={editIcon} alt="" />
            <span>Edit Campaign</span>
          </div>
        </Menu.Item>

        {mode === 'LIVE' ? (
          <>
            <Menu.Item
              key="2"
              onClick={() =>
                navigate(
                  `/dashboard/campaign/offering-transactions/${offeringId}/${fundraising?.companyId}/${id}`,
                )
              }>
              <div className={styles['menu-items']}>
                <img src={eyesIcon} alt="" />
                <span>View Transactions</span>
              </div>
            </Menu.Item>
          </>
        ) : (
          <Menu.Item
            key="3"
            onClick={() =>
              navigate(
                `/dashboard/campaign/users-interested/${id}/${fundraising?.companyId}`,
              )
            }>
            <div className={styles['menu-items']}>
              <img src={eyesIcon} alt="" />
              <span>View Interested Users</span>
            </div>
          </Menu.Item>
        )}
        <Menu.Item key="4" onClick={() => handleModal(id, mode)}>
          <div className={styles['menu-items']}>
            <img src={deleteIcon} alt="" />
            <span>End Campaign</span>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <img src={record?.fundraisingCompany?.logoImgURL} alt="" />
          <h4 style={{width: 100}}>
            {record?.fundraisingCompany?.companyName}
            {record?.listingType === 'IPO' &&
              ` - ${record?.ipoFields?.offeringName}`}
            {record?.listingType === 'VENTURE_FUND' &&
              ` - ${record?.ventureFundFields?.offeringName}`}
          </h4>
        </div>
      ),
    },
    {
      title: 'Stage',
      dataIndex: 'stage',
      key: 'stage',
    },
    {
      title: 'Type',
      dataIndex: 'listingType',
      key: 'listingType',
    },
    {
      title: 'Mode',
      dataIndex: 'campaignMode',
      key: 'campaignMode',
    },
    {
      title: 'Offering Type',
      dataIndex: 'offeringType',
      key: 'offeringType',
    },
    {
      title: 'Funding Target',
      dataIndex: 'target Amount',
      key: 'amount',
      render: (text, record) => (
        <span>
          {amountFormatter.format(record?.investmentTerms?.fundingTarget)}
        </span>
      ),
    },

    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <Dropdown
          overlay={
            active === 'DRAFT'
              ? draftMenu(record?.campaignMode, record.id)
              : active === 'ENDED'
              ? endedMenu(
                  record?.campaignMode,
                  record.id,
                  record?.offeringId,
                  record?.fundraisingCompany,
                )
              : menu(
                  record?.campaignMode,
                  record.id,
                  record?.offeringId,
                  record?.fundraisingCompany,
                )
          }
          placement="bottomLeft">
          <div className={styles['select-btn']}>
            <EllipsisOutlined />
          </div>
        </Dropdown>
      ),
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={activeData} />
      <DBModal
        isOpen={openEndModal}
        handleClose={() => setOpenEndModal(false)}
        content={
          <div className={styles['modal-content']}>
            <h1>Are you sure you want to End this Campaign?</h1>
            <div>
              <Button
                className={styles.button}
                onClick={handleEndCampaign}
                label="Yes"
              />
              <Button
                className={styles.button}
                type="secondary"
                onClick={() => setOpenEndModal(false)}
                label="No"
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default CampaignTable;
