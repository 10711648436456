import {Route, Routes} from 'react-router-dom';
import AddAppleSku from '../appleSku/AddAppleSku';
import CreatePrice from '../new/CreatePlan';
import CreateProduct from '../new/createProduct';
import SubscriptionSetting from '../subscriptionSetting/SubscriptionSetting';
import UpdateProduct from '../update/UpdateProduct';
import AdminPlanUsers from '../users';
import ExpiredPlan from '../users/expired/ExpiredPlan';
import FreePlan from '../users/free/FreePlan';
import PremiumPlan from '../users/premium/PremiumPlan';
import StartersUserPlan from '../users/starter/StartersUserPlan';
import FreeTrial from '../users/trial/FreeTrial';

const PlanRoutes = () => {
  return (
    <Routes>
      <Route path="/create-product" element={<CreateProduct />} />
      <Route path="/create-plan" element={<CreatePrice />} />
      <Route path="/add-apple-sku" element={<AddAppleSku />} />
      <Route path="/subscription-setting" element={<SubscriptionSetting />} />
      <Route path="/update-product/:id" element={<UpdateProduct />} />
      <Route path="/users" element={<AdminPlanUsers />}>
        <Route path="/users" element={<StartersUserPlan />} />
        <Route path="/users/premium" element={<PremiumPlan />} />
        <Route path="/users/free" element={<FreePlan />} />
        <Route path="/users/trial" element={<FreeTrial />} />
        <Route path="expired" element={<ExpiredPlan />} />
      </Route>
    </Routes>
  );
};

export default PlanRoutes;
