import {useEffect, useState} from 'react';
import styles from './company/companyDocCategory.module.scss';

import CompanyDocument from './company/CompanyDocument';
import SeededDocumentsTable from './SeededDocumentsTable';
import StockListingDocuments from 'pages/adminOpportunities/stocksListings/documents/StockListingDocuments';
import {useLocation} from 'react-router-dom';

const DocumentsCenter = () => {
  const [activeTab, setActiveTab] = useState('COMPANY');
  const route = useLocation();

  const searchParamValue = new URLSearchParams(route.search).get('route');

  useEffect(() => {
    if (searchParamValue === 'stocks') {
      setActiveTab('STOCKS');
    }
  }, [searchParamValue]);

  return (
    <div className={styles['container-wrapper']}>
      <div className={styles['header-container']}>
        <h1>Documents Center</h1>
      </div>
      <div className={styles['details-toggler']}>
        <span
          className={activeTab === 'COMPANY' ? styles['active-toggle'] : ''}
          onClick={() => setActiveTab('COMPANY')}>
          COMPANY
        </span>
        <span
          className={activeTab === 'STOCKS' ? styles['active-toggle'] : ''}
          onClick={() => setActiveTab('STOCKS')}>
          STOCK EXCHANGE
        </span>
        <span
          className={activeTab === 'SEEDED' ? styles['active-toggle'] : ''}
          onClick={() => setActiveTab('SEEDED')}>
          SEEDED BRVM DOCUMENTS
        </span>
      </div>
      {activeTab === 'COMPANY' && <CompanyDocument />}
      {activeTab === 'SEEDED' && <SeededDocumentsTable />}
      {activeTab === 'STOCKS' && <StockListingDocuments />}
    </div>
  );
};

export {DocumentsCenter};
