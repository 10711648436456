import {Dropdown, Table} from 'antd';
import styles from './affiliateConfiguration.module.scss';
import {useMutation, useQuery} from '@apollo/client';
import {
  DELETE_AFFILIATE_CONFIG,
  GET_ALL_AFFILIATE_CONFIG,
} from 'graphql/queries/affiliates';
import {useState} from 'react';
import DBModal from 'components/modal/modal';
import dotsIcon from 'images/dots.svg';
import AffiliateConfiguration from './AffiliateConfiguration';
import {Button} from '@dabafinance/react-components';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {toast} from 'react-toastify';

const AffiliateConfigurationDashboard = () => {
  const [openModal, setOpenModal] = useState({isOpen: false});
  const [deleteModal, setDeleteModal] = useState({});
  const {data, loading, refetch} = useQuery(GET_ALL_AFFILIATE_CONFIG, {
    variables: {
      filter: {
        page: 1,
        limit: 100,
      },
    },
  });
  const [deleteAffiliateConfig, {loading: loadingDelete}] = useMutation(
    DELETE_AFFILIATE_CONFIG,
  );

  const handleDeleteConfig = () => {
    deleteAffiliateConfig({
      variables: {
        deleteAffiliateConfigId: deleteModal.id,
      },
      onError: error => toast.error(error.message),
    })
      .then(({data: {deleteAffiliateConfig}}) => {
        if (deleteAffiliateConfig.__typename === 'Error') {
          toast.error(deleteAffiliateConfig.message);
        } else {
          refetch();
          setDeleteModal(false);
          toast.success('Deleted Successfully.');
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  const columns = [
    {
      dataIndex: 'Name',
      key: 'Name',
      title: 'Name',
      render: (value, record) => (
        <span>
          {record?.isDefault
            ? 'DEFAULT CONFIGURATION'
            : record?.affiliate?.name}
        </span>
      ),
    },
    {
      dataIndex: 'language',
      key: 'language',
      title: 'Base Language',
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: 'Actions',
      render: (text, record) => (
        <Dropdown
          trigger="click"
          menu={{
            items: [
              {
                label: 'Update',
                onClick: () => setOpenModal({isOpen: true, content: record}),
                key: 1,
              },
              {
                label: 'Delete',
                onClick: () => setDeleteModal({isOpen: true, id: record?.id}),
                key: 2,
              },
            ],
          }}>
          <img src={dotsIcon} alt="" />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.affiliateConfig}>
      <div>
        <h1>Affiliate Configurations</h1>
        <div className={styles.createBtn}>
          <Button
            label="Create Affiliate Configuration"
            onClick={() => setOpenModal({isOpen: true, type: 'CREATE'})}
          />
        </div>
      </div>
      <div>
        <Table
          columns={columns}
          loading={loading}
          dataSource={data?.getAllAffiliateConfig?.data}
          rowClassName={record => {
            return record?.isDefault ? styles['default-config-row'] : '';
          }}
        />
      </div>
      <DBModal
        isOpen={openModal.isOpen}
        handleClose={() => setOpenModal({isOpen: false, content: {}})}
        width={1000}
        content={
          <div className={styles.configModal}>
            <AffiliateConfiguration
              content={openModal.content}
              type={openModal.type}
              closeModal={() => setOpenModal(false)}
            />
          </div>
        }
      />
      <ConfirmModal
        loading={loadingDelete}
        openModal={deleteModal?.isOpen}
        setOpenModal={setDeleteModal}
        title="Delete this Affiliate Configuration"
        confirmText={'Are you sure you want to carry out this action?'}
        handleOperation={handleDeleteConfig}
      />
    </div>
  );
};

export default AffiliateConfigurationDashboard;
