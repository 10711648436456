import {Button} from '@dabafinance/react-components';
import {DatePicker, Form, Select, Switch, Table} from 'antd';
import {useState} from 'react';
import styles from './notifications.module.scss';
import {useNavigate} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {
  GET_SCHEDULED_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS,
} from 'graphql/mutations/notifications';
import moment from 'moment';
import {toast} from 'react-toastify';
import DBModal from 'components/modal/modal';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';
import {getUserTimezone} from 'utils/helper';

const Notifications = () => {
  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setActiveTab] = useState('PENDING');
  const [activeStatus, setActiveStatus] = useState('ACTIVE');
  const [scheduledStatus, setScheduledStatus] = useState('PENDING');
  const [notificationId, setNotificationId] = useState('');
  const [date, setDate] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const navigate = useNavigate();
  // const [timezone, setTimezone] = useState('');

  const [updatedScheduledNotifications, {loading: loadingUpdate}] =
    useMutation(UPDATE_NOTIFICATIONS);
  const {loading, data, refetch} = useQuery(GET_SCHEDULED_NOTIFICATIONS, {
    variables: {
      data: {
        isActive: activeStatus,
        isScheduled: true,
        status: scheduledStatus,
      },
    },
  });

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.NotificationSchedule),
  );

  const handleUpdateActiveStatus = (id, activeStatus) => {
    updatedScheduledNotifications({
      variables: {
        filters: {
          notificationId: id,
          isActive: !activeStatus ? 'ACTIVE' : 'INACTIVE',
        },
      },
      refetchQueries: [
        {
          query: GET_SCHEDULED_NOTIFICATIONS,
          variables: {
            data: {
              isActive: !activeStatus ? 'INACTIVE' : 'ACTIVE',
              isScheduled: true,
              status: scheduledStatus,
            },
          },
        },
      ],
    })
      .then(({data: {updatedScheduledNotifications}}) => {
        if (updatedScheduledNotifications.__typename === 'Error') {
          toast.error(updatedScheduledNotifications.message);
        } else {
          toast.success('Status Updated Successfully');
        }
      })
      .catch(err => toast.error(err));
  };

  const handleOpenModal = (event, id) => {
    if (event.target.isConnected) {
      setNotificationId(id);
      setOpenModal(true);
    }
  };

  const handleUpdateNotificationStatus = () => {
    const statusFields = {};
    if (date) statusFields.date = new Date(date).toISOString();
    if (notificationStatus) statusFields.status = notificationStatus;

    updatedScheduledNotifications({
      variables: {
        filters: {
          notificationId,
          ...statusFields,
        },
      },
      refetchQueries: [
        {
          query: GET_SCHEDULED_NOTIFICATIONS,
          variables: {
            data: {
              isActive: activeStatus,
              isScheduled: true,
              status: scheduledStatus,
            },
          },
        },
      ],
    })
      .then(({data: {updatedScheduledNotifications}}) => {
        if (updatedScheduledNotifications.__typename === 'Error') {
          toast.error(updatedScheduledNotifications.message);
        } else {
          setNotificationStatus('');
          setDate('');
          setOpenModal(false);
          toast.success('Status Updated Successfully');
        }
      })
      .catch(err => toast.error(err));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (action, record) => (
        <div>
          {record?.category.map(name => (
            // eslint-disable-next-line react/jsx-key
            <span style={{marginRight: 5}} key={name}>
              {name}
            </span>
          ))}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'no',
    },
    {
      title: 'Scheduled Date',
      dataIndex: 'time',
      key: 'createdAt',
      render: (action, record) => (
        <div>
          <span>
            {moment(record.schedule.date).format('LLL')} ({getUserTimezone()})
          </span>
        </div>
      ),
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (action, record) => (
        <Switch
          disabled={!tabOperations?.update}
          onClick={() => handleUpdateActiveStatus(record?.id, record?.isActive)}
          defaultChecked={record.isActive}
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <Button
          label="Update"
          onClick={e => handleOpenModal(e, record.id)}
          type="secondary"
        />
      ),
    },
  ];

  const handleSwitchTab = (tab, status, schedule) => {
    setActiveTab(tab);
    setActiveStatus(status);
    setScheduledStatus(schedule);
    refetch({
      data: {
        isActive: status,
        isScheduled: true,
        status: schedule,
      },
    });
  };

  return (
    <>
      <div className={styles.header}>
        <h1>Notifications</h1>
        <Button
          label="Add New Notification"
          className={styles['button-style']}
          disabled={!tabOperations?.create}
          onClick={() => navigate('/dashboard/notifications/new')}
        />
      </div>
      <div className={styles['details-toggler']}>
        <span
          className={activeTab === 'PENDING' ? styles['active-toggle'] : ''}
          onClick={() => handleSwitchTab('PENDING', 'ACTIVE', 'PENDING')}>
          {' '}
          Scheduled
        </span>
        <span
          className={activeTab === 'INACTIVE' ? styles['active-toggle'] : ''}
          onClick={() => handleSwitchTab('INACTIVE', 'INACTIVE', 'PENDING')}>
          {' '}
          Inactive (Pending)
        </span>
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data?.getAllNotifications}
      />
      <DBModal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        content={
          <div>
            <h1>Update Time and Status</h1>
            <Form
              className={styles.form}
              layout="vertical"
              onFinish={handleUpdateNotificationStatus}>
              <Form.Item name="date" label="Date">
                <DatePicker
                  className={styles.date}
                  name="Date"
                  format={'YYYY-MM-DD HH:mm:ss'}
                  showTime={{
                    defaultValue: moment('00:00', 'HH:mm:ss'),
                  }}
                  value={date}
                  onChange={e =>
                    setDate(moment(e).format('YYYY-MM-DD HH:mm:ss'))
                  }
                />
              </Form.Item>
              <Form.Item name="status" label="Notification Status">
                <Select
                  value={notificationStatus}
                  onChange={e => setNotificationStatus(e)}
                  style={{width: 250}}
                  name="status"
                  placeholder="Notification Status">
                  <Select.Option key="SENT">Sent</Select.Option>
                  <Select.Option key="PENDING">Pending</Select.Option>
                  <Select.Option key="FAILED">Cancel</Select.Option>
                </Select>
              </Form.Item>
              <SubmitButton
                label="Update"
                disabled={loadingUpdate || !tabOperations?.update}
              />
            </Form>
          </div>
        }
      />
    </>
  );
};

export {Notifications};
