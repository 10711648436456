import {Form, Input, InputNumber, Select, Spin, Switch} from 'antd';
import styles from './createWalletCurrency.module.scss';
import {useEffect, useState} from 'react';
import backIcon from 'images/back.png';
import {useNavigate, useParams} from 'react-router-dom';
import {enabledPaymentMethod, walletCurrencies} from 'utils/mock';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useMutation, useQuery} from '@apollo/client';
import {UPDATE_WALLET_CURRENCY_SETTINGS} from 'graphql/mutations/wallet';
import {toast} from 'react-toastify';
import {GET_PLATFORM_WALLET_CURRENCY_BY_NAME} from 'graphql/queries/wallet';
import {
  generateDailyLimitValidator,
  generateMonthlyLimitValidator,
  generateWeeklyLimitValidator,
} from '../actions/MutationRequests';

const CreateWalletCurrency = () => {
  const [currencyName, setCurrencyName] = useState('');
  const [enableCurrency, setEnableCurrency] = useState('');
  const [currencySymbol, setCurrencySymbol] = useState(true);
  const [enableDeposit, setEnableDeposit] = useState(true);
  const [enableWithdrawal, setEnableWithdrawal] = useState(true);
  const [depositPaymentMethods, setDepositPaymentMethods] = useState([]);
  const [withdrawalPaymentMethods, setWithdrawalPaymentMethods] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [dailyDepositLimit, setDailyDepositLimit] = useState(0);
  const [weeklyDepositLimit, setWeeklyDepositLimit] = useState(0);
  const [monthlyDepositLimit, setMonthlyDepositLimit] = useState(0);
  const [dailyWithdrawalLimit, setDailyWithdrawalLimit] = useState(0);
  const [weeklyWithdrawalLimit, setWeeklyWithdrawalLimit] = useState(0);
  const [monthlyWithdrawalLimit, setMonthlyWithdrawalLimit] = useState(0);
  const [displayCurrency, setDisplayCurrency] = useState(false);
  const navigate = useNavigate();
  const width = 300;
  const {currency} = useParams();

  const {data, loading} = useQuery(GET_PLATFORM_WALLET_CURRENCY_BY_NAME, {
    variables: {
      currency,
    },
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache',
  });
  const [updateWalletCurrencySettings, {loading: loadingUpdate}] = useMutation(
    UPDATE_WALLET_CURRENCY_SETTINGS,
  );

  // DEPOSIT LIMIT VALIDATIONS
  const validateDepositDailyLimit = generateDailyLimitValidator(
    weeklyDepositLimit,
    monthlyDepositLimit,
  );
  const validateDepositWeeklyLimit = generateWeeklyLimitValidator(
    dailyDepositLimit,
    monthlyDepositLimit,
  );
  const validateDepositMonthlyLimit = generateMonthlyLimitValidator(
    dailyDepositLimit,
    weeklyDepositLimit,
  );

  // WITHDRAWAL LIMIT VALIDATIONS
  const validateWithdrawalDailyLimit = generateDailyLimitValidator(
    weeklyWithdrawalLimit,
    monthlyWithdrawalLimit,
  );
  const validateWithdrawalWeeklyLimit = generateWeeklyLimitValidator(
    dailyWithdrawalLimit,
    monthlyWithdrawalLimit,
  );
  const validateWithdrawalMonthlyLimit = generateMonthlyLimitValidator(
    dailyWithdrawalLimit,
    weeklyWithdrawalLimit,
  );

  useEffect(() => {
    if (currency) {
      setCurrencyName(data?.getSupportedCurrencyByCurrencyName?.currencyName);
      setEnableCurrency(data?.getSupportedCurrencyByCurrencyName?.enabled);
      setDisplayCurrency(
        data?.getSupportedCurrencyByCurrencyName?.displayCurrency?.enabled,
      );
      setCurrencySymbol(
        data?.getSupportedCurrencyByCurrencyName?.currencySymbol,
      );
      setDepositPaymentMethods(
        data?.getSupportedCurrencyByCurrencyName?.deposit
          ?.enabledPaymentMethods,
      );
      setWithdrawalPaymentMethods(
        data?.getSupportedCurrencyByCurrencyName?.withdrawal
          ?.enabledPaymentMethods,
      );
      setSelectedCurrency(data?.getSupportedCurrencyByCurrencyName?.currency);
      setWeeklyDepositLimit(
        data?.getSupportedCurrencyByCurrencyName?.deposit?.limit?.weekly,
      );
      setDailyDepositLimit(
        data?.getSupportedCurrencyByCurrencyName?.deposit?.limit?.daily,
      );
      setMonthlyDepositLimit(
        data?.getSupportedCurrencyByCurrencyName?.deposit?.limit?.monthly,
      );
      setMonthlyWithdrawalLimit(
        data?.getSupportedCurrencyByCurrencyName?.withdrawal?.limit?.monthly,
      );
      setDailyWithdrawalLimit(
        data?.getSupportedCurrencyByCurrencyName?.withdrawal?.limit?.daily,
      );
      setWeeklyWithdrawalLimit(
        data?.getSupportedCurrencyByCurrencyName?.withdrawal?.limit?.weekly,
      );
    }
  }, [currency, data]);

  const handleFinish = () => {
    updateWalletCurrencySettings({
      variables: {
        input: {
          currency: selectedCurrency,
          currencyName,
          currencySymbol,
          enabled: enableCurrency,
          displayCurrency: {
            enabled: displayCurrency,
          },
          deposit: {
            enabled: enableDeposit,
            enabledPaymentMethods: depositPaymentMethods,
            limit: {
              daily: Number(dailyDepositLimit),
              weekly: Number(weeklyDepositLimit),
              monthly: Number(monthlyDepositLimit),
            },
          },
          withdrawal: {
            enabled: enableWithdrawal,
            enabledPaymentMethods: withdrawalPaymentMethods,
            limit: {
              daily: Number(dailyWithdrawalLimit),
              weekly: Number(weeklyWithdrawalLimit),
              monthly: Number(monthlyWithdrawalLimit),
            },
          },
        },
      },
    })
      .then(({data: {updatePlatformWalletCurrencySettings}}) => {
        if (updatePlatformWalletCurrencySettings.__typename === 'Error') {
          toast.error(updatePlatformWalletCurrencySettings.message);
        } else {
          toast.success(`${selectedCurrency} Deposit Updated Successfully.`);
          navigate(-1);
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  if (loading && currency) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading</h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backIcon} />
        <h1>Create Platform Wallet Currency</h1>
      </div>
      <Form onFinish={handleFinish} className={styles.form} layout="vertical">
        <div className={styles.display}>
          <Form.Item name="enableCurrency" label="Enable Currency">
            <Switch
              value={enableCurrency}
              onChange={e => setEnableCurrency(e)}
              defaultChecked={data?.getSupportedCurrencyByCurrencyName?.enabled}
            />
          </Form.Item>
          <Form.Item name="displayCurrency" label="Display Currency">
            <Switch
              value={displayCurrency}
              onChange={e => setDisplayCurrency(e)}
              defaultChecked={
                data?.getSupportedCurrencyByCurrencyName?.displayCurrency
                  ?.enabled
              }
            />
          </Form.Item>
        </div>
        <Form.Item
          rules={[{required: true, message: 'This Field is Required'}]}
          name="currency"
          className={styles.width}
          initialValue={data?.getSupportedCurrencyByCurrencyName?.currency}
          label="Select Currency">
          <Select
            value={selectedCurrency}
            defaultValue={data?.getSupportedCurrencyByCurrencyName?.currency}
            placeholder="Please Select a currency"
            onChange={e => setSelectedCurrency(e)}>
            {walletCurrencies.map(data => (
              <Select.Option key={data.key}>{data.key}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className={styles.width}
          rules={[{required: true, message: 'This Field is Required'}]}
          name="currencyName"
          initialValue={data?.getSupportedCurrencyByCurrencyName?.currencyName}
          label="Currency Name">
          <Input
            defaultValue={
              data?.getSupportedCurrencyByCurrencyName?.currencyName
            }
            value={currencyName}
            placeholder="Example - US Dollars"
            onChange={e => setCurrencyName(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: 'This Field is Required'}]}
          name="currencySymbol"
          className={styles.width}
          initialValue={
            data?.getSupportedCurrencyByCurrencyName?.currencySymbol
          }
          label="Currency Symbol">
          <Input
            defaultValue={
              data?.getSupportedCurrencyByCurrencyName?.currencySymbol
            }
            value={currencySymbol}
            placeholder="Example: $"
            onChange={e => setCurrencySymbol(e.target.value)}
          />
        </Form.Item>

        <div className={styles.fees}>
          <h2>Deposit Limits & Methods</h2>
          <Form.Item
            initialValue={
              data?.getSupportedCurrencyByCurrencyName?.deposit.enabled
            }
            name="enableDeposit"
            label="Enable Deposit">
            <Switch
              value={enableDeposit}
              onChange={e => setEnableDeposit(e)}
              defaultChecked={
                data?.getSupportedCurrencyByCurrencyName?.deposit.enabled
              }
            />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'This Field is Required'}]}
            className={styles.width}
            name="depositPaymentMethod"
            initialValue={data?.getSupportedCurrencyByCurrencyName?.deposit?.enabledPaymentMethods?.map(
              data => data,
            )}
            label="Allowed Payment Methods">
            <Select
              name="depositPaymentMethod"
              mode="tags"
              defaultValue={data?.getSupportedCurrencyByCurrencyName?.deposit?.enabledPaymentMethods.map(
                data => data,
              )}
              value={depositPaymentMethods}
              onChange={e => setDepositPaymentMethods(e)}>
              {enabledPaymentMethod.map(data => (
                <Select.Option key={data.key}>{data.value}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className={styles.breakdown}>
            <Form.Item
              initialValue={
                data?.getSupportedCurrencyByCurrencyName?.deposit?.limit?.daily
              }
              name="dailyLimit"
              rules={[
                {required: true, message: 'This field is required'},
                {validator: validateDepositDailyLimit},
              ]}
              label="Daily Limit">
              <InputNumber
                value={dailyDepositLimit}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                defaultValue={
                  data?.getSupportedCurrencyByCurrencyName?.deposit?.limit
                    ?.daily
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={e => setDailyDepositLimit(e)}
                style={{width}}
              />
            </Form.Item>
            <Form.Item
              initialValue={
                data?.getSupportedCurrencyByCurrencyName?.deposit?.limit?.weekly
              }
              name="weeklyLimit"
              rules={[
                {required: true, message: 'This field is required'},
                {validator: validateDepositWeeklyLimit},
              ]}
              label="Weekly Limit">
              <InputNumber
                value={weeklyDepositLimit}
                defaultValue={
                  data?.getSupportedCurrencyByCurrencyName?.deposit?.limit
                    ?.weekly
                }
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={e => setWeeklyDepositLimit(e)}
                style={{width}}
              />
            </Form.Item>
            <Form.Item
              initialValue={
                data?.getSupportedCurrencyByCurrencyName?.deposit?.limit
                  ?.monthly
              }
              rules={[
                {required: true, message: 'This field is required'},
                {validator: validateDepositMonthlyLimit},
              ]}
              name="monthlyLimit"
              label="Monthly Limit">
              <InputNumber
                value={monthlyDepositLimit}
                defaultValue={
                  data?.getSupportedCurrencyByCurrencyName?.deposit?.limit
                    ?.monthly
                }
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={e => setMonthlyDepositLimit(e)}
                style={{width}}
              />
            </Form.Item>
          </div>
        </div>
        <div className={styles.fees}>
          <h2>Withdrawal Limits</h2>
          <Form.Item name="enableWithdrawal" label="Enable Withdrawal">
            <Switch
              value={enableWithdrawal}
              onChange={e => setEnableWithdrawal(e)}
              defaultChecked={
                data?.getSupportedCurrencyByCurrencyName?.withdrawal?.enabled
              }
            />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'This Field is Required'}]}
            className={styles.width}
            name="paymentMethod"
            initialValue={data?.getSupportedCurrencyByCurrencyName?.withdrawal?.enabledPaymentMethods?.map(
              data => data,
            )}
            label="Allowed Payment Methods">
            <Select
              name="paymentMethod"
              mode="tags"
              defaultValue={data?.getSupportedCurrencyByCurrencyName?.withdrawal?.enabledPaymentMethods.map(
                data => data,
              )}
              value={withdrawalPaymentMethods}
              onChange={e => setWithdrawalPaymentMethods(e)}>
              {enabledPaymentMethod.map(data => (
                <Select.Option key={data.key}>{data.value}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className={styles.breakdown}>
            <Form.Item
              initialValue={
                data?.getSupportedCurrencyByCurrencyName?.withdrawal?.limit
                  ?.daily
              }
              name="dailyWithdrawalLimit"
              rules={[
                {required: true, message: 'This field is required'},
                {
                  validator: validateWithdrawalDailyLimit,
                },
              ]}
              label="Daily Limit">
              <InputNumber
                value={dailyWithdrawalLimit}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                defaultValue={
                  data?.getSupportedCurrencyByCurrencyName?.withdrawal?.limit
                    ?.daily
                }
                onChange={e => setDailyWithdrawalLimit(e)}
                style={{width}}
              />
            </Form.Item>
            <Form.Item
              initialValue={
                data?.getSupportedCurrencyByCurrencyName?.withdrawal?.limit
                  ?.weekly
              }
              name="weeklyWithdrawalLimit"
              rules={[
                {required: true, message: 'This field is required'},
                {
                  validator: validateWithdrawalWeeklyLimit,
                },
              ]}
              label="Weekly Limit">
              <InputNumber
                value={weeklyWithdrawalLimit}
                defaultValue={
                  data?.getSupportedCurrencyByCurrencyName?.withdrawal?.limit
                    ?.weekly
                }
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={e => setWeeklyWithdrawalLimit(e)}
                style={{width}}
              />
            </Form.Item>
            <Form.Item
              initialValue={
                data?.getSupportedCurrencyByCurrencyName?.withdrawal?.limit
                  ?.monthly
              }
              rules={[
                {required: true, message: 'This field is required'},
                {
                  validator: validateWithdrawalMonthlyLimit,
                },
              ]}
              name="monthlyWithDrawalLimit"
              label="Monthly Limit">
              <InputNumber
                value={monthlyWithdrawalLimit}
                defaultValue={
                  data?.getSupportedCurrencyByCurrencyName?.withdrawal?.limit
                    ?.monthly
                }
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={e => setMonthlyWithdrawalLimit(e)}
                style={{width}}
              />
            </Form.Item>
          </div>
        </div>
        <SubmitButton
          disabled={loadingUpdate}
          label={`${currency ? 'Update' : 'Create'} Currency`}
        />
      </Form>
    </div>
  );
};

export default CreateWalletCurrency;
