import gql from 'graphql-tag';

export const GET_ALL_STARTUPS = gql`
  query GetAllCompanies {
    getAllCompanies {
      ... on Error {
        message
        statusCode
      }
      ... on Company {
        id
        name
        legalName
        ticker
        stage
        isTrending
        logoImgURL
        totalCapitalRaised
      }
    }
  }
`;

export const GET_ALL_COUNTRIES = gql`
  query GetAllCountries {
    getAllCountries {
      ... on Error {
        message
        statusCode
      }
      ... on Country {
        id
        name
        icon
      }
    }
  }
`;

export const GET_ALL_OPERATING_COUNTRIES = gql`
  query GetAllTopics($type: [TopicType]) {
    getAllTopics(type: $type) {
      ... on Error {
        message
        statusCode
      }
      ... on Topic {
        id
        name
        icon
      }
    }
  }
`;

export const CREATE_WEEKLY_STARTUP = gql`
  mutation CreateWeeklyStartup($data: CreateWeeklyStartupInput) {
    createWeeklyStartup(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on WeeklyStartup {
        id
      }
    }
  }
`;

export const GET_WEEKLY_STARTUPS = gql`
  query GetWeeklyStartup {
    getWeeklyStartup {
      id
      title
      description
      videoUrl
      company {
        id
        name
      }
      expirationDate
    }
  }
`;

export const DELETE_WEEKLY_STARTUP = gql`
  mutation DeleteWeeklyStartup($weeklyStartupId: ID!) {
    deleteWeeklyStartup(weeklyStartupId: $weeklyStartupId) {
      ... on Error {
        message
        statusCode
      }
      ... on WeeklyStartup {
        id
      }
    }
  }
`;

export const UPDATE_WEEKLY_STARTUP = gql`
  mutation UpdateWeeklyStartup($data: UpdateWeeklyStartupInput) {
    updateWeeklyStartup(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on WeeklyStartup {
        id
      }
    }
  }
`;

export const GET_COMPANY_BY_ADMIN = gql`
  query AdminGetCompany($companyId: ID!) {
    adminGetCompany(companyId: $companyId) {
      ... on Error {
        message
        statusCode
      }
      ... on Company {
        id
        name
        legalName
        shortDescription
        description
        website
        ticker
        numberOfEmployees
        orderExpirationLimit
        feeSchedule {
          feeName
          feeType
          fixedFeeAmount
          variableFeeAmount
        }
        fractionalShares
        tradeable
        minTradableShares
        maxTradableShares
        headquarter
        addresses {
          id
          addressLineOne
          addressLineTwo
          city
          state
          postalCode
        }
        operatingCountries {
          id
          name
          icon
        }
        multiLang {
          shortDescription
          description
          language
        }
        foundedOn
        language
        companyType
        totalCapitalRaised
        stage
        accelerator
        logoImgURL
        bannerImgURL
        countries {
          id
          name
        }
        socials {
          id
          facebookUrl
          linkedInUrl
          twitterUrl
        }

        topics {
          id
          name
        }
        industry {
          id
          name
          icon
        }
        teamMembers {
          id
          name
          role
          displayImage
          linkedinUrl
          description
          sex
          twitterUrl
          isCEO
        }
        socials {
          facebookUrl
          twitterUrl
          linkedInUrl
        }
        fundraisingHistory {
          latestValuation
          fundingHistory {
            round
            investors {
              investorName
              investedAmount
              imageUrl
            }
            fundingAmount
            fundingDate
            fundingType
            equityPercentage
            debtPercentage
            equityAmount
            debtAmount
            companyValuation
          }
        }
      }
    }
  }
`;

export const GET_A_STARTUP = gql`
  query GetCompany($companyId: ID!) {
    getCompany(companyId: $companyId) {
      ... on Company {
        id
        name
        legalName
        shortDescription
        description
        website
        ticker
        numberOfEmployees
        headquarter
        addresses {
          id
          addressLineOne
          addressLineTwo
          city
          state
          postalCode
        }
        operatingCountries {
          id
          name
          icon
        }
        multiLang {
          shortDescription
          description
          language
        }
        foundedOn
        language
        companyType
        totalCapitalRaised
        stage
        accelerator
        logoImgURL
        bannerImgURL
        countries {
          id
          name
        }
        socials {
          id
          facebookUrl
          linkedInUrl
          twitterUrl
        }

        topics {
          id
          name
        }
        industry {
          id
          name
          icon
        }
        teamMembers {
          id
          name
          role
          displayImage
          linkedinUrl
          description
          sex
          twitterUrl
          isCEO
        }
        socials {
          facebookUrl
          twitterUrl
          linkedInUrl
        }
        fundraisingHistory {
          latestValuation
          fundingHistory {
            round
            investors {
              investorName
              investedAmount
              imageUrl
            }
            fundingAmount
            fundingDate
            fundingType
            equityPercentage
            debtPercentage
            equityAmount
            debtAmount
            companyValuation
          }
        }
      }
    }
  }
`;
