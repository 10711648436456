import {Button} from '@dabafinance/react-components';
import styles from './updateSingleSavingsProduct.module.scss';

import {Form, InputNumber, TimePicker} from 'antd';
import {useEffect, useState} from 'react';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {useMutation} from '@apollo/client';
import {UPDATE_SAVINGS_PRODUCT} from 'graphql/mutations/savings';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {handleUpdateSavingsProduct} from 'pages/Savings/companies/savingsCompanyActions';
import {currencySymbols} from 'utils/mock';
import moment from 'moment';

const BalanceProductsInfo = ({data, onActiveTabChange, refetch, id}) => {
  const [formValues, setFormValues] = useState({});
  const [form] = Form.useForm();
  const [openFinishModal, setOpenFinishModal] = useState(false);

  const [updateSavingsProduct, {loading}] = useMutation(UPDATE_SAVINGS_PRODUCT);

  const currency = currencySymbols[data.config?.allowedCurrency].symbol;

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        maxAllowedBalance: data?.config?.maxAllowedBalance,
        endOfDayCutOffTime: data?.config?.endOfDayCutOffTime
          ? moment(data.config.endOfDayCutOffTime, 'HH:mm')
          : null,
      });
    }
  }, [data]);

  return (
    <div className={styles.generalRoot}>
      <h1>Balance Configurations</h1>
      <Form
        onFinish={e => {
          const cutoffTime = e.endOfDayCutOffTime; // This is a moment object
          const hour = cutoffTime ? cutoffTime.hour() : null; // Get the hour
          const minute = cutoffTime ? cutoffTime.minute() : null; // Get the minute

          setFormValues({
            config: {
              endOfDayCutOffTime: `${hour}:${minute}`,
              maxAllowedBalance: Number(e.maxAllowedBalance),
            },
          });
          setOpenFinishModal(true);
        }}
        form={form}
        layout="vertical">
        <Form.Item
          rules={[{required: true, message: 'This is a Required Field'}]}
          className={styles.field}
          name="endOfDayCutOffTime"
          label="Set End of Day Cutoff Time (UTC Timezone)">
          <TimePicker
            format="HH:mm"
            minuteStep={1}
            className={styles.timePicker}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please enter the maximum allowed balance',
            },
          ]}
          name="maxAllowedBalance"
          className={styles.field}
          label="Maximum Allowed Balance">
          <InputNumber
            min={1}
            formatter={value => {
              if (!value) return '';
              return `${currency} ${value}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ',',
              );
            }}
            parser={value =>
              value.replace(new RegExp(`\\${currency}\\s?|\\,`, 'g'), '')
            }
            className={styles.numberField}
          />
        </Form.Item>
        <div className={styles.buttons}>
          <Button
            type="secondary"
            label="Back"
            onClick={() => onActiveTabChange('INTERESTS')}
          />
          <SubmitButton smallWidth label="Proceed to Update" />
        </div>
      </Form>
      <ConfirmModal
        openModal={openFinishModal}
        title="Update Product Details"
        confirmText={'Are you sure all the details are inputed correctly?'}
        loading={loading}
        setOpenModal={() => setOpenFinishModal(false)}
        handleOperation={() =>
          handleUpdateSavingsProduct({
            formValues,
            id,
            updateSavingsProduct,
            setOpenFinishModal,
            refetch,
          })
        }
      />
    </div>
  );
};

export default BalanceProductsInfo;
