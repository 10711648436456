import {gql} from '@apollo/client';

export const GET_ALL_ACCESS_CODE = gql`
  query GetAllAccessCode($isActive: Boolean) {
    getAllAccessCode(isActive: $isActive) {
      id
      accessCode
      usedDate
      expiredDate
      isActive
      createdBy {
        id
        email
        firstName
      }
      createdAt
    }
  }
`;
