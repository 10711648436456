import {useMutation, useQuery} from '@apollo/client';
import {Form, Input, Spin, Switch} from 'antd';
import {GET_ALL_PRODUCTS, GET_PRODUCT_BY_ID} from 'graphql/queries/plans';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import plusIcon from 'images/plus-circle.png';
import styles from '../new/createPlan.module.scss';
import deleteIcon from 'images/delete.svg';
import backIcon from 'images/back.png';
import {toast} from 'react-toastify';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {CloseCircleOutlined} from '@ant-design/icons';
import {UPDATE_PRODUCT} from 'graphql/mutations/plans';

const UpdateProduct = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isOneTime, setIsOneTime] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [listItems, setListItems] = useState();
  const {id} = useParams();
  const navigate = useNavigate();
  const width = 720;

  const {data, loading} = useQuery(GET_PRODUCT_BY_ID, {
    variables: {
      stripeProductId: id,
    },
  });

  const [updateProduct, {loading: productLoader}] = useMutation(UPDATE_PRODUCT);

  const handleFeatureChange = (i, id, e) => {
    const newListValues = [...listItems];
    newListValues[i].functionalities[id][e.target.name] = e.target.value;
    setListItems(newListValues);
  };

  useEffect(() => {
    if (data?.getProductById != null && data?.getProductById != undefined) {
      setName(data?.getProductById?.name);
      setDescription(data?.getProductById?.description);
      setIsFree(data?.getProductById?.isFree);
      setIsActive(data?.getProductById?.isActive);
      setIsOneTime(data?.getProductById?.isOneTime);
      setListItems(
        data?.getProductById?.items?.map(item => ({
          title: item.title,
          functionalities: item.functionalities.map(feature => ({
            feature: feature.feature,
            isAllowed: feature.isAllowed,
          })),
        })),
      );
    }
  }, [data?.getProductById]);

  const handleAllowedChange = (i, id, e) => {
    const newListValues = [...listItems];
    newListValues[i].functionalities[id].isAllowed = e;
    setListItems(newListValues);
  };

  const handleChangeListFields = (i, e) => {
    const newListValues = [...listItems];
    newListValues[i][e.target.name] = e.target.value;
    setListItems(newListValues);
  };

  const handleAddListItems = () => {
    setListItems([
      ...listItems,
      {
        title: '',
        functionalities: [
          {
            feature: '',
            isAllowed: false,
          },
        ],
      },
    ]);
  };

  const handleDeleteList = id => {
    const filteredList = listItems.filter((item, index) => index !== id);
    setListItems(filteredList);
  };

  // helper function
  const handleAddFeatures = id => {
    const updatedListItem = listItems.map((item, index) => {
      return id === index
        ? {
            ...item,
            functionalities: [
              ...item.functionalities,
              {
                feature: '',
                isAllowed: false,
              },
            ],
          }
        : item;
    });
    setListItems(updatedListItem);
  };
  const handleRemoveFeatures = (id, featureIndex) => {
    const updatedListItem = listItems.map((item, index) => {
      return id === index
        ? {
            ...item,
            functionalities: item.functionalities.filter(
              (_, index) => index !== featureIndex,
            ),
          }
        : item;
    });
    setListItems(updatedListItem);
  };

  const hanleUpdateProduct = () => {
    updateProduct({
      variables: {
        stripeProductId: id,
        name: name.trim(),
        description: description.trim(),
        isFree,
        isActive,
        items: listItems.map(feature => ({
          title: feature.title.trim(),
          functionalities: feature.functionalities.map(f => ({
            feature: f.feature.trim(),
            isAllowed: f.isAllowed,
          })),
        })),
      },
      refetchQueries: [{query: GET_ALL_PRODUCTS}],
    })
      .then(({data: {updateProduct}}) => {
        if (updateProduct.__typename === 'Error') {
          toast.error(updateProduct.message);
        } else {
          toast.success('Updated Successfully');
          navigate('/dashboard/plans');
        }
      })
      .catch(error => toast.error(error.message));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div>
      <div className={styles['nav-header']}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>Update Product</h1>
      </div>
      <div className={styles.content}>
        <Form layout="vertical">
          <div className={styles.details}>
            <Form.Item
              rules={[
                {required: true, message: 'Please input name of product'},
              ]}
              name="name"
              style={{width}}
              label="Name">
              <Input
                placeholder="Name of Product"
                value={name}
                defaultValue={data?.getProductById?.name}
                onChange={e => setName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              style={{width}}
              rules={[
                {required: true, message: 'Product Description Required'},
              ]}
              name="description"
              label="Description">
              <Input.TextArea
                rows={4}
                defaultValue={data?.getProductById?.description}
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder="Description"
              />
            </Form.Item>
            <div>
              <Form.Item name="isFree" label="Free Product">
                <Switch
                  value={isFree}
                  defaultChecked={data?.getProductById?.isFree}
                  onChange={e => setIsFree(e)}
                />
              </Form.Item>
              <Form.Item name="isActive" label="Active">
                <Switch
                  value={isActive}
                  defaultChecked={data?.getProductById?.isActive}
                  onChange={e => setIsActive(e)}
                />
              </Form.Item>
              <Form.Item name="isOneTime" label="One Time">
                <Switch
                  value={isOneTime}
                  defaultChecked={data?.getProductById?.isOneTime}
                  onChange={e => setIsOneTime(e)}
                />
              </Form.Item>
            </div>
          </div>
          <div className={styles.functionalities}>
            <h1 className={styles.title}>Functionalities</h1>
            {listItems?.map(({title, functionalities}, index) => (
              <div className={styles.item} key={index}>
                <div className={styles['close-icon']}>
                  <div />
                  <CloseCircleOutlined
                    onClick={() => handleDeleteList(index)}
                    style={{fontSize: 20, cursor: 'pointer'}}
                  />
                </div>
                <Form.Item label="Title" style={{width: 600}}>
                  <Input
                    onChange={e => handleChangeListFields(index, e)}
                    name="title"
                    value={title || ''}
                  />
                </Form.Item>
                {functionalities.map(({feature, isAllowed}, id) => (
                  <div className={styles.features} key={id}>
                    <Form.Item
                      rules={[
                        {required: true, message: 'Feature Name Required'},
                      ]}
                      label="Feature"
                      style={{width: 500, marginRight: 30}}>
                      <Input
                        onChange={e => handleFeatureChange(index, id, e)}
                        name="feature"
                        value={feature || ''}
                      />
                    </Form.Item>
                    <Form.Item label="Allowed">
                      <Switch
                        onChange={e => handleAllowedChange(index, id, e)}
                        value={isAllowed}
                        defaultChecked={isAllowed}
                      />
                    </Form.Item>
                    <div
                      onClick={() => handleRemoveFeatures(index, id)}
                      className={styles['delete-feature']}>
                      <img src={deleteIcon} alt="" />
                    </div>
                  </div>
                ))}
                <div>
                  <button
                    className={styles['add-item']}
                    onClick={() => handleAddFeatures(index)}>
                    <img src={plusIcon} alt="add-founder" />
                    <span>Feature</span>
                  </button>
                </div>
              </div>
            ))}
            <div
              type="button"
              className={styles['add-item']}
              onClick={handleAddListItems}>
              <img src={plusIcon} alt="add-founder" />
              <span>Add Item</span>
            </div>
          </div>
          <SubmitButton
            disabled={productLoader}
            label="Update a  Product"
            handleClick={hanleUpdateProduct}
          />
        </Form>
      </div>
    </div>
  );
};

export default UpdateProduct;
