import {Form, Input, Spin} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {UploadDocumentToBucket} from '../legalDocument/uploadDocument';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import styles from './dabaScore.module.scss';
import FadeEffect from 'components/animation/fadeEffect';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {score} from 'redux/store/campaign';
import {useNavigate, useParams} from 'react-router-dom';

const DabaScore = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [reportName, setReportName] = useState('Upload PDF Report');
  const [viewPdf, setViewPdf] = useState('');
  const [businessValue, setBusinessValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [industryValue, setIndustryValue] = useState(0);
  const [marketValue, setMarketValue] = useState(0);
  const [teamValue, setTeamValue] = useState(0);
  const [strategyValue, setStrategyValue] = useState(0);
  const [acceleratorValue, setAcceleratorValue] = useState(0);
  const [riskValue, setRiskValue] = useState(0);
  const [analogusValue, setAnalogusValue] = useState(0);
  const [economicValue, setEconomicValue] = useState(0);
  const [complianceValue, setComplianceValue] = useState(0);
  const [outlookValue, setOutlookValue] = useState(0);
  const [socialValue, setSocialValue] = useState(0);
  const [dabaScore, setDabaScore] = useState(0);
  const [pdfReport, setPdfReport] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id, slug, companyId} = useParams();

  const campaign = useSelector(state => state.campaign.campaign);

  useEffect(() => {
    if (campaign?.pdfReport != undefined) {
      setPdfReport(campaign?.pdfReport);
      setDabaScore(campaign?.dabaScore);
      setReportName(campaign?.reportName);
      setBusinessValue(campaign?.businessValue);
      setIndustryValue(campaign?.industryValue);
      setMarketValue(campaign?.marketValue);
      setTeamValue(campaign?.teamValue);
      setStrategyValue(campaign?.strategyValue);
      setAcceleratorValue(campaign?.acceleratorValue);
      setRiskValue(campaign?.riskValue);
      setAnalogusValue(campaign?.analogusValue);
      setEconomicValue(campaign?.economicValue);
      setComplianceValue(campaign?.complianceValue);
      setOutlookValue(campaign?.outlookValue);
      setSocialValue(campaign?.socialValue);
    }
  }, [campaign?.pdfReport]);

  useEffect(() => {
    setLoading(!pdfReport);
  }, [pdfReport]);

  const handlePdfFileChange = async e => {
    const selectedFile = e.target.files[0];
    const fileType = ['application/pdf'];
    setReportName(selectedFile.name);
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        // Upload file to S3 bucket
        setLoading(true);
        setPdfLoading(true);
        const res = await UploadDocumentToBucket(selectedFile);
        setPdfReport(res);
        setLoading(false);
        setPdfLoading(false);
      } else {
        setPdfReport(null);
        toast.error('Please select a valid pdf file');
      }
    } else {
      toast.error('Select a PDF file');
    }
  };

  const handleDeleteFile = () => {
    setPdfReport(null);
    setReportName('Supported formats pdf, docs');
    setViewPdf(null);
  };

  const handlePdfPreview = e => {
    e.preventDefault();
    if (pdfReport !== null) {
      setViewPdf(pdfReport);
    } else {
      setViewPdf(null);
    }
  };

  const handleSave = () => {
    dispatch(
      score({
        dabaScore,
        businessValue,
        industryValue,
        marketValue,
        teamValue,
        strategyValue,
        acceleratorValue,
        reportName,
        riskValue,
        analogusValue,
        economicValue,
        complianceValue,
        outlookValue,
        socialValue,
        pdfReport,
      }),
    );
    if (slug) {
      navigate(
        `/dashboard/campaigns/${slug}/new/${id}/investment/${companyId}`,
      );
    } else {
      navigate(`/dashboard/campaigns/new/${id}/allocation/${companyId}`);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>daba score: {dabaScore}</h1>
        <span>
          Enter the values for the parameters below and click calculate and the
          organzations daba score would be calculated automatically
        </span>
      </div>
      <Form onFinish={handleSave} layout="vertical">
        <div className={styles.form}>
          <Form.Item
            style={{width: 386}}
            name="daba score"
            label="Daba Score"
            rules={[{required: true, message: 'Required Field'}]}
            initialValue={campaign?.dabaScore}>
            <Input
              value={dabaScore}
              onChange={e => setDabaScore(e.target.value)}
              type="number"
              defaultValue={campaign?.dabaScore}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'Required Field'}]}
            style={{width: 386}}
            name="business"
            label="Business"
            initialValue={campaign?.businessValue}>
            <Input
              value={businessValue}
              onChange={e => setBusinessValue(e.target.value)}
              type="number"
              defaultValue={campaign?.businessValue}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'Required Field'}]}
            style={{width: 386}}
            name="Industry"
            label="Industry"
            initialValue={campaign?.industryValue}>
            <Input
              type="number"
              value={industryValue}
              defaultValue={campaign?.industryValue}
              onChange={e => setIndustryValue(e.target.value)}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'Required Field'}]}
            style={{width: 386}}
            name="Market"
            label="Market"
            initialValue={campaign?.marketValue}>
            <Input
              value={marketValue}
              onChange={e => setMarketValue(e.target.value)}
              type="number"
              defaultValue={campaign?.marketValue}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'Required Field'}]}
            style={{width: 386}}
            name="Team"
            label="Team"
            initialValue={campaign?.teamValue}>
            <Input
              value={teamValue}
              defaultValue={campaign?.teamValue}
              onChange={e => setTeamValue(e.target.value)}
              type="number"
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            style={{width: 386}}
            name="Strategy, Product GTM"
            label="Strategy, Product GTM"
            rules={[{required: true, message: 'Required Field'}]}
            initialValue={campaign?.strategyValue}>
            <Input
              type="number"
              defaultValue={campaign?.strategyValue}
              value={strategyValue}
              onChange={e => setStrategyValue(e.target.value)}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            style={{width: 386}}
            name="Accelerator"
            label="Accelerator"
            rules={[{required: true, message: 'Required Field'}]}
            initialValue={campaign?.acceleratorValue}>
            <Input
              value={acceleratorValue}
              onChange={e => setAcceleratorValue(e.target.value)}
              type="number"
              defaultValue={campaign?.acceleratorValue}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            style={{width: 386}}
            name="Risks + Challenges"
            label="Risks + Challenges"
            rules={[{required: true, message: 'Required Field'}]}
            initialValue={campaign?.riskValue}>
            <Input
              value={riskValue}
              onChange={e => setRiskValue(e.target.value)}
              type="number"
              defaultValue={campaign?.riskValue}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'Required Field'}]}
            style={{width: 386}}
            name="Analogue"
            label="Analogue"
            initialValue={campaign?.analogusValue}>
            <Input
              value={analogusValue}
              onChange={e => setAnalogusValue(e.target.value)}
              defaultValue={campaign?.analogusValue}
              type="number"
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'Required Field'}]}
            style={{width: 386}}
            name="Economics / Financials"
            label="Economics / Financials"
            initialValue={campaign?.economicValue}>
            <Input
              value={economicValue}
              onChange={e => setEconomicValue(e.target.value)}
              defaultValue={campaign?.economicValue}
              type="number"
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'Required Field'}]}
            style={{width: 386}}
            name="Legal & Compliance"
            label="Legal & Compliance"
            initialValue={campaign?.complianceValue}>
            <Input
              value={complianceValue}
              onChange={e => setComplianceValue(e.target.value)}
              type="number"
              placeholder="Enter number"
              defaultValue={campaign?.complianceValue}
            />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'Required Field'}]}
            style={{width: 386}}
            name="Returns Outlook"
            label="Returns Outlook"
            initialValue={campaign?.outlookValue}>
            <Input
              value={outlookValue}
              defaultValue={campaign?.outlookValue}
              onChange={e => setOutlookValue(e.target.value)}
              type="number"
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'Required Field'}]}
            style={{width: 386}}
            name="Social Impact"
            label="Social Impact"
            initialValue={campaign?.socialValue}>
            <Input
              value={socialValue}
              defaultValue={campaign?.socialValue}
              onChange={e => setSocialValue(e.target.value)}
              type="number"
              placeholder="Enter number"
            />
          </Form.Item>
        </div>
        <div>
          <h1 className={styles['pdf-title']}>Full Report PDF</h1>
          {!viewPdf && (
            <div className={styles.button}>
              <SubmitButton
                disabled={pdfReport === ''}
                label="Preview Pdf"
                handleClick={handlePdfPreview}
              />
            </div>
          )}
          {!viewPdf && (
            <form>
              <label className={styles.upload} htmlFor="file-upload">
                {pdfLoading ? <Spin size="large" /> : <span>{reportName}</span>}
                <input
                  type="file"
                  id="file-upload"
                  accept="application/pdf"
                  className={styles['input-file']}
                  required
                  onChange={handlePdfFileChange}
                />
              </label>
            </form>
          )}
          {viewPdf && (
            <FadeEffect>
              <div className={styles.button}>
                <SubmitButton
                  label="Delete File"
                  handleClick={handleDeleteFile}
                />
              </div>
              <div className={styles['pdf-height']}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={viewPdf}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </Worker>
              </div>
            </FadeEffect>
          )}
        </div>
        <div className={styles['pdf-title']}>
          <SubmitButton disabled={loading} label="Save Changes" />
        </div>
      </Form>
    </div>
  );
};

export default DabaScore;
