import {gql} from '@apollo/client';

export const GET_ALL_FAQ = gql`
  query GetAllFaqs {
    getAllFaqs {
      id
      question
      answer
      featuredImage
      author
    }
  }
`;

export const GET_FAQ = gql`
  query GetFaqById($faqId: ID!) {
    getFaqById(faqId: $faqId) {
      ... on Error {
        message
        statusCode
      }
      ... on FaqType {
        faq {
          id
          question
          answer
          featuredImage
          author
        }
      }
    }
  }
`;
