import {Route, Routes} from 'react-router-dom';
import {CollectionCompanies} from './singleCollection/CollectionTabs/companies';
import {Collection} from './index';
import {CollectionOrders} from './singleCollection/CollectionTabs/userOrders';
import {SingleCollection} from './singleCollection';
import {CreateCollection} from './singleCollection/newCollection';
import {EditCollection} from './singleCollection/editCollection';
import CollectionGroups from './CollectionGroups/CollectionGroups';
import CollectionOverview from './CollectionOverview';
import CreateCollectionGroups from './CollectionGroups/create/CreateCollectionGroups';

const Collections = () => {
  return (
    <Routes>
      <Route path="/" element={<Collection />}>
        <Route index path="/" element={<CollectionOverview />} />
        <Route path="/groups" element={<CollectionGroups />} />
      </Route>

      <Route path="/new" element={<CreateCollection />} />
      <Route path="/update/:id" element={<EditCollection />} />
      <Route path="/groups/create" element={<CreateCollectionGroups />} />
      <Route path="/groups/update/:id" element={<CreateCollectionGroups />} />

      <Route path="/:id" element={<SingleCollection />}>
        <Route index path="companies" element={<CollectionCompanies />} />
        <Route path="users" element={<CollectionOrders />} />
        <Route />
      </Route>
    </Routes>
  );
};

export {Collections};
