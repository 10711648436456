import {Form, Input, Spin} from 'antd';
import {useEffect, useState} from 'react';
import trash from 'images/trash-can-outline.svg';
import plusIcon from 'images/plus-circle.png';
import styles from './campaignFaq.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useParams} from 'react-router-dom';
import FadeEffect from 'components/animation/fadeEffect';
import {useMutation, useQuery} from '@apollo/client';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {toast} from 'react-toastify';

const UpdateCampaignFAQ = () => {
  const [formValues, setFormValues] = useState([{question: '', answer: ''}]);
  const {id, language} = useParams();
  const width = 700;
  const {TextArea} = Input;

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });
  const [updateCampaign, {loading: campaignLoading}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (campaignData?.getCampaignById.faq) {
      setFormValues(
        campaignData?.getCampaignById?.faq?.map(data => ({
          question: data.question,
          answer: data?.answer,
        })),
      );
    }
  }, [campaignData?.getCampaignById?.faq]);

  const handleChangeFAQ = (i, e) => {
    const newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const handleDeleteFAQ = id => {
    const filteredFormValue = formValues.filter((item, index) => index !== id);
    setFormValues(filteredFormValue);
  };

  const handleAddFaqFields = () => {
    setFormValues([...formValues, {question: '', answer: ''}]);
  };

  const handleSave = () => {
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          faq: formValues?.map(data => ({
            question: data?.question,
            answer: data?.answer,
          })),
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error('Please make sure all fields are not empty');
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div>
        <h1 className={styles.title}>FAQ</h1>
      </div>
      <Form onFinish={handleSave} layout="vertical">
        {formValues.map((element, index) => (
          <div className={styles.faq} key={index}>
            <FadeEffect>
              <div className={styles['question-style']}>
                <Form.Item style={{width}} label="Question">
                  <Input
                    name="question"
                    value={element.question || ''}
                    onChange={e => handleChangeFAQ(index, e)}
                  />
                </Form.Item>
                <img
                  src={trash}
                  onClick={() => handleDeleteFAQ(index)}
                  alt=""
                />
              </div>
              <Form.Item style={{width}} label="Answer">
                <TextArea
                  name="answer"
                  rows={5}
                  value={element.answer || ''}
                  onChange={e => handleChangeFAQ(index, e)}
                />
              </Form.Item>
            </FadeEffect>
          </div>
        ))}
        <div onClick={handleAddFaqFields} className={styles['add-faq']}>
          <img src={plusIcon} alt="add-founder" />
          <span>Add FAQ</span>
        </div>
        <div>
          <SubmitButton
            disabled={campaignLoading}
            label="Update"
            handleClick={handleSave}
          />
        </div>
      </Form>
    </div>
  );
};

export default UpdateCampaignFAQ;
