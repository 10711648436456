import {Form, Input, Select, Upload} from 'antd';
import styles from './campaignInvestors.module.scss';
import deleteIcon from 'images/delete.png';
import {useQuery} from '@apollo/client';
import imgUpload from 'images/upload_image.png';
import {GET_ALL_STARTUPS} from 'graphql/queries/startupCompanies';
import {Button} from '@dabafinance/react-components';

const CoInvestorsCard = ({
  name,
  title,
  description,
  previewImage,
  handleChangeImage,
  otherInvestments,
  handleChange,
  handleDeleteImage,
  handleOtherInvestments,
  handleDeleteCoInvestor,
}) => {
  const {data} = useQuery(GET_ALL_STARTUPS);

  return (
    <div className={styles['card-container']}>
      <div className={styles['left-grid']}>
        {previewImage ? (
          <div className={styles['c-image-uploaded']}>
            <img src={previewImage} alt="" />
            <Button
              className={styles['delete-button']}
              label="Change Image"
              type="secondary"
              onClick={handleDeleteImage}
            />
          </div>
        ) : (
          <Upload
            accept=".png, .jpeg, .jpg"
            name="file"
            onChange={handleChangeImage}>
            <div className={styles['c-company-upload']}>
              <img
                className={styles['company-image']}
                src={imgUpload}
                alt="upload"
              />
              <div style={{marginTop: 6}}>Upload Image</div>
            </div>
          </Upload>
        )}
      </div>

      <div className={styles.form}>
        <div className={styles['co-investrs']}>
          <Form.Item
            rules={[{required: true, message: 'Required Field'}]}
            className={styles.width}
            label="Name">
            <Input value={name} onChange={e => handleChange?.(e)} name="name" />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'Required Field'}]}
            className={styles.width}
            label="Title">
            <Input
              value={title}
              onChange={e => handleChange?.(e)}
              name="title"
            />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'Required Field'}]}
            className={styles.width}
            label="Description">
            <Input.TextArea
              rows={6}
              value={description}
              onChange={e => handleChange?.(e)}
              name="description"
            />
          </Form.Item>
          <div className={styles.title}>
            <h1>Other Investments</h1>
            <div>
              <Form.Item
                rules={[{required: true, message: 'Required Field'}]}
                label="Company"
                className={styles.width}>
                <Select
                  value={otherInvestments}
                  onChange={e => handleOtherInvestments?.(e)}
                  mode="multiple"
                  placeholder="Select Company">
                  {data?.getAllCompanies?.map(data => (
                    <Select.Option key={`${data?.logoImgURL};${data?.id}`}>
                      <div className={styles['all-data']}>
                        <img src={data.logoImgURL} alt="" />
                        <span>{data.name}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.delete}>
        <img
          onClick={e => {
            e.stopPropagation();
            handleDeleteCoInvestor?.();
          }}
          src={deleteIcon}
          alt=""
        />
      </div>
    </div>
  );
};

export {CoInvestorsCard};
