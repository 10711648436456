import styles from './profileBank.module.scss';
import bank1 from 'images/Bank1.svg';
import bank2 from 'images/Bank2.svg';
import star from 'images/Star.svg';

const data = [
  {
    name: 'Wells Fargo',
    number: '1234 **** **** **** 2345',
    image: bank1,
    active: true,
  },
  {
    name: 'Wells Fargo',
    number: '1234 **** **** **** 2345',
    image: bank2,
  },
];

const BankCard = ({image, number, name, active}) => (
  <div className={active ? styles['active-bank-card'] : styles['bank-card']}>
    <div className={styles['card-details']}>
      <div className={styles.image}>
        <img src={image} alt="" />
      </div>
      <div className={styles.body}>
        <h2>{name}</h2>
        <span>{number}</span>
      </div>
    </div>
    <div className={styles.default}>
      {active && (
        <div className={styles.active}>
          <img src={star} alt="" />
          <h3>Default</h3>
        </div>
      )}
    </div>
  </div>
);

const ProfileBanks = () => {
  return (
    <div className={styles['bank-container']}>
      <div className={styles['card-header']}>
        <h1>Banks</h1>
        <div className={styles['bank-card-wrap']}>
          {data?.map(({name, number, image, active}, index) => (
            <div key={index}>
              <BankCard
                name={name}
                active={active}
                number={number}
                image={image}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={styles['card-header']}>
        <h1>Cards</h1>
        <div className={styles['bank-card-wrap']}>
          <BankCard
            name="Darlene Robertson"
            number="1234 **** **** **** 2345"
            image={bank2}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileBanks;
