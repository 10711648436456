import {gql} from '@apollo/client';

export const CREATE_CRYPTO_ACCOUNT = gql`
  mutation CreateCryptoAccount($input: CryptoAccountInput!) {
    createCryptoAccount(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on CryptoAccountDetails {
        id
      }
    }
  }
`;

export const CREATE_BANK_ACCOUNT = gql`
  mutation AddWireTransferAccount($input: WireTransferAccountInput!) {
    addWireTransferAccount(input: $input) {
      ... on Error {
        statusCode
        message
      }
      ... on ResponseWireTransferAccount {
        data {
          flag
        }
      }
    }
  }
`;

export const UPDATE_BANK_ACCOUNT = gql`
  mutation UpdateWireTransferAccount($input: WireTransferAccountUpdateInput!) {
    updateWireTransferAccount(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWireTransferAccount {
        data {
          id
        }
      }
    }
  }
`;

export const DELETE_BANK_ACCOUNT = gql`
  mutation DeleteWireTransferAccount($deleteWireTransferAccountId: ID!) {
    deleteWireTransferAccount(id: $deleteWireTransferAccountId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWireTransferAccount {
        data {
          id
        }
      }
    }
  }
`;

export const DELETE_CRYPTO_ACCOUNT = gql`
  mutation DeleteCryptoAccount($deleteCryptoAccountId: ID!) {
    deleteCryptoAccount(id: $deleteCryptoAccountId) {
      ... on Error {
        message
        statusCode
      }
      ... on CryptoAccountDetails {
        id
      }
    }
  }
`;

export const UPDATE_CRYPTO_ACCOUNT = gql`
  mutation UpdateCryptoAccount(
    $updateCryptoAccountId: ID!
    $input: UpdateCryptoAccountInput!
  ) {
    updateCryptoAccount(id: $updateCryptoAccountId, input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on CryptoAccountDetails {
        id
        walletAddress
        qrCode
        isActive
      }
    }
  }
`;
