import {Form, Input, Modal, Pagination, Select, Spin, Table} from 'antd';
import {useEffect, useState} from 'react';
import styles from './allRewardSchemes.module.scss';
import {Button} from '@dabafinance/react-components';
import {useMutation, useQuery} from '@apollo/client';
import {toast} from 'react-toastify';
import editImg from 'images/edit.svg';
import {walletCurrencies} from 'utils/mock';
import DBModal from 'components/modal/modal';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {GET_ALL_CASH_BANKS} from 'graphql/queries/rewardScheme';
import {
  CREATE_CASH_REWARD_BANK,
  UPDATE_CASH_REWARD_BANK,
} from 'graphql/mutations/rewardScheme';
import {NoUpdate} from 'components/noUpdate';
import PercentageCard from 'pages/rewardSchemes/rewardSchemeCards/PercentageCard';
import {NavTabsEnum} from 'utils/constants';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';

const CashRewardBank = () => {
  const {confirm} = Modal;
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [cashId, setCashId] = useState('');
  const [cash, setCash] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [form] = Form.useForm();

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.RewardBanks),
  );

  const {loading, refetch, data} = useQuery(GET_ALL_CASH_BANKS, {
    errorPolicy: 'all',
    onError: error => toast.error(error?.message),
  });

  const handlePageChange = page => {
    if (page === currentPage) return;
    setCurrentPage(page);
    refetch({page: page, limit: limit});
  };

  const handleLimitChange = size => {
    if (size === limit) return;
    setLimit(size);
    refetch({page: currentPage, limit: size});
  };

  useEffect(() => {
    setLimit(10);
  }, []);

  const handleCashInfo = (id, cash) => {
    setOpenEditModal(true);
    setCashId(id);
    setCash(cash);
  };

  const [createCashRewardBank] = useMutation(CREATE_CASH_REWARD_BANK);

  const handleSave = formValues => {
    confirm({
      content: 'Are you sure you want to CREATE a new cash account?',
      onOk: () => {
        createCashRewardBank({
          variables: {
            data: {
              amountDeposited: Number(formValues?.amount || 0),
              currency: formValues?.currency,
            },
          },
          update(cache) {
            cache.modify({
              fields: {
                getAllAnnouncements() {},
              },
            });
          },
        }).then(({data: {createCashRewardBank}}) => {
          if (createCashRewardBank.__typename === 'Error') {
            toast.error(createCashRewardBank.message);
          } else {
            setOpenCreateModal(false);
            refetch();
            form.resetFields();
            toast.success('Cash Account Created Successfully');
          }
        });
      },
    });
  };

  const [updateCashRewardBank] = useMutation(UPDATE_CASH_REWARD_BANK);

  const handleUpdate = formValues => {
    confirm({
      content: 'Are you sure you want to UPDATE this cash account?',
      onOk: () => {
        updateCashRewardBank({
          variables: {
            data: {
              cashBankId: cashId,
              amountDeposited: Number(formValues?.amount || 0),
            },
          },
          update(cache) {
            cache.modify({
              fields: {
                getAllAnnouncements() {},
              },
            });
          },
        }).then(({data: {updateCashRewardBank}}) => {
          if (updateCashRewardBank.__typename === 'Error') {
            toast.error(updateCashRewardBank.message);
          } else {
            setOpenEditModal(false);
            refetch();
            form.resetFields();
            toast.success('Cash Account Updated Successfully');
          }
        });
      },
    });
  };

  const columns = [
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      render: (text, record) => (
        <div>
          <span>{record?.currency}</span>
        </div>
      ),
    },
    {
      title: 'Deposited Amount',
      dataIndex: 'amountDeposited',
      key: 'amountDeposited',
      render: (text, record) => (
        <div>
          <span>{record?.amountDeposited}</span>
        </div>
      ),
    },
    {
      title: 'Disbursed Amount',
      dataIndex: 'amountDisbursed',
      key: 'amountDisbursed',
      render: (text, render) => (
        <div className={styles['record-label']}>
          <span>{render?.amountDisbursed}</span>
        </div>
      ),
    },
    {
      title: 'Amount Left',
      dataIndex: 'amountLeft',
      key: 'amountLeft',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>
            {record?.amountLeft ||
              record?.amountDeposited - record?.amountDisbursed}
          </span>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <div className={styles.actions}>
          {tabOperations?.update && (
            <div
              onClick={() => handleCashInfo(record?.id, record?.currency)}
              className={styles['action-icon']}>
              <img src={editImg} alt="edit" />
              <span>Edit</span>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={styles['table-container']}>
      <div className={styles.header}>
        <h1>Cash Reward Bank</h1>
        <Button
          label="Create Cash Account"
          className={styles.button}
          disabled={!tabOperations?.create}
          onClick={() => setOpenCreateModal(true)}
        />
      </div>
      <div className={styles['startup-table']}>
        {loading ? (
          <div className={styles.loading}>
            <Spin size="large" />
            <h1>Loading...</h1>
          </div>
        ) : data?.getAllCashBanks?.data?.cashRewardBank?.length >= 1 ? (
          <>
            <section className={styles['percentage-tab']}>
              <PercentageCard
                amount={
                  data?.getAllCashBanks?.data?.cashStats
                    ?.totalNewUsersFromRewards || 0
                }
                label="Total New Users from Cash Reward"
                postive
                active
              />
            </section>
            <Table
              className={styles['user-table']}
              dataSource={data?.getAllCashBanks?.data?.cashRewardBank}
              columns={columns}
              pagination={false}
            />
            <Pagination
              current={currentPage}
              onChange={e => handlePageChange(e)}
              total={data?.getAllCashBanks?.pagination?.total}
              showQuickJumper
              className="ant-table-pagination"
              showSizeChanger
              pageSize={limit}
              onShowSizeChange={(_, size) => {
                handleLimitChange(size);
              }}
            />
          </>
        ) : (
          <NoUpdate
            title={'No Cash Account'}
            description={
              'There are currently no cash account, you could create a new one'
            }
            btnDisabled={!tabOperations?.create}
            buttonLabel={'Create Cash Account'}
            onClick={() => setOpenCreateModal(true)}
          />
        )}
      </div>

      <DBModal
        isOpen={openCreateModal}
        handleClose={() => {
          setOpenCreateModal(false);
          form.resetFields();
        }}
        content={
          <div>
            <h1>Create Cash Account</h1>
            <Form
              form={form}
              className={styles.form}
              layout="vertical"
              onFinish={handleSave}>
              <Form.Item
                name="currency"
                label="Currency"
                rules={[
                  {
                    required: true,
                    message: 'Currency is required',
                  },
                ]}>
                <Select
                  style={{width: '100%'}}
                  name="currency"
                  placeholder="USD">
                  {walletCurrencies.map(({key, name}) => (
                    <Select.Option key={key}>{name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="amount"
                label="Amount"
                rules={[
                  {
                    required: true,
                    message: 'Amount is required',
                  },
                ]}>
                <Input
                  type="number"
                  style={{width: '100%'}}
                  name="amount"
                  placeholder="2000"
                />
              </Form.Item>
              <SubmitButton label="Create account" />
            </Form>
          </div>
        }
      />
      <DBModal
        isOpen={openEditModal}
        handleClose={() => {
          setOpenEditModal(false);
          form.resetFields();
        }}
        content={
          <div>
            <h1>Update {cash} Account</h1>
            <Form
              form={form}
              className={styles.form}
              layout="vertical"
              onFinish={handleUpdate}>
              <Form.Item
                name="amount"
                label="Amount"
                rules={[
                  {
                    required: true,
                    message: 'Amount is required',
                  },
                ]}>
                <Input
                  type="number"
                  style={{width: '100%'}}
                  name="amount"
                  placeholder="2000"
                />
              </Form.Item>
              <SubmitButton label="Update account" />
            </Form>
          </div>
        }
      />
    </div>
  );
};

export {CashRewardBank};
