import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styles from './legalDocument.module.scss';
import plusIcon from 'images/plus-circle.png';
import cloudIcon from 'images/cloud-upload.svg';
import {UploadDocumentToBucket} from './uploadDocument';
import deleteIcon from 'images/delete.svg';
import {Form, Input, Upload} from 'antd';
import FadeEffect from 'components/animation/fadeEffect';
import {useDispatch, useSelector} from 'react-redux';
import {legalDocuments} from 'redux/store/campaign';

const LegalDocument = () => {
  const [documentValues, setDocumentValues] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {id, companyId} = useParams();

  const campaign = useSelector(state => state.campaign.campaign);

  useEffect(() => {
    if (campaign.legalDocuments != undefined) {
      setDocumentValues(
        campaign?.legalDocuments?.map(data => ({
          documentLink: data.documentLink,
          documentName: data.documentName,
          fileName: data.fileName,
        })),
      );
    }
  }, [campaign?.legalDocuments]);

  useEffect(() => {
    if (documentValues.length) {
      documentValues?.map(data =>
        setLoading(!data.documentName || !data?.documentLink),
      );
    } else {
      setLoading(false);
    }
  }, [documentValues]);

  const handleAddDocument = () => {
    setDocumentValues([
      ...documentValues,
      {
        documentLink: '',
        documentName: '',
        fileName: '',
      },
    ]);
  };

  const handleChangeLegalName = (i, e) => {
    const newFormValues = [...documentValues];
    newFormValues[i].documentName = e.target.value;
    setDocumentValues(newFormValues);
  };

  const handleChangeFile = async (i, {file}) => {
    const temp = file.originFileObj;

    const newFormValues = [...documentValues];

    newFormValues[i].fileName = temp.name.split('.')[0];
    setLoading(true);
    const fileLink = await UploadDocumentToBucket(temp);
    newFormValues[i].documentLink = fileLink;
    setDocumentValues(newFormValues);
    setLoading(false);
  };

  const handleDocumentDelete = id => {
    const filteredDocument = documentValues.filter(
      (data, index) => index !== id,
    );
    setDocumentValues(filteredDocument);
  };

  const handleSave = () => {
    dispatch(
      legalDocuments({
        legalDocuments: documentValues,
      }),
    );
    navigate(`/dashboard/campaigns/new/${id}/founders/${companyId}`);
  };
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>Legal Documents</h1>
        <span>
          Upload any legal document that belongs to your company to seal trust
          in investors
        </span>
      </div>
      {documentValues?.map((data, index) => (
        // eslint-disable-next-line react/jsx-key
        <FadeEffect>
          <div className={styles['document-card']} key={index}>
            <Form layout="vertical">
              <Form.Item style={{width: 320}} label="Legal Document Name">
                <Input
                  name="documentName"
                  value={data.documentName}
                  onChange={e => handleChangeLegalName(index, e)}
                  placeholder="Name of document"
                />
              </Form.Item>
              <div className={styles.document}>
                <Upload
                  accept=".pdf, .doc"
                  name="pdf"
                  onChange={e => handleChangeFile(index, e)}
                  maxCount={1}>
                  <label htmlFor="file-upload" className={styles.upload}>
                    <img src={cloudIcon} alt="" />
                    <span>
                      {data.fileName ? data.fileName : 'Upload Document'}
                    </span>
                  </label>
                </Upload>
              </div>
            </Form>
            <div>
              <img
                onClick={() => handleDocumentDelete(index)}
                src={deleteIcon}
                alt=""
              />
            </div>
          </div>
        </FadeEffect>
      ))}
      <div onClick={handleAddDocument} className={styles['add-button']}>
        <img src={plusIcon} alt="" />
        <h1>Add Document</h1>
      </div>
      <SubmitButton
        disabled={loading}
        label="Save & Continue"
        handleClick={handleSave}
      />
    </div>
  );
};

export default LegalDocument;
