import {gql} from '@apollo/client';

export const CREATE_ANNOUNCEMENT = gql`
  mutation CreateAnnouncement($data: createAnnouncementInput!) {
    createAnnouncement(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on AnnouncementType {
        announcement {
          id
          type
          imageUrl
          openUrlOnClick
          announcementUrl
          publishedDate
          opportunity {
            id
          }
          report {
            id
          }
          expiredDate
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const UPDATE_ANNOUNCEMENT = gql`
  mutation UpdateAnnouncement($data: updateAnnouncementInput!) {
    updateAnnouncement(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on AnnouncementType {
        announcement {
          id
          type
          imageUrl
          openUrlOnClick
          announcementUrl
          opportunity {
            id
          }
          report {
            id
          }
          publishedDate
          expiredDate
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const DELETE_ANNOUNCEMENT = gql`
  mutation DeleteAnnouncement($announcementId: ID!) {
    deleteAnnouncement(announcementId: $announcementId) {
      ... on Error {
        message
        statusCode
      }
      ... on AnnouncementType {
        announcement {
          id
          type
          imageUrl
          openUrlOnClick
          announcementUrl
          opportunity {
            id
          }
          report {
            id
          }
          publishedDate
          expiredDate
          createdAt
          updatedAt
        }
      }
    }
  }
`;
