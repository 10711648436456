import {gql} from '@apollo/client';

export const GET_ALL_DEPOSIT_SETTINGS = gql`
  query GetAllSuggestedAmounts {
    getAllSuggestedAmounts {
      ... on Error {
        message
        statusCode
      }
      ... on GetAllUserSuggestedAmountResponseType {
        UserSuggestedAmounts {
          amounts
          currency
          id
          paymentMethod
          provider
        }
      }
    }
  }
`;

export const CREATE_DEPOSIT_SETTINGS = gql`
  mutation AddUserSuggestedAmount($input: UserSuggestedAmountInput!) {
    addUserSuggestedAmount(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on SuggestedAmountResponseType {
        UserSuggestedAmount {
          id
        }
      }
    }
  }
`;

export const GET_DEPOSIT_SETTING_BY_ID = gql`
  query GetUserSuggestedAmountsById($getUserSuggestedAmountsByIdId: String!) {
    getUserSuggestedAmountsById(id: $getUserSuggestedAmountsByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on SuggestedAmountResponseType {
        UserSuggestedAmount {
          currency
          provider
          paymentMethod
          amounts
          id
        }
      }
    }
  }
`;

export const UPDATE_USER_SUGGESTED_AMOUNT = gql`
  mutation UpdateUserSuggestedAmount($input: UpdateUserSuggestedAmountInput!) {
    updateUserSuggestedAmount(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on UpdateUserSuggestedAmountResponseType {
        response
      }
    }
  }
`;
