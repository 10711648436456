import styles from './announcementCard.module.scss';
import deleteImg from 'images/delete.svg';
import {useMutation} from '@apollo/client';
import editImg from 'images/edit.svg';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {toast} from 'react-toastify';
import {DELETE_ANNOUNCEMENT} from 'graphql/mutations/announcement';
import {Modal} from 'antd';
import {getUserTimezone} from 'utils/helper';

const AnnouncementCard = ({type, date, image, id, operations}) => {
  const {confirm} = Modal;
  const navigate = useNavigate();

  const [deleteAnnouncement] = useMutation(DELETE_ANNOUNCEMENT);

  const handleDelete = () => {
    confirm({
      content: 'Are you sure you want to delete this announcement?',
      onOk: () => {
        deleteAnnouncement({
          variables: {
            announcementId: id,
          },
          update(cache) {
            cache.modify({
              fields: {
                getAllActiveAnnouncements() {},
              },
            });
          },
        }).then(({data: {deleteAnnouncement}}) => {
          if (deleteAnnouncement.__typename === 'Error') {
            toast.error(deleteAnnouncement.message);
          } else {
            toast.success('Deleted Successfully');
            window.location.reload();
          }
        });
      },
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <img src={image} alt="" />
      </div>
      <div className={styles.content}>
        <h1>{type.replaceAll('_', ' ')}</h1>
        <div>
          <span>
            {`Published - ${moment(date).format('LLL')}`} ({getUserTimezone()})
          </span>
          <div className={styles.footer}>
            {operations?.delete && (
              <div onClick={handleDelete} className={styles['action-icon']}>
                <img src={deleteImg} alt="delete" />
                <span>Delete</span>
              </div>
            )}
            {operations?.update && (
              <div
                onClick={() => navigate(`/dashboard/announcements/${id}`)}
                className={styles['action-icon']}>
                <img src={editImg} alt="edit" />
                <span>Edit</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementCard;
