import {Form, Input, InputNumber, Select} from 'antd';
import styles from './updateSingleSavingsProduct.module.scss';
import {useEffect, useState} from 'react';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {UPDATE_SAVINGS_PRODUCT} from 'graphql/mutations/savings';
import {useMutation} from '@apollo/client';
import {handleUpdateSavingsProduct} from 'pages/Savings/companies/savingsCompanyActions';
import {Button} from '@dabafinance/react-components';
import {currencySymbols} from 'utils/mock';

const FinancialProductsInfo = ({data, onActiveTabChange, refetch, id}) => {
  const [formValues, setFormValues] = useState({});
  const [form] = Form.useForm();
  const [openFinishModal, setOpenFinishModal] = useState(false);

  const [updateSavingsProduct, {loading}] = useMutation(UPDATE_SAVINGS_PRODUCT);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        currency: data.config?.allowedCurrency,
        minDeposit: data.config?.minAllowedDepositAmount,
        maxDeposit: data.config?.maxAllowedDepositAmount,
        issuer: data.issuer,
        paymentChannel: data?.config?.paymentChannel,
      });
    }
  }, [data]);

  const currency = currencySymbols[data.config?.allowedCurrency].symbol;

  return (
    <div className={styles.generalRoot}>
      <h1>Financial Configuration</h1>
      <Form
        onFinish={e => {
          setFormValues({
            config: {
              minAllowedDepositAmount: Number(e.minDeposit),
              maxAllowedDepositAmount: Number(e.maxDeposit),
            },
          });
          setOpenFinishModal(true);
        }}
        form={form}
        layout="vertical">
        <Form.Item className={styles.field} name="currency" label="Currency">
          <Input disabled />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please enter the minimum deposit amount',
            },
          ]}
          name="minDeposit"
          className={styles.field}
          label="Minimum Deposit Amount">
          <InputNumber
            min={1}
            formatter={value => {
              if (!value) return '';
              return `${currency} ${value}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ',',
              );
            }}
            parser={value =>
              value.replace(new RegExp(`\\${currency}\\s?|\\,`, 'g'), '')
            }
            className={styles.numberField}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please enter the maximum deposit amount',
            },
            {
              validator: (_, value) => {
                const minDeposit = form.getFieldValue('minDeposit');
                if (value && minDeposit && value < minDeposit) {
                  return Promise.reject(
                    new Error(
                      'Maximum deposit must be greater than or equal to minimum deposit',
                    ),
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
          className={styles.field}
          label="Maximum Deposit Amount"
          name="maxDeposit">
          <InputNumber
            min={1}
            formatter={value => {
              if (!value) return '';
              return `${currency} ${value}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ',',
              );
            }}
            parser={value =>
              value.replace(new RegExp(`\\${currency}\\s?|\\,`, 'g'), '')
            }
            className={styles.numberField}
          />
        </Form.Item>
        <Form.Item label="Payment Channel" name="paymentChannel">
          <Select disabled>
            <Select.Option key="DABA_WALLET">Daba Wallet</Select.Option>
          </Select>
        </Form.Item>
        <div className={styles.buttons}>
          <Button
            type="secondary"
            label="Next"
            onClick={() => onActiveTabChange('INTERESTS')}
          />
          <SubmitButton smallWidth label="Proceed to Update" />
        </div>
      </Form>
      <ConfirmModal
        openModal={openFinishModal}
        title="Update Product Details"
        confirmText={'Are you sure all the details are inputed correctly?'}
        loading={loading}
        setOpenModal={() => setOpenFinishModal(false)}
        handleOperation={() =>
          handleUpdateSavingsProduct({
            formValues,
            id,
            updateSavingsProduct,
            setOpenFinishModal,
            refetch,
          })
        }
      />
    </div>
  );
};

export default FinancialProductsInfo;
