import {Form, Input, Spin} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import styles from './dabaScore.module.scss';
import FadeEffect from 'components/animation/fadeEffect';
import {toast} from 'react-toastify';
import {useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {UploadDocumentToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';

const UpdateDabaScore = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [reportName, setReportName] = useState('Upload PDF Report');
  const [viewPdf, setViewPdf] = useState('');
  const [businessValue, setBusinessValue] = useState(0);
  const [industryValue, setIndustryValue] = useState(0);
  const [marketValue, setMarketValue] = useState(0);
  const [teamValue, setTeamValue] = useState(0);
  const [strategyValue, setStrategyValue] = useState(0);
  const [acceleratorValue, setAcceleratorValue] = useState(0);
  const [riskValue, setRiskValue] = useState(0);
  const [analogusValue, setAnalogusValue] = useState(0);
  const [economicValue, setEconomicValue] = useState(0);
  const [complianceValue, setComplianceValue] = useState(0);
  const [outlookValue, setOutlookValue] = useState(0);
  const [socialValue, setSocialValue] = useState(0);
  const [dabaScore, setDabaScore] = useState(0);
  const [pdfReport, setPdfReport] = useState('');
  const {id, language} = useParams();

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });
  const [updateCampaign, {loading: campaignLoading}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (campaignData?.getCampaignById?.dabaScore) {
      setPdfReport(campaignData?.getCampaignById?.dabaScore?.pdfReport);
      setDabaScore(campaignData?.getCampaignById?.dabaScore?.score);
      setBusinessValue(campaignData?.getCampaignById?.dabaScore?.business);
      setIndustryValue(campaignData?.getCampaignById?.dabaScore?.industry);
      setMarketValue(campaignData?.getCampaignById?.dabaScore?.market);
      setTeamValue(campaignData?.getCampaignById?.dabaScore?.team);
      setStrategyValue(
        campaignData?.getCampaignById?.dabaScore?.strategyProduct,
      );
      setAcceleratorValue(
        campaignData?.getCampaignById?.dabaScore?.accelerator,
      );
      setRiskValue(campaignData?.getCampaignById?.dabaScore?.riskChallenges);
      setAnalogusValue(campaignData?.getCampaignById?.dabaScore?.analogus);
      setEconomicValue(
        campaignData?.getCampaignById?.dabaScore?.economicsAndFinance,
      );
      setComplianceValue(
        campaignData?.getCampaignById?.dabaScore?.legalCompliance,
      );
      setOutlookValue(campaignData?.getCampaignById?.dabaScore?.returnOutlook);
      setSocialValue(campaignData?.getCampaignById?.dabaScore?.socialImpact);
    }
  }, [campaignData?.getCampaignById?.dabaScore]);

  const handlePdfFileChange = async e => {
    const selectedFile = e.target.files[0];
    const fileType = ['application/pdf'];
    if (selectedFile.size > 5000000) {
      return toast.error(
        'Upload not Successful: Please upload a pdf with size less than 5mb',
      );
    }
    setReportName(selectedFile.name);
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        const response = await UploadDocumentToBucket(selectedFile);
        setPdfReport(response);
      } else {
        setPdfReport(null);
        toast.error('Please select a valid pdf file');
      }
    } else {
      toast.error('Select a PDF file');
    }
  };

  const handleDeleteFile = () => {
    setPdfReport(null);
    setReportName('Supported formats pdf, docs');
    setViewPdf(null);
  };

  const handlePdfPreview = e => {
    e.preventDefault();
    if (pdfReport !== null) {
      setViewPdf(pdfReport);
    } else {
      setViewPdf(null);
    }
  };

  const handleSave = () => {
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          dabaScore: {
            accelerator: Number(acceleratorValue),
            analogus: Number(acceleratorValue),
            business: Number(businessValue),
            economicsAndFinance: Number(economicValue),
            industry: Number(industryValue),
            team: Number(teamValue),
            legalCompliance: Number(complianceValue),
            market: Number(marketValue),
            returnOutlook: Number(outlookValue),
            riskChallenges: Number(riskValue),
            score: Number(dabaScore),
            socialImpact: Number(socialValue),
            strategyProduct: Number(strategyValue),
            pdfReport,
          },
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error(updateCampaign.message);
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>daba score: {dabaScore}</h1>
        <span>
          Enter the values for the parameters below and click calculate and the
          organzations daba score would be calculated automatically
        </span>
      </div>
      <div>
        <Form className={styles.form} layout="vertical">
          <Form.Item style={{width: 386}} name="daba score" label="Daba Score">
            <Input
              value={dabaScore}
              onChange={e => setDabaScore(e.target.value)}
              type="number"
              defaultValue={campaignData?.getCampaignById?.dabaScore?.score}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item style={{width: 386}} name="business" label="Business">
            <Input
              value={businessValue}
              onChange={e => setBusinessValue(e.target.value)}
              type="number"
              defaultValue={campaignData?.getCampaignById?.dabaScore?.business}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item style={{width: 386}} name="Industry" label="Industry">
            <Input
              type="number"
              value={industryValue}
              defaultValue={campaignData?.getCampaignById?.dabaScore?.industry}
              onChange={e => setIndustryValue(e.target.value)}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item style={{width: 386}} name="Market" label="Market">
            <Input
              value={marketValue}
              onChange={e => setMarketValue(e.target.value)}
              type="number"
              defaultValue={campaignData?.getCampaignById?.dabaScore?.market}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item style={{width: 386}} name="Team" label="Team">
            <Input
              value={teamValue}
              defaultValue={campaignData?.getCampaignById?.dabaScore?.team}
              onChange={e => setTeamValue(e.target.value)}
              type="number"
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            style={{width: 386}}
            name="Strategy, Product GTM"
            label="Strategy, Product GTM">
            <Input
              type="number"
              defaultValue={
                campaignData?.getCampaignById?.dabaScore?.strategyProduct
              }
              value={strategyValue}
              onChange={e => setStrategyValue(e.target.value)}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            style={{width: 386}}
            name="Accelerator"
            label="Accelerator">
            <Input
              value={acceleratorValue}
              onChange={e => setAcceleratorValue(e.target.value)}
              type="number"
              defaultValue={
                campaignData?.getCampaignById?.dabaScore?.accelerator
              }
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            style={{width: 386}}
            name="Risks + Challenges"
            label="Risks + Challenges">
            <Input
              value={riskValue}
              onChange={e => setRiskValue(e.target.value)}
              type="number"
              defaultValue={
                campaignData?.getCampaignById?.dabaScore?.riskChallenges
              }
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item style={{width: 386}} name="Analogue" label="Analogue">
            <Input
              value={analogusValue}
              onChange={e => setAnalogusValue(e.target.value)}
              defaultValue={campaignData?.getCampaignById?.dabaScore?.analogus}
              type="number"
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            style={{width: 386}}
            name="Economics / Financials"
            label="Economics / Financials">
            <Input
              value={economicValue}
              onChange={e => setEconomicValue(e.target.value)}
              defaultValue={
                campaignData?.getCampaignById?.dabaScore?.economicsAndFinance
              }
              type="number"
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            style={{width: 386}}
            name="Legal & Compliance"
            label="Legal & Compliance">
            <Input
              value={complianceValue}
              onChange={e => setComplianceValue(e.target.value)}
              type="number"
              placeholder="Enter number"
              defaultValue={
                campaignData?.getCampaignById?.dabaScore?.legalCompliance
              }
            />
          </Form.Item>
          <Form.Item
            style={{width: 386}}
            name="Returns Outlook"
            label="Returns Outlook">
            <Input
              value={outlookValue}
              defaultValue={
                campaignData?.getCampaignById?.dabaScore?.returnOutlook
              }
              onChange={e => setOutlookValue(e.target.value)}
              type="number"
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            style={{width: 386}}
            name="Social Impact"
            label="Social Impact">
            <Input
              value={socialValue}
              defaultValue={
                campaignData?.getCampaignById?.dabaScore?.socialImpact
              }
              onChange={e => setSocialValue(e.target.value)}
              type="number"
              placeholder="Enter number"
            />
          </Form.Item>
        </Form>
      </div>
      <div>
        <h1 className={styles['pdf-title']}>Full Report PDF</h1>
        {!viewPdf && (
          <div className={styles.button}>
            <SubmitButton
              disabled={pdfReport === null}
              label="Preview Pdf"
              handleClick={handlePdfPreview}
            />
          </div>
        )}
        {!viewPdf && (
          <form>
            <label className={styles.upload} htmlFor="file-upload">
              <span>{reportName}</span>
              <input
                type="file"
                id="file-upload"
                className={styles['input-file']}
                required
                onChange={handlePdfFileChange}
              />
            </label>
            <span>Please make sure the PDF upload size is less than 5mb</span>
          </form>
        )}
        {viewPdf && (
          <FadeEffect>
            <div className={styles.button}>
              <SubmitButton
                label="Delete File"
                handleClick={handleDeleteFile}
              />
            </div>
            <div className={styles['pdf-height']}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={viewPdf}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </Worker>
            </div>
          </FadeEffect>
        )}
      </div>
      <div className={styles['pdf-title']}>
        <SubmitButton
          disabled={pdfReport === '' || campaignLoading}
          label="Update"
          handleClick={handleSave}
        />
      </div>
    </div>
  );
};

export default UpdateDabaScore;
