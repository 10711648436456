export const CHECK_EMAIL_EXIST = `query CheckUserEmail($data: CheckUserEmailInput!) {
  checkUserEmail(data: $data) {
    ... on Error {
      message
      statusCode
    }
    ... on EmailCheck {
      emailExist
      isEmailVerified
      isUsernameSet
      isFullNameSet
    }
  }
}`;

export const LOGIN_MUTATION = `
mutation LoginAdmin($data: LoginInput!) {
  loginAdmin(data: $data) {
    ... on Error {
      message
      statusCode
    }
    ... on AuthenticatedResponse {
      user {
        username
        roleSettings {
          name
          description
          tabs {
            operations
            tab
          }
        }
      }    
      jwtAccessToken
      twoFactorStepRequired
    }
  }
}
`;

export const SIGN_UP_MUTATION = `mutation CreateUser($data: UserSignupInput!) {
  createUser(data: $data) {
    ... on Error {
      message
      statusCode
    }
    ... on AuthenticatedResponse {
      user {
        id
        email
      }
      jwtAccessToken
    }
  }
}`;

export const VERIFY_OTP_MUTATION = `
mutation VerifyEmail($code: String!) {
  verifyEmail(code: $code) {
    ... on Error {
      message
      statusCode
    }
    ... on EmailVerified {
      response
    }
  }
}`;

export const UPDATE_PROFILE_MUTATION = `mutation UpdateProfile($data: UpdateUserProfileInput!) {
  updateProfile(data: $data) {
    ... on UserType {
      user {
        firstName
        lastName
        dateOfBirth
        username
        imageUrl
        username
      }
    }
    ... on Error {
      message
      statusCode
    }
  }
}`;

export const ADD_COUNTRY_INTEREST = `mutation AddCountryOfInterests($data: CountryOfInterestsInput!) {
  addCountryOfInterests(data: $data) {
    ... on Error {
      message
      statusCode
    }
    ... on CountryInterestsType {
      countries {
        id
        name
        imageUrl
        isSelected
      }
    }
  }
}`;

export const ADD_USER_INTEREST = `mutation AddTopics($data: TopicsInput!) {
  addTopics(data: $data) {
    ... on Error {
      message
      statusCode
    }
    ... on TopicsType {
      topics {
        id
        name
        imageUrl
        isSelected
      }
    }
  }
}`;

export const FETCH_COUNTRIES = `query Error {
  getCountryInterests {
    ... on Error {
      message
      statusCode
    }
    ... on CountryInterestsType {
      countries {
        id
        name
        imageUrl
         isSelected
      }
    }
  }
}`;
export const FETCH_TOPICS = `query Query {
  getTopics {
    topics {
      id
      name
      imageUrl
      isSelected
    }
  }
}`;

export const FETCH_GROUPS = `query Query($page: Int, $limit: Int) {
  getGroups(page: $page, limit: $limit) {
    ... on Error {
      message
      statusCode
    }
    ... on ReturnedGroups {
      groups {
        id
        name
        description
        imageUrl
      }
    }
  }
}`;

export const ADD_USER_GROUPS = `mutation Mutation($groupIds: [ID]!) {
  addUserGroups(groupIds: $groupIds) {
    ... on Error {
      message
      statusCode
    }
  }
}`;
