/* eslint-disable no-debugger */
import {Form, Spin, Switch} from 'antd';
import styles from './updateGlobalWalletSettingsModal.module.scss';
import {useMutation, useQuery} from '@apollo/client';
import {UPDATE_GLOBAL_WALLET_PLATFORM_SETTINGS} from 'graphql/mutations/wallet';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {toast} from 'react-toastify';
import {
  GET_ALL_WALLET_PAYMENT_PROVIDERS,
  GET_GLOBAL_WALLET_PROVIDERS,
} from 'graphql/queries/wallet';

const UpdateGlobalWalletSettingsModal = ({handleCloseModal}) => {
  const {data, loading} = useQuery(GET_GLOBAL_WALLET_PROVIDERS, {
    fetchPolicy: 'no-cache',
  });
  const [updateGlobalWalletPlatformSettings, {loading: loadingUpdate}] =
    useMutation(UPDATE_GLOBAL_WALLET_PLATFORM_SETTINGS);

  const handleSubmit = values => {
    updateGlobalWalletPlatformSettings({
      variables: {
        input: {
          isDepositAllowed: values.isDepositAllowed,
          isTransfersAllowed: values.isTransfersAllowed,
          isWithdrawalAllowed: values.isWithdrawalAllowed,
        },
      },
      refetchQueries: [{query: GET_ALL_WALLET_PAYMENT_PROVIDERS}],
    })
      .then(({data: {updateGlobalWalletPlatformSettings}}) => {
        if (updateGlobalWalletPlatformSettings.__typename === 'Error') {
          toast.error(updateGlobalWalletPlatformSettings.message);
        } else {
          toast.success('Settings Updated Successfully.');
        }
      })
      .catch(error => {
        toast.error(error);
      })
      .finally(() => handleCloseModal(false));
  };

  if (loading) {
    return (
      <div className="loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1>Update Wallet Global Settings</h1>
        <p>
          NOTE: this will update the settings of all Providers, it overrides
          other changes made
        </p>
      </div>
      <Form onFinish={handleSubmit} className={styles.form}>
        <Form.Item
          initialValue={data?.getGlobalPlatformSettings?.isDepositAllowed}
          name="isDepositAllowed"
          label="Allow Deposit Globally">
          <Switch
            defaultChecked={data?.getGlobalPlatformSettings?.isDepositAllowed}
          />
        </Form.Item>
        <Form.Item
          initialValue={data?.getGlobalPlatformSettings?.isTransfersAllowed}
          name="isTransferAllowed"
          label="Allow Transfer Globally ">
          <Switch
            defaultChecked={data?.getGlobalPlatformSettings?.isTransfersAllowed}
          />
        </Form.Item>
        <Form.Item
          initialValue={data?.getGlobalPlatformSettings?.isWithdrawalAllowed}
          name="isWithdrawalAllowed"
          label="Allow Withdrawal Globally">
          <Switch
            defaultChecked={
              data?.getGlobalPlatformSettings?.isWithdrawalAllowed
            }
          />
        </Form.Item>
        <SubmitButton disabled={loadingUpdate} label="Update Settings" />
      </Form>
    </div>
  );
};

export default UpdateGlobalWalletSettingsModal;
