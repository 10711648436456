import {DatePicker, Form, Select, Switch} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import moment from 'moment';
import {offeringTypeData} from 'pages/campaigns/campaignData';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {settings} from 'redux/store/campaign';
import styles from './campaignSettings.module.scss';

const CampaignSettings = () => {
  const [offeringType, setOfferingType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [finishDate, setFinishDate] = useState('');
  const [publicAudience, setPublicAudience] = useState([]);
  const [isListingVisible, setIsListingVisible] = useState(false);
  const [notifyUsersOnLaunch, setNotifyUsersOnLaunch] = useState(false);

  const dispatch = useDispatch();
  const {Option} = Select;
  const {id, slug, companyId} = useParams();
  const navigate = useNavigate();

  const campaign = useSelector(state => state.campaign.campaign);

  useEffect(() => {
    if (campaign?.offeringType != undefined) {
      setOfferingType(campaign?.offeringType);
      setStartDate(campaign?.startDate);
      setFinishDate(campaign?.finishDate);
      setPublicAudience(campaign?.publicAudience);
      setIsListingVisible(campaign?.isVisible);
      setNotifyUsersOnLaunch(campaign?.notifyUsersOnLaunch);
    }
  }, [campaign?.offeringType]);

  const handleSave = () => {
    dispatch(
      settings({
        offeringType,
        startDate,
        finishDate,
        publicAudience,
        isVisible: isListingVisible,
        notifyUsersOnLaunch,
      }),
    );
    if (slug) {
      navigate(
        `/dashboard/campaigns/${slug}/new/${id}/users-invite/${companyId}`,
      );
    } else {
      navigate(`/dashboard/campaigns/new/${id}/users-invite/${companyId}`);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Settings</h1>
      </div>
      <div>
        <Form onFinish={handleSave} layout="vertical">
          <Form.Item
            rules={[{required: true, message: 'Required Field'}]}
            initialValue={campaign?.offeringType}
            name="open"
            label="Offering Type">
            <Select
              value={offeringType}
              onChange={e => setOfferingType(e)}
              style={{width: 796}}
              placeholder="Select a Offering Type"
              defaultValue={campaign?.offeringType}>
              {offeringTypeData?.map(({label, value}) => (
                <Option key={value}>
                  <span>{label}</span>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            initialValue={campaign?.publicAudience}
            name="publicAudience"
            label="Public Audience">
            <Select
              value={publicAudience}
              onChange={e => setPublicAudience(e)}
              style={{width: 796}}
              mode="tags"
              allowClear
              placeholder="Select a Public Audience"
              defaultValue={campaign?.publicAudience}>
              <Option key="INVITED_USERS">Invited Users</Option>
              <Option key="MEMBERS_INVITED_GROUPS">
                Members of Invited Groups
              </Option>
            </Select>
          </Form.Item>
          <div className={styles.date}>
            <Form.Item
              label="Start Date"
              name="start"
              className={styles['form-item']}
              rules={[
                {
                  required: true,
                  message:
                    'Required Field, Please make sure its less than the finish date',
                },
              ]}
              initialValue={
                campaign.startDate
                  ? moment(campaign?.startDate, 'YYYY-MM-DD HH:mm:ss')
                  : ''
              }>
              <DatePicker
                placeholder="Start Date"
                value={startDate}
                defaultValue={
                  campaign.startDate
                    ? moment(campaign?.startDate, 'YYYY-MM-DD HH:mm:ss')
                    : ''
                }
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm:ss'),
                }}
                format={'YYYY-MM-DD HH:mm:ss'}
                onChange={e =>
                  setStartDate(moment(e).format('YYYY-MM-DD HH:mm:ss'))
                }
                style={{width: 340}}
              />
            </Form.Item>
            <Form.Item
              label="Finish Date"
              name="finish"
              className={styles['form-item']}
              rules={[
                {
                  required: true,
                  message: 'Required Field',
                },
              ]}
              initialValue={
                campaign.finishDate
                  ? moment(campaign?.finishDate, 'YYYY-MM-DD HH:mm:ss')
                  : ''
              }>
              <DatePicker
                placeholder="Finish Date"
                value={finishDate}
                defaultValue={
                  campaign.finishDate
                    ? moment(campaign?.finishDate, 'YYYY-MM-DD HH:mm:ss')
                    : ''
                }
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm:ss'),
                }}
                format={'YYYY-MM-DD HH:mm:ss'}
                onChange={e =>
                  setFinishDate(moment(e).format('YYYY-MM-DD HH:mm:ss'))
                }
                style={{width: 340}}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="visibility"
              label="Listing Visibility (Disabling this will HIDE this Listing from all but Internal Team Members)">
              <Switch
                value={isListingVisible}
                defaultChecked={campaign?.isVisible}
                onChange={e => setIsListingVisible(e)}
              />
            </Form.Item>

            <Form.Item
              name="notifyUsersOnLaunch"
              label="Notify Users on Launch">
              <Switch
                value={notifyUsersOnLaunch}
                defaultChecked={campaign?.notifyUsersOnLaunch}
                onChange={e => setNotifyUsersOnLaunch(e)}
              />
            </Form.Item>
          </div>
          <div>
            <SubmitButton label="Continue" />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CampaignSettings;
