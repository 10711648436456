import {Form, Select} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {listType} from 'redux/store/campaign';
import styles from './listingType.module.scss';
import {toast} from 'react-toastify';

const ListingType = () => {
  const [listingType, setListingType] = useState('');
  const [listingMode, setListingMode] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {slug, id, companyId} = useParams();

  const campaign = useSelector(state => state.campaign.campaign);

  useEffect(() => {
    setListingType(campaign?.listType);
  }, [campaign?.listType]);

  const handleSubmit = () => {
    if (listingType === 'IPO' && listingMode === 'TEST') {
      return toast.error('Cannot create a Test IPO');
    }
    dispatch(
      listType({
        listType: listingType,
        listMode: listingMode,
      }),
    );
    if (slug) {
      navigate(`/dashboard/campaigns/${slug}/new/${id}/${companyId}`);
    } else {
      navigate(`/dashboard/campaigns/new/${id}/${companyId}`);
    }
  };

  return (
    <div className={styles.container}>
      <h1>Listing Type</h1>
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          initialValue={campaign?.listMode}
          label="List Mode"
          name="listMode"
          className={styles['field-item']}
          rules={[{required: true, message: 'Please select a listing type'}]}>
          <Select
            placeholder="Please select a listing mode"
            defaultValue={campaign?.listMode}
            value={listingMode}
            onChange={e => setListingMode(e)}>
            <Select.Option value="TEST">TEST</Select.Option>
            <Select.Option value="LIVE">LIVE</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          initialValue={campaign?.listType}
          label="List Type"
          name="listType"
          className={styles['field-item']}
          rules={[{required: true, message: 'Please select a listing type'}]}>
          <Select
            placeholder="Please select a listing type"
            defaultValue={campaign?.listType}
            value={listingType}
            onChange={e => setListingType(e)}>
            {listingMode === 'LIVE' && (
              <Select.Option value="IPO">IPO</Select.Option>
            )}
            <Select.Option value="VENTURE">Venture</Select.Option>
            <Select.Option value="VENTURE_FUND">Venture Fund</Select.Option>
          </Select>
        </Form.Item>
        <SubmitButton label="Save Changes" />
      </Form>
    </div>
  );
};

export default ListingType;
