import styles from './websiteNews.module.scss';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {websiteNewsTypes} from '../newsTypes';
import WebsiteNewsCard from './card/websiteNewsCard';
import {Spin} from 'antd';
import {Button} from '@dabafinance/react-components';
import {useQuery} from '@apollo/client';
import {GET_WEBSITE_NEWS} from 'redux/store/news/queries';
import CustomDropdown from 'components/customDropdown/CustomDropdown';
import {NavTabsEnum} from 'utils/constants';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';

const WebsiteNews = () => {
  const [activeTab, setActiveTab] = useState('BREAKING_NEWS');
  const [currentPage, setCurrentPage] = useState(1);
  const [language, setLanguage] = useState('EN');
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.News),
  );

  const handleNextPage = () => setCurrentPage(prevPage => prevPage + 1);
  const handlePreviousPage = () =>
    setCurrentPage(currentPage => currentPage - 1);
  const handleResetPage = () => setCurrentPage(1);

  const navigate = useNavigate();
  const items = [
    {
      label: 'English',
      key: 'EN',
    },
    {
      label: 'French',
      key: 'FR',
    },
  ];

  const {data, loading, refetch} = useQuery(GET_WEBSITE_NEWS, {
    variables: {
      type: activeTab,
      countPage: true,
      language,
      limit: 20,
      pageNumber: currentPage,
    },
    onCompleted: data => {
      setHasPrev(data?.getWebsiteNews?.pagination?.hasPreviousPage);
      setHasNext(data?.getWebsiteNews?.pagination?.hasNextPage);
    },
  });

  const handleClickMenu = e => {
    setLanguage(e.key);
    handleResetPage();
    refetch({
      type: activeTab,
      countPage: true,
      language: e.key,
      limit: 20,
      pageNumber: currentPage,
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.createButton}>
        <CustomDropdown
          selectedOption={language}
          options={items}
          setSelectedOption={handleClickMenu}
        />
        <Button
          label="Create News"
          disabled={!tabOperations?.create}
          onClick={() => navigate('/dashboard/news/create-website-news')}
        />
      </div>
      <div className={styles['nav-buttons']}>
        <Button
          onClick={handlePreviousPage}
          label="Previous"
          type="secondary"
          disabled={!hasPrev || loading}
          className={styles.button}
        />
        <div className={styles['page-number']}>{currentPage}</div>
        <Button
          onClick={handleNextPage}
          label="Next"
          disabled={!hasNext || loading}
          className={styles.button}
        />
      </div>

      <div className={styles.optionSection}>
        {websiteNewsTypes.map((data, index) => (
          <div key={index} className={styles.toggler}>
            <span
              onClick={() => {
                handleResetPage();
                setActiveTab(data.key);
              }}
              className={data.key === activeTab ? styles.active : ''}>
              {data.label}
            </span>
          </div>
        ))}
      </div>
      {loading ? (
        <div className="query-loading">
          <Spin size="large" />
          <h1>Loading</h1>
        </div>
      ) : (
        <div className={styles.newsCards}>
          {data?.getWebsiteNews?.WebsiteNews?.length > 0 ? (
            data?.getWebsiteNews?.WebsiteNews?.map((item, index) => (
              <WebsiteNewsCard
                operations={tabOperations}
                key={index}
                data={item}
                type={activeTab}
              />
            ))
          ) : (
            <div className={styles.emptyData}>
              <h1>No News on this section!</h1>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WebsiteNews;
