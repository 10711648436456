import {Spin, Table} from 'antd';
import {useEffect, useState} from 'react';
import backIcon from 'images/back.png';
import styles from './campaignUsersInterested.module.scss';
import {useNavigate, useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {
  GET_CAMPAIGN_INTERESTS_BY_ID,
  GET_FUNDRAISING_COMPANY_BY_ID,
} from 'graphql/queries/campaign';
import moment from 'moment';
import {ExportButton} from 'components/button/ExportButton/ExportButton';

const CampaignUsersInterested = () => {
  const [usersTransactions, setUsersTransactions] = useState([]);

  const [usersDetails, setUsersDetails] = useState([]);
  const navigate = useNavigate();
  const {id, slug} = useParams();

  const {data, loading} = useQuery(GET_CAMPAIGN_INTERESTS_BY_ID, {
    onCompleted: data =>
      setUsersDetails(data?.getCampaignInterestById?.reservations),
    variables: {
      campaign: id,
    },
  });

  const {data: fundRaisingCompany, loading: loadingCompany} = useQuery(
    GET_FUNDRAISING_COMPANY_BY_ID,
    {
      variables: {
        companyId: slug,
      },
    },
  );

  useEffect(() => {
    const tableData = [];
    for (let i = 0; i < usersDetails.length; i++) {
      const tableRow = {};
      tableRow.firstName = usersDetails[i]?.user?.firstName;
      tableRow.lastName = usersDetails[i]?.user?.lastName;
      tableRow.email = usersDetails[i]?.user?.email;
      tableRow.username = usersDetails[i]?.user?.username;
      tableRow.phoneNumber = `${usersDetails[i]?.user?.countryCode ?? ' '}${
        usersDetails[i]?.user?.phoneNumber ?? ' '
      }`;
      tableRow.amount = `$${
        usersDetails[i]?.interestAmountRange?.amount ?? ' '
      }`;
      tableRow.minInvestmentInterestAmount = `${
        usersDetails[i]?.interestAmountRange?.minInvestmentInterestAmount ?? ' '
      }`;
      tableRow.maxInvestmentInterestAmount = `${
        usersDetails[i]?.interestAmountRange?.maxInvestmentInterestAmount ?? ' '
      }`;
      tableRow.date = `${moment(usersDetails[i]?.createdAt).format(
        'MMMM Do YYYY, h:mm a',
      )}`;
      tableData.push(tableRow);
    }
    setUsersTransactions(tableData);
  }, [usersDetails]);

  const downloadTableHeader = [
    {label: 'First Name', key: 'firstName'},
    {label: 'Last Name', key: 'lastName'},
    {label: 'Username', key: 'username'},
    {label: 'Email', key: 'email'},
    {label: 'Phone Number', key: 'phoneNumber'},
    {label: 'Amount', key: 'amount'},
    {label: 'Minimum Interest Amount', key: 'minInvestmentInterestAmount'},
    {label: 'Maximum Interest Amount', key: 'maxInvestmentInterestAmount'},
    {label: 'Date', key: 'date'},
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div
          onClick={() => navigate(`/dashboard/user/${record?.user?.id}`)}
          className={styles['record-label']}>
          <img src={record?.user?.imageUrl} alt="" />
          <h4 style={{width: 100}}>
            {record?.user?.firstName} {record?.user?.lastName}
          </h4>
        </div>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (text, record) => <span>{record?.user?.username}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => <span>{record?.user?.email}</span>,
    },
    {
      title: 'Minimum Investment Amount',
      dataIndex: 'minimum',
      key: 'amount',
      render: (text, record) => (
        <span>{record?.interestAmountRange?.minInvestmentInterestAmount}</span>
      ),
    },
    {
      title: 'Maximum Investment Amount',
      dataIndex: 'max',
      key: 'max',
      render: (text, record) => (
        <span>{record?.interestAmountRange?.maxInvestmentInterestAmount}</span>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => (
        <span>{record?.interestAmountRange?.amount}</span>
      ),
    },
    {
      title: 'Opened At',
      dataIndex: 'time',
      key: 'time',
      render: (text, record) => (
        <span>{moment(record?.createdAt).format('MMMM Do YYYY, h:mm a')}</span>
      ),
    },
    {
      title: 'Updated At',
      dataIndex: 'time',
      key: 'time',
      render: (text, record) => (
        <span>{moment(record?.updatedAt).format('MMMM Do YYYY, h:mm a')}</span>
      ),
    },
  ];

  if (loading || loadingCompany) {
    return (
      <div className={styles.loading}>
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div>
      <div className={styles['nav-header']}>
        <div className={styles.header}>
          <img src={backIcon} alt="" onClick={() => navigate(-1)} />
          <h1>
            {fundRaisingCompany?.getFundraisingCompanyById?.companyName}{' '}
            Campaign Interests
          </h1>
        </div>
        <ExportButton
          label={'Download Interests'}
          csvData={usersTransactions}
          csvHeaders={downloadTableHeader}
          fileName={`${fundRaisingCompany?.getFundraisingCompanyById?.companyName} Campaign User Interests`}
        />
      </div>
      <div className={styles.table}>
        <Table
          className={styles['user-table']}
          columns={columns}
          dataSource={data?.getCampaignInterestById?.reservations}
        />
      </div>
    </div>
  );
};

export default CampaignUsersInterested;
