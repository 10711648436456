import {useEffect, useState} from 'react';
import styles from './viewFaq.module.scss';
import {Spin} from 'antd';
import backIcon from 'images/back.png';
import {useNavigate, useParams} from 'react-router-dom';
import ShareButton from 'components/button/shareButton';
import {ShareIcon} from 'icons';
import Hand from 'images/hand.png';
import Funny from 'images/funny.png';
import Fire from 'images/imagefire.png';
import Confuse from 'images/confuse.png';
import Angry from 'images/angry.png';
import {FeedbackCard} from '@dabafinance/react-components';
import {useQuery} from '@apollo/client';
import {GET_FAQ} from 'graphql/queries/faq';
const HtmlToReactParser = require('html-to-react').Parser;

const ViewFaq = () => {
  const navigate = useNavigate();
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [image, setImage] = useState('');
  const [author, setAuthor] = useState('');
  const {id} = useParams();

  const {data, loading} = useQuery(GET_FAQ, {
    variables: {
      faqId: id,
    },
  });

  useEffect(() => {
    setQuestion(data?.getFaqById?.faq?.question);
    setAnswer(data?.getFaqById?.faq?.answer);
    setAuthor(data?.getFaqById?.faq?.author);
    setImage(data?.getFaqById?.faq?.featuredImage);
  }, [data?.getFaqById?.faq]);

  const feedBackData = [
    {
      emoji: Hand,
      label: 20,
    },
    {
      emoji: Fire,
      label: 30,
    },
    {
      emoji: Funny,
      label: 40,
    },
    {
      emoji: Confuse,
      label: 50,
    },
    {
      emoji: Angry,
      label: 60,
    },
  ];

  const htmlParser = new HtmlToReactParser();
  const newContent = htmlParser.parse(answer);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <img onClick={() => navigate(-1)} src={backIcon} alt="" />
          <h1>
            FAQ <span> / Preview</span>
          </h1>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles['image-wrapper']}>
          <img src={image} className={styles['faq-background']} />
        </div>
        <div className={styles['content-wrapper']}>
          <h3 className={styles['faq-question']}>{question}</h3>
          <p className={styles.author}>By {author}</p>
          <ShareButton label="Share article" iconElement={<ShareIcon />} />
          <p className={styles['faq-content']}>{newContent}</p>
          <FeedbackCard
            className={styles['feedback-card']}
            data={feedBackData}
            label="Feedbacks"
          />
        </div>
      </div>
    </div>
  );
};

export {ViewFaq};
