import {Route, Routes} from 'react-router-dom';
import {AdminStockListings} from './stocksListings/stocksListing';
import UpdateStockListing from './stocksListings/update/updateStock/updateStockListing';
import {StocksOrders} from './stocksOrders/StocksOrders';
import UpdateStocksOrders from './stocksOrders/update/UpdateStocksOrders';
import CreateStock from './stocksListings/create/CreateStock';
import UpdateStock from './stocksListings/update/updateStock/UpdateStock';
import StockListingUpdate from './stocksListings/update';
import StockListingClosure from './stocksListings/update/stockClosure/stockClosure';

const AdminStock = () => (
  <Routes>
    <Route path="/listings" element={<AdminStockListings />} />
    <Route path="/update-listing" element={<UpdateStockListing />} />
    <Route path="/orders" element={<StocksOrders />} />
    <Route path="/create-stock" element={<CreateStock />} />
    <Route path="/update-stocks" element={<UpdateStocksOrders />} />
    <Route path="update-stock/:id" element={<StockListingUpdate />}>
      <Route index element={<UpdateStock />} />
      <Route path="stock-closure" element={<StockListingClosure />} />
    </Route>
  </Routes>
);

export {AdminStock};
