import {Form} from 'antd';
import styles from './collection.module.scss';
import {useState} from 'react';
import {toast} from 'react-toastify';
import {
  UploadDocumentToBucket,
  UploadVideoToBucket,
} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import {useMutation} from '@apollo/client';
import {CREATE_COLLECTION} from 'graphql/mutations/collections';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {onTranslateCollection} from './actions';
import {useNavigate} from 'react-router-dom';
import backIcon from 'images/back.png';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {GeneralInfo} from './CollectionTabs/forms/general';
import {FinancialInfo} from './CollectionTabs/forms/financial';
import {DabaScore} from './CollectionTabs/forms/dabaScore';
import {GET_ALL_COLLECTIONS} from 'graphql/queries/collections';

export const CreateCollection = () => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [firstForm] = Form.useForm();
  const [secondForm] = Form.useForm();
  const [thirdForm] = Form.useForm();
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [secondFormDisabled, setSecondFormDisabled] = useState(true);
  const [thirdFormDisabled, setThirdFormDisabled] = useState(true);
  const [imgUrl, setImgUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [progress, setProgress] = useState(0);
  const [videoUploadLoading, setVideoUploadLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [activeTab, setActiveTab] = useState('GENERAL');
  const [collectionCompanies, setCollectionCompanies] = useState([{}]);
  const [reportName, setReportName] = useState('Upload PDF Report');
  const [viewPdf, setViewPdf] = useState('');
  const [formValues, setFormValues] = useState({});
  const [pdfReport, setPdfReport] = useState('');
  const [pdfLoading, setPdfLoading] = useState(false);
  const [selectedTranslationLanguage, setSelectedTranslationLanguage] =
    useState('');
  const [translatedCollections, setTranslatedCollections] = useState([]);
  const [allowUsers, setAllowUsers] = useState(true);

  const navigate = useNavigate();

  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );

  const handleTranslation = async (name, description, language) => {
    const translateProps = {
      sourceLang: language,
      targetLang: selectedTranslationLanguage,
      targetText1: name,
      targetText2: description,
    };
    try {
      const newValues = [...translatedCollections];

      //Check if translation exists
      if (newValues.length && newValues[0]) {
        // Check if we're trying to translate in the same language
        const languageExists = newValues.some(
          data => data.language === selectedTranslationLanguage,
        );
        if (languageExists) {
          const updateTranslation = await onTranslateCollection(
            translateText,
            translateProps,
          );
          const translationIndex = newValues.findIndex(
            data => data.language === selectedTranslationLanguage,
          );
          newValues[translationIndex].name = updateTranslation.name;
          newValues[translationIndex].description =
            updateTranslation.description;
          newValues[translationIndex].language = updateTranslation.language;
          newValues[translationIndex].videoFile = '';
          newValues[translationIndex].loadingVideoUpload = false;
          newValues[translationIndex].videoProgress = 0;
          newValues[translationIndex].imageUrl = '';
          newValues[translationIndex].previewUrl = '';
          newValues[translationIndex].pdfname = 'Supported formats pdf, docs';
          newValues[translationIndex].viewPdf = null;
          newValues[translationIndex].pdfReport = null;
          newValues[translationIndex].pdfLoading = false;
          setTranslatedCollections(newValues);
        }
      } else {
        const triggerTranslation = await onTranslateCollection(
          translateText,
          translateProps,
        );
        if (!triggerTranslation) {
          return toast.error('Something went wrong when Translating');
        }
        newValues.push(triggerTranslation);
        setTranslatedCollections(newValues);
      }
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  const onTabChange = e => {
    setActiveTab(e);
  };

  const handleUploadVideo = async e => {
    const selectedFile = e.target.files[0];
    if (selectedFile.type === 'video/mp4') {
      setVideoUploadLoading(true);
      const response = await UploadVideoToBucket(
        selectedFile,
        'annoucement-images',
        setProgress,
      );
      setVideoUrl(response);
      setVideoUploadLoading(false);
      setProgress(0);
    } else {
      toast.error('Please upload a mp4 video');
    }
  };

  const handlePdfFileChange = async e => {
    const selectedFile = e.target.files[0];
    const fileType = ['application/pdf'];
    setReportName(selectedFile.name);
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        // Upload file to S3 bucket
        setPdfLoading(true);
        const res = await UploadDocumentToBucket(selectedFile);
        setPdfReport(res);
        setPdfLoading(false);
      } else {
        setPdfReport(null);
        toast.error('Please select a valid pdf file');
      }
    } else {
      toast.error('Select a PDF file');
    }
  };
  const handleDeleteFile = () => {
    setPdfReport(null);
    setReportName('Supported formats pdf, docs');
    setViewPdf(null);
  };

  const handleFirstFormSubmit = values => {
    if (imgUrl === '') {
      toast.error('Banner is required');
    } else if (selectedIcon === null) {
      toast.error('icon is required');
    } else {
      setFormValues({...formValues, ...values});
      setSecondFormDisabled(false);
      onTabChange('FINANCIAL');
    }
  };

  const handleSecondFormSubmit = values => {
    if (
      collectionCompanies.length < 1 ||
      collectionCompanies.some(data => !data.company)
    ) {
      toast.error(
        'A Company is Required or Missing Stock Company Field to Proceed',
      );
    } else {
      setFormValues({...formValues, ...values});
      setThirdFormDisabled(false);
      onTabChange('DABA_SCORE');
    }
  };

  const handleAddCompany = () => {
    setCollectionCompanies([
      ...collectionCompanies,
      {company: '', min: null, max: null, default: null},
    ]);
  };

  const onCompanyDelete = id => {
    const filteredFormValue = collectionCompanies.filter(
      (item, index) => index !== id,
    );
    setCollectionCompanies(filteredFormValue);
  };

  const handleCompanyChange = (key, value, type) => {
    if (value < 0) return;

    const updatedCompanies = [...collectionCompanies];
    const company = updatedCompanies[key];

    if (type === 'min') {
      if (!allowUsers && Number(value) === 0) {
        return (company.min = 1);
      }
      if (company.max && value > company.max) {
        company.min = company.max;
      } else {
        company.min = value;
      }
      // Ensure default is not less than min
      if (company.default !== undefined && company.default < company.min) {
        company.default = company.min;
      }
    } else if (type === 'max') {
      if (value < company.min) {
        company.max = company.min;
      } else {
        company.max = value;
      }
      // Ensure default is not more than max
      if (company.default !== undefined && company.default > company.max) {
        company.default = company.max;
      }
    } else if (type === 'default') {
      if (value < company.min) {
        company.default = company.min;
      } else if (company.max && value > company.max) {
        company.default = company.max;
      } else {
        company.default = value;
      }
    } else {
      company[type] = value;
    }

    setCollectionCompanies(updatedCompanies); // Update state with new values
  };

  const [createCollection, {loading: loadingCreation}] =
    useMutation(CREATE_COLLECTION);

  const handleSave = () => {
    const values = thirdForm.getFieldsValue();
    createCollection({
      variables: {
        input: {
          allowUserRemoveCompany: formValues?.allowUsers || false,
          bannerUrl: imgUrl,
          ticker: formValues?.ticker,
          companies: collectionCompanies?.map(data => ({
            companyId: data?.company,
            ...(data?.min != null &&
              data?.min !== '' && {
                min: allowUsers
                  ? Number(data?.min)
                  : Math.max(Number(data?.min), 1),
              }),
            ...(data?.max && {max: Number(data?.max)}),
            ...(data?.default && {default: Number(data?.default)}),
          })),
          currency: 'XOF',
          ...(values.dabaScore &&
            values.socialImpact && {
              dabaScore: {
                accelerator: Number(values?.Accelerator),
                analogus: Number(values?.Analogue),
                business: Number(values?.business),
                economicsAndFinance: Number(values?.Economics),
                industry: Number(values?.IndustryScore),
                legalCompliance: Number(values?.Legal),
                market: Number(values?.Market),
                pdfReport: pdfReport,
                returnOutlook: Number(values?.Returns),
                riskChallenges: Number(values?.Risks),
                score: Number(values?.dabaScore),
                socialImpact: Number(values?.Social),
                strategyProduct: Number(values?.Strategy),
                team: Number(values?.Team),
              },
            }),
          description: formValues?.collectionDescription,
          linkedCollectionGroups: formValues?.linkedCollectionGroups,
          ...(formValues.minCompanyCount && {
            minCompanyCount: Number(formValues?.minCompanyCount),
          }),
          iconUrl: selectedIcon,
          industry: formValues?.industry,
          language: formValues?.language,
          multilang: translatedCollections.map(data => ({
            bannerUrl: data.imageUrl,
            description: data.description,
            name: data.name,
            pdfReport: data.pdfReport,
            videoUrl: data.videoFile,
            language: data.language,
          })),
          name: formValues?.collectionName,
          orderType: formValues?.orderType,
          status: values?.status ? 'Active' : 'InActive',
          stockExchange: formValues?.stockExchange?.split('~')[0],
          ...(videoUrl && {videoUrl: videoUrl}),
        },
      },
      refetchQueries: [
        {query: GET_ALL_COLLECTIONS, variables: {filters: {paginate: true}}},
      ],
    })
      .then(({data: {createCollection}}) => {
        if (createCollection.__typename === 'Error') {
          toast.error(createCollection?.message);
        } else {
          toast.success('Collection created successfully');
          navigate('/dashboard/collections');
        }
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  return (
    <>
      <div
        className={styles.backButton}
        onClick={() => navigate('/dashboard/collections')}>
        <img src={backIcon} alt="Go back" />
        Go Back
      </div>
      <div className={styles['tab-component']}>
        <div className={styles['details-toggler']}>
          <button
            className={activeTab === 'GENERAL' ? styles['active-toggle'] : ''}
            onClick={() => {
              setActiveTab('GENERAL');
            }}>
            {' '}
            General Information
          </button>
          <button
            className={activeTab === 'FINANCIAL' ? styles['active-toggle'] : ''}
            onClick={() => {
              onTabChange('FINANCIAL');
            }}
            disabled={secondFormDisabled}>
            {' '}
            Financial Configuration
          </button>
          <button
            className={
              activeTab === 'DABA_SCORE' ? styles['active-toggle'] : ''
            }
            onClick={() => {
              onTabChange('DABA_SCORE');
            }}
            disabled={thirdFormDisabled}>
            {' '}
            Daba Score
          </button>
        </div>
        <div className={styles['tab-content']}>
          {activeTab === 'GENERAL' && (
            <div>
              <GeneralInfo
                firstForm={firstForm}
                handleFirstFormSubmit={handleFirstFormSubmit}
                previewUrl={previewUrl}
                setImgUrl={setImgUrl}
                selectedIcon={selectedIcon}
                handleTranslation={handleTranslation}
                translatedCollections={translatedCollections}
                setTranslatedCollections={setTranslatedCollections}
                setSelectedIcon={setSelectedIcon}
                videoUploadLoading={videoUploadLoading}
                videoUrl={videoUrl}
                setVideoUrl={setVideoUrl}
                progress={progress}
                handleUploadVideo={handleUploadVideo}
                loadingTranslation={loadingTranslation}
                setSelectedTranslationLanguage={setSelectedTranslationLanguage}
                selectedTranslationLanguage={selectedTranslationLanguage}
                setPreviewUrl={setPreviewUrl}
              />
            </div>
          )}
          {activeTab === 'FINANCIAL' && (
            <div>
              <FinancialInfo
                secondForm={secondForm}
                handleAddCompany={handleAddCompany}
                handleSecondFormSubmit={handleSecondFormSubmit}
                collectionCompanies={collectionCompanies}
                allowUsers={allowUsers}
                activeTab={activeTab}
                setAllowUsers={setAllowUsers}
                onCompanyDelete={onCompanyDelete}
                onTabChange={onTabChange}
                handleCompanyChange={handleCompanyChange}
              />
            </div>
          )}
          {activeTab === 'DABA_SCORE' && (
            <div>
              <DabaScore
                thirdForm={thirdForm}
                setOpenConfirmModal={setOpenConfirmModal}
                handleDeleteFile={handleDeleteFile}
                handlePdfFileChange={handlePdfFileChange}
                onTabChange={onTabChange}
                viewPdf={viewPdf}
                pdfLoading={pdfLoading}
                pdfReport={pdfReport}
                reportName={reportName}
              />
            </div>
          )}
        </div>
      </div>

      <ConfirmModal
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        title={'Create Collection'}
        confirmText={'Are you sure that you want to create this collection?'}
        confirmation={true}
        loading={loadingCreation}
        handleOperation={handleSave}
      />
    </>
  );
};
