import {DatePicker, Form, Select, Tooltip} from 'antd';
import styles from './usersFilter.module.scss';
import {depositStatus, kycStatusEnums, profileCompletionEnum} from 'utils/mock';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useCallback, useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {countryISOs, GET_ALL_CITIES} from 'graphql/queries/trendingCountries';
import {debounce} from 'utils/helper';
import {GET_ALL_COUNTRIES} from 'graphql/queries/users';
import moment from 'moment';
import {usersFilterEnumOptions} from 'utils/constants';

const UsersFilter = ({handleClose, handleFilter, activePage}) => {
  const [cities, setCities] = useState([]);
  const [form] = Form.useForm();

  const {data, loading} = useQuery(GET_ALL_COUNTRIES);
  const {
    refetch,
    networkStatus,
    loading: loadingCities,
  } = useQuery(GET_ALL_CITIES, {
    variables: {
      filter: {
        shouldPaginate: true,
        limit: 500,
      },
    },
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      if (data?.getAllCities?.data) {
        setCities(data?.getAllCities?.data);
      }
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    form.resetFields();
  }, [activePage]);

  const onSearchCity = value => {
    refetch({
      filter: {
        search: value,
        limit: 500,
        shouldPaginate: true,
      },
    });
  };

  const optimizedVersion = useCallback(debounce(onSearchCity), []);

  return (
    <div>
      <div className={styles.header}>
        <h1>Filter Users</h1>
        <p role="button" onClick={handleClose}>
          X
        </p>
      </div>
      <Form
        className={styles.form}
        form={form}
        onFinish={handleFilter}
        layout="vertical">
        <Form.Item
          className={styles.fieldWidth}
          name="nationality"
          label="Nationality">
          <Select allowClear showSearch loading={loadingCities}>
            {loading && (
              <Select.Option key="Loading...">Loading...</Select.Option>
            )}
            {data?.getAllCountries?.map(data => (
              <Select.Option key={data?.name}>
                <div className={styles.allData}>
                  <img src={data?.icon} />
                  <span>{data?.name}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className={styles.fieldWidth} name="city" label="City">
          <Select
            onSearch={value => optimizedVersion(value)}
            allowClear
            filterOption={false}
            showSearch>
            <>
              {cities &&
                cities?.map(data => (
                  <Select.Option key={data?.city}>{data?.city}</Select.Option>
                ))}
              {(networkStatus === 2 || loadingCities) && (
                <Select.Option key="Loading...">Loading...</Select.Option>
              )}
            </>
          </Select>
        </Form.Item>
        <Form.Item
          className={styles.fieldWidth}
          name="country"
          label="Country of Citizenship (KYC)">
          <Select optionFilterProp="label" allowClear showSearch>
            {countryISOs.map(data => (
              <Select.Option label={data.label} key={data.value}>
                {data.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className={styles.fieldWidth}
          name="profileCompletion"
          label="Profile Completion">
          <Select allowClear>
            {profileCompletionEnum.map(data => (
              <Select.Option label={data.label} key={data.value}>
                {data.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="startDate" label="Start Date (Joined)">
          <DatePicker
            className={styles.fieldWidth}
            allowClear
            format={'YYYY-MM-DD HH:mm:ss'}
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
          />
        </Form.Item>
        <Form.Item name="endDate" label="End Date (Joined)">
          <DatePicker
            className={styles.fieldWidth}
            allowClear
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
            format={'YYYY-MM-DD HH:mm:ss'}
          />
        </Form.Item>
        <Form.Item
          name="walletTransactionStartDate"
          label={
            <Tooltip
              placement="topLeft"
              title="All Users with Wallet Transactions that starts at this date">
              Start Date (Transaction)
            </Tooltip>
          }>
          <DatePicker
            className={styles.fieldWidth}
            allowClear
            format={'YYYY-MM-DD HH:mm:ss'}
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
          />
        </Form.Item>
        <Form.Item
          name="walletTransactionEndDate"
          label={
            <Tooltip
              placement="topRight"
              title="All Users with Wallet Transactions that ends at this date">
              End Date (Transaction)
            </Tooltip>
          }>
          <DatePicker
            className={styles.fieldWidth}
            allowClear
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
            format={'YYYY-MM-DD HH:mm:ss'}
          />
        </Form.Item>
        <div className={styles.deposit}>
          <Form.Item name="kycStatus" label="KYC Status">
            <Select disabled={activePage !== 'ALL'} allowClear showSearch>
              {kycStatusEnums.map(data => (
                <Select.Option key={data.value}>{data.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="walletTransactionType" label="Transaction Type">
            <Select allowClear showSearch>
              <Select.Option key="DEPOSIT">Deposit</Select.Option>
              <Select.Option key="WITHDRAWAL">Withdrawal</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="depositStatus" label="Transaction Status">
            <Select allowClear showSearch>
              {depositStatus.map(data => (
                <Select.Option key={data.value}>{data.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className={styles.deposit}>
          <Form.Item
            label={
              <Tooltip
                placement="topLeft"
                title="All Users that has Referred someone">
                Has Referral
              </Tooltip>
            }
            name="hasMadeReferral">
            <Select allowClear placeholder="Either">
              {usersFilterEnumOptions.map(option => (
                <Select.Option key={option.value}>
                  <span>{option.label}</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="hasMadeDeposit"
            label={
              <Tooltip
                placement="topLeft"
                title="All Users that has Successfully Deposited money in their wallet">
                Successful Deposit
              </Tooltip>
            }>
            <Select allowClear placeholder="Either">
              {usersFilterEnumOptions.map(option => (
                <Select.Option key={option.value}>
                  <span>{option.label}</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="hasMadeInvestment"
            label={
              <Tooltip
                placement="topLeft"
                title="All Users that has Successfully Invested (stocks, startups)">
                Made Investment
              </Tooltip>
            }>
            <Select allowClear placeholder="Either">
              {usersFilterEnumOptions.map(option => (
                <Select.Option key={option.value}>
                  <span>{option.label}</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <SubmitButton label="Save" />
      </Form>
    </div>
  );
};

export default UsersFilter;
