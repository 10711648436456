import {Dropdown, Menu, Table, Tag} from 'antd';
import styles from './documentCenter.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useNavigate} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {GET_COMPANY_DOCUMENTS} from 'graphql/queries/companyDocument';
import dotsIcon from 'icons/dots.svg';
import moment from 'moment';
import {useState} from 'react';
import DBModal from 'components/modal/modal';
import {DELETE_SINGLE_COMPANY_DOCUMENT} from 'graphql/mutations/documentCenter';
import {Button} from '@dabafinance/react-components';
import {toast} from 'react-toastify';

const DocumentCenter = ({id}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const navigate = useNavigate();

  const {data, loading} = useQuery(GET_COMPANY_DOCUMENTS, {
    variables: {
      filters: {
        companyId: id,
      },
    },
    fetchPolicy: 'network-only',
  });

  const [deleteOneCompanyDocument, {loading: loadingDelete}] = useMutation(
    DELETE_SINGLE_COMPANY_DOCUMENT,
    {
      variables: {
        documentId: deleteId,
      },
    },
  );

  const handleOpenModal = (e, id) => {
    if (e.domEvent.isTrusted) {
      setOpenDeleteModal(true);
      setDeleteId(id);
    }
  };

  const handleDeleteCompanyDocument = () => {
    deleteOneCompanyDocument({
      variables: {
        documentId: deleteId,
      },
      refetchQueries: [
        {query: GET_COMPANY_DOCUMENTS, variables: {companyId: id}},
      ],
    })
      .then(({data: deleteOneCompanyDocument}) => {
        if (deleteOneCompanyDocument.__typename === 'Error') {
          toast.error(
            `Something went wrong!!! - ${deleteOneCompanyDocument.message} `,
          );
        } else {
          toast.success('Document Deleted Successfully');
          setOpenDeleteModal(false);
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err}`));
  };

  const columns = [
    {
      key: 'documentName',
      dataIndex: 'documentName',
      title: 'Document Name',
    },
    {
      key: 'category',
      dataIndex: 'documentCategory',
      title: 'Document Category',
      render: (text, render) => (
        <Tag color="cyan">
          {render?.documentCategory?.name
            ?.replace(/_/g, ' ')
            ?.toLowerCase()
            ?.replace(/\b\w/g, c => c.toUpperCase())}
        </Tag>
      ),
    },
    {
      key: 'languageRestriction',
      dataIndex: 'languageRestriction',
      title: 'Language Restriction',
      render: (text, render) => (
        <Tag
          color={render.restrictLanguage ? 'green' : 'red'}
          key={render.restrictLanguage}>
          {render.restrictLanguage ? 'Yes' : 'No'}
        </Tag>
      ),
    },
    {
      key: 'issuedDate',
      dataIndex: 'issuedDate',
      title: 'Issued Date',
      render: (text, render) => (
        <span>{moment(render?.issuedDate)?.format('Do MMMM YYYY')}</span>
      ),
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      title: 'Actions',
      render: (text, record) => (
        <Dropdown
          trigger="click"
          overlay={
            <Menu>
              <Menu.Item
                onClick={() =>
                  navigate(
                    `/dashboard/document-center/update/${id}/${record?.id}`,
                  )
                }
                key="1">
                Update
              </Menu.Item>
              <Menu.Item key="2" onClick={e => handleOpenModal(e, record?.id)}>
                Delete
              </Menu.Item>
            </Menu>
          }>
          <div>
            <img src={dotsIcon} />
          </div>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <h1>Company Document</h1>

        <SubmitButton
          handleClick={() => navigate(`/dashboard/document-center/new/${id}`)}
          label="Create"
        />
      </div>
      <Table
        columns={columns}
        dataSource={data?.getCompanyDocument?.data}
        loading={loading}
      />
      <DBModal
        isOpen={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        content={
          <div className={styles.deleteModal}>
            <h1>Delete Company Document</h1>
            <span>Are you sure you want to delete this Company Document?</span>
            <div className={styles.deleteButtons}>
              <Button
                onClick={() => setOpenDeleteModal(false)}
                type="secondary"
                label="NO"
              />
              <Button
                disabled={loadingDelete}
                onClick={handleDeleteCompanyDocument}
                label="YES"
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default DocumentCenter;
