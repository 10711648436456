import {useMutation, useQuery} from '@apollo/client';
import {Dropdown, Table, Tag} from 'antd';
import {GET_ALL_COLLECTION_GROUPS} from 'graphql/queries/collections';
import {toast} from 'react-toastify';
import styles from './collectionGroups.module.scss';
import {Button} from '@dabafinance/react-components';
import {useNavigate} from 'react-router-dom';
import dotsIcon from 'images/dots.svg';
import {useState} from 'react';
import DBModal from 'components/modal/modal';
import {DELETE_COLLECTION_GROUP} from 'graphql/mutations/collections';

const CollectionGroups = () => {
  const [modalContent, setModalContent] = useState({isOpen: false});
  const navigate = useNavigate();

  const {data, loading, refetch} = useQuery(GET_ALL_COLLECTION_GROUPS, {
    variables: {
      filters: {
        paginate: false,
      },
    },
    errorPolicy: 'all',
    onError: error => toast.error(error?.message),
  });
  const [deleteCollectionGroup, {loading: loadingDelete}] = useMutation(
    DELETE_COLLECTION_GROUP,
  );

  const columns = [
    {
      dataIndex: 'title',
      key: 'title',
      title: 'Title',
      render: (text, record) => (
        <div className={styles.imageRecord}>
          <img src={record?.imageUrl} />
          <p>{record?.title}</p>
        </div>
      ),
    },
    {
      dataIndex: 'language',
      key: 'language',
      title: 'Language',
      render: (text, record) => (
        <Tag color="default">
          {record?.language === 'EN' ? 'English' : 'French'}
        </Tag>
      ),
    },
    {
      dataIndex: 'collectionCount',
      key: 'collectionCount',
      title: 'No. of Collections',
    },
    {
      dataIndex: 'visible',
      key: 'visible',
      title: 'Visible',
      render: (text, record) => (
        <Tag color={record?.isVisible ? 'green' : 'red'}>
          {record?.isVisible ? 'Visible' : 'Hidden'}
        </Tag>
      ),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: 'Actions',
      render: (text, render) => (
        <Dropdown
          menu={{
            items: [
              {
                label: 'Update Collection Group',
                onClick: () =>
                  navigate(
                    `/dashboard/collections/groups/update/${render?.id}`,
                  ),
              },
              {
                label: 'Delete Collection Group',
                onClick: () => setModalContent({isOpen: true, id: render?.id}),
              },
            ],
          }}
          trigger="click">
          <div>
            <img src={dotsIcon} alt="" />
          </div>
        </Dropdown>
      ),
    },
  ];

  const handleDelete = () => {
    deleteCollectionGroup({
      variables: {
        collectionGroupId: modalContent?.id,
      },
    }).then(({data: {deleteCollectionGroup}}) => {
      if (deleteCollectionGroup.__typename === 'Error') {
        toast.error(deleteCollectionGroup.message);
      } else {
        toast.success('Delete Successfully');
        refetch();
        setModalContent({isOpen: false});
      }
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <h2>Collection Groups</h2>
        <Button
          onClick={() => navigate('/dashboard/collections/groups/create')}
          type="secondary"
          label="Create Collection Group"
        />
      </div>
      <div>
        <Table
          dataSource={data?.getAllCollectionGroups?.data?.collectionGroups}
          columns={columns}
          loading={loading}
        />
      </div>
      <DBModal
        isOpen={modalContent.isOpen}
        handleClose={() => setModalContent({isOpen: false})}
        content={
          <div className={styles.modalRoot}>
            <div className={styles.modalTop}>
              <h1>Delete This Collection Group</h1>
              <span>Are you sure you want to delete this Collection </span>
            </div>
            <div className={styles.buttons}>
              <Button
                disabled={loadingDelete}
                label="Yes"
                onClick={handleDelete}
              />
              <Button
                label="No"
                type="secondary"
                onClick={() => setModalContent({isOpen: false})}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default CollectionGroups;
