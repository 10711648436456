import {useState} from 'react';
import styles from './companyDocCategory.module.scss';
import CompanyDocumentCategories from './CompanyDocumentCategories';
import {Dropdown, Table, Tag} from 'antd';
import {useMutation, useQuery} from '@apollo/client';
import {GET_COMPANY_DOCUMENTS} from 'graphql/queries/companyDocument';
import {toast} from 'react-toastify';
import {Button} from '@dabafinance/react-components';
import {removePrefix} from 'utils/helper';
import {ViewUserDocument} from 'pages/adminUser/userDetails/tabs/documents/viewDocument';
import DBModal from 'components/modal/modal';
import dotsIcon from 'images/dots.svg';
import {DELETE_SINGLE_COMPANY_DOCUMENT} from 'graphql/mutations/documentCenter';
import {useNavigate} from 'react-router-dom';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';
import {useSelector} from 'react-redux';
import moment from 'moment';

const CompanyDocument = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState({});
  const [viewModal, setViewModal] = useState({});
  const [activeTab, setActiveTab] = useState('DOCUMENTS');
  const navigate = useNavigate();
  const {data, loading, refetch} = useQuery(GET_COMPANY_DOCUMENTS, {
    variables: {
      filter: {
        sort: 'DESC',
        page: 1,
        limit: 500,
      },
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
  });
  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.DocCategory),
  );

  const [deleteOneCompanyDocument, {loading: loadingDelete}] = useMutation(
    DELETE_SINGLE_COMPANY_DOCUMENT,
    {
      variables: {
        documentId: openDeleteModal?.id,
      },
    },
  );

  const columns = [
    {
      title: 'Document Name',
      dataIndex: 'documentName',
      key: 'documentName',
      fixed: 'left',
      render: (text, record) => (
        <div className={styles.nameRowWidth}>
          <span>{removePrefix(record?.documentName)}</span>{' '}
          <Tag color={record?.language === 'EN' ? 'geekblue' : 'magenta'}>
            {record?.language}
          </Tag>
        </div>
      ),
    },
    {
      title: 'Document Category',
      dataIndex: 'documentCategory',
      key: 'documentCategory',
      render: (text, render) => (
        <Tag color="blue">
          {render?.documentCategory?.name?.split('_')?.join(' ')}
        </Tag>
      ),
    },
    {
      title: 'Issued Date',
      dataIndex: 'issuedDate',
      key: 'issuedDate',
      render: (text, record) => (
        <span>
          {record?.issuedDate
            ? moment(record?.issuedDate).format('DD MMMM, YYYY h:mmA')
            : ''}
        </span>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <div className={styles.actions}>
          <Button
            onClick={() =>
              setViewModal({
                isOpen: true,
                documentUrl: record?.documentUrl,
                documentName: removePrefix(record?.documentName),
              })
            }
            type="secondary"
            label="View Document"
            className={styles['deactivate-btn']}
          />
          <Dropdown
            trigger="hover"
            menu={{
              items: [
                {
                  label: 'Delete Document',
                  onClick: () =>
                    setOpenDeleteModal({isOpen: true, id: record?.id}),
                  disabled: !tabOperations?.delete,
                },
              ],
            }}>
            <div>
              <img src={dotsIcon} />
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  const handleDeleteCompanyDocument = () => {
    deleteOneCompanyDocument({
      variables: {
        documentId: openDeleteModal?.id,
      },
    })
      .then(({data: deleteOneCompanyDocument}) => {
        if (deleteOneCompanyDocument.__typename === 'Error') {
          toast.error(
            `Something went wrong!!! - ${deleteOneCompanyDocument.message} `,
          );
        } else {
          toast.success('Document Deleted Successfully');
          setOpenDeleteModal(false);
          refetch();
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err}`));
  };

  return (
    <div className={styles.companyRoot}>
      <div className={styles.companyNav}>
        <div className={styles['details-toggler']}>
          <span
            className={activeTab === 'DOCUMENTS' ? styles['active-toggle'] : ''}
            onClick={() => setActiveTab('DOCUMENTS')}>
            DOCUMENTS
          </span>
          <span
            className={
              activeTab === 'CATEGORIES' ? styles['active-toggle'] : ''
            }
            onClick={() => setActiveTab('CATEGORIES')}>
            CATEGORIES
          </span>
        </div>
        {activeTab === 'DOCUMENTS' && (
          <Button
            label="Create Document"
            disabled={!tabOperations?.create}
            onClick={() => navigate(`/dashboard/document-center/new/none`)}
          />
        )}
      </div>
      {activeTab === 'DOCUMENTS' && (
        <div className={styles.documentsTable}>
          <Table
            loading={loading}
            columns={columns}
            dataSource={data?.getCompanyDocument?.data}
          />
        </div>
      )}
      {activeTab === 'CATEGORIES' && <CompanyDocumentCategories />}
      <DBModal
        isOpen={viewModal.isOpen}
        handleClose={() => setViewModal({isOpen: false})}
        width={900}
        content={
          <ViewUserDocument
            url={viewModal?.documentUrl}
            documentName={viewModal?.documentName}
          />
        }
      />
      <DBModal
        isOpen={openDeleteModal?.isOpen}
        handleClose={() => setOpenDeleteModal(false)}
        content={
          <div className={styles.deleteModal}>
            <h1>Delete Company Document</h1>
            <span>Are you sure you want to delete this Company Document?</span>
            <div className={styles.deleteButtons}>
              <Button
                onClick={() => setOpenDeleteModal(false)}
                type="secondary"
                label="NO"
              />
              <Button
                disabled={loadingDelete}
                onClick={handleDeleteCompanyDocument}
                label="YES"
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default CompanyDocument;
