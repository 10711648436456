import {useState, useEffect} from 'react';
import DBModal from 'components/modal/modal';
import {Search} from '@dabafinance/react-components';
import {GET_TOPIC_BY_ID, GET_ALL_TOPICS} from 'graphql/queries/interest';
import {useQuery, useMutation} from '@apollo/client';
import styles from '../adminInterests.module.scss';
import {Form, InputNumber} from 'antd';
import {UPDATE_TOPIC} from 'graphql/mutations/interest';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import SubmitButton from 'components/Startup/components/SubmitButton';

export const RankModal = ({id}) => {
  const [sequence, setSequence] = useState('');
  const [name, setName] = useState('');
  const [openSingleModal, setOpenSingleModal] = useState(true);
  const {data} = useQuery(GET_TOPIC_BY_ID, {
    variables: {
      topicId: id,
    },
  });

  const navigate = useNavigate();

  const [updateTopic] = useMutation(UPDATE_TOPIC);

  const handleUpdate = () => {
    updateTopic({
      variables: {
        input: {
          topicId: id,
          name,
          sequence,
        },
      },
      refetchQueries: [{query: GET_ALL_TOPICS}],
    }).then(({data: {updateTopic}}) => {
      if (updateTopic.__typename === 'Error') {
        toast.error(updateTopic.message);
      } else {
        toast.success('Interest Updated Successfully');
        setOpenSingleModal(false);
        navigate('/dashboard/interest');
      }
    });
  };

  useEffect(() => {
    setName(data?.getTopic?.name);
    setSequence(data?.getTopic?.sequence);
  }, [data?.getTopic]);

  return (
    <div>
      <DBModal
        isOpen={openSingleModal}
        handleClose={() => setOpenSingleModal(false)}
        content={
          <div className={styles['modal-container']}>
            <h2>Change Topic Ranking Order </h2>
            <div>
              <Search
                id="search-interests"
                placeholder="Search Interests"
                onChange={() => {}}
                className={styles.search}
              />
              <div className={styles['topic-section']}>
                <div className={styles.topics}>
                  <div className={styles['topic-info']}>
                    <img src={data?.getTopic?.icon} alt="" />
                    <h4>{data?.getTopic?.name}</h4>
                    <p>Topic</p>
                    <p>
                      Current Ranking: <span>{data?.getTopic?.sequence}</span>
                    </p>
                    <Form onFinish={handleUpdate} layout="vertical">
                      <Form.Item
                        label="Enter New Rank"
                        className={styles.field}
                        name="sequence">
                        <InputNumber
                          defaultValue={data?.getTopic?.sequence}
                          className={styles['input-number']}
                          onChange={e => setSequence(e)}
                          placeholder={'Enter new rank'}
                        />
                      </Form.Item>
                      <SubmitButton
                        handleClick={() => handleUpdate()}
                        label="Update"
                      />
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};
