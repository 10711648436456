import {useEffect, useState} from 'react';
import {ArrowLeft} from 'icons';
import {Form, Select, Input, Spin} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import UploadImage from 'components/Startup/profile/companyImage';
import {useMutation, useQuery} from '@apollo/client';
import plusIcon from 'images/plus-circle.png';
import styles from './topicList.module.scss';
import deleteIcon from 'images/delete.png';
import {useNavigate, useParams} from 'react-router-dom';
import FadeEffect from 'components/animation/fadeEffect';
import {GET_ALL_STARTUPS} from 'graphql/queries/startupCompanies';
import {toast} from 'react-toastify';
import {GET_ALL_TOPICS, GET_A_TOPIC_LIST} from 'graphql/queries/interest';
import {UPDATE_TOPIC, UPDATE_TOPIC_LIST} from 'graphql/mutations/interest';

const UpdateTopicList = () => {
  const [icon, setIcon] = useState('');
  const [iconPreviewUrl, setIconPreviewUrl] = useState('');
  const [bannerImg, setBannerImg] = useState('');
  const [bannerPreviewUrl, setBannerPreviewUrl] = useState('');
  const [type, setType] = useState('');
  const [topicType, setTopicType] = useState('');
  const [topic, setTopic] = useState({});
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [sequence, setSequence] = useState('');
  const [companies, setCompanies] = useState([]);
  const [opportunities, setOpportunities] = useState([]);

  const navigate = useNavigate();
  const fieldWidth = 720;
  const {id} = useParams();
  const {Option} = Select;

  const {data: allCompanies, loading} = useQuery(GET_ALL_STARTUPS);
  const {data, loading: listLoading} = useQuery(GET_A_TOPIC_LIST, {
    variables: {
      topicListId: id,
    },
  });

  const [updateTopicList, {loading: updateLoading}] =
    useMutation(UPDATE_TOPIC_LIST);

  useEffect(() => {
    setName(data?.getTopicList?.name);
    setType(data?.getTopicList?.listType);
    setBannerImg(data?.getTopicList?.topic?.bannerImg);
    setBannerPreviewUrl(data?.getTopicList?.topic?.bannerImg);
    setSequence(data?.getTopicList?.topic?.sequence);
    setDescription(data?.getTopicList?.topic?.description);
    setIcon(data?.getTopicList.icon);
    setIconPreviewUrl(data?.getTopicList?.icon);
    setCompanies(
      data?.getTopicList?.companies?.map(company => ({
        id: company?.company?.id,
        company: company?.company?.legalName,
        sequence: company?.sequence,
      })),
    );
    setOpportunities(
      data?.getTopicList?.opportunities?.map(opportunity => ({
        id: opportunity?.opportunity?.id,
        opportunity: opportunity?.opportunity?.legalName,
        sequence: opportunity?.sequence,
      })),
    );
    setTopic(data?.getTopicList?.topic);
  }, [data?.getTopicList]);

  const handleChangeCompany = (i, e) => {
    const newFormValues = [...companies];
    newFormValues[i].company = e.split(';')[0];
    newFormValues[i].id = e.split(';')[1];
    setCompanies(newFormValues);
  };

  const handleChangeOpportunity = (i, e) => {
    const newFormValues = [...opportunities];
    newFormValues[i].opportunity = e.split(';')[0];
    newFormValues[i].id = e.split(';')[1];
    setOpportunities(newFormValues);
  };

  const handleChangeCompanySequence = (i, e) => {
    const newFormValues = [...companies];
    newFormValues[i][e.target.name] = e.target.value;
    setCompanies(newFormValues);
  };

  const handleChangeOpportunitySequence = (i, e) => {
    const newFormValues = [...opportunities];
    newFormValues[i][e.target.name] = e.target.value;
    setOpportunities(newFormValues);
  };

  const handleAddCompanyTopic = () => {
    setCompanies([
      ...companies,
      {
        id: '',
        company: '',
        sequence: 0,
      },
    ]);
  };

  const handleAddOpportunityTopics = () => {
    setOpportunities([
      ...opportunities,
      {
        id: '',
        opportunity: '',
        sequence: 0,
      },
    ]);
  };

  const handleDeleteCompany = id => {
    const filteredFormValue = companies.filter((data, index) => index !== id);
    setCompanies(filteredFormValue);
  };

  const handleDeleteOpportunity = id => {
    const filteredFormValue = opportunities.filter(
      (data, index) => index !== id,
    );
    setOpportunities(filteredFormValue);
  };

  const handleSave = () => {
    updateTopicList({
      variables: {
        input: {
          topicListId: id,
          topicId: data?.getTopicList?.topic?.id,
          name,
          description,
          listType: type,
          icon,
          bannerImg,
          sequence,
          companies: companies?.map(data => ({
            company: data?.id,
            sequence: parseInt(data?.sequence),
          })),
          opportunities: opportunities?.map(data => ({
            opportunity: data?.id,
            sequence: parseInt(data?.sequence),
          })),
        },
      },
    })
      .then(({data: {updateTopicList}}) => {
        if (updateTopicList.__typename === 'Error') {
          toast.error(updateTopicList.message);
        } else {
          toast.success('Topic List Updated Successfully');
          navigate('/dashboard/interest/list');
        }
      })
      .catch(error => toast.error(error));
  };

  const [updateTopic, {loading: topicUpdate}] = useMutation(UPDATE_TOPIC);

  const handleUpdate = () => {
    updateTopic({
      variables: {
        input: {
          topicId: topic?.id,
          type: topicType,
          name: topic?.name,
          icon: topic?.icon,
          bannerImg: topic?.bannerImg,
          description: topic?.description,
          sequence: topic?.sequence,
          multilang: topic?.multilang.map(item => {
            return {
              name: item?.name,
              description: item?.description,
              language: item?.language,
            };
          }),
          language: topic?.language,
          region: topic?.region || 'NONE',
          relatedCompanies: topic?.relatedCompanies,
        },
      },
      refetchQueries: [{query: GET_ALL_TOPICS}],
    }).then(({data: {updateTopic}}) => {
      if (updateTopic.__typename === 'Error') {
        toast.error(updateTopic.message);
      } else {
        toast.success('Interest Category Updated Successfully');
        navigate('/dashboard/interest');
      }
    });
  };

  if (loading || listLoading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button onClick={() => navigate(-1)}>
          <ArrowLeft />
        </button>
        <h1>Update Topic List</h1>
      </div>
      <div className={styles['inner-container']}>
        <div className={styles.section}>
          <h2 className={styles['sub-heading']}>Topic Icon</h2>
          <UploadImage
            previewUrl={iconPreviewUrl}
            setPreviewUrl={setIconPreviewUrl}
            setImgUrl={setIcon}
            bucket="company-service-bucket"
          />
        </div>
        {/* Interest Banner */}
        <div className={styles.section}>
          <h2 className={styles['sub-heading']}>Topic Banner</h2>
          <UploadImage
            previewUrl={bannerPreviewUrl}
            setPreviewUrl={setBannerPreviewUrl}
            setImgUrl={setBannerImg}
            large
            bucket="company-service-bucket"
          />
        </div>
        <Form onFinish={handleSave} layout="vertical">
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Name is required',
              },
            ]}
            label="Name"
            className={styles['sub-heading']}
            name="name">
            <Input
              style={{width: fieldWidth}}
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder="Name"
              defaultValue={data?.getTopicList?.name}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: false,
              },
            ]}
            label="About Topic"
            className={styles['topic-width']}
            name="about topic">
            <Input.TextArea
              value={description}
              defaultValue={data?.getTopicList?.topic?.description}
              onChange={e => setDescription(e.target.value)}
              rows={6}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Category is required',
              },
            ]}
            label="List Type"
            className={styles['topic-width']}
            name="category">
            <Select
              value={type}
              defaultValue={data?.getTopicList?.listType}
              onChange={e => setType(e)}>
              <Option value="COMPANIES">Companies</Option>
              <Option value="OPPORTUNITIES">Opportunities</Option>
              <Option value="HYBRID">Hybrid</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Sequence"
            className={styles['topic-width']}
            name="sequence">
            <Input
              value={sequence}
              defaultValue={data?.getTopicList?.topic?.sequence}
              className={styles['input-number']}
              type="number"
              onChange={e => setSequence(e.target.value)}
            />
          </Form.Item>
          <div>
            <h1>Topic Companies</h1>
            {companies?.map((value, index) => (
              // eslint-disable-next-line react/jsx-key
              <FadeEffect>
                <div className={styles['topic-lists']} key={index}>
                  <Form.Item style={{width: 320}} label="Company">
                    <Select
                      value={value.company}
                      onChange={e => handleChangeCompany(index, e)}
                      placeholder="Select Company"
                      showSearch>
                      {allCompanies?.getAllCompanies?.map(
                        ({id, legalName, logoImgURL}) => (
                          <Option key={`${legalName};${id}`}>
                            <div className={styles['all-data']}>
                              <img src={logoImgURL} alt="" />
                              <span>{legalName}</span>
                            </div>
                          </Option>
                        ),
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item style={{width: 320}} label="Sequence">
                    <Input
                      type={'number'}
                      name="sequence"
                      placeholder="Seqeunce"
                      value={value.sequence}
                      onChange={e => handleChangeCompanySequence(index, e)}
                    />
                  </Form.Item>
                  <div className={styles.delete}>
                    <img
                      onClick={() => handleDeleteCompany(index)}
                      src={deleteIcon}
                      alt=""
                    />
                  </div>
                </div>
              </FadeEffect>
            ))}
            <div
              onClick={handleAddCompanyTopic}
              className={styles['add-button']}>
              <img src={plusIcon} alt="" />
              <h1>Add Company</h1>
            </div>
          </div>
          <div className={styles.opportunity}>
            <h1>Topic Opportunities</h1>
            {opportunities?.map((value, index) => (
              // eslint-disable-next-line react/jsx-key
              <FadeEffect>
                <div className={styles['topic-lists']} key={index}>
                  <Form.Item style={{width: 320}} label="Opportunity">
                    <Select
                      value={value.opportunity}
                      onChange={e => handleChangeOpportunity(index, e)}
                      showSearch
                      placeholder="Select Opportunity">
                      {allCompanies?.getAllCompanies?.map(
                        ({id, legalName, logoImgURL}) => (
                          <Option key={`${legalName};${id}`}>
                            <div className={styles['all-data']}>
                              <img src={logoImgURL} alt="" />
                              <span>{legalName}</span>
                            </div>
                          </Option>
                        ),
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item style={{width: 320}} label="Sequence">
                    <Input
                      type={'number'}
                      name="sequence"
                      placeholder="Seqeunce"
                      value={value.sequence}
                      onChange={e => handleChangeOpportunitySequence(index, e)}
                    />
                  </Form.Item>
                  <div className={styles.delete}>
                    <img
                      onClick={() => handleDeleteOpportunity(index)}
                      src={deleteIcon}
                      alt=""
                    />
                  </div>
                </div>
              </FadeEffect>
            ))}
            <div
              onClick={handleAddOpportunityTopics}
              className={styles['add-button']}>
              <img src={plusIcon} alt="" />
              <h1>Add Opportunity </h1>
            </div>
          </div>
          <SubmitButton
            disabled={updateLoading}
            handleClick={handleSave}
            label="Update Topic List"
          />
        </Form>
        <hr />
        <Form
          onFinish={handleSave}
          layout="vertical"
          style={{marginTop: '20px'}}>
          <Form.Item
            style={{fieldWidth}}
            label="Category"
            className={styles['sub-heading']}
            name="category">
            <Select
              defaultValue={topic?.type}
              value={topicType}
              onChange={e => setTopicType(e)}>
              <Option value="COUNTRY">Country</Option>
              <Option value="INDUSTRY">Industry</Option>
              <Option value="LIST">List</Option>
              <Option value="GENERAL">General</Option>
            </Select>
          </Form.Item>
          <SubmitButton
            disabled={topicUpdate}
            handleClick={handleUpdate}
            label="Update Topic Category"
          />
        </Form>
      </div>
    </div>
  );
};

export default UpdateTopicList;
