import {Form, InputNumber} from 'antd';
import styles from './swapSettings.module.scss';
import {useEffect} from 'react';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useMutation} from '@apollo/client';
import {UPDATE_WALLET_CURRENCY_SETTINGS} from 'graphql/mutations/wallet';
import {toast} from 'react-toastify';

const SwapSettings = ({data, closeModal, refetch}) => {
  const [form] = Form.useForm();

  const [updatePlatformWalletCurrencySettings, {loading}] = useMutation(
    UPDATE_WALLET_CURRENCY_SETTINGS,
  );

  useEffect(() => {
    form.setFieldsValue({
      minAmount: data?.minAmount,
      maxAmount: data?.maxAmount,
    });
  }, [data]);

  const handleFinish = values => {
    updatePlatformWalletCurrencySettings({
      variables: {
        input: {
          currency: data?.currency,
          swap: {
            maxSwapAmountInSourceCurrency: Number(values?.maxAmount),
            minSwapAmountInSourceCurrency: Number(values?.minAmount),
          },
        },
      },
    })
      .then(({data: {updatePlatformWalletCurrencySettings}}) => {
        if (updatePlatformWalletCurrencySettings.__typename === 'Error') {
          toast.error(updatePlatformWalletCurrencySettings.message);
        } else {
          toast.success('Updated Successfully');
          closeModal();
          refetch();
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1>Update {data?.currency} Swap Settings</h1>
      </div>
      <Form
        form={form}
        onFinish={handleFinish}
        className={styles.form}
        layout="vertical">
        <Form.Item
          rules={[{required: true, message: 'This Field is Required'}]}
          name="minAmount"
          className={styles.fieldWidth}
          label="Minimum Swap Amount For Source Currency">
          <InputNumber
            className={styles.width}
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: 'This Field is Required'}]}
          name="maxAmount"
          className={styles.fieldWidth}
          label="Maximum Swap Amount For Source Currency">
          <InputNumber
            className={styles.width}
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <SubmitButton disabled={loading} label="Update" />
      </Form>
    </div>
  );
};

export default SwapSettings;
