import {useLazyQuery, useMutation} from '@apollo/client';
import DBModal from 'components/modal/modal';
import {Form, Select, Spin, Input, Tag} from 'antd';
import styles from './swapTransactions.module.scss';

import SubmitButton from 'components/Startup/components/SubmitButton';
import {
  FULFIL_OR_REJECT_SWAP_TRANSACTION,
  UPDATE_SWAP_TRANSACTION_RATE,
} from 'graphql/mutations/wallet';
import {GET_PUBLIC_EXCHANGE_RATES} from 'graphql/queries/wallet';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {Button} from '@dabafinance/react-components';
import {currencySymbols} from 'utils/mock';

const SwapModal = ({updateModal, setUpdateModal, refetch}) => {
  const [firstForm] = Form.useForm();
  const [secondForm] = Form.useForm();
  const [openFinishScreen, setOpenFinishScreen] = useState(false);
  const [currentRate, setCurrentRate] = useState('');
  const [fulfilStatus, setFulfilStatus] = useState('');
  const [getPublicFxExchangeRates, {loading: loadingRates}] = useLazyQuery(
    GET_PUBLIC_EXCHANGE_RATES,
  );
  const [updateSwapTransaction, {loading: loadingSwapUpdate}] = useMutation(
    UPDATE_SWAP_TRANSACTION_RATE,
  );
  const [fulfilOrRejectPendingSwapTransaction, {loading: loadingFulfil}] =
    useMutation(FULFIL_OR_REJECT_SWAP_TRANSACTION, {
      errorPolicy: 'all',
    });

  const swapStatusColor = render => {
    return render === 'SUCCESSFUL'
      ? 'green'
      : render === 'PENDING'
      ? 'orange'
      : 'red';
  };

  useEffect(() => {
    if (updateModal.updateOpen) {
      secondForm.setFieldValue('rate', updateModal?.rate);
      getPublicFxExchangeRates({
        variables: {
          from: updateModal?.sourceCurrency,
          to: [updateModal?.targetCurrency],
        },
        onCompleted: data => {
          setCurrentRate(data?.getPublicFxExchangeRates);
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [updateModal]);

  const handleUpdateRate = () => {
    updateSwapTransaction({
      variables: {
        input: {
          swapTransactionId: updateModal.id,
          rate: Number(updateModal?.inputValue),
        },
      },
    })
      .then(({data: {updateSwapTransaction}}) => {
        if (updateSwapTransaction.__typename === 'Error') {
          toast.error(updateSwapTransaction.message);
        } else {
          toast.success('Transaction Updated Successfully');
          refetch();
          setUpdateModal({updateOpen: false});
          setOpenFinishScreen(false);
          secondForm.resetFields();
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  const handleFulfilPending = () => {
    fulfilOrRejectPendingSwapTransaction({
      variables: {
        swapTransactionId: updateModal?.id,
        status: updateModal?.newStatus,
        ...(updateModal.reason && {reasonForFailure: updateModal?.reason}),
      },
    })
      .then(({data: {fulfilOrRejectPendingSwapTransaction}}) => {
        if (fulfilOrRejectPendingSwapTransaction.__typename === 'Error') {
          toast.error(fulfilOrRejectPendingSwapTransaction.message);
        } else {
          toast.success('Transaction Updated Successfully');
          refetch();
          setOpenFinishScreen(false);
          setUpdateModal({open: false});
          firstForm.resetFields();
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  const handleFulfilFields = value => {
    setUpdateModal(prev => ({
      ...prev,
      newStatus: value.status,
      reason: value.reason,
    }));
    setOpenFinishScreen(true);
  };

  const handleUpdateFields = value => {
    setUpdateModal(prev => ({...prev, inputValue: value.rate}));
    setOpenFinishScreen(true);
  };

  return (
    // First Modal: This is the Update Swap Transaction Modal
    <>
      <DBModal
        isOpen={updateModal.open}
        handleClose={() => {
          setUpdateModal({open: false});
          setOpenFinishScreen(false);
        }}
        content={
          <div className={styles.modal}>
            {openFinishScreen ? (
              <div className="finishScreen">
                <h1>You are about to update this Transaction</h1>
                <p>
                  Are you sure you want to Proceed with updating this Swap
                  Transaction?
                </p>
                <div className="finish-items">
                  <div className="items">
                    <p>Current Status</p>
                    <Tag color={swapStatusColor(updateModal.status)}>
                      {updateModal.status}
                    </Tag>
                  </div>
                  <div className="items">
                    <p>New Status</p>
                    <Tag color={swapStatusColor(updateModal.newStatus)}>
                      {updateModal.newStatus}
                    </Tag>
                  </div>
                </div>
                <div className="finish-buttons">
                  <Button
                    className="finish-button"
                    label="No"
                    onClick={() => setOpenFinishScreen(false)}
                    type="secondary"
                  />
                  <Button
                    onClick={handleFulfilPending}
                    className="finish-button"
                    disabled={loadingFulfil}
                    label="Yes"
                  />
                </div>
              </div>
            ) : (
              <>
                <h1>Fulfil / Reject Swap Transaction</h1>
                <Form
                  onFinish={handleFulfilFields}
                  form={firstForm}
                  layout="vertical">
                  <Form.Item
                    name="status"
                    label="Status"
                    rules={[{required: true}]}>
                    <Select onChange={setFulfilStatus} value={fulfilStatus}>
                      <Select.Option key="SUCCESSFUL">Successful</Select.Option>
                      <Select.Option key="FAILED">Failed</Select.Option>
                    </Select>
                  </Form.Item>
                  {fulfilStatus === 'FAILED' && (
                    <Form.Item
                      rules={[
                        {required: true, message: 'This Field is Required'},
                      ]}
                      name="reason"
                      label="Reason">
                      <Input.TextArea rows={3} />
                    </Form.Item>
                  )}
                  <SubmitButton disabled={loadingFulfil} label="Update" />
                </Form>
              </>
            )}
          </div>
        }
      />
      {/**
       * Second Modal: UPDATE RATE MODAL
       */}
      <DBModal
        isOpen={updateModal.updateOpen}
        handleClose={() => {
          setUpdateModal({updateOpen: false});
          setOpenFinishScreen(false);
        }}
        content={
          <div className={styles.modal}>
            {openFinishScreen ? (
              <div className="finishScreen">
                <h1>Update Swap Transaction Rate</h1>
                <p>
                  Are you sure you want to Update the Rate of this Swap
                  Transaction from {updateModal?.sourceCurrency} to{' '}
                  {updateModal?.targetCurrency}?
                </p>
                <div className="finish-items">
                  <div className="items">
                    <p>Current Rate</p>
                    <Tag color="orange">
                      {currencySymbols[updateModal?.targetCurrency]?.symbol}{' '}
                      {updateModal.rate}
                    </Tag>
                  </div>
                  <div className="items">
                    <p>New Rate</p>
                    <Tag color="green">
                      {' '}
                      {
                        currencySymbols[updateModal?.targetCurrency]?.symbol
                      }{' '}
                      {updateModal.inputValue}
                    </Tag>
                  </div>
                </div>
                <div className="finish-buttons">
                  <Button
                    className="finish-button"
                    label="No"
                    onClick={() => setOpenFinishScreen(false)}
                    type="secondary"
                  />
                  <Button
                    onClick={handleUpdateRate}
                    className="finish-button"
                    disabled={loadingSwapUpdate}
                    label="Yes"
                  />
                </div>
              </div>
            ) : (
              <>
                <h1>Update Swap Transaction</h1>
                <Form
                  onFinish={handleUpdateFields}
                  form={secondForm}
                  layout="vertical">
                  <div className={styles.rate}>
                    <div className={styles.values}>
                      {loadingRates ? (
                        <Spin />
                      ) : Object.values(currentRate).length ? (
                        <p>
                          1 {updateModal?.sourceCurrency} ={' '}
                          {currentRate?.to[0]?.rate}
                          {updateModal.targetCurrency}{' '}
                          <span>
                            Last Updated - (
                            {moment(currentRate?.lastUpdated).format(
                              'DD MMMM YYYY hh:mm A',
                            )}
                            )
                          </span>
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                    <Form.Item
                      name="rate"
                      label="Update Rate"
                      rules={[{required: true}]}>
                      <Input type="number" />
                    </Form.Item>
                  </div>
                  <SubmitButton label="Update" />
                </Form>
              </>
            )}
          </div>
        }
      />
    </>
  );
};

export default SwapModal;
