import {Form, Input, InputNumber, Select, Switch} from 'antd';
import styles from '../../collection.module.scss';
import {Button} from '@dabafinance/react-components';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useLazyQuery, useQuery} from '@apollo/client';
import {toast} from 'react-toastify';
import {GET_ALL_COLLECTION_GROUPS} from 'graphql/queries/collections';
import {GET_ALL_STOCKS} from 'graphql/queries/stocks';
import {GET_ALL_STOCKS_BY_STOCK_EXCHANGE} from 'graphql/queries/rewardScheme';
import {useEffect, useState} from 'react';
import trashIcon from 'images/trash-can-outline.png';
import {useParams} from 'react-router-dom';

export const FinancialInfo = ({
  secondForm,
  handleSecondFormSubmit,
  onTabChange,
  handleAddCompany,
  onCompanyDelete,
  handleCompanyChange,
  allowUsers,
  setAllowUsers,
  activeTab,
  collectionCompanies,
}) => {
  const [selectedStock, setSelectedStock] = useState('');
  const [stockCompanies, setStockCompanies] = useState([]);
  const {id} = useParams();
  const [getAllStocksByStockExchange, {loading: companyLoading}] = useLazyQuery(
    GET_ALL_STOCKS_BY_STOCK_EXCHANGE,
    {
      onError: error => toast.error(error),
    },
  );

  useEffect(() => {
    getAllStocksByStockExchange({
      variables: {
        stockExchangeCode:
          selectedStock?.split('~')[1] ||
          id ||
          secondForm.getFieldValue('stockExchange')
            ? secondForm.getFieldValue('stockExchange')?.split('~')[1]
            : '',
      },
      onCompleted: data =>
        setStockCompanies(data?.getAllStocksByStockExchange?.stocks),
      fetchPolicy: 'network-only',
    });
  }, [selectedStock, id, activeTab]);

  const {data: stockExchange, loading: stockLoading} = useQuery(
    GET_ALL_STOCKS,
    {
      onError: error => toast.error(error),
    },
  );

  const {data, loading} = useQuery(GET_ALL_COLLECTION_GROUPS, {
    variables: {
      filters: {
        paginate: false,
      },
    },
    errorPolicy: 'all',
    onError: error => toast.error(error?.message),
  });

  return (
    <div>
      <Form
        form={secondForm}
        className={styles.form}
        layout="vertical"
        onFinish={handleSecondFormSubmit}>
        <Form.Item name="allowUsers" className={styles.field}>
          <div className={styles.switch}>
            <span>Allow users to remove company from collection</span>
            <Switch
              checked={allowUsers}
              onChange={e => setAllowUsers(e)}
              name="allowUsers"
            />
          </div>
        </Form.Item>
        <Form.Item
          name="stockExchange"
          label="Stock Exchange"
          className={styles.field}
          rules={[
            {
              required: true,
              message: 'stock exchange is required',
            },
          ]}>
          <Select
            className={styles.form}
            loading={stockLoading}
            name="stockExchange"
            placeholder="Select stock exchange"
            value={stockLoading ? undefined : selectedStock}
            showSearch
            disabled={stockLoading}
            onChange={setSelectedStock}
            optionFilterProp="children">
            {stockLoading ? (
              <Select.Option key="Loading">Loading...</Select.Option>
            ) : (
              stockExchange?.getAllStocksExchange?.map((item, index) => (
                <Select.Option value={`${item?.id}~${item?.code}`} key={index}>
                  {item.stockExchangeName}
                </Select.Option>
              ))
            )}
          </Select>
        </Form.Item>
        <div className={styles.companies}>
          {collectionCompanies?.map((data, key) => (
            <div className={styles.company} key={key}>
              <div>
                <Form.Item
                  label="Select Companies in Stock Exchange"
                  className={styles.companySelect}>
                  <Select
                    className={styles.form}
                    allowClear
                    value={data?.company}
                    onChange={e => handleCompanyChange(key, e, 'company')}
                    loading={companyLoading}
                    disabled={
                      !secondForm.getFieldValue('stockExchange') ||
                      companyLoading
                    }
                    placeholder="Select Companies in Stock Exchange"
                    showSearch
                    optionFilterProp="children">
                    {companyLoading ? (
                      <Select.Option key="Loading">Loading...</Select.Option>
                    ) : (
                      stockCompanies?.map(item => (
                        <Select.Option key={item?.company?.id}>
                          {item?.company?.name}
                        </Select.Option>
                      ))
                    )}
                  </Select>
                </Form.Item>
                <p>
                  <b>OPTIONAL:</b>The Minimum, Maximum Unit(s) the User is
                  allowed to trade for this Company. Default is what the User
                  sees by Default
                </p>
                <div className={styles.companyNumbers}>
                  <Form.Item label="Minimum Tradable Unit">
                    <InputNumber
                      value={data?.min}
                      onChange={e => handleCompanyChange(key, e, 'min')}
                      className={styles.number}
                    />
                  </Form.Item>
                  <Form.Item label="Maximum Tradable Unit">
                    <InputNumber
                      value={data?.max}
                      onChange={e => handleCompanyChange(key, e, 'max')}
                      className={styles.number}
                    />
                  </Form.Item>
                  <Form.Item label="Default Tradable Unit">
                    <InputNumber
                      value={data?.default}
                      onChange={e => handleCompanyChange(key, e, 'default')}
                      className={styles.number}
                    />
                  </Form.Item>
                </div>
              </div>
              <img
                className={styles.trash}
                src={trashIcon}
                alt=""
                onClick={() => onCompanyDelete(key)}
              />
            </div>
          ))}
        </div>
        <p
          className={styles.addCompany}
          role="button"
          onClick={handleAddCompany}>
          Add Company
        </p>
        <Form.Item
          name="minCompanyCount"
          className={styles.field}
          label="Minimum Number of Companies allowed to Trade">
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="linkedCollectionGroups"
          className={styles.field}
          label="Linked Collection Groups">
          <Select
            className={styles.field}
            loading={loading}
            mode="multiple"
            allowClear>
            {data?.getAllCollectionGroups?.data?.collectionGroups?.map(data => (
              <Select.Option key={data?.id}>{data?.title}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="orderType"
          label="Select Order Type"
          className={styles.field}
          rules={[
            {
              required: true,
              message: 'order type is required',
            },
          ]}>
          <Select
            className={styles.form}
            name="orderType"
            placeholder="Select order type"
            showSearch
            optionFilterProp="children">
            <Select.Option value={'MARKET'}>Market Order</Select.Option>
            <Select.Option value={'LIMIT'}>Limit Order</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="ticker"
          label="Ticker"
          className={styles.field}
          rules={[
            {
              required: true,
              message: 'Collection Ticker is required',
            },
          ]}>
          <Input />
        </Form.Item>
        <div className={styles.buttons}>
          <Button
            label="Previous"
            type="secondary"
            onClick={() => onTabChange('GENERAL')}
          />
          <SubmitButton smallWidth={true} label="Next" />
        </div>
      </Form>
    </div>
  );
};
