import {gql} from '@apollo/client';

export const GET_APP_CONFIGURATION = gql`
  query GetAppConfig {
    getAppConfig {
      ... on Error {
        message
        statusCode
      }
      ... on AppConfigType {
        config {
          documentSettings {
            combineStatement
            sendToUserAutomatically
            statementPeriods {
              monthly
              annually {
                day
                month
              }
            }
          }
          # recurringInvestmentSettings {
          #   scheduledStartTime
          #   scheduledEndTime
          #   maxRetries
          # }
          discountSettings {
            pro {
              order {
                description
                feeSchedule {
                  feeName
                  feeType
                  fixedFeeAmount
                  variableFeeAmount
                }
              }
              campaign {
                description
                feeSchedule {
                  feeName
                  feeType
                  fixedFeeAmount
                  variableFeeAmount
                }
              }
            }
          }
          versionInfo {
            isCritical
            android
            ios
            latestVersion
            message
          }
          securitySettings {
            admin2FA
          }
          dabaWhatsappNumber
          brvmVideoId
          platformReviewSettings {
            filters {
              hasCompletedProfile
              hasInvestedInAnyCampaign
            }
            reviewPeriodEnd
          }
          totalEstimatedRewardsValue {
            amount
            currency
          }
        }
      }
    }
  }
`;

export const UPDATE_APP_CONFIGURATION = gql`
  mutation UpdateAppConfig($data: UpdateAppConfigInput) {
    updateAppConfig(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on AppConfigType {
        config {
          versionInfo {
            latestVersion
          }
        }
      }
    }
  }
`;

export const FORCE_SIGN_OUT = gql`
  mutation ForceSignOutUsers($forceSignOut: Boolean!) {
    forceSignOutUsers(forceSignOut: $forceSignOut) {
      ... on Error {
        message
        statusCode
      }
    }
  }
`;

export const CHANGE_ALL_USER_DOCUMENT_STATUS = gql`
  mutation ChangeAllUserDocumentStatus(
    $filter: ChangeAllUserDocumentStatusFilter!
    $active: Boolean
  ) {
    changeAllUserDocumentStatus(filter: $filter, active: $active) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUserDocument {
        message
      }
    }
  }
`;
