import {gql} from '@apollo/client';

export const CREATE_TOPIC = gql`
  mutation CreateTopic($input: CreateTopicInput!) {
    createTopic(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on Topic {
        id
        name
        icon
        type
        sequence
        bannerImg
        description
        region
      }
    }
  }
`;

export const UPDATE_TOPIC = gql`
  mutation UpdateTopic($input: UpdateTopicInput!) {
    updateTopic(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on Topic {
        id
        name
        icon
        type
        sequence
        bannerImg
        description
        region
      }
    }
  }
`;

export const UPDATE_TOPIC_LIST = gql`
  mutation UpdateTopicList($input: UpdateTopicListInput!) {
    updateTopicList(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on TopicList {
        id
        name
        icon
      }
    }
  }
`;

export const DELETE_TOPIC = gql`
  mutation DeleteTopic($topicId: ID!) {
    deleteTopic(topicId: $topicId) {
      ... on Error {
        message
        statusCode
      }
      ... on Topic {
        id
        name
        icon
        type
        sequence
        hasSequence
      }
    }
  }
`;

export const DELETE_TOPIC_LIST = gql`
  mutation DeleteTopicList($topicListId: ID!) {
    deleteTopicList(topicListId: $topicListId) {
      ... on Error {
        message
        statusCode
      }
      ... on TopicList {
        id
        name
      }
    }
  }
`;

export const CREATE_TOPIC_LIST = gql`
  mutation CreateTopicList($input: CreateTopicListInput!) {
    createTopicList(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on TopicList {
        id
        name
        icon
      }
    }
  }
`;
