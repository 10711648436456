import {Button, Dropdown} from '@dabafinance/react-components';
import {Spin} from 'antd';
import {GET_INFORMATION_SECTION_AND_OTHER_ARTICLES} from 'graphql/queries/learnArticle';
import {useState} from 'react';
import pageNotFound from 'images/pageNotFound.png';
import {useNavigate} from 'react-router-dom';
import styles from './learnArticle.module.scss';
import {useQuery} from '@apollo/client';
import LearnArticleCard from './learnArticleCard';
import {LearnArticleCards} from './learnArticleCards';
import {NavTabsEnum} from 'utils/constants';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';

const LearnArticle = () => {
  const navigate = useNavigate();
  const [informationSectionId, setInformationSectionId] = useState('');
  const [, setInformationSectionTitle] = useState('');
  const [otherArticles, setOtherArticles] = useState([]);
  const [informationSections, setInformationSections] = useState([]);

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Updates),
  );

  const {loading} = useQuery(GET_INFORMATION_SECTION_AND_OTHER_ARTICLES, {
    onCompleted: data => {
      setOtherArticles(
        data?.getInformationSectionsAndOtherLearnArticles?.otherArticles,
      ),
        setInformationSections(data?.getAllInformationSections);
    },
  });

  const handleDropdown = query => {
    const id = query.id;
    const informationTitle = query.title;
    setInformationSectionId(id);
    setInformationSectionTitle(informationTitle);
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Learn & FAQ</h1>
        <div>
          <Button
            className={styles.button}
            disabled={!tabOperations?.create}
            label="Add Article"
            onClick={() => navigate('/dashboard/explore/learn-article/new')}
          />
        </div>
      </div>
      <div className={styles['dropdown']}>
        <Dropdown
          items={informationSections ? informationSections : []}
          title="Select Information Section"
          onSelect={handleDropdown}
          className={styles['info-dropdown']}
        />
      </div>

      {informationSectionId && <LearnArticleCards id={informationSectionId} />}

      <h2>Other Tips</h2>
      <div className={styles['cards']}>
        {otherArticles?.length >= 1 ? (
          otherArticles.map(value => (
            <div key={value?.id}>
              <LearnArticleCard
                date={value?.publishedDate}
                title={value?.title}
                id={value?.id}
                operations={tabOperations}
                image={value?.featuredImage}
                index={value?.id}
              />
            </div>
          ))
        ) : (
          <div className={styles['no-update']}>
            <img src={pageNotFound} alt="" />
            <h1>No Learn & FAQ found</h1>
            <span>Add a new Article</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default LearnArticle;
