import {Form, Input, Select, Spin, Switch, Upload} from 'antd';
import styles from '../ventureFund.module.scss';
import deleteIcon from 'images/delete.png';
import {useEffect, useState} from 'react';
import imgUpload from 'images/upload_image.png';
import {Button} from '@dabafinance/react-components';
import {stageData} from 'utils/mock';

const VentureFundCard = ({
  companies,
  handleChange,
  portfolioCompanyType,
  handleDelete,
  handleChangeImage,
  handleDeleteImage,
  loadingImageUpload,
}) => {
  const {Option} = Select;
  const [isCompanyNotInDaba, setIsCompanyNotInDaba] = useState(false);
  useEffect(() => {
    if (
      portfolioCompanyType.companyId == null ||
      portfolioCompanyType.companyId == undefined
    ) {
      setIsCompanyNotInDaba(true);
    }
  }, [portfolioCompanyType?.companyId]);
  return (
    <div className={styles['card-container']}>
      <div className={styles.form}>
        <Form.Item
          className={styles['field-width']}
          label="Title"
          rules={[
            {
              required: true,
              message: 'Required Field',
            },
          ]}>
          <Input
            value={portfolioCompanyType?.title}
            onChange={e => handleChange('title', e.target.value)}
          />
        </Form.Item>
        <div style={{width: '100%'}}>
          <Form.Item name="isCompanyNotInDaba" label="Not a Company in Daba">
            <Switch
              size="small"
              defaultChecked={isCompanyNotInDaba}
              onChange={e => setIsCompanyNotInDaba(e)}
            />
          </Form.Item>
          {isCompanyNotInDaba ? (
            <div className={styles.card_form}>
              <div className={styles['image-section']}>
                {portfolioCompanyType?.imageUrl ? (
                  <div className={styles['c-image-uploaded']}>
                    <img src={portfolioCompanyType?.imageUrl} alt="" />
                    <Button
                      className={styles['delete-button']}
                      label="Change Image"
                      type="secondary"
                      onClick={handleDeleteImage}
                    />
                  </div>
                ) : (
                  <Upload
                    accept=".png, .jpeg, .jpg"
                    name="file"
                    onChange={handleChangeImage}>
                    <div className={styles['c-company-upload']}>
                      {loadingImageUpload ? (
                        <div className={styles['loading-spinner']}>
                          <Spin size="large" />
                          <h2>Loading...</h2>
                        </div>
                      ) : (
                        <>
                          <img
                            className={styles['company-image']}
                            src={imgUpload}
                            alt="upload"
                          />
                          <div style={{marginTop: 6}}>Upload Image</div>
                        </>
                      )}
                    </div>
                  </Upload>
                )}
              </div>
              <Form.Item label="Company" className={styles['field-width']}>
                <Input
                  value={portfolioCompanyType?.name}
                  onChange={e => handleChange('name', e.target.value)}
                />
              </Form.Item>
            </div>
          ) : (
            <Form.Item label="Company" className={styles['field-width']}>
              <Select
                placeholder="select invested companies"
                value={
                  `${portfolioCompanyType?.companyId}~/-${portfolioCompanyType?.name}~/-${portfolioCompanyType?.imageUrl}` ||
                  ''
                }
                onChange={e => handleChange('companyInDaba', e)}
                name="company"
                showSearch>
                {companies?.getAllCompanies?.map(company => (
                  <Option
                    key={`${company.id}~/-${company.name}~/-${company.logoImgURL}`}>
                    <div className={styles['all-data']}>
                      <img src={company.logoImgURL} alt="" />
                      <span>{company.name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </div>
        <Form.Item
          className={styles['field-width']}
          label="Multiple On Invested Capital"
          rules={[
            {
              required: true,
              message: 'Required Field',
            },
          ]}>
          <Input
            value={portfolioCompanyType?.multipleOnInvestedCapital}
            onChange={e =>
              handleChange('multipleOnInvestedCapital', e.target.value)
            }
            type="number"
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Stage Invested is Required',
            },
          ]}
          label="Stage Invested"
          className={styles['field-width']}>
          <Select
            placeholder={'SERIES_A'}
            value={portfolioCompanyType?.stageInvested}
            onChange={e => handleChange('stageInvested', e)}>
            {stageData?.map(name => (
              <Option key={name.value}>{name.label}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className={styles['field-width']}
          label="Description"
          rules={[
            {
              required: true,
              message: 'Required Field',
            },
          ]}>
          <Input.TextArea
            value={portfolioCompanyType?.description}
            rows={6}
            onChange={e => handleChange('description', e.target.value)}
          />
        </Form.Item>
      </div>
      <div className={styles['delete-icon']}>
        <img onClick={() => handleDelete?.()} src={deleteIcon} alt="" />
      </div>
    </div>
  );
};

export default VentureFundCard;
