import {createSelector, createSlice} from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    userData: {},
    onboardingProgress: 0.0,
    currentNavigationTab: [],
    loginRoute: '',
    pageError: null,
  },
  reducers: {
    loginSuccess: (state, action) => {
      return {
        ...state,
        userData: action.payload.userData,
        isAuthenticated: true,
      };
    },
    logout: state => {
      localStorage.setItem('login', 'loggedOut');
      localStorage.removeItem('token');
      return {
        ...state,
        isAuthenticated: false,
        userData: {},
      };
    },
    setOnboardingProgress: (state, action) => {
      return {
        ...state,
        onboardingProgress: action.payload,
      };
    },
    setTempUserData: (state, action) => {
      state.userData = {...state.userData, ...action.payload.userData};
    },
    handlePageError: (state, action) => {
      return {
        ...state,
        pageError: action.payload.error,
      };
    },
    handleClearPageError: state => {
      return {
        ...state,
        pageError: null,
      };
    },
    handleNavigateToHome: (state, action) => {
      return {
        ...state,
        loginRoute: action.payload.route,
      };
    },
  },
});

export const makeSelectTabOperations = createSelector(
  state => state?.auth?.userData?.user?.roleSettings,
  (_, tabName) => tabName,
  (roleSettings, tabName) => {
    if (roleSettings) {
      const tab = roleSettings.tabs.find(tab => tab.tab === tabName);
      if (['SUPERADMIN', 'ADMIN'].includes(roleSettings.name)) {
        return {
          view: true,
          update: true,
          delete: true,
          export: true,
          create: true,
        };
      }
      if (tab) {
        const all = tab.operations.includes('ALL') ? true : null;

        return {
          view: all ?? tab.operations.includes('VIEW'),
          update: all ?? tab.operations.includes('UPDATE'),
          delete: all ?? tab.operations.includes('DELETE'),
          export: all ?? tab.operations.includes('EXPORT'),
          create: all ?? tab.operations.includes('CREATE'),
        };
      }
    }
    return null; // Return null if tab or operations are not found
  },
);

export default slice.reducer;
export const {
  logout,
  loginSuccess,
  handlePageError,
  setOnboardingProgress,
  handleClearPageError,
  setTempUserData,
  handleNavigateToHome,
} = slice.actions;
