import {useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import CountryDetails from './countryDetails';

// Tab Routes
import Report from './tabs/report';
import AnalystsMemo from './tabs/analystsMemo';

export default function CountryReportRoutes() {
  const [switchToPDF, setSwitchToPDF] = useState(false);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <CountryDetails
            switchToPDF={switchToPDF}
            setSwitchToPDF={setSwitchToPDF}
          />
        }>
        <Route path="/" element={<Report pdfMode={switchToPDF} />} />
        <Route path="/analysts-memos" element={<AnalystsMemo />} />
      </Route>
    </Routes>
  );
}
