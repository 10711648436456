import styles from './researchReportCard.module.scss';
import EditIcon from '../../images/edit.svg';
import DeleteIcon from '../../images/delete.svg';
import moment from 'moment';

export default function ResearchReportCard({
  related,
  createdAt,
  onHandle,
  onDelete,
  operations,
  title,
}) {
  let icon;
  if (related?.logoImgURL) {
    icon = related?.logoImgURL;
  } else {
    icon = related?.icon;
  }

  const titleTrim = title => {
    if (title?.length > 17) {
      return `${title.substr(0, 17)}...`;
    } else return title;
  };

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <img src={icon} alt="" className={styles.image} />
        <h4 className={styles.title}>{related?.name}</h4>
      </div>
      <div className={styles.body}>
        <h3>{titleTrim(title)}</h3>
        <p className={styles.date}>
          Updated {moment(createdAt).format('DD MMMM')}
        </p>
      </div>
      <div className={styles.footer}>
        {operations?.delete && (
          <button onClick={onDelete}>
            <img src={DeleteIcon} alt="" className={styles.icon} />
            <span>Delete</span>
          </button>
        )}
        {operations?.update && (
          <button onClick={onHandle}>
            <img src={EditIcon} alt="" className={styles.icon} />
            <span>Edit</span>
          </button>
        )}
      </div>
    </div>
  );
}
