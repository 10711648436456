import {gql} from '@apollo/client';

export const GET_ALL_NET_PROMOTER = gql`
  query GetAllNetPromoterScores {
    getAllNetPromoterScores {
      id
      recommendationScore
      feedback
      user {
        id
        firstName
        lastName
        imageUrl
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_USER_LATEST_NET_PROMOTER_SCORE = gql`
  query GetUserLatestNetPromoterScore($userId: ID!) {
    getUserLatestNetPromoterScore(userId: $userId) {
      ... on Error {
        message
        statusCode
      }
      ... on NetPromoterScoreType {
        netPromoterScore {
          id
          recommendationScore
          feedback
          user {
            id
            firstName
            lastName
            imageUrl
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_ALL_USER_NET_PROMOTER_SCORES = gql`
  query GetNetPromoterScoreByUserId($userId: ID!) {
    getNetPromoterScoreByUserId(userId: $userId) {
      ... on Error {
        message
        statusCode
      }
      ... on UserNetPromoterScoreType {
        userNetPromoterScore {
          id
          recommendationScore
          feedback
          user {
            id
            firstName
            lastName
            imageUrl
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;
