import {useNavigate} from 'react-router-dom';
import styles from './ratings.module.scss';
import {useQuery} from '@apollo/client';
import {GET_ALL_RATINGS} from 'graphql/queries/assetManagement';
import {Dropdown, Form, Table} from 'antd';
import dotsIcon from 'images/dots.svg';
import {useState} from 'react';
import backIcon from 'images/back.png';
import DBModal from 'components/modal/modal';
import {RatingsForm} from './RatingsForm';
import SubmitButton from 'components/Startup/components/SubmitButton';

const RatingsOverview = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedRatingsId, setSelectedRatingsId] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const {data, refetch, loading} = useQuery(GET_ALL_RATINGS, {
    variables: {
      page: 1,
      limit: 100,
    },
  });

  const columns = [
    {
      title: 'Agency Name',
      dataIndex: 'agencyName',
      key: 'agencyName',
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <Dropdown
          trigger="click"
          menu={{
            items: [
              {
                key: 1,
                label: 'Edit',
                onClick: () => {
                  setSelectedRatingsId(record.id);
                  setOpenModal(true);
                },
              },
            ],
          }}>
          <img src={dotsIcon} alt="" />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <div className={styles.nav}>
          <img src={backIcon} onClick={() => navigate(-1)} />
          <h1>Ratings</h1>
        </div>
        <SubmitButton
          handleClick={() => {
            setSelectedRatingsId(null);
            setOpenModal(true);
            setImgUrl(null);
          }}
          label="Create Rating"
        />
      </div>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data?.getAllRatings?.data}
      />
      <DBModal
        isOpen={openModal}
        handleClose={() => {
          setOpenModal(false);
          form.resetFields();
          setImgUrl(null);
        }}
        content={
          <RatingsForm
            id={selectedRatingsId}
            refetch={refetch}
            imgUrl={imgUrl}
            setImgUrl={setImgUrl}
            setId={setSelectedRatingsId}
            form={form}
            close={() => {
              setOpenModal(false);
              form.resetFields();
            }}
          />
        }
        width={1000}
      />
    </div>
  );
};

export default RatingsOverview;
