import {DatePicker, Form, Select, Spin} from 'antd';
import styles from './totalCashBalance.module.scss';
import {useState} from 'react';
import {currencySymbols, walletCurrencies} from 'utils/mock';
import {useQuery} from '@apollo/client';
import {GET_TOTAL_USER_CASH_BALANCE} from 'graphql/queries/wallet';
import moment from 'moment';

const TotalCashBalance = () => {
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [status, setStatus] = useState('SUCCESSFUL');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const {data, loading} = useQuery(GET_TOTAL_USER_CASH_BALANCE, {
    variables: {
      input: {
        currency: {
          display: selectedCurrency,
          source: selectedCurrency,
        },
        filter: {
          transactionStatus: status,
          range: {
            startDate,
            endDate,
          },
        },
      },
    },
  });

  return (
    <div className={styles.root}>
      <h1>Total Users Cash Balance</h1>
      <span className={styles.subtext}>
        This is the total amount users have in their wallet for a given currency
      </span>
      <Form className={styles.form} layout="vertical">
        <Form.Item name="selectCurrency" label="Select a Currency">
          <Select
            defaultValue="USD"
            value={selectedCurrency}
            onChange={setSelectedCurrency}>
            {walletCurrencies.map(data => (
              <Select.Option key={data.key}>{data.key}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="status" label="Status">
          <Select defaultValue="SUCCESSFUL" value={status} onChange={setStatus}>
            <Select.Option key="PENDING">PENDING</Select.Option>
            <Select.Option key="SUCCESSFUL">SUCCESSFUL</Select.Option>
            <Select.Option key="FAILED">FAILED</Select.Option>
          </Select>
        </Form.Item>
        <div className={styles.dates}>
          <Form.Item label="Start Date" name="startDate">
            <DatePicker
              value={startDate}
              onChange={e => setStartDate(moment(e).format('YYYY-MM-DD'))}
              format={'YYYY-MM-DD'}
            />
          </Form.Item>
          <Form.Item label="End Date" name="endDate">
            <DatePicker
              format={'YYYY-MM-DD'}
              onChange={e => setEndDate(moment(e).format('YYYY-MM-DD'))}
              value={endDate}
            />
          </Form.Item>
        </div>
      </Form>
      <div className={styles.cardContent}>
        <div className={styles.card}>
          <div className={styles.top}>
            <p>TOTAL {status}</p>
            <p>{selectedCurrency}</p>
          </div>
          <div className={styles.display}>
            {loading ? (
              <Spin size="medium" />
            ) : (
              <h1>
                {currencySymbols[selectedCurrency]?.symbol}
                {data?.getPlatformUserTotalCashBalance?.totalBalanceInSourceCurrency?.toLocaleString()}
              </h1>
            )}
          </div>

          <div className={styles.time}>
            <span>
              {startDate ? moment(startDate).format('MMMM Do YYYY') : ''}
            </span>{' '}
            -{' '}
            <span>{endDate ? moment(endDate).format('MMMM Do YYYY') : ''}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalCashBalance;
