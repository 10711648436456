import {TopicCard} from '@dabafinance/react-components';
import styles from './userInterest.module.scss';
import {useQuery} from '@apollo/client';
import {GET_USER_BY_ID} from 'graphql/queries/users';
import {useParams} from 'react-router-dom';
import {Spin} from 'antd';
import pageNotFound from 'images/pageNotFound.png';

const UserInterests = () => {
  const {id} = useParams();

  const {data, loading} = useQuery(GET_USER_BY_ID, {
    variables: {
      getUserByIdId: id,
    },
  });

  return loading ? (
    <div className={styles.loading}>
      <Spin size="large" />
      <h1>Loading...</h1>
    </div>
  ) : (
    <div className={styles.container}>
      <div>
        <h1 className={styles.header}>Interest</h1>
        <div className={styles.topics}>
          {data?.getUserById?.user?.topics?.length >= 1 ? (
            data?.getUserById?.user?.topics?.map(topic => (
              <div className={styles['topic-card']} key={topic?.id}>
                <TopicCard
                  isSelected={true}
                  logo={topic?.icon}
                  label={topic?.name}
                />
              </div>
            ))
          ) : (
            <div className={styles['no-update']}>
              <img src={pageNotFound} alt="" />
              <h1>No Interest found</h1>
            </div>
          )}
        </div>
      </div>
      <div>
        <h1>Countries</h1>
        <div className={styles.topics}>
          {data?.getUserById?.user?.countryOfInterest?.length >= 1 ? (
            data?.getUserById?.user?.countryOfInterest?.map(interest => (
              <div className={styles['topic-card']} key={interest?.id}>
                <TopicCard logo={interest?.icon} label={interest?.name} />
              </div>
            ))
          ) : (
            <div className={styles['no-update']}>
              <img src={pageNotFound} alt="" />
              <h1>No Country of Interest found</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserInterests;
