import {Outlet} from 'react-router-dom';
import styles from './rewardSchemes.module.scss';
import CustomLink from 'components/customLink/customLink';

const RewardBanksManagement = () => {
  return (
    <div className={styles['investor-container']}>
      <div className={styles.header}>
        <h1>Reward Banks</h1>
      </div>
      <section className={styles.section}>
        <nav className={styles['investor-toggler']}>
          <CustomLink to={'/dashboard/reward-banks'} name="Stocks Bank" />
          <CustomLink to={'/dashboard/reward-banks/cash'} name="Cash Bank" />
        </nav>
      </section>
      <section>
        <Outlet />
      </section>
    </div>
  );
};

export {RewardBanksManagement};
