import {useSelector} from 'react-redux';
import WebsiteNewsContents from './WebsiteNewsContents';
import {Spin} from 'antd';

const CreateWebsiteNews = () => {
  const storedNewsData = useSelector(state => state.news);
  if (storedNewsData.isStoreInUse && !storedNewsData.newsTitle) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return <WebsiteNewsContents storedNewsData={storedNewsData} />;
};

export default CreateWebsiteNews;
