import {DatePicker, Form, InputNumber, Select, Spin, Switch} from 'antd';
import styles from './mobileSetting.module.scss';
import {
  CHANGE_ALL_USER_DOCUMENT_STATUS,
  GET_APP_CONFIGURATION,
  UPDATE_APP_CONFIGURATION,
} from 'graphql/queries/mobileSettings';
import {useMutation, useQuery} from '@apollo/client';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {NavTabsEnum, monthsOfTheYear} from 'utils/constants';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import DBModal from 'components/modal/modal';
import {Button} from '@dabafinance/react-components';
import moment from 'moment';
import {getUserTimezone} from 'utils/helper';
import {GET_USER_DOCUMENT_CATEGORIES} from 'graphql/queries/userDocuments';

const DocumentsConfig = () => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const {loading, data} = useQuery(GET_APP_CONFIGURATION, {
    fetchPolicy: 'no-cache',
  });
  const [updateAppConfig, {loading: updateLoading}] = useMutation(
    UPDATE_APP_CONFIGURATION,
  );
  const [changeAllUserDocumentStatus, {loading: loadingActiveStatus}] =
    useMutation(CHANGE_ALL_USER_DOCUMENT_STATUS);

  const {data: getCategory, loading: catLoading} = useQuery(
    GET_USER_DOCUMENT_CATEGORIES,
  );

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.PlatformSettings),
  );

  useEffect(() => {
    if (data?.getAppConfig?.config?.documentSettings) {
      const {sendToUserAutomatically, combineStatement, statementPeriods} =
        data?.getAppConfig?.config?.documentSettings;
      form.setFieldsValue({
        combineStatement,
        sendToUserAutomatically,
        monthly: statementPeriods?.monthly,
        yearDay: statementPeriods?.annually?.day,
        yearly: statementPeriods?.annually?.month,
      });
    }
  }, [data?.getAppConfig?.config?.documentSettings]);

  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  const handleFinish = values => {
    if (
      (values?.yearly && !values?.yearDay) ||
      (!values?.yearly && values?.yearDay)
    )
      return toast.error('You have to select Yearly Month and a Day Together');

    updateAppConfig({
      variables: {
        data: {
          documentSettings: {
            combineStatement: values?.combineStatement || false,
            sendToUserAutomatically: values?.sendToUserAutomatically || false,
            statementPeriods: {
              monthly: Number(values?.monthly),
              annually: {
                day: Number(values?.yearDay),
                month: values?.yearly,
              },
            },
          },
        },
      },
    })
      .then(({data: {updateAppConfig}}) => {
        if (updateAppConfig.__typename === 'Error') {
          toast.error(updateAppConfig.message);
        } else {
          toast.success('Updated Successfully');
        }
      })
      .catch(error => toast.error(error));
  };

  const handleChangeStatus = values => {
    changeAllUserDocumentStatus({
      variables: {
        filter: {
          documentCategory: values?.documentCategory,
          ...(values?.startDate && {
            startDate: moment(values?.startDate).format(),
          }),
          ...(values?.endDate && {endDate: moment(values?.endDate).format()}),
        },
        active: values?.active != null ? values?.active : false,
      },
    })
      .then(({data: {changeAllUserDocumentStatus}}) => {
        if (changeAllUserDocumentStatus.__typename === 'Error') {
          toast.error(changeAllUserDocumentStatus.message);
        } else {
          setFormValues({});
          updateForm.resetFields();
          setOpenConfirmModal(false);
          toast.success('Updated Successfully');
        }
      })
      .catch(error => toast.error(error));
  };

  return (
    <div className={styles.body}>
      <h1 className={styles.documentHeader}>User Documents Settings</h1>
      <Form
        className={styles.form}
        form={form}
        onFinish={handleFinish}
        layout="vertical">
        <div>
          <Form.Item label="Combine Statement" name="combineStatement">
            <Switch
              defaultChecked={
                data?.getAppConfig?.config?.documentSettings?.combineStatement
              }
            />
          </Form.Item>
          <Form.Item
            label="Send to Users Automatically"
            name="sendToUserAutomatically">
            <Switch
              defaultChecked={
                data?.getAppConfig?.config?.documentSettings
                  ?.sendToUserAutomatically
              }
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                type: 'number',
                min: 1,
                max: 28,
                message: 'Please enter a number between 1 and 28',
              },
            ]}
            className={styles.documentFieldWidth}
            label="Monthly Statement Period"
            name="monthly">
            <InputNumber className={styles.documentNumber} />
          </Form.Item>
        </div>

        <div className={styles.yearly}>
          <p>Yearly Statement Period (Select a Month and day in a year)</p>
          <div>
            <Form.Item
              label={'Select Month'}
              name="yearly"
              className={styles.yearlyFlex}>
              <Select showSearch allowClear className={styles.numberSelect}>
                {monthsOfTheYear.map(data => (
                  <Select.Option key={data.key}>{data.value}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  type: 'number',
                  min: 1,
                  max: 28,
                  message: 'Please enter a date between 1 and 28',
                },
              ]}
              label="Set a Day"
              name="yearDay">
              <InputNumber className={styles.numberField} />
            </Form.Item>
          </div>
        </div>
        <SubmitButton
          disabled={updateLoading || !tabOperations?.update}
          label="Update"
        />
      </Form>
      <div className={styles.form2}>
        <h1 className={styles.documentHeader}>
          Update Global User Documents Status
        </h1>
        <Form
          className={styles.form2}
          onFinish={values => {
            setFormValues(values);
            setOpenConfirmModal(true);
          }}
          form={updateForm}
          layout="vertical">
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Required Field',
              },
            ]}
            className={styles.documentFieldWidth}
            name="documentCategory"
            label="Document Category">
            <Select allowClear showSearch loading={catLoading}>
              {getCategory?.getUserDocumentCategories?.data?.map(
                (data, index) => (
                  <Select.Option key={index} value={data?.label}>
                    <div className={styles['all-data']}>
                      <span>{data?.name}</span>
                    </div>
                  </Select.Option>
                ),
              )}
            </Select>
          </Form.Item>
          <Form.Item name="startDate" label="Issued Start Date">
            <DatePicker
              style={{width: 340}}
              format={'YYYY-MM-DD HH:mm:ss'}
              renderExtraFooter={() => (
                <div style={{textAlign: 'center'}}>
                  <p>Your timezone is: {getUserTimezone()}</p>
                </div>
              )}
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
            />
          </Form.Item>
          <Form.Item name="endDate" label="Issued End Date">
            <DatePicker
              style={{width: 340}}
              format={'YYYY-MM-DD HH:mm:ss'}
              renderExtraFooter={() => (
                <div style={{textAlign: 'center'}}>
                  <p>Your timezone is: {getUserTimezone()}</p>
                </div>
              )}
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
            />
          </Form.Item>
          <Form.Item name="active" label="Active Status">
            <Switch />
          </Form.Item>
          <SubmitButton label="Confirm & Proceed" />
        </Form>
      </div>
      <DBModal
        isOpen={openConfirmModal}
        handleClose={() => setOpenConfirmModal(false)}
        content={
          <div className="confirm-modal">
            <div>
              <h1>Are you Sure you want to Update this Status?</h1>
              <p>
                Updating this is going to update all User documents of this
                Category{' '}
                <b>{formValues?.documentCategory?.replace('_', ' ')}</b>{' '}
                Globally
              </p>
            </div>
            <div className="buttons">
              <Button
                label="Update Status"
                disabled={loadingActiveStatus}
                onClick={() => handleChangeStatus(formValues)}
              />
              <Button
                type="secondary"
                label="No"
                onClick={() => setOpenConfirmModal(false)}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default DocumentsConfig;
