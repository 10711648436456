import {useState} from 'react';
import backIcon from 'images/back.png';
import styles from './addNews.module.scss';
import UploadImage from 'components/Startup/profile/companyImage';
import {DatePicker, Form, Input, Select, Switch} from 'antd';
import {useMutation, useQuery} from '@apollo/client';
import {GET_ALL_TOPICS} from 'graphql/queries/investorUpdates';
import {GET_ALL_STARTUPS} from 'graphql/queries/startupCompanies';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useNavigate} from 'react-router-dom';
import {CREATE_NEWS} from 'graphql/mutations/news';
import moment from 'moment';
import {toast} from 'react-toastify';
import {GET_ALL_NEWS} from 'graphql/queries/news';

const AddNews = () => {
  const [title, setTitle] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [sourceURL, setSourceURL] = useState('');
  const [source, setSource] = useState('');
  const [trendingNews, setTrendingNews] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [breakingNews, setBreakingNews] = useState(false);
  const [selectedNews, setSelectedNews] = useState(false);
  const [newsUrl, setNewsUrl] = useState('');
  const [publishedDate, setPublishedDate] = useState('');
  const [topics, setTopics] = useState([]);
  const [companies, setCompanies] = useState([]);
  const {Option} = Select;

  const navigate = useNavigate();

  const [createNews, {loading: loadingNews}] = useMutation(CREATE_NEWS);

  const handleAddNews = () => {
    const optionalField = {};
    if (imgUrl) optionalField.featuredImage = imgUrl;
    createNews({
      variables: {
        data: {
          title,
          link: newsUrl,
          topics,
          newsSource: {
            name: source,
            url: sourceURL,
          },
          companies,
          isTrendingNews: trendingNews,
          isBreakingNews: breakingNews,
          isSelectedNews: selectedNews,
          publishedDate: moment(publishedDate).format(),
          keywords,
          ...optionalField,
        },
      },
      refetchQueries: [{query: GET_ALL_NEWS}],
    })
      .then(({data: {createNews}}) => {
        if (createNews.__typename === 'Error') {
          toast.error(createNews.message);
        } else {
          toast.success('Added Successfully');
          navigate('/dashboard/news');
        }
      })
      .catch(error => toast.error(error));
  };

  const {data: getAllTopics} = useQuery(GET_ALL_TOPICS);
  const {data: getAllCompanies} = useQuery(GET_ALL_STARTUPS);
  const width = 730;
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h2>Add News</h2>
      </div>
      <div className={styles.content}>
        <div className={styles['image-wrapper']}>
          <h1>News Details</h1>
          <UploadImage
            setImgUrl={setImgUrl}
            previewUrl={previewUrl}
            setPreviewUrl={setPreviewUrl}
            large
            bucket="investor-updates-bucket"
          />
        </div>
        <Form layout="vertical" onFinish={handleAddNews}>
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: 'true',
                message: 'Title of news is required',
              },
            ]}>
            <Input
              value={title}
              onChange={e => setTitle(e.target.value)}
              style={{width}}
              placeholder="News Title"
            />
          </Form.Item>
          <Form.Item
            label="News URL"
            name="news url"
            rules={[
              {
                required: 'true',
                message: 'News URL is required',
              },
            ]}>
            <Input
              value={newsUrl}
              onChange={e => setNewsUrl(e.target.value)}
              style={{width}}
              placeholder="https://www.addnews.com"
            />
          </Form.Item>
          <div className={styles.alignment}>
            <Form.Item
              rules={[
                {
                  required: 'true',
                  message: 'News Source is required',
                },
              ]}
              name="source"
              label="Source">
              <Input
                style={{width: 360}}
                value={source}
                onChange={e => setSource(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: 'true',
                  message: 'News Source url is required',
                },
              ]}
              name="source url"
              label="Source URL">
              <Input
                style={{width: 340}}
                value={sourceURL}
                onChange={e => setSourceURL(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className={styles.alignment}>
            <Form.Item
              rules={[
                {
                  required: 'true',
                  message: 'Please Select at least one keyword',
                },
              ]}
              label="Keywords"
              name="keywords">
              <Select
                style={{width: 360}}
                value={keywords}
                onChange={e => setKeywords(e)}
                mode="multiple"
                className={styles['select-height']}>
                {getAllTopics?.getAllTopics?.map(topic => (
                  <Option key={topic.name}>
                    <span>{topic.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: 'true',
                  message: 'Published Time required',
                },
              ]}
              name="published date"
              label="Published Time">
              <DatePicker
                name="published Date"
                value={publishedDate}
                style={{width: 340}}
                format={'YYYY-MM-DD HH:mm:ss'}
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm:ss'),
                }}
                onChange={e =>
                  setPublishedDate(moment(e).format('YYYY-MM-DD HH:mm:ss'))
                }
              />
            </Form.Item>
          </div>
          <Form.Item
            rules={[
              {
                required: 'true',
                message: 'Please Select at least one Topic',
              },
            ]}
            label="Categories"
            name="categories">
            <Select
              style={{width}}
              value={topics}
              onChange={e => setTopics(e)}
              mode="multiple"
              className={styles['select-height']}>
              {getAllTopics?.getAllTopics?.map(topic => (
                <Option key={topic.name}>
                  <div className={styles['all-data']}>
                    <img src={topic.icon} alt="" />
                    <span>{topic.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Companies" name="companies">
            <Select
              style={{width}}
              listHeight={400}
              className={styles['select-height']}
              value={companies}
              onChange={e => setCompanies(e)}
              mode="multiple">
              {getAllCompanies?.getAllCompanies?.map(company => (
                <Option key={company.name}>
                  <div className={styles['all-data']}>
                    <img src={company.logoImgURL} alt="" />
                    <span>{company.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className={styles['audience-grid']}>
            <Form.Item className={styles.audience} name="Verified Investors">
              <span>Breaking News</span>
              <Switch value={breakingNews} onChange={e => setBreakingNews(e)} />
            </Form.Item>
            <Form.Item className={styles.audience} name="All Investors">
              <span>Trending News</span>
              <Switch value={trendingNews} onChange={e => setTrendingNews(e)} />
            </Form.Item>
            <Form.Item className={styles.audience} name="All Users">
              <span>Latest News</span>
              <Switch value={selectedNews} onChange={e => setSelectedNews(e)} />
            </Form.Item>
          </div>
          <Form.Item name="button">
            <SubmitButton disabled={loadingNews} label="Add News" />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddNews;
