import {gql} from '@apollo/client';

export const GET_ALL_AFFILIATES = gql`
  query GetAllAffiliates($filter: AffiliateFilterInput) {
    getAllAffiliates(filter: $filter) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAffiliates {
        data {
          id
          name
          key
          logo
          type
          percentage
          status
        }
      }
    }
  }
`;

export const CREATE_AFFILIATES = gql`
  mutation CreateAffiliate($input: AffiliateInput!) {
    createAffiliate(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAffiliate {
        data {
          type
        }
      }
    }
  }
`;

export const UPDATE_AFFILIATES = gql`
  mutation UpdateAffiliate($input: UpdateAffiliateInput!) {
    updateAffiliate(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAffiliate {
        data {
          status
        }
      }
    }
  }
`;

export const GET_ALL_AFFILIATES_RESERVATIONS = gql`
  query GetReservations($filter: affiliateReservationFilterInput) {
    getReservations(filter: $filter) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithReservations {
        data {
          id
          affiliate {
            name
          }
          status
          orderType
          amount
          txRef
          affiliateId
          currency
          firstName
          lastName
          email
          phoneNumber
          ticker
          estimatedUnit
          remoteAddress
          companyId
          amountWithPercentage
        }
        pagination {
          total
        }
      }
    }
  }
`;

export const SEND_REMINDER_TO_USERS = gql`
  mutation SendReminderEmail($sendReminderEmailId: ID!) {
    sendReminderEmail(id: $sendReminderEmailId) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;

export const DELETE_AFFILIATE = gql`
  mutation DeleteAffiliate($deleteAffiliateId: ID!) {
    deleteAffiliate(id: $deleteAffiliateId) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;

export const GET_AFFILIATE_CONFIG = gql`
  query GetAffiliateConfig {
    getAffiliateConfig {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAffiliateConfig {
        data {
          header
          description
          image
          multilang {
            header
            description
            image
            disclosure
            language
          }
          disclosure
          suggestedAmountMultiplier
          language
          id
        }
      }
    }
  }
`;

export const GET_ALL_AFFILIATE_CONFIG = gql`
  query GetAllAffiliateConfig($filter: AffiliateConfigsFilterInput!) {
    getAllAffiliateConfig(filter: $filter) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAffiliateConfigs {
        data {
          header
          description
          multilang {
            header
            description
            disclosure
            language
            image
          }
          disclosure
          suggestedAmountMultiplier
          language
          isDefault
          id
          image
          affiliateId
          affiliate {
            id
            name
          }
        }
      }
    }
  }
`;

export const UPDATE_AFFILIATE_CONFIG = gql`
  mutation UpdateAffiliateConfig($input: UpdateAffiliateConfigInput!) {
    updateAffiliateConfig(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAffiliateConfig {
        data {
          id
        }
      }
    }
  }
`;

export const CREATE_AFFILIATE_CONFIG = gql`
  mutation AddAffiliateConfig($input: AffiliateConfigInput!) {
    addAffiliateConfig(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAffiliateConfig {
        data {
          id
        }
      }
    }
  }
`;

export const DELETE_AFFILIATE_CONFIG = gql`
  mutation DeleteAffiliateConfig($deleteAffiliateConfigId: ID!) {
    deleteAffiliateConfig(id: $deleteAffiliateConfigId) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;
