import {gql} from '@apollo/client';

export const CREATE_PRODUCT = gql`
  mutation CreateProduct(
    $name: String!
    $isOneTime: Boolean!
    $description: String
    $items: [ItemsInput!]!
  ) {
    createProduct(
      name: $name
      isOneTime: $isOneTime
      description: $description
      items: $items
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on Product {
        id
        name
      }
    }
  }
`;

export const ADD_APPLE_SKU_TO_PRICE = gql`
  mutation AddAppleSkuToPrice($priceId: ID!, $appleSku: String!) {
    addAppleSkuToPrice(priceId: $priceId, appleSku: $appleSku) {
      ... on Error {
        message
        statusCode
      }
      ... on Price {
        id
      }
    }
  }
`;

export const CREATE_PRICE = gql`
  mutation CreatePrice($data: PriceInput!) {
    createPrice(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on Price {
        id
        baseAmount
        discountAmount
        billingCycle
        stripeProductId
        stripePriceId
        trialPeriodDays
      }
    }
  }
`;

export const DELETE_PRICE = gql`
  mutation DeletePrice($stripePriceId: String!) {
    deletePrice(stripePriceId: $stripePriceId) {
      ... on Error {
        message
        statusCode
      }
      ... on Price {
        id
        baseAmount
        discountAmount
        billingCycle
        stripeProductId
        stripePriceId
        trialPeriodDays
      }
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct(
    $stripeProductId: String!
    $name: String!
    $description: String!
    $items: [ItemsInput!]!
    $isFree: Boolean
    $isActive: Boolean
  ) {
    updateProduct(
      stripeProductId: $stripeProductId
      name: $name
      description: $description
      items: $items
      isFree: $isFree
      isActive: $isActive
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on Product {
        id
        name
        description
      }
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($stripeProductId: String!) {
    deleteProduct(stripeProductId: $stripeProductId) {
      ... on Product {
        id
        name
        description
        stripeProductId
        items {
          title
          functionalities {
            isAllowed
            feature
          }
        }
        isFree
        isActive
      }
    }
  }
`;

export const GRANT_USER_SUBSCRIPTION_ACCESS = gql`
  mutation GiveSubscriptionAccess($data: GiveSubscriptionAccessInput!) {
    giveSubscriptionAccess(data: $data) {
      ... on Error {
        message
        statusCode
        type
      }
      ... on StripeSubscriptionResponse {
        subscriptionStatus
        subscriptionMessage
      }
    }
  }
`;

export const UPDATE_USER_SUBSCRIPTION_ACCESS = gql`
  mutation UpdateSubscriptionAccess(
    $data: GiveSubscriptionAccessInput!
    $isActive: Boolean!
  ) {
    updateSubscriptionAccess(data: $data, isActive: $isActive) {
      ... on Error {
        message
        statusCode
      }
      ... on StripeSubscriptionResponse {
        subscriptionStatus
        subscriptionMessage
      }
    }
  }
`;
