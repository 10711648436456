import {Link, Outlet, useMatch, useResolvedPath} from 'react-router-dom';
import styles from './adminAnnouncement.module.scss';
import classnames from 'classnames/bind';

const CustomLink = ({to, name}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({path: resolved.pathname, end: true});
  const cx = classnames.bind(styles);
  return (
    <Link className={cx('nav-link', match ? 'active-toggle' : '')} to={to}>
      <span>{name}</span>
    </Link>
  );
};

const AnnouncementsManagement = () => {
  return (
    <div className={styles['investor-container']}>
      <div className={styles.header}>
        <h1>Announcements</h1>
      </div>
      <section className={styles.section}>
        <nav className={styles['investor-toggler']}>
          <CustomLink
            to={'/dashboard/announcements'}
            name="Active Announcements"
          />
          <CustomLink
            to={'/dashboard/announcements/upcoming'}
            name="Upcoming Announcements"
          />
          <CustomLink
            to={'/dashboard/announcements/expired'}
            name="Past Announcements"
          />
        </nav>
      </section>
      <section>
        <Outlet />
      </section>
    </div>
  );
};

export {AnnouncementsManagement};
