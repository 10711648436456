import styles from './singleInterest.module.scss';
import classnames from 'classnames/bind';
import {
  Link,
  useMatch,
  useParams,
  useResolvedPath,
  useNavigate,
  Outlet,
} from 'react-router-dom';
import {ArrowLeft} from 'icons';
import {GET_TOPIC_BY_ID} from 'graphql/queries/interest';
import {useQuery} from '@apollo/client';

export default function SingleInterest() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {data} = useQuery(GET_TOPIC_BY_ID, {
    variables: {
      topicId: id,
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button onClick={() => navigate('/dashboard/interest')}>
          <ArrowLeft />
        </button>
        <h1>
          Topics / <span>{data?.getTopic?.name}</span>
        </h1>
      </div>
      <section className={styles.section}>
        <nav className={styles['tab-toggler']}>
          <CustomLink to={`/dashboard/interest/${id}`} name="Topic Details" />
          <CustomLink
            to={`/dashboard/interest/${id}/users-interested`}
            name="Users Interested"
          />
        </nav>
      </section>
      <section>
        <Outlet />
      </section>
    </div>
  );
}

const CustomLink = ({to, name}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({path: resolved.pathname, end: true});
  const cx = classnames.bind(styles);

  return (
    <Link className={cx('nav-link', match ? 'active-toggle' : '')} to={to}>
      <span>{name}</span>
    </Link>
  );
};
