import {useEffect, useState} from 'react';
import styles from './pitchDeck.module.scss';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {useNavigate, useParams} from 'react-router-dom';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {pitchDeck} from 'redux/store/campaign';
import FadeEffect from 'components/animation/fadeEffect';
import UploadImage from 'components/Startup/profile/companyImage';
import {UploadDocumentToBucket} from '../legalDocument/uploadDocument';
import {Form, Input, Spin} from 'antd';
import {Button} from '@dabafinance/react-components';

const PitchDeck = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [pdfFile, setPdfFile] = useState(null);
  const [linkType, setLinkType] = useState('INTERNAL');
  const [disableButton, setDisabledButton] = useState(true);
  const [externalFileLink, setExternalFileLink] = useState('');
  const [externalShareLink, setExternalShareLink] = useState('');
  const [pdfLink, setPdfLink] = useState('');
  const [loadingPdfUpload, setLoadingPdfUpload] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [viewPdf, setViewPdf] = useState(null);
  const [pdfName, setPdfName] = useState('Supported formats pdf, docs');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id, slug, companyId} = useParams();

  const campaign = useSelector(state => state.campaign.campaign);

  useEffect(() => {
    if (campaign.pdfCoverImage != undefined) {
      setPdfFile(campaign?.pitchDeckFile);
      setPdfName(campaign?.pdfName);
      setExternalFileLink(campaign?.externalFileLink);
      setLinkType(campaign?.linkType);
      setExternalShareLink(campaign?.externalShareLink);
      setImgUrl(campaign?.pdfCoverImage);
      setPreviewUrl(campaign?.pdfCoverImage);
      setPdfLink(campaign?.pitchDeckPDFLink);
    }
  }, [campaign?.pdfCoverImage]);

  useEffect(() => {
    if (
      !imgUrl ||
      (!pdfLink && linkType === 'INTERNAL') ||
      (pdfLink && (externalFileLink || externalShareLink))
    ) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [imgUrl, pdfLink, linkType, externalFileLink, externalShareLink]);

  const fileType = ['application/pdf'];

  const handlePdfFileChange = async e => {
    const selectedFile = e.target.files[0];
    setPdfName(selectedFile.name);
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        setLoadingPdfUpload(true);
        const imageUrl = await UploadDocumentToBucket(selectedFile);
        setPdfLink(imageUrl);
        setPdfFile(imageUrl);
        setLoadingPdfUpload(false);
      } else {
        setPdfFile(null);
        toast.error('Please select a valid pdf file');
      }
    } else {
      toast.error('Select a PDF file');
    }
  };

  const handleDeleteFile = () => {
    setPdfFile('');
    setPdfLink('');
    setPdfName('Supported formats pdf, docs');
    setViewPdf('');
  };

  const handlePdfFileSave = e => {
    e.preventDefault();
    setViewPdf(pdfFile);
  };

  const handleSubmit = () => {
    if (slug) {
      dispatch(
        pitchDeck({
          pitchLink: pdfLink,
          pdfFile,
          pdfName,
          linkType,
          externalFileLink: externalFileLink?.trim(),
          externalShareLink: externalShareLink?.trim(),
          pdfCoverImage: imgUrl,
          pdfPreviewImage: previewUrl,
        }),
      );
      navigate(`/dashboard/campaigns/${slug}/new/${id}/analysis/${companyId}`);
    } else {
      dispatch(
        pitchDeck({
          pitchLink: pdfLink,
          pdfFile,
          pdfName,
          linkType,
          externalFileLink: externalFileLink?.trim(),
          externalShareLink: externalShareLink?.trim(),
          pdfCoverImage: imgUrl,
          pdfPreviewImage: previewUrl,
        }),
      );
      navigate(`/dashboard/campaigns/new/${id}/analysis/${companyId}`);
    }
  };

  return (
    <div className={styles.container}>
      <h1>
        {campaign?.listType === 'IPO' ? 'IPO Roadshow Material' : 'Pitch Deck'}
      </h1>
      <span>
        Please note that you are only allowed to choose one type to proceed
      </span>
      <div className={styles['details-toggler']}>
        <span
          className={linkType === 'INTERNAL' ? styles['active-toggle'] : ''}
          onClick={() => setLinkType('INTERNAL')}>
          {' '}
          PDF UPLOAD
        </span>
        <span
          className={linkType === 'EXTERNAL' ? styles['active-toggle'] : ''}
          onClick={() => setLinkType('EXTERNAL')}>
          {' '}
          EXTERNAL LINK
        </span>
      </div>
      <div className={styles['pitch-body']}>
        <Form onFinish={handleSubmit} layout="vertical">
          {linkType === 'INTERNAL' && (
            <>
              {!viewPdf && (
                <form>
                  <label className={styles.upload} htmlFor="file-upload">
                    {loadingPdfUpload ? (
                      <div className={styles.spinner}>
                        <Spin size="large" />
                        <h3>Uploading...</h3>
                      </div>
                    ) : (
                      <span> {pdfName}</span>
                    )}
                    <input
                      type="file"
                      id="file-upload"
                      accept="application/pdf"
                      className={styles['input-file']}
                      required
                      onChange={handlePdfFileChange}
                    />
                  </label>
                </form>
              )}
              {viewPdf && (
                <FadeEffect>
                  <div className={styles['pdf-height']}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                      <Viewer
                        fileUrl={viewPdf}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </Worker>
                  </div>
                </FadeEffect>
              )}
            </>
          )}
          {linkType === 'EXTERNAL' && (
            <>
              <Form.Item
                className={styles['field-width']}
                label="File Link"
                name="fileLink"
                initialValue={campaign?.externalFileLink}
                rules={[
                  {
                    required: linkType === 'EXTERNAL',
                    message: 'External Link is required',
                  },
                  {
                    type: 'url',
                    message: 'Please make sure link is valid',
                  },
                ]}>
                <Input
                  defaultValue={campaign?.externalFileLink}
                  value={externalFileLink}
                  onChange={e => setExternalFileLink(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                className={styles['field-width']}
                label="Share Link"
                name="shareLink"
                initialValue={campaign?.externalShareLink}
                rules={[
                  {
                    required: linkType === 'EXTERNAL',
                    message: 'Share link is required',
                  },
                  {
                    type: 'url',
                    message: 'Please make sure link is valid',
                  },
                ]}>
                <Input
                  value={externalShareLink}
                  defaultValue={campaign?.externalShareLink}
                  onChange={e => setExternalShareLink(e.target.value)}
                />
              </Form.Item>
            </>
          )}
          {(pdfLink || viewPdf) && linkType !== 'EXTERNAL' && (
            <div className={styles['button-wrapper']}>
              <Button
                disabled={pdfFile === ''}
                label="Preview PDF"
                onClick={handlePdfFileSave}
              />
              <Button
                type="secondary"
                label="Delete"
                onClick={handleDeleteFile}
              />
            </div>
          )}
          <div className={styles.image}>
            <h1 className={styles.title}>
              {campaign?.listType === 'IPO'
                ? 'IPO Roadshow Preview Image'
                : 'Select Pitch Deck Preview Image'}
            </h1>
            <div>
              <UploadImage
                previewUrl={previewUrl}
                setPreviewUrl={setPreviewUrl}
                large
                setImgUrl={setImgUrl}
                bucket={'campaign-bucket-images'}
              />
            </div>
          </div>
          <div className={styles.submit}>
            <SubmitButton disabled={disableButton} label="Save and Proceed" />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default PitchDeck;
