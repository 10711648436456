import {Button} from '@dabafinance/react-components';
import styles from './affiliates.module.scss';
import AffiliatesTable from './page/AffiliatesTable/AffiliatesTable';
import {useEffect, useState} from 'react';
import AffiliateReservations from './page/AffiliateReservations/AffiliateReservations';
import AffiliateConfigurationDashboard from './page/AffiliateConfiguration';
import {useLocation, useNavigate} from 'react-router-dom';

const Affiliates = () => {
  const history = useNavigate();
  const location = useLocation();
  const [modalContent, setModalContent] = useState({});

  const [activeTab, setActiveTab] = useState('AFFILIATES');
  const affiliateTypes = ['AFFILIATES', 'RESERVATIONS', 'CONFIGURATION'];

  // Effect to set the active tab based on the search param
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab && affiliateTypes.includes(tab)) {
      setActiveTab(tab);
    }
  }, [location.search, affiliateTypes]);

  const handleTabClick = tab => {
    setActiveTab(tab);
    history(`?tab=${tab}`);
  };

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <h1>Affiliates</h1>
        {activeTab === 'AFFILIATES' && (
          <Button
            label="Create Affiliate"
            onClick={() => setModalContent({isOpen: true, type: 'CREATE'})}
          />
        )}
      </div>
      <div className={styles['details-toggler']}>
        {affiliateTypes.map(tab => (
          <span
            key={tab}
            className={activeTab === tab ? styles['active-toggle'] : ''}
            onClick={() => handleTabClick(tab)}>
            {tab}
          </span>
        ))}
      </div>
      <div>
        {activeTab === 'AFFILIATES' && (
          <AffiliatesTable
            modalContent={modalContent}
            setModalContent={setModalContent}
          />
        )}
        {activeTab === 'RESERVATIONS' && (
          <AffiliateReservations isAffiliate={true} />
        )}
        {activeTab === 'CONFIGURATION' && <AffiliateConfigurationDashboard />}
      </div>
    </div>
  );
};

export default Affiliates;
