import {Modal, Spin, Table} from 'antd';
import {useState} from 'react';
import styles from './allAnnouncements.module.scss';
import {Button} from '@dabafinance/react-components';
import pageNotFound from 'images/pageNotFound.png';
import {useMutation, useQuery} from '@apollo/client';
import {toast} from 'react-toastify';
import {GET_ALL_ANNOUNCEMENTS} from 'graphql/queries/announcement';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import deleteImg from 'images/delete.svg';
import editImg from 'images/edit.svg';
import {DELETE_ANNOUNCEMENT} from 'graphql/mutations/announcement';
import {CopyOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const UpcomingAnnouncements = () => {
  const {confirm} = Modal;
  const navigate = useNavigate();
  const [allAnnouncements, setAllAnnouncements] = useState([]);
  const [copyText, setCopyText] = useState('Copy');

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Announcements),
  );

  const {loading} = useQuery(GET_ALL_ANNOUNCEMENTS, {
    variables: {
      type: 'UPCOMING',
    },
    onCompleted: data =>
      setAllAnnouncements(data?.getAllAnnouncements?.announcement),
    onError: error => toast.error(error),
  });

  const [deleteAnnouncement] = useMutation(DELETE_ANNOUNCEMENT);

  const handleDelete = id => {
    confirm({
      content: 'Are you sure you want to delete this announcement?',
      onOk: () => {
        deleteAnnouncement({
          variables: {
            announcementId: id,
          },
          update(cache) {
            cache.modify({
              fields: {
                getAllAnnouncements() {},
              },
            });
          },
        }).then(({data: {deleteAnnouncement}}) => {
          if (deleteAnnouncement.__typename === 'Error') {
            toast.error(deleteAnnouncement.message);
          } else {
            toast.success('Deleted Successfully');
          }
        });
      },
    });
  };

  const urlTrim = announcementUrl => {
    if (announcementUrl?.length > 27) {
      return announcementUrl.substr(0, 27);
    } else return announcementUrl;
  };

  const copyUrl = (announcementUrl, e) => {
    if (e) {
      navigator.clipboard.writeText(announcementUrl);
      setCopyText('Copied');
    }
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <img src={record?.imageUrl} alt="" />
          <h4>{record?.type.replaceAll('_', ' ')}</h4>
        </div>
      ),
    },
    {
      title: 'Announcement Link',
      dataIndex: 'announcementUrl',
      key: 'announcementUrl',
      render: (text, render) => (
        <div className={styles['record-label']}>
          {urlTrim(render?.announcementUrl)}
          <span
            className={styles.tooltip}
            onClick={e => copyUrl(render?.announcementUrl, e)}
            onMouseLeave={() => setCopyText('Copy')}>
            <CopyOutlined /> {copyText}{' '}
          </span>
        </div>
      ),
    },
    {
      title: 'Published Date',
      dataIndex: 'publishedDate',
      key: 'publishedDate',
      render: (text, render) => moment(render?.publishedDate).format('DD MMMM'),
    },
    {
      title: 'Expired Date',
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      render: (text, record) => moment(record?.expiredDate).format('DD MMMM'),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <div className={styles.actions}>
          {tabOperations?.delete && (
            <div
              onClick={() => handleDelete(record?.id)}
              className={styles['action-icon']}>
              <img src={deleteImg} alt="delete" />
              <span>Delete</span>
            </div>
          )}
          {tabOperations?.update && (
            <div
              onClick={() => navigate(`/dashboard/announcements/${record?.id}`)}
              className={styles['action-icon']}>
              <img src={editImg} alt="edit" />
              <span>Edit</span>
            </div>
          )}
        </div>
      ),
    },
  ];

  const noUpdates = (
    <div className={styles['no-updates']}>
      <img src={pageNotFound} alt="page not found" />
      <h1>No Announcement</h1>
      <span>
        There are currently no announcement, you could create a new one
      </span>
      <Button
        label="Create Announcement"
        disabled={!tabOperations?.create}
        className={styles.button}
        onClick={() => navigate('/dashboard/announcements/new')}
      />
    </div>
  );

  return (
    <div className={styles['table-container']}>
      <div className={styles.header}>
        <h1>Upcoming Announcements</h1>
        <Button
          onClick={() => navigate('/dashboard/announcements/new')}
          label="Create Announcement"
          disabled={!tabOperations?.create}
          className={styles.button}
        />
      </div>
      <div className={styles['startup-table']}>
        {loading ? (
          <div className={styles.loading}>
            <Spin size="large" />
            <h1>Loading...</h1>
          </div>
        ) : allAnnouncements?.length >= 1 ? (
          <Table
            className={styles['user-table']}
            dataSource={allAnnouncements}
            columns={columns}
          />
        ) : (
          noUpdates
        )}
      </div>
    </div>
  );
};

export {UpcomingAnnouncements};
