import styles from './usersInterested.module.scss';
import {Form, Input, Table} from 'antd';
import {useLazyQuery, useQuery} from '@apollo/client';
import {USERS_INTERESTED_IN_TOPICS} from 'graphql/queries/interest';
import {useNavigate, useParams} from 'react-router-dom';
import moment from 'moment';
import gamer from 'images/gamer.png';
import {useState} from 'react';
import {Button} from '@dabafinance/react-components';
import SubmitButton from 'components/Startup/components/SubmitButton';
import DBModal from 'components/modal/modal';
import {toast} from 'react-toastify';

export default function UsersInterested() {
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [downloadEmail, setDownloadEmail] = useState('');
  const {id} = useParams();
  const navigate = useNavigate();
  const {data, loading} = useQuery(USERS_INTERESTED_IN_TOPICS, {
    variables: {
      filter: {
        topicId: id,
        page: currentPage,
        limit: 10,
      },
    },
  });
  const [getAllUsersFollowingTopic, {loading: loadingExport}] = useLazyQuery(
    USERS_INTERESTED_IN_TOPICS,
    {fetchPolicy: 'no-cache'},
  );

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };

  const handleDownloadAllUsers = () => {
    if (downloadEmail) {
      getAllUsersFollowingTopic({
        variables: {
          filter: {
            email: downloadEmail,
            topicId: id,
            export: true,
          },
        },
      })
        .then(({data: {getAllUsersFollowingTopic}}) => {
          if (getAllUsersFollowingTopic.statusCode === 'Error') {
            toast.error(getAllUsersFollowingTopic.message);
          } else {
            toast.success('Successful, Please check your mail!');
            setOpenModal(false);
          }
        })
        .catch(err => toast.error(err));
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div
          onClick={() => navigate(`/dashboard/user/${record?.id}`)}
          className={styles['record-label']}>
          <img src={record?.imageUrl ? record?.imageUrl : gamer} alt="" />
          <span>
            {record?.firstName} {record?.lastName}
          </span>
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => (
        <div className={styles['row-width']}>{record?.email}</div>
      ),
    },
    {
      title: 'Joined',
      dataIndex: 'joined',
      key: 'joined',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>{moment(record?.createdAt).format('DD MMMM YYYY')}</span>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles['inner-container']}>
        <div className={styles.top}>
          <h2 className={styles.heading}>Users Interested</h2>
          <SubmitButton
            label="Export Users"
            handleClick={() => setOpenModal(true)}
          />
        </div>
        <div>
          <div className={styles['nav-buttons']}>
            <Button
              onClick={handlePreviousPage}
              label="Previous"
              type="secondary"
              disabled={
                !data?.getAllUsersFollowingTopic?.pagination?.hasPrevPage ||
                loading
              }
              className={styles.button}
            />
            <div className={styles['page-number']}>{currentPage}</div>
            <Button
              onClick={handleNextPage}
              label="Next"
              disabled={
                !data?.getAllUsersFollowingTopic?.pagination?.hasNextPage ||
                loading
              }
              className={styles.button}
            />
          </div>
          <Table
            dataSource={data?.getAllUsersFollowingTopic?.data}
            columns={columns}
            pagination={false}
            loading={loading}
          />
        </div>
      </div>
      <DBModal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        content={
          <div className={styles.userDownloadModal}>
            <div className={styles.modalContent}>
              <div className={styles.modalHeader}>
                <h1>Export Users</h1>
                <span>
                  Export a CSV file of USERS to your Daba Email. Please Note the
                  mail might be in your spam folder
                </span>
              </div>
              <Form onFinish={handleDownloadAllUsers} layout="vertical">
                <Form.Item
                  rules={[
                    {
                      type: 'email',
                      required: true,
                      message: 'Email is Required',
                    },
                  ]}
                  style={{width: 400, marginBottom: '20px'}}
                  name="email"
                  label="Email">
                  <Input
                    placeholder="admin@joindaba.com"
                    value={downloadEmail}
                    onChange={e => setDownloadEmail(e.target.value)}
                  />
                </Form.Item>
                <SubmitButton label="Download" disabled={loadingExport} />
              </Form>
            </div>
          </div>
        }
      />
    </div>
  );
}
