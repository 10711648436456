import {gql} from '@apollo/client';

export const CREATE_INFORMATION_SECTION = gql`
  mutation CreateInformationSection($data: createInformationSectionInput!) {
    createInformationSection(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on InformationSectionType {
        informationSection {
          id
          title
        }
      }
    }
  }
`;

export const CREATE_LEARN_ARTICLE = gql`
  mutation CreateLearnArticle($data: createLearnArticleInput!) {
    createLearnArticle(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on LearnArticleType {
        article {
          id
          informationSection {
            id
            title
          }
          title
          summary
          content
          featuredImage
          videoUrl
          publishedDate
          author {
            id
            email
            firstName
            username
          }
          updatedDate
        }
      }
    }
  }
`;
export const DELETE_LEARN_ARTICLE = gql`
  mutation DeleteLearnArticle($articleId: ID!) {
    deleteLearnArticle(articleId: $articleId) {
      ... on Error {
        message
        statusCode
      }
      ... on LearnArticleType {
        article {
          id
          title
        }
      }
    }
  }
`;

export const UPDATE_LEARN_ARTICLE = gql`
  mutation UpdateLearnArticle($data: updateLearnArticleInput!) {
    updateLearnArticle(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on LearnArticleType {
        article {
          id
          title
          summary
          content
          informationSection {
            id
            title
          }
          featuredImage
          videoUrl
          author {
            id
            email
            firstName
          }
        }
      }
    }
  }
`;
