import {useEffect, useState} from 'react';
import styles from './appleSku.module.scss';
import backIcon from 'images/back.png';
import {Form, Input, Select, Spin} from 'antd';
import {useMutation, useQuery} from '@apollo/client';
import {GET_SUBSCRIPTION_PRODUCTS} from 'graphql/queries/plans';
import {useNavigate} from 'react-router-dom';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {ADD_APPLE_SKU_TO_PRICE} from 'graphql/mutations/plans';
import {toast} from 'react-toastify';

const AddAppleSku = () => {
  const [product, setProduct] = useState('');
  const [price, setPrice] = useState([]);
  const [priceId, setPriceId] = useState('');
  const [appleSku, setAppleSku] = useState();
  const navigate = useNavigate();

  const {data: getProduct, loading} = useQuery(GET_SUBSCRIPTION_PRODUCTS);
  const [addAppleSkuToPrice, {loading: loadingSave}] = useMutation(
    ADD_APPLE_SKU_TO_PRICE,
  );

  useEffect(() => {
    setPrice(
      getProduct?.getSubscriptionProducts?.subscriptionProducts?.filter(
        data => data.id === product,
      )[0],
    );
  }, [product, price]);

  const handleSave = () => {
    addAppleSkuToPrice({
      variables: {
        priceId,
        appleSku: appleSku.trim(),
      },
    })
      .then(({data: {addAppleSkuToPrice}}) => {
        if (addAppleSkuToPrice.__typename === 'Error') {
          toast.error(addAppleSkuToPrice.message);
        } else {
          toast.success('Created Successfully');
          navigate('/dashboard/plans');
        }
      })
      .catch(error => toast.error(error.message));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>Apple SKU</h1>
      </div>
      <div className={styles.body}>
        <div className={styles.title}>
          <h3>Add Apple SKU to an existing price</h3>
          <span className={styles.subTitle}>
            Select an existing product and its corresponding price, then input
            the Apple SKU value
          </span>
        </div>
        <Form onFinish={handleSave} layout="vertical">
          <Form.Item
            className={styles['field-width']}
            name="product"
            label="Select Product"
            rules={[{required: true, message: 'Required'}]}>
            <Select
              value={product}
              onChange={e => setProduct(e)}
              placeholder="Choose a Product">
              {getProduct?.getSubscriptionProducts?.subscriptionProducts?.map(
                data => (
                  <Select.Option key={data?.id}>
                    <span>{data?.name}</span>
                  </Select.Option>
                ),
              )}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles['field-width']}
            name="price"
            label="Select Price"
            rules={[{required: true, message: 'Required'}]}>
            <Select
              value={priceId}
              onChange={e => setPriceId(e)}
              placeholder="Choose a price from the above product">
              {price?.prices?.map(data => (
                <Select.Option key={data?.id}>
                  <span>
                    {data?.billingCycle
                      ? data?.billingCycle
                      : 'Life Time Price'}
                  </span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles['field-width']}
            name="appleSku"
            label="Apple SKU"
            rules={[{required: true, message: 'Required'}]}>
            <Input
              value={appleSku}
              onChange={e => setAppleSku(e.target.value)}
            />
          </Form.Item>
          <Form.Item name="button">
            <SubmitButton label="Save Changes" disabled={loadingSave} />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddAppleSku;
