import {gql} from '@apollo/client';

export const CREATE_KEY_STAT = gql`
  mutation CreateKeyStat($data: KeyStatInput!) {
    createKeyStat(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on KeyStat {
        name
        language
        description
        applicableAssetClasses
        formatType
        isComputed
        canBeManuallyOverridden
        id
      }
    }
  }
`;

export const UPDATE_KEY_STAT = gql`
  mutation UpdateKeyStat($data: UpdateKeyStatInput!) {
    updateKeyStat(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on KeyStat {
        name
        language
        description
        applicableAssetClasses
        formatType
        isComputed
        canBeManuallyOverridden
        id
      }
    }
  }
`;

export const DELETE_KEY_STAT = gql`
  mutation DeleteKeyStat($keystatsId: ID!) {
    deleteKeyStat(keystatsId: $keystatsId) {
      ... on Error {
        message
        statusCode
      }
      ... on KeyStat {
        name
        language
        description
        applicableAssetClasses
        formatType
        isComputed
        canBeManuallyOverridden
        id
      }
    }
  }
`;

export const ADD_COMPANY_KEY_STAT = gql`
  mutation UpdateCompanyKeyMetric($data: [UpdateKeyMetric]!) {
    updateCompanyKeyMetric(data: $data) {
      companyId
      keyStatId
      value
    }
  }
`;

export const DELETE_COMPANY_KEY_STAT = gql`
  mutation DeleteCompanyKeyMetric($data: [UpdateKeyMetric]!) {
    deleteCompanyKeyMetric(data: $data) {
      companyId
      keyStatId
      value
    }
  }
`;
