import moment from 'moment';

const numberWithCommas = x => {
  if (!x) return '-';
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const convertToCurrency = val => {
  if (!val) return '-';
  const valWithComma = numberWithCommas(val);
  return `${valWithComma.replace('-', '-$')}`;
};

const convertToCurrencyRange = (vals = []) => {
  if (!vals && typeof val !== 'object' && vals.length !== 2) {
    return '';
  }
  return `${convertToCurrency(vals[0])}-${convertToCurrency(vals[1])}`;
};

const debounce = func => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 1200);
  };
};

const getUsersChartData = (activeControl, users, setChartData) => {
  switch (activeControl) {
    case 'DAY':
      return users?.daily?.map(data => {
        return {
          date: moment(data?.createdAt).format('L'),
          amount: data?.total,
        };
      });
    case 'WEEK':
      return users?.weekly?.map(data => {
        const val = moment(data?.createdAt).format('WW');
        return {
          date: `Wk ${val} ${moment(
            moment(
              moment(moment().isoWeekYear(moment().year())).isoWeek(val),
            ).startOf('week'),
          ).format('MMM D')}`,
          amount: data?.total,
        };
      });
    case 'MONTH':
      return users?.monthly?.map(data => {
        return {
          date: moment(data?.createdAt).format('MMMM YYYY'),
          amount: data?.total,
        };
      });
    case 'YEAR':
      return users?.yearly?.map(data => {
        return {
          date: moment(data?.createdAt).format('YYYY'),
          amount: data?.total,
        };
      });
    default:
      return [];
  }
};

const getFilteredData = (activeControl, originalData) => {
  // Filters data, by date. assumes definitive data structure.

  switch (activeControl) {
    case '1D':
      return originalData.filter(instance =>
        moment(instance.date).isBetween(moment().subtract(1, 'days')),
      );
    case '3D':
      return originalData.filter(instance =>
        moment(instance.date).isBetween(moment().subtract(1, 'days')),
      );
    case '1W':
      return originalData.filter(instance =>
        moment(instance.date).isBetween(moment().subtract(1, 'weeks')),
      );
    case '1M':
      return originalData.filter(instance =>
        moment(instance.date).isBetween(moment().subtract(1, 'months')),
      );

    case '3M':
      return originalData.filter(instance =>
        moment(instance.date).isBetween(moment().subtract(3, 'months')),
      );
    case '1Y':
      return originalData.filter(instance =>
        moment(instance.date).isBetween(moment().subtract(1, 'years')),
      );
    default:
      return originalData;
  }
};

const fieldRules = (fieldName, required = true) => {
  return {
    required,
    message: `${fieldName} is required!`,
  };
};

const truncateTitle = title => {
  if (title.length > 52) {
    return title.substring(0, 52) + '...';
  } else return title;
};

const sanitizeInput = value => {
  // Remove leading/trailing whitespaces and replace consecutive spaces with a single space
  return value.trim().replace(/\s+/g, ' ');
};

const customFilterSelectSearch = (option, searchText) => {
  const optionKey = `${option?.firstName};${option?.lastName};${option?.id};${option?.email}`;
  const sanitizedSearchText = searchText
    .trim()
    .replace(/\s+/g, ' ')
    .toLowerCase();
  const searchWords = sanitizedSearchText.split(' ');

  return searchWords.every(word => optionKey.toLowerCase().includes(word));
};

const formatWhatsappTemplate = (templateString = '', variables = []) => {
  let replacedString = templateString;

  variables.forEach(variable => {
    const placeholder = `{{${variable.name}}}`;
    const replacement = `<span>${variable.description}</span>`;

    replacedString = replacedString.split(placeholder).join(replacement);
  });

  return replacedString;
};

const removeHtmlTags = (inputString = '') => {
  // Replace any HTML tags with an empty string
  return inputString.replace(/<\/?[^>]+(>|$)/g, '');
};

function getMenuItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

function getUserTimezone() {
  const offsetMinutes = new Date().getTimezoneOffset();

  // Convert the offset to hours and minutes
  const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
  const offsetMins = Math.abs(offsetMinutes) % 60;

  // Determine if the offset is positive or negative
  const offsetSign = offsetMinutes > 0 ? '-' : '+';

  // Format the offset as UTC+X or UTC-X
  return `UTC${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(
    offsetMins,
  ).padStart(2, '0')}`;
}

const formatRoleString = str => {
  return str
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const removePrefix = documentName => {
  const prefix = 'daba-finance-company-report-';
  return documentName.startsWith(prefix)
    ? documentName.substring(prefix.length)
    : documentName;
};

const convertEmptyStringsToNull = variables => {
  return Object.fromEntries(
    Object.entries(variables).map(([key, value]) => [
      key,
      value === '' ? null : value,
    ]),
  );
};

const FeeTypeEnum = {
  Fixed: 'FIXED',
  Floating: 'FLOATING',
  Static: 'STATIC',
  Variable: 'VARIABLE',
};

function computeFeeFromFeeSchedule(feeSchedule, amount, skip) {
  let transactionFees = 0;
  if (feeSchedule?.length) {
    feeSchedule.forEach(fee => {
      if (fee.feeType) {
        if (
          (skip.skipFixed && fee?.feeType === FeeTypeEnum.Fixed) ||
          (skip.skipFloating && fee?.feeType === FeeTypeEnum.Floating) ||
          (skip.skipStatic && fee?.feeType === FeeTypeEnum.Static) ||
          (skip.skipVariable && fee?.feeType === FeeTypeEnum.Variable)
        ) {
          return;
        }
        if (
          [FeeTypeEnum.Fixed, FeeTypeEnum.Static].includes(fee.feeType) &&
          fee?.fixedFeeAmount &&
          fee.fixedFeeAmount > 0
        ) {
          transactionFees += fee?.fixedFeeAmount ?? 0;
        } else if (fee?.variableFeeAmount && fee.variableFeeAmount > 0) {
          transactionFees += (fee.variableFeeAmount / 100) * amount;
        }
      }
    });
  }
  return transactionFees ? transactionFees?.toFixed(2) : transactionFees;
}

const formatValue = (value, formatFn) => (value ? formatFn(value) : value);
const convertToNumber = value => (value ? Number(value) : value);
const extractCompanyId = companyId =>
  companyId ? companyId.split('~')[1] : companyId;
const extractUserId = user => (user ? user.split(';')[2] : user);

const formatEnumType = type => {
  if (!type) return '';
  return type
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

function fullyDecodeURL(url) {
  let decodedURL = url;
  let previousURL;

  // Continue decoding until the decoded URL no longer changes
  do {
    previousURL = decodedURL;
    decodedURL = decodeURIComponent(decodedURL);
  } while (decodedURL !== previousURL); // Loop stops when decoding no longer changes the string

  return decodedURL;
}

const handleCutoffTime = cutoffTime => {
  if (!cutoffTime) return null;
  const hour = cutoffTime ? cutoffTime.hour() : null;
  const minute = cutoffTime ? cutoffTime.minute() : null;
  return `${hour}:${minute}`;
};

export {
  numberWithCommas,
  convertToCurrency,
  convertToCurrencyRange,
  getFilteredData,
  fieldRules,
  customFilterSelectSearch,
  sanitizeInput,
  removePrefix,
  handleCutoffTime,
  computeFeeFromFeeSchedule,
  convertEmptyStringsToNull,
  fullyDecodeURL,
  truncateTitle,
  getUsersChartData,
  formatRoleString,
  getMenuItem,
  formatEnumType,
  formatWhatsappTemplate,
  getUserTimezone,
  debounce,
  removeHtmlTags,
  formatValue,
  convertToNumber,
  extractCompanyId,
  extractUserId,
};
