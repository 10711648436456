import React from 'react';
import styles from './adminreport.module.scss';

// tab components
import Company from './company';
import Country from './country';
import Industry from './industry';

const AdminReports = () => {
  const [activeTab, setActiveTab] = React.useState('COMPANY');

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>Reports</h1>
      </section>
      <section className={styles['sections-tab']}>
        <div className={styles['verifier-toggler']}>
          <span
            onClick={() => setActiveTab('COMPANY')}
            className={activeTab === 'COMPANY' ? styles['active-tab'] : ''}>
            Company
          </span>
          <span
            onClick={() => setActiveTab('COUNTRY')}
            className={activeTab === 'COUNTRY' ? styles['active-tab'] : ''}>
            Country
          </span>
          <span
            onClick={() => setActiveTab('INDUSTRY')}
            className={activeTab === 'INDUSTRY' ? styles['active-tab'] : ''}>
            Industry
          </span>
          <span
            onClick={() => setActiveTab('EXPERT INTERVIEWS')}
            className={
              activeTab === 'EXPERT INTERVIEWS' ? styles['active-tab'] : ''
            }>
            Expert Interviews
          </span>
        </div>
      </section>
      {/* Tab Components */}
      {activeTab === 'COMPANY' && <Company />}
      {activeTab === 'INDUSTRY' && <Industry />}
      {activeTab === 'COUNTRY' && <Country />}
    </div>
  );
};

export {AdminReports};
