import {toast} from 'react-toastify';

export const onTranslateStockClosure = async (query, translateProps) => {
  const {sourceLang, targetLang, targetText1, targetText2} = translateProps;
  try {
    const {data} = await query({
      variables: {
        translations: [
          {sourceLang, sourceText: targetText1, targetLang},
          {sourceLang, sourceText: targetText2, targetLang},
        ],
        treatment: 'html',
      },
    });
    return {
      title: data.translateText.translations[0],
      message: data.translateText.translations[1],
      language: targetLang,
    };
  } catch (error) {
    toast.error('Something went wrong');
  }
};
