import {GET_SAVING_COMPANIES} from 'graphql/queries/savings';
import {toast} from 'react-toastify';
import {addOrganizationProfile} from 'redux/store/savings';

const updateSavingsOrganizationStatus = ({
  id,
  status,
  updateOrganization,
  onClose,
  refetch,
}) => {
  updateOrganization({
    variables: {
      input: {
        id,
        status,
      },
    },
  }).then(({data: {updateOrganization}}) => {
    if (updateOrganization.__typename === 'Error') {
      throw new Error(updateOrganization.message);
    } else {
      refetch();
      toast.success('Organization Status Successfully');
      onClose();
    }
  });
};

const updateSavingsCompany = ({
  formValues,
  id,
  updateOrganization,
  setOpenFinishModal,
  dispatch,
}) => {
  const {
    name,
    city,
    website,
    companyEmail,
    state,
    businessAddress,
    companyPhoneNumber,
    country,
    status,
    industry,
    NoOfEmployees,
    keyContactPersonName,
    keyContactPersonEmail,
    keyContactPersonPhoneNumber,
  } = formValues;

  updateOrganization({
    variables: {
      input: {
        ...(name && {name: name?.trim()}),
        city,
        website,
        companyEmail,
        id,
        state,
        status,
        businessAddress,
        companyPhoneNumber,
        country: country ?? null,
        industry: industry ?? null,
        NoOfEmployees: Number(NoOfEmployees),
        keyContactPersonName,
        keyContactPersonEmail,
        keyContactPersonPhoneNumber,
      },
    },
  })
    .then(({data: {updateOrganization}}) => {
      if (updateOrganization.__typename === 'Error') {
        throw new Error(updateOrganization.message);
      } else {
        toast.success('Action Successfully');
        dispatch(addOrganizationProfile(updateOrganization?.data));
        setOpenFinishModal(false);
      }
    })
    .catch(error => {
      toast.error(`GraphQL Error: ${error.message}`);
    });
};

const createSavingsCompany = ({
  formValues,
  img,
  createOrganization,
  setOpenFinishModal,
  navigate,
}) => {
  if (!img) return toast.error('Please upload a logo');
  const {
    name,
    city,
    website,
    companyEmail,
    state,
    businessAddress,
    companyPhoneNumber,
    country,
    industry,
    NoOfEmployees,
    keyContactPersonName,
    keyContactPersonEmail,
    keyContactPersonPhoneNumber,
  } = formValues;

  createOrganization({
    variables: {
      input: {
        name: name.trim(),
        city,
        website,
        logo: img,
        companyEmail,
        state,
        businessAddress,
        companyPhoneNumber,
        country,
        industry,
        ...(NoOfEmployees && {NoOfEmployees: Number(NoOfEmployees)}),
        keyContactPersonName,
        keyContactPersonEmail,
        keyContactPersonPhoneNumber,
      },
    },
    refetchQueries: [
      {query: GET_SAVING_COMPANIES, variables: {page: 1, limit: 100}},
    ],
  })
    .then(({data: {createOrganization}}) => {
      if (createOrganization.__typename === 'Error') {
        throw new Error(createOrganization.message);
      } else {
        toast.success('Action Successfully');
        setOpenFinishModal(false);
        navigate(-1);
      }
    })
    .catch(error => {
      toast.error(`GraphQL Error: ${error.message}`);
    });
};

const handleUpdateSavingsProduct = ({
  formValues,
  id,
  updateSavingsProduct,
  setOpenFinishModal,
  refetch,
}) => {
  updateSavingsProduct({
    variables: {
      updateSavingsProductId: id,
      input: {
        ...formValues,
      },
    },
  })
    .then(({data: {updateSavingsProduct}}) => {
      if (updateSavingsProduct.__typename === 'Error') {
        throw new Error(updateSavingsProduct.message);
      } else {
        toast.success('Updated Successfully');
        setOpenFinishModal(false);
        refetch();
      }
    })
    .catch(error => {
      toast.error(`GraphQL Error: ${error.message}`);
    });
};

export {
  updateSavingsCompany,
  createSavingsCompany,
  handleUpdateSavingsProduct,
  updateSavingsOrganizationStatus,
};
