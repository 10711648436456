import {useQuery} from '@apollo/client';
import {Select} from 'antd';
import {GET_ALL_USERS_BY_NAME} from 'graphql/queries/campaign';
import {useEffect, useState} from 'react';
import styles from './usersSelectField.module.scss';
import _ from 'lodash';
import {customFilterSelectSearch, sanitizeInput} from 'utils/helper';

export const UserSelectField = ({
  users,
  setUsers,
  skip,
  defaultUsers,
  mode,
}) => {
  const {Option} = Select;
  const [limit, setLimit] = useState(50);
  const [usersData, setUsersData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [hasNextPage, setHasNextPage] = useState(true);

  const {networkStatus, refetch, loading, fetchMore} = useQuery(
    GET_ALL_USERS_BY_NAME,
    {
      variables: {
        filters: {
          pagination: {
            limit: limit,
          },
        },
      },
      skip,
      onCompleted: data => {
        setHasNextPage(data?.getAllUsers?.hasNextPage);
        setUsersData(data?.getAllUsers?.users);
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const debouncedSetSearchValue = _.debounce(value => {
    setSearchValue(value);
    const sanitizedValue = sanitizeInput(value);

    if (sanitizedValue === '') return;

    refetch({
      filters: {
        pagination: {
          limit,
        },
        sort: {
          order: 'DESC',
          field: 'createdAt',
        },
        user: {
          username: sanitizedValue || null,
        },
      },
    });
  }, 1200);

  const onScroll = event => {
    const target = event.target;
    if (!hasNextPage) return;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const newLimit = limit + 50;
      setLimit(newLimit);
      fetchMore({
        variables: {
          filters: {
            pagination: {
              limit: newLimit,
            },
            user: {
              username: target.value,
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    if (defaultUsers?.length > 0) {
      refetch({
        filters: {
          user: {
            userIds: defaultUsers,
          },
        },
      });
    }
  }, [defaultUsers]);

  const filterOptions = data => {
    // Split the search value by commas and trim whitespace
    const searchTerms = searchValue
      .split(',')
      .map(term => term.trim())
      .filter(term => term);

    if (searchTerms.length === 0) return true;
    // Check if any of the search terms match the user's first or last name
    return searchTerms.some(
      term => customFilterSelectSearch(data, term), // Adjust this function to match against the term
    );
  };

  return (
    <Select
      mode={mode === 'single' ? '' : 'multiple'}
      value={users}
      placeholder="Search and Select User(s)"
      showSearch
      allowClear
      filterOption={false}
      onPopupScroll={onScroll}
      onSearch={e => debouncedSetSearchValue(e)}
      onChange={e => setUsers(e)}>
      {usersData?.length > 0 &&
        usersData
          ?.filter(data => data?.firstName != null)
          ?.filter(filterOptions)

          ?.map(data => (
            <Option
              key={`${data?.firstName};${data?.lastName};${data?.id};${data?.email}`}>
              <div className={styles.image}>
                <img src={data?.imageUrl} alt="" />
                <span>
                  {data?.firstName} {data?.lastName}
                </span>
              </div>
            </Option>
          ))}
      {networkStatus === 2 && <Option key="Loading..." />}
    </Select>
  );
};
