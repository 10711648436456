import {Form, Input, Spin} from 'antd';
import {Fragment, useEffect, useState} from 'react';
import trash from 'images/trash-can-outline.svg';
import plusIcon from 'images/plus-circle.png';
import styles from './campaignNews.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useParams} from 'react-router-dom';
import FadeEffect from 'components/animation/fadeEffect';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {useMutation, useQuery} from '@apollo/client';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const UpdateCampaignStatistics = () => {
  const [statisticValues, setStatisticValues] = useState([
    {statisticName: '', statisticsValue: ''},
  ]);
  const {id, language} = useParams();

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Listings),
  );

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });
  const [updateCampaign, {loading: campaignLoading}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (campaignData?.getCampaignById) {
      setStatisticValues(
        campaignData?.getCampaignById?.keyStatistics?.map(data => ({
          statisticName: data?.name,
          statisticsValue: data?.value,
        })),
      );
    }
  }, [campaignData?.getCampaignById]);

  const handleChangeStatisticsValue = (i, e) => {
    const newFormValues = [...statisticValues];
    newFormValues[i][e.target.name] = e.target.value;
    setStatisticValues(newFormValues);
  };

  const handleAddStatistics = () => {
    setStatisticValues([
      ...statisticValues,
      {statisticName: '', statisticsValue: ''},
    ]);
  };

  const handleDeleteStatistics = id => {
    const filteredFormValue = statisticValues.filter(
      (item, index) => index !== id,
    );
    setStatisticValues(filteredFormValue);
  };

  const handleUpdate = () => {
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          keyStatistics: statisticValues?.map(data => ({
            name: data?.statisticName,
            value: data?.statisticsValue,
          })),
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error('Please make sure all fields are not empty');
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Key Statistics</h1>
      </div>
      <div>
        <Form onFinish={handleUpdate} layout="vertical">
          {statisticValues.map((element, index) => (
            <Fragment key={index}>
              <FadeEffect>
                <div className={styles['question-style']}>
                  <Form.Item
                    style={{width: 320, marginRight: 20}}
                    label="Statistic Name">
                    <Input
                      value={element.statisticName || ''}
                      name="statisticName"
                      onChange={e => handleChangeStatisticsValue(index, e)}
                      placeholder="Statistics Name"
                    />
                  </Form.Item>
                  <Form.Item
                    style={{width: 320}}
                    label="Enter Statistics Associated Value">
                    <Input
                      name="statisticsValue"
                      value={element.statisticsValue || ''}
                      onChange={e => handleChangeStatisticsValue(index, e)}
                      placeholder="Statistics Value"
                    />
                  </Form.Item>
                  <img
                    src={trash}
                    onClick={() => handleDeleteStatistics(index)}
                    alt=""
                  />
                </div>
              </FadeEffect>
            </Fragment>
          ))}
        </Form>
        <div onClick={handleAddStatistics} className={styles['add-risks']}>
          <img src={plusIcon} alt="add-founder" />
          <span>Add Metrics</span>
        </div>
        <SubmitButton
          disabled={campaignLoading || !tabOperations?.update}
          label="Update"
          handleClick={handleUpdate}
        />
      </div>
    </div>
  );
};

export default UpdateCampaignStatistics;
