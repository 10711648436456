import {gql} from '@apollo/client';

export const GET_USER_INVESTOR_UPDATE = gql`
  query GetUserInvestorUpdates {
    getUserInvestorUpdates {
      ... on Error {
        message
        statusCode
      }
      ... on GetUserInvestorUpdatesType {
        investorUpdates {
          user {
            userId
            isRead
          }
          update {
            id
            title
            content
            keyTakeAway
            imageUrl
            topics
            companies
            publishedDate
            expirationDate
          }
        }
      }
    }
  }
`;

export const GET_ALL_INVESTORS_UPDATES = gql`
  query GetAllInvestorUpdates {
    getAllInvestorUpdates {
      ... on Error {
        message
        statusCode
      }
      ... on getAllInvestorUpdatesType {
        investorUpdates {
          title
          imageUrl
          keyTakeAway
          isExpired
          isPublished
          id
          updatedAt
          publishedDate
          websiteLink
        }
      }
    }
  }
`;

export const GET_ALL_TOPIC_COUNTRIES = gql`
  query GetAllTopicCountries {
    getAllTopicCountries {
      ... on Error {
        message
        statusCode
      }
      ... on TopicCountry {
        id
        name
        icon
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ALL_INVESTORS_UPDATES_QUERIES = gql`
  query GetAllInvestorsUpdatesQueries {
    getAllTopicCountries {
      ... on Error {
        message
        statusCode
      }
      ... on TopicCountry {
        id
        name
        icon
      }
    }
    getAllCompanies {
      ... on Error {
        message
        statusCode
      }
      ... on Company {
        id
        name
        logoImgURL
      }
    }
    getAllTopics {
      ... on Error {
        message
        statusCode
      }
      ... on Topic {
        id
        icon
        name
      }
    }
  }
`;

export const TRANSLATE_MULTIPLE_FIELDS = gql`
  mutation TranslateText(
    $translations: [TranslationInput]
    $treatment: TextTreatment
  ) {
    translateText(translations: $translations, treatment: $treatment) {
      ... on Error {
        message
        statusCode
      }
      ... on Translation {
        translations
      }
    }
  }
`;

export const GET_INVESTOR_UPDATE_BY_ID = gql`
  query GetInvestorUpdateById($getInvestorUpdateByIdId: ID!) {
    getInvestorUpdateById(id: $getInvestorUpdateByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on InvestorUpdateType {
        multilang {
          content
          keyTakeAway
          language
          notificationTitle
          title
          websiteLink
        }
        investorUpdate {
          content
          title
          imageUrl
          assignedUsers
          notificationTitle
          keyTakeAway
          expirationDate
          publishedDate
          language
          websiteLink
          topics {
            id
            name
            icon
          }
          companies {
            id
            name
            logoImgURL
          }
          countries {
            name
            id
            icon
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_INVESTOR_UPDATE = gql`
  mutation UpdateUserInvestorUpdate($data: UpdateUserInvestorUpdate!) {
    updateUserInvestorUpdate(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on UserInvestorUpdateType {
        investorUpdate {
          content
          title
          imageUrl
          keyTakeAway
          topics {
            name
            imageUrl
          }
          companies {
            name
            imageUrl
          }
          countries {
            name
            imageUrl
          }
          assignedUsers
          publishedDate
          expirationDate
        }
      }
    }
  }
`;

export const GET_ALL_TOPICS = gql`
  query GetAllTopics {
    getAllTopics {
      ... on Error {
        message
        statusCode
      }
      ... on Topic {
        id
        icon
        name
      }
    }
  }
`;

export const GET_ALL_TOPICS_COMPANIES = gql`
  query GetAllTopicsAndCompanies {
    getAllTopics {
      ... on Error {
        message
        statusCode
      }
      ... on Topic {
        id
        icon
        name
      }
    }
    getAllCompanies {
      ... on Error {
        message
        statusCode
      }
      ... on Company {
        id
        name
        legalName
        ticker
        stage
        isTrending
        logoImgURL
        totalCapitalRaised
      }
    }
  }
`;

export const GET_ALL_INDUSTRIES = gql`
  query GetAllIndustries {
    getAllIndustries {
      ... on Error {
        message
        statusCode
      }
      ... on Industry {
        id
        name
        icon
      }
    }
  }
`;
