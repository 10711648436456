import {DatePicker, Form, Select, Spin, Switch} from 'antd';
import moment from 'moment';
import {useState} from 'react';
import styles from './subscriptionPrice.module.scss';
import backIcon from 'images/back.png';
import {useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {GET_SUBSCRIPTION_PRODUCTS} from 'graphql/queries/plans';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {
  GRANT_USER_SUBSCRIPTION_ACCESS,
  UPDATE_USER_SUBSCRIPTION_ACCESS,
} from 'graphql/mutations/plans';
import {toast} from 'react-toastify';
import {USER_SUBSCRIPTION} from 'graphql/queries/users';

const GrantSubscriptionAccess = () => {
  const [priceId, setPriceId] = useState('');
  const [endTime, setEndTime] = useState('');
  const {id, accessType} = useParams();
  const [isActive, setIsActive] = useState(true);
  const navigate = useNavigate();

  const {data, loading} = useQuery(GET_SUBSCRIPTION_PRODUCTS);
  const [giveSubscriptionAccess, {loading: loadingAccess}] = useMutation(
    GRANT_USER_SUBSCRIPTION_ACCESS,
  );
  const [updateSubscriptionAccess, {loading: loadingUpdate}] = useMutation(
    UPDATE_USER_SUBSCRIPTION_ACCESS,
  );

  const handleSubmit = () => {
    if (accessType === 'access') {
      giveSubscriptionAccess({
        variables: {
          data: {
            userId: id,
            subscriptionPrice: priceId,
            currentPeriodEnd: new Date(endTime).toISOString(),
          },
        },
        refetchQueries: [
          {
            query: USER_SUBSCRIPTION,
            variables: {getSubscriptionByIdId: id},
          },
        ],
      })
        .then(({data: giveSubscriptionAccess}) => {
          if (giveSubscriptionAccess.__typename === 'Error') {
            toast.error(giveSubscriptionAccess.message);
          } else {
            toast.success('Access Granted Successfully');
            navigate(-1);
          }
        })
        .catch(error => toast.error(error));
    } else {
      updateSubscriptionAccess({
        variables: {
          isActive,
          data: {
            userId: id,
            subscriptionPrice: priceId,
            currentPeriodEnd: new Date(endTime).toISOString(),
          },
        },
        refetchQueries: [
          {
            query: USER_SUBSCRIPTION,
            variables: {getSubscriptionByIdId: id},
          },
        ],
      })
        .then(({data: updateSubscriptionAccess}) => {
          if (updateSubscriptionAccess.__typename === 'Error') {
            toast.error(updateSubscriptionAccess.message);
          } else {
            toast.success('Updated Successfully');
            navigate(-1);
          }
        })
        .catch(error => toast.error(error));
    }
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" role="button" />
        {accessType === 'access' ? (
          <h1>Grant User Access to a Subscription Price</h1>
        ) : (
          <h1>Update User Subscription Price</h1>
        )}
      </div>
      <div className={styles.content}>
        <Form onFinish={handleSubmit} layout="vertical">
          <Form.Item
            rules={[
              {required: true, message: 'Please select a Subscription Price'},
            ]}
            className={styles['field-width']}
            name="price"
            label="Select Subscription Price">
            <Select
              placeholder="Please select a price"
              value={priceId}
              onChange={e => setPriceId(e)}>
              {data?.getSubscriptionProducts?.subscriptionProducts?.map(
                productData =>
                  productData?.prices?.map(priceData => (
                    <Select.Option key={priceData?.id}>
                      <div className={styles['price-flex']}>
                        <span>{productData?.name}- -</span>
                        <span>{priceData?.billingCycle}</span>
                      </div>
                    </Select.Option>
                  )),
              )}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles['field-width']}
            name="date"
            label="End Time"
            rules={[{required: true, message: 'Please select End Time'}]}>
            <DatePicker
              name="Date"
              format={'YYYY-MM-DD HH:mm:ss'}
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
              value={endTime}
              onChange={e =>
                setEndTime(moment(e).format('YYYY-MM-DD HH:mm:ss'))
              }
            />
          </Form.Item>
          {accessType === 'update' && (
            <Form.Item
              className={styles['field-width']}
              name="active"
              label="Active">
              <Switch
                defaultChecked={isActive}
                value={isActive}
                onChange={e => setIsActive(e)}
              />
            </Form.Item>
          )}
          <SubmitButton
            disabled={loadingAccess || loadingUpdate}
            label={accessType === 'access' ? 'Grant Access' : 'Update Access'}
          />
        </Form>
      </div>
    </div>
  );
};

export default GrantSubscriptionAccess;
