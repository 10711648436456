import {Route, Routes} from 'react-router-dom';
import KeyStatistics from './keyStat';
import {NewKeyStat} from './newKeyStat';

const AdminKeyStat = () => {
  return (
    <Routes>
      <Route path="/" element={<KeyStatistics />} />
      <Route path="/new-key-stat" element={<NewKeyStat />} />
      <Route path="/update-key-stat/:id" element={<NewKeyStat />} />
    </Routes>
  );
};

export {AdminKeyStat};
