import {Tabs, Input} from 'antd';
import deleteImg from 'images/delete.svg';
import styles from './tldrEdit.module.scss';
import {Button} from '@dabafinance/react-components';
import {useEffect, useState} from 'react';
import {GENERATE_NEWS_SUMMARY} from 'graphql/queries/news';
import {client} from 'index';
import {removeHtmlTags} from 'utils/helper';

export const TLDREdit = ({summary, onChangeSummaries, content = ''}) => {
  const [loading, setLoading] = useState(false);
  const [cleanedContent, setCleanedContent] = useState(content);

  useEffect(() => {
    setCleanedContent(removeHtmlTags(content));
  }, [content]);

  const handleDeleteTLDR = index => {
    onChangeSummaries(summary.filter((_, i) => i !== index));
  };

  const handleGenerateSummary = async () => {
    setLoading(true);
    const {data} = await client.mutate({
      mutation: GENERATE_NEWS_SUMMARY,
      variables: {
        data: {
          content: cleanedContent,
        },
      },
      fetchPolicy: 'network-only',
    });

    const cleanedSummaries = data?.summarizeContent?.summary?.map(item =>
      item?.replace('- ', ''),
    );
    onChangeSummaries(cleanedSummaries);
    setLoading(false);
  };

  const handleSummaryChange = (index, value) => {
    const newSummaries = [...summary];
    newSummaries[index] = value;
    onChangeSummaries(newSummaries);
  };

  return (
    <div>
      <Tabs
        className={styles.tabs}
        tabBarExtraContent={
          <div className={styles.buttonsWrapper}>
            <Button
              disabled={loading}
              label="Add"
              onClick={() => onChangeSummaries([...summary, ''])}
            />
            <Button
              disabled={loading || !cleanedContent}
              label={loading ? 'Generating...' : 'Generate'}
              onClick={() => handleGenerateSummary()}
            />
          </div>
        }>
        <Tabs.TabPane tab="TLDR section" key="tldr">
          {summary?.map((tldr, index) => (
            <div key={index} className={styles.inputWrapper}>
              <label>
                <span className={styles.danger}>*</span>{' '}
                {`TLDR Section ${index + 1}`}
              </label>
              <div className={styles.inputInnerWrap}>
                <Input
                  placeholder="African’s Cocoa beans prices have increased by 18% per pound"
                  value={tldr}
                  onChange={e => handleSummaryChange(index, e.target.value)}
                />

                <img
                  src={deleteImg}
                  alt="delete"
                  onClick={() => handleDeleteTLDR(index)}
                />
              </div>
            </div>
          ))}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
