import AccessDeniedImage from 'images/access-denied.svg';
import issueImage from 'images/something_wrong.png';
import {useNavigate} from 'react-router-dom';
import styles from './somethingWentWrong.module.scss';
import {useSelector} from 'react-redux';

const SomethingWentWrong = ({message, title}) => {
  const route = useSelector(state => state?.auth?.loginRoute);
  const navigate = useNavigate();
  return (
    <div>
      <div className={styles['container']}>
        <div className={styles['not-found']}>
          <div className={styles['not-found-image']}>
            <img src={title ? AccessDeniedImage : issueImage} alt="" />
          </div>
          <h1>{title || 'Oops something went wrong'}</h1>
          <div className={styles['text']}>
            <span>
              {message ||
                'We are experiencing some connection issues. We are working to fix it, click the refresh button to reload page or go home.'}
            </span>
          </div>
        </div>
        <div className={styles['button-container']}>
          <div
            onClick={() => navigate(route || '/dashboard')}
            className={styles['home']}>
            Take Me Home
          </div>
          <div
            onClick={() => (title ? navigate(-1) : window.location.reload())}
            className={styles['go-back']}>
            {title ? 'Go Back' : 'Refresh'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SomethingWentWrong;
