import {useState} from 'react';
import styles from './trendingIndustries.module.scss';
import {Button} from '@dabafinance/react-components';
import {Table, Spin, Form, Select} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import {useQuery, useMutation} from '@apollo/client';
import pageNotFound from 'images/pageNotFound.png';
import {toast} from 'react-toastify';
import DBModal from 'components/modal/modal';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {GET_ALL_COMPANY} from 'graphql/queries/report';
import {GET_COMPANY_BY_INDUSTRY_ID} from 'graphql/queries/trendingIndustries';
import {ADD_COMPANY_TO_TRENDING_INDUSTRIES} from 'graphql/mutations/trendingIndustries';
import backIcon from 'images/back.png';

const EditCompany = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {data: getAllCompanies} = useQuery(GET_ALL_COMPANY);
  const [trendingId, setTrendingId] = useState('');
  const [trendingIndustries, setTrendingIndustries] = useState();
  const [company, setCompany] = useState('');
  const [trendingDetails, setTrendingDetails] = useState();
  const navigate = useNavigate();

  const {id} = useParams();

  const {loading} = useQuery(GET_COMPANY_BY_INDUSTRY_ID, {
    variables: {
      industryId: id,
    },
    onCompleted: data => (
      setTrendingId(data?.getCompaniesByIndustryId?.id),
      setTrendingDetails(data?.getCompaniesByIndustryId),
      setTrendingIndustries(
        data?.getCompaniesByIndustryId?.trendingIndustries.filter(
          item => item.id === id,
        ),
      )
    ),
    onError: error => toast.error(error),
  });
  const [addCompanyToTrendingIndustry] = useMutation(
    ADD_COMPANY_TO_TRENDING_INDUSTRIES,
  );

  const {Option} = Select;
  const handleShow = () => {
    setIsOpen(true);
  };

  const industryName = trendingIndustries?.map(item => item?.industry?.name);
  const month = trendingDetails?.month;
  const year = trendingDetails?.year;

  const companies = trendingIndustries?.map(({companies}) => companies);

  const handleAddition = () => {
    const newArr = [];
    for (let i = 0; i < companies[0].length; i++) {
      let newObj = '';
      newObj = companies[0][i]?.id;
      newArr.push(newObj);
    }
    newArr.push(company.split(';')[1]);
    handleUpdate(newArr);
  };

  const handleUpdate = newArr => {
    addCompanyToTrendingIndustry({
      variables: {
        trendingIndustryId: trendingId,
        industryId: id,
        companies: newArr,
      },
      refetchQueries: [
        {query: GET_COMPANY_BY_INDUSTRY_ID, variables: {industryId: id}},
      ],
    })
      .then(({data: {addCompanyToTrendingIndustry}}) => {
        if (addCompanyToTrendingIndustry.__typename === 'Error') {
          toast.error(addCompanyToTrendingIndustry.message);
        } else {
          toast.success('Company Added Successfully');
        }
        setIsOpen(false);
      })
      .catch(error => {
        toast.error(error);
      });
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.arrow}>
          <img onClick={() => navigate(-1)} src={backIcon} alt="" />
          <h2>
            {industryName} Startup Deals ({month}, {year})
          </h2>
        </div>
        <div className={styles.actions}>
          <Button
            className={styles['add-button']}
            onClick={handleShow}
            label="Add Company"
          />

          <DBModal
            isOpen={isOpen}
            content={
              <div>
                <div className={styles['modal-title']}>
                  <span>Add Company</span>
                </div>
                <Form layout="vertical">
                  <Form.Item
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                    label="Select Company"
                    className={styles.field}>
                    <Select
                      showSearch
                      value={company}
                      onChange={e => setCompany(e)}>
                      {getAllCompanies?.getAllCompanies.map(
                        ({name, logoImgURL, id}) => (
                          <Option key={`${name};${id}`}>
                            <div className={styles['all-data']}>
                              <img src={logoImgURL} alt="" />
                              <span>{name}</span>
                            </div>
                          </Option>
                        ),
                      )}
                    </Select>
                  </Form.Item>
                  <SubmitButton
                    type="primary"
                    handleClick={handleAddition}
                    label="Add"
                  />
                </Form>
              </div>
            }
            handleClose={() => setIsOpen(false)}></DBModal>
        </div>
      </div>
      <RenderTable companies={companies} />
    </div>
  );
};

export default EditCompany;

const RenderTable = companies => {
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Company',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <img src={record?.logoImgURL} alt="" />
          <span>{record?.name}</span>
        </div>
      ),
    },
    {
      title: 'Ranking',
      dataIndex: 'sequence',
      key: 'sequence',
      render: (text, record, index) => (
        <div className={styles['record-label']}>
          <span>{index + 1}</span>
        </div>
      ),
    },

    {
      title: 'Investment Received',
      dataIndex: 'fundingAmount',
      key: 'fundingAmount',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>
            $
            {record?.fundraisingHistory?.fundingHistory[
              record?.fundraisingHistory?.fundingHistory?.length - 1
            ]?.fundingAmount || 0}
          </span>
        </div>
      ),
    },
    {
      title: 'Investment Stage',
      dataIndex: 'round',
      key: 'round',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>
            {
              record?.fundraisingHistory?.fundingHistory[
                record?.fundraisingHistory?.fundingHistory?.length - 1
              ]?.round
            }
          </span>
        </div>
      ),
    },
    {
      title: 'Status of daba',
      dataIndex: 'isRaising',
      key: 'isRaising',
      render: (text, record) => (
        <div className={styles['record-label']} style={{width: 100}}>
          {record?.isRasing ? <span>Raising</span> : <span>Not Raising</span>}
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <Button
          type="secondary"
          label="View details"
          className={styles.button}
          onClick={() =>
            navigate(`/dashboard/opportunities/startups/profile/${record.id}`)
          }
        />
      ),
    },
  ];

  return (
    <div>
      {companies?.companies?.length > 0 ? (
        <Table
          dataSource={companies?.companies[0]?.sort(
            (a, b) => b?.totalCapitalRaised - a?.totalCapitalRaised,
          )}
          columns={columns}
        />
      ) : (
        <div className={styles['no-update']}>
          <img src={pageNotFound} alt="" />
          <h1>No Companies</h1>
          <span>Add a new Company</span>
        </div>
      )}
    </div>
  );
};
