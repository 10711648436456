import {Route, Routes} from 'react-router-dom';
import CompanyDetails from './companyDetails';

// Tab Routes
import Report from './tabs/report';
import AnalystsMemo from './tabs/analystsMemo';

export default function CompanyReportRoutes() {
  return (
    <Routes>
      <Route path="/" element={<CompanyDetails />}>
        <Route path="/" element={<Report />}></Route>
        <Route path="/analysts-memos" element={<AnalystsMemo />} />
      </Route>
    </Routes>
  );
}
