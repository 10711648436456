import {
  DatePicker,
  Form,
  Input,
  Select,
  Spin,
  Switch,
  Tabs,
  Tooltip,
} from 'antd';
import styles from './createDocumentCenter.module.scss';
import backIcon from 'images/back.png';
import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {UploadDocumentToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import FadeEffect from 'components/animation/fadeEffect';
import {Button} from '@dabafinance/react-components';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import {toast} from 'react-toastify';
import {useMutation, useQuery} from '@apollo/client';
import moment from 'moment';
import {
  CREATE_COMPANY_DOCUMENT_CENTER,
  UPDATE_COMPANY_DOCUMENT_CENTER,
} from 'graphql/mutations/documentCenter';
import {defaultLanguages} from 'pages/news/newsTypes';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {requestCompanyDocumentTranslation} from 'redux/store/news/actions';
import {formatRoleString} from 'utils/helper';
import CompanyPDFUpload from './CompanyPDFUpload';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {
  GET_ALL_COMPANY_DOCUMENT_CATEGORIES,
  GET_COMPANY_DOCUMENTS,
  GET_SINGLE_USER_DOCUMENT,
} from 'graphql/queries/companyDocument';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {GET_ALL_STARTUPS} from 'graphql/queries/startupCompanies';

const CreateDocumentCenter = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [issueDate, setIssueDate] = useState('');
  const [pdfFile, setPdfFile] = useState('');
  const [loadingPdfUpload, setLoadingPdfUpload] = useState(false);
  const [baseLanguage, setBaseLanguage] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [pdfLink, setPdfLink] = useState('');
  const [viewPdf, setViewPdf] = useState(null);
  const [pdfName, setPdfName] = useState('Supported format: pdf');
  const [translatedData, setTranslatedData] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const {id, documentId} = useParams();
  const [form] = Form.useForm();

  const {data, loading} = useQuery(GET_SINGLE_USER_DOCUMENT, {
    variables: {
      documentId,
    },
    fetchPolicy: 'network-only',
  });
  const {loading: catLoading, data: catData} = useQuery(
    GET_ALL_COMPANY_DOCUMENT_CATEGORIES,
  );

  const {data: getAllCompanies, loading: loadingCompanies} =
    useQuery(GET_ALL_STARTUPS);

  useEffect(() => {
    if (documentId && data?.getOneCompanyDocument?.data) {
      setPdfFile(data?.getOneCompanyDocument?.data?.documentUrl);
      setPdfLink(data?.getOneCompanyDocument?.data?.documentUrl);
      setDocumentName(data?.getOneCompanyDocument?.data?.documentName);
      setPdfName(data?.getOneCompanyDocument?.data?.documentName);
      setBaseLanguage(data?.getOneCompanyDocument?.data?.language);
      setTranslatedData(
        data?.getOneCompanyDocument?.data?.multilang?.map(data => ({
          documentName: data?.documentName,
          pdfFile: data?.documentUrl,
          pdfLink: data?.documentUrl,
          language: data?.language,
          pdfName: data?.documentName,
          viewPdf: data?.viewPdf,
        })),
      );
      form.setFieldsValue({
        documentName: data?.getOneCompanyDocument?.data?.documentName,
        issuedDate: moment(
          data?.getOneCompanyDocument?.data?.issuedDate,
          'YYYY-MM-DD HH:mm:ss',
        ),
        languageRestriction:
          data?.getOneCompanyDocument?.data?.restrictLanguage,
        baseLanguage: data?.getOneCompanyDocument?.data?.language,
        documentCategory:
          data?.getOneCompanyDocument?.data?.documentCategory?.id,
      });
    }
  }, [data?.getOneCompanyDocument?.data]);

  const [addCompanyDocument, {loading: loadingCreate}] = useMutation(
    CREATE_COMPANY_DOCUMENT_CENTER,
  );
  const [updateCompanyDocument, {loading: loadingUpdate}] = useMutation(
    UPDATE_COMPANY_DOCUMENT_CENTER,
  );

  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );

  const navigate = useNavigate();

  const languageToBeTranslatedTo = defaultLanguages.filter(
    key => key.key !== baseLanguage,
  );

  const handleDeleteFile = () => {
    setPdfFile('');
    setPdfLink('');
    setPdfName('Supported format - pdf');
    setViewPdf('');
  };

  const handlePdfFileSave = e => {
    e.preventDefault();
    setViewPdf(pdfFile);
  };

  const handleChangeTranslation = (key, event) => {
    let eventName = event.target.name;
    let eventValue = event.target.value;
    const newFormValues = [...translatedData];
    newFormValues[key][eventName] = eventValue;
    setTranslatedData(newFormValues);
  };

  const handlePdfFileChange = async e => {
    const selectedFile = e.target.files[0];
    setPdfName(selectedFile.name);
    if (selectedFile) {
      if (selectedFile && selectedFile.type === 'application/pdf') {
        setLoadingPdfUpload(true);
        const imageUrl = await UploadDocumentToBucket(selectedFile);
        setPdfLink(imageUrl);
        setPdfFile(imageUrl);
        setLoadingPdfUpload(false);
      } else {
        setPdfFile(null);
        toast.error('Please select a valid pdf file');
      }
    } else {
      toast.error('Select a PDF file');
    }
  };

  const handleTranslation = async () => {
    const newValues = [...translatedData];

    if (!baseLanguage && !documentName) {
      return toast.error(
        'Please make sure none of the necessary fields needed for translation are empty',
      );
    }

    const translateProps = {
      sourceLang: baseLanguage,
      targetLang: selectedLanguage,
      targetText1: documentName,
    };
    if (newValues.length && newValues[0]) {
      const languageExists = newValues.some(
        data => data?.language === selectedLanguage,
      );
      if (languageExists) {
        const updateTranslation = await requestCompanyDocumentTranslation(
          translateText,
          translateProps,
        );
        const translationIndex = newValues.findIndex(
          data => data.language === selectedLanguage,
        );
        newValues[translationIndex].documentName =
          updateTranslation.documentName;
        newValues[translationIndex].language = updateTranslation.language;
        setTranslatedData(newValues);
      }
    } else {
      const triggerNewTranslation = await requestCompanyDocumentTranslation(
        translateText,
        translateProps,
      );
      if (!triggerNewTranslation) {
        return toast.error('Something went wrong when Translating');
      }
      newValues.push(triggerNewTranslation);
      setTranslatedData(newValues);
    }
  };

  const handleDeleteTranslatedPDFFile = index => {
    const newFormValues = [...translatedData];
    newFormValues[index].pdfLink = null;
    newFormValues[index].pdfFile = null;
    newFormValues[index].viewPdf = null;
    newFormValues[index].pdfName = 'Supported format - pdf';
  };

  const handleSubmit = values => {
    if (
      translatedData.length &&
      translatedData.some(
        data => !data || !data.documentName || !data.pdfFile || !data.language,
      )
    ) {
      return toast.error(
        'Translated data must have documentName, pdfFile, and language',
      );
    }
    if (!pdfLink) return toast.error('PDF file must be uploaded');
    if (documentId) {
      updateCompanyDocument({
        variables: {
          data: {
            company: id,
            documentId,
            documentUrlSource: 'UPLOADED',
            language: baseLanguage,
            documentCategory: values?.documentCategory,
            restrictLanguage:
              values?.languageRestriction != null
                ? values?.languageRestriction
                : false,
            documentName: values?.documentName,
            documentUrl: pdfLink,
            issuedDate:
              data?.getOneCompanyDocument?.data?.issuedDate ??
              moment(issueDate).format(),
            multilang: translatedData.length
              ? translatedData?.map(data => ({
                  documentName: data?.documentName,
                  documentUrl: data?.pdfFile,
                  documentUrlSource: 'UPLOADED',
                  language: data?.language,
                }))
              : [],
          },
        },
        refetchQueries: [
          {query: GET_COMPANY_DOCUMENTS, variables: {companyId: id}},
        ],
      })
        .then(({data: {updateCompanyDocument}}) => {
          if (updateCompanyDocument.__typename === 'Error') {
            return toast.error(updateCompanyDocument.message);
          } else {
            toast.success('Updated Successfully');
            navigate(
              `/dashboard/opportunities/startups/profile/${id}?route=document`,
            );
          }
        })
        .catch(error => toast.error(error));
    } else {
      addCompanyDocument({
        variables: {
          data: {
            company: id === 'none' ? values?.companyId?.split('~')[2] : id,
            language: baseLanguage,
            documentCategory: values?.documentCategory,
            restrictLanguage:
              values?.languageRestriction != null
                ? values?.languageRestriction
                : false,
            documentName: values?.documentName,
            documentUrlSource: 'UPLOADED',
            documentUrl: pdfLink,
            issuedDate: moment(issueDate).format(),
            multilang: translatedData?.length
              ? translatedData?.map(data => ({
                  documentName: data?.documentName,
                  documentUrl: data?.pdfFile,
                  documentUrlSource: 'UPLOADED',
                  language: data?.language,
                }))
              : [],
          },
        },
        refetchQueries: [{query: GET_COMPANY_DOCUMENTS}],
      })
        .then(({data: {addCompanyDocument}}) => {
          if (addCompanyDocument.__typename === 'Error') {
            return toast.error(addCompanyDocument.message);
          } else {
            toast.success('Created Successfully');
            if (id === 'none') {
              navigate(-1);
            } else {
              navigate(
                `/dashboard/opportunities/startups/profile/${id}?route=document`,
              );
            }
          }
        })
        .catch(error => toast.error(error));
    }
  };

  if (loading && documentId) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading Document...</h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.nav}>
        <img onClick={() => navigate(-1)} src={backIcon} />
        <h1>{documentId ? 'Update' : 'Create'} Company Document</h1>
      </div>
      <div className={styles.form}>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          {id === 'none' && (
            <Form.Item
              className={styles.field}
              name="companyId"
              rules={[{required: true, message: 'This is a required field'}]}
              label="Select Company">
              <Select
                listHeight={400}
                loading={loadingCompanies}
                allowClear
                showSearch
                className={styles['select-height']}>
                {loadingCompanies ? (
                  <Select.Option key="Loading">Loading..</Select.Option>
                ) : (
                  getAllCompanies?.getAllCompanies?.map(company => (
                    <Select.Option
                      key={`${company?.name}~${company?.ticker}~${company?.id}`}>
                      <div className={styles['all-data']}>
                        <img src={company?.logoImgURL} alt="" />
                        <span>{company?.name}</span>
                      </div>
                    </Select.Option>
                  ))
                )}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            className={styles.field}
            name="baseLanguage"
            rules={[{required: true, message: 'This is a required field'}]}
            label="Base Language">
            <Select value={baseLanguage} onChange={setBaseLanguage}>
              {defaultLanguages.map(data => (
                <Select.Option key={data.key}>{data.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.field}
            name="documentCategory"
            label="Document Category">
            <Select loading={catLoading}>
              {catData?.getCompanyDocumentCategories?.data?.map(data => (
                <Select.Option key={data?.id}>
                  {formatRoleString(data?.name)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className={styles.flexWidth}>
            <Form.Item
              rules={[{required: true, message: 'This Field is Required'}]}
              name="issuedDate"
              label="Issued Date">
              <DatePicker
                name="issuedDate"
                value={issueDate}
                className={styles.date}
                format={'YYYY-MM-DD HH:mm:ss'}
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm:ss'),
                }}
                onChange={e =>
                  setIssueDate(moment(e).format('YYYY-MM-DD HH:mm:ss'))
                }
              />
            </Form.Item>
            <Form.Item
              name="languageRestriction"
              label={
                <span>
                  Language Restriction{' '}
                  <Tooltip
                    title={
                      'This Restricts Users from viewing Document that is not in their Language Preference'
                    }>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }>
              <Switch
                defaultChecked={
                  data?.getOneCompanyDocument?.data?.restrictLanguage
                }
              />
            </Form.Item>
          </div>
          <Form.Item
            label="Translation Language"
            name="translationLanguage"
            className={styles.field}>
            <Select
              value={selectedLanguage}
              className={styles.field}
              onChange={e => setSelectedLanguage(e)}
              placeholder="Select a Language to be translated">
              {languageToBeTranslatedTo.map(name => (
                <Select.Option key={name.key}>{name.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Tabs
            style={{width: 800}}
            tabBarExtraContent={
              selectedLanguage && (
                <Button
                  label={loadingTranslation ? 'Translating...' : 'Translate'}
                  disabled={loadingTranslation || !documentName}
                  onClick={handleTranslation}
                />
              )
            }>
            <Tabs.TabPane className="" key="Original" tab="Original">
              <Form.Item
                rules={[{required: true, message: 'This is a required field'}]}
                name="documentName"
                label="Document Name">
                <Input
                  className={styles.field}
                  name={documentName}
                  onChange={e => setDocumentName(e.target.value)}
                  placeholder="Input the Document Name"
                />
              </Form.Item>
              <h1 className={styles.documentHeader}>Upload Company Document</h1>
              {!viewPdf && (
                <form>
                  <label className={styles.upload} htmlFor="file-upload">
                    {loadingPdfUpload ? (
                      <div className={styles.spinner}>
                        <Spin size="large" />
                        <h3>Uploading...</h3>
                      </div>
                    ) : (
                      <span> {pdfName}</span>
                    )}
                    <input
                      type="file"
                      id="file-upload"
                      accept="application/pdf"
                      className={styles['input-file']}
                      required
                      onChange={handlePdfFileChange}
                    />
                  </label>
                </form>
              )}
              {viewPdf && (
                <FadeEffect>
                  <div className={styles['pdf-height']}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                      <Viewer
                        fileUrl={viewPdf}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </Worker>
                  </div>
                </FadeEffect>
              )}
              <div className={styles['button-wrapper']}>
                <Button
                  disabled={pdfFile === ''}
                  label="Preview PDF"
                  onClick={handlePdfFileSave}
                />
                <Button
                  type="secondary"
                  label="Delete"
                  onClick={handleDeleteFile}
                />
              </div>
            </Tabs.TabPane>
            {translatedData?.map((item, index) => (
              <Tabs.TabPane
                key={index}
                tab={
                  languageToBeTranslatedTo.find(
                    lang => lang?.key === item?.language,
                  )?.label
                }>
                <Form.Item label="Translated Document Name">
                  <Input
                    value={item?.documentName}
                    className={styles.field}
                    onChange={e => handleChangeTranslation(index, e)}
                  />
                </Form.Item>
                <CompanyPDFUpload
                  title="Upload Translated Company Document"
                  setExistingData={setTranslatedData}
                  pdfName={item?.pdfName || 'Supported format: pdf'}
                  existingData={translatedData}
                  loadingPdfUpload={item?.loadingPdfUpload}
                  index={index}
                  pdfFile={item?.pdfFile}
                  handleDeleteFile={() => handleDeleteTranslatedPDFFile(index)}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
          <SubmitButton
            label={documentId ? 'Update' : 'Create'}
            disabled={loadingCreate || loadingUpdate}
          />
        </Form>
      </div>
    </div>
  );
};

export default CreateDocumentCenter;
