import {Route, Routes} from 'react-router-dom';

// import pages
import AddNewInterest from './addNewInterest';
import SingleInterest from './singleInterest';

// tab pages
import InterestDetails from './singleInterest/interestDetails';
import UsersInterested from './singleInterest/usersInterested';
import InterestsTable from '../InterestsTable';
import {AdminInterests} from '..';
import TopicsTable from '../topicsTable';
import ListTable from '../ListTable';
import CountriesTable from '../CountriesTable';
import TopicList from '../topicList/TopicList';
import UpdateTopicList from '../topicList/UpdateTopicList';

export default function InterestRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AdminInterests />}>
        <Route index path="/" element={<InterestsTable />} />
        <Route path="/topics" element={<TopicsTable />} />
        <Route path="/list" element={<ListTable />} />
        <Route path="/countries" element={<CountriesTable />} />
        <Route />
      </Route>
      <Route path="/:id" element={<SingleInterest />}>
        <Route path="/:id" index element={<InterestDetails />} />
        <Route path="users-interested" element={<UsersInterested />} />
      </Route>
      <Route path="/update-topic/:id" element={<UpdateTopicList />} />
      <Route path="/add-new" element={<AddNewInterest />} />
      <Route path="/add-topic-list" element={<TopicList />} />
    </Routes>
  );
}
