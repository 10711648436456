import {useEffect, useState} from 'react';
import styles from './newDisclosure.module.scss';
import {ArrowLeft} from 'icons';
import {Form, Input, Spin, Select, Tabs} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {toast} from 'react-toastify';
import {useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {GET_EMAIL_DISCLOSURE} from 'graphql/queries/emailDisclosure';
import {
  CREATE_EMAIL_DISCLOSURE,
  UPDATE_EMAIL_DISCLOSURE,
} from 'graphql/mutations/emailDisclosure';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {onTranslateContent} from './actions';
import {
  QUILL_FORMATS,
  QUILL_MODULES,
  supportedLanguages,
} from 'utils/constants';
import {fieldRules} from 'utils/helper';
import {Button} from '@dabafinance/react-components';
import {disclosureTypeEnum} from 'utils/mock';
import ReactQuill from 'react-quill';

const NewEmailDisclosure = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [type, setType] = useState('');
  const [enableChangeTranslation, setEnableChangeTranslation] = useState(true);
  const [language, setLanguage] = useState('');
  const [selectedTranslationLanguage, setSelectedTranslationLanguage] =
    useState('');
  const [multilang, setMultilang] = useState([]);
  const navigate = useNavigate();
  const {id} = useParams();
  const isUpdatePage = !!id;

  const {data, loading} = useQuery(GET_EMAIL_DISCLOSURE, {
    variables: {
      disclosureId: id,
    },
  });
  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );

  const languageToBeTranslatedTo = supportedLanguages.filter(
    key => key.value !== language,
  );

  const handleTranslation = async () => {
    const translateProps = {
      sourceLang: language,
      targetLang: selectedTranslationLanguage,
      targetText1: title,
      targetText2: content,
    };
    try {
      const newValues = [...multilang];

      //Check if translation exists
      if (newValues.length && newValues[0]) {
        // Check if we're trying to translate in the same language
        const languageExists = newValues.some(
          data => data.language === selectedTranslationLanguage,
        );
        if (languageExists) {
          const updateTranslation = await onTranslateContent(
            translateText,
            translateProps,
          );
          const translationIndex = newValues.findIndex(
            data => data.language === selectedTranslationLanguage,
          );
          newValues[translationIndex].title = updateTranslation.title;
          newValues[translationIndex].content = updateTranslation.content;
          newValues[translationIndex].language = updateTranslation.language;
          setMultilang(newValues);
        }
      } else {
        const triggerTranslation = await onTranslateContent(
          translateText,
          translateProps,
        );
        if (!triggerTranslation) {
          return toast.error('Something went wrong when Translating');
        }
        newValues.push(triggerTranslation);
        setMultilang(newValues);
        setMultilang(newValues);
      }
    } catch (error) {
      toast.error(`Something went wrong! Error: ${error}`);
    }
  };

  const {Option} = Select;

  useEffect(() => {
    if (data?.getEmailDisclosure) {
      setTitle(data?.getEmailDisclosure?.title);
      setContent(data?.getEmailDisclosure?.content);
      setLanguage(data?.getEmailDisclosure?.language);
      setType(data?.getEmailDisclosure?.type);
      setMultilang(data?.getEmailDisclosure?.multilang);
      setSelectedTranslationLanguage(
        data?.getEmailDisclosure?.multilang[0]?.language,
      );
    }
  }, [data?.getEmailDisclosure]);

  const handleChangeTranslatedValues = (key, event, name) => {
    let eventName = name;
    let eventValue = name ? event : event.target.value;
    const newFormValues = multilang.map((item, index) => {
      if (index === key) {
        return {...item, [eventName]: eventValue};
      }
      return item;
    });
    newFormValues[key][eventName] = eventValue;
    setMultilang(newFormValues);
  };

  const [updateEmailDisclosure, {loading: disclosureUpdate}] = useMutation(
    UPDATE_EMAIL_DISCLOSURE,
  );
  const [createEmailDisclosure, {loading: disclosureCreate}] = useMutation(
    CREATE_EMAIL_DISCLOSURE,
  );
  const width = 720;

  const handleSave = () => {
    isUpdatePage
      ? updateEmailDisclosure({
          variables: {
            data: {
              disclosureId: id,
              title,
              content,
              language,
              type,
              multilang: multilang.map(data => {
                return {
                  title: data?.title,
                  content: data?.content,
                  language: data?.language,
                };
              }),
            },
          },
        }).then(({data: {updateEmailDisclosure}}) => {
          if (updateEmailDisclosure.__typename === 'Error') {
            toast.error(updateEmailDisclosure.message);
          } else {
            toast.success('Email Disclosure updated successfully');
            navigate('/dashboard/app-version-setting');
          }
        })
      : createEmailDisclosure({
          variables: {
            data: {
              title,
              content,
              language,
              type,
              multilang,
            },
          },
        }).then(({data: {createEmailDisclosure}}) => {
          if (createEmailDisclosure.__typename === 'Error') {
            toast.error(createEmailDisclosure.message);
          } else {
            toast.success('Email disclosure created successfully');
            navigate('/dashboard/app-version-setting');
          }
        });
  };

  if (isUpdatePage && loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button onClick={() => navigate(-1)}>
          <ArrowLeft />
        </button>
        <h1>
          {isUpdatePage ? 'Update Email Disclosure' : 'Create Email Disclosure'}{' '}
        </h1>
      </div>
      <div className={styles['inner-container']}>
        <Form onFinish={handleSave} layout="vertical">
          <Form.Item
            rules={[fieldRules('Language')]}
            label="Language"
            className={styles.field}
            name="language"
            initialValue={data?.getEmailDisclosure?.language}>
            <Select
              style={{width}}
              value={language}
              defaultValue={data?.getEmailDisclosure?.language}
              onChange={e => setLanguage(e)}>
              {supportedLanguages.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[fieldRules('You must select a language to be translated')]}
            label="Language Translation"
            className={styles.field}
            name="translation"
            initialValue={data?.getEmailDisclosure?.multilang[0]?.language}>
            <Select
              style={{width}}
              defaultValue={data?.getEmailDisclosure?.multilang[0]?.language}
              value={selectedTranslationLanguage}
              onChange={e => setSelectedTranslationLanguage(e)}>
              {languageToBeTranslatedTo.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Tabs
            className={styles.tabs}
            tabBarExtraContent={
              selectedTranslationLanguage && (
                <Button
                  disabled={!title || !content || loadingTranslation}
                  label="Translate"
                  onClick={handleTranslation}
                />
              )
            }>
            <Tabs.TabPane tab="Original" key="Original">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Title is required',
                  },
                ]}
                style={{width}}
                label="Title (This will not be included in the email)"
                className={styles['sub-heading']}
                name="title"
                initialValue={data?.getEmailDisclosure?.title}>
                <Input
                  defaultValue={data?.getEmailDisclosure?.title}
                  style={{width}}
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  placeholder={'Title'}
                />
              </Form.Item>
              <div className={styles['quill-container']}>
                <span>Disclosure Content</span>
                <ReactQuill
                  value={content}
                  modules={NewEmailDisclosure.modules}
                  formats={NewEmailDisclosure.format}
                  defaultValue={data?.getEmailDisclosure?.content}
                  onChange={e => setContent(e)}
                  placeholder="Content"
                />
              </div>
            </Tabs.TabPane>
            {multilang.length &&
              multilang.map((data, index) => (
                <Tabs.TabPane
                  key={data.language}
                  tab={
                    supportedLanguages.find(
                      lang => lang.value === data.language,
                    ).label
                  }>
                  <div className={styles.updateButton}>
                    <Button
                      onClick={() =>
                        setEnableChangeTranslation(!enableChangeTranslation)
                      }
                      type="secondary"
                      label={
                        enableChangeTranslation
                          ? 'Edit Translation'
                          : 'Disable Translation'
                      }
                    />
                  </div>
                  <div key={index}>
                    <Form.Item label="Title" className={styles.field}>
                      <Input
                        style={{width}}
                        value={data.title}
                        disabled={enableChangeTranslation}
                        onChange={e =>
                          handleChangeTranslatedValues(
                            index,
                            e.target.value,
                            'title',
                          )
                        }
                        placeholder={'Title'}
                      />
                    </Form.Item>
                    <div className={styles['quill-container']}>
                      <span className={styles['quill-header']}>
                        Disclosure Content
                      </span>
                      <ReactQuill
                        value={data.content}
                        modules={NewEmailDisclosure.modules}
                        formats={NewEmailDisclosure.format}
                        onChange={e =>
                          handleChangeTranslatedValues(index, e, 'content')
                        }
                        readOnly={enableChangeTranslation}
                        placeholder="Content"
                      />
                    </div>
                  </div>
                </Tabs.TabPane>
              ))}
          </Tabs>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Type is required',
              },
            ]}
            label="Type"
            style={{width}}
            className={styles['sub-heading']}
            name="type"
            initialValue={data?.getEmailDisclosure?.type}>
            <Select style={{width}} value={type} onChange={e => setType(e)}>
              {disclosureTypeEnum.map(item => (
                <Option key={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <SubmitButton
            disabled={disclosureUpdate || disclosureCreate || !content}
            label={
              isUpdatePage
                ? 'Update Email Disclosure'
                : 'Create Email Disclosure'
            }
          />
        </Form>
      </div>
    </div>
  );
};

NewEmailDisclosure.modules = QUILL_MODULES;

NewEmailDisclosure.format = QUILL_FORMATS;

export {NewEmailDisclosure};
