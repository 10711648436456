import {Form, Input, InputNumber, Select, Spin} from 'antd';
import {Fragment, useEffect, useState} from 'react';
import trash from 'images/trash-can-outline.svg';
import plusIcon from 'images/plus-circle.png';
import styles from './currencySettings.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useParams} from 'react-router-dom';
import FadeEffect from 'components/animation/fadeEffect';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {useMutation, useQuery} from '@apollo/client';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';
import {currencyData, currencyMatch} from 'pages/campaigns/campaignData';

const UpdateCurrencySettings = () => {
  const [baseCurrency, setBaseCurrency] = useState({
    name: 'United States Dollar',
    symbol: '$',
    currencyCode: 'USD',
  });
  const initialCurrencySate = {
    currency: {
      name: 'United States Dollar',
      symbol: '$',
      currencyCode: 'USD',
    },
    currencyConversionMode: 'FIXED',
    fixedFxRateToAssetCurrency: 0.0,
    variableFxRateToAssetCurrency: 0.0,
  };
  const [currencySettings, setCurrencySettings] = useState([
    initialCurrencySate,
  ]);
  const {id, language} = useParams();
  const {Option} = Select;

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });
  const [updateCampaign, {loading: campaignLoading}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (campaignData?.getCampaignById.fxTransactionRatesOnSupportedCurrencies) {
      setCurrencySettings(
        campaignData?.getCampaignById?.fxTransactionRatesOnSupportedCurrencies?.map(
          data => ({
            currency: {
              currencyCode: data?.currency?.currencyCode,
              name: data?.currency?.name,
              symbol: data?.currency?.symbol,
            },
            currencyConversionMode: data?.currencyConversionMode,
            fixedFxRateToAssetCurrency: data?.fixedFxRateToAssetCurrency || 0.0,
            variableFxRateToAssetCurrency:
              data?.variableFxRateToAssetCurrency || 0.0,
          }),
        ),
      );
      setBaseCurrency(campaignData?.getCampaignById?.assetCurrency);
    }
  }, [campaignData?.getCampaignById]);

  const handleFieldChange = (index, field, value) => {
    const newCurrencySettings = [...currencySettings];
    newCurrencySettings[index] = {
      ...newCurrencySettings[index],
      [field]: value,
    };
    setCurrencySettings(newCurrencySettings);
  };

  const handleBaseCurrencyChange = i => {
    setBaseCurrency(currencyData[i]);
  };

  const handleAddSettings = () => {
    setCurrencySettings([...currencySettings, initialCurrencySate]);
    console.log('CURRENCY SETTINGS', currencySettings);
  };

  const handleDeleteSettings = id => {
    const filteredFormValue = currencySettings.filter(
      (item, index) => index !== id,
    );
    setCurrencySettings(filteredFormValue);
  };

  const handleUpdate = () => {
    console.log('COMPANY DATA', campaignData);
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          fxTransactionRatesOnSupportedCurrencies: currencySettings?.map(
            data => ({
              currency: {
                currencyCode: data?.currency?.currencyCode,
                name: data?.currency?.name,
                symbol: data?.currency?.symbol,
              },
              currencyConversionMode: data?.currencyConversionMode,
              fixedFxRateToAssetCurrency: data?.fixedFxRateToAssetCurrency,
              variableFxRateToAssetCurrency:
                data?.variableFxRateToAssetCurrency,
            }),
          ),
          assetCurrency: {
            currencyCode: baseCurrency?.currencyCode,
            name: baseCurrency?.name,
            symbol: baseCurrency?.symbol,
          },
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error('Please make sure all fields are not empty');
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Currency Settings</h1>
      </div>
      <div>
        <Form onFinish={handleUpdate} layout="vertical">
          <div className={styles['question-style']}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Base currency is required',
                },
              ]}
              style={{width: 320, marginRight: 20}}
              label="Base Currency"
              name="name"
              initialValue={campaignData?.getCampaignById?.assetCurrency?.name}>
              <Select
                defaultValue={
                  campaignData?.getCampaignById?.assetCurrency?.name
                }
                value={baseCurrency?.name || ''}
                placeholder="Select a currency"
                onChange={e => handleBaseCurrencyChange(e)}
                name="name">
                {currencyData.map((item, i) => (
                  <Option key={i}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          {currencySettings.map((element, index) => (
            <Fragment key={index}>
              <FadeEffect>
                <div className={styles['question-style']}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Currency name is required',
                      },
                    ]}
                    style={{width: 320, marginRight: 20}}
                    label="Currency Name"
                    name={`${
                      currencyMatch[element.currency.currencyCode].key
                    }-name`}
                    initialValue={`${
                      currencyMatch[element.currency.currencyCode].key
                    }`}>
                    <Select
                      value={element.currency.name}
                      defaultValue={`${
                        currencyMatch[element.currency.currencyCode].key
                      }`}
                      onChange={e =>
                        handleFieldChange(index, 'currency', currencyData[e])
                      }>
                      {currencyData.map((item, i) => (
                        <Option key={i}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item style={{width: 320}} label="Currency Code">
                    <Input
                      value={element.currency.currencyCode || ''}
                      placeholder="Currency code"
                      readOnly
                    />
                  </Form.Item>
                  <img
                    src={trash}
                    onClick={() => handleDeleteSettings(index)}
                    alt=""
                  />
                </div>
                <div className={styles['question-style']}>
                  <Form.Item
                    style={{width: 320, marginRight: 20}}
                    label="Currency Symbol">
                    <Input
                      value={element.currency.symbol || ''}
                      placeholder="Currency Symbol"
                      readOnly
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Conversion mode is required',
                      },
                    ]}
                    style={{width: 320}}
                    label="Currency Conversion Mode"
                    name={`${element.currencyConversionMode}-currencyConversionMode`}>
                    <Select
                      value={element.currencyConversionMode}
                      defaultValue={element.currencyConversionMode}
                      onChange={e =>
                        handleFieldChange(index, 'currencyConversionMode', e)
                      }
                      placeholder="Select conversion mode">
                      <Option value="FIXED">Fixed</Option>
                      <Option value="VARIABLE">Variable</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className={styles['question-style']}>
                  {element.currencyConversionMode === 'FIXED' ? (
                    <Form.Item
                      rules={[
                        {
                          required: element.currencyConversionMode === 'FIXED',
                          message: 'Fixed FX rate is required',
                        },
                      ]}
                      style={{width: 320, marginRight: 20}}
                      label="Fixed FX Rate"
                      name={`${element.fixedFxRateToAssetCurrency}-fixedFxRateToAssetCurrency`}>
                      <InputNumber
                        type="number"
                        value={element.fixedFxRateToAssetCurrency}
                        defaultValue={element.fixedFxRateToAssetCurrency}
                        onChange={e =>
                          handleFieldChange(
                            index,
                            'fixedFxRateToAssetCurrency',
                            e,
                          )
                        }
                        placeholder="Fixed FX Rate"
                        className={styles.inputNumber}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      rules={[
                        {
                          required:
                            element.currencyConversionMode === 'VARIABLE',
                          message: 'Variable FX rate is required',
                        },
                      ]}
                      style={{width: 320}}
                      label="Markup Fee (%)"
                      name={`${element.variableFxRateToAssetCurrency}-variableFxRateToAssetCurrency`}
                      initialValue={element.variableFxRateToAssetCurrency}>
                      <InputNumber
                        type="number"
                        value={element.variableFxRateToAssetCurrency}
                        defaultValue={element.variableFxRateToAssetCurrency}
                        onChange={e =>
                          handleFieldChange(
                            index,
                            'variableFxRateToAssetCurrency',
                            e,
                          )
                        }
                        placeholder="Variable FX Rate"
                        className={styles.inputNumber}
                      />
                    </Form.Item>
                  )}
                </div>
              </FadeEffect>
            </Fragment>
          ))}
        </Form>
        <div onClick={handleAddSettings} className={styles['add-risks']}>
          <img src={plusIcon} alt="add-settings" />
          <span>Add Settings</span>
        </div>
        <SubmitButton
          disabled={campaignLoading}
          label="Update"
          handleClick={handleUpdate}
        />
      </div>
    </div>
  );
};

export default UpdateCurrencySettings;
