import {useEffect, useState} from 'react';
import styles from './editFaq.module.scss';
import backIcon from 'images/back.png';
import {Form, Input, Spin} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {GET_FAQ} from 'graphql/queries/faq';
import ReactQuill from 'react-quill';
import '../../../../node_modules/react-quill/dist/quill.snow.css';
import {UPDATE_FAQ} from 'graphql/mutations/faq';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {toast} from 'react-toastify';
import UploadImage from 'components/Startup/profile/companyImage';

const EditFaq = () => {
  const [imgUrl, setImgUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [author, setAuthor] = useState('');
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const navigate = useNavigate();
  const {id} = useParams();

  const {data, loading} = useQuery(GET_FAQ, {
    variables: {
      faqId: id,
    },
  });

  const fieldWidth = 730;
  const [UpdateFaqInput, {loading: updateFaq}] = useMutation(UPDATE_FAQ);

  const handleSave = () => {
    UpdateFaqInput({
      variables: {
        data: {
          faqId: id,
          question: title,
          answer: content,
          author,
          featuredImage: imgUrl,
        },
      },
    }).then(({data: {updateFaq}}) => {
      if (updateFaq.__typename === 'Error') {
        toast.error(updateFaq.message);
      } else {
        toast.success('Updated Successfully');
        navigate('/dashboard/support/faq');
      }
    });
  };

  useEffect(() => {
    setImgUrl(data?.getFaqById?.faq?.featuredImage);
    setPreviewUrl(data?.getFaqById?.faq?.featuredImage);
    setTitle(data?.getFaqById?.faq?.question);
    setContent(data?.getFaqById?.faq?.answer);
    setAuthor(data?.getFaqById?.faq?.author);
  }, [data?.getFaqById?.faq]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }
  return (
    <div>
      <div className={styles.navigation}>
        <img src={backIcon} alt="Go back" onClick={() => navigate(-1)} />
        <span>
          Learn & FAQ <span className={styles['h-style']}>/ New Article</span>
        </span>
      </div>
      <div className={styles.form}>
        <UploadImage
          previewUrl={previewUrl}
          setPreviewUrl={setPreviewUrl}
          setImgUrl={setImgUrl}
          large
          bucket="faq-and-learn-articles-bucket"
        />
        <Form onFinish={handleSave} layout="vertical">
          <Form.Item
            rules={[
              {
                required: true,
              },
            ]}
            label="Title"
            className={styles.field}
            name="title">
            <Input
              style={{width: fieldWidth}}
              defaultValue={data?.getFaqById?.faq.question}
              value={title}
              onChange={e => setTitle(e.target.value)}
              placeholder={'Title'}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
              },
            ]}
            label="Created By"
            className={styles.field}
            name="created by">
            <Input
              style={{width: fieldWidth}}
              defaultValue={data?.getFaqById?.faq?.author}
              value={author}
              onChange={e => setAuthor(e.target.value)}
              placeholder={'Created By'}
            />
          </Form.Item>
          <div className={styles['quill-container']}>
            <span>Content</span>
            <ReactQuill
              className={styles.quill}
              theme="snow"
              modules={EditFaq.modules}
              formats={EditFaq.format}
              onChange={el => setContent(el)}
              placeholder="Write Something"
              value={content}
            />
          </div>
          <SubmitButton
            handleClick={() => handleSave()}
            disabled={updateFaq}
            label="Save Changes"
          />
        </Form>
      </div>
    </div>
  );
};

EditFaq.modules = {
  toolbar: [
    [{header: '1'}, {header: '2'}, {header: [3, 4, 5, 6]}, {font: []}],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{list: 'ordered'}, {list: 'bullet'}],
    ['link'],

    ['code-block'],
  ],
};

EditFaq.format = [
  'header',
  'font',
  'size',
  'strike',
  'bold',
  'italic',
  'underline',
  'blockquote',
  'list',
  'bullet',
  'link',
  'code-block',
];

export {EditFaq};
