import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import styles from './userInvite.module.scss';
import {Form, Input, Select, Spin} from 'antd';
import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import {GET_ALL_GROUPS} from 'graphql/queries/groups';
import {useDispatch, useSelector} from 'react-redux';
import {invite} from 'redux/store/campaign';
import DBModal from 'components/modal/modal';
import {SubmitCampaign} from '../submit/SubmitCampaign';
import {SubmitTestCampaign} from '../submit/SubmitTestCampaign';
import {UserSelectField} from 'components/userSelectField/UserSelectField';

const UsersInvite = () => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [groupId, setGroupId] = useState('');
  const [bestFit, setBestFit] = useState('');
  const [stamp, setStamp] = useState('');
  const [templateId, setTemplateId] = useState('');
  const {Option} = Select;
  const dispatch = useDispatch();
  const {slug} = useParams();

  const campaign = useSelector(state => state.campaign.campaign);
  const {loading, data: groupData} = useQuery(GET_ALL_GROUPS);

  useEffect(() => {
    if (campaign) {
      setSelectedGroups(campaign?.inviteGroups);
      setSelectedUsers(campaign?.inviteUsers);
      setGroupId(campaign?.groupId);
      setTemplateId(campaign?.templateId);
      setStamp(campaign?.stamp);
      setBestFit(campaign?.bestFit);
    }
  }, [campaign?.stamp]);

  const handleFinish = () => {
    dispatch(
      invite({
        inviteUsers: selectedUsers,
        inviteGroups: selectedGroups,
        groupId,
        templateId: templateId?.trim(),
        stamp,
        bestFit,
      }),
    );
    setOpenModal(true);
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div>
        <h1>{slug ? 'Create Test Campaign' : 'Invite Users and Groups'}</h1>
      </div>
      <div>
        <Form onFinish={handleFinish} className={styles.form} layout="vertical">
          {!slug && (
            <>
              <Form.Item name="users" label="Invite User">
                <UserSelectField
                  users={selectedUsers}
                  setUsers={setSelectedUsers}
                  defaultUsers={campaign?.inviteUsers?.map(
                    data => data?.split(';')[2],
                  )}
                />
              </Form.Item>
              <Form.Item name="groups" label="Invite Groups">
                <Select
                  mode="multiple"
                  value={selectedGroups}
                  onChange={e => setSelectedGroups(e)}
                  showSearch
                  defaultValue={campaign?.inviteGroups}
                  placeholder="Invite Groups">
                  {groupData?.getAllGroups?.groups?.map(data => (
                    <Option key={`${data?.name};${data?.id}`}>
                      <div className={styles['group-image']}>
                        <img src={data?.bannerImg} alt="" />
                        <span>{data?.name}</span>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                className={styles['field-width']}
                name="templateId"
                label="Template ID"
                rules={[{required: !slug}]}
                initialValue={campaign?.templateId}>
                <Input
                  value={templateId}
                  placeholder="Template id required"
                  onChange={e => setTemplateId(e.target.value)}
                  defaultValue={campaign?.templateId}
                />
              </Form.Item>
            </>
          )}

          <Form.Item
            className={styles['field-width']}
            name="group"
            label="Select Group">
            <Select
              value={groupId}
              onChange={e => setGroupId(e)}
              showSearch
              defaultValue={campaign?.groupId}
              placeholder="Select a group for this campaign">
              {groupData?.getAllGroups?.groups?.map(data => (
                <Option key={`${data.name};${data.id}`}>
                  <div className={styles['group-image']}>
                    <img src={data.bannerImg} alt="" />
                    <span>{data.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[{required: true, message: 'Stamp is Required'}]}
            className={styles['field-width']}
            name="Stamp"
            label="Stamp"
            initialValue={campaign?.stamp}>
            <Input
              value={stamp}
              onChange={e => setStamp(e.target.value)}
              placeholder="Enter Stamp"
              defaultValue={campaign?.stamp}
            />
          </Form.Item>
          <Form.Item
            name="bestFit"
            label="Who is this opportunity best fit for?"
            className={styles['field-width']}
            initialValue={campaign?.bestFit}>
            <Input.TextArea
              rows={4}
              value={bestFit}
              onChange={e => setBestFit(e.target.value)}
              defaultValue={campaign?.bestFit}
            />
          </Form.Item>
          <div>
            <SubmitButton label="Save and Finish" />
          </div>
        </Form>
        <div>
          <DBModal
            isOpen={openModal}
            handleClose={() => setOpenModal(false)}
            content={
              slug ? (
                <SubmitTestCampaign closeModal={setOpenModal} />
              ) : (
                <SubmitCampaign closeModal={setOpenModal} />
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default UsersInvite;
