import {useState} from 'react';
import styles from './newAnnouncement.module.scss';
import backIcon from 'images/back.png';
import {DatePicker, Form, Input, Select, Spin, Switch, Tabs} from 'antd';
import {useNavigate} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import SubmitButton from 'components/Startup/components/SubmitButton';
import moment from 'moment';
import {toast} from 'react-toastify';
import UploadImage from 'components/Startup/profile/companyImage';
import {CREATE_ANNOUNCEMENT} from 'graphql/mutations/announcement';
import {GET_ALL_ANNOUNCEMENTS} from 'graphql/queries/announcement';
import {announcementType} from 'utils/mock';
import {UploadVideoToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import {BigPlayButton, Player} from 'video-react';
import {Button} from '@dabafinance/react-components';
import {GET_ALL_REPORT} from 'graphql/queries/report';
import {GET_MINIMAL_CAMPAIGNS} from 'graphql/queries/campaign';
import {fieldRules, getUserTimezone} from 'utils/helper';
import {supportedLanguages} from 'utils/constants';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {onTranslateAnnouncement} from '../actions';

const NewAnnouncement = () => {
  const [imgUrl, setImgUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [progress, setProgress] = useState(0);
  const [announcementUrl, setAnnouncementUrl] = useState('');
  const [type, setType] = useState('');
  const [report, setReport] = useState('');
  const [opportunity, setOpportunity] = useState('');
  const [openUrl, setOpenUrl] = useState(false);
  const [publishedDate, setPublishedDate] = useState('');
  const [expiredDate, setExpiredDate] = useState('');
  const [videoUploadLoading, setVideoUploadLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoUrlActive, setVideoUrlActive] = useState(false);
  const [audioFile, setAudioFile] = useState('');
  const [audioProgress, setAudioProgress] = useState(0);
  const [loadingAudioUpload, setLoadingAudioUpload] = useState(false);
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [language, setLanguage] = useState('');
  const [selectedTranslationLanguage, setSelectedTranslationLanguage] =
    useState('');
  const [enableChangeTranslation, setEnableChangeTranslation] = useState(true);
  const [translatedAnnouncements, setTranslatedAnnouncements] = useState([]);
  const {Option} = Select;
  const navigate = useNavigate();

  const [createAnnouncement, {loading: creating}] =
    useMutation(CREATE_ANNOUNCEMENT);

  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );

  const handleChangeTranslatedValues = (key, event, name) => {
    let eventName = name;
    let eventValue = name ? event : event.target.value;
    const newFormValues = [...translatedAnnouncements];
    newFormValues[key][eventName] = eventValue;
    setTranslatedAnnouncements(newFormValues);
  };

  const handleTranslation = async () => {
    const translateProps = {
      sourceLang: language,
      targetLang: selectedTranslationLanguage,
      targetText1: title,
      targetText2: subTitle,
    };
    try {
      const newValues = [...translatedAnnouncements];

      //Check if translation exists
      if (newValues.length && newValues[0]) {
        // Check if we're trying to translate in the same language
        const languageExists = newValues.some(
          data => data.language === selectedTranslationLanguage,
        );
        if (languageExists) {
          const updateTranslation = await onTranslateAnnouncement(
            translateText,
            translateProps,
          );
          const translationIndex = newValues.findIndex(
            data => data.language === selectedTranslationLanguage,
          );
          newValues[translationIndex].title = updateTranslation.title;
          newValues[translationIndex].subTitle = updateTranslation.subTitle;
          newValues[translationIndex].language = updateTranslation.language;
          newValues[translationIndex].audioFile = '';
          newValues[translationIndex].loadingAudioUpload = false;
          newValues[translationIndex].audioProgress = 0;
          newValues[translationIndex].imageUrl = '';
          newValues[translationIndex].previewUrl = '';
          setTranslatedAnnouncements(newValues);
        }
      } else {
        const triggerTranslation = await onTranslateAnnouncement(
          translateText,
          translateProps,
        );
        if (!triggerTranslation) {
          return toast.error('Something went wrong when Translating');
        }
        newValues.push(triggerTranslation);
        setTranslatedAnnouncements(newValues);
        setTranslatedAnnouncements(newValues);
      }
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  const {data: getAllReports} = useQuery(GET_ALL_REPORT);

  const {data: getAllCampaigns} = useQuery(GET_MINIMAL_CAMPAIGNS);

  const fieldWidth = 730;

  const handleUploadVideo = async e => {
    const selectedFile = e.target.files[0];
    if (selectedFile.type === 'video/mp4') {
      setVideoUploadLoading(true);
      const response = await UploadVideoToBucket(
        selectedFile,
        'annoucement-images',
        setProgress,
      );
      setVideoUrl(response);
      setAnnouncementUrl(response);
      setVideoUploadLoading(false);
      setProgress(0);
    } else {
      toast.error('Please upload a mp4 video');
    }
  };

  const handleUploadAudio = async e => {
    const selectedFile = e.target.files[0];
    setLoadingAudioUpload(true);
    const response = await UploadVideoToBucket(
      selectedFile,
      'annoucement-images',
      setAudioProgress,
    );
    setAudioFile(response);
    setLoadingAudioUpload(false);
  };

  const handleUploadMultilangAudio = async (e, index) => {
    const selectedFile = e.target.files[0];
    const newFormValues = [...translatedAnnouncements];
    newFormValues[index].loadingAudioUpload = true;
    setTranslatedAnnouncements(newFormValues);
    const updatedFormValues = [...translatedAnnouncements];
    const response = await UploadVideoToBucket(
      selectedFile,
      'annoucement-images',
      () => {},
    );
    updatedFormValues[index].audioFile = response;
    updatedFormValues[index].loadingAudioUpload = false;
    setTranslatedAnnouncements(updatedFormValues);
  };

  const setMultilangPreview = (url, index) => {
    const newValues = [...translatedAnnouncements];
    newValues[index].previewUrl = url;
    setTranslatedAnnouncements(newValues);
  };

  const setMultilangImageUrl = (url, index) => {
    const newValues = [...translatedAnnouncements];
    newValues[index].imageUrl = url;
    setTranslatedAnnouncements(newValues);
  };

  const languageToBeTranslatedTo = supportedLanguages.filter(
    key => key.value !== language,
  );

  const handleSave = () => {
    createAnnouncement({
      variables: {
        data: {
          type,
          title,
          subTitle,
          ...(audioFile && {audioUrl: audioFile}),
          imageUrl: imgUrl,
          openUrlOnClick: openUrl,
          announcementUrl,
          language,
          report,
          opportunity,
          publishedDate,
          expiredDate,
          multiLang: translatedAnnouncements.map(item => ({
            ...(audioFile && {audioUrl: audioFile}),
            imageUrl: item?.imageUrl,
            language: item?.language,
            title: item?.title,
            subTitle: item?.subTitle,
            announcementUrl,
          })),
        },
      },
      refetchQueries: [{query: GET_ALL_ANNOUNCEMENTS}],
    }).then(({data: {createAnnouncement}}) => {
      if (createAnnouncement.__typename === 'Error') {
        toast.error(createAnnouncement.message);
      } else {
        toast.success('Announcement created successfully');
        navigate('/dashboard/announcements/upcoming');
      }
    });
  };

  return (
    <div>
      <div className={styles.navigation}>
        <img src={backIcon} alt="Go back" onClick={() => navigate(-1)} />
        <span>
          <span className={styles['h-style']}>New Announcement</span>
        </span>
      </div>
      <div className={styles.form}>
        <span className={styles.note}>*Recommended size is 327px X 158px*</span>
        <UploadImage
          previewUrl={previewUrl}
          setPreviewUrl={setPreviewUrl}
          setImgUrl={setImgUrl}
          large
          bucket="annoucement-images"
        />
        <Form onFinish={handleSave} layout="vertical">
          <Form.Item
            rules={[fieldRules('Language')]}
            label="Language"
            className={styles.field}
            name="language">
            <Select
              style={{width: fieldWidth}}
              value={language}
              onChange={e => setLanguage(e)}>
              {supportedLanguages.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Announcement Type"
            className={styles.field}
            name="type"
            rules={[
              {
                required: true,
              },
            ]}>
            <Select
              style={{width: fieldWidth}}
              value={type}
              onChange={e => setType(e)}>
              {announcementType.map(({key, name}) => (
                <Option key={key} value={key}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {type === 'AUDIO' && (
            <>
              <Form.Item
                label="Language Translation"
                className={styles.field}
                name="translation">
                <Select
                  style={{width: fieldWidth}}
                  value={selectedTranslationLanguage}
                  onChange={e => setSelectedTranslationLanguage(e)}>
                  {languageToBeTranslatedTo.map(data => (
                    <Option key={data.value}>{data.label}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Tabs
                className={styles.tabs}
                tabBarExtraContent={
                  selectedTranslationLanguage && (
                    <Button
                      disabled={!title || !subTitle || loadingTranslation}
                      label="Translate"
                      onClick={handleTranslation}
                    />
                  )
                }>
                <Tabs.TabPane tab="Original" key="Original">
                  <Form.Item
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                    label="Title"
                    className={styles.field}
                    name="title">
                    <Input
                      style={{width: fieldWidth}}
                      value={title}
                      defaultValue={title}
                      onChange={e => setTitle(e.target.value)}
                      placeholder={'title'}
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                    label="Subtitle"
                    className={styles.field}
                    name="subTitle">
                    <Input.TextArea
                      style={{width: fieldWidth}}
                      value={subTitle}
                      rows={5}
                      onChange={e => setSubTitle(e.target.value)}
                    />
                  </Form.Item>
                  {audioFile ? (
                    <div className={styles['audio-file']}>
                      <audio controls src={audioFile} />
                      <Button
                        label="Delete File"
                        onClick={() => {
                          setAudioFile('');
                          setAudioProgress(0);
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <label className={styles.upload} htmlFor="file-upload">
                        {loadingAudioUpload ? (
                          <div className={styles.uploading}>
                            <Spin />
                            <h3>Uploading, Please wait...</h3>
                            <span>{audioProgress}%</span>
                          </div>
                        ) : (
                          <h2>Upload Audio or Recording</h2>
                        )}
                        <input
                          type="file"
                          id="file-upload"
                          accept="audio/mpeg"
                          className={styles['input-file']}
                          onChange={handleUploadAudio}
                        />
                      </label>
                    </div>
                  )}
                </Tabs.TabPane>
                {translatedAnnouncements.length &&
                  translatedAnnouncements.map((data, index) => (
                    <Tabs.TabPane
                      key={data.language}
                      tab={
                        supportedLanguages.find(
                          lang => lang.value === data.language,
                        ).label
                      }>
                      <div className={styles.updateButton}>
                        <Button
                          onClick={() =>
                            setEnableChangeTranslation(!enableChangeTranslation)
                          }
                          type="secondary"
                          label={
                            enableChangeTranslation
                              ? 'Edit Translation'
                              : 'Disable Translation'
                          }
                        />
                      </div>
                      <div key={index}>
                        <span className={styles.note}>
                          *Recommended size is 327px X 158px*
                        </span>
                        <UploadImage
                          previewUrl={data.previewUrl}
                          setPreviewUrl={e => setMultilangPreview(e, index)}
                          setImgUrl={e => setMultilangImageUrl(e, index)}
                          large
                          bucket="annoucement-images"
                        />
                        <Form.Item label="Title" className={styles.field}>
                          <Input
                            style={{width: fieldWidth}}
                            value={data.title}
                            disabled={enableChangeTranslation}
                            onChange={e =>
                              handleChangeTranslatedValues(
                                index,
                                e.target.value,
                                'title',
                              )
                            }
                            placeholder={'title'}
                          />
                        </Form.Item>
                        <Form.Item label="Subtitle" className={styles.field}>
                          <Input.TextArea
                            style={{width: fieldWidth}}
                            value={data.subTitle}
                            disabled={enableChangeTranslation}
                            onChange={e =>
                              handleChangeTranslatedValues(
                                index,
                                e.target.value,
                                'subTitle',
                              )
                            }
                            rows={5}
                          />
                        </Form.Item>
                        {data.audioFile ? (
                          <div className={styles['audio-file']}>
                            <audio controls src={data.audioFile} />
                            <Button
                              label="Delete File"
                              onClick={() => {
                                data.audioFile = '';
                                data.audioProgress = 0;
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <label
                              className={styles.upload}
                              htmlFor="file-upload">
                              {data.loadingAudioUpload ? (
                                <div className={styles.uploading}>
                                  <Spin />
                                  <h3>Uploading, Please wait...</h3>
                                  <span>{data.audioProgress}%</span>
                                </div>
                              ) : (
                                <h2>Upload Audio or Recording</h2>
                              )}
                              <input
                                type="file"
                                id="file-upload"
                                accept="audio/mpeg"
                                className={styles['input-file']}
                                onChange={e =>
                                  handleUploadMultilangAudio(e, index)
                                }
                              />
                            </label>
                          </div>
                        )}
                      </div>
                    </Tabs.TabPane>
                  ))}
              </Tabs>
            </>
          )}
          {type === 'SINGLE_REPORT' && (
            <Form.Item
              label="Report"
              className={styles.field}
              name="report"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select
                style={{width: fieldWidth}}
                value={report}
                onChange={e => setReport(e)}>
                {getAllReports?.getAllReports?.map(({id, title}) => (
                  <Option key={id} value={id}>
                    {title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {type === 'SINGLE_OPPORTUNITY' && (
            <Form.Item
              label="Campaign"
              className={styles.field}
              name="opportunity"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select
                style={{width: fieldWidth}}
                value={opportunity}
                onChange={e => setOpportunity(e)}>
                {getAllCampaigns?.getAllCampaigns?.campaigns
                  ?.filter(
                    data =>
                      data?.campaignStatus === 'ACTIVE' &&
                      data?.campaignMode === 'LIVE',
                  )
                  .map(({id, fundraisingCompany}) => (
                    <Option key={id} value={id}>
                      <div className={styles['all-data']}>
                        <img src={fundraisingCompany?.logoImgURL} alt="" />
                        <span>{fundraisingCompany?.companyName}</span>
                      </div>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          )}
          {type === 'VIDEO' && (
            <div>
              <Form.Item label="Use Video Url" className={styles.field}>
                <Switch
                  defaultChecked={videoUrlActive}
                  onChange={e => setVideoUrlActive(e)}
                />
              </Form.Item>
              {videoUrlActive ? (
                <div className={styles['video-upload']}>
                  <Form.Item
                    label="Video Url"
                    className={styles.field}
                    name="announcementUrl">
                    <Input
                      style={{width: fieldWidth}}
                      value={announcementUrl}
                      defaultValue={announcementUrl}
                      onChange={e => setAnnouncementUrl(e.target.value)}
                      placeholder={'Url'}
                    />
                  </Form.Item>
                </div>
              ) : (
                <div className={styles['video-upload']}>
                  {videoUrl ? (
                    <div>
                      <Player fluid={false} width={730} src={videoUrl}>
                        <BigPlayButton position="center" />
                      </Player>
                      <div>
                        <Button
                          className={styles['delete-button']}
                          label="Delete Video"
                          onClick={() => setVideoUrl('')}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <label className={styles.upload} htmlFor="file-upload">
                        {videoUploadLoading ? (
                          <div className={styles['upload-file']}>
                            <Spin />
                            <h2>Uploading Please Wait...</h2>
                            <span>{progress}%</span>
                          </div>
                        ) : (
                          <h2>Upload Announcement Video</h2>
                        )}
                        <input
                          type="file"
                          id="file-upload"
                          className={styles['input-file']}
                          required
                          onChange={handleUploadVideo}
                        />
                      </label>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {(type === 'EVENT' || type === 'GENERAL' || type === 'AUDIO') && (
            <div>
              <Form.Item
                rules={[
                  {
                    required: false,
                  },
                ]}
                label="Announcement Url"
                className={styles.field}
                name="announcementUrl">
                <Input
                  style={{width: fieldWidth}}
                  value={announcementUrl}
                  defaultValue={announcementUrl}
                  onChange={e => setAnnouncementUrl(e.target.value)}
                  placeholder={'Url'}
                  readOnly={type === 'VIDEO'}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: false,
                  },
                ]}
                label="Open url on click?"
                className={styles.field}
                name="OpenUrlOnClick">
                <Switch
                  defaultChecked={openUrl}
                  onChange={e => setOpenUrl(e)}
                />
              </Form.Item>
            </div>
          )}
          <div className={styles['date-format']}>
            <Form.Item
              label="Published Date (Time is saved in UTC/GMT)"
              name="pubishedDate"
              rules={[
                {
                  required: true,
                  message: 'Published date required',
                },
              ]}>
              <DatePicker
                name="published Date"
                value={publishedDate}
                style={{width: 340}}
                className={styles.publish}
                renderExtraFooter={() => (
                  <div style={{textAlign: 'center'}}>
                    <p>Your timezone is: {getUserTimezone()}</p>
                  </div>
                )}
                format={'YYYY-MM-DD HH:mm:ss'}
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm:ss'),
                }}
                onChange={e =>
                  setPublishedDate(
                    moment(e).format('YYYY-MM-DDTHH:mm:ss+00:00'),
                  )
                }
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Expiry date required',
                },
              ]}
              name="expiredDate"
              label="Expiration Date (Time is saved in UTC/GMT)">
              <DatePicker
                name="Expiry Date"
                format={'YYYY-MM-DD HH:mm:ss'}
                style={{width: 340}}
                renderExtraFooter={() => (
                  <div style={{textAlign: 'center'}}>
                    <p>Your timezone is: {getUserTimezone()}</p>
                  </div>
                )}
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm:ss'),
                }}
                value={expiredDate}
                onChange={e =>
                  setExpiredDate(moment(e).format('YYYY-MM-DDTHH:mm:ss+00:00'))
                }
              />
            </Form.Item>
          </div>
          <SubmitButton disabled={creating} label="Create" />
        </Form>
      </div>
    </div>
  );
};

export {NewAnnouncement};
