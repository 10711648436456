import {useEffect, useState} from 'react';
import {Form, Input, Modal, Spin, Table} from 'antd';
import {Button, Search} from '@dabafinance/react-components';
import DBModal from 'components/modal/modal';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {NoUpdate} from 'components/noUpdate';
import UpdateDocCategory from '../updateDocCategory';
import {GET_ALL_COMPANY_DOCUMENT_CATEGORIES} from 'graphql/queries/companyDocument';
import {useLazyQuery, useMutation} from '@apollo/client';
import {toast} from 'react-toastify';
import editImg from 'images/edit.svg';
import styles from './companyDocCategory.module.scss';

import {
  ADD_COMPANY_DOCUMENT_CATEGORY,
  DELETE_COMPANY_DOCUMENT_CATEGORY,
} from 'graphql/mutations/companyStartups';
import trash from 'images/trash-can-outline.svg';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const CompanyDocumentCategories = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [category, setCategory] = useState('');
  const {confirm} = Modal;

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.DocCategory),
  );

  const [getAllCompanyDocumentCategories, {loading, refetch, data}] =
    useLazyQuery(GET_ALL_COMPANY_DOCUMENT_CATEGORIES);

  useEffect(() => {
    getAllCompanyDocumentCategories({
      variables: {
        filter: {
          paginate: true,
        },
      },
    }).catch(err => {
      toast.error(err?.message);
    });
  }, []);

  const handleSearch = term => {
    if (term !== '') {
      getAllCompanyDocumentCategories({
        variables: {
          filter: {
            paginate: true,
            search: term,
          },
        },
      }).catch(err => {
        toast.error(err?.message);
      });
    } else {
      refetch({
        filter: {
          paginate: true,
        },
      });
    }
  };

  const handleCategoryInfo = category => {
    setOpenEditModal(true);
    setCategory(category);
  };

  const [addCompanyDocumentCategory] = useMutation(
    ADD_COMPANY_DOCUMENT_CATEGORY,
  );

  const handleSave = formValues => {
    confirm({
      content: 'Are you sure you want to ADD this company document category?',
      onOk: () => {
        addCompanyDocumentCategory({
          variables: {
            data: {
              name: formValues?.name,
              description: formValues?.description,
            },
          },
        }).then(({data: {addCompanyDocumentCategory}}) => {
          if (addCompanyDocumentCategory.__typename === 'Error') {
            toast.error(addCompanyDocumentCategory.message);
          } else {
            setOpenCreateModal(false);
            refetch({
              filter: {
                paginate: true,
              },
            });
            toast.success('Category Created Successfully');
          }
        });
      },
    });
  };

  const [deleteCompanyDocumentCategory] = useMutation(
    DELETE_COMPANY_DOCUMENT_CATEGORY,
  );

  const handleDelete = id => {
    confirm({
      content: 'Are you sure you want to DELETE this document category?',
      onOk: () => {
        deleteCompanyDocumentCategory({
          variables: {
            categoryId: id,
          },
        })
          .then(({data: {deleteCompanyDocumentCategory}}) => {
            if (deleteCompanyDocumentCategory.__typename === 'Error') {
              toast.error(deleteCompanyDocumentCategory.message);
            } else {
              refetch({
                filter: {
                  paginate: true,
                },
              });
              toast.success('Category deleted Successfully');
            }
          })
          .catch(error => toast.error(error));
      },
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div>
          <span>{record?.name}</span>
        </div>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => (
        <div>
          <span>{record?.description}</span>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <div className={styles.actions}>
          {tabOperations?.update && (
            <div
              onClick={() => handleCategoryInfo(record)}
              className={styles['action-icon']}>
              <img src={editImg} alt="edit" />
              <span>Edit</span>
            </div>
          )}
          {tabOperations?.delete && (
            <img
              onClick={() => {
                handleDelete(record?.id);
              }}
              className={styles.trash}
              src={trash}
              alt=""
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className={styles['startup-table']}>
        <div className={styles['startup-header']}>
          <div className={styles['header-container']}>
            <Search
              className={styles['search-bar']}
              id={''}
              placeholder="Name"
              onChange={handleSearch}
            />
            <div>
              <Button
                label={'Add Document Category'}
                className={styles['header-button']}
                disabled={!tabOperations?.create}
                onClick={() => setOpenCreateModal(true)}
              />
            </div>
          </div>
        </div>
        {loading ? (
          <div className={styles.loading}>
            <Spin size="large" />
            <h1>Loading...</h1>
          </div>
        ) : data?.getCompanyDocumentCategories?.data?.length >= 1 ? (
          <>
            <Table
              className={styles['user-table']}
              dataSource={data?.getCompanyDocumentCategories?.data}
              columns={columns}
            />
          </>
        ) : (
          <NoUpdate
            title={'No Document Category'}
            description={
              'There are currently no document category, you could create a new one'
            }
            buttonLabel={'Add Document Category'}
            onClick={() => setOpenCreateModal(true)}
          />
        )}
      </div>
      <DBModal
        isOpen={openCreateModal}
        handleClose={() => setOpenCreateModal(false)}
        content={
          <div>
            <h1>Create Document Category</h1>
            <Form
              className={styles.form}
              layout="vertical"
              onFinish={handleSave}>
              <Form.Item
                name="name"
                label="Category Name"
                rules={[
                  {
                    required: true,
                    message: 'Category Name is required',
                  },
                ]}>
                <Input
                  style={{width: '100%'}}
                  name="name"
                  placeholder="Bi-weekly"
                />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: false,
                  },
                ]}>
                <Input.TextArea
                  style={{width: '100%'}}
                  name="description"
                  placeholder="Description..."
                  rows={5}
                />
              </Form.Item>
              <SubmitButton
                disabled={!tabOperations?.create}
                label="Add Document Category"
              />
            </Form>
          </div>
        }
      />

      <DBModal
        isOpen={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        content={
          <UpdateDocCategory
            refetch={refetch}
            closeModal={() => setOpenEditModal(false)}
            data={category}
          />
        }
      />
    </div>
  );
};

export default CompanyDocumentCategories;
