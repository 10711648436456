import {useState} from 'react';
import styles from './adminAccessCode.module.scss';
import {GET_ALL_ACCESS_CODE} from 'graphql/queries/accessCode';
import {
  GENERATE_ACCESS_CODE,
  DEACTIVATE_ACCESS_CODE,
} from 'graphql/mutations/accessCode';
import {Table, Spin, Form, InputNumber, Modal} from 'antd';
import {Button} from '@dabafinance/react-components';
import StartupCounts from 'components/Startup/components/startupCount';
import DBModal from 'components/modal/modal';
import {useNavigate} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import pageNotFound from 'images/pageNotFound.png';
import moment from 'moment';
import {toast} from 'react-toastify';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {NavTabsEnum} from 'utils/constants';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';

const {confirm} = Modal;

const BetaAccessCode = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [codeAmount, setCodeAmount] = useState('');

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.AccessCode),
  );

  const {data, loading} = useQuery(GET_ALL_ACCESS_CODE);
  const {data: allActive} = useQuery(GET_ALL_ACCESS_CODE, {
    variables: {
      isActive: true,
    },
  });
  const {data: notActive} = useQuery(GET_ALL_ACCESS_CODE, {
    variables: {
      isActive: false,
    },
  });

  const [generateAccessCode, {loading: generateCode}] =
    useMutation(GENERATE_ACCESS_CODE);

  const [deactivateCode] = useMutation(DEACTIVATE_ACCESS_CODE);

  const handleGenerate = () => {
    generateAccessCode({
      variables: {
        codeAmount,
      },
      refetchQueries: [{query: GET_ALL_ACCESS_CODE}],
    }).then(({data: {generateAccessCode}}) => {
      if (generateAccessCode.__typename === 'Error') {
        toast.error(generateAccessCode.message);
      } else {
        toast.success('Code Generated Successfully');
        setOpenModal(false);
        navigate('/dashboard/access-code');
      }
    });
  };

  const deactivate = id => {
    confirm({
      content: 'Are you sure you want to deactivate this code?',
      onOk: () => {
        deactivateCode({
          variables: {
            accessCodeId: id,
          },
          refetchQueries: [{query: GET_ALL_ACCESS_CODE}],
        }).then(({data: {deactivateCode}}) => {
          if (deactivateCode.__typename === 'Error') {
            toast.error(deactivateCode.message);
          } else {
            toast.success('Code Deactivated Successfully');
            navigate('/dashboard/access-code');
          }
        });
      },
    });
  };

  const columns = [
    {
      title: 'Generated Code',
      dataIndex: 'accessCode',
      key: 'generatedcode',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4 style={{width: 100}}>{record.accessCode}</h4>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (text, record) => (
        <div>
          <span className={record.isActive ? styles.active : styles.expired}>
            {record.isActive ? 'Active' : 'Expired'}
          </span>
        </div>
      ),
    },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      key: 'creationdate',
      render: (text, record) => (
        <span>{moment(record.createdAt).format('DD MMMM YYYY')}</span>
      ),
    },
  ];

  if (tabOperations?.delete) {
    columns.push({
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <div>
          <Button
            onClick={() => deactivate(record.id)}
            type="secondary"
            label="Deactivate Code"
            disabled={!record.isActive}
            className={styles['deactivate-btn']}
          />
        </div>
      ),
    });
  }

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles['container-wrapper']}>
      <div className={styles['header-container']}>
        <h1>Startups</h1>
        <div className={styles['left-buttons']}>
          <Button
            onClick={() => setOpenModal(true)}
            className={styles['header-button']}
            label={'Generate Code'}
            disabled={!tabOperations?.create}
          />
        </div>
      </div>
      <div className={styles['startup-counts']}>
        <StartupCounts
          active
          value={data?.getAllAccessCode?.length}
          text={'Total Codes Generated'}
        />
        <StartupCounts
          value={allActive?.getAllAccessCode?.length}
          text={'Total Active Code'}
        />
        <StartupCounts
          value={notActive?.getAllAccessCode?.length}
          text={'Total Codes Expired'}
        />
      </div>

      <div>
        <DBModal
          isOpen={openModal}
          handleClose={() => setOpenModal(false)}
          content={
            <div className={styles['modal-container']}>
              <h2 className={styles['modal-header']}>Generate Access Code</h2>
              <div>
                <Form onFinish={handleGenerate} layout="vertical">
                  <Form.Item
                    label="Enter Quantity"
                    className={styles.field}
                    name="codeAmount">
                    <InputNumber
                      className={styles['input-number']}
                      onChange={e => setCodeAmount(e)}
                      placeholder={'Enter Qty'}
                    />
                  </Form.Item>
                  <SubmitButton
                    handleClick={() => handleGenerate()}
                    disabled={generateCode}
                    label="Generate"
                  />
                </Form>
              </div>
            </div>
          }
        />
      </div>

      <div className={styles['startup-table']}>
        <div className={styles['startup-header']}>
          <h1>Access Code</h1>
        </div>
        <div className={styles['table-height']}>
          {data?.getAllAccessCode?.length >= 1 ? (
            <div>
              <Table columns={columns} dataSource={data?.getAllAccessCode} />
            </div>
          ) : (
            <div className="query-loading">
              <img src={pageNotFound} alt="" />
              <h1>No Access Code</h1>
              <span>Generate new access code</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export {BetaAccessCode};
