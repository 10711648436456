const {createSlice} = require('@reduxjs/toolkit');

const slice = createSlice({
  name: 'assetClass',
  initialState: {
    bondInstruments: {},
    bonds: {},
  },
  reducers: {
    handleBondsInstrumentState: (state, action) => {
      state.bondInstruments = {
        ...state.bondInstruments,
        ...action.payload,
      };
    },
    handleBondsFieldState: (state, action) => {
      state.bonds = {
        ...state.bonds,
        ...action.payload,
      };
    },
    handleClearBondsState: state => {
      state.bonds = {};
      state.bondInstruments = {};
    },
  },
});

export default slice.reducer;

export const {
  handleBondsInstrumentState,
  handleClearBondsState,
  handleBondsFieldState,
} = slice.actions;
