import {gql} from '@apollo/client';

export const GET_WIRE_TRANSFER_ACCOUNTS = gql`
  query GetAllWireTransferAccountsFilter(
    $input: getAllWireTransferAccountsFilter
  ) {
    getAllWireTransferAccountsFilter(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAllWireTransferAccountsFilter {
        data {
          accountNumber
          accountType
          bankAddress
          bankLogo
          bankName
          type
          countryName
          countryCode
          isActive
          beneficiaryAddress
          beneficiaryName
          flag
          id
        }
      }
    }
  }
`;

export const GET_BANK_ACCOUNT_BY_ID = gql`
  query GetWireTransferAccountById($getWireTransferAccountByIdId: ID!) {
    getWireTransferAccountById(id: $getWireTransferAccountByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWireTransferAccount {
        data {
          currency
          countryCode
          countryName
          flag
          bankAddress
          bankName
          accountNumber
          accountName
          accountType
          isActive
          iban
          rib
          bankCode
          bic
          beneficiaryName
          beneficiaryBankName
          routingNumber
          accountName
          referenceDetails
          beneficiaryAddress
          beneficiaryName
          routingNumber
          swiftCode
          sortCode
          bankCode
          iban
          bic
          pdfDocument
          type
          bankLogo
        }
      }
    }
  }
`;

export const GET_CRYPTO_DETAILS = gql`
  query GetDabaManualCryptoDetails {
    getDabaManualCryptoDetails {
      ... on Error {
        message
        statusCode
      }
      ... on CryptoAccountList {
        accounts {
          id
          walletAddress
          qrCode
          isActive
          label
          symbol
          network
        }
      }
    }
  }
`;

export const GET_CRYPTO_ACCOUNT_BY_ID = gql`
  query GetCryptoAccountById($getCryptoAccountByIdId: ID!) {
    getCryptoAccountById(id: $getCryptoAccountByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on CryptoAccountDetails {
        id
        walletAddress
        qrCode
        isActive
        symbol
        network
        label
      }
    }
  }
`;
