import {useState} from 'react';
import styles from './deviceUpload.module.scss';
import {Upload, message} from 'antd';
import image from 'images/dragger.png';
import {Button} from '@dabafinance/react-components';
import DBModal from 'components/modal/modal';

const DeviceUpload = ({openModal, setOpenModal}) => {
  const [loading, setLoading] = useState(false);
  const {Dragger} = Upload;

  const draggerProps = {
    name: 'file',
    onChange(info) {
      const {status} = info.file;
      if (status !== 'uploading') {
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        setLoading(true);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop() {
      // TODO Later on
    },
  };

  return (
    <div>
      <DBModal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        content={
          <div className={styles.container}>
            <div className={styles['modal-header']}>
              <h1>Upload Startup from Device</h1>
              <p>
                Click to select or drag and drop the video file you to wish
                upload from your device. There is a format that is accepted if
                you dont have it, please <span> click here to download</span>
              </p>
            </div>
            <div className={styles.dragger}>
              <Dragger accept="mp4" maxCount={1} {...draggerProps}>
                <div className={styles['dragger-header']}>
                  <img src={image} alt="dragger" />
                  <span>
                    Click or drag file here to upload. Files accepted- xls, xlxs
                  </span>
                </div>
              </Dragger>
            </div>
            <Button
              disabled={loading}
              className={styles.button}
              label="Upload Company(s)"
            />
          </div>
        }
      />
    </div>
  );
};

export default DeviceUpload;
