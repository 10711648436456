import PercentageCard, {
  CampaignCard,
} from 'pages/adminUser/card/PercentageCard';

const RenderCampaignCard = ({liveValues, totalCompanies}) => {
  return (
    <>
      <PercentageCard
        amount={liveValues?.totalAmountInvested ?? 0}
        label={'Total Amount Invested'}
      />
      <PercentageCard
        amount={liveValues?.totalNumberOfInvestors ?? 0}
        label={'Total Investors'}
      />
      <CampaignCard
        value={liveValues?.totalLiveCampaigns ?? 0}
        label={'Active Live Listing'}
        amount={liveValues?.activeLiveCampaigns ?? 0}
        footerTitle={'Total No. of Listings: '}
      />
      <CampaignCard
        value={totalCompanies}
        label={'Total Test Companies'}
        amount={liveValues?.totalCompanies ?? 0}
        footerTitle={'Total Companies: '}
      />
    </>
  );
};

export default RenderCampaignCard;
