import {toast} from 'react-toastify';

export const handleUpdatePaymentProviderSetting = (
  mutation,
  values,
  provider,
  enableProvider,
  selectedCurrency,
  depositFeeValues,
  withdrawalFeeValues,
  navigate,
) => {
  mutation({
    variables: {
      input: [
        {
          provider: provider,
          currency: selectedCurrency,
          enabled: enableProvider,
          feeSchedule: [
            ...depositFeeValues?.map(data => ({
              fee: {
                fixedFee: Number(data?.fixedFee),
                variableFee: Number(data?.variableFee),
              },
              feeType: data?.feeType,
              operationType: 'DEPOSIT',
            })),
            ...withdrawalFeeValues?.map(data => ({
              fee: {
                fixedFee: Number(data?.fixedFee),
                variableFee: Number(data?.variableFee),
              },
              feeType: data?.feeType,
              operationType: 'WITHDRAWAL',
            })),
          ],
          deposit: {
            enabled: values.allowedDeposit,
            maxDepositAmount: Number(values.maxDepositAmount),
            minDepositAmount: Number(values.minDepositAmount),
            maxNumberOfDepositPerDay: Number(values.depositADay),
            limit: {
              daily: Number(values.dailyLimit),
              weekly: Number(values.weeklyLimit),
              monthly: Number(values.monthlyLimit),
            },
          },
          withdrawal: {
            enabled: values.allowedWithdrawal,
            maxWithdrawalAmount: Number(values.maxWithdrawalAmount),
            minWithdrawalAmount: Number(values.minWithdrawalAmount),
            maxNumberOfWithdrawalPerDay: Number(values.withdrawalDepositADay),
            limit: {
              daily: Number(values.dailyWithdrawalLimit),
              weekly: Number(values.weeklyWithdrawalLimit),
              monthly: Number(values.monthlyWithdrawalLimit),
            },
          },
        },
      ],
    },
  })
    .then(({data: {updatePaymentProviderCurrencySettings}}) => {
      if (updatePaymentProviderCurrencySettings.__typename === 'Error') {
        toast.error(updatePaymentProviderCurrencySettings.message);
      } else {
        toast.success(`${selectedCurrency} Updated Successfully.`);
        navigate(-1);
      }
    })
    .catch(error => {
      toast.error(error);
    });
};

export const generateDailyLimitValidator =
  (weeklyLimit, monthlyLimit) => (_, value, callback) => {
    if (value >= weeklyLimit || value >= monthlyLimit) {
      callback('Limit cannot be greater than weekly or monthly limit');
    } else {
      callback(); // Validation passed
    }
  };

export const generateWeeklyLimitValidator =
  (weeklyLimit, monthlyLimit) => (_, value, callback) => {
    if (value <= weeklyLimit || value >= monthlyLimit) {
      callback(
        'Weekly Limit Cannot be Greater than Monthly Limit or Less than Daily Limit',
      );
    } else {
      callback(); // Validation passed
    }
  };

export const generateMonthlyLimitValidator =
  (weeklyLimit, monthlyLimit) => (_, value, callback) => {
    if (value <= weeklyLimit || value <= monthlyLimit) {
      callback('Monthly Limit Cannot be less than Weekly & Daily Limit');
    } else {
      callback(); // Validation passed
    }
  };
