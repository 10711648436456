import {useState} from 'react';
import {ArrowLeft} from 'icons';
import {Form, Select, Input, Spin} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import UploadImage from 'components/Startup/profile/companyImage';
import {useMutation, useQuery} from '@apollo/client';
import plusIcon from 'images/plus-circle.png';
import styles from './topicList.module.scss';
import deleteIcon from 'images/delete.png';
import {useNavigate} from 'react-router-dom';
import FadeEffect from 'components/animation/fadeEffect';
import {GET_ALL_STARTUPS} from 'graphql/queries/startupCompanies';
import {CREATE_TOPIC_LIST} from 'graphql/mutations/interest';
import {toast} from 'react-toastify';
import {GET_ALL_TOPIC_LIST} from 'graphql/queries/interest';

const TopicList = () => {
  const [icon, setIcon] = useState('');
  const [iconPreviewUrl, setIconPreviewUrl] = useState('');
  const [bannerImg, setBannerImg] = useState('');
  const [bannerPreviewUrl, setBannerPreviewUrl] = useState('');
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [sequence, setSequence] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [opportunities, setOpportunities] = useState([]);

  const navigate = useNavigate();
  const fieldWidth = 720;
  const {Option} = Select;

  const {data, loading} = useQuery(GET_ALL_STARTUPS);

  const handleChangeCompany = (i, e) => {
    const newFormValues = [...companies];
    newFormValues[i].company = e.split(';')[0];
    newFormValues[i].id = e.split(';')[1];
    setCompanies(newFormValues);
  };

  const handleChangeOpportunity = (i, e) => {
    const newFormValues = [...opportunities];
    newFormValues[i].oppportunity = e.split(';')[0];
    newFormValues[i].id = e.split(';')[1];
    setOpportunities(newFormValues);
  };

  const handleChangeCompanySequence = (i, e) => {
    const newFormValues = [...companies];
    newFormValues[i][e.target.name] = e.target.value;
    setCompanies(newFormValues);
  };

  const handleChangeOpportunitySequence = (i, e) => {
    const newFormValues = [...opportunities];
    newFormValues[i][e.target.name] = e.target.value;
    setOpportunities(newFormValues);
  };

  const handleAddCompanyTopic = () => {
    setCompanies([
      ...companies,
      {
        id: '',
        company: '',
        sequence: 0,
      },
    ]);
  };

  const handleAddOpportunityTopics = () => {
    setOpportunities([
      ...opportunities,
      {
        id: '',
        oppportunity: '',
        sequence: 0,
      },
    ]);
  };

  const handleDeleteCompany = id => {
    const filteredFormValue = companies.filter((data, index) => index !== id);
    setCompanies(filteredFormValue);
  };

  const handleDeleteOpportunity = id => {
    const filteredFormValue = opportunities.filter(
      (data, index) => index !== id,
    );
    setOpportunities(filteredFormValue);
  };

  const [createTopicList, {loading: loadingCreation}] =
    useMutation(CREATE_TOPIC_LIST);

  const handleSave = () => {
    createTopicList({
      variables: {
        input: {
          name,
          description,
          sequence: parseInt(sequence),
          icon,
          bannerImg,
          listType: type,
          companies: companies?.map(data => ({
            company: data?.id,
            sequence: parseInt(data?.sequence),
          })),
          opportunities: opportunities?.map(data => ({
            opportunity: data?.id,
            sequence: parseInt(data?.sequence),
          })),
        },
      },
      refetchQueries: [{query: GET_ALL_TOPIC_LIST}],
    })
      .then(({data: {createTopicList}}) => {
        if (createTopicList.__typename === 'Error') {
          toast.error(createTopicList.message);
        } else {
          toast.success('Topic List Created Successfully');
          navigate('/dashboard/interest/list');
        }
      })
      .catch(error => toast.error(error));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button onClick={() => navigate(-1)}>
          <ArrowLeft />
        </button>
        <h1>Add Topic List</h1>
      </div>
      <div className={styles['inner-container']}>
        <div className={styles.section}>
          <h2 className={styles['sub-heading']}>Topic Icon</h2>
          <UploadImage
            previewUrl={iconPreviewUrl}
            setPreviewUrl={setIconPreviewUrl}
            setImgUrl={setIcon}
            bucket="company-service-bucket"
          />
        </div>
        {/* Interest Banner */}
        <div className={styles.section}>
          <h2 className={styles['sub-heading']}>Topic Banner</h2>
          <UploadImage
            previewUrl={bannerPreviewUrl}
            setPreviewUrl={setBannerPreviewUrl}
            setImgUrl={setBannerImg}
            large
            bucket="company-service-bucket"
          />
        </div>
        <Form onFinish={handleSave} layout="vertical">
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Name is required',
              },
            ]}
            label="Name"
            className={styles['sub-heading']}
            name="name">
            <Input
              style={{width: fieldWidth}}
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder={'Name'}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: false,
              },
            ]}
            label="About Topic"
            className={styles['topic-width']}
            name="about topic">
            <Input.TextArea
              value={description}
              onChange={e => setDescription(e.target.value)}
              rows={6}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Category is required',
              },
            ]}
            label="List Type"
            className={styles['topic-width']}
            name="category">
            <Select value={type} onChange={e => setType(e)}>
              <Option value="COMPANIES">Companies</Option>
              <Option value="OPPORTUNITIES">Opportunities</Option>
              <Option value="HYBRID">Hybrid</Option>
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: false,
              },
            ]}
            label="Sequence"
            className={styles['topic-width']}
            name="sequence">
            <Input
              value={sequence}
              className={styles['input-number']}
              type="number"
              onChange={e => setSequence(e.target.value)}
            />
          </Form.Item>
          {type === 'COMPANIES' || type === 'HYBRID' ? (
            <div>
              <h1>Topic Companies</h1>
              {companies?.map((value, index) => (
                // eslint-disable-next-line react/jsx-key
                <FadeEffect>
                  <div className={styles['topic-lists']} key={index}>
                    <Form.Item style={{width: 320}} label="Company">
                      <Select
                        value={value.company}
                        onChange={e => handleChangeCompany(index, e)}
                        placeholder="Select Company"
                        showSearch>
                        {data?.getAllCompanies?.map(
                          ({id, legalName, logoImgURL}) => (
                            <Option key={`${legalName};${id}`}>
                              <div className={styles['all-data']}>
                                <img src={logoImgURL} alt="" />
                                <span>{legalName}</span>
                              </div>
                            </Option>
                          ),
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item style={{width: 320}} label="Sequence">
                      <Input
                        type={'number'}
                        name="sequence"
                        placeholder="Seqeunce"
                        value={value.sequence}
                        onChange={e => handleChangeCompanySequence(index, e)}
                      />
                    </Form.Item>
                    <div className={styles.delete}>
                      <img
                        onClick={() => handleDeleteCompany(index)}
                        src={deleteIcon}
                        alt=""
                      />
                    </div>
                  </div>
                </FadeEffect>
              ))}
              <div
                onClick={handleAddCompanyTopic}
                className={styles['add-button']}>
                <img src={plusIcon} alt="" />
                <h1>Add Company</h1>
              </div>
            </div>
          ) : (
            ''
          )}
          {type === 'OPPORTUNITIES' || type === 'HYBRID' ? (
            <div className={styles.opportunity}>
              <h1>Topic Opportunities</h1>
              {opportunities?.map((value, index) => (
                // eslint-disable-next-line react/jsx-key
                <FadeEffect>
                  <div className={styles['topic-lists']} key={index}>
                    <Form.Item style={{width: 320}} label="Opportunity">
                      <Select
                        value={value.company}
                        onChange={e => handleChangeOpportunity(index, e)}
                        showSearch
                        placeholder="Select Opportunity">
                        {data?.getAllCompanies?.map(
                          ({id, legalName, logoImgURL}) => (
                            <Option key={`${legalName};${id}`}>
                              <div className={styles['all-data']}>
                                <img src={logoImgURL} alt="" />
                                <span>{legalName}</span>
                              </div>
                            </Option>
                          ),
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item style={{width: 320}} label="Sequence">
                      <Input
                        type={'number'}
                        name="sequence"
                        placeholder="Seqeunce"
                        value={value.sequence}
                        onChange={e =>
                          handleChangeOpportunitySequence(index, e)
                        }
                      />
                    </Form.Item>
                    <div className={styles.delete}>
                      <img
                        onClick={() => handleDeleteOpportunity(index)}
                        src={deleteIcon}
                        alt=""
                      />
                    </div>
                  </div>
                </FadeEffect>
              ))}
              <div
                onClick={handleAddOpportunityTopics}
                className={styles['add-button']}>
                <img src={plusIcon} alt="" />
                <h1>Add Opportunity </h1>
              </div>
            </div>
          ) : (
            ''
          )}
          <SubmitButton disabled={loadingCreation} label="Create Topic List" />
        </Form>
      </div>
    </div>
  );
};

export default TopicList;
