import {useMutation, useQuery} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {Dropdown, Table, Tag} from 'antd';
import {GET_STOCK_EXCHANGE_DOCUMENTS} from 'graphql/queries/stocks';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import dotsIcon from 'icons/dots.svg';
import moment from 'moment';
import styles from './create/createStockExchnageDocument.module.scss';
import {DELETE_STOCK_EXCHANGE_DOCUMENT} from 'graphql/mutations/stocks';
import {useState} from 'react';
import DBModal from 'components/modal/modal';
import {NavTabsEnum} from 'utils/constants';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';

const StockListingDocuments = () => {
  const [openModal, setOpenModal] = useState({});
  const navigate = useNavigate();
  const {data, loading, refetch} = useQuery(GET_STOCK_EXCHANGE_DOCUMENTS, {
    errorPolicy: 'all',
    onError: () =>
      toast.error('There was an error when fetching the listing documents'),
  });

  const [deleteOneStockExchangeDocument, {loading: loadingDelete}] =
    useMutation(DELETE_STOCK_EXCHANGE_DOCUMENT, {
      errorPolicy: 'all',
    });

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.StockListings),
  );

  const handleDelete = () => {
    deleteOneStockExchangeDocument({
      variables: {
        documentId: openModal?.id,
      },
    })
      .then(({data: {deleteOneStockExchangeDocument}}) => {
        if (deleteOneStockExchangeDocument.__typename === 'Error') {
          toast.error(
            `Something went wrong!!! - ${deleteOneStockExchangeDocument.message} `,
          );
        } else {
          toast.success('Deleted Successfully');
          setOpenModal({isOpen: false});
          refetch();
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err}`));
  };

  const columns = [
    {
      title: 'Name',
      key: 'documentName',
      dataIndex: 'documentName',
    },
    {
      title: 'Category',
      key: 'documentCategory',
      dataIndex: 'documentCategory',
      render: (text, record) => (
        <Tag color="blue">{record?.documentCategory}</Tag>
      ),
    },
    {
      title: 'Issued Date',
      key: 'issuedDate',
      dataIndex: 'issuedDate',
      render: (text, record) => (
        <span>
          {record?.issuedDate
            ? moment(record?.issuedDate).format('DD MMMM, YYYY h:mm A')
            : 'N/A'}
        </span>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (text, record) => (
        <Dropdown
          menu={{
            items: [
              {
                key: 1,
                label: 'View / Update',
                disabled: !tabOperations?.update,
                onClick: () =>
                  navigate(`/dashboard/stocks-listing/update/${record?.id}`),
              },
              {
                key: 2,
                label: 'Delete',
                disabled: !tabOperations?.delete,
                onClick: () => setOpenModal({isOpen: true, id: record?.id}),
              },
            ],
          }}
          trigger="hover">
          <div>
            <img src={dotsIcon} alt="actions" />
          </div>
        </Dropdown>
      ),
    },
  ];
  return (
    <div>
      <div className={styles.button}>
        <Button
          onClick={() => navigate('/dashboard/stocks-listing/create')}
          label="Create Stock Exchange Document"
          type="secondary"
          disabled={!tabOperations?.create}
        />
      </div>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data?.getStockExchangeDocument?.data}
      />
      <DBModal
        isOpen={openModal.isOpen}
        handleClose={() => setOpenModal({isOpen: false})}
        content={
          <div className={styles.modal}>
            <h1>DELETE DOCUMENT</h1>
            <span>Are you sure you want to delete this document</span>

            <div className={styles.buttons}>
              <Button
                disabled={loadingDelete}
                label="Yes"
                onClick={handleDelete}
              />
              <Button
                type="secondary"
                label="No"
                onClick={() => setOpenModal({isOpen: false})}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default StockListingDocuments;
