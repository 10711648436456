/* eslint-disable no-undef */
import {Form, Input, Select, Tabs} from 'antd';
import backIcon from 'images/back.png';

import styles from './createWebsiteNews.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import ReactQuill from 'react-quill';
import {defaultLanguages, websiteNewsTypes} from '../newsTypes';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
  onUpdateWebsiteNews,
  requestNewsTranslation,
} from 'redux/store/news/actions';
import {useMutation} from '@apollo/client';
import {fieldRules} from 'utils/helper';
import {useEffect, useState} from 'react';
import NewsTranslation from '../translation/NewsTranslation';
import {handleWebsiteTranslation} from 'redux/store/news';
import {useDispatch} from 'react-redux';
import {Button} from '@dabafinance/react-components';
import {UPDATE_WEBSITE_NEWS} from 'redux/store/news/queries';
import {QUILL_FORMATS, QUILL_MODULES} from 'utils/constants';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {TLDREdit} from 'components/tldrEdit';

const WebsiteNewsContents = ({storedNewsData}) => {
  const [newsState, setNewsState] = useState({
    title: '',
    content: '',
    keyTake: '',
    slug: '',
    imageAltText: '',
    websiteType: '',
    keywords: [],
    metaDescription: '',
    baseLang: '',
    translateLanguage: '',
    summary: ['', '', ''],
  });
  const {id: updateId, lang} = useParams();
  const [translatedNews, setTranslatedNews] = useState([]);
  const {
    title,
    baseLang,
    content,
    websiteType,
    keywords,
    imageAltText,
    keyTake,
    slug,
    translateLanguage,
    metaDescription,
    summary,
  } = newsState;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {Option} = Select;
  const handleChangeNewsState = payload => {
    setNewsState(states => ({...states, ...payload}));
  };

  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );

  const [updateWebsiteNews, {loading: loadingUpdate}] =
    useMutation(UPDATE_WEBSITE_NEWS);

  const handleMoveToNextPage = async () => {
    if (!translatedNews.length) return toast.error('Please Translate the News');
    if (slug === translatedNews[0].slug) {
      return toast.error(
        'Translated slug cannot be the same as the Original Slug, Please Update!!!',
      );
    }
    dispatch(
      handleWebsiteTranslation({
        baseLang,
        content,
        title,
        newsType: websiteType,
        slug,
        metaDescription,
        imageAltText,
        keywords,
        keyTake,
        summary,
        translation: translatedNews,
      }),
    );
    navigate('/dashboard/news/create-website-news-two');
  };

  useEffect(() => {
    if (storedNewsData?.newsTitle) {
      handleChangeNewsState({
        title: storedNewsData?.newsTitle,
        baseLang: storedNewsData?.baseLanguage,
        keyTake: storedNewsData?.newsKeyTake,
        content: storedNewsData?.newsContent,
        imageAltText: storedNewsData?.imageAltText,
        slug: storedNewsData?.slug,
        keywords: storedNewsData?.keywords?.map(name => name),
        metaDescription: storedNewsData?.metaDescription,
        websiteType: storedNewsData?.newsType,
        summary: storedNewsData?.summary?.length
          ? storedNewsData?.summary
          : ['', '', ''],
      });
      setTranslatedNews(storedNewsData?.translation ?? []);
    }
  }, [storedNewsData]);

  const handleUpdate = async () => {
    // Block trying to go to update and you're not on the update page
    if (!translatedNews.length && !updateId) {
      return toast.error('Please translate News Content');
    }
    const updateProps = {
      ...newsState,
      translatedNews,
      navigate,
    };
    try {
      await onUpdateWebsiteNews(updateWebsiteNews, updateProps, updateId);
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  const handleChangeTranslatedValues = (key, event, name) => {
    let eventName = name || event.target.name;
    let eventValue = name ? event : event.target.value;
    if (eventName === 'slug') {
      eventValue.replace(/\s+/g, '-').replace(/[^-a-zA-Z0-9]/g, '');
    }
    const newFormValues = [...translatedNews];
    newFormValues[key][eventName] = eventValue;
    setTranslatedNews(newFormValues);
  };

  const languageToBeTranslatedTo = defaultLanguages.filter(
    key => key.key !== baseLang,
  );

  const handleTranslation = async () => {
    const newValues = [...translatedNews];

    if (
      !baseLang &&
      !title &&
      !keyTake &&
      !content &&
      !websiteNewsTypes &&
      !translateLanguage &&
      summary?.some(val => !val)
    ) {
      return toast.error(
        'Please make sure none of the necessary fields needed for translation are empty',
      );
    }

    const translateProps = {
      sourceLang: baseLang,
      targetLang: translateLanguage,
      targetText1: title,
      targetText2: content,
      targetText3: keyTake,
      targetText4: metaDescription,
      targetText6: imageAltText,
      translateKeywords: keywords,
      translateSlug: slug,
      translateSummaries: summary,
      websiteType,
    };
    if (newValues.length && newValues[0]) {
      const languageExists = newValues.some(
        data => data?.language === translateLanguage,
      );
      if (languageExists) {
        const updateTranslation = await requestNewsTranslation(
          translateText,
          translateProps,
        );
        const translationIndex = newValues.findIndex(
          data => data.language === translateLanguage,
        );
        newValues[translationIndex].title = updateTranslation.title;
        newValues[translationIndex].content = updateTranslation.content;
        newValues[translationIndex].language = updateTranslation.language;
        newValues[translationIndex].keyTake = updateTranslation.keyTake;
        newValues[translationIndex].slug = updateTranslation.slug
          .replace(/\s+/g, '-')
          .replace(/[^-a-zA-Z0-9]/g, '');
        newValues[translationIndex].keywords = updateTranslation.keywords;
        newValues[translationIndex].imageAltText =
          updateTranslation.imageAltText;
        newValues[translationIndex].metaDescription =
          updateTranslation.metaDescription;
        newValues[translationIndex].type = updateTranslation.type;
        newValues[translationIndex].summary = updateTranslation.summary;
        setTranslatedNews(newValues);
      }
    } else {
      const triggerNewTranslation = await requestNewsTranslation(
        translateText,
        translateProps,
      );
      if (!triggerNewTranslation) {
        return toast.error('Something went wrong when Translating');
      }
      newValues.push(triggerNewTranslation);
      setTranslatedNews(newValues);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.nav}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>{updateId ? 'Update' : 'Create'} Website News (1/2)</h1>
      </div>
      <div className={styles.content}>
        <h2>News Contents & Translation(s)</h2>
        <Form
          layout="vertical"
          onFinish={updateId ? handleUpdate : handleMoveToNextPage}>
          <Form.Item
            className={styles.largeWidth}
            label="Base Language"
            name="language"
            initialValue={storedNewsData?.baseLanguage}
            rules={[fieldRules('Language')]}>
            <Select
              value={baseLang}
              defaultValue={storedNewsData?.baseLanguage}
              onChange={e => handleChangeNewsState({baseLang: e})}
              placeholder="Select a base language">
              {defaultLanguages.map(data => (
                <Option key={data.key}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[fieldRules('Website Type')]}
            label="Website Type"
            initialValue={storedNewsData?.newsType}
            className={styles.largeWidth}
            name="types">
            <Select
              value={websiteType}
              defaultValue={websiteType}
              onChange={e => handleChangeNewsState({websiteType: e})}
              placeholder="Select a type for this News"
              className={styles['select-height']}>
              {websiteNewsTypes.map(data => (
                <Option key={data.key}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Translation Language"
            rules={[fieldRules('Language to translate contents to')]}
            initialValue={
              storedNewsData?.translation?.length
                ? storedNewsData?.translation[0]?.language
                : ''
            }
            className={styles.largeWidth}
            name="translatedLanguage">
            <Select
              value={translateLanguage}
              placeholder="Select a Language"
              defaultValue={
                storedNewsData?.translation?.length
                  ? storedNewsData?.translation[0]?.language
                  : ''
              }
              onChange={e => handleChangeNewsState({translateLanguage: e})}
              name="type">
              {languageToBeTranslatedTo.map(name => (
                <Option key={name.key}>{name.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Tabs
            tabBarExtraContent={
              translateLanguage && (
                <Button
                  label="Translate"
                  disabled={
                    loadingTranslation ||
                    !title ||
                    !content ||
                    !keyTake ||
                    newsState.summary?.some(val => !val)
                  }
                  onClick={() => handleTranslation()}
                />
              )
            }
            style={{maxWidth: 800}}>
            <Tabs.TabPane key="Original" tab="Original">
              <Form.Item
                className={styles.largeWidth}
                label="Title  (Max: 70 Characters)"
                initialValue={storedNewsData?.newsTitle}
                name="websiteTitle"
                rules={[fieldRules('Title'), {max: 70, required: true}]}>
                <Input
                  value={title}
                  defaultValue={storedNewsData?.newsTitle}
                  onChange={e => handleChangeNewsState({title: e.target.value})}
                  placeholder="News Title"
                />
              </Form.Item>
              <Form.Item
                className={styles.largeWidth}
                label="Website Slug - Separate by text with (-) - Max: 70 Characters"
                initialValue={storedNewsData?.slug}
                name="websiteSlug"
                rules={[
                  fieldRules('Slug'),
                  {max: 70},
                  {
                    validator: async (_, value) => {
                      const slugPattern = /^[a-zA-Z0-9-]+$/;

                      if (value && !slugPattern.test(value)) {
                        return Promise.reject(
                          'Invalid slug format. Please use letters, numbers, hyphens only as spaces and avoid whitespace.',
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}>
                <Input
                  value={slug
                    .replace(/\s+/g, '-')
                    .replace(/[^-a-zA-Z0-9]/g, '')}
                  defaultValue={storedNewsData?.slug}
                  onChange={e => {
                    const sanitizedSlug = e.target.value
                      .replace(/\s+/g, '-')
                      .replace(/[^-a-zA-Z0-9]/g, '');

                    handleChangeNewsState({slug: sanitizedSlug});
                  }}
                  placeholder="News Slug - Please avoid spaces and special Characters e.g finance-in-africa-and-us"
                />
              </Form.Item>
              <Form.Item
                className={styles.largeWidth}
                label="Meta Description"
                initialValue={storedNewsData?.metaDescription}
                name="metaDescription"
                rules={[fieldRules('Meta Description')]}>
                <Input
                  value={metaDescription}
                  defaultValue={storedNewsData?.metaDescription}
                  onChange={e =>
                    handleChangeNewsState({metaDescription: e.target.value})
                  }
                  placeholder="Type in the News Meta Description (This is like a very short summary of the news)"
                />
              </Form.Item>
              <Form.Item
                className={styles.largeWidth}
                label="Image Alt Text"
                initialValue={storedNewsData?.imageAltText}
                name="imageAltText"
                rules={[fieldRules('Image Alternate Text')]}>
                <Input
                  value={imageAltText}
                  defaultValue={storedNewsData?.imageAltText}
                  onChange={e =>
                    handleChangeNewsState({imageAltText: e.target.value})
                  }
                  placeholder="Type in the News Image Alt Text."
                />
              </Form.Item>

              <TLDREdit
                content={content}
                summary={newsState.summary}
                onChangeSummaries={summary => handleChangeNewsState({summary})}
              />

              <div className={styles['quill-container']}>
                <span>News Content</span>
                <ReactQuill
                  value={content}
                  modules={WebsiteNewsContents.modules}
                  formats={WebsiteNewsContents.format}
                  defaultValue={storedNewsData?.newsContent}
                  onChange={e => handleChangeNewsState({content: e})}
                  placeholder="Type the news content in details"
                />
              </div>
              <div className={styles['quill-container']}>
                <span>Key Takeaway</span>
                <ReactQuill
                  className={styles.quill}
                  theme="snow"
                  defaultValue={storedNewsData?.newsKeyTake}
                  modules={WebsiteNewsContents.modules}
                  formats={WebsiteNewsContents.format}
                  onChange={el => handleChangeNewsState({keyTake: el})}
                  placeholder="What are the key Takeaways"
                  value={keyTake}
                />
              </div>
              <Form.Item
                rules={[fieldRules('Keywords')]}
                label="Keywords"
                initialValue={storedNewsData?.keywords?.map(name => name)}
                className={styles.largeWidth}
                name="keywords">
                <Select
                  showSearch
                  value={keywords}
                  placeholder="Keywords are separated by Enter keyword"
                  onChange={e => handleChangeNewsState({keywords: e})}
                  mode="tags"
                  defaultValue={storedNewsData?.keywords?.map(name => name)}
                  className={styles['select-height']}
                />
              </Form.Item>
            </Tabs.TabPane>
            {translatedNews?.map((item, index) => (
              <Tabs.TabPane
                key={index}
                tab={
                  languageToBeTranslatedTo.find(
                    lang => lang?.key === item?.language,
                  )?.label
                }>
                <NewsTranslation
                  fieldKey={index}
                  onChangeFields={handleChangeTranslatedValues}
                  loading={loadingTranslation}
                  data={item}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
          <SubmitButton
            disabled={loadingUpdate}
            smallWidth
            label={updateId ? 'Update' : 'Page 2/2'}
          />
          {updateId && (
            <Button
              className={styles.nextPageButton}
              onClick={() =>
                navigate(
                  `/dashboard/news/update-website-news-two/${updateId}/${lang}`,
                )
              }
              label="Next Page"
            />
          )}
        </Form>
      </div>
    </div>
  );
};

WebsiteNewsContents.modules = QUILL_MODULES;

WebsiteNewsContents.format = QUILL_FORMATS;

export default WebsiteNewsContents;
