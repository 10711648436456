import gql from 'graphql-tag';

export const ADD_A_TRENDING_STARTUP = gql`
  mutation AddTrendingCompany($companyId: ID!) {
    addTrendingCompany(companyId: $companyId) {
      ... on Error {
        message
        statusCode
      }
      ... on TrendingCompany {
        id
      }
    }
  }
`;

export const REMOVE_TRENDING_COMPANY = gql`
  mutation RemoveTrendingCompany($companyId: ID) {
    removeTrendingCompany(companyId: $companyId) {
      ... on Error {
        message
        statusCode
      }
      ... on TrendingCompany {
        id
        isTrending
      }
    }
  }
`;
