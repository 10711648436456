import {useState} from 'react';
import {Button, Search} from '@dabafinance/react-components';
import styles from './startup.module.scss';
import {Table, Spin} from 'antd';
import {useNavigate} from 'react-router-dom';
import DeviceUpload from './profile/DeviceUpload';
import StartupCounts from './components/startupCount';
import {useQuery} from '@apollo/client';
import DBModal from 'components/modal/modal';
import ViewCompanyRecommendation from './components/recommendation/ViewCompanyRecommendation';
import {
  GET_ALL_COMPANIES,
  GET_ALL_COMPANY_RECOMMENDATIONS,
} from 'graphql/queries/company';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const Startup = () => {
  const [companyData, setCompanyData] = useState([]);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [searchItem, setSearchItem] = useState('');
  const [openViewModal, setOpenViewModal] = useState(false);
  const [page, setPage] = useState(1);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);

  const pageOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Startups),
  );

  const {data, loading: loadingRecommendations} = useQuery(
    GET_ALL_COMPANY_RECOMMENDATIONS,
  );
  const {data: company, loading} = useQuery(GET_ALL_COMPANIES, {
    variables: {
      filters: {
        pagination: {
          limit: 20,
          page,
        },
        company: {
          name: searchItem,
        },
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setCompanyData(data?.getAllAdminCompanies?.companies);
      setHasPrev(data?.getAllAdminCompanies?.hasPrevPage);
      setHasNext(data?.getAllAdminCompanies?.hasNextPage);
    },
    onError: error => toast.error(error),
  });

  const amountFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <img src={record.logoImgURL} alt="" />
          <h4 style={{width: 100}}>{record.name}</h4>
        </div>
      ),
    },
    {
      title: 'Tiker',
      dataIndex: 'ticker',
      key: 'tiker',
    },
    {
      title: 'Funded',
      dataIndex: 'totalCapitalRaised',
      key: 'amount',
      render: (text, record) => (
        <span>{amountFormatter.format(record.totalCapitalRaised)}</span>
      ),
    },
    {
      title: 'Funding Type',
      dataIndex: 'stage',
      key: 'stage',
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <Button
          onClick={() =>
            navigate(`/dashboard/opportunities/startups/profile/${record.id}`)
          }
          disabled={!pageOperations?.view}
          type="secondary"
          label="View details"
        />
      ),
    },
  ];

  if (loadingRecommendations) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles['container-wrapper']}>
      <div className={styles['header-container']}>
        <h1>Companies</h1>
        <div className={styles['left-buttons']}>
          {/*

          TODO: awaiting backend functionality

          <Button
            onClick={() => setOpenModal(true)}
            type='secondary'
            className={styles['header-button']}
            label={'Upload from spreadsheet'}
          /> */}
          <Button
            label={'Add a Company'}
            className={styles['header-button']}
            disabled={!pageOperations?.create}
            onClick={() =>
              navigate('/dashboard/opportunities/startups/create-profile')
            }
          />
        </div>
      </div>
      <div className={styles['startup-counts']}>
        <StartupCounts
          active
          value={company?.getAllAdminCompanies?.statistics?.totalCompanies}
          text={'Total Number of Companies'}
        />
        <StartupCounts value={0} text={'Active campaigns'} />
        <StartupCounts value={0} text={'All Companies Listed'} />
        <div onClick={() => setOpenViewModal(true)}>
          <StartupCounts
            value={data?.getAllCompanyRecommendations?.length}
            text={'Recommended Companies'}
          />
        </div>
      </div>
      <DeviceUpload openModal={openModal} setOpenModal={setOpenModal} />

      <div className={styles['startup-table']}>
        <div className={styles['startup-header']}>
          <h1>Companies</h1>
          <div className={styles['nav-buttons']}>
            <Button
              onClick={() => setPage(page - 1)}
              label="Previous"
              type="secondary"
              disabled={!hasPrev || !!searchItem || loading}
              className={styles.button}
            />
            <div className={styles['page-number']}>{page}</div>
            <Button
              onClick={() => setPage(page + 1)}
              label="Next"
              disabled={!hasNext || !!searchItem || loading}
              className={styles.button}
            />
          </div>
          <div>
            <Search
              className={styles['search-bar']}
              id={''}
              placeholder="Name, Ticker, Funding Type"
              onChange={e => setSearchItem(e)}
            />
          </div>
        </div>
        <div className={styles['table-height']}>
          <div>
            <Table
              dataSource={companyData}
              loading={loading}
              columns={columns}
              pagination={false}
            />
          </div>
        </div>
        <div>
          <DBModal
            isOpen={openViewModal}
            handleClose={() => setOpenViewModal(false)}
            content={
              <div>
                <ViewCompanyRecommendation
                  data={data?.getAllCompanyRecommendations}
                />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Startup;
