import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styles from './campaignInvestors.module.scss';
import {Form, Select, Spin} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import plusIcon from 'images/plus-circle.png';
import {useQuery} from '@apollo/client';
import FadeEffect from 'components/animation/fadeEffect';
import {GET_ALL_STARTUPS} from 'graphql/queries/startupCompanies';
import {useDispatch, useSelector} from 'react-redux';
import {coInvestors} from 'redux/store/campaign';
import {CoInvestorsCard} from './coInvestorsCard';
import {
  resizeImageFile,
  UploadDocumentToBucket,
} from '../legalDocument/uploadDocument';

const CampaignInvestors = () => {
  const [competitors, setCompetitors] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [coInvestorsValues, setCoInvestorsValues] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id, companyId} = useParams();
  const {Option} = Select;
  const width = 730;

  const campaign = useSelector(state => state.campaign.campaign);

  useEffect(() => {
    if (campaign?.coInvestors != undefined) {
      setCoInvestorsValues(
        campaign.coInvestors.map(data => ({
          name: data?.name,
          title: data?.title,
          description: data?.description,
          imageUrl: data?.imageUrl,
          previewUrl: data?.previewUrl,
          otherInvestments: data?.otherInvestments,
        })),
      );
    }
    if (campaign?.competitors != undefined) {
      setCompetitors(
        campaign?.competitors?.map(
          data =>
            `${data.split(';')[0]};${data.split(';')[1]};${data.split(';')[2]}`,
        ),
      );
    }
  }, [campaign?.coInvestors]);

  useEffect(() => {
    if (coInvestorsValues.length) {
      coInvestorsValues?.map(data => {
        if (!data.name || !data.description || !data.title || !data.imageUrl) {
          return setDisableButton(true);
        } else {
          return setDisableButton(false);
        }
      });
    } else {
      setDisableButton(false);
    }
  }, [coInvestorsValues]);

  const {data: getAllCompanies, loading: loadingCompany} =
    useQuery(GET_ALL_STARTUPS);

  const handleAddCoInvestor = () => {
    setCoInvestorsValues([
      ...coInvestorsValues,
      {
        name: '',
        title: '',
        description: '',
        imageUrl: '',
        previewUrl: '',
        otherInvestments: [],
      },
    ]);
  };

  const handleChangeFields = (i, e) => {
    const newFormValues = [...coInvestorsValues];
    newFormValues[i][e.target.name] = e.target.value;
    setCoInvestorsValues(newFormValues);
  };

  const handleChangeImage = async (i, {file}) => {
    const temp = file.originFileObj;
    const newFormValues = [...coInvestorsValues];
    const resizedFile = await resizeImageFile(temp);
    const res = await UploadDocumentToBucket(resizedFile);
    newFormValues[i].previewUrl = res;
    newFormValues[i].imageUrl = res;
    setCoInvestorsValues(newFormValues);
  };

  const handleOtherInvestments = (i, e) => {
    const newFormValues = [...coInvestorsValues];
    newFormValues[i].otherInvestments = e;
    setCoInvestorsValues(newFormValues);
  };

  const handleDeleteImage = i => {
    const newFormValues = [...coInvestorsValues];
    newFormValues[i].previewUrl = '';
    newFormValues[i].imageUrl = '';
    setCoInvestorsValues(newFormValues);
  };

  const handleDeleteCoInvestor = id => {
    const filteredFormValue = coInvestorsValues.filter(
      (data, index) => index !== id,
    );
    setCoInvestorsValues(filteredFormValue);
  };

  const handleSave = () => {
    dispatch(
      coInvestors({
        coInvestors: coInvestorsValues,
        competitors,
      }),
    );
    navigate(`/dashboard/campaigns/new/${id}/analogs/${companyId}`);
  };

  if (loadingCompany) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.container}>
        <h1 className={styles.title}>Co-Investors &#38; Competitors</h1>
        <Form onFinish={handleSave} layout="vertical">
          <h3 className={styles['label-title']}>Co-Investors</h3>
          <div>
            {coInvestorsValues?.map((data, index) => (
              // eslint-disable-next-line react/jsx-key
              <FadeEffect>
                <div key={index}>
                  <CoInvestorsCard
                    name={data.name || ''}
                    title={data.title || ''}
                    description={data.description || ''}
                    otherInvestments={data.otherInvestments || []}
                    previewImage={data.previewUrl || ''}
                    imgUrl={data.imageUrl || ''}
                    handleChange={e => handleChangeFields(index, e)}
                    handleOtherInvestments={e =>
                      handleOtherInvestments(index, e)
                    }
                    handleChangeImage={e => handleChangeImage(index, e)}
                    handleDeleteImage={() => handleDeleteImage(index)}
                    handleDeleteCoInvestor={e =>
                      handleDeleteCoInvestor(index, e)
                    }
                  />
                </div>
              </FadeEffect>
            ))}
          </div>

          <div onClick={handleAddCoInvestor} className={styles['add-button']}>
            <img src={plusIcon} alt="" />
            <h1>Add Investor</h1>
          </div>

          <h3 className={styles['label-title']}>Competitors</h3>
          <Form.Item style={{width}} name="competitors">
            <Select
              mode="multiple"
              defaultValue={campaign?.competitors?.map(
                data =>
                  `${data.split(';')[0]};${data.split(';')[1]};${
                    data.split(';')[2]
                  }`,
              )}
              value={competitors}
              onChange={e => setCompetitors(e)}>
              {getAllCompanies?.getAllCompanies?.map(
                ({name, logoImgURL, id}) => (
                  <Option key={`${name};${logoImgURL};${id}`}>
                    <div className={styles['all-data']}>
                      <img src={logoImgURL} alt="" />
                      <span>{name}</span>
                    </div>
                  </Option>
                ),
              )}
            </Select>
          </Form.Item>
          <SubmitButton disabled={disableButton} label="Save & Continue" />
        </Form>
      </div>
    </div>
  );
};

export default CampaignInvestors;
