import {useQuery} from '@apollo/client';
import {Spin} from 'antd';
import styles from '../learnArticle.module.scss';
import LearnArticleCard from '../learnArticleCard';
import {GET_INFORMATION_BY_ID} from 'graphql/queries/learnArticle';
import pageNotFound from 'images/pageNotFound.png';

const LearnArticleCards = ({id}) => {
  const {data, loading} = useQuery(GET_INFORMATION_BY_ID, {
    variables: {
      informationSectionId: id,
    },
  });

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.cards}>
      {data?.getInformationSectionById?.informationSection?.articles?.length >=
      1 ? (
        data?.getInformationSectionById?.informationSection?.articles.map(
          value => (
            <div key={value?.id}>
              <LearnArticleCard
                informationSection={
                  data?.getInformationSectionById?.informationSection?.title
                }
                title={value?.title}
                id={value?.id}
                image={value?.featuredImage}
                index={value?.id}
                date={value?.publishedDate}
              />
            </div>
          ),
        )
      ) : (
        <div className={styles['no-update']}>
          <img src={pageNotFound} alt="" />
          <h1>No Article Under This Information Section</h1>
          <span>Add a new Article</span>
        </div>
      )}
    </div>
  );
};

export {LearnArticleCards};
