import {Form, Input} from 'antd';
import styles from '../ventureFund.module.scss';

const VentureFundScore = ({campaign, score, handleChange}) => {
  return (
    <div className={styles.form}>
      <Form.Item
        className={styles['field-width']}
        label="ESG and Social Impact"
        name="eSGAndSocialImpact"
        rules={[
          {
            required: true,
            message: 'Required Field',
          },
        ]}
        initialValue={campaign?.ventureFundFields?.score?.eSGAndSocialImpact}>
        <Input
          defaultValue={campaign?.ventureFundFields?.score?.eSGAndSocialImpact}
          value={score.eSGAndSocialImpact}
          type="number"
          onChange={e => handleChange('eSGAndSocialImpact', e.target.value)}
        />
      </Form.Item>
      <Form.Item
        className={styles['field-width']}
        label="Fund Economics and Financials"
        name="fundEconomicsAndFinancials"
        rules={[
          {
            required: true,
            message: 'Required Field',
          },
        ]}
        initialValue={
          campaign?.ventureFundFields?.score?.fundEconomicsAndFinancials
        }>
        <Input
          defaultValue={
            campaign?.ventureFundFields?.score?.fundEconomicsAndFinancials
          }
          value={score.fundEconomicsAndFinancials}
          type="number"
          onChange={e =>
            handleChange('fundEconomicsAndFinancials', e.target.value)
          }
        />
      </Form.Item>
      <Form.Item
        className={styles['field-width']}
        label="Fund Manager"
        name="fundManager"
        rules={[
          {
            required: true,
            message: 'Required Field',
          },
        ]}
        initialValue={campaign?.ventureFundFields?.score?.fundManager}>
        <Input
          defaultValue={campaign?.ventureFundFields?.score?.fundManager}
          value={score.fundManager}
          type="number"
          onChange={e => handleChange('fundManager', e.target.value)}
        />
      </Form.Item>
      <Form.Item
        className={styles['field-width']}
        label="Fund Overview"
        name="fundOverview"
        rules={[
          {
            required: true,
            message: 'Required Field',
          },
        ]}
        initialValue={campaign?.ventureFundFields?.score?.fundOverview}>
        <Input
          defaultValue={campaign?.ventureFundFields?.score?.fundOverview}
          value={score.fundOverview}
          type="number"
          onChange={e => handleChange('fundOverview', e.target.value)}
        />
      </Form.Item>
      <Form.Item
        className={styles['field-width']}
        label="Legal and Compliance"
        name="legalAndCompliance"
        rules={[
          {
            required: true,
            message: 'Required Field',
          },
        ]}
        initialValue={campaign?.ventureFundFields?.score?.legalAndCompliance}>
        <Input
          defaultValue={campaign?.ventureFundFields?.score?.legalAndCompliance}
          value={score.legalAndCompliance}
          type="number"
          onChange={e => handleChange('legalAndCompliance', e.target.value)}
        />
      </Form.Item>
      <Form.Item
        className={styles['field-width']}
        label="Return Outlook"
        name="returnOutlook"
        rules={[
          {
            required: true,
            message: 'Required Field',
          },
        ]}
        initialValue={campaign?.ventureFundFields?.score?.returnOutlook}>
        <Input
          defaultValue={campaign?.ventureFundFields?.score?.returnOutlook}
          value={score.returnOutlook}
          type="number"
          onChange={e => handleChange('returnOutlook', e.target.value)}
        />
      </Form.Item>
    </div>
  );
};

export default VentureFundScore;
