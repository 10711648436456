import styles from './submitbutton.module.scss';
import classnames from 'classnames';

const SubmitButton = ({
  label,
  handleClick,
  disabled,
  smallWidth,
  type,
  secondary,
}) => {
  return (
    <button
      disabled={disabled}
      className={classnames(
        smallWidth
          ? styles['small-width']
          : secondary
          ? styles.secondary
          : styles.button,
        disabled && styles['db-disabled-btn'],
      )}
      type={type ?? 'submit'}
      onClick={handleClick}>
      {label}
    </button>
  );
};

export default SubmitButton;
