import {gql} from '@apollo/client';

export const GET_ALL_EMAIL_DISCLOSURES = gql`
  query Error($disclosureType: DisclosureTypeEnum, $limit: Int) {
    getAllEmailDisclosures(disclosureType: $disclosureType, limit: $limit) {
      ... on Error {
        message
        statusCode
      }
      ... on PaginatedDisclosure {
        page
        totalDocs
        hasPrevPage
        hasNextPage
        prevPage
        nextPage
        offset
        disclosures {
          id
          title
          content
          type
          language
        }
      }
    }
  }
`;

export const GET_EMAIL_DISCLOSURE = gql`
  query GetEmailDisclosure($disclosureId: ID!) {
    getEmailDisclosure(disclosureId: $disclosureId) {
      ... on Error {
        message
        statusCode
      }
      ... on EmailDisclosure {
        id
        title
        content
        type
        language
        multilang {
          title
          content
          language
        }
      }
    }
  }
`;
