import {Table} from 'antd';
import styles from './depositSettings.module.scss';
import {useQuery} from '@apollo/client';
import {GET_ALL_DEPOSIT_SETTINGS} from 'graphql/queries/depositSettings';
import dotsIcon from 'icons/dots.svg';
import {useNavigate} from 'react-router-dom';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';
import {useSelector} from 'react-redux';

const DepositSettings = () => {
  const {data, loading} = useQuery(GET_ALL_DEPOSIT_SETTINGS);
  const navigate = useNavigate();

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.DepositSettings),
  );

  const columns = [
    {
      dataIndex: 'currency',
      key: 'currency',
      title: 'Currency',
    },
    {
      dataIndex: 'provider',
      key: 'provider',
      title: 'Provider',
    },
    {
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      title: 'Payment Method Names',
    },
    {
      dataIndex: 'depositOptions',
      key: 'depositOptions',
      title: 'Deposit Options',
      render: (text, record) =>
        record?.amounts?.map(amount => `$${amount.toLocaleString()}, `),
    },
  ];

  if (tabOperations?.update) {
    columns.push({
      title: 'Update',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, render) => (
        <img
          src={dotsIcon}
          onClick={() =>
            navigate(`/dashboard/deposit-settings/update/${render?.id}`)
          }
        />
      ),
    });
  }

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <h1>Deposit Settings</h1>
        <SubmitButton
          label="Create"
          handleClick={() => navigate('/dashboard/deposit-settings/create')}
          disabled={!tabOperations?.create}
        />
      </div>
      <div>
        <Table
          loading={loading}
          dataSource={data?.getAllSuggestedAmounts?.UserSuggestedAmounts}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default DepositSettings;
