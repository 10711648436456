import {Form, Input, Select, Spin, Switch} from 'antd';
import ReactQuill from 'react-quill';
import styles from './newLearnArticle.module.scss';
import {QUILL_FORMATS, QUILL_MODULES} from 'utils/constants';
import {Button} from '@dabafinance/react-components';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import UploadImage from 'components/Startup/profile/companyImage';
import {UploadVideoToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import {BigPlayButton, Player} from 'video-react';
import FadeEffect from 'components/animation/fadeEffect';
import ReportPDF from 'components/reportPDF';
import {GET_ALL_INFORMATION_SECTION} from 'graphql/queries/learnArticle';
import {useQuery} from '@apollo/client';

const TranslatedArticleContent = ({
  title,
  fieldKey,
  onChangeFields,
  summary,
  loading,
  content,
  pdfLink,
  videoUrl,
  videoTitle,
  videoType,
  informationSectionMultilang,
  language,
}) => {
  const fieldWidth = 730;
  const [enableChangeTranslation, setEnableChangeTranslation] = useState(true);
  const [previewUrl, setPreviewUrl] = useState('');
  const [previewThumbnailUrl, setPreviewThumbnailUrl] = useState('');
  const [progress, setProgress] = useState(0);
  const [videoUploadLoading, setVideoUploadLoading] = useState(false);
  const [videoUrlActive, setVideoUrlActive] = useState(false);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState('');
  const [useExternalLink, setUseExternalLink] = useState(false);

  const {Option} = Select;
  const {data} = useQuery(GET_ALL_INFORMATION_SECTION, {
    variables: {
      language,
    },
  });

  useEffect(() => {
    if (videoType === 'EXTERNAL_URL') {
      setVideoUrlActive(true);
    } else {
      setVideoUrlActive(false);
    }
  }, [videoType]);

  const handleUploadVideo = async e => {
    const selectedFile = e.target.files[0];
    if (selectedFile.type === 'video/mp4') {
      setVideoUploadLoading(true);
      const response = await UploadVideoToBucket(
        selectedFile,
        'annoucement-images',
        setProgress,
      );
      onChangeFields(response);
      setVideoUploadLoading(false);
      setProgress(0);
    } else {
      toast.error('Please upload a mp4 video');
    }
  };

  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading....</h1>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.updateButton}>
        <Button
          onClick={() => setEnableChangeTranslation(!enableChangeTranslation)}
          type="secondary"
          label={
            enableChangeTranslation ? 'Edit Translation' : 'Disable Translation'
          }
        />
      </div>
      <UploadImage
        previewUrl={previewUrl}
        setPreviewUrl={setPreviewUrl}
        setImgUrl={e => onChangeFields(fieldKey, e, 'imgUrl')}
        large
        bucket="investor-updates-bucket"
      />
      <Form.Item label="Title" className={styles.field}>
        <Input
          style={{width: fieldWidth}}
          value={title}
          disabled={enableChangeTranslation}
          name="title"
          onChange={e => onChangeFields(fieldKey, e.target.value, 'title')}
          placeholder={'Title'}
        />
      </Form.Item>
      <Form.Item label="Short Description" className={styles.field}>
        <Input
          style={{width: fieldWidth}}
          value={summary}
          name="summary"
          disabled={enableChangeTranslation}
          onChange={e => onChangeFields(fieldKey, e.target.value, 'summary')}
          placeholder={'Summary'}
        />
      </Form.Item>
      <div className={styles['quill-container']}>
        <span>Content</span>
        <ReactQuill
          className={styles.quill}
          theme="snow"
          readOnly={enableChangeTranslation}
          modules={TranslatedArticleContent.modules}
          onChange={e => onChangeFields(fieldKey, e, 'content')}
          formats={TranslatedArticleContent.format}
          placeholder="Write Something"
          value={content}
        />
      </div>
      <Form.Item
        label="Information Section"
        className={styles.field}
        name="informationSection">
        <Select
          style={{width: fieldWidth}}
          value={informationSectionMultilang}
          defaultValue={informationSectionMultilang}
          loading={loading}
          onChange={e =>
            onChangeFields(fieldKey, e, 'informationSectionMultilang')
          }>
          {data?.getAllInformationSections.map(({title, id}) => (
            <Option value={id} key={id}>
              {title}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Video Title" className={styles.field} name="videoTitle">
        <Input
          style={{width: fieldWidth}}
          value={videoTitle}
          onChange={e => onChangeFields(fieldKey, e.target.value, 'videoTitle')}
          placeholder={'Video title'}
        />
      </Form.Item>
      <div className={styles['upload-image']}>
        <h2 className={styles.title}>Video Thumbnail</h2>
        <UploadImage
          bucket={'investor-updates-bucket'}
          previewUrl={previewThumbnailUrl}
          setImgUrl={e => onChangeFields(fieldKey, e, 'thumbnailImgUrl')}
          setPreviewUrl={setPreviewThumbnailUrl}
          accept
          thumbnail
        />
      </div>
      <Form.Item label="Video Type" className={styles.field} name="videoType">
        <Select
          style={{width: fieldWidth}}
          value={videoType}
          onChange={e => onChangeFields(fieldKey, e, 'videoType')}>
          <Option value="FILE_URL">Video File Link</Option>
          <Option value="EXTERNAL_URL">External Video Link</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Use Video Url" className={styles.field}>
        <Switch
          checked={videoUrlActive}
          defaultChecked={videoUrlActive}
          onChange={e => setVideoUrlActive(e)}
        />
      </Form.Item>
      {videoUrlActive ? (
        <div className={styles['video-upload']}>
          <Form.Item label="Video Url" className={styles.field} name="videoUrl">
            <Input
              style={{width: fieldWidth}}
              value={videoUrl}
              defaultValue={videoUrl}
              onChange={e =>
                onChangeFields(fieldKey, e.target.value, 'videoUrl')
              }
              placeholder={'Url'}
            />
          </Form.Item>
        </div>
      ) : (
        <div>
          <div className={styles['video-upload']}>
            {videoUrl ? (
              <div>
                <Player fluid={false} width={730} src={videoUrl}>
                  <BigPlayButton position="center" />
                </Player>
                <div>
                  <Button
                    className={styles['delete-button']}
                    label="Delete Video"
                    onClick={() => onChangeFields(fieldKey, '', 'videoUrl')}
                  />
                </div>
              </div>
            ) : (
              <div>
                <label className={styles.upload} htmlFor="file-upload">
                  {videoUploadLoading ? (
                    <div className={styles['upload-file']}>
                      <Spin />
                      <h2>Uploading Please Wait...</h2>
                      <span>{progress}%</span>
                    </div>
                  ) : (
                    <h2>Upload Article Video</h2>
                  )}
                  <input
                    type="file"
                    id="file-upload"
                    className={styles['input-file']}
                    required
                    onChange={handleUploadVideo}
                  />
                </label>
              </div>
            )}
          </div>
        </div>
      )}

      <Form.Item label="Use External Link">
        <Switch
          checked={useExternalLink}
          onChange={e => setUseExternalLink(e)}
        />
      </Form.Item>
      {useExternalLink ? (
        <div>
          <Form.Item
            label="File Link"
            name="document"
            rules={[
              {
                required: false,
              },
            ]}>
            <Input
              value={pdfLink}
              onChange={e =>
                onChangeFields(fieldKey, e.target.value, 'pdfLink')
              }
              required
            />
          </Form.Item>
        </div>
      ) : (
        <div className={styles['pdf-file']}>
          <FadeEffect>
            <ReportPDF
              setPdfLink={e => onChangeFields(fieldKey, e, 'pdfLink')}
              previewUrl={pdfPreviewUrl}
              setPreviewUrl={setPdfPreviewUrl}
              large
            />
          </FadeEffect>
        </div>
      )}
    </div>
  );
};

TranslatedArticleContent.modules = QUILL_MODULES;
TranslatedArticleContent.format = QUILL_FORMATS;

export default TranslatedArticleContent;
