import {useQuery} from '@apollo/client';
import {DatePicker, Form, Input, Select} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {GET_ALL_INDUSTRIES} from 'graphql/queries/investorUpdates';
import moment from 'moment';
import {companyStageData} from 'pages/campaigns/campaignData';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {companyDetails} from 'redux/store/campaign';
import styles from './companyDetails.module.scss';

const CampaignCompanyDetails = () => {
  const [foundedOn, setFoundedOn] = useState('');
  const [location, setLocation] = useState('');
  const [industry, setIndustry] = useState('');
  const [stage, setStage] = useState('');
  const [website, setWebsite] = useState('');
  const [linkedinURL, setLinkedinURL] = useState('');
  const [facebookURL, setFacebookURL] = useState('');
  const [twitterURL, setTwitterURL] = useState('');
  const {Option} = Select;
  const {id, slug, companyId} = useParams();
  const navigate = useNavigate('');
  const dispatch = useDispatch();

  const campaign = useSelector(state => state.campaign.campaign);

  useEffect(() => {
    if (campaign?.stage != undefined) {
      setLocation(campaign?.location);
      setFoundedOn(campaign?.foundedOn);
      setIndustry(campaign?.industry);
      setStage(campaign?.stage);
      setWebsite(campaign?.website);
      setFoundedOn(campaign?.foundedOn);
      setLinkedinURL(campaign?.linkedinURL);
      setFacebookURL(campaign?.facebookURL);
      setTwitterURL(campaign?.twitterURL);
    }
  }, [campaign]);

  function disabledDate(current) {
    const customDate = new Date().getFullYear();
    return current && current > moment(customDate + 1, 'YYYY');
  }

  const {data: getAllIndustries} = useQuery(GET_ALL_INDUSTRIES);

  const handleSave = () => {
    dispatch(
      companyDetails({
        foundedOn,
        location,
        industry,
        stage,
        website,
        linkedinURL,
        facebookURL,
        twitterURL,
      }),
    );
    if (slug) {
      navigate(`/dashboard/campaigns/${slug}/new/${id}/settings/${companyId}`);
    } else {
      navigate(`/dashboard/campaigns/new/${id}/risks/${companyId}`);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Company Details</h1>
      </div>
      <Form onFinish={handleSave} layout="vertical">
        <div className={styles.overview}>
          <h1 className={styles.title}>Overview</h1>
          <div className={styles.form}>
            <Form.Item
              label="Founded Date"
              name="date"
              className={styles['form-item']}
              initialValue={
                campaign?.foundedOn ? moment(campaign?.foundedOn, 'YYYY') : ''
              }
              rules={[{required: true, message: 'Required Field'}]}>
              <DatePicker
                value={foundedOn}
                defaultValue={
                  campaign?.foundedOn ? moment(campaign?.foundedOn, 'YYYY') : ''
                }
                onChange={e => setFoundedOn(moment(e).format('YYYY'))}
                name="Date"
                picker="year"
                disabledDate={disabledDate}
                style={{width: 340}}
              />
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'Required Field'}]}
              initialValue={campaign?.location}
              style={{width: 340}}
              label="Location"
              name="location">
              <Input
                value={location}
                defaultValue={campaign?.location}
                onChange={e => setLocation(e.target.value)}
                placeholder="Lagos - Nigeria"
              />
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'Required Field'}]}
              initialValue={campaign?.industry}
              label="Industry"
              name="industry">
              <Select
                value={industry}
                onChange={e => setIndustry(e)}
                style={{width: 340}}
                defaultValue={campaign?.industry}
                placeholder="Select Industry">
                {getAllIndustries?.getAllIndustries?.map(industry => (
                  <Option key={industry.name}>
                    <div className={styles['all-data']}>
                      <img src={industry.icon} alt="" />
                      <span>{industry.name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'Required Field'}]}
              initialValue={campaign?.stage}
              label="Stage"
              name="stage">
              <Select
                value={stage}
                onChange={e => setStage(e)}
                style={{width: 340}}
                defaultValue={campaign?.stage}
                placeholder="Select Stage">
                {companyStageData?.map(data => (
                  <Option key={data.value}>
                    <div>{data.label}</div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Required Field, must be a valid link',
                  type: 'url',
                },
              ]}
              initialValue={campaign?.website}
              label="Website"
              name="website">
              <Input
                value={website}
                onChange={e => setWebsite(e.target.value)}
                style={{width: 340}}
                defaultValue={campaign?.website}
                placeholder="www.website.com"
              />
            </Form.Item>
          </div>
        </div>
        <div>
          <h1 className={styles.title}>Social Media</h1>
          <div className={styles.form}>
            <Form.Item
              initialValue={campaign?.twitterURL}
              style={{width: 340}}
              rules={[{type: 'url'}]}
              name="twitter"
              label="Twitter">
              <Input
                value={twitterURL}
                onChange={e => setTwitterURL(e.target.value)}
                defaultValue={campaign?.twitterURL}
                placeholder="https://twitter.com/"
              />
            </Form.Item>
            <Form.Item
              initialValue={campaign?.facebookURL}
              style={{width: 340}}
              rules={[{type: 'url'}]}
              name="facebook"
              label="Facebook">
              <Input
                placeholder="https://facebook.com/"
                value={facebookURL}
                onChange={e => setFacebookURL(e.target.value)}
                defaultValue={campaign?.facebookURL}
              />
            </Form.Item>
            <Form.Item
              initialValue={campaign?.linkedinURL}
              style={{width: 340}}
              rules={[{type: 'url'}]}
              name="linkedin"
              label="Linkedin">
              <Input
                placeholder="https://linkedin.com/"
                defaultValue={campaign?.linkedinURL}
                value={linkedinURL}
                onChange={e => setLinkedinURL(e.target.value)}
              />
            </Form.Item>
          </div>
          <SubmitButton label="Save & Continue" />
        </div>
      </Form>
    </div>
  );
};

export default CampaignCompanyDetails;
