import {useMutation, useQuery} from '@apollo/client';
import {Dropdown, Menu, Spin, Table} from 'antd';
import {GET_TRANSACTIONS_QUERIES} from 'graphql/queries/campaign';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import backIcon from 'images/back.png';
import styles from './offeringTransactions.module.scss';
import moment from 'moment';
import {ExportButton} from 'components/button/ExportButton/ExportButton';
import DBModal from 'components/modal/modal';
import ExternalTransactionTable from './ExternalTransactionTable';
import {currencySymbols} from 'utils/mock';
import {EllipsisOutlined} from '@ant-design/icons';
import {SEND_USER_DOCUMENT} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';
import {makeSelectTabOperations} from 'redux/store/auth';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';

const OfferingTransactions = () => {
  const [openModal, setOpenModal] = useState(false);
  const [receipt, setReceipt] = useState(false);
  const [activeTab, setActiveTab] = useState('PLATFORM');
  const [usersTransactions, setUsersTransactions] = useState([]);
  const [usersDetails, setUsersDetails] = useState([]);
  const {id, companyId, campaignId} = useParams();
  const navigate = useNavigate();

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Listings),
  );

  const optionalParams = {};
  if (id) optionalParams.offeringId = id;
  const {data, loading} = useQuery(GET_TRANSACTIONS_QUERIES, {
    variables: {
      getCampaignByIdId: campaignId,
      companyId,
      campaign: campaignId,
      ...optionalParams,
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    onError: error => toast.error(error.message),
    onCompleted: data =>
      setUsersDetails(data?.getAllTransactionByCampaign?.transactions),
  });

  useEffect(() => {
    const tableData = [];
    for (let i = 0; i < usersDetails.length; i++) {
      const tableRow = {};
      tableRow.firstName = usersDetails[i]?.user?.firstName;
      tableRow.lastName = usersDetails[i]?.user?.lastName;
      tableRow.email = usersDetails[i]?.user?.email;
      tableRow.city = usersDetails[i]?.user?.city;
      tableRow.state = usersDetails[i]?.user?.state;
      tableRow.country = usersDetails[i]?.user?.country;
      tableRow.accredition = usersDetails[i]?.user?.investorProfile
        ?.isAccredited
        ? 'Accredited'
        : 'Unaccredited';
      tableRow.phoneNumber = `'${
        usersDetails[i]?.user?.countryCode?.toString() ?? ' '
      }${usersDetails[i]?.user?.phoneNumber?.toString() ?? ' '}'`;
      tableRow.status = usersDetails[i]?.transactionStatus;
      tableRow.paymentMethod = usersDetails[i]?.paymentMethod;
      tableRow.transactionInvestedAmount = `${
        currencySymbols[usersDetails[i]?.transactionCurrency].symbol
      } ${usersDetails[i]?.investedAmountInTransactionCurrency}`;
      tableRow.investedAmount = `${
        currencySymbols[usersDetails[i]?.assetCurrency].symbol
      } ${usersDetails[i]?.investedAmount}`;
      tableRow.transactionCurrencyToAssetCurrencyFX = usersDetails[i]
        ?.transactionCurrencyToAssetCurrencyFx
        ? `$1 = ${
            currencySymbols[usersDetails[i]?.assetCurrency].symbol
          } ${Number(
            usersDetails[i]?.transactionCurrencyToAssetCurrencyFx,
          ).toLocaleString()}`
        : '--';
      tableRow.fee = `${
        currencySymbols[usersDetails[i]?.assetCurrency].symbol
      } ${usersDetails[i]?.transactionFees}`;
      tableRow.amount = `${
        currencySymbols[usersDetails[i]?.assetCurrency].symbol
      } ${usersDetails[i]?.transactionAmount}`;
      tableRow.date = `${moment(usersDetails[i]?.createdAt).format(
        'MMMM Do YYYY, h:mm a',
      )}`;
      tableData.push(tableRow);
    }
    setUsersTransactions(tableData);
  }, [usersDetails]);

  const downloadTableHeader = [
    {label: 'First Name', key: 'firstName'},
    {label: 'Last Name', key: 'lastName'},
    {label: 'Email', key: 'email'},
    {label: 'City', key: 'city'},
    {label: 'State', key: 'state'},
    {label: 'Country of Residence', key: 'country'},
    {label: 'Accredition Status', key: 'accredition'},
    {
      label: 'Phone Number',
      key: 'phoneNumber',
    },
    {label: 'Payment Method', key: 'paymentMethod'},
    {
      label: 'Invested Amount in Transaction Currency',
      key: 'transactionInvestedAmount',
    },
    {
      label: 'Transaction Fee in Asset Currency',
      key: 'fee',
    },
    {
      label: 'Transaction Currency To Asset Currency FX',
      key: 'transactionCurrencyToAssetCurrencyFX',
    },
    {
      label: 'Invested Amount in Asset Currency',
      key: 'investedAmount',
    },
    {label: 'Transaction Status', key: 'status'},
    {label: 'Transaction Amount in Asset Currency', key: 'amount'},
    {label: 'Date', key: 'date'},
  ];

  const handleOpenModal = (event, image, blockchainLink) => {
    if (event.target.isConnected && image) {
      setReceipt(image);
      setOpenModal(true);
    }
    if (event.target.isConnected && blockchainLink && !image) {
      window.open(`${blockchainLink}`, '_blank');
    }
  };

  const [sendUserDocument] = useMutation(SEND_USER_DOCUMENT);

  const handleSendDocument = (
    tradeId,
    transactionId,
    transactionAmount,
    userId,
  ) => {
    sendUserDocument({
      variables: {
        input: {
          campaignId,
          tradeId,
          transactionId,
          transactionAmount,
          userId,
        },
      },
    })
      .then(({data: {sendUserDocument}}) => {
        if (sendUserDocument.__typename === 'Error') {
          toast.error(sendUserDocument.message);
        } else {
          toast.success('Contract sent to user Successfully');
        }
      })
      .catch(error => toast.error(error.message));
  };

  const menu = (userId, id, transactionAmount, tradeId) => {
    return (
      <Menu>
        {tabOperations?.update && (
          <Menu.Item
            onClick={() => {
              navigate(
                `/dashboard/campaign/update-transaction/${userId}/${id}`,
              );
            }}>
            Update
          </Menu.Item>
        )}
        {tabOperations?.export && (
          <Menu.Item
            onClick={() => {
              handleSendDocument(tradeId, id, transactionAmount, userId);
            }}>
            Send Contract
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div
          onClick={() => navigate(`/dashboard/user/${record?.user?.id}`)}
          className={styles['record-label']}>
          <img src={record?.user?.imageUrl} alt="" />
          <h4 style={{width: 100}}>
            {record?.user?.firstName} {record?.user?.lastName}
          </h4>
        </div>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (text, record) => (
        <div className={styles['row-width']}>{record?.user?.username}</div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => (
        <div className={styles['row-width']}>{record?.user?.email}</div>
      ),
    },
    {
      title: ' Transaction Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <div className={styles['row-width']}>
          <span
            className={
              styles[
                record?.transactionStatus === 'SETTLED'
                  ? 'success'
                  : record?.transactionStatus === 'FUNDED'
                  ? 'funded'
                  : record?.transactionStatus === 'VOIDED'
                  ? 'voided'
                  : record?.transactionStatus === 'FAILED'
                  ? 'failed'
                  : 'pending'
              ]
            }>
            {record?.transactionStatus}
          </span>
        </div>
      ),
    },
    {
      title: 'Method',
      dataIndex: 'method',
      key: 'method',
      render: (text, record) => (
        <div
          onClick={e =>
            handleOpenModal(
              e,
              record?.wireReceiptImage,
              record?.blockchainTransactionLink,
            )
          }>
          <div className={styles['method-width']}>{record?.paymentMethod}</div>
        </div>
      ),
    },
    {
      title: 'Transaction Amount in Asset Currency',
      dataIndex: 'transactionAmount',
      key: 'transactionAmount',
      render: (text, record) => (
        <div className={styles['row-width']}>
          {currencySymbols[record?.assetCurrency].symbol}{' '}
          {Number(record?.transactionAmount)?.toLocaleString()}
        </div>
      ),
    },
    {
      title: 'Invested Amount in Asset Currency',
      dataIndex: 'investedAmount',
      key: 'investedAmount',
      render: (text, record) => (
        <div className={styles['row-width']}>
          {currencySymbols[record?.assetCurrency].symbol}{' '}
          {Number(record?.investedAmount).toLocaleString()}
        </div>
      ),
    },
    {
      title: 'Transaction Currency To Asset Currency FX',
      dataIndex: 'investedAmount',
      key: 'investedAmount',
      render: (text, record) => (
        <div className={styles['row-width']}>
          {record?.transactionCurrencyToAssetCurrencyFx
            ? `$1 = ${currencySymbols[record?.assetCurrency].symbol} ${Number(
                record?.transactionCurrencyToAssetCurrencyFx,
              ).toLocaleString()}`
            : '--'}
        </div>
      ),
    },
    {
      title: 'Invested Amount in Transaction Currency',
      dataIndex: 'investedAmount',
      key: 'investedAmount',
      render: (text, record) => (
        <div className={styles['row-width']}>
          {currencySymbols[record?.transactionCurrency].symbol}{' '}
          {Number(record?.investedAmountInTransactionCurrency).toLocaleString()}
        </div>
      ),
    },
    {
      title: 'Transaction Fee in Asset Currency',
      dataIndex: 'investedAmount',
      key: 'investedAmount',
      render: (text, record) => (
        <div className={styles['row-width']}>
          {currencySymbols[record?.assetCurrency].symbol}{' '}
          {Number(record?.transactionFees).toLocaleString()}
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'time',
      key: 'time',
      render: (text, record) => (
        <div className={styles['row-width']}>
          {moment(record?.createdAt).format('MMMM Do YYYY, h:mm a')}
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <Dropdown
          overlay={menu(
            record?.user?.id,
            record?.id,
            record?.transactionAmount,
            record?.tradeId,
          )}
          placement="bottomLeft">
          <div className={styles['select-btn']}>
            <EllipsisOutlined />
          </div>
        </Dropdown>
      ),
    },
  ];

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div>
      <div className={styles['nav-header']}>
        <div className={styles.header}>
          <img src={backIcon} alt="" onClick={() => navigate(-1)} />
          <h1>
            {data?.getFundraisingCompanyById?.companyName} Campaign Transactions
          </h1>
        </div>
        <ExportButton
          label={'Download Transactions'}
          csvData={usersTransactions}
          csvHeaders={downloadTableHeader}
          fileName={`${data?.getFundraisingCompanyById?.companyName} Campaign Transactions`}
        />
      </div>
      <div className={styles['details-toggler']}>
        <span
          className={activeTab === 'PLATFORM' ? styles['active-toggle'] : ''}
          onClick={() => setActiveTab('PLATFORM')}>
          Platform Transactions
        </span>
        <span
          className={activeTab === 'EXTERNAL' ? styles['active-toggle'] : ''}
          onClick={() => setActiveTab('EXTERNAL')}>
          External Transactions
        </span>
      </div>
      {activeTab === 'PLATFORM' && (
        <div className={styles.table}>
          <Table
            className={styles['user-table']}
            columns={columns}
            dataSource={data?.getAllTransactionByCampaign?.transactions}
          />
        </div>
      )}
      {activeTab === 'EXTERNAL' && (
        <ExternalTransactionTable externalData={data?.getCampaignById} />
      )}
      <DBModal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        width={800}
        content={
          <div className={styles['modal-container']}>
            <h1>Transaction Receipt</h1>
            <div>
              <img className={styles['modal-image']} src={receipt} alt="" />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default OfferingTransactions;
