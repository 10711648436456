import {useEffect, useState} from 'react';
import styles from './teamMembers.module.scss';
import plusIcon from 'images/plus-circle.png';
import SubmitButton from 'components/Startup/components/SubmitButton';
import FadeEffect from 'components/animation/fadeEffect';
import {
  resizeImageFile,
  UploadDocumentToBucket,
} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import CampaignMemberCard from 'pages/campaigns/new/screens/campaignFounders/CampaignMemberCard';

const BaseTeamMembers = ({onSubmit, existingData}) => {
  const [founderCardList, setFounderCardList] = useState([]);
  const [teamMemberList, setTeamMemberList] = useState([]);

  useEffect(() => {
    if (existingData.founders?.length) {
      setFounderCardList(existingData.founders);
    }
    if (existingData.teamMembers?.length) {
      setTeamMemberList(existingData.teamMembers);
    }
  }, [existingData]);

  const addFounderCard = () => {
    setFounderCardList([
      ...founderCardList,
      {
        fullName: '',
        imageUrl: '',
        jobTitle: '',
        previewImage: '',
        twitter: '',
        linkedin: '',
      },
    ]);
  };

  const addTeamMember = () => {
    setTeamMemberList([
      ...teamMemberList,
      {
        fullName: '',
        imageUrl: '',
        previewImage: '',
        jobTitle: '',
        twitter: '',
        linkedin: '',
      },
    ]);
  };

  const handleChangeImage = async (i, {file}) => {
    const temp = file.originFileObj;

    const url = URL.createObjectURL(temp);
    const newFormValues = [...founderCardList];
    newFormValues[i].previewImage = url;
    const resizedFile = await resizeImageFile(temp);
    const response = await UploadDocumentToBucket(resizedFile);
    newFormValues[i].imageUrl = response;
    setFounderCardList(newFormValues);
  };

  const handleDeletePreviewImage = i => {
    const newFormValues = [...founderCardList];
    newFormValues[i].previewImage = '';
    newFormValues[i].imageUrl = '';
    setFounderCardList(newFormValues);
  };

  const handleChangeTeamMemberImage = async (i, {file}) => {
    const temp = file.originFileObj;

    const url = URL.createObjectURL(temp);
    const newFormValues = [...teamMemberList];
    newFormValues[i].previewImage = url;
    const resizedFile = await resizeImageFile(temp);
    const res = await UploadDocumentToBucket(resizedFile);
    newFormValues[i].imageUrl = res;
    setTeamMemberList(newFormValues);
  };

  const handleDeleteMemeberPreviewImage = i => {
    const newFormValues = [...teamMemberList];
    newFormValues[i].previewImage = '';
    newFormValues[i].imageUrl = '';
    setTeamMemberList(newFormValues);
  };

  const handleChangeFields = (i, e) => {
    const newFormValues = [...founderCardList];
    newFormValues[i][e.target.name] = e.target.value;
    setFounderCardList(newFormValues);
  };

  const handleChangeMemberFields = (i, e) => {
    const newFormValues = [...teamMemberList];
    newFormValues[i][e.target.name] = e.target.value;
    setTeamMemberList(newFormValues);
  };

  const onDeleteFounderCard = id => {
    const deletedFounded = founderCardList.filter(
      (data, index) => index !== id,
    );
    setFounderCardList(deletedFounded);
  };

  const onDeleteTeamMemberCard = id => {
    const deletedFounded = teamMemberList.filter((data, index) => index !== id);
    setTeamMemberList(deletedFounded);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>{'Founders & team'}</h1>
      <div>
        <h1 className={styles.title}>Founders</h1>
        <div>
          <div>
            {founderCardList?.map((data, index) => (
              <FadeEffect key={index}>
                <div key={index}>
                  <CampaignMemberCard
                    deleteMember={() => onDeleteFounderCard(index)}
                    fullName={data.fullName || ''}
                    jobTitle={data.jobTitle || ''}
                    previewImage={data.previewImage || ''}
                    imgUrl={data.imageUrl || ''}
                    twitterLink={data.twitter || ''}
                    linkedinLink={data.linkedin || ''}
                    handleDeleteImage={() => handleDeletePreviewImage(index)}
                    handleChangeImage={e => handleChangeImage(index, e)}
                    handleChange={e => handleChangeFields(index, e)}
                  />
                </div>
              </FadeEffect>
            ))}
          </div>
          <div onClick={addFounderCard} className={styles['add-founder']}>
            <img src={plusIcon} alt="add-founder" />
            <span>Add Founder</span>
          </div>
        </div>
        <div>
          <h1 className={styles.title}>Team Members</h1>
          <div>
            <div>
              {teamMemberList?.map((data, index) => (
                <FadeEffect key={index}>
                  <div key={index}>
                    <CampaignMemberCard
                      deleteMember={() => onDeleteTeamMemberCard(index)}
                      fullName={data.fullName || ''}
                      jobTitle={data.jobTitle || ''}
                      previewImage={data.previewImage || ''}
                      imgUrl={data.imageUrl || ''}
                      twitterLink={data.twitter || ''}
                      linkedinLink={data.linkedin || ''}
                      handleDeleteImage={() =>
                        handleDeleteMemeberPreviewImage(index)
                      }
                      handleChangeImage={e =>
                        handleChangeTeamMemberImage(index, e)
                      }
                      handleChange={e => handleChangeMemberFields(index, e)}
                    />
                  </div>
                </FadeEffect>
              ))}
            </div>
            <div onClick={addTeamMember} className={styles['add-founder']}>
              <img src={plusIcon} alt="add-founder" />
              <span>Add Member</span>
            </div>
          </div>
        </div>
        <div>
          <SubmitButton
            label="Save & Continue"
            handleClick={() =>
              onSubmit({
                founders: founderCardList,
                teamMembers: teamMemberList,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BaseTeamMembers;
