import {gql} from '@apollo/client';

export const UPDATE_SAVINGS_COMPANY = gql`
  mutation UpdateOrganization($input: UpdateOrganizationInput) {
    updateOrganization(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrganization {
        message
        data {
          id
          name
          logo
          userCount
          city
          country {
            bannerImg
            name
            id
          }
          industry {
            id
          }
          website
          NoOfEmployees
          companyEmail
          state
          businessAddress
          companyPhoneNumber
          keyContactPersonName
          keyContactPersonEmail
          keyContactPersonPhoneNumber
        }
      }
    }
  }
`;

export const UPDATE_SAVINGS_COMPANY_STATUS = gql`
  mutation UpdateOrganization($input: UpdateOrganizationInput) {
    updateOrganization(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrganization {
        message
      }
    }
  }
`;

export const ADD_ORGANIZATION_USER = gql`
  mutation CreateOrganizationUser($input: CreateOrganizationUserInput) {
    createOrganizationUser(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUser {
        message
      }
    }
  }
`;

export const UPDATE_ORGANIZATION_USER = gql`
  mutation UpdateOrganizationUser($input: UpdateOrganizationUserInput) {
    updateOrganizationUser(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUser {
        message
      }
    }
  }
`;

export const CREATE_SAVINGS_COMPANY = gql`
  mutation CreateOrganization($input: CreateOrganizationInput) {
    createOrganization(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrganization {
        message
      }
    }
  }
`;

export const UPDATE_SAVINGS_PRODUCT = gql`
  mutation UpdateSavingsProduct(
    $updateSavingsProductId: ID!
    $input: UpdateSavingsProductInput
  ) {
    updateSavingsProduct(id: $updateSavingsProductId, input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithSavingsProduct {
        message
      }
    }
  }
`;
