import {useMutation, useQuery} from '@apollo/client';
import styles from './singleCompany.module.scss';
import backIcon from 'images/back.png';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {GET_SAVINGS_COMPANY_BY_ID} from 'graphql/queries/savings';
import {Spin, Upload} from 'antd';
import {toast} from 'react-toastify';
import {Button} from '@dabafinance/react-components';
import {CustomLink} from 'components/investorUpdate/management';
import {useEffect, useState} from 'react';
import {handleConfigurationImageUpload} from 'pages/Affiliates/page/AffiliateConfiguration/actions';
import {UPDATE_SAVINGS_COMPANY} from 'graphql/mutations/savings';
import {useDispatch} from 'react-redux';
import {addOrganizationProfile} from 'redux/store/savings';

const SingleCompany = () => {
  const [img, setImg] = useState('');
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {id} = useParams();
  const dispatch = useDispatch();

  const [updateOrganization, {loading: loadingUpdate}] = useMutation(
    UPDATE_SAVINGS_COMPANY,
  );
  const {
    data,
    loading: loadingQuery,
    refetch,
  } = useQuery(GET_SAVINGS_COMPANY_BY_ID, {
    variables: {
      getOrganizationByIdId: id,
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    onCompleted: data =>
      dispatch(addOrganizationProfile(data?.getOrganizationById?.data)),
    onError: error => toast.error('Something went wrong', error.message),
  });

  useEffect(() => {
    if (data?.getOrganizationById?.data) {
      setImg(data?.getOrganizationById?.data?.logo);
    }
  }, [data?.getOrganizationById?.data]);

  if (loadingQuery) {
    return (
      <div className="query-loading">
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }

  const handleChangeImage = e => {
    const file = e.file;
    if (file.status === 'uploading') {
      setLoading(true);
    } else if (file.status === 'done' || file.status === 'error') {
      setLoading(false);
      handleConfigurationImageUpload(
        file,
        setImg,
        setLoading,
        setProgress,
        true,
      );
    }
  };

  const handleUpdateProfilePhoto = () => {
    updateOrganization({
      variables: {
        input: {
          id: id,
          logo: img,
        },
      },
    })
      .then(({data: updateOrganization}) => {
        if (updateOrganization.__typename === 'Error') {
          throw new Error(updateOrganization.message);
        } else {
          toast.success('Profile Photo Updated Successfully');
          refetch();
        }
      })
      .catch(error => {
        toast.error(`GraphQL Error: ${error.message}`);
      });
  };

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <img
          onClick={() => navigate(`/dashboard/savings/companies`)}
          src={backIcon}
        />
        <h1>Go back to Organizations</h1>
      </div>
      <div className={styles.topSection}>
        <div>
          {img ? (
            <div className={styles['image-uploaded']}>
              <img src={img} alt="" />
            </div>
          ) : (
            <Upload
              accept=".png, .jpeg, .jpg"
              name="file"
              onChange={handleChangeImage}>
              <div className={styles['upload-container']}>
                {loading ? (
                  <>
                    <h2>{progress}%</h2>
                    <Spin size="small" />
                  </>
                ) : (
                  <>
                    <h2 style={{marginTop: 6}}>Upload Logo</h2>
                  </>
                )}
              </div>
            </Upload>
          )}
        </div>
        <div>
          <div className={styles.topButtons}>
            <Button
              onClick={() => setImg('')}
              disabled={!img}
              type="secondary"
              label="Remove Picture"
            />
            <Button
              label="Update Profile Picture"
              disabled={!img || loadingUpdate}
              onClick={handleUpdateProfilePhoto}
            />
          </div>
          <div className={styles.name}>
            <p>{data?.getOrganizationById?.data?.name}</p>
            <span>Company Name</span>
          </div>
        </div>
      </div>
      <section className={styles.section}>
        <nav className={styles['investor-toggler']}>
          <CustomLink
            to={`/dashboard/savings/company/${id}`}
            name="Organization Profile"
          />
          <CustomLink
            to={`/dashboard/savings/company/${id}/user-management`}
            name="User Management"
          />
          <CustomLink
            to={`/dashboard/savings/company/${id}/users`}
            name="Users"
          />
          <CustomLink
            to={`/dashboard/savings/company/${id}/products`}
            name="Products"
          />
        </nav>
      </section>
      <section>
        <Outlet />
      </section>
    </div>
  );
};

export default SingleCompany;
