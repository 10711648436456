import {useState} from 'react';
import styles from './signup.module.scss';
import Logo from '../../components/logo/logo';
import {useNavigate} from 'react-router-dom';
import Modal from 'components/modal/modal';
import {Button} from '@dabafinance/react-components';

export default function SignUpLayout({left, right, skip, exit}) {
  const [openModal, setOpenModal] = useState(false);
  const navigation = useNavigate();

  return (
    <>
      <div className={styles['layout-container']}>
        <div className={styles.left}>
          <div className={styles['left-container']}>
            <div className={styles['left-main']}>
              <div className={styles['logo-container']}>
                <Logo onClick={() => navigation('/')} />
                {skip ? (
                  <span onClick={() => navigation(-1)}>Go Back</span>
                ) : null}
                {exit ? (
                  <span onClick={() => setOpenModal(true)}>Skip to home</span>
                ) : null}
              </div>
              <div className={styles.content}>{left}</div>
            </div>
          </div>
        </div>

        <div className={styles.right}>{right}</div>
      </div>
      <Modal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        content={
          <div className={styles['modal-container']}>
            <div className={styles.header}>
              <h1>Are you sure you want to skip this step?</h1>
              <span>
                Once you skip this step you would still be able to enable
                investment later, but all previously entered data would not be
                saved and would thereby be lost
              </span>
            </div>
            <div className={styles['modal-button']}>
              <Button
                label="Cancel"
                className={styles['button-style']}
                onClick={() => setOpenModal(false)}
              />
              <Button
                label="Skip to Home"
                type="secondary"
                className={styles['button-style']}
                onClick={() => navigation('/dashboard')}
              />
            </div>
          </div>
        }
      />
    </>
  );
}
