import backIcon from 'images/back.png';
import styles from './olderUpdates.module.scss';
import {useNavigate} from 'react-router-dom';
import ManagementCard from '../managementCard';
import {Spin} from 'antd';
import {GET_ALL_INVESTORS_UPDATES} from 'graphql/queries/investorUpdates';
import {useQuery} from '@apollo/client';

const OlderUpdates = () => {
  const navigate = useNavigate();

  const {data, loading} = useQuery(GET_ALL_INVESTORS_UPDATES);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <img src={backIcon} alt="go-back" onClick={() => navigate(-1)} />
        <span className={styles['i-text']}>
          Investor Updates /{' '}
          <span className={styles['o-text']}>Older Updates</span>
        </span>
      </section>
      <section className={styles.body}>
        <div className={styles.title}>
          <h1>All Updates</h1>
        </div>
        <div className={styles.cards}>
          {data?.getAllInvestorUpdates?.investorUpdates
            ?.filter(data => data.isExpired === true)
            .map(({title, imageUrl, publishedDate, id}, index) => (
              <div key={index}>
                <ManagementCard
                  title={title}
                  image={imageUrl}
                  date={publishedDate}
                  id={id}
                />
              </div>
            ))}
        </div>
      </section>
    </div>
  );
};

export default OlderUpdates;
