import {useMutation, useQuery} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {DatePicker, Form, Input, Select, Spin} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {
  DELETE_WEEKLY_STARTUP,
  GET_ALL_STARTUPS,
  GET_WEEKLY_STARTUPS,
  UPDATE_WEEKLY_STARTUP,
} from 'graphql/queries/startupCompanies';
import moment from 'moment';
import {UploadVideoToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {BigPlayButton, Player} from 'video-react';
import styles from './create/createStartup.module.scss';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const StartupOfTheWeek = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [progress, setProgress] = useState(0);
  const [company, setCompany] = useState('');
  const [videoUploadLoading, setVideoUploadLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const navigate = useNavigate();
  const {Option} = Select;

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Updates),
  );

  const {data: getAllCompanies, loading: loadingCompanies} =
    useQuery(GET_ALL_STARTUPS);
  const {loading, data: weeklyStartup} = useQuery(GET_WEEKLY_STARTUPS, {
    onError: error => toast.error(error),
  });
  const [updateWeeklyStartup, {loading: loadingUpdate}] = useMutation(
    UPDATE_WEEKLY_STARTUP,
  );
  const [deleteWeeklyStartup] = useMutation(DELETE_WEEKLY_STARTUP);

  useEffect(() => {
    setVideoUrl(weeklyStartup?.getWeeklyStartup?.videoUrl);
    setTitle(weeklyStartup?.getWeeklyStartup?.title);
    setDescription(weeklyStartup?.getWeeklyStartup?.description);
    setExpirationDate(weeklyStartup?.getWeeklyStartup?.expirationDate);
  }, [weeklyStartup?.getWeeklyStartup]);

  const handleUploadVideo = async e => {
    const selectedFile = e.target.files[0];
    if (selectedFile.type === 'video/mp4') {
      setVideoUploadLoading(true);
      const response = await UploadVideoToBucket(
        selectedFile,
        'startups-bucket',
        setProgress,
      );
      setVideoUrl(response);
      setVideoUploadLoading(false);
      setProgress(0);
    } else {
      toast.error('Please upload a mp4 video');
    }
  };

  const handleUpdate = () => {
    updateWeeklyStartup({
      variables: {
        data: {
          weeklyStartupId: weeklyStartup?.getWeeklyStartup?.id,
          title: title.trim(),
          description: description.trim(),
          company: company
            ? company.split(';')[1]
            : weeklyStartup?.getWeeklyStartup?.company?.id,
          videoUrl,
          expirationDate: moment(expirationDate).format(),
        },
      },
    })
      .then(({data: {updateWeeklyStartup}}) => {
        if (updateWeeklyStartup.__typename === 'Error') {
          toast.error(updateWeeklyStartup.message);
        } else {
          toast.success('Updated Successfully');
        }
      })
      .catch(error => toast.error(error));
  };

  const handleDeleteStartup = () => {
    deleteWeeklyStartup({
      variables: {
        weeklyStartupId: weeklyStartup?.getWeeklyStartup?.id,
      },
    })
      .then(({data: {deleteWeeklyStartup}}) => {
        if (deleteWeeklyStartup.__typename === 'Error') {
          toast.error(deleteWeeklyStartup.message);
        } else {
          toast.success('Deleted Successfully');
          window.location.reload(false);
        }
      })
      .catch(error => toast.error(error));
  };

  if (loading || loadingCompanies) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles['startup-container']}>
      <div className={styles['startup-header']}>
        <h1>Startup of the Week</h1>
        <SubmitButton
          label="Create"
          disabled={!tabOperations?.create}
          handleClick={() =>
            navigate('/dashboard/explore/create-startup-of-the-week')
          }
        />
      </div>
      {weeklyStartup?.getWeeklyStartup ? (
        <div>
          <Form onFinish={handleUpdate} layout="vertical">
            <div className={styles['video-upload']}>
              {videoUrl ? (
                <div>
                  <Player fluid={false} width={730} src={videoUrl}>
                    <BigPlayButton position="center" />
                  </Player>
                  <div>
                    <Button
                      className={styles['delete-button']}
                      label="Delete Video"
                      onClick={() => setVideoUrl('')}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <label className={styles.upload} htmlFor="file-upload">
                    {videoUploadLoading ? (
                      <div className={styles['upload-file']}>
                        <Spin />
                        <h2>Uploading Please Wait...</h2>
                        <span>{progress}%</span>
                      </div>
                    ) : (
                      <h2>Upload Startup Video</h2>
                    )}
                    <input
                      type="file"
                      id="file-upload"
                      className={styles['input-file']}
                      required
                      onChange={handleUploadVideo}
                    />
                  </label>
                  <span>Please upload a video of type mp4</span>
                </div>
              )}
            </div>
            <Form.Item
              className={styles['field-width']}
              name="Title"
              label="Title">
              <Input
                defaultValue={weeklyStartup?.getWeeklyStartup?.title}
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              name="description"
              label="Description">
              <Input.TextArea
                value={description}
                rows={6}
                defaultValue={weeklyStartup?.getWeeklyStartup?.description}
                onChange={e => setDescription(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              name="company"
              label="Select Company">
              <Select
                defaultValue={`${weeklyStartup?.getWeeklyStartup?.company?.name};${weeklyStartup?.getWeeklyStartup?.company?.id}`}
                showSearch
                value={company}
                onChange={e => setCompany(e)}>
                {getAllCompanies?.getAllCompanies?.map(company => (
                  <Option key={`${company?.name};${company?.id}`}>
                    <div className={styles['all-data']}>
                      <img src={company?.logoImgURL} alt="" />
                      <span>{company?.name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="expiry date" label="Expiration Date">
              <DatePicker
                name="Expiry Date"
                format={'YYYY-MM-DD HH:mm:ss'}
                style={{width: 340}}
                defaultValue={moment(
                  weeklyStartup?.getWeeklyStartup?.expirationDate,
                  'YYYY-MM-DD HH:mm:ss',
                )}
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm:ss'),
                }}
                value={expirationDate}
                onChange={e =>
                  setExpirationDate(moment(e).format('YYYY-MM-DD HH:mm:ss'))
                }
              />
            </Form.Item>
            <div className={styles['handle-buttons']}>
              <SubmitButton
                disabled={loadingUpdate || !tabOperations?.update}
                label="Update"
              />
              <Button
                className={styles['delete-button']}
                onClick={handleDeleteStartup}
                disabled={!tabOperations?.delete}
                label="Delete"
              />
            </div>
          </Form>
        </div>
      ) : (
        <div className={styles['no-data']}>
          <h1>No Startup of the Week at the moment!</h1>
          <span>Click on Create to create one</span>
        </div>
      )}
    </div>
  );
};

export default StartupOfTheWeek;
