import {Button, Form, Input, Select, Space} from 'antd';
import styles from './assetManagementComponents.module.scss';
import {PlusOutlined} from '@ant-design/icons';
import SubmitButton from 'components/Startup/components/SubmitButton';
import trashIcon from 'images/trash-can-outline.svg';
import {useMutation} from '@apollo/client';
import {
  CREATE_ASSET_CLASS,
  UPDATE_ASSET_CLASS,
} from 'graphql/mutations/assetManagement';
import {useEffect, useState} from 'react';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {useNavigate, useParams} from 'react-router-dom';
import {createAssetClassResolver, handleUpdateAssetClass} from '../actions';
import {
  assetClassKeyTypes,
  assetClassStatus,
  assetInstrumentTypes,
} from '../modules/constants';
import {supportedLanguages} from 'utils/constants';

export const AssetClassForm = ({data}) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {id} = useParams();

  const [createAssetClass, {loading: loadingCreate}] =
    useMutation(CREATE_ASSET_CLASS);
  const [updateAssetClass, {loading: loadingUpdate}] =
    useMutation(UPDATE_ASSET_CLASS);

  useEffect(() => {
    if (data) {
      const {name, description, language, key, status, instruments} = data;
      form.setFieldsValue({
        name,
        description,
        language,
        key,
        status,
        instruments: instruments?.length
          ? instruments?.map(data => ({
              name: data.name,
              key: data.key,
              status: data.status,
              description: data.description,
            }))
          : [],
      });
    }
  }, [data]);

  return (
    <div className={styles.assetClassForm}>
      <Form
        onFinish={values => {
          setFormValues({...values});
          setOpenConfirmModal(true);
        }}
        form={form}
        layout="vertical">
        <Form.Item
          rules={[{required: true, message: 'This is a required field'}]}
          className={styles.fieldWidth}
          name="name"
          label="Name">
          <Input />
        </Form.Item>
        <Form.Item
          className={styles.fieldWidth}
          name="description"
          label="Description">
          <Input.TextArea rows={6} />
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: 'This is a required field'}]}
          className={styles.fieldWidth}
          name="language"
          label="Language">
          <Select>
            {supportedLanguages.map(data => (
              <Select.Option key={data.value}>{data.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: 'This is a required field'}]}
          className={styles.fieldWidth}
          name="status"
          label="Status">
          <Select>
            {assetClassStatus.map(data => (
              <Select.Option key={data.value}>{data.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: 'This is a required field'}]}
          className={styles.fieldWidth}
          name="key"
          label="Asset Key">
          <Select>
            {assetClassKeyTypes.map(data => (
              <Select.Option key={data.value}>{data.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div>
          <h2>Asset Instruments</h2>
          <Form.List name="instruments">
            {(fields, {add, remove}) => (
              <>
                {fields.map(({key, name}) => (
                  <Space key={key} className={styles.space} align="baseline">
                    <div>
                      <Form.Item
                        name={[name, 'name']}
                        label="Instrument Name"
                        className={styles.listField}
                        rules={[
                          {
                            required: true,
                            message: 'Instrument Name is required',
                          },
                        ]}>
                        <Input placeholder="Instrument Name" />
                      </Form.Item>
                      <Form.Item
                        className={styles.listField}
                        rules={[
                          {
                            required: true,
                            message: 'Instrument Description is required',
                          },
                        ]}
                        label="Description"
                        name={[name, 'description']}>
                        <Input.TextArea
                          rows={4}
                          placeholder="Instrument Description"
                        />
                      </Form.Item>
                      <Form.Item
                        className={styles.listField}
                        label="Instrument Key"
                        name={[name, 'key']}
                        rules={[
                          {
                            required: true,
                            message: 'Instrument key is required',
                          },
                        ]}>
                        <Select>
                          {assetInstrumentTypes.map(data => (
                            <Select.Option key={data.value}>
                              {data.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        className={styles.listField}
                        label="Status"
                        name={[name, 'status']}>
                        <Select>
                          {assetClassStatus.map(data => (
                            <Select.Option key={data.value}>
                              {data.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>

                    <img
                      onClick={() => remove(name)}
                      className={styles.delete}
                      src={trashIcon}
                      alt=""
                    />
                  </Space>
                ))}

                <Form.Item>
                  <Button type="dashed" onClick={add} icon={<PlusOutlined />}>
                    Add Instrument
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
        <SubmitButton disabled={loadingCreate} label="Proceed" />
      </Form>
      <ConfirmModal
        title="Confirm This Action"
        confirmText="Are you sure all details are Correctly Inputted before Proceeding?"
        openModal={openConfirmModal}
        setOpenModal={() => setOpenConfirmModal(false)}
        loading={loadingCreate || loadingUpdate}
        handleOperation={() =>
          id
            ? handleUpdateAssetClass(
                updateAssetClass,
                formValues,
                id,
                navigate,
                setOpenConfirmModal,
              )
            : createAssetClassResolver(
                createAssetClass,
                formValues,
                navigate,
                setOpenConfirmModal,
              )
        }
      />
    </div>
  );
};
