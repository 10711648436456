import {useEffect, useState} from 'react';
import styles from './campaignFounders.module.scss';
import {useParams} from 'react-router-dom';
import plusIcon from 'images/plus-circle.png';
import CampaignMemberCard from './CampaignMemberCard';
import SubmitButton from 'components/Startup/components/SubmitButton';
import FadeEffect from 'components/animation/fadeEffect';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {useMutation, useQuery} from '@apollo/client';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';
import {Spin} from 'antd';
import {
  resizeImageFile,
  UploadDocumentToBucket,
} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';

const UpdateCampaignFounders = () => {
  const {id, language} = useParams();
  const [founderCardList, setFounderCardList] = useState([]);
  const [teamMemberList, setTeamMemberList] = useState([]);

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });
  const [updateCampaign, {loading: loadingUpdate}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (campaignData?.getCampaignById?.teamMembers) {
      setTeamMemberList(
        campaignData?.getCampaignById?.teamMembers.map(data => ({
          fullName: data.fullName,
          previewImage: data?.imageUrl,
          imageUrl: data.imageUrl,
          jobTitle: data.jobTitle,
          twitter: data.twitter,
          linkedin: data.linkedin,
        })),
      );
    }
  }, [campaignData?.getCampaignById?.teamMembers]);

  useEffect(() => {
    if (campaignData?.getCampaignById?.founders !== undefined) {
      setFounderCardList(
        campaignData?.getCampaignById?.founders.map(data => ({
          fullName: data.fullName,
          imageUrl: data.imageUrl,
          jobTitle: data.jobTitle,
          previewImage: data?.imageUrl,
          twitter: data.twitter,
          linkedin: data.linkedin,
        })),
      );
    }
  }, [campaignData?.getCampaignById?.founders]);

  const addFounderCard = () => {
    setFounderCardList([
      ...founderCardList,
      {
        fullName: '',
        imageUrl: '',
        jobTitle: '',
        previewImage: '',
        twitter: '',
        linkedin: '',
      },
    ]);
  };

  const addTeamMember = () => {
    setTeamMemberList([
      ...teamMemberList,
      {
        fullName: '',
        imageUrl: '',
        previewImage: '',
        jobTitle: '',
        twitter: '',
        linkedin: '',
      },
    ]);
  };

  const handleChangeImage = async (i, {file}) => {
    const temp = file.originFileObj;
    const newFormValues = [...founderCardList];
    const resizedFile = await resizeImageFile(temp);
    const res = await UploadDocumentToBucket(resizedFile);
    newFormValues[i].previewImage = res;
    newFormValues[i].imageUrl = res;
    setFounderCardList(newFormValues);
  };

  const handleDeletePreviewImage = i => {
    const newFormValues = [...founderCardList];
    newFormValues[i].previewImage = '';
    newFormValues[i].imageUrl = '';
    setFounderCardList(newFormValues);
  };

  const handleChangeTeamMemberImage = async (i, {file}) => {
    const temp = file.originFileObj;

    const url = URL.createObjectURL(temp);
    const newFormValues = [...teamMemberList];
    newFormValues[i].previewImage = url;
    const resizedFile = await resizeImageFile(temp);
    const res = await UploadDocumentToBucket(resizedFile);
    newFormValues[i].imageUrl = res;
    setTeamMemberList(newFormValues);
  };

  const handleDeleteMemeberPreviewImage = i => {
    const newFormValues = [...teamMemberList];
    newFormValues[i].previewImage = '';
    newFormValues[i].imageUrl = '';
    setTeamMemberList(newFormValues);
  };

  const handleChangeFields = (i, e) => {
    const newFormValues = [...founderCardList];
    newFormValues[i][e.target.name] = e.target.value;
    setFounderCardList(newFormValues);
  };

  const handleChangeMemberFields = (i, e) => {
    const newFormValues = [...teamMemberList];
    newFormValues[i][e.target.name] = e.target.value;
    setTeamMemberList(newFormValues);
  };

  const onDeleteFounderCard = id => {
    const deletedFounded = founderCardList.filter(
      (data, index) => index !== id,
    );
    setFounderCardList(deletedFounded);
  };

  const onDeleteTeamMemberCard = id => {
    const deletedFounded = teamMemberList.filter((data, index) => index !== id);
    setTeamMemberList(deletedFounded);
  };

  const handleUpdate = () => {
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          founders: founderCardList?.map(
            ({fullName, jobTitle, linkedin, twitter, imageUrl}) => {
              const newObj = {};
              if (twitter) newObj.twitter = twitter;
              if (linkedin) newObj.linkedin = linkedin;
              if (fullName) newObj.fullName = fullName;
              if (jobTitle) newObj.jobTitle = jobTitle;
              if (imageUrl) newObj.imageUrl = imageUrl;
              return newObj;
            },
          ),
          teamMembers: teamMemberList?.map(
            ({fullName, jobTitle, linkedin, twitter, imageUrl}) => {
              const newObj = {};
              if (twitter) newObj.twitter = twitter;
              if (linkedin) newObj.linkedin = linkedin;
              if (fullName) newObj.fullName = fullName;
              if (jobTitle) newObj.jobTitle = jobTitle;
              if (imageUrl) newObj.imageUrl = imageUrl;
              return newObj;
            },
          ),
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error(updateCampaign.message);
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>{'Founders & team'}</h1>
      <div>
        <h1 className={styles.title}>Founders</h1>
        <div>
          <div>
            {founderCardList?.map((data, index) => (
              // eslint-disable-next-line react/jsx-key
              <FadeEffect>
                <div key={index}>
                  <CampaignMemberCard
                    deleteMember={() => onDeleteFounderCard(index)}
                    fullName={data.fullName || ''}
                    jobTitle={data.jobTitle || ''}
                    previewImage={data.previewImage || ''}
                    imgUrl={data.imageUrl || ''}
                    twitterLink={data.twitter || ''}
                    linkedinLink={data.linkedin || ''}
                    handleDeleteImage={() => handleDeletePreviewImage(index)}
                    handleChangeImage={e => handleChangeImage(index, e)}
                    handleChange={e => handleChangeFields(index, e)}
                  />
                </div>
              </FadeEffect>
            ))}
          </div>
          <div onClick={addFounderCard} className={styles['add-founder']}>
            <img src={plusIcon} alt="add-founder" />
            <span>Add Founder</span>
          </div>
        </div>
        <div>
          <h1 className={styles.title}>Team Members</h1>
          <div>
            <div>
              {teamMemberList?.map((data, index) => (
                // eslint-disable-next-line react/jsx-key
                <FadeEffect>
                  <div key={index}>
                    <CampaignMemberCard
                      deleteMember={() => onDeleteTeamMemberCard(index)}
                      fullName={data.fullName || ''}
                      jobTitle={data.jobTitle || ''}
                      previewImage={data.previewImage || ''}
                      imgUrl={data.imageUrl || ''}
                      twitterLink={data.twitter || ''}
                      linkedinLink={data.linkedin || ''}
                      handleDeleteImage={() =>
                        handleDeleteMemeberPreviewImage(index)
                      }
                      handleChangeImage={e =>
                        handleChangeTeamMemberImage(index, e)
                      }
                      handleChange={e => handleChangeMemberFields(index, e)}
                    />
                  </div>
                </FadeEffect>
              ))}
            </div>
            <div onClick={addTeamMember} className={styles['add-founder']}>
              <img src={plusIcon} alt="add-founder" />
              <span>Add Member</span>
            </div>
          </div>
        </div>
        <div>
          <SubmitButton
            disabled={loadingUpdate}
            label="Update"
            handleClick={handleUpdate}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateCampaignFounders;
