import {Form, Input, Select, Spin} from 'antd';
import {Fragment, useEffect, useState} from 'react';
import styles from './investmentContract.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useParams} from 'react-router-dom';
import FadeEffect from 'components/animation/fadeEffect';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {useMutation, useQuery} from '@apollo/client';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';

const UpdateInvestmentContract = () => {
  const [carryPercentageTerms, setCarryPercentageTerms] = useState('');
  const [investmentContractType, setInvestmentContractType] = useState('');
  const [investmentTargetEntityLegalName, setInvestmentTargetEntityLegalName] =
    useState('');
  const [managementFeeTerms, setManagementFeeTerms] = useState('');
  const [minimumInvestmentPerMember, setMinimumInvestmentPerMember] =
    useState('');
  const [spvManagerRepresentativeName, setSpvManagerRepresentativeName] =
    useState('');
  const [spvNamePrefix, setSpvNamePrefix] = useState('');
  const {id, language} = useParams();

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });
  const {Option} = Select;
  const [updateCampaign, {loading: campaignLoading}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (campaignData?.getCampaignById.investmentContractSettings) {
      setCarryPercentageTerms(
        campaignData?.getCampaignById?.investmentContractSettings
          ?.carryPercentageTerms,
      );
      setInvestmentContractType(
        campaignData?.getCampaignById?.investmentContractSettings
          ?.investmentContractType,
      );
      setInvestmentTargetEntityLegalName(
        campaignData?.getCampaignById?.investmentContractSettings
          ?.investmentTargetEntityLegalName,
      );
      setManagementFeeTerms(
        campaignData?.getCampaignById?.investmentContractSettings
          ?.managementFeeTerms,
      );
      setMinimumInvestmentPerMember(
        campaignData?.getCampaignById?.investmentContractSettings
          ?.minimumInvestmentPerMember,
      );
      setSpvManagerRepresentativeName(
        campaignData?.getCampaignById?.investmentContractSettings
          ?.spvManagerRepresentativeName,
      );
      setSpvNamePrefix(
        campaignData?.getCampaignById?.investmentContractSettings
          ?.spvNamePrefix,
      );
    }
  }, [campaignData?.getCampaignById]);

  const handleUpdate = () => {
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          investmentContractSettings: {
            carryPercentageTerms,
            investmentContractType,
            investmentTargetEntityLegalName,
            managementFeeTerms,
            minimumInvestmentPerMember,
            spvManagerRepresentativeName,
            spvNamePrefix,
          },
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error('Please make sure all fields are not empty');
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Investment Contract Settings</h1>
      </div>
      <div>
        <Form onFinish={handleUpdate} layout="vertical">
          <Fragment>
            <FadeEffect>
              <div className={styles['question-style']}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Investment Contract Type is required',
                    },
                  ]}
                  style={{width: 320, marginRight: 20}}
                  label="Investment Contract Type"
                  name="investmentContractType">
                  <Select
                    value={investmentContractType}
                    onChange={e => setInvestmentContractType(e)}
                    defaultValue={
                      campaignData?.getCampaignById?.investmentContractSettings
                        ?.investmentContractType
                    }
                    placeholder="Select contract type"
                    name="investmentContractType">
                    <Option value="SIMPLE_AGREEMENT_FOR_FUTURE_EQUITY">
                      Simple Agreement for Future Equity
                    </Option>
                    <Option value="PRICED_EQUITY">Priced Equity</Option>
                    <Option value="DEBT">Debt</Option>
                    <Option value="REVENUE_SHARE">Revenue Share</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Investment Entity Legal Name is required',
                    },
                  ]}
                  style={{width: 320, marginRight: 20}}
                  label="Investment Target Entity Legal Name"
                  name="investmentTargetEntityLegalName">
                  <Input
                    defaultValue={
                      campaignData?.getCampaignById?.investmentContractSettings
                        ?.investmentTargetEntityLegalName
                    }
                    value={investmentTargetEntityLegalName || ''}
                    name="investmentTargetEntityLegalName"
                    onChange={e =>
                      setInvestmentTargetEntityLegalName(e.target.value)
                    }
                    placeholder="Investment Target Entity Legal Name"
                  />
                </Form.Item>
              </div>
              <div className={styles['question-style']}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Management Fee Terms is required',
                    },
                  ]}
                  style={{width: 320, marginRight: 20}}
                  label="Management Fee Terms"
                  name="managementFeeTerms">
                  <Input
                    defaultValue={
                      campaignData?.getCampaignById?.investmentContractSettings
                        ?.managementFeeTerms
                    }
                    value={managementFeeTerms || ''}
                    name="managementFeeTerms"
                    onChange={e => setManagementFeeTerms(e.target.value)}
                    placeholder="Management Fee Terms"
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Minimum Investment Per Member is required',
                    },
                  ]}
                  style={{width: 320, marginRight: 20}}
                  label="Minimum Investment Per Member"
                  name="minimumInvestmentPerMember">
                  <Input
                    defaultValue={
                      campaignData?.getCampaignById?.investmentContractSettings
                        ?.minimumInvestmentPerMember
                    }
                    value={minimumInvestmentPerMember || ''}
                    name="minimumInvestmentPerMember"
                    onChange={e =>
                      setMinimumInvestmentPerMember(e.target.value)
                    }
                    placeholder="Minimum Investment Per Member"
                  />
                </Form.Item>
              </div>
              <div className={styles['question-style']}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Spv Manager Representative Name is required',
                    },
                  ]}
                  style={{width: 320, marginRight: 20}}
                  label="Spv Manager Representative Name"
                  name="spvManagerRepresentativeName">
                  <Input
                    defaultValue={
                      campaignData?.getCampaignById?.investmentContractSettings
                        ?.spvManagerRepresentativeName
                    }
                    value={spvManagerRepresentativeName || ''}
                    name="spvManagerRepresentativeName"
                    onChange={e =>
                      setSpvManagerRepresentativeName(e.target.value)
                    }
                    placeholder="Spv Manager Representative Name"
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Spv Name Prefix is required',
                    },
                  ]}
                  style={{width: 320, marginRight: 20}}
                  label="Spv Name Prefix"
                  name="spvNamePrefix">
                  <Input
                    defaultValue={
                      campaignData?.getCampaignById?.investmentContractSettings
                        ?.spvNamePrefix
                    }
                    value={spvNamePrefix || ''}
                    name="spvNamePrefix"
                    onChange={e => setSpvNamePrefix(e.target.value)}
                    placeholder="Spv Name Prefix"
                  />
                </Form.Item>
              </div>
              <div className={styles['question-style']}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Percentage Terms',
                    },
                  ]}
                  style={{width: 320, marginRight: 20}}
                  label="Percentage Terms"
                  name="carryPercentageTerms">
                  <Input
                    defaultValue={
                      campaignData?.getCampaignById?.investmentContractSettings
                        ?.carryPercentageTerms
                    }
                    value={carryPercentageTerms || ''}
                    name="carryPercentageTerms"
                    onChange={e => setCarryPercentageTerms(e.target.value)}
                    placeholder="Percentage Terms"
                  />
                </Form.Item>
              </div>
            </FadeEffect>
          </Fragment>
        </Form>
        <SubmitButton
          disabled={campaignLoading}
          label="Update"
          handleClick={handleUpdate}
        />
      </div>
    </div>
  );
};

export default UpdateInvestmentContract;
