import {createSlice} from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'savings',
  initialState: {
    organizationData: {},
  },
  reducers: {
    addOrganizationProfile: (state, action) => {
      return {
        ...state,
        organizationData: action.payload,
      };
    },
  },
});

export default slice.reducer;

export const {addOrganizationProfile} = slice.actions;
