import {gql} from '@apollo/client';

export const GET_ALL_TRENDING_INDUSTRIES = gql`
  query GetAllTrendingIndustries {
    getAllTrendingIndustries {
      id
      trendingIndustries {
        id
        industry {
          id
          name
        }
        companies {
          id
          name
        }
        sequence
        totalAmountReceived
        totalNumOfDeals
      }
      month
      year
    }
  }
`;

export const GET_TRENDING_INDUSTRY = gql`
  query Error {
    getTrendingIndustries {
      ... on Error {
        message
        statusCode
      }
      ... on TrendingIndustriesType {
        id
        trendingIndustries {
          id
          industry {
            id
            name
            icon
          }
          companies {
            id
            name
            logoImgURL
          }
          sequence
          totalAmountReceived
          totalNumOfDeals
        }
        month
        year
        isTrending
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_COMPANY_BY_INDUSTRY_ID = gql`
  query GetCompaniesByIndustryId($industryId: ID!) {
    getCompaniesByIndustryId(industryId: $industryId) {
      ... on Error {
        message
        statusCode
      }
      ... on TrendingIndustriesType {
        id
        trendingIndustries {
          id
          industry {
            id
            name
          }
          companies {
            id
            name
            logoImgURL
            isRaising
            fundraisingHistory {
              fundingHistory {
                fundingAmount
                round
              }
            }
          }
          sequence
          totalAmountReceived
          totalNumOfDeals
        }
        month
        year
        isTrending
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_TRENDING_INDUSTRY_BY_MONTH = gql`
  query GetTrendingIndustriesByMonth($month: String!, $year: String) {
    getTrendingIndustriesByMonth(month: $month, year: $year) {
      ... on Error {
        message
        statusCode
      }
      ... on TrendingIndustriesType {
        id
        trendingIndustries {
          id
          industry {
            id
            name
          }
          companies {
            id
            name
            logoImgURL
          }
          sequence
          totalAmountReceived
          totalNumOfDeals
        }
        isTrending
        month
        year
        createdAt
        updatedAt
      }
    }
  }
`;
