import {Form, Input, Select, Spin, Switch} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styles from './bankSettings.module.scss';
import backImgIcon from 'images/back.png';
import {useMutation, useQuery} from '@apollo/client';
import {CREATE_BANK_ACCOUNT} from 'graphql/mutations/bankAndCrypto';
import {toast} from 'react-toastify';
import {GET_WIRE_TRANSFER_ACCOUNTS} from 'graphql/queries/bankAndCrypto';
import {UploadVideoToBucket} from '../../campaigns/new/screens/legalDocument/uploadDocument';
import FadeEffect from 'components/animation/fadeEffect';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import {Button} from '@dabafinance/react-components';
import UploadImage from 'components/Startup/profile/companyImage';
import {walletCurrencies} from 'utils/mock';
import {GET_ALL_COUNTRIES} from 'graphql/queries/users';

const CreateWiredAccount = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [beneficiaryName, setBeneficiaryName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [beneficiaryAddress, setBeneficiaryAddress] = useState('');
  const [accountChecking, setAccountChecking] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankAddress, setBankAddress] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [swiftCode, setSwiftcode] = useState('');
  const [loadingPdfUpload, setLoadingPdfUpload] = useState(false);
  const [pdfLink, setPdfLink] = useState('');
  const [pdfFile, setPdfFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [bankLogo, setBankLogo] = useState('');
  const [previewBankLogo, setPreviewBankLogo] = useState('');
  const [flagImage, setFlagImage] = useState('');
  const [previewFlagImage, setPreviewFlagImage] = useState('');
  const [country, setCountry] = useState('');

  const [viewPdf, setViewPdf] = useState(null);
  const [pdfName, setPdfName] = useState('Supported formats - PDF');
  const {type} = useParams();
  const navigate = useNavigate();

  const [addWireTransferAccount, {loading}] = useMutation(CREATE_BANK_ACCOUNT);
  const {data: getAllCountries, loading: loadingCountries} =
    useQuery(GET_ALL_COUNTRIES);

  const fileType = ['application/pdf'];

  useEffect(() => {
    if (country) {
      setFlagImage(country?.split('~')[2]);
      setPreviewFlagImage(country?.split('~')[2]);
    }
  }, [country]);

  const handlePdfFileChange = async e => {
    const selectedFile = e.target.files[0];
    setPdfName(selectedFile.name);
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        setLoadingPdfUpload(true);
        const imageUrl = await UploadVideoToBucket(
          selectedFile,
          'accounts-pdf-bucket',
          setProgress,
        );
        setPdfLink(imageUrl);
        setPdfFile(imageUrl);

        setLoadingPdfUpload(false);
      } else {
        setPdfFile(null);
        toast.error('Please select a valid pdf file');
      }
    } else {
      toast.error('Select a PDF file');
    }
  };

  const handleDeleteFile = () => {
    setPdfFile('');
    setPdfLink('');
    setPdfName('Supported formats pdf, docs');
    setViewPdf('');
  };

  const handlePdfFileSave = e => {
    e.preventDefault();
    setViewPdf(pdfFile);
  };

  const handleSubmit = values => {
    const optionalFields = {};
    const filterNullableFields = Object.fromEntries(
      Object.entries(values).filter(
        // eslint-disable-next-line no-unused-vars
        ([_, value]) => ![null, undefined, ''].includes(value),
      ),
    );
    if (swiftCode) optionalFields.swiftCode = swiftCode.trim();
    if (pdfLink) optionalFields.pdfDocument = pdfLink;
    if (bankLogo) optionalFields.bankLogo = bankLogo;
    const {
      iban,
      rib,
      bankCode,
      bic,
      beneficiaryName,
      beneficiaryBankName,
      routingNumber,
      accountName,
      referenceDetails,
    } = filterNullableFields;
    addWireTransferAccount({
      variables: {
        input: {
          isActive,
          accountName,
          beneficiaryName,
          accountNumber: accountNumber.trim(),
          beneficiaryAddress: beneficiaryAddress.trim(),
          routingNumber,
          flag: flagImage,
          bankLogo: bankLogo,
          bic,
          bankCode,
          rib,
          iban,
          referenceDetails,
          beneficiaryBankName,
          countryCode: country?.split('~')[1],
          countryName: country?.split('~')[0],
          currency: values.currency,
          bankAddress: bankAddress.trim(),
          accountType: accountChecking,
          bankName: bankName.trim(),
          type: type === 'us' ? 'DOMESTIC' : 'INTERNATIONAL',
          ...optionalFields,
        },
      },
      refetchQueries: [{query: GET_WIRE_TRANSFER_ACCOUNTS}],
    })
      .then(({data: {addWireTransferAccount}}) => {
        if (addWireTransferAccount.__typename === 'Error') {
          toast.error(addWireTransferAccount.message);
        } else {
          toast.success('Created Successfully');
          navigate(-1);
        }
      })
      .catch(error => toast.error(error));
  };

  return (
    <div>
      <div className={styles['header-title']}>
        <img src={backImgIcon} alt="" onClick={() => navigate(-1)} />
        <h1>
          Create a {type === 'us' ? 'Local' : 'International'} Wire Account
        </h1>
      </div>
      <div className={styles.body}>
        <Form onFinish={handleSubmit} layout="vertical">
          <div className={styles.form}>
            <Form.Item
              className={styles.width}
              name="accountName"
              label="Account Name"
              rules={[{required: true, message: 'Account Name required'}]}>
              <Input />
            </Form.Item>
            <Form.Item
              className={styles.width}
              rules={[
                {
                  required: true,
                  message: 'Please make sure the account number is valid',
                },
              ]}
              name="accountNumber"
              label="Account Number">
              <Input
                value={accountNumber}
                onChange={e => setAccountNumber(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              className={styles.width}
              rules={[
                {required: true, message: 'Please account type is required'},
              ]}
              name="accountType"
              label="Account Type">
              <Select
                value={accountChecking}
                onChange={e => setAccountChecking(e)}>
                <Select.Option value="CHECKING">Checking</Select.Option>
                <Select.Option value="SAVINGS">Saving</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.width}
              name="currency"
              rules={[
                {
                  required: true,
                  message: 'Bank Country is required',
                },
              ]}
              label="Currency">
              <Select>
                {walletCurrencies.map(data => (
                  <Select.Option key={data.key}>{data.key}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              className={styles.width}
              name="bankName"
              label="Bank Name"
              rules={[
                {
                  required: true,
                  message: 'Bank name required',
                },
              ]}>
              <Input
                value={bankName}
                onChange={e => setBankName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              className={styles.width}
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: 'Bank Country is required',
                },
              ]}>
              <Select
                value={country}
                onChange={setCountry}
                showSearch
                loading={loadingCountries}>
                {getAllCountries?.getAllCountries?.map(data => (
                  <Select.Option
                    key={`${data?.name}~${data?.countryCode}~${data?.icon}`}>
                    <div className="select-option-style">
                      <img src={data?.icon} alt="" />
                      <span>{data?.name}</span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.width}
              name="routingNumber"
              label="Bank Routing Number">
              <Input type="number" />
            </Form.Item>
            <Form.Item
              className={styles.width}
              name="bankAddress"
              label="Bank Address"
              rules={[
                {
                  required: true,
                  message: 'Bank Address required',
                },
              ]}>
              <Input.TextArea
                rows={3}
                value={bankAddress}
                onChange={e => setBankAddress(e.target.value)}
              />
            </Form.Item>
          </div>
          <h2>BENEFICIARY DETAILS</h2>
          <div className={styles.form}>
            <Form.Item
              className={styles.width}
              name="beneficiaryName"
              label="Beneficiary Name"
              rules={[{required: true, message: 'Account Name required'}]}>
              <Input
                value={beneficiaryName}
                onChange={e => setBeneficiaryName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              className={styles.width}
              name="beneficiaryBankName"
              label="Beneficiary Bank Name">
              <Input />
            </Form.Item>
            <Form.Item
              className={styles.width}
              rules={[
                {required: true, message: 'Please input beneficiary address'},
              ]}
              name="beneficiaryAddress"
              label="Beneficiary Address">
              <Input.TextArea
                rows={3}
                value={beneficiaryAddress}
                onChange={e => setBeneficiaryAddress(e.target.value)}
              />
            </Form.Item>
          </div>
          <h2>OTHER DETAILS</h2>
          <div className={styles.form}>
            <Form.Item
              className={styles.width}
              name="bic"
              label="Bank Identifier Code (BIC)">
              <Input />
            </Form.Item>
            <Form.Item
              className={styles.width}
              name="sortCode"
              label="Sort Code">
              <Input />
            </Form.Item>
            <Form.Item
              className={styles.width}
              name="bankCode"
              label="Bank Code">
              <Input />
            </Form.Item>
            <Form.Item className={styles.width} name="rib" label="RIB">
              <Input />
            </Form.Item>
            <Form.Item className={styles.width} name="iban" label="IBAN">
              <Input />
            </Form.Item>

            {type !== 'us' && (
              <>
                <Form.Item
                  className={styles.width}
                  name="swiftCode"
                  label="Swift Code"
                  rules={[
                    {
                      required: true,
                      message: 'Swift code required',
                    },
                  ]}>
                  <Input
                    value={swiftCode}
                    onChange={e => setSwiftcode(e.target.value)}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              className={styles.width}
              name="referenceDetails"
              label="Reference">
              <Input.TextArea rows={3} />
            </Form.Item>
            <Form.Item className={styles.switch} name="active" label="Active">
              <Switch value={isActive} onChange={e => setIsActive(e)} />
            </Form.Item>
          </div>

          <div className={styles.uploads}>
            <div>
              <p>
                Flag
                <span>*</span>
              </p>
              <UploadImage
                setPreviewUrl={setPreviewFlagImage}
                previewUrl={previewFlagImage}
                setImgUrl={setFlagImage}
                label="Upload Flag"
                bucket="accounts-pdf-bucket"
              />
            </div>
            <div>
              <p> Bank Logo</p>
              <UploadImage
                setPreviewUrl={setPreviewBankLogo}
                previewUrl={previewBankLogo}
                setImgUrl={setBankLogo}
                label="Upload Bank Logo"
                bucket="accounts-pdf-bucket"
              />
            </div>
          </div>

          <div>
            <h2>Upload PDF Details</h2>
            {!viewPdf && (
              <form>
                <label className={styles.upload} htmlFor="file-upload">
                  {loadingPdfUpload ? (
                    <div className={styles.spinner}>
                      <Spin size="large" />
                      <h3>{progress}%</h3>
                    </div>
                  ) : (
                    <span> {pdfName}</span>
                  )}
                  <input
                    type="file"
                    id="file-upload"
                    accept="application/pdf"
                    className={styles['input-file']}
                    required
                    onChange={handlePdfFileChange}
                  />
                </label>
              </form>
            )}

            {viewPdf && (
              <FadeEffect>
                <div className={styles['pdf-height']}>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={viewPdf}
                      plugins={[defaultLayoutPluginInstance]}
                    />
                  </Worker>
                </div>
              </FadeEffect>
            )}
            {pdfLink && (
              <div className={styles['button-wrapper']}>
                <Button
                  disabled={pdfFile === ''}
                  label="Preview PDF"
                  className={styles['preview-button']}
                  onClick={handlePdfFileSave}
                />
                <Button
                  type="secondary"
                  label="Delete"
                  onClick={handleDeleteFile}
                />
              </div>
            )}
          </div>
          <SubmitButton disabled={loading} label={'Create'} />
        </Form>
      </div>
    </div>
  );
};

export default CreateWiredAccount;
