import {useEffect, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {useNavigate, useParams} from 'react-router-dom';
import {GET_USER_BY_ID, USER_SUBSCRIPTION} from 'graphql/queries/users';
import {Table, Spin, Form, Select, Dropdown, Menu} from 'antd';
import styles from './subscription.module.scss';
import pageNotFound from 'images/pageNotFound.png';
import dabaSupport from 'images/daba-support.png';
import moment from 'moment';
import DBModal from 'components/modal/modal';
import {SubscriptionEnum} from 'utils/mock';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {UPDATE_PROFILE} from 'graphql/mutations/user';
import {toast} from 'react-toastify';
import {DownOutlined} from '@ant-design/icons';

const UsersSubscription = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState('');

  const {data, loading} = useQuery(USER_SUBSCRIPTION, {
    variables: {
      getSubscriptionByIdId: id,
    },
  });

  const {data: subscription} = useQuery(GET_USER_BY_ID, {
    variables: {
      getUserByIdId: id,
    },
  });

  useEffect(() => {
    setSubscriptionPlan(
      subscription?.getUserById?.user?.dabaSubscriptionAccess,
    );
  }, [subscription]);

  const [updateProfile, {loading: updateSubscription}] =
    useMutation(UPDATE_PROFILE);

  const handleUpdate = () => {
    updateProfile({
      variables: {
        data: {
          id,
          dabaSubscriptionAccess: subscriptionPlan,
        },
      },
    }).then(({data: {updateProfile}}) => {
      if (updateProfile.__typename === 'Error') {
        toast.error(updateProfile.message);
      } else {
        toast.success('Subscription plan updated Successfully');
        setOpenModal(false);
      }
    });
  };

  const menuOptions = (
    <Menu>
      <Menu.Item onClick={() => setOpenModal(true)} key="0">
        <span>Subscription Plan</span>
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          navigate(`/dashboard/user/${id}/subscription-price/access`)
        }
        key="1">
        <span>Subscription Access</span>
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          navigate(`/dashboard/user/${id}/subscription-price/update`)
        }
        key="2">
        <span>Subscription Price</span>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Payment',
      dataIndex: 'payment',
      key: 'payment',
      render: () => (
        <div className={styles['record-label']}>
          <img src={dabaSupport} alt="" />
          <h4 style={{width: 100}}>Daba Subscription</h4>
        </div>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'subscriptionPrice',
      key: 'amount',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>${record.subscriptionPrice.baseAmount}</h4>
        </div>
      ),
    },

    {
      title: 'Category',
      dataIndex: 'product',
      key: 'category',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>{record.product.name}</h4>
        </div>
      ),
    },
    {
      title: 'Payment Method',
      dataIndex: 'card',
      key: 'paymentMethod',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4 className={styles['payment-method']}>
            Card:****{record.card.last4}
          </h4>
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'currentPeriodStart',
      key: 'date',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>{moment(record.currentPeriodStart).format('DD MMMM')}</h4>
        </div>
      ),
    },
  ];

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>All Subscriptions</h1>
        <div>
          <Dropdown
            trigger="click"
            placement="bottomRight"
            overlay={menuOptions}>
            <button className={styles['dropdown-btn']}>
              <span>Update</span>
              <DownOutlined />
            </button>
          </Dropdown>
        </div>
      </div>
      <div>
        {data?.getSubscriptionById?.subscription?.length >= 1 ? (
          <Table
            dataSource={data?.getSubscriptionById?.subscription}
            columns={columns}
          />
        ) : (
          <div className={styles['no-update']}>
            <img src={pageNotFound} alt="" />
            <h1>No Subscription found</h1>
          </div>
        )}
      </div>
      <div>
        <DBModal
          isOpen={openModal}
          handleClose={() => setOpenModal(false)}
          content={
            <div className={styles['modal-container']}>
              <h1>Update Plan</h1>
              <div>
                <Form onFinish={handleUpdate} layout="vertical">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Please select subscription plan',
                      },
                    ]}
                    name="subscriptionPlan"
                    label="Subscription Plan"
                    className={styles.width}>
                    <Select
                      value={subscriptionPlan}
                      onChange={e => setSubscriptionPlan(e)}>
                      {SubscriptionEnum.map(data => (
                        <Select.Option key={data.key}>
                          <span>{data.name}</span>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <SubmitButton
                    disabled={updateSubscription}
                    label="Update Plan"
                  />
                </Form>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default UsersSubscription;
