import {useEffect, useState} from 'react';
import styles from './admindashboard.module.scss';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {AdminMainLayout} from '../subLayout/adminDashboard';
import {Nav} from '../../components/nav';
import Logo from '../../components/logo/logo';
import PageErrorBoundary from 'components/Error/ErrorContext';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';

const AdminDashboardLayout = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigation = useNavigate();

  useEffect(() => {
    setOpen(false);

    document.documentElement.classList.remove('_fixed');
    document.body.classList.remove('_fixed');
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={styles.layout}>
      <div
        className={`${styles.backdrop} ${open ? styles.show : ''}`}
        onClick={() => {
          setOpen(false);

          document.documentElement.classList.remove('_fixed');
          document.body.classList.remove('_fixed');
        }}></div>

      <header className={styles.header}>
        <div className={`${styles.logo} ${open ? styles.hide : ''}`}>
          <Logo onClick={() => navigation('/admin/dashboard')} />
        </div>

        <div className={styles.hamburgerBtn}>
          <button
            type="button"
            aria-label="navigation button"
            onClick={() => {
              setOpen(!open);

              document.documentElement.classList.toggle('_fixed');
              document.body.classList.toggle('_fixed');
            }}
            className={`hamburger hamburger--spin ${open ? 'is-active' : ''}`}>
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </header>

      <Nav isOpen={open} />

      <main id="daba-main" className={styles.main}>
        <ErrorBoundary>
          <PageErrorBoundary>
            <AdminMainLayout>
              <Outlet />
            </AdminMainLayout>
          </PageErrorBoundary>
        </ErrorBoundary>
      </main>
    </div>
  );
};

export {AdminDashboardLayout};
