import {useRef, useState} from 'react';
import styles from './trendingStartups.module.scss';
import {Button, Search} from '@dabafinance/react-components';
import pageNotFound from 'images/pageNotFound.png';
import {useMutation, useQuery} from '@apollo/client';
import {GET_ALL_STARTUPS} from 'graphql/queries/startupCompanies';
import {Spin} from 'antd';
import {ADD_A_TRENDING_STARTUP} from 'graphql/mutations/trendingStartups';
import {toast} from 'react-toastify';
import {GET_TRENDING_STARTUPS} from 'graphql/queries/trendingStartups';

const AddAStartup = ({closeModal}) => {
  const [searchItem, setSearchItem] = useState('');
  const [searchData, setSearchData] = useState([]);
  const searchId = useRef();
  const {loading, data} = useQuery(GET_ALL_STARTUPS);

  const handleSearch = term => {
    setSearchItem(term);
    if (term !== '') {
      const newList = data?.getAllCompanies?.filter(data => {
        return Object?.values(data)
          ?.join(' ')
          ?.toLowerCase()
          ?.includes(term.toLowerCase());
      });
      setSearchData(newList);
    } else {
      setSearchData(data?.getAllCompanies);
    }
  };
  const [addTrendingCompany] = useMutation(ADD_A_TRENDING_STARTUP);

  const handleAddStartup = id => {
    addTrendingCompany({
      variables: {
        companyId: id,
      },
      refetchQueries: [
        {query: GET_TRENDING_STARTUPS, variables: {type: 'NOW'}},
        {query: GET_ALL_STARTUPS},
      ],
    })
      .then(({data: {addTrendingCompany}}) => {
        toast.success('Updated Successfully');
        if (addTrendingCompany.__typename === 'Error') {
          toast.error(addTrendingCompany.message);
        } else {
          toast.success('Updated Successfully');
          closeModal(false);
        }
      })
      .catch(error => toast.error(error));
  };

  if (loading) {
    return (
      <div className={styles['modal-loading']}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles['modal-container']}>
      <h1>Trending Startups</h1>
      <Search
        onChange={handleSearch}
        id={searchId}
        placeholder="Search Startup"
      />

      {loading ? (
        <div className={styles['modal-loading']}>
          <Spin size="large" />
        </div>
      ) : data?.getAllCompanies?.length >= 1 ? (
        <div className={styles['startup-max-height']}>
          {(searchItem.length < 1 ? data?.getAllCompanies : searchData).map(
            ({name, ticker, logoImgURL, id, isTrending}, index) => (
              <div key={index} className={styles['add-single']}>
                <div className={styles['image-left']}>
                  <img src={logoImgURL} alt="logo" />
                  <div>
                    <h1 className={styles.header}>{name}</h1>
                    <span>{ticker}</span>
                  </div>
                </div>
                <div className={styles['button-wrapper']}>
                  <Button
                    type={isTrending ? 'primary' : 'secondary'}
                    label={isTrending ? 'Added' : 'Add'}
                    disabled={isTrending}
                    onClick={() => handleAddStartup(id)}
                  />
                </div>
              </div>
            ),
          )}
        </div>
      ) : (
        <div className={styles['page-not-found']}>
          <img src={pageNotFound} alt="Not found" />
          <h1>Startup not listed</h1>
          <span>
            The startup you searched for doesnt currently exist on daba, click
            below to add the startup before it can be added as trending
          </span>
          <div>
            <Button label="Add Startup" className={styles.button} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AddAStartup;
