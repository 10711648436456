import {Form, Switch} from 'antd';
import styles from './mobileSetting.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useMutation} from '@apollo/client';
import {UPDATE_APP_CONFIGURATION} from 'graphql/queries/mobileSettings';
import {toast} from 'react-toastify';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const AdminAuthConfig = ({enabled, refetch}) => {
  const [isEnabled, setIsEnabled] = useState(enabled);

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.PlatformSettings),
  );

  const [updateAppConfig, {loading}] = useMutation(UPDATE_APP_CONFIGURATION, {
    errorPolicy: 'all',
  });

  const handleUpdate = () => {
    updateAppConfig({
      variables: {
        data: {
          securitySettings: {
            admin2FA: isEnabled,
          },
        },
      },
    })
      .then(({data: {updateAppConfig}}) => {
        if (updateAppConfig.__typename === 'Error') {
          toast.error(updateAppConfig.message);
        } else {
          toast.success('Updated Successfully');
          refetch();
        }
      })
      .catch(error => toast.error(error));
  };

  return (
    <div className={styles.body}>
      <div className={styles.top}>
        <h1>Enable 2FA for Admin Login</h1>
        <p>
          This is going to Require an Admin to Input an OTP Code before Login
        </p>
      </div>
      <Form onFinish={handleUpdate} layout="vertical">
        <Form.Item name="admin2FA" label="Enable ">
          <Switch defaultChecked={enabled} onChange={e => setIsEnabled(e)} />
        </Form.Item>
        <SubmitButton
          disabled={loading || !tabOperations?.update}
          label="Update Settings"
        />
      </Form>
    </div>
  );
};

export default AdminAuthConfig;
