import {Form, Input, Select, Tooltip} from 'antd';
import CompanyPDFUpload from 'pages/documentCenter/create/CompanyPDFUpload';
import styles from './createStockExchnageDocument.module.scss';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {SEARCH_SEEDED_COMPANY_DOCUMENTS} from 'graphql/queries/stocks';
import {useQuery} from '@apollo/client';
import {useState} from 'react';
import _ from 'lodash';

const TranslatedStockExchangeDocument = ({
  data,
  index,
  translatedData,
  setTranslatedData,
}) => {
  const [searchResult, setSearchResult] = useState([]);
  const [limit, setLimit] = useState(50);

  const removePrefix = documentName => {
    const prefix = 'daba-finance-company-report-';
    return documentName.startsWith(prefix)
      ? documentName.substring(prefix.length)
      : documentName;
  };

  const {loading, refetch, networkStatus, fetchMore} = useQuery(
    SEARCH_SEEDED_COMPANY_DOCUMENTS,
    {
      variables: {
        filters: {
          isAddedToDocumentCenter: false,
          language: data?.language,
          pagination: {
            limit,
          },
        },
      },
      onCompleted: data =>
        setSearchResult(data?.getSeededStockExchangeDocuments?.documents),
      notifyOnNetworkStatusChange: true,
    },
  );

  const handleDeleteTranslatedPDFFile = index => {
    const newFormValues = [...translatedData];
    newFormValues[index].pdfLink = null;
    newFormValues[index].pdfFile = null;
    newFormValues[index].viewPdf = null;
    newFormValues[index].pdfName = 'Supported format - pdf';
  };

  const handleChangeFieldValue = (value, key, name) => {
    let eventValue = value;
    if (name === 'documentUrl') {
      eventValue = JSON.parse(eventValue)?.documentLink;
    }
    const newFormValues = [...translatedData];
    newFormValues[key][name] = eventValue;
    setTranslatedData(newFormValues);
  };

  const debouncedSetSearchValue = _.debounce(value => {
    const searchByTicker = {};
    if (value !== '') {
      searchByTicker.companyTickers = [value.toUpperCase()];
    }
    refetch({
      filters: {
        ...searchByTicker,
        isAddedToDocumentCenter: false,
        language: data?.language,
        pagination: {
          limit,
          page: 1,
        },
      },
    });
  }, 1200);

  const onScroll = event => {
    const target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const newLimit = limit + 50;
      setLimit(newLimit);
      fetchMore({
        variables: {
          filters: {
            isAddedToDocumentCenter: false,
            language: data?.language,
            pagination: {
              limit: newLimit,
            },
          },
        },
      });
    }
  };

  return (
    <div>
      <Form layout="vertical">
        <Form.Item label="Translated Document Name">
          <Input
            value={data?.documentName}
            onChange={e =>
              handleChangeFieldValue(e.target.value, index, 'documentName')
            }
          />
        </Form.Item>
        <Form.Item
          className={styles.fieldWidth}
          label="Document URL Source Type">
          <Select
            value={data?.documentUrlSource}
            onChange={e =>
              handleChangeFieldValue(e, index, 'documentUrlSource')
            }>
            <Select.Option key="UPLOADED">Upload PDF</Select.Option>
            <Select.Option key="SEEDED">Seeded Document</Select.Option>
          </Select>
        </Form.Item>
        <div>
          {data?.documentUrlSource === 'SEEDED' ? (
            <>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please Search Based on Document Ticker',
                  },
                ]}
                className={styles.fieldWidth}
                name="document"
                label={
                  <span>
                    Search (By TICKER) & Select TRANSLATED Document{' '}
                    <Tooltip
                      title={
                        'NOTE: You cannot Search by Document Name, Also it is filtered based on the Translated Language'
                      }>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }>
                <Select
                  onSearch={e => debouncedSetSearchValue(e)}
                  onChange={e => {
                    handleChangeFieldValue(e, index, 'documentUrl');
                  }}
                  value={data?.selectedDocument}
                  showSearch
                  defaultValue={data?.documentName}
                  onPopupScroll={onScroll}
                  filterOption={false}
                  placeholder="Search is based on Company Ticker">
                  {searchResult.length &&
                    searchResult?.map(data => (
                      <Select.Option key={JSON.stringify(data)}>
                        <span>{removePrefix(data?.documentName)}</span>
                      </Select.Option>
                    ))}
                  {(networkStatus === 2 || loading) && (
                    <Select.Option disabled={true} key="Loading..." />
                  )}
                </Select>
              </Form.Item>
              <CompanyPDFUpload
                onlyButtons={true}
                type={'SINGLE'}
                pdfFile={data?.documentUrl || data?.pdfFile}
                viewPdf={data?.documentUrl || data?.pdfFile}
              />
            </>
          ) : (
            <CompanyPDFUpload
              title="Upload Translated Stock Exchange Document"
              setExistingData={setTranslatedData}
              pdfName={data?.pdfName || 'Supported format: pdf'}
              existingData={translatedData}
              loadingPdfUpload={data?.loadingPdfUpload}
              index={index}
              pdfFile={data?.pdfFile}
              handleDeleteFile={() => handleDeleteTranslatedPDFFile(index)}
            />
          )}
        </div>
      </Form>
    </div>
  );
};

export default TranslatedStockExchangeDocument;
