import backIcon from 'images/back.png';
import styles from './campaignHeader.module.scss';
import {
  Link,
  Outlet,
  useMatch,
  useNavigate,
  useParams,
  useResolvedPath,
} from 'react-router-dom';
import classnames from 'classnames/bind';

const CustomLink = ({to, name}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({path: resolved.pathname, end: true});
  const cx = classnames.bind(styles);
  return (
    <Link className={cx('nav-link', match ? 'active-toggle' : '')} to={to}>
      <span>{name}</span>
    </Link>
  );
};

const UpdateCampaignHeader = () => {
  const navigate = useNavigate();
  const {id, test, language} = useParams();

  return (
    <div>
      <div className={styles.header}>
        <img
          onClick={() => navigate(`/dashboard/campaign/language-version/${id}`)}
          src={backIcon}
          alt=""
        />
        <h1>Update Listing</h1>
      </div>
      {test ? (
        <section className={styles.section}>
          <nav className={styles['investor-toggler']}>
            <CustomLink
              to={`/dashboard/campaign/${test}/update/${language}/${id}`}
              name="Overview"
            />
            <CustomLink
              to={`/dashboard/campaign/${test}/update/${language}/${id}/pitch`}
              name="Pitch"
            />
            <CustomLink
              to={`/dashboard/campaign/${test}/update/${language}/${id}/analysis`}
              name="Analysis"
            />
            <CustomLink
              to={`/dashboard/campaign/${test}/update/${language}/${id}/analogs`}
              name="Analogs"
            />
            <CustomLink
              to={`/dashboard/campaign/${test}/update/${language}/${id}/daba-score`}
              name="daba score"
            />
            <CustomLink
              to={`/dashboard/campaign/${test}/update/${language}/${id}/investment`}
              name="Investments"
            />
            <CustomLink
              to={`/dashboard/campaign/${test}/update/${language}/${id}/venture-fund`}
              name="Venture Fund"
            />

            <CustomLink
              to={`/dashboard/campaign/${test}/update/${language}/${id}/founders`}
              name="Founders"
            />
            <CustomLink
              to={`/dashboard/campaign/${test}/update/${language}/${id}/company-details`}
              name="Details"
            />

            <CustomLink
              to={`/dashboard/campaign/${test}/update/${language}/${id}/settings`}
              name="Settings"
            />
            <CustomLink
              to={`/dashboard/campaign/${test}/update/${language}/${id}/users-invite`}
              name="Finish"
            />
          </nav>
        </section>
      ) : (
        <section className={styles.section}>
          <nav className={styles['investor-toggler']}>
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}`}
              name="Overview"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/pitch`}
              name="Pitch"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/analysis`}
              name="Analysis"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/co-investors`}
              name="Investors"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/analogs`}
              name="Analogs"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/daba-score`}
              name="daba score"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/allocation`}
              name="Fund"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/investment`}
              name="Investments"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/external-investment`}
              name="External-Investments"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/ipo`}
              name="IPO"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/venture-fund`}
              name="Venture Fund"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/documents`}
              name="Document"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/founders`}
              name="Founders"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/faq`}
              name="FAQ"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/company-details`}
              name="Details"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/risks`}
              name="Risks, ESG"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/statistics`}
              name="Statistics"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/investment-contract`}
              name="Investment Contract"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/currency-settings`}
              name="Currency Settings"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/settings`}
              name="Settings"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/users`}
              name="Invite Users"
            />
            <CustomLink
              to={`/dashboard/campaign/update/${language}/${id}/users-invite`}
              name="Finish"
            />
          </nav>
        </section>
      )}
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default UpdateCampaignHeader;
