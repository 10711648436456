import styles from './userbookmarkedCard.module.scss';
import report from 'images/industry-report.png';
import image from 'images/avatar.png';
import news from 'images/trending.jpeg';
import expert from 'images/expert_interview_full.png';

export const UserbookmarkedCard = () => {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <img src={report} alt="" />
        <h1>Fintech in Africa</h1>
      </div>
      <div className={styles.author}>
        <img src={image} alt="" />
        <div className={styles.name}>
          <h4>Kate Simons</h4>
          <span>Q2 2021</span>
        </div>
      </div>
    </div>
  );
};

export const UserBookmarkedNewsCard = () => {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <img src={news} alt="" />
      </div>
      <div className={styles.source}>
        <h4>CNN News</h4>
        <span>2h</span>
      </div>
      <div className={styles.title}>
        <h1>Business insentives from Minister of tech</h1>
      </div>
    </div>
  );
};

export const UserSavedInterviewsCard = () => {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <img src={expert} alt="" />
        <h2 className={styles['saved-header']}>
          Interview of John Baker of Patricia
        </h2>
      </div>
    </div>
  );
};
