import {DownloadOutlined} from '@ant-design/icons';
import {Button} from '@dabafinance/react-components';
import {Tooltip} from 'antd';
import {CSVLink} from 'react-csv';
import styles from './exportButton.module.scss';

export const ExportButton = ({
  csvHeaders,
  csvData,
  fileName,
  label,
  download,
}) => {
  return (
    <CSVLink
      headers={csvHeaders}
      data={csvData}
      filename={fileName}
      type="text/csv">
      {download ? (
        <Tooltip placement="topLeft" title="Download">
          <DownloadOutlined className={styles['download-icon']} />
        </Tooltip>
      ) : (
        <Button className={styles.button} label={label} />
      )}
    </CSVLink>
  );
};
