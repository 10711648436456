import styles from './listTable.module.scss';
import {Search, Button} from '@dabafinance/react-components';
import {Table, Spin} from 'antd';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {GET_ALL_TOPIC_LIST} from 'graphql/queries/interest';
import {useQuery, useMutation} from '@apollo/client';
import pageNotFound from 'images/pageNotFound.png';
import {DELETE_TOPIC_LIST} from 'graphql/mutations/interest';
import {toast} from 'react-toastify';
import trash from 'images/trash-can-outline.svg';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';
import {useSelector} from 'react-redux';

export default function ListTable() {
  const navigate = useNavigate();
  const {loading, data} = useQuery(GET_ALL_TOPIC_LIST);
  const [searchData, setSearchData] = useState([]);

  const [deleteTopicList] = useMutation(DELETE_TOPIC_LIST);

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Topics),
  );

  const handleSearch = term => {
    if (term !== '') {
      const searchList = data?.getAllListTopics?.filter(data => {
        return Object.values(data)
          .join(' ')
          .toLowerCase()
          .includes(term.toLowerCase());
      });
      setSearchData(searchList);
    } else {
      setSearchData(data?.getAllListTopics);
    }
  };

  const handleDelete = id => {
    deleteTopicList({
      variables: {
        topicListId: id,
      },
      refetchQueries: [{query: GET_ALL_TOPIC_LIST}],
    })
      .then(({data: {deleteTopicList}}) => {
        if (deleteTopicList.__typename === 'Error') {
          toast.error(deleteTopicList.message);
        } else {
          toast.success('Topic deleted Successfully');
        }
      })
      .catch(error => toast.error(error));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <img src={record?.icon} alt="" />
          <span>{record?.name}</span>
        </div>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>{record?.listType}</span>
        </div>
      ),
    },

    {
      title: 'Opportunities',
      dataIndex: 'Opportunties',
      key: 'Opportunities',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>{record?.numOfFollowers}</span>
        </div>
      ),
    },
    {
      title: 'Companies',
      dataIndex: 'companies',
      key: 'companies',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>{record?.relatedCompanies?.length || 0}</span>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <Button
          type="secondary"
          label="View details"
          className={styles.button}
          disabled={!tabOperations?.update}
          onClick={() =>
            navigate(`/dashboard/interest/update-topic/${record?.id}`)
          }
        />
      ),
    },
  ];

  if (tabOperations?.delete) {
    columns.push({
      title: '',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <img
          onClick={() => {
            handleDelete(record.id);
          }}
          className={styles.trash}
          src={trash}
          alt=""
        />
      ),
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>All List Topics</h2>
        <div className={styles.actions}>
          <Search
            id="search-interests"
            placeholder="Search Topic Lists"
            onChange={handleSearch}
            className={styles.search}
          />
          <div>
            <Button
              disabled={!tabOperations?.create}
              label="Create Topic List"
              onClick={() => navigate('/dashboard/interest/add-topic-list')}
              className={styles['topic-button']}
            />
          </div>
        </div>
      </div>
      <div>
        {data?.getAllListTopics?.length >= 1 ? (
          <Table
            dataSource={
              searchData.length < 1 ? data?.getAllListTopics : searchData
            }
            columns={columns}
          />
        ) : (
          <div className={styles['no-update']}>
            <img src={pageNotFound} alt="" />
            <h1>No Topic List</h1>
            <span>Add a new Topic List</span>
          </div>
        )}
      </div>
    </div>
  );
}
