import styles from './country.module.scss';
import {Search, Button} from '@dabafinance/react-components';
import {useNavigate} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {GET_ALL_REPORT} from 'graphql/queries/report';
import {Spin} from 'antd';
import pageNotFound from 'images/pageNotFound.png';
import {DELETE_REPORT} from 'graphql/mutations/report';
import {toast} from 'react-toastify';
import ResearchReportCard from 'components/researchReportCard';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const Country = () => {
  const navigate = useNavigate();

  const {data, loading} = useQuery(GET_ALL_REPORT);
  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Report),
  );

  const handleNavigation = id => {
    navigate(`/dashboard/report/country/${id}`);
  };

  const [deleteReport] = useMutation(DELETE_REPORT);

  const handleDelete = id => {
    deleteReport({
      variables: {
        reportId: id,
      },
      refetchQueries: [{query: GET_ALL_REPORT}],
    }).then(({data: {deleteReport}}) => {
      if (deleteReport.__typename === 'Error') {
        toast.error(deleteReport.message);
      } else {
        toast.success('Deleted Successfully');
        navigate('/dashboard/report');
      }
    });
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>All Reports</h2>
        <div className={styles.actions}>
          <Search
            id="search-reports"
            placeholder="Search Report"
            onChange={() => {}}
            className={styles.search}
          />
          <Button
            type="primary"
            label="Add New Report"
            className={styles.button}
            onClick={() => navigate('/dashboard/report/newCountry')}
          />
        </div>
      </div>
      <div className={styles['report-grid']}>
        {data?.getAllReports?.filter(item => item?.type === 'COUNTRY')?.length >
        0 ? (
          data?.getAllReports
            ?.filter(item => item?.type === 'COUNTRY')
            ?.map(({id, title, relatedCountry, createdAt}) => (
              <ResearchReportCard
                key={id}
                title={title}
                operations={tabOperations}
                related={relatedCountry}
                createdAt={createdAt}
                onHandle={() => handleNavigation(id)}
                onDelete={() => handleDelete(id)}
              />
            ))
        ) : (
          <div className={styles['no-update']}>
            <img src={pageNotFound} alt="" />
            <h1>No Report</h1>
            <span>Add a country report</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Country;
