import {useQuery} from '@apollo/client';
import WebsiteNewsContents from '../create/WebsiteNewsContents';
import {GET_WEBSITE_NEWS_BY_ID} from 'redux/store/news/queries';
import {Spin} from 'antd';
import {useState} from 'react';
import {useParams} from 'react-router-dom';

const UpdateWebsiteNews = () => {
  const [newsData, setNewsData] = useState();
  const {id, lang} = useParams();
  const {loading} = useQuery(GET_WEBSITE_NEWS_BY_ID, {
    variables: {
      newsId: id,
      language: lang,
    },
    onCompleted: data => {
      setNewsData({
        newsTitle: data?.getWebsiteNewsById?.websiteNews?.title,
        slug: data?.getWebsiteNewsById?.websiteNews?.slug,
        metaDescription: data?.getWebsiteNewsById?.websiteNews?.metaDescription,
        keywords: data?.getWebsiteNewsById?.websiteNews?.keywords?.map(
          name => name,
        ),
        newsContent: data?.getWebsiteNewsById?.websiteNews?.content,
        imageAltText: data?.getWebsiteNewsById?.websiteNews?.imageAltText,
        newsType: data?.getWebsiteNewsById?.websiteNews?.type,
        newsKeyTake: data?.getWebsiteNewsById?.websiteNews?.keytakes,
        baseLanguage: data?.getWebsiteNewsById?.websiteNews?.language,
        summary: data?.getWebsiteNewsById?.websiteNews?.summary,
      });
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return (
      <div className="query-loading">
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }
  return newsData ? (
    <WebsiteNewsContents storedNewsData={newsData} />
  ) : (
    <div className="query-loading">
      <h1>Something happened when loading news, Please try again!</h1>
    </div>
  );
};

export default UpdateWebsiteNews;
