import {useQuery} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {Dropdown, Menu, Spin} from 'antd';
import {GET_ALL_PRODUCTS} from 'graphql/queries/plans';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './adminPlans.module.scss';
import {FreePlanCard} from './cards/PlanCards';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const HeaderCard = ({amount, text}) => {
  return (
    <div className={styles['header-card-comp']}>
      <h1>{amount}</h1>
      <span>{text}</span>
    </div>
  );
};

const AdminPlans = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('ACTIVE');

  const {data, loading} = useQuery(GET_ALL_PRODUCTS);
  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Plans),
  );

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  const overlayMenu = (
    <Menu>
      <Menu.Item
        onClick={() => navigate('/dashboard/plans/create-product')}
        key="0">
        <span>Create a Product</span>
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate('/dashboard/plans/create-plan')}
        key="1">
        <span>Create Product Price</span>
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate('/dashboard/plans/add-apple-sku')}
        key="2">
        <span>Add Apple SKU to Price</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>Plans</h1>
        <div className={styles['plan-grid']}>
          <Button
            className={styles.button}
            type="secondary"
            disabled
            onClick={() => navigate('/dashboard/plans/users')}
            label="View users"
          />
          <Dropdown trigger="click" overlay={overlayMenu}>
            <div>
              <Button
                disabled={!tabOperations?.create}
                className={styles.button}
                label="Create >"
              />
            </div>
          </Dropdown>
        </div>
      </div>
      <div className={styles['header-cards']}>
        <HeaderCard amount={0} text={'Total daba Starter Users '} />
        <HeaderCard amount={0} text={'Total daba Premium Users'} />
        <HeaderCard amount={0} text={'Total Free Package Users '} />
        <HeaderCard amount={0} text={'Total Free Trial Users'} />
      </div>
      <div className={styles['sections-tab']}>
        <div className={styles.tab}>
          <span
            className={activeTab === 'ACTIVE' ? styles['active-tab'] : ''}
            onClick={() => setActiveTab('ACTIVE')}>
            Active Product(s)
          </span>
          <span
            className={activeTab === 'INACTIVE' ? styles['active-tab'] : ''}
            onClick={() => setActiveTab('INACTIVE')}>
            Inactive Product(s)
          </span>
        </div>
      </div>
      <div className={styles.body}>
        {activeTab === 'ACTIVE' &&
          data?.listProducts?.products
            ?.filter(item => item.isActive === true)
            ?.map(data => (
              <div key={data?.id}>
                <FreePlanCard operations={tabOperations} data={data} />
              </div>
            ))}
        {activeTab === 'INACTIVE' &&
          data?.listProducts?.products
            ?.filter(item => item.isActive === false)
            ?.map(data => (
              <div key={data?.id}>
                <FreePlanCard operations={tabOperations} data={data} />
              </div>
            ))}
      </div>
    </div>
  );
};

export {AdminPlans};
