import {useMutation} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {Tooltip} from 'antd';
import {UPDATE_COMPANY_RECOMMENDATION} from 'graphql/mutations/companyStartups';
import {GET_ALL_COMPANY_RECOMMENDATIONS} from 'graphql/queries/company';
import {toast} from 'react-toastify';
import styles from './recommendation.module.scss';

const ViewCompanyRecommendation = ({data}) => {
  const [updateCompanyRecommendation, {loading: creating}] = useMutation(
    UPDATE_COMPANY_RECOMMENDATION,
  );

  const handleAdd = id => {
    updateCompanyRecommendation({
      variables: {
        recommendationId: id,
        isAdded: true,
      },
      refetchQueries: [{query: GET_ALL_COMPANY_RECOMMENDATIONS}],
    }).then(({data: {updateCompanyRecommendation}}) => {
      if (updateCompanyRecommendation.statusCode) {
        toast.error(updateCompanyRecommendation.message);
      } else {
        toast.success('Added Successfully');
      }
    });
  };

  return (
    <div className={styles['modal-container']}>
      <div className={styles.header}>
        <h2>Company Recommendations</h2>
      </div>
      <div className={styles.column}>
        <h4>User</h4>
        <h4>Company Name</h4>
        <h4>Action</h4>
      </div>
      <div className={styles.body}>
        {data?.map((value, index) => (
          <div key={index} className={styles.card}>
            <div className={styles.name} key={index}>
              <img src={value?.user?.imageUrl} className={styles.image} />
              <span>
                {value?.user?.firstName} {value?.user?.lastName}
              </span>
            </div>
            <Tooltip color="#8b82a7" title="Visit Website">
              <a target="_blank" rel="noreferrer" href={value?.websiteUrl}>
                {value?.companyName}
              </a>
            </Tooltip>
            <div>
              <Button
                label="Add"
                onClick={() => handleAdd(value.id)}
                disabled={creating}
              />
            </div>
          </div>
        ))}
      </div>
      <div></div>
    </div>
  );
};

export default ViewCompanyRecommendation;
