import {gql} from '@apollo/client';

export const GET_ALL_USER_INVITES = gql`
  query GetAllUsersInvites($page: Int, $limit: Int) {
    getAllUsersInvites(page: $page, limit: $limit) {
      totalInvitedUsers
      totalInvitedUserInvested
      totalPayout
      users {
        user {
          id
          email
          firstName
          lastName
          imageUrl
        }
        count
        userTotalRewardEarned
      }
      pagination {
        total
      }
    }
  }
`;

export const GET_USER_INVITES = gql`
  query GetUserInvites($user: ID!, $page: Int, $limit: Int) {
    getUserInvites(user: $user, page: $page, limit: $limit) {
      ... on Error {
        message
        statusCode
      }
      ... on UserReferralType {
        pagination {
          total
        }
        userReferral {
          user {
            id
            firstName
            username
            firstName
            email
            lastName
            imageUrl
          }
          invitedUser {
            id
            firstName
            lastName
            username
            imageUrl
            email
          }
          invitedDate
          referralTokenEarned
          investedAmount
          firstDeposit {
            id
            currency
            amount
          }
        }
      }
    }
  }
`;
