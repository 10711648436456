import {useMutation, useQuery} from '@apollo/client';
import {Spin, Table} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import trashIcon from 'images/trash-can-outline.svg';
import {GET_CRYPTO_DETAILS} from 'graphql/queries/bankAndCrypto';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import styles from './cryptoSettings.module.scss';
import {Button} from '@dabafinance/react-components';
import {DELETE_CRYPTO_ACCOUNT} from 'graphql/mutations/bankAndCrypto';
import {NavTabsEnum} from 'utils/constants';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';

const CryptoDetails = () => {
  const [cryptoData, setCryptoData] = useState([]);
  const navigate = useNavigate();

  const breakAddress = (address = '', width = 6) => {
    return address && `${address.slice(0, width)}...${address.slice(-width)}`;
  };

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.CryptoDetails),
  );

  const {loading} = useQuery(GET_CRYPTO_DETAILS, {
    onCompleted: data =>
      setCryptoData(data?.getDabaManualCryptoDetails?.accounts),
    onError: error => toast.error(error),
  });
  const [deleteCryptoAccount] = useMutation(DELETE_CRYPTO_ACCOUNT);

  const handleDeleteAccount = cryptoId => {
    deleteCryptoAccount({
      variables: {
        deleteCryptoAccountId: cryptoId,
      },
      refetchQueries: [{query: GET_CRYPTO_DETAILS}],
    })
      .then(({data: {deleteCryptoAccount}}) => {
        if (deleteCryptoAccount.__typename === 'Error') {
          toast.error(deleteCryptoAccount.message);
        } else {
          toast.success('Deleted Successfully');
        }
      })
      .catch(error => toast.error(error));
  };

  const columns = [
    {
      title: 'Wallet Address',
      dataIndex: 'address',
      key: 'address',
      render: (text, record) => (
        <div>{breakAddress(record?.walletAddress)}</div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <div>{record?.label}</div>,
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      render: (text, record) => <div>{record?.symbol}</div>,
    },
    {
      title: 'Network',
      dataIndex: 'network',
      key: 'network',
      render: (text, record) => <div>{record?.network}</div>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <div className={styles.actions}>
          <div>
            {tabOperations?.update && (
              <Button
                onClick={() =>
                  navigate(`/dashboard/update-crypto-account/${record?.id}`)
                }
                label="View Details"
                type="secondary"
              />
            )}
          </div>
          {tabOperations?.delete && (
            <img
              src={trashIcon}
              alt=""
              onClick={() => handleDeleteAccount(record?.id)}
            />
          )}
        </div>
      ),
    },
  ];

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <h1>Crypto Wallet Information</h1>
        <SubmitButton
          label="Create Account"
          disabled={!tabOperations?.create}
          handleClick={() => navigate('/dashboard/create-crypto-account')}
        />
      </div>
      <div>
        <Table dataSource={cryptoData} columns={columns} />
      </div>
    </div>
  );
};

export default CryptoDetails;
