import {Button} from '@dabafinance/react-components';
import styles from '../../affiliates.module.scss';
import {useMutation, useQuery} from '@apollo/client';
import {
  CREATE_AFFILIATES,
  DELETE_AFFILIATE,
  GET_ALL_AFFILIATES,
  UPDATE_AFFILIATES,
} from 'graphql/queries/affiliates';
import {
  Dropdown,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
  Table,
  Tag,
  Upload,
} from 'antd';
import {useState} from 'react';
import DBModal from 'components/modal/modal';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {
  UploadDocumentToBucket,
  resizeImageFile,
} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import imgUpload from 'images/upload_image.png';
import {toast} from 'react-toastify';
import dotsIcon from 'images/dots.svg';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';
import {useSelector} from 'react-redux';

const AffiliatesTable = ({modalContent, setModalContent}) => {
  const [deleteModal, setDeleteModal] = useState({isOpen: false});
  const [logoUrl, setLogoUrl] = useState('');
  const [loadingImageUpload, setLoadingImageUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [form] = Form.useForm();

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Affiliates),
  );

  const {data, loading, refetch} = useQuery(GET_ALL_AFFILIATES, {
    variables: {
      filter: {
        page: 1,
        limit: 500,
      },
    },
    errorPolicy: 'all',
  });

  const [createAffiliate, {loading: loadingCreate}] = useMutation(
    CREATE_AFFILIATES,
    {
      errorPolicy: 'all',
      onError: error => toast.error(error.message),
    },
  );

  const [updateAffiliate, {loading: loadingUpdate}] = useMutation(
    UPDATE_AFFILIATES,
    {
      errorPolicy: 'all',
      onError: error => toast.error(error.message),
    },
  );

  const [deleteAffiliate, {loading: loadingDelete}] = useMutation(
    DELETE_AFFILIATE,
    {
      errorPolicy: 'all',
      onError: error => toast.error(error.message),
    },
  );

  const handleChangeImage = async file => {
    const temp = file.originFileObj;
    const resizedFile = await resizeImageFile(temp);
    setLoadingImageUpload(true);
    const res = await UploadDocumentToBucket(resizedFile, setProgress);
    setLoadingImageUpload(false);
    setLogoUrl(res);
    setProgress(0);
  };

  const handleUpdateContents = values => {
    setModalContent({isOpen: true, isUpdate: true, id: values?.id});
    setLogoUrl(values?.logo);
    form.setFieldsValue({
      name: values?.name,
      type: values?.type,
      status: values?.status,
      percentage: Number(values?.percentage),
    });
  };

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Name',
      render: (text, record) => (
        <div
          className={styles.recordImage}
          color={record?.status === 'ACTIVE' ? 'green' : 'red'}>
          <img src={record?.logo} alt="" />
          <span>{record?.name}</span>
        </div>
      ),
    },
    {
      dataIndex: 'key',
      key: 'key',
      title: 'Affiliate Key',
      render: (text, record) => (
        <Tag color="default">
          <b>{record?.key}</b>
        </Tag>
      ),
    },
    {
      dataIndex: 'type',
      key: 'type',
      title: 'Type',
      render: (text, record) => <Tag color="blue">{record?.type}</Tag>,
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      render: (text, record) => (
        <Tag color={record?.status === 'ACTIVE' ? 'green' : 'red'}>
          {record?.status}
        </Tag>
      ),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: 'Actions',
      render: (text, record) => (
        <Dropdown
          trigger="hover"
          menu={{
            items: [
              {
                label: 'Update',
                key: 1,
                onClick: () => handleUpdateContents(record),
                disabled: !tabOperations?.update,
              },
              {
                key: 2,
                label: 'Delete Affiliate',
                onClick: () => setDeleteModal({isOpen: true, id: record?.id}),
                disabled: !tabOperations?.delete,
              },
            ],
          }}>
          <div>
            <img src={dotsIcon} alt="" />
          </div>
        </Dropdown>
      ),
    },
  ];

  const handleDelete = () => {
    deleteAffiliate({
      variables: {
        deleteAffiliateId: deleteModal?.id,
      },
    })
      .then(({data: {deleteAffiliate}}) => {
        if (deleteAffiliate.__typename === 'Error') {
          toast.error(deleteAffiliate.message);
        } else {
          setDeleteModal({isOpen: false});
          toast.success('Deleted Successfully');
          refetch();
          form.resetFields();
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  const handleSubmit = values => {
    if (modalContent?.isUpdate) {
      updateAffiliate({
        variables: {
          input: {
            logo: logoUrl,
            name: values?.name.trim(),
            id: modalContent?.id,
            status: values?.status,
          },
        },
      })
        .then(({data: {updateAffiliate}}) => {
          if (updateAffiliate.__typename === 'Error') {
            toast.error(updateAffiliate.message);
          } else {
            setModalContent({isOpen: false});
            toast.success('Updated Successfully');
            refetch();
            setLogoUrl('');
            form.resetFields();
          }
        })
        .catch(error => {
          toast.error(error);
        });
    } else {
      createAffiliate({
        variables: {
          input: {
            logo: logoUrl,
            name: values?.name.trim(),
            type: values?.type,
            percentage: Number(values?.percentage),
          },
        },
      })
        .then(({data: {createAffiliate}}) => {
          if (createAffiliate.__typename === 'Error') {
            toast.error(createAffiliate.message);
          } else {
            setModalContent({isOpen: false});
            refetch();

            toast.success('Created Successfully');
            form.resetFields();
          }
        })
        .catch(error => {
          toast.error(error);
        });
    }
  };

  return (
    <div>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data?.getAllAffiliates?.data || []}
      />
      <DBModal
        isOpen={modalContent?.isOpen}
        width={600}
        handleClose={() => {
          setModalContent({isOpen: false});
          setLogoUrl('');
          form.resetFields();
        }}
        content={
          <div className={styles.modalRoot}>
            <h1>{modalContent?.isUpdate ? 'Update' : 'Create'} Affiliate</h1>
            <Form form={form} onFinish={handleSubmit} layout="vertical">
              {logoUrl ? (
                <div className={styles['c-image-uploaded']}>
                  <img src={logoUrl} alt="" />
                  <Button
                    className={styles['delete-button']}
                    label="Delete Image"
                    type="secondary"
                    onClick={() => setLogoUrl('')}
                  />
                </div>
              ) : (
                <Upload
                  onChange={e => handleChangeImage(e.file)}
                  accept=".png, .jpeg, .jpg"
                  name="file">
                  <div className={styles['c-company-upload']}>
                    {loadingImageUpload ? (
                      <div className={styles['loading-spinner']}>
                        <h2>{progress}%</h2>
                        <Spin size="large" />
                        <h2>Loading...</h2>
                      </div>
                    ) : (
                      <>
                        <img
                          className={styles['company-image']}
                          src={imgUpload}
                          alt="upload"
                        />
                        <div style={{marginTop: 6}}>Upload Image</div>
                      </>
                    )}
                  </div>
                </Upload>
              )}
              <Form.Item
                rules={[{message: 'This is a required field', required: true}]}
                className={styles.modalField}
                name="name"
                label="Name">
                <Input />
              </Form.Item>
              <Form.Item
                className={styles.modalField}
                rules={[
                  {
                    message: 'This is a required field',
                    required: !modalContent?.isUpdate,
                  },
                ]}
                initialValue="TRADE_AFFILIATES"
                name="type"
                label="Type">
                <Select
                  disabled={modalContent?.isUpdate}
                  defaultValue="TRADE_AFFILIATES">
                  <Select.Option key="TRADE_AFFILIATES">
                    Trade Affiliates
                  </Select.Option>
                </Select>
              </Form.Item>

              {modalContent?.isUpdate && (
                <Form.Item
                  className={styles.modalField}
                  name="status"
                  rules={[
                    {message: 'This is a required field', required: true},
                  ]}
                  label="Status">
                  <Select>
                    <Select.Option key="ACTIVE">Active</Select.Option>
                    <Select.Option key="INACTIVE">In Active</Select.Option>
                  </Select>
                </Form.Item>
              )}
              <Form.Item name="percentage" label="Percentage">
                <InputNumber
                  disabled={modalContent?.isUpdate}
                  className={styles.numberField}
                  max={100}
                />
              </Form.Item>
              <SubmitButton
                disabled={loadingUpdate || loadingCreate}
                label={modalContent?.isUpdate ? 'Update' : 'Create'}
              />
            </Form>
          </div>
        }
      />
      <DBModal
        isOpen={deleteModal?.isOpen}
        handleClose={() => setDeleteModal({isOpen: false})}
        content={
          <div className={styles.modalContent}>
            <div className={styles.modalTop}>
              <h1>Delete Affiliate</h1>
              <p>Are you sure you want to delete this Affiliate?</p>
            </div>
            <div className={styles.buttons}>
              <Button
                label="Yes"
                onClick={handleDelete}
                disabled={loadingDelete}
              />
              <Button
                type="secondary"
                label="No"
                onClick={() => setDeleteModal({isOpen: false})}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default AffiliatesTable;
