import {useQuery} from '@apollo/client';
import {Select} from 'antd';
import {GET_ALL_USERS_PERMISSIONS} from 'graphql/queries/admin';
import {useEffect, useState} from 'react';
import {sanitizeInput} from 'utils/helper';
import _ from 'lodash';
import {toast} from 'react-toastify';

export const UserPermissionsField = ({
  permissions,
  setPermissions,
  defaultPermissions,
  tabs,
  operations,
  mode,
}) => {
  const {Option} = Select;
  const [limit, setLimit] = useState(50);
  const [fetchedData, setFetchedData] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(true);

  const {networkStatus, refetch, loading, fetchMore} = useQuery(
    GET_ALL_USERS_PERMISSIONS,
    {
      variables: {
        filter: {
          // limit: limit,
          operations,
          paginate: false,
          tabs: [tabs],
        },
      },
      onCompleted: data => {
        setHasNextPage(data?.getAllUserPermission?.pagination?.hasNextPage);
        setFetchedData(data?.getAllUserPermission?.data);
      },
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
      onError: () =>
        toast.error(
          "Please clear the particular tab you're on and search Again",
        ),
    },
  );

  const debouncedSetSearchValue = _.debounce(value => {
    const sanitizedValue = sanitizeInput(value);

    refetch({
      filter: {
        search: sanitizedValue,
      },
    });
  }, 1200);

  useEffect(() => {
    refetch({
      filter: {
        ids: defaultPermissions,
      },
    });
  }, []);

  const onScroll = event => {
    const target = event.target;
    if (!hasNextPage) return;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const newLimit = limit + 50;
      setLimit(newLimit);
      fetchMore({
        variables: {
          limit: newLimit,
        },
      });
    }
  };

  const handleSelectChange = value => {
    if (value.includes('selectAll')) {
      if (permissions.length === fetchedData.length) {
        // Deselect all if already selected
        setPermissions([]);
      } else {
        // Select all options
        setPermissions(fetchedData.map(data => data.id));
      }
    } else {
      setPermissions(value);
    }
  };

  return (
    <Select
      mode={mode === 'single' ? '' : 'multiple'}
      value={permissions}
      placeholder="Search and Select Permissions"
      allowClear
      filterOption={false}
      defaultValue={defaultPermissions}
      loading={networkStatus === 1 || loading}
      onPopupScroll={onScroll}
      onSearch={e => debouncedSetSearchValue(e)}
      onChange={handleSelectChange}>
      <>
        <Option key="selectAll" value="selectAll">
          Select All
        </Option>
        {fetchedData.length > 0 &&
          fetchedData?.map(data => (
            <Option key={`${data?.id}`}>{data?.description}</Option>
          ))}
        {networkStatus === 2 && <Option key="Loading..." />}
      </>
    </Select>
  );
};
