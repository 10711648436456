import {Table} from 'antd';

const ExternalTransactionTable = ({externalData}) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Amount Invested',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => `$${Number(record?.amount)?.toLocaleString()}`,
    },
  ];
  return (
    <div>
      <Table
        columns={columns}
        dataSource={externalData?.externallyFundedAmountBreakDown}
      />
    </div>
  );
};

export default ExternalTransactionTable;
