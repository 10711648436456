import {useLazyQuery, useQuery} from '@apollo/client';
import {Form, Input, Table} from 'antd';
import {GET_USERS_FOLLOWING_COMPANY} from 'graphql/queries/users';
import {useNavigate} from 'react-router-dom';
import styles from './companyUserInterested.module.scss';
import {useState} from 'react';
import {Button} from '@dabafinance/react-components';
import moment from 'moment';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {toast} from 'react-toastify';
import DBModal from 'components/modal/modal';

const CompanyUserInterested = ({id}) => {
  const [downloadEmail, setDownloadEmail] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();
  const {data, loading} = useQuery(GET_USERS_FOLLOWING_COMPANY, {
    variables: {
      filter: {
        companyId: id,
        limit: 10,
        page: currentPage,
      },
    },
  });
  const [getAllUsersFollowingCompany, {loading: loadingExport}] = useLazyQuery(
    GET_USERS_FOLLOWING_COMPANY,
  );

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1); // Update the currentPage state
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => prevPage - 1); // Update the currentPage state
  };

  const column = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div
          onClick={() => navigate(`/dashboard/user/${record?.id}`)}
          className={styles['record-label']}>
          <img src={record?.imageUrl} alt="" />
          <h4 style={{width: 100}}>
            {record?.firstName} {record?.lastName}
          </h4>
        </div>
      ),
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => <div>{record?.email}</div>,
    },
    {
      title: 'Joined',
      dataIndex: 'joined',
      key: 'joined',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>{moment(record?.createdAt).format('DD MMMM YYYY')}</span>
        </div>
      ),
    },
  ];

  const handleDownloadAllUsers = () => {
    if (downloadEmail) {
      getAllUsersFollowingCompany({
        variables: {
          filter: {
            email: downloadEmail,
            companyId: id,
            export: true,
          },
        },
      })
        .then(({data: {getAllUsersFollowingCompany}}) => {
          if (getAllUsersFollowingCompany.statusCode === 'Error') {
            toast.error(getAllUsersFollowingCompany.message);
          } else {
            toast.success('Successful, Please check your mail!');
            setOpenModal(false);
          }
        })
        .catch(err => toast.error(err));
    }
  };

  return (
    <div>
      <div className={styles.top}>
        <h1>Users Interested</h1>
        <SubmitButton
          label="Export Users"
          handleClick={() => setOpenModal(true)}
        />
      </div>
      <div className={styles['nav-buttons']}>
        <Button
          onClick={handlePreviousPage}
          label="Previous"
          type="secondary"
          disabled={
            !data?.getAllUsersFollowingCompany?.pagination?.hasPrevPage ||
            loading
          }
          className={styles.button}
        />
        <div className={styles['page-number']}>{currentPage}</div>
        <Button
          onClick={handleNextPage}
          label="Next"
          disabled={
            !data?.getAllUsersFollowingCompany?.pagination?.hasNextPage ||
            loading
          }
          className={styles.button}
        />
      </div>
      <Table
        loading={loading}
        columns={column}
        pagination={false}
        dataSource={data?.getAllUsersFollowingCompany?.data?.filter(Boolean)}
      />
      <DBModal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        content={
          <div className={styles.userDownloadModal}>
            <div className={styles.modalContent}>
              <div className={styles.modalHeader}>
                <h1>Export Users</h1>
                <span>
                  Export a CSV file of USERS to your Daba Email. Please Note the
                  mail might be in your spam folder
                </span>
              </div>
              <Form onFinish={handleDownloadAllUsers} layout="vertical">
                <Form.Item
                  rules={[
                    {
                      type: 'email',
                      required: true,
                      message: 'Email is Required',
                    },
                  ]}
                  name="email"
                  label="Email">
                  <Input
                    placeholder="admin@joindaba.com"
                    style={{width: 400}}
                    value={downloadEmail}
                    onChange={e => setDownloadEmail(e.target.value)}
                  />
                </Form.Item>
                <SubmitButton label="Download" disabled={loadingExport} />
              </Form>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default CompanyUserInterested;
