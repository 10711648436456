import {Button} from '@dabafinance/react-components';
import styles from './affiliateConfiguration.module.scss';
import {Spin, Upload} from 'antd';
import {handleConfigurationImageUpload} from './actions';
import {useEffect, useState} from 'react';

const ConfigurationImageUpload = ({previewImage, handleChangeImage}) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [image, setImage] = useState(previewImage);

  useEffect(() => {
    handleChangeImage(image);
  }, [image]);

  const onChangeImage = e => {
    const file = e.file;
    if (file.status === 'uploading') {
      setLoading(true);
    } else if (file.status === 'done' || file.status === 'error') {
      setLoading(false);
      handleConfigurationImageUpload(file, setImage, setLoading, setProgress);
    }
  };

  return (
    <>
      <span>
        Image dimensions must be within a range of <b>400-450px</b> by{' '}
        <b>780-830px</b>
      </span>
      <div className={styles.imageContainer}>
        {previewImage ? (
          <div className={styles['image-uploaded']}>
            <img src={previewImage} alt="" />
            <Button
              className={styles['delete-button']}
              label="Change Image"
              type="secondary"
              onClick={() => {
                handleChangeImage('');
                setImage('');
              }}
            />
          </div>
        ) : (
          <Upload
            accept=".png, .jpeg, .jpg"
            name="file"
            onChange={onChangeImage}>
            <div className={styles['upload-container']}>
              {loading ? (
                <>
                  <Spin size="small" />
                  <h2>{progress}%</h2>
                </>
              ) : (
                <>
                  <div style={{marginTop: 6}}>Upload Image</div>
                </>
              )}
            </div>
          </Upload>
        )}
      </div>
    </>
  );
};

export default ConfigurationImageUpload;
