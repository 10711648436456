import styles from './shareButton.module.scss';
import classnames from 'classnames/bind';

const ShareButton = ({
  buttonStyle,
  label,
  labelStyle,
  iconElement,
  onClick,
  cursorType,
  ...props
}) => {
  const cs = classnames.bind(styles);

  return (
    <button
      style={{cursor: cursorType === 'default' ? 'default' : 'pointer'}}
      className={cs('share-button', buttonStyle)}
      onClick={() => onClick?.()}
      {...props}>
      {iconElement || null}
      {label ? <span className={labelStyle}>{label}</span> : null}
    </button>
  );
};

export default ShareButton;
