import {useMutation} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {Spin} from 'antd';
import {CREATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {GET_ALL_CAMPAIGNS} from 'graphql/queries/campaign';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {clearCampaign} from 'redux/store/campaign';
import styles from './submit.module.scss';

const SubmitTestCampaign = ({closeModal}) => {
  const {id} = useParams();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const campaign = useSelector(state => state.campaign.campaign);
  const [createCampaign, {loading}] = useMutation(CREATE_CAMPAIGN);

  const handleCreateCampaign = () => {
    setLoader(true);
    const optionalFields = {};
    if (campaign?.audioFile) {
      optionalFields.opportunityVoiceRecording = campaign?.audioFile;
    }
    if (campaign?.videoUrl) {
      optionalFields.opportunityDemoVideo = campaign?.videoUrl;
      optionalFields.opportunityDemoVideoThumbnail =
        campaign?.opportunityThumbnail;
    }
    if (campaign?.levelOneAnalysis) {
      optionalFields.level1AnalystVideo = campaign?.levelOneAnalysis;
      optionalFields.level1AnalystVideoThumbnail = campaign?.levelOneThumbnail;
    }
    if (campaign?.levelTwoAnalysis) {
      optionalFields.level2AnalystVideo = campaign?.levelTwoAnalysis;
      optionalFields.level2AnalystVideoThumbnail = campaign?.levelTwoThumbnail;
    }

    if (campaign?.bestFit) {
      optionalFields.bestFitFor = campaign?.bestFit;
    }
    if (campaign?.vendor) {
      optionalFields.vendor = campaign?.vendor;
    }
    if (campaign?.groupId) {
      optionalFields.groupId = campaign?.groupId?.split(';')[1];
    }

    const pitchType = {};
    if (campaign?.linkType === 'EXTERNAL') {
      pitchType.externalViewLink = campaign?.externalFileLink;
      pitchType.externalShareLink = campaign?.externalShareLink;
    } else {
      pitchType.fileLink = campaign?.pitchDeckPDFLink;
    }

    // IPO Fields
    const ipoFields = {};
    if (campaign?.numSharesAllocated) {
      ipoFields.dabaNumberofSharesAllocated = Number(
        campaign?.numSharesAllocated,
      );
    }
    if (campaign?.expectedIpo) {
      ipoFields.expectedIpo = new Date(campaign?.expectedIpo).toISOString();
    }
    if (campaign?.offeringName) {
      ipoFields.offeringName = campaign?.offeringName;
    }
    if (campaign?.numOfOutstandingShares) {
      ipoFields.totalNumberofSharesOutstandingforIPO = Number(
        campaign?.numOfOutstandingShares,
      );
    }
    if (campaign?.ipoSharePrice) {
      ipoFields.ipoSharePrice = Number(campaign?.ipoSharePrice);
    }

    createCampaign({
      variables: {
        input: {
          fundraisingCompanyId: id,
          ...optionalFields,
          language: campaign?.language,
          aboutCompany: campaign?.aboutCompany,
          relatedTopics: campaign?.category?.map(data => data?.split(';')[1]),
          keyFacts: campaign?.keyFacts
            ? campaign?.keyFacts?.map(data => ({
                name: data?.name,
                value: data?.value,
              }))
            : [],
          analog: campaign.analogs
            ? campaign?.analogs?.map(data => ({
                name: data?.name,
                companyType: data?.type,
                description: data?.description,
                logo: data?.imageUrl,
                valuation: Number(data?.valuation),
              }))
            : [],
          keyMilestones: campaign?.milestones
            ? campaign?.milestones?.map(data => ({
                name: data?.name,
                value: data?.value,
              }))
            : [],
          investmentTerms: {
            minimumInvestmentAmount: Number(campaign?.minimumInvestment),
            companyValuation: Number(campaign?.valuation),
            fundingTarget: Number(campaign?.fundingTarget),
            maximumFundingTarget: Number(campaign?.maximumFundingTarget),
            minimumFundingTarget: Number(campaign?.minimumFundingTarget),
            feeSchedule: campaign?.ipoFeesValues
              ? campaign?.ipoFeesValues?.map(data => ({
                  feeName: data?.feeName,
                  feeType: data?.feeType,
                  fixedFeeAmount: Number(data?.fixedFeeAmount),
                  variableFeeAmount: Number(data?.variableFeeAmount),
                }))
              : [],
          },
          listingType: campaign?.listType,
          ventureFundFields:
            campaign?.listType === 'VENTURE_FUND'
              ? campaign?.ventureFundFields
              : null,
          ipoFields: {...ipoFields},
          missionAndVission: campaign?.mission,
          disclosure: campaign?.disclaimers,
          additionalDisclosures: campaign?.disclosures
            ? campaign?.disclosures?.map(data => ({
                name: data?.name,
                value: data?.value,
              }))
            : [],
          whatWeOffer: campaign?.offer,
          solutions: campaign?.solutions,
          pitchDeck: {
            coverImage: campaign?.pdfCoverImage,
            linkType:
              campaign?.linkType === 'INTERNAL' ? 'EXTERNAL' : 'INTERNAL',
            ...pitchType,
          },
          securityType: campaign?.securityType,
          pricePerSecurity: Number(campaign?.pricePerSecurity),
          closeCampaignOnTargetMet: campaign?.targetMet,
          recommendedPortfolioStrategy: campaign?.portfolioStrategy,
          stamp: campaign?.stamp,
          dabaScore: {
            score: Number(campaign?.dabaScore),
            business: Number(campaign?.businessValue),
            industry: Number(campaign?.industryValue),
            market: Number(campaign?.marketValue),
            team: Number(campaign?.teamValue),
            strategyProduct: Number(campaign?.strategyValue),
            accelerator: Number(campaign?.acceleratorValue),
            riskChallenges: Number(campaign?.riskValue),
            analogus: Number(campaign?.analogusValue),
            economicsAndFinance: Number(campaign?.economicValue),
            legalCompliance: Number(campaign?.complianceValue),
            returnOutlook: Number(campaign?.outlookValue),
            socialImpact: Number(campaign?.socialValue),
            pdfReport: campaign?.pdfReport,
          },
          founders: campaign?.founders?.map(
            ({fullName, imageUrl, jobTitle, twitter, linkedin}) => {
              const newObj = {};
              if (twitter) newObj.twitter = twitter;
              if (linkedin) newObj.linkedin = linkedin;
              if (fullName) newObj.fullName = fullName;
              if (jobTitle) newObj.jobTitle = jobTitle;
              if (imageUrl) newObj.imageUrl = imageUrl;
              return newObj;
            },
          ),
          teamMembers: campaign?.teamMembers?.map(
            ({fullName, imageUrl, jobTitle, twitter, linkedin}) => {
              const newObj = {};
              if (twitter) newObj.twitter = twitter;
              if (linkedin) newObj.linkedin = linkedin;
              if (fullName) newObj.fullName = fullName;
              if (jobTitle) newObj.jobTitle = jobTitle;
              if (imageUrl) newObj.imageUrl = imageUrl;
              return newObj;
            },
          ),
          socials:
            (campaign?.facebookURL && {
              facebook: campaign?.facebookURL,
            }) ||
            (campaign?.twitterURL && {
              twitter: campaign?.twitterURL,
            }) ||
            (campaign?.linkedinURL && {
              linkedin: campaign?.linkedinURL,
            }) ||
            (!campaign?.linkedinURL &&
              !campaign?.twitterURL &&
              !campaign?.facebookURL &&
              {}),
          stage: campaign?.stage,
          foundedOn: campaign?.foundedOn && Number(campaign?.foundedOn),
          location: campaign?.location,
          industry: campaign?.industry,
          website: campaign?.website,

          invitedUsers: campaign?.inviteUsers,
          invitedGroups: campaign?.inviteGroups,
          offeringType: campaign?.offeringType,
          startDate: campaign?.startDate
            ? new Date(campaign?.startDate).toISOString()
            : '',
          endDate: campaign?.finishDate
            ? new Date(campaign?.finishDate).toISOString()
            : '',
          campaignMode: 'TEST',
        },
      },
      refetchQueries: [{query: GET_ALL_CAMPAIGNS}],
    })
      .then(({data: {createCampaign}}) => {
        if (createCampaign.__typename === 'Error') {
          toast.error(
            'Make sure all fields required for a TEST campaign are validated',
          );
        } else {
          toast.success('Campaign Created Successfully');
          navigate('/dashboard/campaigns');
          dispatch(clearCampaign());
          closeModal(true);
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };
  return loader ? (
    <div className={styles.loader}>
      <Spin size="large" />
      <h1>Creating Test Campaign, Please wait...</h1>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Create This Campaign</h1>
        <span>Please make sure all data are entered correctly.</span>
      </div>
      <div>
        <Button
          label="Cancel"
          className={styles.button}
          onClick={() => closeModal(false)}
          type="secondary"
        />
        <Button
          onClick={handleCreateCampaign}
          label="Create"
          disabled={loading}
          className={styles.button}
        />
      </div>
    </div>
  );
};

export {SubmitTestCampaign};
