import {useEffect, useState} from 'react';
import styles from './userInvite.module.scss';
import {Form, Input, Select, Spin} from 'antd';
import {useMutation, useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import {GET_ALL_GROUPS} from 'graphql/queries/groups';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';
import SubmitButton from 'components/Startup/components/SubmitButton';

const UpdateUsersInvite = () => {
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [stamp, setStamp] = useState('');
  const [bestFit, setBestFit] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [templateId, setTemplateId] = useState('');

  const {Option} = Select;
  const {id, test, language} = useParams();
  const {loading: groupLoading, data: groupData} = useQuery(GET_ALL_GROUPS);

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });
  const [updateCampaign, {loading: campaignLoading}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (campaignData?.getCampaignById) {
      setSelectedGroups(
        campaignData?.getCampaignById?.invitedGroups?.map(
          data => `${data?.name};${data?.id}`,
        ),
      );
      setGroupId(campaignData?.getCampaignById?.groupId);
      setTemplateId(campaignData?.getCampaignById?.templateId);
      setStamp(campaignData?.getCampaignById?.stamp);
      setBestFit(campaignData?.getCampaignById?.bestFitFor);
    }
  }, [campaignData?.getCampaignById]);

  if (loading || groupLoading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  const handleUpdate = () => {
    const optionalFields = {};
    if (groupId) optionalFields.groupId = groupId;
    // if (bestFit) optionalFields.bestFitFor = bestFit;
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          ...optionalFields,
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          invitedGroups: selectedGroups?.map(data => data.split(';')[1]),
          stamp: stamp?.trim(),
          bestFitFor: bestFit || null,
          templateId: templateId?.trim(),
        },
      },
      refetchQueries: [
        {
          query: GET_CAMPAIGN_BY_ID,
          variables: {getCampaignByIdId: id, language},
        },
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error(updateCampaign.message);
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Update and Finish</h1>
      </div>
      <div>
        <Form layout="vertical">
          {!test && (
            <>
              <Form.Item
                className={styles.inputWidth}
                name="groups"
                label="Select Groups">
                <Select
                  mode="multiple"
                  value={selectedGroups}
                  onChange={e => setSelectedGroups(e)}
                  defaultValue={campaignData?.getCampaignById?.invitedGroups?.map(
                    data => `${data.name};${data.id}`,
                  )}
                  placeholder="Select groups">
                  {groupData?.getAllGroups?.groups?.map(data => (
                    <Option key={`${data?.name};${data.id}`}>
                      <div className={styles['group-image']}>
                        <img src={data?.bannerImg} alt="" />
                        <span>{data?.name}</span>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                className={styles.inputWidth}
                name="templateId"
                label="Template ID"
                initialValue={campaignData?.getCampaignById?.templateId}>
                <Input
                  value={templateId}
                  placeholder="Template id required"
                  onChange={e => setTemplateId(e.target.value)}
                  defaultValue={campaignData?.getCampaignById?.templateId}
                />
              </Form.Item>
            </>
          )}
          <Form.Item
            className={styles.inputWidth}
            name="group"
            label="Select Group">
            <Select
              value={groupId}
              onChange={e => setGroupId(e)}
              showSearch
              defaultValue={campaignData?.getCampaignById?.groupId}
              placeholder="Select a group for this campaign">
              {groupData?.getAllGroups?.groups?.map(data => (
                <Option key={data.id}>
                  <div className={styles['group-image']}>
                    <img src={data.bannerImg} alt="" />
                    <span>{data.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[
              {required: true, message: 'Stamp is not alllowed to be Empty'},
            ]}
            className={styles.inputWidth}
            name="stamp"
            label="Stamp">
            <Input
              value={stamp}
              onChange={e => setStamp(e.target.value)}
              defaultValue={campaignData?.getCampaignById?.stamp}
            />
          </Form.Item>
          <Form.Item
            name="bestFit"
            label="Who is this opportunity best fit for?"
            className={styles.inputWidth}
            initialValue={campaignData?.getCampaignById?.bestFitFor}>
            <Input.TextArea
              rows={4}
              value={bestFit}
              onChange={e => setBestFit(e.target.value)}
              defaultValue={campaignData?.getCampaignById?.bestFitFor}
            />
          </Form.Item>
          <div>
            <SubmitButton
              disabled={campaignLoading}
              className={styles.button}
              label="Update"
              handleClick={handleUpdate}
              smallWidth
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UpdateUsersInvite;
