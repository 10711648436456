import {useState} from 'react';
import styles from './updateSingleSavingsProduct.module.scss';
import GeneralProductsInfo from './GeneralProductsInfo';
import FinancialProductsInfo from './FinancialProductsInfo';
import backIcon from 'images/back.png';

import {useQuery} from '@apollo/client';
import {GET_SAVINGS_PRODUCT_BY_ID} from 'graphql/queries/savings';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Spin} from 'antd';
import InterestProductsInfo from './InterestProductInfo';
import BalanceProductsInfo from './BalanceProductsInfo';

const UpdateSingleSavingsProduct = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('GENERAL');
  const {id} = useParams();

  const {data, loading, refetch} = useQuery(GET_SAVINGS_PRODUCT_BY_ID, {
    variables: {
      adminGetSavingsProductByIdId: id,
    },
    fetchPolicy: 'no-cache',
    onError: error => toast.error(error.message),
  });

  const tabs = [
    {label: 'General Information', value: 'GENERAL'},
    {label: 'Financial Configuration', value: 'FINANCIAL'},
    {label: 'Interest Configurations', value: 'INTERESTS'},
    {label: 'Balance Configurations', value: 'BALANCE'},
  ];

  return (
    <div className={styles.root}>
      <div className={styles.topNav}>
        <img onClick={() => navigate(-1)} src={backIcon} />
        <h1>Update {data?.adminGetSavingsProductById?.data?.name} Product</h1>
      </div>
      <div className={styles['tab-component']}>
        <div className={styles['details-toggler']}>
          {tabs.map(tab => (
            <button
              key={tab.value}
              className={activeTab === tab.value ? styles['active-toggle'] : ''}
              onClick={() => setActiveTab(tab.value)}>
              {tab.label}
            </button>
          ))}
        </div>
        {loading ? (
          <div className="query-loading">
            <Spin />
            <h1>Loading Product...</h1>
          </div>
        ) : (
          <div className={styles.tabs}>
            {activeTab === 'GENERAL' && (
              <GeneralProductsInfo
                id={id}
                refetch={refetch}
                onActiveTabChange={setActiveTab}
                data={data?.adminGetSavingsProductById?.data}
              />
            )}
            {activeTab === 'FINANCIAL' && (
              <FinancialProductsInfo
                id={id}
                refetch={refetch}
                onActiveTabChange={setActiveTab}
                data={data?.adminGetSavingsProductById?.data}
              />
            )}
            {activeTab === 'INTERESTS' && (
              <InterestProductsInfo
                id={id}
                refetch={refetch}
                onActiveTabChange={setActiveTab}
                data={data?.adminGetSavingsProductById?.data}
              />
            )}
            {activeTab === 'BALANCE' && (
              <BalanceProductsInfo
                id={id}
                refetch={refetch}
                onActiveTabChange={setActiveTab}
                data={data?.adminGetSavingsProductById?.data}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateSingleSavingsProduct;
