import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import styles from './fundAllocation.module.scss';
import plusIcon from 'images/plus-circle.png';
import deleteIcon from 'images/delete.svg';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {Form, Input, Spin} from 'antd';
import FadeEffect from 'components/animation/fadeEffect';
import {useMutation, useQuery} from '@apollo/client';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {toast} from 'react-toastify';

const UpdateFundAllocation = () => {
  const [description, setDescription] = useState('');
  const [fundValues, setFundValues] = useState([{sector: '', percentage: 0}]);
  const {id, language} = useParams();

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });
  const [updateCampaign, {loading: loadingCampaign}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (campaignData?.getCampaignById.fundsAllocation) {
      setDescription(
        campaignData?.getCampaignById?.fundsAllocation?.description,
      );
      setFundValues(
        campaignData?.getCampaignById?.fundsAllocation?.allocations?.map(
          data => ({
            sector: data.allocationCategory,
            percentage: data.percentageValue,
          }),
        ),
      );
    }
  }, [campaignData?.getCampaignById]);

  const handleAddFunds = () => {
    setFundValues([...fundValues, {sector: '', percentage: 0}]);
  };

  const onDeleteFunds = id => {
    const filteredFormValue = fundValues.filter((item, index) => index !== id);
    setFundValues(filteredFormValue);
  };

  const handleFundsValueChange = (i, e) => {
    const newFormValues = [...fundValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFundValues(newFormValues);
  };

  const handleSave = () => {
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          fundsAllocation: {
            description,
            allocations: fundValues?.map(data => ({
              allocationCategory: data.sector,
              percentageValue: Number(data.percentage),
            })),
          },
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error(updateCampaign.message);
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div>
          <h3 className={styles.title}>Fund Allocation</h3>
        </div>
        <div>
          <Form layout="vertical">
            <Form.Item
              style={{width: 720}}
              name="description"
              label="Description">
              <Input.TextArea
                rows={6}
                defaultValue={
                  campaignData?.getCampaignById?.fundsAllocation?.description
                }
                value={description}
                onChange={e => setDescription(e.target.value)}
                name="description"
              />
            </Form.Item>
            {fundValues?.map((data, index) => (
              // eslint-disable-next-line react/jsx-key
              <FadeEffect>
                <div className={styles.form}>
                  <div key={index} className={styles['al-card']}>
                    <Form.Item label="Allocation Category">
                      <Input
                        name="sector"
                        value={data.sector}
                        onChange={e => handleFundsValueChange(index, e)}
                        style={{width: 400}}
                        placeholder="Marketing"
                      />
                    </Form.Item>
                    <Form.Item
                      style={{width: 288, marginLeft: 24}}
                      label="Percentage">
                      <Input
                        name="percentage"
                        value={data.percentage}
                        onChange={e => handleFundsValueChange(index, e)}
                        placeholder="% in numbers"
                        type="number"
                      />
                    </Form.Item>
                    <div className={styles['delete-icon']}>
                      <img
                        onClick={() => onDeleteFunds(index)}
                        src={deleteIcon}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </FadeEffect>
            ))}
          </Form>
          <div onClick={handleAddFunds} className={styles['add-button']}>
            <img src={plusIcon} alt="" />
            <h1>Add Category</h1>
          </div>
        </div>
      </div>
      <div>
        <SubmitButton
          disabled={loadingCampaign}
          label="Update"
          handleClick={handleSave}
        />
      </div>
    </div>
  );
};

export default UpdateFundAllocation;
