import gql from 'graphql-tag';
import {COMPANY} from 'graphql/fragments/company';

export const GET_COMPANIES = gql`
  ${COMPANY}
  {
    getAllCompanies {
      ... on Error {
        message
        statusCode
      }
      ... on Company {
        ...company
      }
    }
  }
`;

export const GET_ALL_COMPANIES = gql`
  query GetAllAdminCompanies($filters: GetCompanyFilters) {
    getAllAdminCompanies(filters: $filters) {
      companies {
        id
        name
        legalName
        ticker
        stage
        isTrending
        logoImgURL
        totalCapitalRaised
      }
      totalDocs
      statistics {
        totalCompanies
      }
      totalPages
      hasPrevPage
      hasNextPage
      prevPage
      nextPage
    }
    getAllCompanyRecommendations {
      companyName
      websiteUrl
      user {
        id
        firstName
        middleName
        lastName
        imageUrl
      }
      isAdded
      id
    }
  }
`;

export const GET_ALL_COMPANY_RECOMMENDATIONS = gql`
  query GetAllCompanyRecommendations {
    getAllCompanyRecommendations {
      companyName
      websiteUrl
      user {
        id
        firstName
        middleName
        lastName
        imageUrl
      }
      isAdded
      id
    }
  }
`;

export const GET_USER_INVESTOR_UPDATE = gql`
  query GetUserInvestorUpdates($user: getUserInvestorUpdateInput) {
    getUserInvestorUpdates(user: $user) {
      ... on Error {
        message
        statusCode
      }
      ... on GetUserInvestorUpdatesType {
        investorUpdates {
          id
          title
          content
          keyTakeAway
          imageUrl
          topics {
            name
            imageUrl
          }
          companies {
            name
            imageUrl
          }
          countries {
            name
            imageUrl
          }
          assignedUsers
          publishedDate
          expirationDate
          isRead
        }
      }
    }
  }
`;

export const GET_COMPANY_BY_ID = gql`
  query GetCompany($companyId: ID!) {
    getCompany(companyId: $companyId) {
      ... on Error {
        message
        statusCode
      }
      ... on Company {
        id
        name
      }
    }
  }
`;
