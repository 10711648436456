import {useQuery} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {InputNumber, Form, Select, DatePicker, Input, Tooltip} from 'antd';
import FadeEffect from 'components/animation/fadeEffect';
import DBModal from 'components/modal/modal';
import {GET_ALL_USERS_BY_NAME} from 'graphql/queries/campaign';
import moment from 'moment';
import {useState} from 'react';
import {stageData} from 'utils/mock';
import UploadImage from '../companyImage';
import styles from '../profile.module.scss';
import deleteIcon from 'images/delete.png';
import plusIcon from 'images/plus-circle.png';
import FundingHistoryTable from './fundingHistoryTable';

const FundingHistory = ({
  profileData,
  fundingHistory,
  setFundingHistory,
  externalInvestor,
  clearExternalInvestor,
  setExternalInvestor,
  stage,
  externalInvestedAmount,
  setExternalInvestedAmount,
  previewImage,
  setPreviewImage,
  externalCoInvestorsImage,
  setExternalCoInvestorsImage,
}) => {
  const [search, setSearch] = useState('');
  const [viewHistory, setViewHistory] = useState(false);
  const [activeAddIndex, setActiveAddIndex] = useState();
  const [form] = Form.useForm();

  const {Option} = Select;

  const {data: getAllUsers} = useQuery(GET_ALL_USERS_BY_NAME, {
    variables: {
      filters: {
        pagination: {
          limit: 50,
        },
        user: {
          username: search,
        },
      },
    },
  });

  const handleAddition = () => {
    setFundingHistory([
      ...fundingHistory,
      {
        round: '',
        investors: [
          {
            investorName: '',
            investedAmount: 0,
            imageUrl: '',
          },
        ],
        fundingAmount: 0,
        fundingDate: '',
        fundingType: '',
        equityPercentage: 0,
        debtPercentage: 0,
        equityAmount: 0,
        debtAmount: 0,
        companyValuation: 0,
      },
    ]);
  };

  const handleAddCoInvestor = id => {
    const updatedFundingHistory = fundingHistory?.map((item, index) => {
      return id === index
        ? {
            ...item,
            investors: [
              ...item?.investors,
              {
                investorName: '',
                investedAmount: 0,
                imageUrl: '',
              },
            ],
          }
        : item;
    });
    setFundingHistory(updatedFundingHistory);
  };

  const handleAddExternalCoInvestor = id => {
    const updatedFundingHistory = fundingHistory?.map((item, index) => {
      return id === index
        ? {
            ...item,
            investors: [
              ...item?.investors,
              {
                investorName: externalInvestor,
                investedAmount: externalInvestedAmount,
                imageUrl: externalCoInvestorsImage,
              },
            ],
          }
        : item;
    });
    setFundingHistory(updatedFundingHistory);
    clearExternalInvestor();
    form.resetFields();
    setActiveAddIndex(undefined);
  };

  const disableExternalInvestorButton =
    !externalCoInvestorsImage || !externalInvestedAmount || !externalInvestor;

  const handleChangeUser = (i, id, e) => {
    const newFormValues = [...fundingHistory];
    newFormValues[i].investors[id].investorName = e.split(';')[0];
    newFormValues[i].investors[id].imageUrl = e.split(';')[1];
    setFundingHistory(newFormValues);
  };

  const handleFundingHistoryRound = (i, e) => {
    const newListValues = [...fundingHistory];
    newListValues[i].round = e;
    setFundingHistory(newListValues);
  };
  const handleFundingHistoryAmount = (i, e) => {
    const newListValues = [...fundingHistory];
    newListValues[i].fundingAmount = e;
    setFundingHistory(newListValues);
  };
  const handleFundingHistoryCompanyValuation = (i, e) => {
    const newListValues = [...fundingHistory];
    newListValues[i].companyValuation = e;
    setFundingHistory(newListValues);
  };
  const handleFundingHistoryType = (i, e) => {
    const newListValues = [...fundingHistory];
    newListValues[i].fundingType = e;
    setFundingHistory(newListValues);
  };
  const handleFundingHistoryDate = (i, date) => {
    const newListValues = [...fundingHistory];
    newListValues[i].fundingDate = date;
    setFundingHistory(newListValues);
  };

  const handleDeleteFundingHistory = id => {
    const filteredFormValue = fundingHistory.filter(
      (data, index) => index !== id,
    );
    setFundingHistory(filteredFormValue);
  };

  const handleChangeAmount = (i, id, e) => {
    const newFormValues = [...fundingHistory];
    newFormValues[i].investors[id].investedAmount = e;
    setFundingHistory(newFormValues);
  };
  const handleDeleteCoInvestor = (id, investorIndex) => {
    const updatedFundingHistory = fundingHistory?.map((item, index) => {
      return id === index
        ? {
            ...item,
            investors: item?.investors.filter((_, i) => i !== investorIndex),
          }
        : item;
    });
    setFundingHistory(updatedFundingHistory);
  };

  const percentageEquityCalculation = (i, e) => {
    const newListValues = [...fundingHistory];
    newListValues[i].equityPercentage = e;
    newListValues[i].equityAmount = (e / 100) * newListValues[i].fundingAmount;
    setFundingHistory(newListValues);
  };
  const percentageDebtCalculation = (i, e) => {
    const newListValues = [...fundingHistory];
    newListValues[i].debtPercentage = e;
    newListValues[i].debtAmount = (e / 100) * newListValues[i].fundingAmount;
    setFundingHistory(newListValues);
  };

  const currentStage = profileData?.stage;

  const checkInvestmentStageDisable = (stageData, previousStage, index) => {
    const previousStageIndex = stageData.findIndex(stage =>
      previousStage
        ? stage.value === previousStage
        : stage.value === currentStage,
    );
    if (previousStageIndex === -1) return;
    if (previousStageIndex < index) {
      return true;
    }
  };

  return (
    <div>
      <div className={styles['fund-header']}>
        <h2 className={styles.header}>Funding History</h2>
        {fundingHistory?.length >= 1 && (
          <Button
            label={`${viewHistory ? 'Update History' : 'View History'}`}
            onClick={() => setViewHistory(toggle => !toggle)}
          />
        )}
      </div>
      <div>
        {!viewHistory &&
          fundingHistory?.map((item, index) => (
            <div key={index} className={styles['form']}>
              <div className={styles['deleteFund']}>
                <Tooltip placement="topRight" title="Delete History">
                  <img
                    onClick={() => handleDeleteFundingHistory(index)}
                    src={deleteIcon}
                    alt=""
                  />
                </Tooltip>
              </div>
              <Form layout="vertical" name="overview">
                <div className={styles['form-inputs']}>
                  <div className={styles['input-width']}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Round Required',
                        },
                      ]}
                      label="Round"
                      className={styles['form-item']}>
                      <Select
                        style={{width: 340}}
                        value={item?.round}
                        onChange={e => handleFundingHistoryRound(index, e)}
                        placeholder="SERIES_A">
                        {stageData?.map((name, index) => (
                          <Option
                            key={name?.value}
                            disabled={checkInvestmentStageDisable(
                              stageData,
                              stage,
                              index,
                            )}>
                            {name?.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className={styles['input-width']}>
                    <Form.Item
                      label="Date"
                      rules={[
                        {
                          required: true,
                          message: 'Please select funding date',
                        },
                      ]}
                      className={styles['form-item']}>
                      <DatePicker
                        style={{width: 340}}
                        value={
                          item?.fundingDate
                            ? moment(item?.fundingDate, 'YYYY/MM/DD HH:mm:ss')
                            : ''
                        }
                        format={'YYYY/MM/DD'}
                        onChange={e => handleFundingHistoryDate(index, e)}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className={styles['form-inputs']}>
                  <div className={styles['input-width']}>
                    <Form.Item
                      label="Amount Raised"
                      className={styles['form-item']}>
                      <InputNumber
                        className={styles['input-number']}
                        style={{width: 340}}
                        onChange={e => handleFundingHistoryAmount(index, e)}
                        placeholder={'Input Amount funded'}
                        value={item?.fundingAmount}
                      />
                    </Form.Item>
                  </div>
                  <div className={styles['input-width']}>
                    <Form.Item
                      label="Company Valuation in this Round"
                      className={styles['form-item']}>
                      <InputNumber
                        className={styles['input-number']}
                        style={{width: 340}}
                        onChange={e =>
                          handleFundingHistoryCompanyValuation(index, e)
                        }
                        placeholder={'$300000'}
                        value={item?.companyValuation}
                      />
                    </Form.Item>
                  </div>
                  <div className={styles['input-width']}>
                    <Form.Item
                      label="Funding Type"
                      className={styles['form-item']}>
                      <Select
                        style={{width: 340}}
                        className={styles['select-field']}
                        placeholder={'Equity'}
                        value={item?.fundingType}
                        onChange={e => handleFundingHistoryType(index, e)}>
                        <Option value="EQUITY">Equity</Option>
                        <Option value="DEBT">Debt</Option>
                        <Option value="BOTH">Equity and Debt</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                {item?.fundingType === 'BOTH' ? (
                  <>
                    <h4 className={styles['small-header']}>
                      Funding Type Percentage Breakdown
                    </h4>
                    <div className={styles['form-inputs']}>
                      <div className={styles['input-width']}>
                        <Form.Item
                          label="Equity Percentage"
                          className={styles['form-item']}>
                          <InputNumber
                            className={styles['input-number']}
                            value={item?.equityPercentage}
                            onChange={e =>
                              percentageEquityCalculation(index, e)
                            }
                            placeholder="50%"
                          />
                        </Form.Item>
                      </div>
                      <div className={styles['input-width']}>
                        <Form.Item
                          label="Debt Percentage"
                          className={styles['form-item']}>
                          <InputNumber
                            className={styles['input-number']}
                            value={item?.debtPercentage}
                            onChange={e => percentageDebtCalculation(index, e)}
                            placeholder="50%"
                          />
                        </Form.Item>
                      </div>
                      <div className={styles['input-width']}>
                        <Form.Item
                          label="Equity Amount"
                          className={styles['form-item']}>
                          <InputNumber
                            className={styles['input-number']}
                            value={item?.equityAmount}
                            placeholder="50%"
                            readOnly
                          />
                        </Form.Item>
                      </div>
                      <div className={styles['input-width']}>
                        <Form.Item
                          label="Debt Amount"
                          className={styles['form-item']}>
                          <InputNumber
                            className={styles['input-number']}
                            value={item?.debtAmount}
                            placeholder="50%"
                            readOnly
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <h4 className={styles['small-header']}>Investors</h4>
                {item?.investors?.map((value, id) => (
                  <FadeEffect key={id}>
                    <div className={styles['form-inputs']} key={id}>
                      <div className={styles['input-width']}>
                        <Form.Item label="Investor Name">
                          <Select
                            style={{width: 340}}
                            value={value?.investorName}
                            showSearch
                            onSearch={e => setSearch(e)}
                            onChange={e => handleChangeUser(index, id, e)}
                            placeholder="Investor Name">
                            {getAllUsers?.getAllUsers?.users
                              ?.filter(data => data?.firstName !== null)
                              ?.map(({firstName, lastName, imageUrl}) => (
                                <Option
                                  key={`${firstName} ${lastName};${imageUrl}`}>
                                  <div className={styles['all-data']}>
                                    <img src={imageUrl} alt="" />
                                    <span>
                                      {firstName} {lastName}
                                    </span>
                                  </div>
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className={styles['input-width']}>
                        <Form.Item label="Amount Invested">
                          <InputNumber
                            className={styles['input-number']}
                            name="investedAmount"
                            placeholder="Amount invested"
                            value={value.investedAmount}
                            onChange={e => handleChangeAmount(index, id, e)}
                          />
                        </Form.Item>
                      </div>
                      <div className={styles.delete}>
                        <img
                          onClick={() => handleDeleteCoInvestor(index, id)}
                          src={deleteIcon}
                          alt=""
                        />
                      </div>
                    </div>
                  </FadeEffect>
                ))}
                <div className={styles['form-inputs']}>
                  <div
                    onClick={() => handleAddCoInvestor(index)}
                    className={styles['add-button']}>
                    <img src={plusIcon} alt="" />
                    <h1>Add Investor</h1>
                  </div>
                  <div className={styles['add-button']}>
                    <h1 onClick={() => setActiveAddIndex(index)}>
                      Not a daba user? Create one.
                    </h1>
                  </div>
                </div>
              </Form>
            </div>
          ))}
        {viewHistory && <FundingHistoryTable fundingHistory={fundingHistory} />}
      </div>
      {!viewHistory && (
        <div onClick={handleAddition} className={styles['add-btn']}>
          <h1>Add Another Round of Funding</h1>
        </div>
      )}
      <br />
      <DBModal
        handleClose={() => {
          clearExternalInvestor();
          form.resetFields();
          setActiveAddIndex(undefined);
        }}
        isOpen={activeAddIndex !== undefined}
        content={
          <div className={styles['modal-container']}>
            <h1>Add Co-Investor</h1>
            <div>
              <Form form={form} layout="vertical">
                <div className={styles['modal-image']}>
                  <UploadImage
                    setImgUrl={setExternalCoInvestorsImage}
                    bucket={'group-images-bucket'}
                    setPreviewUrl={setPreviewImage}
                    previewUrl={previewImage}
                  />
                </div>
                <Form.Item setF name="Name" label="Investor Name">
                  <Input
                    // defaultValue={externalInvestor}

                    onChange={e => setExternalInvestor(e.target.value)}
                    placeholder="Enter name"
                  />
                </Form.Item>
                <Form.Item name="amount" label="Invested Amount">
                  <InputNumber
                    className={styles['input-modal-number']}
                    // defaultValue={externalInvestedAmount}
                    onChange={e => setExternalInvestedAmount(e)}
                    placeholder="Enter Amount"
                  />
                </Form.Item>
              </Form>
              <div className={styles.footer}>
                <Button
                  label="Update"
                  className={styles['modal-button']}
                  disabled={disableExternalInvestorButton}
                  onClick={() => handleAddExternalCoInvestor(activeAddIndex)}
                />
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default FundingHistory;
