import backImg from 'images/back.png';
import styles from './createCollectionGroup.module.scss';
import {Form, Input, Select, Spin, Switch, Tabs} from 'antd';
import UploadImage from 'components/Startup/profile/companyImage';
import {useEffect, useState} from 'react';
import {
  GET_ALL_COLLECTION_GROUPS,
  GET_ALL_COLLECTIONS,
  GET_COLLECTION_GROUP,
} from 'graphql/queries/collections';
import {useMutation, useQuery} from '@apollo/client';
import {supportedLanguages} from 'utils/constants';
import {Button} from '@dabafinance/react-components';
import {handleTranslateCollectionGroups} from './actions';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {handleSubmitCollectionGroups} from './actions';
import {
  CREATE_COLLECTION_GROUP,
  UPDATE_COLLECTION_GROUP,
} from 'graphql/mutations/collections';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useNavigate, useParams} from 'react-router-dom';

const CreateCollectionGroups = () => {
  const [language, setLanguage] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [previewImage, setPreviewImage] = useState('');
  const [image, setImage] = useState('');
  const [multiLang, setMultiLang] = useState([]);
  const [selectedTranslationLanguage, setSelectedTranslationLanguage] =
    useState('');
  const {id} = useParams();

  // RESOLVERS
  const {data, loading} = useQuery(GET_ALL_COLLECTION_GROUPS);
  const {data: getAllCollection, loadingCollections} = useQuery(
    GET_ALL_COLLECTIONS,
    {
      variables: {
        filters: {
          paginate: true,
        },
      },
    },
  );

  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );
  const [createCollectionGroup, {loading: loadingCreate}] = useMutation(
    CREATE_COLLECTION_GROUP,
  );
  const [updateCollectionGroup, {loading: loadingUpdate}] = useMutation(
    UPDATE_COLLECTION_GROUP,
  );
  const {data: collectionGroup, loading: loadingCollectionGroup} = useQuery(
    GET_COLLECTION_GROUP,
    {
      variables: {
        collectionGroupId: id,
      },
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    if (collectionGroup?.getCollectionGroup) {
      const {
        title,
        description,
        language,
        isVisible,
        multilang,
        collections,
        imageUrl,
        linkedCollectionGroups,
      } = collectionGroup?.getCollectionGroup?.data;
      setImage(imageUrl);
      setPreviewImage(imageUrl);
      setMultiLang(
        multilang?.map(data => ({
          title: data?.title,
          description: data?.description,
          language: data?.language,
        })),
      );
      form.setFieldsValue({
        title,
        language,
        description,
        linkedCollections: collections?.map(data => data?.id),
        linkedCollectionGroups: linkedCollectionGroups?.map(data => data?.id),
        isVisible,
      });
    }
  }, [collectionGroup]);

  const languageToBeTranslatedTo = supportedLanguages.filter(
    key => key.value !== form.getFieldValue('language'),
  );

  const handleChangeTranslatedValues = (key, value, name) => {
    const newFormValues = [...multiLang];
    newFormValues[key][name] = value;
    setMultiLang(newFormValues);
  };

  const translationProps = {
    sourceLang: language,
    targetLang: selectedTranslationLanguage,
    targetText1: title,
    targetText2: description,
  };

  if (loadingCollectionGroup && id) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <img onClick={() => navigate(-1)} src={backImg} alt="" />
        <h1>{id ? 'Update' : 'Create'} Collection Group</h1>
      </div>

      <Form
        onFinish={values =>
          handleSubmitCollectionGroups(
            values,
            multiLang,
            image,
            createCollectionGroup,
            updateCollectionGroup,
            navigate,
            id,
          )
        }
        form={form}
        className={styles.form}
        layout="vertical">
        <UploadImage
          bucket="campaign-bucket-images"
          previewUrl={previewImage}
          setImgUrl={setImage}
          setPreviewUrl={setPreviewImage}
          large={true}
        />
        <Form.Item
          name="language"
          className={styles.fieldWidth}
          label="Language"
          rules={[{required: true, message: 'This Field is required'}]}>
          <Select value={language} onChange={setLanguage}>
            {supportedLanguages.map(data => (
              <Select.Option key={data?.value}>{data?.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="collections"
          className={styles.fieldWidth}
          rules={[{required: true, message: 'This Field is required'}]}
          label="Linked Collections">
          <Select mode="multiple" loading={loadingCollections} allowClear>
            {getAllCollection?.adminGetAllCollections?.data?.collections?.map(
              data => (
                <Select.Option key={data?.id}>{data?.name}</Select.Option>
              ),
            )}
          </Select>
        </Form.Item>
        <Form.Item
          name="linkedCollectionGroups"
          className={styles.fieldWidth}
          label="Linked Collection Groups">
          <Select mode="multiple" loading={loading} allowClear>
            {data?.getAllCollectionGroups?.data?.collectionGroups?.map(data => (
              <Select.Option key={data?.id}>{data?.title}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="translatedLanguage"
          className={styles.fieldWidth}
          label=" Select Translation Language"
          rules={[{required: !id, message: 'This Field is required'}]}>
          <Select
            value={selectedTranslationLanguage}
            onChange={e => setSelectedTranslationLanguage(e)}>
            {languageToBeTranslatedTo.map(data => (
              <Select.Option key={data?.value}>{data?.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Tabs
          tabBarExtraContent={
            selectedTranslationLanguage && (
              <Button
                label="Translate"
                disabled={loadingTranslation}
                onClick={() =>
                  handleTranslateCollectionGroups(
                    translationProps,
                    multiLang,
                    setMultiLang,
                    translateText,
                  )
                }
              />
            )
          }>
          <Tabs.TabPane tab="Original" key="Original">
            <Form.Item
              name="title"
              className={styles.fieldWidth}
              label="Title"
              rules={[{required: true, message: 'This Field is required'}]}>
              <Input value={title} onChange={e => setTitle(e.target.value)} />
            </Form.Item>
            <Form.Item
              name="description"
              className={styles.fieldWidth}
              label="Description"
              rules={[{required: true, message: 'This Field is required'}]}>
              <Input.TextArea
                rows={4}
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Form.Item>
          </Tabs.TabPane>
          {multiLang?.length &&
            multiLang.map((data, index) => (
              <Tabs.TabPane
                tab={
                  supportedLanguages.find(lang => lang.value === data.language)
                    ?.label
                }
                key={data?.language}>
                <Form.Item
                  className={styles.fieldWidth}
                  label="Translated Title">
                  <Input
                    value={data?.title}
                    onChange={e =>
                      handleChangeTranslatedValues(
                        index,
                        e.target.value,
                        'title',
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  className={styles.fieldWidth}
                  label="Translated Description">
                  <Input.TextArea
                    rows={4}
                    value={data?.description}
                    onChange={e =>
                      handleChangeTranslatedValues(
                        index,
                        e.target.value,
                        'description',
                      )
                    }
                  />
                </Form.Item>
              </Tabs.TabPane>
            ))}
        </Tabs>
        <Form.Item
          name="isVisible"
          className={styles.fieldWidth}
          label="Visibility">
          <Switch
            defaultChecked={
              collectionGroup?.getCollectionGroup?.data?.isVisible
            }
          />
        </Form.Item>
        <SubmitButton
          label="Save Changes"
          disabled={loadingCreate || loadingUpdate}
        />
      </Form>
    </div>
  );
};

export default CreateCollectionGroups;
