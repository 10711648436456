import {gql} from '@apollo/client';

export const CREATE_COLLECTION = gql`
  mutation CreateCollection($input: CollectionInput!) {
    createCollection(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCollection {
        data {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_COLLECTION = gql`
  mutation UpdateCollection($collectionId: ID!, $input: CollectionInput!) {
    updateCollection(collectionId: $collectionId, input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCollection {
        data {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_COLLECTION = gql`
  mutation DeleteCollection($collectionId: ID!) {
    deleteCollection(collectionId: $collectionId) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;

export const UPSERT_COLLECTION_ICON = gql`
  mutation UpsertCollectionIcons($input: [CollectionIconInput!]!) {
    upsertCollectionIcons(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;

export const CREATE_COLLECTION_GROUP = gql`
  mutation CreateCollectionGroup($input: CollectionGroupInput!) {
    createCollectionGroup(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCollectionGroup {
        message
      }
    }
  }
`;

export const UPDATE_COLLECTION_GROUP = gql`
  mutation UpdateCollectionGroup(
    $collectionGroupId: ID!
    $input: CollectionGroupInput!
  ) {
    updateCollectionGroup(
      collectionGroupId: $collectionGroupId
      input: $input
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCollectionGroup {
        message
      }
    }
  }
`;

export const DELETE_COLLECTION_GROUP = gql`
  mutation DeleteCollectionGroup($collectionGroupId: ID!) {
    deleteCollectionGroup(collectionGroupId: $collectionGroupId) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;
