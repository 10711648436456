import backIcon from 'images/back.png';
import styles from './campaignHeader.module.scss';
import {
  Link,
  Outlet,
  useMatch,
  useNavigate,
  useParams,
  useResolvedPath,
} from 'react-router-dom';
import classnames from 'classnames/bind';
import {useDispatch, useSelector} from 'react-redux';
import {clearCampaign} from 'redux/store/campaign';

const CustomLink = ({to, name}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({path: resolved.pathname, end: true});
  const cx = classnames.bind(styles);
  return (
    <Link className={cx('nav-link', match ? 'active-toggle' : '')} to={to}>
      <span>{name}</span>
    </Link>
  );
};

const CreateCampaignHeader = () => {
  const navigate = useNavigate();
  const {id, slug, companyId} = useParams();
  const dispatch = useDispatch();
  const campaign = useSelector(state => state.campaign.campaign);

  const handleClose = () => {
    dispatch(clearCampaign());
    navigate('/dashboard/campaigns');
  };

  return (
    <div>
      <div className={styles.header}>
        <img onClick={handleClose} src={backIcon} alt="" />
        <h1>Create Listing</h1>
      </div>
      <section className={styles.section}>
        {slug ? (
          <nav className={styles['investor-toggler']}>
            <CustomLink
              to={`/dashboard/campaigns/${slug}/new/${id}/type/${companyId}`}
              name="List Type"
            />
            <CustomLink
              to={`/dashboard/campaigns/${slug}/new/${id}/${companyId}`}
              name="Overview"
            />
            <CustomLink
              to={`/dashboard/campaigns/${slug}/new/${id}/pitch/${companyId}`}
              name="Pitch"
            />
            <CustomLink
              to={`/dashboard/campaigns/${slug}/new/${id}/analysis/${companyId}`}
              name="Analysis"
            />
            <CustomLink
              to={`/dashboard/campaigns/${slug}/new/${id}/analogs/${companyId}`}
              name="Analogs"
            />
            <CustomLink
              to={`/dashboard/campaigns/${slug}/new/${id}/daba-score/${companyId}`}
              name="daba score"
            />

            <CustomLink
              to={`/dashboard/campaigns/${slug}/new/${id}/investment/${companyId}`}
              name="Investments"
            />

            {campaign?.listType === 'VENTURE_FUND' && (
              <CustomLink
                to={`/dashboard/campaigns/${slug}/new/${id}/venture-fund/${companyId}`}
                name="Venture Fund"
              />
            )}

            <CustomLink
              to={`/dashboard/campaigns/${slug}/new/${id}/founders/${companyId}`}
              name="Founders"
            />
            <CustomLink
              to={`/dashboard/campaigns/${slug}/new/${id}/company-details/${companyId}`}
              name="Details"
            />
            <CustomLink
              to={`/dashboard/campaigns/${slug}/new/${id}/settings/${companyId}`}
              name="Settings"
            />

            <CustomLink
              to={`/dashboard/campaigns/${slug}/new/${id}/users-invite/${companyId}`}
              name="Finish"
            />
          </nav>
        ) : (
          <nav className={styles['investor-toggler']}>
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/type/${companyId}`}
              name="List Type"
            />
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/${companyId}`}
              name="Overview"
            />
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/pitch/${companyId}`}
              name="Pitch"
            />
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/analysis/${companyId}`}
              name="Analysis"
            />
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/co-investors/${companyId}`}
              name="Investors"
            />
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/analogs/${companyId}`}
              name="Analogs"
            />
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/daba-score/${companyId}`}
              name="daba score"
            />
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/allocation/${companyId}`}
              name="Fund"
            />
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/investment/${companyId}`}
              name="Investments"
            />

            {campaign?.listType === 'IPO' && (
              <CustomLink
                to={`/dashboard/campaigns/new/${id}/ipo/${companyId}`}
                name="IPO"
              />
            )}
            {campaign?.listType === 'VENTURE_FUND' && (
              <CustomLink
                to={`/dashboard/campaigns/new/${id}/venture-fund/${companyId}`}
                name="Venture Fund"
              />
            )}
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/documents/${companyId}`}
              name="Documents"
            />
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/founders/${companyId}`}
              name="Founders"
            />
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/faq/${companyId}`}
              name="FAQ"
            />
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/company-details/${companyId}`}
              name="Details"
            />
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/risks/${companyId}`}
              name="Risks, ESG"
            />
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/statistics/${companyId}`}
              name="Statistics"
            />
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/investment-contract/${companyId}`}
              name="Investment Contract"
            />
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/currency-settings/${companyId}`}
              name="Currency Settings"
            />
            <CustomLink
              to={`/dashboard/campaigns/new/${id}/settings/${companyId}`}
              name="Settings"
            />

            <CustomLink
              to={`/dashboard/campaigns/new/${id}/users-invite/${companyId}`}
              name="Finish"
            />
          </nav>
        )}
      </section>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default CreateCampaignHeader;
