import {useMutation, useQuery} from '@apollo/client';
import {Dropdown, Form, Switch, Table, Tag} from 'antd';
import DBModal from 'components/modal/modal';
import {GET_ALL_CAMPAIGN_REPORTS} from 'graphql/queries/campaign';
import dotsIcon from 'images/dots.svg';
import {useState} from 'react';
import styles from './campaignReport.module.scss';
import {Button} from '@dabafinance/react-components';
import {
  DELETE_CAMPAIGN_REPORT,
  SEND_CAMPAIGN_REPORTS_NOW,
  UPDATE_CAMPAIGN_REPORT,
} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';
import CreateCampaignReport from './CreateCampaignReport';
import moment from 'moment';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';
import {useSelector} from 'react-redux';

const CampaignReports = () => {
  const [modalContent, setModalContents] = useState({isOpen: false});
  const {data, loading, refetch} = useQuery(GET_ALL_CAMPAIGN_REPORTS, {
    errorPolicy: 'all',
  });
  const [form] = Form.useForm();
  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.ListingReports),
  );

  const [updateCampaignReport, {loading: loadingUpdateCampaignReport}] =
    useMutation(UPDATE_CAMPAIGN_REPORT);

  const [sendCampaignReport, {loading: loadingSendReport}] = useMutation(
    SEND_CAMPAIGN_REPORTS_NOW,
  );

  const [deleteCampaignReport, {loading: loadingDelete}] = useMutation(
    DELETE_CAMPAIGN_REPORT,
  );

  const columns = [
    {
      dataIndex: 'listing',
      key: 'listing',
      title: 'Listing',
      render: (text, render) => (
        <span>{render?.campaign?.fundraisingCompany?.companyName}</span>
      ),
    },
    {
      dataIndex: 'managerName',
      key: 'managerName',
      title: 'Manager(s) Name',
      render: (text, render) =>
        render?.managers?.map((data, key) => (
          <span className={styles.manager} key={key}>
            {key + 1}. {data?.name}
          </span>
        )),
    },

    {
      dataIndex: 'email',
      key: 'email',
      title: 'Email',
      render: (text, render) =>
        render?.managers?.map((data, key) => (
          <span className={styles.manager} key={key}>
            {key + 1}. {data?.email}
          </span>
        )),
    },
    {
      dataIndex: 'language',
      key: 'language',
      title: 'Language',
      render: (text, record) => (
        <span>{record?.language === 'EN' ? 'English' : 'French'}</span>
      ),
    },
    {
      dataIndex: 'interval',
      key: 'interval',
      title: 'Interval',
    },
    {
      dataIndex: 'frequency',
      key: 'frequency',
      title: 'Frequency',
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      render: (text, record) => (
        <Tag color={record?.status === 'ACTIVE' ? 'green' : 'red'}>
          {record?.status}
        </Tag>
      ),
    },
    {
      dataIndex: 'nextSendOutDate',
      key: 'nextSendOutDate',
      title: 'Next SendOut Date',
      render: (text, render) => (
        <span>
          {render?.nextSendOutDate
            ? moment(render?.nextSendOutDate).format('DD MMM, YYYY hh:Ma')
            : ''}
        </span>
      ),
    },
    {
      dataIndex: 'Actions',
      key: 'Actions',
      title: 'Actions',
      render: (text, record) => (
        <Dropdown
          trigger="hover"
          menu={{
            items: [
              {
                label: 'Update Status',
                key: 1,
                disabled: !tabOperations?.update,
                onClick: () =>
                  setModalContents({
                    isOpen: true,
                    contents: record,
                    type: 'STATUS',
                  }),
              },
              {
                label: 'Update Report',
                key: 2,
                disabled: !tabOperations?.update,
                onClick: () =>
                  setModalContents({
                    isOpen: true,
                    contents: record,
                    type: 'UPDATE',
                  }),
              },
              {
                label: 'Send Report Now',
                key: 4,
                disabled: !tabOperations?.update,
                onClick: () =>
                  setModalContents({
                    isOpen: true,
                    contents: record,
                    type: 'SEND_NOW',
                  }),
              },
              {
                label: 'Delete Report',
                key: 3,
                disabled: !tabOperations?.delete,
                onClick: () =>
                  setModalContents({
                    isOpen: true,
                    contents: record,
                    type: 'DELETE',
                  }),
              },
            ],
          }}>
          <div>
            <img src={dotsIcon} />
          </div>
        </Dropdown>
      ),
    },
  ];
  const handleUpdateReport = values => {
    if (modalContent.type === 'DELETE') {
      deleteCampaignReport({
        variables: {
          deleteCampaignReportId: modalContent?.contents?.id,
        },
      })
        .then(({data: {deleteCampaignReport}}) => {
          if (deleteCampaignReport.__typename === 'Error') {
            toast.error(
              `Something went wrong!!! - ${deleteCampaignReport.message} `,
            );
          } else {
            toast.success('Deleted Successfully');
            setModalContents({isOpen: false});
            refetch();
          }
        })
        .catch(err => toast.error(`Something went wrong!! - ${err}`));
    } else if (modalContent?.type === 'SEND_NOW') {
      sendCampaignReport({
        variables: {
          input: {
            id: modalContent?.contents?.id,
            fromLastDate: values?.fromLastDate,
          },
        },
      })
        .then(({data: {sendCampaignReport}}) => {
          if (sendCampaignReport.__typename === 'Error') {
            toast.error(
              `Something went wrong!!! - ${sendCampaignReport.message} `,
            );
          } else {
            toast.success('Sent Successfully');
            setModalContents({isOpen: false});
            form.resetFields();
          }
        })
        .catch(err => toast.error(`Something went wrong!! - ${err}`));
    } else
      updateCampaignReport({
        variables: {
          input: {
            id: modalContent?.contents?.id,
            status:
              modalContent?.contents?.status === 'ACTIVE'
                ? 'INACTIVE'
                : 'ACTIVE',
          },
        },
      })
        .then(({data: {updateCampaignReport}}) => {
          if (updateCampaignReport.__typename === 'Error') {
            toast.error(
              `Something went wrong!!! - ${updateCampaignReport.message} `,
            );
          } else {
            toast.success('Updated Successfully');
            setModalContents({isOpen: false});
            refetch();
          }
        })
        .catch(err => toast.error(`Something went wrong!! - ${err}`));
  };
  return (
    <div>
      <div className={styles.top}>
        <h1>Listing Reports</h1>
        <Button
          label="Create Listing Report"
          onClick={() => setModalContents({isOpen: true, type: 'CREATE'})}
          disabled={!tabOperations?.create}
        />
      </div>
      <Table
        scroll={{x: 'auto'}}
        columns={columns}
        loading={loading}
        dataSource={data?.getCampaignReports?.data}
      />
      <DBModal
        isOpen={
          modalContent.isOpen &&
          ['STATUS', 'DELETE', 'SEND_NOW'].includes(modalContent?.type)
        }
        handleClose={() => setModalContents({isOpen: false})}
        content={
          <div className={styles.modal}>
            <div>
              {modalContent.type === 'DELETE' ? (
                <div>
                  <h1>Delete Report</h1>
                  <p>Are you Sure you want to Delete this report ?</p>
                </div>
              ) : modalContent.type === 'SEND_NOW' ? (
                <div>
                  <h1>Send Report</h1>
                  <p>
                    Are you Sure you want to Send Report NOW to the managers?
                  </p>
                </div>
              ) : (
                <div>
                  <h1>Update Status</h1>
                  <p>Are you Sure you want to Update this report?</p>
                </div>
              )}
              <div>
                <Form
                  onFinish={handleUpdateReport}
                  form={form}
                  layout="vertical">
                  <Form.Item
                    name="fromLastDate"
                    label="Send Report From Last Date">
                    <Switch />
                  </Form.Item>
                  <div className={styles.buttons}>
                    <SubmitButton
                      label="Yes"
                      disabled={
                        loadingUpdateCampaignReport ||
                        loadingDelete ||
                        loadingSendReport
                      }
                    />
                    <Button
                      label="No"
                      type="secondary"
                      onClick={() => setModalContents({isOpen: false})}
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        }
      />
      <DBModal
        isOpen={
          modalContent.isOpen &&
          ['UPDATE', 'CREATE'].includes(modalContent?.type)
        }
        handleClose={() => setModalContents({isOpen: false})}
        width={900}
        content={
          <CreateCampaignReport
            data={modalContent?.contents}
            onClose={() => setModalContents({isOpen: false})}
            type={modalContent?.type}
            refetch={refetch}
          />
        }
      />
    </div>
  );
};

export default CampaignReports;
