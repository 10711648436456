import {gql} from '@apollo/client';

export const GET_ALL_LEARN_ARTICLES = gql`
  query GetAllLearnArticles($page: Int, $limit: Int) {
    getAllLearnArticles(page: $page, limit: $limit) {
      learnArticle {
        id
        title
        language
        summary
        content
        featuredImage
        videoUrl
        videoThumbnail
        videoTitle
        videoType
        author {
          id
          email
          firstName
        }
      }
    }
  }
`;

export const GET_ALL_INFORMATION_SECTION = gql`
  query GetAllInformationSections($language: Languages) {
    getAllInformationSections(language: $language) {
      id
      title
    }
  }
`;

export const GET_INFORMATION_SECTION_AND_OTHER_ARTICLES = gql`
  query GetInfomationSectionAndOtherLearnArticles($page: Int, $limit: Int) {
    getInformationSectionsAndOtherLearnArticles(page: $page, limit: $limit) {
      ... on Error {
        message
        statusCode
      }
      ... on InformationSectionAndOtherArticlesType {
        page
        totalDocs
        informationSections {
          id
          title
          articles {
            id
            title
            featuredImage
            publishedDate
          }
        }
        otherArticles {
          id
          title
          featuredImage
          publishedDate
        }
      }
    }
    getAllInformationSections {
      id
      title
    }
  }
`;

export const GET_INFORMATION_BY_ID = gql`
  query GetInformationSectionById($informationSectionId: ID!) {
    getInformationSectionById(informationSectionId: $informationSectionId) {
      ... on Error {
        message
        statusCode
      }
      ... on InformationSectionType {
        informationSection {
          id
          title
          articles {
            id
            title
            featuredImage
            videoUrl
            publishedDate
          }
        }
      }
    }
  }
`;

export const GET_LEARN_ARTICLE_BY_ID = gql`
  query GetLearnArticleById($articleId: ID!) {
    getLearnArticleById(articleId: $articleId) {
      ... on Error {
        message
        statusCode
      }
      ... on LearnArticleType {
        article {
          id
          informationSection {
            id
            title
          }
          title
          summary
          content
          featuredImage
          language
          videoUrl
          pdfLink
          videoThumbnail
          videoTitle
          author {
            id
            email
            firstName
          }
          multilang {
            content
            featuredImage
            id
            informationSectionMultilang
            language
            link
            pdfLink
            summary
            title
            videoThumbnail
            videoTitle
            videoType
            videoUrl
          }
        }
      }
    }
  }
`;
