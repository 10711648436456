import {useEffect, useState} from 'react';
import styles from '../../../new/screens/pitchDeck/pitchDeck.module.scss';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {useParams} from 'react-router-dom';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {toast} from 'react-toastify';
import FadeEffect from 'components/animation/fadeEffect';
import UploadImage from 'components/Startup/profile/companyImage';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {Input, Spin, Form} from 'antd';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {useMutation, useQuery} from '@apollo/client';
import {UploadDocumentToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import {Button} from '@dabafinance/react-components';

const UpdatePitchDeck = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfLink, setPdfLink] = useState('');
  const [disableButton, setDisabledButton] = useState(true);
  const [linkType, setLinkType] = useState('');
  const [externalFileLink, setExternalFileLink] = useState('');
  const [loadingPdfUpload, setLoadingPdfUpload] = useState(false);
  const [externalShareLink, setExternalShareLink] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [viewPdf, setViewPdf] = useState(null);
  const [pdfName, setPdfName] = useState('Supported formats pdf, docs');
  const {id, language} = useParams();

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });
  const [updateCampaign, {loading: updateLoading}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (
      !imgUrl ||
      (!pdfLink && linkType === 'EXTERNAL') ||
      (pdfLink && (externalFileLink || externalShareLink))
    ) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [imgUrl, pdfLink, linkType, externalFileLink, externalShareLink]);

  useEffect(() => {
    if (campaignData) {
      setPreviewUrl(campaignData?.getCampaignById?.pitchDeck?.coverImage);
      setImgUrl(campaignData?.getCampaignById?.pitchDeck?.coverImage);
      setPdfLink(campaignData?.getCampaignById?.pitchDeck?.fileLink);
      setPdfFile(campaignData?.getCampaignById?.pitchDeck?.fileLink);
      setLinkType(campaignData?.getCampaignById?.pitchDeck?.linkType);
      setExternalFileLink(
        campaignData?.getCampaignById?.pitchDeck?.externalViewLink,
      );
      setExternalShareLink(
        campaignData?.getCampaignById?.pitchDeck?.externalShareLink,
      );
    }
  }, [campaignData]);

  const fileType = ['application/pdf'];

  const handlePdfFileChange = async e => {
    const selectedFile = e.target.files[0];

    setPdfName(selectedFile.name);
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        setLoadingPdfUpload(true);
        const imageUrl = await UploadDocumentToBucket(selectedFile);
        setPdfLink(imageUrl);
        setPdfFile(imageUrl);
        setLoadingPdfUpload(false);
      } else {
        setPdfFile(null);
        toast.error('Please select a valid pdf file');
      }
    } else {
      toast.error('Select a PDF file');
    }
  };

  const handleDeleteFile = () => {
    setPdfFile(null);
    setPdfLink(null);
    setPdfName('Supported formats pdf, docs');
    setViewPdf(null);
  };

  const handlePdfFileSave = e => {
    e.preventDefault();
    if (pdfFile !== null) {
      setViewPdf(pdfFile);
    } else {
      setViewPdf(null);
    }
  };

  const handleSubmit = () => {
    const pitchType = {};
    if (linkType === 'EXTERNAL') {
      pitchType.fileLink = pdfLink;
    } else {
      pitchType.externalViewLink = externalFileLink;
      pitchType.externalShareLink = externalShareLink;
    }

    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          pitchDeck: {
            coverImage: imgUrl,
            linkType,
            ...pitchType,
          },
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error(updateCampaign.message);
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h1>
        {!campaignData.getCampaignById.ipoFields
          ? 'Pitch Deck'
          : 'IPO Roadshow Material'}
      </h1>
      <span>
        Please note that you are only allowed to choose one type to proceed
      </span>
      <div className={styles['details-toggler']}>
        <span
          className={linkType === 'EXTERNAL' ? styles['active-toggle'] : ''}
          onClick={() => setLinkType('EXTERNAL')}>
          {' '}
          PDF UPLOAD
        </span>
        <span
          className={linkType === 'INTERNAL' ? styles['active-toggle'] : ''}
          onClick={() => setLinkType('INTERNAL')}>
          {' '}
          EXTERNAL LINK
        </span>
      </div>
      <div className={styles['pitch-body']}>
        <Form onFinish={handleSubmit} layout="vertical">
          {linkType === 'EXTERNAL' && (
            <>
              {!viewPdf && (
                <form>
                  <label className={styles.upload} htmlFor="file-upload">
                    {loadingPdfUpload ? (
                      <Spin size="large" />
                    ) : (
                      <span> {pdfName}</span>
                    )}
                    <input
                      type="file"
                      id="file-upload"
                      accept="application/pdf"
                      className={styles['input-file']}
                      required
                      onChange={handlePdfFileChange}
                    />
                  </label>
                </form>
              )}
              {viewPdf && (
                <FadeEffect>
                  <div className={styles['pdf-height']}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                      <Viewer
                        fileUrl={viewPdf}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </Worker>
                  </div>
                </FadeEffect>
              )}
            </>
          )}
          {linkType === 'INTERNAL' && (
            <>
              <Form.Item
                className={styles['field-width']}
                label="File Link"
                name="fileLink"
                initialValue={
                  campaignData?.getCampaignById?.pitchDeck?.externalViewLink
                }
                rules={[
                  {
                    required: linkType === 'INTERNAL',
                    message: 'External Link is required',
                  },
                ]}>
                <Input
                  defaultValue={
                    campaignData?.getCampaignById?.pitchDeck?.externalViewLink
                  }
                  value={externalFileLink}
                  onChange={e => setExternalFileLink(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                className={styles['field-width']}
                label="Share Link"
                name="shareLink"
                initialValue={
                  campaignData?.getCampaignById?.pitchDeck?.externalShareLink
                }
                rules={[
                  {
                    required: linkType === 'INTERNAL',
                    message: 'Share link is required',
                  },
                ]}>
                <Input
                  value={externalShareLink}
                  defaultValue={
                    campaignData?.getCampaignById?.pitchDeck?.externalShareLink
                  }
                  onChange={e => setExternalShareLink(e.target.value)}
                />
              </Form.Item>
            </>
          )}
          {(pdfLink || viewPdf) && linkType !== 'INTERNAL' && (
            <div className={styles['button-wrapper']}>
              <Button
                disabled={pdfFile === ''}
                label="Preview PDF"
                onClick={handlePdfFileSave}
              />
              <Button
                type="secondary"
                label="Delete"
                onClick={handleDeleteFile}
              />
            </div>
          )}
          <div className={styles.image}>
            <h1 className={styles.title}>
              {!campaignData.getCampaignById.ipoFields
                ? 'Select Pitch Deck Preview Image'
                : 'Select IPO Roadshow Preview Image'}
            </h1>
            <div>
              <UploadImage
                previewUrl={previewUrl}
                setPreviewUrl={setPreviewUrl}
                large
                setImgUrl={setImgUrl}
                bucket={'campaign-bucket-images'}
              />
            </div>
          </div>
          <div className={styles.submit}>
            <SubmitButton
              disabled={updateLoading || disableButton}
              label="Update"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UpdatePitchDeck;
