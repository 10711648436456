import {gql} from '@apollo/client';

export const CREATE_WEBSITE_NEWS = gql`
  mutation CreateWebsiteNews($data: WebsiteNewsInput!) {
    createWebsiteNews(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on CreateWebsiteNewsType {
        websiteNews {
          id
        }
      }
    }
  }
`;

export const GET_WEBSITE_NEWS = gql`
  query GetWebsiteNews(
    $countPage: Boolean!
    $language: multilangType
    $limit: Int
    $pageNumber: Int
    $type: GetWebsiteNewsType!
  ) {
    getWebsiteNews(
      countPage: $countPage
      language: $language
      limit: $limit
      pageNumber: $pageNumber
      type: $type
    ) {
      ... on WebsiteNewsListType {
        WebsiteNews {
          id
          language
          title
          publishedDate
          source {
            name
            url
          }
          featuredImage
          type
          tag
        }
        pagination {
          hasNextPage
          hasPreviousPage
          nextPage
          prevPage
          totalDocs
          totalPages
        }
      }
      ... on Error {
        message
        statusCode
      }
    }
  }
`;

export const GET_WEBSITE_NEWS_BY_ID = gql`
  query GetWebsiteNewsById($newsId: ID!, $language: multilangType) {
    getWebsiteNewsById(newsId: $newsId, language: $language) {
      ... on Error {
        message
        statusCode
      }
      ... on WebsiteNewsByIdType {
        websiteNews {
          categories {
            name
          }
          companies {
            name
            id
          }
          content
          createdAt
          slug
          imageAltText
          metaDescription
          featuredImage
          id
          keytakes
          keywords
          language
          publishedDate
          source {
            name
            url
          }
          tag
          title
          type
          updatedAt
          summary
        }
      }
    }
  }
`;

export const DELETE_WEBSITE_NEWS = gql`
  mutation DeleteWebsiteNews($newsId: ID!) {
    deleteWebsiteNews(newsId: $newsId) {
      ... on Error {
        message
        statusCode
      }
      ... on DeleteWebsiteNewsType {
        websiteNews {
          status
        }
      }
    }
  }
`;

export const UPDATE_WEBSITE_NEWS = gql`
  mutation UpdateWebsiteNews($newsId: ID!, $data: WebsiteNewsUpdate!) {
    updateWebsiteNews(newsId: $newsId, data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on WebsiteNewsUpdateType {
        websiteNews {
          id
        }
      }
    }
  }
`;
