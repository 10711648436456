import {useMutation} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {Form, Input} from 'antd';
import {
  CREATE_SOCIAL_MEDIA,
  DELETE_COMPANY_SOCIAL,
  UPDATE_COMPANY_SOCIAL,
} from 'graphql/mutations/companyStartups';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import styles from './socialcard.module.scss';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const SocialCard = ({media}) => {
  const [facebookUrl, setFacebookUrl] = useState('');
  const [twitterUrl, setTwitterUrl] = useState('');
  const [linkedInUrl, setLinkedinUrl] = useState('');
  const {id} = useParams();

  const pageOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Startups),
  );

  const [updateSocialMedia] = useMutation(UPDATE_COMPANY_SOCIAL);
  const [deleteSocialMedia] = useMutation(DELETE_COMPANY_SOCIAL);
  const [createSocialMedia] = useMutation(CREATE_SOCIAL_MEDIA);

  useEffect(() => {
    if (media !== null) {
      setFacebookUrl(media?.facebookUrl);
      setLinkedinUrl(media?.linkedInUrl);
      setTwitterUrl(media?.twitterUrl);
    }
  }, [media]);

  const handleSave = () => {
    if (!twitterUrl && !linkedInUrl && !facebookUrl) return;
    const newObj = {};
    if (twitterUrl) newObj.twitterUrl = twitterUrl;
    if (facebookUrl) newObj.facebookUrl = facebookUrl;
    if (linkedInUrl) newObj.linkedInUrl = linkedInUrl;
    if (media === null) {
      createSocialMedia({
        variables: {
          input: {
            companyId: id,
            ...newObj,
          },
        },
      })
        .then(({data: {createSocialMedia}}) => {
          if (createSocialMedia.__typename === 'Error') {
            toast.error(createSocialMedia.message);
          } else {
            toast.success('Updated Successfully');
          }
        })
        .catch(error => {
          toast.error(error);
        });
    } else {
      updateSocialMedia({
        variables: {
          input: {
            companyId: id,
            ...newObj,
          },
        },
      })
        .then(({data: {updateSocialMedia}}) => {
          if (updateSocialMedia.__typename === 'Error') {
            toast.error(updateSocialMedia.message);
          } else {
            toast.success('Updated Successfully');
          }
        })
        .catch(error => {
          toast.error(error);
        });
    }
  };

  const handleDelete = () => {
    deleteSocialMedia({
      variables: {
        companyId: id,
      },
      update(cache) {
        const normalizedId = cache.identify({
          id,
          __typename: 'SocialMedia',
        });
        cache.evict({id: normalizedId});
        cache.gc();
      },
    })
      .then(({data: {deleteSocialMedia}}) => {
        if (deleteSocialMedia.__typename === 'Error') {
          toast.error(deleteSocialMedia.message);
        } else {
          toast.success('Deleted Successfully');
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <div className={styles['social-container']}>
      <h1>Update Company Socials</h1>
      <Form className={styles['form-inputs']} layout="vertical">
        <Form.Item
          label="Facebook"
          rules={[
            {
              type: 'url',
              message: 'Please Input a Valid Social Link',
            },
          ]}
          name="Facebook"
          className={styles['social']}>
          <Input
            style={{width: 340}}
            defaultValue={media?.facebookUrl}
            value={facebookUrl}
            onChange={e => setFacebookUrl(e.target.value)}
            placeholder="Enter Facebook link"
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              type: 'url',
              message: 'Please Input a Valid Social Link',
            },
          ]}
          label="Twitter"
          name="Twitter"
          className={styles['social']}>
          <Input
            style={{width: 340}}
            value={twitterUrl}
            defaultValue={media?.twitterUrl}
            onChange={e => setTwitterUrl(e.target.value)}
            placeholder="Enter Twitter link"
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              type: 'url',
              message: 'Please Input a Valid Social Link',
            },
          ]}
          label="Linkedin"
          name="Linkedin"
          className={styles['social']}>
          <Input
            style={{width: 340}}
            value={linkedInUrl}
            defaultValue={media?.linkedInUrl}
            onChange={e => setLinkedinUrl(e.target.value)}
            placeholder="Enter Linkedin link"
          />
        </Form.Item>
      </Form>
      <Button
        disabled={!pageOperations?.update}
        className={styles.button}
        onClick={handleSave}
        label="Update"
      />
      <Button
        className={styles['button-red']}
        type="secondary"
        disabled={!pageOperations?.update}
        onClick={handleDelete}
        label="Delete"
      />
    </div>
  );
};

export default SocialCard;
