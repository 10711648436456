import {gql} from '@apollo/client';

export const GET_ALL_PRICES = gql`
  query ListPrices {
    listPrices {
      ... on Error {
        message
        statusCode
      }
      ... on PricesType {
        prices {
          id
          baseAmount
          discountAmount
          billingCycle
          stripeProductId
          stripePriceId
          trialPeriodDays
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTION_PRODUCTS = gql`
  query GetSubscriptionProducts {
    getSubscriptionProducts {
      ... on Error {
        message
        statusCode
      }
      ... on SubscriptionProducts {
        subscriptionProducts {
          id
          name
          prices {
            id
            billingCycle
          }
        }
      }
    }
  }
`;

export const GET_ALL_PRODUCTS = gql`
  query ListProducts {
    listProducts {
      ... on Error {
        message
        statusCode
      }
      ... on ProductsType {
        products {
          id
          description
          name
          stripeProductId
          items {
            title
            functionalities {
              isAllowed
              feature
            }
          }
          isFree
          isActive
        }
      }
    }
  }
`;
export const GET_PRODUCT_BY_ID = gql`
  query GetProductById($stripeProductId: String!) {
    getProductById(stripeProductId: $stripeProductId) {
      ... on Error {
        message
        statusCode
      }
      ... on Product {
        id
        description
        name
        isOneTime
        stripeProductId
        items {
          title
          functionalities {
            isAllowed
            feature
          }
        }
        isFree
        isActive
      }
    }
  }
`;
