import {Button} from '@dabafinance/react-components';
import {Spin} from 'antd';
import pageNotFound from 'images/pageNotFound.png';
import {useNavigate} from 'react-router-dom';
import styles from './activeAnnouncements.module.scss';
import {useQuery} from '@apollo/client';
import {GET_ALL_ACTIVE_ANNOUNCEMENT} from 'graphql/queries/announcement';
import AnnouncementCard from '../announcementCard/index';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const ActiveAnnouncements = () => {
  const navigate = useNavigate();

  const pageOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Announcements),
  );

  const {data, loading} = useQuery(GET_ALL_ACTIVE_ANNOUNCEMENT);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  console.log(pageOperations);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Active Announcements</h1>
        <div>
          <Button
            className={styles.button}
            disabled={!pageOperations?.create}
            label="Add New Announcement"
            onClick={() => navigate('/dashboard/announcements/new')}
          />
        </div>
      </div>
      <div className={styles.cards}>
        {data?.getAllActiveAnnouncements?.announcement?.length >= 1 ? (
          data?.getAllActiveAnnouncements?.announcement?.map(
            ({type, publishedDate, imageUrl, id}) => (
              <div key={id}>
                <AnnouncementCard
                  date={publishedDate}
                  type={type}
                  id={id}
                  operations={pageOperations}
                  image={imageUrl}
                  index={id}
                />
              </div>
            ),
          )
        ) : (
          <div className={styles['no-update']}>
            <img src={pageNotFound} alt="" />
            <h1>No Active Announcement</h1>
            <span>Add a new announcement</span>
          </div>
        )}
      </div>
    </div>
  );
};

export {ActiveAnnouncements};
