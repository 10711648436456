import {gql} from '@apollo/client';

export const UPDATE_INVESTOR_UPDATE = gql`
  mutation UpdateInvestorUpdate($data: UpdateInvestorUpdateInput!) {
    updateInvestorUpdate(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on InvestorUpdateType {
        investorUpdate {
          id
          title
          content
          keyTakeAway
          imageUrl
          websiteLink
          topics {
            id
            name
            icon
            type
          }
          companies {
            id
            name
            legalName
          }
          countries {
            id
            name
          }
          isExpired
          isPublished
          assignedUsers
          publishedDate
          expirationDate
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const DELETE_INVESTOR_UPDATE = gql`
  mutation DeleteInvestorUpdate($deleteInvestorUpdateId: ID!) {
    deleteInvestorUpdate(id: $deleteInvestorUpdateId) {
      ... on Error {
        message
        statusCode
      }
      ... on InvestorUpdateType {
        investorUpdate {
          id
          title
          content
          keyTakeAway
          imageUrl
        }
      }
    }
  }
`;

export const CREATE_INVESTOR_UPDATE = gql`
  mutation CreateInvestorUpdate($data: InvestorUpdateInput!) {
    createInvestorUpdate(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on InvestorUpdateType {
        investorUpdate {
          id
          title
          content
          keyTakeAway
          imageUrl
        }
      }
    }
  }
`;

export const ADD_TOPIC_TO_COMPANY = gql`
  mutation AddTopicsToCompany($companyId: ID!, $topicIds: [ID!]!) {
    addTopicsToCompany(companyId: $companyId, topicIds: $topicIds) {
      ... on Error {
        message
        statusCode
      }
      ... on Company {
        name
        legalName
        topics {
          id
          name
        }
      }
    }
  }
`;

export const ADD_INDUSTRY_TO_COMPANY = gql`
  mutation AddIndustryToCompany($companyId: ID!, $industryId: ID!) {
    addIndustryToCompany(companyId: $companyId, industryId: $industryId) {
      ... on Error {
        message
        statusCode
      }
      ... on Company {
        id
        name
        legalName
        industry {
          id
          name
          icon
        }
      }
    }
  }
`;
