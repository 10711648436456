import {Form, Input} from 'antd';
import styles from './mobileSetting.module.scss';
import {FeeSchedule} from 'components/feeSchedule/FeeSchedule';
import {fieldRules} from 'utils/helper';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useMutation} from '@apollo/client';
import {TRANSLATE_TEXT} from 'graphql/mutations/notifications';
import {Button} from '@dabafinance/react-components';
import {toast} from 'react-toastify';
import {UPDATE_APP_CONFIGURATION} from 'graphql/queries/mobileSettings';
import {useEffect} from 'react';

export const ProDiscountSettings = ({data, refetch}) => {
  const [form] = Form.useForm();
  const campaignDescription = Form.useWatch('enCampaignDescription', form);
  const orderDescription = Form.useWatch('enOrderDescription', form);

  const [translateText, {loading: loadingTranslation}] =
    useMutation(TRANSLATE_TEXT);
  const [updateAppConfig, {loading}] = useMutation(UPDATE_APP_CONFIGURATION, {
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        enCampaignDescription: data?.campaign?.description?.EN,
        frCampaignDescription: data?.campaign?.description?.FR,
        enOrderDescription: data?.order?.description?.EN,
        frOrderDescription: data?.order?.description?.FR,
        campaignFeeSchedule: data?.campaign?.feeSchedule || [],
        orderFeeSchedule: data?.order?.feeSchedule || [],
      });
    }
  }, [data]);

  const handleTranslation = type => {
    let sourceText;
    if (type === 'campaign') {
      sourceText = form.getFieldValue('enCampaignDescription');
    } else if (type === 'order') {
      sourceText = form.getFieldValue('enOrderDescription');
    }
    if (!sourceText) return;

    translateText({
      variables: {
        sourceLang: 'EN',
        targetLang: 'FR',
        sourceText,
      },
    })
      .then(({data: {translateText}}) => {
        if (
          translateText.statusCode === 400 ||
          translateText.__typename === 'Error'
        ) {
          toast.error('Error Translating Text', translateText.message);
        } else {
          toast.success('Translated Successfully');
          form.setFieldValue(
            type === 'campaign'
              ? 'frCampaignDescription'
              : 'frOrderDescription',
            translateText?.targetText,
          );
        }
      })
      .catch(err => toast.error(err));
  };

  const handleSubmit = values => {
    updateAppConfig({
      variables: {
        data: {
          discountSettings: {
            pro: {
              campaign: {
                description: {
                  'EN': values.enCampaignDescription,
                  'FR': values.frCampaignDescription,
                },
                ...(values.campaignFeeSchedule && {
                  feeSchedule: values.campaignFeeSchedule?.map(fee => ({
                    feeName: fee?.feeName,
                    feeType: fee?.feeType,
                    ...(fee?.fixedFeeAmount && {
                      fixedFeeAmount: Number(fee?.fixedFeeAmount),
                    }),
                    ...(fee?.variableFeeAmount && {
                      variableFeeAmount: Number(fee?.variableFeeAmount),
                    }),
                  })),
                }),
              },
              order: {
                description: {
                  'EN': values.enOrderDescription,
                  'FR': values.frOrderDescription,
                },
                ...(values.orderFeeSchedule && {
                  feeSchedule: values.orderFeeSchedule?.map(fee => ({
                    feeName: fee?.feeName,
                    feeType: fee?.feeType,
                    ...(fee?.fixedFeeAmount && {
                      fixedFeeAmount: Number(fee?.fixedFeeAmount),
                    }),
                    ...(fee?.variableFeeAmount && {
                      variableFeeAmount: Number(fee?.variableFeeAmount),
                    }),
                  })),
                }),
              },
            },
          },
        },
      },
    })
      .then(({data: {updateAppConfig}}) => {
        if (
          updateAppConfig.statusCode === 400 ||
          updateAppConfig.__typename === 'Error'
        ) {
          toast.error('Error Updating Config', updateAppConfig.message);
        } else {
          refetch();
          toast.success('Updated Successfully');
        }
      })
      .catch(err => toast.error(err));
  };

  return (
    <div className={styles.body}>
      <div className={styles.top}>
        <h1>Daba Pro Plan Discount Settings</h1>
      </div>
      <div>
        <h2>Campaign</h2>
      </div>
      <Form onFinish={handleSubmit} form={form} layout="vertical">
        <div className={styles.translateField}>
          <Form.Item
            rules={[fieldRules('This')]}
            className={styles.proField}
            name="enCampaignDescription"
            label="Campaign Description (EN)">
            <Input.TextArea rows={2} />
          </Form.Item>
          {campaignDescription?.length > 5 && (
            <Button
              type="secondary"
              disabled={loadingTranslation}
              label="Translate"
              onClick={() => handleTranslation('campaign')}
            />
          )}
        </div>
        <Form.Item
          rules={[fieldRules('This')]}
          className={styles.proField}
          name="frCampaignDescription"
          label="Campaign Description (FR)">
          <Input.TextArea rows={2} />
        </Form.Item>
        <h4>Campaign Fee Schedule</h4>
        <FeeSchedule
          feeName="campaignFeeSchedule"
          isOfStockExchange
          form={form}
        />
        <h2>Orders</h2>
        <div className={styles.translateField}>
          <Form.Item
            rules={[fieldRules('This')]}
            className={styles.proField}
            name="enOrderDescription"
            label="Order Description (EN)">
            <Input.TextArea rows={2} />
          </Form.Item>
          {orderDescription?.length > 5 && (
            <Button
              type="secondary"
              disabled={loadingTranslation}
              label="Translate"
              onClick={() => handleTranslation('order')}
            />
          )}
        </div>
        <Form.Item
          rules={[fieldRules('This')]}
          className={styles.proField}
          name="frOrderDescription"
          label="Order Description (FR)">
          <Input.TextArea rows={2} />
        </Form.Item>
        <h4>Order Fee Schedule</h4>
        <FeeSchedule feeName="orderFeeSchedule" isOfStockExchange form={form} />
        <SubmitButton disabled={loading} label="Update" />
      </Form>
    </div>
  );
};
