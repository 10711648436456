import {useDispatch, useSelector} from 'react-redux';
import styles from './otpScreen.module.scss';
import OtpInput from 'components/input/otpInput';
import {loginUser} from 'redux/store/auth/actions';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import OnboardingImage from 'components/image/onboardingImage';
import {useEffect, useRef, useState} from 'react';
import SignUpLayout from 'layout/signup';

const OTPScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const adminDetails = useSelector(state => state?.auth?.userData);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef(null);
  const [activeOTPIndex, setActiveOTPIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showResend, setShowResend] = useState(false);

  const route = useSelector(state => state?.auth?.loginRoute);
  const handleResendOTP = async e => {
    e.preventDefault();
    setLoading(true);
    const result = await dispatch(loginUser(adminDetails));
    if (result.error) {
      toast.error(result.error.message);
    } else {
      toast.success('Resent Successfully');
    }
    setLoading(false);
  };

  useEffect(() => {
    // Show resend button after 2 minutes
    const resendTimeout = setTimeout(() => {
      setShowResend(true);
    }, 1 * 60 * 1000); // 2 minutes in milliseconds

    // Cleanup function
    return () => {
      clearTimeout(resendTimeout);
    };
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    const twoFactorAuthCode = otp.join(''); // Concatenate OTP values into a single string
    const data = {
      email: adminDetails?.email,
      password: adminDetails?.password,
      twoFactorAuthCode,
    };
    setLoading(true);
    const result = await dispatch(loginUser(data));
    if (result.error) {
      toast.error(result.error.message);
      setLoading(false);
    } else {
      setLoading(false);
      if (!result.user && result.twoFactorStepRequired) {
        navigate('/sign-in/otp');
      } else {
        navigate(route || '/dashboard');
      }
    }
  };

  useEffect(() => {
    inputRefs.current?.focus();
  }, [activeOTPIndex]);

  const handleChange = (index, value) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    if (value) {
      setActiveOTPIndex(index + 1);
    }
    setOtp(updatedOtp);
  };

  const handleKeyDown = event => {
    if (event.key === 'Backspace') {
      setActiveOTPIndex(prev => prev - 1);
    }
  };

  return (
    <SignUpLayout
      left={
        <div className={styles.root}>
          <div className={styles.top}>
            <h1>OTP Required to LOGIN as an Admin</h1>
            <p>An OTP Code has been sent to your Email</p>
          </div>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.otp}>
              {otp.map((value, index) => (
                <OtpInput
                  key={index}
                  value={otp[index]}
                  onChange={newValue => handleChange(index, newValue)}
                  inputRef={activeOTPIndex === index ? inputRefs : null}
                  handleKeyDown={e => handleKeyDown(e, index)}
                />
              ))}
            </div>
            <SubmitButton
              disabled={loading || otp.some(value => value === '')}
              label="LOGIN"
            />
          </form>
          {showResend && (
            <p>
              Havent received it yet ?{' '}
              <span
                role="button"
                style={{color: 'blue', cursor: 'pointer'}}
                onClick={handleResendOTP}>
                Resend
              </span>
            </p>
          )}
        </div>
      }
      right={
        <>
          <OnboardingImage alt="login image" image="login.png" />
        </>
      }
    />
  );
};

export default OTPScreen;
