import {Spin} from 'antd';
import styles from './collection.module.scss';
import {Button} from '@dabafinance/react-components';
import {NavTabsEnum} from 'utils/constants';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import PercentageCard from './collectionCards/PercentageCard';
import {Outlet, useNavigate} from 'react-router-dom';

import CustomLink from 'components/customLink/customLink';
import {useQuery} from '@apollo/client';
import {GET_ALL_COLLECTIONS} from 'graphql/queries/collections';
import {toast} from 'react-toastify';

const Collection = () => {
  const navigate = useNavigate();

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Collections),
  );

  const {loading, data} = useQuery(GET_ALL_COLLECTIONS, {
    variables: {
      filters: {
        paginate: true,
      },
    },
    errorPolicy: 'all',
    onError: error => toast.error(error?.message),
  });

  return (
    <div>
      <div className={styles['startup-table']}>
        <div className={styles.header}>
          <h1>Collections</h1>
          <Button
            label="Create New Collection"
            className={styles.button}
            disabled={!tabOperations?.create}
            onClick={() => navigate('/dashboard/collections/new')}
          />
        </div>
        {loading ? (
          <div className={styles.loading}>
            <Spin size="large" />
            <h1>Loading...</h1>
          </div>
        ) : (
          <>
            <section className={styles['percentage-tab']}>
              <div className={styles['percentage-card']}>
                <PercentageCard
                  amount={
                    data?.adminGetAllCollections?.data?.pagination?.total || 0
                  }
                  label="Number of collections"
                  postive
                />
              </div>
              <div className={styles['percentage-card']}>
                <PercentageCard
                  amount={
                    data?.adminGetAllCollections?.data?.collectionOrders || 0
                  }
                  label="Total collection orders"
                  postive
                />
              </div>
            </section>
            <div className={styles['collection-lists']}>
              <CustomLink to="/dashboard/collections" name="Collections List" />
              <CustomLink
                to="/dashboard/collections/groups"
                name="Collections Groups"
              />
            </div>
            <section>
              <Outlet />
            </section>
          </>
        )}
      </div>
    </div>
  );
};

export {Collection};
