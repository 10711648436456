import {Form, Input, InputNumber} from 'antd';
import styles from './baseFundsAllocation.module.scss';
import plusIcon from 'images/plus-circle.png';
import deleteIcon from 'images/delete.svg';
import SubmitButton from 'components/Startup/components/SubmitButton';
import FadeEffect from 'components/animation/fadeEffect';
import {useEffect} from 'react';

export const BaseKeyStatistics = ({onSubmit, existingValues}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (existingValues) {
      form.setFieldsValue({
        keyStatistics: existingValues.keyStatistics,
        externallyFundedAmountBreakDown:
          existingValues.externallyFundedAmountBreakDown,
        additionalInvestmentTerms: existingValues.additionalInvestmentTerms,
      });
    }
  }, [existingValues]);

  return (
    <div className={styles.container}>
      <Form
        form={form}
        onFinish={values => {
          onSubmit({
            keyStatistics: values?.keyStatistics?.map(keyStat => ({
              name: keyStat?.name,
              value: keyStat?.value,
            })),
            externallyFundedAmountBreakDown:
              values?.externallyFundedAmountBreakDown?.map(
                externallyFundedAmountBreakDown => ({
                  name: externallyFundedAmountBreakDown?.name,
                  amount: externallyFundedAmountBreakDown?.amount,
                }),
              ),
            additionalInvestmentTerms: values?.additionalInvestmentTerms?.map(
              additionalInvestmentTerm => ({
                name: additionalInvestmentTerm?.name,
                value: additionalInvestmentTerm?.value,
              }),
            ),
          });
        }}
        layout="vertical">
        <div className={styles.body}>
          <div>
            <h2> Key Statistics</h2>
            <Form.List name="keyStatistics">
              {(fields, {add, remove}) => (
                <>
                  {fields?.map(({name}) => (
                    <FadeEffect key={name}>
                      <div className={styles.form}>
                        <div className={styles['al-card']}>
                          <Form.Item
                            rules={[{required: true, message: 'Required'}]}
                            name={[name, 'name']}
                            label="Statistics Name">
                            <Input
                              name="name"
                              style={{width: 400}}
                              placeholder="Marketing"
                            />
                          </Form.Item>
                          <Form.Item
                            style={{width: 288, marginLeft: 24}}
                            rules={[{required: true, message: 'Required'}]}
                            name={[name, 'value']}
                            label="Enter Statistics Associated Value">
                            <Input name="value" />
                          </Form.Item>
                          <div className={styles['delete-icon']}>
                            <img
                              onClick={() => {
                                remove(name);
                              }}
                              src={deleteIcon}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </FadeEffect>
                  ))}
                  <div onClick={add} className={styles['add-button']}>
                    <img src={plusIcon} alt="" />
                    <h1>Add Statistics</h1>
                  </div>
                </>
              )}
            </Form.List>
          </div>
          <div className={styles.content}>
            <h2> Externally Funded Amount Breakdown</h2>
            <Form.List name="externallyFundedAmountBreakDown">
              {(fields, {add, remove}) => (
                <>
                  {fields?.map(({name}) => (
                    <FadeEffect key={name}>
                      <div className={styles.form}>
                        <div className={styles['al-card']}>
                          <Form.Item
                            rules={[{required: true, message: 'Required'}]}
                            name={[name, 'name']}
                            label=" Name">
                            <Input name="name" style={{width: 400}} />
                          </Form.Item>
                          <Form.Item
                            style={{width: 288, marginLeft: 24}}
                            rules={[{required: true, message: 'Required'}]}
                            name={[name, 'amount']}
                            label="Amount">
                            <InputNumber
                              className={styles['input-number']}
                              formatter={value =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                              }
                              parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />
                          </Form.Item>
                          <div className={styles['delete-icon']}>
                            <img
                              onClick={() => {
                                remove(name);
                              }}
                              src={deleteIcon}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </FadeEffect>
                  ))}
                  <div onClick={add} className={styles['add-button']}>
                    <img src={plusIcon} alt="" />
                    <h1>Add Amount</h1>
                  </div>
                </>
              )}
            </Form.List>
          </div>
          <div className={styles.content}>
            <h2> Additional Investment Terms</h2>
            <Form.List name="additionalInvestmentTerms">
              {(fields, {add, remove}) => (
                <>
                  {fields?.map(({name}) => (
                    <FadeEffect key={name}>
                      <div className={styles.form}>
                        <div className={styles['al-card']}>
                          <Form.Item
                            rules={[{required: true, message: 'Required'}]}
                            name={[name, 'name']}
                            label=" Name">
                            <Input name="name" style={{width: 400}} />
                          </Form.Item>
                          <Form.Item
                            style={{width: 288, marginLeft: 24}}
                            rules={[{required: true, message: 'Required'}]}
                            name={[name, 'value']}
                            label="Value">
                            <Input name="value" />
                          </Form.Item>
                          <div className={styles['delete-icon']}>
                            <img
                              onClick={() => {
                                remove(name);
                              }}
                              src={deleteIcon}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </FadeEffect>
                  ))}
                  <div onClick={add} className={styles['add-button']}>
                    <img src={plusIcon} alt="" />
                    <h1>Add Term</h1>
                  </div>
                </>
              )}
            </Form.List>
          </div>
        </div>
        <div>
          <SubmitButton label="Save & Continue" />
        </div>
      </Form>
    </div>
  );
};
