import {gql} from '@apollo/client';

export const DELETE_FAQ = gql`
  mutation DeleteFaq($faqId: ID!) {
    deleteFaq(faqId: $faqId) {
      ... on Error {
        message
        statusCode
      }
      ... on FaqType {
        faq {
          id
          question
          answer
          featuredImage
          author
        }
      }
    }
  }
`;

export const CREATE_FAQ = gql`
  mutation CreateFaq($data: createFaqInput!) {
    createFaq(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on FaqType {
        faq {
          id
          question
          answer
          featuredImage
          author
        }
      }
    }
  }
`;

export const UPDATE_FAQ = gql`
  mutation UpdateFaq($data: updateFaqInput!) {
    updateFaq(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on FaqType {
        faq {
          id
          question
          answer
          featuredImage
          author
        }
      }
    }
  }
`;
