import styles from './newsOverview.module.scss';
import {NavLink, Outlet, useMatch, useResolvedPath} from 'react-router-dom';
import classnames from 'classnames/bind';

const NewsOverview = () => {
  const CustomLink = ({to, name}) => {
    const resolved = useResolvedPath(to);
    const match = useMatch({path: resolved.pathname, end: true});
    const cx = classnames.bind(styles);
    return (
      <NavLink className={cx('nav-link', match ? 'active-toggle' : '')} to={to}>
        <span>{name}</span>
      </NavLink>
    );
  };

  return (
    <div className={styles.root}>
      <h1>News Administration</h1>
      <div className={styles.section}>
        <div className={styles.toggler}>
          <CustomLink to={'/dashboard/news'} name="Incoming News" />
          <CustomLink
            to={'/dashboard/news/selected-news'}
            name="Selected News"
          />
          <CustomLink to={'/dashboard/news/website-news'} name="Website News" />
        </div>
      </div>
      <section>
        <Outlet />
      </section>
    </div>
  );
};

export default NewsOverview;
