import {Dropdown, Form, Pagination, Select, Table, Tag} from 'antd';
import {useEffect, useState} from 'react';
import styles from './allRewardSchemes.module.scss';
import {useMutation, useQuery} from '@apollo/client';
import {toast} from 'react-toastify';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {GET_USER_REWARDS} from 'graphql/queries/rewardScheme';
import DBModal from 'components/modal/modal';
import {UPDATE_USER_REWARD_STATUS} from 'graphql/mutations/rewardScheme';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {rewardStatus} from 'utils/mock';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';
import filterIcon from 'icons/filter.svg';
import moment from 'moment';
import gamer from 'images/gamer.png';
import dotsIcon from 'images/dots.svg';

const AllUserReward = () => {
  const navigate = useNavigate();
  const [openStatusModal, setStatusModal] = useState(false);
  const [userReward, setUserReward] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams('');
  const [activeTab, setActiveTab] = useState('APPROVED');
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.RewardScheme),
  );

  const tabs = [
    {label: 'Approved', value: 'APPROVED'},
    {label: 'Pending', value: 'PENDING'},
    {label: 'Rejected', value: 'REJECTED'},
  ];

  const {loading, refetch, data} = useQuery(GET_USER_REWARDS, {
    variables: {
      status: activeTab,
      page: 1,
      limit: 10,
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: error => toast.error(error?.message),
  });

  useEffect(() => {
    setActiveTab(
      tabs.find(tab => tab.label === searchParams.get('tab'))?.value ||
        'APPROVED',
    );
  }, []);

  const handlePageChange = page => {
    if (page === currentPage) return;
    setCurrentPage(page);
    refetch({page: page, limit: limit});
  };

  useEffect(() => {
    setLimit(10);
  }, []);

  const handleLimitChange = size => {
    if (size === limit) return;
    setLimit(size);
    refetch({page: currentPage, limit: size});
  };

  const [adminUpdateUserRewardStatus, {loading: loadingUpdate}] = useMutation(
    UPDATE_USER_REWARD_STATUS,
  );

  const handleSave = formValues => {
    adminUpdateUserRewardStatus({
      variables: {
        adminUpdateUserRewardStatusId: userReward,
        status: formValues?.status,
      },
      refetchQueries: [{query: GET_USER_REWARDS}],
    }).then(({data: {adminUpdateUserRewardStatus}}) => {
      if (adminUpdateUserRewardStatus.__typename === 'Error') {
        toast.error(adminUpdateUserRewardStatus.message);
      } else {
        setStatusModal(false);
        refetch();
        toast.success('User reward updated successfully');
      }
    });
  };

  const columns = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (text, record) => (
        <div
          onClick={() => navigate(`/dashboard/user/${record?.userId?.id}`)}
          className={styles['record-label']}>
          {record?.userType === 'USER' ? (
            <div className={styles['record-name']}>
              {record?.userId?.imageUrl ? (
                <img src={record?.userId?.imageUrl} alt="" />
              ) : (
                <img src={gamer} alt="" />
              )}
              <h4>
                {record?.userId?.firstName} {record?.userId?.lastName}
              </h4>
            </div>
          ) : record?.userType === 'REFEREE' ? (
            <div className={styles['record-name']}>
              {record?.refereeId?.imageUrl ? (
                <img src={record?.refereeId?.imageUrl} alt="" />
              ) : (
                <img src={gamer} alt="" />
              )}
              <h4>
                {record?.refereeId?.firstName} {record?.refereeId?.lastName}
              </h4>
            </div>
          ) : (
            <div className={styles['record-name']}>
              {record?.referrerId?.imageUrl ? (
                <img src={record?.referrerId?.imageUrl} alt="" />
              ) : (
                <img src={gamer} alt="" />
              )}
              <h4>
                {record?.referrerId?.firstName} {record?.referrerId?.lastName}
              </h4>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Reward Type',
      dataIndex: 'rewardType',
      key: 'rewardType',
      render: (text, record) => (
        <div>
          <h4>{record?.userReward?.type.replaceAll('_', ' ')}</h4>
        </div>
      ),
    },
    {
      title: 'Reward',
      dataIndex: 'reward',
      key: 'reward',
      render: (text, record) => (
        <div>
          <h4>
            {record?.userReward?.type === 'TRANSACTION_FEE_WAIVER'
              ? `${record?.userReward?.cashValue} ${record?.userReward?.currency?.currency}`
              : record?.userReward?.type === 'STOCKS'
              ? `${record?.userReward?.rewardUnits} units of ${
                  record?.userReward?.ticker
                    ? record?.userReward?.ticker
                    : 'Any'
                }`
              : `${record?.userReward?.amount} ${record?.userReward?.currency?.currency}`}
          </h4>
        </div>
      ),
    },
    {
      title: 'User Type',
      dataIndex: 'userType',
      key: 'userType',
      render: (text, render) => (
        <div className={styles['record-label']}>
          <h4>{render?.userType}</h4>
        </div>
      ),
    },
    {
      title: 'Action Type',
      dataIndex: 'eventType',
      key: 'eventType',
      render: (text, render) => (
        <div className={styles['record-label']}>
          <h4>{render?.eventType}</h4>
        </div>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'eventAmount',
      key: 'eventAmount',
      render: (text, render) => (
        <div className={styles['record-label']}>
          <h4>
            {render?.eventCurrency?.currency} {render?.eventAmount}
          </h4>
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, render) => (
        <div className={styles['record-label']}>
          <h4>{moment(render?.createdAt).format('DD MMMM, YYYY')}</h4>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <div className={styles['record-label']}>
          {record?.status === 'PENDING' ? (
            <Tag color="yellow">Pending</Tag>
          ) : record?.status === 'REJECTED' ? (
            <Tag color="red">Rejected</Tag>
          ) : (
            <Tag color="green">Approved</Tag>
          )}
        </div>
      ),
    },
    {
      title: 'Entry Mode',
      dataIndex: 'entryMode',
      key: 'entryMode',
      render: (text, record) => (
        <div className={styles['record-label']}>
          {record?.entryMode === 'MANUAL' ? (
            <Tag color="default">Manual</Tag>
          ) : (
            <Tag color="blue">Automatic</Tag>
          )}
        </div>
      ),
    },
  ];

  if (tabOperations?.update) {
    columns.push({
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <div className={styles.actions}>
          {record?.status === 'PENDING' && (
            <Dropdown
              trigger="click"
              menu={{
                items: [
                  {
                    key: 1,
                    label: 'Update Status',
                    onClick: () => {
                      setStatusModal(true);
                      setUserReward(record?.id);
                    },
                  },
                  {
                    key: 2,
                    label: 'Update Reward',
                    disabled: record?.entryMode === 'AUTOMATIC',
                    onClick: () =>
                      navigate(
                        '/dashboard/reward-schemes/update-user-reward/' +
                          record?.id,
                      ),
                  },
                ],
              }}>
              <div>
                <img src={dotsIcon} alt="" />
              </div>
            </Dropdown>
          )}
        </div>
      ),
    });
  }

  return (
    <div className={styles['table-container']}>
      <div className={styles['details-toggler']}>
        {tabs.map(tab => (
          <span
            className={activeTab === tab.value ? styles.active : ''}
            onClick={() => {
              setCurrentPage(1);
              setActiveTab(tab.value);
              setSearchParams({tab: tab.label});
            }}
            key={tab.value}>
            {tab.label}
          </span>
        ))}
      </div>
      <div className={styles.filterSection}>
        <div
          onClick={() => setOpenFilterModal(!openFilterModal)}
          className={styles.filter}>
          <h2>Filter</h2>
          <img src={filterIcon} alt="" />
        </div>
        <div
          style={{display: openFilterModal ? 'block' : 'none'}}
          className={styles.filterItems}>
          <h3>FILTER REWARDS</h3>
          <Form
            onFinish={value => {
              setCurrentPage(1);
              refetch({
                ...value,
                page: 1,
                limit,
              });
              setOpenFilterModal(false);
            }}
            layout="vertical">
            <Form.Item name="userType" label="User Type">
              <Select allowClear placeholder="Referral">
                <Select.Option key="REFERRER">Referrer</Select.Option>
                <Select.Option key="REFEREE">Referee</Select.Option>
                <Select.Option key="USER">User</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="entryMode" label="Entry Mode">
              <Select allowClear>
                <Select.Option key="MANUAL">Manual</Select.Option>
                <Select.Option key="AUTOMATIC">Automatic</Select.Option>
              </Select>
            </Form.Item>
            <SubmitButton label="Filter" />
          </Form>
        </div>
      </div>
      <div className={styles['startup-table']}>
        <>
          <Table
            className={styles['user-table']}
            dataSource={data?.adminGetAllUserRewards?.data}
            columns={columns}
            loading={loading}
            pagination={false}
          />
          <Pagination
            current={currentPage}
            onChange={e => handlePageChange(e)}
            total={data?.adminGetAllUserRewards?.pagination?.total}
            showQuickJumper
            className="ant-table-pagination"
            showSizeChanger
            pageSize={limit}
            onShowSizeChange={(_, size) => {
              handleLimitChange(size);
            }}
          />
        </>
      </div>

      <DBModal
        isOpen={openStatusModal}
        handleClose={() => setStatusModal(false)}
        content={
          <div>
            <h1>Update Status</h1>
            <Form
              className={styles.form}
              layout="vertical"
              onFinish={handleSave}>
              <div className={styles['date-format']}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Status required',
                    },
                  ]}
                  name="status"
                  label="Status">
                  <Select style={{width: 340}} name="status">
                    {rewardStatus
                      .filter(item => item.key !== 'PENDING')
                      .map(({key, name}) => (
                        <Select.Option value={key} key={key}>
                          {name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <SubmitButton disabled={loadingUpdate} label="Update" />
            </Form>
          </div>
        }
      />
    </div>
  );
};

export {AllUserReward};
