import {Form, Spin} from 'antd';
import styles from './walletDetails.module.scss';
import {useNavigate, useParams} from 'react-router-dom';
import {GET_BOTH_PLAFORM_AND_WALLET_CURRENCY} from 'graphql/queries/wallet';
import {useMutation, useQuery} from '@apollo/client';
import {useState} from 'react';
import DepositWalletFields from './WalletFields/DepositWalletField';
import WithdrawalWalletFields from './WalletFields/WithdrawalWalletFields';
import backIcon from 'images/back.png';
import {checkFeeValues} from '../create/CreateProviderSettings';
import {handleUpdatePaymentProviderSetting} from '../actions/MutationRequests';
import {toast} from 'react-toastify';
import {UPDATE_PAYMENT_PROVIDER_SETTINGS} from 'graphql/mutations/wallet';

const CurrencyDetails = () => {
  const {provider, currency} = useParams();
  const [currencyDetails, setCurrencyDetails] = useState(null);
  const [currencyLimits, setCurrencyLimits] = useState({});
  const [depositFeeValues, setDepositFeeValues] = useState([]);
  const [withdrawalFeeValues, setWithdrawalFeeValues] = useState([]);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const [updatePaymentProviderCurrencySettings, {loading: loadingUpdate}] =
    useMutation(UPDATE_PAYMENT_PROVIDER_SETTINGS);

  const handleUpdate = values => {
    if (checkFeeValues(withdrawalFeeValues, values.minWithdrawalAmount))
      return toast.error(
        'Fee Values must be LESS than Minimum Withdrawal Amount',
      );

    if (checkFeeValues(depositFeeValues, values.minDepositAmount))
      return toast.error('Fee Values must be LESS than Minimum Deposit Amount');

    handleUpdatePaymentProviderSetting(
      updatePaymentProviderCurrencySettings,
      values,
      provider,
      true,
      currency,
      depositFeeValues,
      withdrawalFeeValues,
      navigate,
    );
  };

  const {loading} = useQuery(GET_BOTH_PLAFORM_AND_WALLET_CURRENCY, {
    variables: {
      providerName: provider,
      currency,
      currency2: currency,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
    onCompleted: data => {
      const currencies = data?.getPaymentProviderByProviderName?.currencies;
      setCurrencyLimits(data?.getSupportedCurrencyByCurrencyName);
      setCurrencyDetails(currencies);
    },
  });

  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backIcon} />
        <h1>{currency} WALLET DETAILS</h1>
      </div>
      <Form onFinish={handleUpdate} form={form} layout="vertical">
        {currency && currencyDetails && (
          <>
            <DepositWalletFields
              selectedCurrency={currency}
              form={form}
              currencyLimits={currencyLimits?.deposit?.limit}
              currencyDetails={currencyDetails}
              feeValues={depositFeeValues}
              setFeeValues={setDepositFeeValues}
            />
            <WithdrawalWalletFields
              selectedCurrency={currency}
              currencyLimits={currencyLimits?.withdrawal?.limit}
              feeValues={withdrawalFeeValues}
              loading={loadingUpdate}
              setFeeValues={setWithdrawalFeeValues}
              form={form}
              currencyDetails={currencyDetails}
            />
          </>
        )}
      </Form>
    </div>
  );
};

export default CurrencyDetails;
