import {Button, Search} from '@dabafinance/react-components';
import {Table} from 'antd';
import {planUserTable} from 'utils/mock';
import styles from './expiredPlan.module.scss';

const ExpiredPlan = () => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <img src={record.image} alt="" />
          <h4 style={{width: 100}}>{record.name}</h4>
        </div>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Expiring ',
      dataIndex: 'expiration',
      key: 'expiration',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Start Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: () => <Button type="secondary" label="Change To" />,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>Expired</h1>
        <Search id="0" placeholder="Search users" />
      </div>
      <div>
        <Table columns={columns} dataSource={planUserTable} />
      </div>
    </div>
  );
};

export default ExpiredPlan;
