import deleteImg from 'images/delete.svg';
import editImg from 'images/edit.svg';
import moment from 'moment';
import styles from './websiteNewsCard.module.scss';
import {useNavigate} from 'react-router-dom';
import DBModal from 'components/modal/modal';
import {useState} from 'react';
import {Button} from '@dabafinance/react-components';
import {useMutation} from '@apollo/client';
import {DELETE_WEBSITE_NEWS} from 'redux/store/news/queries';
import {onDeleteWebsiteNews} from 'redux/store/news/actions';
import {toast} from 'react-toastify';
import {getUserTimezone, truncateTitle} from 'utils/helper';

const WebsiteNewsCard = ({data, type, operations}) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const navigate = useNavigate();

  const [deleteWebsiteNews, {loading}] = useMutation(DELETE_WEBSITE_NEWS);

  const handleDelete = async () => {
    try {
      onDeleteWebsiteNews(deleteWebsiteNews, data?.id, type);
      setIsOpenDeleteModal(false);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.newsCard}>
        <img src={data.featuredImage} />
        <div className={styles.title}>
          <h1>{truncateTitle(data?.title)}</h1>
        </div>
        <div className={styles.content}>
          <span>
            {`Published - ${moment(data?.publishedDate).format('LLL')}`}(
            {getUserTimezone()})
          </span>
          <div className={styles.footer}>
            {operations?.delete && (
              <div
                onClick={() => setIsOpenDeleteModal(true)}
                className={styles['action-icon']}>
                <img src={deleteImg} alt="delete" />
                <span>Delete</span>
              </div>
            )}
            {operations?.update && (
              <div
                onClick={() =>
                  navigate(
                    `/dashboard/news/update-website-news/${data?.id}/${data?.language}`,
                  )
                }
                className={styles['action-icon']}>
                <img src={editImg} alt="edit" />
                <span>Update</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <DBModal
        isOpen={isOpenDeleteModal}
        handleClose={() => setIsOpenDeleteModal(false)}
        content={
          <div>
            <div className={styles['modal-container']}>
              <h1>Are you sure You want to delete this News?</h1>
              <div>
                <Button
                  className={styles['modal-button']}
                  onClick={handleDelete}
                  disabled={loading}
                  label={'Yes'}
                />
                <Button
                  className={styles['modal-button']}
                  type="secondary"
                  onClick={() => setIsOpenDeleteModal(false)}
                  label="No"
                />
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default WebsiteNewsCard;
