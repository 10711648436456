import {DatePicker, Form, Input, Select, Tooltip} from 'antd';
import styles from './createdSeededDocument.module.scss';
import backIcon from 'images/back.png';
import {useEffect, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {SEARCH_SEEDED_COMPANY_DOCUMENTS} from 'graphql/queries/stocks';
import _ from 'lodash';
import {defaultLanguages} from 'pages/news/newsTypes';
import {useNavigate, useParams} from 'react-router-dom';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import FadeEffect from 'components/animation/fadeEffect';
import {Button} from '@dabafinance/react-components';
import moment from 'moment';
import {QuestionCircleOutlined} from '@ant-design/icons';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {CREATE_COMPANY_DOCUMENT_CENTER} from 'graphql/mutations/documentCenter';
import {toast} from 'react-toastify';
import {formatRoleString, removePrefix} from 'utils/helper';
import {GET_ALL_COMPANY_DOCUMENT_CATEGORIES} from 'graphql/queries/companyDocument';

const CreateSeededCompanyDocument = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [viewDocument, setViewDocument] = useState(false);
  const [previewDocument, setPreviewDocument] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [issuedDate, setIssuedDate] = useState('');
  const [language, setLanguage] = useState('FR');
  const navigate = useNavigate();
  const [limit, setLimit] = useState(50);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [form] = Form.useForm();
  const {id, documentId} = useParams();

  const [addCompanyDocument, {loading: loadingCreate}] = useMutation(
    CREATE_COMPANY_DOCUMENT_CENTER,
  );

  const {loading: catLoading, data: catData} = useQuery(
    GET_ALL_COMPANY_DOCUMENT_CATEGORIES,
  );

  const {loading, refetch, networkStatus, fetchMore} = useQuery(
    SEARCH_SEEDED_COMPANY_DOCUMENTS,
    {
      variables: {
        filters: {
          isAddedToDocumentCenter: false,
          language: language,
          ...(startDate && {
            seedDateRange: {
              startDate: moment(startDate).format(),
            },
          }),
          ...(endDate && {
            seedDateRange: {
              ...(startDate && {
                startDate: moment(startDate).format(),
              }),
              endDate: moment(endDate).format(),
            },
          }),
          pagination: {
            limit,
          },
        },
      },
      onCompleted: data =>
        setSearchResult(data?.getSeededStockExchangeDocuments?.documents),
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    if (selectedDocument) {
      const parsedData = JSON.parse(selectedDocument);
      setPreviewDocument(parsedData.documentLink);
      form.setFieldsValue({
        documentName: removePrefix(parsedData.documentName),
      });
    }
  }, [selectedDocument]);

  const debouncedSetSearchValue = _.debounce(value => {
    const searchByTicker = {};
    if (value !== '') {
      searchByTicker.companyTickers = [value.toUpperCase()];
    }
    refetch({
      filters: {
        ...searchByTicker,
        isAddedToDocumentCenter: false,
        language: language,
        pagination: {
          limit,
          page: 1,
        },
      },
    });
  }, 1200);

  const onScroll = event => {
    const target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const newLimit = limit + 50;
      setLimit(newLimit);
      fetchMore({
        variables: {
          filters: {
            isAddedToDocumentCenter: false,
            language: language,
            pagination: {
              limit: newLimit,
            },
          },
        },
      });
    }
  };

  const handleSubmit = values => {
    addCompanyDocument({
      variables: {
        data: {
          company: id,
          documentId,
          language: values.baseLanguage,
          documentCategory: values?.documentCategory,
          restrictLanguage: true,
          documentName: values?.documentName,
          documentUrlSource: 'SEEDED',
          documentUrl: previewDocument,
          issuedDate: moment(issuedDate).format(),
        },
      },
    })
      .then(({data: {addCompanyDocument}}) => {
        if (addCompanyDocument.__typename === 'Error') {
          return toast.error(addCompanyDocument.message);
        } else {
          toast.success('Created Successfully');
          navigate(
            `/dashboard/opportunities/startups/profile/${id}?route=document`,
          );
        }
      })
      .catch(error => toast.error(error));
  };

  return (
    <div>
      <div className={styles.top}>
        <img src={backIcon} onClick={() => navigate(-1)} />
        <h1>Create Company Document</h1>
      </div>
      <div className={styles.content}>
        <h1>Create a Company Document from Seeded Documents</h1>
        <Form onFinish={handleSubmit} form={form} layout="vertical">
          <Form.Item
            className={styles.field}
            name="baseLanguage"
            rules={[{required: true, message: 'This is a required field'}]}
            label={
              <span>
                Base Language{' '}
                <Tooltip
                  title={
                    'Note: This is Restricted to end users in this language ONLY'
                  }>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }>
            <Select value={language} onChange={setLanguage}>
              {defaultLanguages.map(data => (
                <Select.Option key={data.key}>{data.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className={styles.dates}>
            <Form.Item label="Start Date" name="startDate">
              <DatePicker
                value={startDate}
                onChange={e =>
                  setStartDate(e ? moment(e).format('YYYY-MM-DD') : '')
                }
                format={'YYYY-MM-DD'}
              />
            </Form.Item>
            <Form.Item label="End Date" name="endDate">
              <DatePicker
                format={'YYYY-MM-DD'}
                onChange={e =>
                  setEndDate(e ? moment(e).format('YYYY-MM-DD') : '')
                }
                value={endDate}
              />
            </Form.Item>
          </div>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Please Search Based on Document Ticker',
              },
            ]}
            name="document"
            className={styles.field}
            label={
              <span>
                Search (By TICKER) & Select Document{' '}
                <Tooltip title={'NOTE: You cannot Search by Document Name'}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }>
            <Select
              onSearch={e => debouncedSetSearchValue(e)}
              onChange={e => {
                setSelectedDocument(e);
              }}
              value={selectedDocument}
              showSearch
              onPopupScroll={onScroll}
              filterOption={false}
              placeholder="Search is based on Company Ticker">
              {searchResult.length &&
                searchResult?.map(data => (
                  <Select.Option key={JSON.stringify(data)}>
                    <span>{removePrefix(data?.documentName)}</span>
                  </Select.Option>
                ))}
              {(networkStatus === 2 || loading) && (
                <Select.Option disabled={true} key="Loading..." />
              )}
            </Select>
          </Form.Item>
          {selectedDocument && (
            <div className={styles.imageSec}>
              <Form.Item
                className={styles.field}
                name="documentName"
                rules={[{required: true, message: 'This is a required field'}]}
                label="Document Name">
                <Input />
              </Form.Item>
              <Form.Item
                rules={[{required: true, message: 'This Field is Required'}]}
                name="issuedDate"
                label="Issued Date">
                <DatePicker
                  name="issuedDate"
                  value={issuedDate}
                  className={styles.date}
                  format={'YYYY-MM-DD HH:mm:ss'}
                  showTime={{
                    defaultValue: moment('00:00', 'HH:mm:ss'),
                  }}
                  onChange={e =>
                    setIssuedDate(moment(e).format('YYYY-MM-DD HH:mm:ss'))
                  }
                />
              </Form.Item>
              <Form.Item
                className={styles.field}
                name="documentCategory"
                rules={[{required: true, message: 'This Field is Required'}]}
                label="Document Category">
                <Select loading={catLoading}>
                  {catData?.getCompanyDocumentCategories?.data?.map(data => (
                    <Select.Option key={data?.id}>
                      {formatRoleString(data?.name)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {previewDocument && viewDocument && (
                <FadeEffect>
                  <div className={styles['pdf-height']}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                      <Viewer
                        fileUrl={previewDocument}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </Worker>
                  </div>
                </FadeEffect>
              )}
              <Button
                className={styles.button}
                label={`${viewDocument ? 'Hide' : 'View'} Document`}
                onClick={() => setViewDocument(!viewDocument)}
              />
            </div>
          )}
          <SubmitButton label="Create Document" disabled={loadingCreate} />
        </Form>
      </div>
    </div>
  );
};

export default CreateSeededCompanyDocument;
