import {useState} from 'react';
import styles from './reportTagsList.module.scss';
import {Button, Search} from '@dabafinance/react-components';
import {useQuery} from '@apollo/client';
import {GET_ALL_TOPICS} from 'graphql/queries/interest';
import {Spin} from 'antd';
import {toast} from 'react-toastify';

const ReportTagsList = ({onClick, choosenTopics = [], addTopic}) => {
  const [topicsData, setTopicsData] = useState([]);
  const [searchItem, setSearchItem] = useState('');
  const [searchData, setSearchData] = useState([]);
  const {loading} = useQuery(GET_ALL_TOPICS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => setTopicsData(data?.getAllTopics),
    onError: error => toast.error(error),
  });

  const handleSearch = term => {
    setSearchItem(term.trim());
    if (term) {
      const newList = topicsData?.filter(data => {
        return Object.values(data)
          .join(' ')
          .toLowerCase()
          .includes(term.toLowerCase());
      });
      setSearchData(newList);
    } else {
      setSearchData(topicsData);
    }
  };

  return loading ? (
    <div className={styles.loading}>
      <Spin size="large" />
      <h1>Loading...</h1>
    </div>
  ) : (
    <div className={styles['tags-modal-container']}>
      <h2>Add to Report</h2>
      <Search
        id="search-tags"
        placeholder="Search for startup, country, industry and more"
        onChange={handleSearch}
        className={styles['search-tags']}
      />
      <div className={styles['tags-list-container']}>
        {(searchItem.length < 1 ? topicsData : searchData).map(topic => (
          <RenderTagItem
            key={topic.id}
            topic={topic}
            addTopic={addTopic}
            choosenTopics={choosenTopics}
          />
        ))}
      </div>
      <div className={styles['tags-modal-footer']}>
        <Button
          type="primary"
          label="Continue"
          className={styles['modal-continue-button']}
          onClick={() => onClick()}
        />
      </div>
    </div>
  );
};

const RenderTagItem = ({topic, addTopic, choosenTopics = []}) => {
  let checkAdded = choosenTopics.find(input => input.id === topic.id);
  checkAdded = checkAdded || topic;

  const {icon, name, isAdded} = checkAdded;

  return (
    <div className={styles['add-tags-list']}>
      <img alt="" src={icon} />
      <h5 className={styles['tag-name']}>{name}</h5>
      <Button
        type={isAdded ? 'secondary' : 'primary'}
        label={isAdded ? 'Added' : 'Add'}
        className={styles['modal-add-button']}
        onClick={() => addTopic(topic)}
      />
    </div>
  );
};

export default ReportTagsList;
