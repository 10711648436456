import gql from 'graphql-tag';

export const CREATE_COMPANY = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on Company {
        id
        name
        legalName
        description
        website
        ticker
        foundedOn
        headquarter
        stage
        accelerator
        logoImgURL
        bannerImgURL
        createdAt
        updatedAt
        totalCapitalRaised
      }
    }
  }
`;

export const UPDATE_COMPANY_RECOMMENDATION = gql`
  mutation UpdateCompanyRecommendation(
    $recommendationId: ID!
    $isAdded: Boolean
  ) {
    updateCompanyRecommendation(
      recommendationId: $recommendationId
      isAdded: $isAdded
    ) {
      ... on Error {
        message
        statusCode
        type
      }
      ... on CompanyRecommendation {
        id
        companyName
        websiteUrl
        isAdded
        user {
          firstName
        }
      }
    }
  }
`;

export const ADD_TOPICS_TO_COMPANY = gql`
  mutation AddTopicsToCompany($companyId: ID!, $topicIds: [ID!]!) {
    addTopicsToCompany(companyId: $companyId, topicIds: $topicIds) {
      ... on Company {
        id
        name
        topics {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on Company {
        id
      }
    }
  }
`;

export const CREATE_SOCIAL_MEDIA = gql`
  mutation CreateSocialMedia($input: CreateSocialMediaInput!) {
    createSocialMedia(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on SocialMedia {
        id
      }
    }
  }
`;

export const CREATE_COMPANY_TEAM_MEMBER = gql`
  mutation CreateCompanyTeamMember($input: CreateCompanyTeamMemberInput!) {
    createCompanyTeamMember(input: $input) {
      ... on CompanyTeamMember {
        id
        name
        role
        displayImage
        linkedinUrl
        twitterUrl
      }
      ... on Error {
        message
        statusCode
      }
    }
  }
`;

export const DELETE_COMPANY_TEAM_MEMBER = gql`
  mutation DeleteCompanyTeamMember($teamMemberId: ID!, $companyId: ID!) {
    deleteCompanyTeamMember(
      teamMemberId: $teamMemberId
      companyId: $companyId
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on CompanyTeamMember {
        id
        name
        role
        description
      }
    }
  }
`;

export const CREATE_COMPANY_SOCIALS = gql`
  mutation CreateSocialMedia($input: [CreateSocialMediaInput!]) {
    createSocialMedia(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on SocialsResult {
        response
        status
        data {
          url
          platform
          id
        }
      }
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation DeleteCompany($companyId: ID!) {
    deleteCompany(companyId: $companyId) {
      ... on Error {
        message
        statusCode
      }
      ... on Company {
        id
        name
        legalName
        shortDescription
      }
    }
  }
`;

export const DELETE_COMPANY_SOCIAL = gql`
  mutation DeleteSocialMedia($companyId: ID!) {
    deleteSocialMedia(companyId: $companyId) {
      ... on Error {
        message
        statusCode
      }
      ... on SocialMedia {
        facebookUrl
        twitterUrl
        linkedInUrl
      }
    }
  }
`;

export const UPDATE_COMPANY_FOUNDER = gql`
  mutation UpdateCompanyTeamMember($input: UpdateCompanyTeamMemberInput!) {
    updateCompanyTeamMember(input: $input) {
      ... on CompanyTeamMember {
        id
        name
        role
        displayImage
        linkedinUrl
        twitterUrl
      }
    }
  }
`;

export const UPDATE_COMPANY_SOCIAL = gql`
  mutation UpdateSocialMedia($input: UpdateSocialMediaInput!) {
    updateSocialMedia(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on SocialMedia {
        id
      }
    }
  }
`;

export const ADD_COMPANY_DOCUMENT_CATEGORY = gql`
  mutation AddCompanyDocumentCategory($data: AddCompanyDocumentCategory!) {
    addCompanyDocumentCategory(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCompanyDocumentCategory {
        data {
          id
          name
        }
        message
      }
    }
  }
`;

export const UPDATE_COMPANY_DOCUMENT_CATEGORY = gql`
  mutation UpdateCompanyDocumentCategory(
    $data: UpdateCompanyDocumentCategory!
  ) {
    updateCompanyDocumentCategory(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCompanyDocumentCategory {
        message
        data {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_COMPANY_DOCUMENT_CATEGORY = gql`
  mutation DeleteCompanyDocumentCategory($categoryId: ID!) {
    deleteCompanyDocumentCategory(categoryId: $categoryId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCompanyDocumentCategory {
        message
        data {
          name
          id
        }
      }
    }
  }
`;
