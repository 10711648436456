import {gql} from '@apollo/client';

export const GET_ASSET_CLASSES = gql`
  query GetAssetClasses($input: AssetClassFilter) {
    getAssetClasses(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAssetClasses {
        pagination {
          total
        }
        data {
          id
          name
          key
          description
          status
          author

          language
        }
      }
    }
  }
`;

export const GET_ASSET_CLASS_LANGUAGES = gql`
  query GetAssetClassLanguageVersions($getAssetClassLanguageVersionsId: ID!) {
    getAssetClassLanguageVersions(id: $getAssetClassLanguageVersionsId) {
      ... on ResponseWithAssetClassLanguageVersions {
        data {
          description
          id
          languageVersions
          name
        }
      }
      ... on Error {
        message
        statusCode
      }
    }
  }
`;

export const GET_ASSET_CLASS = gql`
  query GetAssetClass($getAssetClassId: ID!, $language: LanguageEnum!) {
    getAssetClass(id: $getAssetClassId, language: $language) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAssetClass {
        message
        data {
          id
          name
          key
          description
          status
          author
          instruments {
            name
            key
            description
            status
          }
          language
        }
      }
    }
  }
`;

export const GET_ASSET_CLASS_INSTRUMENTS = gql`
  query Bond($input: FinancialInstrumentFilter!) {
    getFinancialInstruments(input: $input) {
      ... on ResponseWithFinancialInstruments {
        data {
          ... on Bond {
            name
            key
            language
            ticker
            listingType
            exchange
            id
            status
            currency {
              currencyCode
            }
          }
        }
        pagination {
          total
        }
      }
    }
  }
`;

export const INSTRUMENTS_DETAILS_QUERIES = gql`
  query GetAllInstrumentsQueries {
    getAllCompanies {
      ... on Error {
        message
        statusCode
      }
      ... on Company {
        id
        name
        legalName
        ticker
        stage
        isTrending
        logoImgURL
        totalCapitalRaised
      }
    }
    getAllTopics {
      ... on Error {
        message
        statusCode
      }
      ... on Topic {
        id
        name
        icon
      }
    }
    getAllIndustries {
      ... on Error {
        message
        statusCode
      }
      ... on Industry {
        id
        name
        icon
      }
    }
  }
`;

export const GET_ALL_RATINGS = gql`
  query ResponseWithRatings($page: Int!, $limit: Int!) {
    getAllRatings(page: $page, limit: $limit) {
      ... on ResponseWithRatings {
        message
        data {
          id
          agencyName
          agencyLogo
          rating
          document
          type
        }
      }
      ... on Error {
        message
        statusCode
      }
    }
  }
`;

export const GET_RATING_BY_ID = gql`
  query GetRatingById($getRatingByIdId: ID!) {
    getRatingById(id: $getRatingByIdId) {
      ... on ResponseWithRating {
        data {
          id
          agencyName
          agencyLogo
          rating
          document
          type
        }
      }
      ... on Error {
        message
        statusCode
      }
    }
  }
`;

export const GET_FINANCIAL_INSTRUMENT_LANGUAGE_VERSIONS = gql`
  query GetFinancialInstrumentLanguageVersions(
    $getFinancialInstrumentLanguageVersionsId: ID!
    $assetClassKey: AssetClassKey!
  ) {
    getFinancialInstrumentLanguageVersions(
      id: $getFinancialInstrumentLanguageVersionsId
      assetClassKey: $assetClassKey
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithFinancialInstrumentLanguageVersions {
        data {
          id
          name
          description
          languageVersions
        }
      }
    }
  }
`;

export const GET_FINANCIAL_INSTRUMENT_BY_LANGUAGE = gql`
  query GetFinancialInstrument(
    $getFinancialInstrumentId: ID!
    $language: LanguageEnum
  ) {
    getFinancialInstrument(id: $getFinancialInstrumentId, language: $language) {
      ... on Error {
        statusCode
        message
      }
      ... on ResponseWithFinancialInstrument {
        data {
          ... on Bond {
            issuer {
              id
              issuerType
            }
            name
            description
            currency {
              currencyCode
            }
            endDate
            startDate
            settings {
              isPaused
              isVisible
              shouldNotifyUsersOnLaunch
              publicAudience
            }
            templateId
            key
            language
            topics {
              id
            }
            status
            minimumInvestmentAmount
            managementFee
            gracePeriodPrincipal
            gracePeriodInterest
            assetClassKey
            sectorIndustry {
              id
            }
            investmentContractSettings {
              spvNamePrefix
              investmentTargetEntityLegalName
              managementFeeTerms
              carryPercentageTerms
              minimumInvestmentPerMember
              spvManagerRepresentativeName
              investmentContractType
            }
            listingType
            isShariaCompliant
            investmentRisk
            liquidity
            pitchDeck {
              fileLink
              coverImage
              linkType
              externalViewLink
              externalShareLink
            }
            coInvestors {
              name
              imageUrl
              title
              description
              otherInvestments {
                companyId
                imageUrl
              }
            }
            competitors {
              companyName
              companyLogo
              companyId
            }
            documents {
              name
              fileLink
            }
            dabaScore {
              score
              pdfReport
              business
              industry
              market
              team
              strategyProduct
              accelerator
              riskChallenges
              analogus
              economicsAndFinance
              legalCompliance
              returnOutlook
              socialImpact
            }
            faq {
              question
              answer
            }
            riskAndEsg {
              title
              answer
            }
            impactAndEsg {
              title
              answer
            }
            missionAndVision
            solutions
            invitedUsers {
              id
              firstName
              lastName
              email
            }
            invitedGroups {
              id
            }
            disclosure
            additionalDisclosures {
              name
              value
            }
            recommendedPortfolioStrategy
            opportunityVoiceRecording
            opportunityVoiceRecordingThumbnail
            opportunityDemoVideo
            opportunityDemoVideoThumbnail
            level1AnalystVideo
            level1AnalystVideoThumbnail
            level2AnalystVideo
            level2AnalystVideoThumbnail
            externalVideoUrl
            externalVideoThumbnail
            totalExternallyFundedAmount
            externallyFundedAmountBreakDown {
              name
              amount
            }
            analog {
              logo
              name
              description
              companyType
              valuation
            }
            totalInvestedAmount
            totalInvestment
            totalPayments
            exchange
            feeSchedule {
              feeName
              feeType
              fixedFeeAmount
              variableFeeAmount
            }
            tradingVolume
            fractionable
            minTradeableUnits
            maxTradeableUnits
            minTradeableAmount
            maxTradeableAmount
            orderExpirationLimit

            minimumFundingTarget
            maximumFundingTarget
            fundingTarget
            companyValuation
            additionalInvestmentTerms {
              name
              value
            }
            id
            maturityDate
            duration
            couponRate
            isCallable
            currentMarketPrice
            type
            yieldToMaturity
            issuanceDate
            useOfProceeds {
              description
              allocations {
                allocationCategory
                percentageValue
              }
            }
            interestPaymentFrequency
            projectFinancingDetails
            legalChallenges
            nextCouponDate
            nextCallDate
            nextCallPrice
            outlookAsOfDate
            creditWatchAsOfDate
            CUSIP
            ticker
            issuanceVolume
            isn
            rating {
              id
            }
            primaryBrokerName
            primaryBrokerLogo
            primaryBrokerDescription
            primaryBrokerWebsite
            primaryBrokerOperatingCountries
            marketCapitalization
          }
        }
      }
    }
  }
`;
