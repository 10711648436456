import {Form, Switch} from 'antd';
import styles from './enableCurrencySetting.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {useMutation} from '@apollo/client';
import {ENABLE_OR_DISABLE_SOURCE_CURRENCY} from 'graphql/mutations/wallet';
import {toast} from 'react-toastify';

const EnableCurrencySwap = ({data, closeModal, refetch}) => {
  const [enable, setEnable] = useState(data.isEnabled);

  useEffect(() => {
    setEnable(data.isEnabled);
  }, [data]);

  const [enableOrDisableSourceCurrencyForSwap, {loading}] = useMutation(
    ENABLE_OR_DISABLE_SOURCE_CURRENCY,
  );
  const handleUpdate = () => {
    enableOrDisableSourceCurrencyForSwap({
      variables: {
        currency: data?.currency,
        isEnabled: enable,
      },
    })
      .then(({data: {enableOrDisableSourceCurrencyForSwap}}) => {
        if (enableOrDisableSourceCurrencyForSwap.__typename === 'Error') {
          toast.error(enableOrDisableSourceCurrencyForSwap.message);
        } else {
          toast.success('Currency Updated Successfully');
          refetch();
          closeModal();
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <h1>Swap Settings</h1>
        <span>
          Note: This Enables and Disables this particular Currency for Swap as a
          SOURCE Currency
        </span>
      </div>
      <Form onFinish={handleUpdate} layout="vertical">
        <Form.Item label="Enabled" name="enable">
          <Switch checked={enable} onChange={checked => setEnable(checked)} />
        </Form.Item>
        <SubmitButton disabled={loading} label="Update" />
      </Form>
    </div>
  );
};

export default EnableCurrencySwap;
