import {useMutation, useQuery} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {Form, Select, Spin} from 'antd';
import {
  ADD_INDUSTRY_TO_COMPANY,
  ADD_TOPIC_TO_COMPANY,
} from 'graphql/mutations/investorUpdates';
import {
  GET_ALL_INDUSTRIES,
  GET_ALL_TOPICS,
} from 'graphql/queries/investorUpdates';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import styles from './updatetopics.module.scss';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const UpdateTopics = ({CompanyTopics, CompanyIndustries}) => {
  const [topics, setTopics] = useState(
    CompanyTopics.map(name => `${name?.name};${name?.id}`),
  );

  const [industries, setIndustries] = useState(
    `${CompanyIndustries?.name};${CompanyIndustries?.id}`,
  );
  const {Option} = Select;
  const {id} = useParams();

  const pageOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Startups),
  );

  const {data: getAllTopics, loading} = useQuery(GET_ALL_TOPICS);
  const {data: getAllIndustries, loading: loadingIndustries} =
    useQuery(GET_ALL_INDUSTRIES);
  const [addTopicsToCompany] = useMutation(ADD_TOPIC_TO_COMPANY);
  const [addIndustryToCompany] = useMutation(ADD_INDUSTRY_TO_COMPANY);

  const handleAddIndustries = () => {
    addIndustryToCompany({
      variables: {
        companyId: id,
        industryId: industries.split(';')[1],
      },
    }).then(({data: {addIndustryToCompany}}) => {
      if (addIndustryToCompany.__typename === 'Error') {
        toast.error(addIndustryToCompany.message);
      } else {
        toast.success('Industry added Successfully');
      }
    });
  };

  const handleAddTopic = () => {
    addTopicsToCompany({
      variables: {
        companyId: id,
        topicIds: topics.map(data => data.split(';')[1]),
      },
    }).then(({data: {addTopicsToCompany}}) => {
      if (addTopicsToCompany.__typename === 'Error') {
        toast.error(addTopicsToCompany.message);
      } else {
        toast.success('Topics added Successfully');
      }
    });
  };

  if (loading || loadingIndustries) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles['update-container']}>
      <div className={styles.header}>
        <h1>Update Company Topics and Industry</h1>
      </div>
      <div>
        <Form className={styles.form} layout="vertical">
          <Form.Item label="Topics" name="Topics">
            <Select
              mode="multiple"
              showSearch
              value={topics}
              onChange={e => setTopics(e)}
              defaultValue={CompanyTopics.map(
                name => `${name?.name};${name?.id}`,
              )}
              style={{width: 730}}
              placeholder={'Select Company Topics'}>
              {getAllTopics?.getAllTopics?.map(topic => (
                <Option key={`${topic?.name};${topic?.id}`}>
                  <div className={styles['all-data']}>
                    <img src={topic.icon} alt="" />
                    <span>{topic.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            label="Update Topics"
            disabled={!pageOperations?.update}
            className={styles['button-style']}
            onClick={handleAddTopic}
          />
        </Form>
        <Form className={styles.form} layout="vertical">
          <Form.Item
            label="Industries"
            className={styles['form-item']}
            name="industry">
            <Select
              className={styles['select-field']}
              showSearch
              value={industries}
              defaultValue={`${CompanyIndustries?.name};${CompanyIndustries?.id}`}
              onChange={value => setIndustries(value)}
              style={{width: 730}}
              placeholder={'Select Industries'}>
              {getAllIndustries?.getAllIndustries?.map(industry => (
                <Option key={`${industry.name};${industry.id}`}>
                  <div className={styles['all-data']}>
                    <img src={industry.icon} alt="" />
                    <span>{industry.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            label="Update Industry"
            disabled={!pageOperations?.update}
            className={styles['button-style']}
            onClick={handleAddIndustries}
          />
        </Form>
      </div>
    </div>
  );
};

export default UpdateTopics;
