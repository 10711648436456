import {useMutation} from '@apollo/client';
import {DatePicker, Form, Select} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {
  GET_APP_CONFIGURATION,
  UPDATE_APP_CONFIGURATION,
} from 'graphql/queries/mobileSettings';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import styles from './mobileSetting.module.scss';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const ReviewSettings = ({data}) => {
  const [endDate, setEndDate] = useState('');
  const [profileCompletion, setProfileCompletion] = useState('');
  const [investedInCampaign, setInvestedInCampaign] = useState('');

  const [updateAppConfig, {loading}] = useMutation(UPDATE_APP_CONFIGURATION);

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.PlatformSettings),
  );
  useEffect(() => {
    setEndDate(data?.reviewPeriodEnd);
    setProfileCompletion(data?.filters?.hasCompletedProfile ? 'YES' : 'NO');
    setInvestedInCampaign(
      data?.filters?.hasInvestedInAnyCampaign ? 'YES' : 'NO',
    );
  }, [data]);

  const handleSaveChanges = () => {
    updateAppConfig({
      variables: {
        data: {
          platformReviewSettings: {
            reviewPeriodEnd: endDate,
            filters: {
              hasCompletedProfile: profileCompletion === 'YES',
              hasInvestedInAnyCampaign: investedInCampaign === 'YES',
            },
          },
        },
      },
      refetchQueries: [{query: GET_APP_CONFIGURATION}],
    })
      .then(({data: {updateAppConfig}}) => {
        if (updateAppConfig.__typename === 'Error') {
          toast.error(updateAppConfig.message);
        } else {
          toast.success('Updated Successfully');
        }
      })
      .catch(error => toast.error(error));
  };

  return (
    <div className={styles['body']}>
      <div></div>
      <div className={styles['form']}>
        <div className={styles.filterSection}>
          <h1>REVIEW SETTINGS</h1>
          <span>
            This displays a prompt in the Users App, Alerting them to review our
            App
          </span>
        </div>
        <Form onFinish={handleSaveChanges} layout="vertical">
          <Form.Item
            initialValue={
              data?.reviewPeriodEnd
                ? moment(data?.reviewPeriodEnd)
                    .endOf('month')
                    .format('YYYY-MM-DD')
                : ''
            }
            name="date"
            label="Prompt Display Stop Date">
            <DatePicker
              value={endDate}
              defaultValue={
                data?.reviewPeriodEnd
                  ? moment(data?.reviewPeriodEnd)
                      .endOf('month')
                      .format('YYYY-MM-DD')
                  : ''
              }
              onChange={e =>
                setEndDate(moment(e).endOf('month').format('YYYY-MM-DD'))
              }
              picker="month"
              disabledDate={current => {
                return current < moment().add(0, 'month');
              }}
              format={'MMMM'}
              style={{width: 300}}
            />
          </Form.Item>
          <div className={styles.filterSection}>
            <h1>Who gets to see this?</h1>
          </div>
          <Form.Item
            name="profileCompletion"
            label="Users That have not completed their Profile">
            <Select
              value={profileCompletion}
              onChange={e => setProfileCompletion(e)}
              placeholder="Select an Option"
              defaultValue={data?.filters?.hasCompletedProfile ? 'YES' : 'NO'}
              style={{width: 300}}>
              <Select.Option key={'YES'}>Yes</Select.Option>
              <Select.Option key={'NO'}>No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="investedInCampaign"
            label="Users that have invested in a Campaign">
            <Select
              value={investedInCampaign}
              onChange={e => setInvestedInCampaign(e)}
              placeholder="Select an Option"
              defaultValue={
                data?.filters?.hasInvestedInAnyCampaign ? 'YES' : 'NO'
              }
              style={{width: 300}}>
              <Select.Option key={'YES'}>Yes</Select.Option>
              <Select.Option key={'NO'}>No</Select.Option>
            </Select>
          </Form.Item>
          <SubmitButton
            disabled={loading || !tabOperations?.update}
            label="Update Changes"
          />
        </Form>
      </div>
    </div>
  );
};

export default ReviewSettings;
