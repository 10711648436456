import {gql} from '@apollo/client';

export const CREATE_COMPANY_DOCUMENT_CENTER = gql`
  mutation AddCompanyDocument($data: AddCompanyDocument!) {
    addCompanyDocument(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCompanyDocument {
        message
      }
    }
  }
`;

export const UPDATE_COMPANY_DOCUMENT_CENTER = gql`
  mutation UpdateCompanyDocument($data: UpdateCompanyDocument!) {
    updateCompanyDocument(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCompanyDocument {
        message
      }
    }
  }
`;

export const DELETE_SINGLE_COMPANY_DOCUMENT = gql`
  mutation DeleteOneCompanyDocument($documentId: ID!) {
    deleteOneCompanyDocument(documentId: $documentId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCompanyDocument {
        message
      }
    }
  }
`;
