import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import styles from './baseLegalDocuments.module.scss';
import plusIcon from 'images/plus-circle.png';
import cloudIcon from 'images/cloud-upload.svg';
import deleteIcon from 'images/delete.svg';
import {Form, Input, Upload} from 'antd';
import FadeEffect from 'components/animation/fadeEffect';
import {UploadDocumentToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';

const BaseLegalDocument = ({onSubmit, existingData}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const isDev = process.env.REACT_APP_API_BASE_URL.includes('.daba-dev.');

  useEffect(() => {
    if (existingData && existingData.documents) {
      form.setFieldsValue({documents: existingData.documents});
    }
  }, [existingData]);

  const handleChangeFile = async (i, {file}) => {
    const temp = file.originFileObj;

    const newFormValues = [...form.getFieldValue('documents')];

    newFormValues[i].fileName = temp.name.split('.')[0];

    setLoading(true);
    const bucket = isDev
      ? 'dabafinancialinstruments-nonprod'
      : 'dabafinancialinstruments-prod';
    try {
      const fileLink = await UploadDocumentToBucket(
        temp,
        () => {},
        bucket,
        true,
        'bonds',
      );
      newFormValues[i].fileLink = fileLink ?? null;
    } catch (error) {
      console.error('File upload failed:', error);
    } finally {
      setLoading(false);
      form.setFieldsValue({documents: newFormValues});
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>Legal Documents</h1>
        <span>
          Upload any legal document that belongs to your company to seal trust
          in investors
        </span>
      </div>
      <Form
        onFinish={values => {
          onSubmit({
            documents: values.documents.map(data => ({
              name: data?.name,
              language: data?.language,
              fileLink: data?.fileLink,
            })),
          });
        }}
        form={form}
        layout="vertical">
        <Form.List name="documents">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({name}) => (
                <FadeEffect key={name}>
                  <div className={styles['document-card']} key={name}>
                    <div>
                      <Form.Item
                        name={[name, 'name']}
                        className={styles.field}
                        rules={[{required: true, message: 'Required'}]}
                        label="Legal Document Name">
                        <Input name="name" placeholder="Name of document" />
                      </Form.Item>
                      <Form.Item
                        name={[name, 'fileLink']}
                        className={styles.document}
                        rules={[{required: true, message: 'Required'}]}
                        label="Document Language">
                        <Upload
                          accept=".pdf, .doc"
                          name="pdf"
                          onChange={e => handleChangeFile(name, e)}
                          maxCount={1}>
                          <label
                            htmlFor="file-upload"
                            className={styles.upload}>
                            <img src={cloudIcon} alt="" />
                            <span>
                              {typeof form.getFieldValue('documents')[name]
                                ?.fileLink === 'string'
                                ? 'Document Uploaded Successfully'
                                : typeof form.getFieldValue('documents')[name]
                                    ?.fileLink === 'object'
                                ? 'Uploading...'
                                : 'Upload Document'}
                            </span>
                          </label>
                        </Upload>
                      </Form.Item>
                    </div>

                    <div>
                      <img
                        onClick={() => remove(name)}
                        src={deleteIcon}
                        alt=""
                      />
                    </div>
                  </div>
                </FadeEffect>
              ))}
              <div onClick={add} className={styles['add-button']}>
                <img src={plusIcon} alt="" />
                <h1>Add Document</h1>
              </div>
            </>
          )}
        </Form.List>
        <SubmitButton
          disabled={loading}
          label={loading ? 'Please Wait...' : 'Save & Continue'}
        />
      </Form>
    </div>
  );
};

export default BaseLegalDocument;
