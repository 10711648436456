import {Form, Input, Modal, Select, Spin, Table} from 'antd';
import {useState} from 'react';
import styles from './allRewardSchemes.module.scss';
import {useMutation, useQuery} from '@apollo/client';
import {toast} from 'react-toastify';
import DBModal from 'components/modal/modal';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {
  GET_ALL_STOCK_REWARDS,
  GET_ALL_STOCKS_BY_STOCK_EXCHANGE,
} from 'graphql/queries/rewardScheme';
import {
  CREATE_STOCK_REWARD_BANK,
  UPDATE_STOCK_REWARD_BANK,
} from 'graphql/mutations/rewardScheme';
import {walletCurrencies} from 'utils/mock';
import {NoUpdate} from 'components/noUpdate';
import {NavTabsEnum} from 'utils/constants';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {GET_COLLECTION_BY_ID} from 'graphql/queries/collections';
import {useNavigate, useParams} from 'react-router-dom';

const CollectionCompanies = () => {
  const {confirm} = Modal;
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [stockIndex, setStockIndex] = useState({});
  const [stockId, setStockId] = useState('');
  const [stock, setStock] = useState('');
  // const [limit, setLimit] = useState(10);
  // const [currentPage, setCurrentPage] = useState(1);
  const [allCompanies, setAllCompanies] = useState([]);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const {id} = useParams();

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.RewardBanks),
  );

  const {loading, refetch, data} = useQuery(GET_COLLECTION_BY_ID, {
    variables: {
      collectionId: id,
    },
    errorPolicy: 'all',
    onCompleted: data => setAllCompanies(data?.getCollection?.data?.companies),
    onError: error => toast.error(error?.message),
  });

  const handleStockInfo = (id, stock) => {
    setOpenEditModal(true);
    setStockId(id);
    setStock(stock);
  };

  // const handlePageChange = page => {
  //   if (page === currentPage) return;
  //   setCurrentPage(page);
  //   refetch({page: page, limit: limit});
  // };

  // useEffect(() => {
  //   setLimit(10);
  // }, []);

  // const handleLimitChange = size => {
  //   if (size === limit) return;
  //   setLimit(size);
  //   refetch({page: currentPage, limit: size});
  // };

  const {loading: stockLoading, data: getAllStocks} = useQuery(
    GET_ALL_STOCKS_BY_STOCK_EXCHANGE,
    {
      variables: {
        stockExchangeCode: 'BRVM',
      },
    },
  );

  const filterOption = (input, option) =>
    (option?.children ?? '').toLowerCase().includes(input.toLowerCase());

  const [createStockRewardBank] = useMutation(CREATE_STOCK_REWARD_BANK);
  const [updateStockRewardBank] = useMutation(UPDATE_STOCK_REWARD_BANK);

  const handleSave = formValues => {
    confirm({
      content: 'Are you sure you want to create this stock Reward?',
      onOk: () => {
        createStockRewardBank({
          variables: {
            data: {
              companyId:
                getAllStocks?.getAllStocksByStockExchange?.stocks[stockIndex]
                  ?.company?.id,
              currency: formValues?.currency,
              price: Number(formValues?.price || 0),
              purchasedUnits: Number(formValues?.purchasedUnits || 0),
              stockExchangeId:
                getAllStocks?.getAllStocksByStockExchange?.stocks[stockIndex]
                  ?.company?.StockExchange?.id,
              ticker:
                getAllStocks?.getAllStocksByStockExchange?.stocks[stockIndex]
                  ?.symbol,
            },
          },
          refetchQueries: [{query: GET_ALL_STOCK_REWARDS}],
        }).then(({data: {createStockRewardBank}}) => {
          if (createStockRewardBank.__typename === 'Error') {
            toast.error(createStockRewardBank.message);
          } else {
            setOpenCreateModal(false);
            refetch();
            form.resetFields();
            handleStockInfo();
            toast.success('Stock Reward Created Successfully');
          }
        });
      },
    });
  };

  const handleUpdate = formValues => {
    confirm({
      content: 'Are you sure you want to UPDATE this stock Reward?',
      onOk: () => {
        updateStockRewardBank({
          variables: {
            data: {
              stockBankId: stockId,
              price: Number(formValues?.price || 0),
              purchasedUnits: Number(formValues?.purchasedUnits || 0),
            },
          },
          refetchQueries: [{query: GET_ALL_STOCK_REWARDS}],
        }).then(({data: {updateStockRewardBank}}) => {
          if (updateStockRewardBank.__typename === 'Error') {
            toast.error(updateStockRewardBank.message);
          } else {
            setOpenEditModal(false);
            refetch();
            form.resetFields();
            toast.success('Stock Reward Updated Successfully');
          }
        });
      },
    });
  };

  const columns = [
    {
      title: 'Company Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <div
            onClick={() => navigate(`/dashboard/user/${record.id}`)}
            className={styles['record-name']}>
            <img src={record.logoImgURL} alt="" />
            <h4 style={{width: 100}}>{record.name}</h4>
          </div>
        </div>
      ),
    },
    {
      title: 'Stock Ticker',
      dataIndex: 'ticker',
      key: 'ticker',
      render: (text, record) => (
        <div>
          <span>{record?.stock?.symbol}</span>
        </div>
      ),
    },
    {
      title: 'Stock Amount Per Unit',
      dataIndex: 'lastPrice',
      key: 'lastPrice',
      render: (text, record) => (
        <div>
          <span>{record?.stock?.lastPrice}</span>
        </div>
      ),
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
      render: (text, record) => (
        <div>
          <span>{record?.industry.name}</span>
        </div>
      ),
    },
  ];

  return (
    <div className={styles['table-container']}>
      <div className={styles.header}>
        <h1>{data?.getCollection?.data?.name} Company List</h1>
        {/* Filter */}
      </div>
      <div className={styles['startup-table']}>
        {loading ? (
          <div className={styles.loading}>
            <Spin size="large" />
            <h1>Loading...</h1>
          </div>
        ) : allCompanies?.length >= 1 ? (
          <>
            <Table
              className={styles['user-table']}
              dataSource={allCompanies}
              columns={columns}
              pagination={true}
            />
          </>
        ) : (
          <NoUpdate
            title={"This collection doesn't have any company yet."}
            description={'Update the collection by adding companies to it.'}
            buttonLabel={'Edit Collection'}
            btnDisabled={!tabOperations?.create}
            onClick={() => setOpenCreateModal(true)}
          />
        )}
      </div>

      <DBModal
        isOpen={openCreateModal}
        handleClose={() => {
          setOpenCreateModal(false);
          form.resetFields();
        }}
        content={
          <div>
            <h1>Create Stock Account</h1>
            <Form
              form={form}
              className={styles.form}
              layout="vertical"
              onFinish={handleSave}>
              <Form.Item
                name="ticker"
                label="Stock Ticker"
                rules={[
                  {
                    required: true,
                    message: 'Ticker is required',
                  },
                ]}>
                <Select
                  style={{width: '100%'}}
                  name="ticker"
                  placeholder="ORNG"
                  loading={stockLoading}
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  onChange={e => setStockIndex(e)}>
                  {getAllStocks?.getAllStocksByStockExchange?.stocks?.map(
                    (item, index) => (
                      <Select.Option value={index} key={index}>
                        {item.symbol}
                      </Select.Option>
                    ),
                  )}
                </Select>
              </Form.Item>
              <Form.Item
                name="purchasedUnits"
                label="Stock Units"
                rules={[
                  {
                    required: true,
                    message: 'Units is required',
                  },
                ]}>
                <Input
                  type="number"
                  style={{width: '100%'}}
                  name="purchasedUnits"
                  placeholder="20"
                />
              </Form.Item>
              <Form.Item
                name="price"
                label="Purchased Price"
                rules={[
                  {
                    required: true,
                    message: 'Purchased price is required',
                  },
                ]}>
                <Input
                  type="number"
                  style={{width: '100%'}}
                  name="price"
                  placeholder="2000"
                />
              </Form.Item>
              <Form.Item
                name="currency"
                label="Purchased Currency"
                rules={[
                  {
                    required: true,
                    message: 'Currency is required',
                  },
                ]}>
                <Select
                  style={{width: '100%'}}
                  name="currency"
                  placeholder="USD">
                  {walletCurrencies?.map((item, index) => (
                    <Select.Option value={item.key} key={index}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <SubmitButton label="Create Stock Bank" />
            </Form>
          </div>
        }
      />

      <DBModal
        isOpen={openEditModal}
        handleClose={() => {
          setOpenEditModal(false);
          form.resetFields();
        }}
        content={
          <div>
            <h1 className={styles.h1}>Update {stock} Account</h1>
            <Form
              form={form}
              className={styles.form}
              layout="vertical"
              onFinish={handleUpdate}>
              <Form.Item
                name="purchasedUnits"
                label="Stock Units"
                rules={[
                  {
                    required: true,
                    message: 'Units is required',
                  },
                ]}>
                <Input
                  type="number"
                  style={{width: '100%'}}
                  name="purchasedUnits"
                  placeholder="20"
                />
              </Form.Item>
              <Form.Item
                name="price"
                label="Purchased Price"
                rules={[
                  {
                    required: true,
                    message: 'Purchased price is required',
                  },
                ]}>
                <Input
                  type="number"
                  style={{width: '100%'}}
                  name="price"
                  placeholder="2000"
                />
              </Form.Item>
              <SubmitButton label="Update account" />
            </Form>
          </div>
        }
      />
    </div>
  );
};

export {CollectionCompanies};
