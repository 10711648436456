import {useMutation, useQuery} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {Spin} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import UploadImage from 'components/Startup/profile/companyImage';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {UploadVideoToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {BigPlayButton, Player} from 'video-react';
import styles from '../../../new/screens/analysis/analysis.module.scss';

const UpdateCampaignAnalysis = () => {
  const [levelOneAnalysis, setLevelOneAnalysis] = useState('');
  const [levelTwoAnalysis, setLevelTwoAnalysis] = useState('');
  const [handleProgressOne, setHandleProgressOne] = useState(0);
  const [activeTab, setActiveTab] = useState('ONE');
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [handleProgressTwo, setHandleProgressTwo] = useState(0);
  const [levelOneThumbnail, setLevelOneThumbnail] = useState('');
  const [levelOnePreviewThumnail, setLevelOnePreviewThumbnail] = useState('');
  const [levelTwoThumbnail, setLevelTwoThumbnail] = useState('');
  const [levelTwoPreviewThumnail, setLevelTwoPreviewThumbnail] = useState('');

  const [levelOneLoading, setLevelOneLoading] = useState(false);
  const [levelTwoLoading, setLevelTwoLoading] = useState(false);

  const {id, language} = useParams();

  const {data, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });

  useEffect(() => {
    setLoadingUpload(
      (levelOneAnalysis && !levelOneThumbnail) ||
        (levelTwoAnalysis && !levelTwoThumbnail),
    );
  }, [
    levelOneThumbnail,
    levelOneAnalysis,
    activeTab,
    levelTwoAnalysis,
    levelTwoThumbnail,
  ]);

  const [updateCampaign, {loading: loadingUpdate}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    setLevelOneAnalysis(data?.getCampaignById?.level1AnalystVideo);
    setLevelTwoAnalysis(data?.getCampaignById?.level2AnalystVideo);
    setLevelOnePreviewThumbnail(
      data?.getCampaignById?.level1AnalystVideoThumbnail,
    );
    setLevelOneThumbnail(data?.getCampaignById?.level1AnalystVideoThumbnail);
    setLevelTwoThumbnail(data?.getCampaignById?.level2AnalystVideoThumbnail);
    setLevelTwoPreviewThumbnail(
      data?.getCampaignById?.level2AnalystVideoThumbnail,
    );
  }, [data]);

  const handleLevelOneUpload = async e => {
    const selectedFile = e.target.files[0];
    setLevelOneLoading(true);
    const response = await UploadVideoToBucket(
      selectedFile,
      'campaign-bucket-images',
      setHandleProgressOne,
    );
    setLevelOneAnalysis(response);
    setLevelOneLoading(false);
    setHandleProgressOne(0);
  };

  const handleLevelTwoUpload = async e => {
    const selectedFile = e.target.files[0];
    setLevelTwoLoading(true);
    const response = await UploadVideoToBucket(
      selectedFile,
      'campaign-bucket-images',
      setHandleProgressTwo,
    );
    setLevelTwoAnalysis(response);
    setLevelTwoLoading(false);
    setHandleProgressTwo(0);
  };

  const handleSave = () => {
    const optionalFields = {};
    if (levelOneAnalysis) {
      optionalFields.level1AnalystVideo = levelOneAnalysis;
      optionalFields.level1AnalystVideoThumbnail = levelOneThumbnail;
    }
    if (levelTwoAnalysis) {
      optionalFields.level2AnalystVideo = levelTwoAnalysis;
      optionalFields.level2AnalystVideoThumbnail = levelTwoThumbnail;
    }
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          language,
          level1AnalystVideo: levelOneAnalysis,
          level1AnalystVideoThumbnail: levelOneThumbnail,
          level2AnalystVideo: levelTwoAnalysis,
          level2AnalystVideoThumbnail: levelTwoThumbnail,
          fundraisingCompanyId: data?.getCampaignById?.fundraisingCompany?.id,
        },
      },
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error(updateCampaign.message);
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  const buttonLoading = loadingUpload || loadingUpdate;

  return (
    <div className={styles.container}>
      <div>
        <div className={styles['details-toggler']}>
          <span
            className={activeTab === 'ONE' ? styles['active-toggle'] : ''}
            onClick={() => setActiveTab('ONE')}>
            {' '}
            Level one
          </span>
          <span
            className={activeTab === 'TWO' ? styles['active-toggle'] : ''}
            onClick={() => setActiveTab('TWO')}>
            {' '}
            Level two
          </span>
        </div>
        {activeTab === 'ONE' && (
          <div>
            <h2 className={styles.title}>Level 1 Analysis - Video</h2>
            {levelOneAnalysis ? (
              <div>
                <Player fluid={false} width={550} src={levelOneAnalysis}>
                  <BigPlayButton position="center" />
                </Player>
                <div>
                  <Button
                    className={styles['delete-button']}
                    label="Delete Video"
                    type="secondary"
                    onClick={() => setLevelOneAnalysis(null)}
                  />
                </div>
              </div>
            ) : (
              <div>
                <label className={styles.upload} htmlFor="video-upload">
                  {levelOneLoading ? (
                    <div className={styles['upload-file']}>
                      <Spin />
                      <h2>Uploading Please Wait...</h2>
                      <span>{handleProgressOne}%</span>
                    </div>
                  ) : (
                    <h2>Upload Level 1 Analysis - Video</h2>
                  )}
                  <input
                    type="file"
                    id="video-upload"
                    accept="video/mp4"
                    className={styles['input-file']}
                    required
                    onChange={handleLevelOneUpload}
                  />
                </label>
              </div>
            )}
            <div className={styles['upload-image']}>
              <h2 className={styles.title}>Level 1 analysis - Thumbnail</h2>
              <UploadImage
                bucket={'campaign-bucket-images'}
                previewUrl={levelOnePreviewThumnail}
                setImgUrl={setLevelOneThumbnail}
                setPreviewUrl={setLevelOnePreviewThumbnail}
                accept
                thumbnail
              />
            </div>
          </div>
        )}
        {activeTab === 'TWO' && (
          <div>
            <h2 className={styles.title}>Level 2 Analysis - Video</h2>
            {levelTwoAnalysis ? (
              <div>
                <Player fluid={false} width={550} src={levelTwoAnalysis}>
                  <BigPlayButton position="center" />
                </Player>
                <div>
                  <Button
                    className={styles['delete-button']}
                    label="Delete Video"
                    type="secondary"
                    onClick={() => setLevelTwoAnalysis(null)}
                  />
                </div>
              </div>
            ) : (
              <div>
                <label className={styles.upload} htmlFor="video2-upload">
                  {levelTwoLoading ? (
                    <div className={styles['upload-file']}>
                      <Spin />
                      <h2>Uploading Please Wait...</h2>
                      <span>{handleProgressTwo}%</span>
                    </div>
                  ) : (
                    <h2>Upload Level 2 Analysis - Video</h2>
                  )}
                  <input
                    type="file"
                    id="video2-upload"
                    className={styles['input-file']}
                    accept="video/mp4"
                    required
                    onChange={handleLevelTwoUpload}
                  />
                </label>
              </div>
            )}
            <div className={styles['upload-image']}>
              <h2 className={styles.title}>Level 2 analysis - Thumbnail</h2>
              <UploadImage
                bucket={'campaign-bucket-images'}
                previewUrl={levelTwoPreviewThumnail}
                setImgUrl={setLevelTwoThumbnail}
                setPreviewUrl={setLevelTwoPreviewThumbnail}
                accept
                thumbnail
              />
            </div>
          </div>
        )}
        <SubmitButton
          disabled={buttonLoading}
          handleClick={handleSave}
          label="Save and Continue"
        />
      </div>
    </div>
  );
};

export default UpdateCampaignAnalysis;
