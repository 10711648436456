import {toast} from 'react-toastify';

export const onTranslateInvestorsUpdate = async (query, translateProps) => {
  const {
    sourceLang,
    targetLang,
    targetText1,
    targetText2,
    targetText3,
    targetText4,
  } = translateProps;
  try {
    const {data} = await query({
      variables: {
        translations: [
          {sourceLang, sourceText: targetText1, targetLang},
          {sourceLang, sourceText: targetText2, targetLang},
          {sourceLang, sourceText: targetText3, targetLang},
          {sourceLang, sourceText: targetText4, targetLang},
        ],
        treatment: 'html',
      },
    });
    return {
      title: data.translateText.translations[0],
      notificationTitle: data.translateText.translations[1],
      language: targetLang,
      content: data.translateText.translations[2],
      keyTakeAway: data.translateText.translations[3],
    };
  } catch (error) {
    toast.error('Something went wrong');
  }
};

export const handleChangesMadeToTranslation = (
  originalArray,
  temporalArray,
) => {
  if (originalArray.length !== temporalArray.length) return true;
  for (let i = 0; i < originalArray.length; i++) {
    const originalObj = originalArray[i];
    const temporalObj = temporalArray[i];

    for (const key in originalObj) {
      if (originalObj[key] !== temporalObj[key]) {
        return true;
      }
    }
  }
  return false;
};
