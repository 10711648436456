import {Dropdown, Menu} from 'antd';
import {useNavigate} from 'react-router-dom';
import editIcon from 'images/edit.svg';
import deleteIcon from 'images/delete.svg';
import userIcon from 'images/user.svg';
import checkIcon from 'images/check-icon.svg';
import dotsIcon from 'images/dots.svg';
import eyesIcon from 'images/eye-opened.svg';
import {Button} from '@dabafinance/react-components';
import styles from './card.module.scss';
import {useMutation} from '@apollo/client';
import {
  ADD_SETTLED_TRADES_TO_USERS_PORTFOLIO,
  DELETE_CAMPAIGN,
  UPDATE_CAMPAIGN_STATUS,
} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';
import {GET_ALL_FILTERED_CAMPAIGNS} from 'graphql/queries/campaign';
import DBModal from 'components/modal/modal';
import {Fragment, useState} from 'react';

export const DraftCampaignCard = ({
  id,
  fundraising,
  activeTab,
  visibleState,
  description,
  type,
  visibility,
  mode,
  offeringId,
  listingType,
  offeringType,
  operations,
  target,
  ipoDetails,
  ventureFundFields,
}) => {
  const [openEndModal, setOpenEndModal] = useState(false);
  const navigate = useNavigate();

  const [updateCampaignStatus] = useMutation(UPDATE_CAMPAIGN_STATUS);
  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN);
  const [addSettledTradesToUsersPortfolio] = useMutation(
    ADD_SETTLED_TRADES_TO_USERS_PORTFOLIO,
  );

  const handleUpdate = () =>
    navigate(`/dashboard/campaign/language-version/${id}`);

  const handleUpdateCampaignStatus = () => {
    updateCampaignStatus({
      variables: {
        updateCampaignStatusId: id,
        actionType: 'ACTIVE',
        campaignMode: mode,
      },
      refetchQueries: [
        {
          query: GET_ALL_FILTERED_CAMPAIGNS,
          variables: {
            listingStatus: activeTab,
            isVisible: JSON.parse(visibleState),
          },
        },
      ],
    })
      .then(({data: {updateCampaignStatus}}) => {
        if (updateCampaignStatus.__typename === 'Error') {
          toast.error(updateCampaignStatus.message);
        } else {
          toast.success('Listing is Active');
        }
      })
      .catch(() => {
        return toast.error('Something went wrong, unable to update');
      });
  };

  const handleSettledTrades = () => {
    addSettledTradesToUsersPortfolio({
      variables: {
        campaignId: id,
      },
    })
      .then(({data: {addSettledTradesToUsersPortfolio}}) => {
        if (addSettledTradesToUsersPortfolio.__typename === 'Error') {
          toast.error(addSettledTradesToUsersPortfolio.message);
        } else {
          toast.success('Settled Successfully');
        }
      })
      .catch(error => toast.error(error));
  };

  const handleEndCampaign = () => {
    updateCampaignStatus({
      variables: {
        updateCampaignStatusId: id,
        campaignMode: mode,
        actionType: 'ENDED',
      },
      refetchQueries: [
        {
          query: GET_ALL_FILTERED_CAMPAIGNS,
          variables: {
            listingStatus: activeTab,
            isVisible: JSON.parse(visibleState),
          },
        },
      ],
    })
      .then(({data: {updateCampaignStatus}}) => {
        if (updateCampaignStatus.__typename === 'Error') {
          toast.error(updateCampaignStatus.message);
        } else {
          toast.success('Ended Successfully');
          setOpenEndModal(false);
        }
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const handleSetTestToLive = () => {
    updateCampaignStatus({
      variables: {
        updateCampaignStatusId: id,
        campaignMode: 'LIVE',
        actionType: 'ACTIVE',
      },
      refetchQueries: [
        {
          query: GET_ALL_FILTERED_CAMPAIGNS,
          variables: {
            listingStatus: activeTab,
            isVisible: JSON.parse(visibleState),
          },
        },
      ],
    })
      .then(({data: {updateCampaignStatus}}) => {
        if (updateCampaignStatus.__typename === 'Error') {
          toast.error(updateCampaignStatus.message);
        } else {
          toast.success('Listing is now Live!!!');
          setOpenEndModal(false);
        }
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const handleDelete = () => {
    deleteCampaign({
      variables: {
        deleteCampaignId: id,
      },
      refetchQueries: [
        {
          query: GET_ALL_FILTERED_CAMPAIGNS,
          variables: {
            listingStatus: 'DRAFT',
            isVisible: JSON.parse(visibleState),
          },
        },
      ],
    })
      .then(({data: {deleteCampaign}}) => {
        if (deleteCampaign.__typename === 'Error') {
          toast.error(deleteCampaign.message);
        } else {
          toast.success('Listing Deleted Successfully');
        }
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const amountFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const draftMenu = (
    <Menu>
      {operations?.update && (
        <>
          <Menu.Item key="1" onClick={handleUpdateCampaignStatus}>
            <div className={styles['menu-items']}>
              <img src={checkIcon} alt="" />
              <span>Set Active</span>
            </div>
          </Menu.Item>
          <Menu.Item key="2" onClick={handleUpdate}>
            <div className={styles['menu-items']}>
              <img src={editIcon} alt="" />
              <span>Edit Campaign</span>
            </div>
          </Menu.Item>
        </>
      )}
      {operations?.delete && (
        <Menu.Item key="3" onClick={handleDelete}>
          <div className={styles['menu-items']}>
            <img src={deleteIcon} alt="" />
            <span>Delete Campaign</span>
          </div>
        </Menu.Item>
      )}
    </Menu>
  );

  const endedMenu = (
    <Menu>
      <Menu.Item key="1" onClick={handleUpdate}>
        <div className={styles['menu-items']}>
          <img src={editIcon} alt="" />
          <span>Preview Campaign</span>
        </div>
      </Menu.Item>
      {mode === 'LIVE' ? (
        <>
          {operations?.view && (
            <Menu.Item
              key="2"
              onClick={() =>
                navigate(
                  `/dashboard/campaign/offering-transactions/${offeringId}/${fundraising?.companyId}/${id}`,
                )
              }>
              <div className={styles['menu-items']}>
                <img src={eyesIcon} alt="" />
                <span>View Transactions</span>
              </div>
            </Menu.Item>
          )}
          {operations?.update && (
            <Menu.Item key="3" onClick={handleSettledTrades}>
              <div className={styles['menu-items']}>
                <img src={userIcon} alt="" />
                <span>Settle Users Trades</span>
              </div>
            </Menu.Item>
          )}
        </>
      ) : (
        <Fragment>
          <Menu.Item
            key="4"
            onClick={() =>
              navigate(
                `/dashboard/campaign/users-interested/${id}/${fundraising?.companyId}`,
              )
            }>
            <div className={styles['menu-items']}>
              <img src={eyesIcon} alt="" />
              <span>View Interested Users</span>
            </div>
          </Menu.Item>
        </Fragment>
      )}
      <Menu.Item key="5" onClick={handleSetTestToLive}>
        <div className={styles['menu-items']}>
          <img src={eyesIcon} alt="" />
          <span>Go Live</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  const liveMenu = (
    <Menu>
      {operations?.update && (
        <Menu.Item key="1" onClick={handleUpdate}>
          <div className={styles['menu-items']}>
            <img src={editIcon} alt="" />
            <span>Edit Campaign</span>
          </div>
        </Menu.Item>
      )}

      {mode === 'LIVE' ? (
        <>
          <Menu.Item
            key="2"
            onClick={() =>
              navigate(
                `/dashboard/campaign/offering-transactions/${offeringId}/${fundraising?.companyId}/${id}`,
              )
            }>
            <div className={styles['menu-items']}>
              <img src={eyesIcon} alt="" />
              <span>View Transactions</span>
            </div>
          </Menu.Item>
        </>
      ) : (
        <Menu.Item
          key="3"
          onClick={() =>
            navigate(
              `/dashboard/campaign/users-interested/${id}/${fundraising?.companyId}`,
            )
          }>
          <div className={styles['menu-items']}>
            <img src={eyesIcon} alt="" />
            <span>View Interested Users</span>
          </div>
        </Menu.Item>
      )}
      {operations?.update && (
        <Menu.Item key="4" onClick={() => setOpenEndModal(true)}>
          <div className={styles['menu-items']}>
            <img src={deleteIcon} alt="" />
            <span>End Campaign</span>
          </div>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div
      className={
        mode === 'LIVE' ? styles['ac-container-live'] : styles['ac-container']
      }>
      <div className={styles.campaignMode}>
        <span className={mode === 'TEST' ? styles.test : styles.live}>
          {mode}
        </span>
        <span className={visibility ? styles.visible : styles.hidden}>
          {visibility ? 'Visible' : 'Hidden'}
        </span>
      </div>
      <div onClick={handleUpdate} className={styles['ac-image']}>
        <img className={styles.image} src={fundraising?.bannerImgURL} alt="" />
      </div>
      <div className={styles['ac-body']}>
        <div className={styles['ac-title']}>
          <div>
            <img
              className={styles['ac-logo']}
              src={fundraising?.logoImgURL}
              alt=""
            />
          </div>
          <div className={styles['ac-icons']}>
            <Dropdown
              overlayStyle={{width: 250, height: 200}}
              trigger="hover"
              overlay={
                type === 'DRAFT'
                  ? draftMenu
                  : type === 'ENDED'
                  ? endedMenu
                  : liveMenu
              }>
              <img onClick={e => e.stopPropagation()} src={dotsIcon} alt="" />
            </Dropdown>
          </div>
        </div>
        <div className={styles['bottom-grid']}>
          <div className={styles['ac-header']}>
            <h1>{fundraising?.companyName}</h1>
            <div className={styles['ac-series']}>
              <span className={styles['ac-f-series']}>{offeringType}</span>
              <span
                className={
                  listingType === 'IPO' ? styles['t-ipo'] : styles['t-venture']
                }>
                {listingType}
              </span>
            </div>
          </div>
          {listingType === 'IPO' && (
            <span className={styles['ac-f-series']}>
              {ipoDetails?.offeringName}
            </span>
          )}
          {listingType === 'VENTURE_FUND' && (
            <span className={styles['ac-f-series']}>
              {ventureFundFields?.offeringName}
            </span>
          )}
          <div className={styles.description}>
            <span>{description?.substring(0, 120)}...</span>
          </div>
          <div className={styles.capital}>
            <h1>{amountFormatter.format(target)} </h1>
            <span>Target</span>
          </div>
        </div>
        <DBModal
          isOpen={openEndModal}
          handleClose={() => setOpenEndModal(false)}
          content={
            <div className={styles['modal-content']}>
              <h1>Are you sure you want to End this Campaign?</h1>
              <div>
                <Button
                  className={styles.button}
                  onClick={handleEndCampaign}
                  label="Yes"
                />
                <Button
                  className={styles.button}
                  type="secondary"
                  onClick={() => setOpenEndModal(false)}
                  label="No"
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};
