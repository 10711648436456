import {Form, Input, Modal} from 'antd';
import styles from './updateDocCategory.module.scss';
import {useEffect} from 'react';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useMutation} from '@apollo/client';
import {toast} from 'react-toastify';
import {UPDATE_COMPANY_DOCUMENT_CATEGORY} from 'graphql/mutations/companyStartups';

const UpdateDocCategory = ({data, closeModal, refetch}) => {
  const {confirm} = Modal;
  const [form] = Form.useForm();

  const [updateCompanyDocumentCategory, {loading}] = useMutation(
    UPDATE_COMPANY_DOCUMENT_CATEGORY,
  );

  useEffect(() => {
    form.setFieldsValue({
      name: data?.name,
      description: data?.description,
    });
  }, [data]);

  const handleFinish = values => {
    confirm({
      content: 'Are you sure you want to UPDATE this document category?',
      onOk: () => {
        updateCompanyDocumentCategory({
          variables: {
            data: {
              name: values?.name,
              description: values?.description,
              id: data?.id,
            },
          },
        })
          .then(({data: {updateCompanyDocumentCategory}}) => {
            if (updateCompanyDocumentCategory.__typename === 'Error') {
              toast.error(updateCompanyDocumentCategory.message);
            } else {
              closeModal();
              refetch({
                filter: {
                  paginate: true,
                },
              });
              toast.success('Category Updated Successfully');
            }
          })
          .catch(error => {
            toast.error(error);
          });
      },
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1>Update {data?.name} Category</h1>
      </div>
      <Form
        form={form}
        onFinish={handleFinish}
        className={styles.form}
        layout="vertical">
        <Form.Item
          rules={[{required: true, message: 'This Field is Required'}]}
          name="name"
          className={styles.fieldWidth}
          label="Category Name">
          <Input className={styles.width} />
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: 'This Field is Required'}]}
          name="description"
          className={styles.fieldWidth}
          label="Description">
          <Input.TextArea className={styles.width} row={5} />
        </Form.Item>
        <SubmitButton disabled={loading} label="Update" />
      </Form>
    </div>
  );
};

export default UpdateDocCategory;
