import {useState} from 'react';
import styles from './interestsTable.module.scss';
import {Search, Button} from '@dabafinance/react-components';
import {Spin, Modal} from 'antd';
import {DownOutlined, UpOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {GET_ALL_TOPICS} from 'graphql/queries/interest';
import {useMutation, useQuery} from '@apollo/client';
import pageNotFound from 'images/pageNotFound.png';
import {DraggableTable} from 'components/draggableTable';
import {SortableHandle} from 'react-sortable-hoc';
import trash from 'images/trash-can-outline.svg';
import {toast} from 'react-toastify';
import {DELETE_TOPIC} from 'graphql/mutations/interest';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';
import {useSelector} from 'react-redux';

const {confirm} = Modal;

const DragHandle = SortableHandle(() => (
  <div className={styles['drag-icon']}>
    <DownOutlined />
    <UpOutlined />
  </div>
));

export default function InterestsTable() {
  const [searchParam, setSearchParam] = useState('');
  const [searchData, setSearchData] = useState([]);

  const {loading, data, refetch} = useQuery(GET_ALL_TOPICS, {
    variables: {
      sequence: true,
    },
  });

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Topics),
  );

  const navigate = useNavigate();

  const [deleteTopic] = useMutation(DELETE_TOPIC);

  const handleDelete = id => {
    confirm({
      content: 'Are you sure you want to delete this Topic?',

      onOk: () => {
        deleteTopic({
          variables: {
            topicId: id,
          },
          update(cache) {
            cache.modify({
              fields: {
                getAllTopics() {},
              },
            });
          },
        })
          .then(({data: {deleteTopic}}) => {
            if (deleteTopic.__typename === 'Error') {
              toast.error(deleteTopic.message);
            } else {
              toast.success('Topic deleted Successfully');
              refetch();
            }
          })
          .catch(error => toast.error(error));
      },
    });
  };

  const handleSearch = term => {
    const trimmedTerm = term.trim();
    setSearchParam(trimmedTerm);

    if (term) {
      const newList = data?.getAllTopics.filter(data => {
        return Object.values(data)
          .join(' ')
          .toLowerCase()
          .includes(term.toLowerCase());
      });
      setSearchData(newList);
    } else {
      setSearchData(data?.getAllTopics);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: 'drag-visible',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <DragHandle />
          <img src={record.icon} alt="" className={styles['interest-icon']} />
          <span>{record.name}</span>
        </div>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'type',
      key: 'category',
      className: 'drag-visible',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>{record.type}</span>
        </div>
      ),
    },
    {
      title: 'Rank Order',
      dataIndex: 'sequence',
      key: 'rankOrder',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>{record.sequence}</span>
        </div>
      ),
    },

    {
      title: 'Users Interested',
      dataIndex: 'numOfFollowers',
      key: 'usersInterested',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>{record.numOfFollowers}</span>
        </div>
      ),
    },
    {
      title: 'Companies',
      dataIndex: 'relatedCompanies',
      key: 'companies',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>{record.relatedCompanies.length}</span>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <Button
          type="secondary"
          label="View details"
          disabled={!tabOperations?.update}
          className={styles.button}
          onClick={() => navigate(`/dashboard/interest/${record.id}`)}
        />
      ),
    },
  ];

  if (tabOperations?.delete) {
    columns.push({
      title: '',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <img
          onClick={() => {
            handleDelete(record.id);
          }}
          className={styles.trash}
          src={trash}
          alt=""
        />
      ),
    });
  }

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>All Topics</h2>
        <div className={styles.actions}>
          <Search
            id="search-interests"
            placeholder="Search Interests"
            onChange={e => handleSearch(e)}
            className={styles.search}
          />
        </div>
      </div>
      <div>
        {data?.getAllTopics.length ? (
          <DraggableTable
            columns={columns}
            data={searchParam.length < 1 ? data?.getAllTopics : searchData}
          />
        ) : (
          <div className={styles['no-update']}>
            <img src={pageNotFound} alt="" />
            <h1>No Interest</h1>
            <span>Add a new Interest</span>
          </div>
        )}
      </div>
    </div>
  );
}
