import {Form, Input, Select} from 'antd';
import {Fragment, useEffect, useState} from 'react';
import styles from './investmentContract.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useNavigate, useParams} from 'react-router-dom';
import FadeEffect from 'components/animation/fadeEffect';
import {useDispatch, useSelector} from 'react-redux';
import {investmentContractSettings} from 'redux/store/campaign';

const InvestmentContract = () => {
  const [carryPercentageTerms, setCarryPercentageTerms] = useState('');
  const [investmentContractType, setInvestmentContractType] = useState('');
  const [investmentTargetEntityLegalName, setInvestmentTargetEntityLegalName] =
    useState('');
  const [managementFeeTerms, setManagementFeeTerms] = useState('');
  const [minimumInvestmentPerMember, setMinimumInvestmentPerMember] =
    useState('');
  const [spvManagerRepresentativeName, setSpvManagerRepresentativeName] =
    useState('');
  const [spvNamePrefix, setSpvNamePrefix] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const navigate = useNavigate();
  const {id, companyId} = useParams();
  const dispatch = useDispatch();

  const campaign = useSelector(state => state.campaign.campaign);

  const validate =
    !carryPercentageTerms ||
    !investmentContractType ||
    !investmentTargetEntityLegalName ||
    !managementFeeTerms ||
    !minimumInvestmentPerMember ||
    !spvManagerRepresentativeName ||
    !spvNamePrefix;

  useEffect(() => {
    if (validate) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [disableButton, validate]);

  const {Option} = Select;

  useEffect(() => {
    if (campaign?.investmentContractSettings !== undefined) {
      setCarryPercentageTerms(
        campaign?.investmentContractSettings?.carryPercentageTerms,
      );
      setInvestmentContractType(
        campaign?.investmentContractSettings?.investmentContractType,
      );
      setInvestmentTargetEntityLegalName(
        campaign?.investmentContractSettings?.investmentTargetEntityLegalName,
      );
      setManagementFeeTerms(
        campaign?.investmentContractSettings?.managementFeeTerms,
      );
      setMinimumInvestmentPerMember(
        campaign?.investmentContractSettings?.minimumInvestmentPerMember,
      );
      setSpvManagerRepresentativeName(
        campaign?.investmentContractSettings?.spvManagerRepresentativeName,
      );
      setSpvNamePrefix(campaign?.investmentContractSettings?.spvNamePrefix);
    }
  }, [campaign?.investmentContractSettings]);

  const handleSave = () => {
    dispatch(
      investmentContractSettings({
        investmentContractSettings: {
          carryPercentageTerms,
          investmentContractType,
          investmentTargetEntityLegalName,
          managementFeeTerms,
          minimumInvestmentPerMember,
          spvManagerRepresentativeName,
          spvNamePrefix,
        },
      }),
    );
    navigate(`/dashboard/campaigns/new/${id}/currency-settings/${companyId}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Investment Contract Settings</h1>
      </div>
      <div>
        <Form onFinish={handleSave} layout="vertical">
          <Fragment>
            <FadeEffect>
              <div className={styles['question-style']}>
                <Form.Item
                  style={{width: 320, marginRight: 20}}
                  label="Investment Contract Type"
                  initialValue={
                    campaign?.investmentContractSettings?.investmentContractType
                  }>
                  <Select
                    value={investmentContractType}
                    onChange={e => setInvestmentContractType(e)}
                    placeholder="Select contract type"
                    name="investmentContractType"
                    defaultValue={
                      campaign?.investmentContractSettings
                        ?.investmentContractType
                    }>
                    <Option value="SIMPLE_AGREEMENT_FOR_FUTURE_EQUITY">
                      Simple Agreement for Future Equity
                    </Option>
                    <Option value="PRICED_EQUITY">Priced Equity</Option>
                    <Option value="DEBT">Debt</Option>
                    <Option value="REVENUE_SHARE">Revenue Share</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{width: 320, marginRight: 20}}
                  label="Investment Target Entity Legal Name"
                  initialValue={
                    campaign?.investmentContractSettings
                      ?.investmentTargetEntityLegalName
                  }>
                  <Input
                    value={investmentTargetEntityLegalName || ''}
                    name="investmentTargetEntityLegalName"
                    onChange={e =>
                      setInvestmentTargetEntityLegalName(e.target.value)
                    }
                    placeholder="Investment Target Entity Legal Name"
                    defaultValue={
                      campaign?.investmentContractSettings
                        ?.investmentTargetEntityLegalName
                    }
                  />
                </Form.Item>
              </div>
              <div className={styles['question-style']}>
                <Form.Item
                  style={{width: 320, marginRight: 20}}
                  label="Management Fee Terms"
                  initialValue={
                    campaign?.investmentContractSettings?.managementFeeTerms
                  }>
                  <Input
                    value={managementFeeTerms || ''}
                    name="managementFeeTerms"
                    onChange={e => setManagementFeeTerms(e.target.value)}
                    placeholder="Management Fee Terms"
                    defaultValue={
                      campaign?.investmentContractSettings?.managementFeeTerms
                    }
                  />
                </Form.Item>
                <Form.Item
                  style={{width: 320, marginRight: 20}}
                  label="Minimum Investment Per Member"
                  initialValue={
                    campaign?.investmentContractSettings
                      ?.minimumInvestmentPerMember
                  }>
                  <Input
                    value={minimumInvestmentPerMember || ''}
                    name="minimumInvestmentPerMember"
                    onChange={e =>
                      setMinimumInvestmentPerMember(e.target.value)
                    }
                    placeholder="Minimum Investment Per Member"
                    defaultValue={
                      campaign?.investmentContractSettings
                        ?.minimumInvestmentPerMember
                    }
                  />
                </Form.Item>
              </div>
              <div className={styles['question-style']}>
                <Form.Item
                  style={{width: 320, marginRight: 20}}
                  label="Spv Manager Representative Name"
                  initialValue={
                    campaign?.investmentContractSettings
                      ?.spvManagerRepresentativeName
                  }>
                  <Input
                    value={spvManagerRepresentativeName || ''}
                    name="spvManagerRepresentativeName"
                    onChange={e =>
                      setSpvManagerRepresentativeName(e.target.value)
                    }
                    placeholder="Spv Manager Representative Name"
                    defaultValue={
                      campaign?.investmentContractSettings
                        ?.spvManagerRepresentativeName
                    }
                  />
                </Form.Item>
                <Form.Item
                  style={{width: 320, marginRight: 20}}
                  label="Spv Name Prefix"
                  initialValue={
                    campaign?.investmentContractSettings?.spvNamePrefix
                  }>
                  <Input
                    value={spvNamePrefix || ''}
                    name="spvNamePrefix"
                    onChange={e => setSpvNamePrefix(e.target.value)}
                    placeholder="Spv Name Prefix"
                    defaultValue={
                      campaign?.investmentContractSettings?.spvNamePrefix
                    }
                  />
                </Form.Item>
              </div>
              <div className={styles['question-style']}>
                <Form.Item
                  style={{width: 320, marginRight: 20}}
                  label="Percentage Terms"
                  initialValue={
                    campaign?.investmentContractSettings?.carryPercentageTerms
                  }>
                  <Input
                    value={carryPercentageTerms || ''}
                    name="carryPercentageTerms"
                    onChange={e => setCarryPercentageTerms(e.target.value)}
                    placeholder="Percentage Terms"
                    defaultValue={
                      campaign?.investmentContractSettings?.carryPercentageTerms
                    }
                  />
                </Form.Item>
              </div>
            </FadeEffect>
          </Fragment>
        </Form>
        <div className={styles['warning']}>
          {disableButton && (
            <>
              {' '}
              Please make sure to input all Investment contract settings before
              moving forward
            </>
          )}
        </div>
        <SubmitButton
          disabled={disableButton}
          handleClick={handleSave}
          label="Save & Continue"
        />
      </div>
    </div>
  );
};

export default InvestmentContract;
