import {Button} from '@dabafinance/react-components';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import {Collapse, Form, InputNumber, Spin, Switch} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import FadeEffect from 'components/animation/fadeEffect';
import styles from '../../collection.module.scss';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import DBModal from 'components/modal/modal';
import {ViewUserDocument} from 'pages/adminUser/userDetails/tabs/documents/viewDocument';
import {useState} from 'react';

export const DabaScore = ({
  thirdForm,
  setOpenConfirmModal,
  viewPdf,
  pdfReport,
  handlePdfFileChange,
  handleDeleteFile,
  pdfLoading,
  reportName,
  onTabChange,
}) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [viewModal, setViewModal] = useState({});

  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const handleCollapseChange = key => {
    setIsPanelOpen(key.includes('1'));
  };

  return (
    <div>
      <Form
        form={thirdForm}
        className={styles.form}
        layout="vertical"
        onFinish={() => setOpenConfirmModal(true)}>
        <Collapse
          onChange={handleCollapseChange}
          className={styles.panel}
          ghost>
          <Collapse.Panel
            key={'1'}
            header={<h2>Daba Score (Click to Open)</h2>}>
            <div className={styles['date-format']}>
              <Form.Item
                className={styles.halfWidth}
                name="dabaScore"
                rules={[
                  {required: isPanelOpen, message: 'This is a required field'},
                ]}
                label="Daba Score">
                <InputNumber
                  max={10}
                  className={styles.halfWidth}
                  placeholder="Enter number"
                />
              </Form.Item>
              <Form.Item
                className={styles.halfWidth}
                name="business"
                rules={[
                  {required: isPanelOpen, message: 'This is a required field'},
                ]}
                label="Business">
                <InputNumber
                  max={10}
                  className={styles.halfWidth}
                  placeholder="Enter number"
                />
              </Form.Item>
              <Form.Item
                className={styles.halfWidth}
                name="IndustryScore"
                rules={[
                  {required: isPanelOpen, message: 'This is a required field'},
                ]}
                label="Industry">
                <InputNumber
                  max={10}
                  className={styles.halfWidth}
                  placeholder="Enter number"
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {required: isPanelOpen, message: 'This is a required field'},
                ]}
                className={styles.halfWidth}
                name="Market"
                label="Market">
                <InputNumber
                  max={10}
                  className={styles.halfWidth}
                  placeholder="Enter number"
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {required: isPanelOpen, message: 'This is a required field'},
                ]}
                className={styles.halfWidth}
                name="Team"
                label="Team">
                <InputNumber
                  max={10}
                  className={styles.halfWidth}
                  placeholder="Enter number"
                />
              </Form.Item>
              <Form.Item
                className={styles.halfWidth}
                name="Strategy"
                rules={[
                  {required: isPanelOpen, message: 'This is a required field'},
                ]}
                label="Strategy, Product GTM">
                <InputNumber
                  max={10}
                  className={styles.halfWidth}
                  placeholder="Enter number"
                />
              </Form.Item>
              <Form.Item
                className={styles.halfWidth}
                name="Accelerator"
                rules={[
                  {required: isPanelOpen, message: 'This is a required field'},
                ]}
                label="Accelerator">
                <InputNumber
                  max={10}
                  className={styles.halfWidth}
                  placeholder="Enter number"
                />
              </Form.Item>
              <Form.Item
                className={styles.halfWidth}
                name="Risks"
                rules={[
                  {required: isPanelOpen, message: 'This is a required field'},
                ]}
                label="Risks + Challenges">
                <InputNumber
                  max={10}
                  className={styles.halfWidth}
                  placeholder="Enter number"
                />
              </Form.Item>
              <Form.Item
                className={styles.halfWidth}
                name="Analogue"
                rules={[
                  {required: isPanelOpen, message: 'This is a required field'},
                ]}
                label="Analogue">
                <InputNumber
                  max={10}
                  className={styles.halfWidth}
                  placeholder="Enter number"
                />
              </Form.Item>
              <Form.Item
                className={styles.halfWidth}
                name="Economics"
                rules={[
                  {required: isPanelOpen, message: 'This is a required field'},
                ]}
                label="Economics / Financials">
                <InputNumber
                  max={10}
                  className={styles.halfWidth}
                  placeholder="Enter number"
                />
              </Form.Item>
              <Form.Item
                className={styles.halfWidth}
                name="Legal"
                rules={[
                  {required: isPanelOpen, message: 'This is a required field'},
                ]}
                label="Legal & Compliance">
                <InputNumber
                  max={10}
                  className={styles.halfWidth}
                  placeholder="Enter number"
                />
              </Form.Item>
              <Form.Item
                className={styles.halfWidth}
                name="Returns"
                rules={[
                  {required: isPanelOpen, message: 'This is a required field'},
                ]}
                label="Returns Outlook">
                <InputNumber
                  max={10}
                  className={styles.halfWidth}
                  placeholder="Enter number"
                />
              </Form.Item>
              <Form.Item
                className={styles.halfWidth}
                name="Social"
                rules={[
                  {required: isPanelOpen, message: 'This is a required field'},
                ]}
                label="Social Impact">
                <InputNumber
                  max={10}
                  className={styles.halfWidth}
                  placeholder="Enter number"
                />
              </Form.Item>
            </div>
          </Collapse.Panel>
        </Collapse>

        <Form.Item
          label="Collection Active Status"
          className={styles.field}
          name="status"
          initialValue={true}
          rules={[{required: true, message: 'Required Field'}]}>
          <Switch defaultChecked={true} />
        </Form.Item>
        <div>
          <h1 className={styles['pdf-title']}>Full Report PDF</h1>
          <div className={styles.button}>
            {pdfReport && (
              <Button
                disabled={pdfReport === null}
                label="Preview Pdf"
                onClick={() =>
                  setViewModal({
                    isOpen: true,
                    documentUrl: pdfReport,
                    documentName: reportName,
                  })
                }
              />
            )}
          </div>
          {!viewPdf && (
            <form>
              <label className={styles.upload} htmlFor="file-upload">
                {pdfLoading ? <Spin size="large" /> : <span>{reportName}</span>}
                <input
                  type="file"
                  id="file-upload"
                  accept="application/pdf"
                  className={styles['input-file']}
                  required
                  onChange={handlePdfFileChange}
                />
              </label>
            </form>
          )}
          {viewPdf && (
            <FadeEffect>
              <div className={styles.button}>
                <SubmitButton
                  label="Delete File"
                  handleClick={handleDeleteFile}
                />
              </div>
              <div className={styles['pdf-height']}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={viewPdf}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </Worker>
              </div>
            </FadeEffect>
          )}
        </div>
        <div className={styles.buttons}>
          <Button
            label="Previous"
            type="secondary"
            onClick={() => onTabChange('FINANCIAL')}
          />
          <SubmitButton
            label="Save Collection"
            onClick={() => setOpenConfirmModal(true)}
          />
        </div>
      </Form>

      <DBModal
        isOpen={viewModal.isOpen}
        handleClose={() => setViewModal({isOpen: false})}
        width={900}
        content={
          <ViewUserDocument
            url={viewModal?.documentUrl}
            documentName={viewModal?.documentName}
          />
        }
      />
    </div>
  );
};
