import {useState} from 'react';
import styles from './userReferral.module.scss';
import {Table, Pagination} from 'antd';
import {Button} from '@dabafinance/react-components';
import DBModal from 'components/modal/modal';
import {useNavigate} from 'react-router-dom';
import {useLazyQuery, useQuery} from '@apollo/client';
import moment from 'moment';
import {
  GET_ALL_USER_INVITES,
  GET_USER_INVITES,
} from 'graphql/queries/userReferral';
import gamer from 'images/gamer.png';
import {toast} from 'react-toastify';
import {currencySymbols} from 'utils/mock';

const UserReferral = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [invitedUserCurrentPage, setInvitedUserCurrentPage] = useState(1);
  const [invitedUserLimit, setInvitedUserLimit] = useState(10);
  const [limit, setLimit] = useState(10);

  const {data, loading, refetch} = useQuery(GET_ALL_USER_INVITES, {
    variables: {
      limit: 10,
      page: 1,
    },
    notifyOnNetworkStatusChange: true,
  });
  const [
    getUserInvites,
    {refetch: refetchUsers, data: userInvites, loading: userInviteListLoading},
  ] = useLazyQuery(GET_USER_INVITES);

  const openInvitesModal = id => {
    getUserInvites({
      variables: {
        user: id,
        page: 1,
        limit: 10,
      },
      onError: error => toast.error(error),
      notifyOnNetworkStatusChange: true,
    });
    setOpenModal(true);
  };

  const handleUserLimitChange = size => {
    if (size === invitedUserLimit) return;
    setInvitedUserLimit(size);
    setInvitedUserCurrentPage(1);
    refetchUsers({page: 1, limit: size});
  };

  const handleChangeInvitedUserPage = page => {
    if (page <= invitedUserCurrentPage) return;
    setInvitedUserCurrentPage(page);
    refetchUsers({page: page, limit: invitedUserLimit});
  };

  const inviteColumns = [
    {
      title: 'Name',
      dataIndex: 'username',
      key: 'username',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <div
            onClick={() =>
              navigate(`/dashboard/user/${record?.invitedUser?.id}`)
            }
            className={styles['record-label']}>
            {record?.invitedUser?.imageUrl ? (
              <img src={record?.invitedUser?.imageUrl} alt="" />
            ) : (
              <img src={gamer} alt="" />
            )}
            <h4 style={{width: 100}}>
              {record?.invitedUser?.firstName} {record?.invitedUser?.lastName}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => <span>{record?.invitedUser?.email}</span>,
    },
    {
      title: 'First Deposit Amount',
      dataIndex: 'investedAmount',
      key: 'investedAmount',
      render: (text, record) => (
        <div>
          <p>
            {record?.firstDeposit?.currency
              ? currencySymbols[record?.firstDeposit?.currency]?.symbol
              : ''}{' '}
            {record?.firstDeposit?.amount?.toLocaleString()}
          </p>
        </div>
      ),
    },
    {
      title: 'Date Invited',
      dataIndex: 'invitedDate',
      key: 'invitedDate',
      render: (text, record) => (
        <p>{moment(record?.invitedDate).format('DD, MMMM YYYY')}</p>
      ),
    },
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'username',
      key: 'username',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <div
            onClick={() => navigate(`/dashboard/user/${record?.user?.id}`)}
            className={styles['record-label']}>
            {record?.user?.imageUrl ? (
              <img src={record?.user?.imageUrl} alt="" />
            ) : (
              <img src={gamer} alt="" />
            )}
            <h4 style={{width: 100}}>
              {record?.user?.firstName} {record?.user?.lastName}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: 'Referral Count',
      dataIndex: 'referrals',
      key: 'referrals',
      render: (text, record) => (
        <div>
          <p>{record?.count}</p>
        </div>
      ),
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (text, record) =>
        record?.userTotalRewardEarned ? (
          <p>${record?.userTotalRewardEarned}</p>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <div>
          <Button
            onClick={() => openInvitesModal(record?.user?.id)}
            type="secondary"
            label="View Referrals"
            className={styles['deactivate-btn']}
          />
        </div>
      ),
    },
  ];

  return (
    <div className={styles['container-wrapper']}>
      <div className={styles['header-container']}></div>

      <div>
        <DBModal
          isOpen={openModal}
          handleClose={() => {
            setOpenModal(false);
            setInvitedUserCurrentPage(1);
            setInvitedUserLimit(10);
          }}
          width={1000}
          content={
            <div className={styles['modal-container']}>
              <h2 className={styles['modal-header']}>User Invites</h2>
              <div>
                <Table
                  loading={userInviteListLoading}
                  columns={inviteColumns}
                  dataSource={userInvites?.getUserInvites?.userReferral}
                  pagination={false}
                />
                <Pagination
                  current={invitedUserCurrentPage}
                  onChange={page => handleChangeInvitedUserPage(page)}
                  total={userInvites?.getUserInvites?.pagination?.total}
                  showQuickJumper
                  className="ant-table-pagination"
                  showSizeChanger
                  pageSize={invitedUserLimit}
                  onShowSizeChange={(_, size) => handleUserLimitChange(size)}
                />
              </div>
            </div>
          }
        />
      </div>

      <div className={styles['startup-table']}>
        <div className={styles['startup-header']}>
          <h1>User Referrals</h1>
          <h2>
            TOTAL REFERRALS:{' '}
            <span>{data?.getAllUsersInvites?.totalInvitedUsers || 0}</span>
          </h2>
        </div>
        <div className={styles['table-height']}>
          <div>
            <Table
              loading={loading}
              columns={columns}
              dataSource={data?.getAllUsersInvites?.users}
              pagination={false}
            />
            <Pagination
              current={currentPage}
              onChange={page => {
                if (page <= currentPage) return;
                setCurrentPage(page);
                refetch({page: page, limit: limit});
              }}
              total={data?.getAllUsersInvites?.pagination?.total}
              showQuickJumper
              className="ant-table-pagination"
              showSizeChanger
              pageSize={limit}
              onShowSizeChange={(_, size) => {
                if (size === limit) return;
                setLimit(size);
                setCurrentPage(1);
                refetch({page: 1, limit: size});
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export {UserReferral};
