import {gql} from '@apollo/client';

export const GET_ALL_KEY_STAT = gql`
  query GetKeyStats {
    getKeyStats {
      ... on Error {
        message
        statusCode
      }
      ... on KeyStat {
        name
        language
        description
        applicableAssetClasses
        formatType
        isComputed
        canBeManuallyOverridden
        id
      }
    }
  }
`;

export const GET_KEY_STAT_BY_ID = gql`
  query GetKeyStatById($keystatsId: ID!) {
    getKeyStatById(keystatsId: $keystatsId) {
      ... on Error {
        message
        statusCode
      }
      ... on KeyStat {
        name
        language
        description
        applicableAssetClasses
        formatType
        isComputed
        canBeManuallyOverridden
        multiLang {
          name
          language
          description
          parent
        }
        id
      }
    }
  }
`;

export const GET_COMPANY_KEY_METRIC = gql`
  query GetCompanyKeyMetrics($companyId: ID!) {
    getCompanyKeyMetrics(companyId: $companyId) {
      ... on Error {
        message
        statusCode
      }
      ... on KeyStat {
        name
        language
        description
        applicableAssetClasses
        formatType
        isComputed
        canBeManuallyOverridden
        id
      }
    }
  }
`;
