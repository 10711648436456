import {DatePicker, Form, Input} from 'antd';
import moment from 'moment';
import styles from '../ventureFund.module.scss';

const FundObjectives = ({campaign, objectives, handleChange}) => {
  return (
    <div className={styles.form}>
      <Form.Item
        className={styles['field-width']}
        label="Fund Name"
        name="fundName"
        rules={[
          {
            required: true,
            message: 'Required Field',
          },
        ]}
        initialValue={campaign?.ventureFundFields?.fundObjectives?.fundName}>
        <Input
          defaultValue={campaign?.ventureFundFields?.fundObjectives?.fundName}
          value={objectives.fundName}
          onChange={e => handleChange('fundName', e.target.value)}
        />
      </Form.Item>
      <Form.Item
        className={styles['field-width']}
        label="Fund Closed Date"
        name="fundsClosedToDate"
        rules={[
          {
            required: true,
            message: 'Required Field',
          },
        ]}
        initialValue={
          campaign?.ventureFundFields?.fundObjectives?.fundsClosedToDate
            ? moment(
                campaign?.ventureFundFields?.fundObjectives?.fundsClosedToDate,
                'YYYY-MM-DD HH:mm:ss',
              )
            : ''
        }>
        <DatePicker
          name="fundsClosedToDate"
          value={objectives?.fundsClosedToDate}
          style={{width: 340}}
          defaultValue={
            campaign?.ventureFundFields?.fundObjectives?.fundsClosedToDate
              ? moment(
                  campaign?.ventureFundFields?.fundObjectives
                    ?.fundsClosedToDate,
                  'YYYY-MM-DD HH:mm:ss',
                )
              : ''
          }
          format={'YYYY-MM-DD HH:mm:ss'}
          showTime={{
            defaultValue: moment('00:00', 'HH:mm:ss'),
          }}
          onChange={e =>
            handleChange(
              'fundsClosedToDate',
              moment(e).format('YYYY-MM-DD HH:mm:ss'),
            )
          }
        />
      </Form.Item>
      <Form.Item
        className={styles['field-width']}
        label="Target Average Check Size"
        name="targetAverageCheckSize"
        rules={[
          {
            required: true,
            message: 'Required Field',
          },
        ]}
        initialValue={
          campaign?.ventureFundFields?.fundObjectives?.targetAverageCheckSize
        }>
        <Input
          defaultValue={
            campaign?.ventureFundFields?.fundObjectives?.targetAverageCheckSize
          }
          value={objectives.targetAverageCheckSize}
          type="number"
          onChange={e => handleChange('targetAverageCheckSize', e.target.value)}
        />
      </Form.Item>
      <Form.Item
        className={styles['field-width']}
        label="Target Fund Size"
        name="targetFundSize"
        rules={[
          {
            required: true,
            message: 'Required Field',
          },
        ]}
        initialValue={
          campaign?.ventureFundFields?.fundObjectives?.targetFundSize
        }>
        <Input
          defaultValue={
            campaign?.ventureFundFields?.fundObjectives?.targetFundSize
          }
          value={objectives.targetFundSize}
          type="number"
          onChange={e => handleChange('targetFundSize', e.target.value)}
        />
      </Form.Item>
      <Form.Item
        className={styles['field-width']}
        label="Target IRR (%)"
        name="targetIRR"
        rules={[
          {
            required: true,
            message: 'Required Field',
          },
        ]}
        initialValue={campaign?.ventureFundFields?.fundObjectives?.targetIRR}>
        <Input
          defaultValue={campaign?.ventureFundFields?.fundObjectives?.targetIRR}
          value={objectives.targetIRR}
          type="number"
          onChange={e => handleChange('targetIRR', e.target.value)}
        />
      </Form.Item>
      <Form.Item
        className={styles['field-width']}
        label="Target Multiple On Invested Capital"
        name="targetMultipleOnInvestedCapital"
        rules={[
          {
            required: true,
            message: 'Required Field',
          },
        ]}
        initialValue={
          campaign?.ventureFundFields?.fundObjectives
            ?.targetMultipleOnInvestedCapital
        }>
        <Input
          defaultValue={
            campaign?.ventureFundFields?.fundObjectives
              ?.targetMultipleOnInvestedCapital
          }
          value={objectives.targetMultipleOnInvestedCapital}
          type="number"
          onChange={e =>
            handleChange('targetMultipleOnInvestedCapital', e.target.value)
          }
        />
      </Form.Item>
      <Form.Item
        className={styles['field-width']}
        label="Target Number Of Investments"
        name="targetNumberOfInvestments"
        rules={[
          {
            required: true,
            message: 'Required Field',
          },
        ]}
        initialValue={
          campaign?.ventureFundFields?.fundObjectives?.targetNumberOfInvestments
        }>
        <Input
          defaultValue={
            campaign?.ventureFundFields?.fundObjectives
              ?.targetNumberOfInvestments
          }
          value={objectives.targetNumberOfInvestments}
          type="number"
          onChange={e =>
            handleChange('targetNumberOfInvestments', e.target.value)
          }
        />
      </Form.Item>
    </div>
  );
};

export default FundObjectives;
