import React, {useState} from 'react';
import styles from './textInput.module.scss';
import classNames from 'classnames/bind';

const TextInput = React.forwardRef((props, ref) => {
  const {label, icon, error} = props;
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState(props.type || 'password');
  const cx = classNames.bind(styles);

  const typeHandler = () => {
    if (showPassword) {
      setShowPassword(false);
      setInputType('password');
    } else {
      setShowPassword(true);
      setInputType('text');
    }
  };

  return (
    <div className={cx('text-input-container')}>
      <span className={cx('label')}>{label}</span>
      <small className={cx('icon', error ? 'error' : '')}>
        <i className={icon}></i>
      </small>
      <input
        ref={ref}
        type={inputType}
        {...props}
        className={cx('input', error ? 'error' : '')}
      />
      {props.password === 'password-type' ? (
        <small onClick={typeHandler} className={cx('password-icon')}>
          {showPassword ? (
            <i className="fal fa-eye-slash"></i>
          ) : (
            <i className="fal fa-eye"></i>
          )}
        </small>
      ) : null}
    </div>
  );
});

export default TextInput;
