import {useMutation, useQuery} from '@apollo/client';
import styles from '../assetClassOverview.module.scss';
import {Form, Select, Table} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import {Button} from '@dabafinance/react-components';
import backIcon from 'images/back.png';
import {notificationLanguages} from 'utils/constants';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {GET_ASSET_CLASS_LANGUAGES} from 'graphql/queries/assetManagement';
import {useState} from 'react';
import {toast} from 'react-toastify';
import {TRANSLATE_ASSET_CLASS} from 'graphql/mutations/assetManagement';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';

export const AssetClassLanguageVersions = () => {
  const [languageVersion, setLanguageVersion] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [openConfirmTranslationModal, setOpenConfirmTranslationModal] =
    useState(false);
  const navigate = useNavigate();
  const {id} = useParams();

  const {data, loading, refetch} = useQuery(GET_ASSET_CLASS_LANGUAGES, {
    variables: {
      getAssetClassLanguageVersionsId: id,
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    onError: error => toast.error(error.message),
    onCompleted: data => {
      setLanguageVersion(
        data?.getAssetClassLanguageVersions?.data?.languageVersions.map(
          item => {
            return {
              id: data?.getAssetClassLanguageVersions?.data?.id,
              title: data?.getAssetClassLanguageVersions?.data?.name,
              language: item,
            };
          },
        ),
      );
    },
  });

  const [translateAssetClass, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_ASSET_CLASS,
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>{record?.title}</h4>
        </div>
      ),
    },
    {
      title: 'Language Version',
      dataIndex: 'language',
      key: 'language',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4 style={{width: 100}}>
            {record?.language === 'EN' ? 'English' : 'French'}
          </h4>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <Button
          onClick={() =>
            navigate(
              `/dashboard/asset-management/classes/update/${record?.language}/${record.id}`,
            )
          }
          className={styles.button}
          type="secondary"
          label="Edit Asset Class"
        />
      ),
    },
  ];

  return (
    <div className={styles.languageVersions}>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>
          {data?.getAssetClassLanguageVersions?.data?.name} Language Version
        </h1>
      </div>
      <div>
        <Form
          onFinish={() => setOpenConfirmTranslationModal(true)}
          layout="vertical">
          <Form.Item
            label="Select Language to Translate"
            name="language"
            className={styles.language}
            rules={[
              {
                required: true,
                message: 'Please select a language to translate.',
              },
            ]}>
            <Select
              placeholder="French"
              onChange={setSelectedLanguage}
              style={{width: 320}}
              name="type">
              {notificationLanguages.map(name => (
                <Select.Option key={name.key} value={name.key}>
                  {name.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <SubmitButton label="Proceed" />
        </Form>

        <Table
          loading={loading}
          columns={columns}
          dataSource={languageVersion}
        />
      </div>
      <ConfirmModal
        openModal={openConfirmTranslationModal}
        setOpenModal={() => setOpenConfirmTranslationModal(false)}
        title="Confirm Translation"
        confirmText="Are you sure you want to translate this Asset Class?"
        loading={loadingTranslation}
        handleOperation={() =>
          translateAssetClass({
            variables: {
              translateAssetClassId: id,
              language: selectedLanguage,
            },
          }).then(({data: translateAssetClass}) => {
            if (translateAssetClass.__typename === 'Error') {
              toast.error(translateAssetClass.message);
            } else {
              setOpenConfirmTranslationModal(false);
              refetch();
              toast.success('Asset Class Translated');
            }
          })
        }
      />
    </div>
  );
};
