export const issuerTypes = [
  {name: 'Government', value: 'GOVERNMENT'},
  {name: 'Corporation', value: 'CORPORATION'},
  {name: 'Other', value: 'OTHER'},
];

export const assetClassStatus = [
  {label: 'Pending', value: 'PENDING'},
  {label: 'Enabled', value: 'ENABLED'},
  {label: 'Disabled', value: 'DISABLED'},
];

export const assetClassKeyTypes = [
  {label: 'Equities', value: 'EQUITIES'},
  {label: 'Fixed income', value: 'FIXED_INCOME'},
  {label: 'Cash', value: 'CASH'},
  {label: 'Alternatives', value: 'ALTERNATIVES'},
  {label: 'Real estate', value: 'REAL_ESTATE'},
  {label: 'Commodities', value: 'COMMODITIES'},
  {label: 'Cryptocurrency', value: 'CRYPTOCURRENCY'},
  {label: 'Funds', value: 'FUNDS'},
];

export const assetInstrumentTypes = [
  {label: 'Private equity', value: 'PRIVATE_EQUITY'},
  {label: 'Public equity', value: 'PUBLIC_EQUITY'},
  {label: 'Bond', value: 'BOND'},
  {label: 'Commercial paper', value: 'COMMERCIAL_PAPER'},
  {label: 'Mutual fund', value: 'MUTUAL_FUND'},
  {label: 'ETF', value: 'ETF'},
  {label: 'Index fund', value: 'INDEX_FUND'},
  {label: 'Hedge fund', value: 'HEDGE_FUND'},
  {label: 'Venture capital fund', value: 'VENTURE_CAPITAL_FUND'},
  {label: 'Private equity fund', value: 'PRIVATE_EQUITY_FUND'},
  {label: 'Certificate of deposit', value: 'CERTIFICATE_OF_DEPOSIT'},
  {label: 'Savings account', value: 'SAVINGS_ACCOUNT'},
  {label: 'Money market fund', value: 'MONEY_MARKET_FUND'},
  {label: 'Precious metal', value: 'PRECIOUS_METAL'},
  {label: 'Agricultural', value: 'AGRICULTURAL'},
  {label: 'Livestock', value: 'LIVESTOCK'},
  {label: 'Direct real estate', value: 'DIRECT_REAL_ESTATE'},
  {label: 'REIT', value: 'REIT'},
];

export const bondTypes = [
  {name: 'Corporate', value: 'CORPORATE'},
  {name: 'Government', value: 'GOVERNMENT'},
  {name: 'Municipal', value: 'MUNICIPAL'},
  {name: 'Convertible', value: 'CONVERTIBLE'},
  {name: 'Treasury Bill', value: 'TREASURY_BILL'},
];

export const interestPaymentFrequency = [
  {name: 'Annual', value: 'ANNUAL'},
  {name: 'Semi Annual', value: 'SEMI_ANNUAL'},
  {name: 'Quarterly', value: 'QUARTERLY'},
  {name: 'Monthly', value: 'MONTHLY'},
];

export const ratingsTypes = [
  'AAA',
  'AA',
  'A',
  'BBB',
  'BB',
  'B',
  'CCC',
  'CC',
  'C',
  'D',
];
