import {gql} from '@apollo/client';

export const GET_ALL_TOPICS = gql`
  query GetAllTopics($type: [TopicType], $sequence: Boolean) {
    getAllTopics(type: $type, sequence: $sequence) {
      ... on Error {
        message
        statusCode
      }
      ... on Topic {
        id
        name
        icon
        type
        img
        sequence
        hasSequence
        numOfFollowers
        relatedCompanies {
          id
          name
          logoImgURL
        }
      }
    }
  }
`;

export const GET_ALL_ADMIN_TOPICS = gql`
  query GetAllAdminTopics($filters: GetTopicFilters) {
    getAllAdminTopics(filters: $filters) {
      topics {
        id
        name
        icon
        type
        img
        sequence
        hasSequence
        numOfFollowers
        relatedCompanies {
          id
          name
          logoImgURL
        }
      }
      totalDocs
      totalPages
      hasPrevPage
      hasNextPage
      prevPage
      nextPage
    }
  }
`;

export const GET_TOPIC_BY_ID = gql`
  query GetTopic($topicId: ID!) {
    getTopic(topicId: $topicId) {
      ... on Error {
        message
        statusCode
      }
      ... on Topic {
        id
        name
        icon
        type
        sequence
        bannerImg
        description
        region
        language
        multilang {
          description
          language
          name
        }
        relatedCompanies {
          id
          name
          logoImgURL
        }
      }
    }
  }
`;

export const GET_ALL_TOPIC_LIST = gql`
  query GetAllListTopics {
    getAllListTopics {
      ... on Error {
        message
        statusCode
      }
      ... on TopicList {
        id
        name
        icon
        listType
        topic {
          id
        }
      }
    }
  }
`;

export const GET_A_TOPIC_LIST = gql`
  query GetTopicList($topicListId: ID!) {
    getTopicList(topicListId: $topicListId) {
      ... on Error {
        message
        statusCode
      }
      ... on TopicList {
        id
        name
        icon
        companies {
          sequence
          company {
            id
            legalName
          }
        }
        topic {
          id
          name
          icon
          type
          sequence
          bannerImg
          description
          region
          language
          multilang {
            description
            language
            name
          }
          relatedCompanies {
            id
            name
            logoImgURL
          }
        }
        listType
        opportunities {
          opportunity {
            id
            legalName
          }
          sequence
        }
      }
    }
  }
`;

export const SEARCH_TOPIC = gql`
  query Search($queryString: String!) {
    search(queryString: $queryString) {
      ... on Error {
        message
        statusCode
      }
      ... on Search {
        query
        topics {
          id
          name
          icon
          type
        }
      }
    }
  }
`;

export const TOPIC_FOLLOWERS = gql`
  query GetTopicFollowers($topicId: ID!) {
    getTopicFollowers(topicId: $topicId) {
      user {
        firstName
        id
        lastName
        username
        imageUrl
        createdAt
      }
      topic
    }
  }
`;

export const USERS_INTERESTED_IN_TOPICS = gql`
  query GetAllUsersFollowingTopic($filter: FollowTopicFilter!) {
    getAllUsersFollowingTopic(filter: $filter) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUsers {
        data {
          imageUrl
          firstName
          lastName
          id
          createdAt
          email
        }
        pagination {
          limit
          hasNextPage
          hasPrevPage
        }
      }
    }
  }
`;
