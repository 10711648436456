import styles from './startupCount.module.scss';

const StartupCounts = ({active, value, text}) => {
  return (
    <div className={active ? styles['active-container'] : styles.container}>
      <h1>{value}</h1>
      <span>{text}</span>
    </div>
  );
};

export default StartupCounts;
