import {Form, Input, Select, Spin} from 'antd';
import styles from '../assetInstrument.module.scss';
import {useMutation, useQuery} from '@apollo/client';
import deleteIcon from 'images/delete.svg';
import plusIcon from 'images/plus-circle.png';
import FadeEffect from 'components/animation/fadeEffect';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useDispatch, useSelector} from 'react-redux';
import {handleBondsInstrumentState} from 'redux/store/assetClass';
import {useEffect, useState} from 'react';
import {CreateBondFinishModal} from '../CreateBondFinishModal';
import DBModal from 'components/modal/modal';
import {GET_ALL_TOPIC_COUNTRIES} from 'graphql/queries/investorUpdates';
import {useParams} from 'react-router-dom';
import {UPDATE_BOND} from 'graphql/mutations/assetManagement';
import {GET_FINANCIAL_INSTRUMENT_BY_LANGUAGE} from 'graphql/queries/assetManagement';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {handleUpdateBond} from 'pages/AssetManagement/actions';

const BondsBrokerCompliance = () => {
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [createBondModal, setCreateBondModal] = useState(false);
  const {id, lang} = useParams();

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const bondData = useSelector(state => state?.assetClass?.bondInstruments);
  const [updatedValues, setUpdatedValues] = useState({});

  const [updateBond, {loading: loadingUpdate}] = useMutation(UPDATE_BOND);
  const {data, loading} = useQuery(GET_ALL_TOPIC_COUNTRIES);
  const {refetch, loadingQuery} = useQuery(
    GET_FINANCIAL_INSTRUMENT_BY_LANGUAGE,
    {
      variables: {
        getFinancialInstrumentId: id,
        language: lang,
      },
      skip: !id,
    },
  );

  const handleSubmit = values => {
    if (id) {
      const {proceedDescription, allocations, ...restOfValues} = values;
      setOpenUpdateModal(true);
      setUpdatedValues({
        ...restOfValues,
        useOfProceeds: {
          ...(proceedDescription && {description: proceedDescription}),
          allocations: allocations?.map(allocation => ({
            allocationCategory: allocation.allocationCategory,
            percentageValue: parseInt(allocation.percentageValue),
          })),
        },
      });
    } else {
      dispatch(
        handleBondsInstrumentState({
          ...values,
          useOfProceeds: {
            description: values.proceedDescription,
            allocations: values.allocations?.map(allocation => ({
              allocationCategory: allocation.allocationCategory,
              percentageValue: parseInt(allocation.percentageValue),
            })),
          },
        }),
      );
      setCreateBondModal(true);
    }
  };

  useEffect(() => {
    if (bondData) {
      form.setFieldsValue({
        ...bondData,
        proceedDescription: bondData?.useOfProceeds?.description || null,
      });
    }
  }, [bondData]);

  if (loadingQuery) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.form}>
      <Form onFinish={handleSubmit} form={form} layout="vertical">
        <h2>Primary Broker Information</h2>
        <div className={styles.fieldFlex}>
          <Form.Item
            className={styles.smallWidth}
            name="primaryBrokerName"
            label="Primary Broker Name">
            <Input />
          </Form.Item>
          <Form.Item
            className={styles.smallWidth}
            rules={[
              {
                message: 'Please enter a valid URL',
                type: 'url',
              },
            ]}
            name="primaryBrokerWebsite"
            label="Primary Broker Website">
            <Input />
          </Form.Item>
        </div>
        <Form.Item
          className={styles.field}
          name="primaryBrokerDescription"
          label="Primary Broker Description">
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item
          name="primaryBrokerOperationCountries"
          className={styles.field}
          label="Primary Broker Operation Countries">
          <Select
            mode="multiple"
            loading={loading}
            disabled={loading}
            allowClear>
            {data?.getAllTopicCountries?.map(data => (
              <Select.Option key={data?.id}>
                <div className={styles['all-data']}>
                  <img src={data?.icon} alt="" />
                  <span>{data?.name}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <h2>Legal & Compliance</h2>
        <Form.Item
          className={styles.field}
          name="legalChallenges"
          label="Legal Challenges">
          <Input.TextArea rows={4} />
        </Form.Item>
        <div className={styles.fieldFlex}>
          <Form.Item
            className={styles.smallWidth}
            name="outlookAsOfDate"
            label="Outlook as of Date">
            <Input />
          </Form.Item>
          <Form.Item
            className={styles.smallWidth}
            name="creditWatchAsOfDate"
            label="Credit Watch as of Date">
            <Input />
          </Form.Item>
        </div>
        <h2>Use of Proceeds / Funds Allocations</h2>
        <Form.Item
          style={{width: 720}}
          name="proceedDescription"
          className={styles.field}
          label="Description">
          <Input.TextArea rows={6} name="description" />
        </Form.Item>
        <div>
          <Form.List name="allocations">
            {(fields, {add, remove}) => (
              <>
                {fields?.map(({name}) => (
                  <FadeEffect key={name}>
                    <div className={styles.form}>
                      <div className={styles['al-card']}>
                        <Form.Item
                          rules={[{required: true, message: 'Required'}]}
                          name={[name, 'allocationCategory']}
                          label="Allocation Category">
                          <Input
                            name="allocationCategory"
                            style={{width: 400}}
                            placeholder="Marketing"
                          />
                        </Form.Item>
                        <Form.Item
                          style={{width: 288, marginLeft: 24}}
                          rules={[{required: true, message: 'Required'}]}
                          name={[name, 'percentageValue']}
                          label="Percentage">
                          <Input
                            name="percentageValue"
                            placeholder="% in value"
                            type="number"
                          />
                        </Form.Item>
                        <div className={styles['delete-icon']}>
                          <img
                            onClick={() => {
                              remove(name);
                            }}
                            src={deleteIcon}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </FadeEffect>
                ))}
                <div onClick={add} className={styles['add-button']}>
                  <img src={plusIcon} alt="" />
                  <h1>Add Category</h1>
                </div>
              </>
            )}
          </Form.List>
        </div>
        <SubmitButton label="Proceed" />
      </Form>
      <DBModal
        isOpen={createBondModal}
        handleClose={() => setCreateBondModal(false)}
        content={
          <CreateBondFinishModal
            handleClose={() => setCreateBondModal(false)}
          />
        }
      />
      <ConfirmModal
        openModal={openUpdateModal}
        setOpenModal={() => setOpenUpdateModal(false)}
        loading={loadingUpdate}
        title="Update Bond"
        confirmText="Are you sure you want to update this bond?"
        handleOperation={() =>
          handleUpdateBond(
            updateBond,
            id,
            lang,
            updatedValues,
            refetch,
            setOpenUpdateModal,
            true,
          )
        }
      />
    </div>
  );
};

export default BondsBrokerCompliance;
