import {Dropdown, Table, Tag} from 'antd';
import styles from '../company/management/savingsUserManagement.module.scss';
import {useNavigate, useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {GET_ALL_SAVINGS_COMPANY_PRODUCT} from 'graphql/queries/savings';
import {toast} from 'react-toastify';
import moment from 'moment';
import dotsIcon from 'images/dots.svg';

const SavingsCompanyProducts = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const {data, loading} = useQuery(GET_ALL_SAVINGS_COMPANY_PRODUCT, {
    variables: {
      input: {
        orgId: id,
        page: 1,
        limit: 100,
      },
    },
    fetchPolicy: 'all',
    onError: error => toast.error(error),
  });

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'NAME',
    },
    {
      dataIndex: 'createdAt',
      key: 'createdAt',
      title: 'TIME CREATED',
      render: (text, record) => (
        <span>{moment(record?.createdAt).format('DD MMM, YYYY hh:mm A')}</span>
      ),
    },
    {
      dataIndex: 'type',
      key: 'type',
      title: 'TYPE',
      render: (text, record) => (
        <Tag color={record?.savingsType === 'LOCKED' ? 'magenta' : 'gold'}>
          {record?.savingsType}
        </Tag>
      ),
    },
    {
      dataIndex: 'currency',
      key: 'currency',
      title: 'currency',
      render: (text, record) => <Tag>{record?.config?.allowedCurrency}</Tag>,
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      render: (text, record) => (
        <Tag
          color={
            {
              'ACTIVE': 'green',
              'INACTIVE': 'red',
            }[record?.status] || 'blue'
          }>
          {record?.status}
        </Tag>
      ),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: 'Actions',
      render: (text, render) => (
        <Dropdown
          trigger="click"
          menu={{
            items: [
              {
                key: 1,
                label: 'View Info',
                onClick: () =>
                  navigate(`/dashboard/savings/product/${render?.id}`),
              },
              {
                label: 'Update Product',
                key: 2,
                onClick: () =>
                  navigate(`/dashboard/savings/product/update/${render?.id}`),
              },
            ],
          }}>
          <img src={dotsIcon} />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.root}>
      <div></div>
      <div>
        <Table
          columns={columns}
          dataSource={data?.getSavingsProducts?.data}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default SavingsCompanyProducts;
