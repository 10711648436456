import {gql} from '@apollo/client';

export const GENERATE_ACCESS_CODE = gql`
  mutation GenerateAccessCode($codeAmount: Int!) {
    generateAccessCode(codeAmount: $codeAmount) {
      ... on Error {
        message
        statusCode
      }
      ... on AccessCodeArrayType {
        accessCode {
          id
          accessCode
          usedDate
          expiredDate
          isActive
          createdBy {
            id
            email
            firstName
          }
          createdAt
        }
      }
    }
  }
`;

export const DEACTIVATE_ACCESS_CODE = gql`
  mutation DeactivateCode($accessCodeId: ID!) {
    deactivateCode(accessCodeId: $accessCodeId) {
      ... on Error {
        message
        statusCode
      }
      ... on AccessCodeType {
        accessCode {
          id
          accessCode
          usedDate
          expiredDate
          isActive
          createdBy {
            id
            email
            firstName
          }
          createdAt
        }
      }
    }
  }
`;
