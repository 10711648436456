import {gql} from '@apollo/client';

export const CREATE_TRENDING_INDUSTRIES = gql`
  mutation CreateTrendingIndustries($input: CreateTrendingIndustries!) {
    createTrendingIndustries(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on TrendingIndustriesType {
        id
        trendingIndustries {
          id
          industry {
            id
            name
          }
          companies {
            id
            name
          }
          sequence
          totalAmountReceived
          totalNumOfDeals
        }
        isTrending
        month
        year
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_TRENDING_INDUSTRIES = gql`
  mutation UpdateTrendingIndustries($input: UpdateTrendingIndustries!) {
    updateTrendingIndustries(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on TrendingIndustriesType {
        id
        trendingIndustries {
          industry {
            id
            name
          }
          companies {
            id
            name
          }
          sequence
          totalAmountReceived
          totalNumOfDeals
        }
        month
        year
        isTrending
        createdAt
        updatedAt
      }
    }
  }
`;

export const ADD_COMPANY_TO_TRENDING_INDUSTRIES = gql`
  mutation AddCompanyToTrendingIndustry(
    $trendingIndustryId: ID!
    $industryId: ID!
    $companies: [ID!]!
  ) {
    addCompanyToTrendingIndustry(
      trendingIndustryId: $trendingIndustryId
      industryId: $industryId
      companies: $companies
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on TrendingIndustriesType {
        id
        trendingIndustries {
          id
          industry {
            id
            name
          }
          companies {
            id
            name
          }
          sequence
          totalAmountReceived
          totalNumOfDeals
        }
        month
        year
        isTrending
        createdAt
        updatedAt
      }
    }
  }
`;

export const DELETE_TRENDING_INDUSTRY = gql`
  mutation DeleteTrendingIndustry(
    $trendingIndustriesId: ID!
    $trendingIndustryId: ID!
  ) {
    deleteTrendingIndustry(
      trendingIndustriesId: $trendingIndustriesId
      trendingIndustryId: $trendingIndustryId
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on TrendingIndustriesType {
        id
        trendingIndustries {
          id
          industry {
            id
            name
          }
          companies {
            id
            name
          }
          sequence
          totalAmountReceived
          totalNumOfDeals
        }
        isTrending
        month
        year
        createdAt
        updatedAt
      }
    }
  }
`;
