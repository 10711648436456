import {useQuery} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {Spin} from 'antd';
import {
  GET_BREAKING_NEWS,
  GET_lATEST_NEWS,
  GET_TRENDING_NEWS,
} from 'graphql/queries/news';
import {useNavigate} from 'react-router-dom';
import NewsCard from '../news/newsCard/NewsCard';
import styles from './selectedNews.module.scss';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const SelectedNews = () => {
  const navigate = useNavigate();

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.News),
  );

  const {data: getBreakingNews, loading} = useQuery(GET_BREAKING_NEWS);
  const {data: getTrendingNews} = useQuery(GET_TRENDING_NEWS);
  const {data: getLatestNews} = useQuery(GET_lATEST_NEWS, {
    variables: {
      limit: 5,
    },
  });

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Selected News </h1>
        <div className={styles['search-grid']}>
          <Button
            onClick={() => navigate('/dashboard/explore/news/add-news')}
            label="Add News Manually"
            disabled={!tabOperations?.create}
            className={styles.button}
          />
        </div>
      </div>
      <div className={styles.category}>
        <h1>Breaking News</h1>
        {getBreakingNews?.getNewsByUser?.news?.breakingNews.length > 0 ? (
          getBreakingNews?.getNewsByUser?.news?.breakingNews?.map(
            ({
              title,
              featuredImage,
              publishedDate,
              keywords,
              topics,
              link,
              companies,
              id,
              isBreakingNews,
              newsSource,
            }) => (
              // eslint-disable-next-line react/jsx-key
              <NewsCard
                title={title}
                newsSource={newsSource}
                image={featuredImage}
                link={link}
                date={publishedDate}
                operations={tabOperations}
                id={id}
                companies={companies}
                breakingNews={isBreakingNews}
                keywords={keywords}
                topics={topics}
              />
            ),
          )
        ) : (
          <div className={styles['no-news']}>
            <h1>No Breaking News</h1>
          </div>
        )}
      </div>
      <div>
        <h1>Trending News</h1>
        {getTrendingNews?.getNewsByUser?.news?.trendingNews?.length > 0 ? (
          getTrendingNews?.getNewsByUser?.news?.trendingNews?.map(
            ({
              title,
              featuredImage,
              publishedDate,
              keywords,
              topics,
              link,
              id,
              isTrendingNews,
              newsSource,
              companies,
            }) => (
              // eslint-disable-next-line react/jsx-key
              <NewsCard
                title={title}
                newsSource={newsSource}
                image={featuredImage}
                link={link}
                date={publishedDate}
                trendingNews={isTrendingNews}
                id={id}
                companies={companies}
                keywords={keywords}
                topics={topics}
              />
            ),
          )
        ) : (
          <div className={styles['no-news']}>
            <h1>No Trending News</h1>
          </div>
        )}
      </div>
      <div>
        <h1>Latest News</h1>
        {getLatestNews?.getNewsByUser?.news?.latestNews?.length > 0 ? (
          getLatestNews?.getNewsByUser?.news?.latestNews?.map(
            ({
              title,
              featuredImage,
              publishedDate,
              keywords,
              topics,
              link,
              id,
              isSelectedNews,
              newsSource,
              companies,
            }) => (
              // eslint-disable-next-line react/jsx-key
              <NewsCard
                title={title}
                newsSource={newsSource}
                image={featuredImage}
                link={link}
                date={publishedDate}
                selectedNews={isSelectedNews}
                id={id}
                companies={companies}
                keywords={keywords}
                topics={topics}
              />
            ),
          )
        ) : (
          <div className={styles['no-news']}>
            <h1>No Latest News</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectedNews;
