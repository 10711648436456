import {gql} from '@apollo/client';

export const GET_CAMPAIGN_LANGUAGE_VERSIONS = gql`
  query GetCampaignLanguageVersions($getCampaignLanguageVersionsId: ID!) {
    getCampaignLanguageVersions(id: $getCampaignLanguageVersionsId) {
      ... on Error {
        message
        statusCode
      }
      ... on CampaignLanguageVersionsResponse {
        campaignLanguageVersions {
          campaignId
          companyId
          companyName
          languageVersions
        }
      }
    }
  }
`;

export const GET_ALL_FUNDRAISING_COMPANIES = gql`
  query GetAllFundraisingCompanies {
    getAllFundraisingCompanies {
      ... on Error {
        message
        statusCode
      }
      ... on FundraisingCompanies {
        fundraisingCompanies {
          id
          companyId
          legalName
          companyName
          socials {
            facebook
            twitter
            linkedin
          }
          operatingCountries
          totalCapitalRaised
          bannerImgURL
          fundraisingHistory
          logoImgURL
          accelerator
          stage
          companyType
          foundedOn
          headquarter
          numberOfEmployees
          ticker
          website
          shortDescription
          description
          primaryContactInfo {
            firstName
            lastName
            email
            phoneNumber
          }
        }
      }
    }
  }
`;

export const GET_FUNDRAISING_COMPANY_BY_ID = gql`
  query GetFundraisingCompanyById($companyId: ID!) {
    getFundraisingCompanyById(companyId: $companyId) {
      ... on Error {
        message
        statusCode
      }
      ... on FundraisingCompany {
        id
        companyId
        legalName
        companyName
      }
    }
  }
`;

export const GET_ALL_USERS_BY_NAME = gql`
  query GetAllUsers($filters: GetUserFilters) {
    getAllUsers(filters: $filters) {
      hasNextPage
      users {
        id
        firstName
        middleName
        lastName
        email
        imageUrl
      }
    }
  }
`;

export const GET_CAMPAIGN_INTERESTS_BY_ID = gql`
  query GetCampaignInterestById($campaign: String!) {
    getCampaignInterestById(campaign: $campaign) {
      ... on Error {
        message
        statusCode
      }
      ... on AllReservations {
        reservations {
          campaign
          id
          user {
            id
            firstName
            lastName
            email
            username
            countryCode
            phoneNumber
            imageUrl
          }
          interestAmountRange {
            minInvestmentInterestAmount
            maxInvestmentInterestAmount
            amount
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const CONVERT_CURRENCY = gql`
  query ConvertCurrencies($input: ConversionInput!) {
    convertCurrencies(input: $input) {
      ... on ConversionResult {
        appliedFxRate
        investedAmountInAssetBaseCurrency
      }
    }
  }
`;

export const GET_ALL_FILTERED_CAMPAIGNS = gql`
  query GetAllCampaigns($listingStatus: campaignStatus, $isVisible: Boolean) {
    getAllCampaigns(listingStatus: $listingStatus, isVisible: $isVisible) {
      ... on Error {
        message
        statusCode
      }
      ... on Campaigns {
        campaigns {
          id
          fundraisingCompany {
            id
            companyId
            logoImgURL
            companyName
            bannerImgURL
          }
          ipoFields {
            offeringName
          }
          campaignStatus
          aboutCompany
          offeringId
          ipoFields {
            offeringName
          }
          ventureFundFields {
            offeringName
          }
          stage
          settings {
            isVisible
          }
          listingType
          campaignMode
          offeringType
          investmentTerms {
            fundingTarget
          }
          campaignMode
        }
      }
    }
    getTestCampaignStats {
      totalInterest
      interestedInvestors
      activeTestCampaigns
      totalTestCampaigns
      totalCampaigns
      totalTestCompanies
    }
    getLiveCampaignStats {
      activeLiveCampaigns
      totalAmountInvested
      totalCampaigns
      totalCompanies
      totalLiveCampaigns
      totalNumberOfInvestors
    }
    getTotalNumberOfCompanies
  }
`;

export const GET_MINIMAL_CAMPAIGNS = gql`
  query GetAllCampaigns {
    getAllCampaigns {
      ... on Error {
        message
        statusCode
      }
      ... on Campaigns {
        campaigns {
          id
          fundraisingCompany {
            id
            companyId
            logoImgURL
            companyName
            bannerImgURL
          }
          assetCurrency {
            symbol
            name
            currencyCode
          }

          campaignStatus
          offeringId
          stage
          listingType
          campaignMode
          offeringType

          campaignMode
        }
      }
    }
  }
`;

export const GET_ALL_CAMPAIGNS = gql`
  query GetAllCampaigns {
    getAllCampaigns {
      ... on Error {
        message
        statusCode
      }
      ... on Campaigns {
        campaigns {
          id
          fundraisingCompany {
            id
            companyId
            logoImgURL
            companyName
            bannerImgURL
          }
          assetCurrency {
            symbol
            name
            currencyCode
          }
          fxTransactionRatesOnSupportedCurrencies {
            currency {
              symbol
              name
              currencyCode
            }
            currencyConversionMode
            fixedFxRateToAssetCurrency
            variableFxRateToAssetCurrency
          }
          campaignStatus
          aboutCompany
          offeringId
          stage
          listingType
          campaignMode
          offeringType
          investmentTerms {
            fundingTarget
          }
          campaignMode
        }
      }
    }
    getDabaManualCryptoDetails {
      ... on Error {
        statusCode
        message
      }
      ... on CryptoAccountList {
        accounts {
          id
          walletAddress
          qrCode
          label
          symbol
          network
        }
      }
    }
    getTestCampaignStats {
      totalInterest
      interestedInvestors
      activeTestCampaigns
      totalTestCampaigns
      totalCampaigns
      totalTestCompanies
    }
    getLiveCampaignStats {
      activeLiveCampaigns
      totalAmountInvested
      totalCampaigns
      totalCompanies
      totalLiveCampaigns
      totalNumberOfInvestors
    }
    getTotalNumberOfCompanies
  }
`;

export const GET_ALL_CAMPAIGNS_IDS = gql`
  query GetAllCampaigns {
    getAllCampaigns {
      ... on Campaigns {
        campaigns {
          id
          campaignMode
          listingType
          campaignStatus
          fundraisingCompany {
            logoImgURL
            companyName
          }
        }
      }
    }
  }
`;

export const GET_TRANSACTIONS_QUERIES = gql`
  query GetTransactionsQueries(
    $getCampaignByIdId: ID!
    $companyId: ID!
    $campaign: ID
    $offeringId: ID
  ) {
    getCampaignById(id: $getCampaignByIdId) {
      ... on Campaign {
        externallyFundedAmountBreakDown {
          amount
          name
        }
        id
      }
    }
    getFundraisingCompanyById(companyId: $companyId) {
      ... on Error {
        message
        statusCode
      }
      ... on FundraisingCompany {
        id
        companyId
        legalName
        companyName
      }
    }
    getAllTransactionByCampaign(campaign: $campaign, offeringId: $offeringId) {
      ... on Error {
        message
        statusCode
      }
      ... on AllTransactionByCampaign {
        transactions {
          id
          paymentMethod
          wireReceiptImage
          blockchainTransactionLink
          transactionUnits
          user {
            id
            firstName
            lastName
            username
            email
            countryCode
            city
            country
            state
            phoneNumber
            imageUrl
            investorProfile {
              isAccredited
            }
          }
          transactionAmount
          isDocumentSent
          offering
          transactionStatus
          createdAt
          investedAmount
          investedAmountInTransactionCurrency
          assetCurrency
          transactionCurrencyToAssetCurrencyFx
          transactionCurrency
          transactionFees
          isDocumentSigned
          fundStatus
          tradeId
        }
      }
    }
  }
`;

export const GET_ALL_TRANSACTIONS_BY_OFFERING_ID = gql`
  query GetAllTransactionByOffering($offering: ID!) {
    getAllTransactionByOffering(offering: $offering) {
      ... on Error {
        message
        statusCode
      }
      ... on AllTransactionByOffering {
        transactions {
          id
          paymentMethod
          wireReceiptImage
          blockchainTransactionLink
          transactionUnits
          user {
            id
            firstName
            lastName
            username
            email
            countryCode
            city
            country
            state
            phoneNumber
            imageUrl
            investorProfile {
              isAccredited
            }
          }
          transactionAmount
          isDocumentSent
          offering
          transactionStatus
          createdAt
          investedAmount
          investedAmountInTransactionCurrency
          assetCurrency
          transactionCurrencyToAssetCurrencyFx
          transactionCurrency
          transactionFees
          isDocumentSigned
          fundStatus
          tradeId
        }
      }
    }
  }
`;

export const GET_ALL_TRANSACTIONS_BY_OFFERING = gql`
  query GetAllTransactionByOffering($offering: ID!) {
    getAllTransactionByOffering(offering: $offering) {
      ... on Error {
        message
        statusCode
      }
      ... on AllTransactionByOffering {
        transactions {
          id
          paymentMethod
          wireReceiptImage
          blockchainTransactionLink
          transactionUnits
          user {
            id
            firstName
            lastName
            username
            email
            countryCode
            city
            country
            state
            phoneNumber
            imageUrl
            investorProfile {
              isAccredited
            }
          }
          transactionAmount
          isDocumentSent
          offering
          transactionStatus
          createdAt
          investedAmount
          investedAmountInTransactionCurrency
          assetCurrency
          transactionCurrencyToAssetCurrencyFx
          transactionCurrency
          transactionFees
          isDocumentSigned
          fundStatus
          tradeId
        }
      }
    }
  }
`;

export const GET_CAMPAIGN_TRANSACTIONS = gql`
  query GetAllTransactionByCampaign($campaign: ID, $offeringId: ID) {
    getAllTransactionByCampaign(campaign: $campaign, offeringId: $offeringId) {
      ... on Error {
        message
        statusCode
      }
      ... on AllTransactionByCampaign {
        transactions {
          id
          paymentMethod
          wireReceiptImage
          blockchainTransactionLink
          transactionUnits
          user {
            id
            firstName
            middleName
            username
            email
            countryCode
            phoneNumber
            imageUrl
          }
          transactionAmount
          isDocumentSent
          offering
          transactionStatus
          createdAt
          investedAmount
          transactionFees
          isDocumentSigned
          fundStatus
        }
      }
    }
  }
`;

export const GET_CAMPAIGN_USERS_BY_ID = gql`
  query GetCampaignById($getCampaignByIdId: ID!, $language: LanguageEnum) {
    getCampaignById(id: $getCampaignByIdId, language: $language) {
      ... on Error {
        errorTypeEnum
        message
      }
      ... on Campaign {
        fundraisingCompany {
          id
        }
        invitedUsers {
          imageUrl
          id
          username
          email
          firstName
          lastName
          phoneNumber
          countryCode
          investorProfile {
            isAccredited
          }
          profileCompletion {
            completionPercentage
          }
        }
      }
    }
  }
`;

export const GET_CAMPAIGN_BY_ID = gql`
  query GetCampaignById($getCampaignByIdId: ID!, $language: LanguageEnum) {
    getCampaignById(id: $getCampaignByIdId, language: $language) {
      ... on Error {
        message
        statusCode
      }
      ... on Campaign {
        id
        aboutCompany
        relatedTopics {
          id
          name
          icon
        }
        keyFacts {
          name
          value
        }
        vendor
        analog {
          name
          valuation
          description
          companyType
          logo
        }
        keyStatistics {
          name
          value
        }
        externallyFundedAmountBreakDown {
          amount
          name
        }
        missionAndVission
        foundedOn
        recommendedPortfolioStrategy
        opportunityVoiceRecording
        opportunityDemoVideo
        opportunityDemoVideoThumbnail
        level2AnalystVideoThumbnail
        level1AnalystVideoThumbnail
        bestFitFor
        listingType
        templateId
        settings {
          isPaused
          isVisible
          publicAudience
          shouldNotifyUsersOnLaunch
        }
        groupId
        level1AnalystVideo
        ipoFields {
          dabaNumberofSharesAllocated
          offeringName
          expectedIpo
          ipoSharePrice
          totalNumberofSharesOutstandingforIPO
          offeringName
        }
        ventureFundFields {
          thesisAndInvestmentPhilosophy
          investmentStrategy
          offeringName
          fundObjectives {
            fundName
            targetFundSize
            fundsClosedToDate
            currentIRR
            targetIRR
            targetNumberOfInvestments
            targetAverageCheckSize
            currentMultipleOnInvestedCapital
            targetMultipleOnInvestedCapital
          }
          score {
            fundOverview
            fundManager
            portfolioConstruction
            fundEconomicsAndFinancials
            legalAndCompliance
            returnOutlook
            eSGAndSocialImpact
          }
          comparableVentureFund {
            description
            fundSize
            name
            companiesInvestedIn {
              companyId
              imageUrl
            }
          }
          portfolioCompanyType {
            stageInvested
            imageUrl
            title
            description
            name
            multipleOnInvestedCapital
            companyId
          }
        }
        level2AnalystVideo
        solutions
        stamp
        whatWeOffer
        keyMilestones {
          name
          value
        }
        pitchDeck {
          fileLink
          coverImage
          linkType
          externalViewLink
          externalShareLink
        }

        competitors {
          companyName
          companyLogo
          companyId
        }
        fundsAllocation {
          description
          allocations {
            allocationCategory
            percentageValue
          }
        }
        coInvestors {
          name
          imageUrl
          title
          otherInvestments {
            companyId
            imageUrl
          }
          description
        }
        investmentTerms {
          minimumFundingTarget
          maximumFundingTarget
          minimumInvestmentAmount
          fundingTarget
          companyValuation
          feeSchedule {
            feeName
            feeType
            fixedFeeAmount
            variableFeeAmount
          }
        }
        securityType
        pricePerSecurity
        closeCampaignOnTargetMet
        legalDocuments {
          name
          fileLink
        }
        founders {
          fullName
          imageUrl
          jobTitle
          twitter
          linkedin
        }
        teamMembers {
          fullName
          imageUrl
          jobTitle
          twitter
          linkedin
        }
        faq {
          question
          answer
        }
        stage
        socials {
          twitter
          facebook
          linkedin
        }
        location
        industry
        website
        riskAndEsg {
          title
          answer
        }
        impactAndEsg {
          title
          answer
        }
        invitedUsers {
          id
          firstName
          lastName
          imageUrl
        }
        invitedGroups {
          id
          name
          bannerImg
        }
        offeringType
        startDate
        endDate
        campaignMode
        dabaScore {
          score
          pdfReport
          business
          industry
          market
          team
          strategyProduct
          accelerator
          riskChallenges
          analogus
          economicsAndFinance
          legalCompliance
          returnOutlook
          socialImpact
        }
        fundraisingCompany {
          id
        }
        disclosure
        additionalDisclosures {
          name
          value
        }
        investmentContractSettings {
          carryPercentageTerms
          investmentContractType
          investmentTargetEntityLegalName
          managementFeeTerms
          minimumInvestmentPerMember
          spvManagerRepresentativeName
          spvNamePrefix
        }
        assetCurrency {
          currencyCode
          name
          symbol
        }
        fxTransactionRatesOnSupportedCurrencies {
          currency {
            currencyCode
            name
            symbol
          }
          currencyConversionMode
          fixedFxRateToAssetCurrency
          variableFxRateToAssetCurrency
        }
      }
    }
  }
`;

export const TEST_CAMPAIGN_STATS = gql`
  query GetTestCampaignStats {
    getTestCampaignStats {
      totalInterest
      interestedInvestors
      activeTestCampaigns
      totalTestCampaigns
      totalCampaigns
      totalTestCompanies
    }
  }
`;

export const GET_CAMPAIGN_REPORTS_BY_ID = gql`
  query GetCampaignReportById($getCampaignReportByIdId: ID!) {
    getCampaignReportById(id: $getCampaignReportByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCampaignReport {
        data {
          campaignId
          email
          frequency
          id
          interval
          language
          managerName
        }
      }
    }
  }
`;

export const GET_ALL_CAMPAIGN_REPORTS = gql`
  query GetCampaignReports($filter: campaignFilterInput) {
    getCampaignReports(filter: $filter) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCampaignReports {
        data {
          campaignId
          id
          interval
          frequency
          language
          config {
            sendFollowersList
          }
          campaign {
            fundraisingCompany {
              companyName
            }
          }
          status
          nextSendOutDate
          managers {
            email
            name
          }
        }
      }
    }
  }
`;
