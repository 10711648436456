import styles from './industryDetails.module.scss';
import classnames from 'classnames/bind';
import {
  Link,
  useMatch,
  useParams,
  useResolvedPath,
  useNavigate,
  Outlet,
} from 'react-router-dom';
import {ArrowLeft} from 'icons';

const IndustryDetails = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button onClick={() => navigate('/dashboard/report')}>
          <ArrowLeft />
        </button>
        <h1>
          Industry / <span> Edit Industry</span>
        </h1>
      </div>
      <section className={styles.section}>
        <nav className={styles['investor-toggler']}>
          <CustomLink to={`/dashboard/report/industry/${id}`} name="Report" />
          <CustomLink
            to={`/dashboard/report/industry/${id}/analysts-memos`}
            name="Analysts Memos"
          />
        </nav>
      </section>
      <section>
        <Outlet />
      </section>
    </div>
  );
};

const CustomLink = ({to, name}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({path: resolved.pathname, end: true});
  const cx = classnames.bind(styles);

  return (
    <Link className={cx('nav-link', match ? 'active-toggle' : '')} to={to}>
      <span>{name}</span>
    </Link>
  );
};

export default IndustryDetails;
