import moment from 'moment';
import {toast} from 'react-toastify';

export const handleDefaultPayout = (
  values,
  getInvestedUsersByDateRange,
  dispatch,
  handleUpdateDividendId,
  onClose,
  form,
) => {
  const {
    assetCurrency,
    startDate,
    endDate,
    amountPerShare,
    company,
    investmentCategory,
  } = values;
  getInvestedUsersByDateRange({
    variables: {
      input: {
        assetCurrency,
        investmentCategory,
        companyId: company.split(';')[1],
        ...(startDate && {startDate: moment(startDate).format()}),
        ...(endDate && {endDate: moment(endDate).format()}),
        amountPerShare: Number(amountPerShare),
      },
    },
    onError: error => toast.error(`Error something wrong -${error.message}`),
  })
    .then(({data: {getInvestedUsersByDateRange}}) => {
      if (getInvestedUsersByDateRange.__typename === 'Error') {
        toast.error(getInvestedUsersByDateRange.message);
      } else {
        dispatch(handleUpdateDividendId({dividendId: company.split(';')[1]}));
        toast.success(getInvestedUsersByDateRange?.message);
        form.resetFields();
        onClose();
      }
    })
    .catch(error => toast.error(error));
};

export const handleCSVPayoutUpload = (
  values,
  csvUrl,
  setCSVUrl,
  bulkUploadDividendPayout,
  dispatch,
  handleUpdateDividendId,
  onClose,
  form,
) => {
  const {assetCurrency, startDate, endDate, company, investmentCategory} =
    values;
  if (!csvUrl) return toast.error('CSV File Required');
  return bulkUploadDividendPayout({
    variables: {
      input: {
        assetCurrency,
        investmentCategory,
        url: csvUrl,
        companyId: company.split(';')[1],
        ...(startDate && {
          startDate: moment(startDate).format(),
        }),
        ...(endDate && {endDate: moment(endDate).format()}),
      },
    },
  })
    .then(({data: {bulkUploadDividendPayout}}) => {
      if (bulkUploadDividendPayout.__typename === 'Error') {
        toast.error(bulkUploadDividendPayout.message);
      } else {
        dispatch(handleUpdateDividendId({dividendId: company.split(';')[1]}));
        toast.success(bulkUploadDividendPayout?.message);
        onClose();
        setCSVUrl(null);
        form.resetFields();
      }
    })
    .catch(error => toast.error(error));
};

export const handleSingleUser = (
  values,
  addDividendPayout,
  dispatch,
  onClose,
  handleUpdateDividendId,
  user,
  form,
) => {
  const {
    assetCurrency,
    startDate,
    endDate,
    company,
    investmentCategory,
    amountPerShare,
    units,
  } = values;
  if (!user) return toast.error('Please select a user');
  return addDividendPayout({
    variables: {
      input: {
        assetCurrency,
        investmentCategory,
        units: Number(units),
        userId: user.split(';')[2],
        amountPerShare: Number(amountPerShare),
        companyId: company.split(';')[1],
        ...(startDate && {
          startDate: moment(startDate).format(),
        }),
        ...(endDate && {endDate: moment(endDate).format()}),
      },
    },
  })
    .then(({data: {addDividendPayout}}) => {
      if (addDividendPayout.__typename === 'Error') {
        toast.error(addDividendPayout.message);
      } else {
        dispatch(handleUpdateDividendId({dividendId: company.split(';')[1]}));
        toast.success(addDividendPayout?.message);
        onClose();
        form.resetFields();
      }
    })
    .catch(error => toast.error(error));
};

export const handleProcessCSVPayout = (
  values,
  generateAndExportInvestedUsers,
  onClose,
  form,
) => {
  const {
    assetCurrency,
    startDate,
    endDate,
    amountPerShare,
    company,
    investmentCategory,
  } = values;
  console.log(amountPerShare);
  generateAndExportInvestedUsers({
    variables: {
      filter: {
        assetCurrency,
        investmentCategory,
        companyId: company.split(';')[1],
        ...(startDate && {startDate: moment(startDate).format()}),
        ...(endDate && {endDate: moment(endDate).format()}),
        // amountPerShare: Number(amountPerShare),
      },
    },
    onError: error => toast.error(`Error something wrong -${error.message}`),
  })
    .then(({data: {generateAndExportInvestedUsers}}) => {
      if (generateAndExportInvestedUsers.__typename === 'Error') {
        toast.error(generateAndExportInvestedUsers.message);
      } else {
        toast.success(generateAndExportInvestedUsers?.message);
        form.resetFields();
        onClose();
      }
    })
    .catch(error => toast.error(error));
};
