import {Route, Routes} from 'react-router-dom';
import IndustryDetails from './industryDetails';

// Tab Routes
import Report from './tabs/report';
import AnalystsMemo from './tabs/analystsMemo';

const IndustryReportRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<IndustryDetails />}>
        <Route path="/" element={<Report />} />
        <Route path="/analysts-memos" element={<AnalystsMemo />} />
      </Route>
    </Routes>
  );
};

export default IndustryReportRoutes;
