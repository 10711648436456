import {Table} from 'antd';
import moment from 'moment';

const FundingHistoryTable = ({fundingHistory}) => {
  const columns = [
    {
      title: 'Round',
      dataIndex: 'round',
      key: 'round',
    },
    {
      title: 'Funding Type',
      dataIndex: 'fundingType',
      key: 'fundingType',
    },

    {
      title: 'Amount Raised',
      dataIndex: 'fundingAmount',
      key: 'fundingAmount',
      render: (item, render) => (
        <span>${render?.fundingAmount.toLocaleString()}</span>
      ),
    },
    {
      title: 'Round Valuation',
      dataIndex: 'companyValuation',
      key: 'companyValuation',
      render: (item, render) => (
        <span>${render?.companyValuation.toLocaleString()}</span>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'fundingDate',
      key: 'fundingDate',
      render: (item, render) => (
        <span>{moment(render?.fundingDate).format('YYYY/MM/DD')}</span>
      ),
    },
  ];
  return (
    <div>
      <Table columns={columns} dataSource={fundingHistory} />
    </div>
  );
};

export default FundingHistoryTable;
