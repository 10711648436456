import {AssetClassForm} from 'pages/AssetManagement/components/AssetClassForm';

export const CreateAssetClass = () => {
  return (
    <div>
      <h1>Create Asset Class</h1>
      <AssetClassForm />
    </div>
  );
};
