import {useMutation, useQuery} from '@apollo/client';
import {DatePicker, Form, Select, Spin, Switch} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import moment from 'moment';
import {offeringTypeData} from 'pages/campaigns/campaignData';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import styles from './campaignSettings.module.scss';
// import {paymentMethodOptions} from 'utils/constants';

const UpdateCampaignSettings = () => {
  const [offeringType, setOfferingType] = useState('');
  const [finishDate, setFinishDate] = useState('');
  // const [paymentMethods, setPaymentMethods] = useState([]);
  const [isListingVisible, setIsListingVisible] = useState(false);
  const [pauseListing, setPauseListing] = useState(false);
  const [publicAudience, setPublicAudience] = useState([]);

  const [notifyUsersOnLaunch, setNotifyUsersOnLaunch] = useState(false);

  const {Option} = Select;
  const {id, language} = useParams();

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });
  const [updateCampaign, {loading: campaignLoading}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (campaignData?.getCampaignById) {
      setOfferingType(campaignData?.getCampaignById?.offeringType);
      setOfferingType(campaignData?.getCampaignById?.offeringType);
      setPublicAudience(
        campaignData?.getCampaignById?.settings?.publicAudience,
      );
      setFinishDate(campaignData?.getCampaignById?.endDate);
      setIsListingVisible(
        campaignData?.getCampaignById?.settings?.isVisible ?? false,
      );
      // setPaymentMethods(campaignData?.getCampaignById?.allowedPaymentMethods);
      setPauseListing(
        campaignData?.getCampaignById?.settings?.isPaused ?? false,
      );
      setNotifyUsersOnLaunch(
        campaignData?.getCampaignById?.settings?.shouldNotifyUsersOnLaunch,
      );
    }
  }, [campaignData?.getCampaignById]);

  const handleUpdate = () => {
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          offeringType,
          // allowedPaymentMethods: paymentMethods,
          settings: {
            isPaused: pauseListing,
            isVisible: isListingVisible,
            publicAudience: publicAudience,
            shouldNotifyUsersOnLaunch: notifyUsersOnLaunch,
          },
          endDate: new Date(finishDate).toISOString(),
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error(updateCampaign.message);
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Settings</h1>
      </div>
      <div>
        <Form layout="vertical">
          <Form.Item
            initialValue={
              campaignData?.getCampaignById?.settings?.publicAudience
            }
            name="publicAudience"
            label="Public Audience">
            <Select
              value={publicAudience}
              onChange={e => setPublicAudience(e)}
              style={{width: 796}}
              mode="tags"
              allowClear
              placeholder="Select a Public Audience"
              defaultValue={
                campaignData?.getCampaignById?.settings?.publicAudience
              }>
              <Option key="INVITED_USERS">Invited Users</Option>
              <Option key="MEMBERS_INVITED_GROUPS">
                Members of Invited Groups
              </Option>
            </Select>
          </Form.Item>
          <div className={styles.date}>
            <Form.Item name="open" label="Offering Type">
              <Select
                value={offeringType}
                onChange={e => setOfferingType(e)}
                style={{width: 370}}
                placeholder="Select a Offering Type"
                defaultValue={campaignData?.getCampaignById?.offeringType}>
                {offeringTypeData?.map(({label, value}) => (
                  <Option key={value}>
                    <span>{label}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Finish Date"
              name="finish"
              className={styles['form-item']}>
              <DatePicker
                placeholder="Finish Date"
                value={finishDate}
                defaultValue={
                  campaignData?.getCampaignById?.endDate
                    ? moment(
                        campaignData?.getCampaignById?.endDate,
                        'YYYY-MM-DD HH:mm:ss',
                      )
                    : ''
                }
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm:ss'),
                }}
                format={'YYYY-MM-DD HH:mm:ss'}
                onChange={e =>
                  setFinishDate(moment(e).format('YYYY-MM-DD HH:mm:ss'))
                }
                style={{width: 340}}
              />
            </Form.Item>
          </div>
          <div>
            {/* <Form.Item label="Allowed Payment Methods" name="paymentMethods">
              <Select
                mode="multiple"
                style={{width: 600}}
                placeholder="Select the Allowed Payment methods for this Listing"
                value={paymentMethods}
                defaultValue={
                  campaignData?.getCampaignById?.allowedPaymentMethods
                }
                onChange={e => setPaymentMethods(e)}>
                {paymentMethodOptions.map(data => (
                  <Select.Option key={data.value}>
                    <span>{data.label}</span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
            <Form.Item
              name="visibility"
              label="Listing Visibility (Disabling this will HIDE this Listing from all but Internal Team Members)">
              <Switch
                value={isListingVisible}
                defaultChecked={
                  campaignData?.getCampaignById?.settings?.isVisible
                }
                onChange={e => setIsListingVisible(e)}
              />
            </Form.Item>
            <Form.Item name="pauseListing" label="Pause Listing">
              <Switch
                value={pauseListing}
                defaultChecked={
                  campaignData?.getCampaignById?.settings?.isPaused
                }
                onChange={e => setPauseListing(e)}
              />
            </Form.Item>
            <Form.Item
              name="notifyUsersOnLaunch"
              label="Notify Users on Launch">
              <Switch
                value={notifyUsersOnLaunch}
                defaultChecked={
                  campaignData?.getCampaignById?.settings
                    ?.shouldNotifyUsersOnLaunch
                }
                onChange={e => setNotifyUsersOnLaunch(e)}
              />
            </Form.Item>
          </div>
        </Form>
        <div>
          <SubmitButton
            disabled={campaignLoading}
            label="Update"
            handleClick={handleUpdate}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateCampaignSettings;
