import {Button} from '@dabafinance/react-components';
import {Form, Input, Select, Switch, Table, Tag} from 'antd';
import styles from './notifications.module.scss';
import {useState} from 'react';
import SubmitButton from 'components/Startup/components/SubmitButton';
import DBModal from 'components/modal/modal';
import {useMutation, useQuery} from '@apollo/client';
import {GET_NOTIFICATION_CLASSES} from 'graphql/queries/notifications';
import {UPDATE_NOTIFICATION_CLASS} from 'graphql/mutations/notifications';
import {NavTabsEnum, notificationChannels} from 'utils/constants';
import {toast} from 'react-toastify';
import {makeSelectTabOperations} from 'redux/store/auth';
import {useSelector} from 'react-redux';
import deleteIcon from 'images/delete.svg';
import plusIcon from 'images/plus-circle.png';

const NotificationClass = () => {
  const [openModal, setOpenModal] = useState(false);
  const [channels, setChannels] = useState([
    {
      channel: 'PUSH',
      mandatory: false,
    },
  ]);

  const handleAddChannel = () => {
    setChannels([...channels, {channel: '', mandatory: false}]);
  };
  const onChannelDelete = id => {
    const filteredFormValue = channels.filter((item, index) => index !== id);
    setChannels(filteredFormValue);
  };
  const handleChangeChannelValue = (i, name, e) => {
    const newFormValues = JSON.parse(JSON.stringify(channels));
    newFormValues[i][name] = e;
    setChannels(newFormValues);
  };

  const {data, refetch, loading} = useQuery(GET_NOTIFICATION_CLASSES);
  const [createNotificationClass] = useMutation(UPDATE_NOTIFICATION_CLASS);

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.NotificationClass),
  );

  const [form] = Form.useForm();

  const handleClassUpsert = formValues => {
    createNotificationClass({
      variables: {
        input: {
          active: formValues?.active,
          channels: channels?.map(item => ({
            channel: item?.channel,
            mandatory: item?.mandatory,
          })),
          name: formValues?.name,
        },
      },
    })
      .then(({data: {upsertNotificationClass}}) => {
        if (upsertNotificationClass.__typename === 'Error') {
          toast.error(upsertNotificationClass.message);
        } else {
          setOpenModal(false);
          toast.success(upsertNotificationClass.message);
          refetch({});
        }
      })
      .catch(err => toast.error(err));
  };

  const handleOpenModal = (data, e) => {
    if (e.target.isConnected) {
      setChannels(data.channels);
      form.setFieldsValue({
        name: data.name,
        active: data.active,
      });
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Channels',
      dataIndex: 'channels',
      key: 'channels',
      render: (action, {channels}) => (
        <div>
          {channels.map(tag => (
            <Tag key={tag?.channel}>{tag?.channel.toUpperCase()}</Tag>
          ))}
        </div>
      ),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (action, record) => (
        <Tag color={record?.active ? 'green' : 'red'}>
          {record?.active ? 'ACTIVE' : 'NOT ACTIVE'}
        </Tag>
      ),
    },
  ];

  if (tabOperations?.update) {
    columns.push({
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (_, record) => (
        <Button
          label="Update"
          onClick={e => handleOpenModal(record, e)}
          type="secondary"
        />
      ),
    });
  }
  const handleOpenCreateModal = () => {
    form.setFieldsValue({
      name: '',
      active: false,
      channels: [],
    });
    setOpenModal(true);
  };

  return (
    <>
      <div className={styles.header}>
        <h1>Notification Classes</h1>
        <Button
          label="Add New Class"
          className={styles['button-style']}
          onClick={() => handleOpenCreateModal()}
          disabled={!tabOperations?.create}
        />
      </div>
      <Table
        columns={columns}
        dataSource={data?.getNotificationClasses?.data}
        loading={loading}
      />
      <DBModal
        isOpen={openModal}
        handleClose={() => handleCloseModal()}
        content={
          <div>
            <h3 className={styles.modalHeader}>Add Notification Class Modal</h3>
            <Form
              form={form}
              className={styles.modalForm}
              layout="vertical"
              onFinish={handleClassUpsert}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: 'Name is required',
                  },
                ]}>
                <Input className={styles.input} />
              </Form.Item>
              <Form.Item
                name="active"
                label="Notification Status"
                rules={[
                  {
                    required: true,
                    message: 'Status is required',
                  },
                ]}
                valuePropName="checked">
                <Switch />
              </Form.Item>
              <h3 className={styles.title}>Channels</h3>
              {channels.map((item, index) => (
                <div key={index} className={styles.channels}>
                  <div>
                    <div className={styles.channels}>
                      <Form.Item label="Notification Channel">
                        <Select
                          className={styles.input}
                          name="channel"
                          value={item.channel || ''}
                          placeholder="Notification Channel"
                          onChange={e =>
                            handleChangeChannelValue(index, 'channel', e)
                          }>
                          {notificationChannels.map(key => (
                            <Select.Option key={key}>{key}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item label={'Should be mandatory?'}>
                        <Switch
                          checked={item.mandatory}
                          defaultChecked={item.mandatory || false}
                          onChange={e =>
                            handleChangeChannelValue(index, 'mandatory', e)
                          }
                        />
                      </Form.Item>
                    </div>
                    {!item.channel && (
                      <span className={styles['warning']}>
                        Please note that channel is required
                      </span>
                    )}
                  </div>
                  <img
                    onClick={() => onChannelDelete(index)}
                    src={deleteIcon}
                    alt=""
                  />
                </div>
              ))}
              <div onClick={handleAddChannel} className={styles['add-button']}>
                <img src={plusIcon} alt="" />
                <h1>Add Channel</h1>
              </div>
              <SubmitButton label="Create" disabled={!tabOperations?.create} />
            </Form>
          </div>
        }
      />
    </>
  );
};

export {NotificationClass};
