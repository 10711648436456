import {useState} from 'react';
import styles from './founderCreation.module.scss';
import {useNavigate} from 'react-router-dom';
import FounderCard from './founderCard';
import {Button} from '@dabafinance/react-components';
import plusIcon from 'images/plus-circle.png';

const FounderCreation = () => {
  const [addCard, setAddCard] = useState([
    {
      imgUrl: '',
      previewUrl: '',
      fullname: '',
      ceo: '',
      twitter: '',
      sex: '',
      role: '',
      linkedin: '',
      description: '',
    },
  ]);

  const navigate = useNavigate();

  const addAnother = add => {
    setAddCard(current => [...current, add]);
  };

  const onDelete = id => {
    const filteredFormValue = addCard.filter((item, index) => index !== id);
    setAddCard(filteredFormValue);
  };

  return (
    <div>
      <div className={styles['nav-header']}>
        <h1>Founders</h1>
      </div>
      <div className={styles['founder-container']}>
        <div className={styles.header}>
          <h1>Founders</h1>
          <span>Step 5/5</span>
        </div>
        <div>
          <div>
            {addCard?.map((event, index) => (
              <div key={index}>
                <FounderCard
                  DeleteFounder={() => onDelete(index)}
                  event={event}
                />
              </div>
            ))}
            <div onClick={addAnother} className={styles['add-founder']}>
              <img src={plusIcon} alt="add-founder" />
              <span>Add Founder</span>
            </div>
          </div>
          <div className={styles['button-wrapper']}>
            <Button
              onClick={() => navigate('/dashboard/opportunities/startups/')}
              className={styles.button}
              label="Proceed"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FounderCreation;
