import {Form, Input, Select, Spin, Upload} from 'antd';
import styles from './singleCompany.module.scss';
import {useMutation, useQuery} from '@apollo/client';
import {GET_ALL_SAVING_COMPANY_QUERIES} from 'graphql/queries/savings';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {
  CREATE_SAVINGS_COMPANY,
  UPDATE_SAVINGS_COMPANY,
} from 'graphql/mutations/savings';
import {
  createSavingsCompany,
  updateSavingsCompany,
} from '../savingsCompanyActions';
import {handleConfigurationImageUpload} from 'pages/Affiliates/page/AffiliateConfiguration/actions';
import {Button} from '@dabafinance/react-components';
import backIcon from 'images/back.png';
import {useDispatch, useSelector} from 'react-redux';

const SavingsCompanyProfile = () => {
  const [formValues, setFormValues] = useState({});
  const [openFinishModal, setOpenFinishModal] = useState(false);
  const [img, setImg] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loadingImage, setLoadingImageUpload] = useState(false);
  const navigate = useNavigate();
  const {id} = useParams();
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const savingsData = useSelector(state => state?.savings);

  useEffect(() => {
    console.log('savingsData', savingsData);
    if (savingsData && id) {
      form.setFieldsValue({
        name: savingsData?.organizationData?.name,
        city: savingsData?.organizationData?.city,
        website: savingsData?.organizationData?.website,
        companyEmail: savingsData?.organizationData?.companyEmail,
        state: savingsData?.organizationData?.state,
        businessAddress: savingsData?.organizationData?.businessAddress,
        industry: savingsData?.organizationData?.industry?.id,
        country: savingsData?.organizationData?.country?.id,
        companyPhoneNumber: savingsData?.organizationData?.companyPhoneNumber,
        NoOfEmployees: savingsData?.organizationData?.NoOfEmployees,
        keyContactPersonName:
          savingsData?.organizationData?.keyContactPersonName,
        keyContactPersonEmail:
          savingsData?.organizationData?.keyContactPersonEmail,
        keyContactPersonPhoneNumber:
          savingsData?.organizationData?.keyContactPersonPhoneNumber,
      });
    }
  }, [savingsData]);

  const {data, loading} = useQuery(GET_ALL_SAVING_COMPANY_QUERIES, {
    errorPolicy: 'all',
  });
  const [updateOrganization, {loading: loadingUpdate}] = useMutation(
    UPDATE_SAVINGS_COMPANY,
  );
  const [createOrganization, {loading: loadingCreate}] = useMutation(
    CREATE_SAVINGS_COMPANY,
  );

  const handleChangeImage = e => {
    const file = e.file;
    if (file.status === 'uploading') {
      setLoadingImageUpload(true);
    } else if (file.status === 'done' || file.status === 'error') {
      setLoadingImageUpload(false);
      handleConfigurationImageUpload(
        file,
        setImg,
        setLoadingImageUpload,
        setProgress,
        true,
      );
    }
  };

  if (loading) {
    return (
      <div className="query-loading">
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.singleCompany}>
      <div>
        {!id && (
          <>
            <div className={styles.companyHeader}>
              <img onClick={() => navigate(-1)} src={backIcon} alt="" />
              <h1>Create Organization</h1>
            </div>
            <div className={styles.imageContainer}>
              {img ? (
                <div className={styles['image-uploaded']}>
                  <img src={img} alt="" />
                </div>
              ) : (
                <Upload
                  accept=".png, .jpeg, .jpg"
                  name="file"
                  onChange={handleChangeImage}>
                  <div className={styles['upload-container']}>
                    {loadingImage ? (
                      <>
                        <h2>{progress}%</h2>
                        <Spin size="small" />
                      </>
                    ) : (
                      <>
                        <h2 style={{marginTop: 6}}>Upload Logo</h2>
                      </>
                    )}
                  </div>
                </Upload>
              )}
              {img && <Button label="Delete Logo" onClick={() => setImg('')} />}
            </div>
          </>
        )}
        <Form
          onFinish={value => {
            setFormValues(value);
            setOpenFinishModal(true);
          }}
          form={form}
          className={styles.form}
          layout="vertical">
          <Form.Item
            rules={[{required: true, message: 'This Field is Required'}]}
            className={styles.cFieldWidth}
            label="Organization Name"
            name="name">
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              {
                type: 'email',
                message: 'This Field is Required',
              },
            ]}
            className={styles.cFieldWidth}
            label="Organization Email"
            name="companyEmail">
            <Input />
          </Form.Item>
          <Form.Item
            className={styles.cFieldWidth}
            label="Organization Phone No."
            name="companyPhoneNumber">
            <Input type="number" />
          </Form.Item>
          <Form.Item
            className={styles.cFieldWidth}
            label="Business Address"
            name="businessAddress">
            <Input />
          </Form.Item>
          <Form.Item
            className={styles.cFieldWidth}
            label="Organization Size"
            name="NoOfEmployees">
            <Input type="number" />
          </Form.Item>
          <Form.Item
            className={styles.cFieldWidth}
            label="Industry"
            name="industry">
            <Select
              allowClear
              showSearch
              loading={loading}
              optionFilterProp="label">
              {data?.getAllIndustries?.map(data => (
                <Select.Option label={data?.name} key={data?.id}>
                  <div className={styles['all-data']}>
                    <img src={data?.icon} alt="" />
                    <span>{data?.name}</span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.cFieldWidth}
            label="Country"
            name="country">
            <Select
              showSearch
              allowClear
              name="country"
              loading={loading}
              optionFilterProp="label">
              {data?.getAllCountries?.map(data => (
                <Select.Option label={data?.name} key={data?.id}>
                  <div className={styles['all-data']}>
                    <img src={data?.icon} alt="" />
                    <span>{data?.name}</span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className={styles.cFieldWidth} label="State" name="state">
            <Input />
          </Form.Item>
          <Form.Item className={styles.cFieldWidth} label="City" name="city">
            <Input />
          </Form.Item>
          <Form.Item
            className={styles.cFieldWidth}
            label="Website"
            name="website">
            <Input />
          </Form.Item>
          <div className={styles.ruleTitle}>
            <h1>Key person Information</h1>
            <hr />
          </div>
          <Form.Item
            rules={[{required: true, message: 'This Field is Required'}]}
            className={styles.cFieldWidth}
            label="Key Contact Person Name"
            name="keyContactPersonName">
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                type: 'email',
                message: 'This Field is Required',
              },
            ]}
            className={styles.cFieldWidth}
            label="Contact Person Email"
            name="keyContactPersonEmail">
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'This Field is Required'}]}
            className={styles.cFieldWidth}
            label="Contact Person Phone Number"
            name="keyContactPersonPhoneNumber">
            <Input type="number" />
          </Form.Item>
          <div style={{display: 'block', width: '100%'}}>
            <SubmitButton label="Save Settings" />
          </div>
        </Form>
      </div>
      <ConfirmModal
        title={id ? 'Update this Profile Settings' : 'Create An Organization'}
        confirmText="Are you sure you want to make these Changes?"
        loading={loadingUpdate || loadingCreate}
        openModal={openFinishModal}
        setOpenModal={() => setOpenFinishModal(false)}
        handleOperation={() =>
          id
            ? updateSavingsCompany({
                formValues,
                id,
                updateOrganization,
                setOpenFinishModal,
                dispatch,
              })
            : createSavingsCompany({
                formValues,
                img,
                createOrganization,
                setOpenFinishModal,
                navigate,
              })
        }
      />
    </div>
  );
};

export default SavingsCompanyProfile;
