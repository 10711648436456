import {useState} from 'react';
import styles from './trendingIndustries.module.scss';
import {Button} from '@dabafinance/react-components';
import {Table, Spin, Form, Select, InputNumber, DatePicker, Modal} from 'antd';
import {useNavigate} from 'react-router-dom';
import {GET_ALL_TOPICS} from 'graphql/queries/interest';
import {useQuery, useMutation, useLazyQuery} from '@apollo/client';
import pageNotFound from 'images/pageNotFound.png';
import {toast} from 'react-toastify';
import {
  GET_TRENDING_INDUSTRY,
  GET_TRENDING_INDUSTRY_BY_MONTH,
} from 'graphql/queries/trendingIndustries';
import DBModal from 'components/modal/modal';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {
  CREATE_TRENDING_INDUSTRIES,
  DELETE_TRENDING_INDUSTRY,
  UPDATE_TRENDING_INDUSTRIES,
} from 'graphql/mutations/trendingIndustries';
import moment from 'moment';
import trashImg from 'images/trash-can-outline.svg';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const TrendingIndustries = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [trendingId, setTrendingId] = useState('');
  const [trendingIndustries, setTrendingIndustries] = useState();
  const [industry, setIndustry] = useState('');
  const [sequence, setSequence] = useState();
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [trendingDetails, setTrendingDetails] = useState();
  const [filterMonth, setFilterMonth] = useState('');
  const [filterYear, setFilterYear] = useState('');
  const {data: getAllTopics} = useQuery(GET_ALL_TOPICS, {
    variables: {
      type: 'INDUSTRY',
    },
  });

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Updates),
  );

  const {loading} = useQuery(GET_TRENDING_INDUSTRY, {
    onCompleted: data => (
      setTrendingId(data?.getTrendingIndustries?.id),
      setTrendingDetails(data?.getTrendingIndustries),
      setTrendingIndustries(data?.getTrendingIndustries?.trendingIndustries)
    ),
    onError: error => toast.error(error),
  });
  const [updateTrendingIndustries] = useMutation(UPDATE_TRENDING_INDUSTRIES);
  const [createTrendingIndustries] = useMutation(CREATE_TRENDING_INDUSTRIES);

  const [getTrendingIndustriesByMonth] = useLazyQuery(
    GET_TRENDING_INDUSTRY_BY_MONTH,
  );

  const {Option} = Select;
  const handleShow = () => {
    setIsOpen(true);
  };
  const handleModal = () => {
    setCreateModalOpen(true);
  };

  const handleFilter = () => {
    getTrendingIndustriesByMonth({
      variables: {
        month: moment(filterMonth).format('MMMM'),
        year: moment(filterYear).format('YYYY'),
      },
      onCompleted: data => (
        setTrendingId(data?.getTrendingIndustriesByMonth?.id),
        setTrendingDetails(data?.getTrendingIndustriesByMonth),
        setTrendingIndustries(
          data?.getTrendingIndustriesByMonth?.trendingIndustries,
        )
      ),
      onError: error => toast.error(error),
    });
  };

  const handleAddition = () => {
    const newArr = [];
    for (let i = 0; i < trendingIndustries.length; i++) {
      const newObj = {
        industry: trendingIndustries[i]?.industry?.id,
        sequence: trendingIndustries[i]?.sequence,
        companies: trendingIndustries[i]?.companies?.map(
          company => company?.id,
        ),
        totalAmountReceived: trendingIndustries[i]?.totalAmountReceived,
        totalNumOfDeals: trendingIndustries[i]?.totalNumOfDeals,
      };
      newArr.push(newObj);
    }
    newArr.push({
      industry: industry.split(';')[1],
      sequence,
      totalNumOfDeals: 0,
    });
    handleUpdate(newArr);
  };

  const handleUpdate = newArr => {
    updateTrendingIndustries({
      variables: {
        input: {
          trendingIndustriesId: trendingId,
          trendingIndustries: newArr,
          month: trendingDetails?.month,
          year: trendingDetails?.year,
        },
      },
      refetchQueries: [{query: GET_TRENDING_INDUSTRY}],
    })
      .then(({data: {updateTrendingIndustries}}) => {
        if (updateTrendingIndustries.__typename === 'Error') {
          toast.error(updateTrendingIndustries.message);
        } else {
          toast.success('Industry Added Successfully');
        }
        setIsOpen(false);
      })
      .catch(error => {
        toast.error(error);
      });
  };
  const handleCreate = () => {
    createTrendingIndustries({
      variables: {
        input: {
          month: moment(month).format('MMMM'),
          year: moment(year).format('YYYY'),
          trendingIndustries: [
            {
              industry: industry.split(';')[1],
            },
          ],
        },
      },
      refetchQueries: [{query: GET_TRENDING_INDUSTRY}],
    })
      .then(({data: {createTrendingIndustries}}) => {
        if (createTrendingIndustries.__typename === 'Error') {
          toast.error(createTrendingIndustries.message);
        } else {
          toast.success('Industry Created Successfully');
        }
        setCreateModalOpen(false);
      })
      .catch(error => {
        toast.error(error);
      });
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Trending Industries</h2>
        <Form
          className={styles['date-filter']}
          layout="inline"
          onFinish={handleFilter}>
          <Form.Item
            label="Month"
            name="month"
            rules={[
              {
                required: true,
                message: 'Month is required',
              },
            ]}>
            <DatePicker
              picker="month"
              value={filterMonth}
              onChange={setFilterMonth}
              format={'MMMM'}
            />
          </Form.Item>
          <Form.Item
            label="Year"
            name="year"
            rules={[
              {
                required: true,
                message: 'Year is required',
              },
            ]}>
            <DatePicker
              picker="year"
              value={filterYear}
              format={'YYYY'}
              onChange={setFilterYear}
            />
          </Form.Item>
          <SubmitButton
            smallWidth
            type="secondary"
            className={styles['filter-button']}
            label="Filter"
          />
        </Form>
        <div className={styles.actions}>
          {trendingDetails === null ? (
            <Button
              className={styles['add-button']}
              onClick={handleModal}
              disabled={!tabOperations?.create}
              label="Create Trending Industry"
            />
          ) : (
            <Button
              className={styles['add-button']}
              onClick={handleShow}
              label="Add Trending Industry"
            />
          )}

          <DBModal
            isOpen={createModalOpen}
            content={
              <div>
                <div className={styles['modal-title']}>
                  <span>Create Trending Industry</span>
                </div>
                <Form layout="vertical">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Industry is required',
                      },
                    ]}
                    label="Select Industry"
                    className={styles.field}>
                    <Select
                      showSearch
                      value={industry}
                      onChange={e => setIndustry(e)}>
                      {getAllTopics?.getAllTopics.map(({name, icon, id}) => (
                        <Option key={`${name};${id}`}>
                          <div className={styles['all-data']}>
                            <img src={icon} alt="" />
                            <span>{name}</span>
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className={styles['form-input']}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Month is required',
                        },
                      ]}
                      label="Month"
                      className={styles.field}
                      name="month">
                      <DatePicker
                        picker="month"
                        value={month}
                        onChange={e => setMonth(e)}
                        format={'MMMM'}
                        className={styles['month-input']}
                      />
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Year is required',
                        },
                      ]}
                      label="Year"
                      className={styles.field}
                      name="year">
                      <DatePicker
                        picker="year"
                        value={year}
                        format={'YYYY'}
                        onChange={e => setYear(e)}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item label="Ranking" className={styles.field}>
                    <InputNumber
                      className={styles['input-number']}
                      placeholder="1"
                      onChange={e => setSequence(e)}
                    />
                  </Form.Item>
                  <SubmitButton
                    type="primary"
                    handleClick={() => handleCreate()}
                    label="Add"
                    disabled={!tabOperations?.update}
                  />
                </Form>
              </div>
            }
            handleClose={() => setCreateModalOpen(false)}></DBModal>
          <DBModal
            isOpen={isOpen}
            content={
              <div>
                <div className={styles['modal-title']}>
                  <span>Add Trending Industry</span>
                </div>
                <Form layout="vertical">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Industry is required',
                      },
                    ]}
                    label="Select Industry"
                    className={styles.field}>
                    <Select
                      showSearch
                      value={industry}
                      onChange={e => setIndustry(e)}>
                      {getAllTopics?.getAllTopics.map(({name, icon, id}) => (
                        <Option key={`${name};${id}`}>
                          <div className={styles['all-data']}>
                            <img src={icon} alt="" />
                            <span>{name}</span>
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className={styles['form-input']}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Month is required',
                        },
                      ]}
                      label="Month"
                      className={styles.field}
                      name="month">
                      <DatePicker
                        picker="month"
                        value={month}
                        onChange={e => setMonth(e)}
                        format={'MMMM'}
                        className={styles['month-input']}
                      />
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Year is required',
                        },
                      ]}
                      label="Year"
                      className={styles.field}
                      name="year">
                      <DatePicker
                        picker="year"
                        value={year}
                        format={'YYYY'}
                        onChange={e => setYear(e)}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item label="Ranking" className={styles.field}>
                    <InputNumber
                      className={styles['input-number']}
                      placeholder="1"
                      onChange={e => setSequence(e)}
                    />
                  </Form.Item>
                  <SubmitButton
                    type="primary"
                    handleClick={() => handleAddition()}
                    label="Add"
                    disabled={!tabOperations?.update}
                  />
                </Form>
              </div>
            }
            handleClose={() => setIsOpen(false)}></DBModal>
        </div>
      </div>
      <RenderTable
        trendingIndustries={trendingIndustries}
        trendingDetails={trendingDetails}
      />
    </div>
  );
};

export default TrendingIndustries;

const RenderTable = ({trendingIndustries, trendingDetails}) => {
  const navigate = useNavigate();

  const {confirm} = Modal;
  const [deleteTrendingIndustry] = useMutation(DELETE_TRENDING_INDUSTRY);

  const handleDelete = id => {
    confirm({
      content: 'Are you sure you want to delete this trending Industry?',
      onOk: () => {
        deleteTrendingIndustry({
          variables: {
            trendingIndustriesId: trendingDetails?.id,
            trendingIndustryId: id,
          },
          update(cache) {
            cache.modify({
              fields: {
                getTrendingIndustries() {},
              },
            });
          },
        }).then(({data: {deleteTrendingIndustry}}) => {
          if (deleteTrendingIndustry.__typename === 'Error') {
            toast.error(deleteTrendingIndustry.message);
          } else {
            toast.success('Deleted Successfully');
            window.location.reload();
          }
        });
      },
    });
  };

  const columns = [
    {
      title: 'Industry',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <img src={record?.industry?.icon} alt="" />
          <span>{record?.industry?.name}</span>
        </div>
      ),
    },
    {
      title: 'Ranking',
      dataIndex: 'sequence',
      key: 'sequence',
      render: (text, record, index) => (
        <div className={styles['record-label']}>
          <span>{index + 1}</span>
        </div>
      ),
    },

    {
      title: 'Investment Received',
      dataIndex: 'totalValuation',
      key: 'totalValuation',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>${record?.totalAmountReceived}</span>
        </div>
      ),
    },
    {
      title: 'Total Deals',
      dataIndex: 'totalNumOfDeals',
      key: 'totalNumOfDeals',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>{record?.totalNumOfDeals}</span>
        </div>
      ),
    },
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
      render: () => (
        <div className={styles['record-label']}>
          <span>{trendingDetails?.month}</span>
        </div>
      ),
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      render: () => (
        <div className={styles['record-label']}>
          <span>{trendingDetails?.year}</span>
        </div>
      ),
    },
    {
      title: 'Companies',
      dataIndex: 'companies',
      key: 'companies',
      render: (text, record) => (
        <div className={styles['record-label']}>
          {record?.companies?.length > 0 ? (
            record?.companies?.length === 1 ? (
              <div className={styles['span-element']}>
                <span>{record?.companies[0]?.name}</span>
              </div>
            ) : (
              <div className={styles['span-element']}>
                <span>{record?.companies[0]?.name}</span>
                <span className={styles['company-length']}>
                  +{record?.totalNumOfDeals - 1}
                </span>
              </div>
            )
          ) : (
            <Button
              label="Add Company"
              onClick={() =>
                navigate(`/dashboard/explore/trending-industries/${record.id}`)
              }
            />
          )}
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <div>
          <div className={styles['action-buttons']}>
            {!!record?.companies?.length && (
              <Button
                type="secondary"
                label="View details"
                className={styles.button}
                onClick={() =>
                  navigate(
                    `/dashboard/explore/trending-industries/${record.id}`,
                  )
                }
              />
            )}
            <img
              onClick={() => handleDelete(record?.id)}
              className={styles.trash}
              src={trashImg}
              alt=""
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      {trendingIndustries?.length > 0 ? (
        <Table
          dataSource={[...trendingIndustries]?.sort(
            (a, b) => b?.totalAmountReceived - a?.totalAmountReceived,
          )}
          columns={columns}
        />
      ) : (
        <div className={styles['no-update']}>
          <img src={pageNotFound} alt="" />
          <h1>No Trending Industries</h1>
          <span>Add a new Trending Industry</span>
        </div>
      )}
    </div>
  );
};
