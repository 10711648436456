import {useState} from 'react';
import backIcon from 'images/back.png';
import styles from './createStartup.module.scss';
import {DatePicker, Form, Input, Select, Spin} from 'antd';
import {useMutation, useQuery} from '@apollo/client';
import {
  CREATE_WEEKLY_STARTUP,
  GET_ALL_STARTUPS,
} from 'graphql/queries/startupCompanies';
import moment from 'moment';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {BigPlayButton, Player} from 'video-react';
import {UploadVideoToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {Button} from '@dabafinance/react-components';

const CreateStartupOfTheWeek = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [company, setCompany] = useState('');
  const [progress, setProgress] = useState(0);
  const [videoUploadLoading, setVideoUploadLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const {Option} = Select;
  const navigate = useNavigate();

  const {data: getAllCompanies, loading} = useQuery(GET_ALL_STARTUPS);
  const [createWeeklyStartup, {loading: loadingStartupCreation}] = useMutation(
    CREATE_WEEKLY_STARTUP,
  );

  const handleUploadVideo = async e => {
    const selectedFile = e.target.files[0];
    if (selectedFile.type === 'video/mp4') {
      setVideoUploadLoading(true);
      const response = await UploadVideoToBucket(
        selectedFile,
        'startups-bucket',
        setProgress,
      );
      setVideoUrl(response);
      setVideoUploadLoading(false);
      setProgress(0);
    } else {
      toast.error('Please upload a mp4 video');
    }
  };

  const handleCreate = () => {
    createWeeklyStartup({
      variables: {
        data: {
          title: title.trim(),
          description: description.trim(),
          company: company.split(';')[1],
          videoUrl,
          expirationDate: moment(expirationDate).format(),
        },
      },
    })
      .then(({data: {createWeeklyStartup}}) => {
        if (createWeeklyStartup.__typename === 'Error') {
          toast.error(createWeeklyStartup.message);
        } else {
          toast.success('Created Successfully');
          navigate('/dashboard/explore/startup-of-the-week');
          window.location.reload(false);
        }
      })
      .catch(error => toast.error(error));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>Create Startup of the Week</h1>
      </div>
      <div className={styles.body}>
        <div className={styles['video-upload']}>
          {videoUrl ? (
            <div>
              <Player fluid={false} width={730} src={videoUrl}>
                <BigPlayButton position="center" />
              </Player>
              <div>
                <Button
                  className={styles['delete-button']}
                  label="Delete Video"
                  onClick={() => setVideoUrl('')}
                />
              </div>
            </div>
          ) : (
            <div>
              <label className={styles.upload} htmlFor="file-upload">
                {videoUploadLoading ? (
                  <div className={styles['upload-file']}>
                    <Spin />
                    <h2>Uploading Please Wait...</h2>
                    <span>{progress}%</span>
                  </div>
                ) : (
                  <h2>Upload Startup Video</h2>
                )}
                <input
                  type="file"
                  id="file-upload"
                  className={styles['input-file']}
                  required
                  onChange={handleUploadVideo}
                />
              </label>
              <span>Please upload a video of type mp4</span>
            </div>
          )}
        </div>
        <Form onFinish={handleCreate} layout="vertical">
          <Form.Item
            className={styles['field-width']}
            name="Title"
            label="Title"
            rules={[
              {
                required: true,
                message: 'Title is required',
              },
            ]}>
            <Input value={title} onChange={e => setTitle(e.target.value)} />
          </Form.Item>
          <Form.Item
            className={styles['field-width']}
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: 'Description is required',
              },
            ]}>
            <Input.TextArea
              value={description}
              rows={6}
              onChange={e => setDescription(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            className={styles['field-width']}
            name="company"
            label="Select Company"
            rules={[
              {
                required: true,
                message: 'Company is required',
              },
            ]}>
            <Select showSearch value={company} onChange={e => setCompany(e)}>
              {getAllCompanies?.getAllCompanies?.map(company => (
                <Option key={`${company?.name};${company?.id}`}>
                  <div className={styles['all-data']}>
                    <img src={company?.logoImgURL} alt="" />
                    <span>{company?.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Expiry date required',
              },
            ]}
            name="expiry date"
            label="Expiration Date">
            <DatePicker
              name="Expiry Date"
              format={'YYYY-MM-DD HH:mm:ss'}
              style={{width: 340}}
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
              value={expirationDate}
              onChange={e =>
                setExpirationDate(moment(e).format('YYYY-MM-DD HH:mm:ss'))
              }
            />
          </Form.Item>
          <SubmitButton disabled={loadingStartupCreation} label="Create" />
        </Form>
      </div>
    </div>
  );
};

export default CreateStartupOfTheWeek;
