import {gql} from '@apollo/client';

export const GET_SCHEDULED_NOTIFICATIONS = gql`
  query GetScheduledNotifications {
    getScheduledNotifications {
      id
      type
      title
      body
      isRead
      isActive
      status
      category
      createdAt
    }
  }
`;

export const GET_OFFICIAL_EMAILS = gql`
  query GetOfficialEmails {
    getOfficialEmails {
      email
    }
  }
`;

export const NOTIFICATION_BATCH_QUERY = gql`
  query GetAllGroups {
    getAllGroups {
      ... on Error {
        message
        statusCode
      }
      ... on GroupList {
        groups {
          id
          name
          description
          bannerImg
        }
      }
    }
    getOfficialEmails {
      email
    }
    getAllCollections {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCollections {
        data {
          collections {
            id
            name
            iconUrl
          }
        }
      }
    }
    getAllCampaigns {
      ... on Error {
        message
        statusCode
      }
      ... on Campaigns {
        campaigns {
          id
          fundraisingCompany {
            id
            companyId
            legalName
            logoImgURL
            companyName
          }
          listingType
          campaignStatus
          campaignMode
          offeringType
          campaignMode
        }
      }
    }
    getAllTopics {
      ... on Error {
        message
        statusCode
      }
      ... on Topic {
        id
        icon
        name
      }
    }
    getAllCompanies {
      ... on Error {
        message
        statusCode
      }
      ... on Company {
        id
        name
        logoImgURL
      }
    }
    getAllReports {
      ... on Error {
        message
        statusCode
      }
      ... on Report {
        id
        type
        document
        title
      }
    }
  }
`;

export const GET_NOTIFICATION_CLASSES = gql`
  query GetNotificationClasses($input: NotificationClassFilterInput) {
    getNotificationClasses(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithNotificationClasses {
        message
        data {
          id
          name
          channels {
            channel
            mandatory
          }
          key
          active
        }
        pagination {
          page
          limit
          pages
          total
        }
      }
    }
  }
`;

export const GET_USER_NOTIFICATION_PREFERENCE = gql`
  query GetUserNotificationPreferences(
    $input: NotificationPreferenceFilterInput
  ) {
    getUserNotificationPreferences(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAnything {
        message
        data
      }
    }
  }
`;
