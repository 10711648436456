import {useMutation, useQuery} from '@apollo/client';
import styles from './languageVersion.module.scss';
import {Form, Select, Spin, Table} from 'antd';
import {GET_CAMPAIGN_LANGUAGE_VERSIONS} from 'graphql/queries/campaign';
import {useNavigate, useParams} from 'react-router-dom';
import {Button} from '@dabafinance/react-components';
import {useEffect, useState} from 'react';
import backIcon from 'images/back.png';
import {notificationLanguages} from 'utils/constants';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {TRANSLATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';

const LanguageVersion = () => {
  const [languageVersion, setLanguageVersion] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const {id} = useParams();
  const navigate = useNavigate();

  const {data, loading} = useQuery(GET_CAMPAIGN_LANGUAGE_VERSIONS, {
    variables: {
      getCampaignLanguageVersionsId: id,
    },
  });
  const [translateCampaign, {loading: campaignLoading}] =
    useMutation(TRANSLATE_CAMPAIGN);

  const {Option} = Select;

  useEffect(() => {
    setLanguageVersion(
      data?.getCampaignLanguageVersions?.campaignLanguageVersions?.languageVersions.map(
        item => {
          return {
            id: data?.getCampaignLanguageVersions?.campaignLanguageVersions
              ?.campaignId,
            title:
              data?.getCampaignLanguageVersions?.campaignLanguageVersions
                ?.companyName,
            language: item,
          };
        },
      ),
    );
  }, [data]);

  const handleTranslation = () => {
    translateCampaign({
      variables: {
        translateCampaignId: id,
        targetLanguage: selectedLanguage,
      },
      refetchQueries: [
        {
          query: GET_CAMPAIGN_LANGUAGE_VERSIONS,
          variables: {getCampaignLanguageVersionsId: id},
        },
      ],
    })
      .then(({data: {translateCampaign}}) => {
        if (translateCampaign.__typename === 'Error') {
          toast.error(translateCampaign.message);
        } else {
          setSelectedLanguage('');
          toast.success('Campaign Translated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4 style={{width: 100}}>{record?.title}</h4>
        </div>
      ),
    },
    {
      title: 'Language Version',
      dataIndex: 'language',
      key: 'language',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4 style={{width: 100}}>
            {record?.language === 'EN' ? 'English' : 'French'}
          </h4>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <Button
          onClick={() =>
            navigate(
              `/dashboard/campaign/update/${record?.language}/${record.id}`,
            )
          }
          className={styles.button}
          type="secondary"
          label="Edit Campaign"
        />
      ),
    },
  ];

  return (
    <div>
      <div className={styles.header}>
        <img
          onClick={() => navigate('/dashboard/campaigns')}
          src={backIcon}
          alt=""
        />
        <h1>Campaign Language Version</h1>
      </div>
      {loading ? (
        <div className={styles.loading}>
          <Spin size="large" />
          <h1>Loading...</h1>
        </div>
      ) : (
        <div>
          <Form.Item>
            <Select
              placeholder="Select a Language"
              onChange={e => setSelectedLanguage(e)}
              style={{width: 320}}
              name="type">
              {notificationLanguages.map(name => (
                <Option key={name.key} value={name.key}>
                  {name.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <SubmitButton
            disabled={campaignLoading}
            label="Translate Campaign"
            handleClick={handleTranslation}
          />
          <Table columns={columns} dataSource={languageVersion} />
        </div>
      )}
    </div>
  );
};

export default LanguageVersion;
