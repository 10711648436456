import {Button} from '@dabafinance/react-components';
import styles from './updateSingleSavingsProduct.module.scss';

import {Form, Input} from 'antd';
import {useEffect, useState} from 'react';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {useMutation} from '@apollo/client';
import {UPDATE_SAVINGS_PRODUCT} from 'graphql/mutations/savings';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {handleUpdateSavingsProduct} from 'pages/Savings/companies/savingsCompanyActions';

const GeneralProductsInfo = ({data, onActiveTabChange, refetch, id}) => {
  const [formValues, setFormValues] = useState({});
  const [form] = Form.useForm();
  const [openFinishModal, setOpenFinishModal] = useState(false);

  const [updateSavingsProduct, {loading}] = useMutation(UPDATE_SAVINGS_PRODUCT);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        savingsType: data.savingsType,
        issuer: data.issuer,
      });
    }
  }, [data]);

  return (
    <div className={styles.generalRoot}>
      <h1>General Information</h1>
      <Form
        onFinish={e => {
          setFormValues({
            name: e.name,
            issuer: e.issuer,
          });
          setOpenFinishModal(true);
        }}
        form={form}
        layout="vertical">
        <Form.Item
          rules={[{required: true, message: 'This is a Required Field'}]}
          className={styles.field}
          name="name"
          label="Product Name">
          <Input />
        </Form.Item>
        <Form.Item name="savingsType" label="Savings Type">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Issuer" name="issuer">
          <Input />
        </Form.Item>
        <div className={styles.buttons}>
          <Button
            type="secondary"
            label="Next"
            onClick={() => onActiveTabChange('FINANCIAL')}
          />
          <SubmitButton smallWidth label="Proceed to Update" />
        </div>
      </Form>
      <ConfirmModal
        openModal={openFinishModal}
        title="Update Product Details"
        confirmText={'Are you sure all the details are inputed correctly?'}
        loading={loading}
        setOpenModal={() => setOpenFinishModal(false)}
        handleOperation={() =>
          handleUpdateSavingsProduct({
            formValues,
            id,
            updateSavingsProduct,
            setOpenFinishModal,
            refetch,
          })
        }
      />
    </div>
  );
};

export default GeneralProductsInfo;
