import {Upload as BannerImage} from 'antd';
import '../../../../../node_modules/video-react/dist/video-react.css';
import './player.css';
import styles from './upload.module.scss';
import videoImg from 'images/video.png';
import {Button} from '@dabafinance/react-components';
import {S3Client} from '@aws-sdk/client-s3';
import {Upload} from '@aws-sdk/lib-storage';

const CompanyVideoUpload = ({
  bannerPreviewUrl,
  setBannerPreviewUrl,
  setBannerUrl,
}) => {
  const UploadToBucket = async payload => {
    const fileName = `company-service-bucket/company-banners/${Date.now()}-${payload?.name.replace(
      / /g,
      '',
    )}`;
    const target = {
      Bucket: 'company-service-bucket',
      Key: fileName,
      Body: payload,
    };
    const cred = {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    };
    let imageUrl = '';
    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({
          region: 'us-east-1',
          credentials: cred,
        }),
        leavePartsOnError: false,
        params: target,
      });
      const res = await parallelUploads3.done();

      if (res.$metadata.httpStatusCode === 200) {
        imageUrl = `https://company-service-bucket.s3.amazonaws.com/${fileName}`;
        setBannerUrl(imageUrl);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = async ({file}) => {
    const temp = file.originFileObj;
    const url = URL.createObjectURL(file.originFileObj);
    setBannerPreviewUrl(url);
    await UploadToBucket(temp);
  };

  const uploadButton = (
    <div className={styles['upload-container']}>
      <img src={videoImg} alt="video" />
      <div style={{marginTop: 4}}>Upload Company Banner</div>
    </div>
  );

  const handleVideoDelete = () => {
    setBannerUrl('');
    setBannerPreviewUrl('');
  };

  return (
    <div className={styles['video-container']}>
      <h1>Company Banner</h1>
      <div>
        {bannerPreviewUrl ? (
          <img src={bannerPreviewUrl} alt="company" className={styles.player} />
        ) : (
          <BannerImage
            showUploadList={false}
            onChange={handleChange}
            maxCount={1}
            listType="picture">
            {uploadButton}
          </BannerImage>
        )}
        {bannerPreviewUrl && (
          <div className={styles['upload-buttons']}>
            <BannerImage
              showUploadList={false}
              onChange={handleChange}
              maxCount={1}
              listType="picture"></BannerImage>
            <Button
              type="secondary"
              label="Delete"
              className={styles.delete}
              onClick={handleVideoDelete}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyVideoUpload;
