import {useQuery} from '@apollo/client';
import {Spin} from 'antd';
import {GET_ASSET_CLASS} from 'graphql/queries/assetManagement';
import {AssetClassForm} from 'pages/AssetManagement/components/AssetClassForm';
import {useParams} from 'react-router-dom';

export const UpdateAssetClass = () => {
  const {id, language} = useParams();

  const {loading, data} = useQuery(GET_ASSET_CLASS, {
    variables: {
      getAssetClassId: id,
      language,
    },
  });

  if (loading) {
    return (
      <div className="query-loading">
        <Spin />
        <h1>Loading Asset Class</h1>
      </div>
    );
  }
  return (
    <div>
      <h1>Update Asset Class</h1>
      <AssetClassForm data={data?.getAssetClass?.data} />
    </div>
  );
};
