import {Link, Outlet, useMatch, useResolvedPath} from 'react-router-dom';
import styles from './management.module.scss';
import classnames from 'classnames/bind';

export const CustomLink = ({to, name}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({path: resolved.pathname, end: true});
  const cx = classnames.bind(styles);
  return (
    <Link className={cx('nav-link', match ? 'active-toggle' : '')} to={to}>
      <span>{name}</span>
    </Link>
  );
};

const InvestorManagement = () => {
  return (
    <div className={styles['investor-container']}>
      <div className={styles.header}>
        <h1>Management</h1>
      </div>
      <section className={styles.section}>
        <nav className={styles['investor-toggler']}>
          <CustomLink to={'/dashboard/explore'} name="Investor Updates" />
          <CustomLink
            to={'/dashboard/explore/trending-startups'}
            name="Trending Startups"
          />
          <CustomLink
            to={'/dashboard/explore/startup-of-the-week'}
            name="Startup of the Week"
          />
          <CustomLink
            to={'/dashboard/explore/learn-article'}
            name="Learn & FAQ"
          />
          <CustomLink
            to={'/dashboard/explore/trending-countries'}
            name="Trending Countries"
          />
          <CustomLink
            to={'/dashboard/explore/trending-industries'}
            name="Trending Industries"
          />
        </nav>
      </section>
      <section>
        <Outlet />
      </section>
    </div>
  );
};

export default InvestorManagement;
