import {Button} from '@dabafinance/react-components';
import {Form, Input, Select, Spin, Tabs} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import UploadImage from 'components/Startup/profile/companyImage';
import {BigPlayButton, Player} from 'video-react';
import styles from '../../collection.module.scss';
import {supportedLanguages} from 'utils/constants';
import deleteIcon from 'images/delete.svg';
import plusIcon from 'images/plus-circle.png';
import {toast} from 'react-toastify';
import {
  UploadDocumentToBucket,
  UploadVideoToBucket,
} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import {useMutation, useQuery} from '@apollo/client';
import {GET_ALL_TOPICS} from 'graphql/queries/investorUpdates';
import {useEffect, useState} from 'react';
import DBModal from 'components/modal/modal';
import {ViewUserDocument} from 'pages/adminUser/userDetails/tabs/documents/viewDocument';
import {GET_COLLECTION_ICONS} from 'graphql/queries/collections';
import {UPSERT_COLLECTION_ICON} from 'graphql/mutations/collections';

export const GeneralInfo = ({
  firstForm,
  handleFirstFormSubmit,
  previewUrl,
  setPreviewUrl,
  setImgUrl,
  selectedIcon,
  handleTranslation,
  translatedCollections,
  setTranslatedCollections,
  videoUrl,
  setSelectedIcon,
  setVideoUrl,
  progress,
  videoUploadLoading,
  handleUploadVideo,
  loadingTranslation,
  selectedTranslationLanguage,
  setSelectedTranslationLanguage,
}) => {
  const [language, setLanguage] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [topicIndustries, setTopicIndustries] = useState([]);
  const [viewModal, setViewModal] = useState({});
  const [openIconModal, setOpenIconModal] = useState(false);
  const [icons, setIcons] = useState([
    {
      name: '',
      url: '',
    },
  ]);
  const [videoMultilangProgress, setMultilangVideoProgress] = useState(0);
  const [videoUploadMultilangLoading, setVideoUploadMultilangLoading] =
    useState(false);
  const [pdfUploadMultilangLoading, setPdfUploadMultilangLoading] =
    useState(false);
  const [enableChangeTranslation, setEnableChangeTranslation] = useState(true);
  const [editedIcons, setEditedIcons] = useState([]);
  const {Option} = Select;
  const languageToBeTranslatedTo = supportedLanguages.filter(
    key => key.value !== firstForm.getFieldValue('language'),
  );
  const {data: getCollectionIcons, refetch} = useQuery(GET_COLLECTION_ICONS, {
    onError: error => toast.error(error),
  });

  useEffect(() => {
    setEditedIcons(
      getCollectionIcons?.getAllCollectionIcons?.data?.map(item => ({
        ...item,
        selected: false,
      })),
    );
  }, [getCollectionIcons]);

  const {loading: industryLoading} = useQuery(GET_ALL_TOPICS, {
    variables: {
      type: 'INDUSTRY',
      sequence: true,
    },
    onCompleted: data => setTopicIndustries(data?.getAllTopics),
    onError: error => toast.error(error),
  });

  const setMultilangPreview = (url, index) => {
    const newValues = [...translatedCollections];
    newValues[index].previewUrl = url;
    setTranslatedCollections(newValues);
  };

  const setMultilangImageUrl = (url, index) => {
    const newValues = [...translatedCollections];
    newValues[index].imageUrl = url;
    setTranslatedCollections(newValues);
  };

  const handleChangeTranslatedValues = (key, event, name) => {
    let eventName = name;
    let eventValue = name ? event : event.target.value;
    const newFormValues = [...translatedCollections];
    newFormValues[key][eventName] = eventValue;
    setTranslatedCollections(newFormValues);
  };

  const handleMultilangUploadVideo = async (e, index) => {
    const selectedFile = e.target.files[0];
    const newFormValues = [...translatedCollections];
    newFormValues[index].loadingVideoUpload = true;
    setVideoUploadMultilangLoading(true);
    if (selectedFile.type === 'video/mp4') {
      newFormValues[index].loadingVideoUpload = true;
      const response = await UploadVideoToBucket(
        selectedFile,
        'annoucement-images',
        setMultilangVideoProgress,
      );
      newFormValues[index].videoFile = response;
      setVideoUploadMultilangLoading(false);
      newFormValues[index].loadingVideoUpload = false;
      newFormValues[index].videoProgress = 0;
    } else {
      toast.error('Please upload a mp4 video');
    }
  };

  const [upsertCollectionIcons, {loading: upsertingIcons}] = useMutation(
    UPSERT_COLLECTION_ICON,
  );

  const handleIconUpsert = () => {
    const allIcons = [...editedIcons, ...icons];
    upsertCollectionIcons({
      variables: {
        input: allIcons.map(item => ({
          name: item?.name,
          url: item?.url,
        })),
      },
    })
      .then(({data: {upsertCollectionIcons}}) => {
        if (upsertCollectionIcons.__typename === 'Error') {
          toast.error(upsertCollectionIcons.message);
        } else {
          toast.success(upsertCollectionIcons.message);
          setIcons([{url: '', name: ''}]);
          setOpenIconModal(false);
          refetch();
        }
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const handleMultilangPdfFileChange = async (e, index) => {
    const selectedFile = e.target.files[0];
    const fileType = ['application/pdf'];
    const newFormValues = [...translatedCollections];
    newFormValues[index].pdfname = selectedFile.name;
    setPdfUploadMultilangLoading(true);
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        // Upload file to S3 bucket
        newFormValues[index].pdfLoading = true;
        const res = await UploadDocumentToBucket(selectedFile);
        newFormValues[index].pdfReport = res;
        setPdfUploadMultilangLoading(false);
        newFormValues[index].pdfLoading = false;
      } else {
        newFormValues[index].pdfReport = null;
        toast.error('Please select a valid pdf file');
      }
      setTranslatedCollections(newFormValues);
    } else {
      toast.error('Select a PDF file');
    }
  };

  const handleMultilangDeleteVideo = index => {
    const newFormValues = [...translatedCollections];
    newFormValues[index].videoFile = null;
    setTranslatedCollections(newFormValues);
  };

  const handleMultilangDeleteFile = index => {
    const newFormValues = [...translatedCollections];
    newFormValues[index].pdfReport = null;
    newFormValues[index].pdfname = 'Supported formats pdf, docs';
    newFormValues[index].viewPdf = null;
    setTranslatedCollections(newFormValues);
  };

  const handleAddIcon = () => {
    setIcons([...icons, {name: '', url: ''}]);
  };
  const onIconDelete = id => {
    const filteredFormValue = icons.filter((item, index) => index !== id);
    setIcons(filteredFormValue);
  };
  const handleIconChange = (i, name, e) => {
    const newFormValues = [...icons];
    newFormValues[i][name] = e;
    setIcons(newFormValues);
  };

  return (
    <div>
      <Form
        form={firstForm}
        className={styles.form}
        layout="vertical"
        onFinish={handleFirstFormSubmit}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'base language is required',
            },
          ]}
          label="Language"
          className={styles.field}
          name="language">
          <Select
            className={styles.form}
            value={language}
            onChange={e => setLanguage(e)}>
            {supportedLanguages.map(data => (
              <Option key={data.value}>{data.label}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Language Translation"
          className={styles.field}
          name="translation">
          <Select
            className={styles.form}
            value={selectedTranslationLanguage}
            onChange={e => setSelectedTranslationLanguage(e)}>
            {languageToBeTranslatedTo.map(data => (
              <Option key={data.value}>{data.label}</Option>
            ))}
          </Select>
        </Form.Item>
        <span className={styles.note}>
          {previewUrl ? '' : 'Banner is required'} *Recommended size is 327px X
          158px*
        </span>
        <UploadImage
          previewUrl={previewUrl}
          setPreviewUrl={setPreviewUrl}
          setImgUrl={setImgUrl}
          large
          bucket="annoucement-images"
        />
        <Form.Item
          name="radio-group"
          label={`Choose collection icon '(Icon is required)`}>
          <div className={styles.iconsWrapper}>
            <div className={styles.icons}>
              {editedIcons?.map(item => (
                <div
                  key={item?.url}
                  className={
                    item?.url == selectedIcon ? styles.selected : styles.icon
                  }
                  onClick={() => {
                    setSelectedIcon(item?.url);
                  }}>
                  <img src={item?.url} alt="icon" />
                </div>
              ))}
            </div>
            <Button label="Add Icon" onClick={() => setOpenIconModal(true)} />
          </div>
        </Form.Item>
        <Tabs
          className={styles.tabs}
          tabBarExtraContent={
            selectedTranslationLanguage && (
              <Button
                disabled={
                  !firstForm.getFieldValue('collectionName') ||
                  !firstForm.getFieldValue('collectionDescription') ||
                  loadingTranslation
                }
                label="Translate"
                onClick={() =>
                  handleTranslation(
                    firstForm.getFieldValue('collectionName'),
                    firstForm.getFieldValue('collectionDescription'),
                    firstForm.getFieldValue('language'),
                  )
                }
              />
            )
          }>
          <Tabs.TabPane tab="Original" key="Original">
            <Form.Item
              name="collectionName"
              label="Collection Name"
              className={styles.field}
              rules={[
                {
                  required: true,
                  message: 'collection name is required',
                },
              ]}>
              <Input
                value={name}
                onChange={e => setName(e.target.value)}
                className={styles.form}
                name="collectionName"
                placeholder="input collection name"
              />
            </Form.Item>
            <Form.Item
              name="collectionDescription"
              label="Collection Description"
              className={styles.field}
              rules={[
                {
                  required: true,
                  message: 'collection description is required',
                },
              ]}>
              <Input.TextArea
                value={description}
                rows={5}
                onChange={e => setDescription(e.target.value)}
                className={styles.form}
                name="collectionDescription"
                placeholder="input collection description"
              />
            </Form.Item>
            <Form.Item
              name="industry"
              label="Industry"
              className={styles.field}
              rules={[
                {
                  required: true,
                  message: 'industry is required',
                },
              ]}>
              <Select
                className={styles.form}
                loading={industryLoading}
                name="industry"
                placeholder="Select Industry"
                showSearch
                optionFilterProp="children">
                {topicIndustries?.map((item, index) => (
                  <Select.Option value={item.id} key={index}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <div className={styles['video-upload']}>
              {videoUrl ? (
                <div>
                  <Player fluid={false} width={730} src={videoUrl}>
                    <BigPlayButton position="center" />
                  </Player>
                  <div>
                    <Button
                      className={styles['delete-button']}
                      label="Delete Video"
                      onClick={() => setVideoUrl('')}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <label className={styles.upload} htmlFor="file-upload">
                    {videoUploadLoading ? (
                      <div className={styles['upload-file']}>
                        <Spin />
                        <h2>Uploading Please Wait...</h2>
                        <span>{progress}%</span>
                      </div>
                    ) : (
                      <h2>Upload Video</h2>
                    )}
                    <input
                      type="file"
                      id="file-upload"
                      accept="video/*"
                      className={styles['input-file']}
                      onChange={handleUploadVideo}
                    />
                  </label>
                </div>
              )}
            </div>
          </Tabs.TabPane>
          {translatedCollections.length &&
            translatedCollections.map((data, index) => (
              <Tabs.TabPane
                key={data.language}
                tab={
                  supportedLanguages.find(lang => lang.value === data.language)
                    .label
                }>
                <div className={styles.updateButton}>
                  <Button
                    onClick={() =>
                      setEnableChangeTranslation(!enableChangeTranslation)
                    }
                    type="secondary"
                    label={
                      enableChangeTranslation
                        ? 'Edit Translation'
                        : 'Disable Translation'
                    }
                  />
                </div>
                <div key={index}>
                  <span className={styles.note}>
                    *Recommended size is 327px X 158px*
                  </span>
                  <UploadImage
                    previewUrl={data.previewUrl}
                    setPreviewUrl={e => setMultilangPreview(e, index)}
                    setImgUrl={e => setMultilangImageUrl(e, index)}
                    large
                    bucket="annoucement-images"
                  />
                  <Form.Item label="Collection Name" className={styles.field}>
                    <Input
                      className={styles.form}
                      value={data.name}
                      disabled={enableChangeTranslation}
                      onChange={e =>
                        handleChangeTranslatedValues(
                          index,
                          e.target.value,
                          'name',
                        )
                      }
                      placeholder={'name'}
                    />
                  </Form.Item>
                  <Form.Item label="Description" className={styles.field}>
                    <Input.TextArea
                      rows={5}
                      className={styles.form}
                      value={data.description}
                      disabled={enableChangeTranslation}
                      onChange={e =>
                        handleChangeTranslatedValues(
                          index,
                          e.target.value,
                          'description',
                        )
                      }
                    />
                  </Form.Item>
                  <div className={styles['video-upload']}>
                    {data.videoFile ? (
                      <div>
                        <Player fluid={false} width={730} src={data.videoFile}>
                          <BigPlayButton position="center" />
                        </Player>
                        <div>
                          <Button
                            className={styles['delete-button']}
                            label="Delete Video"
                            onClick={() => handleMultilangDeleteVideo(index)}
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <label
                          className={styles.upload}
                          htmlFor="multilang-file-upload">
                          {videoUploadMultilangLoading ? (
                            <div className={styles['upload-file']}>
                              <Spin />
                              <h2>Uploading Please Wait...</h2>
                              <span>{videoMultilangProgress}%</span>
                            </div>
                          ) : (
                            <h2>Upload Multilang Video</h2>
                          )}
                          <input
                            type="file"
                            id="multilang-file-upload"
                            accept="video/*"
                            className={styles['input-file']}
                            onChange={e => handleMultilangUploadVideo(e, index)}
                          />
                        </label>
                      </div>
                    )}
                  </div>

                  <h1 className={styles['pdf-title']}>Full Report PDF</h1>
                  <div className={styles.button}>
                    {data.pdfReport && (
                      <Button
                        label="Preview Pdf"
                        onClick={() =>
                          setViewModal({
                            isOpen: true,
                            documentUrl: data?.pdfReport,
                            documentName: data?.pdfname,
                          })
                        }
                      />
                    )}
                  </div>
                  <div className={styles.button}>
                    {data.pdfReport && (
                      <Button
                        label="Delete Pdf"
                        onClick={() => handleMultilangDeleteFile(index)}
                      />
                    )}
                  </div>
                  {!data.viewPdf && (
                    <form>
                      <label
                        className={styles.upload}
                        htmlFor="file-pdf-upload">
                        {pdfUploadMultilangLoading ? (
                          <Spin size="large" />
                        ) : (
                          <span>{data.pdfname || 'Upload multilang pdf'}</span>
                        )}
                        <input
                          type="file"
                          id="file-pdf-upload"
                          accept="application/pdf"
                          className={styles['input-file']}
                          required
                          onChange={e => handleMultilangPdfFileChange(e, index)}
                        />
                      </label>
                    </form>
                  )}
                </div>
              </Tabs.TabPane>
            ))}
        </Tabs>
        <div className={styles.buttons}>
          <Button label="Cancel" type="secondary" />
          <SubmitButton label="Next" />
        </div>
      </Form>

      {/* Document modal */}
      <DBModal
        isOpen={viewModal.isOpen}
        handleClose={() => setViewModal({isOpen: false})}
        width={900}
        content={
          <ViewUserDocument
            url={viewModal?.documentUrl}
            documentName={viewModal?.documentName}
          />
        }
      />

      {/* Icons modal */}
      <DBModal
        isOpen={openIconModal}
        handleClose={() => setOpenIconModal(false)}
        width={600}
        content={
          <div>
            <h2>Add Icons</h2>
            <Form
              layout="vertical"
              onFinish={handleIconUpsert}
              className={styles.iconForm}>
              {icons?.map((item, index) => (
                <div key={index} className={styles.content}>
                  <div>
                    <span className={styles.note}>
                      {previewUrl ? '' : 'Icon is required'} *Recommended size
                      is 32px X 32px*
                    </span>
                    <UploadImage
                      previewUrl={item.url}
                      setPreviewUrl={e => handleIconChange(index, 'url', e)}
                      setImgUrl={e => handleIconChange(index, 'url', e)}
                      bucket="annoucement-images"
                      icon
                      label="upload icon"
                    />
                    <Form.Item
                      label="Icon Name"
                      className={styles.fieldWidth}
                      rules={[
                        {
                          required: true,
                          message: 'icon name is required',
                        },
                      ]}>
                      <Input
                        value={item.name}
                        onChange={e =>
                          handleIconChange(index, 'name', e.target.value)
                        }
                        className={styles.form}
                        placeholder="input icon name"
                      />
                    </Form.Item>
                  </div>
                  {icons.length > 1 && (
                    <img
                      onClick={() => onIconDelete(index)}
                      src={deleteIcon}
                      className={styles.deleteIcon}
                      alt=""
                    />
                  )}
                </div>
              ))}
              <div onClick={handleAddIcon} className={styles['add-button']}>
                <img src={plusIcon} alt="" />
                <h1>Add Icon</h1>
              </div>
              <SubmitButton
                label={'Add'}
                disabled={(!icons[0].url && !icons[0].name) || upsertingIcons}
              />
            </Form>
          </div>
        }
      />
    </div>
  );
};
