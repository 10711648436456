import {useState} from 'react';
import styles from './otpInput.module.scss';

export default function OtpInput({onChange, handleKeyDown, inputRef, ...rest}) {
  const [value, setValue] = useState('');

  const handleChange = event => {
    const inputValue = event.target.value;
    setValue(inputValue);
    if (onChange) {
      onChange(inputValue);
    }
  };

  return (
    <div className={styles['otp-text-input-container']}>
      <input
        ref={inputRef}
        maxLength={1}
        value={value}
        onChange={handleChange}
        // onFocus={handleFocus}
        onKeyDownCapture={handleKeyDown}
        {...rest}
      />
    </div>
  );
}
