import {useEffect, useState} from 'react';
import styles from './campaignOverview.module.scss';
import {useParams} from 'react-router-dom';
import ReactQuill from 'react-quill';
import {Form, Input, Select, Spin} from 'antd';
import deleteIcon from 'images/delete.svg';
import plusIcon from 'images/plus-circle.png';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useMutation, useQuery} from '@apollo/client';
import {GET_ALL_TOPICS} from 'graphql/queries/investorUpdates';
import {toast} from 'react-toastify';
import FadeEffect from 'components/animation/fadeEffect';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {UploadVideoToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import {Button} from '@dabafinance/react-components';
import {BigPlayButton, Player} from 'video-react';
import UploadImage from 'components/Startup/profile/companyImage';

const UpdateCampaignOverview = () => {
  const [aboutCompany, setAboutCompany] = useState('');
  const [category, setCategory] = useState([]);
  const [progress, setProgress] = useState(0);
  const [missions, setMissions] = useState('');
  const [audioProgress, setAudioProgress] = useState(0);
  const [opportunityThumbnail, setOpportunityThumbnail] = useState('');
  const [opportunityThumbnailPreview, setOpportunityThumbnailPreview] =
    useState('');
  const [audioFile, setAudioFile] = useState('');
  const [loadingAudioUpload, setLoadingAudioUpload] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [loadingVideoUpload, setLoadingVideoUpload] = useState(false);
  const [disclaimers, setDisclaimers] = useState('');
  const [offer, setOffer] = useState('');
  const [solutions, setSolutions] = useState('');
  const {id, language} = useParams();
  const [keyFactsValue, setKeyFactsValue] = useState([]);
  const [milestonesValue, setMilestonesValue] = useState([]);
  const [addtionalDisclosures, setAdditionalDisclosures] = useState([]);
  const width = 700;
  const {TextArea} = Input;
  const {Option} = Select;

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
    onError: error => toast.error(error),
    errorPolicy: 'all',
  });
  const {data, loadingTopics} = useQuery(GET_ALL_TOPICS);
  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (campaignData?.getCampaignById) {
      setAboutCompany(campaignData?.getCampaignById?.aboutCompany);
      setMissions(campaignData?.getCampaignById?.missionAndVission);
      setDisclaimers(campaignData?.getCampaignById?.disclosure);
      setSolutions(campaignData?.getCampaignById?.solutions);
      setAudioFile(campaignData?.getCampaignById?.opportunityVoiceRecording);
      setVideoUrl(campaignData?.getCampaignById?.opportunityDemoVideo);
      setOpportunityThumbnail(
        campaignData?.getCampaignById?.opportunityDemoVideoThumbnail,
      );
      setOpportunityThumbnailPreview(
        campaignData?.getCampaignById?.opportunityDemoVideoThumbnail,
      );
      setCategory(
        campaignData?.getCampaignById?.relatedTopics?.map(
          data => `${data?.name};${data?.id}`,
        ),
      );
      setOffer(campaignData?.getCampaignById?.whatWeOffer);
      setKeyFactsValue(
        campaignData?.getCampaignById?.keyFacts?.map(data => ({
          name: data?.name,
          value: data?.value,
        })),
      );
      setAdditionalDisclosures(
        campaignData?.getCampaignById?.additionalDisclosures?.map(data => ({
          name: data?.name,
          value: data?.value,
        })),
      );
      setMilestonesValue(
        campaignData?.getCampaignById?.keyMilestones?.map(data => ({
          name: data?.name,
          value: data?.value,
        })),
      );
    }
  }, [campaignData]);

  const handleAddKeyFacts = () => {
    setKeyFactsValue([...keyFactsValue, {name: '', value: ''}]);
  };
  const onKeyFactsDelete = id => {
    const filteredFormValue = keyFactsValue.filter(
      (item, index) => index !== id,
    );
    setKeyFactsValue(filteredFormValue);
  };
  const handleChangeKeyFactsValue = (i, e) => {
    const newFormValues = [...keyFactsValue];
    newFormValues[i][e.target.name] = e.target.value;
    setKeyFactsValue(newFormValues);
  };

  const handleAddMilestones = () => {
    setMilestonesValue([...milestonesValue, {name: '', value: ''}]);
  };

  const handleChangeMilestonesValue = (i, e) => {
    const newFormValues = [...milestonesValue];
    newFormValues[i][e.target.name] = e.target.value;
    setMilestonesValue(newFormValues);
  };

  const onMilestonesDelete = id => {
    const filteredFormValue = milestonesValue.filter(
      (item, index) => index !== id,
    );
    setMilestonesValue(filteredFormValue);
  };

  const handleAddDisclosures = () => {
    setAdditionalDisclosures([...addtionalDisclosures, {name: '', value: ''}]);
  };

  const handleChangeDisclosures = (i, e) => {
    const newFormValues = [...addtionalDisclosures];
    newFormValues[i][e.target.name] = e.target.value;
    setAdditionalDisclosures(newFormValues);
  };

  const handleDeleteDisclosures = id => {
    const filteredFormValue = addtionalDisclosures.filter(
      (item, index) => index !== id,
    );
    setAdditionalDisclosures(filteredFormValue);
  };

  const handleUploadAudio = async e => {
    const selectedFile = e.target.files[0];
    setLoadingAudioUpload(true);
    const response = await UploadVideoToBucket(
      selectedFile,
      'campaign-bucket-images',
      setAudioProgress,
    );
    setAudioFile(response);
    setLoadingAudioUpload(false);
  };

  const handleUploadVideo = async e => {
    const selectedFile = e.target.files[0];
    if (selectedFile.type === 'video/mp4') {
      setLoadingVideoUpload(true);
      const response = await UploadVideoToBucket(
        selectedFile,
        'campaign-bucket-images',
        setProgress,
      );
      setVideoUrl(response);
      setLoadingVideoUpload(false);
      setProgress(0);
    } else {
      toast.error('Please upload a mp4 video');
    }
  };

  const handleUpdate = () => {
    const optionalFields = {};
    if (audioFile) optionalFields.opportunityVoiceRecording = audioFile;
    // if (videoUrl) {
    //   optionalFields.opportunityDemoVideo = videoUrl;
    //   optionalFields.opportunityDemoVideoThumbnail = opportunityThumbnail;
    // }

    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          aboutCompany,
          relatedTopics: category?.map(topic => topic.split(';')[1]),
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          solutions,
          opportunityDemoVideo: videoUrl,
          opportunityDemoVideoThumbnail: opportunityThumbnail,
          missionAndVission: `${missions?.trim()}`,
          whatWeOffer: `${offer.trim()}`,
          disclosure: `${disclaimers}`,
          additionalDisclosures: addtionalDisclosures
            ? addtionalDisclosures?.map(data => ({
                name: data?.name,
                value: data?.value,
              }))
            : [],
          keyFacts: keyFactsValue?.map(data => ({
            name: data?.name,
            value: data?.value,
          })),

          keyMilestones: milestonesValue.map(data => ({
            name: data?.name,
            value: data?.value,
          })),
          ...optionalFields,
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error(updateCampaign.message);
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading || loadingTopics) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div>
        <h1 className={styles['overview-header']}>Overview</h1>
        <div>
          <h2 className={styles['upload-title']}>
            Why is this opportunity listed?” - Voice Recording
          </h2>
          {audioFile ? (
            <div className={styles['audio-file']}>
              <audio controls src={audioFile} />
              <Button label="Delete File" onClick={() => setAudioFile('')} />
            </div>
          ) : (
            <div>
              <label className={styles.upload} htmlFor="file-upload">
                {loadingAudioUpload ? (
                  <div className={styles.uploading}>
                    <Spin />
                    <h3>Uploading, Please wait...</h3>
                    <span>{audioProgress}%</span>
                  </div>
                ) : (
                  <h2>Upload Audio or Recording</h2>
                )}
                <input
                  type="file"
                  id="file-upload"
                  accept="audio/mpeg"
                  className={styles['input-file']}
                  required
                  onChange={handleUploadAudio}
                />
              </label>
            </div>
          )}
        </div>
        <div>
          <h2 className={styles['upload-title']}>Opportunity Demo - Video</h2>
          {videoUrl ? (
            <div>
              <Player fluid={false} width={550} src={videoUrl}>
                <BigPlayButton position="center" />
              </Player>
              <div>
                <Button
                  className={styles['delete-button']}
                  label="Delete Video"
                  onClick={() => setVideoUrl(null)}
                />
              </div>
            </div>
          ) : (
            <div>
              <label className={styles['upload-video']} htmlFor="video-upload">
                {loadingVideoUpload ? (
                  <div className={styles['upload-file']}>
                    <Spin />
                    <h2>Uploading Please Wait...</h2>
                    <span>{progress}%</span>
                  </div>
                ) : (
                  <h2>Upload Opportunity Demo</h2>
                )}
                <input
                  type="file"
                  id="video-upload"
                  className={styles['input-file']}
                  required
                  accept="video/mp4"
                  onChange={handleUploadVideo}
                />
              </label>
            </div>
          )}
          <div>
            <h2 className={styles['upload-title']}>
              Opportunity Demo - Thumbnail
            </h2>

            <UploadImage
              thumbnail
              setImgUrl={setOpportunityThumbnail}
              previewUrl={opportunityThumbnailPreview}
              setPreviewUrl={setOpportunityThumbnailPreview}
              bucket="campaign-bucket-images"
            />
          </div>
        </div>
        <Form layout="vertical">
          <Form.Item label="About Company" style={{width}} name="about">
            <TextArea
              value={aboutCompany}
              onChange={e => setAboutCompany(e.target.value)}
              rows={6}
              defaultValue={campaignData?.getCampaignById?.aboutCompany}
              placeholder="About Company"
            />
          </Form.Item>
          <Form.Item style={{width}} name="category" label="Category">
            <Select
              defaultValue={campaignData?.getCampaignById?.relatedTopics?.map(
                data => `${data?.name};${data?.id}`,
              )}
              value={campaignData}
              mode="multiple"
              placeholder="Select a Category"
              onChange={e => setCategory(e)}>
              {data?.getAllTopics?.map(topic => (
                <Option key={`${topic?.name};${topic?.id}`}>
                  <div className={styles['all-data']}>
                    <img src={topic?.icon} alt="" />
                    <span>{topic?.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div>
            <h3 className={styles.title}>Key Facts</h3>
            {keyFactsValue?.map((name, index) => (
              // eslint-disable-next-line react/jsx-key
              <FadeEffect>
                <div className={styles['key-facts']} key={index}>
                  <div>
                    <Form.Item label="Name of Fact" style={{width}}>
                      <Input
                        name="name"
                        value={name.name || ''}
                        placeholder="Title of Facts"
                        onChange={e => handleChangeKeyFactsValue(index, e)}
                      />
                    </Form.Item>
                    <Form.Item style={{width}}>
                      <Input.TextArea
                        name="value"
                        value={name.value || ''}
                        rows={4}
                        placeholder="body"
                        onChange={e => handleChangeKeyFactsValue(index, e)}
                      />
                    </Form.Item>
                    {(!name.value || !name.name) && (
                      <span className={styles['warning']}>
                        Please note both fields are required
                      </span>
                    )}
                  </div>
                  <img
                    onClick={() => onKeyFactsDelete(index)}
                    src={deleteIcon}
                    alt=""
                  />
                </div>
              </FadeEffect>
            ))}
            <div onClick={handleAddKeyFacts} className={styles['add-button']}>
              <img src={plusIcon} alt="" />
              <h1>Add Facts</h1>
            </div>
          </div>
          <div className={styles.mission}>
            <h3 className={styles.title}>Mission &#38; Vision</h3>
            <ReactQuill
              value={missions}
              theme="snow"
              onChange={e => setMissions(e)}
              style={{width, height: 200, position: 'relative'}}
              modules={UpdateCampaignOverview.modules}
              formats={UpdateCampaignOverview.format}
            />
          </div>
          <div style={{marginTop: 80}}>
            <h3 className={styles.title}>What we offer</h3>
            <ReactQuill
              style={{width, height: 200, position: 'relative'}}
              value={offer}
              theme="snow"
              onChange={el => setOffer(el)}
              modules={UpdateCampaignOverview.modules}
              formats={UpdateCampaignOverview.format}
            />
          </div>
          <div style={{marginTop: 80}}>
            <h3 className={styles.title}>Solutions</h3>
            <ReactQuill
              style={{width, height: 200, position: 'relative'}}
              value={solutions}
              theme="snow"
              onChange={el => setSolutions(el)}
              modules={UpdateCampaignOverview.modules}
              formats={UpdateCampaignOverview.format}
            />
          </div>
          <div style={{marginTop: 80}}>
            <h3 className={styles.title}>Disclosure</h3>
            <ReactQuill
              style={{width, height: 200, position: 'relative'}}
              value={disclaimers}
              theme="snow"
              onChange={el => setDisclaimers(el)}
              modules={UpdateCampaignOverview.modules}
              formats={UpdateCampaignOverview.format}
            />
          </div>
          <div style={{marginTop: 80}}>
            <h3>Key Milestones</h3>
            {milestonesValue?.map((name, index) => (
              // eslint-disable-next-line react/jsx-key
              <FadeEffect>
                <div className={styles['key-facts']} key={index}>
                  <div>
                    <Form.Item label="Milestone Title" style={{width}}>
                      <Input
                        name="name"
                        placeholder="Add a milestone"
                        value={name.name}
                        onChange={e => handleChangeMilestonesValue(index, e)}
                      />
                    </Form.Item>
                    <Form.Item style={{width}}>
                      <Input.TextArea
                        name="value"
                        rows={4}
                        placeholder="Body"
                        value={name.value}
                        onChange={e => handleChangeMilestonesValue(index, e)}
                      />
                    </Form.Item>
                    {(!name.value || !name.name) && (
                      <span className={styles['warning']}>
                        Please note both fields are required
                      </span>
                    )}
                  </div>
                  {
                    <img
                      onClick={() => onMilestonesDelete(index)}
                      src={deleteIcon}
                      alt=""
                    />
                  }
                </div>
              </FadeEffect>
            ))}

            <div onClick={handleAddMilestones} className={styles['add-button']}>
              <img src={plusIcon} alt="" />
              <h1>Add Milestones</h1>
            </div>
          </div>
          <div style={{marginTop: 80}}>
            <h3>Additional Disclosures</h3>
            {addtionalDisclosures?.map((name, index) => (
              // eslint-disable-next-line react/jsx-key
              <FadeEffect>
                <div className={styles['key-facts']} key={index}>
                  <div>
                    <Form.Item label="Disclosure Title" style={{width}}>
                      <Input
                        name="name"
                        placeholder="Add a Disclosure title"
                        value={name.name}
                        onChange={e => handleChangeDisclosures(index, e)}
                      />
                    </Form.Item>
                    <Form.Item style={{width}}>
                      <Input.TextArea
                        name="value"
                        rows={4}
                        placeholder="Body"
                        value={name.value}
                        onChange={e => handleChangeDisclosures(index, e)}
                      />
                    </Form.Item>
                    {(!name.value || !name.name) && (
                      <span className={styles['warning']}>
                        Please note both fields are required
                      </span>
                    )}
                  </div>
                  {
                    <img
                      onClick={() => handleDeleteDisclosures(index)}
                      src={deleteIcon}
                      alt=""
                    />
                  }
                </div>
              </FadeEffect>
            ))}
            <div
              onClick={handleAddDisclosures}
              className={styles['add-button']}>
              <img src={plusIcon} alt="" />
              <h1>Add Disclosure</h1>
            </div>
          </div>
          <div style={{margin: 15}}>
            <SubmitButton handleClick={handleUpdate} label="Update" />
          </div>
        </Form>
      </div>
    </div>
  );
};

UpdateCampaignOverview.modules = {
  toolbar: [
    [{header: '1'}, {header: '2'}, {header: [3, 4, 5, 6]}],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike'],
    [{list: 'ordered'}, {list: 'bullet'}],
    ['link', 'image'],

    ['code-block'],
  ],
};

UpdateCampaignOverview.format = [
  'header',
  'font',
  'bold',
  'italic',
  'underline',
  'blockquote',
  'list',
  'bullet',
  'image',
  'link',
  'code-block',
];

export default UpdateCampaignOverview;
