import {Button} from '@dabafinance/react-components';
import {Form, Input} from 'antd';
import {useState} from 'react';
import backIcon from 'images/back.png';
import styles from './companySocials.module.scss';
import {useMutation} from '@apollo/client';
import {CREATE_SOCIAL_MEDIA} from 'graphql/mutations/companyStartups';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

const CompanySocials = ({setActivePage}) => {
  const [twitterUrl, setTwitterUrl] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [linkedInUrl, setLinkedinUrl] = useState('');

  const {id} = useParams();
  const [createSocialMedia, {loading}] = useMutation(CREATE_SOCIAL_MEDIA);

  const handleSave = () => {
    if (!twitterUrl && !linkedInUrl && !facebookUrl) {
      return setActivePage('MEMBERS');
    }
    const newObj = {};
    if (twitterUrl) newObj.twitterUrl = twitterUrl;
    if (facebookUrl) newObj.facebookUrl = facebookUrl;
    if (linkedInUrl) newObj.linkedInUrl = linkedInUrl;
    createSocialMedia({
      variables: {
        input: {
          companyId: id,
          ...newObj,
        },
      },
    }).then(({data: {createSocialMedia}}) => {
      if (createSocialMedia.__typename === 'Error') {
        toast.error(createSocialMedia.message);
      } else {
        toast.success('Updated Successfully');
        setActivePage('MEMBERS');
      }
    });
  };

  return (
    <div className={styles['social-container']}>
      <div className={styles.header}>
        <div className={styles.nav}>
          <img onClick={() => setActivePage('STOCKS')} src={backIcon} alt="" />
          <h1>Social Media</h1>
        </div>
        <h1>Step 4/5</h1>
      </div>
      <Form layout="vertical">
        <div className={styles['form-inputs']}>
          <Form.Item
            label="Twitter"
            className={styles['form-item']}
            name="twitter"
            rules={[
              {
                type: 'url',
                message: 'Please Input a Valid Social Link',
              },
            ]}>
            <Input
              onChange={e => setTwitterUrl(e.target.value)}
              style={{width: 340}}
              placeholder="https://twitter.com"
              value={twitterUrl}
            />
          </Form.Item>
          <Form.Item
            label="Facebook"
            className={styles['form-item']}
            name="facebook"
            rules={[
              {
                type: 'url',
                message: 'Please Input a Valid Social Link',
              },
            ]}>
            <Input
              onChange={e => setFacebookUrl(e.target.value)}
              style={{width: 340}}
              value={facebookUrl}
              placeholder="https://facebook.com"
            />
          </Form.Item>
          <Form.Item
            label="Linkedin"
            className={styles['form-item']}
            name="linkedin"
            rules={[
              {
                type: 'url',
                message: 'Please Input a Valid Social Link',
              },
            ]}>
            <Input
              onChange={e => setLinkedinUrl(e.target.value)}
              style={{width: 340}}
              value={linkedInUrl}
              placeholder="https://linkedin.com"
            />
          </Form.Item>
        </div>
      </Form>
      <div className={styles.footer}>
        <Button
          className={styles['button-style']}
          type="secondary"
          label="Skip to Next Step"
          onClick={() => setActivePage('MEMBERS')}
        />
        <Button
          onClick={handleSave}
          className={styles['button-style']}
          disabled={loading}
          label="Save and Proceed"
        />
      </div>
    </div>
  );
};

export default CompanySocials;
