import {useState, useEffect} from 'react';
import styles from './report.module.scss';
import {Form, Input, Select, Spin, Switch} from 'antd';
import ReportAccordion from 'components/reportAccordion';
import PlusIcon from 'images/plus_primary.svg';
import ReportPDF from 'components/reportPDF';
import FadeEffect from 'components/animation/fadeEffect';
import {MinusIcon} from 'icons';
import {PlusOutlined} from '@ant-design/icons';
import DBModal from 'components/modal/modal';
import ReportTagsList from 'components/reportTagsList';
import {useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {
  GET_ALL_COMPANY,
  GET_ALL_REPORT,
  GET_REPORT,
} from 'graphql/queries/report';
import {UPDATE_REPORT} from 'graphql/mutations/report';
import {toast} from 'react-toastify';
import {Option} from 'antd/lib/mentions';
import UploadImage from 'components/Startup/profile/companyImage';
import {GET_ALL_TOPICS} from 'graphql/queries/interest';
import SubmitButton from 'components/Startup/components/SubmitButton';

const Report = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [relatedTopic, setRelatedTopic] = useState([]);
  const [relatedIndustry, setRelatedIndustry] = useState('');
  const [relatedCompanies, setRelatedCompanies] = useState([]);
  const [pdfLink, setPdfLink] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [coverPreview, setCoverPreview] = useState('');
  const [pdf, setPdf] = useState('');
  const [type, setType] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [formValues, setFormValues] = useState([]);
  const [useExternalLink, setUseExternalLink] = useState(false);
  const [openTagsModal, setOpenTagsModal] = useState(false);

  const addTopic = item => {
    const exist = relatedTopic.find(input => input.id === item.id);
    if (!exist) {
      const {icon, id, name, type} = item;
      setRelatedTopic([...relatedTopic, {icon, id, type, name, isAdded: true}]);
    }
  };

  const {data, loading} = useQuery(GET_REPORT, {
    variables: {
      getReportId: id,
    },
  });
  const {data: getAllCompany} = useQuery(GET_ALL_COMPANY);

  const {data: getAllIndustries} = useQuery(GET_ALL_TOPICS, {
    variables: {
      type: 'INDUSTRY',
    },
  });

  const [updateReport, {loading: loadingUpdate}] = useMutation(UPDATE_REPORT);

  useEffect(() => {
    if (data) {
      setTitle(data?.getReport?.title);
      setAuthor(data?.getReport?.createdBy);
      setRelatedTopic([
        ...data?.getReport?.relatedTopics,
        ...data?.getReport?.relatedCountries,
      ]);
      setPdf(data?.getReport?.document);
      setPdfLink(data?.getReport?.document);
      setCoverPreview(data?.getReport?.imageUrl);
      setImgUrl(data?.getReport?.imageUrl);
      setType(data?.getReport?.type);
      setRelatedIndustry(data?.getReport?.relatedIndustry?.id);
      setFormValues(
        data?.getReport?.sections?.map(value => ({
          sectionName: value.sectionName,
          content: value.content,
          sequence: value.sequence,
        })),
      );
      setKeywords(data?.getReport?.keywords);
      setRelatedCompanies(
        data?.getReport?.relatedCompanies?.map(
          data => `${data?.name};${data?.id}`,
        ),
      );
      setUseExternalLink(data?.getReport?.linkType === 'EXTERNAL_LINK');
    }
  }, [data]);

  const handleRemoveTag = id => {
    const filteredTags = relatedTopic.filter(tag => tag.id !== id);
    setRelatedTopic(filteredTags);
  };

  const handleDeleteSection = id => {
    const filteredFormValues = formValues.filter((item, index) => index !== id);
    setFormValues(filteredFormValues);
  };

  const handleChange = (i, e) => {
    const index = i - 1;
    const newFormValues = [...formValues];
    newFormValues[index].sectionName = e.target.value;
    setFormValues(newFormValues);
  };

  const handleEditorContent = (i, e) => {
    const index = i - 1;
    const newFormValues = [...formValues];
    newFormValues[index].content = e;
    setFormValues(formValues, newFormValues[index].content);
  };

  //

  const addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        sectionName: 'Enter Section Title',
        sequence: formValues.length + 1,
        content: '',
      },
    ]);
  };
  const onSubmit = () => {
    updateReport({
      variables: {
        data: {
          reportId: id,
          title,
          createdBy: author,
          sections: formValues?.map((data, index) => ({
            sectionName: data?.sectionName,
            sequence: index + 1,
            content: data?.content,
          })),
          document: pdfLink,
          imageUrl: imgUrl,
          keywords,
          relatedTopics: relatedTopic
            .filter(data => data.type === 'INDUSTRY')
            .map(data => data.id),
          relatedCountries: relatedTopic
            .filter(data => data.type === 'COUNTRY')
            .map(data => data.id),
          relatedIndustry: relatedIndustry.split(';')[1],
          type,
          relatedCompanies: relatedCompanies?.map(data => data.split(';')[1]),
        },
      },
      refetchQueries: [{query: GET_ALL_REPORT}],
    }).then(({data: {updateReport}}) => {
      if (updateReport.__typename === 'Error') {
        toast.error(updateReport.message);
      } else {
        toast.success('Updated Successfully');
        navigate('/dashboard/report');
      }
    });
  };

  return loading ? (
    <div className={styles.loading}>
      <Spin size="large" />
      <h1>Loading...</h1>
    </div>
  ) : (
    <>
      <div className={styles.container}>
        <h2 className={styles['heading-text']}>Report</h2>
        <div className={styles['report-tags']}>
          {relatedTopic?.map(({icon, id}) => (
            <div key={id} className={styles['tag-icon']}>
              <img alt="" src={icon} />
              <button onClick={() => handleRemoveTag(id)}>
                <MinusIcon />
              </button>
            </div>
          ))}
          <button
            className={styles['add-tag-button']}
            onClick={() => setOpenTagsModal(true)}>
            <PlusOutlined />
          </button>
        </div>
        <div className={styles['cover-image']}>
          <UploadImage
            setImgUrl={setImgUrl}
            previewUrl={coverPreview}
            setPreviewUrl={setCoverPreview}
            bucket="reports-documents-bucket"
          />
        </div>
        <Form className={styles['form-style']} layout="vertical">
          <Form.Item label="Industry" name="relatedIndustry">
            <Select
              value={relatedIndustry}
              defaultValue={`${data?.getReport?.relatedIndustry?.name};${data?.getReport?.relatedIndustry?.id}`}
              onChange={e => setRelatedIndustry(e)}
              showSearch>
              {getAllIndustries?.getAllTopics?.map(({id, name, icon}) => (
                // eslint-disable-next-line react/jsx-key
                <Option value={`${name};${id}`}>
                  <div className={styles['all-data']}>
                    <img src={icon} alt="" />
                    <span>{name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Title" name="title">
            <Input
              defaultValue={data?.getReport?.title}
              value={title}
              onChange={e => setTitle(e.target.value)}
              required
            />
          </Form.Item>
          <Form.Item label="Created by" name="author">
            <Input
              defaultValue={data?.getReport?.createdBy}
              value={author}
              onChange={e => setAuthor(e.target.value)}
              required
            />
          </Form.Item>
          <Form.Item label="Keywords" name="keywords">
            <Select
              mode="tags"
              value={keywords}
              defaultValue={data?.getReport?.keywords}
              onChange={e => setKeywords(e)}
              tokenSeparators={[',']}>
              {keywords}
            </Select>
          </Form.Item>
          <Form.Item label="Related Companies" name="relatedCompanies">
            <Select
              value={relatedCompanies}
              onChange={e => setRelatedCompanies(e)}
              showSearch
              defaultValue={data?.getReport?.relatedCompanies.map(
                data => `${data?.name};${data?.id}`,
              )}
              mode="multiple">
              {getAllCompany?.getAllCompanies?.map(({id, name, logoImgURL}) => (
                // eslint-disable-next-line react/jsx-key
                <Option value={`${name};${id}`}>
                  <div className={styles['all-data']}>
                    <img src={logoImgURL} alt="" />
                    <span>{name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Use External Link">
            <Switch
              checked={useExternalLink}
              onChange={e => setUseExternalLink(e)}
            />
          </Form.Item>
          {useExternalLink ? (
            <div>
              <Form.Item
                label="File Link"
                name="document"
                rules={[
                  {
                    required: false,
                  },
                ]}>
                <Input
                  value={pdfLink}
                  defaultValue={data?.getReport?.document}
                  onChange={e => setPdfLink(e.target.value)}
                  required
                />
              </Form.Item>
            </div>
          ) : (
            <div>
              <FadeEffect>
                <ReportPDF
                  setPdf={setPdf}
                  previewUrl={previewUrl}
                  setPreviewUrl={setPreviewUrl}
                  setPdfLink={setPdfLink}
                  pdf={pdf}
                  large
                />
              </FadeEffect>
            </div>
          )}

          <FadeEffect>
            {formValues?.map(({sectionName, content, sequence}, index) => (
              <ReportAccordion
                key={index}
                headingText={sectionName || ''}
                handleHeadingText={e => handleChange(sequence, e)}
                content={content || ''}
                handleContent={e => handleEditorContent(sequence, e)}
                index={sequence}
                onDelete={() => handleDeleteSection(index)}
              />
            ))}
            <button
              type="button"
              className={styles['add-button']}
              onClick={() => addFormFields()}>
              <img src={PlusIcon} alt="" />
              <span>Add New Section</span>
            </button>
          </FadeEffect>
          <Form.Item className={styles['submit-button']}>
            <SubmitButton
              label="Save Changes"
              disabled={loadingUpdate}
              handleClick={() => onSubmit()}
            />
          </Form.Item>
        </Form>
      </div>
      {/* Add Tags Modal */}
      <DBModal
        isOpen={openTagsModal}
        handleClose={() => setOpenTagsModal(false)}
        content={
          <ReportTagsList
            onClick={() => setOpenTagsModal(false)}
            choosenTopics={relatedTopic}
            addTopic={addTopic}
          />
        }
      />
    </>
  );
};

export default Report;
