import moment from 'moment';
import {toast} from 'react-toastify';
import {GET_WEBSITE_NEWS} from './queries';
import {handleClearWebsiteTranslation} from '.';

export const onCreateWebsiteNews = async (query, newsProps) => {
  const {newsState, imgUrl, storedNews, navigate, dispatch} = newsProps;
  try {
    query({
      variables: {
        data: {
          title: storedNews.newsTitle,
          categories: newsState.categories,
          companies: newsState.companies,
          content: storedNews.newsContent,
          featuredImage: imgUrl,
          slug: storedNews.slug.trim().replace(/\s+/g, '-'),
          metaDescription: storedNews.metaDescription.trim(),
          keytakes: storedNews.newsKeyTake,
          keywords: storedNews.keywords,
          imageAltText: storedNews.imageAltText.trim(),
          language: storedNews.baseLanguage,
          publishedDate: moment(newsState.publishedDate).format(),
          summary: storedNews.summary,
          source: {
            name: newsState.sourceName,
            url: newsState.sourceURL,
          },
          type: storedNews.newsType,
          tag: newsState.websiteTag,
          multilang: storedNews.translation.map(data => ({
            content: data.content,
            keytakes: data.keyTake,
            language: data.language,
            imageAltText: data.imageAltText.trim(),
            title: data.title,
            slug: data.slug.trim().replace(/\s+/g, '-'),
            metaDescription: data.metaDescription.trim(),
            summary: data.summary,
            keywords: data.keywords,
            type: data.type,
          })),
        },
      },
      refetchQueries: [
        {
          query: GET_WEBSITE_NEWS,
          variables: {
            type: storedNews.newsType,
            countPage: true,
            language: 'EN',
            limit: 20,
            pageNumber: 1,
          },
        },
      ],
    })
      .then(({data: {createWebsiteNews}}) => {
        if (createWebsiteNews.__typename === 'Error') {
          return toast.error(createWebsiteNews.message);
        } else {
          toast.success('Created Successfully');
          dispatch(handleClearWebsiteTranslation());
          navigate('/dashboard/news/website-news');
        }
      })
      .catch(error => toast.error(error));
  } catch (error) {
    toast.error(error);
  }
};

export const onUpdateWebsiteFinishPage = async (query, newsProps, newsId) => {
  try {
    query({
      variables: {
        newsId,
        data: {
          categories: newsProps.categories,
          companies: newsProps.companies,
          featuredImage: newsProps.imgUrl,
          tag: newsProps.websiteTag,
          summary: newsProps.summary,
        },
      },
      refetchQueries: [
        {
          query: GET_WEBSITE_NEWS,
          variables: {
            type: newsProps.websiteType,
            countPage: true,
            language: newsProps.baseLang,
            limit: 20,
            pageNumber: 1,
          },
        },
      ],
    })
      .then(({data: {updateWebsiteNews}}) => {
        if (updateWebsiteNews.__typename === 'Error') {
          toast.error(updateWebsiteNews.message);
        } else {
          toast.success('Updated Successfully');
        }
      })
      .catch(error => toast.error(error));
  } catch (error) {
    toast.error(error);
  }
};

export const onUpdateWebsiteNews = async (query, newsProps, newsId) => {
  try {
    query({
      variables: {
        newsId,
        data: {
          title: newsProps.title.trim(),
          content: newsProps.content,
          keytakes: newsProps.keyTake,
          slug: newsProps.slug.trim().replace(/\s+/g, '-'),
          metaDescription: newsProps.metaDescription.trim(),
          language: newsProps.baseLang,
          type: newsProps.websiteType,
          imageAltText: newsProps.imageAltText.trim(),
          summary: newsProps.summary,
          multilang: newsProps.translatedNews.map(data => ({
            content: data.content,
            keytakes: data.keyTake,
            slug: data.slug.trim().replace(/\s+/g, '-'),
            language: data.language,
            title: data.title.trim(),
            imageAltText: data.imageAltText.trim(),
            type: data.type,
            summary: data.summary,
          })),
        },
        refetchQueries: [
          {
            query: GET_WEBSITE_NEWS,
            variables: {
              type: newsProps.websiteType,
              countPage: true,
              language: newsProps.baseLang,
              limit: 20,
              pageNumber: 1,
            },
          },
        ],
      },
    })
      .then(({data: {updateWebsiteNews}}) => {
        if (updateWebsiteNews.__typename === 'Error') {
          return toast.error(updateWebsiteNews.message);
        } else {
          toast.success('Updated Successfully');
          newsProps.navigate(
            `/dashboard/news/update-website-news-two/${newsId}/${newsProps.baseLang}`,
          );
        }
      })
      .catch(error => toast.error(error));
  } catch (error) {
    toast.error(error);
  }
};

export const onDeleteWebsiteNews = async (query, id, type) => {
  try {
    query({
      variables: {
        newsId: id,
      },
      refetchQueries: [
        {
          query: GET_WEBSITE_NEWS,
          variables: {
            type: type,
            countPage: true,
            language: 'EN',
            limit: 20,
            pageNumber: 1,
          },
        },
      ],
    })
      .then(({data: {deleteWebsiteNews}}) => {
        if (deleteWebsiteNews.__typename === 'Error') {
          toast.error(deleteWebsiteNews.message);
        } else {
          toast.success('Deleted Successfully');
        }
      })
      .catch(error => toast.error(error));
  } catch (error) {
    toast.error(
      'Something went wrong, probably your Network! Please try login in again',
    );
  }
};

export const requestNewsTranslation = async (query, translateProps) => {
  const {
    sourceLang,
    targetLang,
    targetText1,
    targetText2,
    targetText3,
    targetText4,
    targetText6,
    translateSlug,
    translateSummaries,
    translateKeywords,
    websiteType,
  } = translateProps;
  try {
    const {data} = await query({
      variables: {
        translations: [
          {sourceLang, sourceText: targetText1, targetLang},
          {sourceLang, sourceText: targetText2, targetLang},
          {sourceLang, sourceText: targetText3, targetLang},
          {sourceLang, sourceText: targetText4, targetLang},
          {sourceLang, sourceText: targetText6, targetLang},
          {sourceLang, sourceText: translateSlug, targetLang},
          ...translateSummaries?.map(summary => ({
            sourceLang,
            sourceText: summary,
            targetLang,
          })),
        ],
        treatment: 'html',
      },
    });

    // Handle keywords translation
    const {data: keywords} = await query({
      variables: {
        translations: translateKeywords?.map(data => ({
          sourceLang,
          sourceText: data,
          targetLang,
        })),
      },
    });
    return {
      title: data.translateText.translations[0],
      content: data.translateText.translations[1],
      language: targetLang,
      keyTake: data.translateText.translations[2],
      metaDescription: data.translateText.translations[3],
      slug: data.translateText.translations[5]
        ?.replace(/\s+/g, '-')
        .replace(/[^-a-zA-Z0-9]/g, ''),
      imageAltText: data.translateText.translations[4],
      keywords: keywords.translateText.translations,
      type: websiteType,
      summary: data.translateText.translations.slice(
        6,
        6 + translateSummaries?.length,
      ),
    };
  } catch (error) {
    toast.error(error);
  }
};

export const requestCompanyTranslation = async (query, translateProps) => {
  const {sourceLang, targetLang, targetText1, targetText2} = translateProps;
  try {
    const {data} = await query({
      variables: {
        translations: [
          {sourceLang, sourceText: targetText1, targetLang},
          {sourceLang, sourceText: targetText2, targetLang},
        ],
      },
    });

    return {
      description: data.translateText.translations[0],
      longDescription: data.translateText.translations[1],
      language: targetLang,
    };
  } catch (error) {
    toast.error(error);
  }
};

export const requestStocksTranslation = async (query, translateProps) => {
  const {sourceLang, targetLang, targetText1, targetText2, targetText3} =
    translateProps;
  try {
    const {data} = await query({
      variables: {
        translations: [
          {sourceLang, sourceText: targetText1, targetLang},
          {sourceLang, sourceText: targetText2, targetLang},
          {sourceLang, sourceText: targetText3, targetLang},
        ],
      },
    });

    return {
      description: data.translateText.translations[0],
      defaultClosureMessage: data.translateText.translations[1],
      defaultClosureTitle: data.translateText.translations[2],
      language: targetLang,
    };
  } catch (error) {
    toast.error(error);
  }
};

export const requestCompanyDocumentTranslation = async (
  query,
  translateProps,
) => {
  const {sourceLang, targetLang, targetText1} = translateProps;
  try {
    const {data} = await query({
      variables: {
        translations: [{sourceLang, sourceText: targetText1, targetLang}],
      },
    });

    return {
      documentName: data.translateText.translations[0],
      language: targetLang,
    };
  } catch (error) {
    toast.error(error);
  }
};
