import {Route, Routes} from 'react-router-dom';
import {ActiveAnnouncements} from './announcementTabs/activeAnnouncements';
import {AllAnnouncements} from './announcementTabs/allAnnouncements';
import {EditAnnouncement} from './announcementTabs/editAnnouncement';
import {NewAnnouncement} from './announcementTabs/newAnnouncement';
import {UpcomingAnnouncements} from './announcementTabs/upcomingAnnouncement';
import {AnnouncementsManagement} from './index';

const Announcements = () => {
  return (
    <Routes>
      <Route path="/" element={<AnnouncementsManagement />}>
        <Route index path="/" element={<ActiveAnnouncements />} />
        <Route path="/expired" element={<AllAnnouncements />} />
        <Route path="/upcoming" element={<UpcomingAnnouncements />} />
        <Route />
      </Route>
      <Route path="/new" element={<NewAnnouncement />} />
      <Route path="/:id" element={<EditAnnouncement />} />
    </Routes>
  );
};

export {Announcements};
