import styles from './viewDocument.module.scss';
import {Viewer, Worker, SpecialZoomLevel} from '@react-pdf-viewer/core';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import {pageNavigationPlugin} from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

const ViewUserDocument = ({url, documentName}) => {
  const pageNavigationPluginInstance = pageNavigationPlugin();
  return (
    <div>
      <div className={styles.navigation}>
        <span>{documentName ?? 'User Document'}</span>
      </div>
      <div className={styles.form}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
          <div className={styles.pdfviewer}>
            <Viewer
              fileUrl={url}
              defaultScale={SpecialZoomLevel.PageWidth}
              plugins={[pageNavigationPluginInstance]}
            />
          </div>
        </Worker>
      </div>
    </div>
  );
};

export {ViewUserDocument};
