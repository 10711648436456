import {gql} from '@apollo/client';

export const COMPANY = gql`
  fragment company on Company {
    id
    name
    shortDescription
    description
    legalName
    website
    ticker
    numberOfEmployees
    headquarter
    foundedOn
    companyType
    createdAt
    updatedAt
    logoImgURL
    topics {
      id
      name
    }
  }
`;

export const STARTUP_COMPANY = gql`
  fragment company on Company {
    id
    name
    addresses {
      id
      state
    }
    logoImgURL
    bannerImgURL
    foundedOn
    companyType
    accelerator
    socials {
      id
      url
      icon
    }
    ticker
    stage

    industries {
      id
      name
    }
    website
    teamMembers {
      id
      name
      role
      displayImage
      linkedinUrl
      twitterUrl
    }
  }
`;
