import DBModal from 'components/modal/modal';
import styles from '../management/savingsUserManagement.module.scss';
import {SavingsUserProfile} from 'pages/Savings/products/single/SingleSavingsProductOverview';
import {Tag} from 'antd';
import plansIcon from 'icons/SavingPlanSvg.svg';
import coinIcon from 'icons/coin.svg';
import clockIcon from 'icons/access_time.svg';
import moment from 'moment';
import SingleSaverTransactions from './SingleSaverTransactions';
import {currencySymbols} from 'utils/mock';
import {formatEnumType} from 'utils/helper';

const SingleSaversModal = ({openModal, setOpenModal, type, content}) => {
  console.log(content);
  const fields = [
    {
      label: 'Start Date',
      value: (
        <p>
          {content?.createdAt
            ? moment(content?.createdAt).format('DD MMM, YYYY hh:mm A')
            : ''}
        </p>
      ),
    },
    {
      label: 'Interest Earned',
      value: (
        <p>
          {currencySymbols[content?.currency]?.symbol}{' '}
          {content?.paidOutInterest?.toLocaleString()}
        </p>
      ),
    },
    {
      label: 'Savings Type',
      value: (
        <div className={styles.savingType}>
          <p>{content?.savingsProduct?.savingsType}</p>
        </div>
      ),
    },
    {
      label: 'Time Left',
      value: <p>{content?.daysLeft ?? '-'}</p>,
    },
  ];

  return (
    <DBModal
      width={type === 'SAVER_TRANSACTIONS' ? 1200 : 400}
      isOpen={openModal}
      handleClose={() => setOpenModal(false)}
      content={
        type === 'SAVER_TRANSACTIONS' ? (
          <SingleSaverTransactions
            setOpenModal={setOpenModal}
            id={content}
            type={type}
          />
        ) : (
          <div className={styles.rootSaver}>
            <h1>User savings information</h1>
            <div className={styles.profile}>
              <SavingsUserProfile
                imageUrl={content?.user?.imageUrl}
                firstName={content?.user?.firstName}
                lastName={content?.user?.lastName}
              />
              <div className={styles.status}>
                <Tag
                  color={
                    {
                      'ACTIVE': 'green',
                      'INACTIVE': 'magenta',
                      'DELETED': 'red',
                      'COMPLETED': 'green',
                    }[content?.status]
                  }>
                  {content?.status}
                </Tag>
              </div>
            </div>
            <div className={styles.plan}>
              <div className={styles.planTop}>
                <span>Savings Plan Name: </span>{' '}
                <p>{content?.savingsProduct?.name}</p>
              </div>
              <div className={styles.planBottom}>
                <img src={plansIcon} alt="" />
                <div>
                  <span>Savings Balance: </span>{' '}
                  <p>
                    {currencySymbols[content?.currency]?.symbol}{' '}
                    {content?.totalBalance?.toLocaleString()}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.currenciesContainer}>
                {fields.slice(0, 2).map((data, index) => (
                  <CurrencyItem key={index} data={data} />
                ))}
              </div>
              <div className={styles.currenciesContainer}>
                {fields.slice(2).map((data, index) => (
                  <CurrencyItem key={index} data={data} />
                ))}
              </div>
            </div>
            <div className={styles.historyContainer}>
              <div>
                <h1>Saving history</h1>
                <button
                  onClick={() =>
                    setOpenModal({
                      content,
                      isOpen: true,
                      type: 'SAVER_TRANSACTIONS',
                    })
                  }>
                  View all
                </button>
              </div>
              {content?.transactions?.slice(0, 3)?.map(data => (
                <div key={data?.id} className={styles.history}>
                  <div className={styles.time}>
                    <div className={styles.image}>
                      <img src={clockIcon} alt="" />
                      <span />
                    </div>
                    <div className={styles.createdAt}>
                      <p>{formatEnumType(data?.transactionType)}</p>
                      <span>
                        {data?.status?.toLowerCase()} -{' '}
                        {data?.createdAt
                          ? moment(data?.createdAt).format('MMM, DD YYYY')
                          : '--'}
                      </span>
                    </div>
                  </div>
                  <HistoryAmount
                    currency={data?.currency}
                    amount={data?.amount}
                  />
                </div>
              ))}
            </div>
          </div>
        )
      }
    />
  );
};

const HistoryAmount = ({currency, amount}) => {
  const displayText = `${currency}${amount?.toLocaleString() || ''}`;
  const fontSize = Math.max(25 - displayText.length, 10);

  return (
    <div className={styles.historyAmount}>
      <p style={{fontSize: `${fontSize}px`}}>{displayText}</p>
    </div>
  );
};

const CurrencyItem = ({data}) => (
  <div className={styles.currency}>
    <div className={styles.coinImage}>
      <img src={coinIcon} alt="" />
    </div>
    <div>
      <span>{data.label}</span>
      <p>{data?.value}</p>
    </div>
  </div>
);

export default SingleSaversModal;
