import {gql} from '@apollo/client';

export const CREATE_REFERRAL_PROGRAM = gql`
  mutation CreateReferralProgram($data: CreateReferralProgramInput!) {
    createReferralProgram(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on ReferralProgramType {
        referralProgram {
          referralName
          minInvestment
          maxRewardInvites
          referralReward
          isActive
        }
      }
    }
  }
`;

export const UPDATE_REFERRAL_PROGRAM = gql`
  mutation UpdateReferralProgram($data: UpdateReferralProgramInput!) {
    updateReferralProgram(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on ReferralProgramType {
        referralProgram {
          id
          referralName
          minInvestment
          maxRewardInvites
          referralReward
          isActive
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const DELETE_REFERRAL_PROGRAM = gql`
  mutation DeleteReferralProgram($referralProgramId: ID!) {
    deleteReferralProgram(referralProgramId: $referralProgramId) {
      ... on Error {
        message
        statusCode
      }
      ... on ReferralProgramType {
        referralProgram {
          id
          referralName
          minInvestment
          maxRewardInvites
          referralReward
          isActive
          createdAt
          updatedAt
        }
      }
    }
  }
`;
