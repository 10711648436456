import React from 'react';
import styles from './reportAccordion.module.scss';
import {Collapse} from 'antd';
import classnames from 'classnames/bind';
import ReactQuill from 'react-quill';
import EditIcon from '../../images/edit_secondary.svg';
import FadeEffect from 'components/animation/fadeEffect';
import {TrashIcon} from 'icons';
import DBModal from 'components/modal/modal';
import DeleteReportSection from 'components/deleteReportSection';

const {Panel} = Collapse;

const ReportAccordion = ({
  style,
  handleHeadingText,
  headingText,
  content,
  handleContent,
  index,
  onDelete,
}) => {
  const cx = classnames.bind(styles);

  const [openDeleteAccordion, setOpenDeleteAccordion] = React.useState(false);

  return (
    <>
      <FadeEffect>
        <div className={cx('container', style)}>
          <Collapse defaultActiveKey={['1']} ghost>
            <Panel
              header={
                <AccordionHeader
                  headingText={headingText}
                  handleInput={handleHeadingText}
                  index={index}
                  onDelete={() => setOpenDeleteAccordion(true)}
                />
              }
              key={index}>
              <div className={styles['quill-container']}>
                <ReactQuill
                  className={styles.quill}
                  theme="snow"
                  modules={ReportAccordion.modules}
                  formats={ReportAccordion.format}
                  onChange={el => handleContent?.(el)}
                  placeholder="Write Something"
                  value={content}
                />
              </div>
            </Panel>
          </Collapse>
        </div>
      </FadeEffect>
      {/* Delete Accordion Modal */}
      <DBModal
        isOpen={openDeleteAccordion}
        handleClose={() => setOpenDeleteAccordion(false)}
        content={
          <DeleteReportSection
            onDismiss={() => setOpenDeleteAccordion(false)}
            onProceed={() => {
              onDelete?.();
              setOpenDeleteAccordion(false);
            }}
          />
        }
      />
    </>
  );
};

const AccordionHeader = ({headingText, handleInput, index, onDelete}) => {
  const onChangeHandler = e => {
    handleInput?.(e);
  };

  return (
    <div className={styles['accordion-header']}>
      <div
        className={styles['heading-text-wrapper']}
        onClick={e => e.stopPropagation()}>
        <span className={styles['hidden-span']}>{headingText}</span>
        <input
          onClick={e => e.stopPropagation()}
          type="text"
          onChange={e => onChangeHandler(e)}
          value={headingText}
          id={`accordion-header-input${index}`}
          name="header"
        />
      </div>
      <label
        onClick={e => e.stopPropagation()}
        htmlFor={`accordion-header-input${index}`}>
        <img src={EditIcon} alt="" />
      </label>
      <button
        className={styles['accordion-delete-button']}
        onClick={() => onDelete?.()}>
        <TrashIcon />
      </button>
    </div>
  );
};

ReportAccordion.modules = {
  toolbar: [
    [{header: '1'}, {header: '2'}, {header: [3, 4, 5, 6]}, {font: []}],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{list: 'ordered'}, {list: 'bullet'}],
    ['link', 'image'],
    ['code-block'],
  ],
};

ReportAccordion.format = [
  'header',
  'size',
  'font',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'link',
  'image',
  'code-block',
];

export default ReportAccordion;
