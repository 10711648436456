import {Route, Routes} from 'react-router-dom';
import NewsOverview from './overview/NewsOverview';
import News from 'components/investorUpdate/management/news';
import SelectedNews from 'components/investorUpdate/management/selectedNews/SelectedNews';
import AddNews from 'components/investorUpdate/management/news/AddNews/AddNews';
import EditNews from 'components/investorUpdate/management/news/editNews/EditNews';
import WebsiteNews from './websiteNews/WebsiteNews';
import CreateWebsiteNews from './create/CreateWebsiteNews';
import UpdateWebsiteNews from './update/UpdateWebsiteNews';
import UpdateWebsiteNewsFinishPage from './update/UpdateWebsiteNewsFinishPage';
import WebsiteNewsFinishPageContents from './create/WebsiteNewsFinishPageContents';

const NewsPageRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<NewsOverview />}>
        <Route index path="/" element={<News />} />
        <Route path="/selected-news" element={<SelectedNews />} />
        <Route path="website-news" element={<WebsiteNews />} />
      </Route>
      <Route path="/add-news" element={<AddNews />} />
      <Route path="/update-news/:id" element={<EditNews />} />
      <Route path="create-website-news" element={<CreateWebsiteNews />} />
      <Route
        path="create-website-news-two"
        element={<WebsiteNewsFinishPageContents />}
      />
      <Route
        path="update-website-news/:id/:lang"
        element={<UpdateWebsiteNews />}
      />
      <Route
        path="update-website-news-two/:id/:lang"
        element={<UpdateWebsiteNewsFinishPage />}
      />
    </Routes>
  );
};

export default NewsPageRoutes;
