import {useState} from 'react';
import styles from './transferDetails.module.scss';
import {useMutation} from '@apollo/client';
import {PRE_SIGN_MEDIA_URL} from 'graphql/mutations/wallet';
import {toast} from 'react-toastify';
import {Spin} from 'antd';
import {fullyDecodeURL} from 'utils/helper';

const WireTransferDetails = ({data}) => {
  const bankInfo = data?.bankInformation;
  const mobileMoneyInfo = data?.mobileMoneyInformation;
  const [presignedUrlImage, setPresignedUrlImage] = useState('');
  const [viewReceipt, setViewReceipt] = useState(false);
  const [presignMediaURL, {loading}] = useMutation(PRE_SIGN_MEDIA_URL);

  const generatePreSignedURL = () => {
    const imageUrl =
      bankInfo?.wireTransferReceiptImage ||
      bankInfo?.bankTransferInstructionImageURL;
    if (imageUrl) {
      const bucket = imageUrl.split('.s3')[0].replace('https://', '');
      const filename = imageUrl.split('amazonaws.com/')[1];

      presignMediaURL({
        variables: {
          input: {
            filename: fullyDecodeURL(filename),
            bucket,
          },
        },
      })
        .then(({data: {presignMediaURL}}) => {
          if (presignMediaURL.__typename === 'Error') {
            toast.error(presignMediaURL.message);
          } else {
            setPresignedUrlImage(presignMediaURL?.data?.url);
            toast.success('Presigned URL Generated Successfully');
          }
        })
        .catch(error => toast.error('Something went wrong', error));
    }
  };
  const openImageInNewTab = () => {
    if (presignedUrlImage) window.open(presignedUrlImage, '_blank');
  };

  return (
    <div className={styles.root}>
      {['MOBILE_MONEY', 'HUB2_MOBILE_MONEY'].includes(data?.paymentMethod) ? (
        <>
          <div className={styles.top}>
            <h1>Mobile Money Details</h1>
          </div>
          <div className={styles.contents}>
            <div className={styles.content}>
              <h3>Beneficiary Name</h3>
              <p>{mobileMoneyInfo?.beneficiaryName ?? '--'}</p>
            </div>
            <div className={styles.content}>
              <h3>Country ISO</h3>
              <p>{mobileMoneyInfo?.countryISO ?? '--'}</p>
            </div>
            <div className={styles.content}>
              <h3>Phone Number</h3>
              <p>{mobileMoneyInfo?.phoneNumber ?? '--'}</p>
            </div>
            <div className={styles.content}>
              <h3>Mobile Money Network</h3>
              <p>{mobileMoneyInfo?.mobileMoneyNetwork ?? '--'}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.top}>
            <h1>User Bank Details</h1>
          </div>
          {viewReceipt &&
          (bankInfo?.wireTransferReceiptImage ||
            bankInfo?.bankTransferInstructionImageURL) ? (
            loading ? (
              <div className={styles.receipt}>
                <div>
                  <Spin size="large" />
                </div>
              </div>
            ) : (
              <div className={styles.receipt}>
                <img src={presignedUrlImage} alt="" />
              </div>
            )
          ) : (
            <div className={styles.contents}>
              <div className={styles.content}>
                <h3>Account Name</h3>
                <p>{bankInfo?.accountName ?? '--'}</p>
              </div>
              <div className={styles.content}>
                <h3>Account Number</h3>
                <p>{bankInfo?.accountNumber ?? '--'}</p>
              </div>
              <div className={styles.content}>
                <h3>Bank Name</h3>
                <p>{bankInfo?.bankName ?? '--'}</p>
              </div>
              <div className={styles.content}>
                <h3>Bank Country</h3>
                <p>{bankInfo?.bankCountry ?? '--'}</p>
              </div>
              <div className={styles.content}>
                <h3>Routing Number</h3>
                <p>{bankInfo?.routingNumber ?? '--'}</p>
              </div>
              <div className={styles.content}>
                <h3>Swift Code</h3>
                <p>{bankInfo?.swiftCode ?? '--'}</p>
              </div>
            </div>
          )}
          <div className={styles.modalButtons}>
            {bankInfo?.wireTransferReceiptImage ? (
              <button
                className={styles.button}
                disabled={loading}
                onClick={() => {
                  setViewReceipt(!viewReceipt);
                  if (!viewReceipt) {
                    generatePreSignedURL();
                  }
                }}>
                View {viewReceipt ? 'Bank Details' : 'Receipt'}
              </button>
            ) : bankInfo?.bankTransferInstructionImageURL ? (
              <button
                loading={loading}
                className={styles.button}
                onClick={() => {
                  setViewReceipt(!viewReceipt);
                  if (!viewReceipt) {
                    generatePreSignedURL();
                  }
                }}>
                View {viewReceipt ? 'Bank Details' : 'Bank Withdrawal Details'}
              </button>
            ) : (
              <></>
            )}
            {viewReceipt &&
              (bankInfo?.wireTransferReceiptImage ||
                bankInfo?.bankTransferInstructionImageURL) && (
                <button className={styles.button} onClick={openImageInNewTab}>
                  Preview Image
                </button>
              )}
          </div>
        </>
      )}
    </div>
  );
};

export default WireTransferDetails;
