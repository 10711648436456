import {useMutation, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';
import {Table, Spin, Modal} from 'antd';
import styles from './keyMetric.module.scss';
import pageNotFound from 'images/pageNotFound.png';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {Button} from '@dabafinance/react-components';
import {toast} from 'react-toastify';
import trash from 'images/trash-can-outline.svg';
import {formatTypeEnum} from 'utils/mock';
import {GET_COMPANY_KEY_METRIC} from 'graphql/queries/keyStat';
import {DELETE_COMPANY_KEY_STAT} from 'graphql/mutations/keyStat';

const CompanyKeyMetric = ({id}) => {
  const navigate = useNavigate();

  const {data, loading} = useQuery(GET_COMPANY_KEY_METRIC, {
    variables: {
      companyId: id,
    },
  });

  const {confirm} = Modal;
  const [deleteCompanyKeyMetric] = useMutation(DELETE_COMPANY_KEY_STAT);

  const handleDelete = keyStatId => {
    confirm({
      content: 'Are you sure you want to delete this key metric?',
      onOk: () => {
        deleteCompanyKeyMetric({
          variables: {
            data: [
              {
                companyId: id,
                keyStatId,
              },
            ],
          },
          refetchQueries: [
            {
              query: GET_COMPANY_KEY_METRIC,
              variables: {companyId: id},
            },
          ],
        })
          .then(({data: {deleteCompanyKeyMetric}}) => {
            if (deleteCompanyKeyMetric.__typename === 'Error') {
              toast.error(deleteCompanyKeyMetric.message);
            } else {
              toast.success('Company Key Metric Deleted Successfully');
            }
          })
          .catch(error => toast.error(error.message));
      },
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Format Type',
      dataIndex: 'formatType',
      key: 'formatType',
      render: (text, record) => (
        <div className={styles.active}>
          <p>
            {
              formatTypeEnum.find(item => item.value === record?.formatType)
                ?.label
            }
          </p>
        </div>
      ),
    },
    {
      title: 'Computed',
      dataIndex: 'isComputed',
      key: 'isComputed',
      render: (text, record) => (
        <div className={styles.active}>
          <p>{record?.isComputed ? 'Yes' : 'No'}</p>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <div className={styles['action-btn']}>
          <Button
            onClick={() =>
              navigate(`/dashboard/key-statistics/update-key-stat/${record.id}`)
            }
            type="secondary"
            label="Update"
          />
          <img
            onClick={() => {
              handleDelete(record.id);
            }}
            className={styles.trash}
            src={trash}
            alt=""
          />
        </div>
      ),
    },
  ];

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Key Metrics</h1>
        <SubmitButton
          label="Add Key Metric"
          handleClick={() =>
            navigate(`/dashboard/opportunities/startups/add-metric/${id}`)
          }
        />
      </div>
      <div>
        {data?.getCompanyKeyMetrics?.length >= 1 ? (
          <div className={styles.table}>
            <Table
              dataSource={data?.getCompanyKeyMetrics}
              columns={columns}
              className={styles['user-table']}
            />
          </div>
        ) : (
          <div className={styles['no-update']}>
            <img src={pageNotFound} alt="" />
            <h1>No Key Metric</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyKeyMetric;
