import {Search} from '@dabafinance/react-components';
import styles from './adminMainLayout.module.scss';
import {Dropdown, Menu} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import Avatar from 'components/avatar/avatar';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import gamerIcon from 'images/man.png';

const AdminMainLayout = ({children}) => {
  const data = useSelector(state => state?.auth?.userData?.user?.roleSettings);
  const navigate = useNavigate();
  const dropdownMenu = (
    <Menu>
      <Menu.Item onClick={() => navigate('/register')} key="0">
        <span>Create Admin Account</span>
      </Menu.Item>
      <Menu.Item onClick={() => navigate('/dashboard/admin-settings')} key="1">
        <span>Admin Settings</span>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className={styles.wrapper}>
      <nav className={styles.nav}>
        <Search
          disabled
          placeholder="Search"
          className={styles.search}
          id="search"
        />
        {data?.name === 'SUPERADMIN' ? (
          <Dropdown trigger="click" overlay={dropdownMenu}>
            <div className={styles.dropdownNav}>
              <Avatar alt="avatar" src={gamerIcon} />
              SUPER ADMIN
              <DownOutlined />
            </div>
          </Dropdown>
        ) : (
          <div className={styles.dropdownNav}>
            <Avatar alt="avatar" src={gamerIcon} />
            {data?.description?.toUpperCase() || 'ADMIN'}
          </div>
        )}
      </nav>
      {children}
    </div>
  );
};

export {AdminMainLayout};
