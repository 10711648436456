import {DatePicker, Form, Select, Switch} from 'antd';
import styles from './updateDocCategory.module.scss';
import moment from 'moment';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {seededDocumentsCategories} from 'utils/mock';

const SeededDocumentsFilter = ({handleClose, handleFilter, reset}) => {
  const [form] = Form.useForm();
  return (
    <div className={styles.filterRoot}>
      <div className={styles.filterRootHeader}>
        <h1>Sort Documents</h1>
        <p role="button" onClick={handleClose}>
          X
        </p>
      </div>
      <Form
        onFinish={values => {
          handleFilter(values);
          reset();
        }}
        form={form}
        layout="vertical">
        <Form.Item name="language" label="Language">
          <Select allowClear>
            <Select.Option key="EN">English</Select.Option>
            <Select.Option key="FR">French</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="categories" label="Categories">
          <Select allowClear>
            {seededDocumentsCategories.map(data => (
              <Select.Option key={data.key}>{data.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div className={styles.price}>
          <Form.Item name="startDate" label="Seeded Start Date">
            <DatePicker
              allowClear
              format={'YYYY-MM-DD'}
              showTime={{
                defaultValue: moment('00:00'),
              }}
              style={{width: 180}}
            />
          </Form.Item>
          <Form.Item name="endDate" label=" Seeded End Date">
            <DatePicker
              allowClear
              showTime={{
                defaultValue: moment('00:00'),
              }}
              format={'YYYY-MM-DD'}
              style={{width: 180}}
            />
          </Form.Item>
        </div>
        <Form.Item
          label="Documents in Document Center"
          name="isAddedToDocumentCenter">
          <Switch />
        </Form.Item>
        <SubmitButton label="Sort" />
      </Form>
    </div>
  );
};

export default SeededDocumentsFilter;
