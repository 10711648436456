import {useEffect, useState} from 'react';
import {Button} from '@dabafinance/react-components';
import styles from './newLearnArticle.module.scss';
import backIcon from 'images/back.png';
import {Form, Input, Select, Spin, Switch, Tabs} from 'antd';
import {PlusCircleFilled} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {useLazyQuery, useMutation} from '@apollo/client';
import ReactQuill from 'react-quill';
import '../../../../../../node_modules/react-quill/dist/quill.snow.css';
import {
  CREATE_INFORMATION_SECTION,
  CREATE_LEARN_ARTICLE,
} from 'graphql/mutations/learnArticle';
import {GET_ALL_INFORMATION_SECTION} from 'graphql/queries/learnArticle';
import SubmitButton from 'components/Startup/components/SubmitButton';
import moment from 'moment';
import {toast} from 'react-toastify';
import DBModal from 'components/modal/modal';
import UploadImage from 'components/Startup/profile/companyImage';
import {BigPlayButton, Player} from 'video-react';
import {UploadVideoToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import FadeEffect from 'components/animation/fadeEffect';
import ReportPDF from 'components/reportPDF';
import {supportedLanguages} from 'utils/constants';
import {fieldRules} from 'utils/helper';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {onTranslateArticle} from '../actions';
import TranslatedArticleContent from './TranslatedArticlesContent';

const NewLearnArticle = () => {
  const [imgUrl, setImgUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [thumbnailImgUrl, setThumbnailImgUrl] = useState('');
  const [previewThumbnailUrl, setPreviewThumbnailUrl] = useState('');
  const [summary, setSummary] = useState('');
  const [language, setLanguage] = useState('');
  const [selectedTranslationLanguage, setSelectedTranslationLanguage] =
    useState('');
  const [translatedArticles, setTranslatedArticles] = useState([]);
  const [content, setContent] = useState('');
  const [informationSection, setInformationSection] = useState(null);
  const [title, setTitle] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [informationTitle, setInformationTitle] = useState('');
  const [progress, setProgress] = useState(0);
  const [videoUploadLoading, setVideoUploadLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoType, setVideoType] = useState('FILE_URL');
  const [videoUrlActive, setVideoUrlActive] = useState(false);
  const [pdfLink, setPdfLink] = useState('');
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState('');
  const [useExternalLink, setUseExternalLink] = useState(false);
  const [show, setShow] = useState(false);
  const {Option} = Select;
  const navigate = useNavigate();

  const [getSections, {loading, data}] = useLazyQuery(
    GET_ALL_INFORMATION_SECTION,
  );
  const [CreateInformationSectionInput, {loading: createSection}] = useMutation(
    CREATE_INFORMATION_SECTION,
  );
  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );
  const [createLearnArticleInput, {loading: createArticle}] =
    useMutation(CREATE_LEARN_ARTICLE);

  const fieldWidth = 730;

  const handleChangeTranslatedValues = (key, event, name) => {
    let eventName = name || event.target.name;
    let eventValue = name ? event : event.target.value;
    const newFormValues = [...translatedArticles];
    newFormValues[key][eventName] = eventValue;
    setTranslatedArticles(newFormValues);
  };

  const handleSave = () => {
    CreateInformationSectionInput({
      variables: {
        data: {
          title: informationTitle,
        },
      },
      refetchQueries: [{query: GET_ALL_INFORMATION_SECTION}],
    }).then(({data: {CreateInformationSectionInput}}) => {
      if (CreateInformationSectionInput.__typename === 'Error') {
        toast.error(CreateInformationSectionInput.message);
      } else {
        toast.success('Created Successfully');
        navigate('/dashboard/explore/learn-article/new');
        setShow(false);
      }
    });
  };
  const languageToBeTranslatedTo = supportedLanguages.filter(
    key => key.value !== language,
  );

  const onLanguageSelect = e => {
    setLanguage(e);
    getSections({
      variables: {
        language: e,
      },
    });
  };

  useEffect(() => {
    if (videoType === 'EXTERNAL_URL') {
      setVideoUrlActive(true);
    } else {
      setVideoUrlActive(false);
    }
  }, [videoType]);

  const handleUploadVideo = async e => {
    const selectedFile = e.target.files[0];
    if (selectedFile.type === 'video/mp4') {
      setVideoUploadLoading(true);
      const response = await UploadVideoToBucket(
        selectedFile,
        'annoucement-images',
        setProgress,
      );
      setVideoUrl(response);
      setVideoUploadLoading(false);
      setProgress(0);
    } else {
      toast.error('Please upload a mp4 video');
    }
  };

  const handleCreate = () => {
    createLearnArticleInput({
      variables: {
        data: {
          featuredImage: imgUrl,
          informationSection,
          language,
          title,
          content,
          summary,
          videoUrl,
          pdfLink,
          videoTitle,
          videoType,
          videoThumbnail: thumbnailImgUrl,
          publishedDate: moment().format(),
          updatedDate: moment().format(),
          multilang: translatedArticles.map(data => {
            return {
              title: data.title,
              language: data.language,
              content: data.content,
              summary: data.summary,
              videoTitle: data.videoTitle,
              videoUrl: data.videoUrl,
              pdfLink: data.pdfLink,
              videoType: data.videoType,
              videoThumbnail: data.thumbnailImgUrl,
              featuredImage: data.imgUrl,
              informationSectionMultilang: data.informationSectionMultilang,
            };
          }),
        },
      },
    }).then(({data: {createLearnArticle}}) => {
      if (createLearnArticle.__typename === 'Error') {
        toast.error(createLearnArticle.message);
      } else {
        toast.success('Created Successfully');
        navigate('/dashboard/explore/learn-article');
      }
    });
  };

  const handleTranslation = async () => {
    const translateProps = {
      sourceLang: language,
      targetLang: selectedTranslationLanguage,
      targetText1: title,
      targetText2: summary,
      targetText3: content,
    };
    try {
      const newValues = [...translatedArticles];

      //Check if translation exists
      if (newValues.length && newValues[0]) {
        // Check if we're trying to translate in the same language
        const languageExists = newValues.some(
          data => data.language === selectedTranslationLanguage,
        );
        if (languageExists) {
          const updateTranslation = await onTranslateArticle(
            translateText,
            translateProps,
          );
          const translationIndex = newValues.findIndex(
            data => data.language === selectedTranslationLanguage,
          );
          newValues[translationIndex].title = updateTranslation.title;
          newValues[translationIndex].summary = updateTranslation.summary;
          newValues[translationIndex].language = updateTranslation.language;
          newValues[translationIndex].content = updateTranslation.content;
          setTranslatedArticles(newValues);
        }
      } else {
        const triggerTranslation = await onTranslateArticle(
          translateText,
          translateProps,
        );
        if (!triggerTranslation) {
          return toast.error('Something went wrong when Translating');
        }
        newValues.push({
          ...triggerTranslation,
          pdfLink: '',
          videoUrl: '',
          videoTitle: '',
          thumbnailImgUrl: '',
          videoType: 'FILE_URL',
          imgUrl: '',
          informationSectionMultilang: null,
        });
        setTranslatedArticles(newValues);
        setTranslatedArticles(newValues);
      }
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  const plus = <PlusCircleFilled className="plus" />;
  const handleShow = () => {
    setShow(true);
  };
  return (
    <div>
      <div className={styles.navigation}>
        <img src={backIcon} alt="Go back" onClick={() => navigate(-1)} />
        <span>
          Learn & FAQ <span className={styles['h-style']}>/ New Article</span>
        </span>
      </div>
      <div className={styles.form}>
        <Form onFinish={handleCreate} layout="vertical">
          <Form.Item
            rules={[fieldRules('Language')]}
            label="Language"
            className={styles.field}
            name="language">
            <Select
              style={{width: fieldWidth}}
              value={language}
              onChange={e => onLanguageSelect(e)}>
              {supportedLanguages.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[fieldRules('You must select a language to be translated')]}
            label="Language Translation"
            className={styles.field}
            name="translation">
            <Select
              style={{width: fieldWidth}}
              value={selectedTranslationLanguage}
              onChange={e => setSelectedTranslationLanguage(e)}>
              {languageToBeTranslatedTo.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Tabs
            className={styles.tabs}
            tabBarExtraContent={
              selectedTranslationLanguage && (
                <Button
                  disabled={
                    !title || !summary || !content || loadingTranslation
                  }
                  label="Translate"
                  onClick={handleTranslation}
                />
              )
            }>
            <Tabs.TabPane tab="Original" key="Original">
              <UploadImage
                previewUrl={previewUrl}
                setPreviewUrl={setPreviewUrl}
                setImgUrl={setImgUrl}
                large
                bucket="investor-updates-bucket"
              />
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Title is required',
                  },
                ]}
                label="Title"
                className={styles.field}
                name="title">
                <Input
                  style={{width: fieldWidth}}
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  placeholder={'Title'}
                />
              </Form.Item>
              <Form.Item
                label="Short Description"
                className={styles.field}
                name="short-description">
                <Input
                  style={{width: fieldWidth}}
                  value={summary}
                  onChange={e => setSummary(e.target.value)}
                  placeholder={'Short Description'}
                />
              </Form.Item>
              <Form.Item
                label="Information Section"
                className={styles.field}
                name="information section">
                <Select
                  style={{width: fieldWidth}}
                  value={informationSection}
                  loading={loading}
                  onChange={e => setInformationSection(e)}>
                  {data?.getAllInformationSections.map(({title, id}) => (
                    // eslint-disable-next-line react/jsx-key
                    <Option value={id}>{title}</Option>
                  ))}
                  <Option disabled>
                    <Button
                      btnIcon={plus}
                      onClick={handleShow}
                      type="secondary"
                      className={styles.addSection}
                      label="Add Section"></Button>
                  </Option>
                </Select>
              </Form.Item>
              <div className={styles['quill-container']}>
                <Form.Item
                  label="Content"
                  rules={[
                    {
                      required: true,
                      message: 'Content is required',
                    },
                  ]}>
                  <ReactQuill
                    className={styles.quill}
                    theme="snow"
                    modules={NewLearnArticle.modules}
                    formats={NewLearnArticle.format}
                    onChange={el => setContent(el)}
                    placeholder="Write Something"
                    value={content}
                  />
                </Form.Item>
              </div>
              <Form.Item
                label="Video Title"
                className={styles.field}
                name="videoTitle">
                <Input
                  style={{width: fieldWidth}}
                  value={videoTitle}
                  onChange={e => setVideoTitle(e.target.value)}
                  placeholder={'Video title'}
                />
              </Form.Item>
              <div className={styles['upload-image']}>
                <h2 className={styles.title}>Video Thumbnail</h2>
                <UploadImage
                  bucket={'investor-updates-bucket'}
                  previewUrl={previewThumbnailUrl}
                  setImgUrl={setThumbnailImgUrl}
                  setPreviewUrl={setPreviewThumbnailUrl}
                  accept
                  thumbnail
                />
              </div>
              <Form.Item
                label="Video Type"
                className={styles.field}
                name="videoType">
                <Select
                  style={{width: fieldWidth}}
                  value={videoType}
                  onChange={e => setVideoType(e)}>
                  <Option value="FILE_URL">Video File Link</Option>
                  <Option value="EXTERNAL_URL">External Video Link</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Use Video Url" className={styles.field}>
                <Switch
                  checked={videoUrlActive}
                  defaultChecked={videoUrlActive}
                  onChange={e => setVideoUrlActive(e)}
                />
              </Form.Item>
              {videoUrlActive ? (
                <div className={styles['video-upload']}>
                  <Form.Item
                    label="Video Url"
                    className={styles.field}
                    name="videoUrl">
                    <Input
                      style={{width: fieldWidth}}
                      value={videoUrl}
                      defaultValue={videoUrl}
                      onChange={e => setVideoUrl(e.target.value)}
                      placeholder={'Url'}
                    />
                  </Form.Item>
                </div>
              ) : (
                <div>
                  <div className={styles['video-upload']}>
                    {videoUrl ? (
                      <div>
                        <Player fluid={false} width={730} src={videoUrl}>
                          <BigPlayButton position="center" />
                        </Player>
                        <div>
                          <Button
                            className={styles['delete-button']}
                            label="Delete Video"
                            onClick={() => setVideoUrl('')}
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <label className={styles.upload} htmlFor="file-upload">
                          {videoUploadLoading ? (
                            <div className={styles['upload-file']}>
                              <Spin />
                              <h2>Uploading Please Wait...</h2>
                              <span>{progress}%</span>
                            </div>
                          ) : (
                            <h2>Upload Article Video</h2>
                          )}
                          <input
                            type="file"
                            id="file-upload"
                            className={styles['input-file']}
                            required
                            onChange={handleUploadVideo}
                          />
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <Form.Item label="Use External Link">
                <Switch
                  checked={useExternalLink}
                  onChange={e => setUseExternalLink(e)}
                />
              </Form.Item>
              {useExternalLink ? (
                <div>
                  <Form.Item
                    label="File Link"
                    name="document"
                    rules={[
                      {
                        required: false,
                      },
                    ]}>
                    <Input
                      value={pdfLink}
                      onChange={e => setPdfLink(e.target.value)}
                      required
                    />
                  </Form.Item>
                </div>
              ) : (
                <div className={styles['pdf-file']}>
                  <FadeEffect>
                    <ReportPDF
                      setPdfLink={setPdfLink}
                      previewUrl={pdfPreviewUrl}
                      setPreviewUrl={setPdfPreviewUrl}
                      large
                    />
                  </FadeEffect>
                </div>
              )}
            </Tabs.TabPane>
            {translatedArticles.length &&
              translatedArticles.map((data, index) => (
                <Tabs.TabPane
                  key={data.language}
                  tab={
                    supportedLanguages.find(
                      lang => lang.value === data.language,
                    ).label
                  }>
                  <TranslatedArticleContent
                    loading={loadingTranslation}
                    title={data.title}
                    fieldKey={index}
                    onChangeFields={handleChangeTranslatedValues}
                    summary={data.summary}
                    content={data.content}
                    pdfLink={data.pdfLink}
                    videoUrl={data.videoUrl}
                    videoTitle={data.videoTitle}
                    videoType={data.videoType}
                    informationSectionMultilang={
                      data.informationSectionMultilang
                    }
                    language={selectedTranslationLanguage}
                  />
                </Tabs.TabPane>
              ))}
          </Tabs>
          <SubmitButton
            handleClick={() => handleCreate()}
            disabled={createArticle}
            label="Publish Article"
          />
        </Form>
      </div>

      <DBModal
        isOpen={show}
        content={
          <div>
            <div className={styles['modal-title']}>
              <span>Information Section</span>
            </div>

            <Form onFinish={handleSave} layout="vertical">
              <Form.Item
                label="Section Name"
                className={styles.field}
                name="section name">
                <Input
                  style={{width: '470px'}}
                  value={informationTitle}
                  onChange={e => setInformationTitle(e.target.value)}
                  placeholder={'Enter Name'}
                />
              </Form.Item>
              <SubmitButton
                type="primary"
                handleClick={() => handleSave()}
                disabled={createSection}
                label="Create"
              />
            </Form>
          </div>
        }
        handleClose={() => setShow(false)}></DBModal>
    </div>
  );
};

NewLearnArticle.modules = {
  toolbar: [
    [{header: '1'}, {header: '2'}, {header: [3, 4, 5, 6]}, {font: []}],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{list: 'ordered'}, {list: 'bullet'}],
    ['link'],

    ['code-block'],
  ],
};

NewLearnArticle.format = [
  'header',
  'font',
  'bold',
  'size',
  'strike',
  'italic',
  'underline',
  'blockquote',
  'list',
  'bullet',
  'link',
  'code-block',
];

export default NewLearnArticle;
