import {useQuery} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {Table, Tag} from 'antd';
import {useParams} from 'react-router-dom';
import styles from './transactions.module.scss';

import {useState} from 'react';
import {GET_USER_DIVIDENDS_HISTORY} from 'graphql/queries/dividends';

const UserDividends = () => {
  const [currentPage, setCurrentPage] = useState(1);
  // const navigate = useNavigate();
  const {id} = useParams();
  const {loading, data} = useQuery(GET_USER_DIVIDENDS_HISTORY, {
    variables: {
      input: {
        page: currentPage,
        'limit': 10,
        userId: id,
      },
    },
  });

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const columns = [
    {
      dataIndex: 'payoutType',
      title: 'Payout Type',
      key: 'PayoutType',
    },
    {
      dataIndex: 'investmentCategory',
      title: 'Category',
      key: 'category',
    },
    {
      dataIndex: 'units',
      title: 'Number of Shared Units',
      key: 'Number of Shared',
    },
    {
      dataIndex: 'payoutCurrency',
      title: 'Currency',
      key: 'currency',
    },
    {
      dataIndex: 'amountPerShare',
      title: 'Expected Payout (Per Share)',
      key: 'amountPerShare',
      render: (text, render) => (
        <span>
          {render?.amountPerShare} {render?.payoutCurrency}
        </span>
      ),
    },
    {
      dataIndex: 'userTotalDividendPayout',
      title: 'User Total Dividend Payout',
      key: 'userTotalDividendPayout',
      render: (text, render) => (
        <span>
          {render?.payoutAmount?.toLocaleString()} {render?.payoutCurrency}
        </span>
      ),
    },
    {
      dataIndex: 'status',
      title: 'Status',
      key: 'status',
      render: (text, render) => (
        <Tag
          color={
            {
              'PAID': 'green',
              'UNPAID': 'default',
            }[render?.status] || 'blue'
          }
          key={render.status}>
          {render.status}
        </Tag>
      ),
    },
  ];

  return (
    <div>
      <div className={styles['nav-buttons']}>
        <Button
          onClick={handlePreviousPage}
          label="Previous"
          type="secondary"
          disabled={
            !data?.getUserDividendHistory?.pagination?.hasPrevPage || loading
          }
          className={styles.button}
        />
        <div className={styles['page-number']}>{currentPage}</div>
        <Button
          onClick={handleNextPage}
          label="Next"
          disabled={
            !data?.getUserDividendHistory?.pagination?.hasNextPage || loading
          }
          className={styles.button}
        />
      </div>
      <Table
        columns={columns}
        dataSource={data?.getUserDividendHistory?.data}
        loading={loading}
        pagination={false}
      />
    </div>
  );
};

export default UserDividends;
