import {message} from 'antd';
import {GET_ALL_AFFILIATE_CONFIG} from 'graphql/queries/affiliates';
import {
  resizeImageFile,
  UploadDocumentToBucket,
} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';

const {toast} = require('react-toastify');

const onTranslateContent = async (query, translateProps) => {
  const {sourceLang, targetLang, targetText1, targetText2, targetText3} =
    translateProps;
  try {
    const {data} = await query({
      variables: {
        translations: [
          {sourceLang, sourceText: targetText1, targetLang},
          {sourceLang, sourceText: targetText2, targetLang},
          {sourceLang, sourceText: targetText3, targetLang},
        ],
        treatment: 'html',
      },
    });
    return {
      header: data.translateText.translations[0],
      description: data.translateText.translations[1],
      disclosure: data.translateText.translations[2],
      language: targetLang,
    };
  } catch (error) {
    toast.error(`Something went wrong, Error: ${error}`);
  }
};

const handleTranslateAffiliateConfig = async (
  translateProps,
  multilang,
  setMultilang,
  translateText,
) => {
  const {targetLang: selectedTranslationLanguage} = translateProps;
  try {
    const newValues = [...multilang];

    //Check if translation exists
    if (newValues.length && newValues[0]) {
      // Check if we're trying to translate in the same language
      const languageExists = newValues.some(
        data => data.language === selectedTranslationLanguage,
      );
      if (languageExists) {
        const updateTranslation = await onTranslateContent(
          translateText,
          translateProps,
        );
        const translationIndex = newValues.findIndex(
          data => data.language === selectedTranslationLanguage,
        );
        newValues[translationIndex].title = updateTranslation.title;
        newValues[translationIndex].content = updateTranslation.content;
        newValues[translationIndex].language = updateTranslation.language;
        newValues[translationIndex].disclosure = updateTranslation.disclosure;
        setMultilang(newValues);
      }
    } else {
      const triggerTranslation = await onTranslateContent(
        translateText,
        translateProps,
      );
      if (!triggerTranslation) {
        return toast.error('Something went wrong when Translating');
      }
      newValues.push(triggerTranslation);
      setMultilang(newValues);
      setMultilang(newValues);
    }
  } catch (error) {
    toast.error(`Something went wrong! Error: ${error}`);
  }
};

const ErrorHandler = (image, multiLang, disclosure) => {
  if (!image) return toast.error('Image Field Missing');
  if (!disclosure) return toast.error('Disclosure Missing');
  if (!multiLang.length) return toast.error('There is no Translation');
  if (multiLang.length) {
    if (multiLang.some(data => !data.image))
      return toast.error('There is no Uploaded Translated Image');
    if (multiLang.some(data => !data.header))
      return toast.error('There is no Translated Header');
    if (multiLang.some(data => !data.description))
      return toast.error('There is no  Translated Description');
    if (multiLang.some(data => !data.disclosure))
      return toast.error('There is no  Translated Disclosure');
  }
};

const handleSaveAffiliateConfigurations = async (
  values,
  multilang,
  updateResolver,
  createResolver,
  id,
  disclosure,
  image,
  type,
  closeModal,
) => {
  const {
    header,
    description,
    affiliate,
    language,
    amount1,
    amount2,
    amount3,
    amount4,
    amount5,
  } = values;
  let resolver;
  if (type !== 'CREATE') {
    resolver = updateResolver;
  } else {
    resolver = createResolver;
  }

  if (
    !image ||
    !multilang.length ||
    (multilang &&
      multilang?.some(
        data =>
          !data?.image ||
          !data?.header ||
          !data?.description ||
          !data?.disclosure,
      ))
  ) {
    return ErrorHandler(image, multilang, disclosure);
  }
  try {
    await resolver({
      variables: {
        input: {
          header,
          description,
          language,
          disclosure,
          image,
          affiliateId: affiliate,
          ...(id && {id}),
          multilanguage: multilang?.map(data => ({
            description: data?.description,
            header: data?.header,
            language: data?.language,
            image: data?.image,
            disclosure: data?.disclosure,
          })),
          suggestedAmountMultiplier: [
            amount1,
            amount2,
            amount3,
            amount4,
            amount5,
          ].filter(data => data != null),
        },
      },
      refetchQueries: [
        {
          query: GET_ALL_AFFILIATE_CONFIG,
          variables: {
            filter: {
              page: 1,
              limit: 100,
            },
          },
        },
      ],
    })
      .then(({data}) => {
        const type =
          data?.[id ? 'updateAffiliateConfig' : 'addAffiliateConfig'];
        if (type.__typename === 'Error') {
          throw new Error(type.message);
        } else {
          toast.success('Updated Successfully');
          closeModal();
        }
      })
      .catch(error => {
        toast.error(`GraphQL Error: ${error.message}`);
      });
  } catch (error) {
    toast.error(`Something went wrong, Error: ${error.message}`);
  }
};

const handleConfigurationImageUpload = (
  file,
  handleChangeImage,
  setLoading,
  setProgress,
  organization,
) => {
  const img = new Image();
  const reader = new FileReader();

  reader.onload = e => {
    img.src = e.target.result;

    img.onload = async () => {
      const {width, height} = img;
      if (
        (width >= 398 && width <= 450 && height >= 688 && height <= 830) ||
        organization
      ) {
        setLoading(true);
        const temp = file.originFileObj;
        const resizedFile = await resizeImageFile(temp);
        const imageURL = await UploadDocumentToBucket(resizedFile, setProgress);
        handleChangeImage(imageURL);
        setLoading(false);
        setProgress(0);
      } else {
        message.error(
          'Image dimensions must be within a range of 400-450 by 780-830',
        );
      }
    };
  };

  reader.readAsDataURL(file.originFileObj);
};

export {
  handleTranslateAffiliateConfig,
  handleSaveAffiliateConfigurations,
  handleConfigurationImageUpload,
};
