import {useCallback, useState} from 'react';
import styles from './adminInterests.module.scss';
import {Button, Search} from '@dabafinance/react-components';
import {
  useNavigate,
  useMatch,
  useResolvedPath,
  Link,
  Outlet,
} from 'react-router-dom';
import classnames from 'classnames/bind';
import DBModal from 'components/modal/modal';
import {GET_ALL_TOPICS} from 'graphql/queries/interest';
import {useQuery} from '@apollo/client';
import pageNotFound from 'images/pageNotFound.png';
import {RankModal} from './rankModal';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const CustomLink = ({to, name}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({path: resolved.pathname, end: true});
  const cx = classnames.bind(styles);
  return (
    <Link className={cx('nav-link', match ? 'active-toggle' : '')} to={to}>
      <span>{name}</span>
    </Link>
  );
};

const AdminInterests = () => {
  const navigate = useNavigate();
  const [topicId, setTopicId] = useState('');
  const [openTopicModal, setOpenTopicModal] = useState(false);
  const [searchParam, setSearchParam] = useState('');
  const [searchData, setSearchData] = useState([]);

  const {data} = useQuery(GET_ALL_TOPICS);

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Topics),
  );

  const onSelect = useCallback(buttonId => {
    setOpenTopicModal(false);
    setTopicId(buttonId);
  });

  const handleSearch = term => {
    const trimmedTerm = term.trim();
    setSearchParam(trimmedTerm);

    if (term) {
      const newList = data?.getAllTopics.filter(data => {
        return Object.values(data)
          .join(' ')
          .toLowerCase()
          .includes(term.toLowerCase());
      });
      setSearchData(newList);
    } else {
      setSearchData(data?.getAllTopics);
    }
  };

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>Topics</h1>
        <div className={styles['header-buttons']}>
          <Button
            type="secondary"
            label="Rank Topics"
            disabled={!tabOperations?.update}
            className={styles.button}
            onClick={() => setOpenTopicModal(true)}
          />
          <Button
            type="primary"
            label="Create New Topic"
            disabled={!tabOperations?.create}
            className={styles.button}
            onClick={() => navigate('/dashboard/interest/add-new')}
          />
        </div>
      </section>
      <section className={styles.section}>
        <nav className={styles['interest-toggler']}>
          <CustomLink to={'/dashboard/interest/'} name="All" />
          <CustomLink to={'/dashboard/interest/topics'} name="Industries" />
          <CustomLink to={'/dashboard/interest/countries'} name="Countries" />
          <CustomLink to={'/dashboard/interest/list'} name="List" />
        </nav>
      </section>
      <Outlet />

      <div>
        <DBModal
          isOpen={openTopicModal}
          handleClose={() => setOpenTopicModal(false)}
          content={
            <div className={styles['modal-container']}>
              <h2>Select Topic</h2>
              <div>
                <Search
                  id="search-interests"
                  placeholder="Search Interests"
                  onChange={e => handleSearch(e)}
                  className={styles.search}
                />
                <div className={styles['topic-section']}>
                  {data?.getAllTopics.length >= 1 ? (
                    searchParam.length < 1 ? (
                      data?.getAllTopics?.map(({icon, name, id}) => (
                        <div key={id} className={styles.topics}>
                          <div className={styles['topic-name']}>
                            <img src={icon} alt="" />
                            <h4>{name}</h4>
                          </div>
                          <button
                            className={styles.primary}
                            onClick={() => onSelect(id)}>
                            Select
                          </button>
                        </div>
                      ))
                    ) : (
                      searchData?.map(({icon, name, id}) => (
                        // eslint-disable-next-line react/jsx-key
                        <div className={styles.topics}>
                          <div className={styles['topic-name']}>
                            <img src={icon} alt="" />
                            <h4>{name}</h4>
                          </div>
                          <button
                            className={styles.primary}
                            onClick={() => onSelect(id)}>
                            Select
                          </button>
                        </div>
                      ))
                    )
                  ) : (
                    <div className={styles['no-update']}>
                      <img src={pageNotFound} alt="" />
                      <h1>No Topics</h1>
                      <span>Add a new Interest</span>
                    </div>
                  )}
                </div>
                <Button
                  type="primary"
                  label="Continue"
                  className={styles['topic-button']}
                />
              </div>
            </div>
          }
        />
      </div>
      {topicId && <RankModal id={topicId} />}
    </div>
  );
};

export {AdminInterests};
