import {useMutation} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {Form, Input, Radio, Switch} from 'antd';
import DBModal from 'components/modal/modal';
import {CREATE_COMPANY_TEAM_MEMBER} from 'graphql/mutations/companyStartups';
import {GET_A_STARTUP} from 'graphql/queries/startupCompanies';
import {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import SubmitButton from '../components/SubmitButton';
import FounderCard from '../creation/founder/founderCard';
import UploadImage from '../profile/companyImage';
import styles from './founder.module.scss';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const FounderSection = ({team}) => {
  const [openModal, setOpenModal] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [fullname, setFullname] = useState();
  const [previewUrl, setPreviewUrl] = useState('');
  const [isCEO, setIsCEO] = useState(false);
  const [jobTitle, setJobTitle] = useState();
  const [aboutMember, setAboutMember] = useState('');
  const [gender, setGender] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [twitterUrl, setTwitterUrl] = useState('');
  const navigate = useNavigate();
  const {TextArea} = Input;
  const {id} = useParams();

  const pageOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Startups),
  );

  const options = [
    {label: 'Male', value: 'MALE'},
    {label: 'Female', value: 'FEMALE'},
  ];

  const [createCompanyTeamMember, {loading}] = useMutation(
    CREATE_COMPANY_TEAM_MEMBER,
  );

  const handleCreate = () => {
    const optionalFields = {};
    if (twitterUrl) optionalFields.twitterUrl = twitterUrl;
    if (linkedinUrl) optionalFields.linkedinUrl = linkedinUrl;
    if (imgUrl) optionalFields.displayImage = imgUrl;
    if (aboutMember) optionalFields.description = aboutMember;
    createCompanyTeamMember({
      variables: {
        input: {
          companyId: id,
          name: fullname,
          role: jobTitle,
          sex: gender,
          isCEO,
          ...optionalFields,
        },
      },
      refetchQueries: [{query: GET_A_STARTUP}, {variables: id}],
    })
      .then(({data: {createCompanyTeamMember}}) => {
        if (createCompanyTeamMember.__typename === 'Error') {
          toast.error(createCompanyTeamMember.message);
        } else {
          toast.success('Saved Successfully');
          setOpenModal(false);
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div>
          <h1>Management</h1>
        </div>
        <div>
          {team ? (
            team?.map(
              ({
                displayImage,
                linkedinUrl,
                name,
                role,
                twitterUrl,
                id,
                sex,
                description,
                isCEO,
              }) => (
                <div key={id}>
                  <FounderCard
                    description={description}
                    teamId={id}
                    image={displayImage}
                    linkedin={linkedinUrl}
                    name={name}
                    CEO={isCEO}
                    sex={sex}
                    role={role}
                    edit
                    twitter={twitterUrl}
                  />
                </div>
              ),
            )
          ) : (
            <div className={styles['no-founders']}>
              <h1>No Member</h1>
            </div>
          )}
        </div>
        <Button
          label="Proceed"
          className={styles.button}
          onClick={() => navigate('/dashboard/opportunities/startups/')}
        />
        <Button
          label="Add Member"
          className={styles.button}
          onClick={() => setOpenModal(true)}
          disabled={!pageOperations?.update}
        />
      </div>
      <div>
        <DBModal
          isOpen={openModal}
          handleClose={() => setOpenModal(false)}
          content={
            <div>
              <h1 className={styles.title}>Add Member</h1>
              <div className={styles['company-image']}>
                <UploadImage
                  previewUrl={previewUrl}
                  setPreviewUrl={setPreviewUrl}
                  setImgUrl={setImgUrl}
                  bucket="company-service-bucket"
                />
              </div>
              <div>
                <Form
                  onFinish={handleCreate}
                  className={styles.form}
                  layout="horizontal">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Member name is required',
                      },
                    ]}
                    className={styles['form-item']}
                    name="name"
                    label="Full Name">
                    <Input
                      value={fullname}
                      style={{width: 300}}
                      onChange={e => setFullname(e.target.value)}
                      placeholder="Courtney Henry"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Job Title"
                    className={styles['form-item']}
                    rules={[
                      {
                        required: true,
                        message: 'Member Job title is Required',
                      },
                    ]}
                    name="job">
                    <Input
                      className={styles.date}
                      style={{width: 300}}
                      onChange={e => setJobTitle(e.target.value)}
                      value={jobTitle}
                      placeholder={'CTO'}
                    />
                  </Form.Item>
                  <Form.Item label="Twitter" name="twitter">
                    <Input
                      onChange={e => setTwitterUrl(e.target.value)}
                      value={twitterUrl}
                      style={{width: 300}}
                      placeholder="https://twitter.com"
                    />
                  </Form.Item>
                  <Form.Item label="Linkedin">
                    <Input
                      style={{width: 300}}
                      onChange={e => setLinkedinUrl(e.target.value)}
                      value={linkedinUrl}
                      placeholder="https://linkedin.com"
                    />
                  </Form.Item>
                  <div className={styles['founder-ceo']}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Description of member is required',
                        },
                      ]}
                      className={styles['form-item']}
                      name="about"
                      label="About">
                      <TextArea
                        style={{width: 320}}
                        value={aboutMember}
                        onChange={e => setAboutMember(e.target.value)}
                        rows={4}
                      />
                    </Form.Item>
                    <div className={styles['founder-status']}>
                      <Form.Item style={{marginRight: 20}} label="CEO">
                        <Switch onChange={e => setIsCEO(e)} />
                      </Form.Item>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        name="sex"
                        label="Sex">
                        <Radio.Group
                          value={gender}
                          onChange={e => setGender(e.target.value)}
                          options={options}
                          optionType="button"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <SubmitButton label="Create Member" disabled={loading} />
                  </div>
                </Form>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default FounderSection;
