import {useCallback, useEffect, useState} from 'react';
import backIcon from 'images/back.png';
import styles from './createTransaction.module.scss';
import {useNavigate, useParams} from 'react-router-dom';
import {Form, Input, Select, Spin} from 'antd';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {GET_ALL_CAMPAIGNS, CONVERT_CURRENCY} from 'graphql/queries/campaign';
import {CREATE_USER_TRADE} from 'graphql/mutations/trades';
import {toast} from 'react-toastify';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {USER_TRANSACTIONS} from 'graphql/queries/users';
import FadeEffect from 'components/animation/fadeEffect';
import {paymentMethodOptions, transactionStatusOptions} from 'utils/constants';

const CreateTransaction = () => {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [transactionAmount, setTransactionAmount] = useState('');
  const [investedAmountInAssetCurrency, setInvestedAmountInAssetCurrency] =
    useState('');
  const [fundStatus, setFundStatus] = useState('');
  const [selectedCampaignId, setSelectedCampaignId] = useState('');
  const [type, setType] = useState('');
  const [transactionCurrency, setTransactionCurrency] = useState('');
  const [transactionCurrencies, setTransactionCurrencies] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [baseCurrency, setBaseCurrency] = useState({});
  const [conversionResult, setConversionResult] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalFees, setTotalFee] = useState(0);
  const [walletAddress, setWalletAddress] = useState('');
  const [offeringId, setOfferingId] = useState('');
  const [coinType, setCoinType] = useState('');
  const [appliedFxRate, setAppliedFxRate] = useState('');
  const navigate = useNavigate();
  const {id} = useParams();
  const {data, loading} = useQuery(GET_ALL_CAMPAIGNS);

  const [convertCurrency] = useLazyQuery(CONVERT_CURRENCY);
  const [createTrade, {loading: loadingCreation}] =
    useMutation(CREATE_USER_TRADE);

  const handleCreateTrade = async () => {
    const optionalFields = {};
    if (fundStatus) optionalFields.transactionStatus = fundStatus;

    if (coinType) optionalFields.cryptoCoinType = coinType.trim();
    if (walletAddress) optionalFields.walletAddress = walletAddress.trim();
    if (offeringId) optionalFields.offeringId = offeringId;
    const convertAmount = await handleConversion();
    if (convertAmount) {
      createTrade({
        variables: {
          input: {
            paymentMethod,
            campaignId: selectedCampaignId,
            investedAmount: Number(investedAmountInAssetCurrency),
            investedAmountInTransactionCurrency: Number(transactionAmount),
            type,
            userId: id,
            assetCurrency: baseCurrency?.currencyCode,
            transactionCurrency,
            transactionCurrencyToAssetCurrencyFx: appliedFxRate.toString(),
            ...optionalFields,
          },
        },
        refetchQueries: [
          {
            query: USER_TRANSACTIONS,
            variables: {
              userId: id,

              getUserByIdId: id,
            },
          },
        ],
      })
        .then(({data: {createTrade}}) => {
          if (createTrade.__typename === 'Error') {
            toast.error(createTrade.message);
          } else {
            toast.success('Trade Created Successfully');
            navigate(-1);
          }
        })
        .catch(error => toast.error(error.message));
    }
  };

  const handleSelectCampaign = campaignId => {
    setSelectedCampaignId(campaignId);
    const selectedCampaign = data?.getAllCampaigns?.campaigns?.find(
      singleCampaign => singleCampaign.id === campaignId,
    );
    setType(selectedCampaign?.listingType);
    setOfferingId(selectedCampaign?.offeringId);
    setTransactionCurrencies(
      selectedCampaign?.fxTransactionRatesOnSupportedCurrencies.map(item => {
        return {
          currency: item.currency,
          currencyConversionMode: item.currencyConversionMode,
          fixedFxRateToAssetCurrency: item.fixedFxRateToAssetCurrency,
          variableFxRateToAssetCurrency: item.variableFxRateToAssetCurrency,
        };
      }),
    );
    return setSelectedCampaign(selectedCampaign);
  };

  const calculateUnits = removeSuffix => {
    const potentialAmount = Number(transactionAmount);
    let units = 0;
    if (selectedCampaign.listingType === 'IPO') {
      const pricePerShare = Number(selectedCampaign?.ipoFields?.ipoSharePrice);
      units = (potentialAmount / pricePerShare)?.toFixed(2);
    } else {
      units = (
        potentialAmount / Number(selectedCampaign?.pricePerSecurity)
      )?.toFixed(2);
    }
    return removeSuffix ? units : `${units} unit${units === 1 ? '' : 's'}`;
  };

  const calculateTotalAmountAndFees = useCallback(() => {
    const totalAmount = Number(transactionAmount);
    let totalFees = 0;
    if (selectedCampaign?.listingType === 'IPO') {
      selectedCampaign?.investmentTerms?.feeSchedule?.forEach(fee => {
        if (fee?.feeType === 'FIXED') {
          totalFees += Number(fee?.fixedFeeAmount);
        } else if (fee?.feeType === 'VARIABLE') {
          totalFees += (Number(fee?.variableFeeAmount) / 100) * totalAmount;
        }
      });
    }
    return {
      totalAmount: totalAmount + totalFees,
      totalFees,
    };
  }, [selectedCampaign, transactionAmount]);

  useEffect(() => {
    const {totalAmount, totalFees} = calculateTotalAmountAndFees();
    setTotalAmount(totalAmount);
    setTotalFee(totalFees);
  }, [calculateTotalAmountAndFees]);

  useEffect(() => {
    setBaseCurrency(selectedCampaign?.assetCurrency);
  }, [selectedCampaign]);

  const handleConversion = () => {
    const selectedCurrency = transactionCurrencies.find(
      item => item?.currency?.currencyCode === transactionCurrency,
    );
    const amount = parseFloat(transactionAmount).toFixed(2);
    return convertCurrency({
      variables: {
        input: {
          amount: parseFloat(amount),
          currencyConversionMode: selectedCurrency?.currencyConversionMode,
          fixedFxRateToAssetCurrency:
            selectedCurrency?.currencyConversionMode === 'FIXED'
              ? selectedCurrency.fixedFxRateToAssetCurrency
              : 0,
          from: transactionCurrency,
          to: baseCurrency?.currencyCode,
          variableFxRateToAssetCurrency:
            selectedCurrency?.currencyConversionMode === 'VARIABLE'
              ? selectedCurrency.variableFxRateToAssetCurrency
              : 0,
        },
      },
    })
      .then(({data: {convertCurrencies}}) => {
        if (convertCurrencies.__typename === 'Error') {
          toast.error(convertCurrencies.message);
        } else {
          setConversionResult({
            appliedFxRate: convertCurrencies?.appliedFxRate,
            investedAmountInAssetBaseCurrency:
              convertCurrencies?.investedAmountInAssetBaseCurrency,
          });
          return {
            appliedFxRate: convertCurrencies?.appliedFxRate,
            investedAmountInAssetBaseCurrency:
              convertCurrencies?.investedAmountInAssetBaseCurrency,
          };
        }
      })
      .catch(error => toast.error(error.message));
  };

  useEffect(() => {
    setAppliedFxRate(conversionResult?.appliedFxRate);
    setInvestedAmountInAssetCurrency(
      conversionResult?.investedAmountInAssetBaseCurrency,
    );
  }, [conversionResult]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={backIcon} alt="" onClick={() => navigate(-1)} />
        <h1>Create a Transaction</h1>
      </div>
      <div>
        <Form onFinish={handleCreateTrade} layout="vertical">
          <Form.Item
            className={styles.width}
            name="campaign"
            label="Select Campaign"
            rules={[{required: true, message: 'Please select a campaign'}]}>
            <Select
              value={selectedCampaignId}
              onChange={e => handleSelectCampaign(e)}>
              {data?.getAllCampaigns?.campaigns
                ?.filter(
                  data =>
                    data?.campaignMode === 'LIVE' &&
                    data?.campaignStatus !== 'DRAFT',
                )
                .map(data => (
                  <Select.Option key={data?.id}>
                    <div className={styles['opt-root']}>
                      <div className={styles['opt-name']}>
                        <img
                          src={data?.fundraisingCompany?.logoImgURL}
                          alt=""
                        />
                        <span>{data?.fundraisingCompany?.companyName}</span>
                      </div>
                      <div className={styles.status}>
                        <span>
                          -{data?.listingType}(
                          {data?.campaignStatus.toLowerCase()})
                        </span>
                      </div>
                    </div>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {baseCurrency && (
            <Form.Item
              name="baseCurrency"
              label="Campaign Base Currency"
              className={styles.width}>
              <Input
                value={baseCurrency?.name}
                defaultValue={baseCurrency?.name}
                readOnly
              />
            </Form.Item>
          )}
          <Form.Item
            rules={[
              {required: true, message: 'Please select a transaction currency'},
            ]}
            name="type"
            label="Transaction Currency (Please note that the campaign currency settings has to be set
              first)"
            className={styles.width}>
            <Select
              value={transactionCurrency}
              disabled={!selectedCampaignId}
              onChange={e => setTransactionCurrency(e)}
              placeholder="Please select transaction currency">
              {transactionCurrencies.map(data => (
                <Select.Option key={data?.currency?.currencyCode}>
                  <span>{data?.currency?.name}</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {required: true, message: 'Please select a payment method'},
            ]}
            name="paymentMethod"
            label="Payment Method"
            className={styles.width}>
            <Select
              disabled={!selectedCampaignId}
              value={paymentMethod}
              onChange={e => setPaymentMethod(e)}>
              {paymentMethodOptions.map(data => (
                <Select.Option key={data.value}>
                  <span>{data.label}</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {required: true, message: 'Please provide invested amount'},
            ]}
            name="transactionAmount"
            label="Invested Amount in Transaction Currency"
            className={styles.width}>
            <Input
              disabled={!selectedCampaignId}
              value={transactionAmount}
              onChange={e => setTransactionAmount(e.target.value)}
            />
          </Form.Item>
          {(paymentMethod === 'CRYPTO_MANUAL' ||
            paymentMethod === 'CRYPTO_AUTO') && (
            <FadeEffect>
              <Form.Item
                name="walletAddress"
                label="Wallet Address"
                className={styles.width}>
                <Input
                  disabled={!selectedCampaignId}
                  value={walletAddress}
                  onChange={e => setWalletAddress(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="coinType"
                label="Crypto Coin Type"
                className={styles.width}>
                <Select
                  disabled={!selectedCampaignId}
                  value={coinType}
                  onChange={e => setCoinType(e)}>
                  {data?.getDabaManualCryptoDetails?.accounts?.map(data => (
                    <Select.Option key={data?.symbol}>
                      <div className={styles['opt-root']}>
                        <span>
                          {data?.label} - {data?.network}
                        </span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </FadeEffect>
          )}

          <Form.Item
            name="status"
            label="Transaction Status"
            className={styles.width}>
            <Select
              disabled={!selectedCampaignId}
              value={fundStatus}
              onChange={e => setFundStatus(e)}>
              {transactionStatusOptions.map(data => (
                <Select.Option key={data.value}>
                  <span>{data.label}</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div>
            <h3>Fee Breakdown</h3>
            <table className={styles.table}>
              <tr>
                <th className={styles['table-head']}>Transaction Units </th>
                <th className={styles['table-head']}>Transaction Fee</th>
                <th className={styles['table-head']}>Transaction Amount </th>
              </tr>
              <tr className={styles['table-row']}>
                <td className={styles['table-body']}>
                  {calculateUnits(true) || 0}
                </td>
                <td className={styles['table-body']}>
                  {totalFees.toLocaleString()}
                </td>
                <td className={styles['table-body']}>
                  {totalAmount.toLocaleString()}
                </td>
              </tr>
            </table>
          </div>
          <SubmitButton disabled={loadingCreation} label="Create Trade" />
        </Form>
      </div>
    </div>
  );
};

export default CreateTransaction;
