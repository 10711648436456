/* eslint-disable no-undef */
import styles from './newsTranslation.module.scss';
import {Form, Input, Select, Spin} from 'antd';
import {TLDREdit} from 'components/tldrEdit';
import ReactQuill from 'react-quill';
import {QUILL_FORMATS, QUILL_MODULES} from 'utils/constants';

const NewsTranslation = ({data, loading, fieldKey, onChangeFields}) => {
  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading Translation...</h1>
      </div>
    );
  }
  return (
    <div className={styles.root}>
      <Form.Item label="Translated Title" className={styles.inputField}>
        <Input
          placeholder="Translated Title"
          onChange={e => onChangeFields(fieldKey, e)}
          value={data.title}
          name="title"
        />
      </Form.Item>
      <Form.Item label="Translated Slug (Please avoid spaces and special characters)">
        <Input
          value={data?.slug?.replace(/\s+/g, '-').replace(/[^-a-zA-Z0-9]/g, '')}
          name="slug"
          onChange={e => onChangeFields(fieldKey, e)}
          placeholder="News Slug"
        />
      </Form.Item>
      <Form.Item label=" Translated Meta Description">
        <Input
          value={data?.metaDescription}
          name="metaDescription"
          onChange={e => onChangeFields(fieldKey, e)}
          placeholder="Type in the multi lang meta description"
        />
      </Form.Item>
      <Form.Item label="Translated Image Alt Text">
        <Input
          value={data?.imageAltText}
          name="imageAltText"
          onChange={e => onChangeFields(fieldKey, e)}
          placeholder="Type in the image Alt Text"
        />
      </Form.Item>

      <TLDREdit
        content={data.content}
        summary={data.summary}
        onChangeSummaries={summary =>
          onChangeFields(fieldKey, summary, 'summary')
        }
      />

      <Form.Item label="Translated Content" className={styles.inputField}>
        <ReactQuill
          modules={NewsTranslation.modules}
          formats={NewsTranslation.format}
          onChange={e => onChangeFields(fieldKey, e, 'content')}
          className={styles.quill}
          theme="snow"
          value={data.content}
          rows={6}
        />
      </Form.Item>
      <div className={styles['quill-container']}>
        <span>Translated Key Takeaway</span>
        <ReactQuill
          className={styles.quill}
          theme="snow"
          modules={NewsTranslation.modules}
          formats={NewsTranslation.format}
          onChange={e => onChangeFields(fieldKey, e, 'keyTake')}
          placeholder="Write Something"
          value={data.keyTake}
        />
      </div>
      <Form.Item label="Keywords" className={styles.largeWidth}>
        <Select
          showSearch
          value={data.keywords}
          onChange={e => onChangeFields(fieldKey, e, 'keywords')}
          mode="tags"
          className={styles['select-height']}
        />
      </Form.Item>
    </div>
  );
};

NewsTranslation.modules = QUILL_MODULES;
NewsTranslation.format = QUILL_FORMATS;

export default NewsTranslation;
