import {Form, Input, InputNumber, Select, Spin, Upload} from 'antd';
import imgUpload from 'images/upload_image.png';
import styles from './campaignAnalog.module.scss';
import deleteIcon from 'images/delete.png';
import {Button} from '@dabafinance/react-components';

const AnalogCard = ({
  handleDeleteAnalog,
  handleDeleteImage,
  handleChange,
  handleChangeImage,
  name,
  loadingImageUpload,
  handleNumberChange,
  type,
  valuation,
  previewImage,
  description,
  handleChangeCompanyType,
}) => {
  const {Option} = Select;

  return (
    <>
      <div className={styles['card-container']}>
        <div className={styles['image-section']}>
          {previewImage ? (
            <div className={styles['c-image-uploaded']}>
              <img src={previewImage} alt="" />
              <Button
                className={styles['delete-button']}
                label="Change Image"
                type="secondary"
                onClick={handleDeleteImage}
              />
            </div>
          ) : (
            <Upload
              accept=".png, .jpeg, .jpg"
              name="file"
              onChange={handleChangeImage}>
              <div className={styles['c-company-upload']}>
                {loadingImageUpload ? (
                  <div className={styles['loading-spinner']}>
                    <Spin size="large" />
                    <h2>Loading...</h2>
                  </div>
                ) : (
                  <>
                    <img
                      className={styles['company-image']}
                      src={imgUpload}
                      alt="upload"
                    />
                    <div style={{marginTop: 6}}>Upload Image</div>
                  </>
                )}
              </div>
            </Upload>
          )}
        </div>
        <div className={styles['form']}>
          <Form layout="vertical">
            <div>
              <Form.Item label="Company Name">
                <Input
                  value={name}
                  onChange={e => handleChange?.(e)}
                  className={styles['name-input']}
                  name="name"
                />
              </Form.Item>
              <Form.Item
                className={styles['select-input']}
                label="Company Type">
                <Select
                  value={type}
                  placeholder="Select Company type"
                  onChange={e => handleChangeCompanyType?.(e)}>
                  <Option key="PUBLIC">Public</Option>
                  <Option key="PRIVATE">Private</Option>
                  <Option key="ACQUIRED">Acquired</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Company Valuation">
                <InputNumber
                  value={valuation}
                  className={styles['input-number']}
                  onChange={e => handleNumberChange?.(e)}
                  formatter={value =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </div>
            <Form.Item label="Description">
              <Input.TextArea
                value={description}
                onChange={e => handleChange?.(e)}
                rows={4}
                name="description"
              />
            </Form.Item>
          </Form>
        </div>
        <div className={styles['delete']}>
          <img
            onClick={e => {
              e.stopPropagation();
              handleDeleteAnalog?.();
            }}
            src={deleteIcon}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default AnalogCard;
