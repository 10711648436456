import {Form, Input, Select} from 'antd';
import styles from '../listingIPO.module.scss';
import deleteIcon from 'images/delete.png';

const IpoFeeCard = ({
  feeName,
  feeType,
  fixedFeeAmount,
  variableFeeAmount,
  handleChangeIpoFeesValues,
  handleChangeIpoFeesType,
  type,
  handleDeleteIpoFees,
}) => {
  return (
    <div className={styles['card-container']}>
      <div className={styles['left-grid']}>
        <Form.Item
          label="Fee Name"
          rules={[
            {
              required: true,
              message: 'Required Field',
            },
          ]}
          className={styles['card-item']}>
          <Input
            placeholder="Fee Name"
            value={feeName}
            onChange={e => handleChangeIpoFeesValues?.(e)}
            name="feeName"
          />
        </Form.Item>
        <Form.Item label="Fee Type" className={styles['card-item']}>
          <Select
            placeholder="select fee type"
            value={feeType}
            onChange={e => handleChangeIpoFeesType?.(e)}
            name="feeType">
            <Select.Option value="FIXED">Fixed</Select.Option>
            <Select.Option value="VARIABLE">Variable</Select.Option>
            {type === 'COMPANY_STOCK' && (
              <>
                <Select.Option value="FLOATING">Floating</Select.Option>
                <Select.Option value="STATIC">Static</Select.Option>
              </>
            )}
          </Select>
        </Form.Item>

        <Form.Item label="Fixed Fee Amount" className={styles['card-item']}>
          <Input
            type="number"
            placeholder="Input the fixed fee amount"
            disabled={
              feeType === 'VARIABLE' ||
              feeType === 'FLOATING' ||
              !feeType ||
              Number(variableFeeAmount)
            }
            value={fixedFeeAmount}
            name="fixedFeeAmount"
            onChange={e => handleChangeIpoFeesValues?.(e)}
          />
        </Form.Item>

        <Form.Item
          label="Variable Fee Amount (%)"
          className={styles['card-item']}>
          <Input
            placeholder="Input variable fee amount"
            type="number"
            name="variableFeeAmount"
            disabled={
              (feeType === 'FIXED' && !fixedFeeAmount) ||
              !feeType ||
              Number(fixedFeeAmount)
            }
            value={variableFeeAmount}
            onChange={e => handleChangeIpoFeesValues?.(e)}
          />
        </Form.Item>
      </div>
      <div className={styles['delete-icon']}>
        <img onClick={() => handleDeleteIpoFees?.()} src={deleteIcon} alt="" />
      </div>
    </div>
  );
};

export default IpoFeeCard;
