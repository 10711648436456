import {useState} from 'react';
import {DELETE_COLLECTION} from 'graphql/mutations/collections';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import moment from 'moment';
import dotsIcon from 'icons/dots.svg';
import {Dropdown, Menu, Pagination, Table, Tag} from 'antd';
import {useEffect} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {GET_ALL_COLLECTIONS} from 'graphql/queries/collections';
import styles from './collection.module.scss';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';
import {useSelector} from 'react-redux';

const CollectionOverview = () => {
  const [limit, setLimit] = useState(10);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [collectionId, setCollectionId] = useState(null);
  const [deleteCollection, {loading: loadingDelete}] =
    useMutation(DELETE_COLLECTION);
  const navigate = useNavigate();

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Collections),
  );

  const {loading, refetch, data} = useQuery(GET_ALL_COLLECTIONS, {
    variables: {
      filters: {
        paginate: true,
      },
    },
    errorPolicy: 'all',
    onError: error => toast.error(error?.message),
  });

  const handleDelete = () => {
    try {
      deleteCollection({
        variables: {
          collectionId,
        },
      }).then(({data}) => {
        if (data?.deleteCollection?.__typename === 'Error') {
          return toast.error(data?.deleteCollection?.message);
        } else {
          toast.success('Collection deleted successfully');
          setOpenConfirmModal(false);
          refetch();
        }
      });
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handlePageChange = page => {
    if (page === currentPage) return;
    setCurrentPage(page);
    refetch({
      filters: {
        paginate: true,
        page: page,
        limit: limit,
      },
    });
  };

  useEffect(() => {
    setLimit(10);
  }, []);

  const handleLimitChange = size => {
    if (size === limit) return;
    setLimit(size);
    refetch({
      filters: {
        paginate: true,
        page: currentPage,
        limit: size,
      },
    });
  };

  const menu = id => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => navigate(`/dashboard/collections/${id}/companies`)}>
          <div className={styles['menu-items']}>
            <span>View Collection</span>
          </div>
        </Menu.Item>
        <Menu.Item
          onClick={() => navigate(`/dashboard/collections/update/${id}`)}>
          <div className={styles['menu-items']}>
            <span>Edit Collection</span>
          </div>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setCollectionId(id);
            setOpenConfirmModal(true);
          }}>
          <div className={styles['menu-items']}>
            <span>Delete Collection</span>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: 'Collection name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <div className={styles.iconDiv}>
            <img src={record?.iconUrl} className={styles.icon} />
          </div>
          <span>{record?.name}</span>
        </div>
      ),
    },
    {
      title: 'No of companies',
      dataIndex: 'purchasedUnits',
      key: 'purchasedUnits',
      render: (text, record) => (
        <div>
          <span>{record?.companies.length}</span>
        </div>
      ),
    },
    {
      title: 'Industry',
      dataIndex: 'disbursedUnits',
      key: 'disbursedUnits',
      render: (text, record) => (
        <div>
          <span>{record?.industry?.name}</span>
        </div>
      ),
    },
    {
      title: 'Date created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record) => (
        <div>
          <span>
            {moment(record?.createdAt).format('DD MMM, YYYY. H:mm A')}
          </span>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'unitsLeft',
      key: 'unitsLeft',
      render: (text, record) => (
        <div>
          <span>
            {record?.status === 'Active' ? (
              <Tag color="green">{record?.status}</Tag>
            ) : (
              <Tag color="red">{record?.status}</Tag>
            )}
          </span>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <div className={styles.actions}>
          {tabOperations?.update && (
            <Dropdown overlay={menu(record?.id)} placement="bottomLeft">
              <div className={styles['select-btn']}>
                <img style={{cursor: 'pointer'}} src={dotsIcon} />
              </div>
            </Dropdown>
          )}
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className={styles['table-container']}>
        <h3 className={styles['table-header']}>Collection List</h3>
        <Table
          className={styles['user-table']}
          dataSource={data?.adminGetAllCollections?.data?.collections}
          columns={columns}
          pagination={false}
          loading={loading}
        />
        <Pagination
          current={currentPage}
          onChange={e => handlePageChange(e)}
          total={data?.adminGetAllCollections?.data?.pagination?.total}
          showQuickJumper
          className="ant-table-pagination"
          showSizeChanger
          pageSize={limit}
          onShowSizeChange={(_, size) => {
            handleLimitChange(size);
          }}
        />
      </div>
      <ConfirmModal
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        title={'Delete Collection'}
        confirmText={'Are you sure that you want to DELETE this collection?'}
        confirmation={true}
        loading={loadingDelete}
        handleOperation={handleDelete}
      />
    </div>
  );
};

export default CollectionOverview;
