import {useEffect, useRef, useState} from 'react';
import news from 'images/no-image.jpg';
import styles from './newscard.module.scss';
import check from 'images/check-circle.svg';
import inactiveIcon from 'images/inactive-circle.svg';
import trash from 'images/trash-can-outline.svg';
import moment from 'moment';
import editIcon from '../../../../../icons/edit_icon2.svg';
import {Dropdown, Menu} from 'antd';
import {useMutation} from '@apollo/client';
import {CATEGORIZE_NEWS, DELETE_NEWS} from 'graphql/mutations/news';
import DBModal from 'components/modal/modal';
import {Button} from '@dabafinance/react-components';
import {toast} from 'react-toastify';
import {GET_ALL_NEWS} from 'graphql/queries/news';
import {useNavigate} from 'react-router-dom';

const NewsCard = ({
  title,
  image,
  id,
  date,
  keywords,
  link,
  companies,
  topics,
  operations,
  breakingNews,
  selectedNews,
  trendingNews,
  newsSource,
}) => {
  const activePlaceholder = 'Select';
  const [showMore, setShowMore] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [activeNews, setActiveNews] = useState(activePlaceholder);
  const parentRef = useRef();
  const navigate = useNavigate();
  const [categorizeNews] = useMutation(CATEGORIZE_NEWS);
  const [deleteNews] = useMutation(DELETE_NEWS);

  useEffect(() => {
    if (breakingNews) setActiveNews('Br News');
    else if (trendingNews) setActiveNews('Tr News');
    else if (selectedNews) setActiveNews('Lt News');
  }, [breakingNews, trendingNews, selectedNews]);

  const handleCatagorizeNews = () => {
    categorizeNews({
      variables: {
        data: {
          id,
          isBreakingNews: activeNews === 'Br News',
          isTrendingNews: activeNews === 'Tr News',
          isSelectedNews: activeNews === 'Lt News',
        },
      },
      refetchQueries: [{query: GET_ALL_NEWS}],
    })
      .then(({data: {categorizeNews}}) => {
        if (categorizeNews.__typename === 'Error') {
          toast.error(categorizeNews.message);
        } else {
          toast.success('Added Successfully');
          setOpenModal(false);
        }
      })
      .catch(error => toast.error(error));
  };

  const handleEditNews = () => {
    navigate(`/dashboard/explore/news/update-news/${id}`);
  };

  const handleDelete = () => {
    deleteNews({
      variables: {
        deleteNewsId: id,
      },
      update(cache) {
        cache.modify({
          fields: {
            getAllNews() {},
          },
        });
      },
    })
      .then(({data: {deleteNews}}) => {
        if (deleteNews.__typename === 'Error') {
          toast.error(deleteNews.message);
        } else {
          toast.success('Deleted Successfully');
          setOpenDeleteModal(false);
        }
      })
      .catch(error => toast.error(error));
  };

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setOpenModal(true);
          setActiveNews('Br News');
        }}>
        Breaking News
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setOpenModal(true);
          setActiveNews('Tr News');
        }}>
        Trending News
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setActiveNews('Lt News');
          setOpenModal(true);
        }}>
        Latest News
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles['card-container']}>
      <div>
        <img className={styles['news-image']} src={image || news} alt="" />
      </div>
      <div className={styles.container}>
        <div>
          <div>
            <div className={styles['right-grid']}>
              <h1>{title}</h1>
            </div>
          </div>
          <div className={styles['bottom-view']}>
            {!showMore && (
              <div>
                <span className={styles['gray-text']}>
                  Time: {moment(date).fromNow()}
                </span>
                <div
                  style={{cursor: 'pointer'}}
                  onClick={() => setShowMore(!showMore)}>
                  <span className={styles['gray-text']}>
                    Source: {newsSource?.name}
                  </span>
                </div>
              </div>
            )}
            <div
              style={
                showMore
                  ? {
                      height: parentRef.current.scrollHeight + 'px',
                    }
                  : {
                      height: '0px',
                    }
              }
              className={styles.content}
              ref={parentRef}>
              <div
                style={{cursor: 'pointer'}}
                onClick={() => setShowMore(!showMore)}>
                <div>
                  <span className={styles['gray-text']}>Keywords: </span>
                  {keywords.map((name, index) => (
                    <span
                      key={index}
                      className={styles['gray-text']}>{`${name} `}</span>
                  ))}
                </div>

                <a
                  className={styles.link}
                  href={link}
                  target="_blank"
                  rel="noreferrer">
                  Link: {link.substring(0, 50)}...
                </a>
              </div>
              <div className={styles.footer}>
                <div>
                  <div className={styles['add-class']}>
                    <h3>Related Categories ({topics ? topics?.length : 0})</h3>
                  </div>
                </div>
                <div>
                  <div className={styles['add-class']}>
                    <h3>
                      Related Companies ({companies ? companies?.length : 0})
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles['right-grid']}>
            {operations?.update && (
              <Dropdown overlay={menu} placement="bottomLeft">
                <div className={styles['select-btn']}>
                  <img
                    src={activeNews === 'Select' ? inactiveIcon : check}
                    alt=""
                  />
                  <h3 className={styles.select}>{activeNews}</h3>
                </div>
              </Dropdown>
            )}
            {operations?.delete && (
              <img
                onClick={() => setOpenDeleteModal(true)}
                className={styles.trash}
                src={trash}
                alt=""
              />
            )}
            {operations?.update && (
              <img
                onClick={handleEditNews}
                src={editIcon}
                className={styles.trash}
                alt=""
              />
            )}
          </div>
          <div>
            <div>
              <div>
                <DBModal
                  isOpen={openModal}
                  handleClose={() => setOpenModal(false)}
                  content={
                    <div className={styles['modal-container']}>
                      <h1>Are you sure You want to Categorize this News?</h1>
                      <div>
                        <Button
                          className={styles['modal-button']}
                          onClick={handleCatagorizeNews}
                          label={'Yes'}
                        />
                        <Button
                          className={styles['modal-button']}
                          type="secondary"
                          onClick={() => setOpenModal(false)}
                          label="No"
                        />
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
            <div>
              <DBModal
                isOpen={openDeleteModal}
                handleClose={() => setOpenDeleteModal(false)}
                content={
                  <div className={styles['modal-container']}>
                    <h1>Are you sure You want to delete this News?</h1>
                    <div>
                      <Button
                        className={styles['modal-button']}
                        onClick={handleDelete}
                        label={'Yes'}
                      />
                      <Button
                        className={styles['modal-button']}
                        type="secondary"
                        onClick={() => setOpenDeleteModal(false)}
                        label="No"
                      />
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;
