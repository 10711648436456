import {Form, Input, Select, Spin, Switch} from 'antd';
import {useEffect, useState} from 'react';
import styles from '../createWiredAccount/bankSettings.module.scss';
import backImgIcon from 'images/back.png';
import {useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {UPDATE_BANK_ACCOUNT} from 'graphql/mutations/bankAndCrypto';
import {toast} from 'react-toastify';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {
  GET_BANK_ACCOUNT_BY_ID,
  GET_WIRE_TRANSFER_ACCOUNTS,
} from 'graphql/queries/bankAndCrypto';
import FadeEffect from 'components/animation/fadeEffect';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import {Button} from '@dabafinance/react-components';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import {UploadVideoToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import {walletCurrencies} from 'utils/mock';
import UploadImage from 'components/Startup/profile/companyImage';
import {GET_ALL_COUNTRIES} from 'graphql/queries/users';
import {convertEmptyStringsToNull} from 'utils/helper';

const UpdateWiredAccount = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [isActive, setIsActive] = useState(false);
  const [loadingPdfUpload, setLoadingPdfUpload] = useState(false);
  const [viewPdf, setViewPdf] = useState(null);
  const [pdfName, setPdfName] = useState('Supported formats - PDF');
  const [pdfLink, setPdfLink] = useState('');
  const [pdfFile, setPdfFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [bankLogo, setBankLogo] = useState('');
  const [previewBankLogo, setPreviewBankLogo] = useState('');
  const [flagImage, setFlagImage] = useState('');
  const [previewFlagImage, setPreviewFlagImage] = useState('');
  const [country, setCountry] = useState('');

  const {id} = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const {data: getAllCountries, loading: loadingCountries} =
    useQuery(GET_ALL_COUNTRIES);
  const [updateWireTransferAccount, {loading: loadingUpdate}] =
    useMutation(UPDATE_BANK_ACCOUNT);
  const {data, loading} = useQuery(GET_BANK_ACCOUNT_BY_ID, {
    variables: {
      getWireTransferAccountByIdId: id,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (country.split('~')[2]) {
      setFlagImage(country?.split('~')[2]);
      setPreviewFlagImage(country?.split('~')[2]);
    }
  }, [country]);

  useEffect(() => {
    if (data?.getWireTransferAccountById?.data) {
      const {
        currency,
        flag,
        bankLogo,
        beneficiaryAddress,
        accountNumber,
        accountType,
        swiftCode,
        bankAddress,
        bankName,
        countryName,
        countryCode,
        pdfDocument,
        sortCode,
        iban,
        rib,
        bankCode,
        bic,
        beneficiaryName,
        beneficiaryBankName,
        routingNumber,
        accountName,
        referenceDetails,
      } = data?.getWireTransferAccountById?.data;
      form.setFieldsValue({
        accountName,
        bankName,
        sortCode,
        bic,
        beneficiaryAddress,
        iban,
        rib,
        bankCode,
        beneficiaryName,
        beneficiaryBankName,
        routingNumber,
        referenceDetails,
        currency,
        accountType,
        swiftCode,
        bankAddress,
        accountNumber,
      });
      setFlagImage(flag);
      setPreviewFlagImage(flag);
      setBankLogo(bankLogo);
      setPreviewBankLogo(bankLogo);
      setPdfFile(pdfDocument);
      setPdfLink(pdfDocument);
      setCountry(`${countryName}~${countryCode}`);
      setIsActive(data?.getWireTransferAccountById?.data?.isActive);
    }
  }, [data?.getWireTransferAccountById?.data]);

  const fileType = ['application/pdf'];

  const handlePdfFileChange = async e => {
    const selectedFile = e.target.files[0];
    setPdfName(selectedFile.name);
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        setLoadingPdfUpload(true);
        const imageUrl = await UploadVideoToBucket(
          selectedFile,
          'accounts-pdf-bucket',
          setProgress,
        );
        setPdfLink(imageUrl);
        setPdfFile(imageUrl);
        setLoadingPdfUpload(false);
      } else {
        setPdfFile(null);
        toast.error('Please select a valid pdf file');
      }
    } else {
      toast.error('Select a PDF file');
    }
  };

  const handleDeleteFile = () => {
    setPdfFile('');
    setPdfLink('');
    setPdfName('Supported formats pdf, docs');
    setViewPdf('');
  };

  const handlePdfFileSave = e => {
    e.preventDefault();
    setViewPdf(pdfFile);
  };

  const handleSubmit = values => {
    const optionalFields = {};
    const formattedValues = convertEmptyStringsToNull(values);

    const {
      bic,
      bankCode,
      beneficiaryAddress,
      rib,
      iban,
      accountName,
      beneficiaryName,
      beneficiaryBankName,
      swiftCode,
      sortCode,
      accountNumber,
      referenceDetails,
    } = formattedValues;
    if (bankLogo) optionalFields.bankLogo = bankLogo;
    if (pdfLink) optionalFields.pdfDocument = pdfLink;

    updateWireTransferAccount({
      variables: {
        input: {
          id,
          accountName,
          accountNumber,
          beneficiaryAddress,
          isActive,
          bic,
          bankCode,
          rib,
          swiftCode,
          beneficiaryBankName,
          iban,
          beneficiaryName,
          referenceDetails,
          sortCode,
          flag: flagImage,
          bankLogo: bankLogo,
          countryCode: country?.split('~')[1],
          countryName: country?.split('~')[0],
          currency: values.currency,
          routingNumber: values?.routingNumber?.trim(),
          bankAddress: values?.bankAddress?.trim(),
          accountType: values.accountType,
          ...optionalFields,
          bankName: values?.bankName?.trim(),
        },
      },
      refetchQueries: [{query: GET_WIRE_TRANSFER_ACCOUNTS}],
    })
      .then(({data: {updateWireTransferAccount}}) => {
        if (updateWireTransferAccount.__typename === 'Error') {
          toast.error(updateWireTransferAccount.message);
        } else {
          toast.success('Updated Successfully');
          navigate(-1);
        }
      })
      .catch(error => toast.error(error));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div>
      <div className={styles['header-title']}>
        <img src={backImgIcon} alt="" onClick={() => navigate(-1)} />
        <h1>Update Account</h1>
      </div>
      <div className={styles.body}>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <div className={styles.form}>
            <Form.Item
              className={styles.width}
              name="accountName"
              label="Account Name"
              rules={[{required: true, message: 'Account Name required'}]}>
              <Input />
            </Form.Item>
            <Form.Item
              className={styles.width}
              rules={[
                {
                  required: true,
                  message: 'Please make sure the account number is valid',
                },
              ]}
              name="accountNumber"
              label="Account Number">
              <Input />
            </Form.Item>

            <Form.Item
              className={styles.width}
              rules={[
                {required: true, message: 'Please account type is required'},
              ]}
              name="accountType"
              label="Account Type"
              initialValue={
                data?.getWireTransferAccountById?.data?.accountType
              }>
              <Select>
                <Select.Option value="CHECKING">Checking</Select.Option>
                <Select.Option value="SAVINGS">Savings</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.width}
              name="country"
              label="Country"
              initialValue={`${data?.getWireTransferAccountById?.data?.countryName}`}
              rules={[
                {
                  required: true,
                  message: 'Bank Country is required',
                },
              ]}>
              <Select
                value={country}
                onChange={setCountry}
                showSearch
                defaultValue={`${data?.getWireTransferAccountById?.data?.countryName}`}
                loading={loadingCountries}>
                {getAllCountries?.getAllCountries?.map(data => (
                  <Select.Option
                    key={`${data?.name}~${data?.countryCode}~${data?.icon}`}>
                    <div className="select-option-style">
                      <img src={data?.icon} alt="" />
                      <span>{data?.name}</span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              className={styles.width}
              name="currency"
              rules={[
                {required: true, message: 'Please input beneficiary address'},
              ]}
              label="Currency">
              <Select>
                {walletCurrencies.map(data => (
                  <Select.Option key={data.key}>{data.key}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.width}
              name="routingNumber"
              label="Bank Routing Number">
              <Input type="number" />
            </Form.Item>
            <Form.Item
              className={styles.width}
              name="bankName"
              label="Bank Name"
              rules={[
                {
                  required: true,
                  message: 'Bank name required',
                },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              className={styles.width}
              name="bankAddress"
              label="Bank Address"
              rules={[
                {
                  required: true,
                  message: 'Bank Address required',
                },
              ]}>
              <Input.TextArea rows={3} />
            </Form.Item>
          </div>
          <h2>BENEFICIARY DETAILS</h2>

          <div className={styles.form}>
            <Form.Item
              className={styles.width}
              name="beneficiaryName"
              label="Beneficiary Name"
              rules={[{required: true, message: 'Account Name required'}]}>
              <Input />
            </Form.Item>
            <Form.Item
              className={styles.width}
              name="beneficiaryBankName"
              label="Beneficiary Bank Name">
              <Input />
            </Form.Item>
            <Form.Item
              className={styles.width}
              rules={[
                {required: true, message: 'Please input beneficiary address'},
              ]}
              name="beneficiaryAddress"
              label="Beneficiary Address">
              <Input.TextArea rows={3} />
            </Form.Item>
          </div>
          <h2>OTHER DETAILS</h2>

          <div className={styles.form}>
            <Form.Item
              className={styles.width}
              name="bic"
              label="Bank Identifier Code (BIC)">
              <Input />
            </Form.Item>
            <Form.Item
              className={styles.width}
              name="sortCode"
              label="Sort Code">
              <Input />
            </Form.Item>
            <Form.Item
              className={styles.width}
              name="bankCode"
              label="Bank Code">
              <Input />
            </Form.Item>
            <Form.Item className={styles.width} name="rib" label="RIB">
              <Input />
            </Form.Item>
            <Form.Item className={styles.width} name="iban" label="IBAN">
              <Input />
            </Form.Item>
            {data?.getWireTransferAccountById?.data?.type ===
              'INTERNATIONAL' && (
              <>
                <Form.Item
                  className={styles.width}
                  name="swiftCode"
                  label="Swift Code">
                  <Input />
                </Form.Item>
              </>
            )}

            <Form.Item
              className={styles.switch}
              name="active"
              label="Active"
              initialValue={data?.getWireTransferAccountById?.data?.isActive}>
              <Switch
                value={isActive}
                defaultChecked={
                  data?.getWireTransferAccountById?.data?.isActive
                }
                onChange={e => setIsActive(e)}
              />
            </Form.Item>
          </div>

          <div className={styles.uploads}>
            <div>
              <p>
                Flag
                <span>*</span>
              </p>
              <UploadImage
                setPreviewUrl={setPreviewFlagImage}
                previewUrl={previewFlagImage}
                setImgUrl={setFlagImage}
                label="Upload Flag"
                bucket="accounts-pdf-bucket"
              />
            </div>
            <div>
              <p> Bank Logo</p>
              <UploadImage
                setPreviewUrl={setPreviewBankLogo}
                previewUrl={previewBankLogo}
                setImgUrl={setBankLogo}
                label="Upload Bank Logo"
                bucket="accounts-pdf-bucket"
              />
            </div>
          </div>
          <div>
            <h2>Upload PDF Details</h2>
            {!viewPdf && (
              <form>
                <label className={styles.upload} htmlFor="file-upload">
                  {loadingPdfUpload ? (
                    <div className={styles.spinner}>
                      <Spin size="large" />
                      <h3>{progress}%</h3>
                    </div>
                  ) : (
                    <span> {pdfName}</span>
                  )}
                  <input
                    type="file"
                    id="file-upload"
                    accept="application/pdf"
                    className={styles['input-file']}
                    required
                    onChange={handlePdfFileChange}
                  />
                </label>
              </form>
            )}

            {viewPdf && (
              <FadeEffect>
                <div className={styles['pdf-height']}>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={viewPdf}
                      plugins={[defaultLayoutPluginInstance]}
                    />
                  </Worker>
                </div>
              </FadeEffect>
            )}
            {pdfLink && (
              <div className={styles['button-wrapper']}>
                <Button
                  disabled={pdfFile === ''}
                  label="Preview PDF"
                  className={styles['preview-button']}
                  onClick={handlePdfFileSave}
                />
                <Button
                  type="secondary"
                  label="Delete"
                  onClick={handleDeleteFile}
                />
              </div>
            )}
          </div>

          <SubmitButton disabled={loadingUpdate} label={'Update'} />
        </Form>
      </div>
    </div>
  );
};

export default UpdateWiredAccount;
