import {gql} from '@apollo/client';

export const CREATE_REWARD_SCHEME = gql`
  mutation CreateRewardScheme($input: RewardSchemeInput!) {
    createRewardScheme(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on TypeRewardScheme {
        id
        title
      }
    }
  }
`;

export const UPDATE_REWARD_SCHEME = gql`
  mutation UpdateRewardScheme(
    $updateRewardSchemeId: ID!
    $input: RewardSchemeInput!
  ) {
    updateRewardScheme(id: $updateRewardSchemeId, input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on TypeRewardScheme {
        id
        title
      }
    }
  }
`;

export const CREATE_STOCK_REWARD_BANK = gql`
  mutation CreateStockRewardBank($data: CreateStockBankInput!) {
    createStockRewardBank(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithStockRewardBank {
        message
        data {
          id
        }
      }
    }
  }
`;

export const UPDATE_STOCK_REWARD_BANK = gql`
  mutation UpdateStockRewardBank($data: UpdateStockBankInput!) {
    updateStockRewardBank(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithStockRewardBank {
        message
        data {
          id
        }
      }
    }
  }
`;

export const CREATE_CASH_REWARD_BANK = gql`
  mutation CreateCashRewardBank($data: CreateCashBankInput!) {
    createCashRewardBank(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCashRewardBank {
        message
        data {
          id
        }
      }
    }
  }
`;

export const UPDATE_CASH_REWARD_BANK = gql`
  mutation UpdateCashRewardBank($data: UpdateCashBankInput!) {
    updateCashRewardBank(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCashRewardBank {
        message
        data {
          id
        }
      }
    }
  }
`;

export const DELETE_STOCK_REWARD_BANK = gql`
  mutation DeleteStockRewardBank($stockBankId: ID!) {
    deleteStockRewardBank(stockBankId: $stockBankId) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;

export const DELETE_CASH_REWARD_BANK = gql`
  mutation DeleteCashRewardBank($cashBankId: ID!) {
    deleteCashRewardBank(cashBankId: $cashBankId) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;

export const ISSUE_REWARD = gql`
  mutation AdminCreateUserReward($input: UserRewardInput!) {
    adminCreateUserReward(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on UserReward {
        id
        status
      }
    }
  }
`;

export const UPDATE_ISSUED_REWARD = gql`
  mutation AdminUpdateUserReward(
    $input: UserRewardInput!
    $adminUpdateUserRewardId: ID!
  ) {
    adminUpdateUserReward(input: $input, id: $adminUpdateUserRewardId) {
      ... on Error {
        statusCode
        message
      }
      ... on UserReward {
        id
      }
    }
  }
`;

export const UPDATE_REWARD_STATUS = gql`
  mutation UpdateRewardSchemeStatus(
    $isActive: Boolean!
    $rewardScheme: ID!
    $expirationDate: String
  ) {
    updateRewardSchemeStatus(
      isActive: $isActive
      rewardScheme: $rewardScheme
      expirationDate: $expirationDate
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;

export const UPDATE_USER_REWARD_STATUS = gql`
  mutation AdminUpdateUserRewardStatus(
    $status: RewardStatus!
    $adminUpdateUserRewardStatusId: ID!
  ) {
    adminUpdateUserRewardStatus(
      status: $status
      id: $adminUpdateUserRewardStatusId
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on UserReward {
        id
        status
      }
    }
  }
`;

export const TRANSLATE_REWARD_SCHEME = gql`
  mutation TranslateRewardScheme(
    $translateRewardSchemeId: ID!
    $language: Languages!
  ) {
    translateRewardScheme(id: $translateRewardSchemeId, language: $language) {
      ... on Error {
        message
        statusCode
      }
      ... on TypeRewardScheme {
        id
        title
        language
      }
    }
  }
`;
