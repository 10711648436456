import {Form, Input} from 'antd';
import {useEffect, useState} from 'react';
import styles from './campaignRisks.module.scss';

import trash from 'images/trash-can-outline.svg';
import plusIcon from 'images/plus-circle.png';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {riskAndESG} from 'redux/store/campaign';
import FadeEffect from 'components/animation/fadeEffect';

const CampaignRisks = () => {
  const dispatch = useDispatch();
  const [disableButton, setDisableButton] = useState(false);
  const {id, companyId} = useParams();
  const [risksFormValues, setRisksFormValues] = useState([]);
  const [impactFormValues, setImpactFormValues] = useState([]);
  const navigate = useNavigate();
  const width = 700;

  const campaign = useSelector(state => state.campaign.campaign);

  useEffect(() => {
    if (campaign.risks) {
      setRisksFormValues(
        campaign?.risks?.map(data => ({
          question: data.question,
          answer: data.answer,
        })),
      );
    }
  }, [campaign?.risks]);

  useEffect(() => {
    if (campaign.impacts) {
      setImpactFormValues(
        campaign?.impacts?.map(data => ({
          environmental: data.environmental,
          answer: data.answer,
        })),
      );
    }
  }, [campaign?.impacts]);

  useEffect(() => {
    if (risksFormValues.length) {
      risksFormValues?.map(data => {
        if (!data?.question || !data?.answer) {
          setDisableButton(true);
        } else {
          setDisableButton(false);
        }
      });
    } else {
      setDisableButton(false);
    }
  }, [risksFormValues]);

  useEffect(() => {
    if (impactFormValues.length) {
      impactFormValues?.map(data => {
        if (!data?.environmental || !data?.answer) {
          setDisableButton(true);
        } else {
          setDisableButton(false);
        }
      });
    } else {
      setDisableButton(false);
    }
  }, [impactFormValues]);

  const {TextArea} = Input;

  const handleAddRisksFields = () => {
    setRisksFormValues([...risksFormValues, {question: '', answer: ''}]);
  };

  const handleAddImpactFields = () => {
    setImpactFormValues([...impactFormValues, {environmental: '', answer: ''}]);
  };

  const handleChangeImpactValues = (i, e) => {
    const newFormValues = [...impactFormValues];
    newFormValues[i][e.target.name] = e.target.value;
    setImpactFormValues(newFormValues);
  };

  const handleChangeRisksValues = (i, e) => {
    const newFormValues = [...risksFormValues];
    newFormValues[i][e.target.name] = e.target.value;
    setRisksFormValues(newFormValues);
  };

  const handleDeleteRisk = id => {
    const filteredFormValue = risksFormValues.filter(
      (item, index) => index !== id,
    );
    setRisksFormValues(filteredFormValue);
  };

  const handleDeleteInpact = id => {
    const filteredFormValue = impactFormValues.filter(
      (item, index) => index !== id,
    );
    setImpactFormValues(filteredFormValue);
  };

  const handleSave = () => {
    dispatch(
      riskAndESG({
        risks: risksFormValues,
        impacts: impactFormValues,
      }),
    );
    navigate(`/dashboard/campaigns/new/${id}/statistics/${companyId}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Risk &#38; ESG</h1>
      </div>
      <div className={styles.risks}>
        <h1 className={styles.title}>Risks</h1>
        <div>
          <Form layout="vertical">
            {risksFormValues.map((element, index) => (
              // eslint-disable-next-line react/jsx-key
              <FadeEffect>
                <div key={index}>
                  <div className={styles['question-style']}>
                    <Form.Item style={{width}} label="Title">
                      <Input
                        name="question"
                        value={element.question || ''}
                        onChange={e => handleChangeRisksValues(index, e)}
                      />
                    </Form.Item>
                    <img
                      src={trash}
                      onClick={() => handleDeleteRisk(index)}
                      alt=""
                    />
                  </div>
                  <Form.Item style={{width}} label="Answer">
                    <TextArea
                      name="answer"
                      rows={5}
                      value={element.answer || ''}
                      onChange={e => handleChangeRisksValues(index, e)}
                    />
                  </Form.Item>
                </div>
              </FadeEffect>
            ))}
          </Form>
          <div onClick={handleAddRisksFields} className={styles['add-risks']}>
            <img src={plusIcon} alt="add-founder" />
            <span>Add Risks</span>
          </div>
        </div>
      </div>
      <div>
        <h1 className={styles.title}>Impact &#38; ESG</h1>
        <Form layout="vertical">
          {impactFormValues.map((element, index) => (
            <FadeEffect key={index}>
              <div key={index}>
                <div className={styles['question-style']}>
                  <Form.Item style={{width}} label="Impact">
                    <Input
                      name="environmental"
                      value={element.environmental || ''}
                      onChange={e => handleChangeImpactValues(index, e)}
                    />
                  </Form.Item>
                  <img
                    src={trash}
                    onClick={() => handleDeleteInpact(index)}
                    alt=""
                  />
                </div>
                <Form.Item style={{width}} label="Answer">
                  <TextArea
                    name="answer"
                    rows={5}
                    value={element.answer || ''}
                    onChange={e => handleChangeImpactValues(index, e)}
                  />
                </Form.Item>
              </div>
            </FadeEffect>
          ))}
        </Form>
        <div onClick={handleAddImpactFields} className={styles['add-risks']}>
          <img src={plusIcon} alt="add-founder" />
          <span>Add Impact &#38; EGG </span>
        </div>
      </div>
      <div>
        <SubmitButton
          disabled={disableButton}
          label="Save & Continue"
          handleClick={handleSave}
        />
      </div>
    </div>
  );
};

export default CampaignRisks;
