import {useDispatch, useSelector} from 'react-redux';
import {handleBondsInstrumentState} from 'redux/store/assetClass';
import {createBondsInstrumentRoute} from './routes';
import {useNavigate, useParams} from 'react-router-dom';
import {BaseGeneralDetails} from '../BaseFinancialInstruments/generalDetails/BaseGeneralDetails';
import {useMutation, useQuery} from '@apollo/client';
import {GET_FINANCIAL_INSTRUMENT_BY_LANGUAGE} from 'graphql/queries/assetManagement';
import {Spin} from 'antd';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {useState} from 'react';
import {UPDATE_BOND} from 'graphql/mutations/assetManagement';
import {handleUpdateBond} from '../actions';
import {currencySymbols} from 'utils/mock';

export const BondsGeneralDetails = () => {
  const [updatedValues, setUpdatedValues] = useState({});
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const navigate = useNavigate();
  const {assetId, lang, id} = useParams();
  const dispatch = useDispatch();
  const savedData = useSelector(state => state?.assetClass?.bondInstruments);

  const [updateBond, {loading: loadingUpdate}] = useMutation(UPDATE_BOND);
  const {
    data: updateData,
    refetch,
    loading,
  } = useQuery(GET_FINANCIAL_INSTRUMENT_BY_LANGUAGE, {
    variables: {
      getFinancialInstrumentId: id,
      language: lang,
    },
    skip: !id,
  });

  const handleSubmit = values => {
    if (id) {
      const {currency, issuer, issuerType, ...restValues} = values;
      setUpdatedValues({
        ...restValues,
        currency: {
          currencyCode: currency,
          name: currencySymbols[currency]?.name,
          symbol: currencySymbols[currency]?.symbol,
        },
        issuer: {
          id: issuer,
          issuerType,
        },
      });
      setOpenUpdateModal(true);
    } else {
      dispatch(
        handleBondsInstrumentState({
          ...values,
          exchange: values?.listingType === 'LISTED' ? values.exchange : null,
        }),
      );
      navigate(createBondsInstrumentRoute({param: assetId, name: 'settings'}));
    }
  };

  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }
  return (
    <div>
      <BaseGeneralDetails
        onSubmit={handleSubmit}
        existingValues={
          id ? updateData?.getFinancialInstrument?.data : savedData
        }
      />
      <ConfirmModal
        openModal={openUpdateModal}
        setOpenModal={() => setOpenUpdateModal(false)}
        loading={loadingUpdate}
        title="Update Bond"
        confirmText="Are you sure you want to update this bond?"
        handleOperation={() =>
          handleUpdateBond(
            updateBond,
            id,
            lang,
            updatedValues,
            refetch,
            setOpenUpdateModal,
          )
        }
      />
    </div>
  );
};
