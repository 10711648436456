import {useState} from 'react';
import styles from './documents.module.scss';
import {useMutation, useQuery} from '@apollo/client';
import {GET_USER_DOCUMENTS} from 'graphql/queries/userDocuments';
import {useNavigate, useParams} from 'react-router-dom';
import {Dropdown, Menu, Pagination, Table, Tag} from 'antd';
import {Button} from '@dabafinance/react-components';
import moment from 'moment';
import {
  DELETE_USER_DOCUMENT,
  UPDATE_USER_DOCUMENT_STATUS,
} from 'graphql/mutations/userDocuments';
import {toast} from 'react-toastify';
import DBModal from 'components/modal/modal';
import {CopyOutlined} from '@ant-design/icons';
import DocumentFilter from './documentFilter/DocumentFilter';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {ViewUserDocument} from './viewDocument';
import dotsIcon from 'images/dots.svg';

const UserDocuments = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState({});
  const [copyText, setCopyText] = useState('Copy');
  const [limit, setLimit] = useState(10);
  const [viewModal, setViewModal] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const {loading, data, refetch} = useQuery(GET_USER_DOCUMENTS, {
    variables: {
      filter: {
        userId: id,
        limit,
        page: currentPage,
      },
    },
    errorPolicy: 'all',
  });

  const [deleteOneUserDocument, {loading: loadingDelete}] =
    useMutation(DELETE_USER_DOCUMENT);
  const [changUserDocumentStatus, {loading: loadingUpdate}] = useMutation(
    UPDATE_USER_DOCUMENT_STATUS,
  );

  const handleDelete = () => {
    deleteOneUserDocument({
      variables: {
        documentId: openModal.id,
      },
    }).then(({data: {deleteOneUserDocument}}) => {
      if (deleteOneUserDocument.__typename === 'Error') {
        toast.error(deleteOneUserDocument.message);
      } else {
        refetch();
        toast.success('Deleted Successfully');
        setOpenModal({isOpen: false});
      }
    });
  };

  const handleUpdateStatus = () => {
    changUserDocumentStatus({
      variables: {
        documentId: openModal?.id,
        active: !openModal?.status,
      },
    }).then(({data: {changUserDocumentStatus}}) => {
      if (changUserDocumentStatus.__typename === 'Error') {
        toast.error(changUserDocumentStatus.message);
      } else {
        refetch();
        toast.success('Updated Successfully');
        setOpenModal({isOpen: false});
      }
    });
  };

  const handlePageChange = page => {
    if (page === currentPage) return;
    setCurrentPage(page);
  };

  const copyUrl = (documentUrl, e) => {
    if (e) {
      navigator.clipboard.writeText(documentUrl);
      setCopyText('Copied');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'documentName',
      key: 'documentName',
      render: (text, record) => (
        <div>
          <h4>{record.documentName}</h4>
        </div>
      ),
    },
    {
      title: 'Document Link',
      dataIndex: 'documentUrl',
      key: 'documentUrl',
      render: (text, render) => (
        <div
          onClick={e => copyUrl(render?.documentUrl, e)}
          onMouseLeave={() => setCopyText('Copy')}
          className={styles['copy-label']}>
          Copy Link <CopyOutlined />
          <span className={styles.tooltip}>{copyText}</span>
        </div>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'documentCategory',
      key: 'documentCategory',
      render: (text, record) => (
        <div>
          <Tag color="blue">{record?.documentCategory}</Tag>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <div>
          <Tag color={record?.isActive ? 'green' : 'red'}>
            {record?.isActive ? 'Active' : 'InActive'}
          </Tag>
        </div>
      ),
    },
    {
      title: 'Issued Date',
      dataIndex: 'issuedDate',
      key: 'issuedDate',
      render: (text, record) => (
        <div>
          <h4>{moment(record?.issuedDate).format('DD MMMM YYYY')}</h4>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <div className={styles.actions}>
          <Button
            onClick={() =>
              setViewModal({
                isOpen: true,
                documentUrl: record?.documentUrl,
                documentName: record?.documentName,
              })
            }
            type="secondary"
            label="View Document"
            className={styles['deactivate-btn']}
          />
          <Dropdown
            trigger="hover"
            menu={{
              items: [
                {
                  key: 1,
                  label: 'Update Document',
                  onClick: () =>
                    navigate(
                      `/dashboard/user/${id}/documents/upload-document/${record?.id}`,
                    ),
                },
                {
                  key: 2,
                  label: 'Update Status',
                  onClick: () =>
                    setOpenModal({
                      isOpen: true,
                      id: record?.id,
                      type: 'STATUS',
                      status: record?.isActive,
                    }),
                },
                {
                  key: 3,
                  label: 'Delete',
                  onClick: () => setOpenModal({isOpen: true, id: record?.id}),
                },
              ],
            }}>
            <div>
              <img src={dotsIcon} alt="" />
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <div className={styles['table-container']}>
      <div className={styles.header}>
        <h1>User Documents</h1>
        <Dropdown
          trigger="hover"
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                onClick={() => {
                  navigate(`/dashboard/user/${id}/documents/upload-document`);
                }}>
                <div className={styles['menu-items']}>
                  <span>Upload Document</span>
                </div>
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={() => {
                  setOpenFilterModal(true);
                }}>
                <div className={styles['menu-items']}>
                  <span>Generate Document</span>
                </div>
              </Menu.Item>
            </Menu>
          }>
          <div>
            <SubmitButton
              type="secondary"
              handleClick={e => e.stopPropagation()}
              label="New Document"
            />
          </div>
        </Dropdown>
      </div>
      <div className={styles['startup-table']}>
        <div>
          <Table
            dataSource={data?.getUserDocumentByFilter?.data}
            columns={columns}
            loading={loading}
            pagination={false}
          />
          <Pagination
            showSizeChanger
            current={currentPage}
            onChange={e => handlePageChange(e)}
            total={data?.getUserDocumentByFilter?.pagination?.total}
            showQuickJumper
            className="ant-table-pagination"
            pageSize={limit}
            onShowSizeChange={(_, size) => {
              setLimit(size);
            }}
          />
        </div>
      </div>
      <DBModal
        isOpen={openFilterModal}
        handleClose={() => setOpenFilterModal(false)}
        content={
          <DocumentFilter
            refetch={refetch}
            userId={id}
            setOpenModal={setOpenFilterModal}
          />
        }
      />
      <DBModal
        isOpen={openModal.isOpen}
        handleClose={() => setOpenModal({isOpen: false})}
        content={
          <div className={styles['modal-container']}>
            {openModal?.type === 'STATUS' ? (
              <h1>
                Are you sure you want to update this document status to{' '}
                {openModal?.status ? 'IN-ACTIVE' : 'ACTIVE'}
              </h1>
            ) : (
              <h1>Are you sure You want to delete this document?</h1>
            )}
            <div>
              <Button
                className={styles['modal-button']}
                onClick={
                  openModal?.type === 'STATUS'
                    ? handleUpdateStatus
                    : handleDelete
                }
                label={'Yes'}
                disabled={loadingDelete || loadingUpdate}
              />
              <Button
                className={styles['modal-button']}
                type="secondary"
                onClick={() => setOpenModal({isOpen: false})}
                label="No"
              />
            </div>
          </div>
        }
      />
      <DBModal
        isOpen={viewModal.isOpen}
        handleClose={() => setViewModal({isOpen: false})}
        width={900}
        content={
          <ViewUserDocument
            url={viewModal?.documentUrl}
            documentName={viewModal?.documentName}
          />
        }
      />
    </div>
  );
};

export default UserDocuments;
