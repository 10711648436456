import {createSlice} from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'Company',
  initialState: {
    companies: [],
  },
  reducers: {
    setComapnies: (state, action) => {
      state.companies = action.payload;
    },
    addToComapany: (state, action) => {
      state.companies = [...state.companies, action.payload];
    },
    allCompanies: (state, action) => {
      state.companies = [...action.payload];
    },
  },
});

export default slice.reducer;

export const {setComapnies, addToComapany, allCompanies} = slice.actions;
