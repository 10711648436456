import {createSlice} from '@reduxjs/toolkit';

// helper functions
export const getWatchListID = (watchlist, id) => {
  let watchlistIndex = null;
  watchlist.map((carousel, index) => {
    if (carousel.id === id) {
      watchlistIndex = index;
    }
  });
  return watchlistIndex;
};

const slice = createSlice({
  name: 'watchlist',
  initialState: {
    watchlist: [],
    currentWatchList: null,
  },
  reducers: {
    setWatchList: (state, action) => {
      state.watchlist = action.payload;
    },
    addToWatchList: (state, action) => {
      state.watchlist = [...state.watchlist, action.payload];
    },
    setCurrentWatchList: (state, action) => {
      state.currentWatchList = action.payload;
    },
    updateWatchList: (state, action) => {
      const watchlistToUpdateIndex = getWatchListID(
        state.watchlist,
        action.payload.id,
      );
      return {
        ...state,
        watchlist: [
          ...state.watchlist.slice(0, watchlistToUpdateIndex),
          action.payload,
          ...state.watchlist.slice(
            watchlistToUpdateIndex + 1,
            state.watchlist.length,
          ),
        ],
      };
    },
  },
});

export default slice.reducer;

export const {
  setWatchList,
  addToWatchList,
  setCurrentWatchList,
  updateWatchList,
} = slice.actions;
