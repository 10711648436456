import {Table} from 'antd';
import {Button} from '@dabafinance/react-components';
import {useNavigate} from 'react-router-dom';

const AllMobileMoneyOperators = ({data, loading, operations}) => {
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Country Name',
      dataIndex: 'countryName',
      key: 'countryName',
    },
    {
      title: 'Currency Code',
      dataIndex: 'currencyCode',
      key: 'currencyCode',
    },
  ];

  if (operations?.update) {
    columns.push({
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Button
          label="Update"
          type="secondary"
          onClick={() =>
            navigate(`/dashboard/wallet/mobile-money/${record?.countryName}`)
          }
        />
      ),
    });
  }
  return (
    <div>
      <Table loading={loading} dataSource={data} columns={columns} />
    </div>
  );
};

export default AllMobileMoneyOperators;
