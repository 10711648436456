import {v4 as uuidv4} from 'uuid';

const dataSlider = [
  {
    id: uuidv4(),
    title: 'Community',
    subTitle:
      'Join group conversations about African tech investing related topics',
  },
  {
    id: uuidv4(),
    title: 'Portfolio Tracker',
    subTitle: 'Track your investments in African Startups',
  },
  {
    id: uuidv4(),
    title: 'Investment Analysis',
    subTitle:
      'Access exclusive in depth investment analysis and industry reports.',
  },
  {
    id: uuidv4(),
    title: 'Lorem ipsum',
    subTitle: 'Lorem',
  },
  {
    id: uuidv4(),
    title: 'Latest African Tech News',
    subTitle:
      'Read all the news you need to know about the African tech and investing landscape.',
  },
];

export default dataSlider;
