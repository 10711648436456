import {createSlice} from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'campaign',
  initialState: {
    campaign: [],
  },
  reducers: {
    setCampaign: (state, action) => {
      state.campaign = {
        ...state.campaign,
        keyFacts: action.payload.keyFacts,
        milestones: action.payload.milestones,
        mission: action.payload.missions,
        category: action.payload.category,
        aboutCompany: action.payload.aboutCompany,
        offer: action.payload.offer,
        disclaimers: action.payload.disclaimers,
        solutions: action.payload.solutions,
        audioFile: action.payload.audioFile,
        videoUrl: action.payload.videoUrl,
        opportunityThumbnail: action.payload.opportunityThumbnail,
        disclosures: action.payload.disclosures,
        language: action.payload.language,
      };
    },
    setAnalysis: (state, action) => {
      state.campaign = {
        ...state.campaign,
        levelOneAnalysis: action.payload.levelOneAnalysis,
        levelOneThumbnail: action.payload.levelOneThumbnail,
        levelTwoThumbnail: action.payload.levelTwoThumbnail,
        levelTwoAnalysis: action.payload.levelTwoAnalysis,
      };
    },
    pitchDeck: (state, action) => {
      state.campaign = {
        ...state.campaign,
        pitchDeckPDFLink: action.payload.pitchLink,
        pitchDeckFile: action.payload.pdfFile,
        pdfName: action.payload.pdfName,
        linkType: action.payload.linkType,
        pdfCoverImage: action.payload.pdfCoverImage,
        externalFileLink: action.payload.externalFileLink,
        externalShareLink: action.payload.externalShareLink,
        pdfPreviewImage: action.payload.pdfPreviewImage,
      };
    },
    score: (state, action) => {
      state.campaign = {
        ...state.campaign,
        dabaScore: action.payload.dabaScore,
        businessValue: action.payload.businessValue,
        industryValue: action.payload.industryValue,
        marketValue: action.payload.marketValue,
        teamValue: action.payload.teamValue,
        strategyValue: action.payload.strategyValue,
        acceleratorValue: action.payload.acceleratorValue,
        riskValue: action.payload.riskValue,
        analogusValue: action.payload.analogusValue,
        economicValue: action.payload.economicValue,
        complianceValue: action.payload.complianceValue,
        outlookValue: action.payload.outlookValue,
        socialValue: action.payload.socialValue,
        pdfReport: action.payload.pdfReport,
        reportName: action.payload.reportName,
      };
    },
    coInvestors: (state, action) => {
      state.campaign = {
        ...state.campaign,
        coInvestors: action.payload.coInvestors,
        competitors: action.payload.competitors,
      };
    },
    listType: (state, action) => {
      state.campaign = {
        ...state.campaign,
        listType: action.payload.listType,
        listMode: action.payload.listMode,
      };
    },
    fundAllocation: (state, action) => {
      state.campaign = {
        ...state.campaign,
        fundAllocation: action.payload.fundAllocation,
        fundDescription: action.payload.fundDescription,
      };
    },
    campaignFAQ: (state, action) => {
      state.campaign = {
        ...state.campaign,
        FAQ: action.payload.FAQ,
      };
    },
    investmentDetails: (state, action) => {
      state.campaign = {
        ...state.campaign,
        fundingTarget: action.payload.fundingTarget,
        valuation: action.payload.valuation,
        minimumInvestment: action.payload.minimumInvestment,
        securityType: action.payload.securityType,
        vendor: action.payload.vendor,
        portfolioStrategy: action.payload.portfolioStrategy,
        maximumFundingTarget: action.payload.maximumFundingTarget,
        minimumFundingTarget: action.payload.minimumFundingTarget,
        pricePerSecurity: action.payload.pricePerSecurity,
        targetMet: action.payload.targetMet,
      };
    },
    listingIPO: (state, action) => {
      state.campaign = {
        ...state.campaign,
        numSharesAllocated: action.payload.numSharesAllocated,
        expectedIpo: action.payload.expectedIpo,
        ipoSharePrice: action.payload.ipoSharePrice,
        numOfOutstandingShares: action.payload.numOfOutstandingShares,
        ipoFeesValues: action.payload.ipoFeesValues,
        offeringName: action.payload.offeringName,
      };
    },
    ventureFund: (state, action) => {
      state.campaign = {
        ...state.campaign,
        ventureFundFields: action.payload.ventureFundFields,
      };
    },
    legalDocuments: (state, action) => {
      state.campaign = {
        ...state.campaign,
        legalDocuments: action.payload.legalDocuments,
      };
    },
    foundersAndMembers: (state, action) => {
      state.campaign = {
        ...state.campaign,
        founders: action.payload.founders,
        teamMembers: action.payload.teamMembers,
      };
    },
    companyDetails: (state, action) => {
      state.campaign = {
        ...state.campaign,
        foundedOn: action.payload.foundedOn,
        location: action.payload.location,
        industry: action.payload.industry,
        stage: action.payload.stage,
        website: action.payload.website,
        linkedinURL: action.payload.linkedinURL,
        facebookURL: action.payload.facebookURL,
        twitterURL: action.payload.twitterURL,
      };
    },
    analogs: (state, action) => {
      state.campaign = {
        ...state.campaign,
        analogs: action.payload.analogs,
      };
    },
    riskAndESG: (state, action) => {
      state.campaign = {
        ...state.campaign,
        risks: action.payload.risks,
        impacts: action.payload.impacts,
      };
    },
    statistics: (state, action) => {
      state.campaign = {
        ...state.campaign,
        statistics: action.payload.statistics,
      };
    },
    investmentContractSettings: (state, action) => {
      state.campaign = {
        ...state.campaign,
        investmentContractSettings: action.payload.investmentContractSettings,
      };
    },
    fxTransactionRatesOnSupportedCurrencies: (state, action) => {
      state.campaign = {
        ...state.campaign,
        fxTransactionRatesOnSupportedCurrencies:
          action.payload.fxTransactionRatesOnSupportedCurrencies,
      };
    },
    assetCurrency: (state, action) => {
      state.campaign = {
        ...state.campaign,
        assetCurrency: action.payload.assetCurrency,
      };
    },
    settings: (state, action) => {
      state.campaign = {
        ...state.campaign,
        offeringType: action.payload.offeringType,
        startDate: action.payload.startDate,
        finishDate: action.payload.finishDate,
        publicAudience: action.payload.publicAudience,
        isVisible: action.payload.isVisible,
        notifyUsersOnLaunch: action.payload.notifyUsersOnLaunch,
      };
    },
    invite: (state, action) => {
      state.campaign = {
        ...state.campaign,
        inviteUsers: action.payload.inviteUsers,
        inviteGroups: action.payload.inviteGroups,
        mode: action.payload.mode,
        groupId: action.payload.groupId,
        stamp: action.payload.stamp,
        templateId: action.payload.templateId,
        bestFit: action.payload.bestFit,
      };
    },
    clearCampaign: state => {
      state.campaign = [];
    },
  },
});

export default slice.reducer;

export const {
  setCampaign,
  pitchDeck,
  coInvestors,
  statistics,
  foundersAndMembers,
  companyDetails,
  settings,
  investmentContractSettings,
  assetCurrency,
  fxTransactionRatesOnSupportedCurrencies,
  investmentDetails,
  score,
  riskAndESG,
  fundAllocation,
  listingIPO,
  ventureFund,
  analogs,
  setAnalysis,
  listType,
  clearCampaign,
  invite,
  legalDocuments,
  campaignFAQ,
} = slice.actions;
