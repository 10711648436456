import {useMutation, useQuery} from '@apollo/client';
import {Form, Select, Table} from 'antd';
import {GET_FINANCIAL_INSTRUMENT_LANGUAGE_VERSIONS} from 'graphql/queries/assetManagement';
import {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import styles from '../assetClassOverview.module.scss';
import {Button} from '@dabafinance/react-components';
import backIcon from 'images/back.png';
import {notificationLanguages} from 'utils/constants';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {TRANSLATE_BOND} from 'graphql/mutations/assetManagement';

export const AssetInstrumentLanguages = () => {
  const [languageVersion, setLanguageVersion] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [openConfirmTranslationModal, setOpenConfirmTranslationModal] =
    useState(false);
  const {id, key} = useParams();
  const navigate = useNavigate();

  const [translateBond, {loading: loadingTranslation}] =
    useMutation(TRANSLATE_BOND);

  const {data, refetch, loading} = useQuery(
    GET_FINANCIAL_INSTRUMENT_LANGUAGE_VERSIONS,
    {
      variables: {
        getFinancialInstrumentLanguageVersionsId: id,
        assetClassKey: key,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      onError: error => toast.error(error.message),
      onCompleted: data => {
        setLanguageVersion(
          data?.getFinancialInstrumentLanguageVersions?.data
            ? data?.getFinancialInstrumentLanguageVersions?.data?.languageVersions?.map(
                item => {
                  return {
                    id: data?.getFinancialInstrumentLanguageVersions?.data?.id,
                    title:
                      data?.getFinancialInstrumentLanguageVersions?.data?.name,
                    language: item,
                  };
                },
              )
            : [],
        );
      },
    },
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>{record?.title}</h4>
        </div>
      ),
    },
    {
      title: 'Language Version',
      dataIndex: 'language',
      key: 'language',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4 style={{width: 100}}>
            {record?.language === 'EN' ? 'English' : 'French'}
          </h4>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <Button
          onClick={() =>
            navigate(
              `/dashboard/asset-management/update/bond/${key}/${record?.id}/${record.language}`,
            )
          }
          className={styles.button}
          type="secondary"
          label="Edit"
        />
      ),
    },
  ];

  return (
    <div className={styles.languageVersions}>
      <div className={styles.header}>
        <img
          onClick={() =>
            navigate(`/dashboard/asset-management/class/${key}/instruments`)
          }
          src={backIcon}
          alt=""
        />
        <h1>
          {data?.getAssetClassLanguageVersions?.data?.name} Language Versions
        </h1>
      </div>
      <Form
        onFinish={() => setOpenConfirmTranslationModal(true)}
        layout="vertical">
        <Form.Item
          label="Select Language to Translate"
          name="language"
          className={styles.language}
          rules={[
            {
              required: true,
              message: 'Please select a language to translate.',
            },
          ]}>
          <Select
            placeholder="French"
            onChange={setSelectedLanguage}
            style={{width: 320}}
            name="type">
            {notificationLanguages.map(name => (
              <Select.Option key={name.key} value={name.key}>
                {name.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <SubmitButton label="Proceed" />
      </Form>
      <div>
        <Table
          columns={columns}
          dataSource={languageVersion}
          loading={loading}
        />
      </div>
      <ConfirmModal
        openModal={openConfirmTranslationModal}
        setOpenModal={() => setOpenConfirmTranslationModal(false)}
        title="Confirm Translation"
        confirmText="Are you sure you want to translate this?"
        loading={loadingTranslation}
        handleOperation={() =>
          translateBond({
            variables: {
              translateBondId: id,
              language: selectedLanguage,
            },
          }).then(({data: translateBond}) => {
            if (
              translateBond.__typename === 'Error' ||
              translateBond.statusCode === 400
            ) {
              toast.error(translateBond.message);
            } else {
              setOpenConfirmTranslationModal(false);
              refetch();
              toast.success('Bond Translated');
            }
          })
        }
      />
    </div>
  );
};
