import {useMutation, useQuery} from '@apollo/client';
import {Form, Input, Select, Spin} from 'antd';
import {GET_ALL_PRICES, GET_ALL_PRODUCTS} from 'graphql/queries/plans';
import {useState} from 'react';
import styles from './createPlan.module.scss';
import {CREATE_PRICE} from 'graphql/mutations/plans';
import SubmitButton from 'components/Startup/components/SubmitButton';
import backIcon from 'images/back.png';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import NumberinputField from 'components/NumberInputField/NumberinputField';
import {convertToCurrency} from '../../../utils/helper';

const CreatePrice = () => {
  const [baseAmount, setBaseAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [cycle, setCycle] = useState('');
  const [trialDays, setTrialDays] = useState(0);
  const [appleSku, setAppleSku] = useState('');
  const [priceType, setPriceType] = useState('');
  const [productId, setProductId] = useState('');
  const navigate = useNavigate();
  const {Option} = Select;

  const {data, loading} = useQuery(GET_ALL_PRODUCTS);

  const [createPrice, {loading: priceLoader}] = useMutation(CREATE_PRICE);

  const handleCreatePlan = () => {
    createPrice({
      variables: {
        data: {
          stripeProductId: productId,
          baseAmount: parseInt(baseAmount),
          discountAmount: parseInt(discountAmount),
          trialPeriodDays: parseInt(trialDays),
          billingCycle: cycle,
          appleSku: appleSku.trim(),
          type: priceType,
          product: data?.listProducts?.products?.filter(
            data => data.stripeProductId === productId,
          )[0]?.id,
        },
      },
      refetchQueries: [{query: GET_ALL_PRICES}],
    })
      .then(({data: {createPrice}}) => {
        if (createPrice.__typename === 'Error') {
          toast.error(createPrice.message);
        } else {
          toast.success('Created Successfully');
          navigate('/dashboard/plans');
        }
      })
      .catch(error => toast.error(error.message));
  };

  let amount = 0;
  if (cycle === 'quarter' && baseAmount >= 0) {
    amount = parseInt(baseAmount) / 3;
  } else if (cycle === 'year' && baseAmount >= 0) {
    amount = parseInt(baseAmount) / 12;
  } else {
    amount = baseAmount;
  }

  const cyclePrice = (
    <div>
      {baseAmount != 0 && <span>${convertToCurrency(amount.toFixed(2))}</span>}
    </div>
  );

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div>
      <div className={styles['nav-header']}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>Create a Product Price</h1>
      </div>
      <div className={styles.content}>
        <Form layout="vertical">
          <div className={styles.details}>
            <Form.Item
              className={styles['field-width']}
              rules={[{required: true, message: 'Stripe Product Id Required'}]}
              name="stripeProduct"
              label="Stripe Product">
              <Select
                value={productId}
                onChange={e => setProductId(e)}
                placeholder="product">
                {data?.listProducts?.products?.map(product => (
                  <Option key={product.stripeProductId}>
                    <span>{product?.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'Billing Cycle Required'}]}
              className={styles['field-width']}
              name="billing"
              label="Billing Cycle">
              <Select
                value={cycle}
                onChange={e => setCycle(e)}
                placeholder="Select a cycle">
                <Option value="month">Month</Option>
                <Option value="quarter">Quarter</Option>
                <Option value="year">Year</Option>
              </Select>
            </Form.Item>

            <Form.Item
              rules={[{required: true, message: 'Trial Period'}]}
              className={styles['field-width']}
              name="trial"
              label="Trial Period">
              <Input
                value={trialDays}
                onChange={e => setTrialDays(e.target.value)}
                type="number"
                placeholder="Trial days (numbers)"
              />
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'Type is required'}]}
              className={styles['field-width']}
              name="priceType"
              label="Price Type">
              <Select
                value={priceType}
                onChange={e => setPriceType(e)}
                placeholder="Price Type">
                <Option value="oneTime">One Time</Option>
                <Option value="recurring">Recurring</Option>
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'Apple SKU required'}]}
              className={styles['field-width']}
              name="appleSku"
              label="Apple SKU">
              <Input
                value={appleSku}
                onChange={e => setAppleSku(e.target.value)}
                placeholder="Apple SKU"
              />
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'Price Amount Required'}]}
              className={styles['field-width']}
              name="amount"
              label="Price per Cycle">
              <NumberinputField value={baseAmount} setValue={setBaseAmount} />
            </Form.Item>
            <div className={styles['price-amount']}>
              <h1>Monthly Price: </h1>
              <span>{cyclePrice}</span>
            </div>

            <Form.Item
              className={styles['field-width']}
              name="discount amount"
              label="Discount Amount">
              <NumberinputField
                value={discountAmount}
                setValue={setDiscountAmount}
              />
            </Form.Item>
          </div>
          <SubmitButton
            disabled={priceLoader}
            label="Create Price"
            handleClick={handleCreatePlan}
          />
        </Form>
      </div>
    </div>
  );
};

export default CreatePrice;
