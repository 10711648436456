import gql from 'graphql-tag';

export const GET_lATEST_NEWS = gql`
  query GetNewsByUser($limit: Int) {
    getNewsByUser(limit: $limit) {
      ... on Error {
        message
        statusCode
      }
      ... on UserNewsType {
        news {
          latestNews {
            tldr
            id
            title
            link
            featuredImage
            keywords
            isCategorized
            isBreakingNews
            isTrendingNews
            isSelectedNews
            industries {
              id
              name
              icon
            }
            topics {
              name
              icon
            }
            countries {
              id
              name
              icon
            }
            videoUrl
            publishedDate
            newsSource {
              source
              name
              logo
            }
            companies {
              id
              name
              logoImgURL
            }
          }
        }
      }
    }
  }
`;

export const GET_TRENDING_NEWS = gql`
  query GetNewsByUser {
    getNewsByUser {
      ... on Error {
        message
        statusCode
      }
      ... on UserNewsType {
        news {
          trendingNews {
            tldr
            id
            title
            link
            featuredImage
            keywords
            isCategorized
            isBreakingNews
            isTrendingNews
            isSelectedNews
            industries {
              id
              name
              icon
            }
            topics {
              name
              icon
            }
            countries {
              id
              name
              icon
            }
            videoUrl
            publishedDate
            newsSource {
              source
              name
              logo
            }
            companies {
              id
              name
              logoImgURL
            }
          }
        }
      }
    }
  }
`;

export const GET_BREAKING_NEWS = gql`
  query GetNewsByUser {
    getNewsByUser {
      ... on Error {
        message
        statusCode
      }
      ... on UserNewsType {
        news {
          breakingNews {
            tldr
            id
            title
            link
            featuredImage
            keywords
            isCategorized
            isBreakingNews
            isTrendingNews
            isSelectedNews
            industries {
              id
              name
              icon
            }
            topics {
              name
              icon
            }
            countries {
              id
              name
              icon
            }
            videoUrl
            publishedDate
            newsSource {
              source
              name
              logo
            }
            companies {
              id
              name
              logoImgURL
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_NEWS = gql`
  query GetAllNews($pageNumber: Int, $limit: Int) {
    getAllNews(pageNumber: $pageNumber, limit: $limit) {
      ... on Error {
        message
        statusCode
      }
      ... on NewsType {
        news {
          tldr
          id
          title
          link
          featuredImage
          keywords
          isCategorized
          isBreakingNews
          isTrendingNews
          isSelectedNews
          industries {
            id
            name
            icon
          }
          topics {
            name
            icon
          }
          countries {
            id
            name
            icon
          }
          videoUrl
          publishedDate
          newsSource {
            source
            name
            logo
          }
          companies {
            id
            name
            logoImgURL
          }
        }
      }
    }
  }
`;

export const GET_NEWS_BY_SEARCH_PARAM = gql`
  query GetNewsBySearchTerm($searchTerm: String!) {
    getNewsBySearchTerm(searchTerm: $searchTerm) {
      ... on Error {
        message
        statusCode
      }
      ... on NewsType {
        news {
          tldr
          id
          title
          link
          featuredImage
          keywords
          isCategorized
          isBreakingNews
          isTrendingNews
          isSelectedNews
          industries {
            id
            name
            icon
          }
          topics {
            name
            icon
          }
          countries {
            id
            name
            icon
          }
          videoUrl
          publishedDate
          newsSource {
            source
            name
            logo
          }
          companies {
            id
            name
            logoImgURL
          }
        }
      }
    }
  }
`;

export const GET_NEWS_BY_ID = gql`
  query GetNewsById($newsId: String!) {
    getNewsById(newsId: $newsId) {
      ... on Error {
        message
        statusCode
      }
      ... on NewsByIdType {
        news {
          id
          title
          link
          featuredImage
          keywords
          isBreakingNews
          isTrendingNews
          isSelectedNews
          topics {
            name
            icon
          }
          publishedDate
          newsSource {
            name
            url
          }
          companies {
            id
            name
            logoImgURL
          }
        }
      }
    }
  }
`;

export const GENERATE_NEWS_SUMMARY = gql`
  mutation SummarizeContent($data: SummarizeContentInput!) {
    summarizeContent(data: $data) {
      ... on Error {
        type
        message
      }
      ... on SummarizeContentType {
        summary
      }
    }
  }
`;
