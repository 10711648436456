import {S3Client} from '@aws-sdk/client-s3';
import {Upload} from '@aws-sdk/lib-storage';
import Resizer from 'react-image-file-resizer';
import {toast} from 'react-toastify';

export const resizeImageFile = file =>
  // eslint-disable-next-line no-undef
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      'PNG',
      90,
      0,
      uri => {
        resolve(uri);
      },
      'file',
    );
  });

export const UploadDocumentToBucket = async (
  payload,
  setProgress = () => {},
  bucket = 'campaign-bucket-images',
  isACL = false,
  folder,
) => {
  const fileName = `${Date.now()}-${payload?.name.replace(/ /g, '')}`;
  const target = {
    Bucket: bucket,
    Key: isACL ? `${folder}/${fileName}` : `${fileName}`,
    Body: payload,
    ContentType: payload.type,
    ...(isACL ? {ACL: 'public-read'} : {}),
  };
  const cred = {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  };
  try {
    const parallelUploads3 = new Upload({
      client: new S3Client({
        region: 'us-east-1',
        credentials: cred,
      }),
      leavePartsOnError: false,
      params: target,
    });
    parallelUploads3.on('httpUploadProgress', progress => {
      const progressPercentage = Math.round(
        (progress.loaded / progress.total) * 100,
      );
      setProgress(progressPercentage);
    });
    const res = await parallelUploads3.done();

    if (res.$metadata.httpStatusCode === 200) {
      return res.Location;
    }
  } catch (err) {
    toast.error(err);
  }
  return null;
};

export const UploadVideoToBucket = async (payload, bucket, setProgress) => {
  let fileLink = '';
  const fileName = `${Date.now()}-${payload?.name.replace(/ /g, '')}`;
  const target = {
    Bucket: bucket,
    Key: fileName,
    Body: payload,
    ContentType: payload.type,
  };
  const cred = {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  };
  let imageUrl = '';
  try {
    const parallelUploads3 = new Upload({
      client: new S3Client({
        region: 'us-east-1',
        credentials: cred,
      }),
      leavePartsOnError: false,
      params: target,
    });
    parallelUploads3.on('httpUploadProgress', progress => {
      const progressPercentage = Math.round(
        (progress.loaded / progress.total) * 100,
      );
      setProgress(progressPercentage);
    });
    const res = await parallelUploads3.done();
    if (res.$metadata.httpStatusCode === 200) {
      imageUrl = `https://${bucket}.s3.amazonaws.com/${fileName}`;
      fileLink = imageUrl;
    }
  } catch (err) {
    toast.error(err);
  }
  return fileLink;
};
