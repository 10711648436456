import {useEffect, useState} from 'react';
import styles from './campaignProfile.module.scss';
import {useNavigate, useParams} from 'react-router-dom';
import {DatePicker, Form, Input, InputNumber, Select, Spin} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useMutation, useQuery} from '@apollo/client';
import {
  GET_ALL_OPERATING_COUNTRIES,
  GET_A_STARTUP,
} from 'graphql/queries/startupCompanies';
import moment from 'moment';
import backIcon from 'images/back.png';
import {CREATE_FUNDRAISING_COMPANY} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';
import {UPDATE_COMPANY} from 'graphql/mutations/companyStartups';
import {stageData} from 'utils/mock';
import {noOfEmployeesOptions} from 'utils/constants';

const CampaignProfile = () => {
  const [legalName, setLegalName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [twitter, setTwitterURL] = useState('');
  const [linkedin, setLinkedinURL] = useState('');
  const [facebook, setFacebookURL] = useState('');
  const [headquarters, setHeadquarters] = useState('');
  const [foundedOn, setFoundedOn] = useState('');
  const [accelerator, setAccelerator] = useState('');
  const [stage, setStage] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [numOfEmployees, setNumOfEmployees] = useState('');
  const [operatingCountries, setOperatingCountries] = useState([]);
  const [ticker, setTicker] = useState('');
  const [description, setDescription] = useState('');
  const [state, setState] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [website, setWebsite] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [totalCapitalRaised, setTotalCapitalRaised] = useState(0);

  const {id, slug} = useParams();
  const navigate = useNavigate();
  const {Option} = Select;
  const {data, loading} = useQuery(GET_A_STARTUP, {
    variables: {
      companyId: id,
    },
  });
  const [createFundraisingCompany, {loading: creationLoading}] = useMutation(
    CREATE_FUNDRAISING_COMPANY,
  );
  const [updateCompany] = useMutation(UPDATE_COMPANY);

  const {data: countries} = useQuery(GET_ALL_OPERATING_COUNTRIES, {
    variables: {
      type: 'COUNTRY',
    },
  });

  useEffect(() => {
    setLegalName(data?.getCompany?.legalName);
    setCompanyName(data?.getCompany.name);
    setHeadquarters(data?.getCompany?.headquarter);
    setAccelerator(data?.getCompany?.accelerator);
    setFoundedOn(data?.getCompany?.foundedOn);
    setCompanyType(data?.getCompany?.companyType);
    setLinkedinURL(data?.getCompany?.socials?.linkedInUrl);
    setNumOfEmployees(data?.getCompany?.numberOfEmployees);
    setTwitterURL(data?.getCompany?.socials?.twitterUrl);
    setFacebookURL(data?.getCompany?.socials?.facebookUrl);
    setOperatingCountries(
      data?.getCompany?.operatingCountries?.map(
        data => `${data?.name};${data?.id}`,
      ),
    );
    setTicker(data?.getCompany?.ticker);
    setDescription(data?.getCompany?.description);
    setShortDescription(data?.getCompany?.shortDescription);
    setStage(data?.getCompany?.stage);
    setWebsite(data?.getCompany?.website);
    setTotalCapitalRaised(data?.getCompany?.totalCapitalRaised);
  }, [data?.getCompany]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  const handleSave = () => {
    let newObj = {};
    if (!twitter && !linkedin && !facebook) newObj = {};
    if (twitter) newObj.twitter = twitter;
    if (facebook) newObj.facebook = facebook;
    if (linkedin) newObj.linkedin = linkedin;
    createFundraisingCompany({
      variables: {
        input: {
          companyId: id,
          legalName: legalName.trim(),
          companyName: companyName.trim(),
          socials: newObj,
          operatingCountries: operatingCountries?.map(
            data => data?.split(';')[1],
          ),
          totalCapitalRaised: parseInt(totalCapitalRaised),
          bannerImgURL: data?.getCompany?.bannerImgURL,
          logoImgURL: data?.getCompany?.logoImgURL,
          accelerator: accelerator.trim(),
          stage,
          companyType,
          foundedOn: parseInt(foundedOn),
          headquarter: headquarters.trim(),
          numberOfEmployees: numOfEmployees,
          ticker: ticker.trim(),
          website: website.trim(),
          description: description.trim(),
          state: state.trim(),
          shortDescription: shortDescription.trim(),
          primaryContactInfo: {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            email: email.trim(),
            phoneNumber: phoneNumber.trim(),
          },
        },
      },
    })
      .then(({data: {createFundraisingCompany}}) => {
        if (createFundraisingCompany.__typename === 'Error') {
          toast.error('Something went wrong');
        } else {
          updateCompany({
            variables: {
              input: {
                companyId: id,
                legalName: legalName.trim(),
                name: companyName.trim(),
                operatingCountries: operatingCountries?.map(
                  data => data?.split(';')[1],
                ),
                totalCapitalRaised: parseInt(totalCapitalRaised),
                bannerImgURL: data?.getCompany?.bannerImgURL,
                logoImgURL: data?.getCompany?.logoImgURL,
                accelerator,
                stage,
                numberOfEmployees: numOfEmployees,
                companyType,
                foundedOn,
                headquarter: headquarters.trim(),
                ticker: ticker.trim(),
                website: website.trim(),
                description: description.trim(),
                shortDescription: shortDescription.trim(),
              },
            },
          })
            .then(({data: {updateCompany}}) => {
              if (updateCompany.__typename === 'Error') {
                toast.error(updateCompany.message);
              } else {
                if (slug) {
                  navigate(
                    `/dashboard/campaigns/${slug}/new/${createFundraisingCompany.id}/type/${createFundraisingCompany.companyId}`,
                  );
                  toast.success('Created Successfully');
                } else {
                  navigate(
                    `/dashboard/campaigns/new/${createFundraisingCompany.id}/type/${createFundraisingCompany.companyId}`,
                  );
                  toast.success('Created Successfully');
                }
              }
            })
            .catch(() => {
              toast.error('Something went wrong');
            });
        }
      })
      .catch(() =>
        toast.error(
          'something went wrong, are you sure all fields are validated',
        ),
      );
  };

  return (
    <div className={styles.container}>
      <div className={styles['profile-body']}>
        <div className={styles.header}>
          <img src={backIcon} alt="" onClick={() => navigate(-1)} />
          <h1>Create Fundraising Company</h1>
        </div>
        <div className={styles.profile}>
          <div className={styles['cp-image']}>
            <img
              alt=""
              className={styles['image-style']}
              src={data?.getCompany?.bannerImgURL}
            />
          </div>
          <div>
            <Form
              onFinish={handleSave}
              className={styles.form}
              layout="vertical">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Required Field',
                  },
                ]}
                className={styles['input-width']}
                label="Company Name"
                name="companyName"
                initialValue={data?.getCompany?.name}>
                <Input
                  defaultValue={data?.getCompany?.name}
                  name="companyName"
                  placeholder="Enter company name"
                  value={companyName}
                  onChange={e => setCompanyName(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Required Field',
                  },
                ]}
                className={styles['input-width']}
                name="legalName"
                label="Legal Name"
                initialValue={data?.getCompany?.legalName}>
                <Input
                  name="legalName"
                  value={legalName}
                  defaultValue={data?.getCompany?.legalName}
                  placeholder="Enter a LegalName"
                  onChange={e => setLegalName(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Required Field',
                  },
                ]}
                className={styles['input-width']}
                name="companyType"
                label="Company Type"
                initialValue={data?.getCompany?.companyType}>
                <Select
                  defaultValue={data?.getCompany?.companyType}
                  value={companyType}
                  onChange={e => setCompanyType(e)}>
                  <Option value="PUBLIC">Publc</Option>
                  <Option value="PRIVATE">Private</Option>
                </Select>
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Required Field',
                  },
                ]}
                className={styles['input-width']}
                name="countries"
                label="Operating Countries"
                initialValue={data?.getCompany?.operatingCountries?.map(
                  data => `${data?.name};${data?.id}`,
                )}>
                <Select
                  mode="tags"
                  defaultValue={data?.getCompany?.operatingCountries?.map(
                    data => `${data?.name};${data?.id}`,
                  )}
                  value={operatingCountries}
                  onChange={e => setOperatingCountries(e)}>
                  {countries?.getAllTopics?.map(({name, icon, id}) => (
                    <Option key={`${name};${id}`}>
                      <div className={styles['all-data']}>
                        <img src={icon} alt="" />
                        <span>{name}</span>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Required Field',
                  },
                ]}
                className={styles['input-width']}
                name="shortDescription"
                label="Brief Bio (25 words)"
                initialValue={data?.getCompany?.shortDescription}>
                <Input.TextArea
                  value={shortDescription}
                  onChange={e => setShortDescription(e.target.value)}
                  name="shortDescription"
                  rows={4}
                  defaultValue={data?.getCompany?.shortDescription}
                  placeholder="Enter short bio"
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Required Field',
                  },
                ]}
                className={styles['input-width']}
                name="description"
                label="Description"
                initialValue={data?.getCompany?.description}>
                <Input.TextArea
                  rows={12}
                  value={description}
                  name="description"
                  onChange={e => setDescription(e.target.value)}
                  defaultValue={data?.getCompany?.description}
                  placeholder="Enter description"
                  required
                />
              </Form.Item>
              <div className={styles.overview}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Required Field',
                    },
                  ]}
                  className={styles['small-width']}
                  name="FoundedOn"
                  initialValue={moment(data?.getCompany?.foundedOn, 'YYYY')}
                  label="Founded on">
                  <DatePicker
                    name="Founded On"
                    className={styles['small-width']}
                    picker="year"
                    value={foundedOn}
                    defaultValue={moment(data?.getCompany?.foundedOn, 'YYYY')}
                    onChange={e => setFoundedOn(moment(e).format('YYYY'))}
                    placeholder={'Year Founded'}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Required Field',
                    },
                  ]}
                  className={styles['small-width']}
                  name="headquarters"
                  label="Headquarters"
                  initialValue={data?.getCompany?.headquarter}>
                  <Input
                    defaultValue={data?.getCompany?.headquarter}
                    value={headquarters}
                    onChange={e => setHeadquarters(e.target.value)}
                    placeholder="Enter Headquarters"
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Required Field',
                    },
                  ]}
                  className={styles['small-width']}
                  name="state"
                  label="City">
                  <Input
                    value={state}
                    onChange={e => setState(e.target.value)}
                    placeholder="Enter City"
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Required Field',
                    },
                  ]}
                  className={styles['small-width']}
                  name="accelerator"
                  label="Accelerator"
                  initialValue={data?.getCompany?.accelerator}>
                  <Input
                    defaultValue={data?.getCompany?.accelerator}
                    value={accelerator}
                    onChange={e => setAccelerator(e.target.value)}
                    placeholder="Enter Accelerator"
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Required Field',
                    },
                  ]}
                  className={styles['small-width']}
                  name="stage"
                  label="Stage"
                  initialValue={data?.getCompany?.stage}>
                  <Select
                    placeholder="Stage"
                    value={stage}
                    onChange={e => setStage(e)}
                    defaultValue={data?.getCompany?.stage}>
                    {stageData?.map(name => (
                      <Option key={name.value}>{name.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Required Field',
                    },
                  ]}
                  className={styles['small-width']}
                  name="ticker"
                  label="Ticker"
                  initialValue={data?.getCompany?.ticker}>
                  <Input
                    placeholder="Ticker"
                    value={ticker}
                    onChange={e => setTicker(e.target.value)}
                    defaultValue={data?.getCompany?.ticker}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Required Field',
                    },
                  ]}
                  className={styles['small-width']}
                  name="website"
                  label="Website"
                  initialValue={data?.getCompany?.website}>
                  <Input
                    placeholder="Website"
                    value={website}
                    onChange={e => setWebsite(e.target.value)}
                    defaultValue={data?.getCompany?.website}
                  />
                </Form.Item>
                <Form.Item
                  className={styles['small-width']}
                  name="captialRaised"
                  label="Total Capital Raised"
                  initialValue={data?.getCompany?.totalCapitalRaised}>
                  <InputNumber
                    placeholder="Total Capital Raised"
                    value={totalCapitalRaised}
                    min={0}
                    onChange={e => setTotalCapitalRaised(e)}
                    defaultValue={data?.getCompany?.totalCapitalRaised}
                    className={styles['input-number']}
                    formatter={value =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Number of Employees is Required',
                    },
                  ]}
                  initialValue={data?.getCompany?.numberOfEmployees}
                  label="No. of Employees"
                  name="employees">
                  <Select
                    value={numOfEmployees}
                    defaultValue={data?.getCompany?.numberOfEmployees}
                    style={{width: 340}}
                    onChange={e => setNumOfEmployees(e)}>
                    {noOfEmployeesOptions?.map(name => (
                      <Option key={name.value}>{name.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <h2 className={styles.title}>Socials</h2>
              <div className={styles.overview}>
                <Form.Item
                  className={styles['small-width']}
                  name="twitter"
                  label="Twitter">
                  <Input
                    placeholder="twitter link"
                    value={twitter}
                    defaultValue={data?.getCompany?.socials?.twitterUrl}
                    onChange={e => setTwitterURL(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  className={styles['small-width']}
                  name="facebook"
                  label="Facebook">
                  <Input
                    placeholder="facebook link"
                    value={facebook}
                    defaultValue={data?.getCompany?.socials?.facebookUrl}
                    onChange={e => setFacebookURL(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  className={styles['small-width']}
                  name="linkedin"
                  label="Linkedin">
                  <Input
                    placeholder="linkedin link"
                    value={linkedin}
                    defaultValue={data?.getCompany?.socials?.linkedInUrl}
                    onChange={e => setLinkedinURL(e.target.value)}
                  />
                </Form.Item>
              </div>
              <h2 className={styles.title}>Primary Contact Information</h2>
              <div className={styles.overview}>
                <Form.Item
                  className={styles['small-width']}
                  rules={[
                    {
                      required: true,
                      message: 'Required Field',
                    },
                  ]}
                  name="firstName"
                  label="First Name">
                  <Input
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    placeholder="Jane Doe"
                  />
                </Form.Item>
                <Form.Item
                  className={styles['small-width']}
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: 'Required Field',
                    },
                  ]}
                  label="Last Name">
                  <Input
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    placeholder="John Doe"
                  />
                </Form.Item>
                <Form.Item
                  className={styles['small-width']}
                  name="Email"
                  rules={[
                    {
                      type: 'email',
                      message: 'Please make sure your email is a valid email!',
                    },
                    {required: true, message: 'Please provide your email!'},
                  ]}
                  label="Email">
                  <Input
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="me@gmail.com"
                  />
                </Form.Item>
                <Form.Item
                  className={styles['small-width']}
                  name="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: 'Required Field',
                    },
                  ]}
                  label="Phone Number">
                  <Input
                    value={phoneNumber}
                    placeholder="+234709388837"
                    onChange={e => setPhoneNumber(e.target.value)}
                  />
                </Form.Item>
              </div>
              <Form.Item name="button">
                <SubmitButton
                  disabled={creationLoading}
                  className={styles.button}
                  label="Create"
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignProfile;
