import {useState} from 'react';
import styles from './trendingCountries.module.scss';
import {Button} from '@dabafinance/react-components';
import {Table, Spin, Form, Select, InputNumber, DatePicker, Modal} from 'antd';
import {useNavigate} from 'react-router-dom';
import {useQuery, useMutation, useLazyQuery} from '@apollo/client';
import pageNotFound from 'images/pageNotFound.png';
import {toast} from 'react-toastify';
import {
  GET_TRENDING_COUNTRIES,
  GET_TRENDING_COUNTRIES_BY_MONTH,
} from 'graphql/queries/trendingCountries';
import DBModal from 'components/modal/modal';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {
  CREATE_TRENDING_COUNTRIES,
  DELETE_TRENDING_COUNTRY,
  UPDATE_TRENDING_COUNTRIES,
} from 'graphql/mutations/trendingCountries';
import moment from 'moment';
import {GET_ALL_TOPICS} from 'graphql/queries/interest';
import trashImg from 'images/trash-can-outline.svg';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const TrendingCountries = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [trendingId, setTrendingId] = useState('');
  const [trendingCountries, setTrendingCountries] = useState([]);
  const [country, setCountry] = useState('');
  const [sequence, setSequence] = useState();
  const [deals, setDeals] = useState(0);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [trendingDetails, setTrendingDetails] = useState();
  const [filterMonth, setFilterMonth] = useState('');
  const [filterYear, setFilterYear] = useState('');
  const {data: getAllTopics} = useQuery(GET_ALL_TOPICS, {
    variables: {
      type: 'COUNTRY',
    },
  });

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Updates),
  );

  const {loading} = useQuery(GET_TRENDING_COUNTRIES, {
    onCompleted: data => (
      setTrendingId(data?.getTrendingCountries?.id),
      setTrendingDetails(data?.getTrendingCountries),
      setTrendingCountries(data?.getTrendingCountries?.trendingCountries)
    ),
    onError: error => toast.error(error),
  });
  const [updateTrendingCountries] = useMutation(UPDATE_TRENDING_COUNTRIES);
  const [createTrendingCountries] = useMutation(CREATE_TRENDING_COUNTRIES);
  const [getTrendingCountriesByMonth] = useLazyQuery(
    GET_TRENDING_COUNTRIES_BY_MONTH,
  );

  const {Option} = Select;
  const handleShow = () => {
    setIsOpen(true);
  };
  const handleModal = () => {
    setCreateModalOpen(true);
  };

  const handleFilter = () => {
    getTrendingCountriesByMonth({
      variables: {
        month: moment(filterMonth).format('MMMM'),
        year: moment(filterYear).format('YYYY'),
      },
      onCompleted: data => (
        setTrendingId(data?.getTrendingCountriesByMonth?.id),
        setTrendingDetails(data?.getTrendingCountriesByMonth),
        setTrendingCountries(
          data?.getTrendingCountriesByMonth?.trendingCountries,
        )
      ),
      onError: error => toast.error(error),
    });
  };

  const handleAddition = () => {
    const newArr = [];
    for (let i = 0; i < trendingCountries.length; i++) {
      const newObj = {
        country: trendingCountries[i]?.country?.id,
        sequence: trendingCountries[i]?.sequence,
        companies: trendingCountries[i]?.companies?.map(company => company?.id),
        totalAmountReceived: trendingCountries[i]?.totalAmountReceived,
        totalNumOfDeals: trendingCountries[i]?.totalNumOfDeals,
      };
      newArr.push(newObj);
    }
    newArr.push({
      country: country.split(';')[1],
      sequence,
      totalNumOfDeals: deals,
    });
    handleUpdate(newArr);
  };

  const handleUpdate = newArr => {
    updateTrendingCountries({
      variables: {
        input: {
          trendingCountriesId: trendingId,
          trendingCountries: newArr,
          month: trendingDetails?.month,
          year: trendingDetails?.year,
        },
      },
      refetchQueries: [{query: GET_TRENDING_COUNTRIES}],
    })
      .then(({data: {updateTrendingCountries}}) => {
        if (updateTrendingCountries.__typename === 'Error') {
          toast.error(updateTrendingCountries.message);
        } else {
          toast.success('Country Added Successfully');
        }
        setIsOpen(false);
      })
      .catch(error => {
        toast.error(error);
      });
  };
  const handleCreate = () => {
    createTrendingCountries({
      variables: {
        input: {
          month: moment(month).format('MMMM'),
          year: moment(year).format('YYYY'),
          trendingCountries: [
            {
              country: country.split(';')[1],
            },
          ],
        },
      },
      refetchQueries: [{query: GET_TRENDING_COUNTRIES}],
    })
      .then(({data: {createTrendingCountries}}) => {
        if (createTrendingCountries.__typename === 'Error') {
          toast.error(createTrendingCountries.message);
        } else {
          toast.success('Country Created Successfully');
        }
        setCreateModalOpen(false);
      })
      .catch(error => {
        toast.error(error);
      });
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Trending Countries</h2>
        <Form
          className={styles['date-filter']}
          layout="inline"
          onFinish={handleFilter}>
          <Form.Item
            label="Month"
            name="month"
            rules={[
              {
                required: true,
                message: 'Month is required',
              },
            ]}>
            <DatePicker
              picker="month"
              value={filterMonth}
              onChange={setFilterMonth}
              format={'MMMM'}
            />
          </Form.Item>
          <Form.Item
            label="Year"
            name="year"
            rules={[
              {
                required: true,
                message: 'Year is required',
              },
            ]}>
            <DatePicker
              picker="year"
              value={filterYear}
              format={'YYYY'}
              onChange={setFilterYear}
            />
          </Form.Item>
          <SubmitButton
            smallWidth
            type="secondary"
            className={styles['filter-button']}
            label="Filter"
          />
        </Form>
        <div className={styles.actions}>
          {trendingDetails === null ? (
            <Button
              className={styles['add-button']}
              onClick={handleModal}
              disabled={!tabOperations?.create}
              label="Create Trending Country"
            />
          ) : (
            <Button
              className={styles['add-button']}
              disabled={!tabOperations?.update}
              onClick={handleShow}
              label="Add Trending Country"
            />
          )}

          <DBModal
            isOpen={createModalOpen}
            content={
              <div>
                <div className={styles['modal-title']}>
                  <span>Create Trending Country</span>
                </div>
                <Form layout="vertical">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Country is required',
                      },
                    ]}
                    label="Select Country"
                    className={styles.field}>
                    <Select
                      showSearch
                      value={country}
                      onChange={e => setCountry(e)}>
                      {getAllTopics?.getAllTopics.map(({name, icon, id}) => (
                        <Option key={`${name};${id}`}>
                          <div className={styles['all-data']}>
                            <img src={icon} alt="" />
                            <span>{name}</span>
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className={styles['form-input']}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Month is required',
                        },
                      ]}
                      label="Month"
                      className={styles.field}
                      name="month">
                      <DatePicker
                        picker="month"
                        value={month}
                        onChange={e => setMonth(e)}
                        format={'MMMM'}
                        className={styles['month-input']}
                      />
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Year is required',
                        },
                      ]}
                      label="Year"
                      className={styles.field}
                      name="year">
                      <DatePicker
                        picker="year"
                        value={year}
                        format={'YYYY'}
                        onChange={e => setYear(e)}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item label="Ranking" className={styles.field}>
                    <InputNumber
                      className={styles['input-number']}
                      placeholder="1"
                      onChange={e => setSequence(e)}
                    />
                  </Form.Item>
                  <SubmitButton
                    type="primary"
                    handleClick={() => handleCreate()}
                    label="Add"
                  />
                </Form>
              </div>
            }
            handleClose={() => setCreateModalOpen(false)}></DBModal>
          <DBModal
            isOpen={isOpen}
            content={
              <div>
                <div className={styles['modal-title']}>
                  <span>Add Trending Country</span>
                </div>
                <Form layout="vertical">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Country is required',
                      },
                    ]}
                    label="Select Country"
                    className={styles.field}>
                    <Select
                      showSearch
                      value={country}
                      onChange={e => setCountry(e)}>
                      {getAllTopics?.getAllTopics.map(({name, icon, id}) => (
                        <Option key={`${name};${id}`}>
                          <div className={styles['all-data']}>
                            <img src={icon} alt="" />
                            <span>{name}</span>
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <SubmitButton
                    type="primary"
                    handleClick={() => handleAddition()}
                    label="Add"
                  />
                </Form>
              </div>
            }
            handleClose={() => setIsOpen(false)}></DBModal>
        </div>
      </div>
      <RenderTable
        trendingCountries={trendingCountries}
        trendingDetails={trendingDetails}
        setDeals={setDeals}
      />
    </div>
  );
};

export default TrendingCountries;

const RenderTable = ({trendingCountries, trendingDetails}) => {
  const navigate = useNavigate();

  const {confirm} = Modal;
  const [deleteTrendingCountry] = useMutation(DELETE_TRENDING_COUNTRY);

  const handleDelete = id => {
    confirm({
      content: 'Are you sure you want to delete this trending country?',
      onOk: () => {
        deleteTrendingCountry({
          variables: {
            trendingCountriesId: trendingDetails?.id,
            trendingCountryId: id,
          },
          update(cache) {
            cache.modify({
              fields: {
                getTrendingCountries() {},
              },
            });
          },
        }).then(({data: {deleteTrendingCountry}}) => {
          if (deleteTrendingCountry.__typename === 'Error') {
            toast.error(deleteTrendingCountry.message);
          } else {
            toast.success('Deleted Successfully');
            window.location.reload();
          }
        });
      },
    });
  };

  const columns = [
    {
      title: 'Country',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <img src={record?.country?.icon} alt="" />
          <span>{record?.country?.name}</span>
        </div>
      ),
    },
    {
      title: 'Ranking',
      dataIndex: 'sequence',
      key: 'sequence',
      render: (text, record, index) => (
        <div className={styles['record-label']}>
          <span>{index + 1}</span>
        </div>
      ),
    },

    {
      title: 'Investment Received',
      dataIndex: 'totalAmountReceived',
      key: 'totalAmountReceived',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>${record?.totalAmountReceived}</span>
        </div>
      ),
    },
    {
      title: 'Total Deals',
      dataIndex: 'totalNumOfDeals',
      key: 'totalNumOfDeals',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>{record?.totalNumOfDeals}</span>
        </div>
      ),
    },
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
      render: () => (
        <div className={styles['record-label']}>
          <span>{trendingDetails?.month}</span>
        </div>
      ),
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      render: () => (
        <div className={styles['record-label']}>
          <span>{trendingDetails?.year}</span>
        </div>
      ),
    },
    {
      title: 'Companies',
      dataIndex: 'companies',
      key: 'companies',
      render: (text, record) => (
        <div className={styles['record-label']}>
          {record?.companies?.length > 0 ? (
            record?.companies?.length === 1 ? (
              <div className={styles['span-element']}>
                <span>{record?.companies[0]?.name}</span>
              </div>
            ) : (
              <div className={styles['span-element']}>
                <span>{record?.companies[0]?.name}</span>
                <span className={styles['company-length']}>
                  +{record?.totalNumOfDeals - 1}
                </span>
              </div>
            )
          ) : (
            <Button
              label="Add Company"
              onClick={() =>
                navigate(`/dashboard/explore/trending-countries/${record.id}`)
              }
            />
          )}
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <div>
          <div className={styles['action-buttons']}>
            {!!record?.companies?.length && (
              <Button
                type="secondary"
                label="View details"
                className={styles.button}
                onClick={() =>
                  navigate(`/dashboard/explore/trending-countries/${record.id}`)
                }
              />
            )}
            <img
              onClick={() => handleDelete(record?.id)}
              className={styles.trash}
              src={trashImg}
              alt=""
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      {trendingCountries?.length > 0 ? (
        <Table
          dataSource={[...trendingCountries]?.sort(
            (a, b) => b?.totalAmountReceived - a?.totalAmountReceived,
          )}
          columns={columns}
        />
      ) : (
        <div className={styles['no-update']}>
          <img src={pageNotFound} alt="" />
          <h1>No Trending Countries</h1>
          <span>Add a new Trending Countries</span>
        </div>
      )}
    </div>
  );
};
