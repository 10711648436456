import {gql} from '@apollo/client';

export const GET_COMPANY_DOCUMENTS = gql`
  query GetCompanyDocument($filter: CompanyDocumentFilter) {
    getCompanyDocument(filter: $filter) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCompanyDocuments {
        data {
          documentCategory {
            description
            id
            name
          }
          documentName
          documentUrl
          documentUrlSource
          id
          issuedDate
          language
          multilang {
            language
            documentName
            documentUrl
            documentUrlSource
          }
          restrictLanguage
        }
      }
    }
  }
`;

export const GET_SINGLE_USER_DOCUMENT = gql`
  query GetOneCompanyDocument($documentId: ID!) {
    getOneCompanyDocument(documentId: $documentId) {
      ... on Error {
        message
        statusCode
        __typename
      }
      ... on ResponseWithCompanyDocument {
        data {
          id
          documentName
          documentUrl

          restrictLanguage
          issuedDate
          language
          multilang {
            language
            documentName
            documentUrl
          }
          documentCategory {
            description
            name
            id
          }
        }
      }
    }
  }
`;

export const GET_ALL_COMPANY_DOCUMENT_CATEGORIES = gql`
  query GetCompanyDocumentCategories($filter: CompanyDocumentCategoryFilter) {
    getCompanyDocumentCategories(filter: $filter) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCompanyDocumentCategories {
        message
        pagination {
          page
          limit
          pages
          total
          hasPrevPage
          hasNextPage
          previous
          next
        }
        data {
          id
          description
          name
        }
      }
    }
  }
`;

export const GET_COMPANY_DOCUMENT_CATEGORY = gql`
  query GetCompanyDocumentCategoryById($categoryId: ID!) {
    getCompanyDocumentCategoryById(categoryId: $categoryId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCompanyDocumentCategory {
        message
        data {
          id
          name
          description
        }
      }
    }
  }
`;
