import {useEffect, useState} from 'react';
import styles from './uploadDocument.module.scss';
import backIcon from 'images/back.png';
import {DatePicker, Form, Input, Select, Spin} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {toast} from 'react-toastify';
import FadeEffect from 'components/animation/fadeEffect';
import ReportPDF from 'components/reportPDF';
import {
  GET_USER_DOCUMENTS,
  GET_USER_DOCUMENT_BY_ID,
  GET_USER_DOCUMENT_CATEGORIES,
} from 'graphql/queries/userDocuments';
import {
  CREATE_USERDOCUMENTS,
  UPDATE_USER_DOCUMENTS,
} from 'graphql/mutations/userDocuments';
import moment from 'moment';

const UploadUserDocument = () => {
  const [issuedDate, setIssuedDate] = useState('');
  const [name, setName] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [pdfLink, setPdfLink] = useState('');
  const [hasLink, setHasLink] = useState(true);
  const navigate = useNavigate();
  const {id, documentId} = useParams();
  const {Option} = Select;
  const [form] = Form.useForm();

  const {data, loading} = useQuery(GET_USER_DOCUMENT_BY_ID, {
    variables: {
      documentId,
    },
  });
  const {data: getCategory, loading: catLoading} = useQuery(
    GET_USER_DOCUMENT_CATEGORIES,
  );
  const [CreateUserDocument, {loading: createDocument}] =
    useMutation(CREATE_USERDOCUMENTS);
  const [UpdateUserDocument, {loading: updateDocument}] = useMutation(
    UPDATE_USER_DOCUMENTS,
  );

  const fieldWidth = 730;

  useEffect(() => {
    setHasLink(true);
    if (data?.getUserDocumentById?.data) {
      const {documentName, issuedDate, documentCategory, documentUrl} =
        data?.getUserDocumentById?.data;
      setPdfLink(documentUrl);
      setPreviewUrl(documentUrl);
      form.setFieldsValue({
        documentName,
        issuedDate: issuedDate ? moment(issuedDate, 'YYYY-MM-DD') : '',
        documentCategory,
      });
    }
  }, [data]);

  useEffect(() => {
    if (pdfLink.length > 0) {
      setHasLink(false);
    } else {
      setHasLink(true);
    }
  }, [pdfLink]);

  const handleSave = values => {
    const {issuedDate, documentName, documentCategory} = values;
    if (documentId) {
      UpdateUserDocument({
        variables: {
          data: {
            documentId: documentId,
            user: id,
            documentCategory,
            documentName,
            issuedDate,
            documentUrl: pdfLink,
          },
        },
        refetchQueries: [
          {query: GET_USER_DOCUMENTS, variables: {filter: {userId: id}}},
        ],
      }).then(({data: {updateUserDocument}}) => {
        if (updateUserDocument.__typename === 'Error') {
          toast.error(updateUserDocument.message);
        } else {
          toast.success('Uploaded Successfully');
          navigate(`/dashboard/user/${id}/documents`);
        }
      });
    } else {
      CreateUserDocument({
        variables: {
          data: {
            user: id,
            documentName: name,
            documentCategory,
            issuedDate,
            documentUrl: pdfLink,
          },
        },
        refetchQueries: [
          {query: GET_USER_DOCUMENTS, variables: {filter: {userId: id}}},
        ],
      }).then(({data: {createUserDocument}}) => {
        if (createUserDocument.__typename === 'Error') {
          toast.error(createUserDocument.message);
        } else {
          toast.success('Uploaded Successfully');
          navigate(`/dashboard/user/${id}/documents`);
        }
      });
    }
  };

  return loading ? (
    <div className={styles.loading}>
      <Spin size="large" />
      <h1>Loading...</h1>
    </div>
  ) : (
    <div>
      <div className={styles.navigation}>
        <img src={backIcon} alt="Go back" onClick={() => navigate(-1)} />
        <span>
          User Document
          <span className={styles['h-style']}>/ Upload Document</span>
        </span>
      </div>
      <div className={styles.form}>
        <Form onFinish={handleSave} form={form} layout="vertical">
          <FadeEffect>
            <ReportPDF
              setPdfLink={setPdfLink}
              previewUrl={previewUrl}
              setPreviewUrl={setPreviewUrl}
              large
              className={styles.pdf}
              bucket="startups-bucket"
            />
          </FadeEffect>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'This Field is Required',
              },
            ]}
            label="Document Name"
            className={styles.field}
            name="documentName">
            <Input
              style={{width: fieldWidth}}
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder={'name'}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'This Field is Required',
              },
            ]}
            label="Issued Date"
            className={styles.field}
            name="issuedDate">
            <DatePicker
              value={issuedDate}
              style={{width: fieldWidth}}
              format={'YYYY-MM-DD'}
              onChange={e => setIssuedDate(e)}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
              },
            ]}
            label="Document Category"
            className={styles.field}
            name="documentCategory">
            <Select style={{width: fieldWidth}} showSearch loading={catLoading}>
              {getCategory?.getUserDocumentCategories?.data?.map(
                (data, index) => (
                  <Option key={index} value={data?.label}>
                    <div className={styles['all-data']}>
                      <span>{data?.name}</span>
                    </div>
                  </Option>
                ),
              )}
            </Select>
          </Form.Item>
          <SubmitButton
            disabled={hasLink || createDocument || updateDocument}
            label="Save Changes"
          />
        </Form>
      </div>
    </div>
  );
};

export {UploadUserDocument};
