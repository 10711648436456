import {useQuery} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {Form, Select, Spin} from 'antd';
import {GET_ALL_STARTUPS} from 'graphql/queries/startupCompanies';
import {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styles from './companySelect.module.scss';
import backImg from 'images/back.png';

const CompanySelect = () => {
  const [company, setCompany] = useState('');
  const {Option} = Select;
  const navigate = useNavigate();
  const {loading, data} = useQuery(GET_ALL_STARTUPS);
  const {slug} = useParams();

  if (loading) {
    <div className={styles.loading}>
      <Spin size="large" />
      <h1>Loading...</h1>
    </div>;
  }

  const handleSelectCompany = () => {
    slug
      ? navigate(
          `/dashboard/campaigns/${slug}/new/profile/${company.split('/')[1]}`,
        )
      : navigate(`/dashboard/campaigns/new/profile/${company.split('/')[1]}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backImg} alt="" />
        <h1>Select Company</h1>
      </div>
      <div>
        <Form layout="vertical">
          <Form.Item
            rules={[
              {
                required: true,
                message: 'A Company must be selected to continue',
              },
            ]}
            style={{width: 621}}
            name="Company"
            label="Company">
            <Select
              showSearch
              allowClear
              value={company}
              onChange={e => setCompany(e)}>
              {data?.getAllCompanies?.map(company => (
                <Option key={[`${company.name}/${company.id}`]}>
                  <div className={styles['all-data']}>
                    <img alt="" src={company.logoImgURL} />
                    <span>{company.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            disabled={!company}
            label="Continue"
            onClick={handleSelectCompany}
            className={styles.button}
          />
        </Form>
      </div>
    </div>
  );
};

export default CompanySelect;
