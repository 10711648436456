import {Form, InputNumber, Select} from 'antd';
import styles from './updateSingleSavingsProduct.module.scss';
import {useEffect, useState} from 'react';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {UPDATE_SAVINGS_PRODUCT} from 'graphql/mutations/savings';
import {useMutation} from '@apollo/client';
import {handleUpdateSavingsProduct} from 'pages/Savings/companies/savingsCompanyActions';
import {Button} from '@dabafinance/react-components';

const InterestProductsInfo = ({data, onActiveTabChange, refetch, id}) => {
  const [formValues, setFormValues] = useState({});
  const [form] = Form.useForm();
  const [openFinishModal, setOpenFinishModal] = useState(false);

  const [updateSavingsProduct, {loading}] = useMutation(UPDATE_SAVINGS_PRODUCT);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        interestPayoutFrequency: data?.config?.interestPayoutFrequency,
        annualInterest: data?.config?.annualInterest,
        interestAccrualFrequency: data?.config?.interestAccrualFrequency,
        balanceSummary: data?.config?.balanceSummary,
        interestCalculationType: data?.config?.interestCalculationType,
      });
    }
  }, [data]);

  return (
    <div className={styles.generalRoot}>
      <h1>Interest Configuration</h1>
      <Form
        onFinish={e => {
          setFormValues({
            config: {
              interestPayoutFrequency: e.interestPayoutFrequency,
              annualInterest: Number(e.annualInterest),
            },
          });
          setOpenFinishModal(true);
        }}
        form={form}
        layout="vertical">
        <Form.Item
          rules={[{required: true, message: 'This is a Required Field'}]}
          label="Interest Payout Frequency"
          className={styles.field}
          name="interestPayoutFrequency">
          <Select>
            <Select.Option key="DAILY">Daily</Select.Option>
            <Select.Option key="WEEKLY">Weekly</Select.Option>
            <Select.Option key="MONTHLY">Monthly</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: 'This is a Required Field'}]}
          name="annualInterest"
          className={styles.field}
          label="Annual Interest Amount (%)">
          <InputNumber min={1} max={100} className={styles.numberField} />
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: 'This is a Required Field'}]}
          label="Interest Accrual Frequency"
          className={styles.field}
          name="interestAccrualFrequency">
          <Select disabled>
            <Select.Option key="DAILY">Daily</Select.Option>
            <Select.Option key="WEEKLY">Weekly</Select.Option>
            <Select.Option key="MONTHLY">Monthly</Select.Option>
            <Select.Option key="YEARLY">Yearly</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: 'This is a Required Field'}]}
          label="Balance Summary"
          className={styles.field}
          name="balanceSummary">
          <Select disabled>
            <Select.Option key="RUNNING_BALANCE">Running Balance</Select.Option>
            <Select.Option key="DAILY_BALANCE">Daily Balance</Select.Option>
            <Select.Option key="AVERAGE_BALANCE">Average Balance</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: 'This is a Required Field'}]}
          label="Interest Calculation Type"
          className={styles.field}
          name="interestCalculationType">
          <Select disabled>
            <Select.Option key="SIMPLE">Simple</Select.Option>
            <Select.Option key="COMPOUND">Compound</Select.Option>
          </Select>
        </Form.Item>
        <div className={styles.buttons}>
          <Button
            type="secondary"
            label="Next"
            onClick={() => onActiveTabChange('BALANCE')}
          />
          <SubmitButton smallWidth label="Proceed to Update" />
        </div>
      </Form>
      <ConfirmModal
        openModal={openFinishModal}
        title="Update Product Details"
        confirmText={'Are you sure all the details are inputed correctly?'}
        loading={loading}
        setOpenModal={() => setOpenFinishModal(false)}
        handleOperation={() =>
          handleUpdateSavingsProduct({
            formValues,
            id,
            updateSavingsProduct,
            setOpenFinishModal,
            refetch,
          })
        }
      />
    </div>
  );
};

export default InterestProductsInfo;
