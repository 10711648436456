import {Form, Input, Select} from 'antd';
import {Fragment, useEffect} from 'react';
import styles from './baseInvestmentContract.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useNavigate, useParams} from 'react-router-dom';
import {createBondsStepTwoRoute} from 'pages/AssetManagement/Bonds/routes';

export const BaseInvestmentContract = ({handleSave, existingData}) => {
  const [form] = Form.useForm();
  const {Option} = Select;
  const navigate = useNavigate();
  const {assetId, id} = useParams();
  const isListed = existingData?.listingType !== 'LISTED';

  useEffect(() => {
    if (existingData.investmentContractSettings) {
      form.setFieldsValue({...existingData.investmentContractSettings});
    }
  }, [existingData]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Investment Contract Settings</h1>
      </div>
      <div>
        <Form
          form={form}
          onFinish={values =>
            handleSave({
              investmentContractSettings: values,
            })
          }
          layout="vertical">
          <Fragment>
            <div className={styles['question-style']}>
              <Form.Item
                className={styles.smallWidth}
                name="investmentContractType"
                rules={[
                  {required: isListed, message: 'This is a Required field'},
                ]}
                label="Investment Contract Type">
                <Select placeholder="Select contract type">
                  <Option value="SIMPLE_AGREEMENT_FOR_FUTURE_EQUITY">
                    Simple Agreement for Future Equity
                  </Option>
                  <Option value="PRICED_EQUITY">Priced Equity</Option>
                  <Option value="DEBT">Debt</Option>
                  <Option value="REVENUE_SHARE">Revenue Share</Option>
                </Select>
              </Form.Item>
              <Form.Item
                className={styles.smallWidth}
                rules={[
                  {required: isListed, message: 'This is a Required field'},
                ]}
                name="investmentTargetEntityLegalName"
                label="Investment Target Entity Legal Name">
                <Input placeholder="Investment Target Entity Legal Name" />
              </Form.Item>
            </div>
            <div className={styles['question-style']}>
              <Form.Item
                className={styles.smallWidth}
                name="managementFeeTerms"
                rules={[
                  {required: isListed, message: 'This is a Required field'},
                ]}
                label="Management Fee Terms">
                <Input placeholder="Management Fee Terms" />
              </Form.Item>
              <Form.Item
                className={styles.smallWidth}
                name="minimumInvestmentPerMember"
                rules={[
                  {required: isListed, message: 'This is a Required field'},
                ]}
                label="Minimum Investment Per Member">
                <Input placeholder="Minimum Investment Per Member" />
              </Form.Item>
            </div>
            <div className={styles['question-style']}>
              <Form.Item
                className={styles.smallWidth}
                name="spvManagerRepresentativeName"
                rules={[
                  {required: isListed, message: 'This is a Required field'},
                ]}
                label="Spv Manager Representative Name">
                <Input placeholder="Spv Manager Representative Name" />
              </Form.Item>
              <Form.Item
                className={styles.smallWidth}
                name="spvNamePrefix"
                rules={[
                  {required: isListed, message: 'This is a Required field'},
                ]}
                label="Spv Name Prefix">
                <Input placeholder="Spv Name Prefix" />
              </Form.Item>
            </div>
            <div className={styles['question-style']}>
              <Form.Item
                className={styles.smallWidth}
                name="carryPercentageTerms"
                rules={[
                  {required: isListed, message: 'This is a Required field'},
                ]}
                label="Percentage Terms">
                <Input placeholder="Percentage Terms" />
              </Form.Item>
            </div>
          </Fragment>
          <div>
            <SubmitButton label="Save & Continue" />
            {!isListed && !id && (
              <SubmitButton
                handleClick={() =>
                  navigate(createBondsStepTwoRoute({param: assetId, name: ''}))
                }
                secondary
                label="Skip to Next Step"
              />
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};
