import {gql} from '@apollo/client';

export const CREATE_EMAIL_DISCLOSURE = gql`
  mutation CreateEmailDisclosure($data: CreateEmailDisclosureInput!) {
    createEmailDisclosure(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on EmailDisclosure {
        id
        title
        content
        type
        language
      }
    }
  }
`;

export const UPDATE_EMAIL_DISCLOSURE = gql`
  mutation UpdateEmailDisclosure($data: UpdateEmailDisclosureInput!) {
    updateEmailDisclosure(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on EmailDisclosure {
        id
        title
        content
        type
        language
      }
    }
  }
`;

export const DELETE_EMAIL_DISCLOSURE = gql`
  mutation DeleteEmailDisclosure($disclosureId: ID!) {
    deleteEmailDisclosure(disclosureId: $disclosureId) {
      ... on Error {
        message
        statusCode
      }
      ... on ReturnedMessage {
        message
        response
      }
    }
  }
`;
