import styles from './percentageCard.module.scss';
import {Spin, Tooltip} from 'antd';
import {ArrowUpOutlined} from '@ant-design/icons';
import {Receipt} from 'icons';

const PercentageCard = ({
  amount,
  label,
  perecentage,
  active,
  chart,
  postive,
  loading,
  handleClick,
  showBottom,
}) => {
  return (
    <div
      onClick={handleClick}
      className={active ? styles['active-card'] : styles['card-container']}>
      <div className={styles.top}>
        <div className={styles.cardIcon}>
          <Receipt />
        </div>
        <div>
          {loading ? (
            <div className={styles.loading}>
              <Spin size="small" />
            </div>
          ) : (
            <span>{label}</span>
          )}
          <div className={styles['top-control']}>
            <h1>{new Intl.NumberFormat().format(amount)}</h1>
            {chart && (
              <Tooltip placement="topLeft" title="View Chart">
                <ArrowUpOutlined
                  onClick={handleClick}
                  style={{fontSize: '30px'}}
                  className={styles['chart-icon']}
                />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      {showBottom && (
        <div className={styles.bottom}>
          <h2>Last month</h2>
          <span className={postive ? styles.positive : styles.negative}>
            {perecentage}
          </span>
        </div>
      )}
    </div>
  );
};

export default PercentageCard;
