import {useState} from 'react';
import styles from './addUsers.module.scss';
import {useMutation, useQuery} from '@apollo/client';
import {useNavigate, useParams} from 'react-router-dom';
import {Form, Select, Spin, Table} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {ADD_USERS_TO_A_GROUP, REMOVE_MEMBER} from 'graphql/mutations/groups';
import {toast} from 'react-toastify';
import {GET_ALL_GROUPS, GET_GROUP_BY_ID} from 'graphql/queries/groups';
import DBModal from 'components/modal/modal';
import gamerIcon from 'images/gamer.png';
import moment from 'moment';
import {UserSelectField} from 'components/userSelectField/UserSelectField';
import {Button} from '@dabafinance/react-components';

const AddUsersToGroup = ({operations}) => {
  const [users, setUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const {id} = useParams();

  const limit = 20;
  const {data, networkStatus} = useQuery(GET_GROUP_BY_ID, {
    variables: {
      groupId: id,
      page,
      limit,
    },
  });
  const [addMembersToGroup, {loading: loadingUpdate}] =
    useMutation(ADD_USERS_TO_A_GROUP);

  const [removeMemberFromGroup, {loading: removeMember}] =
    useMutation(REMOVE_MEMBER);

  const handleDelete = () => {
    removeMemberFromGroup({
      variables: {
        groupId: id,
        memberIds: members.map(data => data.split(';')[2]),
      },
      update(cache) {
        cache.modify({
          fields: {
            getGroupById() {},
          },
        });
      },
    }).then(({data: {removeMemberFromGroup}}) => {
      if (removeMemberFromGroup.__typename === 'Error') {
        toast.error(removeMemberFromGroup.message);
        setOpenRemoveModal(false);
      } else {
        setMembers([]);
        toast.success('Removed Successfully');
        setOpenRemoveModal(false);
      }
    });
  };

  const disableNextPage =
    networkStatus === 2 ||
    Math.ceil(data?.getGroupById?.group?.memberCount / limit) === page;

  if (networkStatus === 1) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <div
            onClick={() => navigate(`/dashboard/user/${record?.id}`)}
            className={styles['record-name']}>
            {record.imageUrl ? (
              <img src={record?.imageUrl} alt="" />
            ) : (
              <img src={gamerIcon} alt="" />
            )}
            <h4 style={{width: 100}}>
              {record?.firstName} {record?.lastName}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (text, record) => (
        <div>
          <h4>{record?.username}</h4>
        </div>
      ),
    },
    {
      title: 'Phone Number',
      dataIndex: 'expiring',
      key: 'expiring',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>{`${record?.countryCode ?? '--'}${
            record?.phoneNumber ?? '--'
          }`}</h4>
        </div>
      ),
    },
    {
      title: 'Profile Completion',
      dataIndex: 'profileCompleted',
      key: 'invested',
      render: (text, record) => (
        <div>
          <span>{record?.profileCompletion?.completionPercentage}%</span>
        </div>
      ),
    },
    {
      title: 'Accredited',
      dataIndex: 'accredited',
      key: 'accredited',
      render: (text, record) => (
        <div>
          <h4>
            {record?.investorProfile?.isAccredited
              ? 'Accredited'
              : 'Unaccredited'}
          </h4>
        </div>
      ),
    },
    {
      title: 'Date Joined',
      dataIndex: 'createdAt',
      key: 'joined',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>{moment(record.createdAt).format('MMM Do YYYY')}</h4>
        </div>
      ),
    },
  ];

  const handleSubmit = () => {
    addMembersToGroup({
      variables: {
        groupId: id,
        memberIds: users.map(data => data.split(';')[2]),
      },
      refetchQueries: [
        {query: GET_ALL_GROUPS},
        {query: GET_GROUP_BY_ID, variables: {groupId: id}},
      ],
    })
      .then(({data: {addMembersToGroup}}) => {
        if (addMembersToGroup.response) {
          toast.success('Member(s) added successful');
          setOpenModal(false);
          setUsers([]);
        } else {
          toast.error('Member already exists');
        }
      })
      .catch(error => toast.error(error));
  };

  return (
    <div>
      <div className={styles.title}>
        <h1>Group Members</h1>
        <div className={styles['action-buttons']}>
          <SubmitButton
            handleClick={() => setOpenModal(true)}
            label="Add Users"
            disabled={!operations?.update}
          />
          <SubmitButton
            secondary
            disabled={!operations?.update}
            handleClick={() => setOpenRemoveModal(true)}
            label="Remove Users"
          />
        </div>
      </div>
      <div>
        <div className={styles['nav-buttons']}>
          <Button
            onClick={() => setPage(current => current - 1)}
            label="Previous"
            type="secondary"
            disabled={page === 1 || networkStatus === 2}
            className={styles.button}
          />
          <div className={styles['page-number']}>{page}</div>
          <Button
            onClick={() => setPage(current => current + 1)}
            label="Next"
            disabled={disableNextPage}
            className={styles.button}
          />
        </div>
      </div>
      <div>
        <Table
          dataSource={data?.getGroupById?.group?.members}
          columns={columns}
          className={styles['users-table']}
          pagination={false}
          loading={networkStatus === 2}
        />
      </div>
      <DBModal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        content={
          <div className={styles.body}>
            <Form onFinish={handleSubmit} layout="vertical">
              <Form.Item
                label="Select Users"
                name="users"
                className={styles.width}>
                <UserSelectField users={users} setUsers={setUsers} />
              </Form.Item>
              <SubmitButton label="Add Users" disabled={loadingUpdate} />
            </Form>
          </div>
        }
      />
      <DBModal
        isOpen={openRemoveModal}
        handleClose={() => setOpenRemoveModal(false)}
        content={
          <div className={styles.body}>
            <h2>Remove Members</h2>
            <Form onFinish={handleDelete} layout="vertical">
              <Form.Item
                label="Select Users"
                name="users"
                rules={[{required: true}]}>
                <Select
                  mode="multiple"
                  placeholder="Select users"
                  className={styles.width}
                  showSearch
                  value={members}
                  onChange={e => setMembers(e)}>
                  {data?.getGroupById?.group?.members?.map(data => (
                    <Select.Option
                      key={`${data.firstName};${data.lastName};${data.id}`}>
                      <div className={styles.user}>
                        <img src={data.imageUrl} alt="" />
                        <span>
                          {data.firstName} {data.lastName}
                        </span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <SubmitButton label="Remove Users" disabled={removeMember} />
            </Form>
          </div>
        }
      />
    </div>
  );
};

export default AddUsersToGroup;
