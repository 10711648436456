import {useState, useEffect} from 'react';
import styles from './slider.module.scss';
import classNames from 'classnames/bind';

import dataSlider from './dataSlider';

export default function Slider() {
  const [slideIndex, setSlideIndex] = useState(1);
  const cx = classNames.bind(styles);
  const nextSlide = () => {
    if (slideIndex !== dataSlider.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === dataSlider.length) {
      setSlideIndex(1);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, 4000);

    return () => clearInterval(intervalId);
  });

  const moveDot = index => {
    setSlideIndex(index);
  };

  return (
    <>
      <div className={cx('container-slider')}>
        {dataSlider.map((obj, index) => {
          return (
            <div
              key={obj.id}
              className={cx(
                slideIndex === index + 1 ? ['slide', 'active-anim'] : 'slide',
              )}>
              <div className={cx('img-container')}>
                <img
                  src={process?.env?.PUBLIC_URL + `/slides/img${index + 1}.png`}
                  alt="slides"
                />
              </div>

              <div className={cx('community-section')}>
                <h1>{obj.title}</h1>
                <p>{obj.subTitle}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className={cx('container-dots-main')}>
        <div className={cx('container-dots')}>
          {Array.from({length: 5}).map((item, index) => (
            // eslint-disable-next-line react/jsx-key
            <div
              onClick={() => moveDot(index + 1)}
              className={cx(
                slideIndex === index + 1 ? ['dot', 'active'] : 'dot',
              )}></div>
          ))}
        </div>
      </div>
    </>
  );
}
