import gql from 'graphql-tag';

export const GET_TRENDING_STARTUPS = gql`
  query GetTrendingCompanies($type: TrendingCompanyQueryType) {
    getTrendingCompanies(type: $type) {
      ... on Error {
        message
        statusCode
      }
      ... on TrendingCompany {
        id
        isTrending
        company {
          id
          name
          logoImgURL
          ticker
          stage
          totalCapitalRaised
        }
      }
    }
  }
`;
