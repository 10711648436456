import styles from './avatar.module.scss';

const Avatar = ({alt, src}) => {
  return (
    <div className={styles.profileImg}>
      <img alt={alt} loading="lazy" src={src} />
    </div>
  );
};

export default Avatar;
