import {Spin, Table} from 'antd';
import {useState} from 'react';
import styles from './trendingStartups.module.scss';

import {Button} from '@dabafinance/react-components';
import DBModal from 'components/modal/modal';
import pageNotFound from 'images/pageNotFound.png';
import {useMutation, useQuery} from '@apollo/client';
import {GET_TRENDING_STARTUPS} from 'graphql/queries/trendingStartups';
import AddAStartup from './AddAStartup';
import {REMOVE_TRENDING_COMPANY} from 'graphql/mutations/trendingStartups';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const TrendingStartups = () => {
  const [openModal, setOpenModal] = useState(false);

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Updates),
  );

  const {loading, data} = useQuery(GET_TRENDING_STARTUPS, {
    onError: () =>
      toast.error('Something went wrong while fetching Trending Companies'),
    variables: {
      type: 'NOW',
    },
  });

  const [removeTrendingCompany] = useMutation(REMOVE_TRENDING_COMPANY);

  const handleRemoveCompany = id => {
    removeTrendingCompany({
      variables: {
        companyId: id,
      },
      update(cache) {
        cache.modify({
          fields: {
            getTrendingCompanies() {},
          },
        });
      },
    }).then(({data: {removeTrendingCompany}}) => {
      if (removeTrendingCompany.__typename === 'Error') {
        toast.error(removeTrendingCompany.message);
      } else {
        toast.success('Removed Successfully');
      }
    });
  };

  const amountFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <img src={record?.company?.logoImgURL} alt="" />
          <h4>{record?.company?.name}</h4>
        </div>
      ),
    },
    {
      title: 'Tiker',
      dataIndex: 'tiker',
      key: 'tiker',
      render: (text, render) => render?.company?.ticker,
    },
    {
      title: 'Funding Type',
      dataIndex: 'stage',
      key: 'stage',
      render: (text, render) => render?.company?.stage,
    },
    {
      title: 'Amount Funded',
      dataIndex: 'totalCapitalRaised',
      key: 'amount',
      render: (text, record) => (
        <span>
          {amountFormatter?.format(record?.company?.totalCapitalRaised)}
        </span>
      ),
    },
  ];

  if (tabOperations?.delete) {
    columns.push({
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <Button
          type="secondary"
          label="Remove"
          onClick={() => handleRemoveCompany(record.company.id)}
        />
      ),
    });
  }

  const noUpdates = (
    <div className={styles['no-updates']}>
      <img src={pageNotFound} alt="page not found" />
      <h1>No Trending Startup</h1>
      <span>
        There are currently no trending startup, you could add a new one
      </span>
      <Button
        label="Add Startup"
        className={styles.button}
        onClick={() => setOpenModal(true)}
      />
    </div>
  );

  return (
    <div className={styles['table-container']}>
      <div className={styles.header}>
        <h1>Trending Startups</h1>
        <Button
          onClick={() => setOpenModal(true)}
          label="Add New Startup"
          disabled={!tabOperations?.create}
          className={styles.button}
        />
      </div>
      <div className={styles['startup-table']}>
        {loading ? (
          <div className={styles.loading}>
            <Spin size="large" />
            <h1>Loading...</h1>
          </div>
        ) : data?.getTrendingCompanies?.length >= 1 ? (
          <Table dataSource={data?.getTrendingCompanies} columns={columns} />
        ) : (
          noUpdates
        )}
      </div>
      {
        <DBModal
          isOpen={openModal}
          handleClose={() => setOpenModal(false)}
          content={<AddAStartup closeModal={setOpenModal} />}
        />
      }
    </div>
  );
};

export default TrendingStartups;
