import {useEffect, useState} from 'react';
import styles from './newKeyStat.module.scss';
import {ArrowLeft} from 'icons';
import {Form, Input, Switch, Spin, Select, Tabs} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {toast} from 'react-toastify';
import {useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {GET_KEY_STAT_BY_ID} from 'graphql/queries/keyStat';
import {CREATE_KEY_STAT, UPDATE_KEY_STAT} from 'graphql/mutations/keyStat';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {onTranslateKeyStat} from './actions';
import {NavTabsEnum, supportedLanguages} from 'utils/constants';
import {fieldRules} from 'utils/helper';
import {Button} from '@dabafinance/react-components';
import {formatTypeEnum} from 'utils/mock';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';

const NewKeyStat = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [formatType, setFormatType] = useState('');
  const [enableChangeTranslation, setEnableChangeTranslation] = useState(true);
  const [language, setLanguage] = useState('');
  const [selectedTranslationLanguage, setSelectedTranslationLanguage] =
    useState('');
  const [applicableAssetClasses, setApplicableAssetClasses] = useState([]);
  const [multiLang, setMultilang] = useState([]);
  const [canBeManuallyOverridden, setCanBeManuallyOverridden] = useState(false);
  const [isComputed, setIsComputed] = useState(false);
  const navigate = useNavigate();
  const {id} = useParams();
  const isUpdatePage = !!id;

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.keyStatistics),
  );

  const {data, loading} = useQuery(GET_KEY_STAT_BY_ID, {
    variables: {
      keystatsId: id,
    },
  });
  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );

  const languageToBeTranslatedTo = supportedLanguages.filter(
    key => key.value !== language,
  );

  const handleTranslation = async () => {
    const translateProps = {
      sourceLang: language,
      targetLang: selectedTranslationLanguage,
      targetText1: name,
      targetText2: description,
    };
    try {
      const newValues = [...multiLang];

      //Check if translation exists
      if (newValues.length && newValues[0]) {
        // Check if we're trying to translate in the same language
        const languageExists = newValues.some(
          data => data.language === selectedTranslationLanguage,
        );
        if (languageExists) {
          const updateTranslation = await onTranslateKeyStat(
            translateText,
            translateProps,
          );
          const translationIndex = newValues.findIndex(
            data => data.language === selectedTranslationLanguage,
          );
          newValues[translationIndex].name = updateTranslation.name;
          newValues[translationIndex].description =
            updateTranslation.description;
          newValues[translationIndex].language = updateTranslation.language;
          setMultilang(newValues);
        }
      } else {
        const triggerTranslation = await onTranslateKeyStat(
          translateText,
          translateProps,
        );
        if (!triggerTranslation) {
          return toast.error('Something went wrong when Translating');
        }
        newValues.push(triggerTranslation);
        setMultilang(newValues);
        setMultilang(newValues);
      }
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  const {Option} = Select;

  useEffect(() => {
    if (data?.getKeyStatById) {
      setName(data?.getKeyStatById?.name);
      setDescription(data?.getKeyStatById?.description);
      setLanguage(data?.getKeyStatById?.language);
      setApplicableAssetClasses(data?.getKeyStatById?.applicableAssetClasses);
      setCanBeManuallyOverridden(data?.getKeyStatById?.canBeManuallyOverridden);
      setFormatType(data?.getKeyStatById?.formatType);
      setIsComputed(data?.getKeyStatById?.isComputed);
      setMultilang(data?.getKeyStatById?.multiLang);
      setSelectedTranslationLanguage(
        data?.getKeyStatById?.multiLang[0]?.language,
      );
    }
  }, [data?.getKeyStatById]);

  const handleChangeTranslatedValues = (key, event, name) => {
    console.log('---DATA----', key, event, name);
    let eventName = name;
    let eventValue = name ? event : event.target.value;
    const newFormValues = multiLang.map((item, index) => {
      if (index === key) {
        return {...item, [eventName]: eventValue};
      }
      return item;
    });
    newFormValues[key][eventName] = eventValue;
    setMultilang(newFormValues);
  };

  const [updateKeyStat, {loading: keyStatUpdate}] =
    useMutation(UPDATE_KEY_STAT);
  const [createKeyStat, {loading: keyStatCreate}] =
    useMutation(CREATE_KEY_STAT);
  const width = 720;

  const handleSave = () => {
    isUpdatePage
      ? updateKeyStat({
          variables: {
            data: {
              id,
              name,
              description,
              language,
              applicableAssetClasses,
              canBeManuallyOverridden,
              formatType,
              isComputed,
              multiLang: multiLang.map(data => {
                return {
                  name: data?.name,
                  description: data?.description,
                  language: data?.language,
                };
              }),
            },
          },
        }).then(({data: {updateKeyStat}}) => {
          if (updateKeyStat.__typename === 'Error') {
            toast.error(updateKeyStat.message);
          } else {
            toast.success('Key Statistic updated successfully');
            navigate('/dashboard/key-statistics');
          }
        })
      : createKeyStat({
          variables: {
            data: {
              name,
              description,
              language,
              applicableAssetClasses,
              canBeManuallyOverridden,
              formatType,
              isComputed,
              multiLang,
            },
          },
        }).then(({data: {createKeyStat}}) => {
          if (createKeyStat.__typename === 'Error') {
            toast.error(createKeyStat.message);
          } else {
            toast.success('Key Statistic created successfully');
            navigate('/dashboard/key-statistics');
          }
        });
  };

  if (isUpdatePage && loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button onClick={() => navigate(-1)}>
          <ArrowLeft />
        </button>
        <h1>
          {isUpdatePage ? 'Update Key Statistic' : 'Create Key Statistic'}{' '}
        </h1>
      </div>
      <div className={styles['inner-container']}>
        <Form onFinish={handleSave} layout="vertical">
          <Form.Item
            rules={[fieldRules('Language')]}
            label="Language"
            className={styles.field}
            name="language"
            initialValue={data?.getKeyStatById?.language}>
            <Select
              style={{width}}
              value={language}
              defaultValue={data?.getKeyStatById?.language}
              onChange={e => setLanguage(e)}>
              {supportedLanguages.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[fieldRules('You must select a language to be translated')]}
            label="Language Translation"
            className={styles.field}
            name="translation"
            initialValue={data?.getKeyStatById?.multiLang[0]?.language}>
            <Select
              style={{width}}
              defaultValue={data?.getKeyStatById?.multiLang[0]?.language}
              value={selectedTranslationLanguage}
              onChange={e => setSelectedTranslationLanguage(e)}>
              {languageToBeTranslatedTo.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Tabs
            className={styles.tabs}
            tabBarExtraContent={
              selectedTranslationLanguage && (
                <Button
                  disabled={!name || !description || loadingTranslation}
                  label="Translate"
                  onClick={handleTranslation}
                />
              )
            }>
            <Tabs.TabPane tab="Original" key="Original">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Name is required',
                  },
                ]}
                style={{width}}
                label="Name"
                className={styles['sub-heading']}
                name="name"
                initialValue={data?.getKeyStatById?.name}>
                <Input
                  defaultValue={data?.getKeyStatById?.name}
                  style={{width}}
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder={'Name'}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Description is required',
                  },
                ]}
                label="Description"
                style={{width}}
                className={styles['sub-heading']}
                name="description"
                initialValue={data?.getKeyStatById?.description}>
                <Input.TextArea
                  style={{width}}
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  defaultValue={data?.getKeyStatById?.description}
                  rows={5}
                />
              </Form.Item>
            </Tabs.TabPane>
            {multiLang.length &&
              multiLang.map((data, index) => (
                <Tabs.TabPane
                  key={data.language}
                  tab={
                    supportedLanguages.find(
                      lang => lang.value === data.language,
                    ).label
                  }>
                  <div className={styles.updateButton}>
                    <Button
                      onClick={() =>
                        setEnableChangeTranslation(!enableChangeTranslation)
                      }
                      type="secondary"
                      label={
                        enableChangeTranslation
                          ? 'Edit Translation'
                          : 'Disable Translation'
                      }
                    />
                  </div>
                  <div key={index}>
                    <Form.Item label="Name" className={styles.field}>
                      <Input
                        style={{width}}
                        value={data.name}
                        disabled={enableChangeTranslation}
                        onChange={e =>
                          handleChangeTranslatedValues(
                            index,
                            e.target.value,
                            'name',
                          )
                        }
                        placeholder={'Name'}
                      />
                    </Form.Item>
                    <Form.Item label="Description" className={styles.field}>
                      <Input.TextArea
                        style={{width}}
                        value={data.description}
                        disabled={enableChangeTranslation}
                        onChange={e =>
                          handleChangeTranslatedValues(
                            index,
                            e.target.value,
                            'description',
                          )
                        }
                        rows={5}
                      />
                    </Form.Item>
                  </div>
                </Tabs.TabPane>
              ))}
          </Tabs>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Applicable Asset Class(es) is required',
              },
            ]}
            label="Applicable Asset Class(es)"
            style={{width}}
            className={styles['sub-heading']}
            name="applicableAssetClasses"
            initialValue={data?.getKeyStatById?.applicableAssetClasses}>
            <Select
              mode="multiple"
              style={{width}}
              value={applicableAssetClasses}
              onChange={e => setApplicableAssetClasses(e)}>
              <Option key={'VENTURE'}>Venture</Option>
              <Option key={'STOCK'}>Stock</Option>
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Format Type is required',
              },
            ]}
            label="Format Type"
            style={{width}}
            className={styles['sub-heading']}
            name="formatType"
            initialValue={data?.getKeyStatById?.formatType}>
            <Select
              style={{width}}
              value={formatType}
              onChange={e => setFormatType(e)}>
              {formatTypeEnum.map(item => (
                <Option key={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Computed"
            style={{width}}
            className={styles['sub-heading']}
            name="isComputed"
            initialValue={data?.getKeyStatById?.isComputed}>
            <Switch
              checked={isComputed}
              onChange={e => setIsComputed(e)}
              defaultChecked={data?.getKeyStatById?.isComputed}
            />
          </Form.Item>
          <Form.Item
            label="Manually Overrridden"
            style={{width}}
            className={styles['sub-heading']}
            name="canBeManuallyOverridden"
            initialValue={data?.getKeyStatById?.canBeManuallyOverridden}>
            <Switch
              checked={canBeManuallyOverridden}
              onChange={e => setCanBeManuallyOverridden(e)}
              defaultChecked={data?.getKeyStatById?.canBeManuallyOverridden}
            />
          </Form.Item>
          <SubmitButton
            disabled={!tabOperations?.update || keyStatUpdate || keyStatCreate}
            label={isUpdatePage ? 'Update Key Stat' : 'Create Key Stat'}
          />
        </Form>
      </div>
    </div>
  );
};

export {NewKeyStat};
