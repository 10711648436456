import {Form, Input, InputNumber, Select} from 'antd';
import styles from './usersStockTrade.module.scss';
import {Fragment, useEffect, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import _ from 'lodash';
import {SEARCH_STOCK} from 'graphql/queries/stocks';
import {UserSelectField} from 'components/userSelectField/UserSelectField';
import IpoFeeCard from 'pages/campaigns/new/screens/ipo/ipoFeeCard/IpoFeeCard';
import plusIcon from 'images/plus-circle.png';
import {
  MATCHING_BUY_AND_SELL_ORDER,
  SETTLE_STOCK,
} from 'graphql/mutations/stocks';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {toast} from 'react-toastify';
import {GET_ALL_TRANSACTIONS_BY_OFFERING_ID} from 'graphql/queries/campaign';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const BuyAndSellOrder = ({tab}) => {
  const [selectStock, setSelectStock] = useState('');
  const [searchResult, setSearchResult] = useState('');
  const [userSelling, setUserSelling] = useState('');
  const [userBuying, setUserBuying] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [direction, setDirection] = useState('');
  const [form] = Form.useForm();
  const [buyingFees, setBuyingFees] = useState([
    {
      feeName: '',
      feeType: '',
      fixedFeeAmount: '',
      variableFeeAmount: '',
    },
  ]);
  const [sellingFees, setSellingFees] = useState([
    {
      feeName: '',
      feeType: '',
      fixedFeeAmount: '',
      variableFeeAmount: '',
    },
  ]);

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.BuySellOrder),
  );

  useEffect(() => {
    form.resetFields();
    setSelectStock('');
    setUserBuying('');
    setUserSelling('');
  }, [tab]);

  const [matchAndSettleStockOrders, {loading: loadingTradeCreation}] =
    useMutation(MATCHING_BUY_AND_SELL_ORDER);
  const [settleStockOrder, {loading: loadingSettleOrder}] =
    useMutation(SETTLE_STOCK);

  useEffect(() => {
    if (selectStock) {
      setCompanyId(selectStock.split('~~')[2]);
      form.setFieldsValue({
        stockPrice: selectStock.split(`~~`)[1],
        currencyCode: selectStock.split('~~')[0],
        sellSharePrice: selectStock.split('~~')[1],
        buySharePrice: selectStock.split('~~')[1],
      });
    }
  }, [selectStock, form]);

  const {loading, refetch, networkStatus} = useQuery(SEARCH_STOCK, {
    variables: {
      queryString: 'cot',
    },
    onCompleted: data => setSearchResult(data?.search?.stocks),
    notifyOnNetworkStatusChange: true,
  });

  const debouncedSetSearchValue = _.debounce(value => {
    refetch({
      queryString: value,
    });
  }, 1200);

  const handleAddBuyingFee = () => {
    setBuyingFees([
      ...buyingFees,
      {
        feeName: '',
        feeType: '',
        fixedFeeAmount: '',
        variableFeeAmount: '',
      },
    ]);
  };

  const handleAddSellingFee = () => {
    setSellingFees([
      ...sellingFees,
      {
        feeName: '',
        feeType: '',
        fixedFeeAmount: '',
        variableFeeAmount: '',
      },
    ]);
  };

  const handleChangeBuyingFeesValues = (index, event, values, setValues) => {
    const newFormValues = [...values];
    newFormValues[index][event.target.name] = event.target.value;
    setValues(newFormValues);
  };

  const handleDeleteBuyingFees = (id, values, setValues) => {
    const filteredIpoFee = values.filter((_, index) => index !== id);
    setValues(filteredIpoFee);
  };

  const handleChangeFeeType = (index, event, values, setValues) => {
    const newFormValues = [...values];
    newFormValues[index].feeType = event;
    setValues(newFormValues);
  };

  const handleCreateTrade = values => {
    if (tab === 'SINGLE') {
      const type = values.direction === 'BUY';
      settleStockOrder({
        variables: {
          input: {
            direction: values.direction,
            companyId,
            shareUnit: Number(values.shareUnit),
            stockPrice: Number(values.stockPrice),
            assetCurrency: values.currencyCode,
            stockExchangeId: selectStock.split('~~')[3],
            symbol: selectStock.split('~~')[4],
            userId: type ? userBuying.split(';')[2] : userSelling.split(';')[2],
            sharePrice: type
              ? Number(values.buySharePrice)
              : Number(values.sellSharePrice),
            feeSchedule: type
              ? buyingFees?.map(data => ({
                  feeName: data?.feeName,
                  feeType: data?.feeType,
                  fixedFeeAmount: Number(data?.fixedFeeAmount),
                  variableFeeAmount: Number(data?.variableFeeAmount),
                }))
              : sellingFees.map(data => ({
                  feeName: data?.feeName,
                  feeType: data?.feeType,
                  fixedFeeAmount: Number(data?.fixedFeeAmount),
                  variableFeeAmount: Number(data?.variableFeeAmount),
                })),
          },
        },
        refetchQueries: [
          {
            query: GET_ALL_TRANSACTIONS_BY_OFFERING_ID,
            variables: {offering: ''},
          },
        ],
      })
        .then(({data: {settleStockOrder}}) => {
          if (settleStockOrder.__typename === 'Error') {
            toast.error(settleStockOrder.message);
          } else {
            toast.success('Order Successfully');
          }
        })
        .catch(error => toast.error(error.message));
    } else {
      if (userBuying === userSelling)
        return toast.error(
          'The Same User Account cannot Buy and Sell at the same Time',
        );
      matchAndSettleStockOrders({
        variables: {
          input: {
            companyId,
            shareUnit: Number(values.shareUnit),
            stockPrice: Number(values.stockPrice),
            assetCurrency: values.currencyCode,
            stockExchangeId: selectStock.split('~~')[3],
            symbol: selectStock.split('~~')[4],
            sell: {
              sharePrice: Number(values.sellSharePrice),
              userId: userSelling.split(';')[2],
              feeSchedule: sellingFees.map(data => ({
                feeName: data?.feeName,
                feeType: data?.feeType,
                fixedFeeAmount: Number(data?.fixedFeeAmount),
                variableFeeAmount: Number(data?.variableFeeAmount),
              })),
            },
            buy: {
              sharePrice: Number(values.buySharePrice),
              userId: userBuying.split(';')[2],
              feeSchedule: buyingFees.map(data => ({
                feeName: data?.feeName,
                feeType: data?.feeType,
                fixedFeeAmount: Number(data?.fixedFeeAmount),
                variableFeeAmount: Number(data?.variableFeeAmount),
              })),
            },
          },
        },
        refetchQueries: [
          {
            query: GET_ALL_TRANSACTIONS_BY_OFFERING_ID,
            variables: {offering: ''},
          },
        ],
      })
        .then(({data: {matchAndSettleStockOrders}}) => {
          if (matchAndSettleStockOrders.__typename === 'Error') {
            toast.error(matchAndSettleStockOrders.message);
          } else {
            toast.success('Order Successfully');
          }
        })
        .catch(error => toast.error(error.message));
    }
  };

  return (
    <div className={styles.render}>
      <Form onFinish={handleCreateTrade} form={form} layout="vertical">
        {tab === 'SINGLE' && (
          <Form.Item
            rules={[
              {
                required: tab === 'SINGLE',
                message: 'Please Select a Trade direction (Type)',
              },
            ]}
            name="direction"
            className={styles.width}
            label="Order Direction (Type of Order)">
            <Select
              value={direction}
              onChange={setDirection}
              placeholder="Please Either Buy or Sell ">
              <Select.Option key="BUY">Buy</Select.Option>
              <Select.Option key="SELL">Sell</Select.Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item
          rules={[{required: true, message: 'Please Search for a Stock'}]}
          name="stocks"
          className={styles.width}
          label="Search Stocks">
          <Select
            onSearch={e => debouncedSetSearchValue(e)}
            onChange={e => {
              setSelectStock(e);
            }}
            value={selectStock}
            showSearch
            filterOption={false}
            placeholder="Please Search for a stock">
            {searchResult.length > 0 &&
              searchResult?.map(data => (
                <Select.Option
                  key={`${data?.baseCurrencyCode}~~${data?.lastPrice}~~${data?.company?.id}~~${data?.company?.StockExchange?.id}~~${data?.symbol}`}>
                  <span>{data?.company?.name}</span>
                </Select.Option>
              ))}
            {(networkStatus === 2 || loading) && (
              <Select.Option key="Loading..." />
            )}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: 'This is a Required Field'}]}
          label="Share Unit"
          className={styles.width}
          name="shareUnit">
          <InputNumber name="shareUnit" className={styles.width} />
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: 'This is a Required Field'}]}
          label="Stock Price"
          className={styles.width}
          name="stockPrice">
          <InputNumber className={styles.width} name="stockPrice" />
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: 'This is a Required Field'}]}
          name="currencyCode"
          className={styles.width}
          label="Currency of Asset Being Traded">
          <Input name="currencyCode" />
        </Form.Item>
        <div className={styles.trade}>
          {((direction !== 'BUY' && direction) || tab === 'BOTH') && (
            <div>
              <h2>USER SELLING</h2>
              <Form.Item
                className={styles.userWidth}
                name="sellUser"
                label="Search User (User Selling The Stock)">
                <UserSelectField
                  mode="single"
                  users={userSelling}
                  setUsers={setUserSelling}
                />
              </Form.Item>
              <Form.Item
                label="Share Price (Price the User wants to Sell at)"
                rules={[{required: true, message: 'This Field is Required'}]}
                className={styles.userWidth}
                name="sellSharePrice">
                <InputNumber
                  name="sellSharePrice"
                  className={styles.userWidth}
                />
              </Form.Item>
              <div className={styles.fees}>
                <h3>SELLING FEE SCHEDULE</h3>
                {sellingFees.map((data, index) => (
                  <Fragment key={index}>
                    <IpoFeeCard
                      feeName={data.feeName || ''}
                      feeType={data.feeType}
                      fixedFeeAmount={data.fixedFeeAmount}
                      variableFeeAmount={data.variableFeeAmount}
                      handleChangeIpoFeesValues={e =>
                        handleChangeBuyingFeesValues(
                          index,
                          e,
                          sellingFees,
                          setSellingFees,
                        )
                      }
                      handleChangeIpoFeesType={e =>
                        handleChangeFeeType(
                          index,
                          e,
                          sellingFees,
                          setSellingFees,
                        )
                      }
                      handleDeleteIpoFees={() =>
                        handleDeleteBuyingFees(
                          index,
                          sellingFees,
                          setSellingFees,
                        )
                      }
                    />
                  </Fragment>
                ))}
                <div onClick={handleAddSellingFee} className={styles.add}>
                  <img src={plusIcon} alt="" />
                  <h5>Add Field</h5>
                </div>
              </div>
            </div>
          )}
          {((direction !== 'SELL' && direction) || tab === 'BOTH') && (
            <div>
              <h2>USER BUYING</h2>
              <Form.Item
                className={styles.userWidth}
                name="buyUser"
                label="Search User (User Buying The Stock)">
                <UserSelectField
                  mode="single"
                  users={userBuying}
                  setUsers={setUserBuying}
                />
              </Form.Item>
              <Form.Item
                label="Share Price (Price the user wants to buy at)"
                rules={[{required: true, message: 'This Field is Required'}]}
                name="buySharePrice">
                <InputNumber
                  className={styles.userWidth}
                  name="buySharePrice"
                />
              </Form.Item>
              <div className={styles.fees}>
                <h3>BUYING FEE SCHEDULE</h3>
                {buyingFees.map((data, index) => (
                  <Fragment key={index}>
                    <IpoFeeCard
                      feeName={data.feeName || ''}
                      feeType={data.feeType}
                      fixedFeeAmount={data.fixedFeeAmount}
                      variableFeeAmount={data.variableFeeAmount}
                      handleChangeIpoFeesValues={e =>
                        handleChangeBuyingFeesValues(
                          index,
                          e,
                          buyingFees,
                          setBuyingFees,
                        )
                      }
                      handleChangeIpoFeesType={e =>
                        handleChangeFeeType(index, e, buyingFees, setBuyingFees)
                      }
                      handleDeleteIpoFees={() =>
                        handleDeleteBuyingFees(index, buyingFees, setBuyingFees)
                      }
                    />
                  </Fragment>
                ))}
                <div onClick={handleAddBuyingFee} className={styles.add}>
                  <img src={plusIcon} alt="" />
                  <h5>Add Field</h5>
                </div>
              </div>
            </div>
          )}
        </div>
        <SubmitButton
          label="Create Trade"
          disabled={
            loadingTradeCreation || loadingSettleOrder || !tabOperations?.create
          }
        />
      </Form>
    </div>
  );
};

export default BuyAndSellOrder;
