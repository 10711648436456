import {useState} from 'react';
import styles from './topicsTable.module.scss';
import {Search, Button} from '@dabafinance/react-components';
import {Table, Menu, Spin} from 'antd';
import {useNavigate} from 'react-router-dom';
import {GET_ALL_TOPICS} from 'graphql/queries/interest';
import {useQuery, useMutation} from '@apollo/client';
import pageNotFound from 'images/pageNotFound.png';
import {DELETE_TOPIC} from 'graphql/mutations/interest';
import {toast} from 'react-toastify';
import trash from 'images/trash-can-outline.svg';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

export default function TopicsTable() {
  const [searchItem, setSearchItem] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [topicIndustries, setTopicIndustries] = useState([]);

  const navigate = useNavigate();
  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Topics),
  );

  const {loading} = useQuery(GET_ALL_TOPICS, {
    variables: {
      type: 'INDUSTRY',
      sequence: true,
    },
    onCompleted: data => setTopicIndustries(data?.getAllTopics),
    onError: error => toast.error(error),
  });

  const handleSearch = term => {
    setSearchItem(term.trim());

    if (term != '') {
      const searchList = topicIndustries.filter(data => {
        return Object.values(data)
          .join(' ')
          .toLowerCase()
          .includes(term.toLowerCase());
      });
      setSearchData(searchList);
    } else {
      setSearchData(topicIndustries);
    }
  };

  const [deleteTopic] = useMutation(DELETE_TOPIC);

  const handleDelete = id => {
    deleteTopic({
      variables: {
        topicId: id,
      },
      update(cache) {
        cache.modify({
          fields: {
            getAllTopics() {},
          },
        });
      },
    })
      .then(({data: {deleteTopic}}) => {
        if (deleteTopic.__typename === 'Error') {
          toast.error(deleteTopic.message);
        } else {
          toast.success('Topic deleted Successfully');
        }
      })
      .catch(error => toast.error(error));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <img src={record.icon} alt="" />
          <span>{record.name}</span>
        </div>
      ),
    },
    {
      title: 'Rank Order',
      dataIndex: 'rankOrder',
      key: 'rankOrder',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>{record.sequence}</span>
        </div>
      ),
    },

    {
      title: 'Users Interested',
      dataIndex: 'usersInterested',
      key: 'usersInterested',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>{record.numOfFollowers}</span>
        </div>
      ),
    },
    {
      title: 'Companies',
      dataIndex: 'companies',
      key: 'companies',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>{record.relatedCompanies.length}</span>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <Button
          type="secondary"
          label="View details"
          disabled={!tabOperations?.update}
          className={styles.button}
          onClick={() => navigate(`/dashboard/interest/${record.id}`)}
        />
      ),
    },
  ];

  if (tabOperations?.delete) {
    columns.push({
      title: '',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <img
          onClick={() => {
            handleDelete(record.id);
          }}
          className={styles.trash}
          src={trash}
          alt=""
        />
      ),
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>All Industries</h2>
        <div className={styles.actions}>
          <Search
            id="search-interests"
            placeholder="Search Industry Topics"
            onChange={handleSearch}
            className={styles.search}
          />
        </div>
      </div>
      <div>
        {topicIndustries.length >= 1 ? (
          <Table
            dataSource={searchItem.length < 1 ? topicIndustries : searchData}
            columns={columns}
          />
        ) : (
          <div className={styles['no-update']}>
            <img src={pageNotFound} alt="" />
            <h1>No Industry</h1>
            <span>Add a new Industry</span>
          </div>
        )}
      </div>
    </div>
  );
}

export const RenderInterestCategoryMenu = () => {
  return (
    <Menu>
      <Menu.Item key="0">
        <span>All Interests</span>
      </Menu.Item>
      <Menu.Item key="1">
        <span>General</span>
      </Menu.Item>
      <Menu.Item key="2">
        <span>Industry</span>
      </Menu.Item>
      <Menu.Item key="3">
        <span>Country</span>
      </Menu.Item>
    </Menu>
  );
};
