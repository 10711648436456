import {useMutation, useQuery} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {DatePicker, Form, Input, InputNumber, Select, Spin, Tabs} from 'antd';
import DBModal from 'components/modal/modal';
import {CREATE_COMPANY} from 'graphql/mutations/companyStartups';
import {
  GET_ALL_OPERATING_COUNTRIES,
  GET_ALL_STARTUPS,
} from 'graphql/queries/startupCompanies';
import backIcon from 'images/back.png';
import moment from 'moment';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {noOfEmployeesOptions} from 'utils/constants';
import {stageData} from 'utils/mock';
import SubmitButton from '../components/SubmitButton';
import UploadImage from '../profile/companyImage';
import CompanyVideoUpload from '../profile/companyVideo';
import styles from './profileCreation.module.scss';
import {defaultLanguages} from 'pages/news/newsTypes';
import {fieldRules} from 'utils/helper';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {requestCompanyTranslation} from 'redux/store/news/actions';
import TranslatedCompanyDetails from './translation/TranslatedCompanyDetails';

const CompanyCreation = () => {
  const navigate = useNavigate();
  const [imgUrl, setImgUrl] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [description, setDescription] = useState('');
  const [legalName, setLegalName] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [bannerUrl, setBannerUrl] = useState('');
  const [bannerPreviewUrl, setBannerPreviewUrl] = useState('');
  const [ticker, setTicker] = useState('');
  const [fundRaised, setFundRaised] = useState(0);
  const [companyType, setCompanyType] = useState([]);
  const [operatingCountries, setOperatingCountries] = useState([]);
  const [foundedOn, setFoundedOn] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [location, setLocation] = useState('');
  const [stage, setStage] = useState('');
  const [website, setWebsite] = useState('');
  const [accelerator, setAccelerator] = useState('');
  const [baseLanguage, setBaseLanguage] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [translatedData, setTranslatedData] = useState([]);
  const [noOfEmployees, setNoOfEmployees] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const {Option} = Select;
  const {TextArea} = Input;

  const [createCompany, {loading: loadingCompany}] =
    useMutation(CREATE_COMPANY);

  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );

  const handlePopup = () => {
    if (location !== '' || companyType !== '' || companyName !== '') {
      setOpenModal(true);
    }
  };

  const {data: getAllCountries, loading} = useQuery(
    GET_ALL_OPERATING_COUNTRIES,
    {
      variables: {
        type: 'COUNTRY',
      },
    },
  );

  const handleChangeTranslation = (key, event, name) => {
    let eventName = name || event.target.name;
    let eventValue = name ? event : event.target.value;
    const newFormValues = [...translatedData];
    newFormValues[key][eventName] = eventValue;
    setTranslatedData(newFormValues);
  };

  const languageToBeTranslatedTo = defaultLanguages.filter(
    key => key.key !== baseLanguage,
  );

  const handleTranslation = async () => {
    const newValues = [...translatedData];

    if (!baseLanguage && !description && !longDescription) {
      return toast.error(
        'Please make sure none of the necessary fields needed for translation are empty',
      );
    }

    const translateProps = {
      sourceLang: baseLanguage,
      targetLang: selectedLanguage,
      targetText1: description,
      targetText2: longDescription,
    };
    if (newValues.length && newValues[0]) {
      const languageExists = newValues.some(
        data => data?.language === selectedLanguage,
      );
      if (languageExists) {
        const updateTranslation = await requestCompanyTranslation(
          translateText,
          translateProps,
        );
        const translationIndex = newValues.findIndex(
          data => data.language === selectedLanguage,
        );
        newValues[translationIndex].description = updateTranslation.description;
        newValues[translationIndex].longDescription =
          updateTranslation.longDescription;
        newValues[translationIndex].language = updateTranslation.language;
        setTranslatedData(newValues);
      }
    } else {
      const triggerNewTranslation = await requestCompanyTranslation(
        translateText,
        translateProps,
      );
      if (!triggerNewTranslation) {
        return toast.error('Something went wrong when Translating');
      }
      newValues.push(triggerNewTranslation);
      setTranslatedData(newValues);
    }
  };

  const nextPage = () => {
    const optionalFields = {};
    if (noOfEmployees) {
      optionalFields.numberOfEmployees = noOfEmployees;
    }
    if (ticker) {
      optionalFields.ticker = ticker;
    }
    createCompany({
      variables: {
        input: {
          name: companyName,
          logoImgURL: imgUrl,
          bannerImgURL: bannerUrl,
          foundedOn,
          headquarter: location,
          stage,
          legalName,
          companyType,
          language: baseLanguage,
          multiLang: translatedData?.map(data => ({
            description: data?.longDescription,
            language: data?.language,
            shortDescription: data?.description,
          })),
          website,
          accelerator,
          description: longDescription,
          operatingCountries: operatingCountries?.map(
            data => data.split(';')[1],
          ),
          totalCapitalRaised: Number(fundRaised),
          shortDescription: description,
          ...optionalFields,
        },
      },
      refetchQueries: [{query: GET_ALL_STARTUPS}],
    })
      .then(({data: {createCompany}}) => {
        if (createCompany.__typename === 'Error') {
          return toast.error(createCompany.message);
        } else {
          navigate(
            `/dashboard/opportunities/startups/categories/${createCompany.id}`,
          );
          toast.success('Company Created Successfully');
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  function disabledDate(current) {
    const customDate = new Date().getFullYear();
    return current && current > moment(customDate + 1, 'YYYY');
  }

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div>
      <div className={styles['nav-header']}>
        <img src={backIcon} alt="go-back" onClick={() => navigate(-1)} />
        <h1>Create Company</h1>
      </div>
      <div className={styles['creation-container']}>
        <div className={styles.header}>
          <h1>Company Profile</h1>
          <span>Step 1/5</span>
        </div>
        <div className={styles.content}>
          <UploadImage
            previewUrl={previewUrl}
            setPreviewUrl={setPreviewUrl}
            bucket="company-service-bucket"
            setImgUrl={setImgUrl}
          />
          <CompanyVideoUpload
            bannerPreviewUrl={bannerPreviewUrl}
            setBannerPreviewUrl={setBannerPreviewUrl}
            setBannerUrl={setBannerUrl}
          />
          <div className={styles.about}>
            <h1>About Company</h1>
            <Form onFinish={handlePopup} layout="vertical">
              <Form.Item
                label="Company Name"
                rules={[
                  {
                    required: true,
                    message: 'Company Name Required',
                  },
                ]}
                className={styles['form-item']}
                name="company name">
                <Input
                  value={companyName}
                  onChange={e => setCompanyName(e.target.value)}
                  placeholder="Input Company Name"
                />
              </Form.Item>
              <Form.Item
                label="Legal Name"
                rules={[
                  {
                    required: true,
                    message: 'Company Legal Required',
                  },
                ]}
                className={styles['form-item']}
                name="legal name">
                <Input
                  value={legalName}
                  onChange={e => setLegalName(e.target.value)}
                  placeholder="Input Company Name"
                />
              </Form.Item>
              <Form.Item
                label="Base Language"
                className={styles['form-item']}
                name="baseLanguage"
                rules={[fieldRules('Language')]}>
                <Select
                  value={baseLanguage}
                  onChange={e => setBaseLanguage(e)}
                  placeholder="Select a base language">
                  {defaultLanguages.map(data => (
                    <Option key={data.key}>{data.label}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Translation Language"
                className={styles['form-item']}
                name="translationLanguage"
                rules={[
                  fieldRules('You need to select a language to Translate'),
                ]}>
                <Select
                  value={selectedLanguage}
                  onChange={e => setSelectedLanguage(e)}
                  placeholder="Select a Language to be translated">
                  {languageToBeTranslatedTo.map(name => (
                    <Option key={name.key}>{name.label}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Tabs
                style={{maxWidth: 800}}
                tabBarExtraContent={
                  selectedLanguage && (
                    <Button
                      label="Translate"
                      disabled={
                        loadingTranslation || !description || !longDescription
                      }
                      onClick={handleTranslation}
                    />
                  )
                }>
                <Tabs.TabPane key="Original" tab="Original">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message:
                          'Company Short Description Required: Minimum of 30 words, Maximum of 50 words',
                        max: 300,
                        min: 50,
                      },
                    ]}
                    className={styles['form-item']}
                    label="Short Description"
                    name="Short description">
                    <TextArea
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                      rows={4}
                      placeholder="A description about the Company"
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message:
                          'Company Long Decription Required: Minimum of 100 words',
                        min: 300,
                      },
                    ]}
                    className={styles['form-item']}
                    label="Long Description"
                    name="Long Description">
                    <TextArea
                      value={longDescription}
                      onChange={e => setLongDescription(e.target.value)}
                      rows={8}
                      placeholder="A more detailed company description is required"
                    />
                  </Form.Item>
                </Tabs.TabPane>
                {translatedData?.map((item, index) => (
                  <Tabs.TabPane
                    key={index}
                    tab={
                      languageToBeTranslatedTo.find(
                        lang => lang?.key === item?.language,
                      )?.label
                    }>
                    <TranslatedCompanyDetails
                      fieldKey={index}
                      onChangeFields={handleChangeTranslation}
                      loading={loadingTranslation}
                      data={item}
                    />
                  </Tabs.TabPane>
                ))}
              </Tabs>

              <Form.Item
                label="Company Type"
                className={styles['form-item']}
                name="company-type"
                rules={[
                  {
                    required: true,
                    message: 'Company Type Required',
                  },
                ]}>
                <Select
                  value={companyType}
                  placeholder="Select Company Type"
                  onChange={e => setCompanyType(e)}>
                  <Option value="PRIVATE">PRIVATE</Option>
                  <Option value="PUBLIC">PUBLIC</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Operating Countries"
                className={styles['form-item']}
                name="countries"
                rules={[
                  {
                    required: true,
                    message: 'Operating Countries Required',
                  },
                ]}>
                <Select
                  value={operatingCountries}
                  mode="multiple"
                  placeholder="Select Operating Countries"
                  onChange={e => setOperatingCountries(e)}>
                  {getAllCountries?.getAllTopics?.map(({id, name, icon}) => (
                    <Option key={`${name};${id}`}>
                      <div className={styles['all-data']}>
                        <img src={icon} alt="" />
                        <span>{name}</span>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <div className={styles['form-inputs']}>
                <div className={styles['input-width']}>
                  <Form.Item
                    label="Year Founded"
                    name="date"
                    rules={[
                      {
                        required: true,
                        message:
                          'Input a valid the Year the company was founded',
                      },
                    ]}
                    className={styles['form-item']}>
                    <DatePicker
                      picker="year"
                      disabledDate={disabledDate}
                      value={foundedOn}
                      style={{width: 340}}
                      onChange={e => setFoundedOn(moment(e).format('YYYY'))}
                    />
                  </Form.Item>
                </div>
                <div className={styles['input-width']}>
                  <Form.Item
                    label="Location (Country)"
                    className={styles['form-item']}
                    name="location"
                    rules={[
                      {
                        required: true,
                        message: 'Company Location Required',
                      },
                    ]}>
                    <Input
                      className={styles.date}
                      style={{width: 340}}
                      value={location}
                      onChange={value => setLocation(value.target.value)}
                      placeholder={'Select Location'}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className={styles['form-inputs']}>
                <div className={styles['input-width']}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Company Stage Required',
                      },
                    ]}
                    label="Stage"
                    className={styles['form-item']}
                    name="stage">
                    <Select
                      style={{width: 340}}
                      placeholder={'SERIES_A'}
                      onChange={e => setStage(e)}>
                      {stageData?.map(name => (
                        <Option key={name.value} value={name.value}>
                          {name.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className={styles['input-width']}>
                  <Form.Item
                    label="Website"
                    rules={[
                      {
                        required: true,
                        message: 'Company Website Required',
                        type: 'url',
                      },
                    ]}
                    className={styles['form-item']}
                    name="website">
                    <Input
                      style={{width: 340}}
                      onChange={e => setWebsite(e.target.value)}
                      placeholder="https://paystack.com"
                    />
                  </Form.Item>
                </div>
                <div className={styles['input-width']}>
                  <Form.Item
                    label="Accelerator"
                    className={styles['form-item']}
                    name="accelerator">
                    <Input
                      className={styles['select-field']}
                      showSearch
                      onChange={value => setAccelerator(value.target.value)}
                      style={{width: 340}}
                      placeholder={'Y-Combinator'}
                    />
                  </Form.Item>
                </div>
                <div className={styles['input-width']}>
                  <Form.Item
                    rules={[
                      {
                        required: companyType === 'PUBLIC',
                        message: 'Company Ticker Required',
                      },
                    ]}
                    label="Ticker"
                    className={styles['form-item']}
                    name="ticker">
                    <Input
                      style={{width: 340}}
                      onChange={e => setTicker(e.target.value)}
                      placeholder="Ticker"
                    />
                  </Form.Item>
                </div>
                <div className={styles.numberValues}>
                  <Form.Item
                    rules={[
                      {
                        required: companyType === 'PUBLIC',
                        message: 'Number of Employees is Required',
                      },
                    ]}
                    label="No. of Employees"
                    name="employees">
                    <Select
                      value={noOfEmployees}
                      style={{width: 340}}
                      onChange={e => setNoOfEmployees(e)}>
                      {noOfEmployeesOptions?.map(name => (
                        <Option key={name.value}>{name.label}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Capital Raised" name="totalCapitalRaised">
                    <InputNumber
                      style={{width: 340}}
                      onChange={e => setFundRaised(e)}
                      value={fundRaised}
                      placeholder="Total Capital Raised"
                      className={styles['input-number']}
                      formatter={value =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item>
                <SubmitButton label="Proceed" />
              </Form.Item>
            </Form>
          </div>
        </div>
        <DBModal
          isOpen={openModal}
          handleClose={() => setOpenModal(false)}
          content={
            <div className={styles.modal}>
              <h1>Are you sure?</h1>
              <span>
                Once you Proceed the Company details are automatically saved.
              </span>
              <div className={styles['buttons-container']}>
                <Button
                  type="secondary"
                  onClick={() => setOpenModal(false)}
                  label="Back"
                  className={styles['button-modal']}
                />
                <Button
                  disabled={loadingCompany}
                  onClick={nextPage}
                  label={'Proceed'}
                  className={styles['button-modal']}
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default CompanyCreation;
