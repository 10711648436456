import {gql} from '@apollo/client';

export const CREATE_USERDOCUMENTS = gql`
  mutation CreateUserDocument($data: CreateUserDocument!) {
    createUserDocument(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUserDocument {
        message
      }
    }
  }
`;

export const UPDATE_USER_DOCUMENTS = gql`
  mutation UpdateUserDocument($data: UpdateUserDocument!) {
    updateUserDocument(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUserDocument {
        message
      }
    }
  }
`;

export const UPDATE_USER_DOCUMENT_STATUS = gql`
  mutation ChangUserDocumentStatus($documentId: ID!, $active: Boolean) {
    changUserDocumentStatus(documentId: $documentId, active: $active) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUserDocument {
        message
      }
    }
  }
`;

export const DELETE_USER_DOCUMENT = gql`
  mutation DeleteOneUserDocument($documentId: ID!) {
    deleteOneUserDocument(documentId: $documentId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUserDocument {
        message
      }
    }
  }
`;

export const DELETE_ALL_USERDOCUMENTS = gql`
  mutation DeleteAllUserDocuments($userDocumentId: ID!) {
    deleteAllUserDocuments(userDocumentId: $userDocumentId) {
      ... on Error {
        message
        statusCode
      }
      ... on UserDocumentType {
        userDocument {
          id
          user {
            id
            email
          }
          documents {
            id
            documentName
            documentUrl
            issuedDate
            documentCategory
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GENERATE_USER_DOCUMENT = gql`
  mutation GenerateUserDocument($data: GenerateUserDocumentInput!) {
    generateUserDocument(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUserDocument {
        message
        data {
          id
          isActive
        }
      }
    }
  }
`;
