import styles from './learnArticleCard.module.scss';
import deleteImg from 'images/delete.svg';
import {useMutation} from '@apollo/client';
import editImg from 'images/edit.svg';
import {useNavigate} from 'react-router-dom';
import {DELETE_LEARN_ARTICLE} from 'graphql/mutations/learnArticle';
import {toast} from 'react-toastify';
import moment from 'moment';

const LearnArticleCard = ({title, date, image, id, operations}) => {
  const navigate = useNavigate();
  const [deleteLearnArticle] = useMutation(DELETE_LEARN_ARTICLE);

  const handleDelete = () => {
    deleteLearnArticle({
      variables: {
        articleId: id,
      },
      update(cache) {
        const normalizedId = cache.identify({
          id,
          __typename: 'LearnArticle',
        });
        cache.evict({id: normalizedId});
        cache.gc();
      },
    }).then(({data: {deleteLearnArticle}}) => {
      if (deleteLearnArticle.__typename === 'Error') {
        toast.error(deleteLearnArticle.message);
      } else {
        toast.success('Deleted Successfully');
      }
    });
  };

  const trucateTitle = () => {
    if (title?.length > 35) {
      return title.substring(0, 35) + '...';
    } else return title;
  };
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <img src={image} alt="" />
      </div>
      <div className={styles.content}>
        <h1>{trucateTitle()}</h1>
        <div>
          <div className={styles['span']}>
            <span className={styles['date']}>Published Date:</span>
            {date ? moment(new Date(Number(date))).fromNow() : 'No Date'}
          </div>
          <div className={styles['footer']}>
            {operations?.delete && (
              <div onClick={handleDelete} className={styles['action-icon']}>
                <img src={deleteImg} alt="delete" />
                <span>Delete</span>
              </div>
            )}
            {operations?.update && (
              <div
                onClick={() =>
                  navigate(`/dashboard/explore/learn-article/edit/${id}`)
                }
                className={styles['action-icon']}>
                <img src={editImg} alt="edit" />
                <span>Edit</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnArticleCard;
