import DBModal from 'components/modal/modal';
import styles from './viewOrders.module.scss';
import {Form, Input, message} from 'antd';
import {useState} from 'react';
import plusIcon from 'images/plus-circle.png';
import trash from 'images/trash-can-outline.png';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useMutation} from '@apollo/client';
import {SEND_EXPIRING_ORDERS} from 'graphql/mutations/stocks';
import {toast} from 'react-toastify';

const ManualTriggerEmail = ({isOpen, onClose}) => {
  const [emails, setEmails] = useState(['']);

  const handleDelete = id => {
    setEmails(emails?.filter((data, index) => index !== id));
  };

  const [sendExpiringOrderEmail, {loading}] = useMutation(SEND_EXPIRING_ORDERS);

  const handleSubmit = () => {
    if (!emails.length || emails.some(data => data == ''))
      return message.error('Some Required Fields are Missing');
    sendExpiringOrderEmail({
      variables: {
        emails: emails,
      },
    })
      .then(({data: sendExpiringOrderEmail}) => {
        if (sendExpiringOrderEmail.__typename === 'Error') {
          toast.error(
            `Something went wrong!!! - ${sendExpiringOrderEmail.message} `,
          );
        } else {
          toast.success('Sent to Emails Successfully');
          onClose();
          setEmails(['']);
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err}`));
  };

  const onChangeEmailFields = (value, key) => {
    const values = [...emails];
    values[key] = value;
    setEmails(values);
  };

  return (
    <DBModal
      isOpen={isOpen}
      handleClose={() => onClose()}
      content={
        <div className={styles.modal}>
          <h1>Send Expiring Orders to Email(s)</h1>
          <Form onFinish={handleSubmit} layout="vertical">
            <div className={styles.emails}>
              {emails.map((data, index) => (
                <div className={styles.emailField} key={index}>
                  <Form.Item className={styles.emailWidth} label="Email">
                    <Input
                      type="email"
                      value={data}
                      onChange={e => onChangeEmailFields(e.target.value, index)}
                    />
                  </Form.Item>
                  <img onClick={() => handleDelete(index)} src={trash} />
                </div>
              ))}
            </div>
            <div
              onClick={() => setEmails([...emails, ''])}
              className={styles.add}>
              <img src={plusIcon} alt="" />
              <h5>Add Email</h5>
            </div>
            <SubmitButton disabled={loading} label="Save Changes" />
          </Form>
        </div>
      }
    />
  );
};

export default ManualTriggerEmail;
