import styles from './roundedButton.module.scss';
import {Puff} from 'components/spinner/spinner';
import classnames from 'classnames/bind';

export default function RoundedButton(props) {
  const {btnstyle} = props;
  const cx = classnames.bind(styles);

  return (
    <div
      className={cx(
        'rounded-button-container',
        (props.disabled || props.loading) && 'disabled',
      )}>
      <button {...props} className={styles[btnstyle]}>
        <div>
          {props.loading ? (
            <Puff type="Puff" height={25} width={25} className={props.svg} />
          ) : (
            <>{props.text}</>
          )}
        </div>
      </button>
    </div>
  );
}
