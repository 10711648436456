import {Form, Input} from 'antd';
import UploadImage from 'components/Startup/profile/companyImage';
import ReactQuill from 'react-quill';
import styles from './emailNotification.module.scss';

const EmailNotificationFields = ({
  modules,
  formats,
  emailBody,
  onEmailBodyChange,
  header,
  onHeaderChange,
  onChangeImgUrl,
  emailPreviewImage,
  onChangeEmailPreviewImage,
}) => {
  return (
    <div className={styles['container']}>
      <Form.Item
        rules={[{required: true}]}
        style={{width: 730}}
        name="header"
        label="Email Header">
        <Input
          value={header}
          onChange={e => onHeaderChange(e.target.value)}
          placeholder="Input Email Header Text to be displayed"
        />
      </Form.Item>
      <div className={styles['quill-container']}>
        <h3>Email Body</h3>
        <ReactQuill
          className={styles.quill}
          theme="snow"
          modules={modules}
          formats={formats}
          onChange={el => onEmailBodyChange(el)}
          placeholder="Write Something"
          value={emailBody}
        />
      </div>
      <div className={styles['image-section']}>
        <h2>Upload Banner Image (Optional)</h2>
        <UploadImage
          large
          setImgUrl={onChangeImgUrl}
          setPreviewUrl={onChangeEmailPreviewImage}
          previewUrl={emailPreviewImage}
          bucket="notif-banner-images"
        />
      </div>
    </div>
  );
};

export default EmailNotificationFields;
