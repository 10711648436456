import styles from './modal.module.scss';
import './modal.scss';
import PropTypes from 'prop-types';
import {Modal} from 'antd';

const DBModal = props => {
  const {isOpen, handleClose, content, zIndex, destroyOnClose, width} = props;

  return (
    <Modal
      className={styles['modal-container']}
      open={isOpen}
      centered
      width={width || 520}
      onCancel={handleClose}
      footer={null}
      zIndex={zIndex || 1000}
      destroyOnClose={destroyOnClose}>
      {content}
    </Modal>
  );
};

export default DBModal;
DBModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  content: PropTypes.any,
  zIndex: PropTypes.number,
  width: PropTypes.number,
};
