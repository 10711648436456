import {useMutation, useQuery} from '@apollo/client';
import styles from './campaignReport.module.scss';
import {Form, Input, Select, Switch} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {
  CREATE_CAMPAIGN_REPORT,
  UPDATE_CAMPAIGN_REPORT,
} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';
import {GET_ALL_CAMPAIGNS_IDS} from 'graphql/queries/campaign';
import plusIcon from 'images/plus-circle.png';
import trash from 'images/trash-can-outline.svg';

const CreateCampaignReport = ({data, onClose, type, refetch}) => {
  const [managers, setManagers] = useState([{name: '', email: ''}]);
  const [form] = Form.useForm();

  const [createCampaignReport, {loading: loadingCreate}] = useMutation(
    CREATE_CAMPAIGN_REPORT,
  );
  const {data: allCampaigns, loading: loadingCampaigns} = useQuery(
    GET_ALL_CAMPAIGNS_IDS,
  );
  const [updateCampaignReport, {loading: loadingUpdateCampaignReport}] =
    useMutation(UPDATE_CAMPAIGN_REPORT);

  useEffect(() => {
    if (data) {
      const {interval, managers, config, language, frequency} = data;
      form.setFieldsValue({
        interval,
        language,
        frequency,
        sendFollowersList: config?.sendFollowersList,
      });
      setManagers(
        managers?.map(data => ({
          name: data?.name,
          email: data?.email,
        })),
      );
    }
    return () => {
      form.resetFields();
      setManagers([{name: '', email: ''}]);
    };
  }, [data]);

  const handleAddManagers = () => {
    setManagers([...managers, {name: '', email: ''}]);
  };

  const handleDeleteMangers = id => {
    const filteredFormValue = managers.filter((item, index) => index !== id);
    setManagers(filteredFormValue);
  };

  const handleChangeManagers = (i, value, name) => {
    const newFormValues = [...managers];
    newFormValues[i][name] = value;
    setManagers(newFormValues);
  };

  const validateManagers = () => {
    let valid = true;
    managers.forEach((manager, index) => {
      if (!manager.name) {
        valid = false;
        form.setFields([
          {
            name: `name${index}`,
            errors: ['This is a required field'],
          },
        ]);
      }
      if (!manager.email) {
        valid = false;
        form.setFields([
          {
            name: `email${index}`,
            errors: ['This is a required field'],
          },
        ]);
      } else if (!/\S+@\S+\.\S+/.test(manager.email)) {
        valid = false;
        form.setFields([
          {
            name: `email${index}`,
            errors: ['Please provide a valid email'],
          },
        ]);
      }
    });
    return valid;
  };

  const handleSubmit = values => {
    if (!validateManagers()) {
      return toast.error(
        'Please make sure all Managers and Email fields are valid',
      );
    }

    const {interval, language, frequency} = values;
    if (type === 'UPDATE') {
      updateCampaignReport({
        variables: {
          input: {
            id: data?.id,
            interval,
            language,
            config: {
              sendFollowersList:
                values?.sendFollowersList != null
                  ? values?.sendFollowersList
                  : false,
            },
            managers: managers?.map(data => ({
              name: data?.name,
              email: data?.email,
            })),
            frequency,
          },
        },
      })
        .then(({data: {updateCampaignReport}}) => {
          if (updateCampaignReport.__typename === 'Error') {
            toast.error(
              `Something went wrong!!! - ${updateCampaignReport.message} `,
            );
          } else {
            toast.success('Updated Successfully');
            onClose();
            refetch();
            form.resetFields();
          }
        })
        .catch(err => toast.error(`Something went wrong!! - ${err}`));
    } else {
      createCampaignReport({
        variables: {
          input: {
            campaignId: values?.liveCampaignId,
            interval,
            config: {
              sendFollowersList:
                values?.sendFollowersList != null
                  ? values?.sendFollowersList
                  : false,
            },
            managers: managers?.map(data => ({
              name: data?.name,
              email: data?.email,
            })),
            language,
            frequency,
          },
        },
      }).then(({data: {createCampaignReport}}) => {
        if (createCampaignReport.__typename === 'Error') {
          toast.error(createCampaignReport.message);
        } else {
          refetch();
          form.resetFields();
          toast.success('Created Successfully');
          onClose();
        }
      });
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.contents}>
        <div>
          <h1>
            {data?.campaign?.fundraisingCompany?.companyName} Listing REPORT
          </h1>
          <p>
            Control The Frequency at which the Reports are Received by Owners
          </p>
        </div>
        <Form onFinish={handleSubmit} form={form} layout="vertical">
          {type !== 'UPDATE' && (
            <Form.Item
              rules={[{required: true, message: 'This is a required'}]}
              label="Select a Live Campaign"
              className={styles.fieldWidth}
              name="liveCampaignId">
              <Select
                loading={loadingCampaigns}
                placeholder="Select a Campaign">
                {allCampaigns?.getAllCampaigns?.campaigns
                  ?.filter(
                    data =>
                      data?.campaignMode === 'LIVE' &&
                      data?.campaignStatus === 'ACTIVE',
                  )
                  ?.map(data => (
                    <Select.Option key={data?.id}>
                      <div className={styles['option-data']}>
                        <div className={styles['name-data']}>
                          <img
                            src={data?.fundraisingCompany?.logoImgURL}
                            alt=""
                          />
                          <span>{data?.fundraisingCompany?.companyName}</span>
                        </div>
                      </div>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}
          <div className={styles.managersArray}>
            {managers?.map((data, key) => (
              <div className={styles.manager} key={key}>
                <Form.Item className={styles.smallWidth} label="Manger's Name">
                  <Input
                    value={data?.name}
                    onChange={e =>
                      handleChangeManagers(key, e.target.value, 'name')
                    }
                  />
                </Form.Item>
                <Form.Item className={styles.smallWidth} label="Email">
                  <Input
                    value={data?.email}
                    onChange={e =>
                      handleChangeManagers(key, e.target.value, 'email')
                    }
                  />
                </Form.Item>
                <img
                  src={trash}
                  onClick={() => handleDeleteMangers(key)}
                  alt=""
                />
              </div>
            ))}
          </div>

          <div onClick={handleAddManagers} className={styles.addManagers}>
            <img src={plusIcon} alt="add-founder" />
            <span>Add Manager</span>
          </div>
          <Form.Item
            rules={[{required: true, message: 'This is a required'}]}
            className={styles.fieldWidth}
            name="language"
            label="Language">
            <Select>
              <Select.Option key="EN">English</Select.Option>
              <Select.Option key="FR">French</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'This is a required'}]}
            className={styles.fieldWidth}
            name="frequency"
            label="Frequency">
            <Select>
              <Select.Option key="WEEKLY">Weekly</Select.Option>
              <Select.Option key="TWO_WEEKS">Two Weeks</Select.Option>
              <Select.Option key="MONTHLY">Monthly</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'This is a required'}]}
            className={styles.fieldWidth}
            name="interval"
            label="Intervals">
            <Select>
              <Select.Option key="ONE_DAY">One Day</Select.Option>
              <Select.Option key="TWO_DAYS">Two Days</Select.Option>
              <Select.Option key="THREE_DAYS">Three Days</Select.Option>
              <Select.Option key="FOUR_DAYS">Four Days</Select.Option>
              <Select.Option key="FIVE_DAYS">Five Days</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="sendFollowersList" label="Send Followers List">
            <Switch defaultChecked={data?.config?.sendFollowersList} />
          </Form.Item>
          <SubmitButton
            disabled={loadingCreate || loadingUpdateCampaignReport}
            label={type === 'UPDATE' ? 'UPDATE' : 'Create'}
          />
        </Form>
      </div>
    </div>
  );
};

export default CreateCampaignReport;
