import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import styles from './legalDocument.module.scss';
import plusIcon from 'images/plus-circle.png';
import cloudIcon from 'images/cloud-upload.svg';
import deleteIcon from 'images/delete.svg';
import {Form, Input, Spin, Upload} from 'antd';
import FadeEffect from 'components/animation/fadeEffect';
import {useMutation, useQuery} from '@apollo/client';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';
import {UploadDocumentToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';

const UpdateLegalDocument = () => {
  const [documentValues, setDocumentValues] = useState([
    {documentLink: '', documentName: '', fileName: ''},
  ]);
  const {id, language} = useParams();

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });
  const [updateCampaign, {loading: loadingUpdate}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (campaignData?.getCampaignById?.legalDocuments) {
      setDocumentValues(
        campaignData?.getCampaignById?.legalDocuments?.map(data => ({
          documentLink: data?.fileLink,
          documentName: data?.name,
          fileName: data?.name,
        })),
      );
    }
  }, [campaignData?.getCampaignById]);

  const handleAddDocument = () => {
    setDocumentValues([
      ...documentValues,
      {
        documentLink: '',
        documentName: '',
        fileName: '',
      },
    ]);
  };

  const handleChangeLegalName = (i, e) => {
    const newFormValues = [...documentValues];
    newFormValues[i].documentName = e.target.value;
    setDocumentValues(newFormValues);
  };

  const handleChangeFile = async (i, {file}) => {
    const temp = file.originFileObj;

    const newFormValues = [...documentValues];

    newFormValues[i].fileName = temp.name.split('.')[0];
    const fileLink = await UploadDocumentToBucket(temp);

    newFormValues[i].documentLink = fileLink;
    setDocumentValues(newFormValues);
  };

  const handleDocumentDelete = id => {
    const filteredDocument = documentValues.filter(
      (data, index) => index !== id,
    );
    setDocumentValues(filteredDocument);
  };

  const handleSave = () => {
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          legalDocuments: documentValues?.map(data => ({
            fileLink: data?.documentLink,
            name: data?.documentName,
          })),
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error('Please make sure all fields are not empty');
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>Legal Documents</h1>
        <span>
          Upload any legal document that belongs to your company to seal trust
          in investors
        </span>
      </div>
      {documentValues?.map((data, index) => (
        // eslint-disable-next-line react/jsx-key
        <FadeEffect>
          <div className={styles['document-card']} key={index}>
            <Form layout="vertical">
              <Form.Item style={{width: 320}} label="Legal Document Name">
                <Input
                  name="documentName"
                  value={data.documentName}
                  onChange={e => handleChangeLegalName(index, e)}
                  placeholder="Name of document"
                />
              </Form.Item>
              <div className={styles.document}>
                <Upload
                  accept=".pdf, .doc"
                  name="pdf"
                  onChange={e => handleChangeFile(index, e)}
                  maxCount={1}>
                  <label htmlFor="file-upload" className={styles.upload}>
                    <img src={cloudIcon} alt="" />
                    <span>
                      {data.fileName ? data.fileName : 'Upload Document'}
                    </span>
                  </label>
                </Upload>
              </div>
            </Form>
            <div>
              {documentValues.length > 1 && (
                <img
                  onClick={() => handleDocumentDelete(index)}
                  src={deleteIcon}
                  alt=""
                />
              )}
            </div>
          </div>
        </FadeEffect>
      ))}
      <div onClick={handleAddDocument} className={styles['add-button']}>
        <img src={plusIcon} alt="" />
        <h1>Add Document</h1>
      </div>
      <SubmitButton
        disabled={loadingUpdate}
        label="Update"
        handleClick={handleSave}
      />
    </div>
  );
};

export default UpdateLegalDocument;
