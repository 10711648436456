import {useState} from 'react';
import {Button, Search} from '@dabafinance/react-components';
import styles from './keyStat.module.scss';
import {Table, Spin, Modal} from 'antd';
import {useNavigate} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import trash from 'images/trash-can-outline.svg';
import {toast} from 'react-toastify';
import {GET_ALL_KEY_STAT} from 'graphql/queries/keyStat';
import {DELETE_KEY_STAT} from 'graphql/mutations/keyStat';
import {formatTypeEnum} from 'utils/mock';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const KeyStatistics = () => {
  const navigate = useNavigate();
  const {confirm} = Modal;
  const [searchItem, setSearchItem] = useState('');
  const [searchData, setSearchData] = useState([]);

  const {data, loading} = useQuery(GET_ALL_KEY_STAT, {
    fetchPolicy: 'no-cache',
  });

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.keyStatistics),
  );

  const [deleteKeyStat] = useMutation(DELETE_KEY_STAT);

  const handleSearch = term => {
    const result = term.trim();
    setSearchItem(result);

    if (term !== '') {
      const newList = data?.getKeyStats.filter(data => {
        return Object.values(data)
          .join(' ')
          .toLowerCase()
          .includes(term.toLowerCase());
      });
      setSearchData(newList);
    } else {
      setSearchData(data?.getKeyStats);
    }
  };

  const handleDelete = id => {
    confirm({
      content:
        'Are you sure you want to delete this key stat? If it is computed, it is going to mess up the computation',
      onOk: () => {
        deleteKeyStat({
          variables: {
            keystatsId: id,
          },
          refetchQueries: [{query: GET_ALL_KEY_STAT}],
        })
          .then(({data: {deleteKeyStat}}) => {
            if (deleteKeyStat.__typename === 'Error') {
              toast.error(deleteKeyStat.message);
            } else {
              setSearchData(data?.getKeyStats);
              toast.success('Key Statistic deleted Successfully');
            }
          })
          .catch(error => toast.error(error));
      },
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Format Type',
      dataIndex: 'formatType',
      key: 'formatType',
      render: (text, record) => (
        <div className={styles.active}>
          <p>
            {
              formatTypeEnum.find(item => item.value === record?.formatType)
                ?.label
            }
          </p>
        </div>
      ),
    },
    {
      title: 'Computed',
      dataIndex: 'isComputed',
      key: 'isComputed',
      render: (text, record) => (
        <div className={styles.active}>
          <p>{record?.isComputed ? 'Yes' : 'No'}</p>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <div className={styles['action-btn']}>
          <Button
            onClick={() =>
              navigate(`/dashboard/key-statistics/update-key-stat/${record.id}`)
            }
            disabled={!tabOperations?.view}
            type="secondary"
            label="Update"
          />
          {tabOperations?.delete && (
            <img
              onClick={() => {
                handleDelete(record.id);
              }}
              className={styles.trash}
              src={trash}
              alt=""
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={styles['container-wrapper']}>
      <div className={styles['header-container']}>
        <h1>Key Statistics</h1>
        <div className={styles['left-buttons']}>
          <Button
            label={'Create Key Statistic'}
            className={styles['header-button']}
            disabled={!tabOperations?.create}
            onClick={() => navigate('/dashboard/key-statistics/new-key-stat')}
          />
        </div>
      </div>
      <div className={styles['startup-table']}>
        <div className={styles['startup-header']}>
          <div>
            <Search
              className={styles['search-bar']}
              id={''}
              placeholder="Name"
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className={styles['table-height']}>
          {loading ? (
            <div className={styles.loading}>
              <Spin size="large" />
              <h1>Loading...</h1>
            </div>
          ) : (
            <Table
              loading={loading}
              dataSource={
                searchItem.length < 1 ? data?.getKeyStats : searchData
              }
              columns={columns}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default KeyStatistics;
