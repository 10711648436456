import {Form, Input} from 'antd';
import {useEffect, useState} from 'react';
import trash from 'images/trash-can-outline.svg';
import plusIcon from 'images/plus-circle.png';
import styles from './campaignFaq.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {campaignFAQ} from 'redux/store/campaign';
import FadeEffect from 'components/animation/fadeEffect';

const CampaignFAQ = () => {
  const [formValues, setFormValues] = useState([{question: '', answer: ''}]);
  const [disableButton, setDisableButton] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id, companyId} = useParams();
  const width = 700;
  const {TextArea} = Input;

  const campaign = useSelector(state => state.campaign.campaign);

  useEffect(() => {
    if (campaign.FAQ != undefined) {
      setFormValues(
        campaign?.FAQ?.map(data => ({
          question: data.question,
          answer: data?.answer,
        })),
      );
    }
  }, [campaign?.FAQ]);

  useEffect(() => {
    if (formValues.length) {
      formValues?.map(data => {
        if (!data.question || !data.answer) {
          setDisableButton(true);
        } else {
          setDisableButton(false);
        }
      });
    } else {
      setDisableButton(false);
    }
  }, [formValues]);

  const handleChangeFAQ = (i, e) => {
    const newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const handleDeleteFAQ = id => {
    const filteredFormValue = formValues.filter((item, index) => index !== id);
    setFormValues(filteredFormValue);
  };

  const handleAddFaqFields = () => {
    setFormValues([...formValues, {question: '', answer: ''}]);
  };

  const handleSave = () => {
    dispatch(
      campaignFAQ({
        FAQ: formValues,
      }),
    );
    navigate(`/dashboard/campaigns/new/${id}/company-details/${companyId}`);
  };

  return (
    <div className={styles.container}>
      <div>
        <h1 className={styles.title}>FAQ</h1>
      </div>
      <Form layout="vertical">
        {formValues.map((element, index) => (
          <div className={styles.faq} key={index}>
            <FadeEffect>
              <div className={styles['question-style']}>
                <Form.Item style={{width}} label="Question">
                  <Input
                    name="question"
                    value={element.question || ''}
                    onChange={e => handleChangeFAQ(index, e)}
                  />
                </Form.Item>
                <img
                  src={trash}
                  onClick={() => handleDeleteFAQ(index)}
                  alt=""
                />
              </div>
              <Form.Item style={{width}} label="Answer">
                <TextArea
                  name="answer"
                  rows={5}
                  value={element.answer || ''}
                  onChange={e => handleChangeFAQ(index, e)}
                />
              </Form.Item>
            </FadeEffect>
          </div>
        ))}
        <div onClick={handleAddFaqFields} className={styles['add-faq']}>
          <img src={plusIcon} alt="add-founder" />
          <span>Add FAQ</span>
        </div>
        <div>
          <SubmitButton
            label="Save & Continue"
            disabled={disableButton}
            handleClick={handleSave}
          />
        </div>
      </Form>
    </div>
  );
};

export default CampaignFAQ;
