import {useEffect, useState} from 'react';
import styles from './adminuser.module.scss';
import gridIcon from 'images/view-grid-outline.svg';
import listIcon from 'images/format-list-bulleted.svg';
import purpleGridIcon from 'images/grid-purple.svg';
import purpleListIcon from 'images/list-purple.svg';
import PercentageCard from './card/PercentageCard';
import {ADMIN_USER_TYPES, NavTabsEnum} from 'utils/constants';
import {AdminUserList} from './AdminUserList';
import {Dropdown, Form, Input, Spin} from 'antd';
import DBModal from 'components/modal/modal';
import {DownloadOutlined} from '@ant-design/icons';
import {useLazyQuery} from '@apollo/client';
import {DOWNLOAD_ALL_USERS} from 'graphql/queries/users';
import {toast} from 'react-toastify';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';

const AdminUser = () => {
  const [activeTab, setActiveTab] = useState('ALL');
  const [switchView, setSwitchView] = useState(true);
  const [page, setPage] = useState(1);
  const [loadingUsers, setLoadingUser] = useState(false);
  const [downloadAllUsers, setDownloadAllUsers] = useState(false);
  const [userStats, setUserStats] = useState({});
  const [downloadEmail, setDownloadEmail] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(false);
  const [filterValues, setFilterValues] = useState({});

  const [userTypeMeta, setUserTypeMeta] = useState(
    ADMIN_USER_TYPES?.find(instance => instance?.key === activeTab),
  );

  const pageOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Users),
  );

  const handleNextPage = () => setPage(prevPage => prevPage + 1);
  const handlePreviousPage = () => setPage(currentPage => currentPage - 1);
  const handleResetPage = () => setPage(1);

  const searchId = document.getElementById('user-search');

  const [downloadUsers, {loading}] = useLazyQuery(DOWNLOAD_ALL_USERS, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    setUserTypeMeta(() =>
      ADMIN_USER_TYPES?.find(instance => instance?.key === activeTab),
    );
  }, [activeTab]);

  const onMenuClick = ({key}) => {
    if (key === '1') {
      setDownloadAllUsers(true);
      setCurrentUser(false);
      setOpenModal(true);
    }
    if (key === '2') {
      setOpenModal(true);
      setCurrentUser(true);
    }
  };

  const temporalMenu = [
    {
      label: `${activeTab === 'ALL' ? '' : 'All'} ${[
        userTypeMeta?.labelTitle,
      ]}`,
      key: '1',
    },
    {
      label: 'All Current Filtered User',
      key: '2',
    },
  ];

  const handleDownloadAllUsers = () => {
    downloadUsers({
      variables: {
        email: downloadEmail,
        match: {
          user: {
            ...(currentUser && Object.values(filterValues).length
              ? {...filterValues}
              : {}),
            [userTypeMeta?.filterProperty]: userTypeMeta?.filterPropertyValue,
          },
        },
      },
    })
      .then(({data: {downloadAllUsers}}) => {
        if (!downloadAllUsers.response) {
          toast.error(downloadAllUsers.message);
        } else {
          toast.success('Successful, Please check your mail!');
          setOpenModal(false);
        }
      })
      .catch(err => toast.error(err));
  };

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>Users</h1>
        <div className={styles['right-grid']}>
          <span>Switch View: </span>
          <div>
            <img
              onClick={() => setSwitchView(true)}
              src={switchView ? purpleGridIcon : gridIcon}
              alt=""
            />
            <img
              onClick={() => setSwitchView(false)}
              src={switchView ? listIcon : purpleListIcon}
              alt=""
            />
          </div>
        </div>
      </section>
      <section className={styles['percentage-tab']}>
        <PercentageCard
          amount={userStats?.totalUsers || 0}
          label="Total Users"
          active
          postive
        />
        <PercentageCard
          amount={userStats?.totalAccreditedUsers || 0}
          label="Accredited Investors"
          postive
        />
        <PercentageCard
          amount={userStats?.totalActiveInvestors || 0}
          label="Active Investors"
        />
        <PercentageCard
          amount={userStats?.totalVerifiedInvestors || 0}
          label="Total Verified Users"
        />
      </section>
      <section className={styles['sections-tab']}>
        <div className={styles['verifier-toggler']}>
          {ADMIN_USER_TYPES.map(({label, key}) => (
            <span
              key={key}
              onClick={() => {
                if (searchId.value) {
                  searchId.value = '';
                }
                setPage(1);
                setActiveTab(key);
              }}
              className={activeTab === key ? styles['active-tab'] : ''}>
              {label}
            </span>
          ))}
        </div>
        <div className={styles['download-icon']}>
          {pageOperations?.export && (
            <Dropdown
              trigger="click"
              menu={{
                items: temporalMenu,
                onClick: onMenuClick,
              }}
              placement="bottomLeft">
              <div className={styles['select-btn']}>
                <DownloadOutlined className={styles['download-icon']} />
              </div>
            </Dropdown>
          )}
        </div>
        <DBModal
          isOpen={openModal}
          handleClose={() => {
            setDownloadAllUsers(false);
            setOpenModal(false);
          }}
          content={
            <div className={styles.userDownloadModal}>
              {loadingUsers || loading ? (
                <div className={styles.modalLoader}>
                  <Spin size="large" />
                  <h1>Loading.... Please wait</h1>
                </div>
              ) : (
                <div className={styles.modalContent}>
                  <div className={styles.modalHeader}>
                    <h1>Download Users</h1>
                    <span>
                      Download a CSV file of{' '}
                      {currentUser ? 'Current Page' : 'All'} USERS to your Daba
                      Email. Please Note the mail might be in your spam folder
                    </span>
                  </div>
                  <Form onFinish={handleDownloadAllUsers} layout="vertical">
                    <Form.Item name="email" label="Email">
                      <Input
                        placeholder="admin@joindaba.com"
                        style={{width: 400}}
                        value={downloadEmail}
                        onChange={e => setDownloadEmail(e.target.value)}
                      />
                    </Form.Item>
                    <SubmitButton label="Download" />
                  </Form>
                </div>
              )}
            </div>
          }
        />
      </section>

      <section>
        <AdminUserList
          switchView={switchView}
          userType={activeTab}
          userTypeMeta={userTypeMeta}
          onLoadingUser={setLoadingUser}
          setCurrentPage={setPage}
          currentPage={page}
          onNextPage={handleNextPage}
          onPreviousPage={handlePreviousPage}
          operations={pageOperations}
          onPageReset={handleResetPage}
          downloadAllUsers={downloadAllUsers}
          onSetStatistics={setUserStats}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
        />
      </section>
    </div>
  );
};

export {AdminUser};
