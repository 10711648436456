import {gql} from '@apollo/client';

export const GET_INVESTED_USER_BY_DATA_RANGE = gql`
  query GetInvestedUsersByDateRange($input: DividendPayoutFilter!) {
    getInvestedUsersByDateRange(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithDividends {
        message
      }
    }
  }
`;

export const BULK_UPLOAD_PAYOUT = gql`
  mutation BulkUploadDividendPayout($input: BulkDividendPayoutInput!) {
    bulkUploadDividendPayout(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithDividend {
        message
      }
    }
  }
`;

export const PROCESS_SINGLE_USER_PAYOUT = gql`
  mutation AddDividendPayout($input: AddDividendPayoutInput!) {
    addDividendPayout(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithDividend {
        message
      }
    }
  }
`;

export const EXPORT_CSV_USERS_PAYOUT = gql`
  query GenerateAndExportInvestedUsers($filter: ExportDividendPayoutFilter!) {
    generateAndExportInvestedUsers(filter: $filter) {
      ... on Response {
        message
      }
      ... on Error {
        message
        statusCode
      }
    }
  }
`;

export const GET_DIVIDENDS_PAYOUT_HISTORY = gql`
  query GetDividendHistory($input: DividendHistoryFilter!) {
    getDividendHistory(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithDividends {
        message
        data {
          id
          user {
            id
            firstName
            username
            lastName
            imageUrl
          }
          userId
          company {
            ticker
            id
            legalName
            logoImgURL
          }
          companyId
          units
          amountPerShare
          history {
            createdAt
            totalTransactionAmountIncludingFees
            transactionAmountNetOfFees
            transactionFee
            type
            status
            paymentMetadata
          }
          payoutAmount
          payoutDate
          period
          payoutType
          investmentCategory
          description
          payoutCurrency
          status
          period
        }
        pagination {
          hasNextPage
          hasPrevPage
          total
        }
      }
    }
  }
`;

export const GET_USER_DIVIDENDS_HISTORY = gql`
  query GetUserDividendHistory($input: DividendFilter!) {
    getUserDividendHistory(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithDividends {
        data {
          id
          userId
          company {
            ticker
          }
          companyId
          units
          amountPerShare
          payoutAmount
          payoutDate
          payoutType
          investmentCategory
          description
          payoutCurrency
          history {
            createdAt
            totalTransactionAmountIncludingFees
            transactionAmountNetOfFees
            transactionFee
            type
            status
          }
          status
          period
        }
        pagination {
          hasNextPage
          hasPrevPage
        }
      }
    }
  }
`;
