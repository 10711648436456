import {useState} from 'react';
import styles from './newRewardSchemes.module.scss';
import backIcon from 'images/back.png';
import {DatePicker, Form, Input, Select, Switch, Tooltip} from 'antd';
import {useNavigate} from 'react-router-dom';
import SubmitButton from 'components/Startup/components/SubmitButton';
import moment from 'moment';
import {
  operationTypeEnum,
  referralRewardType,
  rewardAssetClass,
  rewardSchemeType,
  rewardTriggerEnum,
  rewardType,
  walletCurrencies,
} from 'utils/mock';
import TextArea from 'antd/lib/input/TextArea';
import {useMutation, useQuery} from '@apollo/client';
import {CREATE_REWARD_SCHEME} from 'graphql/mutations/rewardScheme';
import {
  GET_ALL_REWARD_SCHEMES,
  GET_ALL_STOCK_REWARDS,
} from 'graphql/queries/rewardScheme';
import {toast} from 'react-toastify';
import FadeEffect from 'components/animation/fadeEffect';
import deleteIcon from 'images/delete.svg';
import plusIcon from 'images/plus-circle.png';

const NewRewardScheme = () => {
  const [type, setType] = useState('');
  const [referee, setReferee] = useState('NONE');
  const [referer, setReferer] = useState('NONE');
  const [transactional, setTransactional] = useState('NONE');
  const [rewardTrigger, setRewardTrigger] = useState('');
  const [operationType, setOperationType] = useState('');
  const [instructions, setInstructions] = useState([
    {
      titleText: '',
      descriptionText: '',
    },
  ]);
  const [allowedCurrencies, setAllowedCurrencies] = useState([
    {
      currency: 'USD',
      minTransactionValue: 0,
    },
  ]);
  const [maxFee, setMaxFee] = useState([
    {
      currency: 'USD',
      value: 0,
    },
  ]);
  const [expirationDate, setExpirationDate] = useState('');
  const [allowedConcurrency, setAllowedConcurrency] = useState(false);
  const [randomStock, setRandomStock] = useState(false);
  const [form] = Form.useForm();

  const {loading, data} = useQuery(GET_ALL_STOCK_REWARDS);

  const [createRewardScheme] = useMutation(CREATE_REWARD_SCHEME);

  const width = 700;
  const {Option} = Select;
  const navigate = useNavigate();

  const fieldWidth = 730;

  const handleAddAllowedCurrency = () => {
    setAllowedCurrencies([
      ...allowedCurrencies,
      {currency: '', minTransactionValue: ''},
    ]);
  };
  const onAllowedCurrencyDelete = id => {
    const filteredFormValue = allowedCurrencies.filter(
      (item, index) => index !== id,
    );
    setAllowedCurrencies(filteredFormValue);
  };
  const handleChangeAllowedCurrencyValue = (i, name, e) => {
    const newFormValues = [...allowedCurrencies];
    newFormValues[i][name] = e;
    setAllowedCurrencies(newFormValues);
  };

  const handleAddMaxFee = () => {
    setMaxFee([...maxFee, {currency: '', value: ''}]);
  };
  const onMaxFeeDelete = id => {
    const filteredFormValue = maxFee.filter((item, index) => index !== id);
    setMaxFee(filteredFormValue);
  };
  const handleChangeMaxFeeValue = (i, name, e) => {
    const newFormValues = [...maxFee];
    newFormValues[i][name] = e;
    setMaxFee(newFormValues);
  };

  const handleAddInstruction = () => {
    setInstructions([...instructions, {titleText: '', descriptionText: ''}]);
  };
  const onInstructionDelete = id => {
    const filteredFormValue = instructions.filter(
      (item, index) => index !== id,
    );
    setInstructions(filteredFormValue);
  };
  const handleChangeInstructionValue = (i, name, e) => {
    const newFormValues = [...instructions];
    newFormValues[i][name] = e;
    setInstructions(newFormValues);
  };

  const disabledDate = current => {
    // Can not select days before today
    return current && current < moment().startOf('day');
  };

  const handleSave = formValues => {
    createRewardScheme({
      variables: {
        input: {
          allowConcurrency: allowedConcurrency,
          description: formValues?.description,
          expirationDate,
          isActive: false,
          language: 'EN',
          maxNumberOfRewards: Number(
            rewardTrigger === 'FIRST' ? 1 : formValues?.maxReward,
          ),
          operationConfig: {
            allowedCurrencies: allowedCurrencies?.map(data => ({
              currency: data?.currency,
              minTransactionValue: Number(data?.minTransactionValue || 0),
            })),
            assetClass: formValues?.assetClass,
            instructions: instructions?.map(data => ({
              titleText: data?.titleText,
              descriptionText: data?.descriptionText,
            })),
            ...(formValues?.cumulativeDurationUnit && {
              cumulativeDurationUnit: formValues?.cumulativeDurationUnit,
            }),
            ...(formValues?.cumulativeDurationValue && {
              cumulativeDurationValue: Number(
                formValues?.cumulativeDurationValue,
              ),
            }),

            rewardTriggerOccurrence: formValues?.rewardTrigger,
            type: operationType,
          },
          rewardConfig: {
            cash: {
              amount: Number(formValues?.amount || 0),
              currency: formValues?.cashCurrency?.currency,
            },
            stocks: {
              rewardUnits: Number(formValues?.units),
              ticker: formValues?.ticker,
            },
            transactionFeeWaiver: {
              maxFeeWaiverConfig: maxFee?.map(data => ({
                currency: data?.currency,
                value: Number(data?.value || 0),
              })),
              percentage: Number(formValues?.percentage),
            },
          },
          rewardType: {
            referral: {
              referee: referee,
              referrer: referer,
            },
            transactional: transactional,
          },
          title: formValues?.title,
          type,
        },
      },
      refetchQueries: [{query: GET_ALL_REWARD_SCHEMES}],
    }).then(({data: {createRewardScheme}}) => {
      if (createRewardScheme.__typename === 'Error') {
        toast.error(createRewardScheme.message);
      } else {
        toast.success('Reward Scheme created successfully');
        navigate('/dashboard/reward-schemes');
      }
    });
  };
  const validateDuration = () => {
    const durationUnit = form.getFieldValue('cumulativeDurationUnit');
    const durationValue = form.getFieldValue('cumulativeDurationValue');
    let max;

    // Determine the max value based on the selected duration unit
    switch (durationUnit) {
      case 'DAYS':
        max = 31;
        break;
      case 'WEEKS':
        max = 4;
        break;
      case 'MONTHS':
        max = 1;
        break;
      default:
        max = 31;
    }

    // Check mutual selection requirement
    if ((durationUnit && !durationValue) || (!durationUnit && durationValue)) {
      return Promise.reject(
        new Error('Both Duration fields must be selected together.'),
      );
    }

    if (durationValue <= 0 && durationValue) {
      return Promise.reject(
        new Error(
          'Invalid Duration value. Value must be within range for the selected Duration.',
        ),
      );
    }

    // Check maximum value condition
    if (durationValue > max) {
      return Promise.reject(
        new Error(
          `Value must be less than or equal to ${max} for the selected Duration`,
        ),
      );
    }

    return Promise.resolve();
  };

  const handleUnitChange = () => {
    form.validateFields(['cumulativeDurationValue']);
  };

  const handleValueChange = () => {
    form.validateFields(['cumulativeDurationUnit']);
  };

  const shouldShowMinValue = () => {
    if (type === 'REFERRAL') {
      return true;
    }
    if (type === 'TRANSACTIONAL' && rewardTrigger === 'ANY') {
      return true;
    }
    return false;
  };

  return (
    <div>
      <div className={styles.navigation}>
        <img src={backIcon} alt="Go back" onClick={() => navigate(-1)} />
        <span>
          <span className={styles['h-style']}>New Reward Scheme</span>
        </span>
      </div>
      <div className={styles.form}>
        <Form form={form} onFinish={handleSave} layout="vertical">
          <Form.Item
            label="Reward Scheme Type"
            className={styles.field}
            name="type"
            rules={[
              {
                required: true,
                message: 'Reward scheme type is required',
              },
            ]}>
            <Select
              style={{width: fieldWidth}}
              value={type}
              allowClear
              onChange={e => setType(e)}>
              {rewardSchemeType.map(({key, name}) => (
                <Option key={key} value={key}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'title is required',
              },
            ]}
            label="Title"
            className={styles.field}
            name="title">
            <Input
              style={{width: fieldWidth}}
              name="title"
              placeholder="Reward scheme title"
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'description is required',
              },
            ]}
            label="Description"
            className={styles.field}
            name="description">
            <TextArea
              style={{width: fieldWidth}}
              name="description"
              placeholder="Reward scheme description..."
            />
          </Form.Item>
          {type === 'REFERRAL' && (
            <div className={styles['date-format']}>
              <Form.Item
                label="Referee Reward"
                className={styles.field}
                name="referee"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select
                  style={{width: 340}}
                  value={referee}
                  allowClear
                  onChange={e => setReferee(e)}
                  name="referee">
                  {referralRewardType?.map(({key, name}) => (
                    <Option key={key} value={key}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Referer Reward"
                className={styles.field}
                name="referer"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select
                  style={{width: 340}}
                  value={referer}
                  allowClear
                  onChange={e => setReferer(e)}
                  name="referer">
                  {referralRewardType?.map(({key, name}) => (
                    <Option key={key} value={key}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          )}
          {type === 'TRANSACTIONAL' && (
            <Form.Item
              label="Transactional Reward"
              className={styles.field}
              name="transactional"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select
                style={{width: fieldWidth}}
                allowClear
                value={transactional}
                onChange={e => setTransactional(e)}
                name="transactional">
                {rewardType.map(({key, name}) => (
                  <Option value={key} key={key}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <div>
            <h1 className={styles['top-header-text']}>
              Operation Configuration
            </h1>
          </div>
          <div className={styles['date-format']}>
            <Form.Item
              label="Operation Type"
              name="operationType"
              rules={[
                {
                  required: true,
                  message: 'operation type is required',
                },
              ]}>
              <Select
                style={{width: 340}}
                value={operationType}
                onChange={e => setOperationType(e)}
                allowClear
                name="operationType">
                {operationTypeEnum.map(({key, name}) => (
                  <Option key={key} value={key}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Reward Trigger"
              name="rewardTrigger"
              rules={[
                {
                  required: true,
                  message: 'reward trigger is required',
                },
              ]}>
              <Select
                style={{width: 340}}
                allowClear
                value={rewardTrigger}
                onChange={e => setRewardTrigger(e)}
                name="rewardTrigger">
                {rewardTriggerEnum.map(({key, name}) => (
                  <Option key={key} value={key}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          {type === 'REFERRAL' && rewardTrigger === 'FIRST' && (
            <div className={styles['duration']}>
              <Form.Item
                name="cumulativeDurationUnit"
                className={styles.field}
                rules={[{validator: validateDuration}]}
                label={
                  <Tooltip title="This defines the duration for tracking transactions and deposits, starting from the time the referred user signs up. For example, if you select 'Days' and enter a value of 4, it means we will track the user's activity starting 4 days after their signup.">
                    Cumulative Duration
                  </Tooltip>
                }>
                <Select
                  onChange={handleUnitChange}
                  allowClear
                  style={{width: 340}}>
                  <Option key="DAYS">Days</Option>
                  <Option key="WEEKS">Weeks</Option>
                  <Option key="MONTHS">Months</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="cumulativeDurationValue"
                className={styles.field}
                rules={[{validator: validateDuration}]}
                label={
                  <Tooltip
                    title={
                      <div>
                        <p>
                          Enter a value for the cumulative duration. The maximum
                          value depends on the selected duration unit:
                        </p>
                        <ul>
                          <li>Daily: up to 31</li>
                          <li>Weeks: up to 4</li>
                          <li>Months: up to 1</li>
                        </ul>
                        <p>
                          Ensure that both the duration unit and value are
                          selected together.
                        </p>
                      </div>
                    }>
                    Cumulative Duration Value
                  </Tooltip>
                }>
                <Input
                  style={{width: 340}}
                  type="number"
                  onChange={e => handleValueChange(e.target.value)}
                />
              </Form.Item>
            </div>
          )}
          {operationType === 'INVESTMENT' && (
            <Form.Item
              label="Asset Class"
              className={styles.field}
              name="assetClass"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select
                mode="multiple"
                style={{width: fieldWidth}}
                name="assetClass">
                {rewardAssetClass.map(({key, name}) => (
                  <Option value={key} key={key}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <>
            <h3 className={styles.title}>Allowed Currencies</h3>
            {allowedCurrencies?.map((item, index) => (
              <FadeEffect key={index}>
                <div className={styles['key-facts']} key={index}>
                  <div>
                    <Form.Item label="Currency" style={{width}}>
                      <Select
                        name="currency"
                        value={item.currency || ''}
                        allowClear
                        placeholder="Currency"
                        onChange={e =>
                          handleChangeAllowedCurrencyValue(index, 'currency', e)
                        }>
                        {walletCurrencies.map(({key, name}) => (
                          <Option key={key} value={key}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Minimum Transaction Value"
                      style={{width}}>
                      <Input
                        name="minTransactionValue"
                        value={item.minTransactionValue || ''}
                        placeholder="Minimum Transaction Value"
                        onChange={e =>
                          handleChangeAllowedCurrencyValue(
                            index,
                            'minTransactionValue',
                            e.target.value,
                          )
                        }
                      />
                    </Form.Item>
                    {(!item.currency || !item.minTransactionValue) && (
                      <span className={styles['warning']}>
                        Please note both fields are required
                      </span>
                    )}
                  </div>
                  <img
                    onClick={() => onAllowedCurrencyDelete(index)}
                    src={deleteIcon}
                    alt=""
                  />
                </div>
              </FadeEffect>
            ))}
            <div
              onClick={handleAddAllowedCurrency}
              className={styles['add-button']}>
              <img src={plusIcon} alt="" />
              <h1>Add Currency</h1>
            </div>
          </>
          <div>
            <h1 className={styles['top-header-text']}>Reward Instructions</h1>
          </div>
          <>
            {instructions.map((item, index) => (
              <FadeEffect key={index}>
                <div className={styles['key-facts']} key={index}>
                  <div>
                    <Form.Item
                      label="Title"
                      rules={[
                        {
                          required: true,
                          message: 'title is required',
                        },
                      ]}>
                      <Input
                        style={{width}}
                        value={item.titleText || ''}
                        onChange={e =>
                          handleChangeInstructionValue(
                            index,
                            'titleText',
                            e.target.value,
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label="Description"
                      rules={[
                        {
                          required: true,
                          message: 'description is required',
                        },
                      ]}>
                      <Input.TextArea
                        rows={4}
                        value={item.descriptionText || ''}
                        onChange={e =>
                          handleChangeInstructionValue(
                            index,
                            'descriptionText',
                            e.target.value,
                          )
                        }
                      />
                    </Form.Item>
                    {(!item.titleText || !item.descriptionText) && (
                      <span className={styles['warning']}>
                        Please note both fields are required
                      </span>
                    )}
                  </div>
                  <img
                    onClick={() => onInstructionDelete(index)}
                    src={deleteIcon}
                    alt=""
                  />
                </div>
              </FadeEffect>
            ))}
            <div
              onClick={handleAddInstruction}
              className={styles['add-button']}>
              <img src={plusIcon} alt="" />
              <h1>Add Instruction</h1>
            </div>
          </>

          {(referee === 'STOCKS' ||
            referer === 'STOCKS' ||
            transactional === 'STOCKS') && (
            <>
              <div>
                <h1 className={styles['top-header-text']}>
                  Stocks Reward Configuration
                </h1>
              </div>
              <Form.Item label="Pick Stock Randomly" className={styles.field}>
                <Switch
                  defaultChecked={randomStock}
                  onChange={e => setRandomStock(e)}
                />
              </Form.Item>
              <div className={styles['date-format']}>
                {!randomStock && (
                  <Form.Item
                    label="Ticker"
                    className={styles.field}
                    name="ticker"
                    rules={[
                      {
                        required: false,
                      },
                    ]}>
                    <Select
                      style={{width: 340}}
                      name="ticker"
                      loading={loading}>
                      {data.getAllStockBanks?.data?.stockRewardBank.map(
                        (item, index) => (
                          <Option value={item.ticker} key={index}>
                            {item.ticker}
                          </Option>
                        ),
                      )}
                    </Select>
                  </Form.Item>
                )}
                <Form.Item
                  label="Units"
                  className={styles.field}
                  name="units"
                  rules={[
                    {
                      required: true,
                      message: 'unit is required',
                    },
                  ]}>
                  <Input
                    type="number"
                    min={1}
                    style={{width: 340}}
                    name="units"
                  />
                </Form.Item>
              </div>
            </>
          )}
          {(referee === 'CASH_TO_INVEST' ||
            referer === 'CASH_TO_INVEST' ||
            transactional === 'CASH_TO_INVEST') && (
            <>
              <div>
                <h1 className={styles['top-header-text']}>
                  Cash Reward Configuration
                </h1>
              </div>
              <div className={styles['date-format']}>
                <Form.Item
                  label="Amount"
                  className={styles.field}
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: 'Amount is required',
                    },
                  ]}>
                  <Input
                    type="number"
                    min={0}
                    style={{width: 340}}
                    name="amount"
                  />
                </Form.Item>
                <Form.Item
                  label="Currency"
                  className={styles.field}
                  name="cashCurrency"
                  rules={[
                    {
                      required: true,
                      message: 'currency is required',
                    },
                  ]}>
                  <Select style={{width: 340}} name="cashCurrency">
                    {walletCurrencies.map(({key, name}) => (
                      <Option value={key} key={key}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </>
          )}
          {(referee === 'TRANSACTION_FEE_WAIVER' ||
            referer === 'TRANSACTION_FEE_WAIVER' ||
            transactional === 'TRANSACTION_FEE_WAIVER') && (
            <>
              <div>
                <h1 className={styles['top-header-text']}>
                  Fee Waiver Configuration
                </h1>
              </div>
              <div className={styles['date-format']}>
                <Form.Item
                  label="Percentage (pecentage of transaction fee to be waived)"
                  className={styles.field}
                  name="percentage"
                  rules={[
                    {
                      required: true,
                      message: 'Percentage is required',
                    },
                  ]}>
                  <Input
                    type="number"
                    min={0}
                    max={100}
                    style={{width: 340}}
                    name="percentage"
                  />
                </Form.Item>
              </div>
              <>
                <h3 className={styles.title}>
                  Maximum fee waiver for each currency
                </h3>
                {maxFee?.map((item, index) => (
                  <FadeEffect key={index}>
                    <div className={styles['key-facts']} key={index}>
                      <div>
                        <Form.Item label="Currency" style={{width}}>
                          <Select
                            name="currency"
                            value={item.currency || ''}
                            placeholder="Currency"
                            onChange={e =>
                              handleChangeMaxFeeValue(index, 'currency', e)
                            }>
                            {walletCurrencies.map(({key, name}) => (
                              <Option key={key} value={key}>
                                {name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item label="Value" style={{width}}>
                          <Input
                            name="value"
                            value={item.value || ''}
                            placeholder="Value"
                            onChange={e =>
                              handleChangeMaxFeeValue(
                                index,
                                'value',
                                e.target.value,
                              )
                            }
                          />
                        </Form.Item>
                        {(!item.currency || !item.value) && (
                          <span className={styles['warning']}>
                            Please note both fields are required
                          </span>
                        )}
                      </div>
                      <img
                        onClick={() => onMaxFeeDelete(index)}
                        src={deleteIcon}
                        alt=""
                      />
                    </div>
                  </FadeEffect>
                ))}
                <div onClick={handleAddMaxFee} className={styles['add-button']}>
                  <img src={plusIcon} alt="" />
                  <h1>Add Currency</h1>
                </div>
              </>
            </>
          )}

          <div className={styles['date-format']}>
            {type === 'REFERRAL' ||
            (type === 'TRANSACTIONAL' && rewardTrigger === 'ANY') ? (
              <Form.Item
                label={`Max Number of times user can earn reward`}
                name="maxReward"
                rules={[
                  {
                    required: shouldShowMinValue(),
                    message: 'max reward is required',
                  },
                ]}>
                <Input
                  type="number"
                  style={{width: 340}}
                  name="maxReward"
                  min={1}
                  placeholder="2"
                />
              </Form.Item>
            ) : (
              ''
            )}
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Expiry date required',
                },
              ]}
              name="expiredDate"
              label="Expiration Date (Time is saved in UTC/GMT)">
              <DatePicker
                name="Expiry Date"
                format={'YYYY-MM-DD HH:mm:ss'}
                disabledDate={disabledDate}
                style={{width: 340}}
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm:ss'),
                }}
                value={expirationDate}
                onChange={e =>
                  setExpirationDate(
                    moment(e).format('YYYY-MM-DDTHH:mm:ss+00:00'),
                  )
                }
              />
            </Form.Item>
          </div>
          <Form.Item label="Allow Concurrency" className={styles.field}>
            <Switch
              defaultChecked={allowedConcurrency}
              onChange={e => setAllowedConcurrency(e)}
            />
          </Form.Item>
          <SubmitButton label="Create" />
        </Form>
      </div>
    </div>
  );
};

export {NewRewardScheme};
