import {useState, useEffect} from 'react';
import styles from './closureModal.module.scss';
import {Select, Spin, Form, Input, Tabs, DatePicker} from 'antd';
import {useMutation, useQuery} from '@apollo/client';
import {toast} from 'react-toastify';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {fieldRules} from 'utils/helper';
import {Button} from '@dabafinance/react-components';
import {supportedLanguages} from 'utils/constants';
import {onTranslateStockClosure} from '../actions';
import DBModal from 'components/modal/modal';
import {GET_STOCK_CLOSURE} from 'graphql/queries/stocks';
import {
  CREATE_STOCK_CLOSURE,
  SET_STOCK_CLOSURE_STATUS,
  UPDATE_STOCK_CLOSURE,
} from 'graphql/mutations/stocks';
import moment from 'moment';

const ClosureModal = ({
  openClosureModal,
  setOpenClosureModal,
  stockExchangeId,
  id,
  refetch,
  type,
}) => {
  const [form] = Form.useForm();
  const [status, setStatus] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [enableChangeTranslation, setEnableChangeTranslation] = useState(true);
  const [language, setLanguage] = useState('');
  const [selectedTranslationLanguage, setSelectedTranslationLanguage] =
    useState('');
  const [translatedClosures, setTranslatedClosures] = useState([]);
  const {Option} = Select;
  const title = Form.useWatch('title', form);
  const message = Form.useWatch('message', form);
  const date = Form.useWatch('date', form);

  const width = 680;

  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );

  const {data, loading} = useQuery(GET_STOCK_CLOSURE, {
    variables: {
      getStockExchangeClosureByIdId: id,
    },
    skip: ['CREATE', 'STATUS'].includes(type),
    fetchPolicy: 'no-cache',
  });

  const [updateStockExchangeClosure, {loading: closureUpdate}] =
    useMutation(UPDATE_STOCK_CLOSURE);
  const [createStockExchangeClosure, {loading: closureCreate}] =
    useMutation(CREATE_STOCK_CLOSURE);
  const [setStockExchangeClosureStatus, {loading: closureStatusUpdate}] =
    useMutation(SET_STOCK_CLOSURE_STATUS);

  useEffect(() => {
    if (data?.getStockExchangeClosureById) {
      setLanguage(data?.getStockExchangeClosureById?.language || 'EN');
      form.setFieldsValue({
        language: data?.getStockExchangeClosureById?.language || 'EN',
        title: data?.getStockExchangeClosureById?.title,
        message: data?.getStockExchangeClosureById?.closureMessage,
        date: data?.getStockExchangeClosureById?.date
          ? moment(
              data?.getStockExchangeClosureById?.date,
              'YYYY-MM-DD HH:mm:ss',
            )
          : '',
        translationLanguage:
          (data?.getStockExchangeClosureById?.multiLang &&
            data.getStockExchangeClosureById.multiLang[0]?.language) ||
          '',
      });
    }
  }, [data?.getStockExchangeClosureById]);

  useEffect(() => {
    if (data?.getStockExchangeClosureById?.multiLang?.length > 0) {
      setTranslatedClosures(
        data?.getStockExchangeClosureById?.multiLang?.map(data => ({
          title: data.title,
          message: data?.closureMessage,
          language: data?.language,
        })),
      );
    }
  }, [data?.getStockExchangeClosureById?.multiLang]);

  const languageToBeTranslatedTo = supportedLanguages.filter(
    key => key.value !== language,
  );

  const handleChangeTranslatedValues = (key, event, name) => {
    let eventName = name;
    let eventValue = name ? event : event.target.value;
    const newFormValues = [...translatedClosures];
    newFormValues[key][eventName] = eventValue;
    setTranslatedClosures(newFormValues);
  };

  const handleSave = () => {
    if (type === 'UPDATE') {
      updateStockExchangeClosure({
        variables: {
          updateStockExchangeClosureId: id,
          input: {
            title,
            closureMessage: message,
            date,
            multiLang: translatedClosures.map(item => ({
              title: item.title,
              language: item.language,
              closureMessage: item.message,
            })),
            language,
            stockExchange: stockExchangeId,
          },
        },
      }).then(({data: {updateStockExchangeClosure}}) => {
        if (updateStockExchangeClosure.__typename === 'Error') {
          toast.error(updateStockExchangeClosure.message);
        } else {
          toast.success('Stock closure updated successfully');
          refetch();
          setOpenModal(false);
          form.resetFields();
          setOpenClosureModal();
        }
      });
    } else if (type === 'CREATE') {
      createStockExchangeClosure({
        variables: {
          input: {
            title,
            closureMessage: message,
            date,
            multiLang: translatedClosures.map(item => ({
              title: item.title,
              language: item.language,
              closureMessage: item.message,
            })),
            language,
            stockExchange: stockExchangeId,
          },
        },
      }).then(({data: {createStockExchangeClosure}}) => {
        if (createStockExchangeClosure.__typename === 'Error') {
          toast.error(createStockExchangeClosure.message);
        } else {
          toast.success('Stock closure created successfully');
          refetch();
          setOpenModal(false);
          form.resetFields();
          setOpenClosureModal();
        }
      });
    } else {
      setStockExchangeClosureStatus({
        variables: {
          setStockExchangeClosureStatusId: id,
          status,
        },
      }).then(({data: {setStockExchangeClosureStatus}}) => {
        if (setStockExchangeClosureStatus.__typename === 'Error') {
          toast.error(setStockExchangeClosureStatus.message);
        } else {
          toast.success('Stock closure status updated successfully');
          refetch();
          setOpenModal(false);
          setTranslatedClosures([]);
          setOpenClosureModal({type: 'NONE'});
        }
      });
    }
  };

  const handleTranslation = async () => {
    const translateProps = {
      sourceLang: language,
      targetLang: selectedTranslationLanguage,
      targetText1: title,
      targetText2: message,
    };
    try {
      const newValues = [...translatedClosures];

      //Check if translation exists
      if (newValues.length && newValues[0]) {
        // Check if we're trying to translate in the same language
        const languageExists = newValues.some(
          data => data.language === selectedTranslationLanguage,
        );
        if (languageExists) {
          const updateTranslation = await onTranslateStockClosure(
            translateText,
            translateProps,
          );
          const translationIndex = newValues.findIndex(
            data => data.language === selectedTranslationLanguage,
          );
          newValues[translationIndex].title = updateTranslation.title;
          newValues[translationIndex].message = updateTranslation.message;
          newValues[translationIndex].language = updateTranslation.language;
          setTranslatedClosures(newValues);
        }
      } else {
        const triggerTranslation = await onTranslateStockClosure(
          translateText,
          translateProps,
        );
        if (!triggerTranslation) {
          return toast.error('Something went wrong when Translating');
        }
        newValues.push(triggerTranslation);
        setTranslatedClosures(newValues);
        setTranslatedClosures(newValues);
      }
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  const handleConfirm = () => {
    setOpenModal(true);
  };

  return (
    <DBModal
      isOpen={openClosureModal}
      width={750}
      handleClose={() => {
        form.resetFields();
        setOpenClosureModal();
        setTranslatedClosures([]);
      }}
      content={
        loading ? (
          <div className="query-loading">
            <Spin />
          </div>
        ) : (
          <div>
            <h1>{id ? 'Update' : 'Create'} Stock Exchange Closure</h1>
            <div className={styles['inner-container']}>
              <Form form={form} onFinish={handleConfirm} layout="vertical">
                {type === 'STATUS' ? (
                  <>
                    <Form.Item label="Set Status" name="status">
                      <Select value={status} onChange={e => setStatus(e)}>
                        <Option key={'ACTIVE'} value={'ACTIVE'}>
                          Active
                        </Option>
                        <Option key={'INACTIVE'} value={'INACTIVE'}>
                          In-Active
                        </Option>
                      </Select>
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <Form.Item
                      rules={[fieldRules('Language')]}
                      label="Language"
                      className={styles.field}
                      name="language">
                      <Select
                        style={{width}}
                        value={language}
                        onChange={e => setLanguage(e)}>
                        {supportedLanguages.map(data => (
                          <Option key={data.value}>{data.label}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Language Translation"
                      className={styles.field}
                      name="translationLanguage">
                      <Select
                        style={{width}}
                        value={selectedTranslationLanguage}
                        onChange={e => setSelectedTranslationLanguage(e)}>
                        {languageToBeTranslatedTo.map(data => (
                          <Option key={data.value}>{data.label}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Tabs
                      className={styles.tabs}
                      tabBarExtraContent={
                        selectedTranslationLanguage && (
                          <Button
                            disabled={loadingTranslation}
                            label="Translate"
                            onClick={handleTranslation}
                          />
                        )
                      }>
                      <Tabs.TabPane tab="Original" key="Original">
                        <Form.Item
                          label="Title"
                          rules={[fieldRules('title')]}
                          style={{width}}
                          className={styles['sub-heading']}
                          name="title">
                          <Input placeholder={'Title'} />
                        </Form.Item>
                        <Form.Item
                          label="Closure Message"
                          rules={[fieldRules('Closure Message')]}
                          className={styles['sub-heading']}
                          style={{width}}
                          name="message">
                          <Input.TextArea rows={6} />
                        </Form.Item>
                      </Tabs.TabPane>
                      {translatedClosures?.length &&
                        translatedClosures?.map((data, index) => (
                          <Tabs.TabPane
                            key={data.language}
                            tab={
                              supportedLanguages.find(
                                lang => lang.value === data.language,
                              ).label
                            }>
                            <div className={styles.updateButton}>
                              <Button
                                onClick={() =>
                                  setEnableChangeTranslation(
                                    !enableChangeTranslation,
                                  )
                                }
                                type="secondary"
                                label={
                                  enableChangeTranslation
                                    ? 'Edit Translation'
                                    : 'Disable Translation'
                                }
                              />
                            </div>
                            <div key={index}>
                              <Form.Item label="Title" className={styles.field}>
                                <Input
                                  style={{width}}
                                  value={data.title}
                                  disabled={enableChangeTranslation}
                                  name="title"
                                  onChange={e =>
                                    handleChangeTranslatedValues(
                                      index,
                                      e.target.value,
                                      'title',
                                    )
                                  }
                                  placeholder={'Title'}
                                />
                              </Form.Item>
                              <Form.Item
                                label="Closure Message"
                                className={styles.field}>
                                <Input.TextArea
                                  style={{width}}
                                  value={data.message}
                                  disabled={enableChangeTranslation}
                                  onChange={e =>
                                    handleChangeTranslatedValues(
                                      index,
                                      e.target.value,
                                      'message',
                                    )
                                  }
                                  rows={5}
                                />
                              </Form.Item>
                            </div>
                          </Tabs.TabPane>
                        ))}
                    </Tabs>
                    <Form.Item
                      style={{width}}
                      rules={[fieldRules('Date')]}
                      label="Date"
                      className={styles['sub-heading']}
                      name="date">
                      <DatePicker
                        name="Closure Date"
                        format={'YYYY-MM-DD HH:mm:ss'}
                        style={{width}}
                        showTime={{
                          defaultValue: moment('00:00', 'HH:mm:ss'),
                        }}
                      />
                    </Form.Item>
                  </>
                )}
                <SubmitButton
                  disabled={closureUpdate || closureCreate}
                  label={id ? 'Update Closure' : 'Create Closure'}
                />
              </Form>
            </div>
            <DBModal
              isOpen={openModal}
              handleClose={() => setOpenModal(false)}
              width={590}
              content={
                <div className={styles.deleteModal}>
                  <h1>
                    {type === 'UPDATE'
                      ? 'Update'
                      : type === 'CREATE'
                      ? 'Create'
                      : 'Update Status of'}{' '}
                    Stock Exchange Closure
                  </h1>
                  <span>
                    Are you sure you want to{' '}
                    {type === 'UPDATE'
                      ? 'update '
                      : type === 'CREATE'
                      ? 'create '
                      : 'update status of '}
                    this Exchange Closure?
                  </span>
                  <div className={styles.deleteButtons}>
                    <Button
                      onClick={() => setOpenModal(false)}
                      type="secondary"
                      label="NO"
                    />
                    <Button
                      disabled={
                        closureUpdate || closureCreate || closureStatusUpdate
                      }
                      onClick={handleSave}
                      label="YES"
                    />
                  </div>
                </div>
              }
            />
          </div>
        )
      }
    />
  );
};

export {ClosureModal};
