import styles from './singleSavingsProduct.module.scss';
import backIcon from 'images/back.png';
import coinIcon from 'icons/coin.svg';
import {useQuery} from '@apollo/client';
import {GET_SINGLE_SAVINGS_PRODUCT_BY_ID} from 'graphql/queries/savings';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Dropdown, Spin, Table, Tag} from 'antd';
import moment from 'moment';
import ClockIcon from 'icons/clockBtn.svg';
import CheckIcon from 'icons/checkBtn.svg';
import CancelIcon from 'icons/cancelBtn.svg';
import WalletIcon from 'icons/walletBtn.svg';
import dotsIcon from 'images/dots.svg';
import {useState} from 'react';
import SingleSaversModal from 'pages/Savings/companies/company/users/SingleSaversModal';
import {currencySymbols} from 'utils/mock';

const SingleSavingsProductOverview = () => {
  const [userModal, setUserModal] = useState(false);
  const navigate = useNavigate();
  const {id} = useParams();
  const {data, loading} = useQuery(GET_SINGLE_SAVINGS_PRODUCT_BY_ID, {
    variables: {
      adminGetSavingsProductByIdId: id,
      filter: {
        productId: id,
      },
      page: 1,
      limit: 100,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
  });

  const columns = [
    {
      dataIndex: 'teamMember',
      key: 'nameOfTeam',
      title: 'NAME OF USER',
      render: (text, record) => (
        <SavingsUserProfile
          imageUrl={record?.user?.imageUrl}
          firstName={record?.user?.firstName}
          lastName={record?.user?.lastName}
        />
      ),
    },
    {
      dataIndex: 'amountSaved',
      key: 'amountSaved',
      title: 'Amount Saved',
      render: (text, record) => (
        <div>
          <span style={{fontSize: '11px', fontWeight: 'bold'}}>
            {currencySymbols[record?.currency]?.symbol}{' '}
          </span>
          {record?.totalBalance.toLocaleString()}
        </div>
      ),
    },

    {
      dataIndex: 'status',
      key: 'status',
      title: 'STATUS',
      render: (text, record) => (
        <Tag
          color={
            {
              'ACTIVE': 'green',
              'INACTIVE': 'magenta',
              'DELETED': 'red',
              'COMPLETED': 'green',
            }?.[record?.status] || 'default'
          }>
          {record?.status}
        </Tag>
      ),
    },
    {
      dataIndex: 'Date Added',
      key: 'date',
      title: 'START DATE & TIME',
      render: (text, record) => (
        <span>{moment(record?.createdAt).format('DD MMM, YYYY hh:mm A')}</span>
      ),
    },
    {
      dataIndex: 'EndDate',
      key: 'endDate',
      title: 'END DATE & TIME',
      render: (text, record) => (
        <span>
          {record?.endDate
            ? moment(record?.endDate).format('DD MMM, YYYY hh:mm A')
            : ''}
        </span>
      ),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: 'Actions',
      render: (text, record) => (
        <Dropdown
          trigger="click"
          menu={{
            items: [
              {
                key: 1,
                label: 'View Info',
                onClick: () =>
                  setUserModal({isOpen: true, type: 'INFO', content: record}),
              },
            ],
          }}>
          <img src={dotsIcon} alt="" />
        </Dropdown>
      ),
    },
  ];

  if (loading) {
    return (
      <div className="query-loading">
        <Spin />
        <h1>Loading Product</h1>
      </div>
    );
  }
  const productData = data?.adminGetSavingsProductById;

  return (
    <div className={styles.root}>
      <div className="navigateBack">
        <div role="button" onClick={() => navigate(-1)}>
          <img src={backIcon} alt="Go back" />
          <h1>Go back /</h1>
        </div>
        <h1 className={styles.header}>{productData?.data?.name}</h1>
      </div>
      <TopCurrencies data={productData?.data} />
      <PercentagePlans data={productData?.statistics} />
      <div>
        <div className={styles.tableContainer}>
          <h1>Users Lists</h1>
          <Table
            columns={columns}
            dataSource={data?.getSavingsPlansWithFilter?.data}
          />
        </div>
      </div>
      <SingleSaversModal
        type={userModal.type}
        openModal={userModal.isOpen}
        setOpenModal={setUserModal}
        content={userModal.content}
      />
    </div>
  );
};

const TopCurrencies = ({data}) => {
  const fields = [
    {
      label: 'Time created',
      value: (
        <p>
          {data?.createdAt
            ? moment(data?.createdAt).format('DD MMM, YYYY')
            : 'N/A'}
        </p>
      ),
    },
    {
      label: 'Type',
      value: (
        <div className={styles.savingType}>
          <p>{data?.savingsType}</p>
        </div>
      ),
    },
    {
      label: 'Currency',
      value: <p>{data?.config?.allowedCurrency}</p>,
    },
    {
      label: 'Duration',
      value: <p>N/A</p>,
    },
    {
      label: 'Status',
      value: (
        <div
          style={{background: data?.status === 'ACTIVE' ? '#edffe9' : 'red'}}>
          <p>Active</p>
        </div>
      ),
    },
  ];
  return (
    <div className={styles.currenciesContainer}>
      {fields.map((data, index) => (
        <div key={index} className={styles.currency}>
          <div className={styles.coinImage}>
            <img src={coinIcon} alt="" />
          </div>
          <div>
            <span>{data.label}</span>
            {data?.value}
          </div>
        </div>
      ))}
    </div>
  );
};

const PercentagePlans = ({data}) => {
  const plans = [
    {
      label: 'Total number of Savings started',
      amount: data?.totalSavingsPlans?.toLocaleString(),
      image: <img src={ClockIcon} alt="" />,
    },
    {
      label: 'Active savings',
      amount: data?.activeSavingsCount,
      image: <img src={CheckIcon} alt="" />,
    },
    {
      label: 'Inactive savings',
      amount: data?.inactiveSavingsCount,
      image: <img src={CancelIcon} alt="" />,
    },
    {
      label: 'Total Amount Saved',
      amount: data?.totalBalance.toLocaleString(),

      image: <img src={WalletIcon} alt="" />,
    },
  ];

  return (
    <div className={styles.percentages}>
      {plans.map((data, index) => (
        <div className={styles.percentage} key={index}>
          <div className={styles.percentImage}>{data.image}</div>

          <div className={styles.percentageLabel}>
            <span>{data.label}</span>
            <p>{data.amount}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export const SavingsUserProfile = ({imageUrl, firstName, lastName}) => {
  return (
    <div className={styles.imageProfile}>
      {imageUrl ? (
        <img src={imageUrl} alt={`${firstName} ${lastName}`} />
      ) : firstName ? (
        <div className={styles.dummyAvatar}>
          {firstName?.[0]}
          {lastName?.[0]}
        </div>
      ) : (
        ''
      )}
      <span>
        {firstName} {lastName}
      </span>
    </div>
  );
};

export default SingleSavingsProductOverview;
