import {useEffect, useState} from 'react';
import SubmitButton from 'components/Startup/components/SubmitButton';
import plusIcon from 'images/plus-circle.png';
import styles from '../../../new/screens/analog/campaignAnalog.module.scss';
import FadeEffect from 'components/animation/fadeEffect';
import AnalogCard from 'pages/campaigns/new/screens/analog/AnalogCard';
import {
  resizeImageFile,
  UploadDocumentToBucket,
} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import {useMutation, useQuery} from '@apollo/client';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {useParams} from 'react-router-dom';
import {Spin} from 'antd';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';

const UpdateAnalogs = () => {
  const [analogValues, setAnalogValues] = useState([]);
  const [loadingImageUpload, setLoadingImageUpload] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const {id, language} = useParams();

  const {data, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });
  const [updateCampaign, {loading: campaignLoading}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (data?.getCampaignById.analog) {
      setAnalogValues(
        data?.getCampaignById?.analog?.map(data => ({
          name: data?.name,
          type: data?.companyType,
          description: data?.description,
          imageUrl: data?.logo,
          previewUrl: data?.logo,
          valuation: Number(data?.valuation),
        })),
      );
    }
  }, [data?.getCampaignById.analog]);

  useEffect(() => {
    if (analogValues.length) {
      analogValues?.map(data => {
        const isValid =
          !data.name ||
          !data.description ||
          !data.valuation ||
          !data.imageUrl ||
          !data.type;
        return setDisableButton(isValid);
      });
    } else {
      setDisableButton(false);
    }
  }, [analogValues, campaignLoading]);

  const handleAddAnalog = () => {
    setAnalogValues([
      ...analogValues,
      {
        name: '',
        type: '',
        description: '',
        imageUrl: '',
        previewUrl: '',
        valuation: 0,
      },
    ]);
  };

  const handleChangeImage = async (i, {file}) => {
    const temp = file.originFileObj;
    const newFormValues = [...analogValues];
    const resizedFile = await resizeImageFile(temp);
    setLoadingImageUpload(true);
    const res = await UploadDocumentToBucket(resizedFile);
    setLoadingImageUpload(false);
    newFormValues[i].previewUrl = res;
    newFormValues[i].imageUrl = res;
    setAnalogValues(newFormValues);
  };

  const handleChangeCompanyType = (index, event) => {
    const newFormValues = [...analogValues];
    newFormValues[index].type = event;
    setAnalogValues(newFormValues);
  };

  const handleChangeNumberFields = (i, e) => {
    let newFormValues = [...analogValues];
    newFormValues[i].valuation = e;
    setAnalogValues(newFormValues);
  };

  const handleDeleteAnalog = id => {
    const filteredFormValue = analogValues.filter(
      (data, index) => index !== id,
    );
    setAnalogValues(filteredFormValue);
  };

  const handleChangeFields = (i, e) => {
    const newFormValues = [...analogValues];
    newFormValues[i][e.target.name] = e.target.value;
    setAnalogValues(newFormValues);
  };

  const handleDeleteImage = i => {
    const newFormValues = [...analogValues];
    newFormValues[i].previewUrl = '';
    newFormValues[i].imageUrl = '';
    setAnalogValues(newFormValues);
  };

  const handleSave = () => {
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId: data?.getCampaignById?.fundraisingCompany?.id,
          language,
          analog: analogValues?.map(data => ({
            name: data?.name,
            companyType: data?.type,
            description: data?.description,
            logo: data?.imageUrl,
            valuation: Number(data?.valuation),
          })),
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error('Please make sure all fields are not empty');
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div>
        <h2>Campaign Analogs</h2>
      </div>
      <div>
        {analogValues?.map((data, index) => (
          // eslint-disable-next-line react/jsx-key
          <FadeEffect>
            <AnalogCard
              name={data.name}
              type={data.type}
              valuation={data.valuation}
              imgUrl={data.imageUrl}
              loadingImageUpload={loadingImageUpload}
              previewImage={data.previewUrl}
              description={data.description}
              handleDeleteAnalog={() => handleDeleteAnalog(index)}
              handleNumberChange={e => handleChangeNumberFields(index, e)}
              handleDeleteImage={() => handleDeleteImage(index)}
              handleChange={e => handleChangeFields(index, e)}
              handleChangeImage={e => handleChangeImage(index, e)}
              handleChangeCompanyType={e => handleChangeCompanyType(index, e)}
            />
          </FadeEffect>
        ))}
      </div>
      {disableButton && (
        <span className={styles['warning']}>
          NOTE: All fields are required before proceeding!!!
        </span>
      )}
      <div onClick={handleAddAnalog} className={styles['add-button']}>
        <img src={plusIcon} alt="" />
        <h1>Add Analog</h1>
      </div>
      <SubmitButton
        disabled={disableButton || campaignLoading}
        label="Update Changes"
        handleClick={handleSave}
      />
    </div>
  );
};

export default UpdateAnalogs;
