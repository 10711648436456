import {gql} from '@apollo/client';

export const GET_USER_DOCUMENTS = gql`
  query GetUserDocumentByFilter($filter: UserDocumentFilter!) {
    getUserDocumentByFilter(filter: $filter) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUserDocuments {
        data {
          id
          user {
            id
            email
          }
          documentName
          documentUrl
          issuedDate
          documentCategory
          isActive
          createdAt
          updatedAt
        }
        pagination {
          limit
          hasPrevPage
          hasNextPage
          total
        }
      }
    }
  }
`;

export const GET_ALL_USERSDOCUMENTS = gql`
  query GetAllUserDocuments {
    getAllUserDocuments {
      id
      user {
        id
        email
      }
      documents {
        id
        documentName
        documentUrl
        issuedDate
        documentCategory
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_USER_DOCUMENT_CATEGORIES = gql`
  query Error {
    getUserDocumentCategories {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUserDocumentCategories {
        message
        data {
          label
          name
        }
      }
    }
  }
`;

export const GET_USER_DOCUMENT_BY_ID = gql`
  query GetUserDocumentById($documentId: ID!) {
    getUserDocumentById(documentId: $documentId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUserDocument {
        data {
          id
          documentName
          documentUrl
          issuedDate
          documentCategory
          isActive
          createdAt
          updatedAt
        }
      }
    }
  }
`;
