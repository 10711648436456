import {gql} from '@apollo/client';

export const CREATE_REPORT = gql`
  mutation CreateReport($data: CreateReportInput!) {
    createReport(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on Report {
        title
        createdBy
        document
        type
        linkType
        sections {
          sectionName
          sequence
          content
        }
        relatedCompany {
          id
          name
        }
        relatedTopics {
          id
          name
        }
        relatedCountries {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_REPORT = gql`
  mutation UpdateReport($data: UpdateReportInput!) {
    updateReport(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on Report {
        id
        title
        createdBy
        linkType
      }
    }
  }
`;

export const DELETE_REPORT = gql`
  mutation DeleteReport($reportId: ID!) {
    deleteReport(reportId: $reportId) {
      ... on Error {
        message
        statusCode
      }
      ... on Report {
        id
        title
        createdBy
        document
      }
    }
  }
`;
