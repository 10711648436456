import {Form, Input, InputNumber, Select} from 'antd';
import {Fragment, useEffect, useState} from 'react';
import trash from 'images/trash-can-outline.svg';
import plusIcon from 'images/plus-circle.png';
import styles from './currencySettings.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useParams, useNavigate} from 'react-router-dom';
import FadeEffect from 'components/animation/fadeEffect';
import {currencyData} from 'pages/campaigns/campaignData';
import {useDispatch, useSelector} from 'react-redux';
import {
  fxTransactionRatesOnSupportedCurrencies,
  assetCurrency,
} from 'redux/store/campaign';

const CurrencySettings = () => {
  const [baseCurrency, setBaseCurrency] = useState({
    name: 'United States Dollar',
    symbol: '$',
    currencyCode: 'USD',
  });
  const initialCurrencySate = {
    currency: {
      name: 'United States Dollar',
      symbol: '$',
      currencyCode: 'USD',
    },
    currencyConversionMode: '',
    fixedFxRateToAssetCurrency: 0.0,
    variableFxRateToAssetCurrency: 0.0,
  };
  const [currencySettings, setCurrencySettings] = useState([
    initialCurrencySate,
  ]);
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(false);
  const {id, companyId} = useParams();
  const dispatch = useDispatch();
  const {Option} = Select;

  const campaign = useSelector(state => state.campaign.campaign);

  useEffect(() => {
    if (campaign?.fxTransactionRatesOnSupportedCurrencies) {
      setCurrencySettings(
        campaign?.fxTransactionRatesOnSupportedCurrencies?.map(data => ({
          currency: {
            currencyCode: data?.currency?.currencyCode,
            name: data?.currency?.name,
            symbol: data?.currency?.symbol,
          },
          currencyConversionMode: data?.currencyConversionMode,
          fixedFxRateToAssetCurrency: data?.fixedFxRateToAssetCurrency || 0.0,
          variableFxRateToAssetCurrency:
            data?.variableFxRateToAssetCurrency || 0.0,
        })),
      );
      setBaseCurrency(campaign?.assetCurrency);
    }
  }, [campaign?.fxTransactionRatesOnSupportedCurrencies]);

  useEffect(() => {
    if (currencySettings.length) {
      currencySettings?.map(data =>
        setDisableButton(!data.currencyConversionMode),
      );
    } else {
      setDisableButton(false);
    }
  }, [currencySettings]);

  const handleFieldChange = (index, field, value) => {
    const newCurrencySettings = [...currencySettings];
    newCurrencySettings[index] = {
      ...newCurrencySettings[index],
      [field]: value,
    };
    setCurrencySettings(newCurrencySettings);
  };

  const handleBaseCurrencyChange = i => {
    setBaseCurrency(currencyData[i]);
  };

  const handleAddSettings = () => {
    setCurrencySettings([...currencySettings, initialCurrencySate]);
  };

  const handleDeleteSettings = id => {
    const filteredFormValue = currencySettings.filter(
      (item, index) => index !== id,
    );
    setCurrencySettings(filteredFormValue);
  };

  const handleSave = () => {
    dispatch(
      fxTransactionRatesOnSupportedCurrencies({
        fxTransactionRatesOnSupportedCurrencies: currencySettings,
      }),
      assetCurrency({
        assetCurrency: baseCurrency,
      }),
    );
    navigate(`/dashboard/campaigns/new/${id}/settings/${companyId}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Currency Settings</h1>
      </div>
      <div>
        <Form onFinish={handleSave} layout="vertical">
          <div className={styles['question-style']}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Base currency is required',
                },
              ]}
              style={{width: 320, marginRight: 20}}
              label="Base Currency"
              name="name"
              initialValue={campaign?.assetCurrency?.name}>
              <Select
                value={baseCurrency?.name || ''}
                placeholder="Select a currency"
                onChange={e => handleBaseCurrencyChange(e)}
                name="name"
                defaultValue={campaign?.assetCurrency?.name}>
                {currencyData.map((item, i) => (
                  <Option key={i}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          {currencySettings.map((element, index) => (
            <Fragment key={index}>
              <FadeEffect>
                <div className={styles['question-style']}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Currency name is required',
                      },
                    ]}
                    style={{width: 320, marginRight: 20}}
                    label="Currency Name"
                    name={`${index}-name`}
                    initialValue={element?.currency?.name}>
                    <Select
                      value={element.currency.name || ''}
                      placeholder="Select a currency"
                      onChange={e =>
                        handleFieldChange(index, 'currency', currencyData[e])
                      }>
                      {currencyData.map((item, i) => (
                        <Option key={i}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item style={{width: 320}} label="Currency Code">
                    <Input
                      value={element.currency.currencyCode || ''}
                      placeholder="Currency code"
                      readOnly
                    />
                  </Form.Item>
                </div>
                <div className={styles['question-style']}>
                  <Form.Item
                    style={{width: 320, marginRight: 20}}
                    label="Currency Symbol">
                    <Input
                      value={element.currency.symbol || ''}
                      placeholder="Currency Symbol"
                      readOnly
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Conversion mode is required',
                      },
                    ]}
                    style={{width: 320}}
                    label="Currency Conversion Mode"
                    name={`currencyConversionMode-${index}`}
                    initialValue={element.currencyConversionMode}>
                    <Select
                      value={element.currencyConversionMode || ''}
                      defaultValue={element.currencyConversionMode || ''}
                      onChange={e =>
                        handleFieldChange(index, 'currencyConversionMode', e)
                      }
                      placeholder="Select conversion mode">
                      <Option value="FIXED">Fixed</Option>
                      <Option value="VARIABLE">Variable</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className={styles['question-style']}>
                  {element.currencyConversionMode === 'FIXED' ? (
                    <Form.Item
                      rules={[
                        {
                          required: element.currencyConversionMode === 'FIXED',
                          message: 'Fixed FX rate is required',
                        },
                      ]}
                      style={{width: 320, marginRight: 20}}
                      label="Fixed FX Rate"
                      name={`${index}-fixedFxRateToAssetCurrency`}>
                      <InputNumber
                        type="number"
                        value={element.fixedFxRateToAssetCurrency}
                        onChange={e =>
                          handleFieldChange(
                            index,
                            'fixedFxRateToAssetCurrency',
                            e,
                          )
                        }
                        placeholder="Fixed FX Rate"
                        className={styles.inputNumber}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      rules={[
                        {
                          required:
                            element.currencyConversionMode === 'VARIABLE',
                          message: 'Variable FX rate is required',
                        },
                      ]}
                      style={{width: 320}}
                      label="Markup Fee (%)"
                      name={`${index}-variableFxRateToAssetCurrency`}>
                      <InputNumber
                        type="number"
                        value={element.variableFxRateToAssetCurrency}
                        onChange={e =>
                          handleFieldChange(
                            index,
                            'variableFxRateToAssetCurrency',
                            e,
                          )
                        }
                        placeholder="Variable FX Rate"
                        className={styles.inputNumber}
                      />
                    </Form.Item>
                  )}
                  <img
                    src={trash}
                    onClick={() => handleDeleteSettings(index)}
                    alt=""
                  />
                </div>
              </FadeEffect>
            </Fragment>
          ))}
        </Form>
        <div onClick={handleAddSettings} className={styles['add-risks']}>
          <img src={plusIcon} alt="add-settings" />
          <span>Add Settings</span>
        </div>
        <SubmitButton
          disabled={disableButton}
          label="Save & Continue"
          handleClick={handleSave}
        />
      </div>
    </div>
  );
};

export default CurrencySettings;
