import styles from './draggableTable.module.scss';
import {Table} from 'antd';
import {arrayMoveImmutable} from 'array-move';
import {useState} from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {UPDATE_TOPIC} from 'graphql/mutations/interest';
import {useMutation} from '@apollo/client';
import {toast} from 'react-toastify';

const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

const DraggableTable = ({columns, data}) => {
  const [dataSource, setDataSource] = useState([]);

  const [updateTopic] = useMutation(UPDATE_TOPIC);
  const handleUpdate = ({id, name, sequence}) => {
    updateTopic({
      variables: {
        input: {
          topicId: id,
          name,
          sequence,
        },
      },
      update(cache) {
        cache.modify({
          fields: {
            getAllTopics() {},
          },
        });
      },
    }).then(({data: {updateTopic}}) => {
      if (updateTopic.__typename === 'Error') {
        toast.error(updateTopic.message);
      } else {
        toast.success('Interest Updated Successfully');
        window.location.reload();
      }
    });
  };

  const checkToUpdateRow = async (oldIndex, newIndex) => {
    const row = data[oldIndex];
    const oldToNewIndex = newIndex > oldIndex ? newIndex : newIndex - 1;
    const oldToNew = newIndex > 0 ? data[oldToNewIndex] : null;
    if (oldToNew && !oldToNew.sequence) {
      throw new Error();
    }
    const payload = {
      id: row.id,
      name: row.name,
      sequence: newIndex > 0 ? oldToNew.sequence + 1 : 1,
    };
    await handleUpdate(payload);
  };

  const onSortEnd = async ({oldIndex, newIndex}) => {
    if (oldIndex !== newIndex) {
      try {
        await checkToUpdateRow(oldIndex, newIndex);
        const newData = arrayMoveImmutable(
          data.slice(),
          oldIndex,
          newIndex,
        ).filter(el => !!el);
        setDataSource(newData);
      } catch (error) {
        toast.error("Can't update sequence of null");
      }
    }
  };

  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass={styles['row-dragging']}
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({...restProps}) => {
    const index = data.findIndex(x => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Table
      dataSource={dataSource?.length ? dataSource : data}
      columns={columns}
      rowKey="id"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};

export {DraggableTable};
