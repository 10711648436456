import {useLazyQuery, useQuery} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {Spin} from 'antd';
import {GET_ALL_NEWS, GET_NEWS_BY_SEARCH_PARAM} from 'graphql/queries/news';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import styles from './news.module.scss';
import NewsCard from './newsCard/NewsCard';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const News = () => {
  const [nextPage, setNextPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [newsData, setNewsData] = useState([]);
  const navigate = useNavigate();

  const {data, loading, error} = useQuery(GET_ALL_NEWS, {
    variables: {
      limit: 10,
      pageNumber: nextPage,
    },
    onCompleted: data => setNewsData(data?.getAllNews?.news),
  });

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.News),
  );

  const [searchUsers, {loading: loadingSearch}] = useLazyQuery(
    GET_NEWS_BY_SEARCH_PARAM,
  );

  useEffect(() => {
    if (searchTerm === '') {
      setNewsData(data?.getAllNews?.news);
    }
  }, [data?.getAllNews?.news, searchTerm]);

  const handleSearch = () => {
    setNextPage(1);
    searchUsers({
      variables: {
        searchTerm,
      },
      onCompleted: data => setNewsData(data?.getNewsBySearchTerm?.news),
    });
  };

  if (error) {
    toast.error(error[0].message);
  }

  const handleBackPage = () => {
    if (nextPage > 1) setNextPage(nextPage - 1);
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Incoming News ({`${newsData?.length ?? 0}`})</h1>
        <div className={styles['search-grid']}>
          <div className={styles['search-field']}>
            <input
              type="search"
              className={styles['']}
              placeholder="Search News by Topic"
              value={searchTerm}
              onKeyDown={e => {
                if (e.key === 'Enter') handleSearch();
              }}
              onChange={e => setSearchTerm(e.target.value)}
            />
            <span onClick={handleSearch}>Search</span>
          </div>
          <Button
            onClick={() => navigate('/dashboard/news/add-news')}
            label="Add News Manually"
            disabled={!tabOperations?.create}
            className={styles.button}
          />
        </div>
      </div>
      <div>
        <div className={styles['nav-buttons']}>
          <Button
            onClick={handleBackPage}
            label="Previous"
            type="secondary"
            disabled={searchTerm && true}
            className={styles.button}
          />
          <div className={styles['page-number']}>{nextPage}</div>
          <Button
            onClick={() => setNextPage(nextPage + 1)}
            label="Next"
            disabled={searchTerm && true}
            className={styles.button}
          />
        </div>
        {newsData?.length > 0 && !loadingSearch ? (
          <>
            {newsData?.map(
              (
                {
                  title,
                  featuredImage,
                  publishedDate,
                  keywords,
                  topics,
                  link,
                  id,
                  companies,
                  newsSource,
                  isBreakingNews,
                  isSelectedNews,
                  isTrendingNews,
                },
                index,
              ) => (
                <div key={index}>
                  <NewsCard
                    title={title}
                    newsSource={newsSource}
                    image={featuredImage}
                    link={link}
                    date={publishedDate}
                    id={id}
                    operations={tabOperations}
                    selectedNews={isSelectedNews}
                    breakingNews={isBreakingNews}
                    trendingNews={isTrendingNews}
                    companies={companies}
                    keywords={keywords}
                    topics={topics}
                  />
                </div>
              ),
            )}
          </>
        ) : loading || loadingSearch ? (
          <div className={styles['not-found']}>
            <div>
              <Spin size="large" />
            </div>
          </div>
        ) : (
          <div className={styles['not-found']}>
            <div>
              <h1>No Result Found</h1>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default News;
