import FadeEffect from 'components/animation/fadeEffect';
import AnalogCard from 'pages/campaigns/new/screens/analog/AnalogCard';
import {
  resizeImageFile,
  UploadDocumentToBucket,
} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import plusIcon from 'images/plus-circle.png';

import {useEffect, useState} from 'react';
import styles from './baseAnalogs.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';

export const BaseAnalogs = ({onSubmit, existingData}) => {
  const [analogValues, setAnalogValues] = useState([]);
  const [loadingImageUpload, setLoadingImageUpload] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const isDev = process.env.REACT_APP_API_BASE_URL.includes('.daba-dev.');

  useEffect(() => {
    if (existingData && existingData?.analogsValues) {
      setAnalogValues(existingData.analogsValues);
    }
  }, [existingData]);

  useEffect(() => {
    if (analogValues.length) {
      analogValues?.map(data => {
        const isValid =
          !data.name ||
          !data.description ||
          !data.valuation ||
          !data.imageUrl ||
          !data.type;
        return setDisableButton(isValid);
      });
    } else {
      setDisableButton(false);
    }
  }, [analogValues]);

  const handleAddAnalog = () => {
    setAnalogValues([
      ...analogValues,
      {
        name: '',
        type: '',
        description: '',
        imageUrl: '',
        previewUrl: '',
        valuation: 0,
      },
    ]);
  };

  const handleChangeImage = async (i, {file}) => {
    const temp = file.originFileObj;
    const newFormValues = [...analogValues];
    const resizedFile = await resizeImageFile(temp);
    setLoadingImageUpload(true);
    const res = await UploadDocumentToBucket(
      resizedFile,
      // eslint-disable-next-line no-undef
      _,
      isDev
        ? 'dabafinancialinstruments-nonprod'
        : 'dabafinancialinstruments-prod',
      true,
      'bonds',
    );
    setLoadingImageUpload(false);
    newFormValues[i].previewUrl = res;
    newFormValues[i].imageUrl = res;
    setAnalogValues(newFormValues);
  };

  const handleChangeCompanyType = (index, event) => {
    const newFormValues = [...analogValues];
    newFormValues[index].type = event;
    setAnalogValues(newFormValues);
  };

  const handleDeleteAnalog = id => {
    const filteredFormValue = analogValues.filter(
      (data, index) => index !== id,
    );
    setAnalogValues(filteredFormValue);
  };

  const handleChangeNumberFields = (i, e) => {
    let newFormValues = [...analogValues];
    newFormValues[i].valuation = e;
    setAnalogValues(newFormValues);
  };

  const handleChangeFields = (i, e) => {
    const newFormValues = [...analogValues];
    newFormValues[i][e.target.name] = e.target.value;
    setAnalogValues(newFormValues);
  };

  const handleDeleteImage = i => {
    const newFormValues = [...analogValues];
    newFormValues[i].previewUrl = '';
    newFormValues[i].imageUrl = '';
    setAnalogValues(newFormValues);
  };

  return (
    <div className={styles.analog}>
      <div className={styles.container}>
        <div>
          <h2>Instrument Analogs</h2>
        </div>
        <div>
          {analogValues?.map((data, index) => (
            <FadeEffect key={index}>
              <AnalogCard
                id={index}
                name={data.name}
                type={data.type}
                valuation={data.valuation}
                imgUrl={data.imageUrl}
                loadingImageUpload={loadingImageUpload}
                previewImage={data.previewUrl}
                description={data.description}
                disableButton={disableButton}
                handleNumberChange={e => handleChangeNumberFields(index, e)}
                handleDeleteAnalog={() => handleDeleteAnalog(index)}
                handleDeleteImage={() => handleDeleteImage(index)}
                handleChange={e => handleChangeFields(index, e)}
                handleChangeImage={e => handleChangeImage(index, e)}
                handleChangeCompanyType={e => handleChangeCompanyType(index, e)}
              />
            </FadeEffect>
          ))}
        </div>
        {disableButton && (
          <span className={styles['warning']}>
            Please Note that all fields are required
          </span>
        )}

        <div onClick={handleAddAnalog} className={styles['add-button']}>
          <img src={plusIcon} alt="" />
          <h1>Add Analog</h1>
        </div>
        <SubmitButton
          disabled={disableButton}
          label="Save & Continue"
          handleClick={() =>
            onSubmit({
              analogsValues: analogValues.length > 0 ? analogValues : null,
            })
          }
        />
      </div>
    </div>
  );
};
