import {
  DatePicker,
  Form,
  Input,
  Select,
  Spin,
  Switch,
  Tabs,
  Tooltip,
} from 'antd';
import styles from './createStockExchnageDocument.module.scss';
import backImg from 'images/back.png';
import {useMutation, useQuery} from '@apollo/client';
import {
  GET_STOCK_EXCHANGE_DOCUMENT_BY_ID,
  GET_STOCK_EXCHANGE_DOCUMENT_QUERIES,
  SEARCH_SEEDED_COMPANY_DOCUMENTS,
} from 'graphql/queries/stocks';
import {toast} from 'react-toastify';
import {formatRoleString} from 'utils/helper';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {QuestionCircleOutlined} from '@ant-design/icons';
import _ from 'lodash';
import CompanyPDFUpload from 'pages/documentCenter/create/CompanyPDFUpload';
import {defaultLanguages} from 'pages/news/newsTypes';
import TranslatedStockExchangeDocument from './TranslatedStockExchangeDocument';
import {Button} from '@dabafinance/react-components';
import {requestCompanyDocumentTranslation} from 'redux/store/news/actions';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {
  CREATE_STOCK_EXCHANGE_DOCUMENT,
  UPDATE_STOCK_EXCHANGE_DOCUMENT,
} from 'graphql/mutations/stocks';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useNavigate, useParams} from 'react-router-dom';

const CreateStockExchangeDocument = () => {
  const [form] = Form.useForm();
  const [limit, setLimit] = useState(50);
  const [searchResult, setSearchResult] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentName, setDocumentName] = useState('');
  const [urlType, setUrlType] = useState('UPLOADED');
  const [language, setLanguage] = useState('FR');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [pdfFile, setPdfFile] = useState(null);
  const [viewPDF, setViewPDF] = useState(null);
  const [pdfName, setPdfName] = useState('');
  const [translatedData, setTranslatedData] = useState([]);
  const navigate = useNavigate();
  const {id} = useParams();

  const {data, loading} = useQuery(GET_STOCK_EXCHANGE_DOCUMENT_QUERIES, {
    errorPolicy: 'all',
    onError: () =>
      toast.error('Could not fetch Stock Exchange Document Categories'),
  });
  const {data: existingStockData, loading: loadingExistingStockData} = useQuery(
    GET_STOCK_EXCHANGE_DOCUMENT_BY_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        documentId: id,
      },
    },
  );
  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );
  const [addStockExchangeDocument, {loading: loadingCreate}] = useMutation(
    CREATE_STOCK_EXCHANGE_DOCUMENT,
    {
      errorPolicy: 'all',
    },
  );
  const [updateStockExchangeDocument, {loading: loadingUpdate}] = useMutation(
    UPDATE_STOCK_EXCHANGE_DOCUMENT,
    {
      errorPolicy: 'all',
    },
  );

  const languageToBeTranslatedTo = defaultLanguages.filter(
    key => key.key !== language,
  );

  useEffect(() => {
    if (existingStockData?.getOneStockExchangeDocument) {
      const {
        documentName,
        documentUrl,
        restrictLanguage,
        language,
        multilang,
        stockExchange,
        documentCategory,
        issuedDate,
        documentUrlSource,
      } = existingStockData?.getOneStockExchangeDocument?.data;
      setDocumentName(documentName);
      setSelectedDocument(documentUrl);
      setLanguage(language);
      setUrlType(documentUrlSource);
      setViewPDF(documentUrl);
      setPdfFile(documentUrl);
      setPdfName(documentName);
      form.setFieldsValue({
        restrictLanguage,
        documentCategory,
        language,
        document: documentName,
        stockId: stockExchange?.id,
        documentName,
        issuedDate: issuedDate ? moment(issuedDate, 'YYYY-MM-DD HH:mm:ss') : '',
        type: documentUrlSource,
      });
      setTranslatedData(
        multilang?.map(data => ({
          documentName: data?.documentName,
          documentUrl:
            data?.documentUrlSource === 'SEEDED' ? data?.documentUrl : null,
          documentUrlSource: data?.documentUrlSource,
          pdfFile:
            data?.documentUrlSource === 'UPLOADED' ? data?.documentUrl : null,
          pdfName: data?.documentName,
          language: data?.language,
        })),
      );
    }
  }, [existingStockData?.getOneStockExchangeDocument]);

  const removePrefix = documentName => {
    const prefix = 'daba-finance-company-report-';
    return documentName.startsWith(prefix)
      ? documentName.substring(prefix.length)
      : documentName;
  };

  const {
    loading: loadingSeededDocuments,
    refetch,
    networkStatus,
    fetchMore,
  } = useQuery(SEARCH_SEEDED_COMPANY_DOCUMENTS, {
    variables: {
      filters: {
        isAddedToDocumentCenter: false,
        language,
        pagination: {
          limit,
        },
      },
    },
    onCompleted: data =>
      setSearchResult(data?.getSeededStockExchangeDocuments?.documents),
    notifyOnNetworkStatusChange: true,
  });

  const debouncedSetSearchValue = _.debounce(value => {
    const searchByTicker = {};
    if (value !== '') {
      searchByTicker.companyTickers = [value.toUpperCase()];
    }
    refetch({
      filters: {
        ...searchByTicker,
        isAddedToDocumentCenter: false,
        language: language,
        pagination: {
          limit,
          page: 1,
        },
      },
    });
  }, 1200);

  const onScroll = event => {
    const target = event.target;
    if (
      !loadingSeededDocuments &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const newLimit = limit + 50;
      setLimit(newLimit);
      fetchMore({
        variables: {
          filters: {
            isAddedToDocumentCenter: false,
            language: language,
            pagination: {
              limit: newLimit,
            },
          },
        },
      });
    }
  };

  const handleTranslation = async () => {
    const newValues = [...translatedData];

    if (!language && !documentName) {
      return toast.error(
        'Please make sure none of the necessary fields needed for translation are empty',
      );
    }

    const translateProps = {
      sourceLang: language,
      targetLang: selectedLanguage,
      targetText1: documentName,
    };
    if (newValues.length && newValues[0]) {
      const languageExists = newValues.some(
        data => data?.language === selectedLanguage,
      );
      if (languageExists) {
        const updateTranslation = await requestCompanyDocumentTranslation(
          translateText,
          translateProps,
        );
        const translationIndex = newValues.findIndex(
          data => data.language === selectedLanguage,
        );
        newValues[translationIndex].documentName =
          updateTranslation.documentName;
        newValues[translationIndex].language = updateTranslation.language;
        setTranslatedData(newValues);
      }
    } else {
      let triggerNewTranslation = await requestCompanyDocumentTranslation(
        translateText,
        translateProps,
      );
      if (!triggerNewTranslation) {
        return toast.error('Something went wrong when Translating');
      }
      if (!triggerNewTranslation.documentUrlSource) {
        triggerNewTranslation.documentUrlSource = 'UPLOADED';
      }
      newValues.push(triggerNewTranslation);
      setTranslatedData(newValues);
    }
  };

  const handleSubmit = values => {
    if (urlType === 'UPLOADED' && !pdfFile) {
      return toast.error('Please Upload a PDF FILE');
    }
    const resolver = id
      ? updateStockExchangeDocument
      : addStockExchangeDocument;
    resolver({
      variables: {
        data: {
          ...(id && {documentId: id}),
          documentCategory: values.documentCategory,
          stockExchange: values?.stockId,
          documentName: documentName,
          documentUrl: urlType === 'UPLOADED' ? pdfFile : selectedDocument,
          documentUrlSource: urlType,
          issuedDate: moment(values.issuedDate).format(),
          language: values?.language,

          restrictLanguage:
            values?.restrictLanguage != null ? values?.restrictLanguage : false,
          ...(translatedData.length && {
            multilang: translatedData?.map(data => ({
              documentUrl:
                data?.documentUrlSource === 'SEEDED'
                  ? data?.documentUrl
                  : data?.pdfFile,
              documentName: data?.documentName,
              documentUrlSource: data?.documentUrlSource,
              language: data?.language,
            })),
          }),
        },
      },
    })
      .then(({data}) => {
        const type =
          data?.[
            id ? 'updateStockExchangeDocument' : 'addStockExchangeDocument'
          ];
        if (type.__typename === 'Error') {
          return toast.error(type.message);
        } else {
          toast.success(`Document ${id ? 'Updated' : 'Created'} Successfully`);
          navigate('/dashboard/startup/document-category?route=stocks');
        }
      })
      .catch(error => toast.error(error));
  };

  if (loadingExistingStockData && id) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading Document...</h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <img
          onClick={() =>
            navigate('/dashboard/startup/document-category?route=stocks')
          }
          src={backImg}
        />
        <h1>{id ? 'Update' : 'Create'} Stock Exchange Document</h1>
      </div>
      <div className={styles.content}>
        <Form onFinish={handleSubmit} form={form} layout="vertical">
          <Form.Item
            rules={[{required: true, message: 'This field is Required'}]}
            name="stockId"
            className={styles.fieldWidth}
            label="Select Stock Exchange">
            <Select loading={loading}>
              {data?.getAllStocksExchange?.map(data => (
                <Select.Option key={data?.id}>
                  {data?.stockExchangeName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'This field is Required'}]}
            name="documentCategory"
            className={styles.fieldWidth}
            label="Document Category">
            <Select loading={loading}>
              {data?.getStockExchangeDocumentCategory?.data?.map(data => (
                <Select.Option key={data?.label}>
                  {formatRoleString(data?.name)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className={styles.date}>
            <Form.Item name="issuedDate" label="Issued Date">
              <DatePicker
                format={'YYYY-MM-DD HH:mm:ss'}
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm:ss'),
                }}
              />
            </Form.Item>
            <Form.Item name="restrictLanguage" label="Restrict Language">
              <Switch
                defaultChecked={
                  existingStockData?.getOneStockExchangeDocument?.data
                    ?.restrictLanguage
                }
              />
            </Form.Item>
          </div>
          <Form.Item
            rules={[{required: true, message: 'This field is Required'}]}
            name="language"
            className={styles.fieldWidth}
            label="Base Language">
            <Select value={language} onChange={setLanguage}>
              <Select.Option key="EN">English</Select.Option>
              <Select.Option key="FR">French</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Translation Language"
            name="translationLanguage"
            className={styles.fieldWidth}>
            <Select
              value={selectedLanguage}
              allowClear
              onChange={e => setSelectedLanguage(e)}
              placeholder="Select a Language to be translated">
              {languageToBeTranslatedTo.map(name => (
                <Select.Option key={name.key}>{name.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Tabs
            style={{width: 700}}
            tabBarExtraContent={
              selectedLanguage && (
                <Button
                  label={loadingTranslation ? 'Translating...' : 'Translate'}
                  disabled={loadingTranslation || !documentName}
                  onClick={handleTranslation}
                />
              )
            }>
            <Tabs.TabPane key="original" tab="Original">
              <Form.Item
                rules={[{required: true, message: 'This field is Required'}]}
                name="documentName"
                className={styles.fieldWidth}
                label="Document Name">
                <Input
                  value={documentName}
                  onChange={e => setDocumentName(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                className={styles.fieldWidth}
                name="type"
                rules={[{required: true, message: 'This field is Required'}]}
                label="Link Type">
                <Select
                  defaultValue={'UPLOADED'}
                  onChange={setUrlType}
                  value={urlType}>
                  <Select.Option key="UPLOADED">Upload PDF</Select.Option>
                  <Select.Option key="SEEDED">Seeded Document</Select.Option>
                </Select>
              </Form.Item>
              {urlType === 'SEEDED' ? (
                <>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Please Search Based on Document Ticker',
                      },
                    ]}
                    className={styles.fieldWidth}
                    name="document"
                    label={
                      <span>
                        Search (By TICKER) & Select Document{' '}
                        <Tooltip
                          title={
                            'NOTE: You cannot Search by Document Name, Also it is filtered based on the Selected Language'
                          }>
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </span>
                    }>
                    <Select
                      onSearch={e => debouncedSetSearchValue(e)}
                      onChange={e => {
                        const parseValue = JSON.parse(e);
                        setSelectedDocument(parseValue?.documentLink);
                      }}
                      value={selectedDocument}
                      showSearch
                      onPopupScroll={onScroll}
                      filterOption={false}
                      placeholder="Search is based on Company Ticker">
                      {searchResult.length &&
                        searchResult?.map(data => (
                          <Select.Option key={JSON.stringify(data)}>
                            <span>{removePrefix(data?.documentName)}</span>
                          </Select.Option>
                        ))}
                      {(networkStatus === 2 || loading) && (
                        <Select.Option disabled={true} key="Loading..." />
                      )}
                    </Select>
                  </Form.Item>
                  <CompanyPDFUpload
                    onlyButtons={true}
                    type={'SINGLE'}
                    pdfFile={selectedDocument}
                    viewPdf={selectedDocument}
                  />
                </>
              ) : (
                <CompanyPDFUpload
                  title="Upload Stock Exchange Document (PDF)"
                  existingData={viewPDF}
                  pdfName={pdfName || 'Supported format: pdf'}
                  setPdfName={setPdfName}
                  setSinglePDFFile={setPdfFile}
                  setViewPDFLink={setViewPDF}
                  type="SINGLE"
                  viewPdf={viewPDF}
                  pdfFile={pdfFile}
                  handleDeleteFile={() => {
                    setPdfFile(null);
                    setPdfName('Supported format: pdf');
                    setViewPDF(null);
                  }}
                  index={'original'}
                />
              )}
            </Tabs.TabPane>
            {translatedData?.map((item, index) => (
              <Tabs.TabPane
                tab={
                  languageToBeTranslatedTo.find(
                    lang => lang?.key === item?.language,
                  )?.label
                }
                key={index}>
                <TranslatedStockExchangeDocument
                  index={index}
                  data={item}
                  setTranslatedData={setTranslatedData}
                  translatedData={translatedData}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
          <SubmitButton
            disabled={loadingCreate || loadingUpdate}
            label={id ? 'Update' : 'Create'}
          />
        </Form>
      </div>
    </div>
  );
};

export default CreateStockExchangeDocument;
