/* eslint-disable react/jsx-key */
import {Button} from '@dabafinance/react-components';
import {Form, Input, Select, Spin, Switch} from 'antd';
import {useEffect, useState} from 'react';
import styles from './investment.module.scss';
import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import {GET_INVESTOR_PROFILE} from 'graphql/queries/users';
import {
  InvestingExperienceEnum,
  investmentPreferenceEnum,
  investmentHorizonEnum,
  investmentAffiliationEnum,
} from 'utils/mock';
import {Option} from 'antd/lib/mentions';

const InvestmentDetails = () => {
  const {id} = useParams();
  const [investmentExperience, setInvestmentExperience] = useState('');
  const [investmentPreference, setInvestmentPreference] = useState('');
  const [investmentHorizon, setInvestmentHorizon] = useState('');
  const [investmentAffiliation, setInvestmentAffiliation] = useState('');

  const {data, loading} = useQuery(GET_INVESTOR_PROFILE, {
    variables: {
      getInvestorProfileId: id,
    },
  });

  useEffect(() => {
    setInvestmentExperience(
      data?.getInvestorProfile?.investorProfile?.riskAppetite
        ?.investmentExperience,
    );
    setInvestmentPreference(
      data?.getInvestorProfile?.investorProfile?.riskAppetite
        ?.investmentPreference,
    );
    setInvestmentHorizon(
      data?.getInvestorProfile?.investorProfile?.riskCapacity
        ?.investmentHorizon,
    );
    setInvestmentAffiliation(
      data?.getInvestorProfile?.investorProfile?.affiliations,
    );
  }, [data?.getInvestorProfile?.investorProfile]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  const width = 420;
  return (
    <div className={styles.container}>
      <div>
        <h1 className={styles.header}>Investment Details</h1>
      </div>
      <div>
        <Form layout="vertical" className={styles['invest-form']}>
          <Form.Item
            style={{width}}
            name="investmentExperience"
            label="Investment Experience">
            <Select
              defaultValue={
                data?.getInvestorProfile?.investorProfile?.riskAppetite
                  ?.investmentExperience
              }
              value={investmentExperience}
              onChange={e => setInvestmentExperience(e)}>
              {InvestingExperienceEnum.map(({name, key}) => (
                <Option value={key}>{name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            style={{width}}
            label="How do you see yourself investing?"
            name="investmentPreference">
            <Select
              defaultValue={
                data?.getInvestorProfile?.investorProfile?.riskAppetite
                  ?.investmentPreference
              }
              value={investmentPreference}
              onChange={e => setInvestmentPreference(e)}>
              {investmentPreferenceEnum.map(({name, key}) => (
                <Option key={key}>{name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            style={{width}}
            label="When would you need to take out your investment?"
            name="investmentHorizon">
            <Select
              defaultValue={
                data?.getInvestorProfile?.investorProfile?.riskCapacity
                  ?.investmentHorizon
              }
              value={investmentHorizon}
              onChange={e => setInvestmentHorizon(e)}>
              {investmentHorizonEnum.map(({name, key}) => (
                <Option key={key}>{name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            style={{width}}
            label="What applies to you?"
            name="investmentAffiliation">
            <Select
              defaultValue={
                data?.getInvestorProfile?.investorProfile?.affiliations
              }
              value={investmentAffiliation}
              onChange={e => setInvestmentAffiliation(e)}>
              {investmentAffiliationEnum.map(({name, key}) => (
                <Option value={key}>{name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            style={{width}}
            label="Subscription Plan"
            name="subscription">
            <Input placeholder="Standard" />
          </Form.Item>
          <Form.Item
            style={{width}}
            label="What areas do you most want to grow?"
            name="investmentGoals">
            <div className={styles.checker}>
              <p>Building better budgeting habits</p>{' '}
              <Switch
                defaultChecked={
                  data?.getInvestorProfile?.investorProfile?.riskNeed
                    ?.investmentGoals.betterBudgetting
                }
              />
            </div>
            <div className={styles.checker}>
              <p>Earn stocks rewards</p>{' '}
              <Switch
                defaultChecked={
                  data?.getInvestorProfile?.investorProfile?.riskNeed
                    ?.investmentGoals.stockRewards
                }
              />
            </div>
            <div className={styles.checker}>
              <p>Save for the unexpected</p>{' '}
              <Switch
                defaultChecked={
                  data?.getInvestorProfile?.investorProfile?.riskNeed
                    ?.investmentGoals.emergencySavings
                }
              />
            </div>
            <div className={styles.checker}>
              <p>Save for Retirement</p>{' '}
              <Switch
                defaultChecked={
                  data?.getInvestorProfile?.investorProfile?.riskNeed
                    ?.investmentGoals.retirementSavings
                }
              />
            </div>
            <div className={styles.checker}>
              <p>Invest and build wealth</p>{' '}
              <Switch
                defaultChecked={
                  data?.getInvestorProfile?.investorProfile?.riskNeed
                    ?.investmentGoals.wealthBuilding
                }
              />
            </div>
            <div className={styles.checker}>
              <p>Get financial advice and education</p>{' '}
              <Switch
                defaultChecked={
                  data?.getInvestorProfile?.investorProfile?.riskNeed
                    ?.investmentGoals.education
                }
              />
            </div>
            <div className={styles.checker}>
              <p>Social Impact</p>{' '}
              <Switch
                defaultChecked={
                  data?.getInvestorProfile?.investorProfile?.riskNeed
                    ?.investmentGoals.socialImpact
                }
              />
            </div>
          </Form.Item>
          <Button label="Save Changes" className={styles.button} />
        </Form>
      </div>
    </div>
  );
};

export default InvestmentDetails;
