import {gql} from '@apollo/client';

export const UPDATE_DIVIDEND_PAYOUT_AMOUNT = gql`
  mutation UpdateDividendPayoutAmount($input: UpdatePayoutAmountInput!) {
    updateDividendPayoutAmount(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithDividend {
        message
      }
    }
  }
`;

export const PROCESS_ONE_DIVIDEND_PAYOUT = gql`
  mutation ProcessOneDividendPayout($processOneDividendPayoutId: ID!) {
    processOneDividendPayout(id: $processOneDividendPayoutId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithDividend {
        message
      }
    }
  }
`;

export const CREDIT_OR_DEBIT_USER_PAYOUT = gql`
  mutation CreditOrDebitUserPaidDividend(
    $input: CreditOrDebitPayoutAmountInput!
  ) {
    creditOrDebitUserPaidDividend(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithDividend {
        message
      }
    }
  }
`;

export const PAYOUT_ALL_USERS_DIVIDEND = gql`
  mutation ProcessDividendPayouts($companyId: ID!) {
    processDividendPayouts(companyId: $companyId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithInitiateDividendPayout {
        message
      }
    }
  }
`;

export const DELETE_USER_FROM_PAYOUT = gql`
  mutation DeleteDividendPayoutById($deleteDividendPayoutByIdId: ID!) {
    deleteDividendPayoutById(id: $deleteDividendPayoutByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithDividend {
        message
      }
    }
  }
`;
