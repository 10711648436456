import {gql} from '@apollo/client';

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($data: UpdateUserProfileInput!) {
    updateProfile(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on UserType {
        user {
          id
          email
          notificationPreferences {
            emailNotifications {
              receiveDailyInvestorUpdates
              receiveOtherEmailNotifications
            }
            pushNotifications {
              receiveDailyInvestorUpdates
              receiveOtherPushNotifications
            }
            smsNotifications {
              receiveDailyInvestorUpdates
              receiveOtherSmsNotifications
            }
          }
          firstName
        }
      }
    }
  }
`;
