import {useQuery} from '@apollo/client';
import {Spin} from 'antd';
import DBModal from 'components/modal/modal';
import {GET_HOME_OVERVIEW_DATA} from 'graphql/queries/users';
import PercentageCard from 'pages/adminUser/card/PercentageCard';
import {useState} from 'react';
import {toast} from 'react-toastify';
import styles from './home.module.scss';
import UserVerification from './users/UserVerification';
import {UsersChart} from './UsersChart/UsersChart';

const AdminDashboardHome = () => {
  const [openChartModal, setOpenChartModal] = useState(false);
  const {loading, data, error} = useQuery(GET_HOME_OVERVIEW_DATA, {
    errorPolicy: 'ignore',
  });

  if (error) {
    return toast.error('Something went wrong, Try logging in again');
  }

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <h1>Overview</h1>
        <div></div>
      </div>
      <div className={styles['percentage-box']}>
        <PercentageCard
          handleClick={() => setOpenChartModal(true)}
          active
          chart
          amount={data?.getAllUsers?.statistics?.totalUsers}
          label={'Total Number of Users'}
        />
        <PercentageCard
          amount={data?.getTotalNumberOfCompanies}
          label={'Number of startups'}
        />
        <PercentageCard amount={0} label={'Number of stocks'} postive />
        <PercentageCard
          amount={data?.getAllUsers?.statistics?.totalActiveInvestors}
          label={'Active Investors'}
          postive
        />
      </div>
      <div className={styles['overview-revenue']}>
        <div className={styles.revenue}>
          <UsersChart
            users={data?.getUsersSignupChartData}
            chartStyle="line-chart"
          />
        </div>
      </div>
      <div className={styles.users}>
        <UserVerification data={data?.getRecentUsers?.users} />
      </div>
      <DBModal
        isOpen={openChartModal}
        handleClose={() => setOpenChartModal(false)}
        content={
          <UsersChart
            users={data?.getUsersSignupChartData}
            chartStyle="modal-line-chart"
          />
        }
        width={1300}
      />
    </div>
  );
};

export {AdminDashboardHome};
