import styles from './userCard.module.scss';
import {useNavigate} from 'react-router-dom';

const UserCard = ({
  id,
  firstName,
  lastName,
  accredited,
  operations,
  imageUrl,
}) => {
  const fullName = `${firstName ?? ''} ${lastName ?? ''}`;
  const navigate = useNavigate();
  return (
    <div
      key={id}
      onClick={() =>
        operations?.update ? navigate(`/dashboard/user/${id}`) : {}
      }
      className={
        operations?.update ? styles.container : styles.disabledContainer
      }>
      <div className={styles['image-style']}>
        <img src={imageUrl} alt="" />
      </div>
      <h1>
        {fullName?.length > 20 ? fullName?.substring(0, 20) + '...' : fullName}
      </h1>
      <span>{accredited ? 'Accredited' : 'Unaccredited'}</span>
    </div>
  );
};

export default UserCard;
