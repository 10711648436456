import styles from './fallback.module.scss';
import notFound from 'images/404.png';
import {useNavigate} from 'react-router-dom';

const Fallback = () => {
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate('/dashboard');
  };

  return (
    <div className={styles['container']}>
      <div className={styles['not-found']}>
        <div className={styles['not-found-image']}>
          <img src={notFound} alt="" />
        </div>
        <h1>Oh No! Page Not Found.</h1>
        <div className={styles.text}>
          <span>
            The page you are looking for does not exist. Proceed with one of the
            actions below.
          </span>
        </div>
      </div>
      <div className={styles['button-container']}>
        <div onClick={() => navigate(-1)} className={styles['go-back']}>
          Go Back
        </div>
        <div
          onFocus
          onBlur
          onClick={() => handleRoute()}
          className={styles.home}>
          Take Me Home
        </div>
      </div>
    </div>
  );
};

export default Fallback;
