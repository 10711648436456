import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import styles from './campaignInvestors.module.scss';
import {Form, Select, Spin} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import plusIcon from 'images/plus-circle.png';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {useMutation, useQuery} from '@apollo/client';
import FadeEffect from 'components/animation/fadeEffect';
import {GET_ALL_STARTUPS} from 'graphql/queries/startupCompanies';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';
import {CoInvestorsCard} from 'pages/campaigns/new/screens/Investors/coInvestorsCard';
import {
  resizeImageFile,
  UploadDocumentToBucket,
} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';

const UpdateCampaignInvestors = () => {
  const [competitors, setCompetitors] = useState([]);
  const [coInvestorsValues, setCoInvestorsValues] = useState([
    {
      name: '',
      title: '',
      description: '',
      imageUrl: '',
      previewUrl: '',
      otherInvestments: [],
    },
  ]);
  const {id, language} = useParams();
  const {Option} = Select;
  const width = 730;

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });
  const {data: getAllCompanies, loading: loadingCompany} =
    useQuery(GET_ALL_STARTUPS);
  const [updateCampaign, {loading: updateLoading}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (campaignData?.getCampaignById?.coInvestors !== []) {
      setCoInvestorsValues(
        campaignData?.getCampaignById?.coInvestors?.map(data => ({
          name: data?.name,
          title: data?.title,
          description: data?.description,
          imageUrl: data?.imageUrl,
          previewUrl: data?.imageUrl,
          otherInvestments: data?.otherInvestments?.map(
            data => `${data?.imageUrl};${data.companyId}`,
          ),
        })),
      );
    }
    if (campaignData?.getCampaignById?.competitors) {
      setCompetitors(
        campaignData?.getCampaignById?.competitors?.map(
          data =>
            `${data?.companyName};${data?.companyLogo};${data?.companyId}`,
        ),
      );
    }
  }, [campaignData?.getCampaignById]);

  const handleAddCoInvestor = () => {
    setCoInvestorsValues([
      ...coInvestorsValues,
      {
        name: '',
        title: '',
        description: '',
        imageUrl: '',
        previewUrl: '',
        otherInvestments: [],
      },
    ]);
  };

  const handleChangeFields = (i, e) => {
    const newFormValues = [...coInvestorsValues];
    newFormValues[i][e.target.name] = e.target.value;
    setCoInvestorsValues(newFormValues);
  };

  const handleChangeImage = async (i, {file}) => {
    const temp = file.originFileObj;

    const newFormValues = [...coInvestorsValues];
    const resizedFile = await resizeImageFile(temp);
    const res = await UploadDocumentToBucket(resizedFile);
    newFormValues[i].previewUrl = res;
    newFormValues[i].imageUrl = res;
    setCoInvestorsValues(newFormValues);
  };

  const handleOtherInvestments = (i, e) => {
    const newFormValues = [...coInvestorsValues];
    newFormValues[i].otherInvestments = e;
    setCoInvestorsValues(newFormValues);
  };

  const handleDeleteImage = i => {
    const newFormValues = [...coInvestorsValues];
    newFormValues[i].previewUrl = '';
    newFormValues[i].imageUrl = '';
    setCoInvestorsValues(newFormValues);
  };

  const handleDeleteCoInvestor = id => {
    const filteredFormValue = coInvestorsValues.filter(
      (data, index) => index !== id,
    );
    setCoInvestorsValues(filteredFormValue);
  };

  const handleSave = () => {
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          coInvestors: coInvestorsValues?.map(data => ({
            name: data?.name,
            title: data?.title,
            description: data?.description,
            imageUrl: data?.imageUrl,
            otherInvestments: data?.otherInvestments?.map(data => ({
              companyId: data.split(';')[1],
              imageUrl: data.split(';')[0],
            })),
          })),
          competitors: competitors?.map(data => ({
            companyName: data.split(';')[0],
            companyLogo: data.split(';')[1],
            companyId: data.split(';')[2],
          })),
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error('Please make sure fields are not empty');
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading || loadingCompany) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.container}>
        <h1 className={styles.title}>Co-Investors &#38; Competitors</h1>
        <div>
          <h3 className={styles['label-title']}>Co-Investors</h3>
          <Form onFinish={handleSave} layout="vertical">
            <div>
              {coInvestorsValues?.map((data, index) => (
                // eslint-disable-next-line react/jsx-key
                <FadeEffect>
                  <div key={index}>
                    <CoInvestorsCard
                      name={data.name || ''}
                      title={data.title || ''}
                      description={data.description || ''}
                      otherInvestments={data.otherInvestments || []}
                      previewImage={data.previewUrl || ''}
                      imgUrl={data.imageUrl || ''}
                      handleChange={e => handleChangeFields(index, e)}
                      handleOtherInvestments={e =>
                        handleOtherInvestments(index, e)
                      }
                      handleChangeImage={e => handleChangeImage(index, e)}
                      handleDeleteImage={() => handleDeleteImage(index)}
                      handleDeleteCoInvestor={e =>
                        handleDeleteCoInvestor(index, e)
                      }
                    />
                  </div>
                </FadeEffect>
              ))}
            </div>

            <div onClick={handleAddCoInvestor} className={styles['add-button']}>
              <img src={plusIcon} alt="" />
              <h1>Add Investor</h1>
            </div>
            <h3 className={styles['label-title']}>Competitors</h3>
            <Form.Item style={{width}} name="competitors">
              <Select
                mode="tags"
                defaultValue={campaignData?.getCampaignById?.competitors?.map(
                  data =>
                    `${data.companyName};${data.companyLogo};${data.companyId}`,
                )}
                onChange={e => setCompetitors(e)}>
                {getAllCompanies?.getAllCompanies?.map(
                  ({name, logoImgURL, id}) => (
                    <Option key={`${name};${logoImgURL};${id}`}>
                      <div className={styles['all-data']}>
                        <img src={logoImgURL} alt="" />
                        <span>{name}</span>
                      </div>
                    </Option>
                  ),
                )}
              </Select>
            </Form.Item>
            <SubmitButton
              disabled={updateLoading}
              label="Update"
              handleClick={handleSave}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default UpdateCampaignInvestors;
