/* eslint-disable react/jsx-key */
import {useState} from 'react';
import styles from './newReport.module.scss';
import {Form, Input, Select, Switch} from 'antd';
import ReportAccordion from 'components/reportAccordion';
import PlusIcon from 'images/plus_primary.svg';
import {Button} from '@dabafinance/react-components';
import ReportPDF from 'components/reportPDF';
import FadeEffect from 'components/animation/fadeEffect';
import {ArrowLeft, MinusIcon} from 'icons';
import {PlusOutlined} from '@ant-design/icons';
import DBModal from 'components/modal/modal';
import ReportTagsList from 'components/reportTagsList';
import {useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {CREATE_REPORT} from 'graphql/mutations/report';
import {GET_ALL_COMPANY, GET_ALL_REPORT} from 'graphql/queries/report';
import {toast} from 'react-toastify';
import {Option} from 'antd/lib/mentions';
import UploadImage from 'components/Startup/profile/companyImage';
import {GET_ALL_TOPICS} from 'graphql/queries/interest';

const NewReport = () => {
  const [choosenTopics, setChoosenTopics] = useState([]);
  const [relatedCompany, setRelatedCompany] = useState('');
  const [relatedCompanies, setRelatedCompanies] = useState([]);
  const [pdfLink, setPdfLink] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [coverPreview, setCoverPreview] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [formValues, setFormValues] = useState([]);
  const [useExternalLink, setUseExternalLink] = useState(false);

  const [openTagsModal, setOpenTagsModal] = useState(false);
  const addTopic = item => {
    const exist = choosenTopics.find(input => input.id === item.id);
    if (!exist) {
      const {icon, id, name, type} = item;
      setChoosenTopics([
        ...choosenTopics,
        {icon, id, type, name, isAdded: true},
      ]);
      return true;
    }
    return false;
  };

  const {slug} = useParams();
  const navigate = useNavigate();

  const [createReport] = useMutation(CREATE_REPORT);

  const {data: getAllCompany} = useQuery(GET_ALL_COMPANY);

  const {data: getAllIndustries} = useQuery(GET_ALL_TOPICS, {
    variables: {
      type: 'INDUSTRY',
    },
  });
  const {data: getAllCountries} = useQuery(GET_ALL_TOPICS, {
    variables: {
      type: 'COUNTRY',
    },
  });

  const handleRemoveTag = id => {
    const filteredTags = choosenTopics.filter(tag => tag.id !== id);
    setChoosenTopics(filteredTags);
  };

  const handleDeleteSection = id => {
    const filteredFormValues = formValues.filter(item => item.sequence !== id);
    setFormValues(filteredFormValues);
  };

  const handleChange = (i, e) => {
    const index = i - 1;
    const newFormValues = [...formValues];
    newFormValues[index].sectionName = e.target.value;
    setFormValues(newFormValues);
  };

  const handleEditorContent = (i, e) => {
    const index = i - 1;
    const newFormValues = [...formValues];
    newFormValues[index].content = e;
    setFormValues(formValues, newFormValues[index].content);
  };

  const addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        sectionName: 'Enter Section Title',
        sequence: formValues.length + 1,
        content: '',
      },
    ]);
  };

  const onSubmit = () => {
    createReport({
      variables: {
        data: {
          title,
          createdBy: author,
          sections: formValues,
          document: pdfLink,
          imageUrl: imgUrl,
          keywords,
          relatedTopics: choosenTopics
            .filter(data => data.type === 'INDUSTRY')
            .map(data => data.id),
          relatedCountries: choosenTopics
            .filter(data => data.type === 'COUNTRY')
            .map(data => data.id),
          type:
            slug === 'newCompany'
              ? 'COMPANY'
              : slug === 'newIndustry'
              ? 'INDUSTRY'
              : 'COUNTRY',
          linkType: useExternalLink ? 'EXTERNAL_LINK' : 'INTERNAL_LINK',
          relatedCompany: relatedCompany?.split(';')[1],
          relatedIndustry: relatedCompany?.split(';')[1],
          relatedCountry: relatedCompany?.split(';')[1],
          relatedCompanies: relatedCompanies?.map(data => data.split(';')[1]),
        },
      },
      refetchQueries: [{query: GET_ALL_REPORT}],
    }).then(({data: {createReport}}) => {
      if (createReport.__typename === 'Error') {
        toast.error(createReport.message);
      } else {
        toast.success('Created Successfully');
        navigate('/dashboard/report');
      }
    });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>
          <h2 className={styles['heading-text']}>
            <button onClick={() => navigate('/dashboard/report')}>
              <ArrowLeft />
            </button>
            New Report
          </h2>
        </div>
        <div className={styles['report-tags']}>
          {choosenTopics?.map(({icon, id}) => (
            <div key={id} className={styles['tag-icon']}>
              <img alt="" src={icon} />
              <button onClick={() => handleRemoveTag(id)}>
                <MinusIcon />
              </button>
            </div>
          ))}
          <button
            className={styles['add-tag-button']}
            onClick={() => setOpenTagsModal(true)}>
            <PlusOutlined />
          </button>
        </div>
        <div className={styles['cover-image']}>
          <UploadImage
            setImgUrl={setImgUrl}
            previewUrl={coverPreview}
            setPreviewUrl={setCoverPreview}
            bucket="reports-documents-bucket"
          />
        </div>
        <Form className={styles['form-style']} layout="vertical">
          {slug === 'newCompany' ? (
            <Form.Item
              label="Company"
              name="relatedCompany"
              rules={[
                {
                  required: true,
                  message: 'Company is required',
                },
              ]}>
              <Select
                value={relatedCompany}
                onChange={e => setRelatedCompany(e)}
                showSearch>
                {getAllCompany?.getAllCompanies?.map(
                  ({id, name, logoImgURL}) => (
                    // eslint-disable-next-line react/jsx-key
                    <Option value={`${name};${id}`}>
                      <div className={styles['all-data']}>
                        <img src={logoImgURL} alt="" />
                        <span>{name}</span>
                      </div>
                    </Option>
                  ),
                )}
              </Select>
            </Form.Item>
          ) : slug === 'newIndustry' ? (
            <Form.Item
              label="Industry"
              name="relatedCompany"
              rules={[
                {
                  required: true,
                  message: 'Industry is required',
                },
              ]}>
              <Select
                value={relatedCompany}
                onChange={e => setRelatedCompany(e)}
                showSearch>
                {getAllIndustries?.getAllTopics?.map(({id, name, icon}) => (
                  <Option value={`${name};${id}`}>
                    <div className={styles['all-data']}>
                      <img src={icon} alt="" />
                      <span>{name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              label="Country"
              name="relatedCompany"
              rules={[
                {
                  required: true,
                  message: 'Country is required',
                },
              ]}>
              <Select
                value={relatedCompany}
                onChange={e => setRelatedCompany(e)}
                showSearch>
                {getAllCountries?.getAllTopics?.map(({id, name, icon}) => (
                  <Option value={`${name};${id}`}>
                    <div className={styles['all-data']}>
                      <img src={icon} alt="" />
                      <span>{name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Title is required',
              },
            ]}>
            <Input
              value={title}
              onChange={e => setTitle(e.target.value)}
              required
            />
          </Form.Item>
          <Form.Item
            label="Created by"
            name="author"
            rules={[
              {
                required: true,
                message: 'Author is required',
              },
            ]}>
            <Input
              value={author}
              onChange={e => setAuthor(e.target.value)}
              required
            />
          </Form.Item>
          <Form.Item label="Keywords" name="keywords">
            <Select
              mode="tags"
              onChange={e => setKeywords(e)}
              tokenSeparators={[',']}>
              {keywords}
            </Select>
          </Form.Item>
          {slug !== 'newCompany' && (
            <Form.Item label="Related Companies" name="relatedCompanies">
              <Select
                value={relatedCompanies}
                onChange={e => setRelatedCompanies(e)}
                showSearch
                mode="multiple">
                {getAllCompany?.getAllCompanies?.map(
                  ({id, name, logoImgURL}) => (
                    <Option value={`${name};${id}`}>
                      <div className={styles['all-data']}>
                        <img src={logoImgURL} alt="" />
                        <span>{name}</span>
                      </div>
                    </Option>
                  ),
                )}
              </Select>
            </Form.Item>
          )}
          <Form.Item label="Use External Link">
            <Switch
              checked={useExternalLink}
              onChange={e => setUseExternalLink(e)}
            />
          </Form.Item>
          {useExternalLink ? (
            <div>
              <Form.Item
                label="File Link"
                name="document"
                rules={[
                  {
                    required: false,
                  },
                ]}>
                <Input
                  value={pdfLink}
                  onChange={e => setPdfLink(e.target.value)}
                  required
                />
              </Form.Item>
            </div>
          ) : (
            <div>
              <FadeEffect>
                <ReportPDF
                  setPdfLink={setPdfLink}
                  previewUrl={previewUrl}
                  setPreviewUrl={setPreviewUrl}
                  large
                />
              </FadeEffect>
            </div>
          )}

          <FadeEffect>
            {formValues?.map(({sectionName, content, sequence}) => (
              <ReportAccordion
                key={sequence}
                headingText={sectionName || ''}
                handleHeadingText={e => handleChange(sequence, e)}
                content={content || ''}
                handleContent={e => handleEditorContent(sequence, e)}
                index={sequence}
                onDelete={() => handleDeleteSection(sequence)}
              />
            ))}
            <button
              type="button"
              className={styles['add-button']}
              onClick={() => addFormFields()}>
              <img src={PlusIcon} alt="" />
              <span>Add New Section</span>
            </button>
          </FadeEffect>
          <Form.Item>
            <Button
              type="primary"
              label="Save Changes"
              className={styles['submit-button']}
              onClick={() => onSubmit()}
            />
          </Form.Item>
        </Form>
      </div>
      {/* Add Tags Modal */}
      <DBModal
        isOpen={openTagsModal}
        handleClose={() => setOpenTagsModal(false)}
        content={
          <ReportTagsList
            onClick={() => setOpenTagsModal(false)}
            choosenTopics={choosenTopics}
            addTopic={addTopic}
          />
        }
      />
    </>
  );
};

export default NewReport;
