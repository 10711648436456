import {Form, Select} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import backIcon from 'images/back.png';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ORDER_TRADE_STATUS} from 'utils/constants';
import styles from './updateStocksOrders.module.scss';

const UpdateStocksOrders = () => {
  const [status, setStatus] = useState('');
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>Update Stocks Orders</h1>
      </div>
      <Form className={styles.form} layout="vertical">
        <Form.Item name="status" label="Transaction Status">
          <Select
            style={{width: 500}}
            placeholder="Please Select a status"
            value={status}
            onChange={e => setStatus(e)}>
            {ORDER_TRADE_STATUS.map(data => (
              <Select.Option key={data.key}>{data.value}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <SubmitButton disabled={true} label="Update" />
      </Form>
    </div>
  );
};

export default UpdateStocksOrders;
