import {useEffect, useState} from 'react';
import styles from './newUpdate.module.scss';
import backIcon from 'images/back.png';
import UploadImage from 'components/Startup/profile/companyImage';
import {DatePicker, Form, Input, Select, Tabs} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import ReactQuill from 'react-quill';
import '../../../../../node_modules/react-quill/dist/quill.snow.css';
import {
  GET_ALL_INVESTORS_UPDATES,
  GET_ALL_INVESTORS_UPDATES_QUERIES,
  TRANSLATE_MULTIPLE_FIELDS,
} from 'graphql/queries/investorUpdates';
import SubmitButton from 'components/Startup/components/SubmitButton';
import moment from 'moment';
import {
  CREATE_INVESTOR_UPDATE,
  UPDATE_INVESTOR_UPDATE,
} from 'graphql/mutations/investorUpdates';
import {toast} from 'react-toastify';
import {
  QUILL_FORMATS,
  QUILL_MODULES,
  supportedLanguages,
} from 'utils/constants';
import {fieldRules, getUserTimezone} from 'utils/helper';
import {Button} from '@dabafinance/react-components';
import {onTranslateInvestorsUpdate} from './actions';
import TranslatedUpdateContents from './TranslatedUpdateContents';

const NewUpdate = ({existingData, multiLang}) => {
  const [imgUrl, setImgUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [content, setContent] = useState('');
  const [assignedUsers, setAssignedUsers] = useState('');
  const [notificationTitle, setNotificationTitle] = useState('');
  const [keyTakeAway, setKeyTakeAway] = useState('');
  const [relatedTopics, setRelatedTopics] = useState([]);
  const [relatedCountries, setRelatedCountries] = useState([]);
  const [relatedCompanies, setRelatedCompanies] = useState([]);
  const [publishedDate, setPublishedDate] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [title, setTitle] = useState('');
  const [websiteLink, setWebsiteLink] = useState('');
  const [language, setLanguage] = useState('');
  const [selectedTranslationLanguage, setSelectedTranslationLanguage] =
    useState('');
  const [translatedUpdates, setTranslatedUpdates] = useState([]);
  const {Option} = Select;
  const navigate = useNavigate();
  const {id: updateId} = useParams();
  const {data, loading} = useQuery(GET_ALL_INVESTORS_UPDATES_QUERIES);
  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );
  const [createInvestorUpdate, {loading: createLoading}] = useMutation(
    CREATE_INVESTOR_UPDATE,
  );
  const [updateInvestorUpdate, {loading: updateLoading}] = useMutation(
    UPDATE_INVESTOR_UPDATE,
  );

  const fieldWidth = 730;

  const languageToBeTranslatedTo = supportedLanguages.filter(
    key => key.value !== language,
  );

  const handleChangeTranslatedValues = (key, event, name) => {
    let eventName = name || event.target.name;
    let eventValue = name ? event : event.target.value;
    const newFormValues = [...translatedUpdates];
    newFormValues[key][eventName] = eventValue;
    setTranslatedUpdates(newFormValues);
  };

  useEffect(() => {
    if (existingData) {
      setImgUrl(existingData?.imageUrl);
      setPreviewUrl(existingData?.imageUrl);
      setKeyTakeAway(existingData?.keyTakeAway);
      setTitle(existingData?.title);
      setNotificationTitle(existingData?.notificationTitle);
      setLanguage(existingData?.language);
      setContent(existingData?.content);
      setRelatedCountries(
        existingData?.countries?.map(country => country?.name),
      );
      setExpirationDate(existingData?.expirationDate);
      setPublishedDate(existingData?.publishedDate);
      setRelatedCompanies(
        existingData?.companies?.map(company => company?.name),
      );
      setRelatedTopics(existingData?.topics?.map(topic => topic?.name));
      setWebsiteLink(existingData?.websiteLink);
    }
  }, [existingData]);

  useEffect(() => {
    if (multiLang?.length) {
      setTranslatedUpdates(
        multiLang.map(data => ({
          title: data.title,
          notificationTitle: data?.notificationTitle,
          content: data?.content,
          keyTakeAway: data?.keyTakeAway,
          language: data?.language,
          multiLangWebsiteLink: data?.websiteLink,
        })),
      );
    }
  }, [multiLang]);

  const handleUpdate = () => {
    if (!translatedUpdates.length) {
      return toast.error('Translation is needed');
    }
    updateInvestorUpdate({
      variables: {
        data: {
          id: updateId,
          title: title.trim(),
          content,
          notificationTitle: notificationTitle.trim(),
          language,
          keyTakeAway,
          multilang: translatedUpdates.map(data => ({
            title: data?.title,
            notificationTitle: data.notificationTitle,
            content: data?.content,
            keyTakeAway: data?.keyTakeAway,
            language: data?.language,
            websiteLink: data?.multiLangWebsiteLink,
          })),
          publishedDate: moment(publishedDate).format(),
          expirationDate: moment(expirationDate).format(),
          imageUrl: imgUrl,
          companies: relatedCompanies,
          countries: relatedCountries,
          topics: relatedTopics,
          websiteLink: websiteLink.trim(),
        },
      },
      refetchQueries: [{query: GET_ALL_INVESTORS_UPDATES}],
    }).then(({data: {updateInvestorUpdate}}) => {
      if (updateInvestorUpdate.__typename === 'Error') {
        toast.error(updateInvestorUpdate.message);
      } else {
        toast.success('Updated Successfully');
        navigate('/dashboard/explore');
      }
    });
  };

  const handleCreate = () => {
    if (!translatedUpdates.length) {
      return toast.error('Translation is needed');
    }
    createInvestorUpdate({
      variables: {
        data: {
          imageUrl: imgUrl,
          content,
          title: title.trim(),
          assignedUsers,
          keyTakeAway,
          notificationTitle: notificationTitle.trim(),
          language,
          multilang: translatedUpdates.map(data => ({
            title: data?.title,
            notificationTitle: data.notificationTitle,
            content: data?.content,
            keyTakeAway: data?.keyTakeAway,
            language: data?.language,
            websiteLink: data?.multiLangWebsiteLink,
          })),
          publishedDate: moment(publishedDate).format(),
          expirationDate: moment(expirationDate).format(),
          companies: relatedCompanies,
          countries: relatedCountries,
          topics: relatedTopics,
          websiteLink: websiteLink.trim(),
        },
      },
      refetchQueries: [{query: GET_ALL_INVESTORS_UPDATES}],
    }).then(({data: {createInvestorUpdate}}) => {
      if (createInvestorUpdate.__typename === 'Error') {
        toast.error(createInvestorUpdate.message);
      } else {
        toast.success('Created Successfully');
        navigate('/dashboard/explore');
      }
    });
  };

  const handleTranslation = async () => {
    const translateProps = {
      sourceLang: language,
      targetLang: selectedTranslationLanguage,
      targetText1: title,
      targetText2: notificationTitle,
      targetText3: content,
      targetText4: keyTakeAway,
    };
    try {
      const newValues = [...translatedUpdates];

      //Check if translation exists
      if (newValues.length && newValues[0]) {
        // Check if we're trying to translate in the same language
        const languageExists = newValues.some(
          data => data.language === selectedTranslationLanguage,
        );
        if (languageExists) {
          const updateTranslation = await onTranslateInvestorsUpdate(
            translateText,
            translateProps,
          );
          const translationIndex = newValues.findIndex(
            data => data.language === selectedTranslationLanguage,
          );
          newValues[translationIndex].title = updateTranslation.title;
          newValues[translationIndex].notificationTitle =
            updateTranslation.notificationTitle;
          newValues[translationIndex].language = updateTranslation.language;
          newValues[translationIndex].content = updateTranslation.content;
          newValues[translationIndex].keyTakeAway =
            updateTranslation.keyTakeAway;
          setTranslatedUpdates(newValues);
        }
      } else {
        const triggerTranslation = await onTranslateInvestorsUpdate(
          translateText,
          translateProps,
        );
        if (!triggerTranslation) {
          return toast.error('Something went wrong when Translating');
        }
        newValues.push(triggerTranslation);
        setTranslatedUpdates(newValues);
        setTranslatedUpdates(newValues);
      }
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  return (
    <div>
      <div className={styles.navigation}>
        <img src={backIcon} alt="Go back" onClick={() => navigate(-1)} />
        <span>
          Investors Updates{' '}
          <span className={styles['h-style']}>
            / {updateId ? 'Update' : 'Create'}
          </span>
        </span>
      </div>
      <div className={styles.form}>
        <UploadImage
          previewUrl={previewUrl}
          setPreviewUrl={setPreviewUrl}
          setImgUrl={setImgUrl}
          large
          bucket="investor-updates-bucket"
        />
        <Form
          onFinish={updateId ? handleUpdate : handleCreate}
          layout="vertical">
          <Form.Item
            rules={[fieldRules('Language')]}
            label="Language"
            initialValue={existingData?.language}
            className={styles.field}
            name="language">
            <Select
              style={{width: fieldWidth}}
              defaultValue={existingData?.language}
              value={language}
              onChange={e => setLanguage(e)}>
              {supportedLanguages.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[fieldRules('You must select a language to be translated')]}
            label="Language Translation"
            className={styles.field}
            name="translation">
            <Select
              style={{width: fieldWidth}}
              value={selectedTranslationLanguage}
              onChange={e => setSelectedTranslationLanguage(e)}>
              {languageToBeTranslatedTo.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Tabs
            className={styles.tabs}
            tabBarExtraContent={
              selectedTranslationLanguage && (
                <Button
                  disabled={
                    !title ||
                    !notificationTitle ||
                    !content ||
                    !keyTakeAway ||
                    loadingTranslation
                  }
                  label="Translate"
                  onClick={handleTranslation}
                />
              )
            }>
            <Tabs.TabPane tab="Original" key="Original">
              <Form.Item
                rules={[fieldRules('Title')]}
                label="Title"
                initialValue={existingData?.title}
                className={styles.field}
                name="title">
                <Input
                  style={{width: fieldWidth}}
                  value={title}
                  defaultValue={existingData?.title}
                  onChange={e => setTitle(e.target.value)}
                  placeholder={'Title'}
                />
              </Form.Item>
              <Form.Item
                label="Notification Title"
                className={styles.field}
                initialValue={existingData?.notificationTitle}
                name="notificationTitle"
                rules={[
                  fieldRules('Notification Title with a max of 32 characters'),
                  {max: 32},
                ]}>
                <Input
                  defaultValue={existingData?.notificationTitle}
                  style={{width: fieldWidth}}
                  value={notificationTitle}
                  onChange={e => setNotificationTitle(e.target.value)}
                  placeholder={'Notification Title'}
                />
              </Form.Item>
              <div className={styles['quill-container']}>
                <span>Content</span>
                <ReactQuill
                  className={styles.quill}
                  theme="snow"
                  modules={NewUpdate.modules}
                  formats={NewUpdate.format}
                  onChange={el => setContent(el)}
                  placeholder="Write Something"
                  value={content}
                />
              </div>
              <div className={styles['quill-container']}>
                <span>Key Takeaway</span>
                <ReactQuill
                  className={styles.quill}
                  theme="snow"
                  modules={NewUpdate.modules}
                  formats={NewUpdate.format}
                  onChange={el => setKeyTakeAway(el)}
                  placeholder="Write Something"
                  value={keyTakeAway}
                />
              </div>
              <Form.Item
                rules={[fieldRules('Valid Website Link'), {type: 'url'}]}
                name="website link"
                initialValue={existingData?.websiteLink}
                label="Website Link">
                <Input
                  name="Website Link"
                  style={{width: fieldWidth}}
                  defaultValue={existingData?.websiteLink}
                  value={websiteLink}
                  onChange={e => setWebsiteLink(e.target.value)}
                />
              </Form.Item>
            </Tabs.TabPane>
            {translatedUpdates.length &&
              translatedUpdates.map((data, index) => (
                <Tabs.TabPane
                  key={data.language}
                  tab={
                    supportedLanguages.find(
                      lang => lang.value === data.language,
                    ).label
                  }>
                  <TranslatedUpdateContents
                    loading={loadingTranslation}
                    title={data.title}
                    fieldKey={index}
                    onChangeFields={handleChangeTranslatedValues}
                    notificationTitle={data.notificationTitle}
                    content={data.content}
                    multiLangWebsiteLink={data?.multiLangWebsiteLink}
                    keyTakeAway={data.keyTakeAway}
                  />
                </Tabs.TabPane>
              ))}
          </Tabs>
          <h2>Publish Time</h2>
          {!updateId && (
            <Form.Item
              label="Assigned Users"
              className={styles.field}
              name="assigned users"
              rules={[fieldRules('Assigning who sees this update')]}>
              <Select
                style={{width: fieldWidth}}
                value={assignedUsers}
                onChange={e => setAssignedUsers(e)}>
                <Option value="ALL_USERS">All User</Option>
                <Option value="USERS_BY_TOPICS">Users by Topics</Option>
              </Select>
            </Form.Item>
          )}

          <div className={styles['date-format']}>
            <Form.Item
              label="Published Date"
              name="published Date"
              rules={[
                {
                  required: true,
                  message: 'Published date required',
                },
              ]}
              initialValue={
                existingData?.publishedDate
                  ? moment(existingData?.publishedDate, 'YYYY-MM-DD HH:mm:ss')
                  : ''
              }>
              <DatePicker
                name="published Date"
                value={publishedDate}
                style={{width: 340}}
                format={'YYYY-MM-DD HH:mm:ss'}
                renderExtraFooter={() => (
                  <div style={{textAlign: 'center'}}>
                    <p>Your timezone is: {getUserTimezone()}</p>
                  </div>
                )}
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm:ss'),
                }}
                defaultValue={
                  existingData?.publishedDate
                    ? moment(existingData?.publishedDate, 'YYYY-MM-DD HH:mm:ss')
                    : ''
                }
                onChange={e =>
                  setPublishedDate(moment(e).format('YYYY-MM-DD HH:mm:ss'))
                }
              />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Expiration date required',
                },
              ]}
              name="expiry date"
              label="Expiration Date"
              initialValue={
                existingData?.expirationDate
                  ? moment(existingData?.expirationDate, 'YYYY-MM-DD HH:mm:ss')
                  : ''
              }>
              <DatePicker
                name="Expiry Date"
                style={{width: 340}}
                format={'YYYY-MM-DD HH:mm:ss'}
                renderExtraFooter={() => (
                  <div style={{textAlign: 'center'}}>
                    <p>Your timezone is: {getUserTimezone()}</p>
                  </div>
                )}
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm:ss'),
                }}
                defaultValue={
                  existingData?.expirationDate
                    ? moment(
                        existingData?.expirationDate,
                        'YYYY-MM-DD HH:mm:ss',
                      )
                    : ''
                }
                value={expirationDate}
                onChange={e =>
                  setExpirationDate(moment(e).format('YYYY-MM-DD HH:mm:ss'))
                }
              />
            </Form.Item>
          </div>
          <div className={styles['related-topics']}>
            <h1>Related Topics &#38; Companies</h1>
            <Form.Item
              label="Related Topics"
              className={styles.field}
              initialValue={existingData?.topics
                ?.filter(topic => topic?.name !== null)
                .map(topic => topic?.name)}
              name="related topics">
              <Select
                value={relatedTopics}
                onChange={e => setRelatedTopics(e)}
                mode="multiple"
                loading={loading}
                defaultValue={existingData?.topics
                  ?.filter(topic => topic?.name !== null)
                  .map(topic => topic?.name)}
                allowClear
                style={{width: fieldWidth}}>
                {data?.getAllTopics?.map(({name, icon}) => (
                  <Option key={name}>
                    <div className={styles['all-data']}>
                      <img src={icon} alt="" />
                      <span>{name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Related Companies"
              className={styles.field}
              initialValue={existingData?.companies
                ?.filter(company => company?.name !== null)
                .map(company => company?.name)}
              name="Related Companies">
              <Select
                value={relatedCompanies}
                onChange={e => setRelatedCompanies(e)}
                mode="multiple"
                defaultValue={existingData?.companies
                  ?.filter(company => company?.name !== null)
                  .map(company => company?.name)}
                loading={loading}
                style={{width: fieldWidth}}>
                {data?.getAllCompanies?.map(data => (
                  <Option key={data?.name}>
                    <div className={styles['all-data']}>
                      <img src={data?.logoImgURL} alt="" />
                      <span>{data?.name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Related Countries"
              className={styles.field}
              initialValue={existingData?.countries
                ?.filter(country => country?.name !== null)
                .map(country => country?.name)}
              name="Related Countries">
              <Select
                value={relatedCountries}
                loading={loading}
                defaultValue={existingData?.countries
                  ?.filter(countries => countries?.name !== null)
                  .map(countries => countries?.name)}
                onChange={e => setRelatedCountries(e)}
                mode="multiple"
                style={{width: fieldWidth}}>
                {data?.getAllTopicCountries?.map(({name, icon}) => (
                  <Option key={name}>
                    <div className={styles['all-data']}>
                      <img src={icon} alt="" />
                      <span>{name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item>
            <SubmitButton
              disabled={updateLoading || createLoading}
              label={updateId ? 'Update' : 'Create'}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

NewUpdate.modules = QUILL_MODULES;
NewUpdate.format = QUILL_FORMATS;

export default NewUpdate;
