import {Button} from '@dabafinance/react-components';
import {Table} from 'antd';
import {usersSavedTableData} from 'utils/mock';
import styles from './opportunities.module.scss';

const UserOpportunities = () => {
  const amountFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <img src={record.image} alt="" />
          <h4 style={{width: 100}}>{record.name}</h4>
        </div>
      ),
    },
    {
      title: 'Investors',
      dataIndex: 'investors',
      key: 'investors',
    },
    {
      title: 'Funding Type',
      dataIndex: 'funding',
      key: 'funding',
    },
    {
      title: 'Amount Funded',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => (
        <span>{amountFormatter.format(record.amount)}</span>
      ),
    },

    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: () => <Button type="secondary" label="View details" />,
    },
  ];
  return (
    <div className={styles.container}>
      <h1>Saved Opportunities</h1>
      <div>
        <Table columns={columns} dataSource={usersSavedTableData} />
      </div>
    </div>
  );
};

export default UserOpportunities;
