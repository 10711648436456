import {GET_ASSET_CLASSES} from 'graphql/queries/assetManagement';
import {toast} from 'react-toastify';

export const createAssetClassResolver = (
  createAssetClass,
  formValues,
  navigate,
  closeModal,
) => {
  const {name, description, language, status, key, instruments} = formValues;
  createAssetClass({
    variables: {
      input: {
        name,
        language,
        description,
        status,
        key,
        instruments: instruments.map(instrument => ({
          name: instrument?.name,
          description: instrument?.description,
          status: instrument?.status,
          key: instrument?.key,
        })),
      },
    },
    refetchQueries: [{query: GET_ASSET_CLASSES}],
  })
    .then(({data: {createAssetClass}}) => {
      if (createAssetClass.__typename === 'Error') {
        throw new Error(createAssetClass.message);
      } else {
        toast.success('Asset Class Created Successfully');
        closeModal(false);
        navigate(-1);
      }
    })
    .catch(error => {
      toast.error(`GraphQL Error: ${error.message}`);
    });
};

export const handleDeleteAssetClass = (
  deleteAssetClass,
  id,
  refetch,
  closeModal,
) => {
  deleteAssetClass({
    variables: {
      deleteAssetClassId: id,
    },
  })
    .then(({data: {deleteAssetClass}}) => {
      if (deleteAssetClass.__typename === 'Error') {
        throw new Error(deleteAssetClass.message);
      } else {
        toast.success('Asset Class Deleted Successfully');
        refetch();
        closeModal(false);
      }
    })
    .catch(error => {
      toast.error(`GraphQL Error: ${error.message}`);
    });
};

export const handleUpdateAssetClass = (
  updateAssetClass,
  formValues,
  id,
  navigate,
  closeModal,
) => {
  const {name, description, language, status, key, instruments} = formValues;
  updateAssetClass({
    variables: {
      updateAssetClassId: id,
      input: {
        name,
        language,
        description,
        status,
        key,
        instruments: instruments.map(instrument => ({
          name: instrument?.name,
          description: instrument?.description,
          status: instrument?.status,
          key: instrument?.key,
        })),
      },
    },
    refetchQueries: [{query: GET_ASSET_CLASSES}],
  })
    .then(({data: {updateAssetClass}}) => {
      if (updateAssetClass.__typename === 'Error') {
        throw new Error(updateAssetClass.message);
      } else {
        toast.success('Asset Class Updated Successfully');
        closeModal(false);
        navigate(`/dashboard/asset-management/classes`);
      }
    })
    .catch(error => {
      toast.error(`GraphQL Error: ${error.message}`);
    });
};

export const handleUpdateBond = (
  updateBond,
  id,
  language,
  updatedValues,
  refetch,
  setOpenUpdateModal,
  stepTwo,
) => {
  updateBond({
    variables: {
      updateBondId: id,
      language,
      input: {
        ...(stepTwo
          ? {
              ...updatedValues,
              isCallable: updatedValues.isCallable ?? false,
            }
          : {
              baseFinancialInstrumentInput: {
                ...updatedValues,
              },
            }),
      },
    },
  })
    .then(({data: {updateBond}}) => {
      if (updateBond.__typename === 'Error') {
        toast.error(updateBond.message);
      } else {
        toast.success('Updated Successfully');
        refetch();
        setOpenUpdateModal(false);
      }
    })
    .catch(error => toast.error('Something went wrong', error));
};
