import {Form, Select, Spin} from 'antd';
import styles from './walletDetails.module.scss';
import {useNavigate, useParams} from 'react-router-dom';
import {GET_PAYMENT_PROVIDER_DETAILS_BY_ID} from 'graphql/queries/wallet';
import {useQuery} from '@apollo/client';
import {useState} from 'react';
import DepositWalletFields from './WalletFields/DepositWalletField';
import WithdrawalWalletFields from './WalletFields/WithdrawalWalletFields';
import backIcon from 'images/back.png';

const WalletDetails = () => {
  const {provider} = useParams();
  const [currencyKeys, setCurrencyKeys] = useState([]);
  const [currencyDetails, setCurrencyDetails] = useState({});
  const [isProviderEnabled, setIsProviderEnabled] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const navigate = useNavigate();

  const {loading} = useQuery(GET_PAYMENT_PROVIDER_DETAILS_BY_ID, {
    variables: {
      providerName: provider,
    },
    onCompleted: data => {
      setIsProviderEnabled(data?.getPaymentProviderByProviderName?.enabled);
      const currencies = data?.getPaymentProviderByProviderName?.currencies;
      setCurrencyDetails(currencies);
      const currencyKeys = Object.keys(currencies);
      const currencyKeyValues = currencyKeys.map(key => ({
        key: key,
        value: key,
      }));

      setCurrencyKeys(currencyKeyValues);
    },
  });

  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backIcon} />
        <h1>{provider} PROVIDER DETAILS</h1>
      </div>

      <Form layout="vertical">
        <Form.Item name="currency" label="Select Currency of this Provider">
          <Select
            style={{width: 500}}
            value={selectedCurrency}
            placeholder="Select a currency to View Details"
            onChange={e => setSelectedCurrency(e)}>
            {currencyKeys?.map(data => (
              <Select.Option key={data.key}>
                {data.value} Currency
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      {selectedCurrency && currencyDetails ? (
        <>
          <DepositWalletFields
            selectedCurrency={selectedCurrency}
            isProviderEnabled={isProviderEnabled}
            currencyDetails={currencyDetails}
          />
          <WithdrawalWalletFields
            selectedCurrency={selectedCurrency}
            currencyDetails={currencyDetails}
          />
        </>
      ) : (
        <div className={styles.noCurrency}>
          <p>Please Select a Currency</p>
        </div>
      )}
    </div>
  );
};

export default WalletDetails;
