import {Dropdown, Menu, Table} from 'antd';
import {useQuery} from '@apollo/client';
import styles from '../ViewOrders/viewOrders.module.scss';
import {GET_COMPANIES_IN_STOCK_EXCHANGE} from 'graphql/queries/stocks';
import {useNavigate, useParams} from 'react-router-dom';
import dotsIcon from 'icons/dots.svg';
import backIcon from 'images/back.png';
import {useState} from 'react';
import {Button} from '@dabafinance/react-components';

const ViewCompanyListing = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const {code} = useParams();
  const navigate = useNavigate();

  const {data, loading} = useQuery(GET_COMPANIES_IN_STOCK_EXCHANGE, {
    variables: {
      stockExchangeCode: code,
      page: currentPage,
      limit: 10,
    },
    onCompleted: data => {
      setHasNext(data?.adminGetStockExchangeListedCompanies?.hasNextPage);
      setHasPrev(data?.adminGetStockExchangeListedCompanies?.hasPrevPage);
    },
  });

  const handleNextPage = () => setCurrentPage(prevPage => prevPage + 1);
  const handlePreviousPage = () =>
    setCurrentPage(currentPage => currentPage - 1);

  const columns = [
    {
      dataIndex: 'ticker',
      key: 'ticker',
      title: 'Ticker',
      render: (text, record) => <span>{record?.symbol}</span>,
    },
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Name',
      render: (text, record) => <span>{record?.company?.name}</span>,
    },
    {
      dataIndex: 'baseCurrencyCode',
      key: 'baseCurrencyCode',
      title: 'Currency',
    },
    {
      dataIndex: 'price',
      key: 'price',
      title: 'Current Price',
      render: (text, record) => (
        <span>
          {record?.lastPrice} {record?.baseCurrencyCode}
        </span>
      ),
    },
    {
      dataIndex: 'marketCapitalization',
      key: 'marketCap',
      title: 'Market Capitalization',
      render: (text, record) => (
        <span>{record?.marketCapitalization?.toLocaleString()}</span>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (text, render) => (
        <Dropdown
          trigger="hover"
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                onClick={() =>
                  navigate(
                    `/dashboard/opportunities/startups/profile/${render?.company?.id}?route=stocks`,
                  )
                }>
                <div>
                  <span>View Settings</span>
                </div>
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={() =>
                  navigate(
                    `/dashboard/stocks/listings?route=order&ticker=${render?.symbol}`,
                  )
                }>
                <div>
                  <span>View Transactions</span>
                </div>
              </Menu.Item>
            </Menu>
          }>
          <img src={dotsIcon} onClick={e => e.stopPropagation()} />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>{code} Listed Companies</h1>
      </div>
      <div className={styles.content}>
        <div className={styles['nav-buttons']}>
          <Button
            onClick={handlePreviousPage}
            label="Previous"
            type="secondary"
            disabled={!hasPrev || loading}
            className={styles.button}
          />
          <div className={styles['page-number']}>{currentPage}</div>
          <Button
            onClick={handleNextPage}
            label="Next"
            disabled={!hasNext || loading}
            className={styles.button}
          />
        </div>
        <Table
          loading={loading}
          pagination={false}
          columns={columns}
          dataSource={data?.adminGetStockExchangeListedCompanies?.companies}
        />
      </div>
    </div>
  );
};

export default ViewCompanyListing;
