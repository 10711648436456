import {useState} from 'react';
import styles from './subscriptionSettings.module.scss';
import {useMutation, useQuery} from '@apollo/client';
import {GET_SUBSCRIPTION_PRODUCTS} from 'graphql/queries/plans';
import {Spin, Form, Select, Input} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {UPDATE_APP_CONFIGURATION} from 'graphql/queries/mobileSettings';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const SubscriptionSetting = () => {
  const [priceId, setPriceId] = useState('');
  const [validityDays, setValidityDays] = useState(0);

  const {data, loading} = useQuery(GET_SUBSCRIPTION_PRODUCTS);
  const [updateAppConfig, {loading: loadingUpdate}] = useMutation(
    UPDATE_APP_CONFIGURATION,
  );

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Subscription),
  );

  const handleSubmit = () => {
    updateAppConfig({
      variables: {
        data: {
          subscriptionAccessSettings: {
            subscriptionPrice: priceId,
            validityDays: Number(validityDays),
          },
        },
      },
    })
      .then(({data: {updateAppConfig}}) => {
        if (updateAppConfig.__typename === 'Error') {
          toast.error(updateAppConfig.message);
        } else {
          toast.success('Updated Successfully');
        }
      })
      .catch(error => toast.error(error));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <h1>Update Subscription Plan Validity Days</h1>
      </div>
      <div className={styles.content}>
        <Form onFinish={handleSubmit} layout="vertical">
          <Form.Item
            rules={[
              {required: true, message: 'Please select a Subscription Price'},
            ]}
            className={styles['field-width']}
            name="price"
            label="Select Subscription Price">
            <Select
              placeholder="Please select a price"
              value={priceId}
              onChange={e => setPriceId(e)}>
              {data?.getSubscriptionProducts?.subscriptionProducts?.map(
                productData =>
                  productData?.prices?.map(priceData => (
                    <Select.Option key={priceData?.id}>
                      <div className={styles['price-flex']}>
                        <span>{productData?.name}- -</span>
                        <span>{priceData?.billingCycle}</span>
                      </div>
                    </Select.Option>
                  )),
              )}
            </Select>
          </Form.Item>
          <Form.Item
            name="validityDays"
            rules={[
              {required: true, message: 'Required Field'},
              {min: 1, required: true, message: 'Cannot be less than a day'},
            ]}
            label="Validity Days"
            className={styles['field-width']}>
            <Input
              value={validityDays}
              placeholder="Number of days"
              onChange={e => setValidityDays(e.target.value)}
              type="number"
            />
          </Form.Item>
          <SubmitButton
            disabled={loadingUpdate || !tabOperations?.create}
            label="Update"
          />
        </Form>
      </div>
    </div>
  );
};

export default SubscriptionSetting;
