import {useQuery} from '@apollo/client';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {GET_WEBSITE_NEWS_BY_ID} from 'redux/store/news/queries';
import WebsiteNewsFinishPageContents from '../create/WebsiteNewsFinishPageContents';
import {Spin} from 'antd';

const UpdateWebsiteNewsFinishPage = () => {
  const [fetchedWebsiteNews, setFetchedWebsiteNews] = useState({});
  const {id, lang} = useParams();
  const {loading} = useQuery(GET_WEBSITE_NEWS_BY_ID, {
    variables: {
      newsId: id,
      language: lang,
    },
    onCompleted: data =>
      setFetchedWebsiteNews(data?.getWebsiteNewsById?.websiteNews),
  });

  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    Object.keys(fetchedWebsiteNews).length && (
      <WebsiteNewsFinishPageContents existingNews={fetchedWebsiteNews} />
    )
  );
};

export default UpdateWebsiteNewsFinishPage;
