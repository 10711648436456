import {Route, Routes} from 'react-router-dom';
import UserFeed from './tabs/feed/UserFeed';
import UserInterests from './tabs/interest/UserInterests';
import InvestmentDetails from './tabs/investment/Investment';
import UserOpportunities from './tabs/opportunities/UserOpportunities';
import ProfileBanks from './tabs/profileBanks/ProfileBanks';
import ProfileDetails from './tabs/profileDetails/ProfileDetails';
import UserBookmarked from './tabs/userBookmark/UserBookmarked';
import UserDetails from './UserDetails';
import UsersSubscription from './tabs/subscription/index';
import CreateTransaction from './tabs/transactions/CreateTransaction';
import UpdateKyc from './updateKyc/UpdateKyc';
import UserTransactions from './tabs/trades';
import UpdateTransaction from './tabs/transactions/UpdateTransaction';
import {NotificationPreference} from './tabs/notificationPreference';
import UserDocuments from './tabs/documents';
import GrantSubscriptionAccess from './tabs/subscription/price/GrantSubscriptionAccess';
import UserSecurity from './tabs/userSecurity/UserSecurity';
import UserWalletSettings from './tabs/UserWalletSettings/UserWalletSettings';
import RecordedUserSession from './tabs/RecordedUserSession/RecordedUserSession';

const UserRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<UserDetails />}>
        <Route path="/" element={<ProfileDetails />} />
        <Route path="/banks" element={<ProfileBanks />} />
        <Route path="/investment" element={<InvestmentDetails />} />
        <Route path="/interests" element={<UserInterests />} />
        <Route path="/bookmark" element={<UserBookmarked />} />
        <Route path="/saved" element={<UserOpportunities />} />
        <Route path="/kyc-status" element={<UpdateKyc />} />
        <Route path="/feed" element={<UserFeed />} />
        <Route path="/subscription" element={<UsersSubscription />} />
        <Route
          path="/subscription-price/:accessType"
          element={<GrantSubscriptionAccess />}
        />
        <Route path="/transactions" element={<UserTransactions />} />
        <Route path="/documents" element={<UserDocuments />} />
        <Route
          path="/notification-preferences"
          element={<NotificationPreference />}
        />
        <Route path="/create-transaction" element={<CreateTransaction />} />
        <Route
          path="/update-transaction/:tradeId"
          element={<UpdateTransaction />}
        />
        <Route path="/user-security" element={<UserSecurity />} />
        <Route path="/sessions" element={<RecordedUserSession />} />

        <Route path="/wallet-settings" element={<UserWalletSettings />} />
      </Route>
    </Routes>
  );
};

export default UserRoutes;
