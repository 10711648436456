import backIcon from 'images/back.png';
import styles from './createDepositSettings.module.scss';
import {useNavigate, useParams} from 'react-router-dom';
import {Form, Input, Select, Spin} from 'antd';
import {
  enabledPaymentMethod,
  walletCurrencies,
  walletProviders,
} from 'utils/mock';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useMutation, useQuery} from '@apollo/client';
import {
  CREATE_DEPOSIT_SETTINGS,
  GET_ALL_DEPOSIT_SETTINGS,
  GET_DEPOSIT_SETTING_BY_ID,
  UPDATE_USER_SUGGESTED_AMOUNT,
} from 'graphql/queries/depositSettings';
import {toast} from 'react-toastify';
import {useEffect} from 'react';

const CreateDepositSettings = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [form] = Form.useForm();

  const [addUserSuggestedAmount, {loading: loadingCreate}] = useMutation(
    CREATE_DEPOSIT_SETTINGS,
  );
  const [updateUserSuggestedAmount, {loading: loadingUpdate}] = useMutation(
    UPDATE_USER_SUGGESTED_AMOUNT,
  );

  const {data, loading} = useQuery(GET_DEPOSIT_SETTING_BY_ID, {
    variables: {
      getUserSuggestedAmountsByIdId: id,
    },
  });

  useEffect(() => {
    if (id && data?.getUserSuggestedAmountsById) {
      form.setFieldsValue({
        currency:
          data?.getUserSuggestedAmountsById?.UserSuggestedAmount?.currency,
        provider:
          data?.getUserSuggestedAmountsById?.UserSuggestedAmount?.provider,
        paymentMethod:
          data?.getUserSuggestedAmountsById?.UserSuggestedAmount?.paymentMethod,
        optionOne:
          data?.getUserSuggestedAmountsById?.UserSuggestedAmount?.amounts[0],
        optionTwo:
          data?.getUserSuggestedAmountsById?.UserSuggestedAmount?.amounts[1],
        optionThree:
          data?.getUserSuggestedAmountsById?.UserSuggestedAmount?.amounts[2],
      });
    }
  }, [data?.getUserSuggestedAmountsById]);

  const handleSubmit = values => {
    const amounts = [values?.optionOne, values?.optionTwo, values?.optionThree]
      .map(value => parseFloat(value))
      .filter(value => !isNaN(value));

    if (id) {
      updateUserSuggestedAmount({
        variables: {
          input: {
            amounts,
            id,
          },
        },
        refetchQueries: [{query: GET_ALL_DEPOSIT_SETTINGS}],
      })
        .then(({data: {updateUserSuggestedAmount}}) => {
          if (updateUserSuggestedAmount.__typename === 'Error') {
            return toast.error(updateUserSuggestedAmount.message);
          } else {
            toast.success('Updated Successfully');
            navigate(-1);
          }
        })
        .catch(error => toast.error(error));
    } else {
      addUserSuggestedAmount({
        variables: {
          input: {
            amounts,
            currency: values.currency,
            provider: values.provider,
            paymentMethod: values.paymentMethod,
          },
        },
        refetchQueries: [{query: GET_ALL_DEPOSIT_SETTINGS}],
      })
        .then(({data: {addUserSuggestedAmount}}) => {
          if (addUserSuggestedAmount.__typename === 'Error') {
            return toast.error(addUserSuggestedAmount.message);
          } else {
            toast.success('Created Successfully');
            navigate(-1);
          }
        })
        .catch(error => toast.error(error));
    }
  };

  if (loading && id) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <img src={backIcon} onClick={() => navigate(-1)} />
        <h1>Create Deposit Settings</h1>
      </div>
      <div className={styles.content}>
        <h2>{id ? 'Update' : 'Create'} Deposit Suggestions</h2>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            rules={[{required: true, message: 'This is a required field'}]}
            name="currency"
            className={styles.field}
            label="Currency">
            <Select disabled={id}>
              {walletCurrencies.map(data => (
                <Select.Option key={data.key}>{data.key}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'This is a required field'}]}
            name="provider"
            className={styles.field}
            label="Payment Provider">
            <Select disabled={id}>
              {walletProviders.map(data => (
                <Select.Option key={data.key}>{data.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'This is a required field'}]}
            name="paymentMethod"
            className={styles.field}
            label="Payment Method">
            <Select disabled={id}>
              {enabledPaymentMethod.map(data => (
                <Select.Option key={data.key}>{data.value}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className={styles.deposits}>
            <Form.Item
              rules={[{required: true, message: 'This is a required field'}]}
              name="optionOne"
              label="Deposit Option 1">
              <Input type="number" className={styles.input} />
            </Form.Item>
            <Form.Item name="optionTwo" label="Deposit Option 2">
              <Input type="number" className={styles.input} />
            </Form.Item>
            <Form.Item name="optionThree" label="Deposit Option 3">
              <Input type="number" className={styles.input} />
            </Form.Item>
          </div>
          <SubmitButton
            label={id ? 'Update' : 'Create'}
            disabled={loadingCreate || loadingUpdate}
          />
        </Form>
      </div>
    </div>
  );
};

export default CreateDepositSettings;
