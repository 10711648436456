import styles from '../singleCompany.module.scss';
import backIcon from 'images/back.png';
import {useQuery} from '@apollo/client';
import {GET_USER_SAVINGS_TRANSACTIONS_BY_ID} from 'graphql/queries/savings';
import {Pagination, Table, Tag} from 'antd';
import {formatEnumType} from 'utils/helper';
import moment from 'moment';
import {toast} from 'react-toastify';
import {useState} from 'react';
import {currencySymbols} from 'utils/mock';

const SingleSaverTransactions = ({id, type, setOpenModal}) => {
  console.log(id);
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const {loading, data} = useQuery(GET_USER_SAVINGS_TRANSACTIONS_BY_ID, {
    variables: {
      input: {
        savingsPlanId: id.id,
        page: 1,
        limit,
      },
    },
    fetchPolicy: 'no-cache',
    skip: type !== 'SAVER_TRANSACTIONS',
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
  });

  const handlePageChange = page => {
    if (page === currentPage) return;
    setCurrentPage(page);
  };

  const columns = [
    {
      dataIndex: 'id',
      key: 'id',
      title: 'Transaction ID',
      render: (text, record) => (
        <span>{`${record?.id.slice(0, 4)}...${record?.id.slice(-4)}`}</span>
      ),
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      title: 'Amount',
      render: (text, record) => (
        <span>
          {currencySymbols[record?.currency]?.symbol}{' '}
          {Number(record?.amount?.toFixed(2))?.toLocaleString()}
        </span>
      ),
    },
    {
      dataIndex: 'balanceBefore',
      key: 'balanceBefore',
      title: 'Balance Before',
      render: (text, record) => (
        <span>
          {currencySymbols[record?.currency]?.symbol}{' '}
          {Number(record?.balanceBefore?.toFixed(2))?.toLocaleString()}
        </span>
      ),
    },
    {
      dataIndex: 'balanceAfter',
      key: 'balanceAfter',
      title: 'Balance After',
      render: (text, record) => (
        <span>
          {currencySymbols[record?.currency]?.symbol}{' '}
          {Number(record?.balanceAfter?.toFixed(2))?.toLocaleString()}
        </span>
      ),
    },
    {
      dataIndex: 'transactionType',
      key: 'transactionType',
      title: 'Transaction Type',
      render: (text, record) => (
        <Tag
          color={
            {
              'INTEREST_ACCURAL': 'yellow',
              'INTEREST_PAYOUT': 'purple',
              'SAVINGS_DEPOSIT': 'gold',
              'SAVINGS_WITHDRAWAL': 'magenta',
              'INTEREST_WITHDRAWAL': 'blue',
              'CANCELED_INTEREST': 'red',
            }?.[record?.transactionType]
          }>
          {formatEnumType(record?.transactionType)}
        </Tag>
      ),
    },
    {
      dataIndex: 'transactionCategory',
      key: 'transactionCategory',
      title: 'Transaction Category',
      render: (text, record) => (
        <Tag
          color={
            {
              'INTEREST': 'black',
              'SAVING_BALANCE': 'default',
            }?.[record?.transactionCategory]
          }>
          {formatEnumType(record?.transactionCategory)}
        </Tag>
      ),
    },
    {
      dataIndex: 'date',
      key: 'date',
      title: 'Date',
      render: (text, record) => (
        <span>
          {record?.createdAt
            ? moment(record?.createdAt).format('DD MMM, YYYY - hh:mm A')
            : ''}
        </span>
      ),
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      render: (text, record) => (
        <Tag
          color={
            {
              'SUCCESSFUL': 'green',
              'PENDING': 'orange',
              'FAILED': 'red',
            }?.[record?.status]
          }>
          {' '}
          {record?.status}
        </Tag>
      ),
    },
  ];

  return (
    <div className={styles.transactionsTable}>
      <div className="navigateBack">
        <div
          role="button"
          onClick={() =>
            setOpenModal({type: 'INFO', isOpen: true, content: id})
          }>
          <img src={backIcon} alt="Go back" />
          <h1>Go back /</h1>
        </div>
        <h1 className={styles.header}>Transactions History</h1>
      </div>
      <div className={styles.table}>
        <Table
          columns={columns}
          loading={loading}
          dataSource={data?.getSavingsPlanTransactions?.data}
          pagination={false}
          className={styles.table}
        />
        <Pagination
          showSizeChanger
          current={currentPage}
          onChange={e => handlePageChange(e)}
          total={data?.getSavingsPlanTransactions?.pagination?.total}
          showQuickJumper
          className="ant-table-pagination"
          pageSize={limit}
          onShowSizeChange={(_, size) => {
            setLimit(size);
          }}
        />
      </div>
    </div>
  );
};

export default SingleSaverTransactions;
