import {useState} from 'react';
import {Input, Form, Switch, Radio} from 'antd';
import styles from './founderCard.module.scss';
import trash from 'images/trash-can-outline.png';
import UploadImage from 'components/Startup/profile/companyImage';
import {Button} from '@dabafinance/react-components';
import './switch.scss';
import {
  CREATE_COMPANY_TEAM_MEMBER,
  DELETE_COMPANY_TEAM_MEMBER,
  UPDATE_COMPANY_FOUNDER,
} from 'graphql/mutations/companyStartups';
import {useMutation} from '@apollo/client';
import {toast} from 'react-toastify';
import {useParams} from 'react-router-dom';

const FounderCard = ({
  teamId,
  DeleteFounder,
  name,
  edit,
  description,
  linkedin,
  role,
  sex,
  CEO,
  image,
  twitter,
}) => {
  const {TextArea} = Input;
  const [imgUrl, setImgUrl] = useState(image || '');
  const [fullname, setFullname] = useState(name);
  const {id} = useParams();
  const [previewUrl, setPreviewUrl] = useState(image || '');
  const [isCEO, setIsCEO] = useState(CEO || false);
  const [jobTitle, setJobTitle] = useState(role);
  const [aboutMember, setAboutMember] = useState(description || '');
  const [gender, setGender] = useState(sex || '');
  const [linkedinUrl, setLinkedinUrl] = useState(linkedin || '');
  const [twitterUrl, setTwitterUrl] = useState(twitter || '');
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [teamMemberId, setTeamMemberId] = useState('');

  const [updateCompanyTeamMember] = useMutation(UPDATE_COMPANY_FOUNDER);
  const [createCompanyTeamMember] = useMutation(CREATE_COMPANY_TEAM_MEMBER);
  const [deleteCompanyTeamMember] = useMutation(DELETE_COMPANY_TEAM_MEMBER);

  const handleSubmit = () => {
    const optionalFields = {};
    if (twitterUrl) optionalFields.twitterUrl = twitterUrl;
    if (linkedinUrl) optionalFields.linkedinUrl = linkedinUrl;
    if (imgUrl) optionalFields.displayImage = imgUrl;
    if (aboutMember) optionalFields.description = aboutMember;
    createCompanyTeamMember({
      variables: {
        input: {
          companyId: id,
          name: fullname,
          role: jobTitle,
          sex: gender,
          isCEO,
          ...optionalFields,
        },
      },
    })
      .then(({data: {createCompanyTeamMember}}) => {
        if (createCompanyTeamMember.__typename === 'Error') {
          toast.error(createCompanyTeamMember.message);
        } else {
          toast.success('Saved Successfully');
          setTeamMemberId(createCompanyTeamMember.id);
          setBtnDisabled(true);
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  const handleDeleteFounder = () => {
    deleteCompanyTeamMember({
      variables: {
        teamMemberId: edit ? teamId : teamMemberId,
        companyId: id,
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: teamId,
          __typename: 'CompanyTeamMember',
        });
        cache.evict({id: normalizedId});
        cache.gc();
      },
    }).then(({data: {deleteCompanyTeamMember}}) => {
      if (deleteCompanyTeamMember.__typename === 'Error') {
        toast.error('Card Deleted Successfully');
      } else {
        toast.success('Deleted Successfully');
      }
    });
    DeleteFounder();
  };

  const handleEdit = () => {
    updateCompanyTeamMember({
      variables: {
        input: {
          companyTeamMemberId: teamId,
          companyId: id,
          displayImage: imgUrl,
          name: fullname,
          role: jobTitle,
          linkedinUrl,
          isCEO,
          sex: gender,
          description: aboutMember,
          twitterUrl,
        },
      },
    }).then(({data: {updateCompanyTeamMember}}) => {
      if (updateCompanyTeamMember.__typename === 'Error') {
        toast.error(updateCompanyTeamMember.message);
      } else {
        toast.success('Updated Successfully');
      }
    });
  };

  const options = [
    {label: 'Male', value: 'MALE'},
    {label: 'Female', value: 'FEMALE'},
  ];

  return (
    <div className={styles['card-container']}>
      <div className={styles['left-grid']}>
        <UploadImage
          previewUrl={previewUrl}
          setPreviewUrl={setPreviewUrl}
          setImgUrl={setImgUrl}
          bucket="company-service-bucket"
        />
        <div style={{height: 100}} />
      </div>
      <div className={styles['right-grid']}>
        <Form layout="vertical">
          <div className={styles.form}>
            <div className={styles['input-width']}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Member name is required',
                  },
                ]}
                label="Full Name">
                <Input
                  defaultValue={name}
                  value={fullname}
                  style={{width: 300}}
                  onChange={e => setFullname(e.target.value)}
                  placeholder="Courtney Henry"
                />
              </Form.Item>
            </div>
            <div className={styles['input-width']}>
              <Form.Item
                label="Job Title"
                className={styles['form-item']}
                rules={[
                  {
                    required: true,
                    message: 'Member Job title is Required',
                  },
                ]}>
                <Input
                  className={styles.date}
                  style={{width: 300}}
                  onChange={e => setJobTitle(e.target.value)}
                  value={jobTitle}
                  defaultValue={role}
                  placeholder={'CTO'}
                />
              </Form.Item>
            </div>
            <div className={styles['input-width']}>
              <Form.Item label="Twitter" name="twitter">
                <Input
                  defaultValue={twitter}
                  onChange={e => setTwitterUrl(e.target.value)}
                  value={twitterUrl}
                  style={{width: 300}}
                  placeholder="https://twitter.com"
                />
              </Form.Item>
            </div>
            <div className={styles['input-width']}>
              <Form.Item label="Linkedin">
                <Input
                  style={{width: 300}}
                  onChange={e => setLinkedinUrl(e.target.value)}
                  value={linkedinUrl}
                  defaultValue={linkedin}
                  placeholder="https://linkedin.com"
                />
              </Form.Item>
            </div>
            <div className={styles['founder-ceo']}>
              <div className={styles['founder-status']}>
                <Form.Item label="CEO">
                  <Switch defaultChecked={CEO} onChange={e => setIsCEO(e)} />
                </Form.Item>
                <Form.Item label="Sex" name="sex">
                  <Radio.Group
                    value={gender}
                    onChange={e => setGender(e.target.value)}
                    defaultValue={sex}
                    options={options}
                    optionType="button"
                  />
                </Form.Item>
              </div>
              <div className={styles['text-area-width']}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Description of member is required',
                    },
                  ]}
                  label="Member Description">
                  <TextArea
                    style={{width: 500}}
                    value={aboutMember}
                    defaultValue={description}
                    onChange={e => setAboutMember(e.target.value)}
                    rows={6}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className={styles['delete-button']}>
            {edit ? (
              <Button
                type="secondary"
                className={styles.button}
                label={'Update'}
                onClick={handleEdit}
              />
            ) : (
              <Button
                type="secondary"
                disabled={btnDisabled}
                className={styles.button}
                label={'Save'}
                onClick={handleSubmit}
              />
            )}
            {edit && (
              <div onClick={handleDeleteFounder}>
                <img src={trash} alt="trash" />
                <span>Remove Member</span>
              </div>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default FounderCard;
