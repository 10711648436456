import {Button} from '@dabafinance/react-components';
import {Table} from 'antd';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {stocksListingData} from 'utils/mock';
import styles from './orders.module.scss';

const StocksOrders = () => {
  const [activeTab, setActiveTab] = useState('ALL');
  const navigate = useNavigate();

  const column = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Ticker',
      dataIndex: 'ticker',
      key: 'ticker',
    },
    {
      title: 'Order Type',
      dataIndex: 'orderType',
      key: 'orderType',
    },
    {
      title: 'Transaction Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Method',
      dataIndex: 'method',
      key: 'method',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'QTY OF SHARES',
      dataIndex: 'shares',
      key: 'shares',
    },
    {
      title: 'Order Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Order Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Actions',
      dataIndex: 'update',
      key: 'update',
      render: () => (
        <Button
          label="Update"
          type="secondary"
          onClick={() => navigate('/dashboard/stocks/update-stocks')}
        />
      ),
    },
  ];

  return (
    <div className={styles.stocksContainer}>
      <h1>STOCK ORDERS</h1>
      <div className={styles.header}>
        <section className={styles.section}>
          <div className={styles['details-toggler']}>
            <span
              className={activeTab === 'ALL' ? styles['active-toggle'] : ''}
              onClick={() => setActiveTab('ALL')}>
              {' '}
              All
            </span>
            <span
              className={
                activeTab === 'TRADABLE' ? styles['active-toggle'] : ''
              }
              onClick={() => setActiveTab('TRADABLE')}>
              {' '}
              Tradable
            </span>
            <span
              className={
                activeTab === 'NOT-TRADABLE' ? styles['active-toggle'] : ''
              }
              onClick={() => setActiveTab('NOT-TRADABLE')}>
              {' '}
              Non-Tradable
            </span>
          </div>
        </section>
      </div>
      <div>
        <Table
          columns={column}
          dataSource={stocksListingData}
          className={styles['user-table']}
          scroll={{x: 'max-content'}}
        />
      </div>
    </div>
  );
};

export {StocksOrders};
