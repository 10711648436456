import {useMutation, useQuery} from '@apollo/client';
import styles from './languageVersion.module.scss';
import {Form, Select, Spin, Table} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import {Button} from '@dabafinance/react-components';
import {useEffect, useState} from 'react';
import backIcon from 'images/back.png';
import {notificationLanguages} from 'utils/constants';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {toast} from 'react-toastify';
import {GET_REWARD_LANGUAGE_VERSION} from 'graphql/queries/rewardScheme';
import {TRANSLATE_REWARD_SCHEME} from 'graphql/mutations/rewardScheme';

const LanguageVersion = () => {
  const [languageVersion, setLanguageVersion] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const {id} = useParams();
  const navigate = useNavigate();

  const {data, loading} = useQuery(GET_REWARD_LANGUAGE_VERSION, {
    variables: {
      getRewardsLanguageVersionsId: id,
    },
    errorPolicy: 'all',
    onError: error => toast.error(error?.message),
  });
  const [translateRewardScheme, {loading: rewardLoading}] = useMutation(
    TRANSLATE_REWARD_SCHEME,
  );

  const {Option} = Select;

  useEffect(() => {
    setLanguageVersion(
      data?.getRewardsLanguageVersions?.languages.map(item => {
        return {
          id: data?.getRewardsLanguageVersions?.rewardScheme,
          title: data?.getRewardsLanguageVersions?.title,
          language: item,
        };
      }),
    );
  }, [data]);

  useEffect(() => {
    const usedLanguages = languageVersion?.map(item => item.language);
    setAvailableLanguages(
      notificationLanguages.filter(lang => !usedLanguages?.includes(lang.key)),
    );
  }, [languageVersion]);

  const handleTranslation = () => {
    translateRewardScheme({
      variables: {
        translateRewardSchemeId: id,
        language: selectedLanguage,
      },
      refetchQueries: [
        {
          query: GET_REWARD_LANGUAGE_VERSION,
          variables: {getRewardsLanguageVersionsId: id},
        },
      ],
    })
      .then(({data: {translateRewardScheme}}) => {
        if (translateRewardScheme.__typename === 'Error') {
          toast.error(translateRewardScheme.message);
        } else {
          setSelectedLanguage('');
          toast.success('Reward Scheme Translated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4 style={{width: 100}}>{record?.title}</h4>
        </div>
      ),
    },
    {
      title: 'Language Version',
      dataIndex: 'language',
      key: 'language',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4 style={{width: 100}}>
            {record?.language === 'EN' ? 'English' : 'French'}
          </h4>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <Button
          onClick={() =>
            navigate(
              `/dashboard/reward-schemes/update/${record?.id}/${record.language}`,
            )
          }
          className={styles.button}
          type="secondary"
          label="Edit Reward Scheme"
        />
      ),
    },
  ];

  return (
    <div>
      <div className={styles.header}>
        <img
          onClick={() => navigate('/dashboard/reward-schemes')}
          src={backIcon}
          alt=""
        />
        <h1>Reward Scheme Language Version</h1>
      </div>
      {loading ? (
        <div className={styles.loading}>
          <Spin size="large" />
          <h1>Loading...</h1>
        </div>
      ) : (
        <div>
          <Form layout="vertical">
            <Form.Item
              label={`${
                !availableLanguages.length
                  ? 'No new language available'
                  : 'Select Language'
              }`}>
              <Select
                placeholder="Select a Language"
                onChange={e => setSelectedLanguage(e)}
                style={{width: 320}}
                name="type"
                disabled={!availableLanguages.length}
                value={selectedLanguage}>
                {availableLanguages?.map(name => (
                  <Option key={name.key} value={name.key}>
                    {name.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <SubmitButton
              disabled={rewardLoading || selectedLanguage === ''}
              label="Translate Reward Scheme"
              handleClick={handleTranslation}
            />
          </Form>
          <Table columns={columns} dataSource={languageVersion} />
        </div>
      )}
    </div>
  );
};

export default LanguageVersion;
