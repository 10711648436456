import {Form, Input} from 'antd';
import styles from '../faq/baseFAQ.module.scss';

import trash from 'images/trash-can-outline.svg';
import plusIcon from 'images/plus-circle.png';
import SubmitButton from 'components/Startup/components/SubmitButton';
import FadeEffect from 'components/animation/fadeEffect';
import {useEffect} from 'react';

export const RiskEsg = ({onSubmit, existingData}) => {
  const [form] = Form.useForm();

  const {TextArea} = Input;

  useEffect(() => {
    if (existingData) {
      form.setFieldsValue({
        riskAndEsg: existingData?.riskAndEsg,
        impactAndEsg: existingData?.impactAndEsg,
      });
    }
  }, [existingData]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Risk &#38; ESG</h1>
      </div>
      <div className={styles.risks}>
        <div>
          <Form
            form={form}
            onFinish={values =>
              onSubmit({
                riskAndEsg: values?.riskAndEsg?.map(data => ({
                  title: data?.title,
                  answer: data?.answer,
                })),
                impactAndEsg: values?.impactAndEsg?.map(data => ({
                  title: data?.title,
                  answer: data?.answer,
                })),
              })
            }
            layout="vertical">
            <Form.List name="riskAndEsg">
              {(fields, {add, remove}) => (
                <>
                  {fields.map(({name}) => (
                    <FadeEffect key={name}>
                      <div className={styles.faq}>
                        <div className={styles['question-style']}>
                          <Form.Item
                            rules={[
                              {required: true, message: 'Please enter a title'},
                            ]}
                            name={[name, 'title']}
                            className={styles.question}
                            label="Title">
                            <Input name="question" />
                          </Form.Item>
                          <img
                            src={trash}
                            onClick={() => remove(name)}
                            alt=""
                          />
                        </div>
                        <Form.Item
                          name={[name, 'answer']}
                          className={styles.question}
                          rules={[
                            {required: true, message: 'Please enter a title'},
                          ]}
                          label="Answer">
                          <TextArea name="answer" rows={5} />
                        </Form.Item>
                      </div>
                    </FadeEffect>
                  ))}
                  <div onClick={add} className={styles['add-faq']}>
                    <img src={plusIcon} alt="add-founder" />
                    <span>Add Risks</span>
                  </div>
                </>
              )}
            </Form.List>
            <div className={styles.header}>
              <h1>Impact &#38; ESG</h1>
            </div>

            <Form.List name="impactAndEsg">
              {(fields, {add, remove}) => (
                <>
                  {fields.map(({name}) => (
                    <FadeEffect key={name}>
                      <div className={styles.faq}>
                        <div className={styles['question-style']}>
                          <Form.Item
                            rules={[
                              {required: true, message: 'Please enter a title'},
                            ]}
                            name={[name, 'title']}
                            className={styles.question}
                            label="Title">
                            <Input name="question" />
                          </Form.Item>
                          <img
                            src={trash}
                            onClick={() => remove(name)}
                            alt=""
                          />
                        </div>
                        <Form.Item
                          name={[name, 'answer']}
                          className={styles.question}
                          rules={[
                            {required: true, message: 'Please enter a title'},
                          ]}
                          label="Answer">
                          <TextArea name="answer" rows={5} />
                        </Form.Item>
                      </div>
                    </FadeEffect>
                  ))}
                  <div onClick={add} className={styles['add-faq']}>
                    <img src={plusIcon} alt="add-founder" />
                    <span>Add Impact</span>
                  </div>
                </>
              )}
            </Form.List>
            <SubmitButton label="Save & Continue" />
          </Form>
        </div>
      </div>
    </div>
  );
};
