import CompanyCreation from 'components/Startup/creation';
import FounderCreation from 'components/Startup/creation/founder';
import ProfileDetails from 'components/Startup/profile';
import Startup from 'components/Startup/startup';
import {Route, Routes} from 'react-router-dom';
import CompanyCategories from 'components/Startup/components/CompanyCategories';
import AddKeyMetric from 'components/Startup/profile/keyMetric/addKeyMetric';
import CompanyStock from 'components/CompanyStock/CompanyStock';

const AdminStartup = () => (
  <Routes>
    <Route path="/" element={<Startup />} />
    <Route path="/profile/:id" element={<ProfileDetails />} />
    <Route path="/add-metric/:id" element={<AddKeyMetric />} />
    <Route path="/categories/:id" element={<CompanyCategories />} />
    <Route path="/create-profile" element={<CompanyCreation />} />
    <Route path="/stocks/:id" element={<CompanyStock />} />
    <Route path="/create-founder/:id" element={<FounderCreation />} />
  </Routes>
);

export {AdminStartup};
