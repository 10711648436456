import {gql} from '@apollo/client';

export const GET_SAVINGS_OVERVIEW_QUERIES = gql`
  query GetGeneralStatistics($filter: OrganizationFilterInput) {
    getGeneralStatistics {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithGeneralStatistics {
        data {
          totalOrganizations
          totalUsers
          totalSavingsProducts
        }
      }
    }
    getOrganizations(filter: $filter) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrganizations {
        data {
          id
          name
          status
          logo
          createdAt
          products {
            name
            id
          }
          userCount
          industry {
            bannerImg
            name
          }
        }
      }
    }
  }
`;

export const GET_SAVING_COMPANIES = gql`
  query GetOrganizations($filter: OrganizationFilterInput) {
    getOrganizations(filter: $filter) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrganizations {
        data {
          id
          name
          createdAt
          logo
          status
          userCount
          industry {
            bannerImg
            name
          }
        }
      }
    }
  }
`;

export const GET_SAVINGS_COMPANY_BY_ID = gql`
  query GetOrganizationById($getOrganizationByIdId: ID!) {
    getOrganizationById(id: $getOrganizationByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrganization {
        data {
          id
          name
          logo
          userCount
          city
          country {
            bannerImg
            name
            id
          }
          industry {
            id
          }
          website
          NoOfEmployees
          companyEmail
          state
          businessAddress
          companyPhoneNumber
          keyContactPersonName
          keyContactPersonEmail
          keyContactPersonPhoneNumber
        }
      }
    }
  }
`;

export const GET_ALL_SAVING_COMPANY_QUERIES = gql`
  query GetAllCountries {
    getAllCountries {
      ... on Error {
        message
        statusCode
      }
      ... on Country {
        id
        name
        icon
      }
    }
    getAllIndustries {
      ... on Error {
        message
        statusCode
      }
      ... on Industry {
        id
        name
        icon
      }
    }
  }
`;

export const GET_ALL_COMPANY_TEAM_MEMBERS = gql`
  query GetOrganizationTeam($filter: UserFilterInput) {
    getOrganizationTeam(filter: $filter) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrganizationUsers {
        data {
          dateOfBirth
          firstName
          email
          id
          imageUrl
          organizationRole
          lastName
          createdAt
          isDeleted
          organizationRole
        }
      }
    }
  }
`;

export const GET_ALL_SAVING_COMPANY_USERS = gql`
  query GetSavingsPlansWithFilter(
    $filter: GetSavingsPlansFilter
    $page: Int
    $limit: Int
  ) {
    getSavingsPlansWithFilter(filter: $filter, page: $page, limit: $limit) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithPaginatedSavingsPlans {
        data {
          user {
            id
            imageUrl
            lastName
            firstName
            email
            createdAt
          }
          savingsProduct {
            id
            name
          }
          organization {
            name
            id
            logo
          }
        }
      }
    }
  }
`;

export const GET_ALL_SAVINGS_COMPANY_PRODUCT = gql`
  query GetSavingsProducts($input: GetSavingsProductsFilter) {
    getSavingsProducts(input: $input) {
      ... on Error {
        message
        statusCode
        type
        errorTypeEnum
        retryAfter
      }
      ... on ResponseWithSavingsProducts {
        message
        data {
          id
          orgId
          name
          issuer
          terms
          disclosure
          savingsType
          config {
            allowedCurrency
          }
          status
          configId
        }
        statistics {
          totalBalance
          activeSavingsCount
          inactiveSavingsCount
          totalSavingsPlans
          totalProducts
        }
        pagination {
          page
          limit
          pages
          total
          hasPrevPage
          hasNextPage
          previous
          next
        }
      }
    }
  }
`;

export const GET_SINGLE_SAVINGS_PRODUCT_BY_ID = gql`
  query AdminGetSavingsProductById(
    $adminGetSavingsProductByIdId: ID!
    $page: Int
    $limit: Int
    $filter: GetSavingsPlansFilter
  ) {
    adminGetSavingsProductById(id: $adminGetSavingsProductByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithSavingsProduct {
        data {
          id
          orgId
          name
          issuer
          terms
          disclosure
          savingsType
          config {
            allowedCurrency
          }
          status
          createdAt
          configId
          visibility
        }
        statistics {
          totalBalance
          activeSavingsCount
          inactiveSavingsCount
          totalSavingsPlans
          totalProducts
        }
      }
    }
    getSavingsPlansWithFilter(page: $page, limit: $limit, filter: $filter) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithPaginatedSavingsPlans {
        pagination {
          total
        }
        data {
          createdAt
          user {
            id
            imageUrl
            lastName
            firstName
            email
            createdAt
          }
          organization {
            name
            id
            logo
          }
          status
          endDate
          totalBalance
          currency
          id
          paidOutInterest
          daysLeft
          savingsProduct {
            name
            savingsType
          }
          transactions {
            amount
            id
            currency
            transactionType
            status
            createdAt
          }
        }
      }
    }
  }
`;

export const GET_USER_SAVINGS_TRANSACTIONS_BY_ID = gql`
  query GetSavingsPlanTransactions($input: SavingPlanTransactionFilter) {
    getSavingsPlanTransactions(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithSavingsPlanTransactions {
        data {
          amount
          balanceAfter
          balanceBefore
          createdAt
          id
          currency
          status
          interestRate
          transactionCategory
          transactionType
        }
        pagination {
          total
        }
      }
    }
  }
`;

export const GET_SAVINGS_PRODUCTS = gql`
  query GetSavingsProducts($input: GetSavingsProductsFilter) {
    getSavingsProducts(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithSavingsProducts {
        data {
          id
          createdAt
          savingsType
          name
          config {
            allowedCurrency
          }
          status
        }
        statistics {
          totalBalance
          activeSavingsCount
          inactiveSavingsCount
          totalSavingsPlans
          totalProducts
        }
      }
    }
  }
`;

export const GET_SAVINGS_PRODUCT_BY_ID = gql`
  query AdminGetSavingsProductById($adminGetSavingsProductByIdId: ID!) {
    adminGetSavingsProductById(id: $adminGetSavingsProductByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithSavingsProduct {
        data {
          id
          issuer
          name
          orgId
          status
          savingsType
          createdAt
          config {
            allowedCurrency
            maxAllowedDepositAmount
            maxAllowedBalance
            interestPayoutFrequency
            interestCalculationType
            endOfDayCutOffTime
            annualInterest
            balanceSummary
            interestAccrualFrequency
            minAllowedDepositAmount
            paymentChannel
          }
        }
      }
    }
  }
`;
