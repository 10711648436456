import {gql} from '@apollo/client';

export const GET_ALL_ACTIVE_ANNOUNCEMENT = gql`
  query GetAllActiveAnnouncements {
    getAllActiveAnnouncements {
      ... on Error {
        message
        statusCode
      }
      ... on AnnouncementArrayType {
        announcement {
          id
          type
          imageUrl
          openUrlOnClick
          announcementUrl
          opportunity {
            id
          }
          report {
            id
          }
          publishedDate
          expiredDate
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_ALL_ANNOUNCEMENTS = gql`
  query GetAllAnnouncements($type: AllAnnouncementEnum) {
    getAllAnnouncements(type: $type) {
      ... on Error {
        message
        statusCode
      }
      ... on AnnouncementArrayType {
        announcement {
          id
          type
          imageUrl
          openUrlOnClick
          announcementUrl
          opportunity {
            id
          }
          report {
            id
          }
          audioUrl
          title
          subTitle
          publishedDate
          expiredDate
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_ANNOUNCEMENT = gql`
  query Error($announcementId: ID!) {
    getAnnouncement(announcementId: $announcementId) {
      ... on Error {
        message
        statusCode
      }
      ... on AnnouncementType {
        announcement {
          id
          type
          imageUrl
          openUrlOnClick
          announcementUrl
          opportunity {
            id
          }
          report {
            id
          }
          audioUrl
          title
          subTitle
          language
          multiLang {
            language
            title
            subTitle
            imageUrl
            announcementUrl
            audioUrl
          }
          publishedDate
          expiredDate
          createdAt
          updatedAt
        }
      }
    }
  }
`;
