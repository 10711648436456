import {gql} from '@apollo/client';

export const GET_REFERRAL_PROGRAM_BY_ID = gql`
  query GetReferralProgramById($referralProgramId: ID!) {
    getReferralProgramById(referralProgramId: $referralProgramId) {
      ... on Error {
        message
        statusCode
      }
      ... on ReferralProgramType {
        referralProgram {
          id
          referralName
          minInvestment
          maxRewardInvites
          referralReward
          isActive
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_ACTIVE_REFERRAL_PROGRAM = gql`
  query GetActiveReferralPrograms {
    getActiveReferralPrograms {
      id
      referralName
      minInvestment
      maxRewardInvites
      referralReward
      isActive
      createdAt
      updatedAt
    }
  }
`;

export const GET_ALL_REFERRAL_PROGRAM = gql`
  query Error {
    getAllReferralPrograms {
      referralName
      minInvestment
      maxRewardInvites
      referralReward
      isActive
      id
    }
  }
`;
