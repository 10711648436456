import {useMutation, useQuery} from '@apollo/client';
import {Dropdown, Pagination, Table, Tag} from 'antd';
import {
  GET_ALL_AFFILIATES_RESERVATIONS,
  SEND_REMINDER_TO_USERS,
} from 'graphql/queries/affiliates';
import styles from '../../affiliates.module.scss';
import dotsIcon from 'images/dots.svg';
import moment from 'moment';
import {useEffect, useState} from 'react';
import DBModal from 'components/modal/modal';
import {Button} from '@dabafinance/react-components';
import {toast} from 'react-toastify';

const AffiliateReservations = () => {
  const [modalContent, setModalContent] = useState({});
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const {data, loading, refetch} = useQuery(GET_ALL_AFFILIATES_RESERVATIONS, {
    variables: {
      filter: {
        page: 1,
        limit: 10,
      },
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const [sendReminderEmail, {loading: loadingReminder}] = useMutation(
    SEND_REMINDER_TO_USERS,
  );

  useEffect(() => {
    setCurrentPage(1);
    refetch({
      filter: {
        page: 1,
        limit,
      },
    });
  }, [limit]);

  const handlePageChange = page => {
    if (page === currentPage) return;
    setCurrentPage(page);
    refetch({
      filter: {
        page,
        limit,
      },
    });
  };

  const columns = [
    {
      title: 'User',
      key: 'user',
      dataIndex: 'user',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <div className={styles['record-name']}>
            <h4>
              {record?.firstName} {record?.lastName}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Phone No.',
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Ticker',
      key: 'ticker',
      dataIndex: 'ticker',
    },
    {
      title: 'Affiliate Name',
      key: 'affiliate',
      dataIndex: 'affiliate',
      render: (text, record) => <>{record?.affiliate?.name}</>,
    },
    {
      title: 'Order Type',
      key: 'type',
      dataIndex: 'type',
      render: (text, render) => (
        <Tag color={render?.orderType === 'MARKET' ? 'black' : 'default'}>
          {render?.orderType}
        </Tag>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (text, render) => (
        <Tag
          color={
            {
              'PENDING': 'orange',
              'COMPLETED': 'green',
              'SUCCESSFUL': 'cyan',
            }[render?.status] || 'blue'
          }
          key={render.id}>
          {render.status}
        </Tag>
      ),
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      render: (text, render) => (
        <span>
          {render?.currency} {render?.amount?.toLocaleString()}
        </span>
      ),
    },
    {
      title: 'Amount with Percentage',
      key: 'amountWithPercentage',
      dataIndex: 'amountWithPercentage',
      render: (text, render) => (
        <span>{render?.amountWithPercentage?.toLocaleString()}</span>
      ),
    },
    {
      title: 'Units',
      key: 'units',
      dataIndex: 'units',
      render: (text, render) => <span>{render?.estimatedUnit}</span>,
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
    },

    {
      title: 'Created Date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (text, record) => (
        <span>{moment(record?.createdAt).format('DD MMMM, YYYY h:mmA')}</span>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (text, record) => (
        <Dropdown
          trigger="hover"
          menu={{
            items: [
              {
                key: 1,
                label: 'Send a Reminder',
                onClick: () => setModalContent({isOpen: true, id: record?.id}),
              },
            ],
          }}>
          <div>
            <img src={dotsIcon} />
          </div>
        </Dropdown>
      ),
    },
  ];

  const handleSubmit = () => {
    sendReminderEmail({
      variables: {
        sendReminderEmailId: modalContent?.id,
      },
    })
      .then(({data: {sendReminderEmail}}) => {
        if (sendReminderEmail.__typename === 'Error') {
          return toast.error(sendReminderEmail.message);
        } else {
          toast.success(sendReminderEmail?.message);
          setModalContent({isOpen: false});
        }
      })
      .catch(error => toast.error(error));
  };

  return (
    <div>
      <div>
        <Table
          columns={columns}
          loading={loading}
          dataSource={data?.getReservations?.data}
          className={styles.table}
          pagination={false}
        />
        <Pagination
          className="ant-table-pagination"
          showSizeChanger
          showQuickJumper
          total={data?.getReservations?.pagination?.total}
          pageSize={limit}
          current={currentPage}
          onChange={e => handlePageChange(e)}
          onShowSizeChange={(_, size) => {
            setLimit(size);
          }}
        />
      </div>
      <DBModal
        isOpen={modalContent?.isOpen}
        handleClose={() => setModalContent({isOpen: false})}
        content={
          <div className={styles.modalContent}>
            <div className={styles.modalTop}>
              <h1>Send Reminder to User</h1>
              <span>Send a Reminder to the User to Complete the Order</span>
            </div>
            <div className={styles.buttons}>
              <Button
                onClick={handleSubmit}
                disabled={loadingReminder}
                label="Yes"
              />
              <Button
                label="No"
                onClick={() => setModalContent({isOpen: false})}
                type="secondary"
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default AffiliateReservations;
