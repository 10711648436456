import {DatePicker, Form, Pagination, Table} from 'antd';
import styles from './recordedUserSessions.module.scss';
import {useQuery} from '@apollo/client';
import {GET_USER_SESSION_RECORDINGS} from 'graphql/queries/users';
import moment from 'moment';
import {toast} from 'react-toastify';
import {useState} from 'react';
import {Button} from '@dabafinance/react-components';
import {useParams} from 'react-router-dom';
import {CopyOutlined} from '@ant-design/icons';

const RecordedUserSession = () => {
  const [copyText, setCopyText] = useState('Copy');
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [form] = Form.useForm();
  const {id} = useParams();

  const {data, loading, refetch} = useQuery(GET_USER_SESSION_RECORDINGS, {
    variables: {
      filter: {
        page: 1,
        limit: 10,
        userId: id,
      },
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onError: error => toast.error(error.message),
  });

  const handlePageChange = page => {
    if (page === currentPage) return;
    setCurrentPage(page);
    refetch({
      filter: {
        page,
        limit,
        userId: id,
      },
    });
  };

  const copyUrl = (documentUrl, e) => {
    if (e) {
      navigator.clipboard.writeText(documentUrl);
      setCopyText('Copied');
    }
  };

  const columns = [
    {
      dataIndex: 'sessionURL',
      key: 'sessionUrl',
      title: 'Session URL',
      render: (text, render) => (
        <a
          href={render?.sessionUrl}
          target="_blank"
          rel="noreferrer"
          className={styles['copy-label']}>
          Open Link in New Tab
        </a>
      ),
    },
    {
      title: 'Copy Link',
      dataIndex: 'documentUrl',
      key: 'documentUrl',
      render: (text, render) => (
        <div
          onClick={e => copyUrl(render?.sessionUrl, e)}
          onMouseLeave={() => setCopyText('Copy')}
          className={styles['copy-label']}>
          Copy Link <CopyOutlined />
          <span className={styles.tooltip}>{copyText}</span>
        </div>
      ),
    },
    {
      dataIndex: 'date',
      key: 'date',
      title: 'Session Time',
      render: (text, render) =>
        moment(render?.createdAt).format('DD MMM YYYY hh:mm:ss A'),
    },
  ];

  const handleFilter = values => {
    setCurrentPage(1);
    refetch({
      filter: {
        page: 1,
        limit,
        userId: id,
        ...(values.startDate && {startDate: moment(values.startDate).format()}),
        ...(values.endDate && {endDate: moment(values.endDate).format()}),
      },
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <h1>User Recorded Sessions</h1>
        <Form
          form={form}
          onFinish={handleFilter}
          className={styles.form}
          layout="horizontal">
          <Form.Item name="startDate" label="Start Date">
            <DatePicker
              allowClear
              format={'YYYY-MM-DD HH:mm:ss'}
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
            />
          </Form.Item>
          <Form.Item name="endDate" label="End Date">
            <DatePicker
              allowClear
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
              format={'YYYY-MM-DD HH:mm:ss'}
            />
          </Form.Item>
          <Button
            onClick={() => form.submit()}
            label="Filter"
            type="secondary"
          />
        </Form>
      </div>
      <div className={styles.table}>
        <Table
          dataSource={data?.getUserSessionRecordings?.data}
          loading={loading}
          pagination={false}
          columns={columns}
        />
        <Pagination
          total={data?.getUserSessionRecordings?.pagination?.total}
          className="ant-table-pagination"
          current={currentPage}
          onChange={e => handlePageChange(e)}
          pageSize={limit}
          showQuickJumper
          onShowSizeChange={(_, size) => {
            setLimit(size);
          }}
        />
      </div>
    </div>
  );
};

export default RecordedUserSession;
