import {useQuery} from '@apollo/client';
import {Dropdown, Pagination, Table, Tag} from 'antd';
import {SEARCH_SEEDED_COMPANY_DOCUMENTS} from 'graphql/queries/stocks';
import {useCallback, useEffect, useState} from 'react';
import {removePrefix, sanitizeInput} from 'utils/helper';
import styles from './updateDocCategory.module.scss';
import {Button, Search} from '@dabafinance/react-components';
import {CopyOutlined} from '@ant-design/icons';
import {ViewUserDocument} from 'pages/adminUser/userDetails/tabs/documents/viewDocument';
import DBModal from 'components/modal/modal';
import filterIcon from 'icons/filter.svg';
import SeededDocumentsFilter from './SeededDocumentFilter';
import moment from 'moment';
import dotsIcon from 'images/dots.svg';
import AddDocumentModal from './modals/AddDocumentModal';

const debounce = func => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 1200);
  };
};

const SeededDocumentsTable = () => {
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewModal, setViewModal] = useState({});
  const [searchedValue, setSearchedValue] = useState('');
  const [filterModal, setFilterModal] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [documentModal, setDocumentModal] = useState({isOpen: false});

  const [copyText, setCopyText] = useState('Copy');

  useEffect(() => {
    handleFilter(filterValues, currentPage);
  }, [limit]);

  const {loading, data, refetch} = useQuery(SEARCH_SEEDED_COMPANY_DOCUMENTS, {
    variables: {
      filters: {
        isAddedToDocumentCenter: false,
        language: 'FR',
        pagination: {
          limit,
          page: 1,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });

  const handleResetPage = () => setCurrentPage(1);

  const handleUserSearch = (searchTerm, pageLimit) => {
    const sanitizedValue = sanitizeInput(searchTerm);
    handleResetPage();
    setSearchedValue(sanitizedValue);
    refetch({
      filters: {
        ...(sanitizedValue && {companyTickers: [sanitizedValue.toUpperCase()]}),
        language: 'FR',
        pagination: {
          limit: pageLimit,
          page: currentPage,
        },
      },
    });
  };

  const copyUrl = (documentUrl, e) => {
    if (e) {
      navigator.clipboard.writeText(documentUrl);
      setCopyText('Copied');
    }
  };

  const optimizedVersion = useCallback(debounce(handleUserSearch), []);

  const handlePageChange = page => {
    if (page === currentPage) return;
    setCurrentPage(page);
    handleFilter(filterValues, page);
  };

  const handleFilter = (values, page) => {
    setFilterValues(values);
    let {language, isAddedToDocumentCenter, categories, startDate, endDate} =
      values;
    if (startDate) {
      startDate = moment(startDate).format();
    }
    if (endDate) {
      endDate = moment(endDate).format();
    }

    refetch({
      filters: {
        ...(searchedValue && {companyTickers: [searchedValue.toUpperCase()]}),
        language: language || 'FR',
        isAddedToDocumentCenter,
        categories,
        ...((startDate || endDate) && {
          seedDateRange: {
            startDate,
            endDate,
          },
        }),
        pagination: {
          limit,
          page,
        },
      },
    });
    setFilterModal(false);
  };

  const columns = [
    {
      title: 'Document Name',
      dataIndex: 'documentName',
      key: 'documentName',
      fixed: 'left',
      render: (text, record) => (
        <div className={styles.nameRowWidth}>
          <span>{removePrefix(record?.documentName)}</span>{' '}
          <Tag color={record?.language === 'EN' ? 'geekblue' : 'magenta'}>
            {record?.language}
          </Tag>
        </div>
      ),
    },

    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text, record) => (
        <p className={styles.rowWidth}>{record?.company?.legalName}</p>
      ),
    },
    {
      title: 'Ticker',
      dataIndex: 'ticker',
      key: 'ticker',
      render: (text, record) => <span>{record?.company?.ticker}</span>,
    },
    {
      title: 'Seeded Category',
      dataIndex: 'documentCategory',
      key: 'documentCategory',
      render: (text, render) => (
        <Tag color="blue">
          {render?.documentCategory?.split('_')?.join(' ')}
        </Tag>
      ),
    },
    {
      title: 'Daba Category',
      dataIndex: 'dabaCategory',
      key: 'dabaCategory',
      render: (text, render) => (
        <Tag color="purple">{render?.dabaCategory?.split('_')?.join(' ')}</Tag>
      ),
    },
    {
      title: 'Document Link',
      dataIndex: 'documentUrl',
      key: 'documentUrl',
      render: (text, render) => (
        <div
          onClick={e => copyUrl(render?.documentLink, e)}
          onMouseLeave={() => setCopyText('Copy')}
          className={styles['copy-label']}>
          Copy Link <CopyOutlined />
          <span className={styles.tooltip}>{copyText}</span>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <div className={styles.actions}>
          <Button
            onClick={() =>
              setViewModal({
                isOpen: true,
                documentUrl: record?.documentLink,
                documentName: removePrefix(record?.documentName),
              })
            }
            type="secondary"
            label="View Document"
            className={styles['deactivate-btn']}
          />
          <Dropdown
            trigger="hover"
            menu={{
              items: [
                {
                  label: 'Add to Company Documents',
                  key: 1,
                  onClick: () =>
                    setDocumentModal({
                      isOpen: true,
                      type: 'COMPANY',
                      contents: record,
                    }),
                },
                {
                  label: 'Add to Stock Exchange Documents',
                  key: 2,
                  onClick: () =>
                    setDocumentModal({
                      isOpen: true,
                      type: 'STOCK',
                      contents: record,
                    }),
                },
                {
                  label: 'Add to User Documents',
                  key: 3,
                  disabled: true,
                },
              ],
            }}>
            <div>
              <img src={dotsIcon} />
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <div className={styles['seeded-documents']}>
      <div className={styles.topNav}>
        <h1 className={styles.pageNumber}>{currentPage}</h1>
        <Search
          onChange={e => {
            optimizedVersion(e, limit);
          }}
          placeholder="Search by Ticker"
        />
        <div className={styles.filterContainer}>
          <div
            onClick={() => setFilterModal(!filterModal)}
            className={styles.filter}>
            <h2>Filter</h2>
            <img src={filterIcon} alt="" />
          </div>
          <div
            style={{display: filterModal ? 'block' : 'none'}}
            className={styles.filterItems}>
            <SeededDocumentsFilter
              handleFilter={handleFilter}
              handleClose={() => setFilterModal(false)}
              reset={handleResetPage}
            />
          </div>
        </div>
      </div>
      <div>
        <Table
          dataSource={data?.getSeededStockExchangeDocuments?.documents}
          loading={loading}
          columns={columns}
          pagination={false}
          scroll={{x: 'auto'}}
        />
        <Pagination
          onChange={e => handlePageChange(e)}
          total={data?.getSeededStockExchangeDocuments?.pagination?.total}
          showSizeChanger
          className="ant-table-pagination"
          current={currentPage}
          showQuickJumper
          onShowSizeChange={(_, size) => {
            setLimit(size);
          }}
        />
      </div>
      <DBModal
        isOpen={viewModal.isOpen}
        handleClose={() => setViewModal({isOpen: false})}
        width={900}
        content={
          <ViewUserDocument
            url={viewModal?.documentUrl}
            documentName={viewModal?.documentName}
          />
        }
      />
      <DBModal
        isOpen={documentModal?.isOpen}
        handleClose={() => setDocumentModal({isOpen: false})}
        width={900}
        content={
          <AddDocumentModal
            contents={documentModal?.contents}
            onClose={() => setDocumentModal({isOpen: false})}
            type={documentModal.type}
          />
        }
      />
    </div>
  );
};

export default SeededDocumentsTable;
