import pageNotFound from 'images/pageNotFound.png';
import styles from './noUpdate.module.scss';
import {Button} from '@dabafinance/react-components';

const NoUpdate = ({title, description, buttonLabel, onClick, btnDisabled}) => {
  return (
    <div className={styles['no-updates']}>
      <img src={pageNotFound} alt="page not found" />
      <h1>{title}</h1>
      <span>{description}</span>
      {buttonLabel && (
        <Button
          disabled={btnDisabled}
          label={buttonLabel}
          className={styles.button}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export {NoUpdate};
