import {Fragment, useState} from 'react';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import styles from './updateListing.module.scss';
import backIcon from 'images/back.png';
import {DatePicker, Form, Input, Select} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import FadeEffect from 'components/animation/fadeEffect';
import plusIcon from 'images/plus-circle.png';
import trash from 'images/trash-can-outline.svg';
import IpoFeeCard from 'pages/campaigns/new/screens/ipo/ipoFeeCard/IpoFeeCard';
import {QuestionCircleFilled} from '@ant-design/icons';

const UpdateStockListing = () => {
  const [date, setDate] = useState('');
  const [currency, setCurrency] = useState('');
  const [priceValues, setPriceValues] = useState([{price: '', fee: ''}]);
  const [ipoFeesValues, setIpoFeesValues] = useState([
    {
      feeName: '',
      feeType: '',
      fixedFeeAmount: '',
      variableFeeAmount: '',
    },
  ]);
  const navigate = useNavigate();

  const handleChangePriceValue = (i, e) => {
    const newFormValues = [...priceValues];
    newFormValues[i][e.target.name] = e.target.value;
    setPriceValues(newFormValues);
  };

  const handleAddPrice = () => {
    setPriceValues([...priceValues, {price: '', fee: ''}]);
  };

  const handleDeletePrice = id => {
    const filteredFormValue = priceValues.filter((item, index) => index !== id);
    setPriceValues(filteredFormValue);
  };

  const handleChangeIpoFeesValues = (index, event) => {
    const newFormValues = [...ipoFeesValues];
    newFormValues[index][event.target.name] = event.target.value;
    setIpoFeesValues(newFormValues);
  };

  const handleChangeIpoFeesType = (index, event) => {
    const newFormValues = [...ipoFeesValues];
    newFormValues[index].feeType = event;
    setIpoFeesValues(newFormValues);
  };

  const handleAddIpoFees = () => {
    setIpoFeesValues([
      ...ipoFeesValues,
      {
        feeName: '',
        feeType: '',
        fixedFeeAmount: '',
        variableFeeAmount: '',
      },
    ]);
  };

  const handleDeleteIpoFees = id => {
    const filteredIpoFee = ipoFeesValues.filter((_, index) => index !== id);
    setIpoFeesValues(filteredIpoFee);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>Update MTN Stock Price</h1>
      </div>
      <Form className={styles.form} layout="vertical">
        <Form.Item label="Currency" name="currency">
          <Select
            style={{width: 320}}
            value={currency}
            onChange={e => setCurrency(e)}>
            <Select.Option>USD</Select.Option>
            <Select.Option>EUR</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="date" label="Date">
          <DatePicker
            name="published Date"
            value={date}
            style={{width: 340}}
            format={'YYYY-MM-DD HH:mm:ss'}
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
            onChange={e => setDate(moment(e).format('YYYY-MM-DD HH:mm:ss'))}
          />
        </Form.Item>
        <div>
          <h2>Key Statistics</h2>
        </div>
        {priceValues.map((element, index) => (
          <Fragment key={index}>
            <FadeEffect>
              <div className={styles['question-style']}>
                <Form.Item
                  style={{width: 320, marginRight: 20}}
                  label="Enter Price">
                  <Input
                    value={element.price || ''}
                    name="price"
                    onChange={e => handleChangePriceValue(index, e)}
                    placeholder="Statistics Name"
                  />
                </Form.Item>
                <Form.Item style={{width: 320}} label="Platform Fee (in %)">
                  <Input
                    name="fee"
                    value={element.fee || ''}
                    onChange={e => handleChangePriceValue(index, e)}
                    placeholder="Statistics Value"
                  />
                </Form.Item>
                <img
                  src={trash}
                  onClick={() => handleDeletePrice(index)}
                  alt=""
                />
              </div>
            </FadeEffect>
          </Fragment>
        ))}
        <div onClick={handleAddPrice} className={styles['add-risks']}>
          <img src={plusIcon} alt="add-founder" />
          <span>Add Statistics</span>
        </div>
        <div>
          <h2>
            Fee Schedule
            <QuestionCircleFilled />
          </h2>
          {ipoFeesValues.map((data, index) => (
            <Fragment key={index}>
              <IpoFeeCard
                feeName={data.feeName || ''}
                feeType={data.feeType}
                fixedFeeAmount={data.fixedFeeAmount}
                variableFeeAmount={data.variableFeeAmount}
                handleChangeIpoFeesValues={e =>
                  handleChangeIpoFeesValues(index, e)
                }
                handleChangeIpoFeesType={e => handleChangeIpoFeesType(index, e)}
                handleDeleteIpoFees={e => handleDeleteIpoFees(index, e)}
              />
            </Fragment>
          ))}
        </div>
        <div onClick={handleAddIpoFees} className={styles['add-risks']}>
          <img src={plusIcon} alt="" />
          <span>Add Fee</span>
        </div>
      </Form>

      <SubmitButton disabled={true} label="Save Changes" />
    </div>
  );
};

export default UpdateStockListing;
