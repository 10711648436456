import {Form, Input} from 'antd';
import trash from 'images/trash-can-outline.svg';
import plusIcon from 'images/plus-circle.png';
import styles from './baseFAQ.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import FadeEffect from 'components/animation/fadeEffect';
import {useEffect} from 'react';

export const BaseFAQ = ({onSubmit, existingValues}) => {
  const [form] = Form.useForm();
  const {TextArea} = Input;

  useEffect(() => {
    if (existingValues && existingValues?.faq?.length) {
      form.setFieldsValue({faq: existingValues?.faq});
    }
  }, [existingValues]);

  return (
    <div className={styles.container}>
      <div>
        <h1 className={styles.title}>FAQ</h1>
      </div>
      <Form
        onFinish={values => {
          onSubmit({
            faq: values?.faq?.map(data => ({
              question: data?.question,
              answer: data?.answer,
            })),
          });
        }}
        form={form}
        layout="vertical">
        <Form.List name="faq">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({name}) => (
                <div className={styles.faq} key={name}>
                  <FadeEffect>
                    <div className={styles['question-style']}>
                      <Form.Item
                        name={[name, 'question']}
                        rules={[
                          {required: true, message: 'This is a required field'},
                        ]}
                        className={styles.question}
                        label="Question">
                        <Input name="question" />
                      </Form.Item>
                      <img src={trash} onClick={() => remove(name)} alt="" />
                    </div>
                    <Form.Item
                      rules={[
                        {required: true, message: 'This is a required field'},
                      ]}
                      name={[name, 'answer']}
                      className={styles.question}
                      label="Answer">
                      <TextArea name="answer" rows={5} />
                    </Form.Item>
                  </FadeEffect>
                </div>
              ))}
              <div onClick={add} className={styles['add-faq']}>
                <img src={plusIcon} alt="add-founder" />
                <span>Add FAQ</span>
              </div>
            </>
          )}
        </Form.List>
        <div>
          <SubmitButton label="Save & Continue" />
        </div>
      </Form>
    </div>
  );
};
