import gql from 'graphql-tag';

export const SEND_NOTIFICATION_TO_ALL_USERS = gql`
  mutation SendNotificationToAllUsers($data: NotificationInput!) {
    sendNotificationToAllUsers(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on Success {
        message
        response
      }
    }
  }
`;

export const SEND_NOTIFICATION_TO_USERS = gql`
  mutation SendNotificationToUsers($data: NotificationInput!) {
    sendNotificationToUsers(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on Success {
        message
        response
      }
    }
  }
`;

export const UPDATE_NOTIFICATIONS = gql`
  mutation UpdatedScheduledNotifications(
    $filters: UpdateScheduleNotificationInput!
  ) {
    updatedScheduledNotifications(filters: $filters) {
      ... on Error {
        message
      }
      ... on Success {
        message
        response
      }
    }
  }
`;

export const GET_SCHEDULED_NOTIFICATIONS = gql`
  query GetAllNotifications($data: ScheduledNotificationQueryInput) {
    getAllNotifications(data: $data) {
      schedule {
        date
      }
      id
      type
      title
      isActive
      status
      category
      createdAt
    }
  }
`;

export const TRANSLATE_TEXT = gql`
  mutation TranslateText(
    $sourceLang: SourceLanguage!
    $sourceText: String!
    $targetLang: TargetLanguage!
  ) {
    translateText(
      sourceLang: $sourceLang
      sourceText: $sourceText
      targetLang: $targetLang
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on Translation {
        targetText
      }
    }
  }
`;

export const UPDATE_NOTIFICATION_CLASS = gql`
  mutation UpsertNotificationClass($input: CreateNotificationClassInput) {
    upsertNotificationClass(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithNotificationClass {
        message
        data {
          id
          name
          key
          active
        }
      }
    }
  }
`;

export const UPDATE_USER_NOTIFICATION_PREFERENCE = gql`
  mutation UpdateUserNotificationPreferences(
    $input: UpdateUserNotificationPreferencesInput
  ) {
    updateUserNotificationPreferences(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUser {
        data {
          email
          id
        }
        message
      }
    }
  }
`;
