import {Dropdown, Menu, Spin, Table} from 'antd';
import styles from './faq.module.scss';
import Funny from 'images/funny.png';
import {Button} from '@dabafinance/react-components';
import pageNotFound from 'images/pageNotFound.png';
import {useMutation, useQuery} from '@apollo/client';
import {GET_ALL_FAQ} from 'graphql/queries/faq';
import {DELETE_FAQ} from 'graphql/mutations/faq';
import {toast} from 'react-toastify';
import {EllipsisOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {NavTabsEnum} from 'utils/constants';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';

const AdminSupportFaq = () => {
  const navigate = useNavigate();

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.FAQ),
  );

  const {loading, data} = useQuery(GET_ALL_FAQ);

  const [deleteFaq] = useMutation(DELETE_FAQ);

  const handleDeleteFaq = id => {
    deleteFaq({
      variables: {
        faqId: id,
      },
      update(cache) {
        cache.modify({
          fields: {
            getAllFaqs() {},
          },
        });
      },
    }).then(({data: {deleteFaq}}) => {
      if (deleteFaq.__typename === 'Error') {
        toast.error(deleteFaq.message);
      } else {
        toast.success('Faq Deleted Successfully');
      }
    });
  };

  const menu = id => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            navigate(`/dashboard/support/faq/view/${id}`);
          }}>
          Preview FAQ
        </Menu.Item>
        {tabOperations?.update && (
          <Menu.Item
            onClick={() => {
              navigate(`/dashboard/support/faq/edit/${id}`);
            }}>
            Edit FAQ
          </Menu.Item>
        )}
        {tabOperations?.delete && (
          <Menu.Item
            onClick={() => {
              handleDeleteFaq(id);
            }}>
            Delete FAQ
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <img src={record.featuredImage} alt="" />
          <h3>{record.question}</h3>
        </div>
      ),
    },
    {
      title: 'Views',
      dataIndex: 'views',
      key: 'views',
      render: () => (
        <div>
          <h4>412</h4>
        </div>
      ),
    },
    {
      title: 'Reaction',
      dataIndex: 'reaction',
      key: 'reaction',
      render: () => (
        <div>
          <h4>123</h4>
        </div>
      ),
    },
    {
      title: 'Avg Reactions',
      dataIndex: 'avgReations',
      key: 'avgReactions',
      render: () => (
        <div>
          <img src={Funny} className={styles.emoji} />
        </div>
      ),
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
      render: (text, record) => <h4>{record.author}</h4>,
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <Dropdown overlay={menu(record.id)} placement="bottomLeft">
          <div className={styles['select-btn']}>
            <EllipsisOutlined />
          </div>
        </Dropdown>
      ),
    },
  ];

  const noUpdates = (
    <div className={styles['no-updates']}>
      <img src={pageNotFound} alt="page not found" />
      <h1>No FAQ Found</h1>
      <span>There are currently no FAQ, you could create one</span>
      <Button
        label="Add New FAQ"
        className={styles.button}
        disabled={!tabOperations.create}
        onClick={() => {
          navigate('/dashboard/support/faq/new');
        }}
      />
    </div>
  );

  return (
    <div className={styles['table-container']}>
      <div className={styles.header}>
        <h1>FAQ</h1>
        <Button
          onClick={() => {
            navigate('/dashboard/support/faq/new');
          }}
          label="Add New FAQ"
          className={styles.button}
          disabled={!tabOperations.create}
        />
      </div>
      <div className={styles['startup-table']}>
        {loading ? (
          <div className={styles.loading}>
            <Spin size="large" />
            <h1>Loading...</h1>
          </div>
        ) : data?.getAllFaqs.length >= 1 ? (
          <div>
            <Table dataSource={data?.getAllFaqs} columns={columns} />
          </div>
        ) : (
          noUpdates
        )}
      </div>
    </div>
  );
};

export {AdminSupportFaq};
