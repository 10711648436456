import {useState} from 'react';
import styles from './createGroup.module.scss';
import backIcon from 'images/back.png';
import UploadImage from 'components/Startup/profile/companyImage';
import {Form, Input, Select, Spin, Switch} from 'antd';
import {useNavigate} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {GET_ALL_TOPICS} from 'graphql/queries/investorUpdates';
import {CREATE_GROUP} from 'graphql/mutations/groups';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {GET_ALL_GROUPS} from 'graphql/queries/groups';
import {toast} from 'react-toastify';
import {UserSelectField} from 'components/userSelectField/UserSelectField';

const CreateGroup = () => {
  const [imgUrl, setImgUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [privacy, setPrivacy] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [interests, setInterests] = useState([]);
  const [allowDocumentUpload, setAllowDocumentUpload] = useState({
    admins: false,
    nonAdmins: false,
  });
  const [allowDownload, setAllowDownload] = useState({
    admins: false,
    nonAdmins: false,
  });
  const [allowScreenRecord, setAllowScreenRecord] = useState({
    admins: false,
    nonAdmins: false,
  });
  const navigate = useNavigate();
  const {Option} = Select;
  const {TextArea} = Input;
  const width = 732;

  const {data, loading} = useQuery(GET_ALL_TOPICS);
  const [createGroup, {loading: loadingGroup}] = useMutation(CREATE_GROUP);

  const handleCreateGroup = () => {
    createGroup({
      variables: {
        data: {
          bannerImg: imgUrl,
          description,
          name,
          welcomeMessage,
          admins: admin.map(data => data.split(';')[2]),
          topics: interests?.map(data => data.split(';')[1]),
          isPrivate: privacy === 'PRIVATE',
          settings: {
            allowDocumentUpload,
            allowDownload,
            allowScreenRecord,
          },
        },
      },
      refetchQueries: [{query: GET_ALL_GROUPS}],
    }).then(({data: {createGroup}}) => {
      if (createGroup.__typename === 'Error') {
        toast.error(createGroup.message);
      } else {
        toast.success('Created Successfully');
        navigate('/dashboard/groups');
      }
    });
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1 className={styles['h-style']}>Groups / </h1>
        <span>New Group</span>
      </div>
      <div className={styles['main-content']}>
        <h1>Image</h1>
        <UploadImage
          previewUrl={previewUrl}
          setPreviewUrl={setPreviewUrl}
          setImgUrl={setImgUrl}
          bucket={'group-images-bucket'}
          accept="image/*"
          large
        />
        <Form
          onFinish={handleCreateGroup}
          className={styles.form}
          layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Name of group required',
              },
            ]}>
            <Input
              onChange={e => setName(e.target.value)}
              value={name}
              style={{width}}
              placeholder="Name of group"
            />
          </Form.Item>
          <Form.Item
            name="privacy"
            label="Privacy"
            rules={[
              {
                required: true,
                message: 'Name of group required',
              },
            ]}>
            <Select
              value={privacy}
              onChange={e => setPrivacy(e)}
              style={{width}}>
              <Option value="PUBLIC">Public</Option>
              <Option value="PRIVATE">Private</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="description"
            rules={[
              {
                required: true,
                message: 'Description of group required',
              },
            ]}
            label="Description">
            <TextArea
              style={{width}}
              value={description}
              onChange={e => setDescription(e.target.value)}
              placeholder="Group Description"
              rows={4}
            />
          </Form.Item>
          <Form.Item
            name="welcomeMessage"
            rules={[
              {
                required: true,
                message: 'Welcome Message is required',
              },
            ]}
            label="Welcome Message">
            <TextArea
              style={{width}}
              value={welcomeMessage}
              onChange={e => setWelcomeMessage(e.target.value)}
              placeholder="Group Welcome Message"
              rows={4}
            />
          </Form.Item>
          <Form.Item name="interests" label="Interests">
            <Select
              onChange={e => setInterests(e)}
              style={{width}}
              value={interests}
              placeholder="Select Interests"
              mode="multiple">
              {data?.getAllTopics?.map(({name, icon, id}) => (
                <Option key={`${name};${id}`}>
                  <div className={styles['all-data']}>
                    <img src={icon} alt="" />
                    <span>{name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className={styles.width} label="Select Admin" name="admin">
            <UserSelectField users={admin} setUsers={setAdmin} />
          </Form.Item>
          <div>
            <h1 className={styles['header-text']}>Group Settings</h1>
          </div>
          <div>
            <h3 className={styles['header-subtext']}>Allow Document Upload</h3>
          </div>
          <div className={styles['settings-grid']}>
            <Form.Item
              name="allowUploadAdmins"
              label="Admins"
              valuePropName="checked">
              <Switch
                checked={allowDocumentUpload.admins}
                onChange={e =>
                  setAllowDocumentUpload({...allowDocumentUpload, admins: e})
                }
              />
            </Form.Item>
            <Form.Item
              name="allowUploadNonAdmins"
              label="Members"
              valuePropName="checked">
              <Switch
                checked={allowDocumentUpload.nonAdmins}
                onChange={e =>
                  setAllowDocumentUpload({...allowDocumentUpload, nonAdmins: e})
                }
              />
            </Form.Item>
          </div>
          <div>
            <h3 className={styles['header-subtext']}>Allow Downloads</h3>
          </div>
          <div className={styles['settings-grid']}>
            <Form.Item
              name="allowDownloadAdmins"
              label="Admins"
              valuePropName="checked">
              <Switch
                checked={allowDownload.admins}
                onChange={e => setAllowDownload({...allowDownload, admins: e})}
              />
            </Form.Item>
            <Form.Item
              name="allowDownloadNonAdmins"
              label="Members"
              valuePropName="checked">
              <Switch
                checked={allowDownload.nonAdmins}
                onChange={e =>
                  setAllowDownload({...allowDownload, nonAdmins: e})
                }
              />
            </Form.Item>
          </div>
          <div>
            <h3 className={styles['header-subtext']}>Allow Screen Record</h3>
          </div>
          <div className={styles['settings-grid']}>
            <Form.Item
              name="allowRecordAdmins"
              label="Admins"
              valuePropName="checked">
              <Switch
                checked={allowScreenRecord.admins}
                onChange={e =>
                  setAllowScreenRecord({...allowScreenRecord, admins: e})
                }
              />
            </Form.Item>
            <Form.Item
              name="allowRecordNonAdmins"
              label="Members"
              valuePropName="checked">
              <Switch
                checked={allowScreenRecord.nonAdmins}
                onChange={e =>
                  setAllowScreenRecord({...allowScreenRecord, nonAdmins: e})
                }
              />
            </Form.Item>
          </div>
          <SubmitButton label="Create Group" disabled={loadingGroup} />
        </Form>
      </div>
    </div>
  );
};

export default CreateGroup;
