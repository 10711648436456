import {Form, Input, Spin} from 'antd';

import styles from './companyTranslation.module.scss';

const TranslatedCompanyDetails = ({
  loading,
  onChangeFields,
  fieldKey,
  data,
}) => {
  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading Translation...</h1>
      </div>
    );
  }
  return (
    <div className={styles.root}>
      <Form.Item className={styles['form-item']} label="Short Description">
        <Input.TextArea
          value={data?.description}
          onChange={e => onChangeFields(fieldKey, e)}
          rows={4}
          name="description"
          placeholder="A description about the Company"
        />
      </Form.Item>
      <Form.Item className={styles['form-item']} label="Long Description">
        <Input.TextArea
          value={data?.longDescription}
          onChange={e => onChangeFields(fieldKey, e)}
          name="longDescription"
          rows={8}
          placeholder="A more detailed company description is required"
        />
      </Form.Item>
    </div>
  );
};

export default TranslatedCompanyDetails;
