import {GET_ALL_CAMPAIGNS_IDS} from 'graphql/queries/campaign';
import {GET_ALL_GROUP_IDS} from 'graphql/queries/groups';
import {GET_ALL_REPORT_IDS} from 'graphql/queries/report';
import {GET_ALL_STARTUPS} from 'graphql/queries/startupCompanies';
import {client} from 'index';

export const getWhatsappDynamicLinkData = async type => {
  let response = [];

  try {
    switch (type) {
      case 'SINGLE_OPPORTUNITY': {
        const {data: opportunity} = await client.query({
          query: GET_ALL_CAMPAIGNS_IDS,
        });
        response = opportunity.getAllCampaigns.campaigns;
        break;
      }
      case 'SINGLE_COMPANY': {
        const {data: singleCompany} = await client.query({
          query: GET_ALL_STARTUPS,
        });
        response = singleCompany.getAllCompanies;
        break;
      }
      case 'SINGLE_CIRCLE': {
        const {data: singleCircles} = await client.query({
          query: GET_ALL_GROUP_IDS,
        });
        response = singleCircles.getAllGroups?.groups;
        break;
      }
      case 'SINGLE_REPORT':
      default: {
        const {data: singleReports} = await client.query({
          query: GET_ALL_REPORT_IDS,
        });
        response = singleReports?.getAllReports;
        break;
      }
    }
  } catch (error) {
    console.error(`Error fetching data for ${type}:`, error);
  }

  return response;
};
