import {Form, Input, Switch, Tooltip} from 'antd';
import backIcon from 'images/back.png';
import styles from './companyStock.module.scss';
import {useMutation} from '@apollo/client';
import {UPDATE_COMPANY} from 'graphql/mutations/companyStartups';
import {useParams} from 'react-router-dom';
import {Fragment, useEffect, useState} from 'react';
import IpoFeeCard from 'pages/campaigns/new/screens/ipo/ipoFeeCard/IpoFeeCard';
import plusIcon from 'images/plus-circle.png';
import {toast} from 'react-toastify';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';
import {QuestionCircleOutlined} from '@ant-design/icons';

const CompanyStock = ({setActivePage, update, existingData}) => {
  const [fees, setFees] = useState([]);
  const {id} = useParams();
  const [updateCompany, {loading: updateCompanyLoading}] =
    useMutation(UPDATE_COMPANY);
  const [form] = Form.useForm();
  const pageOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Startups),
  );

  useEffect(() => {
    if (existingData) {
      form.setFieldsValue({
        tradable: existingData?.tradeable,
        fractionalShares: existingData?.fractionalShares,
        minTradableShares: existingData?.minTradableShares,
        maxTradableShares: existingData?.maxTradableShares,
        orderExpirationLimit: existingData?.orderExpirationLimit,
      });
      setFees(
        existingData?.feeSchedule?.map(data => ({
          feeName: data?.feeName,
          feeType: data?.feeType,
          fixedFeeAmount: Number(data?.fixedFeeAmount),
          variableFeeAmount: Number(data?.variableFeeAmount),
        })),
      );
    }
  }, [existingData]);

  const handleChangeBuyingFeesValues = (index, event, values, setValues) => {
    const newFormValues = [...values];
    newFormValues[index][event.target.name] = event.target.value;
    setValues(newFormValues);
  };

  const handleDeleteFees = (id, values, setValues) => {
    const filteredIpoFee = values.filter((_, index) => index !== id);
    setValues(filteredIpoFee);
  };

  const handleChangeFeeType = (index, event, values, setValues) => {
    const newFormValues = [...values];
    newFormValues[index].feeType = event;
    setValues(newFormValues);
  };

  const handleAddFee = () => {
    setFees([
      ...fees,
      {
        feeName: '',
        feeType: '',
        fixedFeeAmount: '',
        variableFeeAmount: '',
      },
    ]);
  };

  const handleSubmit = values => {
    const {minTradableShares, maxTradableShares, tradable, fractionalShares} =
      values;

    let parsedMinTradableShares = null;
    let parsedMaxTradableShares = null;

    // Check if minTradableShares is provided and convert it to a number
    if (minTradableShares !== undefined && minTradableShares !== null) {
      parsedMinTradableShares = Number(minTradableShares);
      if (isNaN(parsedMinTradableShares)) {
        return toast.error('Invalid input for minTradableShares');
      }
    }

    // Check if maxTradableShares is provided and convert it to a number
    if (maxTradableShares !== undefined && maxTradableShares !== null) {
      parsedMaxTradableShares = Number(maxTradableShares);
      if (isNaN(parsedMaxTradableShares)) {
        return toast.error('Invalid input for maxTradableShares');
      }
    }

    updateCompany({
      variables: {
        input: {
          companyId: id,
          ...(parsedMinTradableShares !== null && {
            minTradableShares: parsedMinTradableShares,
          }),
          ...(parsedMaxTradableShares !== null && {
            maxTradableShares: parsedMaxTradableShares,
          }),
          tradeable: tradable != null ? tradable : false,
          orderExpirationLimit: Number(values?.orderExpirationLimit),
          fractionalShares: fractionalShares != null ? fractionalShares : false,
          ...(fees?.length && {
            feeSchedule: fees.map(data => ({
              feeName: data?.feeName,
              feeType: data?.feeType,
              fixedFeeAmount: Number(data?.fixedFeeAmount),
              variableFeeAmount: Number(data?.variableFeeAmount),
            })),
          }),
        },
      },
    })
      .then(({data: {updateCompany}}) => {
        if (updateCompany.__typename === 'Error') {
          toast.error(updateCompany.message);
        } else {
          toast.success('Updated Successfully');
          if (!update) return setActivePage('SOCIALS');
        }
      })
      .catch(error => toast.error(error.message));
  };

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        {!update ? (
          <>
            <div className={styles.nav}>
              <img
                onClick={() => setActivePage('CATEGORIES')}
                src={backIcon}
                alt=""
              />
              <h1>Company Stock Settings</h1>
            </div>
            <h1>Step 3/5</h1>
          </>
        ) : (
          <h2>Update Company Stock Settings</h2>
        )}
      </div>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          className={styles.fieldWidth}
          name="minTradableShares"
          label="Minimum Tradable Shares">
          <Input type="number" />
        </Form.Item>
        <Form.Item
          className={styles.fieldWidth}
          name="maxTradableShares"
          label="Maximum Tradable Shares">
          <Input type="number" />
        </Form.Item>
        <Form.Item
          className={styles.fieldWidth}
          name="orderExpirationLimit"
          label="Order Expiration Limit (Time in Days it takes an order to expire)">
          <Input type="number" placeholder="90" />
        </Form.Item>
        <Form.Item name="tradable" label="Tradable">
          <Switch defaultChecked={existingData?.tradeable} />
        </Form.Item>
        <Form.Item name="fractionalShares" label="Fractional Shares">
          <Switch defaultChecked={existingData?.fractionalShares} />
        </Form.Item>
        <div className={styles.feesHeader}>
          <h4>
            FEE SCHEDULE{' '}
            <Tooltip
              overlayStyle={{width: 1000}}
              title={
                <ul>
                  {' '}
                  <li>
                    - A static fee is one that is charged across orders and will
                    not be refunded irrespective of the eventual order status
                    example: Minimum Platform Charge
                  </li>
                  <br />
                  <li>
                    - A floating fee is one that only applies to MARKET BUY
                    orders and will be refunded if not used or fully used.
                  </li>
                  <br />
                  <li>
                    - Fees apply in the order of order level, stock level, stock
                    exchange level and they override each other in that manner.
                  </li>
                </ul>
              }>
              <QuestionCircleOutlined />
            </Tooltip>
          </h4>
        </div>
        {fees.map((data, index) => (
          <Fragment key={index}>
            <IpoFeeCard
              feeName={data.feeName || ''}
              feeType={data.feeType}
              fixedFeeAmount={data.fixedFeeAmount}
              variableFeeAmount={data.variableFeeAmount}
              type={'COMPANY_STOCK'}
              handleChangeIpoFeesValues={e =>
                handleChangeBuyingFeesValues(index, e, fees, setFees)
              }
              handleChangeIpoFeesType={e =>
                handleChangeFeeType(index, e, fees, setFees)
              }
              handleDeleteIpoFees={() => handleDeleteFees(index, fees, setFees)}
            />
          </Fragment>
        ))}
        <div onClick={handleAddFee} className={styles.add}>
          <img src={plusIcon} alt="" />
          <h5>Add Fee</h5>
        </div>
        <div className={styles.buttons}>
          <SubmitButton
            label="Update"
            disabled={updateCompanyLoading || !pageOperations?.update}
          />
          {!update && (
            <SubmitButton
              label="Skip"
              handleClick={() => setActivePage('SOCIALS')}
            />
          )}
        </div>
      </Form>
    </div>
  );
};

export default CompanyStock;
