import {toast} from 'react-toastify';

export const onTranslateNotification = async (query, translateProps, tab) => {
  const {sourceLang, targetLang, targetText1, targetText2} = translateProps;
  try {
    const translationVariables = [
      {sourceLang, sourceText: targetText1, targetLang},
      {sourceLang, sourceText: targetText2, targetLang},
    ];
    if (tab === 'EMAIL') {
      translationVariables.push({
        sourceLang,
        sourceText: translateProps.targetText3,
        targetLang,
      });
    }
    const {data} = await query({
      variables: {
        translations: translationVariables,
        treatment: 'html',
      },
    });
    return {
      title: data.translateText.translations[0],
      message: data.translateText.translations[1],
      header: data.translateText.translations[2],
      language: targetLang,
    };
  } catch (error) {
    toast.error(`Something went wrong, Error: ${error}`);
  }
};
