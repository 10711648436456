import {
  Link,
  Outlet,
  useMatch,
  useNavigate,
  useResolvedPath,
} from 'react-router-dom';
import backIcon from 'images/back.png';
import classnames from 'classnames/bind';
import styles from './planUsers.module.scss';

const CustomLink = ({to, name}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({path: resolved.pathname, end: true});
  const cx = classnames.bind(styles);
  return (
    <Link className={cx('nav-link', match ? 'active-toggle' : '')} to={to}>
      <span>{name}</span>
    </Link>
  );
};

const AdminPlanUsers = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <h1 className={styles.header}>
          <img
            src={backIcon}
            alt=""
            onClick={() => navigate('/dashboard/plans')}
          />
          <span>Plan /</span>Users List
        </h1>
      </div>
      <section className={styles.section}>
        <nav className={styles.tabs}>
          <CustomLink to="/dashboard/plans/users" name="Starter Users" />
          <CustomLink
            to="/dashboard/plans/users/premium"
            name="Premium Users"
          />
          <CustomLink to="/dashboard/plans/users/free" name="Free Users" />
          <CustomLink to="/dashboard/plans/users/trial" name="Free Trial" />
          <CustomLink to="/dashboard/plans/users/expired" name="Expired" />
        </nav>
      </section>
      <section>
        <Outlet />
      </section>
    </div>
  );
};

export default AdminPlanUsers;
