import {Table} from 'antd';
import styles from '../management/savingsUserManagement.module.scss';
import {useQuery} from '@apollo/client';
import {GET_ALL_SAVING_COMPANY_USERS} from 'graphql/queries/savings';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {toast} from 'react-toastify';
import {SavingsUserProfile} from 'pages/Savings/products/single/SingleSavingsProductOverview';

const SavingsCompanyUsers = () => {
  const {id} = useParams();
  const {data, loading} = useQuery(GET_ALL_SAVING_COMPANY_USERS, {
    variables: {
      page: 1,
      limit: 100,
      filter: {
        orgId: id,
      },
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
  });
  const columns = [
    {
      dataIndex: 'teamMember',
      key: 'nameOfTeam',
      title: 'NAME OF USER',
      render: (text, record) => (
        <SavingsUserProfile
          imageUrl={record?.user?.imageUrl}
          firstName={record?.user?.firstName}
          lastName={record?.user?.lastName}
        />
      ),
    },
    {
      dataIndex: 'email',
      key: 'email',
      title: 'EMAIL',
      render: (text, record) => <span>{record?.user?.email}</span>,
    },
    {
      dataIndex: 'Product',
      key: 'Product',
      title: 'PRODUCT',
      render: (text, record) => <span>{record?.savingsProduct?.name}</span>,
    },
    {
      dataIndex: 'Date Added',
      key: 'date',
      title: 'START DATE & TIME',
      render: (text, record) => (
        <span>{moment(record?.createdAt).format('DD MMM, YYYY hh:mm A')}</span>
      ),
    },
  ];
  return (
    <div className={styles.root}>
      <div></div>
      <div>
        <Table
          dataSource={data?.getSavingsPlansWithFilter?.data}
          loading={loading}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default SavingsCompanyUsers;
