import {Form, Input, Select} from 'antd';
import styles from '../ventureFund.module.scss';
import deleteIcon from 'images/delete.png';

const ComparableVentureFundCard = ({
  handleDelete,
  campaign,
  comparableVentureFund,
  handleChange,
  companies,
}) => {
  const {Option} = Select;

  return (
    <>
      <div className={styles['card-container']}>
        <div className={styles['form']}>
          <Form layout="vertical">
            <Form.Item
              label="Name"
              rules={[
                {
                  required: true,
                  message: 'Required Field',
                },
              ]}
              className={styles['field-width']}>
              <Input
                value={comparableVentureFund?.name}
                onChange={e => handleChange('name', e.target.value)}
                defaultValue={campaign?.name}
              />
            </Form.Item>
            <Form.Item
              label="Fund Size"
              rules={[
                {
                  required: true,
                  message: 'Required Field',
                },
              ]}
              className={styles['field-width']}>
              <Input
                value={comparableVentureFund?.fundSize}
                onChange={e => handleChange('fundSize', e.target.value)}
                type="number"
                defaultValue={campaign?.fundSize}
              />
            </Form.Item>
            <Form.Item
              label="Companies Invested In"
              className={styles['field-width']}>
              <Select
                mode="multiple"
                placeholder="select invested companies"
                value={comparableVentureFund?.companiesInvestedIn.map(
                  item => `${item.companyId}~/-${item.imageUrl}`,
                )}
                defaultValue={campaign?.companiesInvestedIn.map(
                  item => `${item.companyId}~/-${item.imageUrl}`,
                )}
                onChange={e => handleChange('companiesInvestedIn', e)}
                name="companiesInvestedIn">
                {companies?.getAllCompanies?.map(company => (
                  <Option key={`${company.id}~/-${company.logoImgURL}`}>
                    <div className={styles['all-data']}>
                      <img src={company.logoImgURL} alt="" />
                      <span>{company.name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              label="Comparable Venture Fund Description"
              rules={[
                {
                  required: true,
                  message: 'Required Field',
                },
              ]}>
              <Input.TextArea
                defaultValue={campaign?.description}
                value={comparableVentureFund?.description}
                rows={6}
                onChange={e => handleChange('description', e.target.value)}
              />
            </Form.Item>
          </Form>
        </div>
        <div className={styles['delete']}>
          <img
            onClick={e => {
              e.stopPropagation();
              handleDelete?.();
            }}
            src={deleteIcon}
            alt="delete-icon"
          />
        </div>
      </div>
    </>
  );
};

export default ComparableVentureFundCard;
