import {useMutation, useQuery} from '@apollo/client';
import {Input, Form, Spin, Select} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import backImgIcon from 'images/back.png';
import {toast} from 'react-toastify';
import styles from '../addCryptoAccount/cryptoAccount.module.scss';
import {UPDATE_CRYPTO_ACCOUNT} from 'graphql/mutations/bankAndCrypto';
import {
  GET_CRYPTO_ACCOUNT_BY_ID,
  GET_CRYPTO_DETAILS,
} from 'graphql/queries/bankAndCrypto';
import CompanyImage from 'components/Startup/profile/companyImage';

const UpdateCryptoAccount = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [previewQrCode, setPreviewQrCode] = useState('');
  const [symbol, setSymbol] = useState('');
  const [network, setNetwork] = useState('');
  const [label, setLabel] = useState('');
  const [qrCode, setQrCode] = useState('');
  const navigate = useNavigate();
  const {id} = useParams();

  const [updateCryptoAccount, {loading: loadingUpdate}] = useMutation(
    UPDATE_CRYPTO_ACCOUNT,
  );
  const {data, loading} = useQuery(GET_CRYPTO_ACCOUNT_BY_ID, {
    variables: {
      getCryptoAccountByIdId: id,
    },
  });

  useEffect(() => {
    if (data?.getCryptoAccountById) {
      setWalletAddress(data?.getCryptoAccountById?.walletAddress);
      setQrCode(data?.getCryptoAccountById?.qrCode);
      setPreviewQrCode(data?.getCryptoAccountById?.qrCode);
      setQrCode(data?.getCryptoAccountById?.qrCode);
      setNetwork(data?.getCryptoAccountById?.network);
      setSymbol(data?.getCryptoAccountById?.symbol);
      setLabel(data?.getCryptoAccountById?.label);
    }
  }, [data?.getCryptoAccountById]);

  const handleSubmit = () => {
    const optionalField = {};
    if (qrCode) optionalField.qrCode = qrCode;
    updateCryptoAccount({
      variables: {
        updateCryptoAccountId: id,
        input: {
          walletAddress: walletAddress.trim(),
          symbol: symbol.trim(),
          network,
          label: label.trim(),
          ...optionalField,
        },
      },
      refetchQueries: [{query: GET_CRYPTO_DETAILS}],
    })
      .then(({data: {updateCryptoAccount}}) => {
        if (updateCryptoAccount.__typename === 'Error') {
          toast.error(updateCryptoAccount.message);
        } else {
          toast.success('Updated Successfully');
          navigate(-1);
        }
      })
      .catch(error => toast.error(error));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <img src={backImgIcon} alt="" onClick={() => navigate(-1)} />
        <h1>Update Crypto Account</h1>
      </div>
      <div className={styles.form}>
        <CompanyImage
          previewUrl={previewQrCode}
          setPreviewUrl={setPreviewQrCode}
          setImgUrl={setQrCode}
          large
          bucket={'qrcode-images-bucket'}
        />
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="walletAddress"
            label="Wallet Address"
            rules={[{min: 20, message: 'Wallet address is not valid'}]}
            className={styles.field}
            initialValue={data?.getCryptoAccountById?.walletAddress}>
            <Input
              defaultValue={data?.getCryptoAccountById?.walletAddress}
              value={walletAddress}
              onChange={e => setWalletAddress(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="label"
            label="Name"
            rules={[{required: true, message: 'Name of wallet is required'}]}
            className={styles.field}
            initialValue={data?.getCryptoAccountById?.label}>
            <Input
              defaultValue={data?.getCryptoAccountById?.label}
              value={label}
              onChange={e => setLabel(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="symbol"
            label="Symbol"
            rules={[{required: true, message: 'Symbol Required'}]}
            className={styles.field}
            initialValue={data?.getCryptoAccountById?.symbol}>
            <Input
              defaultValue={data?.getCryptoAccountById?.symbol}
              value={symbol}
              onChange={e => setSymbol(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="network"
            label="Network"
            rules={[{required: true, message: 'Network Required'}]}
            className={styles.field}
            initialValue={data?.getCryptoAccountById?.network}>
            <Select value={network} onChange={e => setNetwork(e)}>
              <Select.Option value={'ERC 20'}>ERC-20</Select.Option>
              <Select.Option value={'BEP 20'}>BEP-20</Select.Option>
            </Select>
          </Form.Item>
          <SubmitButton disabled={loadingUpdate} label="Update" />
        </Form>
      </div>
    </div>
  );
};

export default UpdateCryptoAccount;
