import styles from './adminNps.module.scss';
import {Table, Spin} from 'antd';
import {Button} from '@dabafinance/react-components';
import {useNavigate} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import pageNotFound from 'images/pageNotFound.png';
import moment from 'moment';
import {GET_ALL_NET_PROMOTER} from 'graphql/queries/nps';
import gamer from 'images/gamer.png';

const NetPromoter = () => {
  const navigate = useNavigate();

  const {data, loading} = useQuery(GET_ALL_NET_PROMOTER);

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <div
            onClick={() => navigate(`/dashboard/user/${record?.user?.id}`)}
            className={styles['record-name']}>
            {record?.user?.imageUrl ? (
              <img src={record?.user?.imageUrl} alt="" />
            ) : (
              <img src={gamer} alt="" />
            )}
            <h4>
              {record?.user?.firstName} {record?.user?.lastName}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: 'Score',
      dataIndex: 'recommendationScore',
      key: 'recommendationScore',
      render: (text, record) => (
        <div>
          <span>{record?.recommendationScore}</span>
        </div>
      ),
    },
    {
      title: 'Feedback',
      dataIndex: 'feedback',
      key: 'feedback',
      render: (text, record) => (
        <div>
          <span>{record?.feedback}</span>
        </div>
      ),
    },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      key: 'creationdate',
      render: (text, record) => (
        <span>{moment(record?.createdAt).format('DD MMMM YYYY')}</span>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <div>
          <Button
            onClick={() => navigate(`/dashboard/nps/${record?.user?.id}`)}
            type="secondary"
            label="View Details"
            className={styles['deactivate-btn']}
          />
        </div>
      ),
    },
  ];

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles['container-wrapper']}>
      <h1>Net Promoter Scores</h1>
      <div className={styles['startup-table']}>
        <div className={styles['startup-header']}></div>
        <div className={styles['table-height']}>
          {data?.getAllNetPromoterScores?.length >= 1 ? (
            <div>
              <Table
                columns={columns}
                dataSource={data?.getAllNetPromoterScores}
              />
            </div>
          ) : (
            <div className={styles['no-update']}>
              <img src={pageNotFound} alt="" />
              <h1>No Net Promotor Score found</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NetPromoter;
