import {Button} from '@dabafinance/react-components';
import {Table} from 'antd';
import {useNavigate} from 'react-router-dom';
import styles from './userTable.module.scss';
import moment from 'moment';
import gamer from 'images/gamer.png';

const UnverifiedUserTable = ({data, loading, pagination, operations}) => {
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <div
            onClick={() => navigate(`/dashboard/user/${record.id}`)}
            className={styles['record-name']}>
            {record.imageUrl ? (
              <img src={record.imageUrl} alt="" />
            ) : (
              <img src={gamer} alt="user" />
            )}
            <h4 style={{width: 100}}>
              {record.firstName} {record.lastName}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>{record.username}</h4>
        </div>
      ),
    },

    {
      title: 'Phone Number',
      dataIndex: 'expiring',
      key: 'expiring',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>
            {record.phoneNumber
              ? `${record?.countryCode}${record?.phoneNumber}`
              : '--'}
          </h4>
        </div>
      ),
    },
    {
      title: 'Profile Completion',
      dataIndex: 'profileCompleted',
      key: 'invested',
      render: (text, record) => (
        <div>
          <span>{record?.profileCompletion?.completionPercentage}%</span>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'activeInvestor',
      key: 'status',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>{record.activeInvestor ? 'Active' : 'Inactive'}</h4>
        </div>
      ),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Joined',
      dataIndex: 'createdAt',
      key: 'joined',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>{moment(record.createdAt).format('DD MMM, YYYY')}</h4>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <Button
          onClick={() => navigate(`/dashboard/user/${record.id}`)}
          disabled={!operations?.update}
          type="secondary"
          label="View details"
        />
      ),
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.header}></div>
      <div>
        <Table
          className={styles['user-table']}
          dataSource={data}
          columns={columns}
          loading={loading}
          pagination={pagination}
        />
      </div>
    </div>
  );
};

export default UnverifiedUserTable;
