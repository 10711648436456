import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ApolloProvider, ApolloClient, InMemoryCache} from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';
import {onError} from '@apollo/client/link/error';
import {from} from 'apollo-link';
import store from './redux/store';
import {setContext} from '@apollo/client/link/context';
import {Provider} from 'react-redux';
import {handlePageError} from 'redux/store/auth';
const authLink = setContext((_, {headers}) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  const token = localStorage.getItem('token');
  if (!token) {
    return;
  }
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});
export const handleGQLErrors = ({graphQLErrors, networkError}) => {
  const errors = [];
  if (graphQLErrors) {
    graphQLErrors.map(({message}) => {
      errors.push(message);
      return true;
    });
  }
  if (networkError) {
    errors.push(networkError.message);
    if (typeof window !== 'undefined' && !window.navigator.onLine) {
      errors.push('Browser is not connected: check your internet connection');
    }
  }
  return errors;
};
const ErrorHandlingLink = onError(gqlErrors => {
  handleGQLErrors(gqlErrors);
  gqlErrors.graphQLErrors?.forEach(({errorTypeEnum, statusCode, message}) => {
    if (errorTypeEnum === 'AUTHORIZATION_ERROR' && statusCode === 403) {
      return store.dispatch(handlePageError({error: {message, statusCode}}));
    } else if (errorTypeEnum === 'INTERNAL_SERVER_ERROR') {
      return store.dispatch(
        handlePageError({error: {message: 'ERROR', statusCode: 401}}),
      );
    }
  });
});

export const client = new ApolloClient({
  link: authLink.concat(
    from([
      ErrorHandlingLink,
      createUploadLink({
        uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
      }),
    ]),
  ), // Chain it with the HttpLink
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV === 'development',
  queryDeduplication: true,
});
ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root'),
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
