import {Form, Input, Select} from 'antd';
import {Fragment, useEffect, useState} from 'react';
import plusIcon from 'images/plus-circle.png';
import styles from './addKeyMetric.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useNavigate, useParams} from 'react-router-dom';
import FadeEffect from 'components/animation/fadeEffect';
import {useMutation, useQuery} from '@apollo/client';
import {GET_ALL_KEY_STAT} from 'graphql/queries/keyStat';
import trash from 'images/trash-can-outline.svg';
import {GET_ALL_STARTUPS} from 'graphql/queries/startupCompanies';
import {ADD_COMPANY_KEY_STAT} from 'graphql/mutations/keyStat';
import {toast} from 'react-toastify';
import {ArrowLeft} from 'icons';

const AddKeyMetric = () => {
  const {id} = useParams();
  const initialData = {
    companyId: id,
    keyStatId: '',
    value: '',
    isComputed: false,
  };
  const [keyStats, setKeyStats] = useState([]);
  const navigate = useNavigate();
  const {Option} = Select;

  const {data: keyStatData} = useQuery(GET_ALL_KEY_STAT);
  const {data: getAllCompanies} = useQuery(GET_ALL_STARTUPS);

  const [updateCompanyKeyMetric] = useMutation(ADD_COMPANY_KEY_STAT);

  useEffect(() => {
    setKeyStats([initialData]);
  }, [keyStatData]);

  const handleFieldChange = (index, field, value) => {
    const newKeyStat = [...keyStats];
    if (field === 'keyStatId') {
      newKeyStat[index] = {
        ...newKeyStat[index],
        [field]: value.split('-')[0],
        isComputed: value.split('-')[1],
      };
    } else {
      newKeyStat[index] = {
        ...newKeyStat[index],
        [field]: value,
      };
    }
    setKeyStats(newKeyStat);
  };

  const handleAddSettings = () => {
    setKeyStats([...keyStats, initialData]);
  };

  const handleDeleteSettings = id => {
    const filteredFormValue = keyStats.filter((item, index) => index !== id);
    setKeyStats(filteredFormValue);
  };

  const handleSave = () => {
    updateCompanyKeyMetric({
      variables: {
        data: keyStats.map(item => {
          return {
            companyId: item?.companyId,
            keyStatId: item?.keyStatId,
            value: item?.value,
          };
        }),
      },
    }).then(({data: {updateCompanyKeyMetric}}) => {
      if (updateCompanyKeyMetric.__typename === 'Error') {
        toast.error(updateCompanyKeyMetric.message);
      } else {
        toast.success('Key Statistic added successfully');
        navigate(`/dashboard/opportunities/startups/profile/${id}`);
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button onClick={() => navigate(-1)}>
          <ArrowLeft />
        </button>
        <h1>Add Key Metric to Company</h1>
      </div>
      <div>
        <Form onFinish={handleSave} layout="vertical">
          <div className={styles['question-style']}></div>
          {keyStats?.map((element, index) => (
            <Fragment key={index}>
              <FadeEffect>
                <div className={styles['question-style']}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Company is required',
                      },
                    ]}
                    style={{width: 320, marginRight: 20}}
                    label="Company"
                    name="name"
                    initialValue={element?.companyId}>
                    <Select
                      value={element?.companyId}
                      placeholder="company"
                      onChange={e => handleFieldChange(index, 'companyId', e)}
                      name={`${index}-company`}
                      defaultValue={element?.companyId}
                      disabled>
                      {getAllCompanies?.getAllCompanies?.map(data => (
                        <Option key={data?.id}>
                          <div className={styles['all-data']}>
                            <span>{data?.name}</span>
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Key Statistics is required',
                      },
                    ]}
                    style={{width: 320, marginRight: 20}}
                    label="Key Statistics"
                    name={`${index}-keyStat`}>
                    <Select
                      value={element?.keyStatId}
                      placeholder="Select a Key stat"
                      onChange={e => handleFieldChange(index, 'keyStatId', e)}>
                      {keyStatData?.getKeyStats?.map(item => (
                        <Option key={`${item.id}-${item?.isComputed}`}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <img
                    src={trash}
                    onClick={() => handleDeleteSettings(index)}
                    alt=""
                  />
                </div>
                {!element?.isComputed && (
                  <div className={styles['question-style']}>
                    <Form.Item
                      style={{width: 320, marginRight: 20}}
                      label="Value">
                      <Input
                        value={element?.value}
                        placeholder="Value"
                        onChange={e =>
                          handleFieldChange(index, 'value', e.target.value)
                        }
                      />
                    </Form.Item>
                  </div>
                )}
              </FadeEffect>
            </Fragment>
          ))}
        </Form>
        <div onClick={handleAddSettings} className={styles['add-risks']}>
          <img src={plusIcon} alt="add-settings" />
          <span>Add Key Metric</span>
        </div>
        <SubmitButton label="Add" handleClick={handleSave} />
      </div>
    </div>
  );
};

export default AddKeyMetric;
