import {useMutation, useQuery} from '@apollo/client';
import {Dropdown, Form, Input, Select, Table, Tag} from 'antd';
import {GET_ALL_COMPANY_TEAM_MEMBERS} from 'graphql/queries/savings';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import styles from './savingsUserManagement.module.scss';
import moment from 'moment';
import {useEffect, useState} from 'react';
import DBModal from 'components/modal/modal';
import dotsIcon from 'images/dots.svg';
import {
  ADD_ORGANIZATION_USER,
  UPDATE_ORGANIZATION_USER,
} from 'graphql/mutations/savings';
import {Button} from '@dabafinance/react-components';
import SubmitButton from 'components/Startup/components/SubmitButton';

const SavingsUserManagement = () => {
  const [formValues, setFormValues] = useState({});
  const [updateModal, setUpdateModal] = useState({isOpen: false});
  const {id} = useParams();
  const [openFinishModal, setOpenFinishModal] = useState(false);
  const [form] = Form.useForm();

  const [createOrganizationUser, {loading: loadingCreate}] = useMutation(
    ADD_ORGANIZATION_USER,
  );
  const [updateOrganizationUser, {loading: loadingUpdate}] = useMutation(
    UPDATE_ORGANIZATION_USER,
  );

  useEffect(() => {
    form.setFieldsValue({
      firstName: formValues?.firstName || '',
      lastName: formValues?.lastName || '',
      email: formValues?.email || '',
      role: formValues?.role || '',
    });
  }, [form]);

  useEffect(() => {
    if (!updateModal.isOpen) {
      setFormValues({});
      form.resetFields();
    }

    if (updateModal.type === 'UPDATE') {
      form.setFieldsValue({
        firstName: formValues?.firstName || '',
        lastName: formValues?.lastName || '',
        email: formValues?.email || '',
        role: formValues?.organizationRole || '',
      });
    }
  }, [updateModal]);

  const {data, loading, refetch} = useQuery(GET_ALL_COMPANY_TEAM_MEMBERS, {
    variables: {
      filter: {
        orgId: id,
        limit: 100,
        page: 1,
      },
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
  });

  const columns = [
    {
      dataIndex: 'teamMember',
      key: 'nameOfTeam',
      title: 'NAME OF TEAM MEMBER',
      render: (text, record) => (
        <div className={styles.imageProfile}>
          {record?.imageUrl ? (
            <img
              src={record.imageUrl}
              alt={`${record?.firstName} ${record?.lastName}`}
            />
          ) : (
            <div className={styles.dummyAvatar}>
              {record?.firstName?.[0]}
              {record?.lastName?.[0]}
            </div>
          )}
          <span>
            {record?.firstName} {record?.lastName}
          </span>
        </div>
      ),
    },
    {
      dataIndex: 'Date Added',
      key: 'date',
      title: 'DATE ADDED',
      render: (text, record) => (
        <span>{moment(record?.createdAt).format('DD MMM, YYYY hh:mm A')}</span>
      ),
    },
    {
      dataIndex: 'email',
      key: 'email',
      title: 'EMAIL',
    },
    {
      dataIndex: 'organizationRole',
      key: 'organizationRole',
      title: 'ROLE',
      render: (text, record) => (
        <Tag color="default">{record?.organizationRole}</Tag>
      ),
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'STATUS',
    },
    {
      dataIndex: 'action',
      key: 'action',
      title: 'ACTIONS',
      render: (text, record) => (
        <Dropdown
          trigger="click"
          menu={{
            items: [
              {
                key: 'edit',
                label: 'Update Team Member',
                onClick: () => {
                  setUpdateModal({
                    isOpen: true,
                    teamId: record?.id,
                    type: 'UPDATE',
                  });
                  setFormValues(record);
                },
              },
              {
                key: 'delete',
                label: 'Delete Team Member',
                disabled: record?.organizationRole === 'OWNER',
                onClick: () => {
                  setUpdateModal({
                    isOpen: true,
                    teamId: record?.id,
                    type: 'DELETE',
                  });
                },
              },
            ],
          }}>
          <img src={dotsIcon} alt="" />
        </Dropdown>
      ),
    },
  ];

  const handleFinish = () => {
    const handleResponse = (response, isUpdate) => {
      const result = isUpdate
        ? response.data.updateOrganizationUser
        : response.data.createOrganizationUser;
      if (result.__typename === 'Error') {
        toast.error(result.message);
      } else {
        toast.success(result?.message);
        form.resetFields();
        setFormValues({});
        refetch();
        setUpdateModal({isOpen: false});
        setOpenFinishModal(false);
      }
    };

    const input = {
      firstName: formValues?.firstName,
      lastName: formValues?.lastName,
      email: formValues?.email,
    };

    if (updateModal.type === 'UPDATE') {
      input.id = updateModal?.teamId;
      updateOrganizationUser({variables: {input}})
        .then(response => handleResponse(response, true))
        .catch(error => toast.error(error));
    } else if (updateModal.type === 'DELETE') {
      input.id = updateModal?.teamId;
      input.isDeleted = true;
      updateOrganizationUser({variables: {input}})
        .then(response => handleResponse(response, true))
        .catch(error => toast.error(error));
    } else {
      input.role = formValues?.role;
      input.orgId = id;
      createOrganizationUser({variables: {input}})
        .then(response => handleResponse(response, false))
        .catch(error => toast.error(error));
    }
  };

  return (
    <div>
      <div className={styles.top}>
        <Button
          onClick={() => setUpdateModal({isOpen: true, type: 'CREATE'})}
          label={`${
            updateModal.type === 'UPDATE' ? 'Update' : 'Add'
          } Team Member`}
        />
      </div>
      <Table
        columns={columns}
        dataSource={data?.getOrganizationTeam?.data}
        loading={loading}
      />
      <div>
        <DBModal
          isOpen={updateModal.isOpen}
          handleClose={() => {
            setUpdateModal({isOpen: false});
            setOpenFinishModal(false);
          }}
          content={
            <div className={styles.updateModal}>
              <h1>
                {updateModal.type === 'UPDATE'
                  ? 'Update'
                  : updateModal.type === 'DELETE'
                  ? 'Delete'
                  : 'Add'}{' '}
                Team Member
              </h1>
              {openFinishModal || updateModal.type === 'DELETE' ? (
                <div className={styles.confirmModal}>
                  <div>
                    <p>Are you sure you want to carry out this Action?</p>
                  </div>
                  <div className="buttons">
                    <Button
                      label="Cancel"
                      type="secondary"
                      onClick={() => {
                        if (updateModal.type === 'DELETE') {
                          return setUpdateModal(false);
                        }
                        setOpenFinishModal(false);
                      }}
                    />
                    <Button
                      disabled={loadingCreate || loadingUpdate}
                      onClick={handleFinish}
                      label="Yes"
                    />
                  </div>
                </div>
              ) : (
                <Form
                  form={form}
                  onFinish={values => {
                    setFormValues(values);
                    setOpenFinishModal(true);
                  }}
                  layout="vertical">
                  <Form.Item
                    className={styles.formItem}
                    rules={[
                      {required: true, message: 'Please enter your first name'},
                    ]}
                    label="First Name"
                    name="firstName">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    className={styles.formItem}
                    rules={[
                      {required: true, message: 'Please enter your last name'},
                    ]}
                    label="Last Name"
                    name="lastName">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    className={styles.formItem}
                    rules={[
                      {
                        required: true,
                        type: 'email',
                        message: 'Please enter your email',
                      },
                    ]}
                    label="Email"
                    name="email">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    className={styles.formItem}
                    rules={[
                      {
                        required: updateModal?.type !== 'UPDATE',
                        message: 'Role is required',
                      },
                    ]}
                    label="Role"
                    initialValue="ADMIN"
                    name="role">
                    <Select
                      disabled={updateModal?.type === 'UPDATE'}
                      defaultValue={'ADMIN'}>
                      <Select.Option value="ADMIN">Admin</Select.Option>
                    </Select>
                  </Form.Item>
                  <SubmitButton
                    label={`Proceed to ${
                      updateModal.type === 'UPDATE' ? 'Update' : 'Create'
                    }`}
                  />
                </Form>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default SavingsUserManagement;
