import {useMutation, useQuery} from '@apollo/client';
import {Button, Search} from '@dabafinance/react-components';
import {Spin, Table, Modal} from 'antd';
import {GET_ALL_GROUPS} from 'graphql/queries/groups';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import deleteIcon from 'images/delete.svg';
import styles from './groups.module.scss';
import {DELETE_GROUP} from 'graphql/mutations/groups';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const {confirm} = Modal;

const AdminGroups = () => {
  const navigate = useNavigate();
  const [groupData, setGroupData] = useState([]);
  const [searchItem, setSearchItem] = useState('');
  const [searchData, setSearchData] = useState([]);

  const {loading, refetch} = useQuery(GET_ALL_GROUPS, {
    onCompleted: data => setGroupData(data?.getAllGroups?.groups),
  });

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Groups),
  );

  const handleSearch = term => {
    setSearchItem(term);
    if (term != '') {
      const newList = groupData?.filter(data => {
        return Object.values(data)
          .join(' ')
          .toLowerCase()
          .includes(term.toLowerCase());
      });
      setSearchData(newList);
    } else {
      searchData(groupData);
    }
  };

  const [deleteGroup] = useMutation(DELETE_GROUP);

  const handleDelete = id => {
    confirm({
      content: 'Are you sure you want to delete this Group?',
      onOk: () => {
        deleteGroup({
          variables: {
            groupId: id,
          },
          update(cache) {
            cache.modify({
              fields: {
                getAllGroups() {},
              },
            });
          },
        }).then(({data: {deleteGroup}}) => {
          if (deleteGroup.__typename === 'Error') {
            toast.error(deleteGroup.message);
          } else {
            toast.success('Deleted Successfully');
            // Updates table data after delete
            refetch();
          }
        });
      },
    });
  };

  const columns = [
    {
      title: 'Group Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <img src={record?.bannerImg} alt="" />
          <h4 style={{width: 80}}>{record?.name}</h4>
        </div>
      ),
    },
    {
      title: 'Privacy',
      dataIndex: 'isPrivate',
      key: 'Privacy',
      render: (text, record) =>
        record?.isPrivate === true ? <span>Private</span> : <span>Public</span>,
    },

    {
      title: 'Members',
      dataIndex: 'members',
      key: 'members',
      render: (text, record) => <span>{record?.memberCount}</span>,
    },
    // @opensaucerer(unnecessary)
    // {
    //   title: 'Interests',
    //   dataIndex: 'interests',
    //   key: 'interests',
    //   render: (text, record) => (
    //     <div className={styles.interests}>
    //       {record?.topics?.length > 1 ? (
    //         record?.topics?.map(name => (
    //           // eslint-disable-next-line react/jsx-key
    //           <div>
    //             <span>{name?.name}</span>
    //           </div>
    //         ))
    //       ) : (
    //         <span style={{textAlign: 'center'}}>--</span>
    //       )}
    //     </div>
    //   ),
    // },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <div className={styles.actions}>
          <div>
            <Button
              onClick={() => navigate(`/dashboard/group/edit/${record?.id}`)}
              type="secondary"
              label="View  Details"
            />
          </div>
          {tabOperations?.delete && (
            <img
              src={deleteIcon}
              role="button"
              onClick={() => handleDelete(record?.id)}
              alt=""
            />
          )}
        </div>
      ),
    },
  ];

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <h1>Groups</h1>
        <Button
          onClick={() => navigate('/dashboard/group/new')}
          label="Create Group"
          disabled={!tabOperations?.create}
          className={styles.button}
        />
      </div>
      <div className={styles.table}>
        <div className={styles.search}>
          <div />
          <Search
            onChange={handleSearch}
            id=""
            placeholder="Search Groups by name"
          />
        </div>
        <Table
          columns={columns}
          dataSource={searchItem.length < 1 ? groupData : searchData}
        />
      </div>
    </div>
  );
};

export {AdminGroups};
