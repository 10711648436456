import {gql} from '@apollo/client';

export const UPDATE_USER_KYC_STATUS = gql`
  mutation UpdateKycStatus(
    $userId: ID!
    $kycStatus: InvestorKycStatus!
    $amlStatus: InvestorKycStatus
  ) {
    updateKycStatus(
      userId: $userId
      kycStatus: $kycStatus
      amlStatus: $amlStatus
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on IdentityCard {
        id
      }
    }
  }
`;

export const GENERATE_USER_REFERRAL_CODE = gql`
  mutation AdminGenerateReferralCode($data: UserCustomReferralInput!) {
    adminGenerateReferralCode(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;
