import {DatePicker, Form, Input, Select} from 'antd';
import styles from './createWebsiteNews.module.scss';
import UploadImage from 'components/Startup/profile/companyImage';
import {useEffect, useState} from 'react';
import {fieldRules} from 'utils/helper';
import {useMutation, useQuery} from '@apollo/client';
import {GET_ALL_TOPICS_COMPANIES} from 'graphql/queries/investorUpdates';
import moment from 'moment';
import {websiteNewsTags} from '../newsTypes';
import backIcon from 'images/back.png';
import {useNavigate, useParams} from 'react-router-dom';
import {
  CREATE_WEBSITE_NEWS,
  UPDATE_WEBSITE_NEWS,
} from 'redux/store/news/queries';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {toast} from 'react-toastify';
import {
  onCreateWebsiteNews,
  onUpdateWebsiteFinishPage,
} from 'redux/store/news/actions';
import {useDispatch, useSelector} from 'react-redux';

const WebsiteNewsFinishPageContents = ({existingNews}) => {
  const [newsState, setNewsState] = useState({
    sourceURL: '',
    sourceName: '',
    keyTake: '',
    websiteTag: '',
    publishedDate: '',
    categories: [],
    companies: [],
  });

  const {
    sourceURL,
    websiteTag,
    sourceName,
    categories,
    publishedDate,
    companies,
  } = newsState;
  const [imgUrl, setImgUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const {Option} = Select;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storedNews = useSelector(state => state?.news);
  const {id: updateId} = useParams();

  const handleChangeNewsState = payload => {
    setNewsState(states => ({...states, ...payload}));
  };

  useEffect(() => {
    if (updateId) {
      handleChangeNewsState({
        sourceName: existingNews?.source?.name,
        sourceURL: existingNews?.source?.url,
        websiteTag: existingNews?.tag,
        categories: existingNews?.categories?.map(category => category?.name),
        companies: existingNews?.companies?.map(company => company?.name),
        publishedDate: existingNews?.publishedDate,
      });
      setImgUrl(existingNews?.featuredImage);
      setPreviewUrl(existingNews?.featuredImage);
    }
  }, [updateId]);

  const {data: getAllTopicsAndCompanies} = useQuery(GET_ALL_TOPICS_COMPANIES);

  const [updateWebsiteNews, {loading: loadingUpdate}] =
    useMutation(UPDATE_WEBSITE_NEWS);

  const [createWebsiteNews, {loading: loadingNews}] =
    useMutation(CREATE_WEBSITE_NEWS);

  const handleUpdate = async () => {
    const updateProps = {
      categories,
      companies,
      imgUrl,
      websiteTag,
    };
    try {
      await onUpdateWebsiteFinishPage(updateWebsiteNews, updateProps, updateId);
      navigate('/dashboard/news/website-news');
    } catch (error) {
      toast.error(error);
    }
  };

  const handleCreate = async () => {
    const createProps = {
      newsState,
      imgUrl,
      storedNews,
      navigate,
      dispatch,
    };

    try {
      await onCreateWebsiteNews(createWebsiteNews, createProps);
    } catch (error) {
      toast.error('Something went wrong. Please login again');
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.nav}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>Create Website News (2/2)</h1>
      </div>
      <div className={styles.content}>
        <h2>News Source and Filters</h2>
        <div className={styles['image-wrapper']}>
          <UploadImage
            setImgUrl={setImgUrl}
            previewUrl={previewUrl}
            setPreviewUrl={setPreviewUrl}
            large
            bucket="investor-updates-bucket"
          />
        </div>
        <Form
          layout="vertical"
          onFinish={updateId ? handleUpdate : handleCreate}>
          <Form.Item
            rules={[fieldRules('Website Tag')]}
            label="Website Tag"
            className={styles.largeWidth}
            initialValue={existingNews?.tag}
            name="tags">
            <Select
              value={websiteTag}
              onChange={e => handleChangeNewsState({websiteTag: e})}
              defaultValue={existingNews?.tag}
              className={styles['select-height']}>
              {websiteNewsTags.map(data => (
                <Option key={data.key}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[fieldRules('Categories')]}
            label="Categories"
            initialValue={existingNews?.categories?.map(
              category => category?.name,
            )}
            className={styles.largeWidth}
            name="categories">
            <Select
              value={categories}
              onChange={e => handleChangeNewsState({categories: e})}
              mode="multiple"
              defaultValue={existingNews?.categories?.map(
                category => category.name,
              )}
              className={styles['select-height']}>
              {getAllTopicsAndCompanies?.getAllTopics?.map(topic => (
                <Option key={topic?.name}>
                  <div className={styles['all-data']}>
                    <img src={topic?.icon} alt="" />
                    <span>{topic?.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Companies"
            initialValue={existingNews?.companies?.map(name => name?.name)}
            name="companies"
            className={styles.largeWidth}>
            <Select
              listHeight={400}
              className={styles['select-height']}
              value={companies}
              defaultValue={existingNews?.companies?.map(name => name?.name)}
              onChange={e => handleChangeNewsState({companies: e})}
              mode="multiple">
              {getAllTopicsAndCompanies?.getAllCompanies?.map(company => (
                <Option key={company?.name}>
                  <div className={styles['all-data']}>
                    <img src={company?.logoImgURL} alt="" />
                    <span>{company?.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          {!updateId && (
            <>
              <div className={styles.alignment}>
                <Form.Item
                  rules={[fieldRules('News Source Name')]}
                  name="source"
                  label="Source">
                  <Input
                    style={{width: 360}}
                    value={sourceName}
                    onChange={e =>
                      handleChangeNewsState({sourceName: e.target.value})
                    }
                  />
                </Form.Item>
                <Form.Item
                  rules={[fieldRules('News Source URL')]}
                  name="source url"
                  label="Source URL">
                  <Input
                    style={{width: 340}}
                    value={sourceURL}
                    onChange={e =>
                      handleChangeNewsState({sourceURL: e.target.value})
                    }
                  />
                </Form.Item>
              </div>
              <div className={styles.alignment}>
                <Form.Item
                  rules={[fieldRules('Published Time')]}
                  name="published date"
                  className={styles.largeWidth}
                  label="Published Time">
                  <DatePicker
                    name="published Date"
                    value={publishedDate}
                    style={{width: 340}}
                    format={'YYYY-MM-DD HH:mm:ss'}
                    showTime={{
                      defaultValue: moment('00:00', 'HH:mm:ss'),
                    }}
                    onChange={e =>
                      handleChangeNewsState({
                        publishedDate: moment(e).format('YYYY-MM-DD HH:mm:ss'),
                      })
                    }
                  />
                </Form.Item>
              </div>
            </>
          )}
          <SubmitButton
            disabled={loadingNews || loadingUpdate}
            label={updateId ? 'Update News' : 'Create News'}
          />
        </Form>
      </div>
    </div>
  );
};

export default WebsiteNewsFinishPageContents;
