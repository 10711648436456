export const websiteNewsTags = [
  {key: 'STARTUP', label: 'Startup'},
  {key: 'VENTURE_CAPITAL', label: 'Venture Capital'},
  {key: 'STOCKS', label: 'Stocks'},
  {key: 'FINANCE', label: 'Finance'},
  {key: 'BUSINESS', label: 'Business'},
  {key: 'ECONOMY', label: 'Economy'},
];

export const websiteNewsTypes = [
  {key: 'BREAKING_NEWS', label: 'Breaking News'},
  {key: 'STARTUP_VENTURE_CAPITAL', label: 'Startup Venture & Capital'},
  {key: 'AFRICAN_STOCKS_AND_FINANCE', label: 'African Stocks & Finance'},
  {key: 'AFRICAN_BUSINESS_AND_ECONOMY', label: 'African Business & Economy'},
];

export const languageObject = {
  content: '',
  keyTake: '',
  language: '',
  title: '',
  type: '',
};

export const defaultLanguages = [
  {label: 'English', key: 'EN'},
  {label: 'French', key: 'FR'},
];
