import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import styles from './baseUserInvites.module.scss';
import {Form, Input, Select, Spin} from 'antd';
import {useQuery} from '@apollo/client';
import {GET_ALL_GROUPS} from 'graphql/queries/groups';
import {UserSelectField} from 'components/userSelectField/UserSelectField';
import {useParams} from 'react-router-dom';

export const BaseUsersInvite = ({handleSave, existingData}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const {Option} = Select;
  const [form] = Form.useForm();
  const {id} = useParams();

  const {loading, data: groupData} = useQuery(GET_ALL_GROUPS);

  useEffect(() => {
    if (existingData) {
      setSelectedUsers(
        existingData.invitedUsers?.map(
          data =>
            `${data?.firstName};${data?.lastName};${data?.id};${data?.email}`,
        ),
      );
      form.setFieldsValue({
        ...existingData,
        invitedGroups: existingData?.invitedGroups?.map(data => data?.id),
      });
    }
  }, [existingData]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div>
        <h1>Invite Users and Groups</h1>
      </div>
      <div>
        <Form
          form={form}
          onFinish={values =>
            handleSave({...values, invitedUsers: selectedUsers})
          }
          className={styles.form}
          layout="vertical">
          <>
            <Form.Item name="users" label="Invite User">
              <UserSelectField
                users={selectedUsers}
                defaultUsers={
                  existingData?.invitedUsers?.length
                    ? id
                      ? existingData?.invitedUsers?.map(data => data?.id)
                      : existingData?.invitedUsers?.map(
                          data => data?.split(';')[2],
                        )
                    : null
                }
                setUsers={setSelectedUsers}
              />
            </Form.Item>
            <Form.Item name="invitedGroups" label="Invite Groups">
              <Select
                allowClear
                mode="multiple"
                showSearch
                filterOption={true}
                optionFilterProp="label"
                placeholder="Invite Groups">
                {groupData?.getAllGroups?.groups?.map(data => (
                  <Option label={data?.name} key={data?.id}>
                    <div className={styles['group-image']}>
                      <img src={data?.bannerImg} alt="" />
                      <span>{data?.name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              name="templateId"
              label="Template ID">
              <Input placeholder="Template id required" />
            </Form.Item>
          </>

          {/* <Form.Item
            className={styles['field-width']}
            name="group"
            label="Select Group">
            <Select
              allowClear
              showSearch
              placeholder="Select a group for this campaign">
              {groupData?.getAllGroups?.groups?.map(data => (
                <Option key={`${data.name};${data.id}`}>
                  <div className={styles['group-image']}>
                    <img src={data.bannerImg} alt="" />
                    <span>{data.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item> */}
          <div>
            <SubmitButton label="Save and Proceed" />
          </div>
        </Form>
      </div>
    </div>
  );
};
