import {Form, InputNumber, Switch} from 'antd';
import styles from '../generalDetails/generalDetails.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect} from 'react';

export const BaseTrading = ({onSubmit, existingValues}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({...existingValues});
  }, [existingValues]);

  return (
    <div className={styles.form}>
      <h1>Trading Settings</h1>
      <Form onFinish={onSubmit} layout="vertical" form={form}>
        <div className={styles.fieldFlex}>
          <Form.Item
            className={styles.number}
            label="Trading Volume"
            name="tradingVolume">
            <InputNumber
              className={styles['input-number']}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            className={styles.number}
            name="orderExpirationLimit"
            label="Order Expiration Limit">
            <InputNumber
              className={styles['input-number']}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            className={styles.number}
            name="maxTradeableUnits"
            label="Max Tradeable Units">
            <InputNumber
              className={styles['input-number']}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            className={styles.number}
            name="minTradeableUnits"
            label="Min Tradeable Units">
            <InputNumber
              className={styles['input-number']}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        </div>
        <Form.Item name="fractionable" label="Fractional">
          <Switch defaultChecked={existingValues?.fractionable} />
        </Form.Item>
        <SubmitButton label="Save & Proceed" />
      </Form>
    </div>
  );
};
