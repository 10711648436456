import {gql} from '@apollo/client';

export const CREATE_FUNDRAISING_COMPANY = gql`
  mutation CreateFundraisingCompany($input: CreateFundraisingCompany!) {
    createFundraisingCompany(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on FundraisingCompany {
        id
        companyId
        legalName
        companyName
        bannerImgURL
        logoImgURL
        accelerator
        companyType
        stage
        foundedOn
        headquarter
        numberOfEmployees
        ticker
        website
        description
        shortDescription
        primaryContactInfo {
          firstName
          lastName
          email
          phoneNumber
        }
      }
    }
  }
`;

export const ADD_SETTLED_TRADES_TO_USERS_PORTFOLIO = gql`
  mutation AddSettledTradesToUsersPortfolio($campaignId: ID!) {
    addSettledTradesToUsersPortfolio(campaignId: $campaignId) {
      ... on Error {
        message
        statusCode
      }
      ... on SettledTradesResponse {
        success
      }
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign($input: CreateCampaign!) {
    createCampaign(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on Campaign {
        id
      }
    }
  }
`;

export const SEND_USER_DOCUMENT = gql`
  mutation SendUserDocument($input: SendUserDocumentInput!) {
    sendUserDocument(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on DocumentPayload {
        message
        response
      }
    }
  }
`;

export const UPDATE_CAMPAIGN_STATUS = gql`
  mutation UpdateCampaignStatus(
    $updateCampaignStatusId: ID!
    $actionType: campaignStatus!
    $campaignMode: CampaignModeEnum!
  ) {
    updateCampaignStatus(
      id: $updateCampaignStatusId
      actionType: $actionType
      campaignMode: $campaignMode
    ) {
      ... on Error {
        type
        statusCode
        message
      }
      ... on Campaign {
        id
      }
    }
  }
`;
export const DELETE_CAMPAIGN = gql`
  mutation DeleteCampaign($deleteCampaignId: ID!) {
    deleteCampaign(id: $deleteCampaignId) {
      ... on Campaign {
        aboutCompany
        id
      }
      ... on Error {
        message
        statusCode
      }
    }
  }
`;

export const TRANSLATE_CAMPAIGN = gql`
  mutation TranslateCampaign(
    $translateCampaignId: ID!
    $targetLanguage: LanguageEnum!
  ) {
    translateCampaign(
      id: $translateCampaignId
      targetLanguage: $targetLanguage
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on TranslateCampignResponse {
        campaignMultilang {
          parent
          language
        }
      }
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign($input: UpdateCampaign!, $updateCampaignId: ID!) {
    updateCampaign(input: $input, id: $updateCampaignId) {
      ... on Error {
        message
        statusCode
      }
      ... on Campaign {
        id
      }
    }
  }
`;

export const UPDATE_FUNDRAISING_COMPANY = gql`
  mutation UpdateFundraisingCompany($input: UpdateFundraisingCompany!) {
    updateFundraisingCompany(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on FundraisingCompany {
        id
      }
    }
  }
`;

export const CREATE_CAMPAIGN_REPORT = gql`
  mutation CreateCampaignReport($input: CampaignReportInput!) {
    createCampaignReport(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCampaignReport {
        message
      }
    }
  }
`;

export const UPDATE_CAMPAIGN_REPORT = gql`
  mutation UpdateCampaignReport($input: updateCampaignReportInput!) {
    updateCampaignReport(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCampaignReport {
        message
      }
    }
  }
`;

export const DELETE_CAMPAIGN_REPORT = gql`
  mutation DeleteCampaignReport($deleteCampaignReportId: ID!) {
    deleteCampaignReport(id: $deleteCampaignReportId) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;

export const SEND_CAMPAIGN_REPORTS_NOW = gql`
  mutation SendCampaignReport($input: SendCampaignReportInput!) {
    sendCampaignReport(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;
