import {Form, Input, Spin, Upload} from 'antd';
import styles from './mobileMoney.module.scss';
import {Button} from '@dabafinance/react-components';
import {
  UploadDocumentToBucket,
  resizeImageFile,
} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import imgUpload from 'images/upload_image.png';

import {useState} from 'react';
import SubmitButton from 'components/Startup/components/SubmitButton';

const MobileMoneyGuide = ({
  index,
  content,
  existingData,
  onClose,
  handleExistingData,
}) => {
  const [progress, setProgress] = useState(0);
  const [loadingImageUpload, setLoadingImageUpload] = useState(false);

  const handleChangeFieldValues = (event, index, name) => {
    const newFormValues = [...existingData];
    newFormValues[index].howToGuide[name] = event;
    handleExistingData(newFormValues);
  };

  const handleDeleteImage = i => {
    const newFormValues = [...existingData];
    newFormValues[i].howToGuide.imgURL = '';
    newFormValues[i].howToGuide.imgURL = '';
    handleExistingData(newFormValues);
  };

  const handleChangeImage = async (i, file) => {
    const temp = file.originFileObj;
    const newFormValues = [...existingData];
    const resizedFile = await resizeImageFile(temp);
    setLoadingImageUpload(true);
    const res = await UploadDocumentToBucket(resizedFile, setProgress);
    setLoadingImageUpload(false);
    newFormValues[i].howToGuide.imgURL = res;
    newFormValues[i].howToGuide.imgURL = res;
    handleExistingData(newFormValues);
    setProgress(0);
  };

  return (
    <div className={styles.modalRoot}>
      <h1>{existingData[index]?.providerName} Operator Guide</h1>
      <Form onFinish={() => onClose()} layout="vertical">
        {content?.imgURL || existingData[index]?.howToGuide?.imgURL ? (
          <div className={styles['c-image-uploaded']}>
            <img
              src={content?.imgURL || existingData[index]?.howToGuide?.imgURL}
              alt=""
            />
            <Button
              className={styles['delete-button']}
              label="Change Image"
              type="secondary"
              onClick={() => handleDeleteImage(index)}
            />
          </div>
        ) : (
          <Upload
            accept=".png, .jpeg, .jpg"
            name="file"
            onChange={e => handleChangeImage(index, e.file)}>
            <div className={styles['c-company-upload']}>
              {loadingImageUpload ? (
                <div className={styles['loading-spinner']}>
                  <h2>{progress}%</h2>
                  <Spin size="large" />
                  <h2>Loading...</h2>
                </div>
              ) : (
                <>
                  <img
                    className={styles['company-image']}
                    src={imgUpload}
                    alt="upload"
                  />
                  <div style={{marginTop: 6}}>Upload Image</div>
                </>
              )}
            </div>
          </Upload>
        )}
        <Form.Item className={styles.modalField} label="Description">
          <Input.TextArea
            rows={4}
            value={content?.description}
            onChange={e =>
              handleChangeFieldValues(e.target.value, index, 'description')
            }
          />
        </Form.Item>
        <Form.Item
          name={`${index}videoURL`}
          className={styles.modalField}
          rules={[{message: 'Provide a valid URL', type: 'url'}]}
          label="Video URL">
          <Input
            value={content?.description}
            onChange={e =>
              handleChangeFieldValues(e.target.value, index, 'link')
            }
          />
        </Form.Item>{' '}
        <Form.Item
          name={`${index}url`}
          className={styles.modalField}
          rules={[{message: 'Provide a valid URL', type: 'url'}]}
          label="Additional Link">
          <Input
            value={content?.description}
            onChange={e =>
              handleChangeFieldValues(e.target.value, index, 'videoURL')
            }
          />
        </Form.Item>
        <SubmitButton label="Save and Close" la />
      </Form>
    </div>
  );
};

export default MobileMoneyGuide;
