import {Form, Input, Spin} from 'antd';
import ReactQuill from 'react-quill';
import styles from './newUpdate.module.scss';
import {QUILL_FORMATS, QUILL_MODULES} from 'utils/constants';
import {Button} from '@dabafinance/react-components';
import {useState} from 'react';

const TranslatedUpdateContents = ({
  title,
  fieldKey,
  multiLangWebsiteLink,
  onChangeFields,
  notificationTitle,
  loading,
  content,
  keyTakeAway,
}) => {
  const fieldWidth = 730;
  const [enableChangeTranslation, setEnableChangeTranslation] = useState(true);

  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading....</h1>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.updateButton}>
        <Button
          onClick={() => setEnableChangeTranslation(!enableChangeTranslation)}
          type="secondary"
          label={
            enableChangeTranslation ? 'Edit Translation' : 'Disable Translation'
          }
        />
      </div>
      <Form.Item label="Title" className={styles.field}>
        <Input
          style={{width: fieldWidth}}
          value={title}
          disabled={enableChangeTranslation}
          name="title"
          onChange={e => onChangeFields(fieldKey, e)}
          placeholder={'Title'}
        />
      </Form.Item>
      <Form.Item label="Notification Title" className={styles.field}>
        <Input
          style={{width: fieldWidth}}
          value={notificationTitle}
          name="notificationTitle"
          disabled={enableChangeTranslation}
          onChange={e => onChangeFields(fieldKey, e)}
          placeholder={'Notification Title'}
        />
      </Form.Item>
      <div className={styles['quill-container']}>
        <span>Content</span>
        <ReactQuill
          className={styles.quill}
          theme="snow"
          readOnly={enableChangeTranslation}
          modules={TranslatedUpdateContents.modules}
          onChange={e => onChangeFields(fieldKey, e, 'content')}
          formats={TranslatedUpdateContents.format}
          placeholder="Write Something"
          value={content}
        />
      </div>
      <div className={styles['quill-container']}>
        <span>Key Takeaway</span>
        <ReactQuill
          className={styles.quill}
          onChange={e => onChangeFields(fieldKey, e, 'keyTakeAway')}
          theme="snow"
          readOnly={enableChangeTranslation}
          modules={TranslatedUpdateContents.modules}
          formats={TranslatedUpdateContents.format}
          placeholder="Write Something"
          value={keyTakeAway}
        />
        <Form.Item label="Multilang Website Link" className={styles.field}>
          <Input
            style={{width: fieldWidth}}
            value={multiLangWebsiteLink}
            name="multiLangWebsiteLink"
            onChange={e => onChangeFields(fieldKey, e)}
            placeholder={'Multilang Website Link'}
          />
        </Form.Item>
      </div>
    </div>
  );
};

TranslatedUpdateContents.modules = QUILL_MODULES;
TranslatedUpdateContents.format = QUILL_FORMATS;

export default TranslatedUpdateContents;
