import {useState, useEffect} from 'react';
import styles from './interestDetails.module.scss';
import {Select, Spin, Form, Input, InputNumber, Tabs} from 'antd';
import {useParams, useNavigate} from 'react-router-dom';
import {GET_TOPIC_BY_ID, GET_ALL_TOPICS} from 'graphql/queries/interest';
import {UPDATE_TOPIC} from 'graphql/mutations/interest';
import {useMutation, useQuery} from '@apollo/client';
import {toast} from 'react-toastify';
import SubmitButton from 'components/Startup/components/SubmitButton';
import UploadImage from 'components/Startup/profile/companyImage';
import {GET_ALL_COMPANY} from 'graphql/queries/report';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {fieldRules} from 'utils/helper';
import {Button} from '@dabafinance/react-components';
import {supportedLanguages} from 'utils/constants';
import {onTranslateTopic} from '../../actions';

export default function InterestDetails() {
  const [icon, setIcon] = useState('');
  const [iconPreviewUrl, setIconPreviewUrl] = useState('');
  const [bannerImg, setBannerImg] = useState('');
  const [bannerPreviewUrl, setBannerPreviewUrl] = useState('');
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [sequence, setSequence] = useState('');
  const [region, setRegion] = useState('');
  const [relatedCompanies, setRelatedCompanies] = useState([]);
  const [enableChangeTranslation, setEnableChangeTranslation] = useState(true);
  const [language, setLanguage] = useState('');
  const [selectedTranslationLanguage, setSelectedTranslationLanguage] =
    useState('');
  const [translatedTopics, setTranslatedTopics] = useState([]);
  const {Option} = Select;

  const id = useParams();
  const width = 730;

  const navigate = useNavigate();

  const {data: getAllCompanies} = useQuery(GET_ALL_COMPANY);
  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );

  const {data, loading} = useQuery(GET_TOPIC_BY_ID, {
    variables: {
      topicId: id.id,
    },
  });

  const [updateTopic, {loading: topicUpdate}] = useMutation(UPDATE_TOPIC);

  useEffect(() => {
    if (data?.getTopic) {
      setName(data?.getTopic?.name);
      setType(data?.getTopic?.type);
      setBannerImg(data?.getTopic?.bannerImg);
      setBannerPreviewUrl(data?.getTopic?.bannerImg);
      setSequence(data?.getTopic?.sequence);
      setDescription(data?.getTopic?.description);
      setIcon(data?.getTopic?.icon);
      setIconPreviewUrl(data?.getTopic?.icon);
      setRegion(data?.getTopic?.region);
      setLanguage(data?.getTopic?.language || 'EN');
      setRelatedCompanies(
        data?.getTopic?.relatedCompanies?.map(
          name => `${name?.name};${name?.id}`,
        ),
      );
    }
  }, [data?.getTopic]);

  useEffect(() => {
    if (data?.getTopic?.multilang?.length > 0) {
      setTranslatedTopics(
        data?.getTopic?.multilang?.map(data => ({
          name: data.name,
          description: data?.description,
          language: data?.language,
        })),
      );
    }
  }, [data?.getTopic?.multilang]);

  const languageToBeTranslatedTo = supportedLanguages.filter(
    key => key.value !== language,
  );

  const handleChangeTranslatedValues = (key, event, name) => {
    let eventName = name;
    let eventValue = name ? event : event.target.value;
    const newFormValues = [...translatedTopics];
    newFormValues[key][eventName] = eventValue;
    setTranslatedTopics(newFormValues);
  };

  const handleUpdate = () => {
    updateTopic({
      variables: {
        input: {
          topicId: id.id,
          name,
          type,
          icon,
          bannerImg,
          description,
          sequence,
          multilang: translatedTopics,
          language,
          region: region || 'NONE',
          relatedCompanies: relatedCompanies.map(data => data.split(';')[1]),
        },
      },
      refetchQueries: [{query: GET_ALL_TOPICS}],
    }).then(({data: {updateTopic}}) => {
      if (updateTopic.__typename === 'Error') {
        toast.error(updateTopic.message);
      } else {
        toast.success('Interest Updated Successfully');
        navigate('/dashboard/interest');
      }
    });
  };

  const handleTranslation = async () => {
    const translateProps = {
      sourceLang: language,
      targetLang: selectedTranslationLanguage,
      targetText1: name,
      targetText2: description,
    };
    try {
      const newValues = [...translatedTopics];

      //Check if translation exists
      if (newValues.length && newValues[0]) {
        // Check if we're trying to translate in the same language
        const languageExists = newValues.some(
          data => data.language === selectedTranslationLanguage,
        );
        if (languageExists) {
          const updateTranslation = await onTranslateTopic(
            translateText,
            translateProps,
          );
          const translationIndex = newValues.findIndex(
            data => data.language === selectedTranslationLanguage,
          );
          newValues[translationIndex].name = updateTranslation.name;
          newValues[translationIndex].description =
            updateTranslation.description;
          newValues[translationIndex].language = updateTranslation.language;
          setTranslatedTopics(newValues);
        }
      } else {
        const triggerTranslation = await onTranslateTopic(
          translateText,
          translateProps,
        );
        if (!triggerTranslation) {
          return toast.error('Something went wrong when Translating');
        }
        newValues.push(triggerTranslation);
        setTranslatedTopics(newValues);
        setTranslatedTopics(newValues);
      }
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles['inner-container']}>
        <div className={styles.section}>
          <h2 className={styles['sub-heading']}>Interest Icon</h2>
          <UploadImage
            setImgUrl={setIcon}
            previewUrl={iconPreviewUrl}
            setPreviewUrl={setIconPreviewUrl}
            bucket="company-service-bucket"
          />
        </div>
        {/* Interest Banner */}
        <div className={styles.section}>
          <h2 className={styles['sub-heading']}>Interest Banner</h2>
          <UploadImage
            setImgUrl={setBannerImg}
            previewUrl={bannerPreviewUrl}
            setPreviewUrl={setBannerPreviewUrl}
            bucket="company-service-bucket"
            large
          />
        </div>
        <Form onFinish={handleUpdate} layout="vertical">
          <Form.Item
            rules={[fieldRules('Language')]}
            label="Language"
            className={styles.field}
            name="language">
            <Select
              style={{width}}
              value={language}
              defaultValue={data?.getTopic?.language}
              onChange={e => setLanguage(e)}>
              {supportedLanguages.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[fieldRules('You must select a language to be translated')]}
            label="Language Translation"
            className={styles.field}
            name="translation">
            <Select
              style={{width}}
              value={selectedTranslationLanguage}
              defaultValue={data?.getTopic?.multilang[0]?.language}
              onChange={e => setSelectedTranslationLanguage(e)}>
              {languageToBeTranslatedTo.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Tabs
            className={styles.tabs}
            tabBarExtraContent={
              selectedTranslationLanguage && (
                <Button
                  disabled={!name || !description || loadingTranslation}
                  label="Translate"
                  onClick={handleTranslation}
                />
              )
            }>
            <Tabs.TabPane tab="Original" key="Original">
              <Form.Item
                label="Name"
                style={{width}}
                className={styles['sub-heading']}
                name="name">
                <Input
                  defaultValue={data?.getTopic?.name}
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder={'Name'}
                />
              </Form.Item>
              <Form.Item
                label="About Interest"
                className={styles['sub-heading']}
                style={{width}}
                name="aboutInterest">
                <Input.TextArea
                  defaultValue={data?.getTopic?.description}
                  value={description}
                  rows={6}
                  onChange={e => setDescription(e.target.value)}
                />
              </Form.Item>
            </Tabs.TabPane>
            {translatedTopics?.length &&
              translatedTopics?.map((data, index) => (
                <Tabs.TabPane
                  key={data.language}
                  tab={
                    supportedLanguages.find(
                      lang => lang.value === data.language,
                    ).label
                  }>
                  <div className={styles.updateButton}>
                    <Button
                      onClick={() =>
                        setEnableChangeTranslation(!enableChangeTranslation)
                      }
                      type="secondary"
                      label={
                        enableChangeTranslation
                          ? 'Edit Translation'
                          : 'Disable Translation'
                      }
                    />
                  </div>
                  <div key={index}>
                    <Form.Item label="Name" className={styles.field}>
                      <Input
                        style={{width}}
                        value={data.name}
                        disabled={enableChangeTranslation}
                        name="name"
                        onChange={e =>
                          handleChangeTranslatedValues(
                            index,
                            e.target.value,
                            'name',
                          )
                        }
                        placeholder={'Name'}
                      />
                    </Form.Item>
                    <Form.Item label="About Interest" className={styles.field}>
                      <Input.TextArea
                        style={{width}}
                        value={data.description}
                        disabled={enableChangeTranslation}
                        onChange={e =>
                          handleChangeTranslatedValues(
                            index,
                            e.target.value,
                            'description',
                          )
                        }
                        rows={5}
                      />
                    </Form.Item>
                  </div>
                </Tabs.TabPane>
              ))}
          </Tabs>
          <Form.Item
            style={{width}}
            label="Category"
            className={styles['sub-heading']}
            name="category">
            <Select
              defaultValue={data?.getTopic?.type}
              value={type}
              onChange={e => setType(e)}>
              <Option value="COUNTRY">Country</Option>
              <Option value="INDUSTRY">Industry</Option>
              <Option value="LIST">List</Option>
              <Option value="GENERAL">General</Option>
            </Select>
          </Form.Item>
          {type === 'COUNTRY' && (
            <div>
              <Form.Item
                style={{width}}
                label="Region"
                className={styles['sub-heading']}>
                <Select
                  defaultValue={data?.getTopic?.region}
                  value={region}
                  onChange={e => setRegion(e)}>
                  <Option value="WEST_AFRICA">West Africa</Option>
                  <Option value="EAST_AFRICA">East Africa</Option>
                  <Option value="NORTH_AFRICA">North Africa</Option>
                  <Option value="SOUTH_AFRICA">South Africa</Option>
                  <Option value="CENTRAL_AFRICA">Central Africa</Option>
                </Select>
              </Form.Item>
              <Form.Item
                style={{width}}
                label="Related Companies"
                className={styles['sub-heading']}>
                <Select
                  value={relatedCompanies}
                  onChange={e => setRelatedCompanies(e)}
                  defaultValue={data?.getTopic?.relatedCompanies?.map(
                    name => `${name?.name};${name?.id}`,
                  )}
                  mode="multiple">
                  {getAllCompanies?.getAllCompanies?.map(company => (
                    <Option key={`${company?.name};${company?.id}`}>
                      <div className={styles['all-data']}>
                        <img src={company?.logoImgURL} alt="" />
                        <span>{company?.name}</span>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          )}
          {type === 'GENERAL' && (
            <Form.Item
              style={{width}}
              label="Related Companies"
              className={styles['sub-heading']}>
              <Select
                value={relatedCompanies}
                onChange={e => setRelatedCompanies(e)}
                defaultValue={data?.getTopic?.relatedCompanies?.map(
                  name => `${name?.name};${name?.id}`,
                )}
                mode="multiple">
                {getAllCompanies?.getAllCompanies?.map(company => (
                  <Option key={`${company?.name};${company?.id}`}>
                    <div className={styles['all-data']}>
                      <img src={company?.logoImgURL} alt="" />
                      <span>{company?.name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label="Ranking"
            style={{width}}
            className={styles['sub-heading']}
            name="ranking">
            <p className={styles['rank-paragraph']}>
              Current rank: <span>{data?.getTopic?.sequence}</span>
            </p>
            <p className={styles['rank-paragraph']}>Enter New Position:</p>
            <InputNumber
              defaultValue={sequence}
              onChange={e => setSequence(e)}
              className={styles['input-number']}
            />
          </Form.Item>
          <SubmitButton
            handleClick={() => handleUpdate()}
            disabled={topicUpdate}
            label="Update Interest"
          />
        </Form>
      </div>
    </div>
  );
}
