import {Form, Input, InputNumber, Select, Tabs} from 'antd';
import styles from './affiliateConfiguration.module.scss';
import {useLazyQuery, useMutation} from '@apollo/client';
import {
  CREATE_AFFILIATE_CONFIG,
  GET_ALL_AFFILIATES,
  UPDATE_AFFILIATE_CONFIG,
} from 'graphql/queries/affiliates';
import {useEffect, useState} from 'react';
import {
  QUILL_FORMATS,
  QUILL_MODULES,
  supportedLanguages,
} from 'utils/constants';
import {Button} from '@dabafinance/react-components';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {
  handleSaveAffiliateConfigurations,
  handleTranslateAffiliateConfig,
} from './actions';
import SubmitButton from 'components/Startup/components/SubmitButton';
import ReactQuill from 'react-quill';
import ConfigurationImageUpload from './ ConfigurationImageUpload';
import {toast} from 'react-toastify';

const AffiliateConfiguration = ({content, type, closeModal}) => {
  const [disclosure, setDisclosure] = useState('');
  const [image, setImage] = useState('');
  const [selectedTranslationLanguage, setSelectedTranslationLanguage] =
    useState('');
  const [multilang, setMultilang] = useState([]);
  const [form] = Form.useForm();

  // Resolvers
  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );
  const [updateAffiliateConfig, {loading: loadingAffiliateUpdate}] =
    useMutation(UPDATE_AFFILIATE_CONFIG);
  const [addAffiliateConfig, {loading: loadingAffiliate}] = useMutation(
    CREATE_AFFILIATE_CONFIG,
  );
  const [
    loadAllAffiliates,
    {data: allAffiliates, loading: loadingAllAffiliates},
  ] = useLazyQuery(GET_ALL_AFFILIATES, {
    variables: {
      filter: {
        page: 1,
        limit: 100,
      },
    },
    onError: error => toast.error(error.message),
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (type === 'CREATE') loadAllAffiliates();
  }, [type]);

  const languageToBeTranslatedTo = supportedLanguages.filter(
    key => key.value !== form.getFieldValue('language'),
  );

  const translateProps = {
    sourceLang: form.getFieldValue('language'),
    targetLang: selectedTranslationLanguage,
    targetText1: form.getFieldValue('header'),
    targetText2: form.getFieldValue('description'),
    targetText3: disclosure,
  };

  useEffect(() => {
    if (content) {
      const {
        header,
        description,
        disclosure,
        language,
        suggestedAmountMultiplier,
        image,
        multilang,
        id,
      } = content;
      setDisclosure(disclosure);
      setImage(image);
      form.setFieldsValue({
        header,
        description,
        language,
        affiliate: id,
        amount1: suggestedAmountMultiplier[0],
        amount2: suggestedAmountMultiplier[1],
        amount3: suggestedAmountMultiplier[2],
        amount4: suggestedAmountMultiplier[3],
        amount5: suggestedAmountMultiplier[4],
      });
      setMultilang(
        multilang?.map(data => ({
          description: data?.description,
          header: data?.header,
          language: data?.language,
          image: data?.image,
          disclosure: data?.disclosure,
        })) || [],
      );
    }
    return () => {
      form.resetFields();
      setDisclosure('');
      setImage('');
      setMultilang([]);
      setSelectedTranslationLanguage('');
    };
  }, [content]);

  const handleChangeTranslatedValues = (key, event, name) => {
    let eventName = name;
    let eventValue = name ? event : event.target.value;
    const newFormValues = multilang.map((item, index) => {
      if (index === key) {
        return {...item, [eventName]: eventValue};
      }
      return item;
    });
    newFormValues[key][eventName] = eventValue;
    setMultilang(newFormValues);
  };

  const handleSubmit = values =>
    handleSaveAffiliateConfigurations(
      values,
      multilang,
      updateAffiliateConfig,
      addAffiliateConfig,
      type !== 'CREATE' ? content?.id : null,
      disclosure,
      image,
      type,
      closeModal,
    );

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <h1>{content?.affiliate?.name} Affiliate Configuration</h1>
      </div>
      <Form onFinish={handleSubmit} form={form} layout="vertical">
        {type === 'CREATE' && (
          <Form.Item
            name="affiliate"
            className={styles.fieldWidth}
            label="Select Affiliate">
            <Select
              disabled={loadingAllAffiliates}
              loading={loadingAllAffiliates}
              optionFilterProp="children"
              allowClear>
              {loadingAllAffiliates && (
                <Select.Option key="Loading">Loading...</Select.Option>
              )}
              {allAffiliates?.getAllAffiliates?.data?.map(data => (
                <Select.Option key={data?.id}>{data?.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="language"
          className={styles.fieldWidth}
          rules={[{required: true, message: 'This is a required field'}]}
          label="Language">
          <Select>
            <Select.Option key="EN">English</Select.Option>
            <Select.Option key="FR">French</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Language Translation"
          rules={[
            {
              required: !content,
              message: 'This is a required field',
            },
          ]}
          className={styles.fieldWidth}
          name="translation">
          <Select
            defaultValue={content?.[0]?.language}
            value={selectedTranslationLanguage}
            onChange={e => setSelectedTranslationLanguage(e)}>
            {languageToBeTranslatedTo.map(data => (
              <Select.Option key={data.value}>{data.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Tabs
          tabBarExtraContent={
            selectedTranslationLanguage && (
              <Button
                disabled={
                  loadingTranslation ||
                  !form.getFieldValue('header') ||
                  !form.getFieldValue('description') ||
                  !disclosure
                }
                label="Translate"
                onClick={() =>
                  handleTranslateAffiliateConfig(
                    translateProps,
                    multilang,
                    setMultilang,
                    translateText,
                  )
                }
              />
            )
          }>
          <Tabs.TabPane tab="Original" key="Original">
            <ConfigurationImageUpload
              previewImage={image}
              handleChangeImage={setImage}
            />
            <Form.Item
              rules={[{required: true, message: 'This is a required field'}]}
              className={styles.fieldWidth}
              name="header"
              label="Affiliate Header">
              <Input />
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'This is a required field'}]}
              className={styles.fieldWidth}
              name="description"
              label="Affiliate Description">
              <Input.TextArea rows={4} />
            </Form.Item>
            <div className={styles['quill-container']}>
              <span>Disclosure</span>
              <ReactQuill
                className={styles.quill}
                theme="snow"
                modules={AffiliateConfiguration.modules}
                onChange={e => setDisclosure(e)}
                formats={AffiliateConfiguration.format}
                placeholder="Write Something"
                value={disclosure}
              />
            </div>
          </Tabs.TabPane>
          {multilang.length &&
            multilang.map((data, index) => (
              <Tabs.TabPane
                key={data.language}
                tab={
                  supportedLanguages.find(lang => lang.value === data.language)
                    .label
                }>
                <div key={index}>
                  <ConfigurationImageUpload
                    previewImage={data?.image}
                    handleChangeImage={img =>
                      handleChangeTranslatedValues(index, img, 'image')
                    }
                  />
                  <Form.Item label="Header" className={styles.fieldWidth}>
                    <Input
                      value={data?.header}
                      onChange={e =>
                        handleChangeTranslatedValues(
                          index,
                          e.target.value,
                          'header',
                        )
                      }
                      placeholder={'Translated Header'}
                    />
                  </Form.Item>
                  <Form.Item label="Description" className={styles.fieldWidth}>
                    <Input.TextArea
                      rows={4}
                      value={data?.description}
                      onChange={e =>
                        handleChangeTranslatedValues(
                          index,
                          e.target.value,
                          'description',
                        )
                      }
                      placeholder={'Translated Description'}
                    />
                  </Form.Item>{' '}
                  <div className={styles['quill-container']}>
                    <span>Disclosure</span>
                    <ReactQuill
                      className={styles.quill}
                      theme="snow"
                      modules={AffiliateConfiguration.modules}
                      onChange={e =>
                        handleChangeTranslatedValues(index, e, 'disclosure')
                      }
                      formats={AffiliateConfiguration.format}
                      placeholder="Write Something"
                      value={data?.disclosure}
                    />
                  </div>
                </div>
              </Tabs.TabPane>
            ))}
        </Tabs>
        <h2>Suggested Amount Multipliers</h2>
        <div className={styles.suggestedAmounts}>
          <Form.Item name="amount1" label="Amount 1">
            <InputNumber className={styles.numberField} />
          </Form.Item>
          <Form.Item name="amount2" label="Amount 2">
            <InputNumber className={styles.numberField} />
          </Form.Item>
          <Form.Item name="amount3" label="Amount 3">
            <InputNumber className={styles.numberField} />
          </Form.Item>
          <Form.Item name="amount4" label="Amount 4">
            <InputNumber className={styles.numberField} />
          </Form.Item>
          <Form.Item name="amount5" label="Amount 5">
            <InputNumber className={styles.numberField} />
          </Form.Item>
        </div>
        <div>
          <SubmitButton
            disabled={loadingAffiliateUpdate || loadingAffiliate}
            label="Save"
          />
        </div>
      </Form>
    </div>
  );
};

AffiliateConfiguration.modules = QUILL_MODULES;
AffiliateConfiguration.format = QUILL_FORMATS;

export default AffiliateConfiguration;
