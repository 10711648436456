import {Button} from '@dabafinance/react-components';
import {Spin} from 'antd';
import {GET_ALL_INVESTORS_UPDATES} from 'graphql/queries/investorUpdates';
import pageNotFound from 'images/pageNotFound.png';
import {useNavigate} from 'react-router-dom';
import ManagementCard from '../managementCard';
import styles from './investmentDetails.module.scss';
import {useQuery} from '@apollo/client';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const InvestmentDetails = () => {
  const navigate = useNavigate();

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Updates),
  );
  const {data, loading} = useQuery(GET_ALL_INVESTORS_UPDATES);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Investor Updates</h1>
        <div>
          <Button
            className={styles.button}
            type="secondary"
            label="Older Updates"
            onClick={() =>
              navigate('/dashboard/explore/investor-update/older-updates')
            }
          />
          <Button
            className={styles.button}
            label="Add New Update"
            disabled={!tabOperations?.create}
            onClick={() => navigate('/dashboard/explore/investor-update/new')}
          />
        </div>
      </div>
      <div className={styles.cards}>
        {data?.getAllInvestorUpdates?.investorUpdates?.filter(
          data => data.isExpired === false,
        )?.length >= 1 ? (
          data?.getAllInvestorUpdates?.investorUpdates
            ?.filter(data => data.isExpired === false)
            .map(({title, publishedDate, imageUrl, id}) => (
              <div key={id}>
                <ManagementCard
                  date={publishedDate}
                  title={title}
                  id={id}
                  operations={tabOperations}
                  image={imageUrl}
                  index={id}
                />
              </div>
            ))
        ) : (
          <div className={styles['no-update']}>
            <img src={pageNotFound} alt="" />
            <h1>No Investors Update</h1>
            <span>Add a new Update</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default InvestmentDetails;
