import {Form, Input, Select, Spin} from 'antd';
import styles from './userRoleSettings.module.scss';
import backIcon from 'images/back.png';
import {useEffect, useState} from 'react';
import plusIcon from 'images/plus-circle.png';
import {UserPermissionsField} from './UserPermissionsField';
import {useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {CREATE_ADMIN_ROLE, UPDATE_ADMIN_ROLE} from 'graphql/mutations/admin';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {toast} from 'react-toastify';
import {GET_USER_ROLE_BY_ID} from 'graphql/queries/admin';
import trashIcon from 'images/trash-can-outline.svg';
import {ADMIN_TAB_FIELDS} from 'utils/constants';

const UserRoleSettings = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {id} = useParams();
  const [tabs, setTabs] = useState([]);

  const {data, loading} = useQuery(GET_USER_ROLE_BY_ID, {
    variables: {
      getUserRoleByIdId: id,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data?.getUserRoleById?.data?.name,
        description: data?.getUserRoleById?.data?.description,
      });
      setTabs(
        data?.getUserRoleById?.data?.tabs.map(data => ({
          tab: data?.tab,
          operations: data?.operations,
          permissions: data?.permissions?.map(data => data?.id),
        })),
      );
    }
  }, [data]);

  const [createUserRole, {loading: loadingCreate}] =
    useMutation(CREATE_ADMIN_ROLE);
  const [updateUserRole, {loading: loadingUpdate}] =
    useMutation(UPDATE_ADMIN_ROLE);

  const operations = [
    {key: 'VIEW', name: 'View'},
    {key: 'CREATE', name: 'Create'},
    {key: 'DELETE', name: 'Delete'},
    {key: 'EXPORT', name: 'Export'},
    {key: 'UPDATE', name: 'Update'},
    {key: 'ALL', name: 'All'},
  ];

  const handleAddTabs = () => {
    setTabs([
      ...tabs,
      {
        tab: '',
        operations: [],
        permissions: [],
      },
    ]);
  };

  const handleChangeFieldValue = (event, name, index) => {
    const newFormValues = [...tabs];
    newFormValues[index][name] = event;
    setTabs(newFormValues);
  };

  const handleFinish = async values => {
    const hasEmptyFields = tabs.some(tab => {
      return Object.values(tab).some(value => {
        if (Array.isArray(value)) {
          return value.length === 0;
        }
        return !value.trim();
      });
    });

    if (hasEmptyFields) {
      toast.error('Please fill out all fields for each tab.');
      return;
    }

    const transformedName = values.name.toUpperCase().replace(/\s/g, '_');
    if (id) {
      updateUserRole({
        variables: {
          data: {
            id,
            name: transformedName,
            description: values.description,
            tabs,
          },
        },
      })
        .then(({data: updateUserRole}) => {
          const {statusCode, __typename, message} =
            updateUserRole?.updateUserRole;
          if (__typename === 'Error' || statusCode === 400) {
            toast.error(`Something went wrong!!! - ${message} `);
          } else {
            toast.success('Updated Successfully');
            navigate('/dashboard/admin-settings?tab=role');
          }
        })
        .catch(err => toast.error(`Something went wrong!! - ${err.message}`));
    } else {
      createUserRole({
        variables: {
          data: {
            name: transformedName,
            description: values.name,
            tabs,
          },
        },
      })
        .then(({data: createUserRole}) => {
          const {statusCode, __typename, message} =
            createUserRole?.createUserRole;
          if (__typename === 'Error' || statusCode === 400) {
            toast.error(`Something went wrong!!! - ${message} `);
          } else {
            toast.success('Created Successfully');
            navigate('/dashboard/admin-settings?tab=role');
          }
        })
        .catch(err => toast.error(`Something went wrong!! - ${err.message}`));
    }
  };

  function capitalize(str) {
    // Convert the string to lowercase and then capitalize the first letter
    return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1);
  }

  const handleDeleteTab = id => {
    // Create a copy of the current tabs array
    const updatedTabs = [...tabs];
    // Remove the tab at the specified index
    updatedTabs.splice(id, 1);
    // Update the state with the new tabs array
    setTabs(updatedTabs);
  };

  if (id && loading) {
    return (
      <div className="query-loading">
        <h1>Loading Role</h1>
        <Spin size="large" />
      </div>
    );
  }
  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>{id ? 'Update' : 'Create'} Role Settings</h1>
      </div>
      <div className={styles.content}>
        <Form form={form} onFinish={handleFinish} layout="vertical">
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Required Field',
              },
            ]}
            className={styles.width}
            label="Name"
            name="name">
            <Input placeholder="Role Name" />
          </Form.Item>
          {id && (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Required Field',
                },
              ]}
              className={styles.width}
              label="Description"
              name="description">
              <Input placeholder="Role Description (Used as Label Field" />
            </Form.Item>
          )}
          <h2>Admin Access</h2>
          {tabs.map((data, index) => (
            <div className={styles.cardContainer} key={index}>
              <div>
                <Form.Item
                  required
                  className={styles.width}
                  initialValue={data.tab}
                  key={index}
                  rules={[
                    {
                      required: true,
                      message: 'Please Select a Tab',
                    },
                  ]}
                  label="Tab Name (Name of Navigation Tab in the Dashboard)">
                  <Select
                    showSearch
                    value={data.tab}
                    defaultValue={data.tab}
                    style={{textTransform: 'capitalize'}}
                    onChange={e => handleChangeFieldValue(e, 'tab', index)}>
                    {Object.entries(ADMIN_TAB_FIELDS).map(([key, value]) => (
                      <Select.Option key={key}>
                        {capitalize(value)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  required
                  className={styles.width}
                  initialValue={data?.operations}
                  rules={[
                    {
                      required: true,
                      message: 'Required Field',
                    },
                  ]}
                  label="Operation (Admin Action Access)">
                  <Select
                    onChange={e =>
                      handleChangeFieldValue(e, 'operations', index)
                    }
                    value={data?.operations}
                    defaultValue={data?.operations}
                    mode="multiple">
                    {operations.map(data => (
                      <Select.Option key={data.key}>{data.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  className={styles.width}
                  required
                  label="Permissions">
                  <UserPermissionsField
                    permissions={data?.permissions}
                    tabs={data?.tab}
                    operations={data.operations}
                    setPermissions={e =>
                      handleChangeFieldValue(e, 'permissions', index)
                    }
                    defaultPermissions={data?.permissions}
                  />
                </Form.Item>
              </div>
              <img onClick={() => handleDeleteTab(index)} src={trashIcon} />
            </div>
          ))}
          <div onClick={handleAddTabs} className={styles['add-button']}>
            <img src={plusIcon} alt="" />
            <h1>Add Access</h1>
          </div>
          <SubmitButton
            label={id ? 'Update' : 'Create'}
            disabled={loadingCreate || loadingUpdate}
          />
        </Form>
      </div>
    </div>
  );
};

export default UserRoleSettings;
