import styles from './stockClosure.module.scss';
import {Dropdown, Menu, Table, Tag} from 'antd';
import {useMutation, useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {useState} from 'react';
import SubmitButton from 'components/Startup/components/SubmitButton';
import DBModal from 'components/modal/modal';
import {toast} from 'react-toastify';
import dotsIcon from 'images/dots.svg';
import editImg from 'images/edit.svg';
import {GET_ALL_STOCK_CLOSURES} from 'graphql/queries/stocks';
import {ClosureModal} from './closureModal';
import trashIcon from 'images/trash-can-outline.svg';
import {DELETE_STOCK_CLOSURE} from 'graphql/mutations/stocks';
import {Button} from '@dabafinance/react-components';

export default function StockListingClosure() {
  const [openModal, setOpenModal] = useState({type: 'NONE'});
  const [stockId, setStockId] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const {id} = useParams();
  const {data, loading, refetch} = useQuery(GET_ALL_STOCK_CLOSURES, {
    variables: {
      stockExchangeId: id,
    },
  });

  const [deleteStockExchangeClosure, {loading: deleteLoading}] =
    useMutation(DELETE_STOCK_CLOSURE);

  const handleDelete = () => {
    deleteStockExchangeClosure({
      variables: {
        deleteStockExchangeClosureId: stockId,
      },
    })
      .then(({data: deleteStockExchangeClosure}) => {
        if (deleteStockExchangeClosure.__typename === 'Error') {
          toast.error(
            `Something went wrong!!! - ${deleteStockExchangeClosure.message} `,
          );
        } else {
          toast.success('Stock Exchange Closure deleted Successfully');
          refetch();
          setOpenDeleteModal(false);
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err}`));
  };

  const menu = id => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            setOpenModal({type: 'STATUS'});
            setStockId(id);
          }}>
          <div className={styles['menu-items']}>
            <span>Update Status</span>
          </div>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setOpenModal({type: 'UPDATE'});
            setStockId(id);
          }}>
          <div className={styles['menu-items']}>
            <img src={editImg} alt="edit" />
            <span>Edit</span>
          </div>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setOpenDeleteModal(true);
            setStockId(id);
          }}>
          <div className={styles['menu-items']}>
            <img src={trashIcon} alt="edit" />
            <span>Delete</span>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <div className={styles['record-label']}>{record?.title}</div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'datte',
      key: 'date',
      render: (text, record) => (
        <div className={styles['row-width']}>
          {moment(record?.date).format('DD MMMM YYYY')}
        </div>
      ),
    },
    {
      title: 'Message',
      dataIndex: 'closureMessage',
      key: 'closureMessage',
      render: (text, record) => (
        <div className={styles['record-message']}>{record?.closureMessage}</div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <div className={styles['record-label']}>
          {record?.isActive ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="yellow">Not Active</Tag>
          )}
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'message',
      key: 'message',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <Dropdown overlay={menu(record?.id)} placement="bottomLeft">
            <div className={styles['select-btn']}>
              <img style={{cursor: 'pointer'}} src={dotsIcon} />
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles['inner-container']}>
        <div className={styles.top}>
          <h2 className={styles.heading}>Stock Listing Closure</h2>
          <SubmitButton
            label="Add Closure"
            handleClick={() => setOpenModal({type: 'CREATE'})}
          />
        </div>
        <div>
          <Table
            dataSource={data?.getStockExchangeClosures?.closures}
            columns={columns}
            pagination={false}
            loading={loading}
          />
        </div>
      </div>

      {/* Create Modal */}
      <ClosureModal
        setOpenClosureModal={() => setOpenModal({type: 'NONE'})}
        openClosureModal={openModal.type !== 'NONE'}
        stockExchangeId={id}
        refetch={refetch}
        id={['UPDATE', 'STATUS'].includes(openModal.type) ? stockId : null}
        type={openModal.type}
      />

      {/* Delete Modal */}
      <DBModal
        isOpen={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        width={580}
        content={
          <div className={styles.deleteModal}>
            <h1>Delete Stock Exchange Closure</h1>
            <span>Are you sure you want to delete this Exchange Closure?</span>
            <div className={styles.deleteButtons}>
              <Button
                onClick={() => setOpenDeleteModal(false)}
                type="secondary"
                label="NO"
              />
              <Button
                disabled={deleteLoading}
                onClick={handleDelete}
                label="YES"
              />
            </div>
          </div>
        }
      />
    </div>
  );
}
