import {useState} from 'react';
import styles from './usersStockTrade.module.scss';
import BuyAndSellOrder from './BuyAndSellOrder';
import {Table} from 'antd';
import {currencySymbols} from 'utils/mock';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {GET_ALL_TRANSACTIONS_BY_OFFERING_ID} from 'graphql/queries/campaign';

const UserStockTrade = () => {
  const [activeTab, setActiveTab] = useState('BOTH');
  const navigate = useNavigate();

  const {data, loading, refetch} = useQuery(
    GET_ALL_TRANSACTIONS_BY_OFFERING_ID,
    {
      variables: {
        offering: '',
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div
          onClick={() => navigate(`/dashboard/user/${record?.user?.id}`)}
          className={styles['record-label']}>
          <img src={record?.user?.imageUrl} alt="" />
          <h4 style={{width: 100}}>
            {record?.user?.firstName} {record?.user?.lastName}
          </h4>
        </div>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (text, record) => (
        <div className={styles['row-width']}>{record?.user?.username}</div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => (
        <div className={styles['row-width']}>{record?.user?.email}</div>
      ),
    },
    {
      title: ' Transaction Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <div className={styles['row-width']}>
          <span
            className={
              styles[
                record?.transactionStatus === 'SETTLED'
                  ? 'success'
                  : record?.transactionStatus === 'FUNDED'
                  ? 'funded'
                  : record?.transactionStatus === 'VOIDED'
                  ? 'voided'
                  : record?.transactionStatus === 'FAILED'
                  ? 'failed'
                  : 'pending'
              ]
            }>
            {record?.transactionStatus}
          </span>
        </div>
      ),
    },

    {
      title: 'Transaction Amount in Asset Currency',
      dataIndex: 'transactionAmount',
      key: 'transactionAmount',
      render: (text, record) => (
        <div className={styles['row-width']}>
          {currencySymbols[record?.assetCurrency].symbol}{' '}
          {Number(record?.transactionAmount)?.toLocaleString()}
        </div>
      ),
    },
    {
      title: 'Invested Amount in Asset Currency',
      dataIndex: 'investedAmount',
      key: 'investedAmount',
      render: (text, record) => (
        <div className={styles['row-width']}>
          {currencySymbols[record?.assetCurrency].symbol}{' '}
          {Number(record?.investedAmount).toLocaleString()}
        </div>
      ),
    },
    {
      title: 'Transaction Currency To Asset Currency FX',
      dataIndex: 'investedAmount',
      key: 'investedAmount',
      render: (text, record) => (
        <div className={styles['row-width']}>
          {record?.transactionCurrencyToAssetCurrencyFx
            ? `1 ${record?.transactionCurrency} =
            ${Number(
              record?.transactionCurrencyToAssetCurrencyFx,
            ).toLocaleString()}
            ${record?.assetCurrency}`
            : '--'}
        </div>
      ),
    },
    {
      title: 'Invested Amount in Transaction Currency',
      dataIndex: 'investedAmount',
      key: 'investedAmount',
      render: (text, record) => (
        <div className={styles['row-width']}>
          {currencySymbols[record?.transactionCurrency].symbol}{' '}
          {Number(record?.investedAmountInTransactionCurrency).toLocaleString()}
        </div>
      ),
    },
    {
      title: 'Transaction Fee in Asset Currency',
      dataIndex: 'investedAmount',
      key: 'investedAmount',
      render: (text, record) => (
        <div className={styles['row-width']}>
          {currencySymbols[record?.assetCurrency].symbol}{' '}
          {Number(record?.transactionFees).toLocaleString()}
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'time',
      key: 'time',
      render: (text, record) => (
        <div className={styles['row-width']}>
          {moment(record?.createdAt).format('MMMM Do YYYY, h:mm a')}
        </div>
      ),
    },
  ];

  return (
    <div className={styles.root}>
      <h1>Users Stocks Trading</h1>
      <div className={styles['sections-tab']}>
        <div className={styles.tab}>
          <span
            className={activeTab === 'BOTH' ? styles['active-tab'] : ''}
            onClick={() => {
              setActiveTab('BOTH');
            }}>
            BUY & SELL SETTLE ORDER
          </span>
          <span
            className={activeTab === 'SINGLE' ? styles['active-tab'] : ''}
            onClick={() => {
              setActiveTab('SINGLE');
            }}>
            SETTLE ORDER
          </span>
          <span
            className={activeTab === 'VIEW' ? styles['active-tab'] : ''}
            onClick={() => {
              refetch();
              setActiveTab('VIEW');
            }}>
            VIEW ALL SETTLED ORDERS
          </span>
        </div>
      </div>
      {activeTab === 'VIEW' ? (
        <div className={styles.table}>
          <Table
            className={styles.userTable}
            loading={loading}
            dataSource={data?.getAllTransactionByOffering?.transactions}
            columns={columns}
          />
        </div>
      ) : (
        <div className={styles.content}>
          <BuyAndSellOrder tab={activeTab} />
        </div>
      )}
    </div>
  );
};

export default UserStockTrade;
