import {useMutation, useQuery} from '@apollo/client';
import {Form, Select, Spin} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {UPDATE_USER_KYC_STATUS} from 'graphql/mutations/users';
import {GET_USER_BY_ID} from 'graphql/queries/users';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import styles from './updateKyc.module.scss';

const UpdateKyc = () => {
  const [kycStatus, setKycStatus] = useState('');
  const [amlStatus, setAmlStatus] = useState('');
  const {id} = useParams();
  const {Option} = Select;

  const {data, loading} = useQuery(GET_USER_BY_ID, {
    variables: {
      getUserByIdId: id,
      userId: id,
    },
  });
  const [updateKycStatus] = useMutation(UPDATE_USER_KYC_STATUS);

  const status = [
    {label: 'Not Started', value: 'NOT_STARTED'},
    {label: 'Pending', value: 'PENDING'},
    {label: 'Pending Verification', value: 'PENDING_VERIFICATION'},
    {label: 'Approved', value: 'APPROVED'},
    {label: 'Rejected', value: 'REJECTED'},
  ];

  useEffect(() => {
    setKycStatus(
      data?.getUserById?.user?.investorProfile?.identityCard?.kycStatus,
    );
    setAmlStatus(
      data?.getUserById?.user?.investorProfile?.identityCard?.amlStatus,
    );
  }, [data?.getUserById?.user?.investorProfile]);

  const handleUpdateKycStatus = () => {
    updateKycStatus({
      variables: {
        userId: id,
        kycStatus,
        amlStatus,
      },
    })
      .then(({data: {updateKycStatus}}) => {
        if (updateKycStatus.__typename === 'Error') {
          toast.error('Something went wrong!');
        } else {
          toast.success('Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong!'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div>
        <h1>User KYC and AML Status</h1>
      </div>
      <div></div>
      <div className={styles.form}>
        <Form layout="vertical" onFinish={handleUpdateKycStatus}>
          <Form.Item
            rules={[{required: true}]}
            className={styles.width}
            name="kycStatus"
            label="KYC Status"
            initialValue={
              data?.getUserById?.user?.investorProfile?.identityCard?.kycStatus
            }>
            <Select
              defaultValue={
                data?.getUserById?.user?.investorProfile?.identityCard
                  ?.kycStatus
              }
              value={kycStatus}
              onChange={e => setKycStatus(e)}>
              {status.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.width}
            name="amlStatus"
            label="AML Status"
            initialValue={
              data?.getUserById?.user?.investorProfile?.identityCard?.amlStatus
            }>
            <Select
              defaultValue={
                data?.getUserById?.user?.investorProfile?.identityCard
                  ?.amlStatus
              }
              value={amlStatus}
              onChange={e => setAmlStatus(e)}>
              {status.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <SubmitButton label={'Update Status'} />
        </Form>
      </div>
    </div>
  );
};

export default UpdateKyc;
