import {gql} from '@apollo/client';

export const UPDATE_GLOBAL_WALLET_PLATFORM_SETTINGS = gql`
  mutation UpdateGlobalWalletPlatformSettings(
    $input: WalletPlatformSettingsInput!
  ) {
    updateGlobalWalletPlatformSettings(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on GlobalPlatformWalletSettings {
        isDepositAllowed
        isTransfersAllowed
        isWithdrawalAllowed
      }
    }
  }
`;

export const UPDATE_PAYMENT_PROVIDER_SETTINGS = gql`
  mutation UpdatePaymentProviderCurrencySettings(
    $input: [UpdatePaymentProviderCurrencySettingsInput]!
  ) {
    updatePaymentProviderCurrencySettings(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on PaymentProviderSettings {
        enabled
      }
    }
  }
`;

export const UPDATE_MOBILE_MONEY_SETTINGS = gql`
  mutation UpdatePaymentProviderSettings(
    $input: UpdatePaymentProviderInputSettings!
  ) {
    updatePaymentProviderSettings(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on PaymentProviderSettings {
        enabled
        provider
      }
    }
  }
`;

export const UPDATE_WALLET_CURRENCY_SETTINGS = gql`
  mutation UpdatePlatformWalletCurrencySettings(
    $input: WalletOperationSettingsInput!
  ) {
    updatePlatformWalletCurrencySettings(input: $input) {
      ... on WalletCurrencySettings {
        currency
        currencyName
      }
      ... on Error {
        message
        statusCode
      }
    }
  }
`;

export const UPDATE_USER_WALLET_TRANSACTION = gql`
  mutation UpdateUserWalletTransaction($input: UpdateUserTransactionInput!) {
    updateUserWalletTransaction(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on WalletTransaction {
        id
        referenceId
      }
    }
  }
`;

export const UPDATE_USER_WALLET_SETTINGS = gql`
  mutation UpdateUserWalletSettings($input: UpdateUserWalletSettingsInput!) {
    updateUserWalletSettings(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on Wallet {
        id
      }
    }
  }
`;

export const CREATE_FX_EXCHANGE_RATE = gql`
  mutation CreateFxExchangeRate($input: ExchangeRateInput!) {
    createFxExchangeRate(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ExchangeRate {
        sourceCurrency
      }
    }
  }
`;

export const UPDATE_FX_EXCHANGE_RATE = gql`
  mutation UpdateFxExchangeRate($input: ExchangeRateInput!) {
    updateFxExchangeRate(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ExchangeRate {
        id
      }
    }
  }
`;

export const DELETE_FX_EXCHANGE_PAIR = gql`
  mutation RemoveFxExchangeRate($removeFxExchangeRateId: ID!) {
    removeFxExchangeRate(id: $removeFxExchangeRateId) {
      ... on Error {
        message
        statusCode
      }
      ... on ExchangeRate {
        id
      }
    }
  }
`;

export const ENABLE_PAIR_STATUS = gql`
  mutation EnableOrDisableFxExchangeRate(
    $enableOrDisableFxExchangeRateId: ID!
    $isEnabled: Boolean!
  ) {
    enableOrDisableFxExchangeRate(
      id: $enableOrDisableFxExchangeRateId
      isEnabled: $isEnabled
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on ExchangeRate {
        id
      }
    }
  }
`;

export const ENABLE_OR_DISABLE_SOURCE_CURRENCY = gql`
  mutation EnableOrDisableSourceCurrencyForSwap(
    $currency: WalletCurrency!
    $isEnabled: Boolean!
  ) {
    enableOrDisableSourceCurrencyForSwap(
      currency: $currency
      isEnabled: $isEnabled
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on WalletCurrencySettings {
        swap {
          sourceCurrencyEnabledForSwap
        }
      }
    }
  }
`;

export const FULFIL_OR_REJECT_SWAP_TRANSACTION = gql`
  mutation FulfilOrRejectPendingSwapTransaction(
    $swapTransactionId: ID!
    $status: UpdateWalletTransactionStatus!
    $reasonForFailure: String
  ) {
    fulfilOrRejectPendingSwapTransaction(
      swapTransactionId: $swapTransactionId
      status: $status
      reasonForFailure: $reasonForFailure
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on SwapTransactions {
        id
      }
    }
  }
`;

export const UPDATE_SWAP_TRANSACTION_RATE = gql`
  mutation UpdateSwapTransaction($input: UpdateSwapTransactionInput) {
    updateSwapTransaction(input: $input) {
      ... on Error {
        message
        statusCode
      }
    }
  }
`;

export const CREATE_USER_SWAP_TRANSACTION = gql`
  mutation SwapCurrencies($input: CurrencySwapInput!) {
    swapCurrencies(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on SwapTransactions {
        id
      }
    }
  }
`;

export const SET_DAILY_THRESHOLD_FOR_SWAP = gql`
  mutation SetDailyThresholdForAutomaticSwap(
    $sourceCurrency: WalletCurrency!
    $targetCurrency: WalletCurrency!
    $amount: Float!
    $shouldNotifyAdminIfRemainingIsBelow: Float
  ) {
    setDailyThresholdForAutomaticSwap(
      sourceCurrency: $sourceCurrency
      targetCurrency: $targetCurrency
      amount: $amount
      shouldNotifyAdminIfRemainingIsBelow: $shouldNotifyAdminIfRemainingIsBelow
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on ExchangeRate {
        id
      }
    }
  }
`;

export const CREATE_DEPOSIT_TX = gql`
  mutation CreateManualDepositWalletTransaction(
    $input: CreateManualWalletTransactionInput!
  ) {
    createManualDepositWalletTransaction(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithManualCreateWalletTransaction {
        data {
          amount
        }
      }
    }
  }
`;

export const CREATE_WITHDRAWAL_TX = gql`
  mutation CreateManualWithdrawalWalletTransaction(
    $input: CreateManualWalletTransactionInput!
  ) {
    createManualWithdrawalWalletTransaction(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithManualCreateWalletTransaction {
        data {
          status
        }
      }
    }
  }
`;

export const TRIGGER_WALLET_REPORT = gql`
  mutation TriggerWalletReportJob($emails: [String!]!) {
    triggerWalletReportJob(emails: $emails) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;

export const PRE_SIGN_MEDIA_URL = gql`
  mutation PresignMediaURL($input: PresignMediaURLInput!) {
    presignMediaURL(input: $input) {
      ... on Error {
        statusCode
        message
      }
      ... on ResponseWithAnything {
        data
        message
      }
    }
  }
`;
