import {Button} from '@dabafinance/react-components';
import {Spin} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import UploadImage from 'components/Startup/profile/companyImage';
import {useEffect, useState} from 'react';
import {BigPlayButton, Player} from 'video-react';
import styles from './baseAnalysis.module.scss';
import {UploadVideoToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';

export const BaseAnalysis = ({onSubmit, existingData}) => {
  const [levelOneAnalysis, setLevelOneAnalysis] = useState('');
  const [levelTwoAnalysis, setLevelTwoAnalysis] = useState('');
  const [activeTab, setActiveTab] = useState('ONE');
  const [levelOneThumbnail, setLevelOneThumbnail] = useState('');
  const [levelOnePreviewThumnail, setLevelOnePreviewThumbnail] = useState('');
  const [levelTwoThumbnail, setLevelTwoThumbnail] = useState('');
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingLevelTwoUpload, setLoadingLevelTwoUpload] = useState(false);
  const [levelTwoPreviewThumnail, setLevelTwoPreviewThumbnail] = useState('');
  const [handleProgressOne, setHandleProgressOne] = useState(0);
  const [handleProgressTwo, setHandleProgressTwo] = useState(0);
  const [levelOneLoading, setLevelOneLoading] = useState(false);
  const [levelTwoLoading, setLevelTwoLoading] = useState(false);

  useEffect(() => {
    setLoadingUpload(
      activeTab === 'ONE' && (!levelOneAnalysis || !levelOneThumbnail),
    );
  }, [levelOneThumbnail, levelOneAnalysis, activeTab]);

  useEffect(() => {
    setLoadingLevelTwoUpload(
      activeTab === 'TWO' && (!levelTwoAnalysis || !levelTwoThumbnail),
    );
  }, [levelTwoAnalysis, levelTwoThumbnail, activeTab]);

  useEffect(() => {
    if (existingData && existingData.level1AnalystVideo) {
      setLevelOneAnalysis(existingData.level1AnalystVideo);
      setLevelOneThumbnail(existingData.level1AnalystVideoThumbnail);
      setLevelOnePreviewThumbnail(existingData.level1AnalystVideoThumbnail);
      setLevelTwoPreviewThumbnail(existingData.level2AnalystVideoThumbnail);
      setLevelTwoAnalysis(existingData.level2AnalystVideo);
      setLevelTwoThumbnail(existingData.level2AnalystVideoThumbnail);
    }
  }, [existingData]);

  const handleLevelOneUpload = async e => {
    const selectedFile = e.target.files[0];
    setLevelOneLoading(true);
    const response = await UploadVideoToBucket(
      selectedFile,
      'campaign-bucket-images',
      setHandleProgressOne,
    );
    setLevelOneAnalysis(response);
    setLevelOneLoading(false);
    setHandleProgressOne(0);
  };

  const handleLevelTwoUpload = async e => {
    const selectedFile = e.target.files[0];
    setLevelTwoLoading(true);
    const response = await UploadVideoToBucket(
      selectedFile,
      'campaign-bucket-images',
      setHandleProgressTwo,
    );
    setLevelTwoAnalysis(response);
    setLevelTwoLoading(false);
    setHandleProgressTwo(0);
  };

  const handleSave = () => {
    onSubmit({
      level1AnalystVideo: levelOneAnalysis,
      level2AnalystVideo: levelTwoAnalysis,
      level1AnalystVideoThumbnail: levelOneThumbnail,
      level2AnalystVideoThumbnail: levelTwoThumbnail,
    });
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles['details-toggler']}>
          <span
            className={activeTab === 'ONE' ? styles['active-toggle'] : ''}
            onClick={() => setActiveTab('ONE')}>
            {' '}
            Level one
          </span>
          <span
            className={activeTab === 'TWO' ? styles['active-toggle'] : ''}
            onClick={() => setActiveTab('TWO')}>
            {' '}
            Level two
          </span>
        </div>
        {activeTab === 'ONE' && (
          <div>
            <h2 className={styles.title}>Level 1 Analysis - Video</h2>
            {levelOneAnalysis ? (
              <div>
                <Player fluid={false} width={550} src={levelOneAnalysis}>
                  <BigPlayButton position="center" />
                </Player>
                <div>
                  <Button
                    className={styles['delete-button']}
                    label="Delete Video"
                    type="secondary"
                    onClick={() => setLevelOneAnalysis('')}
                  />
                </div>
              </div>
            ) : (
              <div>
                <label className={styles.upload} htmlFor="video-upload">
                  {levelOneLoading ? (
                    <div className={styles['upload-file']}>
                      <Spin />
                      <h2>Uploading Please Wait...</h2>
                      <span>{handleProgressOne}%</span>
                    </div>
                  ) : (
                    <h2>Upload Level 1 Analysis - Video</h2>
                  )}
                  <input
                    type="file"
                    id="video-upload"
                    accept="video/mp4"
                    className={styles['input-file']}
                    required
                    onChange={handleLevelOneUpload}
                  />
                </label>
              </div>
            )}
            <div className={styles['upload-image']}>
              <h2 className={styles.title}>Level 1 analysis - Thumbnail</h2>
              <UploadImage
                bucket={'campaign-bucket-images'}
                previewUrl={levelOnePreviewThumnail}
                setImgUrl={setLevelOneThumbnail}
                setPreviewUrl={setLevelOnePreviewThumbnail}
                accept
                thumbnail
              />
            </div>
          </div>
        )}
        {activeTab === 'TWO' && (
          <div>
            <h2 className={styles.title}>Level 2 Analysis - Video</h2>
            {levelTwoAnalysis ? (
              <div>
                <Player fluid={false} width={550} src={levelTwoAnalysis}>
                  <BigPlayButton position="center" />
                </Player>
                <div>
                  <Button
                    className={styles['delete-button']}
                    label="Delete Video"
                    type="secondary"
                    onClick={() => setLevelTwoAnalysis('')}
                  />
                </div>
              </div>
            ) : (
              <div>
                <label className={styles.upload} htmlFor="video2-upload">
                  {levelTwoLoading ? (
                    <div className={styles['upload-file']}>
                      <Spin />
                      <h2>Uploading Please Wait...</h2>
                      <span>{handleProgressTwo}%</span>
                    </div>
                  ) : (
                    <h2>Upload Level 2 Analysis - Video</h2>
                  )}
                  <input
                    type="file"
                    id="video2-upload"
                    className={styles['input-file']}
                    accept="video/mp4"
                    required
                    onChange={handleLevelTwoUpload}
                  />
                </label>
              </div>
            )}
            <div className={styles['upload-image']}>
              <h2 className={styles.title}>Level 2 analysis - Thumbnail</h2>
              <UploadImage
                bucket={'campaign-bucket-images'}
                previewUrl={levelTwoPreviewThumnail}
                setImgUrl={setLevelTwoThumbnail}
                setPreviewUrl={setLevelTwoPreviewThumbnail}
                accept
                thumbnail
              />
            </div>
          </div>
        )}
        <div className={styles['footer-button']}>
          <SubmitButton
            disabled={loadingUpload || loadingLevelTwoUpload}
            handleClick={handleSave}
            label="Save and Continue"
          />
          {/* <SubmitButton handleClick={handleSkip} label="Skip" /> */}
        </div>
      </div>
    </div>
  );
};
