import {useState} from 'react';
import styles from './addNewInterest.module.scss';
import {ArrowLeft} from 'icons';
import {Form, Select, Input, InputNumber, Tabs} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {CREATE_TOPIC} from 'graphql/mutations/interest';
import {useMutation} from '@apollo/client';
import UploadImage from 'components/Startup/profile/companyImage';
import {supportedLanguages} from 'utils/constants';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {onTranslateTopic} from '../actions';
import {Button} from '@dabafinance/react-components';
import {fieldRules} from 'utils/helper';

const {Option} = Select;

export default function AddNewInterest() {
  const [icon, setIcon] = useState('');
  const [iconPreviewUrl, setIconPreviewUrl] = useState('');
  const [bannerImg, setBannerImg] = useState('');
  const [bannerPreviewUrl, setBannerPreviewUrl] = useState('');
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [sequence, setSequence] = useState(null);
  const [region, setRegion] = useState('');
  const [enableChangeTranslation, setEnableChangeTranslation] = useState(true);
  const [language, setLanguage] = useState('');
  const [selectedTranslationLanguage, setSelectedTranslationLanguage] =
    useState('');
  const [translatedTopics, setTranslatedTopics] = useState([]);

  const [createTopic, {loading: topicCreation}] = useMutation(CREATE_TOPIC);
  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );
  const width = 720;

  const languageToBeTranslatedTo = supportedLanguages.filter(
    key => key.value !== language,
  );

  const handleChangeTranslatedValues = (key, event, name) => {
    let eventName = name;
    let eventValue = name ? event : event.target.value;
    const newFormValues = [...translatedTopics];
    newFormValues[key][eventName] = eventValue;
    setTranslatedTopics(newFormValues);
  };

  const handleSave = () => {
    createTopic({
      variables: {
        input: {
          name,
          type,
          sequence,
          icon,
          description,
          bannerImg,
          region: region || 'NONE',
          multilang: translatedTopics,
          language,
        },
      },
    }).then(({data: {createTopic}}) => {
      if (createTopic.__typename === 'Error') {
        toast.error(createTopic.message);
      } else {
        toast.success('Interest created successfully');
        navigate('/dashboard/interest');
      }
    });
  };

  const handleTranslation = async () => {
    const translateProps = {
      sourceLang: language,
      targetLang: selectedTranslationLanguage,
      targetText1: name,
      targetText2: description,
    };
    try {
      const newValues = [...translatedTopics];

      //Check if translation exists
      if (newValues.length && newValues[0]) {
        // Check if we're trying to translate in the same language
        const languageExists = newValues.some(
          data => data.language === selectedTranslationLanguage,
        );
        if (languageExists) {
          const updateTranslation = await onTranslateTopic(
            translateText,
            translateProps,
          );
          const translationIndex = newValues.findIndex(
            data => data.language === selectedTranslationLanguage,
          );
          newValues[translationIndex].name = updateTranslation.name;
          newValues[translationIndex].description =
            updateTranslation.description;
          newValues[translationIndex].language = updateTranslation.language;
          setTranslatedTopics(newValues);
        }
      } else {
        const triggerTranslation = await onTranslateTopic(
          translateText,
          translateProps,
        );
        if (!triggerTranslation) {
          return toast.error('Something went wrong when Translating');
        }
        newValues.push(triggerTranslation);
        setTranslatedTopics(newValues);
        setTranslatedTopics(newValues);
      }
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button onClick={() => navigate(-1)}>
          <ArrowLeft />
        </button>
        <h1>Add Interest</h1>
      </div>
      <div className={styles['inner-container']}>
        <div className={styles.section}>
          <h2 className={styles['sub-heading']}>Interest Icon</h2>
          <UploadImage
            previewUrl={iconPreviewUrl}
            setPreviewUrl={setIconPreviewUrl}
            setImgUrl={setIcon}
            bucket="company-service-bucket"
            accept="image/*"
          />
        </div>
        {/* Interest Banner */}
        <div className={styles.section}>
          <h2 className={styles['sub-heading']}>Interest Banner</h2>
          <UploadImage
            previewUrl={bannerPreviewUrl}
            setPreviewUrl={setBannerPreviewUrl}
            setImgUrl={setBannerImg}
            large
            bucket="company-service-bucket"
          />
        </div>
        <Form onFinish={handleSave} layout="vertical">
          <Form.Item
            rules={[fieldRules('Language')]}
            label="Language"
            className={styles.field}
            name="language">
            <Select
              style={{width}}
              value={language}
              onChange={e => setLanguage(e)}>
              {supportedLanguages.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[fieldRules('You must select a language to be translated')]}
            label="Language Translation"
            className={styles.field}
            name="translation">
            <Select
              style={{width}}
              value={selectedTranslationLanguage}
              onChange={e => setSelectedTranslationLanguage(e)}>
              {languageToBeTranslatedTo.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Tabs
            className={styles.tabs}
            tabBarExtraContent={
              selectedTranslationLanguage && (
                <Button
                  disabled={!name || !description || loadingTranslation}
                  label="Translate"
                  onClick={handleTranslation}
                />
              )
            }>
            <Tabs.TabPane tab="Original" key="Original">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Name is required',
                  },
                ]}
                style={{width}}
                label="Name"
                className={styles['sub-heading']}
                name="name">
                <Input
                  style={{width}}
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder={'Name'}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: false,
                  },
                ]}
                label="About Interest"
                style={{width}}
                className={styles['sub-heading']}
                name="aboutInterest">
                <Input.TextArea
                  value={description}
                  rows={5}
                  onChange={e => setDescription(e.target.value)}
                />
              </Form.Item>
            </Tabs.TabPane>
            {translatedTopics.length &&
              translatedTopics.map((data, index) => (
                <Tabs.TabPane
                  key={data.language}
                  tab={
                    supportedLanguages.find(
                      lang => lang.value === data.language,
                    ).label
                  }>
                  <div className={styles.updateButton}>
                    <Button
                      onClick={() =>
                        setEnableChangeTranslation(!enableChangeTranslation)
                      }
                      type="secondary"
                      label={
                        enableChangeTranslation
                          ? 'Edit Translation'
                          : 'Disable Translation'
                      }
                    />
                  </div>
                  <div key={index}>
                    <Form.Item label="Name" className={styles.field}>
                      <Input
                        style={{width}}
                        value={data.name}
                        disabled={enableChangeTranslation}
                        name="name"
                        onChange={e =>
                          handleChangeTranslatedValues(
                            index,
                            e.target.value,
                            'name',
                          )
                        }
                        placeholder={'Name'}
                      />
                    </Form.Item>
                    <Form.Item label="About Interest" className={styles.field}>
                      <Input.TextArea
                        style={{width}}
                        value={data.description}
                        disabled={enableChangeTranslation}
                        onChange={e =>
                          handleChangeTranslatedValues(
                            index,
                            e.target.value,
                            'description',
                          )
                        }
                        rows={5}
                      />
                    </Form.Item>
                  </div>
                </Tabs.TabPane>
              ))}
          </Tabs>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Category is required',
              },
            ]}
            style={{width}}
            label="Category"
            className={styles['sub-heading']}
            name="category">
            <Select value={type} onChange={e => setType(e)}>
              <Option value="COUNTRY">Country</Option>
              <Option value="INDUSTRY">Industry</Option>
              <Option value="LIST">List</Option>
              <Option value="GENERAL">General</Option>
            </Select>
          </Form.Item>
          {type === 'COUNTRY' ? (
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
              style={{width}}
              label="Region"
              className={styles['sub-heading']}>
              <Select value={region} onChange={e => setRegion(e)}>
                <Option value="WEST_AFRICA">West Africa</Option>
                <Option value="EAST_AFRICA">East Africa</Option>
                <Option value="NORTH_AFRICA">North Africa</Option>
                <Option value="SOUTH_AFRICA">South Africa</Option>
                <Option value="CENTRAL_AFRICA">Central Africa</Option>
              </Select>
            </Form.Item>
          ) : (
            <div></div>
          )}
          <Form.Item
            rules={[
              {
                required: false,
              },
            ]}
            label="Ranking"
            style={{width}}
            className={styles['sub-heading']}
            name="ranking">
            <InputNumber
              defaultValue={sequence}
              value={sequence}
              className={styles['input-number']}
              onChange={e => setSequence(e)}
              type="number"
            />
          </Form.Item>
          <SubmitButton
            handleClick={() => handleSave()}
            disabled={topicCreation}
            label="Create Interest"
          />
        </Form>
      </div>
    </div>
  );
}
