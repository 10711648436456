import {useState} from 'react';
import {Button, Search} from '@dabafinance/react-components';
import styles from './emailDisclosure.module.scss';
import {Table, Spin, Modal} from 'antd';
import {useNavigate} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import trash from 'images/trash-can-outline.svg';
import {toast} from 'react-toastify';
import {GET_ALL_EMAIL_DISCLOSURES} from 'graphql/queries/emailDisclosure';
import {DELETE_EMAIL_DISCLOSURE} from 'graphql/mutations/emailDisclosure';
import {NavTabsEnum} from 'utils/constants';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';

const EmailDisclosure = () => {
  const navigate = useNavigate();
  const {confirm} = Modal;
  const [searchItem, setSearchItem] = useState('');
  const [searchData, setSearchData] = useState([]);

  const {data, loading} = useQuery(GET_ALL_EMAIL_DISCLOSURES, {
    fetchPolicy: 'no-cache',
  });

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.PlatformSettings),
  );

  const [deleteEmailDisclosure] = useMutation(DELETE_EMAIL_DISCLOSURE);

  const handleSearch = term => {
    const result = term.trim();
    setSearchItem(result);

    if (term !== '') {
      const newList = data?.getAllEmailDisclosures?.disclosures.filter(data => {
        return Object.values(data)
          .join(' ')
          .toLowerCase()
          .includes(term.toLowerCase());
      });
      setSearchData(newList);
    } else {
      setSearchData(data?.getAllEmailDisclosures?.disclosures);
    }
  };

  const handleDelete = id => {
    confirm({
      content: 'Are you sure you want to delete this email disclosure?',
      onOk: () => {
        deleteEmailDisclosure({
          variables: {
            keystatsId: id,
          },
          refetchQueries: [{query: GET_ALL_EMAIL_DISCLOSURES}],
        })
          .then(({data: {deleteEmailDisclosure}}) => {
            if (deleteEmailDisclosure.__typename === 'Error') {
              toast.error(deleteEmailDisclosure.message);
            } else {
              setSearchData(data?.getEmailDisclosures);
              toast.success('Email Disclosure deleted Successfully');
            }
          })
          .catch(error => toast.error(error));
      },
    });
  };

  const stringTrim = string => {
    if (string?.length > 50) {
      return string.substr(0, 50) + '...';
    } else return string;
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
      render: (text, record) => (
        <div dangerouslySetInnerHTML={{__html: stringTrim(record?.content)}} />
      ),
    },
    {
      title: 'Disclosure Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <div className={styles['action-btn']}>
          {tabOperations?.update && (
            <Button
              onClick={() =>
                navigate(
                  `/dashboard/app-version-setting/update-disclosure/${record.id}`,
                )
              }
              type="secondary"
              label="Update"
            />
          )}
          {tabOperations?.delete && (
            <img
              onClick={() => {
                handleDelete(record.id);
              }}
              className={styles.trash}
              src={trash}
              alt=""
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={styles['container-wrapper']}>
      <div className={styles['header-container']}>
        <h1>Email Disclosures</h1>
        <div className={styles['left-buttons']}>
          <Button
            label={'Create Email Disclosure'}
            className={styles['header-button']}
            disabled={!tabOperations?.create}
            onClick={() =>
              navigate('/dashboard/app-version-setting/new-disclosure')
            }
          />
        </div>
      </div>
      <div className={styles['startup-table']}>
        <div className={styles['startup-header']}>
          <div>
            <Search
              className={styles['search-bar']}
              id={''}
              placeholder="Name"
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className={styles['table-height']}>
          {loading ? (
            <div className={styles.loading}>
              <Spin size="large" />
              <h1>Loading...</h1>
            </div>
          ) : (
            <Table
              loading={loading}
              dataSource={
                searchItem.length < 1
                  ? data?.getAllEmailDisclosures?.disclosures
                  : searchData
              }
              columns={columns}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailDisclosure;
