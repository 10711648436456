import {useMutation, useQuery} from '@apollo/client';
import {DatePicker, Input, Spin, Form} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import moment from 'moment';
import IpoFeeCard from 'pages/campaigns/new/screens/ipo/ipoFeeCard/IpoFeeCard';
import plusIcon from 'images/plus-circle.png';

import {Fragment, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import styles from '../../../new/screens/ipo/listingIPO.module.scss';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';

const UpdateListingIPO = () => {
  const [numSharesAllocated, setNumSharesAllocated] = useState(0);
  const [expectedIpo, setExpectedIpo] = useState('');
  const [offeringName, setOfferingName] = useState('');
  const [ipoSharePrice, setIpoSharePrice] = useState(0);
  const [numOfOutstandingShares, setNumOfOutstandingShares] = useState(0);
  const {id, language} = useParams();
  const [ipoFeesValues, setIpoFeesValues] = useState([
    {
      feeName: '',
      feeType: '',
      fixedFeeAmount: '',
      variableFeeAmount: '',
    },
  ]);

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
    errorPolicy: 'ignore',
  });
  const [updateCampaign, {loading: updateLoading}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (campaignData?.getCampaignById?.ipoFields) {
      setNumSharesAllocated(
        campaignData?.getCampaignById?.ipoFields?.dabaNumberofSharesAllocated,
      );
      setExpectedIpo(campaignData?.getCampaignById?.ipoFields?.expectedIpo);
      setOfferingName(campaignData?.getCampaignById?.ipoFields?.offeringName);
      setIpoSharePrice(campaignData?.getCampaignById?.ipoFields?.ipoSharePrice);
      setOfferingName(campaignData?.getCampaignById?.ipoFields?.offeringName);
      setNumOfOutstandingShares(
        campaignData?.getCampaignById?.ipoFields
          ?.totalNumberofSharesOutstandingforIPO,
      );
    }
  }, [campaignData?.getCampaignById?.ipoFields]);

  useEffect(() => {
    if (campaignData?.getCampaignById?.investmentTerms?.feeSchedule) {
      setIpoFeesValues(
        campaignData?.getCampaignById?.investmentTerms?.feeSchedule?.map(
          data => ({
            feeName: data.feeName,
            feeType: data.feeType,
            fixedFeeAmount: data.fixedFeeAmount,
            variableFeeAmount: data.variableFeeAmount,
          }),
        ),
      );
    }
  }, [campaignData?.getCampaignById?.investmentTerms?.feeSchedule]);

  const handleChangeIpoFeesValues = (index, event) => {
    const newFormValues = [...ipoFeesValues];
    newFormValues[index][event.target.name] = event.target.value;
    setIpoFeesValues(newFormValues);
  };

  const handleChangeIpoFeesType = (index, event) => {
    const newFormValues = [...ipoFeesValues];
    newFormValues[index].feeType = event;
    setIpoFeesValues(newFormValues);
  };

  const handleAddIpoFees = () => {
    setIpoFeesValues([
      ...ipoFeesValues,
      {
        feeName: '',
        feeType: '',
        fixedFeeAmount: '',
        variableFeeAmount: '',
      },
    ]);
  };

  const handleDeleteIpoFees = id => {
    const filteredIpoFee = ipoFeesValues.filter((_, index) => index !== id);
    setIpoFeesValues(filteredIpoFee);
  };

  const handleSubmit = () => {
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          ipoFields: {
            dabaNumberofSharesAllocated: Number(numSharesAllocated),
            expectedIpo: new Date(expectedIpo).toISOString(),
            ipoSharePrice: Number(ipoSharePrice),
            totalNumberofSharesOutstandingforIPO: Number(
              numOfOutstandingShares,
            ),
            offeringName,
          },
          investmentTerms: {
            companyValuation:
              campaignData?.getCampaignById?.investmentTerms?.companyValuation,
            fundingTarget:
              campaignData?.getCampaignById?.investmentTerms?.fundingTarget,
            minimumFundingTarget:
              campaignData?.getCampaignById?.investmentTerms
                ?.minimumFundingTarget,
            minimumInvestmentAmount:
              campaignData?.getCampaignById?.investmentTerms
                ?.minimumInvestmentAmount,
            maximumFundingTarget:
              campaignData?.getCampaignById?.investmentTerms
                ?.maximumFundingTarget,
            feeSchedule: ipoFeesValues?.map(data => ({
              feeName: data?.feeName,
              feeType: data?.feeType,
              fixedFeeAmount: Number(data?.fixedFeeAmount),
              variableFeeAmount: Number(data?.variableFeeAmount),
            })),
          },
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error('Please make sure fields are not empty');
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  if (!campaignData.getCampaignById.ipoFields) {
    return (
      <div className={styles['venture-page']}>
        <h1>This is not an IPO Listing</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h1>Update IPO Shares Details</h1>
      <Form onFinish={handleSubmit} layout="vertical">
        <Form.Item
          className={styles['form-item']}
          label="Offering Name"
          name="offeringName"
          rules={[
            {
              required: true,
              message: 'Required Field',
            },
          ]}
          initialValue={campaignData?.getCampaignById?.ipoFields?.offeringName}>
          <Input
            defaultValue={
              campaignData?.getCampaignById?.ipoFields?.offeringName
            }
            value={offeringName}
            onChange={e => setOfferingName(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          className={styles['form-item']}
          label="Number of Shares Allocated"
          name="numSharesAllocated"
          rules={[
            {
              required: true,
              message: 'Required Field',
            },
          ]}
          initialValue={
            campaignData?.getCampaignById?.ipoFields
              ?.dabaNumberofSharesAllocated
          }>
          <Input
            defaultValue={
              campaignData?.getCampaignById?.ipoFields
                ?.dabaNumberofSharesAllocated
            }
            value={numSharesAllocated}
            type="number"
            onChange={e => setNumSharesAllocated(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          className={styles['form-item']}
          label="Offering Name"
          name="offeringName"
          rules={[
            {
              required: true,
              message: 'Required Field',
            },
          ]}
          initialValue={campaignData?.getCampaignById?.ipoFields?.offeringName}>
          <Input
            defaultValue={
              campaignData?.getCampaignById?.ipoFields?.offeringName
            }
            value={offeringName}
            onChange={e => setOfferingName(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          className={styles['form-item']}
          label="IPO Share Price"
          rules={[
            {
              required: true,
              message: 'Required Field',
            },
          ]}
          name="ipoSharePrice"
          initialValue={
            campaignData?.getCampaignById?.ipoFields?.ipoSharePrice
          }>
          <Input
            defaultValue={
              campaignData?.getCampaignById?.ipoFields?.ipoSharePrice
            }
            value={ipoSharePrice}
            onChange={e => setIpoSharePrice(e.target.value)}
            type="number"
          />
        </Form.Item>
        <Form.Item
          label="Total Number of Shares outstanding for IPO"
          name="numOfOutstandingShares"
          rules={[
            {
              required: true,
              message: 'Required Field',
            },
          ]}
          className={styles['form-item']}
          initialValue={
            campaignData?.getCampaignById?.ipoFields
              ?.totalNumberofSharesOutstandingforIPO
          }>
          <Input
            value={numOfOutstandingShares}
            onChange={e => setNumOfOutstandingShares(e.target.value)}
            type="number"
            placeholder="Total Number of outstanding shares"
            defaultValue={
              campaignData?.getCampaignById?.ipoFields
                ?.totalNumberofSharesOutstandingforIPO
            }
          />
        </Form.Item>
        <Form.Item
          label="Expected IPO Date"
          name="date"
          className={styles['form-item']}
          rules={[
            {
              required: true,
              message: 'Required Field',
            },
          ]}
          initialValue={
            campaignData?.getCampaignById?.ipoFields?.expectedIpo
              ? moment(
                  campaignData?.getCampaignById?.ipoFields?.expectedIpo,
                  'YYYY-MM-DD HH:mm:ss',
                )
              : ''
          }>
          <DatePicker
            placeholder="Select Date Time"
            value={expectedIpo}
            defaultValue={
              campaignData?.getCampaignById?.ipoFields?.expectedIpo
                ? moment(
                    campaignData?.getCampaignById?.ipoFields?.expectedIpo,
                    'YYYY-MM-DD HH:mm:ss',
                  )
                : ''
            }
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
            format={'YYYY-MM-DD HH:mm:ss'}
            onChange={e =>
              setExpectedIpo(moment(e).format('YYYY-MM-DD HH:mm:ss'))
            }
            style={{width: 340}}
          />
        </Form.Item>
        <div>
          <h1>Update IPO Investment Details</h1>
        </div>
        <div className={styles.form}>
          {ipoFeesValues.map((data, index) => (
            <Fragment key={index}>
              <IpoFeeCard
                feeName={data.feeName || ''}
                feeType={data.feeType}
                fixedFeeAmount={data.fixedFeeAmount}
                variableFeeAmount={data.variableFeeAmount}
                handleChangeIpoFeesValues={e =>
                  handleChangeIpoFeesValues(index, e)
                }
                handleChangeIpoFeesType={e => handleChangeIpoFeesType(index, e)}
                handleDeleteIpoFees={e => handleDeleteIpoFees(index, e)}
              />
            </Fragment>
          ))}
        </div>
        <div onClick={handleAddIpoFees} className={styles['add-button']}>
          <img src={plusIcon} alt="" />
          <h2>Add Field</h2>
        </div>

        <SubmitButton label="Update Changes" disabled={updateLoading} />
      </Form>
    </div>
  );
};

export default UpdateListingIPO;
