import {Form, Input, Spin} from 'antd';
import {useEffect, useState} from 'react';
import styles from './campaignRisks.module.scss';

import trash from 'images/trash-can-outline.svg';
import plusIcon from 'images/plus-circle.png';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useParams} from 'react-router-dom';
import FadeEffect from 'components/animation/fadeEffect';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {useMutation, useQuery} from '@apollo/client';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';

const UpdateCampaignRisks = () => {
  const {id, language} = useParams();
  const [risksFormValues, setRisksFormValues] = useState([
    {question: '', answer: ''},
  ]);
  const [impactFormValues, setImpactFormValues] = useState([
    {environmental: '', answer: ''},
  ]);
  const width = 700;

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });

  const [updateCampaign, {loading: updateLoading}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (campaignData?.getCampaignById?.riskAndEsg) {
      setRisksFormValues(
        campaignData?.getCampaignById?.riskAndEsg?.map(data => ({
          question: data.title,
          answer: data.answer,
        })),
      );
    }
  }, [campaignData?.getCampaignById?.riskAndEsg]);

  useEffect(() => {
    if (campaignData?.getCampaignById?.impactAndEsg) {
      setImpactFormValues(
        campaignData?.getCampaignById?.impactAndEsg?.map(data => ({
          environmental: data.title,
          answer: data.answer,
        })),
      );
    }
  }, [campaignData?.getCampaignById?.impactAndEsg]);

  const {TextArea} = Input;

  const handleAddRisksFields = () => {
    setRisksFormValues([...risksFormValues, {question: '', answer: ''}]);
  };

  const handleAddImpactFields = () => {
    setImpactFormValues([...impactFormValues, {environmental: '', answer: ''}]);
  };

  const handleChangeImpactValues = (i, e) => {
    const newFormValues = [...impactFormValues];
    newFormValues[i][e.target.name] = e.target.value;
    setImpactFormValues(newFormValues);
  };

  const handleChangeRisksValues = (i, e) => {
    const newFormValues = [...risksFormValues];
    newFormValues[i][e.target.name] = e.target.value;
    setRisksFormValues(newFormValues);
  };

  const handleDeleteRisk = id => {
    const filteredFormValue = risksFormValues.filter(
      (item, index) => index !== id,
    );
    setRisksFormValues(filteredFormValue);
  };

  const handleDeleteInpact = id => {
    const filteredFormValue = impactFormValues.filter(
      (item, index) => index !== id,
    );
    setImpactFormValues(filteredFormValue);
  };

  const handleSave = () => {
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          riskAndEsg: risksFormValues?.map(data => ({
            title: data?.question,
            answer: data?.answer,
          })),
          impactAndEsg: impactFormValues.map(data => ({
            title: data?.environmental,
            answer: data?.answer,
          })),
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error('Please make sure no field is empty');
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Risk &#38; ESG</h1>
      </div>
      <Form onFinish={handleSave} layout="vertical">
        <div className={styles.risks}>
          <h1 className={styles.title}>Risks</h1>
          <div>
            {risksFormValues.map((element, index) => (
              // eslint-disable-next-line react/jsx-key
              <FadeEffect>
                <div key={index}>
                  <div className={styles['question-style']}>
                    <Form.Item style={{width}} label="Title">
                      <Input
                        name="question"
                        value={element.question || ''}
                        onChange={e => handleChangeRisksValues(index, e)}
                      />
                    </Form.Item>
                    {
                      <img
                        src={trash}
                        onClick={() => handleDeleteRisk(index)}
                        alt=""
                      />
                    }
                  </div>
                  <Form.Item style={{width}} label="Answer">
                    <TextArea
                      name="answer"
                      rows={5}
                      value={element.answer || ''}
                      onChange={e => handleChangeRisksValues(index, e)}
                    />
                  </Form.Item>
                </div>
              </FadeEffect>
            ))}
            <div onClick={handleAddRisksFields} className={styles['add-risks']}>
              <img src={plusIcon} alt="add-founder" />
              <span>Add Risks</span>
            </div>
          </div>
        </div>
        <div>
          <h1 className={styles.title}>Impact &#38; ESG</h1>
          {impactFormValues.map((element, index) => (
            // eslint-disable-next-line react/jsx-key
            <FadeEffect>
              <div key={index}>
                <div className={styles['question-style']}>
                  <Form.Item style={{width}} label="Environmental">
                    <Input
                      name="environmental"
                      value={element.environmental || ''}
                      onChange={e => handleChangeImpactValues(index, e)}
                    />
                  </Form.Item>
                  {
                    <img
                      src={trash}
                      onClick={() => handleDeleteInpact(index)}
                      alt=""
                    />
                  }
                </div>
                <Form.Item style={{width}} label="Answer">
                  <TextArea
                    name="answer"
                    rows={5}
                    value={element.answer || ''}
                    onChange={e => handleChangeImpactValues(index, e)}
                  />
                </Form.Item>
              </div>
            </FadeEffect>
          ))}
          <div onClick={handleAddImpactFields} className={styles['add-risks']}>
            <img src={plusIcon} alt="add-founder" />
            <span>Add Impact &#38; EGG </span>
          </div>
        </div>
        <div>
          <SubmitButton
            disabled={updateLoading}
            label="Update"
            handleClick={handleSave}
          />
        </div>
      </Form>
    </div>
  );
};

export default UpdateCampaignRisks;
