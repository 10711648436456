import {Form, Input, TimePicker} from 'antd';
import styles from './mobileSetting.module.scss';
import moment from 'moment';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useState} from 'react';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {UPDATE_APP_CONFIGURATION} from 'graphql/queries/mobileSettings';
import {useMutation} from '@apollo/client';
import {handleCutoffTime} from 'utils/helper';
import {toast} from 'react-toastify';

export const InvestmentConfig = ({data, refetch}) => {
  const [openModal, setOpenModal] = useState(false);
  const [formValues, setFormValues] = useState({});

  const [updateAppConfig, {loading}] = useMutation(UPDATE_APP_CONFIGURATION, {
    errorPolicy: 'all',
  });

  return (
    <div className={styles.body}>
      <div>
        <h2>Recurring Investment Configuration</h2>
      </div>
      <Form
        onFinish={values => {
          setFormValues(values);
          setOpenModal(true);
        }}
        layout="vertical">
        <Form.Item
          initialValue={
            data?.scheduledStartTime
              ? moment(data?.scheduledStartTime, 'HH:mm')
              : null
          }
          className={styles.timezone}
          rules={[
            {required: true, message: 'This is a required field'},
            {
              validator: (_, value) => {
                if (!value || !data?.scheduledEndTime) return Promise.resolve();
                return moment(value).isBefore(
                  moment(data.scheduledEndTime, 'HH:mm'),
                )
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error('Start time must be before end time'),
                    );
              },
            },
          ]}
          name="scheduledStartTime"
          label="Scheduled Start Time (UTC Timezone)">
          <TimePicker
            format="HH:mm"
            defaultValue={
              data?.scheduledStartTime
                ? moment(data?.scheduledStartTime, 'HH:mm')
                : null
            }
            minuteStep={1}
            className={styles.timePicker}
            disabledTime={() => ({
              disabledHours: () => {
                const hours = [];
                for (let i = 0; i < 8; i++) {
                  hours.push(i);
                }
                for (let i = 17; i < 24; i++) {
                  hours.push(i);
                }
                return hours;
              },
            })}
          />
        </Form.Item>
        <Form.Item
          className={styles.timezone}
          rules={[
            {required: true, message: 'This is a required field'},
            {
              validator: (_, value) => {
                if (!value || !data?.scheduledStartTime)
                  return Promise.resolve();
                return moment(value).isAfter(
                  moment(data.scheduledStartTime, 'HH:mm'),
                )
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error('End time must be after start time'),
                    );
              },
            },
          ]}
          initialValue={
            data?.scheduledEndTime
              ? moment(data?.scheduledEndTime, 'HH:mm')
              : null
          }
          name="scheduledEndTime"
          label="Scheduled End Time (UTC Timezone)">
          <TimePicker
            format="HH:mm"
            defaultValue={
              data?.scheduledEndTime
                ? moment(data?.scheduledEndTime, 'HH:mm')
                : null
            }
            minuteStep={1}
            className={styles.timePicker}
            disabledTime={() => ({
              disabledHours: () => {
                const hours = [];
                for (let i = 0; i < 8; i++) {
                  hours.push(i);
                }
                for (let i = 17; i < 24; i++) {
                  hours.push(i);
                }
                return hours;
              },
            })}
          />
        </Form.Item>
        <Form.Item
          className={styles.timezone}
          rules={[{required: true, message: 'This is a required field'}]}
          name="maxRetries"
          initialValue={data?.maxRetries}
          label="Maximum Retries">
          <Input type="number" defaultValue={data?.maxRetries} />
        </Form.Item>
        <SubmitButton label="Save Changes" />
      </Form>
      <ConfirmModal
        openModal={openModal}
        setOpenModal={() => setOpenModal(false)}
        loading={loading}
        title="Update Configuration"
        confirmText="Are you sure you want to update this, This action cannot be undone?"
        handleOperation={() =>
          updateAppConfig({
            variables: {
              data: {
                recurringInvestmentSettings: {
                  scheduledStartTime: handleCutoffTime(
                    formValues.scheduledStartTime,
                  ),
                  scheduledEndTime: handleCutoffTime(
                    formValues.scheduledEndTime,
                  ),
                  maxRetries: Number(formValues.maxRetries),
                },
              },
            },
          })
            .then(({data: {updateAppConfig}}) => {
              if (updateAppConfig.__typename === 'Error') {
                toast.error(updateAppConfig.message);
              } else {
                toast.success('Updated Successfully');
                refetch();
                setOpenModal(false);
              }
            })
            .catch(error => toast.error(error))
        }
      />
    </div>
  );
};
