import UploadImage from 'components/Startup/profile/companyImage';
import styles from './ratings.module.scss';
import {Form, Input, Select, Spin, Upload} from 'antd';
import cloudIcon from 'images/cloud-upload.svg';
import {useEffect, useState} from 'react';
import {UploadDocumentToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import {ratingsTypes} from '../modules/constants';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useMutation, useQuery} from '@apollo/client';
import {GET_RATING_BY_ID} from 'graphql/queries/assetManagement';
import {CREATE_RATING, UPDATE_RATING} from 'graphql/mutations/assetManagement';
import {toast} from 'react-toastify';

export const RatingsForm = ({id, refetch, imgUrl, setImgUrl, form, close}) => {
  const [loading, setLoading] = useState(false);

  const {data, loading: loadingQuery} = useQuery(GET_RATING_BY_ID, {
    skip: !id,
    variables: {
      getRatingByIdId: id,
    },
    fetchPolicy: 'no-cache',
  });

  const [updateRating, {loading: loadingUpdate}] = useMutation(UPDATE_RATING);
  const [createRating, {loading: loadingCreate}] = useMutation(CREATE_RATING);

  useEffect(() => {
    if (id) {
      setImgUrl(data?.getRatingById?.data?.agencyLogo);

      form.setFieldsValue({
        ...data?.getRatingById?.data,
      });
    }
  }, [id, data]);

  const handleChangeDocument = async ({file}) => {
    const temp = file.originFileObj;

    setLoading(true);
    try {
      const fileLink = await UploadDocumentToBucket(
        temp,
        () => {},
        'dabafinancialinstruments-nonprod',
      );
      form.setFieldValue('document', fileLink);
    } catch (error) {
      console.error('File upload failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePreview = async file => {
    const fileUrl = file.url || file.preview; // Get the file URL
    if (fileUrl) {
      // Open the document in a new window or modal
      window.open(fileUrl, '_blank'); // This opens the document in a new tab
    }
  };

  if (loadingQuery && id) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  const handleFinish = values => {
    const resolver = id ? updateRating : createRating;
    resolver({
      variables: {
        updateRatingId: id,
        input: {
          agencyLogo: imgUrl,
          ...values,
        },
      },
    }).then(({data}) => {
      const resolverType = data?.[id ? 'updateRating' : 'createRating'];
      if (resolverType.__typename === 'Error') {
        toast.error(resolverType.message);
      } else {
        toast.success('Action Successful');
        refetch();
        close();
      }
    });
  };

  return (
    <div className={styles.form}>
      <h1>{id ? 'Update' : 'Create'} Rating</h1>
      <Form onFinish={handleFinish} form={form} layout="vertical">
        <div>
          <UploadImage
            bucket="campaign-bucket-images"
            setImgUrl={setImgUrl}
            label="Agency Logo"
            imgUrl={imgUrl}
            previewUrl={imgUrl}
          />
        </div>
        <Form.Item
          rules={[{required: true}]}
          name="agencyName"
          className={styles.field}
          label="Agency Name">
          <Input />
        </Form.Item>
        <Form.Item
          label="Rating"
          className={styles.field}
          name="rating"
          rules={[{required: true}]}>
          <Select>
            {ratingsTypes.map(data => (
              <Select.Option key={data}>{data}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Type"
          className={styles.field}
          initialValue="BOND"
          name="type"
          rules={[{required: true}]}>
          <Select value="BOND" defaultValue="BOND" disabled>
            <Select.Option key="BOND">Bond</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="document"
          className={styles.document}
          label="Document Language">
          <Upload
            accept=".pdf, .doc"
            name="document"
            onPreview={handlePreview}
            onChange={handleChangeDocument}
            showUploadList={true}
            maxCount={1}>
            <label htmlFor="file-upload" className={styles.upload}>
              <img src={cloudIcon} alt="" />
              <span>
                {loading
                  ? 'Uploading...'
                  : form.getFieldValue('document')
                  ? 'Uploaded Successfully'
                  : 'Upload Document'}
              </span>
            </label>
          </Upload>
        </Form.Item>
        <SubmitButton
          disabled={loadingUpdate || loadingCreate}
          label={id ? 'Update' : 'Create'}
        />
      </Form>
    </div>
  );
};
export default RatingsForm;
