import {useMutation, useQuery} from '@apollo/client';
import {DatePicker, Form, Input, Select, Spin} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {GET_ALL_INDUSTRIES} from 'graphql/queries/investorUpdates';
import moment from 'moment';
import {companyStageData} from 'pages/campaigns/campaignData';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import styles from './companyDetails.module.scss';

const UpdateCampaignCompanyDetails = () => {
  const [foundedOn, setFoundedOn] = useState('');
  const [location, setLocation] = useState('');
  const [industry, setIndustry] = useState('');
  const [stage, setStage] = useState('');
  const [website, setWebsite] = useState('');
  const [linkedinURL, setLinkedinURL] = useState('');
  const [facebookURL, setFacebookURL] = useState('');
  const [twitterURL, setTwitterURL] = useState('');
  const {Option} = Select;
  const {id, language} = useParams();

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });
  const [updateCampaign, {loading: campaignLoading}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (campaignData?.getCampaignById) {
      setLocation(campaignData?.getCampaignById?.location);
      setFoundedOn(campaignData?.getCampaignById?.foundedOn);
      setIndustry(campaignData?.getCampaignById?.industry);
      setStage(campaignData?.getCampaignById?.stage);
      setWebsite(campaignData?.getCampaignById?.website);
      setFoundedOn(campaignData?.getCampaignById?.foundedOn);
      setLinkedinURL(campaignData?.getCampaignById?.socials?.linkedin);
      setFacebookURL(campaignData?.getCampaignById?.socials?.facebook);
      setTwitterURL(campaignData?.getCampaignById?.socials?.twitter);
      console.log(campaignData?.getCampaignById);
    }
  }, [campaignData?.getCampaignById]);

  function disabledDate(current) {
    const customDate = new Date().getFullYear();
    return current && current > moment(customDate + 1, 'YYYY');
  }

  const {data: getAllIndustries} = useQuery(GET_ALL_INDUSTRIES);

  const handleSave = () => {
    const socials = {};
    if (twitterURL) socials.twitter = twitterURL;
    if (facebookURL) socials.facebook = facebookURL;
    if (linkedinURL) socials.linkedin = linkedinURL;
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          foundedOn: parseInt(foundedOn),
          location,
          socials: {
            ...socials,
          },
          industry,
          website,
          stage,
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error(updateCampaign.message);
        } else {
          toast.success('Campaign Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Company Details</h1>
      </div>
      <div className={styles.overview}>
        <h1 className={styles.title}>Overview</h1>
        <Form className={styles.form} layout="vertical">
          <Form.Item
            label="Founded Date"
            name="date"
            className={styles['form-item']}>
            <DatePicker
              value={foundedOn}
              onChange={e => setFoundedOn(moment(e).format('YYYY'))}
              picker="year"
              defaultValue={
                campaignData?.getCampaignById?.foundedOn
                  ? moment(
                      campaignData?.getCampaignById?.foundedOn,
                      'YYYY-MM-DD HH:mm:ss',
                    )
                  : ''
              }
              name="Date"
              disabledDate={disabledDate}
              style={{width: 340}}
            />
          </Form.Item>
          <Form.Item style={{width: 340}} label="Location" name="location">
            <Input
              value={location}
              defaultValue={campaignData?.getCampaignById?.location}
              onChange={e => setLocation(e.target.value)}
              placeholder="Lagos - Nigeria"
            />
          </Form.Item>
          <Form.Item label="Industry" name="industry">
            <Select
              value={industry}
              onChange={e => setIndustry(e)}
              style={{width: 340}}
              defaultValue={campaignData?.getCampaignById?.industry}
              placeholder="Select Industry">
              {getAllIndustries?.getAllIndustries?.map(industry => (
                <Option key={industry.name}>
                  <div className={styles['all-data']}>
                    <img src={industry.icon} alt="" />
                    <span>{industry.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Stage" name="stage">
            <Select
              value={stage}
              onChange={e => setStage(e)}
              style={{width: 340}}
              defaultValue={campaignData?.getCampaignById?.stage}
              placeholder="Select Stage">
              {companyStageData?.map(data => (
                <Option key={data.value}>
                  <div>{data.label}</div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item rules={[{type: 'url'}]} label="Website" name="website">
            <Input
              value={website}
              onChange={e => setWebsite(e.target.value)}
              style={{width: 340}}
              defaultValue={campaignData?.getCampaignById?.website}
              placeholder="www.website.com"
            />
          </Form.Item>
        </Form>
      </div>
      <div>
        <h1 className={styles.title}>Social Media</h1>
        <Form layout="vertical" className={styles.form}>
          <Form.Item
            rules={[{type: 'url'}]}
            style={{width: 340}}
            name="twitter"
            label="Twitter">
            <Input
              value={twitterURL}
              onChange={e => setTwitterURL(e.target.value)}
              defaultValue={campaignData?.getCampaignById?.socials?.twitter}
              placeholder="https://twitter.com/"
            />
          </Form.Item>
          <Form.Item
            rules={[{type: 'url'}]}
            style={{width: 340}}
            name="facebook"
            label="Facebook">
            <Input
              placeholder="https://facebook.com/"
              value={facebookURL}
              onChange={e => setFacebookURL(e.target.value)}
              defaultValue={campaignData?.getCampaignById?.socials?.facebook}
            />
          </Form.Item>
          <Form.Item
            rules={[{type: 'url'}]}
            style={{width: 340}}
            name="linkedin"
            label="Linkedin">
            <Input
              placeholder="https://linkedin.com/"
              defaultValue={campaignData?.getCampaignById?.socials?.linkedin}
              value={linkedinURL}
              onChange={e => setLinkedinURL(e.target.value)}
            />
          </Form.Item>
        </Form>
      </div>
      <div>
        <SubmitButton
          disabled={campaignLoading}
          label="Update"
          handleClick={handleSave}
        />
      </div>
    </div>
  );
};

export default UpdateCampaignCompanyDetails;
