import {Form, Input} from 'antd';
import {Fragment, useEffect, useState} from 'react';
import trash from 'images/trash-can-outline.svg';
import plusIcon from 'images/plus-circle.png';
import styles from './campaignNews.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {statistics} from 'redux/store/campaign';
import FadeEffect from 'components/animation/fadeEffect';

const CampaignStatistics = () => {
  const [statisticValues, setStatisticValues] = useState([]);
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(false);
  const {id, companyId} = useParams();
  const dispatch = useDispatch();

  const campaign = useSelector(state => state.campaign.campaign);

  useEffect(() => {
    if (campaign.statistics != undefined) {
      setStatisticValues(
        campaign?.statistics?.map(data => ({
          statisticName: data.statisticName,
          statisticsValue: data.statisticsValue,
        })),
      );
    }
  }, [campaign.statistics]);

  useEffect(() => {
    if (statisticValues.length) {
      statisticValues?.map(data =>
        setDisableButton(!data.statisticName || !data.statisticsValue),
      );
    } else {
      setDisableButton(false);
    }
  }, [statisticValues]);

  const handleChangeStatisticsValue = (i, e) => {
    const newFormValues = [...statisticValues];
    newFormValues[i][e.target.name] = e.target.value;
    setStatisticValues(newFormValues);
  };

  const handleAddStatistics = () => {
    setStatisticValues([
      ...statisticValues,
      {statisticName: '', statisticsValue: ''},
    ]);
  };

  const handleDeleteStatistics = id => {
    const filteredFormValue = statisticValues.filter(
      (item, index) => index !== id,
    );
    setStatisticValues(filteredFormValue);
  };

  const handleSave = () => {
    dispatch(
      statistics({
        statistics: statisticValues,
      }),
    );
    navigate(`/dashboard/campaigns/new/${id}/investment-contract/${companyId}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>
          {campaign?.listType === 'IPO' ? 'Financials' : 'Key Statistics'}
        </h1>
      </div>
      <div>
        <Form layout="vertical">
          {statisticValues.map((element, index) => (
            <Fragment key={index}>
              <FadeEffect>
                <div className={styles['question-style']}>
                  <Form.Item
                    style={{width: 320, marginRight: 20}}
                    label="Statistic Name">
                    <Input
                      value={element.statisticName || ''}
                      name="statisticName"
                      onChange={e => handleChangeStatisticsValue(index, e)}
                      placeholder="Statistics Name"
                    />
                  </Form.Item>
                  <Form.Item
                    style={{width: 320}}
                    label="Enter Statistics Associated Value">
                    <Input
                      name="statisticsValue"
                      value={element.statisticsValue || ''}
                      onChange={e => handleChangeStatisticsValue(index, e)}
                      placeholder="Statistics Value"
                    />
                  </Form.Item>
                  <img
                    src={trash}
                    onClick={() => handleDeleteStatistics(index)}
                    alt=""
                  />
                </div>
              </FadeEffect>
            </Fragment>
          ))}
        </Form>
        <div onClick={handleAddStatistics} className={styles['add-risks']}>
          <img src={plusIcon} alt="add-founder" />
          <span>Add Field</span>
        </div>
        <SubmitButton
          disabled={disableButton}
          label="Save & Continue"
          handleClick={handleSave}
        />
      </div>
    </div>
  );
};

export default CampaignStatistics;
