import {Link, useMatch, useResolvedPath} from 'react-router-dom';
import styles from './customLink.module.scss';
import classNames from 'classnames/bind';

const CustomLink = ({icon, name, to, plain, ...props}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({path: resolved.pathname, end: true});
  const cx = classNames.bind(styles);

  return (
    <>
      {!plain && (
        <Link
          to={to}
          className={cx('navLink', match ? 'active' : '')}
          {...props}>
          {!!icon && icon}
          <span>{name}</span>
        </Link>
      )}

      {plain && (
        <Link to={to} className={cx('plain', match ? 'active' : '')} {...props}>
          {!!icon && icon}
          <span>{name}</span>
        </Link>
      )}
    </>
  );
};

export default CustomLink;
