import {gql} from '@apollo/client';

export const GET_ALL_USER_ROLES = gql`
  query GetAllUserRole($filter: UserRoleFilter) {
    getAllUserRole(filter: $filter) {
      ... on ResponseWithUserRoles {
        data {
          id
          name
          description
          tabs {
            tab
            permissions {
              id
            }
            operations
          }
        }
      }
    }
  }
`;

export const GET_USER_ROLE_BY_ID = gql`
  query GetUserRoleById($getUserRoleByIdId: ID!) {
    getUserRoleById(id: $getUserRoleByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUserRole {
        data {
          id
          name
          description
          tabs {
            tab
            operations
            permissions {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_USERS_PERMISSIONS = gql`
  query GetAllUserPermission($filter: UserPermissionFilter) {
    getAllUserPermission(filter: $filter) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUserPermissions {
        data {
          id
          operation
          description
        }
      }
    }
  }
`;
