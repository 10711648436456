import styles from './userfeeds.module.scss';
import Avatar from 'images/avatar.png';
import Cry from 'images/cry.png';
import Clap from 'images/clap.png';
import Fire from 'images/fire.png';
import {Post} from '@dabafinance/react-components';

const UserFeed = () => {
  const topReactions = [
    {key: 1, img: Cry},
    {key: 2, img: Clap},
    {key: 3, img: Fire},
  ];
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>User Feeds</h1>
      </div>
      <div>
        <Post
          isVerified={true}
          timeStamp="2m"
          comments={324}
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi nibh malesuada dui, proin. Eu dolor aliquet in pretium, senectus pellentesque ac feugiat netus. Facilisi sit et odio neque arcu mi et eleifend..."
          avatar={Avatar}
          username="@ralphdedwards"
          topReactions={topReactions}
          // postImage={PostOne}
        />
      </div>
    </div>
  );
};

export default UserFeed;
