import styles from './campaignFounders.module.scss';
import {Input, Form, Upload} from 'antd';
import trash from 'images/trash-can-outline.png';
import imgUpload from 'images/upload_image.png';
import {Button} from '@dabafinance/react-components';

const CampaignMemberCard = ({
  deleteMember,
  fullName,
  handleChangeImage,
  jobTitle,
  linkedinLink,
  previewImage,
  handleChange,
  twitterLink,
  handleDeleteImage,
}) => {
  return (
    <div className={styles['card-container']}>
      <div className={styles['left-grid']}>
        {previewImage ? (
          <div className={styles['c-image-uploaded']}>
            <img src={previewImage} alt="" />
            <Button
              className={styles['delete-button']}
              label="Change Image"
              type="secondary"
              onClick={handleDeleteImage}
            />
          </div>
        ) : (
          <Upload
            accept=".png, .jpeg, .jpg"
            name="file"
            onChange={handleChangeImage}>
            <div className={styles['c-company-upload']}>
              <img
                className={styles['company-image']}
                src={imgUpload}
                alt="upload"
              />
              <div style={{marginTop: 6}}>Upload Image</div>
            </div>
          </Upload>
        )}
      </div>
      <div className={styles['right-grid']}>
        <Form layout="vertical">
          <div className={styles.form}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Member name is required',
                },
              ]}
              label="Full Name">
              <Input
                value={fullName}
                onChange={e => handleChange?.(e)}
                name="fullName"
                style={{width: 300}}
                placeholder="Courtney Henry"
              />
            </Form.Item>
            <Form.Item
              label="Job Title"
              className={styles['form-item']}
              rules={[
                {
                  required: true,
                  message: 'Member Job title is Required',
                },
              ]}>
              <Input
                name="jobTitle"
                value={jobTitle}
                onChange={e => handleChange?.(e)}
                className={styles.date}
                style={{width: 300}}
                placeholder={'CTO'}
              />
            </Form.Item>
            <Form.Item label="Twitter">
              <Input
                name="twitter"
                style={{width: 300}}
                value={twitterLink}
                onChange={e => handleChange?.(e)}
                placeholder="https://twitter.com"
              />
            </Form.Item>
            <Form.Item label="Linkedin">
              <Input
                name="linkedin"
                style={{width: 300}}
                value={linkedinLink}
                onChange={e => handleChange?.(e)}
                placeholder="https://linkedin.com"
              />
            </Form.Item>
            <div className={styles['founder-ceo']}>
              <div className={styles['founder-status']}></div>
            </div>
          </div>
          <div className={styles['delete-founder']}>
            <div
              onClick={e => {
                e.stopPropagation();
                deleteMember?.();
              }}>
              <img src={trash} alt="trash" />
              <span>Delete</span>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CampaignMemberCard;
