import gql from 'graphql-tag';

export const CATEGORIZE_NEWS = gql`
  mutation CategorizeNews($data: CategorizationInput) {
    categorizeNews(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on NewsUpdateType {
        news {
          id
          topics {
            name
            icon
          }
          isBreakingNews
          isTrendingNews
          isCategorized
          title
        }
      }
    }
  }
`;

export const CREATE_NEWS = gql`
  mutation CreateNews($data: NewsInput) {
    createNews(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on CreateNewsType {
        news {
          id
          title
          link
          featuredImage
          keywords
          isBreakingNews
          isSelectedNews
          isTrendingNews
          topics {
            id
            name
            icon
          }
          countries {
            name
            icon
          }
          publishedDate
          newsSource {
            source
            name
          }
          companies {
            id
            name
            logoImgURL
          }
        }
      }
    }
  }
`;

export const DELETE_NEWS = gql`
  mutation DeleteNews($deleteNewsId: ID!) {
    deleteNews(id: $deleteNewsId) {
      ... on Error {
        message
        statusCode
      }
      ... on DeleteNewsType {
        news {
          status
        }
      }
    }
  }
`;

export const EDIT_NEWS = gql`
  mutation UpdateNews($newsId: ID!, $data: NewsInput) {
    updateNews(newsId: $newsId, data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on UpdateNewsType {
        news {
          id
          title
          link
          featuredImage
          isBreakingNews
          isTrendingNews
          isSelectedNews
          topics {
            name
            icon
          }
          publishedDate
          newsSource {
            source
            url
          }
          companies {
            id
            name
            logoImgURL
          }
        }
      }
    }
  }
`;
