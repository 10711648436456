import DBModal from 'components/modal/modal';
import styles from './emailTriggerModal.module.scss';
import {Form, Input, message} from 'antd';
import {useState} from 'react';
import {toast} from 'react-toastify';
import plusIcon from 'images/plus-circle.png';
import trash from 'images/trash-can-outline.png';
import {TRIGGER_WALLET_REPORT} from 'graphql/mutations/wallet';
import {useMutation} from '@apollo/client';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';

const EmailTriggerModal = ({isOpen, onCloseModal}) => {
  const [emails, setEmails] = useState(['']);
  const [openFinishModal, setOpenFinishModal] = useState(false);

  const handleDelete = id => {
    setEmails(emails?.filter((data, index) => index !== id));
  };

  const [triggerWalletReportJob, {loading}] = useMutation(
    TRIGGER_WALLET_REPORT,
  );

  const handleSubmit = () => {
    triggerWalletReportJob({
      variables: {
        emails: emails,
      },
    })
      .then(({data: triggerWalletReportJob}) => {
        if (triggerWalletReportJob.__typename === 'Error') {
          toast.error(
            `Something went wrong!!! - ${triggerWalletReportJob.message} `,
          );
        } else {
          setOpenFinishModal(false);
          toast.success('Sent to Email(s) Successfully');
          onCloseModal();
          setEmails(['']);
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err}`));
  };

  const onChangeEmailFields = (value, key) => {
    const values = [...emails];
    values[key] = value;
    setEmails(values);
  };

  return (
    <DBModal
      isOpen={isOpen}
      handleClose={onCloseModal}
      content={
        <div className={styles.modal}>
          <h1>Generate Wallet Report for Pending and Failed Transactions</h1>
          <p>Reports will be sent to the specified email addresses.</p>
          <Form
            onFinish={() => {
              if (!emails.length || emails.some(data => data == ''))
                return message.error('Some Required Fields are Missing');
              setOpenFinishModal(true);
            }}
            layout="vertical">
            <div className={styles.emails}>
              {emails.map((data, index) => (
                <div className={styles.emailField} key={index}>
                  <Form.Item className={styles.emailWidth} label="Email">
                    <Input
                      type="email"
                      value={data}
                      onChange={e => onChangeEmailFields(e.target.value, index)}
                    />
                  </Form.Item>
                  <img onClick={() => handleDelete(index)} src={trash} />
                </div>
              ))}
            </div>
            <div
              onClick={() => setEmails([...emails, ''])}
              className={styles.add}>
              <img src={plusIcon} alt="" />
              <h5>Add Email</h5>
            </div>
            <SubmitButton disabled={loading} label="Save Changes" />
          </Form>
          <ConfirmModal
            openModal={openFinishModal}
            setOpenModal={setOpenFinishModal}
            confirmText={'Are you sure the detailed were inputed correctly?'}
            title={'Trigger Wallet Report'}
            loading={loading}
            handleOperation={handleSubmit}
          />
        </div>
      }
    />
  );
};

export default EmailTriggerModal;
