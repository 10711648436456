import {
  UserbookmarkedCard,
  UserBookmarkedNewsCard,
  UserSavedInterviewsCard,
} from './userbookmarkedCard';
import styles from './userbookmarked.module.scss';

const UserBookmarked = () => {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles['saved-reports']}>
          <h1>Saved Reports</h1>
          <div className={styles.report}>
            <UserbookmarkedCard />
            <UserbookmarkedCard />
            <UserbookmarkedCard />
          </div>
        </div>
        <div className={styles.news}>
          <h1>News</h1>
          <div className={styles.report}>
            <UserBookmarkedNewsCard />
            <UserBookmarkedNewsCard />
            <UserBookmarkedNewsCard />
          </div>
        </div>
        <div className={styles.news}>
          <h1>Saved Interviews</h1>
          <div className={styles.report}>
            <UserSavedInterviewsCard />
            <UserSavedInterviewsCard />
            <UserSavedInterviewsCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBookmarked;
