/* eslint-disable no-unused-vars */
import {createSlice} from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'app', // name of reducer
  initialState: {
    isInitialising: true, // boolean to be toggled when making api calls to get data about current user
    appTheme: 'light',
    loading: {},
    dividendId: null,
    pastDividendFilter: {},
    investorUpdates: [],
  },
  reducers: {
    initialisingStart: (state, action) => {
      state.isInitialising = true;
    },
    initialisingDone: (state, action) => {
      state.isInitialising = false;
    },
    updateLoading: (state, action) => {
      state.loading = {
        ...state.loading,
        [action.payload.event]: action.payload.value,
      };
    },
    loadingStart: (state, action) => {
      state.loading = {
        ...state.loading,
        [action.payload]: true,
      };
    },
    loadingEnd: (state, action) => {
      state.loading = {
        ...state.loading,
        [action.payload]: false,
      };
    },
    updateInvestorsUpdate: (state, action) => {
      state.investorUpdates = [...action.payload];
    },

    markInvestorsUpdateAsRead: (state, action) => {
      const newState = state;
      newState.investorUpdates.map(update => {
        if (update.update.id === action.payload) {
          update.user.isRead = true;
        }
      });
      return newState;
    },
    handleUpdateDividendId: (state, action) => {
      return {
        ...state,
        dividendId: action.payload.dividendId,
      };
    },
    handlePastDivideId: (state, action) => {
      return {
        ...state,
        pastDividendFilter: action.payload.data,
      };
    },
  },
});

export default slice.reducer;

export const {
  initialisingStart,
  initialisingDone,
  loadingStart,
  loadingEnd,
  updateInvestorsUpdate,
  handleUpdateDividendId,
  markInvestorsUpdateAsRead,
  handlePastDivideId,
} = slice.actions;
