import {Dropdown, Menu, Table, Switch, Form, InputNumber, Tag} from 'antd';
import styles from './fxExchangeRate.module.scss';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {
  GET_ALL_FX_EXCHANGE_RATES,
  GET_PUBLIC_EXCHANGE_RATES,
} from 'graphql/queries/wallet';
import {useNavigate} from 'react-router-dom';
import SubmitButton from 'components/Startup/components/SubmitButton';
import dotsIcon from 'icons/dots.svg';
import {currencyMatch} from 'pages/campaigns/campaignData';
import {
  DELETE_FX_EXCHANGE_PAIR,
  ENABLE_PAIR_STATUS,
  SET_DAILY_THRESHOLD_FOR_SWAP,
} from 'graphql/mutations/wallet';
import DBModal from 'components/modal/modal';
import {useEffect, useState} from 'react';
import {Button} from '@dabafinance/react-components';
import {toast} from 'react-toastify';
import {currencySymbols} from 'utils/mock';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';
import {useSelector} from 'react-redux';

const FxExchangeRate = () => {
  const [modalData, setModalData] = useState({});
  const {data, loading, refetch} = useQuery(GET_ALL_FX_EXCHANGE_RATES);
  const [fieldValues, setFieldValues] = useState({});
  const [isFinishScreen, setIsFinishScreen] = useState(false);

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Swap),
  );

  const [marketRatesState, setMarketRatesState] = useState({});

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [removeFxExchangeRate, {loading: loadingDelete}] = useMutation(
    DELETE_FX_EXCHANGE_PAIR,
  );
  const [enableOrDisableFxExchangeRate, {loading: loadingEnable}] =
    useMutation(ENABLE_PAIR_STATUS);

  const [setDailyThresholdForAutomaticSwap, {loading: loadingThresholdUpdate}] =
    useMutation(SET_DAILY_THRESHOLD_FOR_SWAP);
  const [checkMarketRate, {loading: loadingRate}] = useLazyQuery(
    GET_PUBLIC_EXCHANGE_RATES,
    {
      fetchPolicy: 'no-cache',
      onError: error => toast.error(error.message),
      errorPolicy: 'all',
    },
  );

  const handleCheckMarketRate = (sourceCurrency, targetCurrency) => {
    checkMarketRate({
      variables: {
        from: sourceCurrency,
        to: [targetCurrency],
      },
    }).then(({data}) => {
      if (
        data &&
        !loadingRate &&
        sourceCurrency === data?.getPublicFxExchangeRates?.from &&
        targetCurrency === data?.getPublicFxExchangeRates?.to[0]?.currency
      ) {
        setMarketRatesState(prevState => ({
          ...prevState,
          [`${sourceCurrency}_${targetCurrency}`]: {
            checked: true,
            value: data?.getPublicFxExchangeRates?.to[0]?.rate,
          },
        }));
      }
    });
  };

  useEffect(() => {
    if (modalData) {
      form.setFieldsValue({
        amount: modalData.amount,
        remain: modalData.remaining,
      });
    }
  }, [modalData]);

  const handleDeletePair = () => {
    removeFxExchangeRate({
      variables: {
        removeFxExchangeRateId: modalData.id,
      },
    })
      .then(({data: {removeFxExchangeRate}}) => {
        if (removeFxExchangeRate.__typename === 'Error') {
          toast.error(removeFxExchangeRate.message);
        } else {
          toast.success('Swap Pair Deleted Successfully');
          refetch();
          setModalData({isOpen: false});
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  const handleUpdatePairStatus = () => {
    enableOrDisableFxExchangeRate({
      variables: {
        enableOrDisableFxExchangeRateId: modalData.id,
        isEnabled: !modalData.enabled,
      },
      refetchQueries: [{query: GET_ALL_FX_EXCHANGE_RATES}],
    })
      .then(({data: {enableOrDisableFxExchangeRate}}) => {
        if (enableOrDisableFxExchangeRate.__typename === 'Error') {
          toast.error(enableOrDisableFxExchangeRate.message);
        } else {
          toast.success('Swap Pair Updated Successfully');
          refetch();
          setModalData({isOpen: false});
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  const columns = [
    {
      title: 'Currency Pair',
      dataIndex: 'sourceCurrency',
      key: 'sourceCurrency',
      render: (text, record) => (
        <span>
          {record?.sourceCurrency} / {record?.targetCurrency}
        </span>
      ),
    },
    {
      title: 'Rate',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => (
        <span>
          1 {record?.sourceCurrency} to{' '}
          {currencyMatch[record?.targetCurrency]?.symbol}
          {record?.rate}
        </span>
      ),
    },

    {
      title: 'Market Rate',
      dataIndex: 'marketRate',
      key: 'marketRate',
      render: (text, record) => {
        const {sourceCurrency, targetCurrency} = record;
        const checkerField = [`${sourceCurrency}_${targetCurrency}`];
        const isMarketRateChecked = marketRatesState[checkerField]?.checked;

        return !isMarketRateChecked ? (
          <div className={styles.columnButton}>
            <Button
              type="secondary"
              label="Check"
              disabled={loadingRate}
              onClick={() =>
                handleCheckMarketRate(sourceCurrency, targetCurrency)
              }
            />
          </div>
        ) : (
          <Tag color="default">
            {currencySymbols[targetCurrency]?.symbol}{' '}
            {marketRatesState[checkerField]?.value}
          </Tag>
        );
      },
    },
    {
      title: 'Daily Threshold',
      dataIndex: 'dailyThreshold',
      key: 'dailyThreshold',
      render: (text, record) =>
        record?.dailyThresholdForAutomaticSwapToTargetCurrency?.initial ? (
          <span>
            {currencySymbols[record?.targetCurrency]?.symbol}{' '}
            {record?.dailyThresholdForAutomaticSwapToTargetCurrency?.initial?.toLocaleString()}
          </span>
        ) : (
          <Tag color="default">NOT SET</Tag>
        ),
    },
    {
      title: 'Remaining Daily Threshold',
      dataIndex: 'dailyThreshold',
      key: 'dailyThreshold',
      render: (text, record) =>
        record?.dailyThresholdForAutomaticSwapToTargetCurrency?.remaining ? (
          <span>
            {currencySymbols[record?.targetCurrency]?.symbol}{' '}
            {record?.dailyThresholdForAutomaticSwapToTargetCurrency?.remaining?.toLocaleString()}
          </span>
        ) : (
          <Tag color="default">NOT SET</Tag>
        ),
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      key: 'enabled',
      render: (text, record) => (
        <div style={{maxWidth: '50px'}}>
          <Switch
            checked={record?.enabled}
            defaultChecked={record?.enabled}
            disabled={!tabOperations?.update}
            onClick={() =>
              setModalData({
                isOpen: true,
                enabled: record?.enabled,
                id: record.id,
                type: 'ENABLE',
              })
            }
          />
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'Actions',
      render: (text, render) => (
        <Dropdown
          trigger="hover"
          overlay={
            <Menu>
              {tabOperations?.update && (
                <>
                  <Menu.Item
                    onClick={() =>
                      navigate(
                        `/dashboard/fx-exchange-rate/update/${render?.id}`,
                      )
                    }
                    key="1">
                    <div className={styles['menu-items']}>
                      <span>Update Settings</span>
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    onClick={() =>
                      navigate(
                        `/dashboard/wallet/swap-transactions/${render?.sourceCurrency}`,
                      )
                    }>
                    <div className={styles['menu-items']}>
                      <span>View Transactions</span>
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      setModalData({
                        thresholdOpen: true,
                        id: render.id,
                        sourceCurrency: render?.sourceCurrency,
                        targetCurrency: render?.targetCurrency,
                        amount:
                          render?.dailyThresholdForAutomaticSwapToTargetCurrency
                            ?.initial,
                        remaining:
                          render?.dailyThresholdForAutomaticSwapToTargetCurrency
                            ?.shouldNotifyAdminIfRemainingIsBelow,
                        type: 'THRESHOLD',
                      })
                    }
                    key="3">
                    Set Daily Threshold
                  </Menu.Item>
                </>
              )}
              {!render?.enabled && tabOperations?.delete && (
                <Menu.Item
                  onClick={() => setModalData({isOpen: true, id: render.id})}
                  key="4">
                  Delete Pair
                </Menu.Item>
              )}
            </Menu>
          }>
          <img src={dotsIcon} onClick={e => e.stopPropagation()} />
        </Dropdown>
      ),
    },
  ];

  const handleSetThreshold = () => {
    setDailyThresholdForAutomaticSwap({
      variables: {
        amount: Number(fieldValues.amount),
        sourceCurrency: modalData.sourceCurrency,
        targetCurrency: modalData.targetCurrency,
        ...(fieldValues.remain && {
          shouldNotifyAdminIfRemainingIsBelow: Number(fieldValues.remain),
        }),
      },
    })
      .then(({data: {setDailyThresholdForAutomaticSwap}}) => {
        if (setDailyThresholdForAutomaticSwap.__typename === 'Error') {
          toast.error(setDailyThresholdForAutomaticSwap.message);
        } else {
          toast.success('Swap Pair Daily Threshold Updated Successfully');
          setModalData({thresholdOpen: false});
          setIsFinishScreen(false);
          refetch();
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <div className={styles.root}>
      <div className={styles.nav}>
        <h1>Currency Swap Settings</h1>
      </div>

      <div className={styles.table}>
        <SubmitButton
          label="Add New Swap Pair"
          disabled={!tabOperations?.create}
          handleClick={() => navigate('/dashboard/fx-exchange-rate/create')}
        />
        <Table
          loading={loading}
          dataSource={data?.getAllFxExchangeRates?.rates}
          columns={columns}
        />
      </div>
      <DBModal
        isOpen={modalData.isOpen}
        handleClose={() => setModalData({isOpen: false})}
        content={
          <div className={styles['modal-container']}>
            {modalData.type === 'ENABLE' ? (
              <h1>Enable / Disable this Pair</h1>
            ) : (
              <h1>Are you sure You want to Delete This Currency Pair?</h1>
            )}
            <div>
              <Button
                disabled={loadingDelete || loadingEnable}
                className={styles['modal-button']}
                label={'Yes'}
                onClick={
                  modalData.type === 'ENABLE'
                    ? handleUpdatePairStatus
                    : handleDeletePair
                }
              />
              <Button
                className={styles['modal-button']}
                type="secondary"
                label="No"
                onClick={() => setModalData({isOpen: false})}
              />
            </div>
          </div>
        }
      />
      <DBModal
        isOpen={modalData.thresholdOpen}
        handleClose={() => setModalData({thresholdOpen: false})}
        content={
          isFinishScreen ? (
            <>
              <div className="finishScreen">
                <h1>You are about to UPDATE the Daily Threshold Limit</h1>
                <p>
                  Are you sure you want to Proceed with updating this on{' '}
                  {modalData?.sourceCurrency}/{modalData?.targetCurrency} Pair?
                </p>
                <div className="finish-items">
                  <div className="items">
                    <p>Threshold Amount</p>
                    <span>
                      {currencySymbols[modalData?.targetCurrency]?.symbol}
                      {fieldValues?.amount.toLocaleString()}
                    </span>
                  </div>
                  <div className="items">
                    <p>Amount to Notify Admin</p>
                    <span>
                      {currencySymbols[modalData?.targetCurrency]?.symbol}
                      {fieldValues?.remain.toLocaleString()}
                    </span>
                  </div>
                </div>
                <div className="finish-buttons">
                  <Button
                    className="finish-button"
                    label="No"
                    onClick={() => setIsFinishScreen(false)}
                    type="secondary"
                  />
                  <Button
                    onClick={handleSetThreshold}
                    className="finish-button"
                    disabled={loadingThresholdUpdate}
                    label="Yes"
                  />
                </div>
              </div>
            </>
          ) : (
            <div className={styles['modal-container']}>
              <h1>
                Update Daily Threshold Setting for{' '}
                {modalData.sourceCurrency + '/' + modalData.targetCurrency} Pair
              </h1>
              <Form
                onFinish={values => {
                  setFieldValues(values);
                  setIsFinishScreen(true);
                }}
                form={form}
                layout="vertical">
                <Form.Item
                  rules={[{required: true, message: 'This Field is required'}]}
                  name="amount"
                  label="Threshold Amount">
                  <InputNumber
                    className={styles.bigWidth}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item
                  name="remain"
                  label="Limit Amount (Once the Threshold Amount is below this Amount, the admin would be notified)">
                  <InputNumber
                    className={styles.bigWidth}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <SubmitButton label="Update" />
              </Form>
            </div>
          )
        }
      />
    </div>
  );
};

export default FxExchangeRate;
