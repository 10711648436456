import {Form, Input, InputNumber, Spin} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import styles from './updateExternalInvestment.module.scss';
import {useParams} from 'react-router-dom';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {useMutation, useQuery} from '@apollo/client';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';
import plusIcon from 'images/plus-circle.png';
import deleteIcon from 'images/delete.png';

const UpdateExternalInvestment = () => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const {id, language} = useParams();
  let width = 386;

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });
  const [updateCampaign, {loading: updateLoading}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    setFormValues(
      campaignData?.getCampaignById?.externallyFundedAmountBreakDown?.map(
        item => ({
          name: item?.name,
          amount: item?.amount,
        }),
      ),
    );
  }, [campaignData?.getCampaignById]);

  useEffect(() => {
    if (formValues?.length) {
      formValues?.map(data => {
        const isValid = !data.name || !data.amount;
        return setDisableButton(isValid);
      });
    } else {
      setDisableButton(false);
    }
  }, [formValues]);

  const handleAddInvestment = () => {
    setFormValues([
      ...formValues,
      {
        name: '',
        amount: 0,
      },
    ]);
  };

  const handleDeleteInvestment = id => {
    const filteredFormValue = formValues.filter((data, index) => index !== id);
    setFormValues(filteredFormValue);
  };

  const handleChangeAmount = (i, e) => {
    const newFormValues = [...formValues];
    newFormValues[i].amount = e;
    setFormValues(newFormValues);
  };

  const handleChangeName = (i, e) => {
    const newFormValues = [...formValues];
    newFormValues[i].name = e;
    setFormValues(newFormValues);
  };

  const handleSave = () => {
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          externallyFundedAmountBreakDown: formValues,
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error(updateCampaign.message);
        } else {
          toast.success('External Investment Details Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles['container']}>
      <Form onc form={form} onFinish={handleSave} layout="vertical">
        <div>
          <h1 className={styles.header}>External Investment Details</h1>
        </div>
        <div>
          {formValues?.map((value, index) => (
            // eslint-disable-next-line react/jsx-key
            <div className={styles.form}>
              <Form.Item
                style={{width}}
                label="Name"
                rules={[
                  {
                    required: true,
                    message: 'Name is required',
                  },
                ]}>
                <Input
                  value={value?.name}
                  onChange={e => handleChangeName(index, e.target.value)}
                  placeholder="Enter Name"
                />
              </Form.Item>
              <Form.Item style={{width}} label="Funded Amount">
                <InputNumber
                  className={styles['input-number']}
                  value={value?.amount}
                  onChange={e => handleChangeAmount(index, e)}
                  placeholder="Enter amount"
                  formatter={value =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
              <div className={styles.delete}>
                <img
                  onClick={() => handleDeleteInvestment(index)}
                  src={deleteIcon}
                  alt=""
                />
              </div>
            </div>
          ))}
          <div onClick={handleAddInvestment} className={styles['add-button']}>
            <img src={plusIcon} alt="" />
            <h1>Add Investment</h1>
          </div>
          <div className={styles['warning']}>
            {disableButton && <span>IMPORTANT: Both Fields are Required</span>}
          </div>
          <SubmitButton
            disabled={updateLoading || disableButton}
            label="Update"
          />
        </div>
      </Form>
    </div>
  );
};

export default UpdateExternalInvestment;
