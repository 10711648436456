import {DatePicker, Form, Input} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import moment from 'moment';
import {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import plusIcon from 'images/plus-circle.png';

import {listingIPO} from 'redux/store/campaign';
import IpoFeeCard from './ipoFeeCard/IpoFeeCard';
import styles from './listingIPO.module.scss';

const ListingIPO = () => {
  const [numSharesAllocated, setNumSharesAllocated] = useState(0);
  const [expectedIpo, setExpectedIpo] = useState('');
  const [offeringName, setOfferingName] = useState('');
  const [ipoSharePrice, setIpoSharePrice] = useState(0);
  const [numOfOutstandingShares, setNumOfOutstandingShares] = useState(0);
  const {slug, id, companyId} = useParams();
  const [ipoFeesValues, setIpoFeesValues] = useState([
    {
      feeName: '',
      feeType: '',
      fixedFeeAmount: '',
      variableFeeAmount: '',
    },
  ]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const campaign = useSelector(state => state.campaign.campaign);

  useEffect(() => {
    if (campaign?.numSharesAllocated) {
      setNumSharesAllocated(campaign?.numSharesAllocated);
      setExpectedIpo(campaign?.expectedIpo);
      setOfferingName(campaign?.offeringName);
      setIpoSharePrice(campaign?.ipoSharePrice);
      setNumOfOutstandingShares(campaign?.numOfOutstandingShares);
      setOfferingName(campaign?.offeringName);
    }
  }, [campaign?.numSharesAllocated]);

  useEffect(() => {
    if (campaign?.ipoFeesValues) {
      setIpoFeesValues(
        campaign?.ipoFeesValues?.map(data => ({
          feeName: data?.feeName,
          feeType: data?.feeType,
          fixedFeeAmount: data?.fixedFeeAmount,
          variableFeeAmount: data?.variableFeeAmount,
        })),
      );
    }
  }, [campaign?.ipoFeesValues]);

  const handleChangeIpoFeesValues = (index, event) => {
    const newFormValues = [...ipoFeesValues];
    newFormValues[index][event.target.name] = event.target.value;
    setIpoFeesValues(newFormValues);
  };

  const handleChangeIpoFeesType = (index, event) => {
    const newFormValues = [...ipoFeesValues];
    newFormValues[index].feeType = event;
    setIpoFeesValues(newFormValues);
  };

  const handleAddIpoFees = () => {
    setIpoFeesValues([
      ...ipoFeesValues,
      {
        feeName: '',
        feeType: '',
        fixedFeeAmount: '',
        variableFeeAmount: '',
      },
    ]);
  };

  const handleDeleteIpoFees = id => {
    const filteredIpoFee = ipoFeesValues.filter((_, index) => index !== id);
    setIpoFeesValues(filteredIpoFee);
  };

  const handleSubmit = () => {
    dispatch(
      listingIPO({
        numSharesAllocated,
        expectedIpo,
        ipoSharePrice,
        ipoFeesValues,
        numOfOutstandingShares,
        offeringName,
      }),
    );
    if (slug) {
      navigate(`/dashboard/campaigns/${slug}/new/${id}/founders/${companyId}`);
    } else {
      navigate(`/dashboard/campaigns/new/${id}/documents/${companyId}`);
    }
  };

  return (
    <div className={styles.container}>
      <h1>IPO Shares Details</h1>
      <Form onFinish={handleSubmit} layout="vertical">
        <Form.Item
          className={styles['form-item']}
          label="Number of Shares Allocated"
          name="numSharesAllocated"
          rules={[
            {
              required: true,
              message: 'Required Field',
            },
          ]}
          initialValue={campaign?.numSharesAllocated}>
          <Input
            defaultValue={campaign?.numSharesAllocated}
            value={numSharesAllocated}
            type="number"
            onChange={e => setNumSharesAllocated(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          className={styles['form-item']}
          label="Offering Name"
          name="offeringName"
          rules={[
            {
              required: true,
              message: 'Required Field',
            },
          ]}
          initialValue={campaign?.offeringName}>
          <Input
            defaultValue={campaign?.offeringName}
            value={offeringName}
            onChange={e => setOfferingName(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          className={styles['form-item']}
          label="IPO Share Price"
          rules={[
            {
              required: true,
              message: 'Required Field',
            },
          ]}
          name="ipoSharePrice"
          initialValue={campaign?.ipoSharePrice}>
          <Input
            defaultValue={campaign?.ipoSharePrice}
            value={ipoSharePrice}
            onChange={e => setIpoSharePrice(e.target.value)}
            type="number"
          />
        </Form.Item>
        <Form.Item
          label="Total Number of Shares outstanding for IPO"
          name="numOfOutstandingShares"
          rules={[
            {
              required: true,
              message: 'Required Field',
            },
          ]}
          className={styles['form-item']}
          initialValue={campaign?.numOfOutstandingShares}>
          <Input
            value={numOfOutstandingShares}
            onChange={e => setNumOfOutstandingShares(e.target.value)}
            type="number"
            defaultValue={campaign?.numOfOutstandingShares}
          />
        </Form.Item>
        <Form.Item
          label="Expected IPO Date"
          name="date"
          className={styles['form-item']}
          rules={[
            {
              required: true,
              message: 'Required Field',
            },
          ]}
          initialValue={
            campaign.expectedIpo
              ? moment(campaign?.expectedIpo, 'YYYY-MM-DD HH:mm:ss')
              : ''
          }>
          <DatePicker
            placeholder="Select Date Time"
            value={expectedIpo}
            defaultValue={
              campaign.expectedIpo
                ? moment(campaign?.expectedIpo, 'YYYY-MM-DD HH:mm:ss')
                : ''
            }
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
            format={'YYYY-MM-DD HH:mm:ss'}
            onChange={e =>
              setExpectedIpo(moment(e).format('YYYY-MM-DD HH:mm:ss'))
            }
            style={{width: 340}}
          />
        </Form.Item>
        <div>
          <h1>IPO Investment Details</h1>
        </div>
        <div className={styles.form}>
          {ipoFeesValues.map((data, index) => (
            <Fragment key={index}>
              <IpoFeeCard
                feeName={data.feeName || ''}
                feeType={data.feeType}
                fixedFeeAmount={data.fixedFeeAmount}
                variableFeeAmount={data.variableFeeAmount}
                handleChangeIpoFeesValues={e =>
                  handleChangeIpoFeesValues(index, e)
                }
                handleChangeIpoFeesType={e => handleChangeIpoFeesType(index, e)}
                handleDeleteIpoFees={e => handleDeleteIpoFees(index, e)}
              />
            </Fragment>
          ))}
        </div>
        <div onClick={handleAddIpoFees} className={styles['add-button']}>
          <img src={plusIcon} alt="" />
          <h2>Add Field</h2>
        </div>

        <SubmitButton label="Save Changes" />
      </Form>
    </div>
  );
};

export default ListingIPO;
