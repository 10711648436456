import {Spin, Table} from 'antd';
import styles from './allRewardSchemes.module.scss';
import {toast} from 'react-toastify';
import {NoUpdate} from 'components/noUpdate';
import {NavTabsEnum} from 'utils/constants';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {GET_COLLECTION_ORDERS} from 'graphql/queries/collections';
import {useQuery} from '@apollo/client';
import {useNavigate, useParams} from 'react-router-dom';
import moment from 'moment';

const CollectionOrders = () => {
  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.RewardBanks),
  );

  const {id} = useParams();

  const navigate = useNavigate();

  const {loading, data} = useQuery(GET_COLLECTION_ORDERS, {
    variables: {
      input: {
        collectionId: id,
        collection: true,
      },
    },
    errorPolicy: 'all',
    onError: error => toast.error(error?.message),
  });

  const columns = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <div
            onClick={() => navigate(`/dashboard/user/${record.id}`)}
            className={styles['record-name']}>
            <img src={record?.user?.imageUrl} alt="" />
            <h4 style={{width: 100}}>
              {record?.user?.firstName} {record?.user?.lastName}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: 'Date Invested',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record) => (
        <div>
          <span>
            {moment(record?.createdAt).format('DD MMM, YYYY. H:mm A')}
          </span>
        </div>
      ),
    },
    {
      title: 'Amount Invested',
      dataIndex: 'price',
      key: 'price',
      render: (text, render) => (
        <div className={styles['record-label']}>
          <span>{render?.price}</span>
        </div>
      ),
    },
    {
      title: 'No of Units Purchased',
      dataIndex: 'units',
      key: 'units',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <span>{record?.quantity}</span>
        </div>
      ),
    },
  ];

  return (
    <div className={styles['table-container']}>
      <div className={styles.header}>
        <h1>Users Invested</h1>
      </div>
      <div className={styles['startup-table']}>
        {loading ? (
          <div className={styles.loading}>
            <Spin size="large" />
            <h1>Loading...</h1>
          </div>
        ) : data?.getOrders?.data?.length >= 1 ? (
          <>
            <Table
              className={styles['user-table']}
              dataSource={data?.getOrders?.data}
              columns={columns}
              pagination
            />
          </>
        ) : (
          <NoUpdate
            title={'No Order for this collection yet'}
            btnDisabled={!tabOperations?.create}
          />
        )}
      </div>
    </div>
  );
};

export {CollectionOrders};
