import {Spin} from 'antd';
import styles from './createDocumentCenter.module.scss';
import FadeEffect from 'components/animation/fadeEffect';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import {Button} from '@dabafinance/react-components';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import {useState} from 'react';
import {UploadDocumentToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import {toast} from 'react-toastify';

const CompanyPDFUpload = ({
  pdfName,
  title,
  existingData,
  setExistingData,
  index,
  pdfFile,
  viewPdf,
  type,
  setSinglePDFFile,
  setPdfName,
  onlyButtons,
  setViewPDFLink,
  handleDeleteFile,
}) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [preview, setPreview] = useState(false);

  const handlePdfFileSave = e => {
    e.preventDefault();
    setPreview(prev => !prev);
  };

  const handlePdfFileChange = async e => {
    const selectedFile = e.target.files[0];
    if (type === 'SINGLE' && selectedFile.type === 'application/pdf') {
      setLoadingUpload(true);

      const imageUrl = await UploadDocumentToBucket(selectedFile, setProgress);
      setSinglePDFFile(imageUrl);
      setViewPDFLink(imageUrl);
      setLoadingUpload(false);
      setPdfName(selectedFile.name);
    } else {
      const updatedData = [...existingData]; // Create a copy of the array
      updatedData[index].pdfName = selectedFile.name;

      if (selectedFile) {
        if (selectedFile.type === 'application/pdf') {
          setLoadingUpload(true);
          // Use setState callback to ensure the state is updated before continuing

          const imageUrl = await UploadDocumentToBucket(
            selectedFile,
            setProgress,
          );
          updatedData[index].pdfLink = imageUrl;
          updatedData[index].pdfFile = imageUrl;
          updatedData[index].viewPdf = imageUrl;

          setLoadingUpload(false);
        } else {
          updatedData[index].pdfFile = null;
          toast.error('Please select a valid pdf file');
        }
      } else {
        toast.error('Select a PDF file');
      }
      setExistingData(updatedData);
    }
    setProgress(0);
  };

  const pdfViewType =
    type === 'SINGLE' ? viewPdf : existingData[index]?.viewPdf;

  return !onlyButtons ? (
    <>
      <div>
        <h1 className={styles.documentHeader}>{title}</h1>
        {!preview && (
          <form>
            <label className={styles.upload} htmlFor={`file-upload${index}`}>
              {loadingUpload ? (
                <div className={styles.spinner}>
                  <Spin size="large" />
                  <h3>Uploading...</h3>
                  <h4>{progress}%</h4>
                </div>
              ) : (
                <>
                  <span> {pdfName}</span>
                  <input
                    type="file"
                    id={`file-upload${index}`}
                    accept="application/pdf"
                    className={styles['input-file']}
                    required
                    onChange={handlePdfFileChange}
                  />
                </>
              )}
            </label>
          </form>
        )}

        {pdfViewType && preview && (
          <FadeEffect>
            <div className={styles['pdf-height']}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={
                    type === 'SINGLE' ? viewPdf : existingData[index]?.viewPdf
                  }
                  plugins={[defaultLayoutPluginInstance]}
                />
              </Worker>
            </div>
          </FadeEffect>
        )}
      </div>
      <div className={styles['button-wrapper']}>
        <Button
          disabled={
            type === 'SINGLE' ? !pdfFile : !existingData[index]?.pdfFile
          }
          label="Preview PDF"
          onClick={handlePdfFileSave}
        />
        <Button
          type="secondary"
          label="Delete"
          onClick={() => handleDeleteFile(index)}
        />
      </div>
    </>
  ) : (
    <div className={styles['button-wrapper']}>
      {preview && (
        <FadeEffect>
          <div className={styles['pdf-height']}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
              <Viewer
                fileUrl={
                  type === 'SINGLE' ? viewPdf : existingData[index]?.viewPdf
                }
                plugins={[defaultLayoutPluginInstance]}
              />
            </Worker>
          </div>
        </FadeEffect>
      )}
      <Button
        disabled={type === 'SINGLE' ? !pdfFile : !existingData[index]?.pdfFile}
        label={preview ? 'Close Preview' : 'Preview PDF'}
        onClick={handlePdfFileSave}
        className={styles.previewBtn}
      />
    </div>
  );
};

export default CompanyPDFUpload;
