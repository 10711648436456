import {Select, Input, Form, DatePicker, Spin} from 'antd';
import {useEffect} from 'react';
import './antd-select.scss';
import styles from './detailOverview.module.scss';
import {
  GET_A_STARTUP,
  GET_ALL_OPERATING_COUNTRIES,
} from 'graphql/queries/startupCompanies';
import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {stageData} from 'utils/mock';
import NumberinputField from 'components/NumberInputField/NumberinputField';
import {noOfEmployeesOptions} from 'utils/constants';

const DetailOverview = ({
  foundedOn,
  headquarter,
  setFoundedOn,
  setLocation,
  setNumOfEmployees,
  setAccelerator,
  setFundRaised,
  setTicker,
  setStage,
  setWebsite,
  setOperatingCountries,
}) => {
  const {Option} = Select;
  const {id} = useParams();

  const {loading, data} = useQuery(GET_A_STARTUP, {
    variables: {
      companyId: id,
    },
  });

  const {data: getAllCountries} = useQuery(GET_ALL_OPERATING_COUNTRIES, {
    variables: {
      type: 'COUNTRY',
    },
  });

  useEffect(() => {
    setOperatingCountries(
      data?.getCompany?.operatingCountries?.map(
        data => `${data?.name};${data?.id}`,
      ),
    );
  }, [data?.getCompany]);

  function disabledDate(current) {
    const customDate = new Date().getFullYear();
    return current && current > moment(customDate, 'YYYY');
  }
  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }
  return (
    <Form layout="vertical" name="overview">
      <h1 className={styles.header}>Overview</h1>
      <div className={styles['form-inputs']}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Operating Countries is required',
            },
          ]}
          label="Operating Countries"
          className={styles['form-item']}
          name="operatingCountries">
          <Select
            style={{width: 740}}
            className={styles['select-field']}
            placeholder={'Operating Countries'}
            defaultValue={data?.getCompany?.operatingCountries?.map(
              data => `${data?.name};${data?.id}`,
            )}
            onChange={e => setOperatingCountries(e)}
            mode="multiple"
            allowClear>
            {getAllCountries?.getAllTopics?.map(({id, name, icon}) => (
              <Option key={`${name};${id}`}>
                <div className={styles['all-data']}>
                  <img src={icon} alt="" />
                  <span>{name}</span>
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className={styles['input-width']}>
          <Form.Item
            label="Founder"
            name="date"
            rules={[
              {
                required: true,
                message: 'Please select year the Company was founded',
              },
            ]}
            className={styles['form-item']}>
            <DatePicker
              picker="year"
              value={foundedOn}
              disabledDate={disabledDate}
              style={{width: 340}}
              defaultValue={moment(data?.getCompany?.foundedOn, 'YYYY')}
              onChange={e => setFoundedOn(moment(e).format('YYYY'))}
            />
          </Form.Item>
        </div>
        <div className={styles['input-width']}>
          <Form.Item
            label="Location (Country)"
            className={styles['form-item']}
            name="location"
            rules={[
              {
                required: true,
                message: 'Company Location Required',
              },
            ]}>
            <Input
              className={styles.date}
              style={{width: 340}}
              value={headquarter}
              onChange={value => setLocation(value.target.value)}
              placeholder={'Select Location'}
              defaultValue={data?.getCompany?.headquarter}
            />
          </Form.Item>
        </div>
      </div>
      <div className={styles['form-inputs']}>
        <div className={styles['input-width']}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Company Stage Required',
              },
            ]}
            label="Stage"
            className={styles['form-item']}
            name="stage">
            <Select
              style={{width: 340}}
              className={styles['select-field']}
              placeholder={'SERIES_A'}
              defaultValue={data?.getCompany?.stage}
              onChange={e => setStage(e)}>
              {stageData?.map(name => (
                <Option key={name.value}>{name.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className={styles['input-width']}>
          <Form.Item
            label="Website"
            rules={[
              {
                required: true,
                message: 'Company Website Required',
              },
            ]}
            className={styles['form-item']}
            name="website">
            <Input
              style={{width: 340}}
              defaultValue={data?.getCompany?.website}
              onChange={e => setWebsite(e.target.value)}
              placeholder="https://paystack.com"
            />
          </Form.Item>
        </div>
        <div className={styles['input-width']}>
          <Form.Item
            label="Accelerator"
            className={styles['form-item']}
            name="accelerator">
            <Input
              className={styles['select-field']}
              onChange={e => setAccelerator(e.target.value)}
              style={{width: 340}}
              defaultValue={data?.getCompany?.accelerator}
              placeholder={'Y-Combinator'}
            />
          </Form.Item>
        </div>
        <div className={styles['input-width']}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Company Ticker Required',
              },
            ]}
            label="Ticker"
            className={styles['form-item']}
            name="ticker">
            <Input
              style={{width: 340}}
              defaultValue={data?.getCompany?.ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ticker"
            />
          </Form.Item>
        </div>
        <div className={styles['input-width']}>
          <Form.Item name="employees" label="No. of Employees">
            <Select
              defaultValue={data?.getCompany?.numberOfEmployees}
              onChange={e => setNumOfEmployees(e)}>
              {noOfEmployeesOptions?.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Capital Raised"
            className={styles['form-item']}
            name="totalCapitalRaised">
            <NumberinputField
              setValue={setFundRaised}
              defaultValue={data?.getCompany?.totalCapitalRaised}
            />
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default DetailOverview;
