import {useState, useEffect} from 'react';
import styles from './menu.module.scss';
import {useLocation} from 'react-router';
import classNames from 'classnames/bind';

const Menu = ({children, name, icon, ...props}) => {
  const cx = classNames.bind(styles);
  const [show, setShow] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setShow(false);
  }, [location.pathname]);

  return (
    <div className={styles.menu} {...props}>
      <button
        onClick={() => setShow(!show)}
        className={cx(show ? 'active' : '')}>
        {!!icon && icon}
        <span>{name}</span>
        <div>
          <i
            className={cx(
              'far',
              'fa-chevron-down',
              show ? 'fa-rotate-180' : '',
            )}></i>
        </div>
      </button>
      <div className={cx('menuItems', show ? 'show' : '')}>{children}</div>
    </div>
  );
};

export default Menu;
