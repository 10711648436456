import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styles from './fundAllocation.module.scss';
import plusIcon from 'images/plus-circle.png';
import deleteIcon from 'images/delete.svg';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {Form, Input} from 'antd';
import FadeEffect from 'components/animation/fadeEffect';
import {useDispatch, useSelector} from 'react-redux';
import {fundAllocation} from 'redux/store/campaign';

const FundAllocation = () => {
  const [description, setDescription] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [fundValues, setFundValues] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id, companyId} = useParams();

  const campaign = useSelector(state => state.campaign.campaign);

  useEffect(() => {
    if (campaign?.fundAllocation != undefined) {
      setDescription(campaign?.fundDescription);
      setFundValues(
        campaign?.fundAllocation?.map(data => ({
          sector: data.sector,
          percentage: data.percentage,
        })),
      );
    }
  }, [campaign?.fundAllocation]);

  useEffect(() => {
    fundValues?.map(data =>
      setDisableButton(!data.sector || !data?.percentage),
    );
  }, [fundValues]);

  const handleAddFunds = () => {
    setFundValues([...fundValues, {sector: '', percentage: 0}]);
  };

  const onDeleteFunds = id => {
    const filteredFormValue = fundValues.filter((item, index) => index !== id);
    setFundValues(filteredFormValue);
  };

  const handleFundsValueChange = (i, e) => {
    const newFormValues = [...fundValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFundValues(newFormValues);
  };

  const handleSave = () => {
    dispatch(
      fundAllocation({
        fundAllocation: fundValues,
        fundDescription: description,
      }),
    );
    navigate(`/dashboard/campaigns/new/${id}/investment/${companyId}`);
  };

  return (
    <div className={styles.container}>
      <Form onFinish={handleSave} layout="vertical">
        <div className={styles.body}>
          <div>
            <h3 className={styles.title}>Fund Allocation</h3>
          </div>
          <div>
            <Form.Item
              style={{width: 720}}
              name="description"
              label="Description"
              initialValue={campaign?.fundDescription}
              rules={[{required: true, message: 'Required Field'}]}>
              <Input.TextArea
                rows={6}
                defaultValue={campaign?.fundDescription}
                value={description}
                onChange={e => setDescription(e.target.value)}
                name="description"
              />
            </Form.Item>
            <div>
              {fundValues?.map((data, index) => (
                // eslint-disable-next-line react/jsx-key
                <FadeEffect>
                  <div className={styles.form}>
                    <div key={index} className={styles['al-card']}>
                      <Form.Item label="Allocation Category">
                        <Input
                          name="sector"
                          value={data.sector}
                          onChange={e => handleFundsValueChange(index, e)}
                          style={{width: 400}}
                          placeholder="Marketing"
                        />
                      </Form.Item>
                      <Form.Item
                        style={{width: 288, marginLeft: 24}}
                        label="Percentage">
                        <Input
                          name="percentage"
                          value={data.percentage}
                          onChange={e => handleFundsValueChange(index, e)}
                          placeholder="% in value"
                          type="number"
                        />
                      </Form.Item>
                      <div className={styles['delete-icon']}>
                        <img
                          onClick={() => onDeleteFunds(index)}
                          src={deleteIcon}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </FadeEffect>
              ))}
            </div>
            <div onClick={handleAddFunds} className={styles['add-button']}>
              <img src={plusIcon} alt="" />
              <h1>Add Category</h1>
            </div>
          </div>
        </div>
        <div>
          <SubmitButton disabled={disableButton} label="Save & Continue" />
        </div>
      </Form>
    </div>
  );
};

export default FundAllocation;
