import {Route, Routes} from 'react-router-dom';
import MobileSetting from './MobileSetting';
import {NewEmailDisclosure} from './emailDisclosure/disclosure';

const AdminMobileSettings = () => {
  return (
    <Routes>
      <Route path="/" element={<MobileSetting />} />
      <Route path="/new-disclosure" element={<NewEmailDisclosure />} />
      <Route path="/update-disclosure/:id" element={<NewEmailDisclosure />} />
    </Routes>
  );
};

export {AdminMobileSettings};
