import {gql} from '@apollo/client';

export const CREATE_ADMIN = gql`
  mutation CreateAdmin($data: AdminUserSignupInput!) {
    createAdmin(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on AuthenticatedResponse {
        jwtAccessToken
        refreshToken
        user {
          id
        }
      }
    }
  }
`;

export const CREATE_ADMIN_ROLE = gql`
  mutation CreateUserRole($data: AddUserRoleInput!) {
    createUserRole(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUserRole {
        message
      }
    }
  }
`;

export const UPDATE_ADMIN_ROLE = gql`
  mutation UpdateUserRole($data: UpdateUserRoleInput!) {
    updateUserRole(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUserRole {
        message
      }
    }
  }
`;

export const ASSIGN_USER_ROLE = gql`
  mutation AssignUserRole($userId: ID!, $roleId: ID!) {
    assignUserRole(userId: $userId, roleId: $roleId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUser {
        message
      }
    }
  }
`;
