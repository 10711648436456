import {useMutation, useQuery} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {Dropdown, Menu, Table} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {GET_WIRE_TRANSFER_ACCOUNTS} from 'graphql/queries/bankAndCrypto';
import {useState} from 'react';
import trashIcon from 'images/trash-can-outline.svg';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import styles from './bankDetails.module.scss';
import {
  DELETE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT,
} from 'graphql/mutations/bankAndCrypto';
import DBModal from 'components/modal/modal';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const BankDetails = () => {
  const [openModalStatus, setOpenModalStatus] = useState('');
  const [activeTab, setActiveTab] = useState('USD');
  const [activeType, setActiveType] = useState('DOMESTIC');
  const [accountId, setAccountId] = useState('');
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.BankWireDetails),
  );

  const {loading, data, refetch} = useQuery(GET_WIRE_TRANSFER_ACCOUNTS, {
    variables: {
      input: {
        currency: activeTab,
        type: activeType,
      },
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: error => toast.error(error),
  });
  const [updateWireTransferAccount, {loading: loadingUpdate}] =
    useMutation(UPDATE_BANK_ACCOUNT);
  const [deleteWireTransferAccount, {loading: loadingDelete}] =
    useMutation(DELETE_BANK_ACCOUNT);

  const handleUpdate = () => {
    if (openModalStatus === 'ACTIVE') {
      updateWireTransferAccount({
        variables: {
          input: {
            id: accountId,
            isActive: !isActive,
          },
        },
      })
        .then(({data: {updateWireTransferAccount}}) => {
          if (updateWireTransferAccount.__typename === 'Error') {
            toast.error(updateWireTransferAccount.message);
          } else {
            refetch();
            toast.success('Updated Successfully');
            setOpenModalStatus('');
          }
        })
        .catch(error => toast.error(error));
    } else if (openModalStatus === 'DELETE') {
      deleteWireTransferAccount({
        variables: {
          deleteWireTransferAccountId: accountId,
        },
      })
        .then(({data: {deleteWireTransferAccount}}) => {
          if (deleteWireTransferAccount.__typename === 'Error') {
            toast.error(deleteWireTransferAccount.message);
          } else {
            refetch();
            toast.success('Deleted Successfully');
            setOpenModalStatus('');
          }
        })
        .catch(error => toast.error(error));
    }
  };

  const handleSetActive = (bankId, active) => {
    setOpenModalStatus('ACTIVE');
    setAccountId(bankId);
    setIsActive(active);
  };

  const columns = [
    {
      title: 'Bank Name',
      dataIndex: 'bankName',
      key: 'bankName',
    },
    {
      title: 'Beneficiary Name',
      dataIndex: 'beneficiaryName',
      key: 'beneficiaryName',
    },
    {
      title: 'Account Number',
      dataIndex: 'accountNumber',
      key: 'accountNumber',
    },
    {
      title: 'Account Type',
      dataIndex: 'accountType',
      key: 'accountType',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Country',
      dataIndex: 'countryName',
      key: 'countryName',
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (text, record) => (
        <span className={styles[record?.isActive ? 'active' : 'inactive']}>
          {record?.isActive ? 'Active' : 'Inactive'}
        </span>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <div className={styles.actions}>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="0"
                  onClick={() =>
                    navigate(`/dashboard/update-account/${record?.id}`)
                  }>
                  <span>View Details</span>
                </Menu.Item>
                <Menu.Item
                  key="1"
                  onClick={() => handleSetActive(record?.id, record?.isActive)}>
                  <span>Set {record?.isActive ? 'InActive' : 'Active'}</span>
                </Menu.Item>
              </Menu>
            }>
            <div>
              <Button
                label="More Options"
                disabled={!tabOperations?.update}
                type="secondary"
              />
            </div>
          </Dropdown>
          {tabOperations?.delete && (
            <img
              src={trashIcon}
              alt=""
              onClick={() => {
                setAccountId(record?.id);
                setOpenModalStatus('DELETE');
              }}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <h1>Wire Transfer Information</h1>
      <section className={styles.header}>
        <div className={styles.tab}>
          <span
            className={activeTab === 'USD' ? styles['active-tab'] : ''}
            onClick={() => setActiveTab('USD')}>
            USD
          </span>
          <span
            className={activeTab === 'XOF' ? styles['active-tab'] : ''}
            onClick={() => setActiveTab('XOF')}>
            CFA
          </span>
          <span
            className={activeTab === 'EUR' ? styles['active-tab'] : ''}
            onClick={() => setActiveTab('EUR')}>
            EUR
          </span>
        </div>
      </section>
      <section className={styles.table}>
        <div className={styles.tab}>
          <span
            className={activeType === 'DOMESTIC' ? styles['active-tab'] : ''}
            onClick={() => setActiveType('DOMESTIC')}>
            Local
          </span>
          <span
            className={
              activeType === 'INTERNATIONAL' ? styles['active-tab'] : ''
            }
            onClick={() => setActiveType('INTERNATIONAL')}>
            International
          </span>
        </div>
        <div className={styles.title}>
          {activeType === 'DOMESTIC' ? (
            <h1>Local Account(s)</h1>
          ) : (
            <h1>International Account(s)</h1>
          )}
          <SubmitButton
            label={`Create ${
              activeType === 'DOMESTIC' ? 'Local' : "Int'l"
            } Account`}
            disabled={!tabOperations?.create}
            handleClick={() =>
              navigate(
                `/dashboard/banks/create-account/${
                  activeType === 'DOMESTIC' ? 'us' : 'international'
                }`,
              )
            }
          />
        </div>
        <Table
          className={styles['table-width']}
          columns={columns}
          loading={loading}
          dataSource={data?.getAllWireTransferAccountsFilter?.data}
        />
      </section>
      <DBModal
        isOpen={openModalStatus !== ''}
        handleClose={() => setOpenModalStatus('')}
        content={
          <div className={styles.modal}>
            <h1>
              {openModalStatus === 'ACTIVE'
                ? 'Update Active Status'
                : 'Delete Account'}
            </h1>
            <span>Are you sure you want to perform this Action?</span>
            <div className={styles.buttons}>
              <Button
                label="Yes"
                onClick={handleUpdate}
                disabled={loadingUpdate || loadingDelete}
              />
              <Button
                label="No"
                type="secondary"
                onClick={() => setOpenModalStatus('')}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default BankDetails;
