import {Route, Routes} from 'react-router-dom';
import SavingsOverview from './overview/SavingsOverview';
import SavingCompanies from './companies/SavingCompanies';
import SingleCompany from './companies/company/SingleCompany';
import SavingsCompanyProfile from './companies/company/SavingsCompanyProfile';
import SavingsUserManagement from './companies/company/management/SavingsUserManagement';
import SavingsCompanyUsers from './companies/company/users/SavingsCompanyUsers';
import SavingsCompanyProducts from './companies/products/SavingsCompanyProducts';
import SingleSavingsProductOverview from './products/single/SingleSavingsProductOverview';
import SavingsProductsOverview from './products/SavingsProductsOverview';
import UpdateSingleSavingsProduct from './products/single/update/UpdateSingleSavingsProduct';

const SavingsRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<SavingsOverview />} />
      <Route path="/companies" element={<SavingCompanies />} />
      <Route path="/products" element={<SavingsProductsOverview />} />

      <Route path="/company/:id" element={<SingleCompany />}>
        <Route index element={<SavingsCompanyProfile />} />
        <Route
          path="/company/:id/user-management"
          element={<SavingsUserManagement />}
        />
        <Route path="/company/:id/users" element={<SavingsCompanyUsers />} />
        <Route
          path="/company/:id/products"
          element={<SavingsCompanyProducts />}
        />
      </Route>
      <Route path="/product/:id" element={<SingleSavingsProductOverview />} />
      <Route
        path="/product/update/:id"
        element={<UpdateSingleSavingsProduct />}
      />

      <Route path="/company/create/" element={<SavingsCompanyProfile />} />
    </Routes>
  );
};

export default SavingsRoute;
