import {CustomLink} from 'components/investorUpdate/management';
import backImg from 'images/back.png';
import styles from '../../AssetManagement/components/assetManagementComponents.module.scss';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';

export const AssetInstrumentHeader = () => {
  const {assetId, lang, id} = useParams();
  const location = useLocation();
  const isStepTwo = location.pathname.includes('step-two');
  const navigate = useNavigate();
  const preRoute = id
    ? `/dashboard/asset-management/update/bond/${assetId}/${id}/${lang}`
    : `/dashboard/asset-management/create/bond/${assetId}`;

  return (
    <div className={styles.instrumentHeader}>
      <div className={styles.header}>
        <img
          src={backImg}
          alt=""
          onClick={() =>
            navigate(
              id
                ? `/dashboard/asset-management/class/${assetId}/instruments/${id}/languages`
                : `/dashboard/asset-management/class/${assetId}/instruments`,
            )
          }
        />
        <h1> {id ? 'Update' : 'Create'} Bond</h1>
      </div>
      <div className={styles.navLink}>
        <div
          onClick={() => navigate(`${preRoute}`)}
          className={!isStepTwo ? styles.active : ''}>
          STEP 1 (Financial Instrument)
        </div>
        <div
          onClick={() => navigate(`${preRoute}/step-two`)}
          className={isStepTwo ? styles.active : ''}>
          STEP 2
        </div>
      </div>
      {isStepTwo ? (
        <section className={styles.section}>
          <CustomLink to={`${preRoute}/step-two`} name="Bond Overview" />
          <CustomLink
            to={`${preRoute}/step-two/broker`}
            name="Broker & Compliance"
          />
        </section>
      ) : (
        <section className={styles.section}>
          <CustomLink to={`${preRoute}/`} name="Issuer Info" />
          <CustomLink to={`${preRoute}/settings`} name="Bond Settings" />
          <CustomLink to={`${preRoute}/investment`} name="Investment Details" />
          <CustomLink
            to={`${preRoute}/contracts`}
            name="Investment Contracts"
          />
          <CustomLink to={`${preRoute}/trading`} name="Trading Settings" />
          <CustomLink to={`${preRoute}/score`} name="Daba Score" />
          <CustomLink to={`${preRoute}/pitch`} name="Pitch Deck" />
          <CustomLink
            to={`${preRoute}/overview`}
            name="Recordings/ Addt. Info"
          />
          {/* <CustomLink to={`${preRoute}/analysis`} name="Analysis" /> */}
          {/* <CustomLink to={`${preRoute}/analogs`} name="Analogs" /> */}
          <CustomLink to={`${preRoute}/funds`} name="Key Statistics" />

          <CustomLink to={`${preRoute}/documents`} name="Legal Documents" />
          <CustomLink to={`${preRoute}/faq`} name="FAQ" />
          <CustomLink to={`${preRoute}/risks`} name="Risk & Impact" />
          <CustomLink to={`${preRoute}/users-invite`} name="Users Invite" />
        </section>
      )}

      <section>
        <div>
          <Outlet />
        </div>
      </section>
    </div>
  );
};
