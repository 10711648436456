import {useDispatch, useSelector} from 'react-redux';
import SomethingWentWrong from './ErrorBoundary/SomethingWentWrong/SomethingWentWrong';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {handleClearPageError} from 'redux/store/auth';

const PageErrorBoundary = props => {
  const pageError = useSelector(state => state.auth.pageError);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(handleClearPageError());
  }, [location]);

  if (pageError?.statusCode === 401) {
    return <SomethingWentWrong />;
  }

  if (pageError) {
    return (
      <SomethingWentWrong
        title="You do not have access to this page"
        message="You do not have the admin permission to view this page kindly go back home or reach out to the admin"
      />
    );
  }
  return <div>{props.children}</div>;
};

export default PageErrorBoundary;
