import {useMutation, useQuery} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {Form, Select, Spin} from 'antd';
import FounderCreation from 'components/Startup/creation/founder';
import {
  ADD_INDUSTRY_TO_COMPANY,
  ADD_TOPIC_TO_COMPANY,
} from 'graphql/mutations/investorUpdates';
import {
  GET_ALL_INDUSTRIES,
  GET_ALL_TOPICS,
} from 'graphql/queries/investorUpdates';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import styles from './categories.module.scss';
import CompanySocials from './CompanySocials';
import CompanyStock from 'components/CompanyStock/CompanyStock';

const CompanyCategories = () => {
  const [topics, setTopics] = useState([]);
  const {id} = useParams();
  const [industries, setIndustries] = useState('');
  const [activePage, setActivePage] = useState('CATEGORIES');
  const {Option} = Select;

  const {data: getAllTopics, loading} = useQuery(GET_ALL_TOPICS);
  const {data: getAllIndustries} = useQuery(GET_ALL_INDUSTRIES);

  const [addTopicsToCompany, {loading: updatingTopics}] =
    useMutation(ADD_TOPIC_TO_COMPANY);
  const [addIndustryToCompany, {loading: updatingIndustry}] = useMutation(
    ADD_INDUSTRY_TO_COMPANY,
  );

  const handleAddTopic = () => {
    addTopicsToCompany({
      variables: {
        companyId: id,
        topicIds: topics.map(data => data.split(';')[1]),
      },
    })
      .then(({data: {addTopicsToCompany}}) => {
        if (addTopicsToCompany.__typename === 'Error') {
          toast.error(addTopicsToCompany.message);
        } else {
          toast.success('Topics added Successfully');
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  const handleAddIndustries = () => {
    addIndustryToCompany({
      variables: {
        companyId: id,
        industryId: industries.split(';')[1],
      },
    })
      .then(({data: {addIndustryToCompany}}) => {
        if (addIndustryToCompany.__typename === 'Error') {
          toast.error(addIndustryToCompany.message);
        } else {
          toast.success('Industries added Successfully');
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }
  return (
    <>
      {activePage === 'CATEGORIES' && (
        <div className={styles.container}>
          <div className={styles.header}>
            <h1>Select Company Topics and Industries</h1>
            <h1>Step 2/5</h1>
          </div>

          <Form className={styles.form} layout="vertical">
            <Form.Item label="Topics" name="Topics">
              <Select
                mode="multiple"
                showSearch
                value={topics}
                onChange={e => setTopics(e)}
                style={{width: 730}}
                placeholder={'Select Company Topics'}>
                {getAllTopics?.getAllTopics?.map(topic => (
                  <Option key={`${topic?.name};${topic?.id}`}>
                    <div className={styles['all-data']}>
                      <img src={topic.icon} alt="" />
                      <span>{topic.name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              label="Save Topics"
              disabled={updatingTopics}
              className={styles['button-style']}
              onClick={handleAddTopic}
            />
          </Form>
          <Form className={styles.form} layout="vertical">
            <Form.Item
              label="Industry"
              className={styles['form-item']}
              name="industry">
              <Select
                className={styles['select-field']}
                showSearch
                value={industries}
                onChange={value => setIndustries(value)}
                style={{width: 730}}
                placeholder={'Select Industries'}>
                {getAllIndustries?.getAllIndustries?.map(industry => (
                  <Option key={`${industry.name};${industry.id}`}>
                    <div className={styles['all-data']}>
                      <img src={industry.icon} alt="" />
                      <span>{industry.name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              label="Save Industry"
              className={styles['button-style']}
              disabled={updatingIndustry}
              onClick={handleAddIndustries}
            />
          </Form>
          <div className={styles.footer}>
            <Button
              className={styles['footer-button-style']}
              label="Proceed to Step 3/5"
              onClick={() => setActivePage('STOCKS')}
            />
          </div>
        </div>
      )}
      {activePage === 'SOCIALS' && (
        <CompanySocials setActivePage={setActivePage} />
      )}
      {activePage === 'MEMBERS' && (
        <FounderCreation setActivePage={setActivePage} />
      )}
      {activePage === 'STOCKS' && (
        <CompanyStock setActivePage={setActivePage} />
      )}
    </>
  );
};

export default CompanyCategories;
