import SignUpLayout from 'layout/signup';
import styles from './register.module.scss';
import {useNavigate} from 'react-router-dom';
import Slider from 'components/slider/slider';
import classNames from 'classnames/bind';
import {Form, Input, Select} from 'antd';
import {useMutation, useQuery} from '@apollo/client';
import {GET_ALL_USER_ROLES} from 'graphql/queries/admin';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {CREATE_ADMIN} from 'graphql/mutations/admin';
import {toast} from 'react-toastify';
import {useState} from 'react';
import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons';
import {formatRoleString} from 'utils/helper';

export default function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const cx = classNames.bind(styles);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const {data, loading} = useQuery(GET_ALL_USER_ROLES, {
    variables: {
      filter: {
        limit: 50,
      },
    },
  });
  const [createAdmin, {loading: loadingCreation}] = useMutation(CREATE_ADMIN);

  const onSubmit = values => {
    createAdmin({
      variables: {
        data: {
          email: values.email,
          password: values.password,
          role: values.role,
        },
      },
    }).then(({data: {createAdmin}}) => {
      if (createAdmin.__typename === 'Error') {
        toast.error(createAdmin.message);
      } else {
        toast.success('Admin Created Successfully');
        navigate('/dashboard/admin-settings');
      }
    });
  };
  return (
    <SignUpLayout
      skip
      left={
        <div className={cx('form-container')}>
          <Form onFinish={onSubmit} layout="vertical">
            <div className={cx('welcome-section')}>
              <h1>Create an Admin Account 👋</h1>
              <p>
                As a Super Admin you have the Ability to create an Admin and
                Assign Roles to the Admin
              </p>
            </div>
            <div className={styles.form}>
              <Form.Item
                rules={[
                  {required: true, message: 'Email is Required', type: 'email'},
                ]}
                name="email"
                className={styles.field}
                label="Email">
                <Input type="email" />
              </Form.Item>
              <Form.Item
                rules={[{required: true, message: 'Password is Required'}]}
                name="password"
                className={styles.field}
                label="Password">
                <Input.Password
                  iconRender={visible =>
                    visible ? (
                      <EyeOutlined onClick={handleTogglePassword} />
                    ) : (
                      <EyeInvisibleOutlined onClick={handleTogglePassword} />
                    )
                  }
                  placeholder="Enter password"
                  visibilityToggle
                  type={showPassword ? 'text' : 'password'}
                />
              </Form.Item>
              <Form.Item
                rules={[{required: true, message: 'Please Select a Role'}]}
                name="role"
                className={styles.field}
                label="Role">
                <Select loading={loading}>
                  {data?.getAllUserRole?.data
                    ?.filter(
                      data => !['SUPERADMIN', 'USER'].includes(data?.name),
                    )
                    ?.map(data => (
                      <Select.Option key={data?.id}>
                        {formatRoleString(data?.name)}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <SubmitButton label="Create Admin" disabled={loadingCreation} />
            </div>
          </Form>
        </div>
      }
      right={
        <>
          <Slider />
        </>
      }
    />
  );
}
