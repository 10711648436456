import {useMutation} from '@apollo/client';
import {Input, Form, Select} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {CREATE_CRYPTO_ACCOUNT} from 'graphql/mutations/bankAndCrypto';
import {GET_CRYPTO_DETAILS} from 'graphql/queries/bankAndCrypto';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import backImgIcon from 'images/back.png';
import {toast} from 'react-toastify';
import styles from './cryptoAccount.module.scss';
import CompanyImage from 'components/Startup/profile/companyImage';

const AddCryptoAccount = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [previewQrCode, setPreviewQrCode] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [symbol, setSymbol] = useState('');
  const [network, setNetwork] = useState('');
  const [label, setLabel] = useState('');
  const navigate = useNavigate();

  const [createCryptoAccount, {loading}] = useMutation(CREATE_CRYPTO_ACCOUNT);

  const handleSubmit = () => {
    const optionalField = {};
    if (qrCode) optionalField.qrCode = qrCode;
    createCryptoAccount({
      variables: {
        input: {
          walletAddress: walletAddress.trim(),
          symbol: symbol.trim(),
          network,
          label: label.trim(),
          ...optionalField,
        },
      },
      refetchQueries: [{query: GET_CRYPTO_DETAILS}],
    })
      .then(({data: {createCryptoAccount}}) => {
        if (createCryptoAccount.__typename === 'Error') {
          toast.error(createCryptoAccount.message);
        } else {
          toast.success('Created Successfully');
          navigate(-1);
        }
      })
      .catch(error => toast.error(error));
  };

  return (
    <div>
      <div className={styles.header}>
        <img src={backImgIcon} alt="" onClick={() => navigate(-1)} />
        <h1>Create Crypto Account</h1>
      </div>
      <div className={styles.form}>
        <CompanyImage
          previewUrl={previewQrCode}
          setPreviewUrl={setPreviewQrCode}
          setImgUrl={setQrCode}
          large
          bucket={'qrcode-images-bucket'}
        />
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="walletAddress"
            label="Wallet Address"
            rules={[
              {required: true, message: 'Please provide a wallet address'},
              {min: 30, message: 'Wallet address is not valid'},
            ]}
            className={styles.field}>
            <Input
              value={walletAddress}
              onChange={e => setWalletAddress(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="label"
            label="Name"
            rules={[{required: true, message: 'Name of wallet is required'}]}
            className={styles.field}>
            <Input value={label} onChange={e => setLabel(e.target.value)} />
          </Form.Item>
          <Form.Item
            name="symbol"
            label="Symbol"
            rules={[{required: true, message: 'Symbol Required'}]}
            className={styles.field}>
            <Input value={symbol} onChange={e => setSymbol(e.target.value)} />
          </Form.Item>
          <Form.Item
            name="network"
            label="Network"
            rules={[{required: true, message: 'Network Required'}]}
            className={styles.field}>
            <Select value={network} onChange={e => setNetwork(e)}>
              <Select.Option value={'ERC 20'}>ERC-20</Select.Option>
              <Select.Option value={'BEP 20'}>BEP-20</Select.Option>
            </Select>
          </Form.Item>
          <SubmitButton disabled={loading} label="Create" />
        </Form>
      </div>
    </div>
  );
};

export default AddCryptoAccount;
