import {InputNumber} from 'antd';

import styles from './numberInput.module.scss';

const NumberinputField = ({value, setValue, defaultValue, width}) => {
  return (
    <InputNumber
      style={{width: width ? width : 350}}
      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={value => value.replace(/\$\s?|(,*)/g, '')}
      className={styles['input-number']}
      defaultValue={defaultValue}
      value={value}
      onChange={e => setValue(e)}
      placeholder="Enter Amount"
    />
  );
};

export default NumberinputField;
