import {gql} from '@apollo/client';

export const GET_ALL_USERS_NAMES = gql`
  query GetAllUsers($filters: GetUserFilters) {
    getAllUsers(filters: $filters) {
      id
      firstName
      lastName
    }
  }
`;

export const DOWNLOAD_ALL_USERS = gql`
  query DownloadAllUsers($email: String!, $match: GetUserFilters) {
    downloadAllUsers(email: $email, match: $match) {
      ... on Error {
        message
        statusCode
        type
        errorTypeEnum
        retryAfter
      }
      ... on ReturnedMessage {
        response
      }
    }
  }
`;

export const GET_ALL_ADMIN_USERS = gql`
  query GetAllUsers($filters: GetUserFilters) {
    getAllUsers(filters: $filters) {
      users {
        id
        email
        roleSettings {
          name
        }
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUsers($filters: GetUserFilters) {
    getAllUsers(filters: $filters) {
      hasNextPage
      hasPrevPage
      totalDocs
      users {
        id
        firstName
        username
        email
        lastName
        isDeleted
        imageUrl
        phoneNumber
        countryCode
        createdAt
        country
        profileCompletion {
          completionPercentage
        }
      }
    }
    statistics: getAllUsers {
      statistics {
        totalAccreditedUsers
        totalActiveInvestors
        totalUsers
        totalVerifiedInvestors
      }
    }
  }
`;

export const GET_RECENT_USERS = gql`
  query GetRecentUsers {
    getRecentUsers {
      users {
        id
        firstName
        middleName
        email
        username
        investorProfile {
          isAccredited
        }
        imageUrl
        isEmailVerified
        profileCompletion {
          completionPercentage
          step {
            title
            completed
          }
        }
      }
    }
  }
`;

export const GET_INVESTOR_PROFILE = gql`
  query GetInvestorProfile($getInvestorProfileId: ID) {
    getInvestorProfile(id: $getInvestorProfileId) {
      ... on Error {
        message
        statusCode
      }
      ... on InvestorProfileType {
        investorProfile {
          riskNeed {
            investmentGoals {
              betterBudgetting
              stockRewards
              emergencySavings
              retirementSavings
              wealthBuilding
              education
              socialImpact
            }
            investmentHorizon
            annualReturnTarget
            accountUsage
          }
          riskCapacity {
            retirementSavings
            age
            singleAnnualIncome
            jointAnnualNetIncome
            employmentStatus
            taxFilingStatus
            estimatedNetWorth
            investmentHorizon
            investmentTarget
            liquidityNeeds
          }
          isAccredited
          accreditationStatus {
            selfDeclaredAccreditation
            thirdPartyAccreditation
          }
          riskAppetite {
            investmentPreference
            investmentExperience
            selfCategorization
            experienceWithLosses
          }
          affiliations
        }
      }
    }
  }
`;

export const GET_USER_WALLET_DETAILS = gql`
  query GetUserById($getUserByIdId: ID!, $userId: ID) {
    getUserById(id: $getUserByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on UserType {
        user {
          firstName
          lastName
          wallets(userId: $userId) {
            id
            balance {
              availableBalance
              availableForInvesting
              lockedBalance
              ledgerBalance
              pendingDepositBalance
              pendingWithdrawalBalance
            }
            currency
            currencySymbol
            currencyName
            accountFlags {
              isFlagged
              notes
              reason
            }
            status
          }
        }
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query GetUserById($getUserByIdId: ID!, $userId: ID) {
    getUserById(id: $getUserByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on UserType {
        user {
          id
          firstName
          middleName
          lastName
          email
          bio
          complyCubeClientId
          internalTeamMember
          phoneNumber
          dateOfBirth
          preferredDisplayCurrency
          language
          isAccountLocked
          homeAddress
          homeAddressNumber
          referralCode
          currentSessionDetails {
            currentDevice
            currentAppVersion
            currentTimeZone
            currentDateTime
          }
          countryOfBirth {
            id
            name
          }
          countryOfCitizenship {
            id
            name
          }
          state
          city
          postalCode
          username
          middleName
          imageUrl
          bannerImage
          citizenStatus
          trustedContact {
            firstName
            middleName
            lastName
            phoneNumber
            countryCode
          }
          zipCode
          isInvestorProfileEnabled
          visaType

          countryCode
          isEmailVerified
          country
          numOfFollowers
          numOfFollowing
          countryOfInterest {
            id
            name
            icon
            type
          }
          topics {
            id
            name
            icon
            type
          }
          notificationPreferences {
            emailNotifications {
              receiveDailyInvestorUpdates
              receiveOtherEmailNotifications
            }
            pushNotifications {
              receiveDailyInvestorUpdates
              receiveOtherPushNotifications
            }
            smsNotifications {
              receiveDailyInvestorUpdates
              receiveOtherSmsNotifications
            }
          }
          investorProfile {
            isKycApproved(userId: $userId)
            identityCard(userId: $userId) {
              amlStatus
              kycStatus
            }
            riskNeed {
              investmentGoals {
                betterBudgetting
                stockRewards
                emergencySavings
                retirementSavings
                wealthBuilding
                education
                socialImpact
              }
              investmentHorizon
              annualReturnTarget
              accountUsage
            }
            riskCapacity {
              retirementSavings
              age
              singleAnnualIncome
              jointAnnualNetIncome
              employmentStatus
              taxFilingStatus
              estimatedNetWorth
              investmentHorizon
              investmentTarget
              liquidityNeeds
            }
            isAccredited
            accreditationStatus {
              selfDeclaredAccreditation
              thirdPartyAccreditation
            }
            riskAppetite {
              investmentPreference
              investmentExperience
              selfCategorization
              experienceWithLosses
            }
          }
        }
      }
    }
  }
`;

export const GET_HOME_OVERVIEW_DATA = gql`
  query GetHomeOverview {
    getUsersSignupChartData {
      daily {
        total
        createdAt
      }
      monthly {
        createdAt
        total
      }
      weekly {
        createdAt
        total
      }
      yearly {
        createdAt
        total
      }
    }
    getTotalNumberOfCompanies
    getRecentUsers {
      users {
        id
        firstName
        middleName
        email
        username
        investorProfile {
          isAccredited
        }
        imageUrl
        isEmailVerified
        profileCompletion {
          completionPercentage
          step {
            title
            completed
          }
        }
      }
    }
    getAllUsers {
      statistics {
        totalActiveInvestors
        totalUsers
      }
    }
  }
`;

export const GET_USERS_SIGNUP_CHART_DATA = gql`
  query GetUsersSignupChartData {
    getUsersSignupChartData {
      daily {
        total
        createdAt
      }
      monthly {
        createdAt
        total
      }
      weekly {
        createdAt
        total
      }
      yearly {
        createdAt
        total
      }
    }
  }
`;

export const GET_ALL_COUNTRIES = gql`
  query GetAllCountries {
    getAllCountries {
      ... on Error {
        message
        statusCode
      }
      ... on Country {
        id
        name
        countryCode
        icon
      }
    }
  }
`;

export const GET_ALL_WHATSAPP_TEMPLATES = gql`
  query GetWhatsappTemplate($language: SourceLanguage!) {
    getWhatsappTemplate(language: $language) {
      ... on Error {
        message
        statusCode
        type
        errorTypeEnum
        retryAfter
      }
      ... on WhatsappTemplateResponse {
        whatsappTemplates {
          templateVariables {
            name
            isAppLink
            description
          }
          templateType
          templateTitle {
            title
            hasVariable
          }
          templateName
          templateLink {
            url
            type
            isStatic
          }
          templateContent
          id
          hasNameVariable
        }
      }
    }
  }
`;

export const GET_WHATSAPP_TEMPLATE_BY_NAME = gql`
  query GetWhatsappTemplateByName($name: String!, $language: SourceLanguage!) {
    getWhatsappTemplateByName(name: $name, language: $language) {
      ... on Error {
        message
        statusCode
        type
        errorTypeEnum
        retryAfter
      }
      ... on WhatsappTemplateByIdResponse {
        whatsappTemplate {
          id
          templateTitle {
            title
            hasVariable
          }
          templateName
          templateType
          templateContent
          templateLink {
            isStatic
            type
            url
          }
          templateVariables {
            name
            description
            isAppLink
          }
          hasNameVariable
        }
      }
    }
  }
`;

export const USER_SUBSCRIPTION = gql`
  query GetSubscriptionById($getSubscriptionByIdId: ID) {
    getSubscriptionById(id: $getSubscriptionByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on SubscriptionById {
        subscription {
          userId
          stripeSubscriptionId
          card {
            last4
          }
          subscriptionPrice {
            baseAmount
          }
          currentPeriodStart
          product {
            name
            id
          }
        }
      }
    }
  }
`;

export const GET_USER_TRADE_BY_ID = gql`
  query GetTradeById($tradeId: ID!) {
    getTradeById(tradeId: $tradeId) {
      ... on Error {
        message
        statusCode
      }
      ... on Trade {
        id
        paymentMethod
        transactionUnits
        transactionStatus
        fundStatus
        transactionAmount
        type
        userId
        offering
        walletAddress
        cryptoCoinType
        campaignId
      }
    }
  }
`;

export const USER_TRANSACTIONS = gql`
  query GetUserById($getUserByIdId: ID!, $userId: ID) {
    getUserById(id: $getUserByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on UserType {
        user {
          id
          transactions(userId: $userId) {
            id
            fundraisingCompany {
              companyName
              logoImgURL
            }
            paymentMethod
            transactionAmount
            bankAccountName
            campaignId
            investedAmount
            investedAmountInTransactionCurrency
            assetCurrency
            transactionFees
            transactionStatus
            transactionCurrencyToAssetCurrencyFx
            transactionCurrency
            tradeId
            fundStatus
            transactionUnits
            createdAt
          }
        }
      }
    }
  }
`;

export const GET_USERS_FOLLOWING_COMPANY = gql`
  query GetAllUsersFollowingCompany($filter: FollowCompanyFilter!) {
    getAllUsersFollowingCompany(filter: $filter) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUsers {
        data {
          firstName
          lastName
          username
          email
          id
          imageUrl
        }
        pagination {
          hasNextPage
          hasPrevPage
        }
      }
    }
  }
`;

export const GET_USER_SESSION_RECORDINGS = gql`
  query GetUserSessionRecordings($filter: UserSessionRecordingFilter) {
    getUserSessionRecordings(filter: $filter) {
      data {
        id
        sessionUrl
        updatedAt
        createdAt
      }
      pagination {
        total
      }
    }
  }
`;
