import {useState} from 'react';
import styles from './customDropdown.module.scss';
import {DownOutlined} from '@ant-design/icons';

const CustomDropdown = ({options, selectedOption, setSelectedOption}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = option => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className={styles['custom-dropdown']}>
      <div
        className={`${styles['dropdown-header']} ${
          isOpen ? styles['open'] : ''
        }`}
        onClick={() => setIsOpen(!isOpen)}>
        {selectedOption !== null
          ? `${options.find(option => option.key === selectedOption).label}`
          : 'Select an option'}{' '}
        <DownOutlined style={{marginLeft: 10}} />
      </div>
      {isOpen && (
        <ul className={styles['dropdown-options']}>
          {options.map(option => (
            <li key={option.key} onClick={() => handleOptionClick(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
