import {useState} from 'react';
import styles from './editUpdate.module.scss';
import {Spin} from 'antd';
import {GET_INVESTOR_UPDATE_BY_ID} from 'graphql/queries/investorUpdates';
import {useQuery} from '@apollo/client';
import NewUpdate from '../newUpdate';
import {useParams} from 'react-router-dom';

const EditUpdate = () => {
  const [updateData, setUpdateData] = useState();
  const {id} = useParams();
  const {loading} = useQuery(GET_INVESTOR_UPDATE_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      getInvestorUpdateByIdId: id,
    },
    onCompleted: data => setUpdateData(data?.getInvestorUpdateById),
  });

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return updateData ? (
    <NewUpdate
      existingData={updateData?.investorUpdate}
      multiLang={updateData?.multilang}
    />
  ) : (
    <div className="query-loading">
      <h1>Could not load This Update, Please try again</h1>
    </div>
  );
};

export default EditUpdate;
