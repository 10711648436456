import {DatePicker, Form, Select} from 'antd';
import styles from './newNotification.module.scss';
import FadeEffect from 'components/animation/fadeEffect';
import moment from 'moment';
import {
  audienceTypes,
  notificationFilterOptions,
  notificationLastVisitOptions,
} from 'utils/constants';
import {
  InvestingExperienceEnum,
  investmentPreferenceEnum,
  investmentHorizonEnum,
  investmentAffiliationEnum,
  visaTypeEnum,
  citizenStatusEnum,
  annualNetIncomeEnum,
  taxFilingStatusEnum,
  employmentStatusEnum,
  estimatedNetWorthEnum,
  investmentGoalsEnum,
  investmentStrategiesEnum,
} from 'utils/mock';
import {UserSelectField} from 'components/userSelectField/UserSelectField';
import {getUserTimezone} from 'utils/helper';

const NotificationFilter = ({
  audience,
  setAudience,
  users,
  setUsers,
  allGroups,
  allCampaigns,
  allTopics,
  allCountries,
  from,
  setFrom,
  to,
  setTo,
}) => {
  const {Option} = Select;
  const width = 400;
  return (
    <div>
      <div className={styles['audience-grid']}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Audience required',
            },
          ]}
          name="audience"
          label="Audience"
          initialValue={'ALL_USERS'}>
          <Select
            style={{width: 720}}
            name="audience"
            value={audience}
            placeholder="All Users"
            defaultValue="ALL_USERS"
            onChange={e => setAudience(e)}>
            {audienceTypes.map(data => (
              <Option key={data.value}>{data.label}</Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      {audience === 'USERS' && (
        <Form.Item
          style={{width: 720}}
          label="Search and Select User(s)"
          name="users">
          <UserSelectField users={users} setUsers={setUsers} />
        </Form.Item>
      )}
      {audience === 'USERS_BY_GROUP' && (
        <Form.Item
          rules={[{required: audience === 'USERS_BY_GROUP'}]}
          label="Groups"
          name="groups">
          <Select
            mode="tags"
            style={{width: 720}}
            placeholder="Select by Groups">
            {allGroups?.map(data => (
              <Option key={data?.id}>
                <div className={styles['name-data']}>
                  <img src={data?.bannerImg} alt="" />
                  <span>{data?.name}</span>
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {audience === 'INVESTORS_IN_A_CAMPAIGN' && (
        <Form.Item label="Live Campaign" name="liveCampaignId">
          <Select style={{width: 720}} placeholder="Select by Campaign">
            {allCampaigns
              ?.filter(
                data =>
                  data?.campaignMode === 'LIVE' &&
                  data?.campaignStatus !== 'DRAFT',
              )
              ?.map(data => (
                <Option key={data?.id}>
                  <div className={styles['option-data']}>
                    <div className={styles['name-data']}>
                      <img src={data?.fundraisingCompany?.logoImgURL} alt="" />
                      <span>{data?.fundraisingCompany?.companyName}</span>
                    </div>
                    <div>--{data?.campaignStatus}</div>
                  </div>
                </Option>
              ))}
          </Select>
        </Form.Item>
      )}
      {audience === 'INVESTORS_WITH_RESERVATIONS' && (
        <Form.Item label="Test Campaign" name="testCampaignId">
          <Select style={{width: 720}} placeholder="Select by Campaign">
            {allCampaigns
              ?.filter(
                data =>
                  data?.campaignMode === 'TEST' &&
                  data?.campaignStatus !== 'DRAFT',
              )
              ?.map(data => (
                <Option key={data?.id}>
                  <div className={styles['option-data']}>
                    <div className={styles['name-data']}>
                      <img src={data?.fundraisingCompany?.logoImgURL} alt="" />
                      <span>{data?.fundraisingCompany?.companyName}</span>
                    </div>
                    <div>--{data?.campaignStatus}</div>
                  </div>
                </Option>
              ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item label="Topics" name="topics">
        <Select
          mode="multiple"
          style={{width: 720}}
          placeholder="Select by Topics">
          {allTopics?.map(item => (
            <Option key={`${item?.name};${item?.id}`}>
              <div className={styles['name-data']}>
                <img src={item?.icon} alt="" />
                <span>{item?.name}</span>
              </div>
            </Option>
          ))}
        </Select>
      </Form.Item>
      {audience !== 'USERS' && (
        <FadeEffect>
          <div className={styles.accredited}>
            <Form.Item
              className={styles['field-width']}
              label="Accredited"
              name="accredited">
              <Select defaultValue={'EITHER'} placeholder="Either">
                {notificationFilterOptions.map(option => (
                  <Option key={option.value}>
                    <span>{option.label}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              label="Active Investors"
              name="activeInvestors">
              <Select defaultValue={'EITHER'} placeholder="Either">
                {notificationFilterOptions.map(option => (
                  <Option key={option.value}>
                    <span>{option.label}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              label="Verified Investors"
              name="verifiedInvestors">
              <Select defaultValue={'EITHER'} placeholder="Either">
                {notificationFilterOptions.map(option => (
                  <Option key={option.value}>
                    <span>{option.label}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              label="Latest App Visit"
              name="latestVisit">
              <Select defaultValue={'EITHER'} placeholder="Either">
                {notificationLastVisitOptions.map(option => (
                  <Option key={option.value}>
                    <span>{option.label}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              label="Profile Completion"
              name="profileCompletion">
              <Select defaultValue={'EITHER'} placeholder="Either">
                <Option key="EITHER">Either</Option>
                <Option key="EmailVerification">
                  Email Verification - Basics
                </Option>
                <Option key="PhoneVerification">
                  Phone Verification - Basics
                </Option>
                <Option key="Basics">Step One(Not Completed) - Basics</Option>
                <Option key="Preferences">
                  Step Two(Not Completed) - Preferences
                </Option>
                <Option key="Investments">
                  Step Three(Not Completed) - Investments
                </Option>
                <Option key="Customization">
                  Step Four(Not Completed) - Customization
                </Option>
              </Select>
            </Form.Item>
          </div>
        </FadeEffect>
      )}
      <div>
        <h1 className={styles['header-text']}>Citizenship Details Filter</h1>
      </div>
      <div className={styles['invest-form']}>
        <Form.Item label="Country of Residence" name="countryOfResidence">
          <Select mode="multiple" style={{width}} showSearch>
            {allCountries.map(data => (
              <Option key={data?.name}>
                <div className={styles['all-data']}>
                  <img src={data?.icon} alt="" />
                  <span>{data?.name}</span>
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="State (Type state and click on Enter to type another input)"
          name="state">
          <Select mode="tags" style={{width}} showSearch>
            <Option disabled>Please Input State</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="City (Type city and click on Enter to type another input)"
          name="city">
          <Select mode="tags" style={{width}} showSearch>
            <Option disabled>Please Input City</Option>
          </Select>
        </Form.Item>
        <Form.Item
          style={{width}}
          label="Country of Birth"
          name="countryOfBirth">
          <Select mode="multiple" style={{width}} showSearch>
            {allCountries.map(data => (
              <Option key={`${data?.name};${data?.id}`}>
                <div className={styles['all-data']}>
                  <img src={data?.icon} alt="" />
                  <span>{data?.name}</span>
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          style={{width}}
          label="Country of Citizenship"
          name="countryOfCitizenship">
          <Select mode="multiple" style={{width}} showSearch>
            {allCountries.map(data => (
              <Option key={`${data?.name};${data?.id}`}>
                <div className={styles['all-data']}>
                  <img src={data?.icon} alt="" />
                  <span>{data?.name}</span>
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item style={{width}} label="U.S Visa Type" name="visaType">
          <Select mode="tags">
            {visaTypeEnum.map(({name, key}) => (
              <Option key={key}>{name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          style={{width}}
          label="Citizenship Status"
          name="citizenStatus">
          <Select mode="tags">
            {citizenStatusEnum.map(({name, key}) => (
              <Option key={key}>{name}</Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div>
        <h1 className={styles['header-text']}>Financial Profile Filter</h1>
      </div>
      <div className={styles['invest-form']}>
        <Form.Item style={{width}} label="Annual Net Income" name="netIncome">
          <Select mode="tags">
            {annualNetIncomeEnum.map(({name, key}) => (
              <Option key={key}>{name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          style={{width}}
          label="Tax Filling Status"
          name="taxFilingStatus">
          <Select mode="tags">
            {taxFilingStatusEnum.map(({name, key}) => (
              <Option key={key}>{name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          style={{width}}
          label="Employment Status"
          name="employmentStatus">
          <Select mode="tags">
            {employmentStatusEnum.map(({name, key}) => (
              <Option key={key}>{name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          style={{width}}
          label="Estimated Net Worth"
          name="estimatedNetWorth">
          <Select mode="tags">
            {estimatedNetWorthEnum.map(({name, key}) => (
              <Option key={key}>{name}</Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div>
        <h1 className={styles['header-text']}>Investor Profile Filter</h1>
      </div>
      <div className={styles['invest-form']}>
        <Form.Item
          style={{width}}
          name="investmentExperience"
          label="InvestmentExperience">
          <Select mode="tags">
            {InvestingExperienceEnum.map(({name, key}) => (
              <Option value={key} key={key}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          style={{width}}
          label="Investment Preference"
          name="investmentPreference">
          <Select mode="tags">
            {investmentPreferenceEnum.map(({name, key}) => (
              <Option key={key}>{name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          style={{width}}
          label="Investment Horizon"
          name="investmentHorizon">
          <Select mode="tags">
            {investmentHorizonEnum.map(({name, key}) => (
              <Option key={key}>{name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          style={{width}}
          label="Investment Affiliation"
          name="investmentAffiliation">
          <Select mode="tags">
            {investmentAffiliationEnum.map(({name, key}) => (
              <Option value={key} key={key}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          style={{width}}
          label="Investment Goals"
          name="investmentGoals">
          <Select mode="tags">
            {investmentGoalsEnum.map(({name, key}) => (
              <Option value={key} key={key}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          style={{width}}
          label="Dominant Investment Strategies"
          name="investmentStrategies">
          <Select mode="tags">
            {investmentStrategiesEnum.map(({name, key}) => (
              <Option value={key} key={key}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div>
        <h1 className={styles['header-text']}>Signup Date Filter</h1>
      </div>
      <div className={styles['schedule-grid']}>
        <Form.Item name="from" label="From">
          <DatePicker
            className={styles.date}
            name="From"
            format={'YYYY-MM-DD HH:mm:ss'}
            renderExtraFooter={() => (
              <div style={{textAlign: 'center'}}>
                <p>Your timezone is: {getUserTimezone()}</p>
              </div>
            )}
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
            value={from}
            onChange={e => setFrom(moment(e).format('YYYY-MM-DD HH:mm:ss'))}
          />
        </Form.Item>
        <Form.Item name="to" label="To">
          <DatePicker
            className={styles.date}
            name="To"
            format={'YYYY-MM-DD HH:mm:ss'}
            renderExtraFooter={() => (
              <div style={{textAlign: 'center'}}>
                <p>Your timezone is: {getUserTimezone()}</p>
              </div>
            )}
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
            value={to}
            onChange={e => setTo(moment(e).format('YYYY-MM-DD HH:mm:ss'))}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default NotificationFilter;
