import {Route, Routes} from 'react-router-dom';
import {StockRewardBank} from './rewardBanksTabs/stockRewardBanks';
import {RewardBanksManagement} from './index';
import {CashRewardBank} from './rewardBanksTabs/cashRewardBanks';

const RewardBanks = () => {
  return (
    <Routes>
      <Route path="/" element={<RewardBanksManagement />}>
        <Route index path="/" element={<StockRewardBank />} />
        <Route path="/cash" element={<CashRewardBank />} />
        <Route />
      </Route>
    </Routes>
  );
};

export {RewardBanks};
