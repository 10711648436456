import gql from 'graphql-tag';

export const CREATE_GROUP = gql`
  mutation CreateGroup($data: CreateGroupInput!) {
    createGroup(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on SingleReturnedGroup {
        group {
          id
          name
          description
          bannerImg
          isPrivate
        }
      }
    }
  }
`;

export const ADD_USERS_TO_A_GROUP = gql`
  mutation AddMembersToGroup($groupId: ID!, $memberIds: [ID!]!) {
    addMembersToGroup(groupId: $groupId, memberIds: $memberIds) {
      ... on Error {
        message
        statusCode
      }
      ... on ReturnedMessage {
        message
        response
      }
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup($data: UpdateGroupInput!) {
    updateGroup(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on SingleReturnedGroup {
        group {
          id
          name
          description
          bannerImg
          isPrivate
          memberCount
          status
        }
      }
    }
  }
`;

export const REMOVE_MEMBER = gql`
  mutation RemoveMemberFromGroup($groupId: ID!, $memberIds: [ID!]!) {
    removeMemberFromGroup(groupId: $groupId, memberIds: $memberIds) {
      ... on Error {
        message
        statusCode
        type
      }
      ... on ReturnedMessage {
        message
        response
      }
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation DeleteGroup($groupId: ID!) {
    deleteGroup(groupId: $groupId) {
      ... on Error {
        message
        statusCode
      }
      ... on ReturnedMessage {
        message
        response
      }
    }
  }
`;
