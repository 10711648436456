import {createSlice} from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'news',
  initialState: {
    baseLanguage: '',
    newsContent: '',
    newsTitle: '',
    newsKeyTake: '',
    newsType: '',
    translation: [],
    summary: ['', '', ''],
    isStoreInUse: false,
  },
  reducers: {
    handleWebsiteTranslation: (state, action) => {
      return {
        ...state,
        baseLanguage: action.payload.baseLang,
        newsContent: action.payload.content,
        newsTitle: action.payload.title,
        newsType: action.payload.newsType,
        newsKeyTake: action.payload.keyTake,
        slug: action.payload.slug,
        metaDescription: action.payload.metaDescription,
        imageAltText: action.payload.imageAltText,
        keywords: action.payload.keywords,
        translation: action.payload.translation,
        summary: action.payload.summary,
        isStoreInUse: true,
      };
    },
    handleClearWebsiteTranslation: () => {
      return {
        baseLanguage: '',
        newsContent: '',
        newsTitle: '',
        newsKeyTake: '',
        newsType: '',
        translation: [],
        isStoreInUse: false,
        summary: ['', '', ''],
      };
    },
  },
});

export default slice.reducer;
export const {handleWebsiteTranslation, handleClearWebsiteTranslation} =
  slice.actions;
