import {Form, Switch, Tooltip} from 'antd';
import styles from './orderSettings.module.scss';
import {Fragment} from 'react';
import IpoFeeCard from 'pages/campaigns/new/screens/ipo/ipoFeeCard/IpoFeeCard';
import plusIcon from 'images/plus-circle.png';
import {QuestionCircleOutlined} from '@ant-design/icons';

const OrderSettings = ({fees = [], handleChangeFees, existingData}) => {
  const handleDeleteFees = id => {
    const filteredIpoFee = fees.filter((_, index) => index !== id);
    handleChangeFees(filteredIpoFee);
  };

  const handleChangeFeesValues = (index, event) => {
    const newFormValues = [...fees];
    newFormValues[index][event.target.name] = event.target.value;
    handleChangeFees(newFormValues);
  };

  const handleChangeFeeTypeField = (index, event) => {
    const newFormValues = [...fees];
    newFormValues[index].feeType = event;
    handleChangeFees(newFormValues);
  };

  const handleAddIpoFees = () => {
    handleChangeFees([
      ...fees,
      {
        feeName: '',
        feeType: '',
        fixedFeeAmount: '',
        variableFeeAmount: '',
      },
    ]);
  };

  return (
    <>
      <div className={styles.root}></div>
      <div className={styles.switch}>
        <Form.Item name="tradable" label="Tradable">
          <Switch defaultChecked={existingData?.tradeable} />
        </Form.Item>
        <Form.Item name="fractionalShares" label="Fractional Shares">
          <Switch defaultChecked={existingData?.fractionalShares} />
        </Form.Item>
      </div>
      <div className={styles.fee}>
        <h4>
          FEE SCHEDULE{' '}
          <Tooltip
            overlayStyle={{width: 1000}}
            title={
              <ul>
                {' '}
                <li>
                  - A static fee is one that is charged across orders and will
                  not be refunded irrespective of the eventual order status
                  example: Minimum Platform Charge
                </li>
                <br />
                <li>
                  - A floating fee is one that only applies to MARKET BUY orders
                  and will be refunded if not used or fully used.
                </li>
                <br />
                <li>
                  - Fees apply in the order of order level, stock level, stock
                  exchange level and they override each other in that manner.
                </li>
              </ul>
            }>
            <QuestionCircleOutlined />
          </Tooltip>
        </h4>
        {fees?.map((data, index) => (
          <Fragment key={index}>
            <IpoFeeCard
              feeName={data.feeName || ''}
              feeType={data.feeType}
              fixedFeeAmount={data.fixedFeeAmount}
              variableFeeAmount={data.variableFeeAmount}
              type="COMPANY_STOCK"
              handleChangeIpoFeesValues={e => handleChangeFeesValues(index, e)}
              handleChangeIpoFeesType={e => handleChangeFeeTypeField(index, e)}
              handleDeleteIpoFees={() => handleDeleteFees(index)}
            />
          </Fragment>
        ))}
        <div onClick={handleAddIpoFees} className={styles['add-button']}>
          <img src={plusIcon} alt="" />
          <h2>Add Fee Field</h2>
        </div>
      </div>
    </>
  );
};

export default OrderSettings;
